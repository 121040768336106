import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-hot-toast';

import { Form } from '@unform/web';
import { Row, Col } from 'reactstrap';

import { Container, Header, Card } from './styles';
import Input from '~/components/Form/Input';
import CheckboxInput from '~/components/Form/Checkbox';
import SaveButton from '~/components/Buttons/SaveButton';

import { useAuth } from '~/context/AuthContext';
import api from '~/services/api';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { VideoPlayer } from '~/components/VideoPlayer';
import { Hyperlink } from 'ui-kit-takeat';
import { FaCopy } from 'react-icons/fa';
import { PageTitle } from '~/components/PageTitle';

function DeliveryConfig() {
  const { user, getProfileData, updateUser } = useAuth();



  const initialData = {
    time_to_delivery: user.delivery_info.time_to_delivery,
    time_to_withdrawal: user.delivery_info.time_to_withdrawal,
    delivery_minimum_price: user.delivery_info.delivery_minimum_price,
    client_pay_sms: user.delivery_info.client_pay_sms,
    is_sms_service_optional: user.delivery_info.is_sms_service_optional,
    is_distance_by_path: user.delivery_info.is_distance_by_path,
    auto_accept: user.delivery_info.auto_accept,
    cep_required: user.delivery_info.cep_required,
    ifood_auto_accept: user.ifood_auto_accept,
    auto_print_delivery: user.auto_print_delivery,
    withdrawal_minimum_price: user.delivery_info.withdrawal_minimum_price,
    notify_whatsapp: user.delivery_info.notify_whatsapp,
    payment_at_withdrawal: user.delivery_info.payment_at_withdrawal

  };

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'DeliveryConfigurations'}`
      );

      const { can_read } = response.data.DeliveryConfigurations;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  // const [isNotifyWhatsapp, setIsNotifyWhatsapp] = useState(
  //   user.delivery_info.notify_whatsapp
  // );

  // const [takeOff, setTakeOff] = useState(
  //   user.delivery_info.payment_at_withdrawal
  // );

  // function changeNotifyWhatsapp() {
  //   setIsNotifyWhatsapp(!isNotifyWhatsapp);
  // }

  // function changeTakeOff() {
  //   setTakeOff(!takeOff);
  // }

  const changeDeliveryConfig = useCallback(
    async (data) => {
      try {
        await api.put('/restaurants/delivery-info', {
          time_to_delivery: data.time_to_delivery,
          time_to_withdrawal: data.time_to_withdrawal,
          delivery_minimum_price: data.delivery_minimum_price,
          has_sms_service: data.has_sms_service,
          is_sms_service_optional: data.is_sms_service_optional,
          client_pay_sms: data.client_pay_sms,
          notify_whatsapp: data.notify_whatsapp,
          payment_at_withdrawal: data.payment_at_withdrawal,
          is_distance_by_path: data.is_distance_by_path,
          auto_accept: data.auto_accept,
          cep_required: data.cep_required,
          withdrawal_minimum_price: data.withdrawal_minimum_price
        });

        updateUser({
          ifood_auto_accept: data.ifood_auto_accept,
          auto_print_delivery: data.auto_print_delivery,
        });

        getProfileData();
        // toast.success('Informações cadastradas com sucesso');
      } catch (error) {
        toast.error('Erro ao atualizar perfil do restaurante');
      }
    },
    [getProfileData, updateUser]
  );

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <PageTitle>Configurações</PageTitle>
          <VideoPlayer id={69} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
          <span>Link do seu delivery: </span>
          <Hyperlink
            fontSize={14}
            onClick={() =>
              window.open(
                `https://pedido.takeat.app/${user?.nickname ? user?.nickname : user?.name
                }`
              )
            }
          >
            https://pedido.takeat.app/
            {user?.nickname ? user?.nickname : user?.name}
          </Hyperlink>
          <Hyperlink
            fontSize={14}
            onClick={() => {
              navigator.clipboard.writeText(
                `https://pedido.takeat.app/${user?.nickname ? user?.nickname : user?.name
                }`
              );
              toast.success('Link copiado com sucesso');
            }}
          >
            <FaCopy />
          </Hyperlink>
        </div>
      </Header>

      <Card>
        <Form initialData={initialData} onSubmit={changeDeliveryConfig}>
          <Row>
            <Col lg="3">
              <Input
                label="Tempo para retirada aprox. (min.)"
                name="time_to_withdrawal"
                type="number"
                step="0.01"
              />
            </Col>
            <Col lg="3">
              <Input
                label="Tempo para entrega aprox. (min.)"
                name="time_to_delivery"
                type="number"
                step="0.01"
              />
            </Col>
            <Col lg="3">
              <Input
                label="Preço mínimo pro pedido delivery."
                name="delivery_minimum_price"
                type="number"
                step="0.01"
              />
            </Col>
            <Col lg="3">
              <Input
                label="Preço mínimo pro pedido retirada."
                name="withdrawal_minimum_price"
                type="number"
                step="0.01"
              />
            </Col>
          </Row>
          <Row>
            <h4
              style={{
                paddingLeft: 10,
                paddingBottom: 15,
                paddingTop: 30,
                textAlign: 'left',
              }}
            >
              Aviso de aceite ao cliente
            </h4>

            <Col md="12">
              <p style={{ textAlign: 'left' }}>
                O aviso de aceite, caso ativado, permite o sistema de abrir a
                página do whatsapp web, assim que o pedido for aceito,
                diretamente com o numero do cliente, para que o restaurante
                possa avisá-lo.
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm="12" lg="12">
              <CheckboxInput
                label="Ativar aviso ao cliente"
                name="notify_whatsapp"

              />
            </Col>
          </Row>

          <Row>
            <h4
              style={{
                paddingLeft: 10,
                paddingBottom: 15,
                paddingTop: 30,
                textAlign: 'left',
              }}
            >
              Pagar na Retirada
            </h4>
            <Col md="12">
              <p style={{ textAlign: 'left' }}>
                Se o seu estabelecimento aceita pagar pelo pedido na retirada,
                marque a opção a baixo. A Takeat não se responsabiliza com o não
                comparecimento do cliente nesta modalidade de pagamento.{' '}
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm="12">

              <CheckboxInput
                label="Aceita Pagar na Retirada"
                name="payment_at_withdrawal"

              />

            </Col>
          </Row>

          <Row>
            <h4
              style={{
                paddingLeft: 10,
                paddingBottom: 15,
                paddingTop: 30,
                textAlign: 'left',
              }}
            >
              Usar trajeto para calcular a distância de entrega
            </h4>
            <Col md="12">
              <p style={{ textAlign: 'left' }}>
                Se o seu estabelecimento usa o trajeto do restaurante até o
                cliente no cálculo da distância, marque a opção a baixo. Será
                usada a distância radial se esta opção estiver desmarcada.{' '}
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <CheckboxInput
                label="Usar o trajeto no cálculo da distância"
                name="is_distance_by_path"
              />
            </Col>
          </Row>

          <Row>
            <h4
              style={{
                paddingLeft: 10,
                paddingBottom: 15,
                paddingTop: 30,
                textAlign: 'left',
              }}
            >
              Aceitar Pedidos Delivery Automaticamente
            </h4>
            <Col md="12">
              <p style={{ textAlign: 'left' }}>
                Os pedidos de delivery que chegarem vão ser aceitos
                automaticamente ao invés de ir para "Pendentes".
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <CheckboxInput
                label="Aceitar Pedidos Delivery Automaticamente"
                name="auto_accept"
              />
            </Col>
          </Row>

          <Row>
            <h4
              style={{
                paddingLeft: 10,
                paddingBottom: 15,
                paddingTop: 30,
                textAlign: 'left',
              }}
            >
              Impressão Automática de Pedidos Delivery
            </h4>
            <Col md="12">
              <p style={{ textAlign: 'left' }}>
                Os pedidos de delivery que chegarem vão ser impressos
                automaticamente ao serem aceitos.
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <CheckboxInput
                label="Impressão Automática de Pedidos Delivery"
                name="auto_print_delivery"
              />
            </Col>
          </Row>

          {user?.has_ifood && (
            <>
              <Row>
                <h4
                  style={{
                    paddingLeft: 10,
                    paddingBottom: 15,
                    paddingTop: 30,
                    textAlign: 'left',
                  }}
                >
                  Aceitar Pedidos iFood Automaticamente
                </h4>
                <Col md="12">
                  <p style={{ textAlign: 'left' }}>
                    Os pedidos do iFood que chegarem vão ser aceitos
                    automaticamente ao invés de ir para "Pendentes".
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <CheckboxInput
                    label="Aceitar Pedidos iFood Automaticamente"
                    name="ifood_auto_accept"
                  />
                </Col>
              </Row>
            </>
          )}

          <Row>
            <h4
              style={{
                paddingLeft: 10,
                paddingBottom: 15,
                paddingTop: 30,
                textAlign: 'left',
              }}
            >
                CEP obrigatório em Pedidos Delivery
            </h4>
            <Col md="12">
              <p style={{ textAlign: 'left' }}>
                  O cliente não poderá fazer o cadastro e finalizar o pedido sem informar o CEP.
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <CheckboxInput
                label="CEP obrigatório em Pedidos Delivery"
                name="cep_required"
              />
            </Col>
          </Row>

          {/* 
          <Row>
            <h4 style={{ paddingLeft: 10, paddingBottom: 15, paddingTop: 30 }}>
              SMS
            </h4>
            <Col md="12">
              <p style={{ textAlign: 'left' }}>
                O serviço de SMS é a opção do seu estabelecimento enviar uma SMS
                para o celular do cliente assim que o pedido fica pronto. O
                serviço pode ser pago pelo seu estabelecimento ou pelo cliente.{' '}
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm="12" lg="6">
              <Row>
                <Col sm="12" lg="12">
                  <CheckboxInput
                    label="Painel de Senha Eletrônica"
                    name="is_sms_service_optional"
                    value
                    onChange={changePassPanel}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="12" lg="6">
                  {passPanel ? (
                    <CheckboxInput
                      label="Disponível"
                      name="has_sms_service"
                      value={smsService}
                      onChange={changeSmsService}
                    />
                  ) : (
                    <CheckboxInput
                      label="Disponível"
                      name="has_sms_service"
                      checked={checked}
                      disabled
                    />
                  )}
                </Col>
              </Row>
              {smsService ? (
                <div>
                  <FormGroup tag="fieldset">
                    <Row>
                      <Col sm="12" lg="6">
                        <CheckboxInput
                          label="Pago pelo Cliente"
                          name="client_pay_sms"
                          defaultChecked={clientPaySms}
                        />
                      </Col>
                    </Row>
                  </FormGroup>{' '}
                </div>
              ) : (
                <></>
              )}
            </Col>
            <Col sm="12" lg="6">
              <CheckboxInfo>
                <h4> Legenda</h4>
                <div>
                  <span>Painel de Senha Eletrônico</span>
                  <p>
                    Se o seu estabelecimento tiver painel de senha eletrônico,
                    marque a opção ao lado. Se sim, o cliente escolhe se quer
                    receber SMS, se não, o serviço de SMS é obrigatório.
                  </p>
                </div>
                <div>
                  <span>Disponível</span>
                  <p>Seu estabelecimento oferece a opção de SMS.</p>
                </div>
                <div>
                  <span>Pago pelo Cliente</span>
                  <p>
                    Se a opção escolhida for de pagamento pelo cliente, o total
                    do pedido do cliente será acrescido de R$0,10. Se não, o
                    serviço será pago pelo estabelecimento, e será cobrado
                    R$0,10 a cada pedido.
                  </p>
                </div>
              </CheckboxInfo>
            </Col>
          </Row> */}

          <Row>
            <Col lg="12" align="right">
              <SaveButton type="submit" />
            </Col>
          </Row>
        </Form>
      </Card>
    </Container>
  );
}

export default DeliveryConfig;