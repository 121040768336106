import React from 'react';

import { FiUsers } from 'react-icons/fi';
import { Content, Icon, ItemsCount } from '../styles';
import { Header, Bloco } from './styles';

import CloseTable from '~/components/Tables/CloseOrder/Items/CloseTable';

export default function CardCloseTable({
  tableBills,
  handlePrintItem,
  printQueueTable,
  getSessions,
  payments,
}) {
  return (
    <Bloco>
      <Header>
        <Icon>
          <FiUsers color="#fff" size={25} />
        </Icon>
        MESA
        <ItemsCount>{tableBills?.length}</ItemsCount>
      </Header>
      <Content>
        {tableBills ? (
          tableBills.map((item) => (
            <CloseTable
              key={item.id}
              item={item}
              handlePrintItem={handlePrintItem}
              printQueueTable={printQueueTable}
              tableBills={tableBills}
              payments={payments}
              getSessions={getSessions}
            />
          ))
        ) : (
          <p>Nenhum dado para informar</p>
        )}
      </Content>
    </Bloco>
  );
}
