import React from 'react';

import { ButtonContainer } from './styles';
import englishFlag from "~/assets/img/englishFlag.png"

export default function EnglishMenuButton({ text, includeIcon = false, ...rest }) {
  return (
    <ButtonContainer type="button" {...rest}>
      <img src={englishFlag} alt="Bandeira dos Estados unidos" />
      <span>Cardápio em Inglês</span>
    </ButtonContainer>
  );
}
