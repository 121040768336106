export function startingPrice(complement_categories, isDelivery) {
  const requiredCategories = complement_categories.filter(
    (cat) => !cat.optional
  );

  const value = requiredCategories.reduce((acc, cat) => {
    if (isDelivery ? cat.cheapest_delivery || cat.cheapest : cat.cheapest) {
      return (
        acc +
        parseFloat(
          isDelivery ? cat.cheapest_delivery || cat.cheapest : cat.cheapest
        ) *
          (cat.minimum ? cat.minimum : 1)
      );
    }
    const cheapestComplement = Math.min(
      ...cat.complements
        .filter((comp) => comp.available)
        .map((comp) =>
          isDelivery ? comp.delivery_price || comp.price : comp.price
        )
    );

    return acc + cheapestComplement * (cat.minimum ? cat.minimum : 1);
  }, 0);

  return value;
}