import styled from 'styled-components';

export const QrCodeArea = styled.div`
  width: 470px;
  height: 470px;

  background-color: grey;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;

  p {
    color: #fff;
    font-size: 38px;
    position: absolute;
    top: 12px;
  }
`;

export const Img2 = styled.img`
  width: 200px;
  height: 200px;
  background-color: grey;
  position: absolute;
  top: 195px;
`;

export const RestaurantName = styled.span`
  max-width: 250px;
  position: absolute;
  left: 20px;
  top: 16px;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
`;

export const RatingComment = styled.span`
  width: 270px;
  height: 120px;

  position: absolute;
  left: ${props => props.hasImage ? "100px" : "120px"};
  color: #333;
  bottom: 100px;
  line-height: 20px;

  font-size: 16px;
  /* 
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; */
`;

export const RatingAreaHeader = styled.div`
  width: 270px;
  height: 90px;
  position: absolute;
  left: 100px;
  top: 140px;

  display: flex;
  align-items: center;

  img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
  }
`;

export const RatingInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;
