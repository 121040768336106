import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';
import {
  format,
  startOfDay,
  endOfDay,
  subHours,
  addHours,
  differenceInDays,
} from 'date-fns';
import { ModalBody, ModalFooter, Modal, ModalHeader } from 'reactstrap';
import { FaTrash } from 'react-icons/fa';
import * as Yup from 'yup';
import {
  Card,
  Container,
  Header,
  TableReport,
  ButtonClose,
  RegisterButtonPersonal,
  ButtonSubmit,
} from './styles';

import api from '~/services/api';
import formatCompleteDate from '~/services/formatCompleteDate';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { useAuth } from '~/context/AuthContext';
import ButtonDefault from '~/components/Buttons/ButtonDefault';
import InputReact from '~/components/Form/Input';
import getValidationErrors from '~/services/getValidationErrors';
import {
  DefaultInput,
  DownloadButton,
  FullCalendar,
  OrderButton,
} from 'ui-kit-takeat';
import FileSaver from 'file-saver';
import { generateDiscountSheet } from '~/services/SheetGenerate/generateDiscountReportSheet';
import { getTableName } from '~/utils/getTableName';

function ReportsDiscount() {
  const {
    removediscountObs,
    restaurantDiscountObs,
    getDiscountObs,
    updateDiscountObs,
  } = useAuth();
  const [rangeOn, setRangeOn] = useState(true);
  const [infoToShow, setInfoToShow] = useState('all');
  const [discounts, setDiscounts] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const arrayFilter = (item) => {
    if (infoToShow === 'all') {
      return true;
    }
    if (infoToShow === 'discount') {
      return item.discount_obs !== null;
    }
    if (infoToShow === 'coupon') {
      return item.coupon_transaction !== null;
    }
    return true;
  };

  const filterSearchFunction = (item) => {
    if (searchValue === '') {
      return true;
    }
    return (
      item.discount_obs?.toLowerCase().startsWith(searchValue.toLowerCase()) ||
      item.coupon_transaction?.discount_coupon?.name
        ?.toLowerCase()
        .startsWith(searchValue.toLowerCase())
    );
  };

  const oldTotal = parseFloat(
    discounts
      .filter((item) => arrayFilter(item))
      .filter(filterSearchFunction)
      .reduce((acc, d) => acc + parseFloat(d.old_total_price), 0)
  );
  const total = parseFloat(
    discounts
      .filter((item) => arrayFilter(item))
      .filter(filterSearchFunction)
      .reduce((acc, d) => acc + parseFloat(d.total_price), 0)
  );
  const discountTotal = parseFloat(
    discounts
      .filter((item) => arrayFilter(item))
      .filter(filterSearchFunction)
      .reduce((acc, d) => acc + parseFloat(d.discount_total), 0)
  );

  const defaultInicial = useMemo(() => {
    return format(
      startOfDay(subHours(new Date('2023-10-01 03:00:00'), 3)),
      "yyyy-MM-dd'T'HH:mm:ss"
    );
  }, []);
  const defaultFinal = useMemo(() => {
    return format(
      endOfDay(subHours(new Date('2023-10-31 02:59:59'), 3)),
      "yyyy-MM-dd'T'HH:mm"
    );
  }, []);

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'ReportsDiscounts'}`
      );

      const { can_read } = response.data.ReportsDiscounts;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  const getDiscounts = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/reports/discount', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });
      setDiscounts(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [inicialDate, finalDate]);

  useEffect(() => {
    getDiscounts();
    validateUser();
  }, [getDiscounts, validateUser]);

  const [isRestaurantDiscountObsOpened, setIsRestaurantDiscountObsOpened] =
    useState(false);

  const toggleModalDiscountsObs = () =>
    setIsRestaurantDiscountObsOpened(!isRestaurantDiscountObsOpened);

  const [
    isCreateRestaurantDiscountObsOpened,
    setIsCreateRestaurantDiscountObsOpened,
  ] = useState(false);

  const toggleCreateModalDiscountsObs = () =>
    setIsCreateRestaurantDiscountObsOpened(
      !isCreateRestaurantDiscountObsOpened
    );

  const downloadSheet = async () => {
    try {
      const sheet = await generateDiscountSheet(discounts);

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        `Descontos(${format(new Date(inicialDate), 'dd-MM')}_${format(
          new Date(finalDate),
          'dd-MM'
        )}).xlsx`
      );
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };

  useEffect(() => {
    if (isRestaurantDiscountObsOpened) {
      getDiscountObs();
    }
  }, [getDiscountObs, isRestaurantDiscountObsOpened]);

  const formRefsubmit = useRef(null);

  async function handleAddObsDiscount(data) {
    try {
      if (formRefsubmit.current) {
        formRefsubmit.current.setErrors({});
      }

      const schema = Yup.object().shape({
        obs: Yup.string().required('Desconto obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await updateDiscountObs(data.obs);
      toast.success('Desconto cadastrado!');
      toggleCreateModalDiscountsObs();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRefsubmit.current) {
          formRefsubmit.current.setErrors(errors);
        }
      } else {
        toast.error('Erro ao cadastrar desconto.');
      }
    }
  }

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Modal
        isOpen={isRestaurantDiscountObsOpened}
        toggle={toggleModalDiscountsObs}
      >
        <ModalHeader>
          <p style={{ fontWeight: 'bold', fontSize: 16 }}> Descontos</p>
        </ModalHeader>
        <ModalBody>
          {restaurantDiscountObs &&
            restaurantDiscountObs.length > 0 &&
            restaurantDiscountObs.map((justify) => (
              <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                {justify.obs}{' '}
                <FaTrash
                  color="#ff2c3a"
                  onClick={() => {
                    removediscountObs(justify.id);
                  }}
                  cursor="pointer"
                  title="Deletar o desconto"
                />
              </p>
            ))}
          <RegisterButtonPersonal onClick={toggleCreateModalDiscountsObs}>
            + Adicionar desconto
          </RegisterButtonPersonal>
        </ModalBody>
        <ModalFooter>
          <div
            style={{
              width: '100%',
              borderTop: '1px solid #33333330',
              paddingTop: 10,
            }}
          >
            <ButtonClose onClick={toggleModalDiscountsObs}>
              Cancelar
            </ButtonClose>
          </div>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={isCreateRestaurantDiscountObsOpened}
        toggle={toggleCreateModalDiscountsObs}
      >
        <Form onSubmit={handleAddObsDiscount} ref={formRefsubmit}>
          <ModalBody>
            <InputReact name="obs" label="Adicionar desconto" />
          </ModalBody>
          <ModalFooter>
            <div
              style={{
                width: '100%',
                borderTop: '1px solid #33333330',
                paddingTop: 10,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <ButtonClose
                type="button"
                onClick={toggleCreateModalDiscountsObs}
              >
                Cancelar
              </ButtonClose>
              <ButtonSubmit type="submit">Salvar</ButtonSubmit>
            </div>
          </ModalFooter>
        </Form>
      </Modal>

      <Header>
        <div>
          <p>Acompanhe os descontos do seu estabelecimento!</p>
        </div>
        {/* <BackButton onClick={() => history.goBack()}>Voltar</BackButton> */}

        <ButtonDefault
          label="Descontos cadastrados"
          color="#FF2C3A"
          onClick={toggleModalDiscountsObs}
        />
      </Header>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <FullCalendar
          value={{ start: new Date(inicialDate), end: new Date(finalDate) }}
          hideFields={['this_year']}
          onDateSelected={(e) => {
            const days = differenceInDays(e.end, e.start);

            if (days > 31) {
              setRangeOn(false);
            } else {
              setRangeOn(true);
              setInicialDate(e.start);
              setFinalDate(e.end);
            }
          }}
        />
      </div>
      <p
        style={{
          textAlign: 'right',
          color: 'red',
          opacity: rangeOn ? 0 : 1,
        }}
      >
        Favor selecionar o intervalo máximo de 31 dias entre as datas.
      </p>

      <Card>
        <div
          style={{ display: 'flex', justifyContent: 'space-between', gap: 20 }}
        >
          <DefaultInput
            placeholder="Buscar justificativa"
            style={{ padding: '5px 10px', height: 38, maxWidth: '400px' }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 5,
            }}
          >
            <DownloadButton
              text="Baixar planilha"
              buttonWidth={110}
              onClick={downloadSheet}
            />
            <OrderButton
              title="Todos"
              color="#ff2c3a"
              onClick={() => {
                setInfoToShow('all');
              }}
              inverted={infoToShow !== 'all'}
            />
            <OrderButton
              title="Descontos"
              color="#ff2c3a"
              onClick={() => {
                setInfoToShow('discount');
              }}
              inverted={infoToShow !== 'discount'}
            />
            <OrderButton
              title="Cupom"
              color="#ff2c3a"
              onClick={() => {
                setInfoToShow('coupon');
              }}
              inverted={infoToShow !== 'coupon'}
            />
          </div>
        </div>
        <TableReport borderless>
          <thead>
            <tr>
              <th>Mesa</th>
              <th>Data/Hora</th>
              <th>Valor Original</th>
              <th>Valor c/ Desconto</th>
              <th>Desconto (R$)</th>
              <th>%</th>
              <th>Justificativa</th>
              <th>Usuário</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total</td>
              <td>-</td>
              <td>
                R$
                {oldTotal.toLocaleString('pt-br', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
              <td>
                R$
                {total.toLocaleString('pt-br', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
              <td>
                <strong>
                  R$
                  {discountTotal.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </strong>
              </td>
              <td>
                {oldTotal > 0
                  ? ((discountTotal / oldTotal) * 100).toLocaleString('pt-BR', {
                      maximumFractionDigits: 2,
                    })
                  : 0}
                %
              </td>
              <td>-</td>
              <td>-</td>
            </tr>
            {discounts
              .filter((item) => {
                return arrayFilter(item);
              })
              .filter(filterSearchFunction)
              .map((discount) => {
                const coupon = discount.coupon_transaction?.discount_coupon;
                const discountPercent = discount.discount_percent
                  ? discount.discount_percent.replace('.', ',')
                  : (
                      (discount.discount_total / discount.old_total_price) *
                      100
                    ).toLocaleString('pt-BR', {
                      maximumFractionDigits: 2,
                    });

                return (
                  <tr key={discount.id}>
                    <td>{getTableName(discount.table)}</td>
                    <td>
                      {discount.discount_at
                        ? formatCompleteDate(discount.discount_at)
                        : formatCompleteDate(discount.createdAt)}
                    </td>
                    <td>
                      R$
                      {parseFloat(discount.old_total_price).toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 2 }
                      )}
                    </td>
                    <td style={{ color: 'green' }}>
                      R$
                      {parseFloat(discount.total_price).toLocaleString(
                        'pt-br',
                        {
                          minimumFractionDigits: 2,
                        }
                      )}
                    </td>
                    <td style={{ color: 'red' }}>
                      <strong>
                        R$
                        {parseFloat(discount.discount_total).toLocaleString(
                          'pt-br',
                          { minimumFractionDigits: 2 }
                        )}
                      </strong>
                    </td>
                    <td>{discountPercent}%</td>
                    <td style={{ color: 'red' }}>
                      {coupon
                        ? `Cupom (${coupon.name})`
                        : discount.discount_obs}
                    </td>
                    <td>{discount.users?.name}</td>
                  </tr>
                );
              })}
          </tbody>
        </TableReport>
      </Card>
    </Container>
  );
}

export default ReportsDiscount;
