import React from 'react';

import { Spinner } from 'reactstrap';

import { CategoriesTable, Container, LoadingDiv, EmptyDiv } from './styles';

import NfeEntry from './NfeEntry';
import StockTransferManifestRowEntry from '../Rows/StockTransferManifestRowEntry';

export default function NfesEntry({ nfes, loading, html, getNfes, search }) {
  return (
    <Container>
      <CategoriesTable borderless>
        <thead>
          <tr>
            <th>Fornecedor</th>
            <th>Data de emissão</th>
            {/* <th>Chave NFe</th> */}
            <th>Valor</th>
            <th>Número</th>
            <th>Ações</th>
          </tr>
        </thead>
        {loading ? (
          <tbody>
            <tr>
              <td />
              <td />
              {/* <td /> */}
              <td />
              <td>
                <LoadingDiv>
                  <Spinner color="info" />
                </LoadingDiv>
              </td>
              <td />
              <td />
              <td />
              <td />
            </tr>
          </tbody>
        ) : (
          <tbody>
            {nfes?.filter(nfe => search !== '' ? (nfe?.chave_nfe.includes(search) || nfe?.numero.includes(search) || (nfe?.nome_emitente && nfe?.nome_emitente?.toLowerCase().includes(search?.toLowerCase()))) : true)
            .map((nfe, i) =>
            (!nfe.is_stock ?
              <NfeEntry key={nfe.id} nfe={nfe} html={html} index={i} length={nfes.length}/>
              :
              <StockTransferManifestRowEntry
                key={nfe.id}
                stock={nfe}
                getNfes={getNfes}
              />
            )
            )}
          </tbody>
        )}
      </CategoriesTable>
      {nfes?.length === 0 ? (
        <EmptyDiv>
          <span>Não há nenhuma Nota Fiscal com os filtros selecionados.</span>
        </EmptyDiv>
      ) : (
        <></>
      )}
    </Container>
  );
}
