import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { InputContainer, NumberFormatStyled } from './styles';

function InputCurrency({ name, label, maxlength, icon, fontSize, format, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, inputRef, registerField]);

  return (
    <InputContainer>
      {label && (
        <label htmlFor={fieldName} style={fontSize ? fontSize : null}>
          {label}
          {icon}
        </label>
      )}
      <NumberFormatStyled
        format={format}
        ref={inputRef}
        maxLength={maxlength}
        defaultValue={defaultValue && parseFloat(defaultValue) * 100}
        {...rest}
      />
      {error && (
        <p style={{ color: 'red' }}>{error}</p>
      )}
    </InputContainer>
  );
}

export default InputCurrency;
