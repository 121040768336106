import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  background-color: #ff2c3a;
  justify-content: space-between;
  align-items: center;
  color: white;
  height: 50px;
  width: 100%;
  padding: 20px;
  text-align: center;
  border-radius: 10px 10px 0 0;

  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
`;

export const Bloco = styled.div`
  width: 100%;
  min-width: 200px;
  height: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  background: white;  
  // filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  text-align: center;
`;


export const Content = styled.div`
  background-color: white;
  color: black;
  padding-top: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  border-radius: 0 0 12px 12px;
  border: solid 1px #ff2c3a;
`;

