import React, { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import formatValue from '~/services/formatValue';
import {
  Container,
  Table,
  Order,
  Body,
  Phone,
  Items,
  Item,
  ItemName,
  Complements,
  ComplementsTitle,
  Obs,
  DeliveryInfo,
  MotoboyDiv,
  DeliveryPrice,
} from './styles';

import { ProgressCircle } from '../ProgressComponent/ProgressCircle';
import { ProgressMiddleBar } from '../ProgressComponent/ProgressMiddleBar';
import api from '~/services/api';

import formatPhone from '~/services/formatPhoneString';
import { useAuth } from '~/context/AuthContext';
import formatCompleteDate from '~/services/formatCompleteDate';
import { SiIfood } from 'react-icons/si';
import { Button, DefaultSelect, Hyperlink } from 'ui-kit-takeat';
import IfoodOnDemand from '../Delivery/IfoodOnDemand';
import toast from 'react-hot-toast';
import { useOrder } from '~/context/orderContext';

export default function EyeModalOrders({ item }) {
  const [orders, setOrders] = useState([]);
  const { user } = useAuth();
  const { updateIfoodOnDemandReady } = useOrder();

  const [step, setStep] = useState(() => {
    switch (item?.basket?.order_status) {
      case 'pending':
        return 0;
      case 'accepted':
        return 1;
      case 'ready':
        return 2;
      case 'finished':
        return 3;
      default:
        return 0;
    }
  });

  const userName = item?.buyer
    ? formatPhone(
      item?.buyer?.phone ? item?.buyer.phone : item?.buyer?.ifood_phone
    ) || item?.buyer.name
    : item?.waiter?.name;

  function changeValue(value) {
    const newValue = value.replace('.', ',');

    return newValue;
  }

  const getOrders = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/basket-orders/${item.basket.id}`
      );

      setOrders(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [item]);

  function searchTableName(table) {
    if (table.table_type === 'balcony') {
      return 'Balcão';
    }

    if (table.table_type === 'pos') {
      return 'POS';
    }

    if (table.table_number === -10) {
      return 'Mesa Copa';
    }

    if (table.table_type === 'delivery' && item?.scheduled_to && !item?.with_withdrawal) {
      return 'Delivery Agendado';
    }

    if (table.table_type === 'delivery' && item?.scheduled_to && item?.with_withdrawal) {
      return 'Retirada Agendada';
    }

    if (table.table_type === 'delivery' && item?.with_withdrawal) {
      return 'Retirada';
    }

    if (table.table_type === 'delivery') {
      return 'Delivery';
    }

    if (table.table_type === 'command') {
      return `Comanda  ${table.table_number}`;
    }

    return `Mesa  ${table.table_number}`;
  }

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  function isPaidMethod(paymentMethod) {
    const paidMethods = [301, 238, 572, 2548];
    return paidMethods.includes(paymentMethod?.id);
  }

  function getPaymentStatus(item) {
    if (item?.ifood_paid) {
      return "Pagamento já efetuado.";
    }

    const paymentMethod = item?.intended_payment_method || item?.payment_method;

    if (isPaidMethod(paymentMethod)) {
      return "Pagamento já efetuado";
    } else if (item?.with_withdrawal) {
      return "Pagamento na retirada.";
    } else {
      return "Pagamento na entrega.";
    }
  }

  const [modalOnDemand, setModalOnDemand] = useState(false);
  const toggleOnDemand = () => {
    setModalOnDemand(!modalOnDemand);
  };

  const [loadingCancelOnDemand, setLoadingCancelOnDemand] = useState(false);
  const [cancelOnDemandSelect, setCancelOnDemandSelect] = useState(null);

  const [cancelOnDemandOptions, setCancelOnDemandOptions] = useState([]);
  const getCancelOnDemandOptions = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/ifood/cancellationReasons/${item.session_id}`,
        {
          params: {
            isOnDemand: true
          }
        }
      );

      if (response.data?.code === '400 BAD_REQUEST') {
        toast.error('Erro ao consultar opções de cancelamento no iFood');
        return;
      }

      setCancelOnDemandOptions(response.data);
    } catch (error) {
      toast.error('Erro ao consultar opções de cancelamento no iFood');
      console.log(error);
    }
  }, [item]);

  const handleCancelOnDemand = useCallback(async () => {
    setLoadingCancelOnDemand(true);
    try {
      await api.post(
        `/restaurants/ifood/cancel-on-demand/${item.session_id}`,
        {
          reason: cancelOnDemandSelect.label,
          cancellationCode: cancelOnDemandSelect.value
        }
      );

      updateIfoodOnDemandReady({
        order_basket_id: item.basket.id,
        ifood_on_demand_id: null
      });

      toggleCancelOnDemand();
      // setCancelOnDemandOptions(response.data);
    } catch (error) {
      toast.error('Erro ao solicitar cancelamento do Entrega Fácil');
      console.log(error);
    }
    setLoadingCancelOnDemand(false);
  }, [cancelOnDemandSelect, item]);

  const [modalCancelOnDemand, setModalCancelOnDemand] = useState(false);
  const toggleCancelOnDemand = () => {
    if (!modalCancelOnDemand) {
      getCancelOnDemandOptions();
      setCancelOnDemandSelect(null);
    }
    setModalCancelOnDemand(!modalCancelOnDemand);
  };

  return (
    <Container>

      <Table>
        <strong>
          {item?.basket?.ifood_table
            ? item?.basket?.ifood_table
            : searchTableName(item.table)}
        </strong>
      </Table>

      {item?.table?.table_type === 'command' && item?.basket?.command_table_number && (
        <p
          style={{ textAlign: 'center' }}
        >{`Mesa ${item?.basket?.command_table_number}`}</p>
      )}
      <Order>
        {item?.ifood_restaurant?.name && <p>{item?.ifood_restaurant?.name}</p>}
      </Order>
      {item?.table?.table_type === 'delivery' ? (
        <Order
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: '1px solid  #cdcdcd',
          }}
        >
          <span style={{ fontWeight: 'bold' }}>
            {' '}
            Pedido #{item?.basket.basket_id}{' '}
            {item?.attendance_password && ` / #${item?.attendance_password}`}
          </span>
          <span>
            {' '}
            {item?.buyer?.name} - {item?.buyer?.phone}{' '}
          </span>

          {item?.scheduled_to && (
            <p>{`Agendado para ${formatCompleteDate(item?.scheduled_to)}`}</p>
          )}
        </Order>
      ) : (
        <Order
          style={{
            borderBottom: '1px solid  #cdcdcd',
          }}
        >
          <span>
            {' '}
            Pedido #{item?.basket.basket_id}{' '}
            {item?.attendance_password && ` / #${item?.attendance_password}`}
          </span>
        </Order>
      )}

      <Body>
        <Phone style={{ width: '100%' }}>
          {item?.table?.table_type === 'delivery' ? (
            <DeliveryInfo>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',

                  marginTop: '70px',
                  width: '104%',
                  marginBottom: 10,
                }}
              >
                <ProgressCircle
                  label="Feito"
                  hour={
                    item?.basket?.start_time
                      ? format(new Date(item.basket.start_time), 'HH:mm')
                      : '--'
                  }
                  step={step}
                  idx={0}
                />
                <ProgressMiddleBar step={step} idx={0} />
                <ProgressCircle
                  label="Confirmado"
                  hour={
                    item?.accepted_at
                      ? format(new Date(item.accepted_at), 'HH:mm')
                      : '--'
                  }
                  step={step}
                  idx={1}
                />
                <ProgressMiddleBar step={step} idx={1} />
                <ProgressCircle
                  label="Despachado"
                  hour={
                    item?.ready_at
                      ? format(new Date(item.ready_at), 'HH:mm')
                      : '--'
                  }
                  step={step}
                  idx={2}
                />
                <ProgressMiddleBar step={step} idx={2} />
                <ProgressCircle
                  label={
                    item?.ifood_delivery_time && step < 3
                      ? 'Previsão'
                      : 'Entregue'
                  }
                  hour={
                    item?.ifood_delivery_time
                      ? format(new Date(item.ifood_delivery_time), 'HH:mm')
                      : '--'
                  }
                  step={step}
                  idx={3}
                />
              </div>

              {item?.ifood_on_demand_id &&
                <>
                  <div style={{ marginTop: 10 }}>
                    <SiIfood color="#FF2C3A" size={17} style={{ marginRight: 5 }} />
                    Acompanhar entrega via Entrega Fácil:
                    <a href={`https://meupedido.ifood.com.br/${item.ifood_on_demand_id}`}
                      target="_blank"
                      style={{ color: 'blue' }}
                    > clique aqui
                    </a>
                  </div>
                  <div style={{ marginBottom: 5 }}>
                    <Hyperlink color='#FF2C3A' fontSize={14} textDecoration='underline' onClick={toggleCancelOnDemand}>
                      Cancelar entrega fácil
                    </Hyperlink>
                  </div>

                  <Modal isOpen={modalCancelOnDemand} toggle={toggleCancelOnDemand}>
                    <ModalBody>
                      <p><strong>Tem certeza que deseja cancelar a Entrega Fácil?</strong></p>
                      <p>Selecione o motivo</p>
                      <DefaultSelect
                        name="Motivo do cancelamento"
                        options={cancelOnDemandOptions}
                        value={cancelOnDemandSelect}
                        onChange={e => setCancelOnDemandSelect(e)}
                      />

                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
                        <Button
                          title='Fechar'
                          onClick={toggleCancelOnDemand}
                        />

                        <Button
                          title='Confirmar'
                          buttonColor='#2EC9B7'
                          disabled={!cancelOnDemandSelect || loadingCancelOnDemand}
                          onClick={handleCancelOnDemand}
                        />
                      </div>
                    </ModalBody>
                  </Modal>
                </>
              }


              {user.has_ifood && [2, 15516, 49803, 31884, 54753, 44424].includes(user.id) &&
                !item?.basket?.ifood_id &&
                item.basket?.order_status === 'ready' &&
                item.buyer?.delivery_address?.latitude &&
                item.buyer?.delivery_address?.longitude &&
                !item.ifood_on_demand_id &&
                <>
                  <Button
                    title={<><SiIfood color="#FF2C3A" size={17} style={{ marginRight: 5 }} /> Entrega Fácil iFood</>}
                    inverted
                    style={{ paddingTop: 5, paddingBottom: 5 }}
                    onClick={toggleOnDemand}
                  />

                  <Modal isOpen={modalOnDemand} toggle={toggleOnDemand}>
                    <IfoodOnDemand
                      item={item}
                      user={user}
                      toggleModal={toggleOnDemand}
                    />
                  </Modal>
                </>
              }

              {item?.basket?.schedule && (
                <strong>
                  <span>{item?.basket?.schedule}</span>
                </strong>
              )}

              {item?.delivery_by && (
                item?.delivery_by === "MERCHANT" ? (
                  <p style={{ fontWeight: 'bold', fontSize: 16 }}>
                    Modalidade de entrega: <span style={{ fontWeight: "normal" }}>Agente Fixo</span>
                  </p>
                ) : (
                  <p style={{ fontWeight: 'bold', fontSize: 16 }}>
                    Modalidade de entrega: <span style={{ fontWeight: "normal" }}>Agente Livre (Nuvem)</span>
                  </p>
                )
              )}

              {item?.basket?.ifood_id && (
                <span>
                  {item?.buyer?.ifood_phone} - ID: {item?.buyer?.localizer}
                </span>
              )}

              {item?.with_withdrawal.toString() === 'false' && (
                <>
                  <p style={{ fontWeight: 'bold', fontSize: 16 }}>
                    Endereço de entrega
                  </p>
                  <span>
                    {item?.buyer?.delivery_address?.street || ''} -{' '}
                    {item?.buyer?.delivery_address?.number || ''}
                  </span>
                  <span>{item?.buyer?.delivery_address?.complement || ''}</span>
                  <span>
                    {item?.buyer?.delivery_address?.neighborhood || ''}
                  </span>
                  <span>
                    {item?.buyer?.delivery_address?.city || ''} -{' '}
                    {item?.buyer.delivery_address?.state || ''}
                  </span>
                  <span>{item?.buyer?.delivery_address?.zip_code || ''}</span>
                  <span>{item?.buyer?.delivery_address?.reference || ''}</span>
                </>
              )}

              {item?.with_withdrawal && (
                <span style={{ fontWeight: 'bold', marginTop: 10 }}>
                  Cliente irá retirar o produto.
                </span>
              )}
            </DeliveryInfo>
          ) : (
            <>
              {item?.buyer?.phone
                ? `${userName} - ${item?.buyer?.name || ''}`
                : `${userName} `}
            </>
          )}
        </Phone>
        {/* <Time>{formatTime(item?.basket.start_time)}</Time> */}
      </Body>

      <Items>
        <p style={{ fontWeight: 'bold', fontSize: 16 }}>Pedido:</p>
        {orders.length > 0 ? (
          orders.map((order) => (
            <Item key={order.id}>
              <ItemName>
                {order.use_weight ? (
                  <span>
                    {changeValue(order.weight)}kg {order.product.name}
                  </span>
                ) : (
                  <span>
                    {order.amount}x {order.product.name}
                  </span>
                )}
              </ItemName>
              {order.complement_categories.map((category) => (
                <div key={category.id}>
                  <ComplementsTitle>
                    {category.complement_category.name}:
                  </ComplementsTitle>
                  {category.order_complements.map((complement) => (
                    <Complements key={complement.id}>
                      - {complement.amount} x {complement.complement.name}{' '}
                    </Complements>
                  ))}
                </div>
              ))}
              {order.details !== '' && <Obs>Obs: {order.details}</Obs>}
            </Item>
          ))
        ) : (
          <div style={{ marginTop: 20 }}>
            <Spinner />
          </div>
        )}
      </Items>

      {item?.table.table_type === 'delivery' && (
        <DeliveryPrice>
          <>
            <span>
              <span>
                {getPaymentStatus(item)}
              </span>
            </span>
          </>

          <span>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ fontWeight: 'bold', marginTop: 10, fontSize: 14 }}>
                Método de Pagamento
              </span>
              <span style={{ fontWeight: 'normal', fontSize: 14 }}>
                {item?.intended_payment_method?.name
                  ? item?.intended_payment_method?.name
                  : item?.payment_method?.name
                    ? item?.payment_method?.name
                    : 'Não informado'}
              </span>
            </div>
          </span>

          {item?.details && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ fontWeight: 'bold', marginTop: 10, fontSize: 14 }}>
                Informações adicionais
              </span>
              <span style={{ fontWeight: 'normal', fontSize: 14 }}>
                {item?.details}
              </span>
            </div>
          )}
          <div style={{ marginTop: 10 }}>
            <span>Total do pedido:</span>
            <span>
              {formatValue(
                parseFloat(item?.basket?.total_price) +
                parseFloat(item?.merchant_discount || 0) -
                parseFloat(item?.additional_fees || 0)
              )}
            </span>
          </div>

          {item?.delivery_tax_price && (
            <div>
              <span>Taxa de entrega:</span>
              <span>{formatValue(item?.delivery_tax_price)}</span>
            </div>
          )}
          {!item?.basket?.ifood_id && item?.delivery_fee_discount && item?.delivery_fee_discount > 0 && (
            <div>
              <span>
                Cupom de entrega grátis aplicado
              </span>
            </div>
          )}
          {item?.additional_fees && item?.additional_fees > 0 && (
            <div>
              <span>Taxa de serviço iFood:</span>
              <span>{formatValue(item?.additional_fees)}</span>
            </div>
          )}
          {item?.coupon_discount &&
            item?.ifood_discount === null &&
            item?.merchant_discount === null && (
              <div>
                <span>Desconto do cupom:</span>
                <span> {formatValue(item?.coupon_discount)}</span>
              </div>
            )}
          {item?.ifood_discount > 0 && (
            <div>
              <span>Cupom do iFood:</span>
              <span>- {formatValue(item?.ifood_discount)}</span>
            </div>
          )}
          {item?.merchant_discount > 0 && (
            <div>
              <span>Cupom do restaurante:</span>
              <span>- {formatValue(item?.merchant_discount)}</span>
            </div>
          )}
          {item?.rescue && (
            <div>
              <span>Resgate do clube:</span>
              <span>- {formatValue(item?.rescue.cashback)}</span>
            </div>
          )}

          <div>
            <span>Total:</span>
            <span>
              {item?.rescue
                ? formatValue(
                  Math.max(
                    Number(item?.total_delivery_price) -
                    Number(item?.rescue?.cashback) -
                    Number(item?.ifood_discount || 0),
                    0
                  )
                )
                : formatValue(Number(item?.total_delivery_price) - Number(item?.ifood_discount || 0))}
            </span>
          </div>

          {item?.user_change > 0 &&
            (item?.intended_payment_method?.name === 'Dinheiro' ||
              item?.intended_payment_method?.name === 'Dinheiro (iFood)') && (
              <>
                <div>
                  <span>Receber do cliente:</span>
                  <span>{formatValue(Number(item?.user_change))}</span>
                </div>
                <div>
                  <span>Troco:</span>
                  <span>
                    {formatValue(
                      Number(item?.user_change) -
                      (Number(item?.total_delivery_price) -
                        (Number(item?.rescue?.cashback) || 0) -
                        Number(item?.ifood_discount || 0))
                    )}
                  </span>
                </div>
              </>
            )}
        </DeliveryPrice>
      )}
      {item.motoboy && (
        <Items>
          <strong>Motoboy</strong>
          <MotoboyDiv>
            <span>{item.motoboy.name}</span>
            <span>
              {item.motoboy.phone
                ? `${item.motoboy.phone.substring(
                  0,
                  9
                )}-${item.motoboy.phone.substring(9)}`
                : ''}
            </span>
          </MotoboyDiv>
        </Items>
      )}
    </Container>
  );
}