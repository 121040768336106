import styled from 'styled-components';

import { ButtonDropdown, DropdownToggle } from 'reactstrap';

export const Container = styled.div`
  padding: 0 20px 20px;
  height: calc(100vh - 136px);
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const ConfigButton = styled(ButtonDropdown)`
  height: 50px;
  padding: 0px;
  padding-bottom: 4px;
  align-items: flex-end;
  align-self: flex-end;
  border: none;
  background-color: transparent;
  svg {
    color: #333;
    cursor: pointer;
  }
`;

export const ConfigDropdownToggle = styled(DropdownToggle)`
  display: flex;
  color: #0ccab4 !important;
  font-weight: bold;
  font-size: 12pt;
  text-decoration: underline !important;
  cursor: pointer;

  svg {
    color: #0ccab4;
  }
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px;
`;
