import styled from 'styled-components';

export const Header = styled.div`
  background-color: #5c95ff;
  padding: 20px 0px;
  justify-content: center;
  color: white;
  font-size: 20px;
  height: 70px;
  text-align: center;

  @media (max-width: 410px) {
    font-size: 14px;
    align-items: center;
  }
`;

export const Bloco = styled.div`
  margin: 20px auto;
  width: 48%;
  min-width: 200px;
  height: 250px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  margin-left: 20px auto;
  background: white;
  border-radius: 8px;
  border: solid 2px #5c95ff;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));

  @media (max-width: 410px) {
    margin: 0px auto;
    width: 90%;
    min-width: 200px;
    margin-top: 16px;
    font-size: 12px;
  }
`;
