import React, { useState, useCallback, useEffect } from 'react';

import { Row } from 'reactstrap';

import { toast } from 'react-hot-toast';
import { Wrapper } from './styles';

import api from '~/services/api';

function PrintNFePage(props) {
  const { ref } = props.match.params;

  const [html, setHtml] = useState(null);
  const getHtmlNfce = useCallback(async () => {
    try {
      const response = await api.get(`restaurants/nfce-get-html/${ref}`);
      const newHtml = response.data.html.replace(
        '/images/logo-nfce.png',
        'https://api.focusnfe.com.br/images/logo-nfce.png'
      );
      setHtml(newHtml);
    } catch (err) {
      console.log(err);
      alert(err);
      toast.error('Erro ao carregar informações');
    }
  }, []);

  useEffect(() => {
    getHtmlNfce();
  }, [getHtmlNfce]);

  useEffect(() => {
    if (html !== null) {
      window.print();
      window.close();
    }
  }, [html]);

  return (
    <Wrapper>
      <Row>
        <div
          style={{ margin: 'auto' }}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </Row>
    </Wrapper>
  );
}

export default PrintNFePage;
