import styled, { css } from 'styled-components';

import { Table } from 'reactstrap';

export const Container = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 30px;
  justify-content: center;
  display: flex;
`;
export const ButtonStyled = styled.button`
  margin-right: 8px;
  border: none;
  padding: 8px;
  border-radius: 7px;
  color: #fff;
  font-size: 14px;

  svg {
    margin-right: 8px;
  }

  ${(props) =>
    props.operationType === 'deposit' &&
    css`
      background-color: #28a745;
    `}

  ${(props) =>
    props.operationType === 'withdrawal' &&
    css`
      background-color: #ff403d;
    `}

    ${(props) =>
    props.operationType === 'report' &&
    css`
      background-color: #33333370;
    `}

      &:hover {
    filter: opacity(0.9);
  }
`;

export const CategoriesTable = styled(Table)`
  width: 95%;
  min-width: 400px;
  align-items: center;
  border-collapse: separate;
  border-spacing: 0 20px;
  background: #fff6b3;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  padding: 0 30px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #dee2e6;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;
export const StyledLink = styled.button`
  &:hover {
    text-decoration: none;
  }
  border: none;

  margin-right: 10px;
`;

export const Delete = styled.div`
  border: none;
  background: none;

  &:active {
    border: none;
  }
  &:hover {
    cursor: pointer;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div`
  button{
    margin-bottom: 8px;
  }
`;

export const StatusTag = styled.div`
  width: 138px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  background-color: ${(props) => `${props.color}20`};

  span{
    color: ${(props) => props.color};
  }
  

`;
export const ModalPaymentBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const DisplayNoneImprimir = styled.button`
  display: none;
`;

export const DeleteAndEditActions = styled.div`
display: grid;
  /* display: flex;
  align-self: center;
  margin-top: auto;
  flex-direction: column;
  margin: auto; */
`;

export const CloseButton = styled.button`
  height: 25px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 0px;
  border: none;
  background: #2EC9B7;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 6px;
  width: 98%;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const ActionsInside = styled.div`
  display: flex;
  gap: 7px;
  flex-wrap: wrap;
  height: 83px;
  align-items: center;
  justify-content: flex-end;
  width: 314px;

  button{
      width: 153px;
  }
  
  `;

export const InnerArea = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 90vh;
  padding: 20px 20px 220px;

  @media (max-width: 1281px) {
    height: 90vh;
  }

  @media (min-width: 1282px) and (max-width: 1450px) {
    padding: 20px 20px 420px;
     height: 90vh;
  }

  ${(props) =>
    props.browserZoomLevel > 100 &&
    css`
      padding: 20px 20px 420px;
    `}
`;

export const CashierButton = styled.button`
  height: 25px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 0px;
  border: none;
  background: #01AFFF;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 6px;
  width: 98%;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const ReopenButton = styled.button`
  height: 25px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: #FFA814;
  align-self: center;
  margin-right: 0px;
  border: 1px solid #FFA814;
  background: #fff;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 6px;
  width: 98%;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    background: #FFA81420;
    border: none;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const SeeButton = styled.button`
  height: 25px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 0px;
  border: none;
  background: #FFA814;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 6px;
  width: 98%;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const Print = styled.button`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-self: center;
  float: right;
  position: relative;
  display: block;
  right: 0;
  margin-right: 10px;
  border: none;
  background: #f0b402;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
    border-radius: 50%;
  }
`;
