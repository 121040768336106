import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  padding: 20px;
`;

export const Wrapper = styled.div`
  margin: 10px;
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
    color: red;
  }
`;
