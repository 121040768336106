import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Content } from './styles';
import Header from './Header';

import Tasks from './Tasks';
import UserProfile from './UserProfile';
import Profile from './Profile';

function ProfilePage() {
  return (
    <>
      <Header />
      <Content>
        <Switch>
          <Route path="/profile/info" component={Profile} />
          <Route path="/profile/tasks" component={Tasks} />
          <Route path="/profile/user-profile" component={UserProfile} />
        </Switch>
      </Content>
    </>
  );
}

export default ProfilePage;
