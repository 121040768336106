import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Container } from 'reactstrap';
import { Wrapper, Header, TablesPlace } from './styles';

import history from '~/services/history';

import BackButton from '~/components/Buttons/BackButton';
import api from '~/services/api';

import CardCloseIndividual from '~/components/Tables/CloseOrder/Cards/CardCloseIndividual';
import CardCloseTable from '~/components/Tables/CloseOrder/Cards/CardCloseTable';
import UpdateButton from '~/components/Buttons/UpdateButton';

function CloseSolicitation() {
  const [individualBills, setIndividualBills] = useState([]);
  const [tableBills, setTableBills] = useState([]);

  async function getBillsIndividual() {
    const response = await api.get('restaurants/closing/individual-bills');
    setIndividualBills(response.data);
  }

  useEffect(() => {
    getBillsIndividual();
  }, []);

  async function handlePrintItem({ session_key, bill_id }) {
    try {
      await api.put('restaurants/close-individual-bill', {
        key: session_key,
        bill_id,
      });
      getBillsIndividual();
      toast.success('Comanda fechada!');
    } catch (error) {
      toast.error('Não foi possível finalizar a comanda! tente novamente');
    }
  }

  async function getBillsTable() {
    const response = await api.get('restaurants/table-sessions', {
      params: {
        status: 'closing',
      },
    });

    setTableBills(response.data);
  }

  useEffect(() => {
    getBillsTable();
  }, []);

  async function handlePrintItemTable({ session_key }) {
    try {
      await api.put('restaurants/close-table-bill', {
        key: session_key,
      });
      getBillsTable();
      toast.success('Comanda fechada!');
    } catch (error) {
      toast.error('Não foi possível finalizar a comanda! tente novamente');
    }
  }

  async function handleUpdate() {
    getBillsIndividual();
    getBillsTable();
  }

  const validateUser = useCallback(async () => {
    try {
      await api.post('/restaurants/users/permission');
    } catch (error) {
      if (error.response.data.payload.user_access === 'pdv') {
        window.location.href = '/operation';
      }
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  return (
    <Wrapper>
      <Header>
        <div>
          <h2>Solicitações de Fechamento de Comandas</h2>
          <p>
            Aqui você acompanha as solicitações de fechamento de comandas
            individuais e das mesas.
          </p>
          <UpdateButton onClick={handleUpdate} />
        </div>
        <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
      </Header>
      <Container>
        <TablesPlace>
          <CardCloseIndividual
            individualBills={individualBills}
            handlePrintItem={handlePrintItem}
          />
          <CardCloseTable
            handlePrintItem={handlePrintItemTable}
            tableBills={tableBills}
          />
        </TablesPlace>
      </Container>
    </Wrapper>
  );
}

export default CloseSolicitation;
