/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import Switch from 'react-switch';
import { toast } from 'react-hot-toast';

import {
  Modal,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Tooltip,
} from 'reactstrap';
import { FiX } from 'react-icons/fi';
import { FaAngleDown, FaEllipsisV } from 'react-icons/fa';
import ReactModal from 'react-modal';
import api from '~/services/api';
import { useMenu } from '~/context/OrderingSystem/Menu';

import {
  Container,
  CategoryHeader,
  ButttonArea,
  CategoryName,
  AreaSwitcher,
  DropdownItemStyled,
} from './styles';

import RegisterButton from '~/components/Buttons/RegisterButton';
import RadioGroup from '~/components/RadioGroup';
import PersonalButton from '~/components/Buttons/PersonalButton';

import ProductsTables from '../../../pages/Products/ProductsTables';
import CreateProduct from '~/pages/Products/Create';
import OrderProductCategory from '~/pages/Products/OrderProductCategory';

import formatTime from '~/services/formatTime';

const duplicateOptions = [
  {
    value: 1,
    label:
      'Duplicar categoria de produtos e manter os vínculos com os complementos.',
    description:
      'Cria uma cópia da categoria e mantém os complementos originais.',
  },
  {
    value: 2,
    label: 'Duplicar categoria de produtos e ignorar os complementos.',
    description:
      'Cria uma cópia da categoria sem os "Grupos de complementos" associados.',
  },
  {
    value: 3,
    label: 'Duplicar categoria de produtos e seus complementos.',
    description:
      'Cria uma cópia da categoria e também cria uma cópia dos "Grupos de complementos" associados.',
  },
];

function CategoryItem({
  category,
  user,
  getCategories,
  handleChangeModalEditCategory,
  handleDeleteCategory,
  menewProducts,
  categories,
  cache,
  setCategoriesFiltered,
  setCategories,
  idx,
  setComplementsCategories,
  complementCategories,
  fiscalGroups,
}) {
  const { updateMenuCategory } = useMenu();
  const [modalOrdenationCategory, setModalOrdenationCategory] = useState(false);
  const [modalCreateProduct, setModalCreateProduct] = useState(false);
  const [pdvAvailable, setPdvAvailable] = useState(category?.available);
  const [deliveryAvailable, setDeliveryAvailable] = useState(
    category?.available_in_delivery
  );

  const [isExclusiveAvailable, setIsExclusiveAvailable] = useState(
    category?.is_exclusive
  );

  const canCreateProductsCategory =
    !category.is_multistore_child ||
    !user?.price_table ||
    user?.price_table?.create_product_categories;

  const canCreateProducts =
    !category.is_multistore_child ||
    !user.price_table ||
    user?.price_table?.create_products;

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    setPdvAvailable(category?.available);
    setDeliveryAvailable(category?.available_in_delivery);
    setIsExclusiveAvailable(category?.is_exclusive);
  }, [category]);

  const toggleModalCreateProduct = useCallback(() => {
    setModalCreateProduct(!modalCreateProduct);
  }, [modalCreateProduct]);

  function toggleModalOrdenationCategory() {
    setModalOrdenationCategory(!modalOrdenationCategory);
  }

  async function handleChangeCategory(value, event, idx) {
    const id = Number(idx);
    try {
      await api.put(`restaurants/product-category-available/${id}`, {
        available: value,
      });

      setPdvAvailable(value);
      if (category) {
        category.available = value;
      }

      updateMenuCategory(id, { available: value });
      toast.success('Categoria alterada com sucesso!');
    } catch (err) {
      toast.error('Erro ao ativar/ desativar categoria!');
    }
  }

  async function handleChangeDeliveryCategory(value, event, idx) {
    const id = Number(idx);
    try {
      await api.put(`restaurants/product-category-available/${id}`, {
        available_in_delivery: value,
      });
      setDeliveryAvailable(value);
      if (category) {
        category.available_in_delivery = value;
      }

      updateMenuCategory(id, { available: value });
      toast.success('Categoria alterada com sucesso!');
    } catch (err) {
      toast.error('Erro ao ativar/ desativar categoria!');
    }
  }

  async function handleChangeExclusiveCategory(value, event, idx) {
    const id = Number(idx);
    try {
      await api.put(`restaurants/product-category-available/${id}`, {
        is_exclusive: value,
      });
      setIsExclusiveAvailable(value);
      if (category) {
        category.is_exclusive = value;
      }

      updateMenuCategory(id, { available: value });

      toast.success('Categoria alterada com sucesso!');
    } catch (err) {
      toast.error('Erro ao editar categoria!');
    }
  }

  const productsRef = useRef(null);
  const toggleProductsList = () => {
    return productsRef?.current?.toggle();
  };

  const handleSetProduct = useCallback(
    (data) => {
      productsRef.current.setProductsState(data);
      toggleModalCreateProduct();
    },
    [toggleModalCreateProduct]
  );

  const [collapsed, setCollapsed] = useState([]);

  const handleCollapse = (id) => {
    if (collapsed.includes(id)) {
      const newCollapse = collapsed.filter((c) => c !== id);
      setCollapsed(newCollapse);
    } else {
      setCollapsed((state) => [...state, id]);
    }
  };

  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [dupOptionSelected, setDupOptionSelected] = useState(1);

  const handleDuplicateCategoryProduct = useCallback(
    async (category_id) => {
      try {
        let complements_action = 'keep';

        if (dupOptionSelected === 2) {
          complements_action = 'remove';
        } else if (dupOptionSelected === 3) {
          complements_action = 'duplicate';
        }

        const response = await api.put(
          `/restaurants/product-category/duplicate/${category_id}`,
          {},
          {
            params: {
              complements_action,
            },
          }
        );

        const duplicate_item = response.data;

        const newCategories = [...categories];

        newCategories.splice([idx + 1], 0, duplicate_item);
        setCategories(newCategories);
        localStorage.setItem('@gddashboard:isMenuUpdated', true);
        toast.success('Categoria duplicado com sucesso');
      } catch (error) {
        toast.error('Erro ao duplicar categoria');
      }
    },
    [categories, idx, setCategories, dupOptionSelected]
  );

  const [tooltipOpen, setTooltipOpen] = useState(false);

  function toggleTip() {
    setTooltipOpen(!tooltipOpen);
  }

  const [tooltipEllipsisOpen, setTooltipEllipsisOpen] = useState(false);

  function toggleTip() {
    setTooltipOpen(!tooltipOpen);
  }

  function toggleEllipsisTip() {
    setTooltipEllipsisOpen(!tooltipEllipsisOpen);
  }

  return (
    <Container>
      <Modal
        isOpen={modalOrdenationCategory}
        toggle={toggleModalOrdenationCategory}
        size="lg"
        onClosed={getCategories}
      >
        <ModalBody>
          <OrderProductCategory
            updateCategories={getCategories}
            toggleModalOrdenationCategory={toggleModalOrdenationCategory}
          />
        </ModalBody>
      </Modal>
      <CategoryHeader>
        <CategoryName>
          {category?.enable_times && category?.start_time ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              <span>{category?.name}</span>
              <span
                style={{ marginLeft: '10px', color: '#33333390', fontSize: 16 }}
              >{`- Disponível das ${category?.start_time ? formatTime(category?.start_time) : ''
                } às ${category?.end_time ? formatTime(category?.end_time) : ''
                }`}</span>
            </div>
          ) : (
            <span>{category?.name}</span>
          )}
        </CategoryName>

        <div>
          <AreaSwitcher title="Caso ativo, a categoria nao aparecerá disponível para o cliente pedir.">
            <span>Uso Exclusivo</span>
            <Switch
              id={`${category.id}`}
              onChange={handleChangeExclusiveCategory}
              draggable
              checked={isExclusiveAvailable}
              checkedIcon={false}
              uncheckedIcon={false}
              height={27}
              width={44}
              handleDiameter={16}
              activeBoxShadow="0 0 2px 3px #111"
              boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
              onColor="#0CCAB4"
              offColor="#666666"
            />
          </AreaSwitcher>

          {!user?.only_delivery && (
            <AreaSwitcher title="Ativar/ Desativar no PDV">
              <span>PDV</span>
              <Switch
                id={`${category.id}`}
                onChange={handleChangeCategory}
                draggable
                checked={pdvAvailable}
                checkedIcon={false}
                uncheckedIcon={false}
                height={27}
                width={44}
                handleDiameter={16}
                activeBoxShadow="0 0 2px 3px #111"
                boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                disabled={category.is_ifood}
                onColor="#0CCAB4"
                offColor="#666666"
              />
            </AreaSwitcher>
          )}

          {user?.delivery_info?.is_delivery_allowed && (
            <AreaSwitcher title="Ativar/ Desativar no Delivery">
              <span>Delivery</span>
              <Switch
                id={`${category.id}`}
                onChange={handleChangeDeliveryCategory}
                draggable
                checked={deliveryAvailable}
                checkedIcon={false}
                uncheckedIcon={false}
                height={27}
                width={44}
                handleDiameter={16}
                activeBoxShadow="0 0 2px 3px #111"
                boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                disabled={category.is_ifood}
                onColor="#0CCAB4"
                offColor="#666666"
              />
            </AreaSwitcher>
          )}

          <FaAngleDown
            onClick={() => {
              toggleProductsList();
              handleCollapse(category.id);
            }}
            size={28}
            style={{
              transition: 'all 0.2s',
              transform: collapsed.includes(category.id)
                ? 'rotate(0deg)'
                : 'rotate(-180deg)',
              marginLeft: 22,
            }}
            href="#"
            id={`arrow2-${idx}`}
          />
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            autohide={false}
            target={`arrow2-${idx}`}
            toggle={toggleTip}
          >
            Mostrar produtos
          </Tooltip>
          {!category.is_ifood && (
            <div
              style={{
                marginLeft: 0,
                display: 'flex',
                alignItems: 'center',
                height: 30,
              }}
            >
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
                direction="down"
              >
                <DropdownToggle tag="span">
                  {' '}
                  <FaEllipsisV
                    style={{ marginLeft: 20 }}
                    size={20}
                    id={`ellipsis2-${idx}`}
                  />
                  <Tooltip
                    placement="top"
                    isOpen={tooltipEllipsisOpen}
                    autohide={false}
                    target={`ellipsis2-${idx}`}
                    toggle={toggleEllipsisTip}
                  >
                    Mostrar Opções
                  </Tooltip>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItemStyled
                    onClick={() => {
                      handleChangeModalEditCategory(category);
                    }}
                  >
                    Editar Categoria
                  </DropdownItemStyled>
                  {!category.is_multistore_child && (
                    <DropdownItemStyled
                      disabled={!canCreateProductsCategory}
                      onClick={() => handleDeleteCategory(category.id)}
                    >
                      Excluir Item
                    </DropdownItemStyled>
                  )}
                  {!category.is_multistore_child && (
                    <DropdownItemStyled
                      disabled={!canCreateProductsCategory}
                      onClick={() => setShowDuplicateModal(true)}
                    >
                      Duplicar Item
                    </DropdownItemStyled>
                  )}
                  <DropdownItemStyled onClick={toggleModalOrdenationCategory}>
                    Ordenar Categoria
                  </DropdownItemStyled>
                </DropdownMenu>
              </Dropdown>
            </div>
          )}
        </div>
      </CategoryHeader>
      <ButttonArea>
        <RegisterButton
          text="Adicionar Produto"
          title="Adicionar produto"
          onClick={() => {
            toggleModalCreateProduct();
          }}
          disabled={!canCreateProducts}
        />
      </ButttonArea>

      <ProductsTables
        category={category}
        user={user}
        getCategories={getCategories}
        menewProducts={menewProducts}
        categories={categories}
        ref={productsRef}
        cache={cache}
        setCategoriesFiltered={setCategoriesFiltered}
        setCategories={setCategories}
        setComplementsCategories={setComplementsCategories}
        complementCategories={complementCategories}
        fiscalGroups={fiscalGroups}
      />

      <ReactModal
        isOpen={modalCreateProduct}
        onRequestClose={toggleModalCreateProduct}
        className="modal-content-takeat-without-overflow"
        closeTimeoutMS={500}
      >
        <CreateProduct
          category={category}
          togle={toggleModalCreateProduct}
          getCategory={getCategories}
          handleSetProduct={handleSetProduct}
          categories={categories}
          cache={cache}
          fiscalGroups={fiscalGroups}
        />
      </ReactModal>

      <Modal
        isOpen={showDuplicateModal}
        toggle={() => setShowDuplicateModal(false)}
        size="lg"
        style={{ maxWidth: '534px' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            width: '100%',
            padding: '24px 24px 0px 24px',
          }}
        >
          <strong style={{ fontSize: 18 }}>
            Como deseja duplicar a Categoria "{category?.name || '??'}"?
          </strong>

          <FiX
            size={22}
            color="#FF2129"
            cursor="pointer"
            onClick={() => setShowDuplicateModal(false)}
          />
        </div>

        <ModalBody style={{ lineHeight: 1.5 }}>
          <RadioGroup
            selected={dupOptionSelected}
            labelStyle={{ marginTop: -2, fontWeight: 500 }}
            options={duplicateOptions}
            onClick={(v) => setDupOptionSelected(v)}
          />
        </ModalBody>

        <ModalFooter style={{ justifyContent: 'flex-end' }}>
          <PersonalButton
            type="submit"
            color="#58CEB1"
            message="Confirmar"
            onClick={() => {
              setDupOptionSelected(1);
              setShowDuplicateModal(false);
              handleDuplicateCategoryProduct(category?.id);
            }}
          />
        </ModalFooter>
      </Modal>
    </Container>
  );
}

export default CategoryItem;