export const sheetStyles = (rows, num_cols, worksheet, highlightRow) => {
  const columns = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  for (let i = 0; i < rows.length; i += 1) {
    for (let j = 0; j < num_cols; j += 1) {
      const cell = worksheet[columns[j] + (i + 1)];

      if (cell) {
        cell.s = {
          font: {
            sz: 12,
            name: 'Poppins',
          },
          alignment: {
            horizontal: 'center',
          },
          fill: {
            fgColor: { rgb: i % 2 === 0 ? 'ffffff' : 'fff0f0' },
          },
          border: {
            top: { style: 'thin', color: { rgb: 'FF000000' } },
            bottom: { style: 'thin', color: { rgb: 'FF000000' } },
            left: { style: 'thin', color: { rgb: 'FF000000' } },
            right: { style: 'thin', color: { rgb: 'FF000000' } },
          },
        };

        if (i === 0) {
          cell.s = {
            fill: {
              fgColor: { rgb: 'ff2c3a' },
            },
            font: {
              bold: true,
            },
            alignment: {
              horizontal: 'center',
            },
            border: {
              top: { style: 'thin', color: { rgb: 'FF000000' } },
              bottom: { style: 'thin', color: { rgb: 'FF000000' } },
              left: { style: 'thin', color: { rgb: 'FF000000' } },
              right: { style: 'thin', color: { rgb: 'FF000000' } },
            },
          };
        }
        if (highlightRow && i === highlightRow) {
          cell.s = {
            fill: {
              fgColor: { rgb: 'fbb1b6' },
            },
            alignment: {
              horizontal: 'center',
            },
            border: {
              top: { style: 'thin', color: { rgb: 'FF000000' } },
              bottom: { style: 'thin', color: { rgb: 'FF000000' } },
              left: { style: 'thin', color: { rgb: 'FF000000' } },
              right: { style: 'thin', color: { rgb: 'FF000000' } },
            },
          };
        }
      }
    }
  }
};
