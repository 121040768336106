import React from 'react';

import {
  Container,
  BillExplanation,
  OpenOrders,
  CloseOrders,
  PaymentTable,
} from './styles';

import formatPhone from '~/services/formatPhoneString';
import OrdersBillOperationItem from './OrderslBillOperationItem';
import { useBills } from '~/context/BillsContext';

export default function OrdersBillOperation({ session, isOpen }) {
  const { pendingBills, closedBills } = useBills();

  // const session_id = session?.table?.table_number ? session?.id : null;

  // useEffect(() => {
  //   if (isOpen) {
  //     getTableBills(session_id);
  //   }
  // }, [getTableBills, session_id, isOpen]);

  return (
    <Container>
      <BillExplanation>
        <h5>Clientes da Comanda</h5>
      </BillExplanation>
      <OpenOrders>
        <h6>
          <strong>Clientes na Mesa</strong>
        </h6>
        <PaymentTable borderless>
          <tbody>
            {pendingBills.length > 0 ? (
              pendingBills.map((bill) => {
                const userName = bill.buyer
                  ? formatPhone(bill.buyer.phone) || bill.buyer.name
                  : bill.waiter.name;
                return (
                  <OrdersBillOperationItem
                    bill={bill}
                    userName={userName}
                    session={session}
                  />
                );
              })
            ) : (
              <p>Nenhum cliente restante!</p>
            )}
          </tbody>
        </PaymentTable>
      </OpenOrders>
      <CloseOrders>
        <h6>
          <strong>Contas Individuais Fechadas</strong>
        </h6>

        <PaymentTable borderless>
          <tbody>
            {closedBills.length > 0 ? (
              closedBills.map((bill) => {
                const userName = bill.buyer
                  ? formatPhone(bill.buyer.phone)
                  : bill.waiter.name;
                return (
                  <tr key={bill.id}>
                    <td>{userName}</td>
                    <td>R${bill.total_service_price}</td>
                  </tr>
                );
              })
            ) : (
              <p>Nenhuma conta fechada</p>
            )}
          </tbody>
        </PaymentTable>
      </CloseOrders>
    </Container>
  );
}
