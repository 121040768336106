import React from 'react';
import OrderCard from '../OrderCard';
import Masonry from 'react-masonry-css';

function OrdersFinished({ baskets, kdsViewMode }) {
  const breakpointColumnsObj = {
    default: kdsViewMode,
    1100: 3,
    700: 2,
    500: 1
  };

  const priorities = {
    pending: 0,
    doing: 1,
    finished: 2,
  };

  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
    >
      {baskets?.length > 0 &&
        baskets?.filter((item) => item.status === 'finished')
          .map((item) => <OrderCard item={item} key={item.id} />)}
    </Masonry>
  );
}

export default OrdersFinished;
