import styled from 'styled-components';

export const Container = styled.div`
  @page {
    size: 75mm 200mm;
    margin: 0;
  }
  @media print {
    * {
      background: transparent;
      color: #000;
      text-shadow: none;
      filter: none;
      -ms-filter: none;
    }
    html,
    body {
      width: 75mm;
      height: 200mm;
    }
    h1 {
      margin-right: auto;
    }
    img {
      width: 70mm;
      height: auto;
    }
  }
`;

export const Title = styled.div`
  padding-top: 10px;
  width: 110mm;
  text-align: center;
  font-size: 25px;
  margin-right: auto;
`;

export const Filter = styled.div`
  padding-top: 5px;
  width: 110mm;
  text-align: center;
  font-size: 22px;
  margin-right: auto;
`;

export const Restaurant = styled.p`
  text-transform: capitalize;
  padding-top: 10px;
  width: 110mm;
  text-align: center;
  font-size: 30px;
  margin-right: auto;
  padding-bottom: 10px;
`;

export const Body = styled.div`
  padding-top: 0px;
  width: 110mm;
  clear: both;
  display: block;
  padding-bottom: 20px;
`;

export const BoxInformations = styled.div`
  padding-top: 0px;
  width: 110mm;
  clear: both;
  display: block;
  padding-bottom: 0px;
`;

export const Phone = styled.div`
  text-align: left;
  padding-left: 5px;
  font-size: ${(props) => (props.type === 'description' ? '16px' : '20px')};
  padding-top: 10px;
  float: left;
  display: block;
`;

export const OpenOrCloseBy = styled.div`
  width: 50%;
  text-align: left;
  padding-left: 5px;
  font-size: 14px;
  padding-top: 10px;
  float: left;
  display: block;
`;
export const TimeClosedOrOpen = styled.div`
  width: 50%;
  text-align: right;
  padding-right: 5px;
  font-size: 14px;
  padding-top: 10px;
  float: right;
`;

export const Price = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: ${(props) => (props.type === 'description' ? '16px' : '20px')};
  padding-top: 10px;
  float: right;
`;

export const Items = styled.div`
  width: 110mm;
  padding-top: 15px;
  padding-bottom: 20px;
`;

export const Item = styled.div`
  padding-top: 10px;
  padding-left: 10px;
`;

export const NamePrice = styled.div`
  width: 100%;
  display: flex;
`;

export const ItemName = styled.div`
  font-size: 25px;
  width: 65%;
  text-align: left;
`;

export const PriceItem = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 25px;
  width: 35%;
  float: right;
`;

export const ComplementsTitle = styled.div`
  font-size: 20px;
`;

export const OrderTime = styled.div`
  width: 100%;
  font-size: 20px;
  padding-left: 10px;
  padding-top: 20px;
  align-self: center;
`;

export const Complements = styled.div`
  font-size: 20px;
  padding-left: 15px;
  width: 75%;
`;

export const PriceComplement = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 20px;
  width: 25%;
  float: right;
`;

export const Obs = styled.div`
  padding-top: 5px;
  font-size: 20px;
`;

export const Footer = styled.div`
  margin-top: 16px;
  font-size: 14px;
  text-align: center;
  width: 110mm;
  padding-bottom: 30px;
  padding-top: 30px;

  strong {
    margin-top: 18px;
  }

  img {
    width: 100px;
  }
`;

export const AlreadyClosed = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 25px;
  text-align: center;
  width: 110mm;
`;

export const TotalDiv = styled.div`
  padding-top: 5px;
  margin-top: 20px;
  padding-bottom: 20px;
  border-top: solid black 1px;
  font-size: 25px;
  text-align: center;
  width: 110mm;
  border-bottom: solid black 1px;
`;

export const TotalRow = styled.div`
  margin-top: 5px;
  width: 110mm;
  clear: both;
  display: block;
`;

export const TotalName = styled.div`
  text-align: left;
  padding-left: 5px;
  font-size: 25px;
  padding-top: 5px;
  float: left;
`;

export const TotalPrice = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 25px;
  padding-top: 5px;
  float: right;
`;

export const TotalName10 = styled.div`
  text-align: left;
  padding-left: 5px;
  font-size: 20px;
  padding-top: 5px;
  float: left;
`;

export const TotalPrice10 = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 20px;
  padding-top: 5px;
  float: right;
`;
