import styled from 'styled-components';

export const Container = styled.div`
  width: 110px;
  height: 177px;
  background-color: #f8f9fa;
  border-radius: 7px;
  text-align: center;
  box-shadow: ${(props) =>
    props.isProductSelected ? '1px 1px 3px #fb4a20' : '1px 1px 3px #33333320'};
  /* transition: transform 0.7s; */
  cursor: pointer;
  position: relative;
  /* &:hover {
    transform: scale(1.1);
  } */
`;
export const ProductInfo = styled.div`
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 8px;
`;

export const ProductImage = styled.div`
  width: 112px;
  border-radius: 10px 10px 0 0;
  height: 110px;

  img {
    object-fit: cover;
    width: 112px;
    border-radius: 10px 10px 0 0;
    height: 110px;
  }
  /* background-image: url(${(props) => props.link});
  background-position: center; //centraliza imagem
  background-size: cover; //imagem cobre toda área do div */
`;

export const ProductPrice = styled.span`
  font-weight: 12px;
`;

export const ProductName = styled.span`
  font-weight: bold;
  font-weight: 14px;

  line-height: 15px;
`;
