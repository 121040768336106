import styled from 'styled-components';

export const Label = styled.div`
  position: absolute;
  margin: 0;
  bottom: 30px;
  left: 50%;
  font-size: 12px;
  transform: translateX(-50%);
  text-align: center;
  @media (max-width: 480px) {
    font-size: 10px;
  }
`;

export const Hour = styled.div`
  position: absolute;
  margin: 0;
  bottom: 30px;
  left: 50%;
  font-size: 11px;
  transform: translateX(-50%);
  text-align: center;
  @media (max-width: 480px) {
    font-size: 10px;
  }
`;
