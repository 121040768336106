import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { Form } from '@unform/web';

import * as Yup from 'yup';

import { toast } from 'react-hot-toast';
import { AiOutlineEye, AiOutlinePrinter } from 'react-icons/ai';
import {
  FaHandHoldingUsd,
  FaRedo,
  FaExclamationCircle,
  FaTimes,
} from 'react-icons/fa';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import ReactToPrint from 'react-to-print';
import FooterNF from '~/components/FooterNF';
import { useAuth } from '~/context/AuthContext';
import { Button as ButtonKit } from 'ui-kit-takeat';
import {
  StatusTag,
  ModalPaymentBody,
  DisplayNoneImprimir,
  ActionsInside,
  Print,
  ButtonStyled,
  InnerArea,
} from './styles';
import PaymentForm from '~/components/PaymentForm';
import formatCompleteDate from '~/services/formatCompleteDate';
import OrdersBill from '~/components/Tables/Bills/OrdersBill';
import PrintBillsTable from '~/components/Prints/PrintBillsTable';
import EyeBillsTable from '~/components/Tables/CloseOrder/Eye/EyeBillsTable';

import EyeOrdersBill from '~/components/Tables/EyeOrdersBill';

import api from '~/services/api';
import Input from '../Form/Input';
import getValidationErrors from '~/services/getValidationErrors';
import SelectTable from '../Form/SelectTable';
import { Button } from 'ui-kit-takeat';
import PrintNewOrderComponentDelivery from '../PrintNewOrderComponentDelivery';

export default function Bill({
  session,
  getSessions,
  // payments,
  // setAllPayments,
  // paymentMethods,
  setSessions,
  sessions,
  finalDate,
  inicialDate,
  handleUpdate,
}) {
  const { user } = useAuth();
  const [isModalErrorUserPdvOpened, setIsModalUserPdvOpened] = useState(false);
  const [rescues, setRescues] = useState(
    JSON.parse(localStorage.getItem('@gddashboard:rescues')) || []
  );
  const canCancel = ['table', 'command'].includes(session.table?.table_type);

  useEffect(() => {
    localStorage.setItem('@gddashboard:rescues', JSON.stringify(rescues));
  }, [rescues]);

  const toggleModalErrorUserPdv = useCallback(() => {
    setIsModalUserPdvOpened(!isModalErrorUserPdvOpened);
  }, [isModalErrorUserPdvOpened]);

  const [loading, setLoading] = useState(false);

  const [count, setCount] = useState('');
  const [bills, setBills] = useState();
  const [printBills, setPrintBills] = useState();
  const [productBills, setProductBills] = useState();
  const [sessionState, setSessionState] = useState();
  const [payments, setAllPayments] = useState(session?.payments);
  const [hasOpenedBox, setHasOpenedBox] = useState(false);
  const [modalOpeningBox, setModalOpeningBox] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const status = useMemo(() => {
    if (session.status === 'open') {
      return {
        statusText: 'Aberta',
        color: '#17a2b8',
      };
    }
    if (session.status === 'closing') {
      return {
        statusText: 'Fechando',
        color: '#17a2b8',
      };
    }
    if (session.status === 'transfer') {
      return {
        statusText: 'Transferida',
        color: '#17a2b8',
      };
    }
    if (session.status === 'finished') {
      return {
        statusText: 'Fechada',
        color: 'green',
      };
    }
    if (session.status === 'finished2') {
      return {
        statusText: 'Fechada',

        color: '#dc3545',
      };
    }
    if (session.status === 'completed') {
      return {
        statusText: 'Finalizada',

        color: '#dc3545',
      };
    }
    return {
      statusText: '',
      color: '#ddd',
    };
  }, [session.status]);

  const [modal, setModal] = useState(false);

  const [isModalTransferPasswordOpen, setIsModalTransferPasswordOpen] =
    useState(false);

  const [transferDataState, setTransferDataState] = useState();

  const [modalEye, setModalEye] = useState(false);

  const [modalEyeOpen, setModalEyeOpen] = useState(false);
  function toggleEyeOpen() {
    setModalEyeOpen(!modalEyeOpen);
  }

  const [modalReopen, setModalReopen] = useState(false);
  function toggleReopen() {
    setModalReopen(!modalReopen);
  }

  const [modalRefinish, setModalRefinish] = useState(false);
  function toggleRefinish() {
    setModalRefinish(!modalRefinish);
  }

  const [modalTransfer, setModalTransfer] = useState(false);
  async function toggleTransfer() {
    await getTables();
    setModalTransfer(!modalTransfer);
  }

  const [paid, setPaid] = useState(0);
  const [porcent, setPorcent] = useState(0);

  const [tables, setTables] = useState([]);

  const getTables = useCallback(async () => {
    const response = await api.get('/restaurants/tables');
    const tables = response.data.filter(
      (table) =>
        table.status === 'available' &&
        (table?.table_type === 'table' || table?.table_type === 'command')
    );
    const tablesOptions = [];

    tables.forEach((table) => {
      tablesOptions.push({
        value: table.id,
        label: table.table_number.toString(),
      });
    });

    setTables(tablesOptions);
  }, []);

  async function printClick(removeSelfSession) {
    try {
      await api.post('restaurants/printers/printer-queue', {
        table_session_id: session.id,
        close_after_print: true,
      });
      if (removeSelfSession) {
        const newSessions = sessions.filter((s) => s.id !== session.id);
        setSessions(newSessions);
      }
      toast.success('Comanda impressa e fechada.');
    } catch (error) {
      toast.error('Erro ao imprimir e fechar comanda.');
    }
  }

  // async function printClickWithoutClose() {
  //   try {
  //     await api.post('restaurants/printers/printer-queue', {
  //       table_session_id: session.id,
  //       close_after_print: false,
  //     });
  //     toast.success('Comanda impressa.');
  //   } catch (error) {
  //     toast.error('Erro ao imprimir comanda.');
  //   }
  // }

  const [paymentsBill, setPaymentsBill] = useState();

  async function handlePrintItem({ session_key }) {
    try {
      await api.put('restaurants/close-table-bill', {
        key: session_key,
      });
      getSessions();
      toast.success('Comanda fechada!');
    } catch (error) {
      toast.error('Não foi possível finalizar a comanda! tente novamente');
    }
  }

  const getPayments = useCallback(() => {
    let total = 0;

    paymentsBill &&
      paymentsBill.forEach((payment) => {
        total += parseFloat(payment.payment_value);
      });
    setPaid(total.toFixed(2).toString());

    const troco =
      total > session?.total_service_price
        ? total - session?.total_service_price
        : 0;

    const porcentPaid = session?.is_delivery
      ? (total / session?.total_delivery_price) * 100
      : ((total - troco) / session?.total_service_price) * 100;

    setPorcent(isNaN(porcentPaid) ? 0 : parseFloat(porcentPaid.toFixed(2)));
  }, [paymentsBill, session]);

  const toggle = useCallback(() => {
    setModal(!modal);
    getPayments();
  }, [setModal, getPayments, modal]);

  useEffect(() => {
    try {
      getPayments();
    } catch (error) {
      toast.error('Erro ao carregar informações');
    }
  }, [getPayments]);

  const toggleEye = useCallback(() => {
    setModalEye(!modalEye);
    getPayments();
  }, [getPayments, modalEye]);

  const componentRef = useRef(null);

  async function handleReopenTableBill() {
    try {
      await api.put('restaurants/reopen-table-bill', {
        key: session.key,
      });
      getSessions();
      toast.success('Comanda reaberta!');
    } catch (error) {
      if (session.table.status === 'ongoing') {
        toast.error('Essa mesa está aberta com outra sessão.');
      } else {
        toast.error('Erro ao reabrir mesa.');
      }
    }
  }

  async function handleRefinishSession(data) {
    setLoading(true);
    if (data.reopen_password === '' || !data.reopen_password) {
      toast.error('Digite a senha administrativa.');
      setLoading(false);
      return false;
    }

    try {
      await api.put('restaurants/finish-completed-session', {
        key: session.key,
        reopen_password: data.reopen_password,
      });
      getSessions();
      toggleRefinish();
      toast.success('Comanda reativada!');
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Erro ao reativar mesa.');
      }
    }
    setLoading(false);
  }

  function toggleModalTransferPassword() {
    setIsModalTransferPasswordOpen(!isModalTransferPasswordOpen);
  }

  function handleTransferTable(data) {
    if (user.has_order_transfer_password) {
      toggleModalTransferPassword();
      setTransferDataState(data);
    } else {
      handleTransferTableBill(data);
    }
  }

  async function handleTransferTableBill(data) {
    setIsSubmiting(true);
    try {
      await api.put('restaurants/transfer-table-bill', {
        key: session.key,
        table_id: data.new_table_id,
      });
      getSessions();
      toggleTransfer();
      toast.success('Mesa transferida com sucesso!');
      setIsSubmiting(false);
    } catch (error) {
      toast.error('Erro ao transferir mesa.');
      setIsSubmiting(true);
    }
  }

  async function handleSentMenew() {
    try {
      await api.post('restaurants/menew/send-bills', {
        session_key: session.key,
      });
      toast.success('Enviado para Menew!');
    } catch (error) {
      toast.error('Não foi possível enviar para Menew. Tente novamente');
    }
  }

  async function handleVerifyTransferPassword(data) {
    setIsSubmiting(true);
    const response = await api.post('/restaurants/verify-transfer', {
      order_transfer_password: data.order_transfer_password,
    });

    if (response.data.success) {
      handleTransferTableBill(transferDataState);
      toggleModalTransferPassword();
    } else {
      toast.error('Senha incorreta');
      setIsSubmiting(false);
    }
  }

  const getBills = useCallback(async () => {
    const res = await api.get(`restaurants/table-sessions-bills/${session.id}`);

    setBills(res.data.bills);
    setPrintBills(res.data.print_bills);
    setSessionState(res.data.session);
    setProductBills(res.data.products);
  }, [session.id]);

  // useEffect(() => {
  //   getBills();
  // }, [getBills]);

  useEffect(() => {
    const payments_bill = payments;

    setPaymentsBill(payments_bill);
  }, [session, payments]);

  const toggleModalHasOpenedBoxMessage = useCallback(() => {
    setHasOpenedBox(!hasOpenedBox);
  }, [hasOpenedBox]);

  function searchTableName(table) {
    if (table?.table_type === 'balcony') {
      return 'Balcão';
    }

    if (table?.table_type === 'pos') {
      return 'POS';
    }

    if (table?.table_type === 'command') {
      return `Comanda ${table?.table_number}`;
    }

    if (table?.table_number === -10) {
      return 'Mesa Copa';
    }

    if (table?.table_type === 'delivery') {
      if (session?.with_withdrawal && session?.scheduled_to) {
        return 'Retirada Agendada';
      }
      if (session?.with_withdrawal) {
        return 'Retirada';
      }

      if (!session?.with_withdrawal && session?.scheduled_to) {
        return 'Delivery Agendado';
      }

      return 'Delivery';
    }

    return `Mesa ${table?.table_number}`;
  }

  const openingBoxRef = useRef();

  const toggleModalOpeningBox = useCallback(() => {
    setModalOpeningBox(!modalOpeningBox);
  }, [modalOpeningBox]);

  const [modalType, setModalType] = useState('');

  const verifyOpeningBoxSessionOpen = useCallback(async () => {
    try {
      await api.get('/restaurants/cashier-opening-verify');

      toggle();
    } catch (err) {
      if (err.response.data.errorType === 'cashier_opening_not_found') {
        toggleModalHasOpenedBoxMessage();
        setModalType('modal');
      } else {
        toast.error('Erro ao carregar valores totais');
      }
    }
  }, [toggle, toggleModalHasOpenedBoxMessage]);

  const verifyOpeningBox = useCallback(async () => {
    try {
      await api.get('/restaurants/cashier-opening-verify');

      toggleEye();
    } catch (err) {
      if (err.response.data.errorType === 'cashier_opening_not_found') {
        toggleModalHasOpenedBoxMessage();
        setModalType('modalEye');
      } else {
        toast.error('Erro ao carregar valores totais');
      }
    }
  }, [toggleEye, toggleModalHasOpenedBoxMessage]);

  const [isModalOnlineDepositsOpened, setIsModalOnlineDepositsOpened] =
    useState(false);
  const [onlineDeposits, setOnlineDeposits] = useState([]);

  const toggleOnlineDepositisModal = useCallback(() => {
    setIsModalOnlineDepositsOpened(!isModalOnlineDepositsOpened);
  }, [isModalOnlineDepositsOpened]);

  const [orders, setOrders] = useState([]);
  const [buyer, setBuyer] = useState(null);

  const getOrders = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/delivery/session-orders/${session.id}`
      );

      setOrders(response.data.orders);
      setBuyer(response.data.buyer);
    } catch (error) {
      console.log(error);
    }
  }, [session]);

  const openingBox = useCallback(
    async (data) => {
      if (!user?.is_pdv) {
        try {
          if (openingBoxRef?.current) {
            openingBoxRef.current.setErrors({});
          }

          const schema = Yup.object().shape({
            opening_value: Yup.string().required('Valor obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          const response = await api.post('/restaurants/cashier-opening', {
            initial_value: data.opening_value,
          });

          const { online_deposits } = response.data;
          setOnlineDeposits(online_deposits);

          if (online_deposits.length > 0) {
            toggleOnlineDepositisModal();
          }

          toggleModalHasOpenedBoxMessage();

          if (modalType === 'modal') {
            toggle();
          }

          if (modalType === 'modalEye') {
            toggleEye();
          }

          toast.success('Caixa aberto com sucesso.');
        } catch (error) {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            if (openingBoxRef?.current) {
              openingBoxRef.current.setErrors(errors);
            }
          } else if (error.response.data.errorType === 'cashier_already_open') {
            toast.error('Caixa já aberto');
          } else {
            toast.error('Erro ao abrir o caixa');
          }
        }
      } else {
        toggleModalErrorUserPdv();
      }
    },
    [
      toggleModalHasOpenedBoxMessage,
      toggle,
      toggleEye,
      modalType,
      user,
      toggleModalErrorUserPdv,
      toggleOnlineDepositisModal,
    ]
  );

  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);

  const cancelForm = useRef(null);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [modalCancelIsOpened, setModalCancelIsOpened] = useState(false);

  const toggleModalCancel = useCallback(() => {
    setModalCancelIsOpened(!modalCancelIsOpened);
  }, [modalCancelIsOpened]);

  const handleCancelBill = useCallback(
    async (data) => {
      setLoadingCancel(true);
      try {
        if (cancelForm?.current) {
          cancelForm.current.setErrors({});
        }

        let handleData = null;

        if (user?.order_cancel_password !== '') {
          const schema = Yup.object().shape({
            order_cancel_password: Yup.string().required('senha obrigatória'),
            cancel_reason: Yup.string().required('justicativa obrigatória'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          handleData = {
            order_cancel_password: data.order_cancel_password,
            cancel_reason: data.cancel_reason,
          };
        }

        await api.delete(`restaurants/session/cancel/${session.id}`, {
          data: handleData,
        });

        toast.success('Comanda cancelada.');
        getSessions();
        toggleModalCancel();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (cancelForm?.current) {
            cancelForm.current.setErrors(errors);
          }
        } else if (
          error.response.data.errorType === 'incorrect_order_cancel_password'
        ) {
          toast.error('Senha incorreta');
        } else {
          toast.error('Erro ao cancelar pedido!');
        }
      }
      setLoadingCancel(false);
    },
    [toggleModalCancel, session]
  );

  //restaurantes do Five nao podem nem reabrir e nem reativar comandas
  const isFiveSport = [29640, 36801, 36834, 29641, 35646, 28518, 35679].includes(user.id);
  
  return (
    <>
      <Modal
        isOpen={isModalOnlineDepositsOpened}
        toggle={toggleOnlineDepositisModal}
      >
        <ModalBody>
          <strong>
            Os valores a seguir, que foram pagamentos online feitos enquanto seu
            caixa estava fechado, entrarão no caixa que você está abrindo:
          </strong>
          {onlineDeposits &&
            onlineDeposits.length > 0 &&
            onlineDeposits
              .filter((item) => item.total > 0)
              .map((deposit) => (
                <p>
                  {deposit.description} - R$ {deposit.total.toFixed(2)}
                </p>
              ))}
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-success"
            onClick={() => {
              toggleOnlineDepositisModal();
              setOnlineDeposits([]);
            }}
          >
            Ok, entendi
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={hasOpenedBox} toggle={toggleModalHasOpenedBoxMessage}>
        <Form onSubmit={openingBox} ref={openingBoxRef}>
          <ModalBody>
            <FaExclamationCircle color="darkOrange" size={32} />
            <h3 style={{ marginTop: '15px' }}>
              Você precisa abrir seu caixa para inserir pagamentos.
            </h3>

            <Input
              name="opening_value"
              label="Valor inicial no caixa (Dinheiro)"
              type="number"
              step="0.01"
              min="0"
            />
          </ModalBody>
          <ModalFooter>
            <ButtonStyled
              operationType="withdrawal"
              type="button"
              onClick={toggleModalHasOpenedBoxMessage}
            >
              Cancelar
            </ButtonStyled>
            <ButtonStyled operationType="deposit" type="submit">
              Abrir caixa
            </ButtonStyled>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={isModalErrorUserPdvOpened}
        toggle={toggleModalErrorUserPdv}
      >
        <ModalBody>
          Seu perfil de usuário não possui permissão de abrir caixa. Solicite ao
          seu administrador
        </ModalBody>
        <ModalFooter>
          <div style={{ width: '100%' }}>
            <ButtonStyled
              operationType="deposit"
              type="button"
              onClick={toggleModalErrorUserPdv}
              style={{ float: 'right' }}
            >
              Entendi, irei solicitar.
            </ButtonStyled>
          </div>
        </ModalFooter>
      </Modal>

      {/* modal de cancelamento de balcao */}
      <Modal isOpen={modalCancelIsOpened} toggle={toggleModalCancel}>
        <Form onSubmit={handleCancelBill} ref={cancelForm}>
          <ModalBody>
            <h4>
              <b>Tem certeza que deseja cancelar essa comanda inteira?</b>
            </h4>
            <p>
              Os <b>pagamentos</b> inseridos serão deletados{' '}
              {session.nfce_allowed && (
                <>
                  e a <b>nota fiscal</b> será cancelada se estiver dentro do
                  período elegível para cancelamento.
                </>
              )}
            </p>
            <p>
              <b>Essa comanda não poderá ser reativada</b>
            </p>
            {user?.order_cancel_password !== '' && (
              <div style={{ borderTop: '1px solid #D9D9D9', marginTop: 5 }}>
                <Input
                  name="order_cancel_password"
                  label="Senha de cancelamento"
                  type="password"
                />
                <Input name="cancel_reason" label="Justificativa" />
              </div>
            )}
          </ModalBody>
          <ModalFooter style={{ float: 'right' }}>
            <Button
              title="Cancelar comanda"
              type="submit"
              disabled={loadingCancel}
            />
          </ModalFooter>
        </Form>
      </Modal>

      <tr>
        <td>{searchTableName(session?.table)}</td>
        <td>{formatCompleteDate(session.start_time)}</td>
        <td>#{session.id}</td>

        <td>
          R$
          {session.is_delivery
            ? session.total_delivery_price
            : session.total_service_price}
        </td>
        <td>
          R${paid}
          <StatusTag color={porcent >= 100 ? '#3BD2C1' : '#FF2C3D'}>
            <span>
              {status.statusText} {porcent}%
            </span>
          </StatusTag>
        </td>

        <td style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {' '}
          {!session.is_delivery ? (
            <>
              {session.status === 'finished' ||
              session.status === 'completed' ? (
                <ActionsInside>
                  {session.status === 'completed' && user.has_menew && (
                    <ButtonKit
                      title="Enviar Menew"
                      buttonColor="#2EC9B7"
                      inverted
                      style={{ border: 'none', background: '#F4F4F4' }}
                      onClick={() => handleSentMenew(session.key)}
                    />
                  )}
                  {user.is_printed_on_web || user.print_bills_on_web ? (
                    <ReactToPrint
                      onBeforeGetContent={async () => {
                        if (session.is_delivery) {
                          await getOrders();
                        }
                        await getBills();
                      }}
                      trigger={() => (
                        <ButtonKit
                          title="Imprimir"
                          buttonColor="#2EC9B7"
                          inverted
                          icon={<AiOutlinePrinter />}
                          style={{ border: 'none', background: '#F4F4F4' }}
                        />
                      )}
                      content={() => componentRef.current}
                    />
                  ) : (
                    <ButtonKit
                      title="Imprimir"
                      buttonColor="#2EC9B7"
                      inverted
                      icon={<AiOutlinePrinter />}
                      style={{ border: 'none', background: '#F4F4F4' }}
                      onClick={() => printClick(false)}
                    />
                  )}

                  {session?.status !== 'completed' ? (
                    <>
                      <ButtonKit
                        title="Caixa"
                        buttonColor="#01AFFF"
                        inverted
                        icon={<FaHandHoldingUsd />}
                        style={{ border: 'none', background: '#F4F4F4' }}
                        onClick={verifyOpeningBox}
                      />

                      {!session?.is_prepaid &&
                        !(session?.table?.table_type === 'pos') && (
                          <ButtonKit
                            title="Reabrir"
                            buttonColor="#ec9006"
                            inverted
                            icon={<FaRedo />}
                            style={{ border: 'none', background: '#F4F4F4' }}
                            onClick={toggleReopen}
                          />
                        )}
                    </>
                  ) : (
                    <>
                      {(user.order_cancel_password !== '') && (
                        <ButtonKit
                          title="Reativar"
                          buttonColor="#ec9006"
                          inverted
                          icon={<FaRedo />}
                          style={{
                            order: 'none',
                            background: '#F4F4F4',
                            border: 'none',
                          }}
                          onClick={toggleRefinish}
                        />
                      )}
                    </>
                  )}

                  {canCancel && (
                    <ButtonKit
                      title="Cancelar"
                      buttonColor="#FF3A2C"
                      inverted
                      icon={<FaTimes />}
                      style={{ border: 'none', background: '#F4F4F4' }}
                      onClick={toggleModalCancel}
                    />
                  )}

                  {session.table?.table_type === 'balcony' && (
                    <ButtonKit
                      title="Cancelar"
                      buttonColor="#FF3A2C"
                      inverted
                      icon={<FaTimes />}
                      style={{ border: 'none', background: '#F4F4F4' }}
                      onClick={toggleModalCancel}
                    />
                  )}
                </ActionsInside>
              ) : (
                <ActionsInside>
                  {user.is_printed_on_web || user.print_bills_on_web ? (
                    <ReactToPrint
                      onBeforeGetContent={async () => {
                        if (session.is_delivery) {
                          await getOrders();
                        }
                        await getBills();
                      }}
                      trigger={() => (
                        <ButtonKit
                          title="Fechar"
                          buttonColor="#2EC9B7"
                          inverted
                          icon={<AiOutlinePrinter />}
                          style={{ border: 'none', background: '#F4F4F4' }}
                          onClick={toggleModalCancel}
                        />
                      )}
                      content={() => componentRef.current}
                      onAfterPrint={() =>
                        handlePrintItem({
                          session_key: session.key,
                        })
                      }
                    />
                  ) : (
                    <ButtonKit
                      title="Imprimir"
                      buttonColor="#2EC9B7"
                      inverted
                      icon={<AiOutlinePrinter />}
                      style={{ border: 'none', background: '#F4F4F4' }}
                      onClick={() => printClick(true)}
                    />
                  )}

                  {session.status !== 'completed' && (
                    <>
                      <ButtonKit
                        title="Caixa"
                        buttonColor="#01AFFF"
                        inverted
                        icon={<FaHandHoldingUsd />}
                        style={{ border: 'none', background: '#F4F4F4' }}
                        onClick={verifyOpeningBoxSessionOpen}
                      />
                      <ButtonKit
                        title="Ver"
                        buttonColor="#2EC9B7"
                        inverted
                        icon={<AiOutlineEye />}
                        style={{ border: 'none', background: '#F4F4F4' }}
                        onClick={toggleEyeOpen}
                      />
                    </>
                  )}
                  {session.status !== 'completed' && (
                    <ButtonKit
                      title="Mudar Mesa"
                      buttonColor="#ec9006"
                      inverted
                      onClick={toggleTransfer}
                    />
                  )}
                </ActionsInside>
              )}
            </>
          ) : (
            <>
              {session.status === 'finished' ||
              session.status === 'completed' ? (
                <ActionsInside>
                  {user.is_printed_on_web || user.print_bills_on_web ? (
                    <ReactToPrint
                      onBeforeGetContent={async () => {
                        if (session.is_delivery) {
                          await getOrders();
                        }
                        await getBills();
                      }}
                      trigger={() => (
                        <ButtonKit
                          title="Imprimir"
                          buttonColor="#2EC9B7"
                          inverted
                          icon={<AiOutlinePrinter />}
                          style={{ border: 'none', background: '#F4F4F4' }}
                          onClick={toggleTransfer}
                        />
                      )}
                      content={() => componentRef.current}
                    />
                  ) : (
                    <ButtonKit
                      title="Imprimir"
                      buttonColor="#2EC9B7"
                      inverted
                      icon={<AiOutlinePrinter />}
                      style={{ border: 'none', background: '#F4F4F4' }}
                      onClick={() => printClick(false)}
                    />
                  )}

                  {session.status !== 'completed' && (
                    <>
                      <ButtonKit
                        title="Caixa"
                        buttonColor="#01AFFF"
                        inverted
                        icon={<FaHandHoldingUsd />}
                        style={{ border: 'none', background: '#F4F4F4' }}
                        onClick={verifyOpeningBox}
                      />
                      {!session?.is_prepaid &&
                        !(session?.table?.table_type === 'pos') && (
                          <ButtonKit
                            title="Reabrir"
                            buttonColor="#FFA814"
                            inverted
                            icon={<FaHandHoldingUsd />}
                            style={{ border: 'none', background: '#F4F4F4' }}
                            onClick={toggleReopen}
                          />
                        )}
                    </>
                  )}
                </ActionsInside>
              ) : (
                <ActionsInside>
                  {session.status !== 'completed' && (
                    <>
                      <ButtonKit
                        title="Caixa"
                        buttonColor="#01AFFF"
                        inverted
                        icon={<FaHandHoldingUsd />}
                        style={{ border: 'none', background: '#F4F4F4' }}
                        onClick={verifyOpeningBoxSessionOpen}
                      />

                      <ButtonKit
                        title="Ver"
                        buttonColor="#2EC9B7"
                        inverted
                        icon={<FaHandHoldingUsd />}
                        style={{ border: 'none', background: '#F4F4F4' }}
                        onClick={toggleEyeOpen}
                      />
                    </>
                  )}
                </ActionsInside>
              )}
            </>
          )}
        </td>
        <td>
          <Modal
            isOpen={modal}
            toggle={toggle}
            size="lg"
            onClosed={handleUpdate}
          >
            <InnerArea browserZoomLevel={browserZoomLevel}>
              <ModalPaymentBody>
                <PaymentForm
                  session={session}
                  payments_bill={paymentsBill}
                  setPaymentsBill={setPaymentsBill}
                  all_payments={payments}
                  getPaymentsBill={getPayments}
                  setAllPayments={setAllPayments}
                  // paymentMethods={paymentMethods}
                  setCount={setCount}
                  getSessions={getSessions}
                  setRescues={setRescues}
                  getBills={getBills}
                />
                <OrdersBill session={session} bills={bills} />
              </ModalPaymentBody>
            </InnerArea>
          </Modal>

          <Modal isOpen={modalEyeOpen} toggle={toggleEyeOpen}>
            <ModalBody>
              <EyeOrdersBill
                session={session}
                setSessions={setSessions}
                inicialDate={inicialDate}
                finalDate={finalDate}
                toggleEyeOpen={toggleEyeOpen}
                modalEyeOpen={modalEyeOpen}
                getSessions={getSessions}
              />
            </ModalBody>
            <ModalFooter>
              {user.is_printed_on_web || user.print_bills_on_web ? (
                <ReactToPrint
                  onBeforeGetContent={async () => {
                    if (session.is_delivery) {
                      await getOrders();
                    }
                    await getBills();
                  }}
                  trigger={() => (
                    <Print>
                      <AiOutlinePrinter color="black" size={20} />
                    </Print>
                  )}
                  content={() => componentRef.current}
                />
              ) : (
                <Print onClick={() => printClick(false)}>
                  <AiOutlinePrinter color="black" size={20} />
                </Print>
              )}
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={isModalTransferPasswordOpen}
            toggle={toggleModalTransferPassword}
          >
            <Form onSubmit={handleVerifyTransferPassword}>
              <ModalBody>
                <Input
                  name="order_transfer_password"
                  label="Senha de Transferência"
                  type="password"
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  onClick={toggleModalTransferPassword}
                  title="Cancelar"
                />
                <Button
                  title="Transferir"
                  type="submit"
                  onClick={() => {}}
                  disabled={isSubmiting}
                  buttonColor="#2ec9b7"
                />
              </ModalFooter>
            </Form>
          </Modal>

          <Modal isOpen={modalReopen} toggle={toggleReopen}>
            <ModalBody>
              {session.table?.status === 'available' ||
              session.table?.table_type === 'balcony' ? (
                <p>Tem certeza que deseja reabrir essa comanda?</p>
              ) : (
                <p style={{ color: 'red' }}>
                  Essa mesa está com uma sessão ativa no momento.
                </p>
              )}
            </ModalBody>
            <ModalFooter>
              <Button onClick={toggleReopen} title="Cancelar" />
              {(session.table?.status === 'available' ||
                session.table?.table_type === 'balcony') && (
                <Button
                  onClick={handleReopenTableBill}
                  title="Sim, reabrir"
                  buttonColor="#2EC9B7"
                />
              )}
            </ModalFooter>
          </Modal>

          <Modal isOpen={modalRefinish} toggle={toggleRefinish}>
            <Form onSubmit={handleRefinishSession}>
              <ModalBody>
                <p>
                  <strong>Tem certeza que deseja reativar essa comanda?</strong>
                </p>
                <p>Ela voltará para as Comandas Fechadas.</p>
                {user.has_nfce && (
                  <p>
                    Se a nota fiscal da comanda foi emitida nos últimos 30
                    minutos, ela será cancelada na SEFAZ. Sendo necessário
                    emitir novamente.
                  </p>
                )}
                <Input
                  name="reopen_password"
                  label="Senha administrativa"
                  type="password"
                />
              </ModalBody>
              <ModalFooter>
                <Button onClick={toggleRefinish} title="Cancelar" />
                <Button
                  type="submit"
                  onClick={() => {}}
                  buttonColor="#2EC9B7"
                  disabled={loading}
                  isLoading={loading}
                  title="Sim, reativar"
                />
              </ModalFooter>
            </Form>
          </Modal>

          <Modal isOpen={modalTransfer} toggle={toggleTransfer}>
            <Form onSubmit={handleTransferTable}>
              <ModalBody style={{ padding: 20 }}>
                <h3 style={{ fontSize: 20, fontWeight: 700, marginBottom: 10 }}>
                  Transferência de Mesa
                </h3>
                {tables.length !== 0 ? (
                  <div>
                    <h6 style={{ fontSize: 16 }}>
                      Mesa atual:{' '}
                      <strong>{session?.table?.table_number}</strong>
                    </h6>
                    <SelectTable
                      name="new_table_id"
                      label="Nova mesa:"
                      options={tables.filter(
                        (a) => a.value !== session?.table?.id
                      )}
                    />
                  </div>
                ) : (
                  <p style={{ color: 'red' }}>
                    Não há nenhuma mesa disponível no momento.
                  </p>
                )}
              </ModalBody>
              <ModalFooter style={{ padding: '0 20px 20px' }}>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    toggleTransfer();
                  }}
                  title="Cancelar"
                />
                {tables.length !== 0 && (
                  <Button
                    type="submit"
                    title="Transferir"
                    onClick={() => {}}
                    buttonColor="#2EC9B7"
                    disabled={isSubmiting}
                  />
                )}
              </ModalFooter>
            </Form>
          </Modal>
          {!session.is_delivery ? (
            <DisplayNoneImprimir>
              <PrintBillsTable
                item={session}
                restaurant={user}
                ref={componentRef}
                paymentData={payments}
                printBills={printBills}
                sessionState={sessionState}
                count={count}
                productBills={productBills}
              />
            </DisplayNoneImprimir>
          ) : (
            <DisplayNoneImprimir>
              <PrintNewOrderComponentDelivery
                restaurant={user}
                item={session}
                ref={componentRef}
                orders={orders}
                bills={bills}
                paid={paid}
                productBills={productBills}
                buyer={buyer}
              />
            </DisplayNoneImprimir>
          )}
          <Modal
            isOpen={modalEye}
            toggle={toggleEye}
            size="lg"
            onClosed={handleUpdate}
          >
            <InnerArea browserZoomLevel={browserZoomLevel}>
              <ModalPaymentBody>
                {/* <EyePaymentBills session={session} restaurant={user} /> */}
                <PaymentForm
                  session={session}
                  payments_bill={paymentsBill}
                  setPaymentsBill={setPaymentsBill}
                  all_payments={payments}
                  getPaymentsBill={getPayments}
                  setAllPayments={setAllPayments}
                  // paymentMethods={paymentMethods}
                  setCount={setCount}
                  getSessions={getSessions}
                  setRescues={setRescues}
                  getBills={getBills}
                />
                <EyeBillsTable
                  item={session}
                  payments_bill={paymentsBill}
                  modalEye={modalEye}
                />
                {/* <OrdersBill session={session} /> */}
              </ModalPaymentBody>
              {session.status === 'finished' && (
                <FooterNF
                  session={session}
                  toggleEye={toggleEye}
                  getSessions={getSessions}
                  payments={payments}
                  has_nfce={user.has_nfce}
                  setCount={setCount}
                  rescues={rescues}
                />
              )}
            </InnerArea>
          </Modal>
        </td>
      </tr>
    </>
  );
}