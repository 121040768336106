import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: fit-content;
  gap: 20px;
  margin: 8px auto;
  width: 98%;
  padding:8px;
  svg {
    width: 30px;
    height: 30px;
  }
`;



export const LabelResearch = styled.div`
 display: flex;
 justify-content: space-between;
 width: 100%;
 line-height:24px;
 margin-top: 8px;
padding: 8px;

`

export const Button = styled.button`
  width: 40px;
  height: 40px;
  padding: 8px 15px;  // Unificação do padding
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent; 
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border: 0.3px #666666 solid;
  border-radius: 8px;
  padding:8px;
  &:focus {
    outline: none;
  }

  // &:hover {
  //   color: black;
  //   background-color: #F3F3F3;
  //   transform: scale(1.2);
  // }

  &:active {
    background-color: ${(props) => props.bgColor || 'transparent'};
  }
`;


export const SatisfactionOption = styled.span`
  padding: 1.2rem;
  color: ${(props) => props.color};
  
`;