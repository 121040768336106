import React from 'react';


import {
  Container,
  Restaurant,
  Footer,
  TotalRow,
  MotoboysTable,
} from './styles';

import formatCompleteDate from '~/services/formatCompleteDate';
import { format } from 'date-fns';


const PrintTermAccountDetails = React.forwardRef(
  (
    {
      item,
      restaurant,

    },
    ref
  ) => {



    return (
      <Container ref={ref}>
        <React.Fragment key={item?.id}>
          <Restaurant style={{ borderBottom: "none" }}>
            <strong>{restaurant?.fantasy_name}</strong>
          </Restaurant>
          <Restaurant>
            <strong>COMPROVANTE</strong>
          </Restaurant>

          <TotalRow>

            <span>{format(new Date(), 'dd/MM/yy')}</span>
          </TotalRow>
          <TotalRow>
            <span>Cliente:</span>
            <span>{item?.name}</span>
          </TotalRow>
          <TotalRow>
            <span>Telefone:</span>
            <span>{item?.phone}</span>
          </TotalRow>

          <MotoboysTable>

            <>
              <thead>
                <tr>
                  <th>VISITAS</th>
                  <th>VALOR</th>

                </tr>
              </thead>
              <tbody>
                {item?.credit_register_items
                  ?.sort((a, b) => b.created_at?.localeCompare(a.created_at))
                  .map((session) => {

                    return (
                      <tr key={session.id}>

                        <td>{formatCompleteDate(session.createdAt)}</td>

                        <td>R${session?.value}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </>


          </MotoboysTable>


          <Footer>
            <span>Desenvolvido por Takeat!</span>
          </Footer>
        </React.Fragment>

      </Container>
    );
  }
);

export default PrintTermAccountDetails;
