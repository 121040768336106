import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import DatePicker from 'react-datepicker';

export const DataPickerArea = styled.div`
  margin-top: 10px;

  span {
    font-weight: bold;
  }
`;

export const Container = styled.div`
  padding: 20px;
  max-height: calc(100vh - 48px);
  overflow-y: auto;
`;

export const CategoriesTable = styled(Table)`
  width: 100%;
  margin: 15px auto 0;
  min-width: 400px;
  align-items: center;
  border-collapse: separate;
  border-spacing: 0 20px;
  background: #ffffff;
  box-shadow: 1px 0px 5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 0 30px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    border-top: none;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #dee2e6;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const DatePickerStyled = styled(DatePicker)`
  height: 44px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  margin-top: 8px;

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }
`;

export const NavDiv = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  clear: both;
`;

export const NavDivTeste = styled.div`
  float: right;
  margin: auto;
`;

export const NavLinkSpan = styled.span`
  margin-right: 5px;
`;

export const InputsTable = styled.table`
  width: 100%;
  min-width: 200px;
  margin: 15px auto 15px 0;
  border-collapse: initial;
  border-spacing: 0 20px;
  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  padding: 0 30px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid transparent;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ErrorContainer = styled.div`
  width: 60%;
  min-width: 600px;
  margin: 10px auto 30px auto;

  background: orange;
  color: #fff;

  border-radius: 8px;
  padding: 30px;

  p {
    text-align: center;
    font-size: 22px;
  }
`;

export const SearchDiv = styled.div`
  width: 90%;
  min-width: 200px;
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 0 20px;
  background: transparent;
  padding: 0 10px;
  margin-bottom: 10px;
`;

export const ComplementsTable = styled(Table)`
  width: 90%;
  min-width: 200px;
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 0 20px;
  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  padding: 0 30px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #eee;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const DeleteFilterButton = styled.button`
  margin-right: 14px;
  width: 78px;
  border: none;
  background: #17a2b8;
  color: #fff;
  padding: 8px 10px;
  border-radius: 4px;
`;
