import styled from 'styled-components';

export const Eye = styled.button`
  height: 50px;
  width: 50px;

  border: none;
  background-color: transparent;
  background: white;
`;

export const ContentBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  &:not(:last-child) {
    border-bottom: solid #dee2e6 1px;
  }
`;

export const TableNumber = styled.span`
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;

  @media (max-width: 410px) {
    margin-left: 130px;
    font-size: 12px;
  }
`;
