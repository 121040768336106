import styled, { css } from 'styled-components';
import { Form } from '@unform/web';
import { FiEye } from 'react-icons/fi';
import fundo from '../../assets/img/fundo.png';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${fundo});
  background-size: cover;
  width: 100vw;
  height: 100vh;
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-size: cover;
  gap: 50px;

  @media (min-width: 1200px) {
    gap: 100px;
  }
`;

export const DeviceImg = styled.img`
  width: 504px;

  ${(props) =>
    props.browserZoomLevel > 100 &&
    css`
      width: 504px;
      height: 542px;
    `}

  @media(max-width: 1000px) {
    display: none;
  }
`;

export const ButtonWaiter = styled.a`
  background: #fff;
  color: #ff1814;
  padding: 10px 15px;
  border-radius: 7px;
  border: none;

  margin-top: 17px;

  cursor: pointer;


  svg {
    margin-right: 5px;
  }

    @media(max-height: 721px){
   margin-top: 10px;
  }
`;

export const OutlineButton = styled.button`
  background: transparent;
  color: #fff;
  padding: 10px 15px;
  border-radius: 7px;
  border: 1px solid #fff;
font-weight: bold;
  margin-top: 17px;

  cursor: pointer;


  svg {
    margin-right: 5px;
  }
`;

export const ClientArea = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
margin-top: 40px;

@media(max-height: 721px){
   
    margin-top: 19px;
  }
  >p{
    font-weight: bold;
  }

  p{
    font-size: 16px;
    color: #fff;

    @media(max-width: 1025px){
   
      font-size: 12px;
    }
  }
`

export const LeftSide = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  @media(max-width: 430px){
   
   display: none;
 }
`;

export const LogoImg = styled.img`
  position: absolute;
  left: 12%;
  top: 14%;

`

export const RightSide = styled.div`
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;


@media(max-height: 721px){
  justify-content: space-around;
  }
`;

export const Card = styled.div`
  width: 538px;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
  padding: 40px;

  background-color: #fff;
  text-align: center;
  border-radius: 10px;


  @media(max-width: 1445px){
   
   width: 444px;
 }

  @media(max-height: 721px){
    height: 332px;
    width: 538px;
  }

  h1 {
    font-size: 22px;

    text-align: center;
    font-weight: bold;
  }
`;

export const InputArea = styled.span`
  position: relative;
`;

export const FiEyeStyled = styled(FiEye)`
  position: absolute;
  right: 10px;
  bottom: 35px;
  cursor: pointer;
`;

export const StyledForm = styled(Form).attrs((props) => ({
  primary: props.theme.colors.primary,
  secondary: props.theme.colors.secondary,
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  position: relative;

  input {
    /* background: rgba(0, 0, 0, 0.03); */

    border-radius: 4px;
    border: 1px solid #ddd;
    height: 44px;
    padding: 20px;
    color: ${(props) => props.theme.colors.text};
    margin: 0 0 10px;

   
  }

  button {
    margin-top: 20px;
    width: 100%;
    height: 44px;
    border: none;
    border-radius: 8px;
    background: #ff1814;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
  }

  a {
    cursor: pointer;
    margin-top: 20px;
    text-align: left;
    width: 100%;
  }
`;
