import styled from 'styled-components';

export const Container = styled.div`
    @page {
    size: 75mm 200mm;
    margin: 0;
  }
  @media print {
    * {
      background: transparent;
      //color: #000;
      text-shadow: none;
      filter: none;
      -ms-filter: none;
    }
    html,
    body {
      width: 75mm;
      height: 200mm;
    }
    h1 {
      margin-right: auto;
    }
    img {
      width: 70mm;
      height: auto;
    }
  }
`;

export const Body = styled.div`
  width: 110mm;
  clear: both;
  display: block;


  .borderArea{
    margin-bottom:16px;
    border-bottom: 0.2px solid #000000;
    padding: 0px  0px 16px 0px;
  }
`;

export const Title = styled.span`
    text-transform: capitalize;
  padding-top: 10px;
  width: 110mm;
  text-align: center;
  font-size: 30px;
  margin-right: auto;
  padding-bottom: 15px;
  display:flex;
  justify-content:center;
`;

export const OrderInfo = styled.div`
  display:flex;
  justify-content:space-between;
  padding:8px 24px;
  font-family: Arial;
  font-size: 14px;
  line-height: 16.1px;
  text-align: center;

  .header{
    font-weight:700;
    font-size:18px;
    line-height: 18.4px;
    text-align: center;

  }

  .info{
    font-weight:700;
    font-size:16px
  }

  span{
    font-style: normal;
    line-height: normal;
    font-weight:100;
  }

`;

export const TableArea = styled.table`
  width: calc(110mm - 48px);
  margin:24px;
  font: Arial;
  th {
    border: 1px solid #000000;
  }

  td {
    border: 1px solid #000000;
  }

   th:first-child, td:first-child {
    text-align: left;
  }

  th:nth-child(2), th:nth-child(3),
  td:nth-child(2), td:nth-child(3) {
    text-align: center;
  }
  
  th:nth-child(1),td:nth-child(1){
    padding-left:8px;
  }
`

