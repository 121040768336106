import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
   * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: 0;
  }
  table {
  tbody{
    tr{
      /* td{
        color: #333;
      } */
    }
  }
  
}
  html, body, #root {
    height: 100%;
  }

  *{
    &::-webkit-scrollbar {
    background: transparent;
    width: 10px;
    height: 8px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background: lightgray !important;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 5px;
  }
  }
  body {
    -webkit-font-smoothing: antialiased;
  }
  /* body, input, button {
    font: 16px 'Poppins','Roboto', sans-serif;
  } */
  body::-webkit-scrollbar-track {
    background: #1e1e24;
  }
  body::-webkit-scrollbar-thumb {
    background: #6649b8;
  }



  ul {
    list-style: none;
    color: #333;
  }
  button {
    cursor: pointer;
    color: #fff;
    span{
      color: #fff;
    }
    p{
      color: #fff;
    }
  }
  body {
    background: ${(props) => props.theme.colors.background};
   

    table {
  tbody{
    
  }
}
    span {
      color: #333;
    }

    p {
      color: #333;
    }

    h2 {
      color: #333;
    }

    strong {
    color: #333;
  }

    td, th {
    /* color: #333; */
  }
  button {
    span{ color: #fff;}
  }
}
.form-control {
  font-family: system-ui, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -15px; /* gutter size offset */
  width: 100%;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */

  margin-bottom: 30px;
  min-height: 380px;
}

input[type="checkbox"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: #2ec9b7;
  width: 17px;
  height: 17px;
  border: 0.15em solid #2ec9b7;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #2ec9b7;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
  background-color: #2ec9b7;

  
}

.form-control + .form-control {
  margin-top: 1em;
}

input[type="checkbox"]:disabled {


  color: #3c3c3c;
  cursor: not-allowed;
}


input[type="radio"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: #2ec9b7;
  width: 17px;
  height: 17px;
  border: 0.15em solid #2ec9b7;
  border-radius:50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #2ec9b7;
  transform-origin: bottom left;
  clip-path: circle(50%);
}

input[type="radio"]:checked::before {
  transform: scale(1);
  background-color: #2ec9b7;

  
}

.form-control + .form-control {
  margin-top: 1em;
}

input[type="radio"]:disabled {


  color: #3c3c3c;
  cursor: not-allowed;
}

.container {
  width: 100%;
  padding-right: var(--bs-gutter-x, 1.5rem);
  padding-left: var(--bs-gutter-x, 1.5rem);
  margin-right: auto;
  margin-left: auto;
  height: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}


`;
