import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 15px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 20px;
  background: #fff;
  border-radius: 8px;
  height: 100%;
  width: 350px;
  box-shadow: 0 1px 4px 0 rgba(192, 208, 230, 0.8);
  cursor: grab;

  svg {
    cursor: pointer;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto 10px;
    text-align: initial;

    span {
      font-size: 14px;
      color: ${(props) => props.theme.colors.gray};
      margin-bottom: 20px;
    }
    p {
      font-weight: bold;
      font-size: 18px;
    }
  }

  ${(props) =>
    props.isDragging &&
    css`
      border: 2px dashed rgba(0, 0, 0, 0.2);
      /* padding-top: 31px; */
      border-radius: 0;
      background: transparent;
      box-shadow: none;
      cursor: grabbing;

      p,
      span,
      img,
      header {
        opacity: 0;
      }
    `}
`;
