import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  margin: 10px;
`;

export const StyledLink = styled(Link)`
  margin: auto;
  &:hover {
    text-decoration: none;
  }
`;

export const PageButton = styled.div.attrs((props) => ({
  color: props.color,
}))`
  margin: 20px auto;
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  background: #f9f9f1;
  border-radius: 8px;
  color: ${(props) => props.color};
  border: solid 2px ${(props) => props.color};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.5));
  text-align: center;
  transition-duration: 0.2s;

  &:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
`;

export const RestaurantInfo = styled.div`
  display: flex;
  padding: 20px 0;
  margin: 10px;

  span {
    margin-left: 10px;
    font-size: 16px;
  }
`;

export const Header = styled.div`
  margin: auto;
  // display: flex;
  // justify-content: space-between;
  border: 1px solid black;
`;

export const Card = styled.div`
  margin: 20px auto;
  width: 90%;
  display: flex;
  padding: 30px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
`;

export const Bloco = styled.div`
  margin: 20px auto;
  width: 32%;
  height: 500px;
  padding: 30px;
  display: flex;
  float: left;
  margin-right: 10px;
  background: red;
  border-radius: 8px;
`;

export const LugarPedidos = styled.div`
  display: flex;
  background-color: white;
  border: 1px solid #dee2e6;
  border-top: none;
  border-bottom-radius: 10px;
`;

export const TablesPlace = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 300px;
  background-color: white;
  border-radius: 8px;
  overflow-x: auto;
`;
