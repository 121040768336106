import styled from "styled-components";

export const SidebarContainer = styled.aside`
  height: 100vh;
  width: fit-content;
  background: #fff;
  display: flex;
  flex-direction: column;

  grid-area: aside;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const SidebarTopContainer = styled.div`

`;

export const FillBar = styled.div`
  flex-grow: 1;
  background-color: ${({ color }) => color ? color : "#a82743"};
  width: 50px;
  min-height: 50px;
`;

export const FillBarTop = styled.div`
  background-color: ${({ color }) => color ? color : "#a82743"};
  width: 50px;
  height: 50px;
`;
