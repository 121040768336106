import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Table } from 'reactstrap';

export const Container = styled.div``;

export const LoadingContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  div {
    margin-bottom: 10px;
  }
`;

export const SelectStyled = styled(Select)`
  width: 300px;
  z-index: 10;

  [class*='-menu'] {
    /* z-index: 999999999; */
  }
`;

export const InputSearchArea = styled.div`
  margin-bottom: 10px;
  height: 40px;
  display: flex;
  position: relative;
  input {
    border: 1px solid #33333320;
    border-radius: 4px;
  }

  button {
    margin-left: 8px;
    border: none;
    background-color: transparent;
    position: absolute;
    right: 10px;
    top: 7px;
  }

  span {
    margin-left: 8px;
    border: none;
    background-color: transparent;
    position: absolute;
    right: 280px;
    top: 7px;
  }
`;

export const Header = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;
`;

export const CategoriesTable = styled(Table)`
  width: 70%;
  min-width: 400px;
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 0 20px;
  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  padding: 0 30px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #eee;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const ButtonIfood = styled.button`
  border: none;
  background-color: transparent;
  color: #ff2c3a;
  text-decoration: underline;
  font-size: 18;
  font-weight: bold;
  margin-top: 12px;
  float: right;

  svg {
    margin-right: 8px;
  }
`;

export const Actions = styled.div``;

export const SetSliceSizeButton = styled.button`
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: #ff2c3a;
  font-size: 18px;
  margin-top: 16px;
  margin-left: 40px;
  float: left;
  font-weight: bold;
`;

export const CategoryItemHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding: 30px;
`;

export const CategoryItemBody = styled.div`
  width: 100%;
  display: flex;
  padding: 0 30px;
  justify-content: space-between;
`;

export const ButtonCollapse = styled.div`
  margin-left: 50px;
  border: none;
  background: none;
  margin-bottom: 16px;
  font-weight: bold;

  cursor: pointer;
`;

export const CategoryName = styled.div`
  font-weight: bold;
  font-size: 25px;
  margin-left: 12px;
`;
export const CategoryItemHeaderTitle = styled.p`
  font-weight: bold;
`;

export const ButtonAssociate = styled.button`
  border: none;
  text-decoration: underline;
  background-color: transparent;
  color: #ff2c3a;
  font-weight: bold;
  font-size: 16px;
  margin-top: 12px;
`;

export const ContainerComplementsCategories = styled.div`
  margin: auto;
  width: 95%;
  background-color: #fff;
  border-radius: 7px;

  margin-top: 30px;
  background: ${(props) => props.theme.colors.white};
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #f4f4f4;
`;

export const CategoryHeader = styled.div`
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
  padding-left: 30px;

  background-color: #f4f4f4;

  div {
    display: flex;
    align-items: flex-end;

    svg {
      cursor: pointer;
    }

    p {
      margin-left: 16px;
    }
  }

  p {
    font-size: 20px;
    margin-top: 14px;
  }
`;
