import React, { useState } from 'react';
import { Modal } from 'reactstrap';
import { v4 } from 'uuid';
import ReportWaiters from '~/pages/Reports/ReportsPrincipal/ReportWaiters';
import {
  Container,
  Title,
  ContainertInfo,
  Sum,
  Info,
  ButtonOpenWaiterModal,
} from './styles';

function ClientsCard({
  headerText,
  data,
  type,
  initialDate,
  finalDate,
  totalBox,
  totalWaiters,
}) {
  const [isModalWaitersOpened, setIsModalWaitersOpened] = useState(false);

  function toggleModalWaiters() {
    setIsModalWaitersOpened(!isModalWaitersOpened);
  }

  return (
    <Container>
      <Title>{headerText}</Title>
      {data &&
        data.map((cli) => (
          <ContainertInfo key={v4()}>
            {type === 'client' && (
              <>
                <Info>{cli.buyer?.phone}</Info>
                <Sum>R$ {cli?.sum}</Sum>
              </>
            )}
            {type === 'waiter' && (
              <>
                <Info>{cli.waiter?.name || 'Outros'}</Info>
                <Sum>R$ {cli?.sum}</Sum>
              </>
            )}
          </ContainertInfo>
        ))}
      {type === 'waiter' && (
        <ButtonOpenWaiterModal onClick={toggleModalWaiters}>
          + Detalhes
        </ButtonOpenWaiterModal>
      )}
      <Modal
        isOpen={isModalWaitersOpened}
        toggle={toggleModalWaiters}
        size="lg"
      >
        <ReportWaiters
          initialDate={initialDate}
          finalDateComponent={finalDate}
          totalBox={totalBox}
          totalWaiters={totalWaiters}
        />
      </Modal>
    </Container>
  );
}

export default ClientsCard;
