import styled, { css } from 'styled-components';

export const Container = styled.div`
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  position: relative;
`;

export const SearchBar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;

  position: relative;
  margin-top: 10px;
  width: 100%;

  svg {
    color: #d3d3d3;
  }

  input {
    width: 100%;
    font-size: 12px;
    background: #fff;
    color: #4d4d4c;
    border: 2px solid #d3d3d3;
    margin-top: -10px;
    border-radius: 7px;
    padding: 8px;
    padding-left: 28px;
    ::placeholder {
      color: #4d4d4c;
    }
  }
`;

export const InputProductAmount = styled.input`
  border: 1px solid #e3e3e3;
  color: #ff2c3a;
  border-radius: 5px;
  width: 62px;
  height: 28px;
  font-size: 16px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
`;

export const InputComplementAmount = styled.input`
  border: 1px solid #e3e3e3;
  color: #ff2c3a;
  border-radius: 5px;
  width: 62px;
  height: 28px;
  font-size: 16px;
  text-align: center;
  margin-right: 10px;
  margin-left: 10px;
`;

export const ButtonDeleteInput = styled.button`
  background: transparent;
  border: none;

  border-radius: 8px;
  position: absolute;
  right: 20px;
  bottom: 12px;
`;

export const ButtonSearchInput = styled.div`
  background: transparent;
  border: none;

  border-radius: 8px;
  position: absolute;
  left: 8px;
  bottom: 14px;
`;

export const Content = styled.div`
  max-height: 80vh;
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 65px;
  a {
    text-decoration: none;
    color: #fb4a20;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 700;
  }

  @media (max-width: 1281px) {
    padding-bottom: 95px;
  }

  @media (min-width: 1282px) and (max-width: 1441px) {
    padding-bottom: 95px;
    margin-top: 30px;
  }
`;

export const Footer = styled.footer`
  height: 48px;

  position: absolute;
  bottom: 0;
  width: 99%;

  button {
    width: 100%;

    height: 48px;
    background: #ff2c3a;
    color: #fff;
    border-radius: 7px;
    border: none;

    span {
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      margin-left: 8px;
      margin-top: 3px;
    }
  }
`;

export const HeaderAction = styled.div`
  width: 100%;

  padding: 16px;
  padding-bottom: 0;
  background: #fff;

  border-radius: 7px;

  button {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }
`;

export const ButtonClose = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  border: none;
  background: #fff;
  border-radius: 50%;
`;

export const ImageProduct = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    width: 115px;
    height: 115px;
    object-fit: cover;
    /* width: auto;
    height: auto; */
  }
`;

export const DescriptionProduct = styled.div`
  h1 {
    font-size: 20px;
    font-weight: 700;
  }

  p {
    font-size: 14px;
  }
`;

export const AddItemsProduct = styled.div`
  padding-right: 10px;
  padding-bottom: 20px;
  div {
    li {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 10px;

      & + li {
        border-top: 1px solid #33333320;
      }
    }
  }
`;

export const EditAction = styled.div`
  display: flex;

  align-items: center;
`;

export const EditActionButtons = styled.div`
  width: 150px;

  justify-content: center;
  align-items: center !important;
  display: flex;
  color: #ff281e;

  font-family: Arial, Helvetica, sans-serif;

  p {
    margin-top: 16px;
  }

  button {
    border: 0;
    background: transparent;
  }
`;

export const InputProductWeight = styled.input`
  width: 150px;
  height: 40px;
  border-radius: 5px;
  padding: 7px 14px;
  border: 1px solid #33333380;
  margin-left: 10px;
  font-size: 16px;
`;

export const ComplementsArea = styled.div`
  ${(props) =>
    props.complementLength > 1 &&
    css`
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    `}
`;

export const ComplementItemArea = styled.div`
  min-width: 50%;
  max-height: 260px;
  overflow-y: auto;
  padding-bottom: 20px;
  border: 1px solid #ededed;
  position: relative;

  @media (min-width: 1679px) {
    ${(props) =>
      props.complementLength < 3 &&
      css`
        max-height: 420px;
      `}
  }
`;

export const ComplementsHeaderProduct = styled.div`
  position: sticky;
  top: 0;

  background: #f8f8f8;

  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
export const ComplementsHeaderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const MandatoryItem = styled.span`
  font-size: 10px;
  background: #ff281e;
  color: #fff;
  border-radius: 7px;
  padding: 4px;
`;
export const ComplementItem = styled.div`
  width: 100%;

  display: flex;
  display: flex;
  flex-direction: column;
`;

export const ComplementItemDescription = styled.div`
  display: flex;
  span {
    font-family: Arial, Helvetica, sans-serif;

    @media (min-width: 1020px) and (max-width: 1400px) {
      font-size: 12px;
    }
  }
`;

export const ComplementName = styled.span`
  font-size: 14px;
  width: 330px;
  max-width: 330px;
  /* white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis; */

  @media (max-width: 1025px) {
    width: 108px;
    font-size: 12px;
    max-width: 108px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  @media (min-width: 1028px) and (max-width: 1299px) {
    width: 154px;
    max-width: 170px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (min-width: 1300px) and (max-width: 1600px) {
    width: 200px;
    font-size: 12px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (min-width: 1601px) and (max-width: 1700px) {
    width: 258px;

    max-width: 258px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (min-width: 1701px) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const ComplementItemDescriptionLimit = styled.p`
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  color: #ff281e;
  margin-top: 5px;
`;

export const ComplementItemAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin-right: 10px;
    margin-left: 10px;
    color: #ff281e;
  }
`;

export const InformationText = styled.div`
  margin-top: 20px;
  margin-bottom: 12px;
  position: relative;

  p {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }

  svg {
    position: absolute;
    margin-right: 8px;
    top: 24px;
    left: 14px;
  }

  input {
    width: 100%;

    height: 40px;
    padding-left: 32px;
    margin-top: 10px;
    text-align: left;

    color: #a3a3a3;
    border-radius: 5px;
    border: 1px solid #f7c3ca;

    ::placeholder {
      color: #a3a3a3;
      font-size: 14px;
    }
  }
`;

export const ModalTitle = styled.div`
  padding: 10px;
  p {
    text-align: left;
    font-weight: bold;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid #33333310;
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #4ceb5b;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
`;

export const QuestionSubtitle = styled.span`
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  line-height: 10px;
`;

export const ComplementQuestion = styled.span`
  font-weight: bold;
  line-height: 20px;
  font-size: 12px;

  white-space: nowrap;
  max-width: 246px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
