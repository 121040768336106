import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Content } from './styles';
import Header from './Header';
import NFeEntry from './NFeEntry';
// import NFeAvulsa from './NFeAvulsa';
import NFeDevolucao from './NFeDevolucao';
import Nfe from './NFeManifest';
// import Nfces from '~/components/Nfces';
import NFCeHistory from './NFCeHistory';

function FiscalPage() {


  return (
    <>
      <Header />
      <Content>
        <Switch>
          <Route path="/operation/fiscal/manifest" component={Nfe} />
          <Route path="/operation/fiscal/entries" component={NFeEntry} />
          <Route
            path="/operation/fiscal/issued"
            component={NFCeHistory}
          />
          <Route
            path="/operation/fiscal/returned"
            component={NFeDevolucao}
          />
        </Switch>
      </Content>
    </>
  );
}

export default FiscalPage;