import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-hot-toast';
import Input from '~/components/Form/Input';
import Select from '~/components/Form/SelectInput';

import SaveButton from '~/components/Buttons/DefaultButton';
import RegisterButton from '~/components/Buttons/RegisterButton';

import Category from './Category';

import { Container, Card, Header } from './styles';

import api from '~/services/api';
import getValidationErrors from '~/services/getValidationErrors';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { PageTitle } from '~/components/PageTitle';

function CostCenters() {
  const formRef = useRef(null);
  const [categories, setCategories] = useState(false);
  const [subCategories, setSubCategories] = useState(false);
  const [isModalCreateCategoryOpen, setIsModalCreateCategoryOpen] =
    useState(false);
  const cashFlowTypes = [
    { value: 'financeiro', label: 'Financeiro' },
    { value: 'operacional', label: 'Operacional' },
    { value: 'insumos', label: 'Insumos e Produtos' },
  ];

  const selecSubCategoriesOptions =
    subCategories.length > 0 &&
    subCategories?.map((sub) => {
      return {
        value: sub.id,
        label: sub.name,
      };
    });

  const toggleModalCreateCategory = useCallback(() => {
    setIsModalCreateCategoryOpen(!isModalCreateCategoryOpen);
  }, [isModalCreateCategoryOpen]);

  const getCategories = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows/categories');

      setCategories(response.data);
    } catch (error) {
      toast.error('Erro ao baixar categorias');
    }
  }, []);

  const getSubCategories = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows/subcategories');

      setSubCategories(response.data);
    } catch (error) {
      toast.error('Erro ao baixar subcategorias');
    }
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        if (formRef?.current) {
          formRef.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          type: Yup.string().required('Tipo obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/restaurants/cash-flows/categories', {
          name: data.name,
          subcategories: data.subcategories,
          type: data.type,
        });

        toast.success('Categoria cadastrada com sucesso!');

        getCategories();
        toggleModalCreateCategory();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRef?.current) {
            formRef.current.setErrors(errors);
          }
        } else {
          toast.error(`Erro ao cadastrar catedoria!`);
        }
      }
    },
    [getCategories, toggleModalCreateCategory]
  );

  async function handleDeleteCategory(id) {
    if (window.confirm('Você realmente deseja deletar esta categoria?')) {
      try {
        await api.delete(`/restaurants/cash-flows/categories/${id}`);

        getCategories();
      } catch (error) {
        if (!error.response.ok) {
          if (
            error.response.data.errorType ===
            'can_not_delete_cash_flow_category'
          ) {
            toast.error(
              'O usuário não pode deletar categorias padrões do sistema'
            );
          } else {
            toast.error('Erro ao deletar categoria');
          }
        }
      }
    }
  }

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'FinanceCashFlowCategories'}`
      );

      const { can_read } = response.data.FinanceCashFlowCategories;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    getSubCategories();
  }, [getSubCategories]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div>
          <PageTitle>Centro de custo</PageTitle>
          <p>
            Gerencie aqui as categorias e sub-categorias do centro de custo que
            serão usadas para movimentações
          </p>
        </div>

        <RegisterButton onClick={toggleModalCreateCategory} />
      </Header>
      <Card borderless>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {categories &&
            categories.length > 0 &&
            categories.map((category) => (
              <>
                <Category
                  category={category}
                  subCategories={subCategories}
                  key={category.id}
                  handleDeleteCategory={handleDeleteCategory}
                  getCategories={getCategories}
                  getSubCategories={getSubCategories}
                />
              </>
            ))}
        </tbody>
      </Card>

      <Modal
        isOpen={isModalCreateCategoryOpen}
        toggle={toggleModalCreateCategory}
      >
        <ModalHeader> Criar Categoria</ModalHeader>
        <Form onSubmit={handleSubmit} ref={formRef}>
          <ModalBody>
            <Input label="Nome do categoria" name="name" />
            <Select
              label="Subcategorias"
              name="subcategories"
              isMulti
              options={selecSubCategoriesOptions}
            />
            <Select label="Tipo" name="type" options={cashFlowTypes} />
          </ModalBody>
          <ModalFooter>
            <SaveButton type="submit" message="Salvar" />
          </ModalFooter>
        </Form>
      </Modal>
    </Container>
  );
}

export default CostCenters;
