import React, { useRef, useState, useCallback, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';


import ReactModal from 'react-modal';
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from 'reactstrap';
import Select from '~/components/Form/SelectInput';
import InputMask from 'react-input-mask';
import NFeInfo from '~/services/NFeInfo';
import nfeInfo from '~/services/NFeInfo';

import { FaArrowLeft, FaTrash } from 'react-icons/fa';
import {
  Header,
  StyledForm,
  ButtonsArea,
  Container,
  NavDiv,
  NavDivTeste,
  NavLinkSpan,
  InnerArea,
  FiscalMessage,
} from './styles';

import api from '~/services/api';

import Input from '~/components/Form/Input';
import Checkbox from '~/components/Form/Checkbox';
import englishFlag from "~/assets/img/englishFlag.png"
import { useAuth } from '~/context/AuthContext';
import { useMenu } from '~/context/OrderingSystem/Menu';
import PersonalButton from '~/components/Buttons/PersonalButton';
import CoverInput from '~/components/Form/CoverInput';
import { DefaultInput, Button } from 'ui-kit-takeat';
import { PersonalizedHoursContainer } from '../../Edit/styles';
import { format } from 'date-fns';
import EnglishMenuButton from '~/components/Buttons/EnglishMenuButton';
import PersonalOutlineButton from '~/components/Buttons/PersonalOutlineButton';

function Store({ toggle, getCategories, category, fiscalGroups }) {
  const [startTime, setStartTime] = useState(() => {
    if (category.start_time) {
      const d = new Date(category.start_time);
      const hours = d.getHours().toString().padStart(2, '0');
      const mins = d.getMinutes().toString().padStart(2, '0');

      return `${hours}:${mins}`;
    }

    return null;
  });

  const [endTime, setEndTime] = useState(() => {
    if (category.end_time) {
      const d = new Date(category.end_time);
      const hours = d.getHours().toString().padStart(2, '0');
      const mins = d.getMinutes().toString().padStart(2, '0');

      return `${hours}:${mins}`;
    }

    return null;
  });

  function readDaysSelected(value) {
    const selected = [];

    for (let i = 0; i < 7; i++) {
      if (value[i] === 't') {
        selected.push(i);
      }
    }

    return selected;
  }

  function daysSelectedToStr(selected) {
    const days = [];

    for (let i = 0; i < 7; i++) {
      days.push(selected.includes(i) ? 't' : 'f');
    }

    return days.join('');
  }

  const [weekDays] = useState([
    'Dom',
    'Seg',
    'Ter',
    'Qua',
    'Qui',
    'Sex',
    'Sáb',
  ]);

  const [selectedDays, setSelectedDays] = useState(
    readDaysSelected(category.active_days)
  );

  const [enableTimes, setEnableTimes] = useState(category.enable_times);
  const disable_enable_times = category?.is_multistore_child && category?.multistore_enable_times;
  const [daysSelected, setDaysSelected] = useState(
    category.active_days.split('')
  );
  const [hourSelected, setHourSelected] = useState({
    start: format(new Date(category.start_time), 'HH:mm'),
    end: format(new Date(category.end_time), 'HH:mm'),
  });

  const handleChangeDay = (idx) => {
    const updatedDays = [...daysSelected];
    updatedDays[idx] = updatedDays[idx] === 'f' ? 't' : 'f';
    setDaysSelected(updatedDays);
  };

  const handleTimeInput = (time) => {
    let validTime = '';
    const array = time.split('');

    array.forEach((element, i) => {
      if (i === 0 && element > 2) {
        validTime += '2';
      } else if (i === 1 && element > 3 && array[0] > 1) {
        validTime += '3';
      } else if (i === 3 && element > 5) {
        validTime += '5';
      } else {
        validTime += element;
      }
      return;
    });

    return validTime;
  };

  const [initialData, setInitialData] = useState({
    name: category.name,
    time: category.preparation_time,
    available: category.available,
    is_exclusive: category.is_exclusive,
    available_in_delivery: category.available_in_delivery,
    personalized_hours: category.start_time && category.end_time,
    startTime,
    endTime,
    image_id: {
      id: category.image ? category.image.id : null,
      url: category.image
        ? category?.image?.url_thumb || category?.image?.url
        : null,
    },
    enable_times: enableTimes,
  });

  const { getMenu } = useMenu();

  const formRef = useRef(null);
  const { user } = useAuth();

  const [activeTab, setActiveTab] = useState('1');

  async function handleUpdate(data) {
    try {
      const start_time =
        enableTimes && data.startTime
          ? `2021-01-09T${data.startTime}:00-03:00`
          : null;
      const end_time =
        enableTimes && data.endTime
          ? `2021-01-09T${data.endTime}:00-03:00`
          : null;

      const schema = Yup.object().shape({
        name: Yup.string().required('O nome é obrigatório'),
        start_time: Yup.date('Data inicial inválida').nullable(),
        end_time: Yup.date('Data inicial inválida').nullable(),
      });

      await schema.validate(
        {
          name: data.name,
          start_time,
          end_time,
        },
        {
          abortEarly: false,
        }
      );

      const image_id_parsed = data.image_id ? Number(data.image_id) : null;

      await api.put(`/restaurants/product-category/${category.id}`, {
        name: data.name,
        preparation_time: data.time,
        available: availableCategory,
        available_in_delivery: availableDeliveryCategory,
        is_exclusive: data.is_exclusive,
        enable_times: enableTimes,
        start_time: new Date(`2023/01/01 ${hourSelected.start}`),
        end_time: new Date(`2023/01/01 ${hourSelected.end}`),
        active_days: daysSelected.join(''),
        image_id: image_id_parsed,
      });

      if (category) {
        category.name = data.name;
        category.preparation_time = data.time;
        category.available = availableCategory;
        category.available_in_delivery = availableDeliveryCategory;
        category.is_exclusive = data.is_exclusive;
        category.start_time = category.start_time;
        category.end_time = category.end_time;
        category.image_id = data.image_id;
        category.enable_times = category.enable_times;
      }

      toast.success('Categoria atualizada com sucesso!');
      getMenu();
      getCategories();
      toggle();
    } catch (error) {
      if (error.response.data.errorType === 'only_one_time') {
        toast.error(
          `Favor preencher os tempos dos horários de início e fim das categorias disponíveis.`
        );
      } else {
        toast.error(`Erro ao atualizar categoria!`);
      }
    }
  }

  const onDaySelected = useCallback(
    (selected) => {
      const index = selectedDays.indexOf(selected);

      if (index < 0) {
        selectedDays.push(selected);
      } else {
        selectedDays.splice(index, 1);
      }

      setSelectedDays([...selectedDays]);
    },
    [selectedDays]
  );

  // switchers

  const [availableCategory, setAvailableCategory] = useState(
    category?.available
  );

  function handleChangeAvailableCategory() {
    setAvailableCategory(!availableCategory);
  }

  const [availableDeliveryCategory, setAvailableDeliveryCategory] = useState(
    category?.available_in_delivery
  );

  function handleChangeAvailableDeliveryCategory() {
    setAvailableDeliveryCategory(!availableDeliveryCategory);
  }

  const [deleteCover, setDeleteCover] = useState(false);
  function handleDeleteCover() {
    setDeleteCover(!deleteCover);

    setInitialData((state) => {
      return { ...state, image_id: null };
    });
  }

  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);

  const [fiscalGroup, setFiscalGroup] = useState(null);
  const [fiscalGroupDefault, setFiscalGroupDefault] = useState(null);

  // regime simples
  const [icmsOrigemValue, setIcmsOrigemValue] = useState(null);
  const [csosnValue, setCsosnValue] = useState(null);
  const [cfopValue, setCfopValue] = useState(null);
  const [cofinsValue, setCofinsValue] = useState(null);
  const [pisValue, setPisValue] = useState(null);
  const [cestValue, setCestValue] = useState(null);
  const [icmsValorValue, setIcmsValorValue] = useState(null);
  const [baseCalculoValue, setBaseCalculoValue] = useState(null);
  const [aliquotaValue, setAliquotaValue] = useState(null);
  // regime normal
  const [cstValue, setCstValue] = useState(null);
  const [pisAliquotaValue, setPisAliquotaValue] = useState(null);
  const [cofinsAliquotaValue, setCofinsAliquotaValue] = useState(null);
  const [icmsModalidadeBCValue, setIcmsModalidadeBCValue] = useState(null);
  const [cbenefValue, setCbenefValue] = useState(null);
  const [ncm, setNcm] = useState(null);

  const [csosnSelected, setCsosnSelected] = useState(null);
  const [cfopOptions, setCfopOptions] = useState([]);

  useEffect(() => {
    const cfopSelect = nfeInfo.csosn.filter((cs) => cs.csosn === csosnSelected);
    const cfop = [];

    cfopSelect.map((selected) =>
      selected.cfop?.forEach((cf) => {
        const cfopDesc = nfeInfo.cfopDescription.filter(
          (cfop) => cfop.cfop === cf
        );

        cfop.push({
          value: cfopDesc[0].cfop,
          label: `${cfopDesc[0].cfop} - ${cfopDesc[0].description}`,
        });
      })
    );

    setCfopOptions(cfop);
  }, [csosnSelected]);

  async function updateFiscalValues(id) {
    const group = fiscalGroups.find((group) => group.id === id);
    setFiscalGroup(id);
    setFiscalGroupDefault({
      value: group.id,
      label: group.title,
    });
    setIcmsOrigemValue(
      icms_origem_options.find((x) => x.value === group.icms_origem)
    );
    setCsosnValue(csosn_options.find((x) => x.value === group.csosn));
    setCfopValue(NFeInfo.cfop_options.find((x) => x.value === group.cfop));
    setCofinsValue(
      NFeInfo.cofins_options.find((x) => x.value === group.cofins)
    );
    setPisValue(NFeInfo.cofins_options.find((x) => x.value === group.pis));
    setCestValue(group.cest);
    setIcmsValorValue(group.icms_valor);
    setAliquotaValue(group.aliquota);
    setBaseCalculoValue(
      base_calculo_options.find((x) => x.value === group.base_calculo)
    );
    // regime normal
    setCstValue(
      NFeInfo.icms_situacao_tributaria.find((x) => x.value === group.cst)
    );
    setPisAliquotaValue(group.pis_aliquota);
    setCofinsAliquotaValue(group.cofins_aliquota);
    setIcmsModalidadeBCValue(
      NFeInfo.icms_modalidade_base_calculo.find(
        (x) => x.value === group.icms_modalidade_base_calculo
      )
    );
    setCbenefValue(group.cbenef);
  }

  const icms_origem_options = nfeInfo.icms_origem.map((icms) => ({
    value: icms.value,
    label: icms.description,
  }));

  const csosn_options = nfeInfo.csosn.map((c) => ({
    value: c.csosn,
    label: `${c.csosn} - ${c.description}`,
  }));

  const base_calculo_options = nfeInfo.base_calculo.map((c) => ({
    value: c.value,
    label: c.description,
  }));

  async function handleSubmitFiscal(data) {
    try {
      const parsedData = {
        fiscal_group_id: fiscalGroup,
      };

      if (user.regime_tributario === '1') {
        parsedData.cfop = cfopValue?.value || data.cfop;
        parsedData.csosn = csosnValue?.value || data.csosn;
        parsedData.cest = cestValue?.value || data?.cest || null;
        parsedData.icms_origem = icmsOrigemValue?.value || data.icms_origem;
        parsedData.ncm = ncm || data.ncm;

        if (csosnSelected === '00') {
          parsedData.aliquota = aliquotaValue || data.aliquota;
          parsedData.base_calculo =
            baseCalculoValue?.value || data.base_calculo;
        }

        if (user.has_nfce && data.cofins !== undefined) {
          parsedData.cofins = cofinsValue?.value || data.cofins;
        }

        if (user.has_nfce && data.pis !== undefined) {
          parsedData.pis = pisValue?.value || data.pis;
        }
      } else {
        parsedData.cfop = cfopValue?.value || data.cfop;
        parsedData.csosn = csosnValue?.value || data.csosn;
        parsedData.cest = cestValue?.value || data?.cest || null;
        parsedData.icms_origem = icmsOrigemValue?.value || data.icms_origem;
        parsedData.icms_aliquota = aliquotaValue || data.icms_aliquota;
        parsedData.icms_valor = icmsValorValue || data.icms_valor;
        parsedData.ncm = ncm || data.ncm;

        parsedData.cst = cstValue?.value || data.cst;
        parsedData.pis_aliquota = pisAliquotaValue || data.pis_aliquota;
        parsedData.cofins_aliquota =
          cofinsAliquotaValue || data.cofins_aliquota;
        parsedData.icms_modalidade_base_calculo =
          icmsModalidadeBCValue?.value || data.icms_modalidade_base_calculo;
        parsedData.cbenef = cbenefValue || data.cbenef;

        if (user.has_nfce && data.cofins !== undefined) {
          parsedData.cofins = cofinsValue?.value || data.cofins;
        }

        if (user.has_nfce && data.pis !== undefined) {
          parsedData.pis = pisValue?.value || data.pis;
        }
      }

      await api.put(
        `restaurants/product-category/fiscal/${category.id}`,
        parsedData
      );
      toast.success('Informações fiscais atualizadas');
    } catch (error) {
      toast.error('Falha ao salvar dados fiscais');
      console.log(error);
    }
  }

  //English Menu
  const [isEnglishMenuModalOpened, setIsEnglishMenuModalOpened] = useState(false)
  function toggleEnglishMenuModal() {
    setIsEnglishMenuModalOpened(!isEnglishMenuModalOpened)
  }
  const englishInitialData = {
    en_name: category?.translations?.en?.name,
  }

  async function handleSubmitEnglishMenu(data) {
    if (!category) {
      return;
    }

    let translations = null;

    if (data.en_name) {
      translations = {
        en: {
          name: data.en_name,
        },
      };
    }

    try {
      const response = await api.put(`/restaurants/product-category/${category.id}`, {
        translations,
      });

      category.translations = response.data.translations;

      toast.success('Categoria atualizada com sucesso!');

      toggleEnglishMenuModal();
    } catch (error) {
      toast.error('Falha ao atualizar categoria');
      console.log(error);
    }
  }

  return (
    <Container>
      <ReactModal
        onRequestClose={toggleEnglishMenuModal}
        isOpen={isEnglishMenuModalOpened}
        className="modal-content-takeat-without-overflow"
        closeTimeoutMS={500}
      >
        <InnerArea browserZoomLevel={browserZoomLevel}>
          <StyledForm
            initialData={englishInitialData}
            ref={formRef}
            onSubmit={handleSubmitEnglishMenu}
          >
            <Row>
              <span style={{ fontWeight: 'bold' }}> <img src={englishFlag} alt="Bandeira dos Estados unidos" style={{ width: 20, height: 20 }} /> Cardápio em inglês</span>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col size="md-6">
                <Input
                  label="Nome da categoria"
                  name="en_name"
                  type="text"
                  placeholder="Nome da categoria"
                  disabled={category?.is_multistore_child}
                />
              </Col>
            </Row>
            <ButtonsArea>
              <PersonalOutlineButton
                color="transparent"
                fontColor="#FF2C3A"
                borderColor="#FF2C3A"
                message="Voltar"
                Icon={FaArrowLeft}
                style={{
                  marginLeft: 0,
                }}
                onClick={() => {
                  toggleEnglishMenuModal();
                }}
              />

              <PersonalButton
                color="#0CCAB4"
                message="Salvar"
                type="submit"
                style={{
                  marginRight: 10,
                }}
              />
            </ButtonsArea>
          </StyledForm>
        </InnerArea>
      </ReactModal>
      <Header>

        <h3>Editar categoria de produtos</h3>
        {user?.enable_translations && (
          <EnglishMenuButton onClick={toggleEnglishMenuModal} />
        )}

      </Header>

      <div>
        <NavDiv>
          <NavDivTeste>
            <Nav tabs red borderless>
              <NavItem>
                <NavLink
                  className={activeTab === '1' ? 'active' : ''}
                  onClick={() => setActiveTab('1')}
                >
                  <NavLinkSpan>Categoria</NavLinkSpan>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={activeTab === '2' ? 'active' : ''}
                  onClick={() => setActiveTab('2')}
                >
                  <NavLinkSpan>Fiscal</NavLinkSpan>
                </NavLink>
              </NavItem>
            </Nav>
          </NavDivTeste>
        </NavDiv>
      </div>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <InnerArea browserZoomLevel={browserZoomLevel}>
            <StyledForm
              initialData={initialData}
              ref={formRef}
              onSubmit={handleUpdate}
            >
              <Row>
                <Col size="md-6">
                  <Input
                    label="Nome"
                    name="name"
                    type="text"
                    placeholder="Nome da categoria"
                    disabled={category?.is_multistore_child}
                  />

                  <Row>
                    <Input
                      label="Tempo de preparo"
                      name="time"
                      type="number"
                      placeholder="Tempo (em minutos)"
                    />
                  </Row>
                </Col>

                <Col md="6">
                  <p style={{ fontWeight: 'bold' }}>
                    Imagem para capa dessa categoria
                  </p>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CoverInput name="image_id" deleteCover={deleteCover} disabled={category?.is_multistore_child} />
                    <FaTrash
                      cursor="pointer"
                      onClick={() => handleDeleteCover()}
                      color="#FF2B3A"
                      style={{ marginLeft: 6 }}
                    />
                  </div>

                  <p>
                    Caso não queira inserir uma imagem, usaremos a foto do
                    primeiro produto dessa categoria como imagem de capa.
                  </p>
                </Col>
              </Row>

              <Row>
                {!user?.only_delivery && (
                  <Col md="3">
                    <Checkbox
                      label="Disponível no presencial"
                      name="available"
                      onChange={handleChangeAvailableCategory}
                      value={availableCategory}
                    />
                  </Col>
                )}

                {user?.delivery_info?.is_delivery_allowed && (
                  <Col md="3">
                    <Checkbox
                      label="Disponível no delivery"
                      name="available_in_delivery"
                      onChange={handleChangeAvailableDeliveryCategory}
                      value={availableDeliveryCategory}
                    />
                  </Col>
                )}
                <Col md="3">
                  <Checkbox
                    label="Uso exclusivo"
                    name="is_exclusive"
                    disabled={category?.is_multistore_child || false}
                  />
                </Col>
                <Col md="3">
                  <Checkbox
                    label="Horário personalizado"
                    name="enable_times"
                    value={enableTimes}
                    onChange={() => setEnableTimes(!enableTimes)}
                    disabled={disable_enable_times}
                  />
                </Col>
              </Row>

              {enableTimes && (
                <PersonalizedHoursContainer>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>Dias:</span>
                    <div style={{ display: 'flex', gap: 5 }}>
                      <Button
                        type="button"
                        title="Dom"
                        buttonColor="#2ec9b7"
                        containerStyles={{
                          width: 60,
                          height: 30,
                          opacity: daysSelected[0] === 'f' ? 0.5 : 1,
                        }}
                        onClick={() => {
                          handleChangeDay(0);
                        }}
                        disabled={disable_enable_times}
                      />
                      <Button
                        type="button"
                        title="Seg"
                        containerStyles={{
                          width: 60,
                          height: 30,
                          opacity: daysSelected[1] === 'f' ? 0.5 : 1,
                        }}
                        buttonColor="#2ec9b7"
                        onClick={() => {
                          handleChangeDay(1);
                        }}
                        disabled={disable_enable_times}
                      />
                      <Button
                        type="button"
                        title="Ter"
                        containerStyles={{
                          width: 60,
                          height: 30,
                          opacity: daysSelected[2] === 'f' ? 0.5 : 1,
                        }}
                        buttonColor="#2ec9b7"
                        onClick={() => {
                          handleChangeDay(2);
                        }}
                        disabled={disable_enable_times}
                      />
                      <Button
                        type="button"
                        title="Qua"
                        containerStyles={{
                          width: 60,
                          height: 30,
                          opacity: daysSelected[3] === 'f' ? 0.5 : 1,
                        }}
                        buttonColor="#2ec9b7"
                        onClick={() => {
                          handleChangeDay(3);
                        }}
                        disabled={disable_enable_times}
                      />
                      <Button
                        type="button"
                        title="Qui"
                        containerStyles={{
                          width: 60,
                          height: 30,
                          opacity: daysSelected[4] === 'f' ? 0.5 : 1,
                        }}
                        buttonColor="#2ec9b7"
                        onClick={() => {
                          handleChangeDay(4);
                        }}
                        disabled={disable_enable_times}
                      />
                      <Button
                        type="button"
                        title="Sex"
                        containerStyles={{
                          width: 60,
                          height: 30,
                          opacity: daysSelected[5] === 'f' ? 0.5 : 1,
                        }}
                        buttonColor="#2ec9b7"
                        disabled={disable_enable_times}
                        onClick={() => {
                          handleChangeDay(5);
                        }}
                      />
                      <Button
                        type="button"
                        title="Sáb"
                        containerStyles={{
                          width: 60,
                          height: 30,
                          opacity: daysSelected[6] === 'f' ? 0.5 : 1,
                        }}
                        buttonColor="#2ec9b7"
                        onClick={() => {
                          handleChangeDay(6);
                        }}
                        disabled={disable_enable_times}
                      />
                    </div>
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>Horários:</span>
                    <div
                      style={{
                        display: 'flex',
                        gap: 5,
                        alignItems: 'center',
                      }}
                    >
                      <span>das</span>
                      <DefaultInput
                        placeholder="__:__"
                        mask="99:99"
                        maskChar="0"
                        style={{
                          width: '70px',
                          padding: '0px 10px',
                          height: '30px',
                        }}
                        inputStyle={{ textAlign: 'center' }}
                        value={hourSelected.start}
                        disabled={disable_enable_times}
                        onChange={(e) => {
                          const value = e.target.value;
                          setHourSelected((state) => {
                            return {
                              start: handleTimeInput(value),
                              end: state.end,
                            };
                          });
                        }}
                      />
                      <span>às</span>
                      <DefaultInput
                        placeholder="__:__"
                        mask="99:99"
                        maskChar="0"
                        style={{
                          width: '70px',
                          padding: '0px 10px',
                          height: '30px',
                        }}
                        inputStyle={{ textAlign: 'center' }}
                        value={hourSelected.end}
                        disabled={disable_enable_times}
                        onChange={(e) => {
                          const value = e.target.value;
                          setHourSelected((state) => {
                            return {
                              start: state.start,
                              end: handleTimeInput(value),
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                </PersonalizedHoursContainer>
              )}
              <ButtonsArea>
                <PersonalButton
                  color="#FF2C3A"
                  message="Cancelar"
                  style={{
                    marginLeft: 0,
                  }}
                  onClick={() => {
                    toggle();
                  }}
                />

                <PersonalButton
                  color="#0CCAB4"
                  message="Salvar"
                  type="submit"
                  style={{
                    marginRight: 10,
                  }}
                />
              </ButtonsArea>
            </StyledForm>
          </InnerArea>
        </TabPane>

        <TabPane tabId="2">
          <StyledForm onSubmit={handleSubmitFiscal}>
            <InnerArea browserZoomLevel={browserZoomLevel}>
              {user.has_nfce && (
                <p>
                  Área destinada a aplicar informações fiscais em massa em todos
                  os produtos dessa categoria. Ao Salvar, as informações fiscais
                  serão aplicadas a todos os produtos dessa categoria, e essa
                  ação não poderá ser desfeita. Portanto, é importante tomar
                  cuidado.
                </p>
              )}
              {user.has_nfce && (
                <Row>
                  <Col sm="12">
                    <Select
                      name="fiscal_group_id"
                      options={fiscalGroups?.map((group) => {
                        return {
                          label: group.title,
                          value: group.id,
                        };
                      })}
                      label="Grupo Fiscal"
                      onChange={(e) => updateFiscalValues(e.value)}
                      value={fiscalGroupDefault}
                    />
                  </Col>
                </Row>
              )}

              {!user.has_nfce && (
                <FiscalMessage>
                  <p>Seu plano atual não contempla a função de fiscal!</p>
                  <span>
                    Entre em contato com nosso time de suporte e faça a
                    ativação.
                  </span>
                </FiscalMessage>
              )}
              {user.has_nfce &&
                (user.regime_tributario === '1' ? (
                  <div>
                    <Row style={{ paddingTop: 10 }}>
                      <Col sm="12" lg="6">
                        <InputMask
                          mask="99999999"
                          onChange={(e) => {
                            setNcm(e.target.value);
                          }}
                          value={ncm}
                        >
                          <Input label="NCM" name="ncm" type="text" />
                        </InputMask>
                      </Col>
                      <Col sm="12" lg="6" style={{ marginTop: 15 }}>
                        <Select
                          name="icms_origem"
                          options={icms_origem_options}
                          label="ICMS de Origem"
                          value={icmsOrigemValue}
                          onChange={(e) => {
                            setIcmsOrigemValue(
                              icms_origem_options.find(
                                (x) => x.value === e.value
                              )
                            );
                          }}
                        />
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: 10 }}>
                      <Col sm="12" lg="6">
                        <Select
                          name="csosn"
                          options={csosn_options}
                          label="CSOSN"
                          onChange={(e) => {
                            setCsosnSelected(e.value);
                            setCsosnValue(
                              csosn_options.find((x) => x.value === e.value)
                            );
                          }}
                          value={csosnValue}
                        />
                      </Col>

                      <Col sm="12" lg="6">
                        <Select
                          name="cfop"
                          options={cfopOptions}
                          label="CFOP"
                          onChange={(e) => {
                            setCfopValue(
                              NFeInfo.cfop_options.find(
                                (x) => x.value === e.value
                              )
                            );
                          }}
                          value={cfopValue}
                        />
                      </Col>
                    </Row>

                    <Row style={{ paddingTop: 10 }}>
                      <Col sm="12" lg="6">
                        <Select
                          name="cofins"
                          options={nfeInfo.cofins_options}
                          label="Cofins"
                          onChange={(e) => {
                            setCofinsValue(
                              NFeInfo.cofins_options.find(
                                (x) => x.value === e.value
                              )
                            );
                          }}
                          value={cofinsValue}
                        />
                      </Col>

                      <Col sm="12" lg="6">
                        <Select
                          name="pis"
                          options={nfeInfo.cofins_options}
                          label="Pis"
                          onChange={(e) => {
                            setPisValue(
                              NFeInfo.cofins_options.find(
                                (x) => x.value === e.value
                              )
                            );
                          }}
                          value={pisValue}
                        />
                      </Col>
                    </Row>

                    {(csosnValue?.value === '00' ||
                      aliquotaValue ||
                      baseCalculoValue) && (
                        <div>
                          <Row style={{ paddingTop: 20 }}>
                            <Col sm="12" lg="6">
                              <Input
                                label="Alíquota (Entre 0 e 100)"
                                name="aliquota"
                                type="text"
                                value={aliquotaValue}
                                onChange={(e) => {
                                  setAliquotaValue(e.target.value);
                                }}
                              />
                            </Col>

                            <Col sm="12" lg="6" style={{ marginTop: 15 }}>
                              <Select
                                name="base_calculo"
                                options={base_calculo_options}
                                label="Base Cálculo"
                                value={baseCalculoValue}
                                onChange={(e) => {
                                  setBaseCalculoValue(
                                    base_calculo_options.find(
                                      (x) => x.value === e.value
                                    )
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      )}

                    {(csosnValue?.value === '500' || cestValue) && (
                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <InputMask
                            mask="9999999"
                            onChange={(e) => {
                              setCestValue(e.target.value);
                            }}
                            value={cestValue}
                          >
                            <Input label="CEST" name="cest" type="text" />
                          </InputMask>
                        </Col>
                      </Row>
                    )}
                  </div>
                ) : (
                  <div>
                    <Row style={{ paddingTop: 10 }}>
                      <Col sm="12" lg="4">
                        <InputMask
                          mask="99999999"
                          onChange={(e) => {
                            setNcm(e.target.value);
                          }}
                          value={ncm}
                        >
                          <Input label="NCM" name="ncm" type="text" />
                        </InputMask>
                      </Col>

                      <Col sm="12" lg="4">
                        <Select
                          name="icms_origem"
                          options={icms_origem_options}
                          label="ICMS de Origem"
                          value={icmsOrigemValue}
                          onChange={(e) => {
                            setIcmsOrigemValue(
                              icms_origem_options.find(
                                (x) => x.value === e.value
                              )
                            );
                          }}
                        />
                      </Col>

                      <Col sm="12" lg="4">
                        <Input
                          label="Alíquota ICMS (%)"
                          name="icms_aliquota"
                          type="text"
                          value={aliquotaValue}
                          onChange={(e) => {
                            setAliquotaValue(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: 10 }}>
                      <Col sm="12" lg="6">
                        <Select
                          name="cfop"
                          options={cfopOptions}
                          label="CFOP"
                          onChange={(e) => {
                            setCfopValue(
                              NFeInfo.cfop_options.find(
                                (x) => x.value === e.value
                              )
                            );
                          }}
                          value={cfopValue}
                        />
                      </Col>
                      <Col sm="12" lg="6">
                        <Select
                          name="cst"
                          options={NFeInfo.icms_situacao_tributaria}
                          label="CST"
                          onChange={(e) => {
                            setCstValue(
                              NFeInfo.icms_situacao_tributaria.find(
                                (x) => x.value === e.value
                              )
                            );
                          }}
                          value={cstValue}
                        />
                      </Col>
                    </Row>

                    <Row style={{ paddingTop: 10 }}>
                      <Col sm="12" lg="6">
                        <Select
                          name="pis"
                          options={nfeInfo.pis_situacao_tributaria}
                          label="PIS"
                          onChange={(e) => {
                            setPisValue(
                              NFeInfo.cofins_options.find(
                                (x) => x.value === e.value
                              )
                            );
                          }}
                          value={pisValue}
                        />
                      </Col>

                      <Col sm="12" lg="6">
                        <Input
                          label="Alíquota PIS (%)"
                          name="pis_aliquota"
                          type="text"
                          onChange={(e) => {
                            setPisAliquotaValue(e.target.value);
                          }}
                          value={pisAliquotaValue}
                        />
                      </Col>
                    </Row>

                    <Row style={{ paddingTop: 10 }}>
                      <Col sm="12" lg="6">
                        <Select
                          name="cofins"
                          options={nfeInfo.pis_situacao_tributaria}
                          label="COFINS"
                          onChange={(e) => {
                            setCofinsValue(
                              NFeInfo.cofins_options.find(
                                (x) => x.value === e.value
                              )
                            );
                          }}
                          value={cofinsValue}
                        />
                      </Col>

                      <Col sm="12" lg="6">
                        <Input
                          label="Alíquota COFINS (%)"
                          name="cofins_aliquota"
                          type="text"
                          onChange={(e) => {
                            setCofinsAliquotaValue(e.target.value);
                          }}
                          value={cofinsAliquotaValue}
                        />
                      </Col>
                    </Row>

                    <Row style={{ paddingTop: 10 }}>
                      <Col sm="12" lg="6">
                        <Select
                          name="icms_modalidade_base_calculo"
                          options={nfeInfo.icms_modalidade_base_calculo}
                          label="Modalidade Base Calculo"
                          onChange={(e) => {
                            setIcmsModalidadeBCValue(
                              NFeInfo.icms_modalidade_base_calculo.find(
                                (x) => x.value === e.value
                              )
                            );
                          }}
                          value={icmsModalidadeBCValue}
                        />
                      </Col>

                      <Col sm="12" lg="6">
                        <Input
                          label="Código de Benefícios Fiscais (cBenef)"
                          name="cbenef"
                          type="text"
                          onChange={(e) => {
                            setCbenefValue(e.target.value);
                          }}
                          value={cbenefValue}
                        />
                      </Col>
                    </Row>

                    {(csosnValue?.value === '500' || cestValue) && (
                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="12">
                          <InputMask
                            mask="9999999"
                            onChange={(e) => {
                              setCestValue(e.target.value);
                            }}
                            value={cestValue}
                          >
                            <Input label="CEST" name="cest" type="text" />
                          </InputMask>
                        </Col>
                      </Row>
                    )}

                    {(cstValue?.value === '00' || icmsValorValue) && (
                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="12">
                          <Input
                            label="ICMS Valor"
                            name="icms_valor"
                            type="text"
                            onChange={(e) => {
                              setIcmsValorValue(e.target.value);
                            }}
                            value={icmsValorValue}
                          />
                        </Col>
                      </Row>
                    )}
                  </div>
                ))}
            </InnerArea>
            <ButtonsArea>
              <PersonalButton
                color="#FF2C3A"
                message="Cancelar"
                style={{
                  marginLeft: 0,
                }}
                onClick={() => {
                  toggle();
                }}
              />

              <PersonalButton
                color="#0CCAB4"
                message="Salvar"
                type="submit"
                style={{
                  marginRight: 10,
                }}
              />
            </ButtonsArea>
          </StyledForm>
        </TabPane>
      </TabContent>
    </Container>
  );
}

export default Store;