import React, { useEffect, useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { IconInput } from 'ui-kit-takeat';
import {
  BackLink,
  Table,
  TablesArea,
  Container,
  Header,
  Body,
  BodyTitle,
} from './styles';
import { useBalanceContext } from '~/pages/BalancePage';

export const BalanceTablesArea = ({ }) => {
  const { tables, setCommand, command } = useBalanceContext();
  const [search, setSearch] = useState('');
  const [filteredTables, setFilteredTables] = useState([{}]);

  useEffect(() => {
    if (search === '') {
      setFilteredTables(tables);
    }
    else {
      const table = tables.filter((table) => {
        const tableNumberToString = table.table_number.toString();
        return tableNumberToString.includes(search)
      });
      setFilteredTables(table);
    }
  }, [search, tables]);
  return (
    <Container>
      <Header>
        <div>
          <BackLink to="/operation">
            <IoIosArrowBack color="#C8131B" />
            <span style={{ color: '#C8131B' }}>Voltar</span>
          </BackLink>
        </div>
        <IconInput
          color="#cdcdcd"
          icon={<FaSearch color="#cdcdcd" />}
          deleteIcon={<FaTimes color="#cdcdcd" onClick={() => setSearch('')} />}
          isClearable
          containerStyles={{
            width: '100%',
            height: 30,
            padding: '0px 10px',
          }}
          placeholder="Busque pelo número"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Header>

      <Body>
        <BodyTitle>Comandas</BodyTitle>
        <TablesArea>
          {filteredTables.map((table) => (
            <Table
              key={table.id}
              onClick={() => setCommand({
                id: table.id,
                label: table.table_number.toString(),
                status: table.status,
                tableType: table.table_type
              })}
              tableStatus={table.status}
              isTableSelected={table.id === command.id}
            >
              <span>{table.table_number}</span>
            </Table>
          ))}
        </TablesArea>
      </Body>
    </Container>
  );
};
