import styled from 'styled-components';

export const QrCodeArea = styled.div`
  width: 450px;
  height: 450px;

  background-color: grey;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;

  p {
    color: #fff;
    font-size: 38px;
    position: absolute;
    top: 12px;
  }
`;

export const Img2 = styled.img`
  width: 200px;
  height: 200px;
  background-color: grey;
  position: absolute;
  top: 180px;
`;
