import React, { useState, useEffect } from 'react';
import {
  Card,
  Container,
  Header,
  SearchInput,
  SearchLink,
  Title,
} from './styles';
import { HiMiniArrowTopRightOnSquare } from 'react-icons/hi2';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { FiX } from 'react-icons/fi';

export const SearchPage = (props) => {
  const { menuItems, user } = props;
  const [filter, setFilter] = useState('');
  const [searchArray, setSearchArray] = useState([]);

  useEffect(() => {
    const flattenItems = (
      items,
      depth = 1,
      parentPath = '',
      parentCondition = true
    ) => {
      return items.reduce((result, item) => {
        const { text, path, subitems, condition } = item;
        let itemCondition = condition === undefined ? true : condition;
        let fullPath = parentPath ? `${parentPath}` : '';
        if (path) {
          fullPath = `${fullPath}${path}`;
        }
        if (path && subitems?.length > 0) {
          itemCondition = false;
        }
        if (!path && subitems?.length > 0) {
          fullPath = `${fullPath}${subitems[0].path}`;
        }
        if (parentCondition === false) itemCondition = false;
        result.push({
          text,
          path: fullPath,
          external: item.external,
          externalRef: item.externalRef,
          condition: itemCondition,
          fullPath,
        });

        if (depth > 1 && subitems) {
          result.push(
            ...flattenItems(
              subitems,
              depth - 1,
              item.path ? item.path : parentPath
            )
          );
        }

        return result;
      }, []);
    };

    const flattenedItems = flattenItems(menuItems(user), 3);

    setSearchArray(flattenedItems);
  }, []);

  return (
    <Container>
      <Header>
        <div>
          <Title>Ver tudo</Title>
        </div>
        <SearchInput>
          <FaMagnifyingGlass color="#666" size={16} />
          <input
            type="text"
            placeholder="O que você procura?"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
          <FiX color="#CDCDCD" size={22} onClick={() => setFilter('')} />
        </SearchInput>
        <div />
      </Header>
      <div style={{ position: 'relative' }}></div>
      <Card>
        {searchArray
          .sort((a, b) => a.text.localeCompare(b.text))
          .filter((item) =>
            item.text
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .includes(
                filter
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
              )
          )
          .map((item, index) => {
            if (item.condition === false) return;

            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: 310,
                  height: 30,
                }}
              >
                <SearchLink
                  to={item.external ? '#' : item.path}
                  onClick={() => {
                    if (item.external) {
                      window.open(item.external, item.externalRef);
                    }
                    setFilter('');
                  }}
                >
                  <div
                    style={{
                      height: 30,
                      width: 30,
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  >
                    <HiMiniArrowTopRightOnSquare color="#666" size={18} />
                  </div>
                  {item.text}
                </SearchLink>
              </div>
            );
          })}
      </Card>
    </Container>
  );
};
