import { StyledNumberFormat } from './styles';
import React from 'react';

export const CurrencyInput = ({ value, onChange, hasPrefix, disabled }) => {
  function currencyFormatter(value) {
    if (!Number(value)) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return hasPrefix ? `${amount}` : `${amount}`.substring(3);
  }

  return (
    <StyledNumberFormat
      value={value}
      format={(e) => currencyFormatter(parseFloat(e))}
      onValueChange={(values) => onChange(values)}
      placeholder="-"
      disabled={disabled}
    />
  );
};
