import styled from 'styled-components';
import DatePicker from 'react-datepicker'
import { Table } from 'reactstrap';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 48px);
  overflow-y: auto;
  padding: 20px;
`;

export const Header = styled.header`
width: 100%;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  h2{
    font-weight: bold;
  }
`;

export const MessageContainer = styled.div` 
background-color: #E8F8FF;
border: 1px solid #85D0F2;
margin-left: 10px;
padding: 20px;
border-radius: 5px;
margin-top: 20px;
`
export const SearchBar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
margin-right: 10px;
  position: relative;

  input {
    width: 300px;
    font-size: 12px;
    background: #fff;
    color: #333;
    border: 1px solid  #33333370;
    border-radius: 4px;
    padding: 17px;
    padding-left: 28px;
    ::placeholder {
      color: #333;
    }
  }
`;
export const ButtonDeleteInput = styled.button`
  background: transparent;
  border: none;

  border-radius: 8px;
  position: absolute;
  left: 280px;
  bottom: 11px;

  svg {
    color: #33333340;
  }
`;

export const ButtonSearchInput = styled.button`
  background: transparent;
  border: none;

  border-radius: 8px;
  position: absolute;
  left: 10px;
  bottom: 11px;
  svg {
    color: #333;
  }
`;

export const ButtonUpdate = styled.button`
  color: #2EC9B7;
  border-color: #2EC9B7;
  background-color: transparent;
  text-decoration: underline;
  font-weight: bold;
  border: none;
  margin-right: 12px;
  display: flex;
`
export const CardMain = styled.main`
  width: 97%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius:7px;
  margin-left: 30px;
  background: #fff;

span{
  font-size: 22px;
  font-weight: bold;
}
`


export const Card = styled(Table)`
  width: 100%;
  min-width: 400px;
  align-items: center;
  border-radius:7px;
  border-collapse: separate;
  border-spacing: 0 20px;
  background: #fff;

  padding: 0 30px;
  margin: 20px auto;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    border-top: none;
    tr {
      th {
        button {
          border: none;
          background-color: transparent;
          font-weight: bold;
          color: #333;
        }
      }
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    tr {
      td {
        border-bottom: 1px solid #33333310;
      }
    }
    td:last-child {
      text-align: right;
    }
  }
`;


export const DatePickerStyledUnify = styled(DatePicker)`
  height: 44px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  width: 100%;
  margin-top: 8px;
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }
`;