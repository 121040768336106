import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  h1 {
    font-size: 16px;
    font-weight: bold;
  }
`;

export const AdviceArea = styled.div`
  background-color: #fff6e5;
  display: flex;
  align-items: center;
  border: 1px solid #ffa814;
  border-radius: 5px;
  justify-content: space-around;

  p {
    font-weight: bold;
  }
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;
export const InnerArea = styled.div`
  max-height: 60vh;
  overflow-y: auto;
`;

export const StyledTable = styled.table`
  width: 100%;
  min-width: 400px;
  align-items: center;
  margin-top: 20px;
  border-spacing: 0 20px;

  th,
  td {
    padding: 24px;
  }

  thead {
    border-bottom: none;
    text-align: left;
    background: #efefef;
    color: #6c6c6c;

    tr,
    th {
      border-color: #fff;
      border-style: solid;
      border-left: none;
      text-align: center;
    }

    /* tr {
      th:last-child {
        text-align: right;
      }
    } */
  }
  tbody {
    td {
      border-bottom: 1px solid #dee2e6;
    }
    /* td:last-child {
      text-align: right;
    } */

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    font-size: 18px;
    color: #4d4d4c;
    font-weight: bold;
  }
`;
