import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Button } from 'ui-kit-takeat';

import { Modal, ModalBody } from 'reactstrap';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { FaExclamationTriangle, FaTimesCircle } from 'react-icons/fa';

import {
  AdviceArea,
  Container,
  Footer,
  Header,
  InnerArea,
  StyledTable,
} from './styles';

import ProgressBar from '../ProgressBar';

import formatCompleteDate from '~/services/formatDate';
import api from '~/services/api';

import { useAuth } from '~/context/AuthContext';

function FinishBillsInBatch({
  initialDate,
  finalDate,
  isDelivery,
  toggleModalFinishBillsInBatch,
  getSessions,
}) {
  const [submiting, setSubmiting] = useState(false);
  const [isBillsSubmitted, setIsBillsSubmitted] = useState(false);
  const [billsFinisehd, setBillsFinished] = useState(false);
  const [bills, setBills] = useState([]);
  const { user } = useAuth();

  const sendCommandToBackend = async (command) => {
    try {
      const response = await api.post('restaurants/new-nfce', {
        key: command.key,
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getBills = useCallback(async () => {
    const response = await api.get('/restaurants/completable-table-sessions', {
      params: {
        is_delivery: isDelivery,
        start_date: initialDate,
        end_date: finalDate,
      },
    });

    setBills(response.data);
  }, []);

  const handleSubmitBills = async () => {
    setSubmiting(true);

    if (isDelivery) {
      await api.put('/restaurants/delivery/orders/fill-payments');
    }

    const batchSize = 10;
    let updatedCommands = [...bills];

    for (let i = 0; i < bills.length; i += batchSize) {
      const batch = bills.slice(i, i + batchSize);

      const promises = batch.map(async (bill, j) => {
        const data = { key: bill.key };
        const idx = i + j;

        try {
          const result = await sendCommandToBackend(data);

          updatedCommands[idx] = {
            ...updatedCommands[idx],
            success: result.status_sefaz === '100' ? 'true' : 'false',
            percentual_paid: '100.00',
            ...result,
          };
          setBills(updatedCommands.slice());
        } catch (error) {
          console.error(`Erro na comanda ${idx}:`, error);
        }
      });

      await Promise.all(promises);

      setBills([...updatedCommands]);
    }

    if (isDelivery) {
      try {
        const baskets = updatedCommands
          .filter((c) => c.success === 'true')
          .map((c) => {
            return { buyer_id: c.buyer_id, basket_id: c.order_basket_id };
          });

        if (baskets.length > 0) {
          await api.put('/restaurants/batch/finish-delivery-baskets', {
            baskets,
          });
        }
      } catch (e) {
        console.error('error', e);
      }
    }

    setIsBillsSubmitted(true);
    setSubmiting(false);
  };

  const finishBills = useCallback(async () => {
    try {
      setSubmiting(true);

      if (isDelivery) {
        await api.put('/restaurants/delivery/orders/fill-payments');
      }

      await api.put('/restaurants/complete-paid-table-sessions', null, {
        params: {
          is_delivery: isDelivery,
          start_date: initialDate,
          end_date: finalDate,
        },
      });
      setSubmiting(false);
      setIsBillsSubmitted(true);
      setBillsFinished(true);
    } catch (error) {
      setSubmiting(false);
      toast.error('Erro ao finalizar comandas');
    }
  }, []);

  useEffect(() => {
    getBills();
  }, [getBills]);

  // nfe modal
  const [isNfeModalErrorOpened, setIsNfeModalErrorOpened] = useState(false);
  const [errorNfeData, setErrorNfeData] = useState();

  function toggleModalErrorNfe() {
    setIsNfeModalErrorOpened(!isNfeModalErrorOpened);
  }

  function handleOpenModalNfeError(data) {
    setIsNfeModalErrorOpened(true);
    setErrorNfeData(data);
  }

  function handleOpenModalNfe(item) {
    window.open(
      `https://api.focusnfe.com.br${item?.caminho_danfe}`,
      'janela',
      'width=795, height=590, top=100, left=699, scrollbars=no, status=no, toolbar=no, location=no, menubar=no, resizable=no, fullscreen=no'
    );
  }

  function handleCloseModalAndGetBills() {
    toggleModalFinishBillsInBatch();
    getSessions();
  }

  return (
    <Container>
      <Modal isOpen={isNfeModalErrorOpened} toggle={toggleModalErrorNfe}>
        <ModalBody>
          <Header>
            <FaTimesCircle color="#FF2C3A" size={37} />
            <p>Comanda #{errorNfeData?.id}</p>
            <p>Erro SEFAZ ao finalizar comanda.</p>
          </Header>
          <p>
            {errorNfeData?.response?.mensagem ||
              errorNfeData?.mensagem_sefaz ||
              `Erro na validação do Schema XML, verifique o detalhamento do erro abaixo e corrija em seguida.`}
          </p>

          {errorNfeData?.erros_schema ? (
            <ul>
              {errorNfeData?.erros_schema &&
                errorNfeData?.erros_schema.length > 0 &&
                errorNfeData.erros_schema.map((error) => (
                  <li style={{ listStyle: 'inside', marginTop: 10 }}>
                    <strong>{error?.name}: </strong>
                    {error?.erro}
                  </li>
                ))}
            </ul>
          ) : (
            <p>{errorNfeData?.response?.mensagem}</p>
          )}

          <Footer style={{ justifyContent: 'flex-end' }}>
            <Button
              title="Entendi"
              buttonColor="#2EC9B7"
              onClick={toggleModalErrorNfe}
            />
          </Footer>
        </ModalBody>
      </Modal>
      <h1>Finalizar comandas</h1>
      {!isDelivery && (
        <AdviceArea>
          <FaExclamationTriangle size={27} color="#FFA814" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p>Atenção</p>
            <span>
              Apenas comandas 100% pagas serão listadas. A emissão fiscal é
              limitada a 100 comandas por vez.
            </span>
          </div>
        </AdviceArea>
      )}
      <InnerArea>
        <StyledTable>
          <thead>
            <th>Data/ Hora</th>
            <th>Comanda</th>
            <th>Valor</th>
            <th>Pago</th>
            <th>Status</th>
          </thead>
          <tbody>
            {bills &&
              bills.length > 0 &&
              bills.map((item) => (
                <tr>
                  <td style={{ textAlign: 'center' }}>
                    {formatCompleteDate(item?.created_at)}
                  </td>
                  <td style={{ textAlign: 'center' }}>#{item?.id}</td>
                  <td style={{ textAlign: 'center' }}>
                    {isDelivery
                      ? item?.total_delivery_price
                      : item?.total_service_price}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {Number(item.percentual_paid)}%
                  </td>
                  {user?.has_nfce ? (
                    <td style={{ display: 'flex', justifyContent: 'center' }}>
                      {item.success === 'true' ? (
                        <Button
                          title="Sucesso"
                          buttonColor="#2EC9B7"
                          style={{ width: 118 }}
                          onClick={() => {
                            handleOpenModalNfe(item);
                          }}
                          icon={<BsBoxArrowUpRight />}
                        />
                      ) : item.success === 'false' ? (
                        <Button
                          title="Erro"
                          buttonColor="#FF2C3A"
                          style={{ width: 118 }}
                          onClick={() => {
                            handleOpenModalNfeError(item);
                          }}
                          icon={<BsBoxArrowUpRight />}
                        />
                      ) : submiting ? (
                        <ProgressBar />
                      ) : (
                        'Fechada'
                      )}
                    </td>
                  ) : (
                    <td style={{ display: 'flex', justifyContent: 'center' }}>
                      {billsFinisehd ? (
                        <Button
                          title="Sucesso"
                          buttonColor="#2EC9B7"
                          style={{ width: 118 }}
                        />
                      ) : submiting ? (
                        <ProgressBar />
                      ) : (
                        'Fechada'
                      )}
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </StyledTable>
      </InnerArea>

      <Footer>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            title="Cancelar"
            inverted
            buttonColor="#FF2C3A"
            onClick={toggleModalFinishBillsInBatch}
          />
          {submiting || isBillsSubmitted ? (
            <Button
              title="Concluir"
              buttonColor="#2EC9B7"
              style={{ marginLeft: 8, width: 201 }}
              onClick={handleCloseModalAndGetBills}
              disabled={!isBillsSubmitted}
            />
          ) : (
            <Button
              title="Finalizar comandas"
              buttonColor="#2EC9B7"
              style={{ marginLeft: 8, width: 201 }}
              onClick={user.has_nfce ? handleSubmitBills : finishBills}
              disabled={submiting}
            />
          )}
        </div>
      </Footer>
    </Container>
  );
}

export default FinishBillsInBatch;
