import React, { useEffect, useState, useRef, useCallback } from 'react';
import { DefaultSelect, Checkbox, Button } from 'ui-kit-takeat';
import {
  Container,
  OrderArea,
  Footer,
  FooterContent,
  Body,
  AddClientDiv,
  DisplayNone
} from './styles';
import { FaUserPlus } from 'react-icons/fa';
import { ProductDetails } from './ProductDetails';
import { useCart } from '~/context/OrderingSystem/Cart';
import toast from 'react-hot-toast';
import api from '~/services/api';
import { useBalanceContext } from '~/pages/BalancePage';
import formatValue from '~/utils/formatValue';
import { useAuth } from '~/context/AuthContext';
import { FaPencil } from 'react-icons/fa6';
import ReactToPrint from 'react-to-print';
import { PrintBillsOrderOperation } from '../PrintBillsOrderOperation';

export const BalanceOrderArea = ({ setPrice, setWeight }) => {
  const {
    weightProducts,
    commandTotal,
    command,
    getMenu,
    user,
    setAddClient,
    getTables,
    setWeightProductSelected,
    tables,
    setCommand,
    setTables
  } = useBalanceContext();

  const [loadingOrder, setLoadingOrder] = useState(false);
  const [printOrder, setPrintOrder] = useState(true);
  const [weightOptions, setWeightOption] = useState([{}]);
  const [selectedWeightProduct, setSelectedWeightProduct] = useState({});
  const [printBills, setPrintBills] = useState({});
  const [bills, setBills] = useState({});
  const [products, setProducts] = useState([{}]);
  const [session, setSession] = useState();

  const { increment, decrement, removeFromCart, cart, closeCart, addCartToOrder } = useCart();
  const { buyerCreatedByWaiter } = useAuth();

  const refComponent = useRef();
  const printRef = useRef();

  const getBills = useCallback(async (individualBillId) => {
    const res = await api.get(`restaurants/table-sessions-bills/${individualBillId}`);
    setBills(res.data.bills);
    setPrintBills(res.data.print_bills);
    setProducts(res.data.products);
    setSession(res.data.session);
  }, [command.id]);

  async function handleAddToOrder() {
    if (cart.length <= 0) {
      toast.error('Adicione produtos ao carrinho para finalizar o pedido');
      return;
    }

    setLoadingOrder(true);
    try {
      const res = await addCartToOrder(
        command.id,
        user.id,
        'in_person',
        null,
        null,
        null,
        null,
        true, // isTablePrintable (comentado no código original)
        command.id,
        getMenu,
        null,
        null,
        user.user_id,
        null,
        null,
        null
      );
      if (res.status === 200) {
        closeCart();
      }

      if (res.data.tableBill.id) {
        await getBills(res.data.tableBill.id);
        if (printOrder) {
          await api.post('restaurants/printers/printer-queue', {
            table_session_id: res.data.tableBill.id,
            close_after_print: false,
          });

          const newTables = [...tables]
          const tableFind = newTables.findIndex(it => it.id === command.id)

          if (tableFind !== -1) {
            newTables[tableFind].status = "ongoing"
          }

          const sortedTables = newTables.sort((a, b) => {
            if (a.status === 'ongoing' && b.status === 'available') {
              return 1;
            } else if (a.status === 'available' && b.status === 'ongoing') {
              return -1;
            }
            // Se ambos têm o mesmo status, ordenar pelo table_number
            return a.table_number - b.table_number;
          });


          setTables(sortedTables)

          const nextCommand = sortedTables.find(t => t.status === "available" && t.table_number > Number(command.label))
          if (nextCommand) {

            setCommand({
              id: nextCommand.id,
              label: nextCommand.table_number.toString(),
              status: nextCommand.status,
              tableType: nextCommand.table_type
            });
          }

          setWeight('0')
          setPrice(0)
        }
      }

      setLoadingOrder(false);
      if (!printOrder) {
        printRef.current.handleClick();
      }
      return res;
    } catch (error) {
      console.log(error);
    }
    setLoadingOrder(false);
  }

  function handleAddWeightProducts(e) {
    const selectedProduct = weightProducts.find((prod) => prod.id === e.value);
    setWeightProductSelected(selectedProduct);
    setSelectedWeightProduct(e);
    localStorage.setItem(
      'selectedWeightProduct',
      JSON.stringify(selectedProduct)
    );
  }

  useEffect(() => {
    const optionTeste = [];
    if (weightProducts.length > 0) {
      weightProducts.map((product) => {
        const produto = {
          label: product.name,
          value: product.id,
          price: product.price,
        };
        optionTeste.push(produto);
      });
    }
    setWeightOption(optionTeste);

    const savedProduct = localStorage.getItem('selectedWeightProduct');
    const parseSavedProdut = JSON.parse(savedProduct);

    const savedSelectedProduct = weightProducts?.find((prod) => prod.id === parseSavedProdut?.id)

    if (weightProducts.length > 0 && savedSelectedProduct) {
      setWeightProductSelected(savedSelectedProduct)
    }

  }, [weightProducts]);

  useEffect(() => {
    const savedProduct = localStorage.getItem('selectedWeightProduct');
    const parseSavedProdut = JSON.parse(savedProduct);
    const savedSelectedProduct = weightOptions?.find((prod) => {
      return prod.value === parseSavedProdut?.id;
    });

    setSelectedWeightProduct(savedSelectedProduct);
    if (selectedWeightProduct?.value) {
      setWeightProductSelected(parseSavedProdut);
    }
  }, [weightOptions]);

  useEffect(() => {
    const hasWeightProduct = cart.some((prod) => prod.use_weight === true);
    if (hasWeightProduct) {
      handleAddToOrder();
    }
  }, [cart]);

  return (
    <Container>
      <DefaultSelect
        options={weightOptions}
        containerStyles={{
          display: 'block',
          padding: 16,
          borderBottom: '1px solid #F6F6F6',
        }}
        onChange={(e) => handleAddWeightProducts(e)}
        value={selectedWeightProduct}
      />
      <Body>
        <AddClientDiv>
          {buyerCreatedByWaiter ? (
            <>
              <span id="addClient" onClick={() => setAddClient(true)}>
                {buyerCreatedByWaiter.name} - {buyerCreatedByWaiter.phone}
              </span>
              <FaPencil color="#2ec9b7" />
            </>
          ) : (
            <>
              <span id="addClient" onClick={() => setAddClient(true)}>
                Adicionar Cliente
              </span>
              <FaUserPlus color="#2ec9b7" />
            </>
          )}
        </AddClientDiv>
        <OrderArea>
          <span>Resumo do pedido</span>
        </OrderArea>
        <ProductDetails
          cart={cart}
          increment={increment}
          decrement={decrement}
          removeFromCart={removeFromCart}
        />
      </Body>

      <Footer>
        <Checkbox
          label="Imprimir comanda"
          customColor="#545454"
          labelStyles={{ color: '#545454' }}
          containerStyles={{
            borderBottom: '1px solid #EDEDED',
            borderTop: '1px solid #EDEDED',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            gap: 5,
            padding: '10px 16px',
          }}
          checked={printOrder}
          onClick={() => setPrintOrder(!printOrder)}
        />

        <FooterContent>
          <div>
            <span style={{ fontSize: 16, fontWeight: 600 }}>Total:</span>
            <span style={{ color: '#43AF50', fontSize: 16, fontWeight: 600 }}>
              {formatValue(commandTotal)}
            </span>
          </div>

          <div>
            <Button
              title="Limpar"
              inverted={true}
              onClick={() => closeCart()}
              buttonColor="#c8131b"
              containerStyles={{ borderRadius: 8 }}
              textStyle={{ fontWeight: 600 }}
              isLoading={loadingOrder}
              disabled={loadingOrder}
            />
            <Button
              title="Confirmar"
              buttonColor="#2EC9B7"
              onClick={() => handleAddToOrder()}
              containerStyles={{ flexGrow: 1, borderRadius: 8 }}
              textStyle={{ fontWeight: 600 }}
              isLoading={loadingOrder}
              disabled={loadingOrder}
            />
          </div>
        </FooterContent>
      </Footer>

      <DisplayNone>
        <ReactToPrint
          trigger={() => <React.Fragment />}
          content={() => refComponent.current}
          ref={printRef}
          onAfterPrint={() => {
            const newTables = [...tables]
            const tableFind = newTables.findIndex(it => it.id === command.id)

            if (tableFind !== -1) {
              newTables[tableFind].status = "ongoing"
            }

            const sortedTables = newTables.sort((a, b) => {
              if (a.status === 'ongoing' && b.status === 'available') {
                return 1;
              } else if (a.status === 'available' && b.status === 'ongoing') {
                return -1;
              }
              // Se ambos têm o mesmo status, ordenar pelo table_number
              return a.table_number - b.table_number;
            });


            setTables(sortedTables)

            const nextCommand = sortedTables.find(t => t.status === "available" && Number(t.table_number) > Number(command.label))
            if (nextCommand) {
              setCommand({
                id: nextCommand.id,
                label: nextCommand.table_number.toString(),
                status: nextCommand.status,
                tableType: nextCommand.table_type
              });
            }

            setWeight('0')
            setPrice(0)
          }}
        />
        <PrintBillsOrderOperation
          ref={refComponent}
          printBills={printBills}
          bills={bills}
          products={products}
          session={session}
        />
      </DisplayNone>
    </Container>
  );
};
