import styled from 'styled-components';

import DatePicker from 'react-datepicker';

export const Container = styled.div`
  padding: 20px;
  width: 100%;
  height: calc(100vh - 48px);
`;

export const CostCenterTable = styled.table`
  width: 100%;
  padding: 20px 10px 10px 10px;

  th,
  td:not(:first-child) {
    text-align: center;
  }
`;

export const DataPickerArea = styled.div`
  display: inline-block;
  z-index: 500000;
`;

export const DatePickerStyled = styled(DatePicker)`
  height: 40px;
  padding: 10px;
  margin-right: 15px;
  margin-left: 10px;
  border-radius: 20px;
  align-self: center;
  border: 1px solid ${(props) => props.theme.colors.lightGray};

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 20px;
  }
`;

export const ButtonsSelectDate = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;

  div {
    p {
      padding-left: 15px;
    }
  }
`;

export const ErrorContainer = styled.div`
  width: 60%;
  min-width: 600px;
  margin: 10px auto 30px auto;

  background: orange;
  color: #fff;

  border-radius: 8px;
  padding: 30px;

  p {
    text-align: center;
    font-size: 22px;
  }
`;

export const CCTableHeader = styled.thead`
  position: sticky;
  top: 0;
  padding: 12px 7px;
  border-bottom: none;
  background-color: #ffffff;
  z-index: 1;

  box-shadow: 0px 1px 0px 0px #525f7f;
`;

export const CategoryRow = styled.tr`
  /* background-color: #ff8d72; */
  color: #fb6340;
  cursor: pointer;
  border-bottom: 1px solid lightgrey;
  :hover {
    color: #fff;
    background-color: #fb6340;
  }

  td {
    padding: 12px 7px;
  }
`;

export const EarningRow = styled.tr`
  /* background-color: #2dce89; */
  cursor: pointer;
  border-bottom: 1px solid lightgrey;
  color: #2dce89;
  td {
    padding: 12px 7px;
    font-weight: bold;
  }

  :hover {
    color: #fff;
    background-color: #2dce89;
  }
`;

export const SubCategoryRow = styled.tr`
  cursor: ${(props) => props.clickable && 'pointer'};

  span {
    border-bottom: 1px solid lightgrey;
    padding: 12px 7px;
  }
`;

export const ResultRow = styled.tr`
  /* background-color: #f2ea4b; */
  border-bottom: 1px solid lightgrey;

  td {
    padding: 12px 7px;
    color: #525f7f;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CollapsableTd = styled.td`
  transition: all 0.3s;
  padding: ${(props) => (props.check ? '12px 7px' : '0')};
  border-bottom: ${(props) => (props.check ? '1px solid lightgrey' : 'none')};
`;

export const SelectDateButton = styled.button`
  height: 40px;
  border-radius: 20px;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  color: white;
  align-self: center;
  margin-right: 15px;
  border: none;
  background: #17a2b8;
  margin-left: 10px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
  }

  :selected {
    border: none;
  }
`;

export const DateSelectorDiv = styled.div`
  width: 90%;
  clear: both;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 20px;
  display: flex;
`;

export const DateDiv = styled.div`
  right: 0;
  margin-left: 15px;
`;

export const SubmitDate = styled.button`
  height: 30px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 15px;
  border: none;
  background: #17a2b8;
  margin-left: 10px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
  }
`;
