import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import { Row, Col } from 'reactstrap';
import { Wrapper, Header, Card, Head, CheckboxInfo, Container } from './styles';

import api from '~/services/api';

import Input from '~/components/Form/Input';
import CheckboxInput from '~/components/Form/Checkbox';
import Select from '~/components/Form/SelectInput';

import SaveButton from '~/components/Buttons/SaveButton';
import getValidationErrors from '~/services/getValidationErrors';

function CreateComplementCategory({
  setComplementsCategories,
  complementsCategories,
  toggle,
}) {
  const [complements, setComplements] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const formRef = useRef(null);

  const getComplements = useCallback(async () => {
    const response = await api.get('/restaurants/product/complements');

    const data = response.data.map((complement) => ({
      value: complement.id,
      label: complement.name,
    }));

    setComplements(data);
  }, []);

  useEffect(() => {
    try {
      getComplements();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getComplements]);

  async function handleSubmit(data) {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        limit: Yup.string().required('Máximo obrigatório'),
        minimum: Yup.string().required('Mínimo obrigatório'),
        question: Yup.string().required('Pergunta obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.post(
        'restaurants/product/complement-category',
        {
          name: data.name,
          question: data.question,
          limit: data.limit,
          minimum: Number(data.minimum),
          single_choice: data.single_choice,
          optional: data.optional,
          available: data.available,
          additional: data.additional,
          complements: data.complements,
          more_expensive_only: data.more_expensive_only,
        }
      );
      setSelectedCategory(response.data.id);

      toast.success('Categoria cadastrada com sucesso!');

      setComplementsCategories((state) => {
        return [...state, response.data];
      });

      toggle();
    } catch (error) {
      if (selectedCategory) {
        await api.delete(
          `/restaurants/product/complement-category/${selectedCategory}`
        );
      }

      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else {
        toast.error('Falha ao salvar categoria');
        console.log(error.message);
      }
    }
  }

  return (
    <Wrapper>
      <Header>
        <h2>Cadastrar Categoria de complementos</h2>
      </Header>
      <Card>
        <Form onSubmit={handleSubmit} ref={formRef}>
          <Container>
            <Row>
              <Col sm="12" lg="6">
                <Input
                  label="Nome"
                  name="name"
                  type="text"
                  placeholder="Nome da categoria"
                />
              </Col>
              <Col sm="12" lg="3">
                <Input
                  label="Mínimo"
                  name="minimum"
                  type="number"
                  placeholder="1"
                />
              </Col>
              <Col sm="12" lg="3">
                <Input
                  label="Máximo"
                  name="limit"
                  type="number"
                  placeholder="1"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Input
                  label="Pergunta"
                  name="question"
                  type="text"
                  placeholder="Escolha uma opção de carne, Qual o ponto da carne..."
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                <Row>
                  <Col>
                    <CheckboxInput
                      defaultChecked
                      label="Disponível"
                      name="available"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CheckboxInput
                      defaultChecked
                      label="Disponível no presencial"
                      name="available"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CheckboxInput
                      defaultChecked
                      label="Disponível no delivery"
                      name="available"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CheckboxInput
                      defaultChecked
                      label="Opcional"
                      name="optional"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CheckboxInput
                      defaultChecked
                      label="Escolha única"
                      name="single_choice"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CheckboxInput
                      defaultChecked
                      label="Adicional"
                      name="additional"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" lg="6">
                    <CheckboxInput
                      label="Cobra apenas complemento mais caro"
                      name="more_expensive_only"
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm="12" lg="6">
                <CheckboxInfo>
                  <h4> Legenda</h4>
                  <div>
                    <span>Disponível</span>
                    <p>
                      Se esta categoria está disponível no restaurante no
                      momento
                    </p>
                  </div>
                  <div>
                    <span>Disponível no presencial</span>
                    <p>
                      Se esta categoria está disponível para os clientes
                      presenciais
                    </p>
                  </div>
                  <div>
                    <span>Disponível no delivery</span>
                    <p>
                      Se esta categoria está disponível no cardápio do delivery
                    </p>
                  </div>
                  <div>
                    <span>Opcional</span>
                    <p>O cliente poderá escolher ou não algo nesta categoria</p>
                  </div>
                  <div>
                    <span>Escolha única</span>
                    <p>
                      O cliente poderá escolher somente uma opção nesta
                      categoria
                    </p>
                  </div>
                  <div>
                    <span>Adicional</span>
                    <p>
                      Se marcado, os complementos terão preço adicional. Caso
                      contrário, serão gratúitos
                    </p>
                  </div>
                  <div>
                    <span>Cobra apenas complemento mais caro</span>
                    <p>
                      Se marcado, apenas o complemento mais caro da categoria
                      será cobrado.
                    </p>
                  </div>
                </CheckboxInfo>
              </Col>
            </Row>
            <Head>
              <h3>Associar complementos à categoria</h3>
            </Head>
            <Select
              name="complements"
              isMulti
              options={complements}
              label="Selecione os complementos"
            />
            <SaveButton type="submit">Salvar</SaveButton>
          </Container>
        </Form>
      </Card>
    </Wrapper>
  );
}

export default CreateComplementCategory;
