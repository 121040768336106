import React, { useRef, useState, useCallback } from 'react';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';

import { Row, Col, Container, Button, ButtonGroup } from 'reactstrap';
import { FaQuestionCircle } from 'react-icons/fa';
import { Wrapper, Header, Card, Times, StyledForm } from './styles';

import api from '~/services/api';

import Input from '~/components/Form/Input';
import Checkbox from '~/components/Form/Checkbox';
import SaveButton from '~/components/Buttons/SaveButton';
import { useAuth } from '~/context/AuthContext';

function Store({ toggle, getCategories }) {
  const formRef = useRef(null);
  const { user } = useAuth();

  const [weekDays] = useState([
    'Dom',
    'Seg',
    'Ter',
    'Qua',
    'Qui',
    'Sex',
    'Sáb',
  ]);
  const [selectedDays, setSelectedDays] = useState([0, 1, 2, 3, 4, 5, 6]);

  const onDaySelected = useCallback(
    (selected) => {
      const index = selectedDays.indexOf(selected);

      if (index < 0) {
        selectedDays.push(selected);
      } else {
        selectedDays.splice(index, 1);
      }

      setSelectedDays([...selectedDays]);
    },
    [selectedDays]
  );

  function daysSelectedToStr(selected) {
    const days = [];

    for (let i = 0; i < 7; i++) {
      days.push(selected.includes(i) ? 't' : 'f');
    }

    return days.join('');
  }

  async function handleSubmit(data) {
    try {
      const start_time = data.startTime
        ? `2021-01-09T${data.startTime}:00-03:00`
        : null;
      const end_time = data.endTime
        ? `2021-01-09T${data.endTime}:00-03:00`
        : null;

      const schema = Yup.object().shape({
        name: Yup.string().required('O nome é obrigatório'),
        start_time: Yup.date('Data final inválida').nullable(),
        end_time: Yup.date('Data final inválida').nullable(),
      });

      await schema.validate(
        {
          name: data.name,
          start_time,
          end_time,
        },
        {
          abortEarly: false,
        }
      );

      await api.post('/restaurants/product-category', {
        name: data.name,
        preparation_time: data.time || '0',
        available: data.available,
        available_in_delivery: data.available_in_delivery,
        is_exclusive: data.is_exclusive,
        start_time,
        end_time,
        active_days: daysSelectedToStr(selectedDays),
      });

      getCategories();

      toast.success('Categoria cadastrada com sucesso!');
      toggle();
    } catch (error) {
      if (error.response.data.errorType === 'only_one_time') {
        toast.error(
          `Favor preencher o horário disponível de início e fim das categorias disponíveis.`
        );
      } else {
        toast.error(`Erro ao cadastrar categoria!`);
      }
    }
  }
  return (
    <Wrapper>
      <Header>
        <div>
          <h3>Cadastrar categoria de produtos</h3>
        </div>
      </Header>
      <Container>
        <Row>
          <Col md="6 ml-auto mr-auto">
            <Card>
              <StyledForm ref={formRef} onSubmit={handleSubmit}>
                <Input
                  label="Nome"
                  name="name"
                  type="text"
                  placeholder="Nome da categoria"
                />
                <Input
                  label="Tempo de preparo"
                  name="time"
                  type="number"
                  placeholder="Tempo (em minutos)"
                />

                {!user?.only_delivery && (
                  <Checkbox
                    defaultChecked
                    label="Categoria disponível"
                    name="available"
                  />
                )}

                {user?.delivery_info?.is_delivery_allowed && (
                  <Checkbox
                    defaultChecked
                    label="Categoria disponível no delivery"
                    name="available_in_delivery"
                  />
                )}

                <Checkbox label="Uso exclusivo" name="is_exclusive" />

                <div>
                  <b> Dias: </b>
                  <FaQuestionCircle
                    color="darkOrange"
                    title="Dias em que que essa categoria ficará disponível para o cliente"
                  />
                </div>

                <ButtonGroup style={{ margin: '10px 0' }}>
                  {weekDays.map((item, i) => (
                    <Button
                      color="info"
                      size="sm"
                      tag="label"
                      className="btn-simple"
                      style={{
                        margin: '0 1px',
                        padding: '5px',
                        'font-size': '12pt',
                        filter: selectedDays.includes(i)
                          ? 'opacity(100%)'
                          : 'opacity(40%)',
                      }}
                      onClick={() => onDaySelected(i)}
                      active={selectedDays.includes(i)}
                    >
                      {item}
                    </Button>
                  ))}
                </ButtonGroup>

                <div style={{ marginBottom: -10 }}>
                  <b> Horário disponível: </b>

                  <FaQuestionCircle
                    color="darkOrange"
                    title="Determina a faixa de horário em que essa categoria ficará disponível para o cliente realizar o pedido"
                  />
                </div>

                <Times>
                  <div> das </div>
                  <Input type="time" name="startTime" />
                  <div> às </div>
                  <Input type="time" name="endTime" /> horas.
                </Times>

                <SaveButton
                  style={{ float: 'right', marginTop: '30px' }}
                  type="submit"
                >
                  Cadastrar
                </SaveButton>
              </StyledForm>
            </Card>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Store;
