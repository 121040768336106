import React from 'react';

import { MdAdd } from 'react-icons/md';

import { ButtonContainer } from './styles';

export default function OutlineButton({ text, includeIcon = false, ...rest }) {
  return (
    <ButtonContainer type="button" {...rest}>
      {includeIcon ? <MdAdd color="#FF2C3A" size={16} /> : ''}
      <span>{text || 'CADASTRAR'}</span>
    </ButtonContainer>
  );
}
