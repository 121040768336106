import React, { useCallback, useEffect, useState,useRef } from 'react';
import { Spinner } from 'reactstrap';
import formatCompleteDate from '~/services/formatCompleteDate';
import Image from '~/assets/img/Logo_Takeat.png'
import { useAuth } from '~/context/AuthContext';

import {
  Container,
  Title,
  Restaurant,
  Body,
  Phone,
  Price,
  Footer,
  TimeClosedOrOpen,
  OpenOrCloseBy,
  BoxInformations,

} from './styles';
import api from '~/services/api';

const PrintClosingBoxAuditById = React.forwardRef(
  (
    {
      totalManualDeposits,
      totalAutomaticsDeposit,
      boxTotalValue,
      boxInitialValue,
      totalWithdrawalDeposits,
      boxFinalValue,
      paymentsManual,
      paymentsAutomatics,
      manualDeposits,
      manualWithdrawals,
      boxClosingTime,
      userClose,
      userOpen,
      auditItems,
      modalPrintAudit,
      boxOpeningTime,
      boxId,

    },
    ref
  ) => {
    const { user } = useAuth();
    const filtered = paymentsAutomatics?.find(
      (item) => item.description === 'Dinheiro'
    );

    // const [auditItemsParams, setAuditItemsParams] = useState(auditItems);

    const filteredValue =
      filtered && filtered.length > 0 ? Number(filtered.value) : 0;

    const boxDifference =
      Number(totalWithdrawalDeposits || 0) +
      Number(boxFinalValue || 0) -
      Number(boxInitialValue || 0) +
      filteredValue +
      Number(totalManualDeposits || 0);

    // const boxDifference = boxDifferences > 0 ? boxDifferences : -boxDifferences;

    const finalValueTheory =
      Number(boxInitialValue || 0) +
      Number(totalManualDeposits || 0) -
      Number(totalWithdrawalDeposits || 0) +
      filteredValue;
    // const filteredReady = filtered ? filtered?.value : 0;

    const [cashiers, setCashiers] = useState([]);
    const [totalFinalCashierValue, setTotalFinalCashierValue] = useState(
      '0.00'
    );

    const [
      totalFinalDiferenceCashierValue,
      setTotalFinalDiferenceCashierValue,
    ] = useState('0.00');

    const [totalValue, setTotalValue] = useState('');
    const [totalSystemValue, setTotalSystemValue] = useState('');
    const [loadingCahiers, setLoadingCashers] = useState(false);

    const getCashiers = useCallback(async () => {
      const removeManualValues = (total) => {
        const new_total =
          parseFloat(total) -
          parseFloat(boxInitialValue || 0) -
          parseFloat(totalManualDeposits || 0) +
          parseFloat(totalWithdrawalDeposits || 0);

        return new_total.toFixed(2);
      };

      if (boxId) {
        setLoadingCashers(true);
        const response = await api.get(`/restaurants/cashier-audit/${boxId}`);

        const moneyMethod = response.data?.cashier_audit_items.find((item) => {
          return item.payment_method.name === 'Dinheiro';
        });

        if (moneyMethod) {
          setTotalFinalCashierValue(moneyMethod.value);
          const diferenceFinalValue =
            Number(moneyMethod.value) - Number(moneyMethod.system_value);
          setTotalFinalDiferenceCashierValue(diferenceFinalValue || '0.00');

          moneyMethod.value = removeManualValues(moneyMethod.value);
          moneyMethod.system_value = removeManualValues(
            moneyMethod.system_value
          );
        } else if (response.data?.cashier_audit_items) {
          setTotalFinalCashierValue('0.00');

          response.data.cashier_audit_items.unshift({
            payment_method: { name: 'Dinheiro' },
            value: '0.00',
            system_value: '0.00',
          });
        }

        setCashiers(response.data?.cashier_audit_items);
        setTotalValue(removeManualValues(response.data?.total_value));
        setTotalSystemValue(
          removeManualValues(response.data?.total_system_value)
        );
        setLoadingCashers(false);
      } else {
        setLoadingCashers(true);
        const moneyMethod = auditItems?.cashier_audit_items.find((item) => {
          return item.payment_method.name === 'Dinheiro';
        });

        if (moneyMethod) {
          setTotalFinalCashierValue(moneyMethod.value);

          const diferenceFinalValue =
            Number(moneyMethod.value) - Number(moneyMethod.system_value);
          setTotalFinalDiferenceCashierValue(diferenceFinalValue || '0.00');

          moneyMethod.value = removeManualValues(moneyMethod.value);
          moneyMethod.system_value = removeManualValues(
            moneyMethod.system_value
          );
        } else if (auditItems) {
          setTotalFinalCashierValue('0.00');

          auditItems.cashier_audit_items.unshift({
            payment_method: { name: 'Dinheiro' },
            value: '0.00',
            system_value: '0.00',
          });
        }

        setCashiers(auditItems?.cashier_audit_items);
        setTotalValue(removeManualValues(auditItems?.total_value));
        setTotalSystemValue(removeManualValues(auditItems?.total_system_value));
        setLoadingCashers(false);
      }
    }, [
      boxId,
      boxInitialValue,
      totalWithdrawalDeposits,
      totalManualDeposits,
      auditItems,
    ]);

    useEffect(() => {
      if (modalPrintAudit) {
        getCashiers();
      }
    }, [getCashiers, modalPrintAudit]);

    return (
      <Container ref={ref}>

        <Restaurant>
          <strong>{user.name}</strong>
        </Restaurant>
        
        <Title>
          <strong>FECHAMENTO DE<br/> CAIXA</strong>
        </Title>
        <Body>
        {boxOpeningTime && (
            <div >
              <Phone>
              <strong>Aberto dia: &nbsp;</strong>
              </Phone>

              <Price>
              <strong>
              &nbsp; &nbsp;
                {boxOpeningTime
                  ? formatCompleteDate(boxOpeningTime) 
                  : formatCompleteDate(new Date())}
              </strong> 
              </Price>
            </div>
          )}

          <OpenOrCloseBy >
          Aberto por:
          </OpenOrCloseBy>

          <TimeClosedOrOpen>
          {userOpen || '-'} 
          </TimeClosedOrOpen>
        </Body>

        <Body>
        
        {boxClosingTime && (
            <div style={{ fontSize: 16 }}>
              <Phone>
              <strong>Fechado dia:</strong> 
              </Phone>
              
              <Price>
              <strong>
                {boxClosingTime
                  ? formatCompleteDate(boxClosingTime)
                  : formatCompleteDate(new Date())}
              </strong>
              </Price>
            </div>
          )}
          
          <OpenOrCloseBy>
            Fechado por:
          </OpenOrCloseBy>

          <TimeClosedOrOpen>
            {userClose || '-'}
          </TimeClosedOrOpen>

        </Body>

        <Body>
          <Phone>
            <strong>Abertura (Dinheiro)</strong>
          </Phone>
          <Price>
            <strong>{`R$ ${boxInitialValue || '0.00'} `}</strong>
          </Price>
        </Body>

        {loadingCahiers && (
          <p style={{ marginTop: 10 }}>
            Carregando valores <Spinner />
          </p>
        )}

        {cashiers && cashiers.length > 0 ? (
          <table
            style={{
              width: '110mm',
              borderTop: '1px solid #333',
              paddingLeft: 10,
              paddingBottom: 20,
              marginTop: 20,
            }}
          >
            <thead>
              <th
                style={{
                  paddingLeft: 10,
                  paddingTop: 10,
                  fontSize: 14,
                }}
              >
                Método
              </th>
              <th
                style={{
                  paddingLeft: 10,
                  paddingTop: 10,
                  fontSize: 14,
                }}
              >
                Sistema R$
              </th>
              <th
                style={{
                  paddingLeft: 10,
                  paddingTop: 10,
                  fontSize: 14,
                }}
              >
                Usuário R$
              </th>
              <th
                style={{
                  paddingLeft: 10,
                  paddingTop: 10,
                  fontSize: 14,
                }}
              >
                Dif. R$
              </th>
            </thead>
            <tbody>
              {cashiers
                .filter(
                  (item) =>
                    item.value > 0 || item.system_value > 0
                )
                .map((item) => (
                  <tr>
                    <td
                      style={{
                        paddingLeft: 10,
                        paddingTop: 10,
                        fontSize: 14,
                        maxWidth: '60px',
                      }}
                    >
                      {item.payment_method.name}
                    </td>
                    <td
                      style={{
                        paddingLeft: 10,
                        paddingTop: 10,
                        fontSize: 16,
                      }}
                    >
                      R${parseFloat(item.system_value).toFixed(2)}
                    </td>

                    <td
                      style={{
                        paddingLeft: 10,
                        paddingTop: 10,
                        fontSize: 16,
                      }}
                    >
                      R${parseFloat(item.value).toFixed(2)}
                    </td>

                    <td
                      style={{
                        paddingLeft: 10,
                        paddingTop: 10,
                        fontSize: 16,
                      }}
                    >
                      {(Number(item.value) - Number(item.system_value)).toFixed(2) > 0
                        ? `+R$ ${(
                          Number(item.value) - Number(item.system_value)
                        ).toFixed(2)}`
                        : "R$"+(
                          Number(item.value) - Number(item.system_value)
                         ).toFixed(2)}
                    </td>
                  </tr>
                ))}
              <tr
                style={{
                  borderBottom: '1px solid #333',
                  paddingLeft: 10,
                  paddingBottom: 20,
                  marginTop: 20,
                }}
              >
                <td
                  style={{
                    paddingLeft: 10,
                    paddingTop: 20,
                    fontWeight: 'bold',
                  }}
                >
                  Total no Caixa
                </td>

                <td
                  style={{
                    paddingLeft: 10,
                    paddingTop: 10,
                    fontWeight: 'bold',
                  }}
                >
                  R${totalSystemValue}
                </td>
                <td
                  style={{
                    paddingLeft: 10,
                    paddingTop: 10,
                    fontWeight: 'bold',
                  }}
                >
                  R${totalValue}
                </td>

                <td
                  style={{
                    paddingLeft: 10,
                    paddingTop: 10,
                    fontWeight: 'bold',
                  }}
                >
                  {`R$${(Number(totalValue) - Number(totalSystemValue)).toFixed(2)}` >
                    0
                    ? `+ R$${(
                      Number(totalValue) - Number(totalSystemValue)
                    ).toFixed(2)}`
                    : `R$${(Number(totalValue) - Number(totalSystemValue)).toFixed(2)}`}
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <table
            style={{
              width: '110mm',
              borderTop: '1px solid #333',
              paddingLeft: 10,
              paddingBottom: 20,
              marginTop: 20,
            }}
          >
            <thead>
              <th
                style={{
                  paddingLeft: 10,
                  paddingTop: 10,
                  fontSize: 14,
                }}
              >
                Método
              </th>
              <th
                style={{
                  paddingLeft: 10,
                  paddingTop: 10,
                  fontSize: 14,
                }}
              >
                Sistema R$
              </th>
              <th
                style={{
                  paddingLeft: 10,
                  paddingTop: 10,
                  fontSize: 14,
                }}
              >
                Usuário R$
              </th>
              <th
                style={{
                  paddingLeft: 10,
                  paddingTop: 10,
                  fontSize: 14,
                }}
              >
                Dif. R$
              </th>
            </thead>
            <tbody>
              {paymentsAutomatics
                .filter(
                  (item) =>
                    // item.payment_method.name !== 'Dinheiro' &&
                    item.value > 0
                )
                .map((item) => (
                  <tr>
                    <td
                      style={{
                        paddingLeft: 10,
                        paddingTop: 10,
                        maxWidth: '60px',
                        fontSize: 14,
                      }}
                    >
                      {item.description}
                    </td>
                    <td
                      style={{
                        paddingLeft: 10,
                        paddingTop: 10,
                        fontSize: 16,
                      }}
                    >
                      {`R$${parseFloat(item.value).toFixed(2)}`}
                    </td>

                    <td
                      style={{
                        paddingLeft: 10,
                        paddingTop: 10,
                        fontSize: 16,
                      }}
                    >
                      0
                    </td>

                    <td
                      style={{
                        paddingLeft: 10,
                        paddingTop: 10,
                        fontSize: 16,
                      }}
                    >
                      {`|R$${(Number(item.value) - 0).toFixed(2)}`}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}

        <Restaurant />

        <Body >
          <Phone>
            <strong>Sangrias</strong>
          </Phone>
          <Price>
            <strong>{`R$ ${totalWithdrawalDeposits || '0.00'} `}</strong>
          </Price>
        </Body>

        {manualWithdrawals &&
          manualWithdrawals?.length > 0 &&
          manualWithdrawals.map((item) => (
            <Body>
              <Phone type="description">
                <strong style={{ fontSize: '14px' }}>
                  {`${formatCompleteDate(item.createdAt)} - ${item.description
                    }`}
                </strong>
              </Phone>
              <Price type="description">
                <strong
                  style={{ fontSize: '14px' }}
                >{`R$ ${item.value}`}</strong>
              </Price>
            </Body>
          ))}

        <BoxInformations >
          <Phone>
            <strong>Reforços</strong>
          </Phone>
          <Price>
            <strong>{`R$ ${totalManualDeposits || '0.00'}`}</strong>
          </Price>
        </BoxInformations>
        {manualDeposits &&
          manualDeposits?.length > 0 &&
          manualDeposits.map((item) => (
            <Body>
              <Phone type="description">
                <strong style={{ fontSize: '14px' }}>
                  {`${formatCompleteDate(item.createdAt)} - ${item.description
                    }`}
                </strong>
              </Phone>
              <Price type="description">
                <strong
                  style={{ fontSize: '14px' }}
                >{`R$ ${item.value}`}</strong>
              </Price>
            </Body>
          ))}
        <Restaurant />

        <br />
        <div style={{border: '0.1px solid #333',width:'95%'}} />

        <Body>
          <Phone>
            <strong style={{ fontSize: '16px',paddingBottom:0}}>
              Fundo de caixa final (Dinheiro):
            </strong>
          </Phone>
          <Price>
            <strong style={{ fontSize: '16px',paddingBottom:0 }}>
              {cashiers && cashiers.length > 0
                ? `R$ ${totalFinalCashierValue}`
                : `R$ ${boxFinalValue || "não informado"}`}
            </strong>
          </Price>
        </Body>

        <Body>
          <Phone>
            <strong style={{ fontSize: '16px' }}>
              Diferença de caixa (Dinheiro):
            </strong>
          </Phone>
          <Price>
            {cashiers && cashiers.length > 0 ? (
              <strong style={{ fontSize: '16px' }}>
                {`R$ ${totalFinalDiferenceCashierValue
                  ? Number(totalFinalDiferenceCashierValue).toFixed(2)
                  : '0.00'
                  }`}
              </strong>
            ) : (
              <strong style={{ fontSize: '16px' }}>
                {`R$ ${boxDifference ? boxDifference.toFixed(2) : '0.00'}`}
              </strong>
            )}
          </Price>
        </Body>

        <Footer>
          <strong>Desenvolvido por &nbsp;</strong>
          <img src={Image}></img>
        </Footer>
      </Container>
    );
  }
);

export default PrintClosingBoxAuditById;
