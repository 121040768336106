import {
  FaAddressCard,
  FaBookOpen,
  FaMotorcycle,
  FaPrint,
  FaQuestionCircle,
  FaUtensils,
  FaUserCircle,
} from 'react-icons/fa';
import React from 'react';
import { FaUserTie } from "react-icons/fa";

export const menuItems = (user) => ([
  {
    id: "0",
    text: "Perfil",
    icon: <FaUserCircle />,
    path: "/profile",
    subitems: [
      {
        text: "Editar meu negócio",
        path: "/info",
      },
      {
        text: "Alterar perfil da conta",
        path: "/user-profile",
      },
      {
        text: "Tarefas",
        path: "/tasks",
      },
      {
        text: "Sair",
      }
    ]
  },
  {
    text: 'Operação',
    icon: <FaUtensils />,
    path: '/operation',
    subitems: [
      {
        text: 'Mesa, Balcão e Comandas individuais',
        path: '/',
        condition: !user.only_delivery,
      },
      {
        text: 'Comandas',
        path: '/bills',
        condition: !user.only_delivery,
      },
      {
        text: 'Caixa',
        path: '/opening-box',
        // overridePath: '/financial/opening-box',
      },
      {
        text: 'Histórico de transferência de itens',
        path: '/transfers',
        condition: !user.only_delivery,
      },
      {
        text: 'Notas emitidas',
        path: '/fiscal/issued',
        condition: user.has_nfce,
      },
      {
        text: 'Manifesto de notas',
        path: '/fiscal/manifest',
        condition: user.has_nfce,
      },
      {
        text: 'Entrada de notas',
        path: '/fiscal/entries',
        condition: user.has_nfce,
      },
      {
        text: 'Notas devolvidas',
        path: '/fiscal/returned',
        condition: user.has_nfce,
      },
      {
        text: 'Contas a prazo',
        path: '/credit-register',
        condition: user.credit_register_enabled,
      },
      {
        text: 'Lançamentos',
        path: '/transactions',
      },
      {
        text: 'Transferência de Estoque',
        path: '/transfer-inputs',
        condition: user.is_distribution_center,
      },
      {
        text: 'KDS',
        path: '/kds',
      },
    ],
  },
  {
    text: 'Delivery',
    icon: <FaMotorcycle />,
    path: '/delivery',
    condition:
      user.delivery_info?.is_delivery_allowed ||
      user.delivery_info?.is_withdrawal_allowed,
    subitems: [
      {
        text: 'Pedidos delivery',
        path: '/orders',
      },
      {
        text: 'Caixa',
        path: '/cashier',
        overridePath: '/operation/opening-box',
        condition: user.only_delivery,
      },
      {
        text: 'Comandas',
        path: '/bills',
        overridePath: '/operation/bills',
        condition: user.only_delivery,
      },
      {
        text: 'Gerenciamento',
        path: null,
        subitems: [
          {
            text: 'Motoboys',
            path: '/motoboys',
          },
          {
            text: 'Endereços',
            path: '/addresses',
          },
          {
            text: 'Funcionamento',
            path: '/opening-hours',
          },
          {
            text: 'Agendamento',
            path: '/scheduling',
          },
          {
            text: 'Cupons',
            path: '/coupons',
          },
          {
            text: 'Configurações',
            path: '/config',
          },
        ],
      },
    ],
  },
  {
    text: 'Cardápio',
    icon: <FaBookOpen />,
    path: '/products',
    subitems:
      user.has_ifood || user.has_nfce
        ? [
          {
            text: 'Seus produtos',
            path: '/',
          },
          {
            text: 'Vinculações iFood',
            path: '/ifood',
            condition: user.has_ifood,
          },
          {
            text: 'Dados fiscais',
            path: '/fiscal',
            condition: user.has_nfce,
          },
        ]
        : [],
  },
  {
    text: 'Cadastros',
    icon: <FaAddressCard />,
    path: '/register',
    subitems: [
      {
        text: 'Estoque/insumos\n(Ficha técnica)',
        path: '/stock',
      },
      {
        text: 'Fornecedores',
        path: '/suppliers',
      },

      {
        text: 'Outro cadastros',
        path: null,
        subitems: [
          {
            text: 'Mesas/Comandas',
            path: '/tables',
          },
          {
            text: 'Garçons',
            path: '/waiters',
          },
          {
            text: 'Usuários',
            path: '/users',
          },
          {
            text: 'Maquininhas POS',
            path: '/stone-devices',
            condition: user.has_stone_pos,
          },
          {
            text: 'Balanças',
            path:'/balances',
            condition: user.use_balance
          }
        ],
      },
      {
        text: 'Grupos Fiscais',
        path: '/fiscal-groups',
        condition: user.has_nfce,
      },
    ],
  },
  // {
  //   text: 'Relatórios',
  //   icon: <BsFileBarGraphFill />,
  //   path: '/reports',
  //   isCollapsable: true,
  //   subitems: [
  //     // {
  //     //   text: 'Produtos',
  //     //   path: null,
  //     //   subitems: [
  //     //     {
  //     //       text: 'Cancelados',
  //     //       path: '/products/canceled-products',
  //     //     },
  //     //     {
  //     //       text: 'Descontos',
  //     //       path: '/products/discounts',
  //     //     },
  //     //   ],
  //     // },

  //     // {
  //     //   text: 'DFC',
  //     //   path: '/dfc',
  //     // },

  //     {
  //       text: 'Avançado',
  //       path: null,
  //       external: 'https://gestor.takeat.app/',
  //       externalRef: 'NewManagerTab',
  //       subitems: [
  //         {
  //           text: 'Área do Gestor',
  //           external: 'https://gestor.takeat.app/',
  //           externalRef: 'NewManagerTab',
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   text: 'Financeiro',
  //   icon: <FaCoins />,
  //   path: '/financial',
  //   isCollapsable: true,
  //   subitems: [
  //     {
  //       text: 'Gerenciar Caixa',
  //       path: null,
  //       subitems: [
  //         {
  //           text: 'Caixa',
  //           path: '/opening-box',
  //         },
  //       ],
  //     },

  //     {
  //       text: 'Transferência de Estoque',
  //       path: '/transfer-inputs',
  //       condition: user.is_distribution_center,
  //     },
  //     {
  //       text: 'Avançado',
  //       path: null,
  //       external: 'https://gestor.takeat.app/',
  //       externalRef: 'NewManagerTab',
  //       subitems: [
  //         {
  //           text: 'Área do Gestor',
  //           external: 'https://gestor.takeat.app/',
  //           externalRef: 'NewManagerTab',
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    text: 'Impressoras',
    path: '/settings',
    icon: <FaPrint size={14} />,
    isCollapsable: true,
  },
  {
    text: 'Ajuda',
    path: '/help',
    icon: <FaQuestionCircle />,
    isCollapsable: true,
  },
  {
    text: 'Área do Gestor',
    icon: <FaUserTie />,
    external: 'https://gestor.takeat.app/',
    externalRef: 'NewManagerTab',
    isCollapsable: true,
  },
]);
