import styled from 'styled-components';
import { lighten } from 'polished';

export const EmptyDiv = styled.div`
  padding-top: 20px;
  justify-content: center;
  height: 60px;
  width: 95%;
  text-align: center;
`;

export const SeeButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;

  color: white;
  background: #17a2b8;

  height: 25px;
  width: 160px;
  border: none;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  margin-right: 0px;
  margin-bottom: 3px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
`;

export const StatusTag = styled.div.attrs((props) => ({
  color: props.color,
  backgroundcolor: lighten(0.33, props.color),
}))`
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundcolor};
  font-weight: bold;
  width-max: 120px;
  padding: 5px 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  &::before {
    content: ' ';
    display: inline-block;
    position: relative;
    margin-right: 5px;
    width: 10px;
    height: 10px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
  }
`;
