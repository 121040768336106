import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { lighten } from 'polished';

import { Table } from 'reactstrap';

export const BillExplanation = styled.div`
  width: 90%;
  margin-left: 2%;
  margin-top: 5px;
`;

export const InformationDiv = styled.div`
  width: 90%;
  border-bottom: 1px solid #dee2e6;
  margin-left: 2%;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const FinalDiv = styled.div`
  width: 90%;
  margin-left: 2%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const Total = styled.div`
  display: flex;
  flex-direction: column;
  span {
    color: grey;
  }
`;

export const Paid = styled.div`
  right: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  span {
    color: grey;
  }
`;

export const Container = styled.div`
  height: auto;
  padding-bottom: 170px;

  width: 100%;

  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const CategoriesTable = styled(Table)`
  width: 95%;
  min-width: 400px;
  align-items: center;
  border-collapse: separate;
  border-spacing: 0 20px;
  background: #fff6b3;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  padding: 0 30px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #dee2e6;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;
export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }

  margin-right: 10px;
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div``;

export const StatusTag = styled.div.attrs((props) => ({
  color: props.color,
  backgroundcolor: lighten(0.33, props.color),
}))`
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundcolor};
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  &::before {
    content: ' ';
    display: inline-block;
    position: relative;
    margin-right: 5px;
    width: 10px;
    height: 10px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
  }
`;

export const OpenOrders = styled.div`
  width: 98%;
  padding-bottom: 20px;
  padding-top: 10px;
  margin-left: 2%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const CloseOrders = styled.div`
  width: 100%;
  padding-bottom: 20px;
  padding-top: 10px;

  margin-left: 2%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const PaymentTable = styled.table`
  width: 100%;
  margin-top: 10px;

  tr {
    font-size: 18px;
    height: 30px;
  }

  tbody {
    top: 0;
  }
`;

export const AddPaymentButton = styled(Link).attrs((props) => ({
  color: props.color,
  backgroundcolor: lighten(0.33, props.color),
}))`
  background-color: ${(props) => props.backgroundcolor};
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  border: none;
  width: 60%;
  &:hover {
    text-decoration: none;
  }
  span {
    color: ${(props) => props.color};
  }
`;

export const Print = styled.button`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-self: center;
  float: right;
  position: absolute;
  clear: both;
  right: 0;
  margin-right: 10px;
  border: none;
  background: #2ec4b6;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
    border-radius: 50%;
  }
`;

export const DisplayNoneImprimir = styled.div`
  display: none;
`;

export const Bill = styled.div`
  padding-top: 5px;
  width: 110mm;
  text-align: center;
  font-size: 25px;
  margin-right: auto;
`;

export const Restaurant = styled.p`
  text-transform: capitalize;
  padding-top: 10px;
  width: 110mm;
  text-align: center;
  font-size: 30px;
  margin-right: auto;
  padding-bottom: 15px;
  border-bottom: solid black 1px;
`;

export const Body = styled.div`
  padding-top: 10px;
  width: 110mm;
  clear: both;
  display: block;
  padding-bottom: 20px;
`;

export const Phone = styled.div`
  text-align: left;
  padding-left: 5px;
  font-size: 18px;
  padding-top: 10px;
  float: left;
`;

export const Price = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 18px;
  padding-top: 10px;
  float: right;
`;

export const Items = styled.div`
  width: 110mm;
  padding-top: 10px;
`;

export const Item = styled.div`
  padding-top: 10px;
  padding-left: 10px;
`;

export const NamePrice = styled.div`
  width: 100%;
  display: flex;
`;

export const ItemName = styled.div`
  font-size: 15px;
  width: 65%;
`;

export const PriceItem = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 15px;
  width: 35%;
  float: right;
`;

export const ComplementsTitle = styled.div`
  font-size: 20px;
`;

export const OrderTime = styled.div`
  width: 100%;
  font-size: 20px;
  padding-left: 10px;
  padding-top: 20px;
  align-self: center;
`;

export const Complements = styled.div`
  font-size: 13px;
  padding-left: 15px;
  width: 75%;
`;

export const CloseButton = styled(Link)`
  height: 25px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 0px;
  border: none;
  background: #dc3545;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 6px;
  width: 100%;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const ReopenButton = styled(Link)`
  height: 30px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 0px;
  border: none;
  background: #17a2b8;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 6px;
  width: 98%;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const PriceComplement = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 13px;
  width: 25%;
  float: right;
`;

export const Obs = styled.div`
  padding-top: 5px;
  font-size: 20px;
`;

export const Footer = styled.div`
  margin-top: 20px;
  padding-top: 10px;
  border-top: solid black 1px;
  font-size: 25px;
  text-align: center;
  width: 110mm;
  padding-bottom: 30px;
`;

export const AlreadyClosed = styled.div`
  margin-top: 10px;
  border-top: solid black 1px;
  padding-bottom: 5px;
  font-size: 25px;
  text-align: center;
  width: 110mm;
`;

export const TotalDiv = styled.div`
  padding-top: 5px;
  margin-top: 20px;
  border-top: solid black 1px;
  padding-bottom: 5px;
  font-size: 25px;
  text-align: center;
  width: 110mm;
  padding-bottom: 15px;
`;

export const TotalRow = styled.div`
  margin-top: 5px;
  width: 110mm;
  clear: both;
  display: block;
`;

export const TotalName = styled.div`
  text-align: left;
  padding-left: 5px;
  font-size: 25px;
  padding-top: 5px;
  float: left;
`;

export const TotalPrice = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 25px;
  padding-top: 5px;
  float: right;
`;

export const TotalName10 = styled.div`
  text-align: left;
  padding-left: 5px;
  font-size: 20px;
  padding-top: 5px;
  float: left;
`;

export const TotalPrice10 = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 20px;
  padding-top: 5px;
  float: right;
`;
