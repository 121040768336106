import React, {
  createContext,
  useMemo,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { toast } from 'react-hot-toast';
import { useAuth } from '../AuthContext';
import api from '~/services/api';

const PosContext = createContext();

const PosProvider = ({ children }) => {
  const { user } = useAuth();

  const [stoneTransactions, setStoneTransactions] = useState([]);

  const getStoneTransactions = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/pos/transactions');

      setStoneTransactions(response.data);
    } catch (err) {
      console.log('getTransactions Error: ', err);
    }
  }, []);

  const createStoneTransactions = useCallback(async ({ stone_device_id, payment_id }) => {
    try {
      await api.post('/restaurants/pos/transactions', {
        stone_device_id,
        payment_id
      });
      getStoneTransactions();
    } catch (err) {
      toast.error(err?.response?.data?.error || 'Erro ao mandar pedido para POS.');
      console.log('getTransactions Error: ', err);
    }
  }, []);

  const createStoneTransactionsBalcony = useCallback(async ({ method, payment_value, payment_balcony_id }) => {
    try {
      await api.post('/restaurants/pos/transactions/balcony', {
        method,
        payment_value,
        payment_balcony_id
      });
      getStoneTransactions();
    } catch (err) {
      toast.error(err?.response?.data?.error || 'Erro ao mandar pedido para POS.');
      console.log('getTransactions Error: ', err);
    }
  }, []);

  const cancelStoneTransactions = useCallback(async ({ payment_id }) => {
    try {
      await api.post('/restaurants/pos/cancel', {
        payment_id
      });
      getStoneTransactions();
    } catch (err) {
      toast.error(err?.response?.data?.error || 'Erro ao cancelar pedido POS.');
      console.log('getTransactions Error: ', err);
    }
  }, []);

  const cancelStoneTransactionsBalcony = useCallback(async ({ payment_balcony_id }) => {
    try {
      await api.post('/restaurants/pos/cancel/balcony', {
        payment_balcony_id
      });
      getStoneTransactions();
    } catch (err) {
      toast.error(err?.response?.data?.error || 'Erro ao cancelar pedido POS.');
      console.log('getTransactions Error: ', err);
    }
  }, []);

  const cancelAllStoneTransactions = useCallback(async () => {
    try {
      await api.post(`/restaurants/pos/cancel/all/${user.stone_device_id}`);
      toast.success('Pagamentos cancelados.');
      getStoneTransactions();
    } catch (err) {
      toast.error(err?.response?.data?.error || 'Erro ao cancelar pedido POS.');
      console.log('getTransactions Error: ', err);
    }
  }, []);

  useEffect(() => {
    if(user?.has_stone_pdv){
      getStoneTransactions();
    }
  }, [getStoneTransactions]);

  const value = useMemo(
    () => ({
      getStoneTransactions,
      stoneTransactions,
      setStoneTransactions,
      createStoneTransactions,
      cancelStoneTransactions,
      createStoneTransactionsBalcony,
      cancelStoneTransactionsBalcony,
      cancelAllStoneTransactions
    }),
    [
      getStoneTransactions, 
      stoneTransactions, 
      setStoneTransactions, 
      createStoneTransactions,
      cancelStoneTransactions,
      createStoneTransactionsBalcony,
      cancelStoneTransactionsBalcony,
      cancelAllStoneTransactions
    ]
  );
  return (
    <PosContext.Provider value={value}>{children}</PosContext.Provider>
  );
};

function usePos() {
  const context = useContext(PosContext);

  if (!context) {
    throw new Error('usePos must be used within an PosProvider');
  }

  return context;
}

export { usePos, PosProvider };
