const csosn = [
  {
    csosn: '101',
    cfop: ['5102', '5929', '6108', '5403', '5405'],
    description: 'Tributada pelo Simples Nacional com permissão de crédito;'
  },
  {
    csosn: '102',
    cfop: ['5101', '5102', '5103', '5104', '5115', '5949', '5152', '5929', '6108', '5403', '5405'],
    description: 'Tributada pelo Simples Nacional sem permissão de crédito;',
  },
  {
    csosn: '500',
    cfop: ['5405', '5949', '5152', '5401', '5929', '6108', '5403'],
    description:
      'CMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação;',
  },
  // {
  //   csosn: '103',
  //   cfop: ['5101', '5102', '5103', '5104', '5115'],
  //   description:
  //     'Isenção do ICMS no Simples Nacional para faixa de receita bruta;',
  // },
  {
    csosn: '300',
    cfop: ['5101', '5102', '5103', '5104', '5115', '5949', '5152', '5929', '6108', '5403', '5405'],
    description: 'Imune;',
  },
  {
    csosn: '400',
    cfop: ['5101', '5102', '5103', '5104', '5115', '5949', '5152', '5929', '6108', '5403', '5405'],
    description: 'Não tributada pelo Simples Nacional;',
  },
  {
    csosn: '900',
    cfop: ['5101', '5102', '5103', '5104', '5115', '5403', '5405'],
    description: 'Outros (a critério da UF);',
  },
  // {
  //   csosn: '00',
  //   cfop: ['5101', '5102', '5103', '5104', '5115', '5949', '5152', '5929'],
  //   description: 'Tributada Integralmente;',
  // },
  {
    csosn: '103',
    cfop: ['5101', '5102', '5103', '5104', '5115', '5949', '5152', '5929', '6108', '5403', '5405'],
    description: 'Isenção do ICMS no Simples Nacional para faixa de receita bruta;',
  },
  {
    csosn: '201',
    cfop: ['5101', '5102', '5103', '5104', '5115', '5949', '5152', '5401', '5929', '6108', '5403', '5405'],
    description: 'Tributada com permissão de crédito e com cobrança do ICMS por substituição tributária;',
  },
  {
    csosn: '203',
    cfop: ['5101', '5102', '5103', '5104', '5115', '5949', '5152', '5401', '5929', '6108', '5403', '5405'],
    description: 'Isenção do ICMS para faixa de receita bruta e com cobrança do ICMS por substituição tributária;',
  }
  // {
  //   csosn: '40',
  //   cfop: [],
  //   description: 'Isenta;',
  // },
  // {
  //   csosn: '41',
  //   cfop: [],
  //   description: 'Não Tributada;',
  // },
  // {
  //   csosn: '60',
  //   cfop: [],
  //   description: 'ICMS cobrado anteriormente por substituição tributária;',
  // },
];

const cst = [
  { cst: '00', label: '00 - Tributada integralmente', cfop: ['5101', '5102', '5103', '5104', '5105', '5115', '5949', '5152'] },
  // { cst: '10', label: '10 - Tributada e com cobrança do ICMS por substituição tributária', cfop: ['5101', '5102', '5103', '5104', '5105', '5115', '5949', '5152'] },
  { cst: '20', label: '20 - Com redução de base de cálculo', cfop: ['5101', '5102', '5103', '5104', '5105', '5115', '5949', '5152'] },
  { cst: '30', label: '30 - Isenta ou não tributada e com cobrança do ICMS por substituição tributária', cfop: ['5101', '5102', '5103', '5104', '5105', '5115', '5949', '5152'] },
  { cst: '40', label: '40 - Isenta', cfop: ['5101', '5102', '5103', '5949', '5152'] },
  { cst: '41', label: '41 - Não tributada', cfop: ['5103', '5949', '5152'] },
  { cst: '50', label: '50 - Suspensão', cfop: ['5103', '5949'] },
  { cst: '51', label: '51 - Diferimento', cfop: ['5101', '5102', '5949'] },
  { cst: '60', label: '60 - ICMS cobrado anteriormente por substituição tributária', cfop: ['5405'] },
  { cst: '70', label: '70 - Com redução de base de cálculo e cobrança do ICMS por substituição tributária', cfop: ['5405'] },
  { cst: '90', label: '90 - Outras (regime Normal).', cfop: ['5103', '5949'] },
  { cst: '720', label: '720', cfop: ['5101', '5102', '5103', '5104', '5105', '5115', '5949', '5152'] },

  // { cst: '101', label: '101 - Tributada pelo Simples Nacional com permissão de crédito.' },
  // { cst: '102', label: '102 - Tributada pelo Simples Nacional sem permissão de crédito.' },
  // { cst: '103', label: '103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta.' },

  // { cst: '201', label: '201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária.' },
  // { cst: '202', label: '202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária.' },
  // { cst: '203', label: '203 - Isenção do ICMS nos Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária.' },

  // { cst: '300', label: '300 - Imune.' },
  // { cst: '400', label: '400 - Não tributada pelo Simples Nacional.' },
  // { cst: '500', label: '500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação.' },


];

const icms_s_t_devolucao = [
  { cst: '00', label: '00 - Tributada integralmente', cfop: ['5101', '5102', '5103', '5104', '5105', '5115', '5949', '5152'] },
  { cst: '10', label: '10 - Tributada e com cobrança do ICMS por substituição tributária', cfop: ['5101', '5102', '5103', '5104', '5105', '5115', '5949', '5152'] },
  { cst: '20', label: '20 - Com redução de base de cálculo', cfop: ['5101', '5102', '5103', '5104', '5105', '5115', '5949', '5152'] },
  { cst: '30', label: '30 - Isenta ou não tributada e com cobrança do ICMS por substituição tributária' },
  { cst: '40', label: '40 - Isenta', cfop: ['5101', '5102', '5103', '5949', '5152'] },
  { cst: '41', label: '41 - Não tributada', cfop: ['5103', '5949', '5152'] },
  { cst: '50', label: '50 - Suspensão', cfop: ['5103', '5949'] },
  { cst: '51', label: '51 - Diferimento', cfop: ['5101', '5102', '5949'] },
  { cst: '60', label: '60 - ICMS cobrado anteriormente por substituição tributária', cfop: ['5405'] },
  { cst: '70', label: '70 - Com redução de base de cálculo e cobrança do ICMS por substituição tributária', cfop: ['5405'] },
  { cst: '90', label: '90 - Outras (regime Normal).', cfop: ['5103', '5949'] },

  { cst: '101', label: '101 - Tributada pelo Simples Nacional com permissão de crédito.' },
  { cst: '102', label: '102 - Tributada pelo Simples Nacional sem permissão de crédito.' },
  { cst: '103', label: '103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta.' },

  { cst: '201', label: '201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária.' },
  { cst: '202', label: '202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária.' },
  { cst: '203', label: '203 - Isenção do ICMS nos Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária.' },

  { cst: '300', label: '300 - Imune.' },
  { cst: '400', label: '400 - Não tributada pelo Simples Nacional.' },
  { cst: '500', label: '500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação.' },
];

const cfopDescription = [
  {
    cfop: '5101',
    description: 'Venda de produção do estabelecimento;',
  },
  {
    cfop: '5102',
    description: 'Venda de mercadoria de terceiros;',
  },
  {
    cfop: '5103',
    description:
      'Venda de produção do estabelecimento, efetuada fora do estabelecimento;',
  },
  {
    cfop: '5104',
    description:
      'Venda de mercadoria adquirida ou recebida de terceiros, efetuada fora do estabelecimento;',
  },
  {
    cfop: '5105',
    description:
      'Venda de prod do estabelecimento que não deva por ele transitar;',
  },
  {
    cfop: '5115',
    description:
      'Venda de mercadoria de terceiros, recebida anteriormente em consignação mercantil;',
  },
  {
    cfop: '5405',
    description:
      'Venda de mercadoria de terceiros, sujeita a ST, como contribuinte substituído;',
  },
  {
    cfop: '5949',
    description:
      'Outra saída de mercadoria ou prestação de serviço não especificado;',
  },
  {
    cfop: '5152',
    description:
      'Transferência de mercadoria adquirida ou recebida de terceiros;',
  },
  {
    cfop: '5401',
    description:
      'Venda de produção do estabelecimento quando o produto esteja sujeito ao regime de substituição tributária;',
  },
  {
    cfop: '5929',
    description:
      'Lançamento efetuado em decorrência de emissão de documento fiscal relativo a operação ou prestação também registrada em equipamento Emissor de Cupom Fiscal - ECF',
  },
  {
    cfop: '6108',
    description: 'Venda de mercadoria adquirida ou recebida de terceiros, destinada a não contribuinte',
  },
  {
    cfop: '1411',
    description: 'Devolução de venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao regime de substituição tributária',
  },
  {
    cfop: '5403',
    description: 'Venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao regime de substituição tributária, na condição de contribuinte substituto',
  },
];

const cfop_options = [
  {
    value: '5101',
    label: '5101 - Venda de produção do estabelecimento;',
  },
  {
    value: '5102',
    label: '5102 - Venda de mercadoria de terceiros;',
  },
  {
    value: '5103',
    label:
      '5103 - Venda de produção do estabelecimento, efetuada fora do estabelecimento;',
  },
  {
    value: '5104',
    label:
      '5104 - Venda de mercadoria adquirida ou recebida de terceiros, efetuada fora do estabelecimento;',
  },
  {
    value: '5115',
    label:
      '5115 - Venda de mercadoria de terceiros, recebida anteriormente em consignação mercantil;',
  },
  {
    value: '5405',
    label:
      '5405 - Venda de mercadoria de terceiros, sujeita a ST, como contribuinte substituído;',
  },
  {
    value: '5949',
    label:
      '5949 - Outra saída de mercadoria ou prestação de serviço não especificado;',
  },
  {
    value: '5401',
    label:
      '5401 - Venda de produção do estabelecimento quando o produto esteja sujeito ao regime de substituição tributária;',
  },
  {
    value: '5929',
    label:
      '5929 - Lançamento efetuado em decorrência de emissão de documento fiscal relativo a operação ou prestação também registrada em equipamento Emissor de Cupom Fiscal - ECF;',
  },
  {
    value: '6108',
    label:
      '6108 - Venda de mercadoria adquirida ou recebida de terceiros, destinada a não contribuinte',
  },
  {
    value: '1411',
    label: '1411 - Devolução de venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao regime de substituição tributária',
  },
  {
    value: '5403',
    label: '5403 - Venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao regime de substituição tributária, na condição de contribuinte substituto',
  },
];


const icms_origem = [
  { value: '0', description: '0 - Nacional' },
  { value: '1', description: '1 - Estrangeira (Importação Direta)' },
  { value: '2', description: '2 - Estrangeira (Adquirida no Mercado Interno)' },
  {
    value: '3',
    description: '3 - Nacional com mais de 40% de conteúdo estrangeiro',
  },
  {
    value: '4',
    description:
      '4 - Nacional produzida através de processos produtivos básicos',
  },
  {
    value: '5',
    description: '5 - Nacional com menos de 40% de conteúdo estrangeiro',
  },
  {
    value: '6',
    description:
      '6 - Estrangeira (Importação Direta) sem produto nacional similar',
  },
  {
    value: '7',
    description:
      '7 - Estrangeira (Adquirida no Mercado Interno) sem produto nacional similar',
  },
];

const base_calculo = [
  { value: '0', description: '0 - Margem de Valor Agregado (%)' },
  { value: '1', description: '1 - Pauta (Valor)' },
  { value: '2', description: '2 - Preço Tabelado Máximo (Valor)' },
  { value: '3', description: '3 - Valor da Operação' },
];

const cofins_options = [
  {
    value: '01',
    label:
      '01 – Operação tributável: base de cálculo = valor da operação (alíquota normal – cumulativo/não cumulativo);',
  },
  {
    value: '02',
    label:
      '02 – Operação tributável: base de cálculo = valor da operação (alíquota diferenciada);',
  },
  {
    value: '03',
    label:
      '03 – Operação tributável: base de cálculo = quantidade vendida × alíquota por unidade de produto;',
  },
  {
    value: '04',
    label: '04 – Operação tributável: tributação monofásica (alíquota zero);',
  },
  {
    value: '05',
    label: '05 – Operação tributável: substituição tributária;',
  },
  { value: '06', label: '06 – Operação tributável: alíquota zero;' },
  { value: '07', label: '07 – Operação isenta da contribuição;' },
  { value: '08', label: '08 – Operação sem incidência da contribuição;' },
  { value: '09', label: '09 – Operação com suspensão da contribuição;' },
  { value: '49', label: '49 – Outras operações de saída;' },
  {
    value: '50',
    label:
      '50 – Operação com direito a crédito: vinculada exclusivamente a receita tributada no mercado interno;',
  },
  {
    value: '51',
    label:
      '51 – Operação com direito a crédito: vinculada exclusivamente a receita não tributada no mercado interno;',
  },
  {
    value: '52',
    label:
      '52 – Operação com direito a crédito: vinculada exclusivamente a receita de exportação;',
  },
  {
    value: '53',
    label:
      '53 – Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno;',
  },
  {
    value: '54',
    label:
      '54 – Operação com direito a crédito: vinculada a receitas tributadas no mercado interno e de exportação;',
  },
  {
    value: '55',
    label:
      '55 – Operação com direito a crédito: vinculada a receitas não-tributadas no mercado interno e de exportação;',
  },
  {
    value: '56',
    label:
      '56 – Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação;',
  },
  {
    value: '60',
    label:
      '60 – Crédito presumido: operação de aquisição vinculada exclusivamente a receita tributada no mercado interno;',
  },
  {
    value: '61',
    label:
      '61 – Crédito presumido: operação de aquisição vinculada exclusivamente a receita não-tributada no mercado interno;',
  },
  {
    value: '62',
    label:
      '62 – Crédito presumido: operação de aquisição vinculada exclusivamente a receita de exportação;',
  },
  {
    value: '63',
    label:
      '63 – Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno;',
  },
  {
    value: '64',
    label:
      '64 – Crédito presumido: operação de aquisição vinculada a receitas tributadas no mercado interno e de exportação;',
  },
  {
    value: '65',
    label:
      '65 – Crédito presumido: operação de aquisição vinculada a receitas não-tributadas no mercado interno e de exportação;',
  },
  {
    value: '66',
    label:
      '66 – Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação;',
  },
  { value: '67', label: '67 – Crédito presumido: outras operações;' },
  { value: '70', label: '70 – Operação de aquisição sem direito a crédito;' },
  { value: '71', label: '71 – Operação de aquisição com isenção;' },
  { value: '72', label: '72 – Operação de aquisição com suspensão;' },
  { value: '73', label: '73 – Operação de aquisição a alíquota zero;' },
  {
    value: '74',
    label: '74 – Operação de aquisição sem incidência da contribuição;',
  },
  {
    value: '75',
    label: '75 – Operação de aquisição por substituição tributária;',
  },
  { value: '98', label: '98 – Outras operações de entrada;' },
  { value: '99', label: '99 – Outras operações;' },
];

const icms_situacao_tributaria = [
  { value: '00', label: '00 - Tributada integralmente' },
  // { value: '10', label: '10 - Tributada e com cobrança do ICMS por substituição tributária' },
  { value: '20', label: '20 - Com redução de base de cálculo' },
  { value: '30', label: '30 - Isenta ou não tributada e com cobrança do ICMS por substituição tributária' },
  { value: '40', label: '40 - Isenta' },
  { value: '41', label: '41 - Não tributada' },
  { value: '50', label: '50 - Suspensão' },
  { value: '51', label: '51 - Diferimento' },
  { value: '60', label: '60 - ICMS cobrado anteriormente por substituição tributária' },
  { value: '70', label: '70 - Com redução de base de cálculo e cobrança do ICMS por substituição tributária' },
  { value: '90', label: '90 - Outras (regime Normal).' },

  // { value: '101', label: '101 - Tributada pelo Simples Nacional com permissão de crédito.' },
  // { value: '102', label: '102 - Tributada pelo Simples Nacional sem permissão de crédito.' },
  // { value: '103', label: '103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta.' },

  // { value: '201', label: '201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária.' },
  // { value: '202', label: '202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária.' },
  // { value: '203', label: '203 - Isenção do ICMS nos Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária.' },

  // { value: '300', label: '300 - Imune.' },
  // { value: '400', label: '400 - Não tributada pelo Simples Nacional.' },
  // { value: '500', label: '500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação.' },
  // { value: '900', label: '900 - Outras (regime Simples Nacional).' },
];

const pis_situacao_tributaria = [
  { value: '01', label: '01 – Operação tributável: base de cálculo = valor da operação (alíquota normal – cumulativo/não cumulativo);' },
  { value: '02', label: '02 – Operação tributável: base de cálculo = valor da operação (alíquota diferenciada);' },
  { value: '03', label: '03 – Operação tributável: base de cálculo = quantidade vendida × alíquota por unidade de produto;' },
  { value: '04', label: '04 – Operação tributável: tributação monofásica (alíquota zero);' },
  { value: '05', label: '05 – Operação tributável: substituição tributária;' },
  { value: '06', label: '06 – Operação tributável: alíquota zero;' },
  { value: '07', label: '07 – Operação isenta da contribuição;' },
  { value: '08', label: '08 – Operação sem incidência da contribuição;' },
  { value: '09', label: '09 – Operação com suspensão da contribuição;' },

  { value: '49', label: '49 – Outras operações de saída;' },
  { value: '50', label: '50 – Operação com direito a crédito: vinculada exclusivamente a receita tributada no mercado interno;' },
  { value: '51', label: '51 – Operação com direito a crédito: vinculada exclusivamente a receita não tributada no mercado interno;' },
  { value: '52', label: '52 – Operação com direito a crédito: vinculada exclusivamente a receita de exportação;' },
  { value: '53', label: '53 – Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno;' },
  { value: '54', label: '54 – Operação com direito a crédito: vinculada a receitas tributadas no mercado interno e de exportação;' },
  { value: '55', label: '55 – Operação com direito a crédito: vinculada a receitas não-tributadas no mercado interno e de exportação;' },
  { value: '56', label: '56 – Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação;' },

  { value: '60', label: '60 – Crédito presumido: operação de aquisição vinculada exclusivamente a receita tributada no mercado interno;' },
  { value: '61', label: '61 – Crédito presumido: operação de aquisição vinculada exclusivamente a receita não-tributada no mercado interno;' },
  { value: '62', label: '62 – Crédito presumido: operação de aquisição vinculada exclusivamente a receita de exportação;' },
  { value: '63', label: '63 – Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno;' },
  { value: '64', label: '64 – Crédito presumido: operação de aquisição vinculada a receitas tributadas no mercado interno e de exportação;' },
  { value: '65', label: '65 – Crédito presumido: operação de aquisição vinculada a receitas não-tributadas no mercado interno e de exportação;' },
  { value: '66', label: '66 – Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação;' },
  { value: '67', label: '67 – Crédito presumido: outras operações;' },

  { value: '70', label: '70 – Operação de aquisição sem direito a crédito;' },
  { value: '71', label: '71 – Operação de aquisição com isenção;' },
  { value: '72', label: '72 – Operação de aquisição com suspensão;' },
  { value: '73', label: '73 – Operação de aquisição a alíquota zero;' },
  { value: '74', label: '74 – Operação de aquisição sem incidência da contribuição;' },
  { value: '75', label: '75 – Operação de aquisição por substituição tributária;' },

  { value: '98', label: '98 – Outras operações de entrada;' },
  { value: '99', label: '99 – Outras operações;' },
];

const icms_motivo_desoneracao = [
  { value: '1', label: '1 – Táxi;' },
  { value: '3', label: '3 – Produtor Agropecuário;' },
  { value: '4', label: '4 – Frotista/Locadora;' },
  { value: '5', label: '5 – Diplomático/Consular;' },
  { value: '6', label: '6 – Utilitários e Motocicletas da Amazônia Ocidental e Áreas de Livre Comércio (Resolução 714/88 e 790/94 – CONTRAN e suas alterações);' },
  { value: '7', label: '7 – SUFRAMA;' },
  { value: '8', label: '8 – Venda a Órgãos Públicos;' },
  { value: '9', label: '9 – Outros;' },
  { value: '10', label: '10 – Deficiente Condutor (Convênio ICMS 38/12);' },
  { value: '11', label: '11 – Deficiente Não Condutor (Convênio ICMS 38/12);' },
];


const icms_modalidade_base_calculo = [
  { value: '0', label: '0 – Margem Valor Agregado (%)' },
  { value: '1', label: '1 – Pauta (Valor)' },
  { value: '2', label: '2 – Preço Tabela Máx. (Valor)' },
  { value: '3', label: '3 – Valor da operação' },
];

export default {
  csosn,
  cfopDescription,
  icms_origem,
  base_calculo,
  cofins_options,
  icms_situacao_tributaria,
  pis_situacao_tributaria,
  icms_motivo_desoneracao,
  icms_modalidade_base_calculo,
  cfop_options,
  cst,
  icms_s_t_devolucao
};