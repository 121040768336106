import Push from 'push.js';
import { toast } from 'react-hot-toast';

Push.config({
  serviceWorker: './customServiceWorker.js',
  fallback(payload) {
    toast.success(`${payload.text}`);
  },
});

export default Push;
