/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */

import React, {
  useState, useCallback, useRef, useEffect,
} from 'react';
import { toast } from 'react-hot-toast';

import { MdDelete, MdEdit } from 'react-icons/md';
import { AiOutlinePrinter, AiOutlineEye } from 'react-icons/ai';
import { RiArrowLeftRightLine } from 'react-icons/ri';

import ReactToPrint from 'react-to-print';

import {
  Delete,
  DisplayNoneImprimir,

} from './styles';
import api from '~/services/api';
import PrintRecipe from '~/components/Prints/PrintRecipe';
import IntermediaryRowModal from './IntermediaryRowModal';

function IntermediaryRow({
  intermediary,
  setIntermediary,
  handleDelete,
  inputs,
  intermediaries,
  setIntermediaryFiltered,
  getInputs,
  filterStringInter,
  filterString,
  isInterInventory,
  interInventory,
  setInterInventory,
}) {
  const componentRef = useRef(null);
  const [modals, setModals] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    setModals(!modals);
  };

  const [monthYearDate, setMonthYearDate] = useState(new Date());
  const [historyIntermediary, setHistoryIntermediary] = useState(null);
  const [modalEye, setModalEye] = useState(false);
  const toggleEye = useCallback(() => {
    setModalEye(!modalEye);
    setModals(!modals);
  }, [modalEye]);

  const [modalUnit, setModalUnit] = useState(false);
  const toggleUnit = () => {
    setModalUnit(!modalUnit);
    setModals(!modals);
  };

  const [selecteInterInput, setSelectedInterInput] = useState(null);
  const [selectedInterIntermediaryInput, setSelectedInterIntermediaryInput] = useState(null);

  // const unidade_options = [
  //   {
  //     value: 'un',
  //     label: 'Unidade',
  //   },
  //   {
  //     value: 'kg',
  //     label: 'Kg',
  //   },
  //   {
  //     value: 'l',
  //     label: 'Litro',
  //   },
  // ];

  let unidade_default;
  if (intermediary.unidade === 'kg') {
    unidade_default = { value: 'kg', label: 'Kg' };
  } else {
    unidade_default = { value: 'un', label: 'Unidade' };
  }

  const [quantidadeUpdateInterInp, setQuantidadeUpdateInterInp] = useState(null);

  useEffect(() => {
    if (modalEye) {
      api
        .get('/restaurants/history-intermediaries', {
          params: {
            intermediary_id: intermediary?.id || 0,
            start_date: monthYearDate?.start || new Date(),
          },
        })
        .then((response) => setHistoryIntermediary(response.data))
        .catch((e) => toast.error('Não foi possível consultar o histórico.'));
    }
  }, [modalEye, monthYearDate]);

  useEffect(() => {
    setQuantidadeUpdateInterInp(
      (
        parseFloat(selecteInterInput?.quantidade)
        * parseFloat(intermediary.yield)
      ).toFixed(3),
    );
  }, [selecteInterInput]);

  useEffect(() => {
    setQuantidadeUpdateInterInp(
      (
        parseFloat(selectedInterIntermediaryInput?.quantidade)
        * parseFloat(intermediary.yield)
      ).toFixed(3),
    );
  }, [selectedInterIntermediaryInput]);

  return (
    <>
      <tr>
        <td>{intermediary.name}</td>

        {isInterInventory ? (
          <td>
            <input
              defaultValue={parseFloat(intermediary.quantidade)}
              value={interInventory[intermediary.id]}
              onChange={(e) => setInterInventory({
                ...interInventory,
                [intermediary.id]: e.target.value,
              })}
              style={{ width: '75px', textAlign: 'center' }}
            />
          </td>
        ) : parseFloat(intermediary.quantidade) > 0 ? (
          <td>
            <strong style={{ color: 'green' }}>
              {parseFloat(intermediary.quantidade).toFixed(4).replace('.', ',')}
              {' '}
              {intermediary.unidade}
            </strong>
            <button
              type="button"
              style={{
                border: 'none',
                borderRadius: '50%',
                width: 20,
                height: 20,
                background: 'orange',
                marginLeft: 5,
              }}
              onClick={() => toggleUnit()}
            >
              <RiArrowLeftRightLine size={10} color="white" />
            </button>
          </td>
        ) : (
          <td>
            <strong style={{ color: 'red' }}>
              {parseFloat(intermediary.quantidade).toFixed(4).replace('.', ',')}
              {' '}
              {intermediary.unidade}
            </strong>
            <button
              type="button"
              style={{
                border: 'none',
                borderRadius: '50%',
                width: 20,
                height: 20,
                background: 'orange',
                marginLeft: 5,
              }}
              onClick={() => toggleUnit()}
            >
              <RiArrowLeftRightLine size={10} color="white" />
            </button>
          </td>
        )}

        <td>
          R$
          {parseFloat(intermediary.unitary_price).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
          })}
        </td>

        <td>
          {parseFloat(intermediary.quantidade) >= 0
            ? `R$
          ${parseFloat(intermediary.total_value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
            })}`
            : 'N/A'}
        </td>

        <td>
          {intermediary.intermediary_inputs.length
            + intermediary.intermediary_intermediaries.length}
          {' '}
          insumos
        </td>

        <td>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ReactToPrint
              trigger={() => (
                <Delete>
                  <div>
                    <AiOutlinePrinter size={20} style={{ marginRight: 5 }} />
                  </div>
                </Delete>
              )}
              content={() => componentRef.current}
            />
            <Delete onClick={toggleEye}>
              <AiOutlineEye size={20} color="blue" />
            </Delete>
            <Delete onClick={toggle}>
              <MdEdit size={20} color="#17a2b8" />
            </Delete>
            {!intermediary?.is_multistore_child && (
              <Delete
                onClick={() => handleDelete(intermediary.id, filterStringInter)}
              >
                <MdDelete size={20} />
              </Delete>
            )}
          </div>

          <DisplayNoneImprimir>
            <PrintRecipe intermediary={intermediary} ref={componentRef} />
          </DisplayNoneImprimir>
          {}
        </td>
      </tr>
      {modals && (
        <IntermediaryRowModal
          setIntermediary={setIntermediary}
          inputs={inputs}
          intermediaries={intermediaries}
          setIntermediaryFiltered={setIntermediaryFiltered}
          getInputs={getInputs}
          filterString={filterString}
          setMonthYearDate={setMonthYearDate}
          historyIntermediary={historyIntermediary}
          setSelectedInterInput={setSelectedInterInput}
          setSelectedInterIntermediaryInput={setSelectedInterIntermediaryInput}
          quantidadeUpdateInterInp={quantidadeUpdateInterInp}
          intermediary={intermediary}
          selecteInterInput={selecteInterInput}
          setModalUnit={setModalUnit}
          toggle={toggle}
          modal={modal}
          monthYearDate={monthYearDate}
          selectedInterIntermediaryInput={selectedInterIntermediaryInput}
          filterStringInter={filterStringInter}
          setHistoryIntermediary={setHistoryIntermediary}
          modalEye={modalEye}
          toggleEye={toggleEye}
          modalUnit={modalUnit}
          setQuantidadeUpdateInterInp={setQuantidadeUpdateInterInp}
          toggleUnit={toggleUnit}
        />
      )}
    </>
  );
}

export default IntermediaryRow;