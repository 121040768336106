import styled from 'styled-components';
import { darken } from 'polished';

export const ButtonContainer = styled.button`

width: 222px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  margin: 5px 0 0;
  color: #ff403d;
  height: 36px;
  padding: 0 15px;
  font-weight: bold;
  border: 1px solid ${(props) => props.theme.colors.secondary};
  border-radius: 4px;
  font-size: 16px;
  transition: background 0.2s;

  span {
    color: #ff403d;
    margin-left: 6px;
  
  }

  &:hover {
    background: ${(props) => darken(0.2, props.theme.colors.secondary)};
    color: #fff;
  }

  svg {
    margin-right: 6px;
    color: #ff403d;
  }
`;
