/* eslint-disable no-shadow */
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';

import ReactToPrint from 'react-to-print';

import { toast } from 'react-hot-toast';

import JSZip from 'jszip';
import FileSaver from 'file-saver';

import { format, startOfDay, endOfDay, subHours, addHours } from 'date-fns';

import {
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import { Form } from '@unform/web';

import { HiOutlineMail } from 'react-icons/hi';
import { FiDollarSign } from 'react-icons/fi';

import {
  Wrapper,
  Header,
  Card,
  Content,
  DisplayNoneImprimir,
  ButtonsArea,
  Progress2,
  ButtonsDiv,
} from './styles';

import Nfces from '~/components/Nfces';
import Select from '~/components/Form/SelectInput';
import PrintPaymentReportFiscal from '~/components/Prints/PrintPaymentReportFiscal';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import NfeAvulsa from '~/components/NfesEntry/NfeAvulsa';
import NfeInutilizacao from '~/components/NfesEntry/NfeInutilizacao';
import InputSimple from '~/components/Form/InputSimple';

import api from '~/services/api';
import {
  Button,
  DefaultInput,
  DownloadButton,
  FullCalendar,
  OrderButton,
  Tabs,
} from 'ui-kit-takeat';
import { FaPlus } from 'react-icons/fa';
import history from '~/services/history';

export default function NFCeHistory() {
  const componentRef = useRef(null);
  const [activeTab, setActiveTab] = useState(0);
  const [emailToSend, setEmailToSend] = useState('');
  const [searchDate, setSearchDate] = useState({
    start: startOfDay(new Date()),
    end: endOfDay(new Date()),
  });

  const defaultInicial = useMemo(() => {
    return format(
      startOfDay(subHours(new Date(), 27)),
      "yyyy-MM-dd'T'HH:mm:ss"
    );
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const inicialFormat = format(new Date(defaultInicial), 'dd/MM/yyyy, HH:mm');
  const finalFormat = format(new Date(defaultFinal), 'dd/MM/yyyy, HH:mm');
  const phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [hourPhrase, setHourPhrase] = useState(phrase);
  const [nfces, setNfces] = useState([]);
  const [payments, setPayments] = useState([]);
  const [nfcesFiltered, setNfcesFiltered] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');

  const setFormattedPhrase = (date) => {
    const inicialFormat = format(new Date(date.start), 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(new Date(date.end), 'dd/MM/yyyy, HH:mm');
    setHourPhrase(`De ${inicialFormat} até ${finalFormat}`);
  };

  const getAmount = (filter) => {
    return nfcesFiltered.filter((nfce) => nfce.status === filter).length;
  };

  const getNfces = useCallback(async () => {
    try {
      let response = await api.get('restaurants/nfces', {
        params: {
          start_date: `${format(
            addHours(new Date(searchDate.start), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(searchDate.end), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });
      let sortedNfces = response?.data?.nfces?.sort((a, b) => b.numero - a.numero);
      setNfces(sortedNfces || response.data.nfces);
      setNfcesFiltered(sortedNfces || response.data.nfces);
      setPayments(response.data?.payments || []);
      setSearch('');
    } catch (err) {
      console.log(err);
      toast.error('Erro ao carregar informações');
    }
  }, [searchDate, setSearch]);

  useEffect(() => {
    getNfces();
    setLoading(false);
  }, [getNfces]);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [typeSelected, setTypeSelected] = useState('both');
  const options_nf = [
    { value: 'both', label: 'NFCe e NFe' },
    { value: 'nfce', label: 'NFCe' },
    { value: 'nfe', label: 'NFe' },
  ];

  const default_nf = [{ value: 'both', label: 'NFCe e NFe' }];

  useEffect(() => {
    if (typeSelected === 'nfce') {
      const newNfces = nfces.filter((nfce) => nfce.type === typeSelected);
      setNfcesFiltered(newNfces);
    }
    else if (typeSelected === 'nfe') {
      const newNfces = nfces.filter((nfce) => nfce.type === typeSelected || nfce.type === 'nfe-avulsa');
      setNfcesFiltered(newNfces);
    }
    else {
      setNfcesFiltered(nfces);
    }
  }, [typeSelected, nfces]);

  const searchTimer = useRef(0);

  function handleSearchChange(event) {
    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
    }

    const value = event.target.value;

    searchTimer.current = setTimeout(() => {
      setSearch(value ? value.trim() : '');

      searchTimer.current = 0;
    }, 200);
  }

  useEffect(() => {
    if (search !== '') {
      // numero, ref, total_price
      const newNfces = nfces.filter((nfce) => {
        return (
          nfce.ref.includes(search) ||
          nfce.numero.toString() === search ||
          // parseFloat(nfce.total_price) === parseFloat(search.replace(',', '.'))
          nfce.total_price.includes(search) // essa mudança e pra ir pegando as notcas conforme o valor vai sendo digitado,o metodo anterior precisava digitar o valor todo da nota
        );
      });

      setNfcesFiltered(newNfces);
    } else {
      setNfcesFiltered(nfces);
    }
  }, [search, nfces]);

  useEffect(() => {
    setFormattedPhrase(searchDate);
  }, [searchDate]);

  const [modal, setModal] = useState(false);
  async function toggleEmail() {
    setModal(!modal);
  }

  const sendXMLEmail = useCallback(
    async (data) => {
      try {
        await api.post('restaurants/nfces-sent-email', {
          email: data.email,
          start_date: `${format(
            addHours(new Date(searchDate.start), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(searchDate.end), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          nfce_type: typeSelected,
        });
        toast.success('E-mail enviado com sucesso!');
        document.getElementById('close_modal').click();
      } catch (err) {
        toast.error('Não foi possível enviar o e-mail;');
      }
    },
    [searchDate, typeSelected]
  );

  function partition(array, n = 5) {
    const array2 = [...array];
    const pieces = [];

    while (array2.length > 0) {
      pieces.push(array2.splice(0, n));
    }

    return pieces;
  }

  const terminateDownload = useRef(false);
  const [zipDownloadProgress, setZipDownloadProgress] = useState(0);

  const downloadZip = useCallback(async () => {
    if (nfces.length === 0) {
      terminateDownload.current = true;
      setZipModal(false);
      return;
    }

    const downloadBatch = async (partitions) => {
      let files = [];

      for (const p of partitions) {
        let remainingAttempts = 5;

        while (remainingAttempts > 0) {
          if (terminateDownload.current) {
            return null;
          }

          console.log('@@ attempt', 6 - remainingAttempts);

          try {
            const { data } = await api.post('/restaurants/nfce/xmls/download', {
              xmls: p,
            });

            setZipDownloadProgress((v) => v + data.length);

            files = files.concat(data.map((item) => item.data));

            remainingAttempts = -1; // Condição de parada com sucesso
          } catch (err) {
            remainingAttempts -= 1;
          }
        }

        // Nenhuma tentativa de download foi bem sucedida
        if (remainingAttempts === 0) {
          toast.error('Estão ocorrendo perdas de conexão. Tente novamente.');

          terminateDownload.current = true;
          setZipModal(false);

          return null;
        }
      }

      return files;
    };

    const autorizados = nfces.filter((nfce) => nfce.status === 'autorizado');
    const cancelados = nfces.filter((nfce) => nfce.status === 'canceled');

    // Downloading by batches of 15
    const partitionSize = 15;

    const xmls = autorizados.map((n) => n.nfce_xml);

    const files = await downloadBatch(
      partition(xmls, partitionSize)
    );

    if (files === null) {
      return;
    }

    const xmlsCanceladasAutorizacao = cancelados.map((n) => n.nfce_xml);

    const filesCanceladasAutorizacao = await downloadBatch(
      partition(xmlsCanceladasAutorizacao, partitionSize)
    );

    if (filesCanceladasAutorizacao === null) {
      return;
    }

    const xmlsCanceladasCancelamento = cancelados
      .filter((n) => n.nfce_xml_cancelamento !== null)
      .map((n) => n.nfce_xml_cancelamento);

    const filesCanceladasCancelamento = await downloadBatch(
      partition(xmlsCanceladasCancelamento, partitionSize)
    );

    if (filesCanceladasCancelamento === null) {
      return;
    }

    const zip = new JSZip();

    files.forEach((blob, i) => {
      const [name] = xmls[i].split('/').slice(-1);

      // Blob pode não exitir se ocorreu alguma falha ao baixar o xml no backend
      if (blob) {
        zip.folder('Autorizadas').file(name, blob, { base64: false });
      }
    });

    filesCanceladasAutorizacao.forEach((blob, i) => {
      const [name] = xmlsCanceladasAutorizacao[i].split('/').slice(-1);

      if (blob) {
        zip
          .folder('Canceladas - XML de Autorização')
          .file(name, blob, { base64: false });
      }
    });

    filesCanceladasCancelamento.forEach((blob, i) => {
      const [name] = xmlsCanceladasCancelamento[i].split('/').slice(-1);

      if (blob) {
        zip
          .folder('Canceladas - XML de Cancelamento')
          .file(name, blob, { base64: false });
      }
    });

    const fileName = `Notas Fiscais.zip`;
    const zipFile = await zip.generateAsync({ type: 'blob' });

    FileSaver.saveAs(zipFile, fileName);

    terminateDownload.current = true;
    setZipModal(false);
  }, [nfces, setZipDownloadProgress]);

  const [zipModal, setZipModal] = useState(false);
  const [zipXMLsCount, setZipXMLsCount] = useState(0);

  function toggleZip() {
    if (!zipModal) {
      // Fazendo a contagem de xmls para incluir os xmls como nota de cancelamento
      if (nfces) {
        let count = nfces.length;

        for (const nfce of nfces) {
          if (nfce.status === 'canceled' && nfce.nfce_xml_cancelamento) {
            count += 1;
          }
        }

        setZipXMLsCount(count);
      } else {
        setZipXMLsCount(0);
      }

      terminateDownload.current = false;
      setZipDownloadProgress(0);
      downloadZip();
    } else {
      terminateDownload.current = true;
      setZipXMLsCount(0);
    }

    setZipModal(!zipModal);
  }

  const [permission, setPermission] = useState();

  const [modalAvulsa, setModalAvulsa] = useState(false);
  const toggleModalAvulsa = () => setModalAvulsa(!modalAvulsa);

  const [modalInutilizacao, setModalInutilizacao] = useState(false);
  const toggleModalInutilizacao = () =>
    setModalInutilizacao(!modalInutilizacao);

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'FiscalEmittedNF'}`
      );

      const { can_read } = response.data.FiscalEmittedNF;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);
  console.log(nfcesFiltered)
  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Wrapper>
      <Header>
        <span style={{ display: 'flex', flexDirection: 'column' }}>
          <p>
            Aqui você acompanha o histórico de NFCe emitidas pelo seu
            restaurante.
          </p>

          <strong>
            A NFCe só pode ser cancelada 30 minutos após a sua criação.
          </strong>
        </span>
        <ButtonsDiv>
          <span style={{ display: 'flex', gap: 5 }}>
            <Button
              containerStyles={{ height: window.innerWidth < 1370 ? 36 : 49 }}
              // onClick={toggleModalAvulsa}
              onClick={() => history.push('nfe-avulsa')}
              title="Nota"
              icon={<FaPlus />}
            />
            <Button
              containerStyles={{ height: window.innerWidth < 1370 ? 36 : 49 }}
              onClick={toggleModalInutilizacao}
              title="Inutilização"
              icon={<FaPlus />}
            />
          </span>
          <FullCalendar value={searchDate} onDateSelected={setSearchDate} />
        </ButtonsDiv>
      </Header>
      <Tabs
        tabs={['Autorizadas', 'Canceladas']}
        counter={[getAmount('autorizado'), getAmount('canceled')]}
        selected={activeTab}
        onChangeTab={setActiveTab}
      />
      <Card firstTab={activeTab === 0}>
        <Content>
          <div
            style={{ display: 'flex', gap: 5, justifyContent: 'space-between' }}
          >
            <Form style={{ minWidth: 135 }}>
              <Select
                name="type_nf"
                options={options_nf}
                defaultValue={default_nf}
                label="Tipo:"
                onChange={(e) => {
                  console.log('e', e)
                  setTypeSelected(e.value)
                }}
              />
            </Form>

            <Form style={{ width: window.innerWidth < 1120 && 150 }}>
              <InputSimple
                name="search"
                label="Pesquise:"
                type="text"
                placeholder="ref, número, valor"
                style={{ height: '38px' }}
                onChange={handleSearchChange}
              />
            </Form>
            <ButtonsArea>
              <DownloadButton
                onClick={toggleZip}
                text="Baixar XMLs"
                fixed="open"
                buttonWidth={90}
              />

              <DownloadButton
                onClick={toggleEmail}
                text="XML por E-mail"
                buttonWidth={115}
                icon={<HiOutlineMail size={14} />}
                fixed="open"
              />

              <ReactToPrint
                trigger={() => (
                  <OrderButton
                    title="Pagamentos"
                    icon={<FiDollarSign />}
                    color="81cc66"
                  />
                )}
                content={() => componentRef.current}
              />
            </ButtonsArea>
          </div>

          <DisplayNoneImprimir>
            <PrintPaymentReportFiscal
              items={payments}
              ref={componentRef}
              phrase={hourPhrase}
            />
          </DisplayNoneImprimir>

          <Row />

          <Nfces
            nfces={nfcesFiltered}
            loading={loading}
            getNfces={getNfces}
            activeTab={activeTab}
          />
        </Content>
      </Card>

      <Modal isOpen={modal} toggle={toggleEmail}>
        <Form onSubmit={() => { }}>
          <ModalHeader style={{ padding: '20px 20px 0' }}>
            <h4 style={{ margin: 0 }}>Enviar arquivos XML por E-mail</h4>
          </ModalHeader>
          <ModalBody style={{ padding: '10px 20px' }}>
            <DefaultInput
              value={emailToSend}
              onChange={(e) => setEmailToSend(e.target.value)}
              placeholder="E-mail"
              name="email"
            />
            {/* <InputSimple id="email" label="E-mail" type="email" name="email" /> */}
            <p>Será enviado os arquivos XML do período selecionado.</p>
          </ModalBody>
          <ModalFooter style={{ padding: '0 20px 20px' }}>
            <Button onClick={toggleEmail} id="close_modal" title="Cancelar" />
            <Button
              title="Enviar E-mail"
              buttonColor="#2ec9b7"
              onClick={() => sendXMLEmail({ email: emailToSend })}
            />
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={zipModal} toggle={toggleZip}>
        <Form onSubmit={() => { }}>
          <ModalHeader>
            <h4>Baixando XMLs</h4>
            <p>
              O zip será salvo automaticamente assim que o download dos XMLs
              concluir.
            </p>
            <p>
              Será enviado os XML das notas <strong>Autorizadas</strong>,{' '}
              <strong>Canceladas - XML de Autorização</strong> e{' '}
              <strong>Canceladas - XML de Cancelamento</strong>
            </p>
          </ModalHeader>
          <ModalBody>
            <Progress2
              value={zipDownloadProgress}
              max={nfces?.length || 0}
              color="primary"
              animated
            />

            <div style={{ textAlign: 'right' }}>
              {zipDownloadProgress} de {zipXMLsCount}
            </div>
          </ModalBody>
          <ModalFooter style={{ justifyContent: 'end' }}>
            <Button id="close_modal" title="Cancelar" onClick={toggleZip} />
          </ModalFooter>
        </Form>
      </Modal>

      <NfeAvulsa
        modal={modalAvulsa}
        toggle={toggleModalAvulsa}
        getNfces={getNfces}
      />

      <NfeInutilizacao
        modal={modalInutilizacao}
        toggle={toggleModalInutilizacao}
        getNfces={getNfces}
      />
    </Wrapper>
  );
}
