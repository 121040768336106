import isMobile from '~/utils/isMobile';

import { onMessage, firebaseToken, isSupported } from './firebaseInit';

export default class FCMManager {
  constructor() {
    this.restaurant_id = null;
    this.deviceToken = null;
    this.messageCallback = (r) => r;

    this.is_mobile = isMobile();
    this.isSupported = isSupported;


    this.loading = false;

    if (!this.isSupported) {
      return;
    }

    if (this.is_mobile) {
      if ('serviceWorker' in navigator && navigator.serviceWorker) {
        navigator.serviceWorker.ready.then((registration) => {
          // console.log('sw ready', registration);
          navigator.serviceWorker.onmessage = (payload) => {
            // console.log('sw firebase msg', payload);
            const { data } = payload.data;
            const icon = './favicon.png';

            if (data.type === 'new-order') {
              const notificationTitle =
                'Um novo pedido chegou em seu restaurante!';
              const notificationOptions = { body: data.text, icon };
              registration.showNotification(
                notificationTitle,
                notificationOptions
              );
            } else if (data.type === 'notification') {
              const notificationTitle = 'Solicitação de fechamento de conta!!';
              const notificationOptions = { body: data.text, icon };
              registration.showNotification(
                notificationTitle,
                notificationOptions
              );
            }
          };
        });
      }

      return;
    }

    onMessage((payload) => {
      console.log('@@@ firebase msg', payload);

      const { data } = payload;

      let result = null;

      if (data.type === 'new-order') {
        result = {
          title: 'Um novo pedido chegou em seu restaurante! ',
          body: data.text,
          tag: 'new-order',
        };
      } else if (data.type === 'notification') {
        result = {
          title: 'Solicitação de fechamento de conta!!',
          body: data.text,
          tag: 'closing-solicitation',
        };
      } else if (data.type === 'delivery-opened') {
        result = {
          title: 'Delivery Aberto',
          body: data.text,
          tag: 'delivery-opened',
        };

        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      } else if (data.type === 'delivery-closed') {
        result = {
          title: 'Delivery Fechado',
          body: data.text,
          tag: 'delivery-closed',
        };

        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      }

      this.messageCallback(result);
    });
  }

  setOnMessageCallback(callback) {
    if (typeof callback === 'function') {
      this.messageCallback = callback;
    }
  }

  enableFirebase(restaurant_id) {
    this.restaurant_id = restaurant_id;

    if (this.loading) {
      return;
    }

    this.loading = true;

    if (!this.isSupported) {
      console.log('fcm not supported');
      return;
    }

    console.log('enableFirebase');
    if (this.deviceToken === null) {
      firebaseToken()
        .then(async (token) => {
          console.log('Token do firebase', token);
          await this.register(token);

          this.deviceToken = token;
          this.loading = false;
        })
        .catch((err) => {
          console.log('Algo deu errado com o firebase', err?.message);

          this.deviceToken = null;
          this.loading = false;
        });
    }
  }

  async register(token) {
    const url = `${process.env.REACT_APP_API_URL}/public/device/register`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ restaurant_id: this.restaurant_id, token }),
    });

    if (response.ok) {
      console.log('token registered');
    } else {
      console.log('unable to register token');
      this.deviceToken = null;
    }
  }
}
