import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';

import { Modal, ModalFooter, ModalBody, ModalHeader, Spinner } from 'reactstrap';

import { Form } from '@unform/web';

import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import {
  format,
  endOfDay,
  subHours,
  addHours,
  differenceInHours,
  getDay,
  getHours,
} from 'date-fns';

import { FaCircle, FaExclamationTriangle } from 'react-icons/fa';
import { FiCopy, FiX } from 'react-icons/fi';
import { toJpeg } from 'html-to-image';
import ImgToPrint from '~/components/ImgToPrint';
import ButtonDefault from '~/components/Buttons/DefaultButton';
import Input from '~/components/Form/Input';
import formatCompleteDate from '~/services/formatCompleteDate';
import pixLogo from '~/assets/img/pix_logo.png';
// import clubSail from '~/assets/img/promo_club.png';
import {
  Wrapper,
  OperationArea,
  TablesCardArea,
  OrdersCardArea,
  Table,
  SaveButton,
  WppModal,
  ReconnectButton,
  RatingContent,
  RatingHeader,
  RatingBody,
  TablesContainer,
  LiveButton,
  LiveTextContent,
  VideoContainer,
  BalconyLink,
} from './styles';

import { useOrder } from '~/context/orderContext';
import { useClosing } from '~/context/closingContext';
import { useTables } from '~/context/TablesContext';
import { useAuth } from '~/context/AuthContext';
import Textarea from '~/components/Form/Textarea';
import api from '~/services/api';
import OrdersOperation from '~/components/Operation/OrdersOperation';
import TablesOperation from '~/components/Operation/TablesOperation';
import getValidationErrors from '~/services/getValidationErrors';
import PersonalButton from '~/components/Buttons/PersonalButton';
import { useMenu } from '~/context/OrderingSystem/Menu';
import { useWaiterTickets } from '~/context/WaiterTicketContext';

import history from '~/services/history';
import { NotificationPannel } from '~/components/NotificationPannel';
import YouTube from 'react-youtube';
import wppLostConnection from '../../assets/img/wpp_lost_connection.png';
import { Button, Checkbox, Tabs } from 'ui-kit-takeat';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { differenceInMonths } from 'date-fns';
import { NewHeader } from '~/components/NewHeader';

import bannerLive from '~/assets/img/banner_live.png';
import bannerDecret from '~/assets/img/decreto.png';
import bannerGetnet1 from '~/assets/img/Comunicado_Getnet.png';
import bannerGetnet2 from '~/assets/img/Comunicado_Getnet2.png';
import { useWhatsgw } from '~/context/WhatsgwContext';
import { FaX } from 'react-icons/fa6';
import { AnimatePresence } from 'framer-motion/dist/framer-motion';

function Operation() {
  const {
    signOut,
    user,
    token,
    notificationsOpen,
    setNotificationsOpen,
    generalNotifications,
    setData,
    modalNpsCloseTime,
    setModalNpsCloseTime,
    userAlreadyRate,
    setUserAlreadyRate,
    modalOperationClubSale,
    modalLiveBanner4,
    setModalLiveBanner4,
    modalBannerGetnetAlert,
    setModalBannerGetnetAlert,
    modalBannerDecret,
    setModalBannerDecret,
    modalManagerArea,
    setModalManagerArea,
  } = useAuth();

  const warningBalconyStorage = JSON.parse(
    localStorage.getItem('@gddashboard:warningBalcony')
  );

  const { wppConnectedGw, setShowReconnectGw } = useWhatsgw();

  const [minimalSessions, setMinimalSessions] = useState([]);
  const [minimalSessionsOld, setMinimalSessionsOld] = useState([]);
  const [orderBasketId, setOrderBasketId] = useState();
  const [deliveryOpened, setDeliveryOpened] = useState(false);
  const [withdrawalOpened, setWithdrawalOpened] = useState(false);

  // const [notificationsOpen, setNotificationsOpen] = useState(false);
  // const [notifications, setNotifications] = useState([]);
  const notiButtonRef = useRef(null);
  // const [notiIds, setNotiIds] = useState([]);
  const [newsRead, setNewsRead] = useState(
    JSON.parse(localStorage.getItem('@gddashboard:newsRead'))
  );

  const [clubIsLogged, setClubIsLogged] = useState(false);
  const [clubToken, setClubToken] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  const { getMenu } = useMenu();
  const { waiterTickets } = useWaiterTickets();

  const [count, setCount] = useState('');

  const cancelForm = useRef(null);
  const imgRef = useRef(null);

  const downloadPng = useCallback(() => {
    if (imgRef.current === null) {
      return;
    }

    toJpeg(imgRef.current, { cacheBust: true, fontEmbedCSS: false })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'cardapio_digital_takeat.jpg';
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [imgRef]);

  const { tablesAvailable, getTablesAvailable, tableMenu } = useTables();

  const [tablesFilteredSearch, setTablesFilteredSearch] = useState([]);
  const [allTables, setAllTables] = useState([]);
  const [allTablesAvailable, setAllTablesAvailable] = useState(tablesAvailable);

  const [isModalSystemBloquedOpen, setIsModalSystemBloqued] = useState(false);

  const [fromState, setFromState] = useState();
  const [codeCopyed, setCodeCopyed] = useState(false);

  const hasKey = user ? user.has_order_cancel_password : false;

  const [modalCancelOrderIsOpened, setModalCancelOrderIsOpened] =
    useState(false);

  const [wppModal, setWppModal] = useState(true);
  const toggleWppModal = () => {
    setWppModal(!wppModal);
  };

  //modal club sale
  const [isModalNewsClubSaleOpened, setIsModalNewsClubSaleOpened] = useState(
    modalOperationClubSale.toString() !== 'false' && !user.has_clube
  );

  function toggleModalClubSale() {
    setIsModalNewsClubSaleOpened(!isModalNewsClubSaleOpened);
  }
  //

  function toggleModalSystemBloqued() {
    setIsModalSystemBloqued(!isModalSystemBloquedOpen);
  }

  function handleOpenCancelOrderModal(order_basket_id, from) {
    setModalCancelOrderIsOpened(true);
    setOrderBasketId(order_basket_id);
    setFromState(from);
  }

  const toggleModalCancelOrder = useCallback(() => {
    setModalCancelOrderIsOpened(!modalCancelOrderIsOpened);
  }, [modalCancelOrderIsOpened]);

  const orderOperationRef = useRef(null);
  const tablesRef = useRef(null);

  const setInputValueTables = useCallback(() => {
    tablesRef.current.setInput();
  }, []);

  const [modalPix, setModalPix] = useState(false);

  function toggleModalPix() {
    setModalPix(!modalPix);
  }

  const {
    pendingBaskets,
    acceptedBaskets,
    finishedBaskets,
    canceledBaskets,
    loading,
    setLoading,
    getAllOrders,
    setUpdateTime,
    updateTime,
    moveOrderToAccepted,
    moveOrderToFinished,
    moveOrderToCanceled,
    pixToShow,
    allPix,
    getPixPayments,
    ifoodClosedMessage,
    sessionsInCache,
    setSessionsInCache,
  } = useOrder();

  const {
    closingIndividualBills,
    closingTableBills,
    getClosingIndividualBills,
    getClosingTableBills,
    printIndividualBill,
    printTableBill,
    printQueueTable,
  } = useClosing();

  const [localPixToShow, setLocalPixToShow] = useState([]);

  useEffect(() => {
    setIsModalSystemBloqued(!user.is_active);
  }, [user.is_active]);

  function handleOpenWhatsppClubSale() {
    try {
      window.open(
        `https://api.whatsapp.com/send?phone=%22+552799635-0965%22&text=%22*Solicito%20falar%20sobre%20o%20clube*%22`
      );
    } catch (error) {
      console.log(error);
      toast.error('Erro ao abrir suporte');
    }
  }

  const [lastNumber, setLastNumber] = useState('');

  const handleSearchInputTables = useCallback(
    (e) => {
      try {
        const tableNumber = e;

        setLastNumber(e);

        if (minimalSessions.length < 1) {
          setMinimalSessions(minimalSessionsOld);
        }

        const tableFind = allTables.filter((table) => {
          if (table?.table_number?.toString().startsWith(tableNumber)) {
            return table;
          }
          return '';
        });

        const tableOccpuiedFind = allTables.filter((table) => {
          if (table?.table?.table_number?.toString().startsWith(tableNumber)) {
            return table;
          }
          return '';
        });

        const tableAvailableFind = tablesAvailable.filter((table) => {
          if (table?.table_number?.toString().startsWith(tableNumber)) {
            return table;
          }
          return '';
        });

        if (tableAvailableFind.length > 0) {
          setAllTablesAvailable(tableAvailableFind);
        } else {
          setAllTablesAvailable([
            {
              status: 'none',
              id: 'abc124s',
            },
          ]);
        }

        if (tableFind.length > 0 || tableOccpuiedFind.length > 0) {
          setTablesFilteredSearch([...tableOccpuiedFind, ...tableFind]);
        } else {
          setTablesFilteredSearch([
            {
              status: 'none',
              id: 'abc124s',
            },
          ]);
        }

        if (!tableNumber) {
          setTablesFilteredSearch('');
          setAllTablesAvailable('');
        }
      } catch (error) {
        toast.error(`Produto não encontrado!`);
      }
    },
    [allTables, minimalSessions, tablesAvailable, minimalSessionsOld]
  );

  const handlePrintItem = useCallback(
    async (id) => {
      try {
        await api.put(`restaurants/orders/${id}`, {
          order_status: 'accepted',
        });
        moveOrderToAccepted({ order_basket_id: id });
        toast.success('Pedido aceito!');
      } catch (err) {
        toast.error('Não foi possível atualizar o pedido! tente novamente');
      }
    },
    [moveOrderToAccepted]
  );

  const handleItemFinished = useCallback(
    async (id) => {
      try {
        await api.put(`restaurants/orders/${id}`, {
          order_status: 'finished',
        });
        moveOrderToFinished({ order_basket_id: id });
        toast.success('Pedido entregue!');
      } catch (err) {
        toast.error('Não foi possível atualizar o pedido! tente novamente');
      }
    },
    [moveOrderToFinished]
  );

  const [loadingCancelItem, setLoadingCancelItem] = useState(false);

  const handleCancelItemModal = useCallback(
    async (data) => {
      setLoadingCancelItem(true);
      try {
        if (cancelForm?.current) {
          cancelForm.current.setErrors({});
        }

        const schema = Yup.object().shape({
          order_cancel_password: Yup.string().required('senha obrigatória'),
          cancel_reason: Yup.string().required('justicativa obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        await api.put(`restaurants/orders/cancel/${orderBasketId}`, {
          order_cancel_password: data.order_cancel_password,
          cancel_reason: data.cancel_reason,
        });
        moveOrderToCanceled({
          order_basket_id: orderBasketId,
          from: fromState,
        });
        toggleModalCancelOrder();
        handleSearchInputTables(lastNumber);
        toast.success('Pedido cancelado!');
        setLoadingCancelItem(false);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (cancelForm?.current) {
            cancelForm.current.setErrors(errors);
          }
        } else if (
          error.response.data?.err?.errorType ===
          'incorrect_order_cancel_password'
        ) {
          toast.error('senha incorreta');
        } else {
          toast.error('Erro ao cancelar pedido!');
        }
        setLoadingCancelItem(false);
      }
    },
    [
      fromState,
      handleSearchInputTables,
      lastNumber,
      orderBasketId,
      moveOrderToCanceled,
      toggleModalCancelOrder,
      setLoadingCancelItem,
    ]
  );

  const handleCancelItem = useCallback(
    async ({ order_basket_id, from }) => {
      try {
        if (hasKey) {
          handleOpenCancelOrderModal(order_basket_id, from);
          handleSearchInputTables(lastNumber);
        } else if (
          window.confirm('Tem certeza que deseja cancelar este pedido?')
        ) {
          await api.put(`restaurants/orders/cancel/${order_basket_id}`);
          moveOrderToCanceled({ order_basket_id, from });
          toast.success('Pedido cancelado!');
        }
      } catch (err) {
        toast.error('Não foi possível atualizar o pedido! tente novamente');
      }
    },
    [handleSearchInputTables, lastNumber, hasKey, moveOrderToCanceled]
  );

  // Comandas

  const defaultFinal = useMemo(() => {
    return endOfDay(new Date()).toISOString();
  }, []);

  const defaultInicial = useMemo(() => {
    return subHours(new Date(), 12).toISOString();
  }, []);

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [opened, setOpened] = useState(user.opened);

  const [sessions, setSessions] = useState([]);
  const [isModalQrcodeOpened, setIsModalQrcodeOpened] = useState(false);
  const toggleModalQrcode = () => {
    setIsModalQrcodeOpened(!isModalQrcodeOpened);
    setCodeCopyed(false);
  };

  const getMinimalSessions = useCallback(async () => {
    try {
      const final = endOfDay(new Date()).toISOString();
      const inicial = subHours(new Date(), 12).toISOString();

      const response = await api.get('/restaurants/table-sessions-minimal', {
        // params: {
        //   start_date: `${format(
        //     subHours(new Date(inicialDate), 9),
        //     "yyyy-MM-dd'T'HH:mm:ss"
        //   )}`,
        //   end_date: `${finalDate}`,
        // },
        // params: {
        //   start_date: `${inicialDate}`,
        //   end_date: `${finalDate}`,
        // }
        params: {
          start_date: `${inicial}`,
          end_date: `${final}`,
        },
      });

      const sessionsOrder = response.data.sort((a, b) => {
        if (a.table.table_type[0] === b.table.table_type[0]) {
          return a.table.table_number - b.table.table_number;
        }
        return a.table.table_type[0] === 'b' ? -1 : 1;
      });

      // salvando em cache para resumo de comandas
      const openSessions = response.data.filter(
        (data) =>
          (data.status === 'open' || data.status === 'closing') &&
          data.is_delivery === false
      );
      let sessionsInCache = openSessions?.map((session) => {
        const paid = session.payments.reduce(
          (acc, pay) => acc + parseFloat(pay.payment_value),
          0
        );

        // const bills = session?.bills.map((bill) => {
        //   return {
        //     name: bill?.buyer?.name || null,
        //     phone: bill?.buyer?.phone || 'Garçom',
        //     total: bill.total_service_price,
        //   };
        // });

        return {
          id: session.id,
          table_number: session.table.table_number,
          start_time: session.start_time,
          paid,
          bills: [],
          status: session.status,
          total_price: session.total_price,
          total_service_price: session.total_service_price,
        };
      });

      sessionsInCache = sessionsInCache.sort((a, b) => {
        return a.table_number - b.table_number;
      });

      const toCache = {
        updated_at: new Date(),
        sessions: sessionsInCache,
      };

      setSessionsInCache(toCache);
      localStorage.setItem(
        '@gddashboard:sessionsInCache',
        JSON.stringify(toCache)
      );

      setMinimalSessions(sessionsOrder);
      setAllTables(sessionsOrder);
      // getTablesAvailable();
    } catch (err) {
      if (!err?.response?.ok) {
        if (err?.response?.data?.errorType === 'invalid_token') {
          window.location.reload();
          signOut();
        }

        if (err?.response?.data?.errorType === 'token_not_sent') {
          signOut();
        }
      }
      toast.error('Erro ao carregar informações');
    }
  }, [finalDate, inicialDate, signOut]);

  useEffect(() => {
    setAllTablesAvailable(tablesAvailable);
  }, [tablesAvailable]);

  // useEffect(() => {
  //   getSessions();
  // }, [pendingBaskets, acceptedBaskets, getSessions]);

  async function handleUpdate() {
    setLoading(true);
    getMinimalSessions();
    getAllOrders();
    getClosingIndividualBills();
    getClosingTableBills();
    // getPayments();
    setLoading(false);
    getTablesAvailable();
    setAllTablesAvailable('');

    const nowTime = format(new Date(), 'HH:mm');
    setUpdateTime(nowTime);
  }

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const updateOpeninBoxOperation = useCallback(() => {
    orderOperationRef.current.updateOpeninBox();
  }, []);

  async function orderByTableNumber() {
    try {
      const response = await api.get('/restaurants/table-sessions-minimal', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${finalDate}`,
        },
      });

      const sessionsOrder = response.data.sort((a, b) => {
        if (a.table.table_type[0] === b.table.table_type[0]) {
          return a.table.table_number - b.table.table_number;
        }
        return a.table.table_type[0] === 'b' ? -1 : 1;
      });

      setMinimalSessions(sessionsOrder);
    } catch (err) {
      // console.log('setsessions', response.data, inicialDate, finalDate);
      toast.error('Erro ao carregar informações');
    }
  }

  async function orderByTableDate() {
    try {
      const response = await api.get('/restaurants/table-sessions-minimal', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${finalDate}`,
        },
      });

      setMinimalSessions(response.data);
    } catch (err) {
      // console.log('setsessions', response.data, inicialDate, finalDate);
      toast.error('Erro ao carregar informações');
    }
  }

  async function handlePixNotification() {
    setModalPix(false);
    setLocalPixToShow([]);
  }

  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  useEffect(() => {
    const isMenuUpdated = localStorage.getItem('@gddashboard:isMenuUpdated');

    if (isMenuUpdated) {
      if (isMenuUpdated.toString() === 'true') {
        getMenu();

        localStorage.setItem('@gddashboard:isMenuUpdated', false);
      }
    }
  }, [getMenu]);

  useEffect(() => {
    if (pixToShow.length > 0 && user.has_pix) {
      const pixTo = pixToShow?.map((pix) => pix);
      localPixToShow.forEach((pix) => {
        !pixTo.includes(pix) && pixTo.push(pix);
      });
      setLocalPixToShow(pixTo);
      setModalPix(true);
    }
  }, [pixToShow, allPix, user]);

  useEffect(() => {
    setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    if (user?.has_pix) {
      getPixPayments();
    }
  }, [getPixPayments, token]);

  useEffect(() => {
    getMinimalSessions();
  }, [getMinimalSessions, pendingBaskets, acceptedBaskets]);

  useEffect(() => {
    if (!!opened !== user.opened) {
      setOpened(user.opened);
    }

    const { is_delivery_active, is_withdrawal_active } = user?.delivery_info;

    if (!!deliveryOpened !== is_delivery_active) {
      setDeliveryOpened(is_delivery_active);
    }

    if (!!withdrawalOpened !== is_withdrawal_active) {
      setWithdrawalOpened(is_withdrawal_active);
    }
  }, [opened, user.delivery_info, deliveryOpened, withdrawalOpened, user]);

  const inputRef = useRef(null);
  const inputDeliveryRef = useRef(null);

  function copyUrlCode() {
    const textoCopiado = inputRef.current;
    textoCopiado.select();
    textoCopiado.setSelectionRange(0, 99999);
    document.execCommand('copy');

    setCodeCopyed(true);
  }

  function copyDeliveryUrlCode() {
    const textoCopiado = inputDeliveryRef.current;
    textoCopiado.select();
    textoCopiado.setSelectionRange(0, 99999);
    document.execCommand('copy');

    setCodeCopyed(true);
  }

  const tableUrl = tableMenu
    ? `${tableMenu.table_code?.url}?q=${Date.now()}`
    : '';

  function handleOpenInvoices() {
    try {
      history.push('/financial/invoices');
    } catch (error) {
      console.log(error);
      toast.error('Erro ao abrir financeiro');
    }
  }

  async function handleOpenInvoice() {
    try {
      const response = await api.get(`restaurants/invoices/last`);
      window.open(response.data.invoice_url);
    } catch (error) {
      console.log(error);
      history.push('/financial/invoices');
    }
  }

  // const getNotifications = async () => {
  //   const res = await api.get('/restaurants/notifications');

  //   setGeneralNotifications(res.data);
  //   const fixedIds = res.data.fixed?.map((f) => f.id);
  //   const allIds = res.data.all?.map((a) => a.id);
  //   setNotiIds(fixedIds.concat(allIds));
  // };

  const loginClub = async () => {
    const response = await api.get('/restaurants/club');

    if (response.data.token) {
      setClubToken(response.data.token);
      localStorage.setItem('@clube:token', response.data.token);
    }

    if (response.data.user) {
      localStorage.setItem('@clube:user', JSON.stringify(response.data.user));
      setData((state) => {
        return {
          ...state,
          user: {
            ...state.user,
            club_key: response.data.user.key,
          },
        };
      });
    }
    setClubIsLogged(true);
  };

  useEffect(() => {
    if (user.has_clube) {
      if (!clubIsLogged) {
        loginClub();
      }
    }
  }, []);

  // useEffect(() => {
  //   getNotifications();
  // }, []);

  useEffect(() => {
    if (!notificationsOpen) {
      setNewsRead(JSON.parse(localStorage.getItem('@gddashboard:newsRead')));
    }
  }, [notificationsOpen]);

  const [permission, setPermission] = useState(true);

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'OperationCurrentSessions'}`
      );

      const { can_read } = response.data.OperationCurrentSessions;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  const [isModalNpsOpen, setIsModalNpsOpen] = useState(
    differenceInMonths(new Date(), new Date(user.created_at || new Date())) <=
    3 &&
    userAlreadyRate?.toString() !== 'true' &&
    differenceInHours(new Date(), new Date(modalNpsCloseTime)) > 6 &&
    user.table_limit_type === 'only-balcony'
  );
  const [rating, setRating] = useState();
  const [showCommentArea, setShowCommentArea] = useState(false);

  function toggleModalNps() {
    setIsModalNpsOpen(!isModalNpsOpen);
    setModalNpsCloseTime(new Date());
  }

  function handleSetRating(e) {
    setRating(e);
    setShowCommentArea(true);
  }

  async function handleSubmit(data) {
    try {
      await api.post('/restaurants/research-answer', {
        research_id: 101,
        value: rating,
        comment: data.comment || null,
      });

      setUserAlreadyRate(true);
      setIsModalNpsOpen(false);
    } catch (error) {
      toast.error('Erro ao enviar avaliação');
    }
  }

  // function handleSendMessageToTakeat() {
  //   window.open("https://api.whatsapp.com/send?phone=5527996350965&utm_source=banner-upsell&utm_medium=dashboard-takeat&utm_campaign=campanha-upselll")
  // }

  // const [isModalWhatsappOpened, setIsModalWhatsappOpened] = useState(modalOperationMessageWhatsapp.toString() === "true")

  // function toggleWhatsappModal() {
  //   setIsModalWhatsappOpened(!isModalWhatsappOpened)
  // }
  const [isModalBannerGetnetAlertOpened, setIsModalGetnetAlertOpened] = useState(
    modalBannerGetnetAlert.toString() !== 'false'
  );

  const toggleModalBannerGetnetAlert = () => {
    setIsModalGetnetAlertOpened(!isModalBannerGetnetAlertOpened);
  }

  const [getnetBannerTab, setGetnetBannerTab] = useState(0);
  const [getnetCheckbox, setGetnetCheckbox] = useState(false);
  const getnetCnpjList = [
    "04545658000130",
    "13425203000171",
    "19321795000168",
    "21053633000138",
    "28586052000100",
    "32389994000176",
    "33834153000193",
    "37665752000181",
    "41796232000185",
    "42370511000145",
    "42407845000146",
    "42407845000227",
    "43780239000134",
    "43882677000103",
    "47017434000168",
    "47045511000193",
    "50455012000189",
    "52037209000123",
    "52585675000143",
    "52657224000174",
    "52964890000155",
    "53197978000151",
    "53438045000109",
    "53494874000109",
    "54137885000102",
    "32005228000160"
  ]
  const [userIncludesCnpj, setUserIncludesCnpj] = useState(false);
  useEffect(() => {
    if (getnetCnpjList.includes(user.cnpj)) {
      setUserIncludesCnpj(true);
    }
  }, [user]);

  const [isModalLiveBannerOpened4, setIsModalLiveBannerOpened4] = useState(
    modalLiveBanner4.toString() !== 'false'
  );

  function toggleModalLiveBanner4() {
    setIsModalLiveBannerOpened4(!isModalLiveBannerOpened4);
  }

  //banner decret

  function acceptsDdds(phone) {
    if (phone) {
      const ddds = ['65', '66'];

      const ddd = phone.slice(1, 3);

      if (ddds.includes(ddd)) {
        return true;
      } else {
        return false;
      }
    }
  }

  const [isModalBannerDecretOpened, setIsModalBannerDecretOpened] = useState(
    modalBannerDecret.toString() !== 'false' &&
    (user.adress?.state?.toLowerCase() === 'rs' || acceptsDdds(user?.phone))
  );

  function toggleModalBannerDecret() {
    setIsModalBannerDecretOpened(!isModalBannerDecretOpened);
  }

  const [player, setPlayer] = useState(null);
  const [clickHere, setClickHere] = useState(false);

  //live conditional

  const [isLiveOn, setIsLiveOn] = useState(
    ((getDay(new Date()) === 1 || getDay(new Date()) === 3) &&
      getHours(new Date()) >= 15 &&
      getHours(new Date()) < 17) ||
    ((getDay(new Date()) === 2 || getDay(new Date()) === 4) &&
      getHours(new Date()) >= 9 &&
      getHours(new Date()) < 11)
  );

  const [isLiveTextContextOpened, setIsLiveTextContextOpened] = useState(false);

  function toggleLiveTextContext() {
    setIsLiveTextContextOpened(!isLiveTextContextOpened);
  }

  function handleGoToLive() {
    window.open('https://meet.google.com/wqr-yurp-jed');
  }

  const handleGoToBalconyMode = () => {
    const isBalconyOpen = allTables.some((table) => {
      return (
        table.table.table_type === 'balcony' && table.table.status === 'ongoing'
      );
    });
    if (isBalconyOpen) {
      toast.error(
        'Mesa Balcão está aberta no momento. Por favor, feche ela para ir para o Modo Balcão.'
      );
    }
    if (!isBalconyOpen) {
      history.push('/balcony');
    }
  };

  return (
    <>
      <Modal isOpen={isModalNpsOpen} toggle={toggleModalNps} size="lg">
        <Form onSubmit={handleSubmit}>
          <ModalBody>
            <RatingContent>
              <RatingHeader>
                <span
                  style={{ fontSize: 20, fontWeight: 'bold', color: '#424242' }}
                >
                  QUEREMOS A SUA AVALIAÇÃO!
                </span>
                <span
                  style={{ fontSize: 16, color: '#424242', width: '654px' }}
                >
                  Caso você ou algum familiar/amigo venha a abrir um novo
                  estabelecimento, o quanto você estaria disposto a recomendar o
                  uso do sistema Takeat ?
                </span>
              </RatingHeader>
              <RatingBody>
                <div
                  style={{
                    width: '90%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <span
                    style={{
                      fontSize: 14,
                      color: '#424242',
                      fontWeight: 'bold',
                    }}
                  >
                    Não Recomendo
                  </span>
                  <span
                    style={{
                      fontSize: 14,
                      color: '#424242',
                      fontWeight: 'bold',
                    }}
                  >
                    Recomendo
                  </span>
                </div>
                <div
                  style={{
                    width: '90%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <button
                    style={{ background: '#FC1412' }}
                    type="button"
                    onClick={(e) => handleSetRating(e.target.innerText)}
                  >
                    0
                  </button>
                  <button
                    style={{ background: '#FE191C' }}
                    type="button"
                    onClick={(e) => handleSetRating(e.target.innerText)}
                  >
                    1
                  </button>
                  <button
                    style={{ background: '#FE3030' }}
                    type="button"
                    onClick={(e) => handleSetRating(e.target.innerText)}
                  >
                    2
                  </button>
                  <button
                    style={{ background: '#FF8B00' }}
                    type="button"
                    onClick={(e) => handleSetRating(e.target.innerText)}
                  >
                    3
                  </button>
                  <button
                    style={{ background: '#FF9B23' }}
                    type="button"
                    onClick={(e) => handleSetRating(e.target.innerText)}
                  >
                    4
                  </button>
                  <button
                    style={{ background: '#FFCD0C' }}
                    type="button"
                    onClick={(e) => handleSetRating(e.target.innerText)}
                  >
                    5
                  </button>
                  <button
                    style={{ background: '#FEC400' }}
                    type="button"
                    onClick={(e) => handleSetRating(e.target.innerText)}
                  >
                    6
                  </button>
                  <button
                    style={{ background: '#80CD0D' }}
                    type="button"
                    onClick={(e) => handleSetRating(e.target.innerText)}
                  >
                    7
                  </button>
                  <button
                    style={{ background: '#70B407' }}
                    type="button"
                    onClick={(e) => handleSetRating(e.target.innerText)}
                  >
                    8
                  </button>
                  <button
                    style={{ background: '#00C132' }}
                    type="button"
                    onClick={(e) => handleSetRating(e.target.innerText)}
                  >
                    9
                  </button>
                  <button
                    style={{ background: '#009A22' }}
                    type="button"
                    onClick={(e) => handleSetRating(e.target.innerText)}
                  >
                    10
                  </button>
                </div>

                {showCommentArea && (
                  <Textarea
                    name="comment"
                    size
                    placeHolder={
                      rating <= 5
                        ? 'Diga o que podemos melhorar para que você se sinta realmente satisfeito.'
                        : 'Nos dê um feedback para que possamos melhorar ainda mais.'
                    }
                  />
                )}
              </RatingBody>
            </RatingContent>
          </ModalBody>
          <ModalFooter>
            <div
              style={{
                width: '92%',
                marginLeft: 20,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div style={{ display: 'flex' }}>
                <PersonalButton
                  message="Avaliar depois"
                  color="#FF2C3A"
                  style={{ marginRight: 8 }}
                  type="button"
                  onClick={toggleModalNps}
                />
                <PersonalButton
                  message="Enviar"
                  color="#2EC9B7"
                  type="submit"
                  disabled={!rating}
                />
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={isModalBannerDecretOpened}
        toggle={toggleModalBannerDecret}
        style={{ padding: 0 }}
      >
        {/* <ModalHeader style={{ display: 'flex', justifyContent: 'end' }}>
          <FiX
            onClick={toggleModalBannerDecret}
            color="red"
            cursor="pointer"
            size={17}
            title="Fechar Modal"
          />
        </ModalHeader> */}
        <VideoContainer>
          <img
            style={{ width: '100%' }}
            src={bannerDecret}
            alt="Banner mostrando ao usuário que a Takeat está de acordo com o decreto do Mato Grosso"
          />
          <div style={{ position: 'absolute', left: 63, bottom: 85 }}>
            <YouTube
              videoId={'QfjiN2Tp-Rg'}
              onReady={(e) => setPlayer(e.target)}
              onPlay={() => {
                // toggleModalYoutube();
                // setIsHidden(true);
                setClickHere(false);
              }}
              opts={{
                width: 380,
                height: 200,
                playerVars: { enablejsapi: 1, autoplay: 1 },
              }}
            />
          </div>
        </VideoContainer>

        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            type="button"
            onClick={() => {
              setModalBannerDecret(false);
              setIsModalBannerDecretOpened(false);
            }}
            title="Não desejo ver novamente"
            inverted
          />

          <a
            href="https://api.whatsapp.com/send?phone=5527998431975"
            target="blank"
          >
            <Button type="button" buttonColor="#2ec9b7" title="Eu quero!" />
          </a>
        </div>
      </Modal>

      <Modal
        isOpen={isModalLiveBannerOpened4}
        toggle={toggleModalLiveBanner4}
        style={{ padding: 0 }}
      >
        <ModalHeader style={{ display: 'flex', justifyContent: 'end' }}>
          <FiX
            onClick={toggleModalLiveBanner4}
            color="red"
            cursor="pointer"
            size={17}
            title="Fechar Modal"
          />
        </ModalHeader>
        <a href="https://meet.google.com/adz-ummu-brb" target="blank">
          <img
            style={{ width: '100%' }}
            src={bannerLive}
            alt="Banner mostrando ao usuário anuncio sobre a live"
          />
        </a>

        <ModalFooter>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <button
              className="btn"
              type="button"
              onClick={() => {
                setIsModalLiveBannerOpened4(false);
                setModalLiveBanner4(false);
              }}
              style={{ background: '#ff403d' }}
            >
              Não desejo ver novamente
            </button>
            <a href="https://meet.google.com/adz-ummu-brb" target="blank">
              <button
                className="btn"
                type="button"
                style={{ background: '#2ec9b7' }}
              >
                Acessar a live!
              </button>
            </a>
          </div>
        </ModalFooter>
      </Modal>

      {userIncludesCnpj && (
        <Modal
          isOpen={isModalBannerGetnetAlertOpened}
          toggle={toggleModalBannerGetnetAlert}
          style={{ padding: 0 }}
        >
          {getnetBannerTab === 0 && (
            <>
              <img
                style={{ width: '100%' }}
                src={bannerGetnet1}
                alt="Banner mostrando ao usuário informacao sobre a Getnet"
              />
            <ModalFooter style={{ padding: '10px 20px', display: 'block' }}>
                <Button
                  title='Próximo'
                  buttonColor='#2ec9b7'
                  onClick={() => setGetnetBannerTab(1)}
                  containerStyles={{ float: 'right' }}
                  textStyle={{ fontSize: 14, fontWeight: 600 }}
                />
              </ModalFooter>
            </>
          )}
          {getnetBannerTab === 1 && (
            <>
            {bannerGetnet2 ? (
              <img
                style={{ width: '100%' }}
                src={bannerGetnet2}
                alt="Banner mostrando ao usuário informacao sobre a Getnet"
              />
            ) : (
              <div style={{ width: 500, height: 500 }}>
                <Spinner
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                />
              </div>
            )}
            <ModalFooter style={{ padding: '10px 20px' }}>
              <Checkbox
                checked={getnetCheckbox}
                value={getnetCheckbox}
                onClick={() => setGetnetCheckbox(!getnetCheckbox)}
                labelStyles={{ fontWeight: 700, fontSize: 14, color: '#222' }}
                label='Li e estou ciente'
              />
              <Button
                title='Entendi'
                buttonColor='#2ec9b7'
                disabled={!getnetCheckbox}
                textStyle={{ fontSize: 14, fontWeight: 600 }}
                onClick={() => {
                  setIsModalGetnetAlertOpened(false)
                  setModalBannerGetnetAlert(false)
                }}
              />
            </ModalFooter>
            </>
          )}
        </Modal>
      )}

      <AnimatePresence>
        {notificationsOpen && (
          <NotificationPannel
            setNotificationsOpen={setNotificationsOpen}
            notiButtonRef={notiButtonRef}
            notifications={generalNotifications}
          />
        )}
      </AnimatePresence>

      {/* Modal whatsgw */}
      <WppModal
        isOpen={
          wppModal &&
          user.use_wpp &&
          wppConnectedGw === false &&
          wppConnectedGw !== null
        }
        toggle={toggleWppModal}
      >
        <img src={wppLostConnection} />
        <h1>CONEXÃO PERDIDA COM WHATSAPP</h1>
        <p>
          Atenção, não detectamos conexão com o seu WhatsApp. Clique no botão
          abaixo e reestabeleça conexão o quanto antes.
        </p>
        <ReconnectButton
          onClick={() => {
            toggleWppModal();
            setShowReconnectGw(true);
          }}
        >
          Reestabelecer conexão
        </ReconnectButton>
      </WppModal>

      <Wrapper>
        <NewHeader signOut={signOut} />

        <OperationArea>
          {!permission ? (
            <div
              style={{
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'flex-start',
                marginTop: '10%',
              }}
            >
              <PermissionErrorContainer />
            </div>
          ) : (
            <>
              <TablesCardArea>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                  }}
                >
                  <div
                    style={{
                      position: 'relative',
                      display: 'flex',
                      gap: 10,
                    }}
                  >
                    <Tabs
                      tabs={['Mesas', 'Comandas Offline']}
                      selected={activeTab}
                      counter={[null, null]}
                      onChangeTab={(e) => setActiveTab(e)}
                      mediaQueries={[
                        {
                          maxWidth: 1110,
                          styles: `font-size: 12px;`,
                        },
                      ]}
                    />

                    <div style={{ paddingTop: 5 }}>
                      {/* <BalconyLink to="/balcony">Modo Balcão</BalconyLink> */}
                      <BalconyLink onClick={() => handleGoToBalconyMode()}>
                        Modo Balcão
                      </BalconyLink>
                    </div>

                    {/* {[40893, 2].includes(user.id) && 
                      <div style={{paddingTop: 10}}>
                        <Button type="button" inverted title="Modo Balcão" buttonColor="#58ceb1" ></Button>
                      </div>
                    } */}
                  </div>

                  <div style={{ position: 'relative' }}>
                    <LiveButton
                      active={isLiveOn}
                      onClick={toggleLiveTextContext}
                    >
                      <FaCircle color={isLiveOn ? '#2EC9B7' : '#C0C0C0'} />
                      PLANTÃO COM ESPECIALISTA
                    </LiveButton>

                    {isLiveOn && isLiveTextContextOpened ? (
                      <LiveTextContent>
                        <FaX
                          onClick={toggleLiveTextContext}
                          color="#f10d27"
                          cursor="pointer"
                          style={{ float: 'right' }}
                        />
                        <p>
                          Esclarecemos as suas dúvidas em tempo real. Junte-se à
                          nossa Live!{' '}
                        </p>

                        <p style={{ fontSize: 14, marginTop: 10 }}>
                          Programação: Todas as segundas e quartas, das 15h às
                          17h e terças e quintas, de 9h as 11h.
                        </p>

                        <Button
                          title="Ir para a live"
                          containerStyles={{ width: '100%' }}
                          onClick={handleGoToLive}
                        />
                      </LiveTextContent>
                    ) : (
                      isLiveTextContextOpened && (
                        <LiveTextContent>
                          <FaX
                            onClick={toggleLiveTextContext}
                            color="#f10d27"
                            cursor="pointer"
                            style={{ float: 'right' }}
                          />
                          <p>
                            Para um atendimento de qualidade, nossos
                            especialistas estão em live às segundas e quartas,
                            de 15h as 17h e terças e quintas, de 9h às 11h
                          </p>
                          <p>Traga suas dúvidas!</p>
                        </LiveTextContent>
                      )
                    )}
                  </div>
                </div>

                <TablesContainer isFirst={activeTab === 0}>
                  <TablesOperation
                    activeTab={activeTab}
                    minimalSessions={
                      tablesFilteredSearch.length > 0
                        ? tablesFilteredSearch
                        : minimalSessions
                    }
                    getMinimalSessions={getMinimalSessions}
                    loading={loading}
                    // payments={payments}
                    // paymentMethods={paymentMethods}
                    setMinimalSessions={setMinimalSessions}
                    inicialDate={inicialDate}
                    finalDate={finalDate}
                    getTablesAvailable={getTablesAvailable}
                    tablesAvailable={
                      allTablesAvailable.length > 0
                        ? allTablesAvailable
                        : tablesAvailable
                    }
                    updateOpeninBoxOperation={updateOpeninBoxOperation}
                    orderByTableDate={orderByTableDate}
                    handleSearchInputTables={handleSearchInputTables}
                    setAllTablesAvailable={setAllTablesAvailable}
                    setTablesFilteredSearch={setTablesFilteredSearch}
                    setCount={setCount}
                    hasFilteredSessions={tablesFilteredSearch.length > 0}
                    ref={tablesRef}
                    handleUpdate={handleUpdate}
                    sessionsInCache={sessionsInCache}
                  />
                </TablesContainer>
              </TablesCardArea>
              <OrdersCardArea>
                <OrdersOperation
                  sessions={minimalSessions}
                  loading={loading}
                  setSessions={setSessions}
                  inicialDate={inicialDate}
                  finalDate={finalDate}
                  orderByTableDate={orderByTableDate}
                  orderByTableNumber={orderByTableNumber}
                  pendingBaskets={pendingBaskets}
                  acceptedBaskets={acceptedBaskets}
                  canceledBaskets={canceledBaskets}
                  finishedBaskets={finishedBaskets}
                  handleItemFinished={handleItemFinished}
                  handleCancelItem={handleCancelItem}
                  handlePrintItem={handlePrintItem}
                  tableBills={closingTableBills}
                  printQueueTable={printQueueTable}
                  individualBills={closingIndividualBills}
                  printTableBill={printTableBill}
                  printIndividualBill={printIndividualBill}
                  getTablesAvailable={getTablesAvailable}
                  ref={orderOperationRef}
                  minimalSessions={minimalSessions}
                  getMinimalSessions={getMinimalSessions}
                  setAllTablesAvailable={setAllTablesAvailable}
                  setTablesFilteredSearch={setTablesFilteredSearch}
                  setInputValueTables={setInputValueTables}
                  waiterTickets={waiterTickets}
                  sessionsInCache={sessionsInCache}
                />
              </OrdersCardArea>
            </>
          )}

          <Modal isOpen={isModalQrcodeOpened} toggle={toggleModalQrcode}>
            <ModalHeader>
              <strong style={{ fontSize: 16 }}>Cardápio Digital</strong>
            </ModalHeader>
            <ModalBody>
              <p>
                Compartilhe seu Cardápio em suas redes sociais e Whatsapp
                clicando no ícone "Copiar” <FiCopy color="FF2C3A" /> . Salve a
                imagem ou copie o link. Aproveite!
              </p>

              <div
                style={{
                  marginBottom: '16px',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',

                    alignItems: 'center',

                    width: '100%',
                  }}
                >
                  <strong>Cardápio Digital (SOMENTE VISUALIZAÇÃO)</strong>

                  <FiCopy
                    color="FF2C3A"
                    onClick={copyUrlCode}
                    size={18}
                    style={{ cursor: 'pointer', marginLeft: 8 }}
                    title="Copiar link"
                  />

                  {/* 
                <SaveButton type="button" onClick={copyUrlCode}>
                  {codeCopyed ? 'Copiado' : 'Copiar'}
                </SaveButton> */}
                </div>
                {tableMenu && (
                  <input
                    id="url-qrcode"
                    ref={inputRef}
                    value={`https://cardapio.takeat.app/${user.name}`}
                    type="Text"
                    style={{ border: 'none', width: '80%' }}
                  />
                )}
              </div>

              {(user?.delivery_info?.is_delivery_allowed ||
                user?.delivery_info?.is_withdrawal_allowed) && (
                  <div
                    style={{
                      marginBottom: '16px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',

                        width: '100%',
                      }}
                    >
                      <strong>Cardápio Digital (PEDIDOS)</strong>
                      <FiCopy
                        color="FF2C3A"
                        onClick={copyDeliveryUrlCode}
                        size={18}
                        style={{ cursor: 'pointer', marginLeft: 8 }}
                        title="Copiar link"
                      />
                    </div>

                    <input
                      id="url-delivery"
                      ref={inputDeliveryRef}
                      value={`https://pedido.takeat.app/${user.name}`}
                      type="Text"
                      style={{ border: 'none', width: '80%' }}
                    />
                  </div>
                )}

              {tableMenu && (
                <>
                  <ImgToPrint
                    ref={imgRef}
                    imgSrc={tableUrl}
                    tableMenu={tableMenu}
                  />

                  <SaveButton type="button" onClick={downloadPng}>
                    Baixar Imagem
                  </SaveButton>
                </>
              )}
            </ModalBody>
          </Modal>
          <Modal
            isOpen={modalCancelOrderIsOpened}
            toggle={toggleModalCancelOrder}
          >
            <Form onSubmit={handleCancelItemModal} ref={cancelForm}>
              <ModalBody>
                <Input
                  name="order_cancel_password"
                  label="Senha de cancelamento"
                  type="password"
                />
                <Input name="cancel_reason" label="Justificativa" />
              </ModalBody>
              <ModalFooter>
                <ButtonDefault
                  message="Confirmar"
                  type="submit"
                  disabled={loadingCancelItem}
                />
              </ModalFooter>
            </Form>
          </Modal>

          <Modal isOpen={modalPix} toggle={toggleModalPix}>
            <ModalBody>
              <div style={{ margin: 'auto', textAlign: 'center' }}>
                <img
                  src={pixLogo}
                  style={{ width: '45%', heigth: 'auto' }}
                  alt="logotipo do PIX"
                />
                <h5 style={{ marginTop: 10 }}>Novos Pagamentos Online</h5>
              </div>
              <Table borderless>
                <thead>
                  <tr>
                    <th>Mesa</th>
                    <th>Tipo</th>
                    <th>Telefone</th>
                    <th>Valor</th>
                    <th>Horário</th>
                  </tr>
                </thead>
                <tbody>
                  {localPixToShow?.map((pix) => (
                    <tr>
                      <td>
                        {pix?.table_session.table.table_type === 'delivery'
                          ? 'Delivery'
                          : pix?.table_session.table.table_number}
                      </td>
                      <td>{pix?.individual_bill_id ? 'Individual' : 'Mesa'}</td>
                      <td>{pix?.phone}</td>
                      <td>R${pix?.total}</td>
                      <td>
                        {pix?.payed_at && formatCompleteDate(pix?.payed_at)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </ModalBody>
            <ModalFooter>
              <ButtonDefault
                message="Ok"
                onClick={() => handlePixNotification()}
              />
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={isModalSystemBloquedOpen}
            toggle={toggleModalSystemBloqued}
            size="md"
          >
            <div
              style={{
                fontSize: '16px',
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                padding: '20px',
              }}
            >
              <button
                type="button"
                style={{
                  border: 'none',
                  background: 'transparent',
                  color: '#333',
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
                onClick={toggleModalSystemBloqued}
              >
                X
              </button>
            </div>

            <ModalBody>
              <div
                style={{
                  height: '300px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <p>
                  <FaExclamationTriangle color="#f10d27" size={90} />
                </p>
                <p style={{ fontSize: '26px' }}>
                  <strong>Atenção</strong>
                </p>
                <p style={{ fontSize: '16px', textAlign: 'center' }}>
                  O sistema está bloqueado por falta de pagamento. Efetue o
                  pagamento para voltar a usar.
                </p>
              </div>
            </ModalBody>
            <ModalFooter />
            <button
              style={{
                width: '100%',
                background: '#f10d27',
                color: '#fff',
                border: 'none',
                fontSize: 20,
                height: 50,
                fontWeight: 'bold',
              }}
              type="button"
              onClick={() => {
                handleOpenInvoice();
                toggleModalSystemBloqued();
              }}
            >
              Pagar fatura
            </button>
          </Modal>

          {/* <Modal
            isOpen={isModalNewsClubSaleOpened}
            toggle={toggleModalClubSale}
          >
            <ModalBody>
              <img
                src={clubSail}
                alt="baner de promoção de serviço de cashback"
                style={{ width: '100%' }}
              />
            </ModalBody>
            <ModalFooter
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <PersonalOutlineButton
                color="transparent"
                message="Não desejo mais ver essa mensagem"
                fontColor="#2EC9B7"
                borderColor="#2EC9B7"
                onClick={() => {
                  setModalOperationClubSale(false);
                  setIsModalNewsClubSaleOpened(false);
                }}
              />
              <PersonalButton
                color="#2EC9B7"
                message="Eu quero!"
                onClick={handleOpenWhatsppClubSale}
              />
            </ModalFooter>
          </Modal> */}
        </OperationArea>
      </Wrapper>
    </>
  );
}

export default Operation;
