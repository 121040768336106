import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  gap: 10px;
  overflow-y: auto;
  padding: 0 16px 16px;
  flex-grow: 1;
  padding:16px;
`;

export const ProductCard = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  width: 110px;
  height: 175px;
  flex-direction: column;
  border-radius: 10px;
  margin: 0 auto;
  box-shadow: 3px 2px 6px 0px rgba(0, 0, 0, 0.12);
`;

export const ProductImg = styled.img`
  width: 110px;
  height: 110px;
  border-radius: 10px 10px 0 0;
  object-fit: cover;
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
  padding: 5px;
  height: 65px;
  border-radius: 0px 0px 10px 10px;
  background: #f5f5f5;

  h5 {
    margin: 0;
    color: #4d4d4c;
    text-align: center;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  span {
    color: #4d4d4c;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 300;
  }
`;

export const CountBadge = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  right: -5px;
  border-radius: 50%;
  background: #ff2b3a;
  color: #fff;
  font-weight: bold;
`;

export const ProductsNotFound = styled.div`
  padding: 10px 0;
  grid-column: 1 / span 3;
  color: #666;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
`;
