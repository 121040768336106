import React from 'react';

import { RiLockPasswordLine } from 'react-icons/ri';

import { ButtonContainer } from './styles';

export default function ChangePasswordButton({ ...rest }) {
  return (
    <ButtonContainer type="button" {...rest}>
      <RiLockPasswordLine color="#33333380" size={16} />
      <span>Alterar Senha</span>
    </ButtonContainer>
  );
}
