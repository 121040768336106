import React, {
  createContext,
  useMemo,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import api from '~/services/api';
import { toast } from 'react-hot-toast';
import { useAuth } from '../AuthContext';

const WhatsgwContext = createContext();

const WhatsgwProvider = ({ children }) => {
  const { user } = useAuth();
  const [wppConnectedGw, setWppConnectedGw] = useState(null);
  const [wppQrcodeGw, setWppQrcodeGw] = useState(null);
  const [loadingQrCodeGw, setLoadingQrCodeGw] = useState(false);
  const [showReconnectGw, setShowReconnectGw] = useState(false);

  const checkWppStatusGw = async () => {
    const response = await api.get('/restaurants/whatsgw/status');

    const connected =
      response.data.result === 'success' && response.data.phone_state === 'connected';

    setWppConnectedGw(connected);

    localStorage.setItem(
      '@gddashboard:wppConnected',
      JSON.stringify(connected)
    );

    return connected;
  };

  const logoutWppGw = async () => {
    try{
      setLoadingQrCodeGw(true);
      const response = await api.post('/restaurants/whatsgw/logout');
  
      setWppConnectedGw(false);
      localStorage.setItem('@gddashboard:wppConnected', JSON.stringify(false));
      setWppQrcodeGw(null);
    }catch(err){
      toast.error(err?.response?.data?.message || 'Erro ao desconectar WhatsApp. Tente novamente')
    }
    setLoadingQrCodeGw(false);
  };

  const crateInstanceGw = useCallback(async () => {
    setLoadingQrCodeGw(true);
    try {
      const status = await checkWppStatusGw();

      if (!status) {
        const response = await api.post('/restaurants/whatsgw/createInstance');

        if (response.data.result !== 'success') {
          toast.error(
            'Não foi possível fazer o login no whatsapp. Tente novamente em alguns segundos.'
          );

          setLoadingQrCodeGw(false);
        }

        return response.data;

        // if (response.data.state === 'STARTING') {
        //   return;
        // }

        // const urlToUse =
        //   user.uzapi_url || 'https://takeatcrm.uzapi.com.br:3333';

        // const qrCode = await Axios.get(
        //   `${urlToUse}/getQrCode?session=${user.name}&sessionkey=${user.name}`,
        //   { responseType: 'blob' }
        // );

        // const url = URL.createObjectURL(qrCode.data);

        // setWppQrcode(url);
      }else{
        toast.error('Para gerar um novo qrcode, desconecte o dispositivo já conectado.');
      }
    } catch (err) {
      console.log('qrcode error > ', err);

      toast.error('Erro ao carregar QRCode, clique para gerar um novamente');
    }
  }, [user]);

  useEffect(() => {
    if (user?.use_wpp) {
      checkWppStatusGw();
      window.addEventListener('click', () => setShowReconnectGw(false));
    }
  }, []);

  useEffect(() => {
    if (user?.use_wpp) {
      setWppConnectedGw(null);
      checkWppStatusGw();
    }
  }, [user]);

  const value = useMemo(
    () => ({
      wppConnectedGw,
      setWppConnectedGw,
      wppQrcodeGw,
      setWppQrcodeGw,
      showReconnectGw,
      setShowReconnectGw,
      loadingQrCodeGw,
      setLoadingQrCodeGw,
      crateInstanceGw,
      logoutWppGw,
    }),
    [
      wppConnectedGw,
      setWppConnectedGw,
      wppQrcodeGw,
      setWppQrcodeGw,
      showReconnectGw,
      setShowReconnectGw,
      loadingQrCodeGw,
      setLoadingQrCodeGw,
      crateInstanceGw,
      logoutWppGw,
    ]
  );

  return (
    <WhatsgwContext.Provider value={value}>{children}</WhatsgwContext.Provider>
  );
};

function useWhatsgw() {
  const context = useContext(WhatsgwContext);

  if (!context) {
    throw new Error('useWhatsgw must be used within an WhatsgwProvider');
  }

  return context;
}

export { useWhatsgw, WhatsgwProvider };
