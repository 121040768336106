import React, { useState, useEffect, useCallback } from 'react';
import produce from 'immer';
import { toast } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';

import OrderProductCategoryContext from './context';
import { useAuth } from '~/context/AuthContext';

import {
  Container,
  Header,
  ProductCategoriesContainer,
  ReorderContainer,
} from './styles';
import BackButton from '~/components/Buttons/BackButton';

import history from '~/services/history';

import api from '~/services/api';
import ProductItem from './ProductItem';

function OrderProductCategory() {
  const { user } = useAuth();
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [products, setProducts] = useState([]);
  const location = useLocation();

  const { category } = location.state;

  const getProducts = useCallback(async () => {
    const response = await api.get(
      `/restaurants/products-custom-order/${category.id}`
    );

    setProducts(response.data.products);
    const options = [];

    for (let i = 1; i <= response.data.count; i += 1) {
      options.push({
        label: i,
        value: i,
      });
    }
    setCategoriesOptions(options);
  }, [category.id]);

  useEffect(() => {
    try {
      getProducts();
    } catch (err) {
      // console.tron.log(err);
      toast.error('Erro ao carregar dados');
    }
  }, [getProducts]);

  async function move(from_product_id, to_product_id, from, to) {
    try {
      await api.put('/restaurants/products-custom-order', {
        from_product_id,
        to_product_id,
        to_position: products[to].custom_order,
        from_position: products[from].custom_order,
      });

      // console.log('swaping - from: %d, to: %d', from, to);
      // console.log('orders - from: %d, to: %d', products[from].custom_order, products[to].custom_order);

      const next = produce(products, (draft) => {
        const dragged = draft[from];

        dragged.custom_order = draft[to].custom_order;

        if (to > from) {
          for (let i = from + 1; i <= to; i++) {
            draft[i].custom_order -= 1;
          }
        } else {
          for (let i = to; i < from; i++) {
            draft[i].custom_order += 1;
          }
        }

        draft.splice(from, 1);
        draft.splice(to, 0, dragged);
      });
      // console.log('before', products);
      // console.log('after', next);
      setProducts(next);
      toast.success('Posição Atualizada com sucesso!');
    } catch (err) {
      toast.error('Erro ao atualizar posição da categoria');
      getProducts();
    }
  }

  async function reorderProducts() {
    try {
      await api.post(`/restaurants/products/reorder/${user.id}`);

      toast.success('Produtos reordenados com sucesso!');

      getProducts();
    } catch (err) {
      // console.tron.log(err);
      toast.error('Erro ao ordenar cardápio');
    }

    // window.location.reload();
  }

  return (
    <OrderProductCategoryContext.Provider value={{ products, move }}>
      <Container>
        <Header>
          <div>
            <h2>Ordenar categoria: {category.name}</h2>
            <p>
              Ordene aqui como ficará os produtos dentro desta categoria, basta
              clicar e arrastar o card do produto para a posição desejada.{' '}
              <br />
              Caso os produtos continuem fora de ordem no cardapio do cliente,
              clique no botao Normalizar Ordenação.
            </p>
          </div>
          <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
        </Header>

        <ProductCategoriesContainer>
          <ReorderContainer>
            {/* <p>
              Caso os produtos continuem fora de ordem no cardapio do cliente,
              favor clicar no botao Normalizar Ordenação e refazer, ordenando os
              produtos um por vez.
            </p> */}
            <button type="button" onClick={reorderProducts}>
              Normalizar Ordenação
            </button>
          </ReorderContainer>

          {products.length > 0 ? (
            products.map((product, index) => (
              <ProductItem
                key={product.id}
                index={index}
                product={product}
                categoriesOptions={categoriesOptions}
                getCategories={getProducts}
              />
            ))
          ) : (
            <p>Nenhum produto nesta categoria</p>
          )}
        </ProductCategoriesContainer>
      </Container>
    </OrderProductCategoryContext.Provider>
  );
}

export default OrderProductCategory;
