import { Modal } from 'reactstrap';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  font-family: 'Poppins';
  font-style: normal;
  color: #000000;
  height: calc(100vh - 48px);
  overflow-y: scroll;

  h2 {
    font-weight: 500;
    font-size: 14px;
    margin: 0;
  }
`;

export const ServiceTaxTable = styled.div`
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 1px 0 5px 1px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
  ${({ firstTab }) => firstTab && 'border-top-left-radius: 0px;'}

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #666666;
`;

export const TableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 20px;
  text-align: center;
  gap: 5px;

  span {
    color: #666666;
    flex-basis: 25%;
  }

  span:last-child {
    text-align: right;
  }

  span:first-child {
    text-align: left;
  }
`;

export const HeaderText = styled.div`
  display: flex;
  position: relative;
  width: ${({ customWidth }) => customWidth || '100%'};
  justify-content: ${({ customJustify }) => customJustify || 'center'};
`;

export const RowContainer = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid #d9d9d9;
  }
`;

export const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  cursor: ${(props) => (props.collapsable ? 'pointer' : 'default')};
  text-align: center;
  gap: 5px;

  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #666666;
    flex-basis: 25%;
  }

  span:last-child {
    text-align: right;
  }

  span:first-child {
    text-align: left;
  }
`;

export const MonthRow = styled.div`
  padding: 20px 10px;
  background: #f4f4f4;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #666666;
  text-align: center;

  span {
    flex-basis: 25%;
  }

  span:last-child {
    text-align: right;
  }

  span:first-child {
    text-align: left;
  }
`;

export const SessionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.check ? '14px 0' : '0')};
  text-align: center;
  gap: 5px;
  position: relative;
  transition: all 0.2s;
  transform: ${(props) => (props.check ? 'scaleY(1)' : 'scaleY(0)')};

  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #666666;
    flex-basis: 25%;
  }

  span:last-child {
    text-align: right;
  }

  span:first-child {
    text-align: left;
  }

  &::after {
    content: '';
    position: absolute;
    top: -16px;
    left: 5px;
    background-color: #0ccab4;
    height: ${(props) => (props.check ? '32px' : '0px')};
    width: 1px;
  }
`;

export const DatePicker = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  float: right;
  position: relative;
  ${(props) => props.loading && 'pointer-events: none'};
`;

export const ButtonContainer = styled.div`
  width: 48px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #ff2c3a;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${(props) => props.loading && 'pointer-events: none'};
`;

export const DateContainer = styled.span`
  padding: 10px 20px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  width: 180px;
  text-align: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #666666;

  cursor: pointer;
`;

export const ModalWaiter = styled(Modal)`
  .modal-content {
    border-radius: 12px;
    width: 600px;
  }

  .modal-header {
    padding: 20px 20px 0;

    h2 {
      margin: 0;
      font-family: 'Poppins';
      font-size: 24px;
      font-weight: 600;
    }
  }

  .modal-body {
    padding: 0 20px 20px;

    p {
      margin: 0;
      font-size: 20px;
    }
  }

  ol {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    overflow-y: auto;

    li {
      display: flex;
      gap: 10px;
      padding: 5px 0;

      &:not(:last-child) {
        border-bottom: 1px solid #d9d9d9;
      }

      span {
        font-size: 16px;
      }
    }
  }
`;

export const ListTag = styled.span`
  display: flex;
  justify-content: center;
  align-items: stretch;
  height: 30px;
  width: fit-content;
  min-width: 30px;
  border-radius: 30px;
  color: #ffffff;
  background-color: ${(props) => props.color || '#0ccab4'};
  font-size: 16px;
  font-weight: 600;

  ${(props) =>
    !props.color &&
    `background-color: #ffffff; color: #979797; border: 1px solid #979797;`}
`;

export const ModalHeaderH2 = styled.h2`
  color: #4d4d4c;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const ModalHeaderSubtitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
`;

export const ModalTable = styled.table`
  width: 100%;

  th {
    color: #6c6c6c;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    padding: 10px;
    background-color: #f4f4f4;
  }

  td {
    color: #6c6c6c;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    padding: 10px;
  }

  tr:not(:last-child) {
    td {
      border-bottom: 1px solid #c4c4c4;
    }
  }

  tr {
    th:last-child {
      text-align: right;
    }

    td:last-child {
      text-align: right;
    }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.color || 'transparent'};
  border-radius: 20px;
  border: ${(props) => `1px solid ${props.color || '#C0C0C0'}`};
  color: ${(props) => (props.color ? '#ffffff' : '#C0C0C0')};
  margin-right: 12px;
`;
