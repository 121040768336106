import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  addHours,
  endOfDay,
  format,
  startOfDay,
  subHours,
} from 'date-fns';

import { MdKeyboardArrowRight } from 'react-icons/md';
import { CardBody, Card, Spinner, Collapse} from 'reactstrap';
import FileSaver from 'file-saver';
import { toast } from 'react-hot-toast';
import api from '~/services/api';
import {
  CCTableHeader,
  CollapsableTd,
  Container,
  CostCenterTable,
  EarningRow,
  Header,
  SubCategoryRow,
  ButtonsSelectDate,
} from './styles';
import formatCompleteDate from '~/services/formatCompleteDate';
import { generateEarningsSheetXLSX } from '~/services/generateEarningsSheet';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { PageTitle } from '~/components/PageTitle';
import { DownloadButton,  FullHourCalendar } from 'ui-kit-takeat';

function Invoicing() {
  const defaultInicial = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const [inicialFormat, setInicialFormat] = useState(
    format(new Date(defaultInicial), 'dd/MM/yyyy, HH:mm')
  );
  const [finalFormat, setFinalFormat] = useState(
    format(new Date(defaultInicial), 'dd/MM/yyyy, HH:mm')
  );
  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [payments, setPayments] = useState([]);
  const [exportData, setExportData] = useState(null);
  const [collapse, setCollapse] = useState([]);

  const [loadingReport, setLoadingReport] = useState(false);

  const phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [hourPhrase, setHourPhrase] = useState(phrase);
  const [ifoodPayments, setIfoodPayments] = useState([]);
  const [deliveryPayments, setDeliveryPayments] = useState([]);
  const [localPayments, setLocalPayments] = useState([]);

  const getPayments = useCallback(async () => {
    setLoadingReport(true);
    const response = await api.get('restaurants/reports/cashier', {
      params: {
        start_date: `${format(
          addHours(new Date(inicialDate), 3),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}`,
        end_date: `${format(
          addHours(new Date(finalDate), 3),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}`,
      },
    });
    setPayments(response.data.totalPaymentsReport);
    setExportData(response.data);

    const localPay = response.data.localPaymentsReport.map((item) =>
      item.payments.reduce((accum, curr) => {
        return accum + Number(curr.payment_value);
      }, 0)
    );

    const deliveryPay = response.data.deliveryPaymentsReport.map((item) =>
      item.payments.reduce((accum, curr) => {
        return accum + Number(curr.payment_value);
      }, 0)
    );

    const ifoodPay = response.data.ifoodPaymentsReport.map((item) =>
      item.payments.reduce((accum, curr) => {
        return accum + Number(curr.payment_value);
      }, 0)
    );

    setIfoodPayments(ifoodPay);
    setDeliveryPayments(deliveryPay);
    setLocalPayments(localPay);
    setLoadingReport(false);
  }, [finalDate, inicialDate]);

  const formatValue = (value) => {
    if (value === null) {
      value = 0;
    }
    if (typeof value === 'string') {
      value = parseFloat(value);
    }

    return value.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleCollapse = (id) => {
    if (collapse.includes(id)) {
      const newCollapse = collapse.filter((c) => c !== id);
      setCollapse(newCollapse);
    } else {
      setCollapse((state) => [...state, id]);
    }
  };

  const handleDownloadSheet = async () => {
    try {
      const sheet = await generateEarningsSheetXLSX(exportData);

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        `Faturamento(${format(new Date(inicialDate), 'dd-MM')}_${format(
          new Date(finalDate),
          'dd-MM'
        )}).xlsx`
      );
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'ReportsInvoicing'}`
      );

      const { can_read } = response.data.ReportsInvoicing;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  useEffect(() => {
    getPayments();
  }, [getPayments]);

  useEffect(() => {
    const inicialFormat = format(new Date(inicialDate), 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(new Date(finalDate), 'dd/MM/yyyy, HH:mm');
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }, [inicialDate, finalDate]);

  function searchTable(table, number) {
    switch (table) {
      case 'delivery':
        return 'Delivery';
      case 'balcony':
        return 'Balcão';
      case 'table':
      case 'command':
      case 'prepaid':
        return `Mesa ${number}`;
      default:
        break;
    }
  }

  // useEffect(() => {
  //   console.log('inicial > ', inicialDate);
  //   console.log('final > ', finalDate);
  // }, [inicialDate, finalDate]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <PageTitle>Relatório de Faturamento</PageTitle>
        <p>Filtro referente a data que foi informado o pagamento.</p>
      </Header>
      <ButtonsSelectDate>
        <p style={{ width: 190, flexGrow: 1 }}>{hourPhrase}</p>
        <DownloadButton
          text="Baixar planilha"
          buttonWidth={110}
          fixed="open"
          onClick={() => handleDownloadSheet()}
        />

        <FullHourCalendar
          value={{ start: new Date(inicialDate), end: new Date(finalDate) }}
          onDateSelected={(e) => {
            setInicialDate(e.start);
            setFinalDate(e.end);
          }}
        />
        <FullHourCalendar
          isEnd
          value={{ start: new Date(inicialDate), end: new Date(finalDate) }}
          onDateSelected={(e) => {
            setInicialDate(e.start);
            setFinalDate(e.end);
          }}
          onError={(e) => toast.error(e)}
          maxRange={31}
        />
      </ButtonsSelectDate>

      <Card style={{ maxHeight: 'calc(100% - 100px)', overflowY: 'auto' }}>
        <CardBody style={{ textAlign: loadingReport && 'center' }}>
          {loadingReport ? (
            <Spinner />
          ) : (
            <CostCenterTable>
              <CCTableHeader>
                <tr>
                  <th
                    style={{
                      padding: '12px 7px',
                      color: '#525f7f',
                      textAlign: 'left',
                    }}
                  >
                    Método
                  </th>
                  <th style={{ padding: '12px 7px', color: '#525f7f' }}>
                    Total
                  </th>
                  <th style={{ padding: '12px 7px', color: '#525f7f' }}>
                    Presencial
                  </th>
                  <th style={{ padding: '12px 7px', color: '#525f7f' }}>
                    Delivery
                  </th>
                  <th style={{ padding: '12px 7px', color: '#525f7f' }}>
                    Ifood
                  </th>
                  <th style={{ padding: '12px 7px', color: '#525f7f' }}>
                    Taxa
                  </th>
                  <th style={{ padding: '12px 7px', color: '#525f7f' }}>
                    Líquido
                  </th>
                </tr>
              </CCTableHeader>
              <tbody>
                {payments.length > 0 && (
                  <tr
                    style={{
                      fontWeight: 'bold',
                      borderBottom: '1px solid lightgrey',
                    }}
                  >
                    <td style={{ padding: '12px 7px' }}>TOTAL</td>
                    <td style={{ padding: '12px 7px' }}>
                      R$
                      {formatValue(
                        payments.reduce(
                          (acc, pays) =>
                            acc +
                            pays.payments.reduce(
                              (acc2, p) => acc2 + parseFloat(p.payment_value),
                              0
                            ),
                          0
                        )
                      )}
                    </td>
                    <td style={{ padding: '12px 7px' }}>
                      R$
                      {formatValue(
                        localPayments.reduce((acc, p) => acc + p, 0)
                      )}
                    </td>
                    <td style={{ padding: '12px 7px' }}>
                      R$
                      {formatValue(
                        deliveryPayments.reduce((acc, p) => acc + p, 0)
                      )}
                    </td>
                    <td style={{ padding: '12px 7px' }}>
                      R$
                      {formatValue(
                        ifoodPayments.reduce((acc, p) => acc + p, 0)
                      )}
                    </td>
                    <td style={{ padding: '12px 7px' }}>
                      R$
                      {formatValue(
                        payments.reduce(
                          (acc, p) => acc + parseFloat(p.tax_payments_price),
                          0
                        )
                      )}
                    </td>
                    <td style={{ padding: '12px 7px' }}>
                      R$
                      {formatValue(
                        payments.reduce(
                          (acc, p) => acc + parseFloat(p.liquid_payments_price),
                          0
                        )
                      )}
                    </td>
                  </tr>
                )}
                {payments.map((p, ind) => (
                  <>
                    <EarningRow
                      onClick={() => {
                        handleCollapse(p.id);
                      }}
                    >
                      <td>
                        <MdKeyboardArrowRight
                          style={{
                            transition: 'all 0.2s',
                            transform: collapse.includes(p.id)
                              ? 'rotate(90deg)'
                              : 'rotate(0)',
                          }}
                        />{' '}
                        {p.name}
                      </td>
                      <td>R$ {formatValue(p.total_payments_price)}</td>
                      <td>R$ {formatValue(localPayments[ind] || 0)}</td>
                      <td>R$ {formatValue(deliveryPayments[ind] || 0)} </td>
                      <td>R$ {formatValue(ifoodPayments[ind] || 0)} </td>
                      <td>R$ {formatValue(p.tax_payments_price)}</td>
                      <td>R$ {formatValue(p.liquid_payments_price)}</td>
                    </EarningRow>

                    <>
                      {p.payments.map((item) => {
                        const check = collapse.includes(p.id);
                        if (item.is_food) {
                          return (
                            <SubCategoryRow>
                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}>
                                  {item.is_food
                                    ? 'Ifood'
                                    : searchTable(
                                        item.table_type,
                                        item.table_number
                                      )}{' '}
                                  - {formatCompleteDate(item.created_at)}
                                </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}>
                                  R$ {formatValue(item.payment_value)}
                                </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>
                            </SubCategoryRow>
                          );
                        }

                        if (
                          item.table_type === 'table' ||
                          item.table_type === 'command' ||
                          item.table_type === 'prepaid' ||
                          item.table_type === 'balcony'
                        ) {
                          return (
                            <SubCategoryRow>
                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}>
                                  {item.is_food
                                    ? 'Ifood'
                                    : searchTable(
                                        item.table_type,
                                        item.table_number
                                      )}{' '}
                                  - {formatCompleteDate(item.created_at)}
                                </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}>
                                  R$ {formatValue(item.payment_value)}
                                </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>
                            </SubCategoryRow>
                          );
                        }

                        if (item.table_type === 'delivery' && !item.is_food) {
                          return (
                            <SubCategoryRow>
                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}>
                                  {item.is_food
                                    ? 'Ifood'
                                    : searchTable(
                                        item.table_type,
                                        item.table_number
                                      )}{' '}
                                  - {formatCompleteDate(item.created_at)}
                                </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}>
                                  R$ {formatValue(item.payment_value)}
                                </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>
                            </SubCategoryRow>
                          );
                        }
                      })}
                    </>
                  </>
                ))}
              </tbody>
            </CostCenterTable>
          )}
        </CardBody>
      </Card>
    </Container>
  );
}

export default Invoicing;
