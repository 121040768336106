import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-hot-toast';

import { Modal } from 'reactstrap';
import { Container, CategoriesTable, Header } from './styles';

import api from '~/services/api';
import NewTicket from './Store';

import Coupom from './Coupom';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { VideoPlayer } from '~/components/VideoPlayer';
import { Button } from 'ui-kit-takeat';
import { FaPlus } from 'react-icons/fa';
import { PageTitle } from '~/components/PageTitle';

function Tickets() {
  const [tickets, setTickets] = useState([]);
  const [isModalAddTicketsOpen, setIsModalAddTicketsOpen] = useState(false);

  function toggleAddTicketModal() {
    setIsModalAddTicketsOpen(!isModalAddTicketsOpen);
  }

  const getTickets = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/discount-coupons');
      setTickets(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, []);

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'DeliveryCoupons'}`
      );

      const { can_read } = response.data.DeliveryCoupons;

      setPermission(can_read);
    } catch (error) {
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  useEffect(() => {
    getTickets();
  }, [getTickets]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PageTitle>Cupons</PageTitle>
            <VideoPlayer id={71} />
          </div>
        </div>

        <Button
          title="Cadastrar"
          icon={<FaPlus />}
          onClick={toggleAddTicketModal}
        />
      </Header>
      <Modal
        isOpen={isModalAddTicketsOpen}
        toggle={toggleAddTicketModal}
        size="lg"
      >
        <NewTicket
          getTickets={getTickets}
          toggleAddTicketModal={toggleAddTicketModal}
        />
      </Modal>

      <CategoriesTable borderless>
        <thead>
          <tr>
            <th>Código</th>
            <th>Nome</th>
            <th>Ativo</th>
            <th>Público</th>
            <th>Desconto</th>
            <th>Valor Mínimo</th>
            <th>Desconto Máximo</th>
            <th>Limite</th>
            <th>Usado</th>
            <th>Expiração</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {tickets && tickets.length > 0 ? (
            tickets.map((ticket) => (
              <Coupom coupom={ticket} getTickets={getTickets} />
            ))
          ) : (
            <h1>Vazio</h1>
          )}
        </tbody>
      </CategoriesTable>
    </Container>
  );
}

export default Tickets;
