import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';

import { MdInsertPhoto } from 'react-icons/md';
import {  Row, Col } from 'reactstrap';
import {
  Wrapper,
  Header,
  Card,
  QrcodeContainer,
  DefaultAvatar,
  FormContainer,
  NumberFormatStyled,
} from './styles';

import history from '~/services/history';
import api from '~/services/api';

import Input from '~/components/Form/Input';
import InputMask from '~/components/Form/InputMask';
import BackButton from '~/components/Buttons/BackButton';
import SaveButton from '~/components/Buttons/SaveButton';
import Select from '~/components/Form/SelectInput';
import { PageTitle } from '~/components/PageTitle';

function CreateProduct() {
  const location = useLocation();
  const [preview, setPreview] = useState({});
  const [qrcode, setQrcode] = useState('');
  const [saved, setSaved] = useState(false);

  const { table } = location.state;
  const qrcodeRef = useRef(null);

  const initialData = {
    table_number: table?.table_number,
  };

  

  const tableOptions = [
    { label: 'Mesa', value: 'table' },
    { label: 'Mesa Pré-paga', value: 'prepaid' },
    { label: 'Comanda', value: 'command' },
  ];

  const [tableTypeValue, setTableTypeValue] = useState(
    tableOptions.find((tab) => tab.value === table.table_type)
  );

  useEffect(() => {
    if (table.table_code) {
      setPreview(table.table_code);
    } else {
      setPreview({});
    }
  }, [setPreview, table]);

  useEffect(() => {
    qrcodeRef.current = preview;
    return async () => {
      if (!saved && preview.url) {
        const deletedQrcode = qrcodeRef.current;
        await api.delete(`/qrcode/${deletedQrcode.id}`);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleQrcode() {
    try {
      if (
        preview.url &&
        window.confirm('Tem certeza que deseja gerar um novo QRCode?')
      ) {
        await api.delete(`restaurants/qrcode/${preview.id}`);

        if (qrcode.length > 0) {
          const schema = Yup.object().shape({
            qrcode: Yup.string()
              .min(6, 'O código precisa ter 6 dígitos')
              .max(6, 'O código precisa ter 6 dígitos')
              .matches(/^[a-z0-9]+$/i, 'Código só pode ser alfanumérico'),
          });

          await schema.validate(
            { qrcode },
            {
              abortEarly: false,
            }
          );
          const response = await api.post('/qrcode', {
            code: qrcode,
          });

          setPreview(response.data);
        } else {
          const response = await api.post('qrcode');

          setPreview(response.data);
        }

        toast.success('QRCode criado com sucesso!');
      }
      if (!preview.url) {
        if (qrcode.length > 0) {
          const schema = Yup.object().shape({
            qrcode: Yup.string()
              .min(6, 'O código precisa ter 6 dígitos')
              .max(6, 'O código precisa ter 6 dígitos')
              .matches(/^[a-z0-9]+$/i, 'Código só pode ser alfanumérico'),
          });

          await schema.validate(
            { qrcode },
            {
              abortEarly: false,
            }
          );
          const response = await api.post('restaurants/qrcode', {
            code: qrcode,
          });

          setPreview(response.data);
        } else {
          const response = await api.post('restaurants/qrcode');

          setPreview(response.data);
        }

        toast.success('QRCode criado com sucesso!');
      }
      setSaved(false);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        // Validation failed
        toast.error(`Erro ao gerar QRCode! ${error}`);
      } else {
        toast.error('Erro ao gerar novo QRCode');
      }
    }
  }

  async function handleSubmit(data) {
    if (data.table_number < 1 || data.table_number === '') {
      toast.error('Número da mesa deve ser maior que 0');
      return;
    }
    try {
      const formattedData = {
        table_number: Number(data.table_number),
        table_code_id: preview.id,
        table_type: tableTypeValue.value,
      };

      console.log(formattedData);
      // if (isTablePrepaid) {
      //   formattedData.table_type = 'prepaid';
      // } else {
      //   formattedData.table_type = table.table_type;
      // }

      if (loungePriceLimit && table.table_type === 'lounge') {
        formattedData.price_limit = loungePriceLimit.floatValue / 100;
      }

      if (!formattedData.price_limit && table.table_type === 'lounge') {
        toast.error('Saldo do lounge obrigatorio.');
        return false;
      }

      await api.put(`restaurants/tables/${table.id}`, formattedData);
      setSaved(true);
      toast.success('Mesa atualizada com sucesso!');
      history.push('/register/tables');
    } catch (error) {
      if (error.response.data.errorType === 'table_with_open_sessions') {
        toast.error('Mesa com comanda em aberto, favor finalizar.');
      } else {
        toast.error(`Falha ao atualizar mesa ${error.message}`);
      }
    }
  }

  function currencyFormatter(value) {
    if (!Number(value)) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  }

  const [loungePriceLimit, setLoungePriceLimit] = useState(null);

  return (
    <Wrapper>
      <Header>
        <div>
          <PageTitle>Cadastrar Mesas</PageTitle>
        </div>
        <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
      </Header>
      <Card>
        <Form id="save-form" initialData={initialData} onSubmit={handleSubmit}>
          <div>
            <Row>
              <Col sm="12">
                <QrcodeContainer>
                  {preview.url ? (
                    <img src={preview.url} alt="QR code da mesa" />
                  ) : (
                    <DefaultAvatar>
                      <MdInsertPhoto size={45} color="#ddd" />
                    </DefaultAvatar>
                  )}
                  <h4>Código atual da mesa</h4>
                  <span>{preview.key ? preview.key : 'Nenhum código'}</span>
                  <FormContainer>
                    <InputMask
                      mask="******"
                      maskChar=""
                      maskPlaceholder="______"
                      label="Novo código da mesa"
                      name="qrcode_key"
                      type="text"
                      value={qrcode}
                      onChange={(e) => {
                        setQrcode(e.target.value);
                      }}
                      style={{ textTransform: 'lowercase' }}
                    />
                    <p>
                      OBS: São aceitos apenas letras de a-z minúsculas e
                      números.
                    </p>
                    <span>
                      Caso queira um código aleatório, apenas clique em Gerar
                      QRCode
                    </span>
                    <button type="button" onClick={handleQrcode}>
                      Gerar QRCode
                    </button>
                  </FormContainer>
                </QrcodeContainer>
              </Col>
            </Row>
            <Row>
              <Col
                sm={
                  table.table_type === 'lounge'
                    ? '6'
                    : table.table_number > 0
                    ? '8'
                    : '12'
                }
              >
                <Input
                  label="Número da mesa"
                  name="table_number"
                  type="number"
                  min="1"
                  placeholder="Número positivo maior que 0"
                />
              </Col>
              {table.table_type === 'lounge' && (
                <Col
                  sm="6"
                  style={{
                    flexDirection: 'column',
                    display: 'flex',
                    marginTop: 10,
                  }}
                >
                  <span>
                    <strong>Saldo do lounge</strong>
                  </span>
                  <NumberFormatStyled
                    prefix="R$"
                    format={currencyFormatter}
                    name="value"
                    defaultValue={table.price_limit}
                    onValueChange={(e) => {
                      setLoungePriceLimit(e);
                    }}
                  />
                </Col>
              )}
              {table.table_type !== 'lounge' && table.table_number > 0 && (
                <Col sm="4">
                  <Select
                    label="Tipo"
                    options={tableOptions}
                    value={tableTypeValue}
                    onChange={(e) => setTableTypeValue(e)}
                    name="table_type"
                    style={{ marginTop: 20 }}
                  />
                </Col>
              )}
            </Row>
            <SaveButton type="submit" form="save-form">
              Cadastrar
            </SaveButton>
          </div>
        </Form>
      </Card>
    </Wrapper>
  );
}

export default CreateProduct;