import { Modal } from 'reactstrap';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  background-color: #fff;
  border-radius: 7px;
  width: 100%;
  padding: 15px;

  h1 {
    font-size: 26px;
  }
`;

export const OpeninBoxReportArea = styled.div`
  width: 100%;
  padding: 15px;
  /* button {
    border: none;
    border-radius: 7px;
    padding: 12px;
    background-color: #333;
    color: #fff;
    font-size: 14px;

    &:hover {
      filter: opacity(0.9);
    }
  } */
  div {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 16px;

    & + div {
      padding-top: 16px;
      border-top: 1px solid #33333310;
    }
  }
`;

export const ModalPrintReport = styled(Modal)`
  .modal-content {
    width: fit-content;
  }

  .modal-body {
    padding-bottom: 0;
  }
`;
