import styled from 'styled-components'

export const PageContainer = styled.div`
    padding:20px;
    height:90vh;
    display:flex;
`

export const SelectComplementsContainer = styled.div `
    background-color: white;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    box-shadow: 1px 0px 5px 1px rgba(0, 0, 0, 0.1);
    padding:20px;
`
export const BalanceCard = styled.div`
    width:100%;
    height:calc(100vh - 100px);
    display:flex;
    background-color:white;
    border-radius:10px;
    box-shadow: 1px 0px 5px 1px rgba(0, 0, 0, 0.1);
`

export const MenuArea = styled.div`
    display:flex;
    flex-direction:column;
    overflow-y:auto;
    padding:10px;
    gap:10px;
    width:65%;
`