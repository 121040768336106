import React from 'react';

import {
  Container,
  Table,
  Order,
  Restaurant,
  Body,
  Phone,
  Time,
  Items,
  Item,
  ItemName,
  Complements,
  ComplementsTitle,
  Obs,
  Footer,
  DeliveryInfo,
  DeliveryPrice,
} from './styles';

import formatTime from '~/services/formatTime';

import formatCompleteDate from '~/services/formatCompleteDate';
import formatValue from '~/services/formatValue';

const PrintNewOrderComponentDelivery = React.forwardRef(
  ({ item, restaurant, orders, bills, paid, productBills, buyer }, ref) => {
    // const userName = useMemo(() => {
    //   return item?.buyer ? formatPhone(item?.buyer?.phone) : item?.waiter?.name;
    // }, [item]);

    // const userNameDelivery = item?.buyer
    //   ? item?.buyer?.phone
    //   : item?.waiter?.name;
    function changeValue(value) {
      const newValue = value.replace('.', ',');

      return newValue;
    }

    function searchTableName(table) {
      if (table.table_type === 'balcony') {
        return 'Balcão';
      }

      if (table.table_type === 'delivery' && item?.scheduled_to) {
        return 'Delivery Agendado';
      }


      if (table.table_type === 'delivery' && item?.with_withdrawal) {
        return 'Retirada';
      }

      if (table.table_type === 'delivery') {
        return 'Delivery';
      }

      return `Mesa  ${table.table_number}`;
    }

    return (
      <Container ref={ref}>
        {console.log(buyer)}
        <Table>
          <strong>{searchTableName(item?.table)}</strong>
        </Table>
        <Order>
          <strong>Pedido #{item?.attendance_password}</strong>
          {item?.scheduled_to && (
            <p>Agendado para {formatCompleteDate(item?.scheduled_to)}</p>
          )}
        </Order>
        <Restaurant>
          <strong>{restaurant?.fantasy_name}</strong>
        </Restaurant>
        <Body>
          <Phone>
            {`${item?.bills[0]?.buyer?.phone || ''} - ${item?.bills[0]?.buyer?.name || ''
              }`}
          </Phone>

          <Time>
            {item?.table?.table_type === 'delivery' ? (
              <strong>{formatCompleteDate(item?.start_time)}</strong>
            ) : (
              <strong>{formatTime(item?.start_time)}</strong>
            )}
          </Time>
        </Body>

        {item?.table.table_type === 'delivery' && (
          <DeliveryInfo>
            <span style={{ fontWeight: 'bold' }}>Dados do cliente:</span>
            <span>{`${buyer?.name} - ${buyer?.phone}`}</span>
            {buyer?.localizer && <span>ID: {buyer?.localizer}</span>}

            <span>{buyer?.email}</span>
            {item?.with_withdrawal.toString() === 'false' && (
              <>
                <span>
                  {buyer ? buyer?.delivery_address?.street || '' : ''} -{' '}
                  {buyer ? buyer?.delivery_address?.number || '' : ''}
                </span>
                <span>
                  {buyer ? buyer?.delivery_address?.neighborhood || '' : ''}
                </span>
                <span>
                  {buyer ? buyer?.delivery_address?.city || '' : ''} -{' '}
                  {buyer ? buyer?.delivery_address?.state || '' : ''}
                </span>
                <span>
                  {buyer ? buyer?.delivery_address?.zip_code || '' : ''}
                </span>
              </>
            )}

            {item?.intended_payment_method?.name && (
              <>
                <span style={{ fontWeight: 'bold', marginTop: 10 }}>
                  Método de pagamento:
                </span>
                <span> {item?.intended_payment_method?.name || ''}</span>
              </>
            )}

            {item?.with_withdrawal && (
              <span style={{ fontWeight: 'bold', marginTop: 10 }}>
                Cliente irá retirar o produto.
              </span>
            )}

            {item?.details && (
              <span style={{ fontWeight: 'bold', marginTop: 10 }}>
                {item?.details}
              </span>
            )}
          </DeliveryInfo>
        )}

        {/* {item?.table.table_type === 'delivery' &&
          item?.with_withdrawal.toString() === 'true' && (
            <DeliveryInfo>
              <span style={{ fontWeight: 'bold' }}>Cliente:</span>
              <span> {item?.buyer?.name}</span>
              <span>{item?.buyer?.email}</span>
            </DeliveryInfo>
          )} */}
        <Items>
          <span style={{ fontWeight: 'bold' }}>Pedido:</span>
          {orders?.length > 0 &&
            orders?.map((order) => (
              <Item key={order.id}>
                <ItemName>
                  {order.use_weight ? (
                    <strong>
                      {changeValue(order.weight)}kg {order.product.name}
                    </strong>
                  ) : (
                    <strong>
                      {order.amount}x {order.product.name}
                    </strong>
                  )}
                </ItemName>
                {order.complement_categories.map((category) => (
                  <div key={category.id}>
                    <ComplementsTitle>
                      <strong>{category.complement_category.name}:</strong>
                    </ComplementsTitle>
                    {category.order_complements.map((complement) => (
                      <Complements key={complement.id}>
                        <strong>
                          - {complement.amount} x {complement.complement.name}{' '}
                        </strong>
                      </Complements>
                    ))}
                  </div>
                ))}

                {order.details && (
                  <Obs>
                    <strong>Obs: {order.details}</strong>
                  </Obs>
                )}
              </Item>
            ))}
        </Items>
        {item?.table.table_type === 'delivery' && (
          <DeliveryPrice>
            {item?.discount_total ? (
              <div>
                <span>Pedido:</span>
                <span>{formatValue(item?.old_total_price)}</span>
              </div>
            ) : (
              <div>
                <span>Pedido:</span>
                <span>{formatValue(item?.total_service_price)}</span>
              </div>
            )}


            {item?.discount_total && (
              <div>
                <span>Desconto/ Cupom:</span>
                <span>-{formatValue(item?.discount_total)}</span>
              </div>
            )}
            {item?.delivery_tax_price && (
              <div>
                <span>Taxa de entrega:</span>
                <span>{formatValue(item?.delivery_tax_price)}</span>
              </div>
            )}

            {/* {!item?.basket?.ifood_id && item?.delivery_fee_discount && (
              <div>
                <span>
                  Desconto de entrega grátis aplicado
                </span>
              </div>
            )} */}

            <div>
              <span>Total:</span>


              <span>
                {formatValue(
                  Number(item?.total_delivery_price)
                )}
              </span>


            </div>

            {item?.user_change > 0 &&
              (item?.intended_payment_method?.name === 'Dinheiro' ||
                item?.intended_payment_method?.name === 'Dinheiro (iFood)') && (
                <div>
                  <span>Troco:</span>
                  <span>
                    {formatValue(
                      Number(item?.user_change) -
                      Number(item?.total_delivery_price)
                    )}
                  </span>
                </div>
              )}

            <div>
              <span>Pago:</span>
              <span>{formatValue(paid)}</span>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              {item?.payments &&
                item?.payments.length > 0 &&
                item?.payments.map((payment) => (
                  <div
                    key={payment.id}
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span style={{ fontSize: 20, width: "60%" }}>
                      - {payment?.payment_method?.name}
                    </span>
                    <span style={{ fontSize: 20 }}>
                      R$ {parseFloat(payment?.payment_value).toFixed(2)}
                    </span>
                  </div>
                ))}
            </div>

            <div>
              <span>Restante:</span>
              <span>
                {formatValue(
                  Number(item?.total_service_price) +
                  Number(item?.delivery_tax_price) -
                  Number(paid)
                )}
              </span>
            </div>
          </DeliveryPrice>
        )}
        <Footer>
          <strong>Desenvolvido por Takeat</strong>
        </Footer>
      </Container>
    );
  }
);
export default PrintNewOrderComponentDelivery;
