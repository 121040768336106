import React, { useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';

import {  FaTrash } from 'react-icons/fa';
import { Row, Col} from 'reactstrap';
import {
  Header,
  StyledForm,
  Container,
  ButtonsArea,
  InnerArea,
} from './styles';

import api from '~/services/api';
import { Button, DefaultInput } from 'ui-kit-takeat';
import Input from '~/components/Form/Input';
import Checkbox from '~/components/Form/Checkbox';

import { useAuth } from '~/context/AuthContext';
import PersonalButton from '~/components/Buttons/PersonalButton';

import CoverInput from '~/components/Form/CoverInput';
import { PersonalizedHoursContainer } from '../../Edit/styles';

function Store({ toggle, getCategories }) {
  const formRef = useRef(null);
  const { user } = useAuth();

  const [availableCategory, setAvailableCategory] = useState(true);

  function handleChangeAvailableCategory() {
    setAvailableCategory(!availableCategory);
  }

  const [availableDeliveryCategory, setAvailableDeliveryCategory] =
    useState(true);

  function handleChangeAvailableDeliveryCategory() {
    setAvailableDeliveryCategory(!availableDeliveryCategory);
  }

  const [exclusiveCategory, setExclusiveCategory] = useState(false);

  function handleChangeExclusiveCategory() {
    setExclusiveCategory(!exclusiveCategory);
  }

  const [enableTimes, setEnableTimes] = useState(false);
  const [daysSelected, setDaysSelected] = useState([
    't',
    't',
    't',
    't',
    't',
    't',
    't',
  ]);
  const [hourSelected, setHourSelected] = useState({
    start: '00:00',
    end: '23:59',
  });

  const handleChangeDay = (idx) => {
    const updatedDays = [...daysSelected];
    updatedDays[idx] = updatedDays[idx] === 'f' ? 't' : 'f';
    setDaysSelected(updatedDays);
  };

  const handleTimeInput = (time) => {
    let validTime = '';
    const array = time.split('');

    array.forEach((element, i) => {
      if (i === 0 && element > 2) {
        validTime += '2';
      } else if (i === 1 && element > 3 && array[0] > 1) {
        validTime += '3';
      } else if (i === 3 && element > 5) {
        validTime += '5';
      } else {
        validTime += element;
      }
      return;
    });

    return validTime;
  };

  async function handleSubmit(data) {
    try {
      const start_time =
        enableTimes && data.startTime
          ? `2021-01-09T${data.startTime}:00-03:00`
          : null;
      const end_time =
        enableTimes && data.endTime
          ? `2021-01-09T${data.endTime}:00-03:00`
          : null;

      const schema = Yup.object().shape({
        name: Yup.string().required('O nome é obrigatório'),
        start_time: Yup.date('Data final inválida').nullable(),
        end_time: Yup.date('Data final inválida').nullable(),
      });

      await schema.validate(
        {
          name: data.name,
          start_time,
          end_time,
        },
        {
          abortEarly: false,
        }
      );

      const image_id_parsed = data.image_id ? Number(data.image_id) : null;

      await api.post('/restaurants/product-category', {
        name: data.name,
        preparation_time: data.time || '0',
        available: availableCategory,
        available_in_delivery: availableDeliveryCategory,
        is_exclusive: exclusiveCategory,
        start_time: new Date(`2023/01/01 ${hourSelected.start}`),
        end_time: new Date(`2023/01/01 ${hourSelected.end}`),
        active_days: daysSelected.join(''),
        image_id: image_id_parsed,
        enable_times: enableTimes,
      });

      getCategories();

      toast.success('Categoria cadastrada com sucesso!');
      toggle();
    } catch (error) {
      // if (error.response.data.errorType === 'only_one_time') {
      //   toast.error(
      //     `Favor preencher o horário disponível de início e fim das categorias disponíveis.`
      //   );
      // } else {
      //   toast.error(`Erro ao cadastrar categoria!`);
      // }
      toast.error(
        `Erro ao cadastrar categoria! Favor preencher corretamente os campos.`
      );
      console.log(error);
    }
  }
  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);

  const [deleteCover, setDeleteCover] = useState(false);

  return (
    <Container>
      <Header>
        <div>
          <h3>Adicionar categoria</h3>
        </div>
      </Header>

      <StyledForm ref={formRef} onSubmit={handleSubmit}>
        <InnerArea browserZoomLevel={browserZoomLevel}>
          <Row>
            <Col size="md-6">
              <Input
                label="Nome"
                name="name"
                type="text"
                placeholder="Nome da categoria"
              />

              <Row>
                <Input
                  label="Tempo de preparo"
                  name="time"
                  type="number"
                  placeholder="Tempo (em minutos)"
                />
              </Row>
            </Col>

            <Col md="6">
              <p style={{ fontWeight: 'bold' }}>
                Imagem para capa dessa categoria
              </p>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CoverInput name="image_id" deleteCover={deleteCover} />
                <FaTrash
                  cursor="pointer"
                  onClick={() => setDeleteCover(!deleteCover)}
                  color="#FF2B3A"
                  style={{ marginLeft: 6 }}
                />
              </div>

              <p>
                Caso não queira inserir uma imagem, usaremos a foto do primeiro
                produto dessa categoria como imagem de capa.
              </p>
            </Col>
          </Row>

          <Row>
            {!user?.only_delivery && (
              <Col md="3">
                <Checkbox
                  label="Disponível no presencial"
                  value={availableCategory}
                  onChange={handleChangeAvailableCategory}
                  name="available_category"
                />
              </Col>
            )}

            {user?.delivery_info?.is_delivery_allowed && (
              <Col md="3">
                <Checkbox
                  label="Disponível no delivery"
                  value={availableDeliveryCategory}
                  onChange={handleChangeAvailableDeliveryCategory}
                  name="available_delivery"
                />
              </Col>
            )}
            <Col md="3">
              <Checkbox
                label="Uso exclusivo"
                value={exclusiveCategory}
                onChange={handleChangeExclusiveCategory}
                name="exclusive"
              />
            </Col>
            <Col md="3">
              <Checkbox
                label="Horário personalizado"
                value={enableTimes}
                onChange={(e) => {
                  setEnableTimes(!enableTimes);
                }}
                name="enable_times"
              />
            </Col>
          </Row>

          {enableTimes && (
            <PersonalizedHoursContainer>
              <div>
                <span style={{ fontWeight: 'bold' }}>Dias:</span>
                <div style={{ display: 'flex', gap: 5 }}>
                  <Button
                    type="button"
                    title="Dom"
                    buttonColor="#2ec9b7"
                    containerStyles={{
                      width: 60,
                      height: 30,
                      opacity: daysSelected[0] === 'f' ? 0.5 : 1,
                    }}
                    onClick={() => {
                      handleChangeDay(0);
                    }}
                  />
                  <Button
                    type="button"
                    title="Seg"
                    containerStyles={{
                      width: 60,
                      height: 30,
                      opacity: daysSelected[1] === 'f' ? 0.5 : 1,
                    }}
                    buttonColor="#2ec9b7"
                    onClick={() => {
                      handleChangeDay(1);
                    }}
                  />
                  <Button
                    type="button"
                    title="Ter"
                    containerStyles={{
                      width: 60,
                      height: 30,
                      opacity: daysSelected[2] === 'f' ? 0.5 : 1,
                    }}
                    buttonColor="#2ec9b7"
                    onClick={() => {
                      handleChangeDay(2);
                    }}
                  />
                  <Button
                    type="button"
                    title="Qua"
                    containerStyles={{
                      width: 60,
                      height: 30,
                      opacity: daysSelected[3] === 'f' ? 0.5 : 1,
                    }}
                    buttonColor="#2ec9b7"
                    onClick={() => {
                      handleChangeDay(3);
                    }}
                  />
                  <Button
                    type="button"
                    title="Qui"
                    containerStyles={{
                      width: 60,
                      height: 30,
                      opacity: daysSelected[4] === 'f' ? 0.5 : 1,
                    }}
                    buttonColor="#2ec9b7"
                    onClick={() => {
                      handleChangeDay(4);
                    }}
                  />
                  <Button
                    type="button"
                    title="Sex"
                    containerStyles={{
                      width: 60,
                      height: 30,
                      opacity: daysSelected[5] === 'f' ? 0.5 : 1,
                    }}
                    buttonColor="#2ec9b7"
                    onClick={() => {
                      handleChangeDay(5);
                    }}
                  />
                  <Button
                    type="button"
                    title="Sáb"
                    containerStyles={{
                      width: 60,
                      height: 30,
                      opacity: daysSelected[6] === 'f' ? 0.5 : 1,
                    }}
                    buttonColor="#2ec9b7"
                    onClick={() => {
                      handleChangeDay(6);
                    }}
                  />
                </div>
              </div>
              <div>
                <span style={{ fontWeight: 'bold' }}>Horários:</span>
                <div
                  style={{
                    display: 'flex',
                    gap: 5,
                    alignItems: 'center',
                  }}
                >
                  <span>das</span>
                  <DefaultInput
                    placeholder="__:__"
                    mask="99:99"
                    maskChar="0"
                    style={{
                      width: '70px',
                      padding: '0px 10px',
                      height: '30px',
                    }}
                    inputStyle={{ textAlign: 'center' }}
                    value={hourSelected.start}
                    onChange={(e) => {
                      const value = e.target.value;
                      setHourSelected((state) => {
                        return {
                          start: handleTimeInput(value),
                          end: state.end,
                        };
                      });
                    }}
                  />
                  <span>às</span>
                  <DefaultInput
                    placeholder="__:__"
                    mask="99:99"
                    maskChar="0"
                    style={{
                      width: '70px',
                      padding: '0px 10px',
                      height: '30px',
                    }}
                    inputStyle={{ textAlign: 'center' }}
                    value={hourSelected.end}
                    onChange={(e) => {
                      const value = e.target.value;
                      setHourSelected((state) => {
                        return {
                          start: state.start,
                          end: handleTimeInput(value),
                        };
                      });
                    }}
                  />
                </div>
              </div>
            </PersonalizedHoursContainer>
          )}
        </InnerArea>
        <ButtonsArea>
          <PersonalButton
            color="#FF2C3A"
            message="Cancelar"
            style={{
              marginLeft: 0,
            }}
            onClick={() => {
              toggle();
            }}
          />

          <PersonalButton
            color="#0CCAB4"
            message="Salvar"
            type="submit"
            style={{
              marginRight: 10,
            }}
          />
        </ButtonsArea>
      </StyledForm>
    </Container>
  );
}

export default Store;