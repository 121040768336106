import React from 'react';
import ReactStars from 'react-stars';
import {
  QrCodeArea,
  RestaurantName,
  RatingComment,
  RatingAreaHeader,
  RatingInfo,
} from './styles';
import Template from '~/assets/img/banner_avaliacoes.png';
import formatCompleteDate from '~/services/formatCompleteDate';

import { useAuth } from '~/context/AuthContext';

const ClientRatingBanner = React.forwardRef(({ ratingDetails }, ref) => {
  const { user } = useAuth();

  return (
    <QrCodeArea
      ref={ref}
      style={{
        backgroundImage: `url(${Template || ''})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <RestaurantName>{user.fantasy_name}</RestaurantName>

      <RatingAreaHeader>
        {ratingDetails?.product?.image && (
          <img
            src={
              `${ratingDetails?.product?.image?.url_thumb.replace('takeat.app', 's3.amazonaws.com')}?q=${Date.now()}` ||
              `${ratingDetails?.product?.image?.url.replace('takeat.app', 's3.amazonaws.com')}?q=${Date.now()}`
            }
            alt="foto do produto consumido"
          />
        )}


        <RatingInfo>
          <ReactStars
            count={ratingDetails?.stars}
            size={24}
            color1="darkOrange"
          />

          <span style={{ fontWeight: 'bold', fontSize: '18px' }}>
            {ratingDetails?.buyer?.name}
          </span>
          <span style={{ fontSize: '10px' }}>
            Data da visita:{' '}
            {ratingDetails ? formatCompleteDate(ratingDetails?.createdAt) : ''}
          </span>
        </RatingInfo>
      </RatingAreaHeader>

      <RatingComment hasImage={ratingDetails?.product?.image}>{ratingDetails?.comment}</RatingComment>
    </QrCodeArea>
  );
});

export default ClientRatingBanner;
