import React, { useState, useCallback, useEffect } from 'react';

import { toast } from 'react-hot-toast';

import {
  format,
  startOfDay,
  endOfDay,
} from 'date-fns';

import {
  LossTable,
  SearchDiv,
  ButtonsSelectDate,
} from './styles';

import api from '~/services/api';
import formatCompleteDate from '~/services/formatCompleteDate';
import { DownloadButton, FullCalendar, Hyperlink } from 'ui-kit-takeat';
import FileSaver from 'file-saver';
import NfeEntryInfoModal from '~/components/NfesEntry/NfeEntryInfoModal';
import { generateInputNfeReportSheet } from '~/services/SheetGenerate/generateInputNfeReportSheet';

export default function InputNfeReport() {
  const [rangeStartDate, setRangeStartDate] = useState(
    {
      start: startOfDay(new Date()),
      end: endOfDay(new Date())
    }
  );

  const [history, setHistory] = useState([]);

  const handleDownloadSheet = async () => {
    try {
      const sheet = await generateInputNfeReportSheet(history);

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        `Compras (${format(new Date(), 'dd-MM-yyyy')}).xlsx`
      );
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };

  const updateHistory = useCallback(
    async (inicial, final) => {
      try {
        const response = await api.get('/restaurants/history-inputs-report', {
          params: {
            start_date: inicial.toISOString(),
            end_date: final.toISOString(),
            type: '+',
            onlyNfe: true
          },
        });

        setHistory(response.data);
      } catch (error) {
        toast.error('Falha ao carregar dados');
      }
    },
    [setHistory]
  );

  useEffect(() => {
    updateHistory(rangeStartDate.start, rangeStartDate.end);
  }, [rangeStartDate]);

  const [nfeReq, setNfeReq] = useState(null);
  const [modal, setModal] = useState(false);
  function toggle() {
    setModal(!modal);
  }

  const openModal = useCallback(async (id) => {
    try {
      // setLoading(true);
      const response = await api.get(`/restaurants/nfe-received/info/${id}`);
      // setNfeInfo(response.data);
      setNfeReq(
        response.data.requisicao_nota_fiscal
          ? response.data.requisicao_nota_fiscal
          : null
      );
      toggle();
      // setLoading(false);
    } catch (error) {
      toast.error('Não foi possível carregar a Nfe desejada, tente novamente');
    }
  }, []);

  const formatValue = (value) => {
    return (
      'R$' +
      parseFloat(value).toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  };

  return (
    <div
      style={{
        background: '#fff',
        borderRadius: 8,
        padding: '20px',
        boxShadow: '1px 0 5px 1px rgba(0, 0, 0, 0.1)',
      }}
    >
      <SearchDiv>
        <DownloadButton
          onClick={() => {
            if (history.length > 0) {
              handleDownloadSheet();
            }
          }}
        />

        <ButtonsSelectDate>
          <FullCalendar
            isRange
            onDateSelected={setRangeStartDate}
            value={rangeStartDate}
            hideFields={[
              'this_year'
            ]}
            
          />
        </ButtonsSelectDate>
      </SearchDiv>

      <LossTable borderless>
        <thead>
          <tr>
            <th>Insumo</th>
            <th>Compra</th>
            <th>Custo</th>
            <th>Preço Unitário</th>
            <th>Fornecedor</th>
            <th>Centro de custo</th>
            <th>Nº Nota</th>
            <th>Data</th>
            <th>Usuário</th>
          </tr>
        </thead>
        <tbody>
          {history?.length ? (
            history.map((item) => (
              <tr key={item.id}>
                <td> {item.name} </td>
                <td>
                  +
                  {`${parseFloat(item.quantidade).toFixed(3)} ${item.unidade}`}{' '}
                </td>
                <td> {item.cmv !== null ? formatValue(item.total) : '-'} </td>
                <td> {formatValue(item.unitary_price)} </td>
                <td> {item?.nfe?.nome_emitente || '-'} </td>
                <td>{item?.category_subcategory === ""? item?.category_subcategory : "-"}</td>
                <td>
                  <Hyperlink onClick={(e) => openModal(item.nfe.id)} fontSize="14px"> 
                    {item?.nfe?.numero || '-'} 
                  </Hyperlink>
                </td>
                <td> {formatCompleteDate(item.created_at)} </td>
                <td> {item.user?.name || '-'} </td>
              </tr>
            ))
          ) : (
            <tr>
              <td style={{ textAlign: 'center' }} colSpan={7}>
                {' '}
                Período sem dados!{' '}
              </td>
            </tr>
          )}
        </tbody>
      </LossTable>

      <NfeEntryInfoModal modal={modal} toggle={toggle} nfeReq={nfeReq} />

    </div>
  );
}