import React, { useState } from "react";
import {
  ProductCard,
  Container,
  ProductInfo,
  ProductImg,
  CountBadge,
  ProductsNotFound,
} from './styles';
import { useCart } from '~/context/OrderingSystem/Cart';
import { TypeBadge } from '~/components/TypeBadge';
import toast from "react-hot-toast";


export const ProductsArea = ({ category, setSelectedProduct, setIsSelectingComplements,weight }) => {
  const { addToCart, countProducts, productsHasCounted } = useCart();

  function handleSelectProduct(product) {    
    if (product?.complement_categories?.length > 0 || (product?.use_weight && product?.complement_categories?.length > 0)) {
      const weightPrice = parseFloat(product.price) * parseFloat(product.weight);
      setSelectedProduct({
        ...product,
        price: product.combo_price||product?.price_promotion || product?.price,
        weight_price:weightPrice
      });
      setIsSelectingComplements(true);
    }
    else if(product?.use_weight && weight === '0') {
      toast.error('Insira o peso do produto')
    }
    else {
      countProducts({ id: product.id, amount: 1 });
      const productFormatted = {
        ...product,
        price: product.combo_price || product.price_promotion || product.price,
      };


      addToCart({
        product: productFormatted,
        amount: 1,
        observation: '',
        complementsCategory: [],
        isProductWeight: product?.use_weight,
        weightPrice: parseFloat(product.weight) * parseFloat(product.price),
      });
      setIsSelectingComplements(false)
    }
  }

  const productsFiltered = category?.products?.filter((product) => product.available).filter((product)=>
    !(product.use_weight && product.complement_categories?.length === 0));
  
  return (
    <Container>
      {productsFiltered?.length > 0 ? (
        productsFiltered?.map((product) => {
          const price = product?.price_promotion || product?.price;
          const badgeCount = productsHasCounted
            ? productsHasCounted.find((pr) => pr.id === product.id)?.amount
            : 0;

          return (
            <ProductCard key={product.id} onClick={() => {
              handleSelectProduct(product);
            }}>
            {product.is_combo && <TypeBadge type="COMBO" />}
            {product.use_weight && <TypeBadge type="PESO" />}
              <ProductImg src={product.image?.url || 'static/media/ImgPlaceholder.ae20e374.png'} />
              <ProductInfo>
                <h5>{product.name}</h5>
                <span>R$ {price.replace('.', ',')}</span>
              </ProductInfo>
              {badgeCount > 0 && <CountBadge>{badgeCount}</CountBadge>}
            </ProductCard>
          );
        })
      ) : (
        <ProductsNotFound>Nenhum produto encontrado</ProductsNotFound>
      )}
    </Container>
  );
};
