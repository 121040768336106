import styled from 'styled-components';

export const CategorySelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 150px;
  min-width: 150px;
  overflow-y: scroll;
`;

export const Title = styled.span`
  display: flex;
  gap: 10px;
  align-items: center;
  color: #979797;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
`;
