import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { FaMinus } from 'react-icons/fa6';
import { DefaultInput } from 'ui-kit-takeat';
import { ComplementAddedContainer } from './styles';
import { usePreCart } from '~/context/PreCartContext';

export const ComplementAdded = ({ category, complement }) => {
  const { incrementOnPreCart, decrementOnPreCart, changeComplementPreCart } =
    usePreCart();

  return (
    <ComplementAddedContainer>
      <p>{complement.name}</p>
      <FaMinus onClick={() => decrementOnPreCart(category, complement)} />
      <DefaultInput
        value={complement.amount || 0}
        style={{ padding: 0, height: 30 }}
        containerStyles={{ width: 30, minWidth: 30 }}
        inputStyle={{
          fontWeight: 500,
          textAlign: 'center',
          fontSize: 12,
          height: 30,
        }}
        onChange={(e) => {
          if (e.target.value.length > 0 && !/^\d+$/.test(e.target.value))
            return;

          const value =
            e.target.value.length < 1 ? 0 : parseInt(e.target.value);
          changeComplementPreCart(category, complement, value);
        }}
      />
      <FaPlus onClick={() => incrementOnPreCart(category, complement)} />
    </ComplementAddedContainer>
  );
};
