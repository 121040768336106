import React from 'react';
import OrderCard from '../OrderCard';
import Masonry from 'react-masonry-css';

function OrdersNew({ baskets, setBaskets, kdsViewMode }) {
  const breakpointColumnsObj = {
    default: kdsViewMode,
    1100: 3,
    700: 2,
    500: 1
  };

  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
    >
      {baskets?.length > 0 &&
        baskets
          ?.filter(
            (item) => item.status === 'pending' || item.status === 'doing'
          )
          .map((item) => (
            <OrderCard
              item={item}
              baskets={baskets}
              setBaskets={setBaskets}
              key={item.id}
            />
          ))}
    </Masonry>
  );
}

export default OrdersNew;
