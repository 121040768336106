import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
} from 'react';
import { FiArrowDown } from 'react-icons/fi';
import { TabContent, TabPane, Spinner, Row, Col, ModalBody, Modal, ModalFooter } from 'reactstrap';
import formatCompleteDate from '~/services/formatCompleteDate';
import Input from '~/components/Form/Input';
import {
  CategoriesTable,
  Container,
  NavDiv,
  LoadingDiv,
  EmptyDiv,
  Content,
  OrdersContainer,
  OrdersHeader,
} from './styles';

import BillOperation from '~/components/BillOperation';

import OrderAndamento from '~/components/Orders/Items/OrderAndamento';
import OrderFinished from '~/components/Orders/Items/OrderFinished';
import OrderCanceled from '~/components/Orders/Items/OrderCanceled';
import WaiterTicket from '~/components/WaiterTickets';

import OrderNew from '~/components/Orders/Items/OrderNew';
import { useOrder } from '~/context/orderContext';
import CloseTable from '~/components/Tables/CloseOrder/Items/CloseTable';
import CloseIndividual from '~/components/Tables/CloseOrder/Items/CloseIndividual';
import OpeningBox from '~/pages/Financial/CashManagementPage/OpeningBox';
import { useAuth } from '~/context/AuthContext';
import { usePos } from '~/context/PosContext';

import ButtonDefault from '~/components/Buttons/DefaultButton';
import { Button, Hyperlink, Tabs } from 'ui-kit-takeat';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import api from '~/services/api';
import toast from 'react-hot-toast';
import { FaCheck } from 'react-icons/fa';
import { Form } from '@unform/web';

function OrdersOperation(
  {
    sessions,
    getSessions,
    loading,
    minimalSessions,
    setSessions,
    inicialDate,
    finalDate,
    orderByTableDate,
    pendingBaskets,
    acceptedBaskets,
    canceledBaskets,
    finishedBaskets,
    handleItemFinished,
    handleCancelItem,
    handlePrintItem,
    tableBills,
    printQueueTable,
    printTableBill,
    printIndividualBill,
    individualBills,
    getTablesAvailable,
    getMinimalSessions,
    setAllTablesAvailable,
    setTablesFilteredSearch,
    setInputValueTables,
    waiterTickets,
  },
  ref
) {
  const { allPix, getPixPayments, pixLoading } = useOrder();
  const { user } = useAuth();
  const { cancelAllStoneTransactions } = usePos();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(0);
  const [finishedSortType, setFinishedSortType] = useState('date');
  const [completedSortType, setCompletedSortType] = useState('date');
  const [sessionsMinimalFinished, setSessionsMinimalFinished] = useState([]);
  const [sessionsMinimalCompleted, setSessionsMinimalCompleted] = useState([]);
  const [horizontalPadding, setHorizontalPadding] = useState(20);
  const [sortType, setSortType] = useState('date-desc');

  const [modalTotem, setModalTotem] = useState(false);
  function toggleTotem() {
    setModalTotem(!modalTotem);
  }

  const [modalPassword, setModalPassword] = useState(false);
  function togglePassword() {
    setModalPassword(!modalPassword);
  }

  const [adminPassword, setAdminPassword] = useState('');

  useEffect(() => {
    const finished = sortBy(
      minimalSessions.filter(
        (session) => session.status === 'finished' && !session.is_delivery
      ),
      finishedSortType
    );
    const completed = sortBy(
      minimalSessions.filter(
        (session) => session.status === 'completed' && !session.is_delivery
      ),
      completedSortType
    );

    setSessionsMinimalFinished(finished);
    setSessionsMinimalCompleted(completed);
  }, [minimalSessions, completedSortType, finishedSortType]);

  function sortBy(sessions, type_, info) {
    if (type_ === 'table') {
      sortSessionsByTableNumber(sessions);
    } else {
      sortSessionsByDate(sessions);
    }

    return sessions;
  }

  function sortSessionsByTableNumber(sessions) {
    sessions.sort((a, b) => {
      return a.table.table_number - b.table.table_number;
    });
  }

  function sortSessionsByDate(sessions) {
    sessions.sort((a, b) => {
      if (new Date(a.start_time).valueOf() < new Date(b.start_time).valueOf()) {
        return 1;
      }
      return -1;
    });
  }

  function selectFinishedSessionBy(type_) {
    setFinishedSortType(type_);

    if (type_ === 'table') {
      sortSessionsByTableNumber(sessionsMinimalFinished);
    } else {
      sortSessionsByDate(sessionsMinimalFinished);
    }
  }

  function selectCompletedSessionBy(type_) {
    setCompletedSortType(type_);

    if (type_ === 'table') {
      sortSessionsByTableNumber(sessionsMinimalCompleted);
    } else {
      sortSessionsByDate(sessionsMinimalCompleted);
    }
  }
  const sessionsCompletedLength = sessionsMinimalCompleted.length;
  const sessionsFinishedLength = sessionsMinimalFinished.length;

  const openingBoxRef = useRef(null);

  const updateOpeninBox = useCallback(() => {
    openingBoxRef.current.updateBox();
  }, []);

  useImperativeHandle(ref, () => {
    return {
      updateOpeninBox,
    };
  });

  const getSortedArray = (type) => {
    switch (type) {
      case 'table-asc':
        return sessionsMinimalFinished.sort((a, b) => {
          return a.table.table_number - b.table.table_number;
        });
      case 'table-desc':
        return sessionsMinimalFinished.sort((a, b) => {
          return b.table.table_number - a.table.table_number;
        });
      case 'date-asc':
        return sessionsMinimalFinished.sort((a, b) => {
          if (
            new Date(a.start_time).valueOf() > new Date(b.start_time).valueOf()
          ) {
            return 1;
          }
          return -1;
        });
      case 'date-desc':
        return sessionsMinimalFinished.sort((a, b) => {
          if (
            new Date(a.start_time).valueOf() < new Date(b.start_time).valueOf()
          ) {
            return 1;
          }
          return -1;
        });
      default:
        return sessionsMinimalFinished;
    }
  };

  useEffect(() => {
    setHorizontalPadding(
      window.innerWidth < 1380
        ? window.innerWidth < 1270
          ? window.innerWidth < 1200
            ? 5
            : 10
          : 15
        : 20
    );
  }, []);

  const [selfServiceTransactions, setSelfServiceTransactions] = useState([]);

  const [loadingConfirmPayment, setLoadingConfirmPayment] = useState(false);

  const openTotemModal = useCallback(async () => {
    try {
      const response = await api.get(
        '/restaurants/stone/transactions/self-service'
      );

      setSelfServiceTransactions(response.data);
      toggleTotem();
    } catch (error) {
      toast.error('Erro ao carregar pagamentos do totem');
    }
  }, [toggleTotem]);

  const confirmTotemPayment = useCallback(async (id) => {
    setLoadingConfirmPayment(true);
    try {
      await api.post(
        `/restaurants/stone/confirm/${id}`
      );

      getMinimalSessions();
      setModalPassword(false);
      toggleTotem();
      toast.success('Pagamento confirmado!');
    } catch (error) {
      toast.error('Erro ao carregar pagamentos do totem');
    }
    setLoadingConfirmPayment(false);
  }, [toggleTotem]);

  function getStatusTransaction(status) {
    switch (status) {
      case 'paid':
        return 'Pago';
      case 'pending':
        return 'Pendente';
      case 'canceled':
        return 'Cancelado';
      case 'closed':
        return 'Finalizado';
      default:
        return status;
    }
  }

  function getColorTransaction(status) {
    switch (status) {
      case 'paid':
        return 'green';
      case 'pending':
        return '#CC8500';
      case 'canceled':
        return 'red';
      case 'closed':
        return 'green';
      default:
        return 'black';
    }
  }

  function getTransactionType(type) {
    switch (type) {
      case 'credit':
        return 'Crédito';
      case 'debit':
        return 'Débito';
      default:
        return type;
    }
  }

  function confirmAdminPassword() {
    if (adminPassword === user.order_cancel_password) {
      return true;
    } else {
      toast.error('Senha administrativa incorreta');
      return false;
    }
  }

  return (
    <Container>
      <NavDiv>
        <Tabs
          tabs={['Pedidos', 'Fechadas', 'Caixa', 'Pgtos Online']}
          selected={activeTab}
          counter={[null, sessionsFinishedLength, null, null]}
          mediaQueries={[
            {
              maxWidth: 1350,
              styles: `padding: 5px 15px;`,
            },
            {
              maxWidth: 1265,
              styles: `padding: 5px 10px;`,
            },
            {
              maxWidth: 1185,
              styles: `padding: 5px 5px;`,
            },
            ,
            {
              maxWidth: 1110,
              styles: `font-size: 12px;`,
            },
          ]}
          onChangeTab={(e) => setActiveTab(e)}
        />
        <Hyperlink
          fontSize={window.innerWidth < 1110 ? 12 : 14}
          onClick={() => history.push('/operation/bills?isCompleted=true')}
        >
          Comandas finalizadas
        </Hyperlink>
      </NavDiv>
      <OrdersContainer isFirst={activeTab === 0}>
        <TabContent activeTab={activeTab} style={{ height: '100%' }}>
          <TabPane tabId={0} style={{ height: '100%' }}>
            <Content>
              {tableBills &&
                tableBills.length > 0 &&
                tableBills.map((item) => (
                  <CloseTable
                    key={item.id}
                    item={item}
                    handlePrintItem={printTableBill}
                    printQueueTable={printQueueTable}
                    tableBills={tableBills}
                    // payments={payments}
                    getSessions={getSessions}
                  />
                ))}

              {individualBills &&
                individualBills.length > 0 &&
                individualBills.map((item) => (
                  <CloseIndividual
                    key={item.id}
                    item={item}
                    handlePrintItem={printIndividualBill}
                    individualBills={individualBills}
                  />
                ))}
              {pendingBaskets &&
                pendingBaskets?.length > 0 &&
                pendingBaskets
                  .filter((order) => order.table.table_type !== 'delivery')
                  .map((item) => (
                    <OrderNew
                      key={item.basket.id}
                      handleCancelItem={handleCancelItem}
                      handlePrintItem={handlePrintItem}
                      item={item}
                    />
                  ))}

              {waiterTickets &&
                waiterTickets.length > 0 &&
                waiterTickets.map((ticket) => <WaiterTicket item={ticket} />)}

              {acceptedBaskets &&
                acceptedBaskets?.length > 0 &&
                acceptedBaskets
                  .filter((order) => order?.table?.table_type !== 'delivery')
                  .map((item) => (
                    <OrderAndamento
                      key={item.basket.id}
                      handleCancelItem={handleCancelItem}
                      handleItemFinished={handleItemFinished}
                      item={item}
                    />
                  ))}

              {finishedBaskets &&
                finishedBaskets?.length > 0 &&
                finishedBaskets
                  .filter((order) => order?.table?.table_type !== 'delivery')
                  .map((item) => (
                    <OrderFinished
                      key={item?.basket && item.basket.id}
                      handleCancelItem={handleCancelItem}
                      item={item}
                    />
                  ))}

              {canceledBaskets &&
                canceledBaskets?.length > 0 &&
                canceledBaskets
                  .filter((order) => order?.table?.table_type !== 'delivery')
                  .map((item) => <OrderCanceled key={item?.id} item={item} />)}

              {tableBills.length +
                individualBills.length +
                pendingBaskets.filter(
                  (order) => order.table.table_type !== 'delivery'
                ).length +
                waiterTickets.length +
                acceptedBaskets.filter(
                  (order) => order?.table?.table_type !== 'delivery'
                ).length +
                finishedBaskets.filter(
                  (order) => order?.table?.table_type !== 'delivery'
                ).length +
                canceledBaskets.filter(
                  (order) => order?.table?.table_type !== 'delivery'
                ).length <=
                0 && (
                  <EmptyDiv>
                    <span>Não há pedidos no momento.</span>
                  </EmptyDiv>
                )}
            </Content>
          </TabPane>

          <TabPane tabId={1} style={{ height: '100%' }}>
            <Content>
              <OrdersHeader>
                <span
                  onClick={() => {
                    setSortType(
                      sortType === 'table-desc' ? 'table-asc' : 'table-desc'
                    );
                    selectCompletedSessionBy('table');
                  }}
                  title="Ordenar pela mesa"
                  style={{ cursor: 'pointer' }}
                >
                  {sortType?.split('-')[0] === 'table' && (
                    <FiArrowDown
                      size={14}
                      style={{
                        transform:
                          sortType === 'table-asc' && 'rotateZ(180deg)',
                      }}
                    />
                  )}{' '}
                  Mesa
                </span>
                <span
                  onClick={() => {
                    setSortType(
                      sortType === 'date-desc' ? 'date-asc' : 'date-desc'
                    );
                    selectCompletedSessionBy('date');
                  }}
                  title="Ordenar pela data"
                  style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                >
                  {sortType?.split('-')[0] === 'date' && (
                    <FiArrowDown
                      size={14}
                      style={{
                        transform: sortType === 'date-asc' && 'rotateZ(180deg)',
                      }}
                    />
                  )}{' '}
                  Data/Hora
                </span>
                <span>Nº</span>
                <span>Valor Total</span>
                <span>Valor pago</span>
              </OrdersHeader>
              {loading ? (
                <LoadingDiv>
                  <Spinner color="success" />
                </LoadingDiv>
              ) : (
                <>
                  {getSortedArray(sortType).map((session) => (
                    <BillOperation
                      session={session}
                      key={session.id}
                      getSessions={getMinimalSessions}
                      setInputValueTables={setInputValueTables}
                      // payments={payments}
                      // setAllPayments={setAllPayments}
                      // paymentMethods={paymentMethods}
                      setSessions={setSessions}
                      sessions={sessions}
                      inicialDate={inicialDate}
                      finalDate={finalDate}
                      getTablesAvailable={getTablesAvailable}
                      orderByTableDate={orderByTableDate}
                      getMinimalSessions={getMinimalSessions}
                      setAllTablesAvailable={setAllTablesAvailable}
                      setTablesFilteredSearch={setTablesFilteredSearch}
                    />
                  ))}
                </>
              )}
              {sessionsFinishedLength === 0 && (
                <EmptyDiv>
                  <span>Não há comandas fechadas no momento.</span>
                </EmptyDiv>
              )}
            </Content>
          </TabPane>

          <TabPane tabId={5}>
            <Content>
              <CategoriesTable borderless>
                <thead>
                  <tr>
                    <th
                      onClick={() => selectCompletedSessionBy('table')}
                      title="Ordenar pela mesa"
                      style={{ cursor: 'pointer' }}
                    >
                      <FiArrowDown size={14} /> Mesa
                    </th>
                    <th
                      onClick={() => selectCompletedSessionBy('date')}
                      title="Ordenar pela data"
                      style={{ cursor: 'pointer' }}
                    >
                      <FiArrowDown size={14} /> Data/Hora
                    </th>
                    <th>Nº</th>
                    <th>Valor</th>
                    <th>Status</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                {loading ? (
                  <tbody>
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td>
                        <LoadingDiv>
                          <Spinner color="success" />
                        </LoadingDiv>
                      </td>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {sessionsMinimalCompleted.map((session) => (
                      <BillOperation
                        session={session}
                        key={session.id}
                        getSessions={getMinimalSessions}
                        // payments={payments}
                        // setAllPayments={setAllPayments}
                        // paymentMethods={paymentMethods}
                        setSessions={setSessions}
                        sessions={sessions}
                        inicialDate={inicialDate}
                        finalDate={finalDate}
                        getTablesAvailable={getTablesAvailable}
                        orderByTableDate={orderByTableDate}
                        getMinimalSessions={getMinimalSessions}
                      />
                    ))}
                  </tbody>
                )}
              </CategoriesTable>
              {sessionsCompletedLength === 0 && (
                <EmptyDiv>
                  <span>Não há comandas finalizadas no momento.</span>
                </EmptyDiv>
              )}
            </Content>
          </TabPane>

          <TabPane tabId={2}>
            <Content>
              <OpeningBox ref={openingBoxRef} />
            </Content>
          </TabPane>

          {(user.has_pix || user.has_stone_pdv || user.has_stone_pos) && (
            <TabPane tabId={3}>
              <Content>
                <Row style={{ margin: 0 }}>
                  {user.has_stone_pdv && user.stone_device_id && (
                    <Col md={user.has_stone_pos ? "6" : "8"}>
                      <ButtonDefault
                        message="Cancelar Pendentes Stone"
                        style={{
                          float: 'left',
                          background: '#00a868',
                          fontSize: 13
                        }}
                        onClick={() => cancelAllStoneTransactions()}
                      />
                    </Col>
                  )}

                  {user.has_stone_pos &&
                    <>
                      <Col md="2">
                        <ButtonDefault
                          message="Totem"
                          isLoading={pixLoading}
                          disabled={pixLoading}
                          onClick={() => openTotemModal()}
                          style={{ marginLeft: 'auto' }}
                        />
                      </Col>

                      <Modal isOpen={modalTotem} toggle={toggleTotem} size="lg">
                        <ModalBody>
                          <Row>
                            <Col>
                              <h4>Últimos pagamentos feitos pelo totem</h4>
                            </Col>
                          </Row>
                          <CategoriesTable borderless>
                            <thead>
                              <tr>
                                <th>Hora</th>
                                <th>Tipo</th>
                                <th>Valor</th>
                                <th>Status</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {selfServiceTransactions.map((transaction) => (
                                <tr key={transaction.id}>
                                  <td>{formatCompleteDate(transaction.createdAt)}</td>
                                  <td>{getTransactionType(transaction.type)}</td>
                                  <td>{transaction.amount && `R$${parseInt(transaction.amount) / 100}`}</td>
                                  <td style={{ color: getColorTransaction(transaction.status) }}>
                                    {getStatusTransaction(transaction.status)}
                                  </td>
                                  <td>
                                    {transaction.status === 'pending' &&
                                      <div>
                                        <Button
                                          containerStyles={{ fontSize: 14, float: 'right' }}
                                          title={loadingConfirmPayment ? 'Aguarde' : 'Confirmar'}
                                          icon={loadingConfirmPayment ? <Spinner style={{ width: '13px', height: '13px' }} /> : <FaCheck />}
                                          buttonColor="#2ec9b7"
                                          // onClick={() => (user.order_cancel_password && user.order_cancel_password !== '') ? togglePassword() : confirmTotemPayment(transaction.id)}
                                          onClick={() => confirmTotemPayment(transaction.id)}
                                          disabled={loadingConfirmPayment}
                                        />

                                        <Modal isOpen={modalPassword} toggle={togglePassword}>
                                          <Form>
                                            <ModalBody>
                                              <Input
                                                type="password"
                                                name="password"
                                                label="Senha administrativa"
                                                onChange={e => setAdminPassword(e.target.value)}
                                              />
                                            </ModalBody>
                                          </Form>
                                          <ModalFooter>
                                            <Button
                                              containerStyles={{ fontSize: 14, marginLeft: 'auto' }}
                                              title={loadingConfirmPayment ? 'Aguarde' : 'Confirmar'}
                                              icon={loadingConfirmPayment ? <Spinner style={{ width: '13px', height: '13px' }} /> : <FaCheck />}
                                              buttonColor="#2ec9b7"
                                              onClick={() => {
                                                if (confirmAdminPassword()) {
                                                  confirmTotemPayment(transaction.id)
                                                }
                                              }}
                                              disabled={loadingConfirmPayment}
                                            />
                                          </ModalFooter>
                                        </Modal>
                                      </div>
                                    }
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </CategoriesTable>
                        </ModalBody>
                      </Modal>
                    </>
                  }

                  {user.has_pix && (
                    <Col md="4">
                      <ButtonDefault
                        message="Atualizar Pagamentos"
                        isLoading={pixLoading}
                        disabled={pixLoading}
                        onClick={() => getPixPayments()}
                        style={{ backgroundColor: '#6bd098' }}
                      />
                    </Col>
                  )}
                </Row>

                <CategoriesTable borderless>
                  <thead>
                    <tr>
                      <th>Mesa/Comanda</th>
                      <th>Tipo</th>
                      <th>Telefone</th>
                      <th>Valor</th>
                      <th>Horário</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allPix && allPix.map((pix) => (
                      <tr>
                        <td>{pix.table_session.table.table_number}</td>
                        <td>
                          {pix.individual_bill_id
                            ? 'Individual'
                            : pix.table_session.table.table_type === 'command'
                              ? 'Comanda'
                              : 'Mesa'}
                        </td>
                        <td>{pix?.phone}</td>
                        <td>R${pix?.total}</td>
                        <td>{pix?.payed_at && formatCompleteDate(pix?.payed_at)}</td>
                      </tr>
                    ))}
                  </tbody>
                </CategoriesTable>
              </Content>
            </TabPane>
          )}
        </TabContent>
      </OrdersContainer>
    </Container>
  );
}

export default React.forwardRef(OrdersOperation);
