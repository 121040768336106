import styled from 'styled-components';

export const ComplementsAreaContainer = styled.div`
  width: 65%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const BackButton = styled.button`
  width: fit-content;
  display: flex;
  gap: 5px;
  background: transparent;
  align-items: center;
  border: none;

  svg {
    height: 13px;
    min-height: 13px;
    color: #4d4d4c;
  }

  span {
    color: #4d4d4c;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const ProductName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  h2 {
    margin: 0;
    color: #4d4d4c;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const ProductQuantity = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    width: 16px;
    height: 16px;
    color: #ff2c3a;
    cursor: pointer;
  }
`;

export const ProductDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    margin: 0;
    color: #4d4d4c;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 60%;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const SelectionArea = styled.div`
  width: 100%;
  height: calc(100% - 133px);
  display: flex;
  gap: 10px;
`;
