import React, { useState, useEffect, useCallback } from 'react';
import produce from 'immer';
import { toast } from 'react-hot-toast';

import { MdCached } from 'react-icons/md';
import OrderProductCategoryContext from './context';
import { useAuth } from '~/context/AuthContext';

import {
  Container,
  Header,
  ProductCategoriesContainer,
  ReorderContainer,
} from './styles';

import api from '~/services/api';
import CategoryItem from './CategoryItem';

function OrderProductCategory({ getCategory }) {
  const [categories, setCategories] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const { user } = useAuth();

  const getCategories = useCallback(async () => {
    const response = await api.get('/restaurants/product-categories-order');

    setCategories(response.data.categories);
    const options = [];
    for (let i = 1; i <= response.data.count; i += 1) {
      options.push({
        label: i,
        value: i,
      });
    }
    setCategoriesOptions(options);
  }, []);

  useEffect(() => {
    try {
      getCategories();
    } catch (err) {
      // console.tron.log(err);
      toast.error('Erro ao carregar dados');
    }
  }, [getCategories]);

  async function move(from_category_id, to_category_id, from, to) {
    setCategories(
      produce(categories, (draft) => {
        const dragged = draft[from];

        draft.splice(from, 1);
        draft.splice(to, 0, dragged);
      })
    );
  }

  async function onDrop(from_category_id, to_category_id, from, to) {
    try {
      await api.put('/restaurants/product-categories-order', {
        from_product_category_id: from_category_id,
        to_product_category_id: to_category_id,
        to_position: to,
        from_position: from,
      });
    } catch (err) {
      toast.error('Erro ao atualizar posição da categoria');
      getCategories();
    }
  }

  async function reorderProducts() {
    try {
      await api.post(`/restaurants/products/reorder/${user.id}`);

      toast.success('Produtos reordenados com sucesso!');

      getCategories();
    } catch (err) {
      // console.tron.log(err);
      toast.error('Erro ao ordenar cardápio');
    }

    // window.location.reload();
  }

  return (
    <OrderProductCategoryContext.Provider value={{ categories, move, onDrop }}>
      <Container>
        <Header>
          <div>
            <h2>Ordenar cardápio</h2>
            <p>
              Ordene aqui como ficará seu cardápio, basta clicar e arrastar o
              card da Categoria de produto para a posição desejada
            </p>
          </div>
        </Header>

        <ReorderContainer>
          {/* <p>
              Caso os produtos continuem fora de ordem no cardapio do cliente,
              favor clicar no botao Normalizar Ordenação e refazer, ordenando os
              produtos um por vez.
            </p> */}
          <button type="button" onClick={reorderProducts}>
            <MdCached /> Normalizar Ordenação
          </button>
        </ReorderContainer>

        <ProductCategoriesContainer>
          {categories.map((category, index) => (
            <CategoryItem
              key={category.id}
              index={index}
              category={category}
              categoriesOptions={categoriesOptions}
              getCategories={getCategories}
            />
          ))}
        </ProductCategoriesContainer>
      </Container>
    </OrderProductCategoryContext.Provider>
  );
}

export default OrderProductCategory;
