import React, { useRef, useCallback } from 'react';

import { toast } from 'react-hot-toast';
import {
  FaCheck,
  FaCheckSquare,
  FaStickyNote,
} from 'react-icons/fa';

import {
  CategoriesTable,
  AreaTable,
  CategoriesTableData,
} from './styles';

import Annotation from '../Annotation';

import api from '~/services/api';
import formatCompleteDate from '~/services/formatCompleteDate';

function AuditCashierTable({ cashier, getCashiers }) {
  const annotationRef = useRef(null);

  const toggleAnnotation = useCallback(() => {
    annotationRef.current.handleOpenAnnotation();
  }, []);

  const auditedValues = useRef(
    (cashier?.cashier_audit_items || []).map((item) => item.audited_value)
  );

  function onAuditedValueChange(value, idx) {
    auditedValues.current[idx] = value;
  }

  async function handleSubmitAuditValue(id, idx) {
    const value = auditedValues.current[idx];

    try {
      await api.put(`/restaurants/cashier-audit/item/${id}`, {
        checked: true,
        audited_value: value,
      });

      toast.success('Valor conferido!');
      getCashiers();
    } catch (error) {
      toast.error('Erro ao atualizar valor');
    }
  }

  return (
    <AreaTable>
      <Annotation
        ref={annotationRef}
        cashier={cashier}
        getCashiers={getCashiers}
      />
      {console.log(cashier)}

      <CategoriesTable>
        <thead>
          <tr>
            <th colSpan={2} style={{ textAlign: 'left' }}>
              Caixa #{cashier?.cashier_opening_id}
              {cashier.owner_user && (
                <p style={{ fontWeight: 'normal' }}>
                  Usuário: {cashier.owner_user?.name}
                </p>
              )}
            </th>
            {cashier?.cashier_audit_items.filter(item => item.payment_method.name !== "Prazo").map((item) => (
              <th>
                {item.payment_method.name === 'Dinheiro'
                  ? 'Fundo de Caixa'
                  : item.payment_method.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <td>
            <tr style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ textAlign: 'left' }}> Abertura</span>
              <span>{formatCompleteDate(cashier?.opened_at)}</span>
            </tr>
            <tr style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ textAlign: 'left', marginTop: 8 }}>
                Fechamento
              </span>
              <span>{formatCompleteDate(cashier?.closed_at)}</span>
            </tr>
            <tr>
              <div style={{ marginLeft: -10 }}>
                <span>Anotações</span>
                <FaStickyNote
                  color="grey"
                  style={{ marginLeft: 8, cursor: 'pointer' }}
                  onClick={toggleAnnotation}
                  title="Escrever anotação"
                />
              </div>
            </tr>
          </td>
          <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
            <tr>Sistema</tr>
            <tr>Caixa</tr>
            <tr>Conferência</tr>
          </td>
          {cashier?.cashier_audit_items.filter(item => item.payment_method.name !== "Prazo").map((item, idx) => (
            <td>
              <tr>R$ {item.system_value}</tr>
              <tr style={{ position: 'relative' }}>
                <span>R$ {item.value}</span>{' '}
                {Number(item.value - item.system_value) > 0 && (
                  <span
                    style={{
                      color: '#0CCAB4',
                      position: 'absolute',
                      fontSize: 10,
                      top: '20px',
                      left: '35px',
                    }}
                  >
                    + R$ {(item.value - item.system_value).toFixed(2)}
                  </span>
                )}{' '}
                {Number(item.value - item.system_value) < 0 && (
                  <span
                    style={{
                      color: 'red',
                      fontSize: 10,
                      position: 'absolute',
                      top: '20px',
                      left: '35px',
                    }}
                  >
                    - R$ {Math.abs(item.value - item.system_value).toFixed(2)}
                  </span>
                )}{' '}
              </tr>
              <tr>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <input
                      type="number"
                      placeholder={
                        item.checked ? `R$ ${item.audited_value}` : 'R$'
                      }
                      name="item_audit_value"
                      style={{
                        width: '90px',
                        borderRadius: 7,
                        padding: '4px 8px',
                        border: item.checked
                          ? '1px solid #0CCAB4'
                          : '1px solid lightGrey',
                      }}
                      readOnly={item.checked}
                      onChange={(e) =>
                        onAuditedValueChange(e.target.value, idx)
                      }
                    />
                    {item.checked ? (
                      <FaCheck
                        style={{ marginLeft: 8 }}
                        color="#0CCAB4"
                        title="Confirmado"
                      />
                    ) : (
                      // <button>

                      // </button>
                      <FaCheckSquare
                        style={{ marginLeft: 4, cursor: 'pointer' }}
                        color="#0CCAB4"
                        title="Confirmar e enviar"
                        size={30}
                        onClick={(e) => handleSubmitAuditValue(item.id, idx)}
                      />
                    )}
                  </div>
                  {item.checked && (
                    <span style={{ color: '#0CCAB4', fontSize: 10 }}>
                      Confirmado
                    </span>
                  )}
                </div>
              </tr>
            </td>
          ))}
        </tbody>
      </CategoriesTable>
      <CategoriesTableData style={{ width: '45%', marginLeft: 5 }}>
        <thead>
          <tr>
            <th style={{ textAlign: 'left' }}>Dinheiro</th>
            <th>Sangria</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody style={{ paddingTop: 60 }}>
          <td>
            <tr> R$ {cashier?.subtotal?.system_value || '0.00'}</tr>
            <tr>R$ {cashier?.subtotal?.value || '0.00'}</tr>
            <tr>-</tr>
          </td>

          <td>
            <tr>-</tr>
            <tr>R$ {cashier?.manual_withdrawal}</tr>
            <tr>-</tr>
          </td>

          <td>
            <tr>
              R${' '}
              {(
                Number(cashier?.total_system_value) -
                Number(cashier?.subtotal?.system_value || 0)
              ).toFixed(2)}
            </tr>
            <tr>
              R${' '}
              {(
                Number(cashier?.total_value) -
                Number(cashier?.subtotal?.value || 0)
              ).toFixed(2)}
            </tr>
            <tr>R$ {cashier?.total_audited_value}</tr>
          </td>
        </tbody>
      </CategoriesTableData>
    </AreaTable>
  );
}

export default AuditCashierTable;
