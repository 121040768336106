import styled from 'styled-components';

export const BadgeContainer = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 9px 0 9px 0;
  padding: 4px 8px;
  width: fit-content;
  background-color: ${({ color }) => color};

  font-family: Poppins;
  font-size: 10px;
  font-weight: 700;
  line-height: 15px;
  text-align: center;
  color: white;
`;
