/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import { FaCheck, FaEllipsisV } from 'react-icons/fa';

import Switch from 'react-switch';
import { toast } from 'react-hot-toast';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { useAuth } from '~/context/AuthContext';
import api from '~/services/api';

import {
  Container,
  TdStyled,
  NumberFormatStyled,
  SubmitStyledButton,
  DropdownDiv,
  ComplementNameTable,
} from './styles';
import beforeBg from '~/assets/img/line-circle.svg';
import EditComplementInsideProducts from './EditComplementInsideProducts';
import ReactModal from 'react-modal';
import { differenceInMinutes, format } from 'date-fns';

function ComplementCategoryComplements({
  complement,
  index,
  complements,
  complementCategories,
  isOpen,
  getCategories,
  menewProducts,
  setComplementsCategories,
  category,
  categories,
  setCategories,
  product,
  complementCategory,
  setComplementCategoriesInsideProduct,
  complementCategoriesInsideProduct,
  fiscalGroups,
}) {
  const { user } = useAuth();
  const [modalEditComplement, setModalEditComplement] = useState(false);
  const [modalEditComplementData, setModalEditComplementData] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const canEditComplement =
    !user?.price_table ||
    user?.price_table?.edit_complements ||
    !user?.price_table?.is_customizable;
  const canAssociateComplement =
    !user?.price_table || user?.price_table?.change_product_complements;

  function toggleModalEditComplement() {
    setModalEditComplement(!modalEditComplement);
  }

  function handleChangeEditComplement(complement) {
    toggleModalEditComplement();
    setModalEditComplementData(complement);
  }

  async function handleDelete(complement) {
    try {
      if (
        window.confirm(
          'Tem certeza que deseja desassociar este Complemento desta categoria? '
        )
      ) {
        const complementFiltered = complementCategory?.complements
          .filter((item) => item.id !== complement.id)
          .map((item) => item.id);

        const response = await api.put(
          `restaurants/product/complement-category/${complementCategory.id}`,
          {
            complements: complementFiltered,
          }
        );

        localStorage.setItem('@gddashboard:isMenuUpdated', true);

        const newComplementsCategoriesInside = [
          ...complementCategoriesInsideProduct,
        ];

        newComplementsCategoriesInside.map((cat, idix) => {
          if (cat.id === complementCategory.id) {
            newComplementsCategoriesInside[idix].complements =
              response.data.complements;
          }
        });

        setComplementCategoriesInsideProduct(newComplementsCategoriesInside);

        // set complements categories array
        const newComplememtsCategories = [...complementCategories];

        newComplememtsCategories.map((cat, idix) => {
          if (cat.id === complementCategory.id) {
            newComplememtsCategories[idix].complements =
              response.data.complements;
          }
        });

        setComplementsCategories(newComplememtsCategories);

        toast.success('Complemento desassociado com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao deletar complemento');
      console.log(error.message);
    }
  }
  async function handleChangeComplement(value, event, idx) {
    try {
      const [type, compId] = idx.split('-');
      const id = Number(compId);

      const data = {};

      if (type === 'pdv') {
        data.available = value;

        const newCategories = [...complementCategoriesInsideProduct];

        newCategories.map((cat, idix) =>
          cat.complements.map((item, idex) => {
            if (item.id === id) {
              newCategories[idix].complements[idex].available = value;
            }
          })
        );

        setComplementCategoriesInsideProduct(newCategories);

        const newComplementCategories = [...complementCategories];

        newComplementCategories.map((cat, idix) =>
          cat.complements.map((item, idex) => {
            if (item.id === id) {
              newComplementCategories[idix].complements[idex].available = value;
            }
          })
        );

        setComplementsCategories(newComplementCategories);
      } else {
        data.available_in_delivery = value;

        const newCategories = [...complementCategoriesInsideProduct];

        newCategories.map((cat, idix) =>
          cat.complements.map((item, idex) => {
            if (item.id === id) {
              newCategories[idix].complements[idex].available_in_delivery =
                value;
            }
          })
        );

        setComplementCategoriesInsideProduct(newCategories);

        const newComplementCategories = [...complementCategories];

        newComplementCategories.map((cat, idix) =>
          cat.complements.map((item, idex) => {
            if (item.id === id) {
              newComplementCategories[idix].complements[
                idex
              ].available_in_delivery = value;
            }
          })
        );

        setComplementsCategories(newComplementCategories);
      }
      await api.put(`/restaurants/product/complements/${id}`, data);
      localStorage.setItem('@gddashboard:isMenuUpdated', true);
    } catch (error) {
      toast.error('Erro ao ativar/ desativar complemento');
      console.log(error.message);
    }
  }

  // edit price and limit
  function currencyFormatter(value) {
    if (!Number(value)) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  }

  const [buttonsSubmitChangesDesbloqued, setButtonsSubmitChangesDesbloqued] =
    useState([]);

  function handleDesbloquedSubmitChangesButton(id) {
    if (complement.id === id) {
      setButtonsSubmitChangesDesbloqued([id]);
    } else {
      const idsFiltered = buttonsSubmitChangesDesbloqued.filter(
        (item) => item !== id
      );
      setButtonsSubmitChangesDesbloqued(idsFiltered);
    }

    // if(buttonsSubmitChangesDesbloqued.includes(id)){
    //   setButtonsSubmitChangesDesbloqued([id]);
    // }else{
    //   const idsFiltered = buttonsSubmitChangesDesbloqued.filter(
    //     (item) => item === id
    //   );
    //   setButtonsSubmitChangesDesbloqued([idsFiltered, id]);

    // }
  }

  const [price, setPrice] = useState(complement.price);
  const [deliveryPrice, setDeliveryPrice] = useState(complement.delivery_price);
  const [limit, setLimit] = useState(complement.limit);

  const handleSubmitChanges = useCallback(async () => {
    await api.put(`restaurants/product/complements/${complement.id}`, {
      price: price,
      delivery_price: deliveryPrice,
      limit: limit,
    });

    const newCategories = [...complementCategoriesInsideProduct];

    newCategories.map((cat, idix) =>
      cat.complements.map((item, idex) => {
        if (item.id === complement.id) {
          newCategories[idix].complements[idex].price = price;
          newCategories[idix].complements[idex].delivery_priceprice =
            deliveryPrice;
          newCategories[idix].complements[idex].limit = limit;
        }
      })
    );

    setComplementCategoriesInsideProduct(newCategories);

    const newComplementCategories = [...complementCategories];

    newComplementCategories.map((cat, idix) =>
      cat.complements.map((item, idex) => {
        if (item.id === complement.id) {
          newComplementCategories[idix].complements[idex].price = price;

          newComplementCategories[idix].complements[idex].delivery_price =
            deliveryPrice;

          newComplementCategories[idix].complements[idex].limit = limit;
        }
      })
    );

    setComplementsCategories(newComplementCategories);
    localStorage.setItem('@gddashboard:isMenuUpdated', true);

    const complementFiltered = complements?.find(
      (item) => item.id === complement.id
    );

    complementFiltered.price = price;
    complementFiltered.delivery_price = deliveryPrice;
    complementFiltered.limit = limit;
    toast.success('Preço alterado com sucesso.');
    setButtonsSubmitChangesDesbloqued([]);
  }, [
    complement,
    price,
    deliveryPrice,
    limit,
    complementCategories,
    setComplementsCategories,
    complementCategoriesInsideProduct,
    setComplementCategoriesInsideProduct,
    complements,
  ]);

  const checkPersonalizedHoursActive = () => {
    if (!complement?.enable_times) {
      return true;
    }
    const daysActive = complement?.active_days.split('');
    const today = new Date().getDay();

    if (daysActive[today] === 't') {
      const diff1 = differenceInMinutes(
        new Date(`2023/01/01 ${format(new Date(), 'HH:mm')}`),
        new Date(
          `2023/01/01 ${format(new Date(complement?.start_time), 'HH:mm')}`
        )
      );

      const diff2 = differenceInMinutes(
        new Date(`2023/01/01 ${format(new Date(), 'HH:mm')}`),
        new Date(
          `2023/01/01 ${format(new Date(complement?.end_time), 'HH:mm')}`
        )
      );

      if (diff1 >= 0 && diff2 <= 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  function handleValueChange(e) {
    const readyValue = e.floatValue ? (e.floatValue / 100).toFixed(2) : price;

    const formatPrice = (price) => {
      return price.replace(',', '.');
    };

    const formattedPrice = formatPrice(readyValue);
    setPrice(formattedPrice);
  }

  function handleDeliveryValueChange(e) {
    const readyDeliveryValue = e.floatValue
      ? (e.floatValue / 100).toFixed(2)
      : null;

    const formatDeliveryPrice = (deliveryPrice) => {
      return deliveryPrice.replace(',', '.');
    };

    const formattedDeliveryPrice = readyDeliveryValue
      ? formatDeliveryPrice(readyDeliveryValue)
      : null;

    setDeliveryPrice(formattedDeliveryPrice);
  }

  function handleLimitChange(e) {
    const readyLimitValue = e.floatValue;
    setLimit(readyLimitValue);
  }

  return (
    <Container isOpen={isOpen}>
      <TdStyled
        style={{
          borderTop: 'none',
          display: 'flex',
          alignItems: 'flex-end',
        }}
        backgroundImage={beforeBg}
      >
        <ComplementNameTable style={{ marginTop: 12 }}>
          {complement?.name}
        </ComplementNameTable>
      </TdStyled>
      {!user.only_delivery && (
        <td
          style={{ borderTop: 'none' }}
          onClick={() => {
            if (!checkPersonalizedHoursActive()) {
              toast.error(
                'Para reativar esse item, clique nos 3 pontinhos > Editar item e desmarque a opção “Horário personalizado”',
                {
                  duration: 5000,
                }
              );
              return;
            }
          }}
        >
          <Switch
            id={`pdv-${complement.id}`}
            onChange={handleChangeComplement}
            draggable
            checked={complement?.available && checkPersonalizedHoursActive()}
            checkedIcon={false}
            uncheckedIcon={false}
            disabled={!checkPersonalizedHoursActive()}
            height={27}
            width={44}
            handleDiameter={16}
            activeBoxShadow="0 0 2px 3px #111"
            boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
            onColor="#0CCAB4"
            offColor="#666666"
          />
        </td>
      )}

      {user.delivery_info.is_delivery_allowed && (
        <td
          style={{ borderTop: 'none' }}
          onClick={() => {
            if (!checkPersonalizedHoursActive()) {
              toast.error(
                'Para reativar esse item, clique nos 3 pontinhos > Editar item e desmarque a opção “Horário personalizado”',
                {
                  duration: 5000,
                }
              );
              return;
            }
          }}
        >
          <Switch
            id={`delivery-${complement?.id}`}
            onChange={handleChangeComplement}
            draggable
            checked={
              complement?.available_in_delivery &&
              checkPersonalizedHoursActive()
            }
            checkedIcon={false}
            uncheckedIcon={false}
            disabled={!checkPersonalizedHoursActive()}
            height={27}
            width={44}
            handleDiameter={16}
            activeBoxShadow="0 0 2px 3px #111"
            boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
            onColor="#0CCAB4"
            offColor="#666666"
          />
        </td>
      )}

      <td style={{ borderTop: 'none' }}>
        <NumberFormatStyled
          prefix="R$"
          format={currencyFormatter}
          name="price"
          defaultValue={complement?.price}
          onValueChange={(e) => handleValueChange(e)}
          onClick={() => {
            setPrice(complement?.price);
            handleDesbloquedSubmitChangesButton(complement.id);
          }}
          key={complement?.price}
          disabled={!canEditComplement}
        />
      </td>

      {!user.only_delivery && user.delivery_info.is_delivery_allowed && (
        <td style={{ borderTop: 'none' }}>
          <NumberFormatStyled
            prefix="R$"
            format={currencyFormatter}
            name="delivery_price"
            defaultValue={complement?.delivery_price}
            onValueChange={(e) => handleDeliveryValueChange(e)}
            onClick={() => {
              setDeliveryPrice(complement?.delivery_price);
              handleDesbloquedSubmitChangesButton(complement.id);
            }}
            key={complement?.delivery_price}
            disabled={!canEditComplement}
          />
        </td>
      )}

      <td style={{ borderTop: 'none' }}>
        <NumberFormatStyled
          limit
          name="liimt"
          defaultValue={complement?.limit}
          onValueChange={(e) => handleLimitChange(e)}
          onClick={() => {
            setLimit(complement?.limit);
            handleDesbloquedSubmitChangesButton(complement.id);
          }}
          key={complement?.limit}
          disabled={!canEditComplement}
        />
      </td>
      <td style={{ borderTop: 'none' }}>
        <SubmitStyledButton
          id="limit"
          onClick={handleSubmitChanges}
          type="button"
          disabled={
            !buttonsSubmitChangesDesbloqued.includes(complement.id) ||
            complement?.is_multistore_child
          }
        >
          <FaCheck />
        </SubmitStyledButton>
      </td>
      <td style={{ borderTop: 'none' }}></td>
      {(canEditComplement || user.has_ifood) && (
        <td style={{ borderTop: 'none' }}>
          <DropdownDiv>
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggleDropdown}
              direction={index + 1 === complements?.length ? 'up' : 'down'}
            >
              <DropdownToggle tag="span">
                {' '}
                <FaEllipsisV
                  style={{ marginLeft: 20 }}
                  title="Mostrar Opções"
                  cursor="pointer"
                />
              </DropdownToggle>
              <DropdownMenu flip>
                <DropdownItem
                  disabled={!canEditComplement}
                  onClick={() => {
                    handleChangeEditComplement(complement);
                  }}
                >
                  Editar Complemento
                </DropdownItem>
                {!complement.is_multistore_child && (
                  <DropdownItem
                    disabled={!canAssociateComplement}
                    onClick={() => handleDelete(complement)}
                  >
                    Desassociar Complemento
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          </DropdownDiv>
        </td>
      )}
      <ReactModal
        onRequestClose={toggleModalEditComplement}
        isOpen={modalEditComplement}
        className="modal-content-takeat-without-overflow"
        closeTimeoutMS={500}
      >
        <EditComplementInsideProducts
          complement={modalEditComplementData}
          getCategories={getCategories}
          toggleModal={toggleModalEditComplement}
          // getComplements={getComplements}
          // setCount={setCount}
          menewProducts={menewProducts}
          allCategories={complementCategories}
          isInsideProduct
          setCategories={setCategories}
          categories={categories}
          setComplementCategoriesInsideProduct={
            setComplementCategoriesInsideProduct
          }
          complementCategories={complementCategories}
          complementCategoriesInsideProduct={complementCategoriesInsideProduct}
          setComplementsCategories={setComplementsCategories}
          fiscalGroups={fiscalGroups}
        />
      </ReactModal>
    </Container>
  );
}

export default ComplementCategoryComplements;
