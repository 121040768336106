import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { InputContainer, Error } from './styles';

function InputSimple({ name, label, maxlength, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, inputRef, registerField]);

  return (
    <InputContainer>
      <label htmlFor={fieldName}>{label}</label>
      <input
        ref={inputRef}
        defaultValue={defaultValue}
        maxlength={maxlength}
        {...rest}
      />
      {error && (
        <Error>
          <p>{error}</p>
        </Error>
      )}
    </InputContainer>
  );
}

export default InputSimple;
