import React, { useState, useCallback } from 'react';

import { Form } from '@unform/web';

import { toast } from 'react-hot-toast';

import {
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  TabContent,
  TabPane,
  Spinner,
} from 'reactstrap';

import { Button } from 'ui-kit-takeat';

import {
  Container,
} from './styles';

import Input from '~/components/Form/Input';
import NfcesTable from './NfcesTable';

import api from '~/services/api';
import formatCompleteDate from '~/services/formatCompleteDate';

function getStatusName(status) {
  switch (status) {
    case 'autorizado':
      return 'Autorizada';
    case 'canceled':
      return 'Cancelada';
    default:
      return '';
  }
}

export default function Nfces({ nfces, getNfces, activeTab }) {
  const autorizados = nfces.filter((nfce) => nfce.status === 'autorizado');
  const cancelados = nfces.filter((nfce) => nfce.status === 'canceled');

  const [loading, setLoading] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);

  const [nfce, setNfce] = useState({});


  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showCartaCorrecaoModal, setShowCartaCorrecaoModal] = useState(false);
  const [cartaCorrecaoUrl, setCartaCorrecaoUrl] = useState(null);

  function toggleDetailModal() {
    setShowDetailModal((state) => !state);
  }

  function toggleEmailModal() {
    setShowEmailModal((state) => !state);
  }

  function toggleCancelModal() {
    setShowCancelModal((state) => !state);
  }

  function toggleCartaCorrecaoModal() {
    setShowCartaCorrecaoModal((state) => !state);
  }

  function onClickDetail(nfce) {
    setNfce(nfce);
    toggleDetailModal();
  }

  function onClickEmail(nfce) {
    setNfce(nfce);
    toggleEmailModal();
  }

  function onClickCancel(nfce) {
    setNfce(nfce);
    toggleCancelModal();
  }

  function onClickCartaCorrecao(nfce) {
    setNfce(nfce);
    setCartaCorrecaoUrl(nfce.carta_correcao_url || null);
    toggleCartaCorrecaoModal();
  }

  function onClickPrint(nfce) {
    const pageParams = {
      width: 795,
      height: 590,
      top: 100,
      left: 699,
      scrollbars: 'no',
      status: 'no',
      toolbar: 'no',
      location: 'no',
      menubar: 'no',
      resizable: 'no',
      fullscreen: 'no',
    };

    const paramsStr = Object.entries(pageParams)
      .map(([key, value]) => `${key}=${value}`)
      .join(', ');

    window.open(`${nfce.nfce_html}`, 'janela', paramsStr);
  }

  function onClickPrintCartaCorrecao() {
    const pageParams = {
      width: 795,
      height: 590,
      top: 100,
      left: 699,
      scrollbars: 'no',
      status: 'no',
      toolbar: 'no',
      location: 'no',
      menubar: 'no',
      resizable: 'no',
      fullscreen: 'no',
    };

    const paramsStr = Object.entries(pageParams)
      .map(([key, value]) => `${key}=${value}`)
      .join(', ');

    window.open(`${cartaCorrecaoUrl}`, 'janela', paramsStr);
    toggleCartaCorrecaoModal();
  }

  const sendEmail = useCallback(
    async (data) => {
      setLoadingEmail(true);

      const url =
        nfce.type === 'nfce'
          ? 'restaurants/email-nfce'
          : 'restaurants/email-nfe';

      try {
        await api.post(url, {
          ref: nfce.ref,
          email: data.email,
        });

        toast.success('E-mail enviado com sucesso!');
      } catch (err) {
        toast.error('Erro ao enviar E-mail.');
      }

      setLoadingEmail(false);
      setShowEmailModal(false);
    },
    [nfce]
  );

  const cancelNfce = useCallback(async () => {
    setLoading(true);

    const url =
      nfce.type === 'nfce'
        ? 'restaurants/delete-nfce'
        : 'restaurants/delete-nfe';

    try {
      const response = await api.post(url, {
        ref: nfce.ref,
      });

      if (response.data.status_sefaz === '135') {
        toast.success('NFCe deletada com sucesso!');
      }

      getNfces();
    } catch (err) {
      toast.error('Erro ao deletar Nota Fiscal. Tente novamente.');
    }

    setLoading(false);
    setShowCancelModal(false);
  }, [nfce]);

  const handleCartaCorrecao = useCallback(async (data) => {
    setLoading(true);

    if(data.correcao.length < 15 || data.correcao.length > 1000){
      toast.error('A correção deve conter de 15 a 1000 caracteres');
      setLoading(false);
      return;
    }

    try {
      const response = await api.post(`restaurants/carta-correcao/${nfce.id}`, {
        correcao: data.correcao,
      });

      if (response.data.status === 'autorizado' && response.data.url) {
        toggleCartaCorrecaoModal();
        setCartaCorrecaoUrl(response.data.url);

        const pageParams = {
          width: 795,
          height: 590,
          top: 100,
          left: 699,
          scrollbars: 'no',
          status: 'no',
          toolbar: 'no',
          location: 'no',
          menubar: 'no',
          resizable: 'no',
          fullscreen: 'no',
        };
    
        const paramsStr = Object.entries(pageParams)
          .map(([key, value]) => `${key}=${value}`)
          .join(', ');
    
        window.open(`${response.data.url}`, 'janela', paramsStr);
      }

      getNfces();
    } catch (err) {
      toast.error('Erro ao deletar Nota Fiscal. Tente novamente.');
    }

    setLoading(false);
    setShowCancelModal(false);
  }, [nfce]);

  return (
    <Container>
      <TabContent activeTab={activeTab}>
        <TabPane
          tabId={0}
          style={{
            borderCollapse: 'separate',
            borderSpacing: '0 20px',
            borderRadius: '8px',
            padding: '12px 10px',
            minWidth: '750px',
            background: '#fff',
          }}
        >
          <NfcesTable
            nfces={autorizados}
            onClickDetail={onClickDetail}
            onClickPrint={onClickPrint}
            onClickEmail={onClickEmail}
            onClickCancel={onClickCancel}
            onClickCartaCorrecao={onClickCartaCorrecao}
          />
        </TabPane>

        <TabPane
          tabId={1}
          style={{
            borderCollapse: 'separate',
            borderSpacing: '0 20px',
            borderRadius: '8px',
            padding: '12px 10px',
            minWidth: '750px',
            background: '#fff',
          }}
        >
          <NfcesTable
            nfces={cancelados}
            onClickDetail={onClickDetail}
            showCancelDate={true}
          />
        </TabPane>
      </TabContent>

      {/* Enviar por email */}
      <Modal isOpen={showEmailModal} toggle={toggleEmailModal}>
        <Form onSubmit={sendEmail}>
          <ModalBody>
            <h6>Enviar Nota Fiscal por E-mail</h6>
            <Input
              label="E-mail"
              name="email"
              type="email"
              placeholder="exemplo@gmail.com"
            />
          </ModalBody>

          <ModalFooter>
            <Button
              buttonColor="#dc3545"
              onClick={toggleEmailModal}
              title="Fechar"
            />
            
            <Button
              type="submit"
              buttonColor="#008000"
              onClick={() => {}}
              disabled={loadingEmail}
              title={loadingEmail ? (
                <Spinner
                  color="light"
                  style={{ width: '1em', height: '1em' }}
                />
              ) : (
                'Enviar'
              )}
            />
          </ModalFooter>
        </Form>
      </Modal>

      {/* Detalhes */}
      <Modal isOpen={showDetailModal} toggle={toggleDetailModal}>
        <ModalBody style={{ padding: 20 }}>
          <Row style={{ paddingTop: 15 }}>
            <Col md="6">
              <strong>Referência:</strong> #{nfce.ref}
            </Col>
            <Col md="6">
              <strong>Status:</strong> {getStatusName(nfce.status)}
            </Col>
          </Row>
          <Row style={{ paddingTop: 15 }}>
            <Col md="6">
              <strong>Criada em:</strong>{' '}
              {formatCompleteDate(nfce.created_at || new Date())}
            </Col>
            <Col md="6">
              <strong>Cancelada em:</strong>{' '}
              {nfce.deleted_at !== null
                ? formatCompleteDate(nfce.deleted_at || new Date())
                : '-'}
            </Col>
          </Row>
          <Row style={{ paddingTop: 15 }}>
            <Col md="6">
              <strong>Série:</strong> {nfce.serie}
            </Col>
            <Col md="6">
              <strong>Número:</strong> {nfce.numero}
            </Col>
          </Row>
          <Row style={{ paddingTop: 15 }}>
            <Col md="6">
              <strong>Valor:</strong> R${nfce.total_price}
            </Col>
            <Col md="6">
              <strong>Tipo:</strong> {nfce.type === 'nfce' ? 'NFCe' : 'NFe'}
            </Col>
          </Row>
          <Row style={{ paddingTop: 15 }}>
            <Col>
              <strong>Chave da {nfce.type === 'nfce' ? 'NFCe' : 'NFe'}:</strong>{' '}
              {nfce.chave_nfce}
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      {/* Cancelar notas */}
      <Modal isOpen={showCancelModal} toggle={toggleCancelModal}>
        <ModalBody>
          <h4 style={{ color: 'red' }}>
            Tem certeza que deseja cancelar essa Nota Fiscal?
          </h4>
        </ModalBody>
        <ModalFooter>
          <Button 
            onClick={toggleCancelModal} 
            buttonColor="#008000"
            title="Fechar"
          />
          
          <Button
            onClick={cancelNfce}
            buttonColor="#dc3545"
            disabled={loading}
            title=            {loading ? (
              <Spinner color="light" style={{ width: '1em', height: '1em' }} />
            ) : (
              'Sim, cancelar'
            )}
          />
        </ModalFooter>
      </Modal>

      {/* Carta de Correção */}
      <Modal isOpen={showCartaCorrecaoModal} toggle={toggleCartaCorrecaoModal}>
        {cartaCorrecaoUrl ?
        <>
          <ModalBody>
            <h4 style={{color: 'green'}}>
              Carta de correção já emitida!
            </h4>

            <Row>
              <Col>
                É possível enviar até 20 correções diferentes, sendo que será válido sempre a última correção enviada.
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button 
              onClick={() => setCartaCorrecaoUrl(null)}
              title="Emitir outra"
              type="button"
            />

            <Button
              buttonColor="#008000"
              onClick={() => onClickPrintCartaCorrecao()}
              title="Abrir Carta"
              type="button"
            />
          </ModalFooter>
        </> :
        <Form onSubmit={handleCartaCorrecao}>
          <ModalBody>
            <Row>
              <Col>
                <Input
                  name="correcao"
                  label="Correção"
                  type="text"
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button 
              onClick={toggleCartaCorrecaoModal} 
              title="Fechar"
              type="button"
            />

            <Button
              buttonColor="#008000"
              onClick={() => {}}
              disabled={loading}
              title="Enviar"
              type="submit"
            />
          </ModalFooter>
        </Form>}
      </Modal>
    </Container>
  );
}
