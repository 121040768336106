import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import { MdDelete } from 'react-icons/md';
import { format } from 'date-fns';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  NavLink,
  NavItem,
  TabPane,
  TabContent,
  Nav,
} from 'reactstrap';
import InputMask from 'react-input-mask';
import Switch from 'react-switch';
import {
  FaCheck,
  FaInfoCircle,
  FaPlus,
  FaQuestionCircle,
  FaTrash,
} from 'react-icons/fa';

import ReactModal from 'react-modal';
import StockImg from '~/assets/img/stock_banner.png';
import { useMenu } from '~/context/OrderingSystem/Menu';
import getValidationErrors from '../../../services/getValidationErrors';
import nfeInfo from '~/services/NFeInfo';

import NFeInfo from '~/services/NFeInfo';
import {
  Card,
  InputsTable,
  Delete,
  NavDiv,
  NavDivTeste,
  NavLinkSpan,
  Container,
  FiscalMessage,
  SwitcherArea,
  ButtonsArea,
  InnerArea,
  CmvButtons,
  NumberFormatStyled,
  SelectStyled,
  InputStyled,
  ButtonAdd,
  SubmitStyledButton,
  StockBanner,
  NumberFormatStyledValue,
  PersonalizedHoursContainer,
} from './styles';

import api from '~/services/api';

import ImageInput from '~/components/Form/ImageInput';
import Input from '~/components/Form/Input';
import Textarea from '~/components/Form/Textarea';
import Select from '~/components/Form/SelectInput';
import Checkbox from '~/components/Form/Checkbox';

import { useAuth } from '~/context/AuthContext';
import CheckboxInput from '~/components/Form/Checkbox';
import PersonalButton from '~/components/Buttons/PersonalButton';
import formatValue from '~/utils/formatValue';
import {
  Button,
  DefaultInput,
  Checkbox as CheckboxUi,
  Tooltip,
} from 'ui-kit-takeat';

function CreateProduct({
  category,
  handleSetProduct,
  categories,
  cache,
  fiscalGroups,
  togle,
}) {
  const { user } = useAuth();
  const { getMenu } = useMenu();
  const [userScreenTop] = useState(window.scrollY);
  const formRef = useRef(null);
  const formInputRef = useRef(null);
  const [complementCategories, setComplementCategories] = useState([]);
  const [activeTab, setActiveTab] = useState('1');
  const [activeIntermediaryTab, setActiveIntermediaryTab] = useState('4');
  const [comboTooltip, setComboTooltip] = useState(false);

  const [restaurantInputs, setRestaurantInputs] = useState([]);
  const [restaurantInputsSelect, setRestaurantInputsSelect] = useState([]);
  const [inputSelectedId, setInputSelectedId] = useState(null);
  const [, setInputSelected] = useState('');
  const [productPriceState, setProductPriceState] = useState(0);
  const [currentCmv, setCurrentCmv] = useState('0.00');
  const [currentCmvManualChecked, setCurrentCmvManualChecked] = useState(false);
  const [enableTimes, setEnableTimes] = useState(false);
  const [daysSelected, setDaysSelected] = useState([
    't',
    't',
    't',
    't',
    't',
    't',
    't',
  ]);
  const [hourSelected, setHourSelected] = useState({ start: '', end: '' });
  const [isCombo, setIsCombo] = useState(false);

  const handleChangeDay = (idx) => {
    const updatedDays = [...daysSelected];
    updatedDays[idx] = updatedDays[idx] === 'f' ? 't' : 'f';
    setDaysSelected(updatedDays);
  };

  const [hasInput, setHasInput] = useState(false);

  const getComplementCategories = useCallback(async () => {
    const response = await api.get('restaurants/product/complement-category');

    const data = response.data.map((categ) => ({
      value: categ.id,
      label: categ.name,
    }));

    setComplementCategories(data);
  }, []);

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    setInputSelected('');
  };

  const handleTimeInput = (time) => {
    let validTime = '';
    const array = time.split('');

    array.forEach((element, i) => {
      if (i === 0 && element > 2) {
        validTime += '2';
      } else if (i === 1 && element > 3 && array[0] > 1) {
        validTime += '3';
      } else if (i === 3 && element > 5) {
        validTime += '5';
      } else {
        validTime += element;
      }
      return;
    });

    return validTime;
  };

  useEffect(() => {
    try {
      getComplementCategories();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getComplementCategories]);

  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);

  const [categoriesDetails, setCategoriesDetails] = useState();

  const getCategoriesDetails = useCallback(async () => {
    try {
      const response = await api.get(
        '/restaurants/cash-flows/categories-detail'
      );

      const categoriesSelect = response?.data?.map((category) => {
        return {
          value: category.id,
          label: category.text,
        };
      });

      setCategoriesDetails(categoriesSelect);
    } catch (error) {
      toast.error('Erro ao baixar métodos de pagamento');
    }
  }, []);

  useEffect(() => {
    getCategoriesDetails();
  }, [getCategoriesDetails]);

  const icms_origem_options = nfeInfo.icms_origem.map((icms) => ({
    value: icms.value,
    label: icms.description,
  }));

  const csosn_options = nfeInfo.csosn.map((c) => ({
    value: c.csosn,
    label: `${c.csosn} - ${c.description}`,
  }));

  const base_calculo_options = nfeInfo.base_calculo.map((c) => ({
    value: c.value,
    label: c.description,
  }));

  const [csosnSelected, setCsosnSelected] = useState('');
  const [cstSelected, setCstSelected] = useState('');
  const [cfopOptions, setCfopOptions] = useState([]);

  useEffect(() => {
    const cfopSelect = nfeInfo.csosn.filter((cs) => cs.csosn === csosnSelected);
    const cfop = [];

    cfopSelect.map((selected) =>
      selected.cfop?.forEach((cf) => {
        const cfopDesc = nfeInfo.cfopDescription.filter(
          (cfop) => cfop.cfop === cf
        );

        cfop.push({
          value: cfopDesc[0].cfop,
          label: `${cfopDesc[0].cfop} - ${cfopDesc[0].description}`,
        });
      })
    );

    setCfopOptions(cfop);
  }, [csosnSelected]);

  useEffect(() => {
    const cstSelect = nfeInfo.cst.filter((cs) => cs.cst === cstSelected);
    const cfop = [];

    cstSelect.map((selected) =>
      selected.cfop?.forEach((cf) => {
        const cfopDesc = nfeInfo.cfopDescription.filter(
          (cfop) => cfop.cfop === cf
        );

        cfop.push({
          value: cfopDesc[0]?.cfop,
          label: `${cfopDesc[0]?.cfop} - ${cfopDesc[0]?.description}`,
        });
      })
    );

    setCfopOptions(cfop);
  }, [cstSelected]);

  const [fiscalGroup, setFiscalGroup] = useState(null);
  const [fiscalGroupDefault, setFiscalGroupDefault] = useState(null);

  // regime simples
  const [icmsOrigemValue, setIcmsOrigemValue] = useState(null);
  const [csosnValue, setCsosnValue] = useState(null);
  const [cfopValue, setCfopValue] = useState(null);
  const [cofinsValue, setCofinsValue] = useState(null);
  const [pisValue, setPisValue] = useState(null);
  const [cestValue, setCestValue] = useState(null);
  const [icmsValorValue, setIcmsValorValue] = useState(null);
  const [baseCalculoValue, setBaseCalculoValue] = useState(null);
  const [aliquotaValue, setAliquotaValue] = useState(null);
  // regime normal
  const [cstValue, setCstValue] = useState(null);
  const [pisAliquotaValue, setPisAliquotaValue] = useState(null);
  const [cofinsAliquotaValue, setCofinsAliquotaValue] = useState(null);
  const [icmsModalidadeBCValue, setIcmsModalidadeBCValue] = useState(null);
  const [cbenefValue, setCbenefValue] = useState(null);

  const [icmsValorDesoneradoValue, setIcmsValorDesoneradoValue] =
    useState(null);
  const [icmsBaseCalculoRetidoSt, setIcmsBaseCalculoRetidoSt] = useState(null);
  const [icmsValorSubstituto, setIcmsValorSubstituto] = useState(null);
  const [icmsValorRetidoSt, setIcmsValorRetidoSt] = useState(null);
  const [icmsAliquotaFinal, setIcmsAliquotaFinal] = useState(null);
  const [icmsReducaoBaseCalculo, setIcmsReducaoBaseCalculo] = useState(null);
  const [icmsMotivoDesoneracao, setIcmsMotivoDesoneracao] = useState(null);
  const [fcpPercentual, setFcpPercentual] = useState(null);

  async function handleSubmit(data) {
    if (activeTab === '1') {
      if (
        data?.price_promotion &&
        Number(data?.price_promotion) >= Number(data?.price)
      ) {
        toast.error(
          'O preço promocional deve ser menor que o preço do produto.'
        );
        return;
      }

      if (
        data?.delivery_price_promotion &&
        data?.delivery_price &&
        Number(data?.delivery_price_promotion) >= Number(data?.delivery_price)
      ) {
        toast.error(
          'O preço promocional no delivery deve ser menor que o preço do produto no delivery.'
        );
        return;
      }

      try {
        if (formRef?.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          price: Yup.number()
            .typeError('Somente números')
            .required('Preço obrigatório'),
          description: Yup.string().max(800, 'Máximo de 800 caracteres'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setActiveTab('2');
        setProductPriceState(data.price);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRef?.current) {
            formRef.current.setErrors(errors);
          }
        } else {
          if (
            error.response.data.error &&
            error.response.data.error.message ===
              'value too long for type character varying(800)'
          ) {
            console.log(error.response.data);
            toast.error('O campo descrição deve ter no máximo 800 caracteres.');
            return;
          }
          toast.error(`Erro ao cadastrar produto!`);
          console.log(error);
        }
      }
    }

    if (activeTab === '2') {
      try {
        if (formRef?.current) {
          formRef.current.setErrors({});
        }

        if (
          user.has_nfce &&
          csosnSelected !== '00' &&
          user.regime_tributario === '1'
        ) {
          const schema = Yup.object().shape({
            ncm: Yup.string().required('Campo obrigatório'),
            cfop: Yup.string().required('Campo obrigatório'),
            csosn: Yup.string().required('Campo obrigatório'),
            icms_origem: Yup.string().required('Campo obrigatório'),
            cofins: Yup.string().required('Campo obrigatório'),
            pis: Yup.string().required('Campo obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          setActiveTab('3');
        } else if (
          user.has_nfce &&
          csosnSelected === '00' &&
          user.regime_tributario === '1'
        ) {
          const schema = Yup.object().shape({
            ncm: Yup.string().required('Campo obrigatório'),
            cfop: Yup.string().required('Campo obrigatório'),
            csosn: Yup.string().required('Campo obrigatório'),
            icms_origem: Yup.string().required('Campo obrigatório'),
            cofins: Yup.string().required('Campo obrigatório'),
            pis: Yup.string().required('Campo obrigatório'),
            aliquota: Yup.string().required('Campo obrigatório'),
            base_calculo: Yup.string().required('Campo obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          setActiveTab('3');
        } else if (user.has_nfce && user.regime_tributario !== '1') {
          const schema = Yup.object().shape({
            ncm: Yup.string().required('Campo obrigatório'),
            cfop: Yup.string().required('Campo obrigatório'),
            cst: Yup.string().required('Campo obrigatório'),
            icms_origem: Yup.string().required('Campo obrigatório'),
            cofins: Yup.string().required('Campo obrigatório'),
            pis: Yup.string().required('Campo obrigatório'),
            // aliquota: Yup.string().required('Campo obrigatório'),
            // base_calculo: Yup.string().required('Campo obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          setActiveTab('3');
        } else {
          setActiveTab('3');
        }
      } catch (error) {
        console.log(error);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRef?.current) {
            formRef.current.setErrors(errors);
          }
        } else {
          toast.error(`Erro ao cadastrar produto!`);
          console.log(error);
        }
      }
    }

    const formatPrice = (price) => {
      return parseFloat(price.replace(',', '.')).toFixed(2);
    };

    const formattedPrice = user.only_delivery
      ? formatPrice(data.price)
      : formatPrice(data.price);

    const formattedPromotionPrice = data.price_promotion
      ? formatPrice(data.price_promotion)
      : null;

    const formattedDeliveryPrice = data.delivery_price
      ? formatPrice(data.delivery_price)
      : null;

    const formattedDeliveryPromotionPrice = data.delivery_price_promotion
      ? formatPrice(data.delivery_price_promotion)
      : null;
    const parsedData = {
      name: data.name,
      description: data.description,
      price: formattedPrice,
      product_category_id: category.id,
      promotion: data.promotion,
      sold_off: data.sold_off,
      charge_service_tax: data.charge_service_tax,
      use_weight: data.use_weight,
      has_starting_price: data.has_starting_price,
      is_combo: data.is_combo,
      available: data.available,
      available_in_delivery: data.available_in_delivery,
      delivery_price: formattedDeliveryPrice,
      delivery_price_promotion: formattedDeliveryPromotionPrice,
      ifood_product_id:
        data?.ifood_product?.length > 0 ? data?.ifood_product : null,
      ncm: null,
      cfop: null,
      csosn: null,
      icms_origem: null,
      aliquota: null,
      base_calculo: null,
      cofins: null,
      pis: null,
      unitary_price: data.unitary_price,
      enable_times: enableTimes,
      active_days: daysSelected.join(''),
      start_time: new Date(`2023/01/01 ${hourSelected.start}`),
      end_time: new Date(`2023/01/01 ${hourSelected.end}`),
    };

    if (activeTab === '3') {
      if (user.has_nfce) {
        if (user.regime_tributario === '1') {
          parsedData.ncm = data.ncm;
          parsedData.cfop = cfopValue?.value || data.cfop;
          parsedData.csosn = csosnValue?.value || data.csosn;
          parsedData.cest = cestValue?.value || data?.cest || null;
          parsedData.icms_origem = icmsOrigemValue?.value || data.icms_origem;

          if (csosnSelected === '00') {
            parsedData.aliquota = aliquotaValue || data.aliquota;
            parsedData.base_calculo =
              baseCalculoValue?.value || data.base_calculo;
          }

          if (user.has_nfce && data.cofins !== undefined) {
            parsedData.cofins = cofinsValue?.value || data.cofins;
          }

          if (user.has_nfce && data.pis !== undefined) {
            parsedData.pis = pisValue?.value || data.pis;
          }

          if (csosnSelected === '500') {
            parsedData.icms_base_calculo_retido_st =
              data?.icms_base_calculo_retido_st?.replace(',', '.') || null;
            parsedData.icms_aliquota_final =
              data?.icms_aliquota_final?.replace(',', '.') || null;
            parsedData.icms_valor_substituto =
              data?.icms_valor_substituto?.replace(',', '.') || null;
            parsedData.icms_valor_retido_st =
              data?.icms_valor_retido_st?.replace(',', '.') || null;
          }

          if (csosnSelected === '101') {
            parsedData.icms_aliquota_credito_simples =
              data?.icms_aliquota_credito_simples?.replace(',', '.') || null;
            parsedData.icms_valor_credito_simples =
              data?.icms_valor_credito_simples?.replace(',', '.') || null;
          }
        } else {
          parsedData.ncm = data.ncm;
          parsedData.cfop = cfopValue?.value || data.cfop;
          parsedData.csosn = csosnValue?.value || data.csosn;
          parsedData.cest = cestValue?.value || data?.cest || null;
          parsedData.icms_origem = icmsOrigemValue?.value || data.icms_origem;
          parsedData.icms_aliquota = aliquotaValue || data.icms_aliquota;
          parsedData.icms_valor = icmsValorValue || data.icms_valor;

          parsedData.cst = cstValue?.value || data.cst;
          parsedData.pis_aliquota = pisAliquotaValue || data.pis_aliquota;
          parsedData.cofins_aliquota =
            cofinsAliquotaValue || data.cofins_aliquota;
          parsedData.icms_modalidade_base_calculo =
            icmsModalidadeBCValue?.value || data.icms_modalidade_base_calculo;
          parsedData.cbenef = cbenefValue || data.cbenef;

          if (user.has_nfce && data.cofins !== undefined) {
            parsedData.cofins = cofinsValue?.value || data.cofins;
          }

          if (user.has_nfce && data.pis !== undefined) {
            parsedData.pis = pisValue?.value || data.pis;
          }

          if (cstValue?.value === '20' || cstValue?.value === '40') {
            parsedData.icms_reducao_base_calculo =
              data?.icms_reducao_base_calculo?.replace(',', '.') || null;
            parsedData.icms_valor_desonerado =
              data?.icms_valor_desonerado?.replace(',', '.') || null;
            parsedData.icms_motivo_desoneracao =
              data?.icms_motivo_desoneracao || null;
          }

          if (cstValue?.value === '60') {
            parsedData.icms_base_calculo_retido_st =
              data?.icms_base_calculo_retido_st?.replace(',', '.') || null;
            parsedData.icms_aliquota_final =
              data?.icms_aliquota_final?.replace(',', '.') || null;
            parsedData.icms_valor_substituto =
              data?.icms_valor_substituto?.replace(',', '.') || null;
            parsedData.icms_valor_retido_st =
              data?.icms_valor_retido_st?.replace(',', '.') || null;
          }
        }

        parsedData.fcp_percentual = data?.fcp_percentual
          ? data?.fcp_percentual?.replace(',', '.')
          : null;
      }

      if (data.price_promotion && data.price_promotion.length > 0) {
        parsedData.price_promotion = formattedPromotionPrice;
      }
      if (data.image_id) {
        parsedData.image_id = data.image_id;
      }
      if (data.thumbnail_id) {
        parsedData.thumbnail_id = data.thumbnail_id;
      }

      if (hasInput && data.input_quantidade) {
        parsedData.has_input = hasInput;
        parsedData.input_quantidade = data.input_quantidade?.replace(',', '.');
      }

      if (fiscalGroup) {
        parsedData.fiscal_group_id = fiscalGroup;
      }

      if (user.use_stock) {
        parsedData.intermediaries = intermediaryArray || [];
        parsedData.inputs = inputArray || [];
      }

      if (currentCmv > 0) {
        parsedData.current_cmv = currentCmv;
      }

      if (currentCmvManualChecked) {
        parsedData.is_cmv_manual = true;
      }

      try {
        if (formRef?.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          price: Yup.number()
            .typeError('Somente números')
            .required('Preço obrigatório'),
          description: Yup.string().max(800, 'Máximo de 800 caracteres'),
          input_quantidade: hasInput
            ? Yup.string().required('Campo obrigatório')
            : Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.post('restaurants/products', parsedData);

        await api.put('restaurants/product-complements', {
          product_id: response.data.id,
          complement_categories: data.complement_categories,
        });

        toast.success('Produto cadastrado com sucesso!');

        getMenu(userScreenTop);
        handleSetProduct(response.data);

        cache.clearAll();

        const catFind = categories?.findIndex((cat) => cat.id === category.id);

        categories[catFind].products = [
          ...categories[catFind].products,
          response.data,
        ];
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRef?.current) {
            formRef.current.setErrors(errors);
          }
        } else {
          toast.error(`Erro ao cadastrar produto!`);
          console.log(error);
        }
      }
    }
  }

  const [applyFiscalArea, setApplyFiscalArea] = useState(false);
  function handleChangeApplyFiscalArea() {
    setApplyFiscalArea(!applyFiscalArea);
  }

  async function updateFiscalValues(id) {
    const group = fiscalGroups.find((group) => group.id === id);
    setFiscalGroup(id);
    setFiscalGroupDefault({
      value: group.id,
      label: group.title,
    });

    setFcpPercentual(group.fcp_percentual);
    setIcmsOrigemValue(
      icms_origem_options.find((x) => x.value === group.icms_origem)
    );
    setCsosnValue(csosn_options.find((x) => x.value === group.csosn));
    setCfopValue(NFeInfo.cfop_options.find((x) => x.value === group.cfop));
    setCofinsValue(
      NFeInfo.cofins_options.find((x) => x.value === group.cofins)
    );
    setPisValue(NFeInfo.cofins_options.find((x) => x.value === group.pis));
    setCestValue(group.cest);
    setIcmsValorValue(group.icms_valor);
    setAliquotaValue(group.icms_aliquota);
    setBaseCalculoValue(
      base_calculo_options.find((x) => x.value === group.base_calculo)
    );
    // regime normal
    setCstValue(
      NFeInfo.icms_situacao_tributaria.find((x) => x.value === group.cst)
    );
    setPisAliquotaValue(group.pis_aliquota);
    setCofinsAliquotaValue(group.cofins_aliquota);
    setIcmsModalidadeBCValue(
      NFeInfo.icms_modalidade_base_calculo.find(
        (x) => x.value === group.icms_modalidade_base_calculo
      )
    );
    setCbenefValue(group.cbenef);

    setIcmsBaseCalculoRetidoSt(group.icms_base_calculo_retido_st);
    setIcmsValorSubstituto(group.icms_valor_substituto);
    setIcmsValorRetidoSt(group.icms_valor_retido_st);
    setIcmsAliquotaFinal(group.icms_aliquota_final);
    setIcmsMotivoDesoneracao(group.icms_motivo_desoneracao);
    setIcmsValorDesoneradoValue(group.icms_valor_desonerado);
    setIcmsReducaoBaseCalculo(group.icms_reducao_base_calculo);
  }

  // inputs and intermediaries
  const [modalInput, setModalInput] = useState(false);
  const [createCashFlowOnNewInput, setCreateCashFlowOnNewInput] =
    useState(true);

  function handleCreateCashFlowOnNewInput() {
    if (createCashFlowOnNewInput === true) {
      setCreateCashFlowOnNewInput(false);
    } else {
      setCreateCashFlowOnNewInput(true);
    }
  }
  const toggleModalInput = () => {
    setModalInput(!modalInput);
    setCreateCashFlowOnNewInput(true);
  };

  const [modalIntermediary, setModalIntermediary] = useState(false);
  const toggleIntermediary = () => setModalIntermediary(!modalIntermediary);
  const [paidCheck, setPaidCheck] = useState(false);

  function handleSetPaidCheck() {
    setPaidCheck(!paidCheck);
  }

  const [, setSelectedComplement] = useState({});

  const [intermediaries, setIntermediaries] = useState([]);
  const [intermediaryState, setIntermediaryState] = useState();
  const [inputState, setInputState] = useState();
  const [inputQuantidade, setInputQuantidade] = useState();
  const [intermediaryQuantidade, setIntermediaryQuantidade] = useState();

  const [inputArray, setInputArray] = useState([]);
  const [intermediaryArray, setIntermediaryArray] = useState([]);

  const arrayInputs = [];

  const [restaurantIntermediaries, setRestaurantIntermediaries] = useState([]);
  const [restaurantIntermediarySelect, setRestaurantIntermediarySelect] =
    useState([]);
  const [intermediarySelectedId, setIntermediarySelectedId] = useState(null);

  const [inputs, setInputs] = useState([]);

  const getInputs = useCallback(async () => {
    try {
      const response = await api.get('restaurants/inputs-minimal');
      const inputs_id =
        inputs.length > 0 ? inputs?.map((input) => input.id) : [];

      const new_inputs = response.data.filter(
        (input) => !inputs_id.includes(input.id)
      );
      const select_inputs = new_inputs.map((input) => ({
        value: input.id,
        label: input.name,
        unidade: input.unidade,
        unitary_price: input.unitary_price,
        quantidade: input.quantidade,
        name: input.name,
        id: input.id,
      }));
      setRestaurantInputs(response.data);
      setRestaurantInputsSelect(select_inputs);
    } catch (err) {
      toast.error('Erro ao carregar insumos.');
      console.log(err.message);
    }
  }, [inputs]);
  const handleInput = useCallback(
    async (data) => {
      try {
        if (formInputRef.current) {
          formInputRef.current.setErrors({});
        }

        if (data.total_to_send && parseFloat(data.total_to_send) > 0) {
          const schema = Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            unidade: Yup.string().required('Unidade Obrigatória'),
            quantidade: Yup.string().required('Quantidade Obrigatória'),
            total_to_send: Yup.number().min(0, 'Não pode ser negativo'),
            category: Yup.string().required('Centro de custo obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });
        } else {
          const schema = Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            unidade: Yup.string().required('Unidade Obrigatória'),
            quantidade: Yup.string().required('Quantidade Obrigatória'),
            total_to_send: Yup.number().min(0, 'Não pode ser negativo'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });
        }

        const new_date = `${data.due_date}T03:00:00.000Z`;
        const new_paid = `${data.paid_at}T03:00:00.000Z`;

        const handleData = {
          name: data.name,
          quantidade: data.quantidade.replace(',', '.'),
          unidade: data.unidade,
          total: parseFloat(data.total_to_send.replace(',', '.')),
          cash_flow_category_subcategory_id: data.category || null,
          minimum_stock: data.minimum_stock,
          ideal_stock: data.ideal_stock,
          provider_id: data.provider_id || null,
          bank_account_id: data.bank_account_id || null,
          due_date: data.due_date ? new_date : null,
          paid: data.paid,
          paid_at: data.paid ? new_paid : null,
          create_cash_flow: data.create_cashflow,
        };

        const response = await api.post(`/restaurants/inputs`, handleData);
        setInputs((state) => {
          return [response.data, ...state];
        });

        toast.success('Insumo adiconado com sucesso!');
        setModalInput(false);
        setCreateCashFlowOnNewInput(true);

        setPaidCheck(!paidCheck);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formInputRef.current) {
            formInputRef.current.setErrors(errors);
          }
        } else {
          toast.error('Erro ao adicionar insumo.');
        }
      }
    },
    [paidCheck]
  );

  const getIntermediaries = useCallback(async () => {
    try {
      const response = await api.get('restaurants/intermediary-minimal');
      const intermediaries_id = intermediaries?.map((inter) => inter.id);

      const new_inter = response.data.filter(
        (inter) => !intermediaries_id.includes(inter.id)
      );
      const select_inter = new_inter.map((inter) => ({
        value: inter.id,
        label: inter.name,
        unidade: inter.unidade,
        unitary_price: inter.unitary_price,
        quantidade: inter.quantidade,
        name: inter.name,
        id: inter.id,
      }));
      setRestaurantIntermediaries(response.data);
      setRestaurantIntermediarySelect(select_inter);
    } catch (err) {
      toast.error('Erro ao carregar produtos intermediarios.');
    }
  }, [intermediaries]);

  useEffect(() => {
    const handle = restaurantInputs.filter(
      (input) => input.id === inputSelectedId
    );
    if (handle.length > 0) {
      setInputSelected(handle[0].unidade);
    }
  }, [inputSelectedId, restaurantInputs]);
  useEffect(() => {
    const inputs_id = inputs.length > 0 ? inputs?.map((input) => input.id) : [];

    const new_inputs = restaurantInputs.filter(
      (input) => !inputs_id.includes(input.id)
    );
    const select_inputs = new_inputs.map((input) => ({
      value: input.id,
      label: input.name,
      unidade: input.unidade,
      unitary_price: input.unitary_price,
      quantidade: input.quantidade,
      name: input.name,
      id: input.id,
    }));

    setRestaurantInputsSelect(select_inputs);
  }, [inputs, restaurantInputs]);

  useEffect(() => {
    const inter_id = intermediaries?.map((inter) => inter.id);

    const new_inter = restaurantIntermediaries.filter(
      (inter) => !inter_id.includes(inter.id)
    );
    const select_inter = new_inter.map((inter) => ({
      value: inter.id,
      label: inter.name,
      unidade: inter.unidade,
      unitary_price: inter.unitary_price,
      quantidade: inter.quantidade,
      name: inter.name,
      id: inter.id,
    }));

    setRestaurantIntermediarySelect(select_inter);
  }, [intermediaries, restaurantIntermediaries]);

  useEffect(() => {
    getInputs();
  }, [getInputs]);

  useEffect(() => {
    getIntermediaries();
  }, [getIntermediaries]);

  function handleAddIntermediaryAtArray() {
    if (intermediaryQuantidade) {
      const intermediaryAlreadyInserted = intermediaryArray.find(
        (item) => item.intermediary_id === intermediarySelectedId
      );
      if (!intermediaryAlreadyInserted) {
        setIntermediaryArray((state) => {
          return [
            {
              quantidade: intermediaryQuantidade.replace(',', '.'),
              intermediary_id: intermediarySelectedId,
              name: intermediaryState.name,
              unitary_price: intermediaryState.unitary_price,
            },
            ...state,
          ];
        });
        setIntermediaryQuantidade('');
      } else {
        toast.error('Inserir valor do consumo');
      }
    } else {
      toast.error('Inserir valor do consumo');
    }
  }

  function currencyFormatter(value) {
    if (!Number(value)) return 'R$ 0,00';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  }

  const unidade_options = [
    {
      value: 'UN',
      label: 'UNIDADE',
    },
    {
      value: 'KG',
      label: 'QUILOGRAMA',
    },
    {
      value: 'L',
      label: 'LITRO',
    },
    {
      value: 'ML',
      label: 'MILILITRO',
    },
    {
      value: 'PC',
      label: 'PACOTE',
    },
    {
      value: 'BD',
      label: 'BALDE',
    },
    {
      value: 'CX',
      label: 'CAIXA',
    },
    {
      value: 'FD',
      label: 'FARDO',
    },
    {
      value: 'GF',
      label: 'GARRAFA',
    },
    {
      value: 'G',
      label: 'GRAMA',
    },
    {
      value: 'GL',
      label: 'GALAO',
    },
    {
      value: 'PT',
      label: 'POTE',
    },
  ];

  const formIntermediaryRef = useRef(null);

  const formRefBank = useRef(null);
  const formRefSupplier = useRef(null);

  const [suppliers, setSuppliers] = useState();
  const [banks, setBanks] = useState();

  const getSuppliers = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows/providers');

      const providersSelect = response?.data?.map((provider) => {
        return {
          value: provider.id,
          label: provider.name,
        };
      });

      setSuppliers(providersSelect);
    } catch (error) {
      toast.error('Erro ao baixar fornecedores');
    }
  }, []);

  const getBanks = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows/bank-accounts');

      const banksSelect = response?.data?.map((bank) => {
        return {
          value: bank.id,
          label: bank.name,
        };
      });

      setBanks(banksSelect);
    } catch (error) {
      toast.error('Erro ao baixar contas de banco');
    }
  }, []);

  useEffect(() => {
    getBanks();
  }, [getBanks]);

  useEffect(() => {
    getSuppliers();
  }, [getSuppliers]);

  // create banks

  const [isModalCreateBankOpen, setIsModalCreateBankOpen] = useState(false);

  const toggleModalCreateBank = useCallback(() => {
    setIsModalCreateBankOpen(!isModalCreateBankOpen);
  }, [isModalCreateBankOpen]);

  const handleBankSubmit = useCallback(
    async (data) => {
      try {
        if (formRefBank?.current) {
          formRefBank.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/restaurants/cash-flows/bank-accounts', {
          name: data.name,
        });

        toast.success('Conta cadastrada com sucesso!');

        getBanks();
        toggleModalCreateBank();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRefBank?.current) {
            formRefBank.current.setErrors(errors);
          }
        } else {
          // console.tron.log(error);
          toast.error(`Erro ao cadastrar conta!`);
        }
      }
    },
    [getBanks, toggleModalCreateBank]
  );

  // create suplliers

  const [isModalCreateSuppliersOpen, setIsModalCreatesuppliersOpen] =
    useState(false);

  const toggleModalCreateSuplliers = useCallback(() => {
    setIsModalCreatesuppliersOpen(!isModalCreateSuppliersOpen);
  }, [isModalCreateSuppliersOpen]);

  const handleSupplierSubmit = useCallback(
    async (data) => {
      try {
        if (formRefSupplier?.current) {
          formRefSupplier.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          cnpj: Yup.string()
            .required('CNPJ/ CPF obrigatório')
            .trim()
            .matches('^[0-9]+$', 'Apenas números, sem espaço'),
          phone: Yup.string().required('Telefone obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/restaurants/cash-flows/providers', {
          name: data.name,
          phone: data.phone,
          cnpj: data.cnpj,
        });

        toast.success('Fornecedor cadastrada com sucesso!');

        getSuppliers();
        toggleModalCreateSuplliers();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRefSupplier?.current) {
            formRefSupplier.current.setErrors(errors);
          }
        } else {
          toast.error(`Erro ao cadastrar fornecedor!`);
        }
      }
    },
    [getSuppliers, toggleModalCreateSuplliers]
  );

  const [interInputs, setInterInputs] = useState([]);
  const [defaultInterValue, setDefaultInterValue] = useState(0);
  const [selectInput, setSelectInput] = useState([]);
  const [quantInter, setQuantInter] = useState(0);

  const [interInter, setInterInter] = useState([]);
  const [defaultInterInterValue, setDefaultInterInterValue] = useState(0);
  const [selectInter, setSelectInter] = useState([]);

  const [filterString] = useState('');
  const [, setUnidadeSelected] = useState('Kg');
  const [, setNameIntermediary] = useState('');
  const [yeldIntermediary, setYeldIntermediary] = useState(1);

  function deleteInputInter(id) {
    const updated = interInputs.filter((int) => int.id !== id);
    setInterInputs(updated);
  }

  function insertInputInter(selectInput, defaultInterValue) {
    const exists = interInputs.filter((int) => int.id === selectInput.value);

    if (exists.length > 0) {
      toast.error('Insumo já cadastrado');
    } else {
      setInterInputs((state) => {
        return [
          ...state,
          {
            name: selectInput.label,
            id: selectInput.value,
            quantidade_inter_value:
              defaultInterValue > 0 ? defaultInterValue.replace(',', '.') : 0,
            unidade: selectInput.unidade,
            unitary_price: selectInput.unitary_price,
            quantidade: selectInput.quantidade,
          },
        ];
      });
    }
  }

  async function handleIntermediary(data) {
    try {
      if (formIntermediaryRef.current) {
        formIntermediaryRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        unidade: Yup.string().required('Unidade Obrigatória'),
        quantidade_inter: Yup.string().required('Quantidade Obrigatória'),
        yield: Yup.string().required('Valor Obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const inputs = interInputs.map((inter) => ({
        id: inter.id,
        quantidade: inter.quantidade_inter_value,
      }));
      const intermediaries = interInter.map((inter) => ({
        id: inter.id,
        quantidade: inter.quantidade_inter_value,
      }));

      const handleData = {
        name: data.name,
        quantidade: quantInter.toString().replace(',', '.'),
        unidade: data.unidade,
        recipe: data.recipe,
        yield: data.yield,
        minimum_stock: data.minimum_stock,
        ideal_stock: data.ideal_stock,
        inputs,
        intermediaries,
      };

      const response = await api.post(`/restaurants/intermediary`, handleData);
      setIntermediaries((state) => {
        return [response.data, ...state];
      });

      setInterInputs([]);
      setInterInter([]);
      getInputs(filterString);
      toggleIntermediary();
      toast.success('Produto intermediario adiconado com sucesso!');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formIntermediaryRef.current) {
          formIntermediaryRef.current.setErrors(errors);
        }
      } else {
        toast.error('Erro ao adicionar produto intermediário.');
        console.log(error);
      }
    }
  }
  function insertInterInter(selectInter, defaultInterValue) {
    const exists = interInter.filter((int) => int.id === selectInter.value);

    if (exists.length > 0) {
      toast.error('Produto Intermediário já cadastrado');
    } else {
      setInterInter([
        ...interInter,
        {
          name: selectInter.label,
          id: selectInter.value,
          quantidade_inter_value:
            defaultInterValue > 0 ? defaultInterValue.replace(',', '.') : 0,
          unidade: selectInter.unidade,
          unitary_price: selectInter.unitary_price,
          quantidade: selectInter.quantidade,
        },
      ]);
    }
  }

  function deleteInterInter(id) {
    const updated = interInter.filter((int) => int.id !== id);
    setInterInter(updated);
  }

  const handleAddInputAtArray = useCallback(() => {
    if (inputQuantidade) {
      const inputsAlreadyInserted = inputArray.find(
        (item) => item.input_id === inputSelectedId
      );

      if (!inputsAlreadyInserted) {
        setInputArray((state) => {
          return [
            {
              quantidade: inputQuantidade.replace(',', '.'),
              input_id: inputSelectedId,
              name: inputState.name,
              unitary_price: inputState.unitary_price,
            },
            ...state,
          ];
        });
        setInputQuantidade('');
      } else {
        toast.error('Item já inserido');
      }
    } else {
      toast.error('Inserir valor do consumo');
    }
  }, [inputQuantidade, inputSelectedId, inputState, inputArray]);

  function handleDeleteIntermediaryArray(id) {
    const itensFiltered = intermediaryArray.filter(
      (item) => item.intermediary_id !== id
    );

    setIntermediaryArray(itensFiltered);
  }

  function handleDeleteInputArray(id) {
    const itensFiltered = inputArray.filter((item) => item.input_id !== id);

    setInputArray(itensFiltered);
  }

  const [activeButtonsSubmitQuantidade, setActiveButtonsSubmitQuantidade] =
    useState([]);

  function handleActiveButton(id) {
    setActiveButtonsSubmitQuantidade([id]);
  }

  const [inputQuantidadeAtList, setInputQuantidadeAtList] = useState();
  const [inputQuantidadeState, setInputQuantidadeState] = useState({});

  useEffect(() => {
    const inputFind = document.getElementById(`${inputQuantidadeState?.id}`);

    if (inputFind) {
      inputFind.value =
        inputQuantidadeState &&
        formatValue(
          parseFloat(inputQuantidadeState?.quantidade) *
            parseFloat(inputQuantidadeState?.unitary_price).toFixed(2) || '0.00'
        );
    }
  }, [inputQuantidadeState]);

  const [intermediaryQuantidadeAtList, setIntermediaryQuantidadeAtList] =
    useState();
  const [intermediaryQuantidadeState, setIntermediaryQuantidadeState] =
    useState({});

  useEffect(() => {
    const intermediaryFind = document.getElementById(
      `${intermediaryQuantidadeState?.id}`
    );

    if (intermediaryFind) {
      intermediaryFind.value =
        intermediaryQuantidadeState &&
        formatValue(
          parseFloat(intermediaryQuantidadeState?.quantidade) *
            parseFloat(intermediaryQuantidadeState?.unitary_price).toFixed(2) ||
            '0.00'
        );
    }
  }, [intermediaryQuantidadeState]);

  function handleChangeInputQuantidadeAtArray(id) {
    const inputFind = inputArray.find((item) => item.input_id === id);
    if (inputFind) {
      inputFind.quantidade = inputQuantidadeAtList;

      toast.success('Quantidade alterada.');
    }
  }

  function handleChangeIntermediaryQuantidadeAtArray(id) {
    const intermediaryFind = intermediaryArray.find(
      (item) => item.intermediary_id === id
    );
    if (intermediaryFind) {
      intermediaryFind.quantidade = intermediaryQuantidadeAtList;

      toast.success('Quantidade alterada.');
    }
  }

  useEffect(() => {
    console.log(inputArray);

    const inpTotal = inputArray.reduce(
      (acc, cur) =>
        acc +
        parseFloat(cur?.unitary_price) *
          parseFloat(cur?.ComplementInput?.quantidade || cur?.quantidade),
      0
    );
    const intTotal = intermediaryArray.reduce(
      (acc, cur) =>
        acc +
        parseFloat(cur.unitary_price) *
          parseFloat(
            cur?.ComplementIntermediary?.quantidade || cur?.quantidade
          ),
      0
    );
    // setTotalInputs((inpTotal + intTotal).toFixed(2));
    setCurrentCmv((inpTotal + intTotal).toFixed(2));
  }, [inputArray, intermediaryArray]);

  const motivoDesoneracaoOptions = [
    { value: '1', label: '1 - Táxi' },
    { value: '3', label: '2 - Produtor Agropecuário' },
    { value: '4', label: '4 - Frotista/Locadora' },
    { value: '5', label: '5 - Diplomático/Consular' },
    {
      value: '6',
      label:
        '6 - Utilitários e Motocicletas da Amazônia Ocidental e Áreas de Livre Comércio (Resolução 714/88 e 790/94 - CONTRAN e suas alterações)',
    },
    { value: '7', label: '7 - SUFRAMA' },
    { value: '8', label: '8 - Venda a Órgão Público' },
    { value: '9', label: '9 - Outros' },
    { value: '10', label: '10 - Deficiente Condutor (Convênio ICMS 38/12)' },
    {
      value: '11',
      label: '11 - Deficiente Não Condutor (Convênio ICMS 38/12)',
    },
  ];

  return (
    <Container>
      <ReactModal
        isOpen={modalInput}
        toggle={toggleModalInput}
        className="modal-content-takeat-without-overflow"
        closeTimeoutMS={500}
        onRequestClose={toggleModalInput}
      >
        <Form onSubmit={handleInput} ref={formInputRef}>
          <Row>
            <Col md="12">
              <p>Cadastrar Insumos</p>
            </Col>
          </Row>

          <InnerArea browserZoomLevel={browserZoomLevel}>
            <Row>
              <Col md="12">
                <Input label="Nome do Insumo:" name="name" type="text" />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Input
                  label="Estoque atual:"
                  name="quantidade"
                  type="number"
                  step="0.001"
                  icon={
                    <FaQuestionCircle
                      title="Quantidade de insumo adiciondo ao estoque"
                      color="orange"
                      style={{ marginLeft: 5 }}
                    />
                  }
                />
              </Col>
              <Col md="4">
                <Select
                  label="Unidade:"
                  name="unidade"
                  options={unidade_options}
                  defaultValue={unidade_options[1]}
                  // onChange={(e) => setInputSelectedId(e.value)}
                />
              </Col>
              <Col md="4">
                <Input
                  label="Valor Gasto"
                  name="total_to_send"
                  type="number"
                  step="0.01"
                  defaultValue="1"
                  icon={
                    <FaQuestionCircle
                      title="Custo referente à quantidade de insumo adicionado."
                      color="orange"
                      style={{ marginLeft: 5 }}
                    />
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Input
                  label="Estoque Mínimo"
                  name="minimum_stock"
                  type="number"
                  step="0.001"
                  icon={
                    <FaQuestionCircle
                      title="Caso o estoque do Insumo esteja abaixo da quantidade mínima, você receberá uma notificação na central de tarefas."
                      color="orange"
                      style={{ marginLeft: 5 }}
                    />
                  }
                />
              </Col>
              <Col md="4">
                <Input
                  label="Estoque Ideal"
                  name="ideal_stock"
                  type="number"
                  step="0.001"
                />
              </Col>

              <Col md="4">
                <Select
                  label="Associar centro de custo"
                  name="category"
                  options={categoriesDetails}
                  placeholder="Selecione a categoria"
                  // onChange={(e) => setInputSelectedId(e.value)}
                />
              </Col>
            </Row>

            <Row>
              <div
                style={{
                  display: 'flex',
                }}
              >
                <label>
                  <CheckboxInput
                    name="create_cashflow"
                    label="Vincular esta movimentação ao financeiro"
                    defaultChecked
                    onChange={() => {
                      handleCreateCashFlowOnNewInput();
                    }}
                  />
                </label>
              </div>
            </Row>

            {createCashFlowOnNewInput && (
              <>
                <p style={{ marginTop: 10 }}>
                  Informações Adicionais{' '}
                  <FaQuestionCircle
                    color="orange"
                    title="Complemente as informações do seu lançamento para uma melhor gestão de estoque."
                  />
                </p>
                <Row>
                  <Col md="4">
                    <Row>
                      <Col md="9" style={{ marginLeft: 5 }}>
                        <Select
                          label="Fornecedor"
                          name="provider_id"
                          options={suppliers}
                          placeholder="Fornecedor"
                          // onChange={(e) => setInputSelectedId(e.value)}
                        />
                      </Col>
                      <Col md="1">
                        <button
                          type="button"
                          style={{
                            width: 32,
                            height: 32,
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            background: '#3BD2C1',
                            borderRadius: '5px',
                            border: 'none',
                            marginBottom: 15,
                          }}
                          title="Cadastrar Fornecedor"
                          onClick={toggleModalCreateSuplliers}
                        >
                          <FaPlus />
                        </button>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="4">
                    <Row>
                      <Col md="9" style={{ marginLeft: 5 }}>
                        <Select
                          label="Conta"
                          name="bank_account_id"
                          options={banks}
                          placeholder="Conta"
                          // onChange={(e) => setInputSelectedId(e.value)}
                        />
                      </Col>
                      <Col md="1">
                        <button
                          type="button"
                          style={{
                            width: 32,
                            height: 32,
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            background: '#3BD2C1',
                            borderRadius: '5px',
                            border: 'none',
                            marginBottom: 15,
                          }}
                          onClick={toggleModalCreateBank}
                          title="Cadastrar Conta Bancária"
                        >
                          <FaPlus />
                        </button>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="4">
                    <Input
                      id="date-local"
                      label="Vencimento"
                      type="date"
                      name="due_date"
                      defaultValue={format(new Date(), 'yyyy-MM-dd')}
                      className="data"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <CheckboxInput
                      name="paid"
                      label="Pago"
                      onChange={handleSetPaidCheck}
                    />
                  </Col>{' '}
                </Row>

                {paidCheck && (
                  <Row>
                    <Col>
                      <Input
                        id="date-local"
                        label="Pago em:"
                        type="date"
                        name="paid_at"
                        defaultValue={format(new Date(), 'yyyy-MM-dd')}
                        className="data"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
          </InnerArea>

          <ButtonsArea>
            <PersonalButton
              color="#FF2C3A"
              message="Cancelar"
              onClick={toggleModalInput}
            />
            <PersonalButton color="#0CCAB4" message="Salvar" type="submit" />
          </ButtonsArea>
        </Form>
      </ReactModal>

      <ReactModal
        className="modal-content-takeat-without-overflow"
        closeTimeoutMS={500}
        isOpen={modalIntermediary}
        toggle={toggleIntermediary}
        onRequestClose={toggleIntermediary}
      >
        <Form onSubmit={handleIntermediary} ref={formIntermediaryRef}>
          <TabContent activeTab={activeIntermediaryTab}>
            <TabPane tabId="4">
              <InnerArea browserZoomLevel={browserZoomLevel}>
                <Row>
                  <Col md="12">
                    <p>Adicionar produto intermediário</p>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div style={{ lineHeight: '18px', display: 'flex' }}>
                      <p style={{ fontWeight: 'bold' }}>Nome:</p>
                      <span style={{ fontSize: 14, color: '#FF2C3A' }}>*</span>
                    </div>
                    <Input
                      name="name"
                      id="name_intermediary"
                      type="text"
                      onChange={(e) => setNameIntermediary(e.target.value)}
                    />
                  </Col>

                  <Col md="6" style={{ marginTop: 0 }}>
                    <div style={{ lineHeight: '18px', display: 'flex' }}>
                      <p style={{ fontWeight: 'bold' }}>Unidade:</p>
                      <span style={{ fontSize: 14, color: '#FF2C3A' }}>*</span>
                    </div>
                    <Select
                      name="unidade"
                      options={unidade_options}
                      defaultValue={unidade_options[1]}
                      onChange={(e) => setUnidadeSelected(e.label)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div style={{ lineHeight: '18px' }}>
                      <div style={{ lineHeight: '18px', display: 'flex' }}>
                        <p style={{ fontWeight: 'bold' }}>Rendimento</p>
                        <span style={{ fontSize: 14, color: '#FF2C3A' }}>
                          *
                        </span>
                      </div>
                      <span style={{ fontSize: 14 }}>
                        Inserir o rendimento da ficha técnica (receita)
                      </span>
                    </div>

                    <Input
                      name="yield"
                      defaultValue={1}
                      type="number"
                      step="0.001"
                      onChange={(e) => setYeldIntermediary(e.target.value)}
                    />
                  </Col>
                  <Col md="6">
                    <div style={{ lineHeight: '18px' }}>
                      <p style={{ fontWeight: 'bold' }}>
                        Quantidade em Estoque
                      </p>
                      <span style={{ fontSize: 14 }}>
                        Quantidade atual do produto intermediário
                      </span>
                    </div>
                    <Input
                      name="quantidade_inter"
                      onChange={(e) => setQuantInter(e.target.value)}
                      type="number"
                      step="0.001"
                      icon={
                        <FaQuestionCircle
                          title="Quantidade criada do Produto Intermediário nessa receita."
                          color="orange"
                          style={{ marginLeft: 5 }}
                        />
                      }
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <div style={{ lineHeight: '18px' }}>
                      <p style={{ fontWeight: 'bold' }}>Estoque Mínimo</p>
                      <span style={{ fontSize: 14 }}>
                        Você receberá uma notificação caso o estoque esteja
                        abaixo do mínimo
                      </span>
                    </div>
                    <Input
                      name="minimum_stock"
                      icon={
                        <FaQuestionCircle
                          title="Caso o estoque do Produto Intermediário esteja abaixo da quantidade mínima, você receberá uma notificação na central de tarefas.."
                          color="orange"
                          style={{ marginLeft: 5 }}
                        />
                      }
                    />
                  </Col>
                  <Col md="6">
                    <div style={{ lineHeight: '18px' }}>
                      <p style={{ fontWeight: 'bold' }}>Estoque ideal</p>
                      <span style={{ fontSize: 14 }}>
                        Você receberá uma notificação caso o seu estoque esteja
                        abaixo do ideal
                      </span>
                    </div>
                    <Input name="ideal_stock" />
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <div style={{ lineHeight: '18px' }}>
                      <p style={{ fontWeight: 'bold' }}>Receita</p>
                      <span style={{ fontSize: 14 }}>
                        Informe a receita desse produto intermediário
                      </span>
                    </div>
                    <Textarea name="recipe" type="text" maxlength={500} />
                  </Col>
                </Row>
              </InnerArea>
            </TabPane>
          </TabContent>

          <TabContent activeTab={activeIntermediaryTab}>
            <TabPane tabId="5">
              <InnerArea browserZoomLevel={browserZoomLevel}>
                <Row>
                  <Col md="12">
                    <p>Adicionar produto intermediário &gt; Ficha Técnica</p>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Select
                      label="Insumos"
                      name="inputs_select"
                      options={restaurantInputsSelect}
                      placeholder="Selecione"
                      onChange={(e) => setSelectInput(e)}
                      icon={
                        <FaQuestionCircle
                          title="Vincule os insumos usados na receita para produzir seu produto intermediário"
                          color="orange"
                          style={{ marginLeft: 5 }}
                        />
                      }
                    />
                  </Col>
                  <Col md="4">
                    <Input
                      label="Quantidade"
                      name="quantidade"
                      type="text"
                      defaultValue={0}
                      onChange={(e) => {
                        setDefaultInterValue(e.target.value);
                        document.getElementById('add').disabled = false;
                      }}
                      icon={
                        <FaQuestionCircle
                          title="Insira a quantidade necessária utilizada na receita para produzir seu produto intermediário."
                          color="orange"
                          style={{ marginLeft: 5 }}
                        />
                      }
                    />
                  </Col>
                  <Col md="2" style={{ margin: 'auto', marginBottom: 15 }}>
                    <button
                      type="button"
                      id="add"
                      style={{
                        background: '#3BD2C1',
                        width: 40,
                        height: 40,
                        borderRadius: '5px',
                        border: 'none',
                        fontSize: 16,
                      }}
                      onClick={() =>
                        insertInputInter(selectInput, defaultInterValue)
                      }
                    >
                      <FaPlus />
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <InputsTable borderless>
                      <thead>
                        <tr>
                          <th>Insumo</th>
                          <th>
                            Consumo unitário{' '}
                            <FaQuestionCircle
                              title="Quantidade necessária para
                            se produzir uma unidade do Produto Intermediário."
                              color="orange"
                              style={{ marginLeft: 2 }}
                            />
                          </th>
                          <th>
                            Custo do insumo{' '}
                            <FaQuestionCircle
                              title="Custo de uma unidade do insumo."
                              color="orange"
                              style={{ marginLeft: 2 }}
                            />
                          </th>
                          <th>
                            Consumo total{' '}
                            <FaQuestionCircle
                              title="Quantidade necessária para
                                se produzir uma receita do Produto Intermediário."
                              color="orange"
                              style={{ marginLeft: 2 }}
                            />
                          </th>
                          <th>
                            Custo da receita{' '}
                            <FaQuestionCircle
                              title="Custo para se produzir uma receita do Produto Intermediário."
                              color="orange"
                              style={{ marginLeft: 2 }}
                            />
                          </th>
                          <th>Disponível</th>
                          <th>Ações</th>
                        </tr>
                      </thead>

                      <tbody>
                        {interInputs.length > 0 &&
                          interInputs.map((input) => (
                            <tr>
                              <td>{input.name}</td>
                              <td>
                                {parseFloat(
                                  input.quantidade_inter_value /
                                    yeldIntermediary
                                ).toLocaleString('pt-BR', {
                                  minimumFractionDigits: 2,
                                })}{' '}
                                {input.unidade}
                              </td>

                              <td>
                                R$
                                {parseFloat(input.unitary_price).toLocaleString(
                                  'pt-BR',
                                  {
                                    minimumFractionDigits: 2,
                                  }
                                )}
                              </td>
                              <td>
                                {parseFloat(input.quantidade_inter_value)
                                  .toFixed(3)
                                  .replace('.', ',')}{' '}
                                {input.unidade}
                              </td>

                              <td>
                                R$
                                {parseFloat(
                                  input.quantidade_inter_value *
                                    // yeldIntermediary *
                                    input.unitary_price
                                ).toLocaleString('pt-BR', {
                                  minimumFractionDigits: 2,
                                })}
                              </td>
                              <td>
                                {' '}
                                {parseFloat(input.quantidade)
                                  .toFixed(4)
                                  .replace('.', ',')}{' '}
                                {input.unidade}
                              </td>

                              <td>
                                <Delete
                                  type="button"
                                  onClick={() => deleteInputInter(input.id)}
                                >
                                  <MdDelete size={20} />
                                </Delete>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </InputsTable>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ margin: 5 }} />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Select
                      label="Prod. Interm."
                      name="inter_select"
                      options={restaurantIntermediarySelect}
                      placeholder="Selecione"
                      onChange={(e) => setSelectInter(e)}
                      icon={
                        <FaQuestionCircle
                          title="Vincule os produtos intermediários usados na receita para produzir seu novo produto intermediário."
                          color="orange"
                          style={{ marginLeft: 5 }}
                        />
                      }
                    />
                  </Col>
                  <Col md="4">
                    <Input
                      label="Quantidade"
                      name="quantidade"
                      type="text"
                      defaultValue={0}
                      onChange={(e) => {
                        setDefaultInterInterValue(
                          e.target.value.replace(',', '.')
                        );
                        document.getElementById('add1').disabled = false;
                      }}
                    />
                  </Col>
                  <Col md="2" style={{ margin: 'auto', marginBottom: 15 }}>
                    <button
                      type="button"
                      id="add1"
                      style={{
                        background: '#3BD2C1',
                        width: 40,
                        height: 40,
                        borderRadius: '5px',
                        border: 'none',
                        fontSize: 16,
                      }}
                      onClick={() =>
                        insertInterInter(selectInter, defaultInterInterValue)
                      }
                    >
                      <FaPlus />
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <InputsTable borderless>
                      <thead>
                        <tr>
                          <th>Prod. interm.</th>
                          <th>Consumo unitário</th>
                          <th>Custo do Prod. Int.</th>
                          <th>Consumo total</th>
                          <th>Custo da receita</th>
                          <th>Disponível</th>
                          <th>Ações</th>
                        </tr>
                      </thead>

                      <tbody>
                        {interInter.length > 0 &&
                          interInter.map((inter) => (
                            <tr key={inter.id}>
                              <td>{inter.name}</td>
                              <td>
                                {parseFloat(
                                  inter.quantidade_inter_value /
                                    yeldIntermediary
                                ).toLocaleString('pt-BR', {
                                  minimumFractionDigits: 2,
                                })}{' '}
                                {inter.unidade}
                              </td>

                              <td>
                                R$
                                {parseFloat(inter.unitary_price).toLocaleString(
                                  'pt-BR',
                                  {
                                    minimumFractionDigits: 2,
                                  }
                                )}
                              </td>
                              <td>
                                {parseFloat(inter.quantidade_inter_value)
                                  .toFixed(3)
                                  .replace('.', ',')}
                                {inter.unidade}
                              </td>

                              <td>
                                R$
                                {parseFloat(
                                  inter.quantidade_inter_value *
                                    // yeldIntermediary *
                                    inter.unitary_price
                                ).toLocaleString('pt-BR', {
                                  minimumFractionDigits: 2,
                                })}
                              </td>

                              <td>
                                {' '}
                                {parseFloat(inter.quantidade)
                                  .toFixed(4)
                                  .replace('.', ',')}{' '}
                                {inter.unidade}
                              </td>

                              <td>
                                <Delete
                                  type="button"
                                  onClick={() => deleteInterInter(inter.id)}
                                >
                                  <MdDelete size={20} />
                                </Delete>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </InputsTable>
                  </Col>
                </Row>
              </InnerArea>
            </TabPane>
          </TabContent>

          <ButtonsArea>
            {activeIntermediaryTab === '4' && (
              <>
                <PersonalButton
                  color="#FF2C3A"
                  message="Cancelar"
                  onClick={toggleIntermediary}
                />
                <PersonalButton
                  color="#0CCAB4"
                  message="Próximo"
                  type="button"
                  onClick={() => setActiveIntermediaryTab('5')}
                />
              </>
            )}

            {activeIntermediaryTab === '5' && (
              <>
                <PersonalButton
                  color="#FF2C3A"
                  message="Voltar"
                  onClick={() => setActiveIntermediaryTab('4')}
                />
                <PersonalButton
                  color="#0CCAB4"
                  message="Salvar"
                  type="submit"
                />
              </>
            )}
          </ButtonsArea>
        </Form>
      </ReactModal>

      <div>
        <NavDiv>
          <NavDivTeste>
            <Nav tabs red borderless>
              <NavItem style={{ cursor: 'pointer' }}>
                <NavLink
                  className={activeTab === '1' ? 'active' : ''}
                  onClick={() => setActiveTab('1')}
                  disabled={activeTab !== 1}
                >
                  <NavLinkSpan>Produto</NavLinkSpan>
                </NavLink>
              </NavItem>

              <NavItem style={{ cursor: 'pointer' }}>
                <NavLink
                  className={activeTab === '2' ? 'active' : ''}
                  onClick={() => setActiveTab('2')}
                  disabled={activeTab !== 2}
                >
                  <NavLinkSpan>Fiscal</NavLinkSpan>
                </NavLink>
              </NavItem>

              <NavItem style={{ cursor: 'pointer' }}>
                <NavLink
                  className={activeTab === '3' ? 'active' : ''}
                  onClick={() => setActiveTab('3')}
                  disabled={activeTab !== 3}
                >
                  <NavLinkSpan>Estoque</NavLinkSpan>
                </NavLink>
              </NavItem>
            </Nav>
          </NavDivTeste>
        </NavDiv>
      </div>
      <Card>
        <Form onSubmit={handleSubmit} ref={formRef}>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="3">
              <InnerArea browserZoomLevel={browserZoomLevel}>
                <p style={{ color: '#818181', marginTop: 10 }}>
                  Some seu talento e paixão pela gastronomia a uma boa
                  administração.
                </p>

                <SwitcherArea>
                  <Switch
                    onChange={() => setHasInput(!hasInput)}
                    draggable
                    checked={hasInput}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={27}
                    width={44}
                    handleDiameter={16}
                    activeBoxShadow="0 0 2px 3px #111"
                    boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                    onColor="#0CCAB4"
                    offColor="#666666"
                  />
                  <div style={{ paddingLeft: 20 }}>
                    <p>Controlar quantidade de itens</p>
                    <span>
                      Tudo na sua mão: Você determina quantos ítens possui no
                      estoque, e a cada venda essa quantidade diminui. Ao chegar
                      em zero, o produto é pausado. Ideal para quem trabalha com
                      estoque limitado de produtos.
                    </span>
                  </div>
                </SwitcherArea>

                {hasInput && (
                  <div
                    style={{
                      display: 'flex',
                      width: 100,
                      alignItems: 'center',
                    }}
                  >
                    <Input name="input_quantidade" type="number" />
                    <span
                      style={{
                        marginLeft: 8,
                      }}
                    >
                      Qtde.
                    </span>
                  </div>
                )}

                <SwitcherArea>
                  <Switch
                    onChange={handleChangeApplyFiscalArea}
                    draggable
                    checked={applyFiscalArea}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={27}
                    width={44}
                    handleDiameter={16}
                    activeBoxShadow="0 0 2px 3px #111"
                    boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                    onColor="#0CCAB4"
                    offColor="#666666"
                    disabled={!user.use_stock}
                  />
                  <div style={{ paddingLeft: 20 }}>
                    <p>Controle de estoque</p>
                    <span>
                      Associe insumos ou produtos intermediários ao seu item e
                      tenha um controle total do seu restaurante. Ideal para
                      quem quer realizar gestão de produção/estoque. A
                      atualizaçõo do estoque ocorre uma vez ao dia às 03:00
                      (horário de Brasília).
                    </span>
                  </div>
                </SwitcherArea>

                {!user.use_stock && (
                  <StockBanner>
                    <div>
                      <img
                        src={StockImg}
                        alt="ilustração representando um estoque, com caixas e uma pessoa verificando no tablet"
                      />
                    </div>
                    <div>
                      <p>
                        Boas notícias! Seu plano atual já contempla a função de
                        controle de estoque!
                      </p>
                      <span>
                        Para ativar essa funcionalidade, entre em contato com o
                        time de suporte que sua ativação será realizada sem
                        custos extras.
                      </span>
                    </div>
                  </StockBanner>
                )}

                {applyFiscalArea && user.use_stock && (
                  <>
                    <InputsTable borderless style={{ width: '54%' }}>
                      <thead>
                        <tr>
                          <th>Custo do Item (CMV)</th>
                          <th>Valor de Venda</th>
                          <th>Lucro Bruto</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td style={{ color: '#A6803A' }}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <CmvButtons>
                                <NumberFormatStyled
                                  prefix="R$"
                                  name="value"
                                  format={currencyFormatter}
                                  defaultValue={formatValue(
                                    currentCmv || '0.00'
                                  )}
                                  disabled={!currentCmvManualChecked}
                                  value={formatValue(currentCmv || '0.00')}
                                  onChange={(e) =>
                                    setCurrentCmv(
                                      e.target.value
                                        .replace(/(R|\$|\s)/g, '')
                                        .replace(',', '.')
                                    )
                                  }
                                />
                              </CmvButtons>
                              <CheckboxUi
                                onClick={() =>
                                  setCurrentCmvManualChecked(
                                    !currentCmvManualChecked
                                  )
                                }
                                label="Inserir valor manualmente"
                                checked={currentCmvManualChecked}
                              />
                            </div>
                          </td>
                          <td>
                            R$
                            {parseFloat(productPriceState).toLocaleString(
                              'pt-BR',
                              {
                                minimumFractionDigits: 2,
                              }
                            )}
                          </td>
                          <td style={{ color: 'green' }}>
                            R$
                            {(
                              parseFloat(productPriceState) -
                              parseFloat(currentCmv || 0)
                            ).toLocaleString('pt-BR', {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                        </tr>
                      </tbody>
                    </InputsTable>

                    <InputsTable borderless style={{ marginTop: -40 }}>
                      <thead>
                        <tr>
                          <th>Insumo</th>
                          <th>Consumo</th>
                          <th>Valor</th>
                          <th>Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ width: '60%' }}>
                            <SelectStyled
                              placeholder="Selecione o insumo"
                              name="input"
                              options={restaurantInputsSelect}
                              onChange={(e) => {
                                setInputSelectedId(e.value);
                                setInputState(e);
                              }}
                            />
                          </td>

                          <td>
                            <InputStyled
                              name="input_quantidade"
                              placeholder="0"
                              type="number"
                              onChange={(e) =>
                                setInputQuantidade(e.target.value)
                              }
                              value={inputQuantidade}
                            />
                          </td>
                          <td>-</td>
                          <td>
                            <PersonalButton
                              color="#0CCAB4"
                              message="Associar"
                              onClick={handleAddInputAtArray}
                              style={{ float: 'right' }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <ButtonAdd onClick={toggleModalInput} type="button">
                            + Cadastrar novo
                          </ButtonAdd>
                        </tr>
                        {inputArray &&
                          inputArray.length > 0 &&
                          inputArray.map((item) => (
                            <tr>
                              <td>{item.name}</td>
                              <td style={{ display: 'flex' }}>
                                <InputStyled
                                  defaultValue={item.quantidade}
                                  type="number"
                                  key={item.quantidade}
                                  onClick={() =>
                                    handleActiveButton(item.input_id)
                                  }
                                  onChange={(e) => {
                                    setInputQuantidadeAtList(e.target.value);
                                    setInputQuantidadeState({
                                      id: `input${item?.id}`,
                                      quantidade: e.target.value,
                                      unitary_price: item?.unitary_price,
                                    });
                                  }}
                                />

                                <SubmitStyledButton
                                  type="button"
                                  disabled={
                                    !activeButtonsSubmitQuantidade.includes(
                                      item.input_id
                                    )
                                  }
                                  onClick={() =>
                                    handleChangeInputQuantidadeAtArray(
                                      item.input_id
                                    )
                                  }
                                >
                                  <FaCheck />
                                </SubmitStyledButton>
                              </td>
                              <td>
                                <NumberFormatStyledValue
                                  id={`input${item?.id}`}
                                  defaultValue={formatValue(
                                    item?.quantidade * item?.unitary_price ||
                                      '0.00'
                                  )}
                                  disabled
                                  value={formatValue(
                                    item?.quantidade * item?.unitary_price ||
                                      '0.00'
                                  )}
                                />
                              </td>
                              <td>
                                <FaTrash
                                  color="#FF2C3A"
                                  onClick={() => {
                                    handleDeleteInputArray(item.input_id);
                                  }}
                                  cursor="pointer"
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </InputsTable>
                    <InputsTable borderless>
                      <thead>
                        <tr>
                          <th>Produto Intermediário</th>
                          <th>Consumo</th>
                          <th>Valor</th>
                          <th>Ações</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td style={{ width: '60%' }}>
                            <SelectStyled
                              placeholder="Selecione o produto intermediário"
                              name="intermediary"
                              options={restaurantIntermediarySelect}
                              onChange={(e) => {
                                setIntermediarySelectedId(e.value);
                                setIntermediaryState(e);
                              }}
                            />
                          </td>
                          <td>
                            <InputStyled
                              name="intermediary_quantidade"
                              placeholder="0"
                              type="number"
                              onChange={(e) =>
                                setIntermediaryQuantidade(e.target.value)
                              }
                              value={intermediaryQuantidade}
                            />
                          </td>
                          <td>-</td>
                          <td>
                            <PersonalButton
                              color="#0CCAB4"
                              message="Associar"
                              onClick={handleAddIntermediaryAtArray}
                              style={{ float: 'right' }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <ButtonAdd onClick={toggleIntermediary} type="button">
                            + Cadastrar novo
                          </ButtonAdd>
                        </tr>

                        {intermediaryArray &&
                          intermediaryArray.length > 0 &&
                          intermediaryArray.map((item) => (
                            <tr>
                              <td>{item.name}</td>
                              <td style={{ display: 'flex' }}>
                                <InputStyled
                                  defaultValue={item.quantidade}
                                  type="number"
                                  key={item.quantidade}
                                  onClick={() =>
                                    handleActiveButton(item.intermediary_id)
                                  }
                                  onChange={(e) => {
                                    setIntermediaryQuantidadeAtList(
                                      e.target.value
                                    );
                                    setIntermediaryQuantidadeState({
                                      id: `inter${item?.id}`,
                                      quantidade: e.target.value,
                                      unitary_price: item?.unitary_price,
                                    });
                                  }}
                                />
                                <SubmitStyledButton
                                  type="button"
                                  disabled={
                                    !activeButtonsSubmitQuantidade.includes(
                                      item.intermediary_id
                                    )
                                  }
                                  onClick={() =>
                                    handleChangeIntermediaryQuantidadeAtArray(
                                      item.intermediary_id
                                    )
                                  }
                                >
                                  <FaCheck />
                                </SubmitStyledButton>
                              </td>
                              <td>
                                <NumberFormatStyledValue
                                  id={`inter${item?.id}`}
                                  defaultValue={formatValue(
                                    item?.quantidade * item?.unitary_price ||
                                      '0.00'
                                  )}
                                  disabled
                                  value={formatValue(
                                    item?.quantidade * item?.unitary_price ||
                                      '0.00'
                                  )}
                                />
                              </td>
                              <td>
                                <FaTrash
                                  color="#FF2C3A"
                                  onClick={() => {
                                    handleDeleteIntermediaryArray(
                                      item.intermediary_id
                                    );
                                  }}
                                  cursor="pointer"
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </InputsTable>
                  </>
                )}
              </InnerArea>
            </TabPane>
            <TabPane tabId="2">
              <InnerArea browserZoomLevel={browserZoomLevel}>
                {user.has_nfce ? (
                  <div style={{ paddingTop: 30 }}>
                    <Row>
                      <Col>
                        <h5>Fiscal</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Select
                          name="fiscal_group_id"
                          options={fiscalGroups?.map((group) => {
                            return {
                              label: group.title,
                              value: group.id,
                            };
                          })}
                          label="Grupo Fiscal"
                          onChange={(e) => updateFiscalValues(e.value)}
                          value={fiscalGroupDefault}
                        />
                      </Col>
                    </Row>
                    {user.regime_tributario === '1' ? (
                      <div>
                        <Row style={{ paddingTop: 10 }}>
                          <Col sm="12" lg="6">
                            <InputMask mask="99999999">
                              <Input label="NCM" name="ncm" type="text" />
                            </InputMask>
                          </Col>

                          <Col sm="12" lg="6" style={{ marginTop: 15 }}>
                            <Select
                              name="icms_origem"
                              options={icms_origem_options}
                              label="ICMS de Origem"
                              value={icmsOrigemValue}
                              key={icmsOrigemValue}
                              onChange={(e) => {
                                setIcmsOrigemValue(
                                  icms_origem_options.find(
                                    (x) => x.value === e.value
                                  )
                                );
                              }}
                            />
                          </Col>
                        </Row>
                        <Row style={{ paddingTop: 10 }}>
                          <Col sm="12" lg="6">
                            <Select
                              name="csosn"
                              options={csosn_options}
                              label="CSOSN"
                              onChange={(e) => {
                                setCsosnSelected(e.value);
                                setCsosnValue(
                                  csosn_options.find((x) => x.value === e.value)
                                );
                              }}
                              value={csosnValue}
                              key={csosnValue}
                            />
                          </Col>

                          <Col sm="12" lg="6">
                            <Select
                              name="cfop"
                              options={cfopOptions}
                              label="CFOP"
                              onChange={(e) => {
                                setCfopValue(
                                  NFeInfo.cfop_options.find(
                                    (x) => x.value === e.value
                                  )
                                );
                              }}
                              value={cfopValue}
                              key={cfopValue}
                            />
                          </Col>
                        </Row>

                        <Row style={{ paddingTop: 10 }}>
                          <Col sm="12" lg="6">
                            <Select
                              name="cofins"
                              options={nfeInfo.cofins_options}
                              label="Cofins"
                              onChange={(e) => {
                                setCofinsValue(
                                  NFeInfo.cofins_options.find(
                                    (x) => x.value === e.value
                                  )
                                );
                              }}
                              value={cofinsValue}
                              key={cofinsValue}
                            />
                          </Col>

                          <Col sm="12" lg="6">
                            <Select
                              name="pis"
                              options={nfeInfo.cofins_options}
                              label="Pis"
                              onChange={(e) => {
                                setPisValue(
                                  NFeInfo.cofins_options.find(
                                    (x) => x.value === e.value
                                  )
                                );
                              }}
                              value={pisValue}
                              key={pisValue}
                            />
                          </Col>
                        </Row>

                        {(csosnValue?.value === '00' ||
                          aliquotaValue ||
                          baseCalculoValue) && (
                          <div>
                            <Row style={{ paddingTop: 20 }}>
                              <Col sm="12" lg="6">
                                <Input
                                  label="Alíquota (Entre 0 e 100)"
                                  name="aliquota"
                                  type="text"
                                  value={aliquotaValue}
                                  key={aliquotaValue}
                                  onChange={(e) => {
                                    setAliquotaValue(e.target.value);
                                  }}
                                />
                              </Col>

                              <Col sm="12" lg="6" style={{ marginTop: 15 }}>
                                <Select
                                  name="base_calculo"
                                  options={base_calculo_options}
                                  label="Base Cálculo"
                                  value={baseCalculoValue}
                                  key={baseCalculoValue}
                                  onChange={(e) => {
                                    setBaseCalculoValue(
                                      base_calculo_options.find(
                                        (x) => x.value === e.value
                                      )
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          </div>
                        )}

                        {(csosnValue?.value === '500' || cestValue) && (
                          <Row style={{ paddingTop: 10 }}>
                            <Col sm="12" lg="6">
                              <InputMask
                                mask="9999999"
                                onChange={(e) => {
                                  setCestValue(e.target.value);
                                }}
                                value={cestValue}
                              >
                                <Input label="CEST" name="cest" type="text" />
                              </InputMask>
                            </Col>

                            {csosnValue?.value === '500' && (
                              <>
                                <Col sm="12" lg="3">
                                  <Input
                                    label="Base de Cálculo ICMS Retido na operação anterior"
                                    name="icms_base_calculo_retido_st"
                                    type="text"
                                  />
                                </Col>
                                <Col sm="12" lg="3">
                                  <Input
                                    label="Alíquota suportada pelo Consumidor Final"
                                    name="icms_aliquota_final"
                                    type="text"
                                  />
                                </Col>
                                <Col sm="12" lg="3">
                                  <Input
                                    label="Valor do ICMS próprio do Substituto"
                                    name="icms_valor_substituto"
                                    type="text"
                                  />
                                </Col>
                                <Col sm="12" lg="3">
                                  <Input
                                    label="Valor do ICMS ST Retido na operação anterior"
                                    name="icms_valor_retido_st"
                                    type="text"
                                  />
                                </Col>
                              </>
                            )}
                          </Row>
                        )}

                        {csosnValue?.value === '101' && (
                          <Row>
                            <Col sm="12" lg="6">
                              <Input
                                label="Alíquota crédito do ICMS"
                                name="icms_aliquota_credito_simples"
                                type="text"
                                placeholder="%"
                              />
                            </Col>
                            <Col sm="12" lg="6">
                              <Input
                                label="Valor crédito do ICMS"
                                name="icms_valor_credito_simples"
                                type="text"
                                placeholder="R$"
                              />
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col sm="12" lg="6">
                            <Input
                              label="Fundo de Comb. a Pobreza (%)"
                              name="fcp_percentual"
                              type="text"
                              value={fcpPercentual}
                              onChange={(e) => {
                                setFcpPercentual(e.target.value);
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <div>
                        <Row style={{ paddingTop: 10 }}>
                          <Col sm="12" lg="6">
                            <Select
                              name="icms_origem"
                              options={icms_origem_options}
                              label="ICMS de Origem"
                              value={icmsOrigemValue}
                              key={icmsOrigemValue}
                              onChange={(e) => {
                                setIcmsOrigemValue(
                                  icms_origem_options.find(
                                    (x) => x.value === e.value
                                  )
                                );
                              }}
                            />
                          </Col>

                          <Col sm="12" lg="6">
                            <Input
                              label="Alíquota ICMS (%)"
                              name="icms_aliquota"
                              type="text"
                              value={aliquotaValue}
                              key={aliquotaValue}
                              onChange={(e) => {
                                setAliquotaValue(e.target.value);
                              }}
                            />
                          </Col>
                        </Row>
                        <Row style={{ paddingTop: 10 }}>
                          <Col sm="12" lg="6">
                            <Select
                              name="cfop"
                              options={cfopOptions}
                              label="CFOP"
                              onChange={(e) => {
                                setCfopValue(
                                  NFeInfo.cfop_options.find(
                                    (x) => x.value === e.value
                                  )
                                );
                              }}
                              value={cfopValue}
                              key={cfopValue}
                            />
                          </Col>
                          <Col sm="12" lg="6">
                            <Select
                              name="cst"
                              options={NFeInfo.icms_situacao_tributaria}
                              label="CST"
                              onChange={(e) => {
                                setCstSelected(e.value);
                                setCstValue(
                                  NFeInfo.icms_situacao_tributaria.find(
                                    (x) => x.value === e.value
                                  )
                                );
                              }}
                              value={cstValue}
                              key={cstValue}
                            />
                          </Col>
                        </Row>

                        <Row style={{ paddingTop: 10 }}>
                          {/* <Col sm="12" lg="6">
                            <Select
                              name="csosn"
                              options={csosn_options}
                              label="CSOSN"
                              onChange={(e) => {
                                setCsosnSelected(e.value);
                                setCsosnValue(
                                  csosn_options.find((x) => x.value === e.value)
                                );
                              }}
                              value={csosnValue}
                              key={csosnValue}
                            />
                          </Col> */}
                          <Col sm="12" lg="6">
                            <InputMask mask="99999999">
                              <Input label="NCM" name="ncm" type="text" />
                            </InputMask>
                          </Col>
                        </Row>

                        <Row style={{ paddingTop: 10 }}>
                          <Col sm="12" lg="6">
                            <Select
                              name="pis"
                              options={nfeInfo.pis_situacao_tributaria}
                              label="PIS"
                              onChange={(e) => {
                                setPisValue(
                                  NFeInfo.cofins_options.find(
                                    (x) => x.value === e.value
                                  )
                                );
                              }}
                              value={pisValue}
                              key={pisValue}
                            />
                          </Col>

                          <Col sm="12" lg="6">
                            <Input
                              label="Alíquota PIS (%)"
                              name="pis_aliquota"
                              type="text"
                              onChange={(e) => {
                                setPisAliquotaValue(e.target.value);
                              }}
                              value={pisAliquotaValue}
                              key={pisAliquotaValue}
                            />
                          </Col>
                        </Row>

                        <Row style={{ paddingTop: 10 }}>
                          <Col sm="12" lg="6">
                            <Select
                              name="cofins"
                              options={nfeInfo.pis_situacao_tributaria}
                              label="COFINS"
                              onChange={(e) => {
                                setCofinsValue(
                                  NFeInfo.cofins_options.find(
                                    (x) => x.value === e.value
                                  )
                                );
                              }}
                              value={cofinsValue}
                              key={cofinsValue}
                            />
                          </Col>

                          <Col sm="12" lg="6">
                            <Input
                              label="Alíquota COFINS (%)"
                              name="cofins_aliquota"
                              type="text"
                              onChange={(e) => {
                                setCofinsAliquotaValue(e.target.value);
                              }}
                              value={cofinsAliquotaValue}
                              key={cofinsAliquotaValue}
                            />
                          </Col>
                        </Row>

                        <Row style={{ paddingTop: 10 }}>
                          <Col sm="12" lg="6">
                            <Select
                              name="icms_modalidade_base_calculo"
                              options={nfeInfo.icms_modalidade_base_calculo}
                              label="Modalidade Base Calculo"
                              onChange={(e) => {
                                setIcmsModalidadeBCValue(
                                  NFeInfo.icms_modalidade_base_calculo.find(
                                    (x) => x.value === e.value
                                  )
                                );
                              }}
                              value={icmsModalidadeBCValue}
                              key={icmsModalidadeBCValue}
                            />
                          </Col>

                          <Col sm="12" lg="6">
                            <Input
                              label="Código de Benefícios Fiscais (cBenef)"
                              name="cbenef"
                              type="text"
                              onChange={(e) => {
                                setCbenefValue(e.target.value);
                              }}
                              value={cbenefValue}
                              key={cbenefValue}
                            />
                          </Col>
                        </Row>

                        <Row>
                          {(['10', '30', '60', '70', '90'].includes(
                            cstValue?.value
                          ) ||
                            cestValue) && (
                            <Col sm="3" lg="3">
                              <InputMask
                                mask="9999999"
                                onChange={(e) => {
                                  setCestValue(e.target.value);
                                }}
                                value={cestValue}
                              >
                                <Input label="CEST" name="cest" type="text" />
                              </InputMask>
                            </Col>
                          )}

                          {cstValue?.value === '20' && (
                            <>
                              <Col sm="3" lg="3">
                                <Input
                                  label="Valor Desonerado ICMS"
                                  name="icms_valor_desonerado"
                                  type="text"
                                  placeholder="R$"
                                  onChange={(e) => {
                                    setIcmsValorDesoneradoValue(e.target.value);
                                  }}
                                  value={icmsValorDesoneradoValue}
                                />
                              </Col>
                              <Col sm="4" lg="4">
                                <Input
                                  label="Percentual de redução da base cálculo"
                                  name="icms_reducao_base_calculo"
                                  type="text"
                                  placeholder="%"
                                  onChange={(e) => {
                                    setIcmsReducaoBaseCalculo(e.target.value);
                                  }}
                                  value={icmsReducaoBaseCalculo}
                                />
                              </Col>
                              <Col sm="5" lg="5">
                                <Select
                                  name="icms_motivo_desoneracao"
                                  options={motivoDesoneracaoOptions}
                                  label="Motivo da desoneração do ICMS"
                                  value={
                                    motivoDesoneracaoOptions.find(
                                      (mot) =>
                                        mot.value === icmsMotivoDesoneracao
                                    ) || null
                                  }
                                />
                              </Col>
                            </>
                          )}

                          {cstValue?.value === '60' && (
                            <>
                              <Col sm="3" lg="3">
                                <Input
                                  label="Base de Cálculo ICMS Retido na operação anterior"
                                  name="icms_base_calculo_retido_st"
                                  type="text"
                                  onChange={(e) => {
                                    setIcmsBaseCalculoRetidoSt(e.target.value);
                                  }}
                                  value={icmsBaseCalculoRetidoSt}
                                />
                              </Col>
                              <Col sm="3" lg="3">
                                <Input
                                  label="Alíquota suportada pelo Consumidor Final"
                                  name="icms_aliquota_final"
                                  type="text"
                                  onChange={(e) => {
                                    setIcmsAliquotaFinal(e.target.value);
                                  }}
                                  value={icmsAliquotaFinal}
                                />
                              </Col>
                              <Col sm="3" lg="3">
                                <Input
                                  label="Valor do ICMS próprio do Substituto"
                                  name="icms_valor_substituto"
                                  type="text"
                                  onChange={(e) => {
                                    setIcmsValorSubstituto(e.target.value);
                                  }}
                                  value={icmsValorSubstituto}
                                />
                              </Col>
                              <Col sm="3" lg="3">
                                <Input
                                  label="Valor do ICMS ST Retido na operação anterior"
                                  name="icms_valor_retido_st"
                                  type="text"
                                  onChange={(e) => {
                                    setIcmsValorRetidoSt(e.target.value);
                                  }}
                                  value={icmsValorRetidoSt}
                                />
                              </Col>
                            </>
                          )}

                          {cstValue?.value === '40' && (
                            <>
                              <Col sm="6" lg="6">
                                <Input
                                  label="Valor Desonerado ICMS"
                                  name="icms_valor_desonerado"
                                  type="text"
                                  placeholder="R$"
                                  onChange={(e) => {
                                    setIcmsValorDesoneradoValue(e.target.value);
                                  }}
                                  value={icmsValorDesoneradoValue}
                                />
                              </Col>
                              <Col sm="6" lg="6">
                                <Select
                                  name="icms_motivo_desoneracao"
                                  options={motivoDesoneracaoOptions}
                                  label="Motivo da desoneração do ICMS"
                                  value={
                                    motivoDesoneracaoOptions.find(
                                      (mot) =>
                                        mot.value === icmsMotivoDesoneracao
                                    ) || null
                                  }
                                />
                              </Col>
                            </>
                          )}
                        </Row>

                        <Row>
                          <Col sm="12" lg="6">
                            <Input
                              label="Fundo de Comb. a Pobreza (%)"
                              name="fcp_percentual"
                              type="text"
                              defaultValue={fcpPercentual}
                              onChange={(e) => {
                                setFcpPercentual(e.target.value);
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    )}
                    {/* <Row style={{ paddingTop: 10 }}>
                      <Col sm="12" lg="6">
                        <InputMask mask="99999999">
                          <Input label="NCM" name="ncm" type="text" />
                        </InputMask>
                      </Col>

                      <Col sm="12" lg="6" style={{ marginTop: 15 }}>
                        <Select
                          name="icms_origem"
                          options={icms_origem_options}
                          label="ICMS de Origem"
                        />
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: 10 }}>
                      <Col sm="12" lg="6">
                        <Select
                          name="csosn"
                          options={csosn_options}
                          label="CSOSN"
                          onChange={(e) => setCsosnSelected(e.value)}
                        />
                      </Col>

                      <Col sm="12" lg="6">
                        <Select
                          name="cfop"
                          options={cfopOptions}
                          label="CFOP"
                        />
                      </Col>
                    </Row>

                    <Row style={{ paddingTop: 10 }}>
                      <Col sm="12" lg="6">
                        <Select
                          name="cofins"
                          options={nfeInfo.cofins_options}
                          label="Cofins"
                        />
                      </Col>

                      <Col sm="12" lg="6">
                        <Select
                          name="pis"
                          options={nfeInfo.cofins_options}
                          label="Pis"
                        />
                      </Col>
                    </Row> */}
                    {/* {csosnSelected === '00' && (
                      <div>
                        <Row style={{ paddingTop: 20 }}>
                          <Col sm="12" lg="6">
                            <Input
                              label="Alíquota (Entre 0 e 100)"
                              name="aliquota"
                              type="text"
                              maxlength="3"
                            />
                          </Col>

                          <Col sm="12" lg="6" style={{ marginTop: 15 }}>
                            <Select
                              name="base_calculo"
                              options={base_calculo_options}
                              label="Base Cálculo"
                            />
                          </Col>
                        </Row>
                      </div>
                    )} */}
                  </div>
                ) : (
                  <FiscalMessage>
                    <p>Seu plano atual não contempla a função de fiscal!</p>
                    <span>
                      Entre em contato com nosso time de suporte e faça a
                      ativação.
                    </span>
                  </FiscalMessage>
                )}
              </InnerArea>
            </TabPane>
            <TabPane tabId="1">
              <InnerArea browserZoomLevel={browserZoomLevel}>
                <Row>
                  <Col md="9">
                    <Row>
                      <Col md="6">
                        <Input
                          label="Nome"
                          name="name"
                          type="text"
                          placeholder="Nome do Produto"
                        />
                      </Col>
                      <Col md="6">
                        <Input
                          label="Atributo"
                          name="promotion"
                          type="text"
                          placeholder="Dose dupla, sugestão..."
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <Textarea
                          label="Descrição"
                          name="description"
                          type="textarea"
                          placeholder="Descrição do Produto"
                          maxlength={800}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col md="3" style={{ marginTop: 40 }}>
                    <ImageInput name="image_id" />
                  </Col>
                </Row>

                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                  }}
                >
                  <Checkbox label="Produto Esgotado" name="sold_off" />

                  <CheckboxInput
                    disabled={isCombo}
                    name="has_starting_price"
                    label={`Mostrar "a partir de R$ x,xx"`}
                  />

                  <div
                    style={{
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 10,
                    }}
                    onMouseEnter={() => setComboTooltip(true)}
                    onMouseLeave={() => setComboTooltip(false)}
                  >
                    <CheckboxInput
                      name="is_combo"
                      label="Produto combo"
                      onChange={(e) => {
                        setIsCombo(e.target.checked);
                        if (e.target.checked) {
                          formRef.current.setFieldValue(
                            'has_starting_price',
                            true
                          );
                        }
                      }}
                    />
                    <FaInfoCircle />
                    <Tooltip
                      content={
                        <div>
                          Ao marcar essa opção, o valor dos complementos
                          obrigatórios não serão exibidos ao seu cliente.{' '}
                          <a
                            style={{
                              color: '#2ec9b7',
                              fontWeight: 500,
                              textDecoration: 'underline',
                            }}
                            target="_blank"
                            href="https://produtotakeat.notion.site/Produto-do-tipo-Combo-2483248e2c1a4c64bf6f09e5a15b43af?pvs=4"
                          >
                            Entender melhor
                          </a>
                        </div>
                      }
                      show={comboTooltip}
                      containerStyles={{
                        top: 27,
                        padding: 10,
                      }}
                    />
                  </div>

                  <Checkbox label="Vendido por peso" name="use_weight" />

                  <Checkbox
                    label="Horário personalizado"
                    name="enable_times"
                    onChange={() => {
                      setEnableTimes(!enableTimes);
                    }}
                  />

                  {!user.only_delivery && (
                    <Checkbox
                      defaultChecked
                      label="Disponível no Presencial"
                      name="available"
                    />
                  )}

                  <Checkbox
                    defaultChecked
                    label="Disponível no Delivery"
                    name="available_in_delivery"
                  />

                  {!user.only_delivery && user?.has_service_tax && (
                    <CheckboxInput
                      name="charge_service_tax"
                      label={`Cobrar ${user.service_tax}% de taxa.`}
                      defaultChecked={user?.has_service_tax}
                    />
                  )}
                  <CheckboxInput name="is_unitary" label={`Escolha única`} />
                </div>
                {/* <Row>
                  <Col md="4">
                    <Checkbox label="Produto Esgotado" name="sold_off" />
                  </Col>

                  <Col md="4">
                    <CheckboxInput
                      disabled={isCombo}
                      name="has_starting_price"
                      label={`Mostrar "a partir de R$ x,xx"`}
                    />
                  </Col>

                  <Col md="4">
                    <CheckboxInput
                      name="is_combo"
                      label="Produto combo"
                      onChange={(e) => {
                        setIsCombo(e.target.checked);
                        if (e.target.checked) {
                          formRef.current.setFieldValue(
                            'has_starting_price',
                            true
                          );
                        }
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md="4">
                    <Checkbox label="Vendido por peso" name="use_weight" />
                  </Col>

                  <Col md="4">
                    <Checkbox
                      label="Horário personalizado"
                      name="enable_times"
                      onChange={() => {
                        setEnableTimes(!enableTimes);
                      }}
                    />
                  </Col>

                  <Col md="4">
                    <Checkbox
                      defaultChecked
                      label="Disponível no Delivery"
                      name="available_in_delivery"
                    />
                  </Col>
                </Row>

                <Row>
                  {!user.only_delivery && (
                    <Col md="4">
                      <Checkbox
                        defaultChecked
                        label="Disponível no Presencial"
                        name="available"
                      />
                    </Col>
                  )}

                  {!user.only_delivery && user?.has_service_tax && (
                    <>
                      <Col md="4">
                        <CheckboxInput
                          name="charge_service_tax"
                          label={`Cobrar ${user.service_tax}% de taxa.`}
                          defaultChecked={user?.has_service_tax}
                        />
                      </Col>
                    </>
                  )}
                </Row> */}

                {enableTimes && (
                  <PersonalizedHoursContainer>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>Dias:</span>
                      <div style={{ display: 'flex', gap: 5 }}>
                        <Button
                          type="button"
                          title="Dom"
                          buttonColor="#2ec9b7"
                          containerStyles={{
                            width: 60,
                            height: 30,
                            opacity: daysSelected[0] === 'f' ? 0.5 : 1,
                          }}
                          onClick={() => {
                            handleChangeDay(0);
                          }}
                        />
                        <Button
                          type="button"
                          title="Seg"
                          containerStyles={{
                            width: 60,
                            height: 30,
                            opacity: daysSelected[1] === 'f' ? 0.5 : 1,
                          }}
                          buttonColor="#2ec9b7"
                          onClick={() => {
                            handleChangeDay(1);
                          }}
                        />
                        <Button
                          type="button"
                          title="Ter"
                          containerStyles={{
                            width: 60,
                            height: 30,
                            opacity: daysSelected[2] === 'f' ? 0.5 : 1,
                          }}
                          buttonColor="#2ec9b7"
                          onClick={() => {
                            handleChangeDay(2);
                          }}
                        />
                        <Button
                          type="button"
                          title="Qua"
                          containerStyles={{
                            width: 60,
                            height: 30,
                            opacity: daysSelected[3] === 'f' ? 0.5 : 1,
                          }}
                          buttonColor="#2ec9b7"
                          onClick={() => {
                            handleChangeDay(3);
                          }}
                        />
                        <Button
                          type="button"
                          title="Qui"
                          containerStyles={{
                            width: 60,
                            height: 30,
                            opacity: daysSelected[4] === 'f' ? 0.5 : 1,
                          }}
                          buttonColor="#2ec9b7"
                          onClick={() => {
                            handleChangeDay(4);
                          }}
                        />
                        <Button
                          type="button"
                          title="Sex"
                          containerStyles={{
                            width: 60,
                            height: 30,
                            opacity: daysSelected[5] === 'f' ? 0.5 : 1,
                          }}
                          buttonColor="#2ec9b7"
                          onClick={() => {
                            handleChangeDay(5);
                          }}
                        />
                        <Button
                          type="button"
                          title="Sáb"
                          containerStyles={{
                            width: 60,
                            height: 30,
                            opacity: daysSelected[6] === 'f' ? 0.5 : 1,
                          }}
                          buttonColor="#2ec9b7"
                          onClick={() => {
                            handleChangeDay(6);
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>Horários:</span>
                      <div
                        style={{
                          display: 'flex',
                          gap: 5,
                          alignItems: 'center',
                        }}
                      >
                        <span>das</span>
                        <DefaultInput
                          placeholder="__:__"
                          mask="99:99"
                          maskChar="0"
                          style={{
                            width: '74px',
                            padding: '0px 10px',
                            height: '30px',
                          }}
                          value={hourSelected.start}
                          onChange={(e) => {
                            const value = e.target.value;
                            setHourSelected((state) => {
                              return {
                                start: handleTimeInput(value),
                                end: state.end,
                              };
                            });
                          }}
                        />
                        <span>às</span>
                        <DefaultInput
                          placeholder="__:__"
                          mask="99:99"
                          maskChar="0"
                          style={{
                            width: '74px',
                            padding: '0px 10px',
                            height: '30px',
                          }}
                          value={hourSelected.end}
                          onChange={(e) => {
                            const value = e.target.value;
                            setHourSelected((state) => {
                              return {
                                start: state.start,
                                end: handleTimeInput(value),
                              };
                            });
                          }}
                        />
                      </div>
                    </div>
                  </PersonalizedHoursContainer>
                )}

                {!user.only_delivery && (
                  <Row>
                    <Col md="3">
                      <Input
                        label="Preço no Presencial"
                        name="price"
                        type="number"
                        step="0.01"
                        min="0"
                        placeholder="R$"
                      />
                    </Col>
                    <Col md="3">
                      <Input
                        label="Preço Promo. Presenc."
                        name="price_promotion"
                        type="number"
                        step="0.01"
                        min="0"
                        placeholder="R$"
                      />
                    </Col>
                    {user?.delivery_info?.is_delivery_allowed && (
                      <>
                        <Col md="3">
                          <Input
                            label="Preço no Delivery"
                            name="delivery_price"
                            type="number"
                            step="0.01"
                            min="0"
                            placeholder="R$"
                          />
                        </Col>
                        <Col md="3">
                          <Input
                            label="Preço Promo. Delivery"
                            name="delivery_price_promotion"
                            type="number"
                            step="0.01"
                            min="0"
                            placeholder="R$"
                          />
                        </Col>{' '}
                      </>
                    )}
                  </Row>
                )}

                {user?.delivery_info?.is_delivery_allowed &&
                  user.only_delivery && (
                    <Row>
                      <Col md="3">
                        <Input
                          label="Preço no Delivery"
                          name="price"
                          type="number"
                          step="0.01"
                          min="0"
                          placeholder="R$"
                        />
                      </Col>
                      <Col md="3">
                        <Input
                          label="Preço Promo. Delivery"
                          name="delivery_price_promotion"
                          type="number"
                          step="0.01"
                          min="0"
                          placeholder="R$"
                        />
                      </Col>
                    </Row>
                  )}

                <Row>
                  <Select
                    name="complement_categories"
                    isMulti
                    options={complementCategories}
                    label="Associar categorias de complementos ao seu produto"
                  />
                </Row>
              </InnerArea>
            </TabPane>
          </TabContent>

          <ButtonsArea>
            {activeTab === '1' && (
              <>
                <PersonalButton
                  color="#FF2C3A"
                  message="Cancelar"
                  style={{
                    marginLeft: 0,
                  }}
                  onClick={() => {
                    togle();
                  }}
                />
                <PersonalButton
                  color="#0CCAB4"
                  message="Próximo"
                  type="submit"
                />
              </>
            )}
            {activeTab === '2' && (
              <>
                <PersonalButton
                  color="#FF2C3A"
                  message="Voltar"
                  style={{
                    marginLeft: 0,
                  }}
                  onClick={() => {
                    setActiveTab('1');
                  }}
                />
                <PersonalButton
                  color="#0CCAB4"
                  message="Próximo"
                  type="submit"
                />
              </>
            )}
            {activeTab === '3' && (
              <>
                <PersonalButton
                  color="#FF2C3A"
                  message="Voltar"
                  style={{
                    marginLeft: 0,
                  }}
                  onClick={() => {
                    setActiveTab('2');
                  }}
                />
                <PersonalButton
                  color="#0CCAB4"
                  message="Salvar"
                  type="submit"
                />
              </>
            )}
          </ButtonsArea>
        </Form>
      </Card>

      <Modal
        isOpen={isModalCreateSuppliersOpen}
        toggle={toggleModalCreateSuplliers}
      >
        <ModalHeader> Criar Fornecedor</ModalHeader>
        <Form onSubmit={handleSupplierSubmit} ref={formRefSupplier}>
          <ModalBody>
            <Input label="Nome do fornecedor" name="name" />
            <Input label="CNPJ" name="cnpj" />
            <InputMask mask="(99) 99999-9999">
              <Input
                label="Telefone"
                name="phone"
                maskChar=""
                placeholder="(xx) xxxxx-xxxx"
              />
            </InputMask>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={toggleModalCreateSuplliers}>
              Cancelar
            </Button>
            <Button color="success" type="submit">
              Salvar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={isModalCreateBankOpen} toggle={toggleModalCreateBank}>
        <ModalHeader> Criar Conta</ModalHeader>
        <Form onSubmit={handleBankSubmit} ref={formRefBank}>
          <ModalBody>
            <Input label="Nome do banco" name="name" />
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={toggleModalCreateBank}>
              Cancelar
            </Button>
            <Button color="success" type="submit">
              Salvar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Container>
  );
}

export default CreateProduct;
