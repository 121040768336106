import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 377px;

  border-radius: 5px;
  background: #fff;
  border: 2px solid #d9d9d9;
  position: relative;
`;

export const Complement = styled.span`
 font-size: 18px;
 font-weight: normal;
 margin-right: 10px;
 border-radius:49px;
 padding: 0px 10px;
 background: #F6F6F6;
 white-space: nowrap; 
  display: inline-flex;
  align-items: center;
  margin-top: 4px;
  color: #222;

  ${(props) =>
    (props.isVeryLate || props.isLate) &&
    css`
      background: #fff;
    `}


`

export const AverageTime = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

`

export const Orders = styled.div`
  height: 300px;

  background: #fff;
  border-radius: 5px;
  overflow-y: auto;
  padding-bottom: 48px;
`;

export const Header = styled.div`
  background: #d9d9d9;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
`;

export const BuyerInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  border-bottom: 2px solid #d9d9d9;
`;

export const OrdersInfo = styled.main`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  border-bottom: 1px solid #d9d9d9;
  

  ${(props) =>
    props.isLate &&
    css`
      background: #FFD894;
    `}

     ${(props) =>
    props.isVeryLate &&
    css`
      background: #DB6E7280;
    `}

    &:last-child {
      ${props => (props.status !== "pending" && props.status !== "doing") && css`
         border-radius: 0 0 8px 8px;
      `}
    }
`;

export const ConfirmButton = styled.button`
  width: 100%;
  height: 48px;
  background: #01afff;
  position: absolute;
  bottom: 0;
  border-radius: 0px 0px 5px 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: bold;

  ${(props) =>
    props.status === 'doing' &&
    css`
      background: #0ccab4;
    `}
`;

export const ExpandButton = styled.button`
  width: 100%;
  height: 40px;
  background: #f4f4f4;
  position: absolute;
  bottom: 48px;
  border-radius: 0px 0px 5px 5px;
  border: none;
  color: #3d3d3d;
  font-size: 14px;
  font-weight: bold;
`;

export const ProductName = styled.span`
  font-size: 18px;
  font-weight: bold;

  ${(props) =>
    (props.isVeryLate || props.isLate) &&
    css`
      color: #000;
    `}

  ${(props) =>
    (props.canceled || props.checked) &&
    css`
      text-decoration: line-through;
    `}

    ${(props) =>
    props.canceled &&
    css`
      text-decoration: line-through;
      color: #7A7A7A;
    `}
`;
