import { Modal } from 'reactstrap';
import styled from 'styled-components';

export const ToReceiveModalStyles = styled(Modal)`
  max-width: 70%;

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  h2 {
    margin: 0;
    font-weight: 500;
  }
`;
