import React from 'react';

import { Container } from './styles';

class PrintQrCode extends React.Component {
  render() {
    return (
      <Container>
        {this.props.table.table_code ? (
          <img src={this.props.table.table_code.url} alt="Qrcode" />
        ) : (
          <p>Nenhum QRCode para imprimir</p>
        )}
      </Container>
    );
  }
}

export default PrintQrCode;
