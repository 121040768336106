import { Table } from 'reactstrap';
import styled, { css } from 'styled-components';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 48px);
  /* overflow-y: auto; */
  padding: 20px;
`;
export const PageTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
`;


export const ButtonsArea = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 100px;
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding-right: 25px;
  padding-left: 10px;
  padding-top: 20px;

  border-top: 1px solid #c4c4c4;
  background: #fff;

  @media (max-width: 1300px) {
    bottom: 0px;
  }
`;
export const Card = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 349px);
  width: 100%;
  align-items: center;
  border-radius: 8px;
  background: #fff;
  padding: 0 20px;
  padding-bottom: 90px;
  transition: all 0.2s;
  box-shadow: 1px 0px 5px 1px rgba(0, 0, 0, 0.1);
  ${({ firstTab }) => firstTab && `border-top-left-radius: 0px;`}
`;

export const InnerArea = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 729px;
  max-height: 720px;
 position: relative;
  padding: 20px 20px 220px;

  @media (max-width: 1281px) {
    padding: 20px 20px 420px;
    max-height: 584px;
    height: 584px;
    margin-bottom: 420px;
  }

  @media (min-width: 1282px) and (max-width: 1450px) {
    padding: 20px 20px 420px;
    max-height: 620px;
    height: 620px;
  }


  ${(props) =>
    props.browserZoomLevel > 100 &&
    css`
      padding: 20px 20px 720px;
    `}
`;


export const CardTable = styled(Table)`
  border-collapse: separate;
  border-spacing: 0 20px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    border-top: none;
    tr {
      th {
        button {
          border: none;
          background-color: transparent;
          font-weight: bold;
          color: #333;
        }
      }
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    tr {
      td {
        border-bottom: 1px solid #33333310;
      }
    }
    td:last-child {
      text-align: right;
    }
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
`;

export const CategoryListItem = styled.div`
  width: 100%;

  align-items: center;
  border-bottom: 1px solid #33333310;
`;

export const CategoryListInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const DateSelectorDiv = styled.div`
  width: 90%;

  display: flex;
  align-items: center;
  margin-left: 60px;
  margin-top: 20px;

  padding-bottom: 20px;
`;

export const DateDiv = styled.div`
  float: left;
  left: 0;
  margin-left: 15px;
`;

export const SubmitDate = styled.button`
  height: 40px;
  border-radius: 8px;

  padding-left: 10px;
  padding-right: 10px;
  color: white;

  border: none;
  background: #17a2b8;
  margin-left: 10px;
  margin-top: 25px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
  }
`;

export const SelectDateButton = styled.button`
  height: 40px;
  border-radius: 20px;
  padding: 10px;

  color: white;
  align-self: center;
  margin-right: 15px;
  border: none;
  background: #17a2b8;
  margin-left: 10px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
  }

  :selected {
    border: none;
  }
`;

export const SelectDataArea = styled.div`
  margin-left: 0px;
`;

export const DatePickerStyledUnify = styled(DatePicker)`
  height: 44px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};



  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }
`;

export const DatePickerStyled = styled(DatePicker)`
  height: 44px;
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  margin-top: 8px;

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }
`;

export const NumberFormatStyled = styled(NumberFormat)`
  height: 44px;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  margin-top: 8px;

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }
`;

export const DataPickerArea = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  span {
    font-weight: bold;
  }
`;
export const FilterArea = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
  margin-top: 10px;
`;

export const DeleteFilterButton = styled.button`
  border: none;
  background: #17a2b8;
  color: #fff;
  padding: 8px 10px;
  border-radius: 8px;
`;

export const ErrorContainer = styled.div`
  width: 60%;
  min-width: 600px;
  margin: 10px auto 30px auto;

  background: orange;
  color: #fff;

  border-radius: 8px;
  padding: 30px;

  p {
    text-align: center;
    font-size: 22px;
  }
`;

export const ButtonAdd = styled.button`
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 10%;

  background: #3bd2c1;
  border-radius: 7px;
  border: none;
`;

export const StyledLink = styled(Link)`
  text-align: center;
  display: flex;
  align-items: center;
  background: green;
  margin: 10px 0;
  color: #fff;
  height: 36px;
  padding: 0 15px;
  font-weight: bold;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  transition: background 0.2s;
`;

export const StyledCard = styled.div`
  height: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 1px 0 5px 1px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
`;

export const InfoDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  p {
    margin: 0;
  }

  h3 {
    margin: 0;
    font-weight: 600;
  }
`;

export const CardIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ bgColor }) => bgColor || '#ff2c3a'};
  height: 50px;
  width: 50px;
  border-radius: 50%;
`;
