import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  grid-gap: 5px;
  justify-items: center;
  padding-top: 15px;
`;
