import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useContext,
  useMemo,
} from 'react';
import api from '../../services/api';

import { useAuth } from '~/context/AuthContext';

const WaiterTicketContext = createContext();

const WaiterTicketProvider = ({ children }) => {
  const { websocketManager } = useAuth();

  const [waiterTickets, setWaiterTickets] = useState(() => {
    const waiterTicketsStorage = localStorage.getItem(
      '@gddashboard:waiterTickets'
    );

    if (waiterTicketsStorage) {
      return JSON.parse(waiterTicketsStorage);
    }

    return [];
  }, []);

  const getTickets = useCallback(async () => {
    const response = await api.get('/restaurants/help-requests');

    const filtered = response.data.filter((ticket) => !ticket.checked);

    setWaiterTickets(filtered);
  }, []);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:waiterTickets',
      JSON.stringify(waiterTickets)
    );
  }, [waiterTickets]);

  useEffect(() => {
    getTickets();
  }, [getTickets]);

  /*
  useEffect(() => {
    let interval;
    if (user) {
      interval = setInterval(getAllClosingBills, 30000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [getAllClosingBills, user]);
  */

  useEffect(() => {
    if (websocketManager) {
      websocketManager.addMessageCallback(3, (data) => {
        if (data.type === 'update-help-request') {
          getTickets();
        } else if (data.type === 'new-help-request') {
          const waiterTicket = data.item;
          setWaiterTickets((state) => [waiterTicket, ...state]);
        }
      });
    }
  }, [websocketManager, getTickets, waiterTickets]);

  const value = useMemo(() => ({ waiterTickets }), [waiterTickets]);
  return (
    <WaiterTicketContext.Provider value={value}>
      {children}
    </WaiterTicketContext.Provider>
  );
};

function useWaiterTickets() {
  const context = useContext(WaiterTicketContext);

  if (!context) {
    throw new Error('useWaiterTickets must be used within an ClosingProvider');
  }

  return context;
}

export { WaiterTicketProvider, useWaiterTickets };
