import React, { useRef, useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { FiMenu } from 'react-icons/fi';

import OrderProductCategoryContext from '../context';

import { Container } from './styles';

function CategoryItem({ category, index }) {
  const ref = useRef();
  const { move, onDrop } = useContext(OrderProductCategoryContext);

  const [{ isDragging }, dragRef] = useDrag({
    item: { type: 'CATEGORYCARD', index, product_category_id: category.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (!monitor.didDrop()) {
        const draggedIndex = item.index;
        const targetIndex = index;

        onDrop(item.product_category_id, category.id, draggedIndex, targetIndex);
      }
    },
  });

  const [, dropRef] = useDrop({
    accept: 'CATEGORYCARD',
    hover(item, monitor) {
      const draggedIndex = item.index;
      const targetIndex = index;

      if (draggedIndex === targetIndex) {
        return;
      }

      const targetSize = ref.current.getBoundingClientRect();
      const targetCenter = targetSize.height / 2;
      const draggedOffset = monitor.getClientOffset();
      const draggedTop = draggedOffset.y - targetSize.top;
      if (draggedIndex < targetIndex && draggedTop < targetCenter) {
        return;
      }
      if (draggedIndex > targetIndex && draggedTop > targetCenter) {
        return;
      }
      move(item.product_category_id, category.id, draggedIndex, targetIndex);
      item.index = targetIndex;
    },
    drop(item, monitor) {
      const draggedIndex = item.index;
      const targetIndex = index;

      onDrop(item.product_category_id, category.id, draggedIndex, targetIndex);
    }
  });

  dragRef(dropRef(ref));

  return (
    <Container isDragging={isDragging} ref={ref}>
      <FiMenu />
      <div>
        <span>Categoria</span>
        <p>{category.name}</p>
      </div>
      <div>
        <span>Posição</span>
        <p>{index + 1}</p>
      </div>
    </Container>
  );
}

export default CategoryItem;
