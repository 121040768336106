import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  getDay,
  format,
  subMinutes,
  startOfDay,
  endOfDay,
  addMinutes,
} from 'date-fns';
import { AnimatePresence } from 'framer-motion';
import ReactDatePicker from 'react-datepicker';
import { MdCheck } from 'react-icons/md';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Spinner,
} from 'reactstrap';
import * as Yup from 'yup';
import ReactInputMask from 'react-input-mask';
import { FaPen, FaTrash } from 'react-icons/fa';
import {
  Container,
  Footer,
  ButtonGoToCart,
  CountBadge,
  ButtonAction,
  Content,
  SelectField,
  SelectStyled,
  SelectGroup,
  AddressField,
  StreetInputField,
  AcceptsPhrase,
  InputField,
  InputStyled,
  ErrorPhrase,
  UserInfoCard,
  DeleteUserInfoButton,
  ButtonGoToCartSubmit,
  FooterSubmit,
  PersonalSelectStyled,
  HoursAvailable,
  HourCheckbox,
  DataPickerArea,
  HourTitle,
  HourDiv,
} from './styles';

import logo_icon from '../../../assets/img/logo_icon.png';

import { useCart } from '~/context/OrderingSystem/Cart';
import Input from '~/components/Form/Input';
import api from '~/services/api';
import { useAuth } from '~/context/AuthContext';
import CheckboxInput from '~/components/Form/Checkbox';
import getValidationErrors from '~/services/getValidationErrors';
import { useMenu } from '~/context/OrderingSystem/Menu';
import { ModalConfirmation } from '~/components/ModalConfirmation';
import { useOrder } from '~/context/OrderingSystem/Order';
import { Button, Warning } from 'ui-kit-takeat';

function InputUserInfo({
  toggleModalOperation,
  discount,
  discountObs,
  adminPassword,
  setAdminPassword,
  setDeliveryTax,
  hasDeliveryTax,
  setHasDeliveryTax,
}) {
  const { cart, addCartToOrder } = useCart();
  const { user, paymentMethodsState } = useAuth();
  const { getMenu } = useMenu();
  const { isMakingOrder } = useOrder()

  const [method, setMethod] = useState();
  const [methodId, setMethodId] = useState('');
  const [isWithdrawalAllowed, setIsWithdrawalAllowed] = useState(false);
  const [willReceiveSms, setWillReceiveSms] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [, setSelectedNeihgboorhood] = useState('');

  const [paymentMethods, setPaymentMethods] = useState('');
  const [orderType, setOrderType] = useState('delivery');
  const [userChangeValue, setUserChangeValue] = useState(0);
  const [inputPhone, setInputPhone] = useState('');
  const [inputAddressIdx, setInputAddressIdx] = useState();
  const [optionStates, setOptionStates] = useState([]);
  const [optionCities, setOptionCities] = useState([]);
  const [optionNeihgboorhood, setOptionNeihgboorhood] = useState([]);

  const [buyerId, setBuyerId] = useState();
  const [inputAddressId, setInputAddressId] = useState();

  const [acceptsAllNeihgboorhoods, setAcceptsAllNeihgboorhoods] =
    useState(false);

  const [hasUsedDelivery, setHasUsedDelivery] = useState(true);
  const [renderPhrase, setRenderPhrase] = useState(false);

  const [userInfo, setUserInfo] = useState(null);
  const [userInfoSelected, setUserInfoSelected] = useState(null);
  const [details, setDetails] = useState();

  const [isModalEditAddressOpened, setIsModalEditAddressOpened] =
    useState(false);

  const [isModalEditUserOpened, setIsModalEditUserOpened] = useState(false);
  const [userEditInfo, setUserEditInfo] = useState();
  const [selectedAddressId, setSelectedAddressId] = useState([]);
  function toggleModalEditUserAddress(data) {
    setIsModalEditAddressOpened(!isModalEditAddressOpened);
    setUserEditInfo(data);
  }

  function toggleModalEditUser(data) {
    setIsModalEditUserOpened(!isModalEditUserOpened);
    setUserEditInfo(data);
  }

  const [isModalCreateAddressOpened, setIsModalCreateAddressOpened] =
    useState(false);

  function toggleModalCreateUser() {
    setIsModalCreateAddressOpened(!isModalCreateAddressOpened);
  }

  useEffect(() => {
    setHasDeliveryTax(true);
  }, []);

  const cartAmount = cart.reduce((accum, curr) => accum + curr.amount, 0);

  const restaurantId = user.id;

  const userRef = useRef(null);

  const formRef = useRef(null);

  const getDeliveryTax = async (address_id) => {
    const response = await api.post('/restaurants/buyers/delivery-tax', {
      buyer_address_id: address_id,
    });

    return response.data;
  };

  async function getUserInfo() {
    try {
      const response = await api.get(
        `/restaurants/buyers?phone=${userRef.current.value}`
      );

      const { id, delivery_addresses, has_used_delivery, name, email, phone } =
        response.data;

      setBuyerId(id);

      if (delivery_addresses && has_used_delivery) {
        setHasUsedDelivery(true);

        for (const address of delivery_addresses) {
          try {
            const tax = await getDeliveryTax(address.id);
            address.delivery_tax = tax;

            if (delivery_addresses.indexOf(address) === 0) {
              setDeliveryTax(tax);
              verifySelectedAddress(address.id);
            }
          } catch (err) {
            address.delivery_tax = null;
            if (err.response.data.errorType === 'delivery_max_distance') {
              address.delivery_tax = false;
            }
            if (delivery_addresses.indexOf(address) === 0) {
              setDeliveryTax(null);
            }
          }
        }

        setUserInfo({ name, email, phone, addresses: delivery_addresses });

        setUserInfoSelected({
          name,
          email,
          phone,
          address: delivery_addresses[0],
        });

        setRenderPhrase(false);
      } else {
        setRenderPhrase(true);
        setHasUsedDelivery(false);
        setUserInfo('');
      }
    } catch (error) {
      setRenderPhrase(true);
      setHasUsedDelivery(false);
      setUserInfo('');
      setDeliveryTax(null);
    }
  }

  function verifySelectedAddress(id) {
    if (selectedAddressId.includes(id)) {
      const newArray = selectedAddressId.filter((item) => item !== id);

      setSelectedAddressId(newArray);
    } else {
      setSelectedAddressId([id]);
    }
  }

  function handleChangeAddress(data) {
    setUserInfoSelected(data);
  }

  function setUserPhoneDigit(e) {
    if (e.charAt(14) !== '_') {
      getUserInfo();

      setInputPhone(e);
    }
  }

  function changeOrderType() {
    if (orderType === 'delivery') {
      setOrderType('withdrawal');
    }

    if (orderType === 'withdrawal') {
      setOrderType('delivery');
    }
  }

  async function handleGoToCartWithUser(registerPayment = null) {

    const infoUser = {
      ...userInfoSelected,
      with_withdrawal: isWithdrawalAllowed,
      payment_method_id: methodId,
      will_receive_sms: willReceiveSms,
      user_change: userChangeValue,
      has_delivery_tax: hasDeliveryTax,
    };

    if (user.order_cancel_password && discount) {
      if (adminPassword !== user.order_cancel_password) {
        toast.error('Senha administrativa incorreta.');
        return;
      }
    }
    if (!cart.some(basket => basket.amount > 0)) {
      toast.error("Adicione produtos ao carrinho");
      return;
    }

    if (cart.length < 1) {
      toast.error('Carrinho vazio');
    } else if (isSchedluing) {
      if (!hourSelected) {
        toast.error('Selecione o horário de agendamento.');
      } else {
        addCartToOrder(
          null,
          null,
          orderType,
          hasUsedDelivery,
          infoUser,
          toggleModalOperation,
          null,
          null,
          null,
          getMenu,
          discount,
          discountObs,
          null,
          null,
          hourSelected,
          null,
          details,
          registerPayment
        );
      }
    } else {
      addCartToOrder(
        null,
        null,
        orderType,
        hasUsedDelivery,
        infoUser,
        toggleModalOperation,
        null,
        null,
        null,
        getMenu,
        discount,
        discountObs,
        null,
        null,
        null,
        null,
        details,
        registerPayment
      );
    }
  }

  const [registerPayment, setRegisterPayment] = useState(null)

  const handleSubmit = (data) => {

    handleGoToCart(data, registerPayment);
  };


  async function handleGoToCart(data, registerPayment) {

    if (!cart.some(basket => basket.amount > 0)) {
      toast.error("Adicione produtos ao carrinho");
      return;
    }

    if (cart.length > 0) {
      if (user.order_cancel_password && discount) {
        if (adminPassword !== user.order_cancel_password) {
          toast.error('Senha administrativa incorreta.');
          return;
        }
      }

      if (isSchedluing) {
        if (!hourSelected) {
          toast.error('Selecione o horário de agendamento');
        } else {
          try {
            if (formRef.current) {
              formRef.current.setErrors({});
            }

            if (
              isWithdrawalAllowed.toString() === 'true' &&
              !acceptsAllNeihgboorhoods
            ) {
              const schema = Yup.object().shape({
                name: Yup.string().required('Nome obrigatório'),
                phone: Yup.string().required('Telefone Obrigatório'),
              });

              await schema.validate(data, {
                abortEarly: false,
              });
            }

            if (acceptsAllNeihgboorhoods) {
              const schema = Yup.object().shape({
                name: Yup.string().required('Nome obrigatório'),
                phone: Yup.string().required('Telefone Obrigatório'),
                street: Yup.string().required('Rua obrigatória'),
                neighborhood: Yup.string().required('Bairro obrigatório'),
              });

              await schema.validate(data, {
                abortEarly: false,
              });
            }

            if (
              !acceptsAllNeihgboorhoods &&
              isWithdrawalAllowed.toString() === 'false'
            ) {
              const schema = Yup.object().shape({
                name: Yup.string().required('Nome obrigatório'),
                phone: Yup.string().required('Telefone Obrigatório'),
                street: Yup.string().required('Rua obrigatória'),
              });

              await schema.validate(data, {
                abortEarly: false,
              });
            }

            data = {
              ...data,
              state: data.state || selectedState,
            };

            addCartToOrder(
              null,
              null,
              orderType,
              hasUsedDelivery,
              data,
              toggleModalOperation,
              null,
              null,
              null,
              getMenu,
              discount,
              discountObs,
              user.user_id,
              null,
              hourSelected,
              null,
              details,
              registerPayment
            );

          } catch (error) {
            if (error instanceof Yup.ValidationError) {
              const errors = getValidationErrors(error);
              if (formRef.current) {
                formRef.current.setErrors(errors);
              }
            } else {
              console.log(error);
            }
          }
        }
      } else {
        try {
          if (formRef.current) {
            formRef.current.setErrors({});
          }

          if (
            isWithdrawalAllowed.toString() === 'true' &&
            !acceptsAllNeihgboorhoods
          ) {
            const schema = Yup.object().shape({
              name: Yup.string().required('Nome obrigatório'),
              phone: Yup.string().required('Telefone Obrigatório'),
            });

            await schema.validate(data, {
              abortEarly: false,
            });
          }

          if (acceptsAllNeihgboorhoods) {
            const schema = Yup.object().shape({
              name: Yup.string().required('Nome obrigatório'),
              phone: Yup.string().required('Telefone Obrigatório'),
              street: Yup.string().required('Rua obrigatória'),
              neighborhood: Yup.string().required('Bairro obrigatório'),
            });

            await schema.validate(data, {
              abortEarly: false,
            });
          }

          if (
            !acceptsAllNeihgboorhoods &&
            isWithdrawalAllowed.toString() === 'false'
          ) {
            const schema = Yup.object().shape({
              name: Yup.string().required('Nome obrigatório'),
              phone: Yup.string().required('Telefone Obrigatório'),
              street: Yup.string().required('Rua obrigatória'),
            });

            await schema.validate(data, {
              abortEarly: false,
            });
          }

          data = {
            ...data,
            state: data.state || selectedState,
          };

          addCartToOrder(
            null,
            null,
            orderType,
            hasUsedDelivery,
            data,
            toggleModalOperation,
            null,
            null,
            null,
            getMenu,
            discount,
            discountObs,
            null,
            null,
            null,
            null,
            details,
            registerPayment
          );

        } catch (error) {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            if (formRef.current) {
              formRef.current.setErrors(errors);
            }
          } else {
            console.log(error);
          }
        }
      }
    } else {
      toast.error('Carrinho vazio');
    }
  }

  const getCitiesData = useCallback(async () => {
    try {
      const res = await api.get(
        `public/restaurants/delivery-addresses/${restaurantId}`
      );

      const dataCountries = res.data.countries;

      dataCountries
        .map((country) => country.states)
        .map((state) =>
          state.map((item) => {
            if (state.length === 1) {
              setSelectedState(item.name);
              setOptionStates('');
            } else if (state.length > 1) {
              setOptionStates((prevState) => [
                ...prevState,
                {
                  value: item.name,
                  label: item.name,
                },
              ]);
            }
          })
        );
    } catch (e) {
      console.log(e.message);
    }
  }, [restaurantId]);

  useEffect(() => {
    getCitiesData();
  }, [getCitiesData]);

  useEffect(() => {
    async function fetchDatas() {
      const res = await api.get(
        `public/restaurants/delivery-addresses/${restaurantId}`
      );

      const dataCountries = res.data.countries;

      const state = dataCountries
        .map((item) => item.states)
        .map((item) =>
          item.filter((statesFound) => statesFound.name === selectedState)
        );

      const parsedCities = state.map((item) =>
        item
          .map((citiesFound) => citiesFound.cities)
          .map((cityArray) =>
            cityArray.map((cityArrayFound) => {
              return {
                value: cityArrayFound.name,
                label: cityArrayFound.name,
              };
            })
          )
      );

      setOptionCities(parsedCities[0][0]);
    }

    fetchDatas();
  }, [selectedState, restaurantId]);

  const getPaymentMethods = useCallback(async () => {
    try {
      const paymentOptions = orderType === "delivery" ? paymentMethodsState
        .filter((item) => item.delivery_accepts && item.keyword !== 'pix_auto')
        .map((method) => {
          return {
            label: method.name,
            value: method.id,
          };
        }) : paymentMethodsState
          .filter((item) => item.withdrawal_accepts && item.keyword !== 'pix_auto')
          .map((method) => {
            return {
              label: method.name,
              value: method.id,
            };
          })

      setPaymentMethods(paymentOptions);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [orderType]);

  useEffect(() => {
    getPaymentMethods();
  }, [getPaymentMethods]);

  useEffect(() => {
    async function fetchData() {
      const res = await api.get(
        `public/restaurants/delivery-addresses/${restaurantId}`
      );

      const dataCountries = res.data.countries;

      const state = dataCountries
        .map((item) => item.states)
        .map((item) =>
          item.filter((statesFound) => statesFound.name === selectedState)
        );

      const cityParsed = state.map((item) =>
        item
          .map((it) => it.cities)
          .map((cty) => cty.filter((ct) => ct.name === selectedCity))
      );

      const neighborhoodsFound = [];

      cityParsed[0].map((ct) =>
        ct.map((nb) => {
          nb.neighborhoods.map((nbfound) => neighborhoodsFound.push(nbfound));
          if (nb.neighborhoods.length > 0) {
            setAcceptsAllNeihgboorhoods(false);
          } else {
            setAcceptsAllNeihgboorhoods(true);
          }
        })
      );

      // setDeliveryNeihgboorhood(neighborhoodsFound);

      const neighborhoodsParsed = neighborhoodsFound.map((nb) => {
        return {
          value: nb.name,
          label: nb.name,
        };
      });

      if (neighborhoodsParsed.length > 0) {
        setOptionNeihgboorhood(neighborhoodsParsed);
      } else {
        setOptionNeihgboorhood('');
      }
    }
    fetchData();
  }, [selectedCity, selectedState, restaurantId]);

  function handleEditUser(data) {
    setUserInfo((state) => {
      return {
        name: data.name,
        email: data.email,
        phone: data.phone,
        addresses: state.addresses,
      };
    });

    setUserInfoSelected((state) => {
      return {
        name: data.name,
        email: data.email,
        phone: data.phone,
        address: userInfoSelected.address,
      };
    });

    toggleModalEditUser();
  }

  async function handleCreateUser(data) {
    try {
      const response = await api.post('/restaurants/buyer/delivery-addresses', {
        country: 'BR',
        state: data.state || selectedState,
        buyer_id: buyerId,
        city: selectedCity,
        complement: data.complement,
        neighborhood: data.neighborhood,
        number: data.number,
        reference: data.reference,
        street: data.street,
        zip_code: data.zip_code,
      });

      try {
        const tax = await getDeliveryTax(response.data.id);

        setUserInfo((state) => {
          const addr = [...state.addresses, response.data];
          addr.find((a) => a.id === response.data.id).delivery_tax = tax;

          return { ...state, addresses: addr };
        });

        const newAddress = response.data;
        newAddress.delivery_tax = tax;
        setUserInfoSelected((state) => {
          return { ...state, address: newAddress };
        });

        setDeliveryTax(tax);
        setSelectedAddressId([response.data.id]);
        toggleModalCreateUser();
      } catch (error) {
        setUserInfo((state) => {
          const addr = [...state.addresses];
          addr[inputAddressIdx] = response.data;
          addr[inputAddressIdx].delivery_tax = null;

          if (error.response.data.errorType === 'delivery_max_distance') {
            addr[inputAddressIdx].delivery_tax = false;
          }
          return { ...state, addresses: addr };
        });

        setUserInfoSelected((state) => {
          return { ...state, address: response.data };
        });

        setDeliveryTax(null);
        toggleModalCreateUser();
        console.log('getDeliveryTax error', error);
        toast.error('Endereço fora da área de entrega.');
      }
    } catch (error) {
      toast.error('Erro ao adicionar novo endereço');
      console.log(error);
    }
  }

  async function handleEditUserAddresses(data) {
    try {
      const response = await api.put(
        `/restaurants/buyer/delivery-addresses/${inputAddressId}`,
        {
          buyer_id: buyerId,
          city: data.city,
          complement: data.complement,
          neighborhood: data.neighborhood,
          number: data.number,
          reference: data.reference,
          street: data.street,
          zip_code: data.zip_code,
          state: data.state || selectedState,
        }
      );

      try {
        const tax = await getDeliveryTax(response.data.id);

        setUserInfo((state) => {
          const addr = [...state.addresses];
          addr[inputAddressIdx] = response.data;
          addr[inputAddressIdx].delivery_tax = tax;

          return { ...state, addresses: addr };
        });

        const newAddress = response.data;
        newAddress.delivery_tax = tax;
        setUserInfoSelected((state) => {
          return { ...state, address: newAddress };
        });

        setDeliveryTax(tax);

        toggleModalEditUserAddress();
      } catch (error) {
        setUserInfo((state) => {
          const addr = [...state.addresses];
          addr[inputAddressIdx] = response.data;
          addr[inputAddressIdx].delivery_tax = null;

          if (error.response.data.errorType === 'delivery_max_distance') {
            addr[inputAddressIdx].delivery_tax = false;
          }
          return { ...state, addresses: addr };
        });

        setUserInfoSelected((state) => {
          return { ...state, address: response.data };
        });

        setDeliveryTax(null);

        toggleModalEditUserAddress();
        console.log('getDeliveryTax error', error);
        toast.error('Endereço fora da área de entrega.');
      }
    } catch (error) {
      console.log(error);
    }
  }

  const editUseRef = useRef(null);

  // scheduling
  const [isSchedluing, setIsSchedluing] = useState(false);
  const [loadingTimes, setLoadingTimes] = useState(false);
  const [activeDays, setRestaurantActiveDays] = useState([]);
  const [restaurantSettings, setRestaurantSettings] = useState(null);
  const [hourSelected, setHourSelected] = useState(null);
  const [startDate, setStartDate] = useState(startOfDay(new Date()));
  const [hours, setHours] = useState([]);
  const [maxDate, setMaxDate] = useState(null);

  const getRestaurantConfigs = useCallback(async () => {
    try {
      const response = await api.get(`/restaurants/order-scheduling/`);
      setRestaurantSettings(response.data);
    } catch (err) {
      console.log('getRestaurantConfigs error: ', err);
    }
  }, []);

  const getTimes = async (day) => {
    setLoadingTimes(true);
    try {
      const response = await api.get(
        '/restaurants/order-scheduling/available-times',
        {
          params: {
            day,
            with_withdrawal: orderType.toString() === 'withdrawal',
          },
        }
      );

      setHours(response.data.times);
    } catch (err) {
      console.log('getTimes error: '.err);
    }
    setLoadingTimes(false);
  };

  const getFirstLetter = (word) => {
    return word.substring(0, 1).toUpperCase();
  };

  const handleDateUpdate = (date) => {
    setStartDate(date);
  };

  useEffect(() => {
    const day = startDate.getDay();
    if (activeDays.includes(day)) {
      getTimes(startDate.toISOString());
    }
  }, [startDate, activeDays]);

  const isWeekday = (date) => {
    const day = getDay(date);

    const ref_date = new Date(date).getTime();

    if (ref_date < startOfDay(new Date())) {
      return false;
    }

    if (maxDate) {
      if (ref_date > maxDate.getTime()) {
        return false;
      }
    }
    // activeDays.split('').map(item => {
    //   return {

    //   }
    // })

    return activeDays.includes(day);
  };

  useEffect(() => {
    if (!restaurantSettings) {
      setRestaurantActiveDays([]);
      return;
    }

    const daysFilter = [];

    const active_days =
      orderType === 'delivery'
        ? restaurantSettings.active_days
        : restaurantSettings.withdrawal_active_days;

    for (let i = 0; i < 7; i += 1) {
      if (active_days[i] === 't') {
        daysFilter.push(i);
      }
    }

    const max_time_in_advance =
      orderType === 'delivery'
        ? restaurantSettings.max_time_in_advance
        : restaurantSettings.withdrawal_max_time_in_advance;

    if (max_time_in_advance) {
      const start = startOfDay(new Date());
      const end = endOfDay(addMinutes(start, max_time_in_advance));

      setMaxDate(end);
    } else {
      setMaxDate(null);
    }

    setRestaurantActiveDays(daysFilter);
  }, [restaurantSettings, orderType]);

  useEffect(() => {
    getRestaurantConfigs();
  }, [getRestaurantConfigs]);

  const [isModalDeleteOpened, setIsModalDeleteOpened] = useState(false);
  const [deliveryAddressIdToDelete, setDeliveryAddressIdToDelete] = useState();

  function toggleModalDelete(id) {
    setIsModalDeleteOpened(!isModalDeleteOpened);
    setDeliveryAddressIdToDelete(id);
  }

  async function handleDeleteAddress() {
    try {
      await api.put('/restaurants/buyer/delete/delivery-addresses', {
        buyer_address_id: deliveryAddressIdToDelete,
      });

      const userInfoFiltered = userInfo.addresses.filter(
        (address) => address.id !== deliveryAddressIdToDelete
      );
      userInfo.addresses = userInfoFiltered;

      toast.success('Endereço deletado com sucesso!');
      setUserInfoSelected((state) => {
        return { ...state, address: null }
      });
      setDeliveryTax(null);
      toggleModalDelete();
    } catch (error) {
      toast.error('Erro ao deletar endereço');
      console.log(error.message);
    }
  }

  //warning state
  const warningButtonStorage = JSON.parse(
    localStorage.getItem('@gddashboard:warningButtonStorage')
  );
  const [warningbutton, setWarningButton] = useState(
    warningButtonStorage === null ? true : warningButtonStorage
  );

  function toggleWarning() {
    setWarningButton(false)
    localStorage.setItem('@gddashboard:warningButtonStorage', false)

  }

  return (
    <Container>
      <ModalConfirmation
        isOpen={isModalDeleteOpened}
        toggle={toggleModalDelete}
        confirm={handleDeleteAddress}
        cancel={toggleModalDelete}
        title={'Deseja realmente deletar esse endereço?'}
      />

      <Modal isOpen={isModalEditUserOpened} toggle={toggleModalEditUser}>
        <Form initialData={userEditInfo} onSubmit={handleEditUser}>
          <ModalHeader>Editar cliente</ModalHeader>
          <ModalBody>
            <AddressField>
              <Input type="text" placeholder="Nome" name="name" />

              <InputStyled mask="(99) 99999-9999">
                <Input name="phone" placeHolder="Telefone" />
              </InputStyled>

              <Input name="email" placeHolder="Email" />
            </AddressField>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-danger"
              type="button"
              onClick={toggleModalEditUser}
            >
              Cancelar
            </button>
            <button className="btn btn-success" type="submit">
              Salvar
            </button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={isModalEditAddressOpened}
        toggle={toggleModalEditUserAddress}
      >
        <Form
          initialData={userEditInfo}
          onSubmit={handleEditUserAddresses}
          ref={editUseRef}
        >
          <ModalHeader>Editar endereço</ModalHeader>
          <ModalBody>
            <AddressField>
              <StreetInputField>
                {' '}
                <InputField>
                  <Input
                    type="text"
                    placeholder="Digite sua rua ..."
                    name="street"
                    id="street"
                  />
                </InputField>
                <InputField>
                  <Input
                    type="text"
                    placeholder="Número"
                    name="number"
                    id="number"
                  />
                </InputField>
              </StreetInputField>

              <Input type="text" placeholder="Bairro" name="neighborhood" />

              <Input name="city" placeHolder="Cidade" />
              <Input name="state" placeHolder="Estado" />

              <Input
                type="text"
                name="complement"
                placeholder="Ex: Casa 3, fundos"
              />

              <Input
                type="text"
                name="reference"
                placeholder="Ex: Prox. ao mercado x"
              />
            </AddressField>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-danger"
              type="button"
              onClick={toggleModalEditUserAddress}
            >
              Cancelar
            </button>
            <button className="btn btn-success" type="submit">
              Salvar
            </button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={isModalCreateAddressOpened} toggle={toggleModalCreateUser}>
        <Form onSubmit={handleCreateUser} ref={formRef}>
          <ModalHeader>Adicionar endereço</ModalHeader>
          <ModalBody>
            <AddressField>
              {optionStates.length > 0 && orderType === 'delivery' && (
                <SelectField>
                  <SelectStyled
                    name="state"
                    options={optionStates}
                    placeholder="Selecione seu estado ..."
                    onChange={(e) => {
                      setSelectedState(e.value);
                    }}
                    isSearchable
                    noOptionsMessage={() => 'nada encontrado ...'}
                  />
                </SelectField>
              )}
              {orderType === 'delivery' && (
                <SelectField>
                  <SelectStyled
                    name="city"
                    options={optionCities}
                    placeholder="Selecione sua cidade ..."
                    onChange={(e) => {
                      setSelectedCity(e.value);
                    }}
                    isSearchable
                    noOptionsMessage={() => 'nada encontrado ...'}
                  />
                </SelectField>
              )}
              <StreetInputField>
                {' '}
                <InputField>
                  <Input
                    type="text"
                    placeholder="Digite sua rua ..."
                    name="street"
                    id="street"
                  />
                </InputField>
                <InputField>
                  <Input
                    type="text"
                    placeholder="Número"
                    name="number"
                    id="number"
                  />
                </InputField>
              </StreetInputField>

              {optionNeihgboorhood.length === 0 && orderType === 'delivery' && (
                <Input
                  type="text"
                  placeholder="Bairro"
                  name="neighborhood"
                  // value={selectedNeihgboorhood}
                  // onChange={(e) => {
                  //   setSelectedNeihgboorhood(e.target.value);
                  // }}
                  autoComplete="off"
                />
              )}

              {optionNeihgboorhood.length > 0 && (
                <SelectField>
                  <SelectStyled
                    name="neighborhood"
                    options={optionNeihgboorhood}
                    placeholder="Selecione seu bairro ..."
                    onChange={(e) => {
                      setSelectedNeihgboorhood(e.value);
                    }}
                    isSearchable
                    noOptionsMessage={() => 'nada encontrado ...'}
                  />
                </SelectField>
              )}

              <Input
                name="city"
                placeHolder="Cidade"
                style={{ display: 'none' }}
              />
              <Input
                name="state"
                placeHolder="Estado"
                style={{ display: 'none' }}
              />

              <Input
                type="text"
                name="complement"
                placeholder="Ex: Casa 3, fundos"
              />

              <Input
                type="text"
                name="reference"
                placeholder="Ex: Prox. ao mercado x"
              />
            </AddressField>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-danger"
              type="button"
              onClick={toggleModalCreateUser}
            >
              Cancelar
            </button>
            <button className="btn btn-success" type="submit">
              Salvar
            </button>
          </ModalFooter>
        </Form>
      </Modal>
      <Content>
        <InputStyled
          mask="(99) 99999-9999"
          type="text"
          name="new-password"
          placeholder="Buscar pelo celular"
          onChange={(e) => {
            setUserPhoneDigit(e.target.value);
          }}
          ref={userRef}
          autoComplete="new-password"
          autoCorrect="off"
          maskChar=""
          maskPlaceHolder=""
          spellCheck="false"
        />

        {renderPhrase && inputPhone && inputPhone.charAt(14) !== '_' && (
          <ErrorPhrase>
            Usuário ainda não pediu no delivery, favor preencher os dados
            abaixo.
          </ErrorPhrase>
        )}

        <Form>
          {user.delivery_info.is_withdrawal_allowed &&
            user.delivery_info.is_withdrawal_active && (
              <CheckboxInput
                label="Retirada"
                id="is_withdrawal_allowed"
                name="is_withdrawal_allowed"
                onChange={(e) => {
                  setIsWithdrawalAllowed(!isWithdrawalAllowed);
                  changeOrderType();
                }}
              />
            )}

          {!isWithdrawalAllowed && (
            <CheckboxInput
              label="Cobrar taxa de entrega"
              id="has_delivery_tax"
              name="has_delivery_tax"
              checked={hasDeliveryTax}
              onChange={(e) => setHasDeliveryTax(!hasDeliveryTax)}
            />
          )}

          {/* {user.delivery_info.has_sms_service && (
            <CheckboxInput
              label="Enviar SMS quando o pedido estiver pronto"
              id="will_receive_sms"
              name="will_receive_sms"
              onChange={(e) => setWillReceiveSms(!willReceiveSms)}
            />
          )} */}
          {user.is_order_scheduling_active && (
            <CheckboxInput
              label="Agendado"
              name="is_scheduling"
              value={isSchedluing}
              onChange={() => {
                setIsSchedluing(!isSchedluing);
              }}
            />
          )}
        </Form>

        {isSchedluing && (
          <DataPickerArea>
            <div style={{ position: 'relative' }}>
              <ReactDatePicker
                dateFormat="dd MMMM yyyy"
                formatWeekDay={(day) => getFirstLetter(day)}
                locale="pt"
                selected={startDate}
                onSelect={(e) => handleDateUpdate(e)}
                onChange={(e) => handleDateUpdate(e)}
                customInput={<span />}
                open
                filterDate={isWeekday}
              />
            </div>

            <HourDiv>
              {/* {!daySelected && <p>Selecione o dia desejado para que os horários sejam exibidos.</p>} */}
              <HourTitle>
                <span>Hora</span>
              </HourTitle>

              <HoursAvailable>
                <AnimatePresence>
                  {loadingTimes ? (
                    <Spinner style={{ margin: '15px auto', display: 'block' }}>
                      <span className="visually-hidden">Carregando...</span>
                    </Spinner>
                  ) : (
                    hours?.map((h, i) => {
                      const hour = new Date(h);
                      const isDisabled =
                        subMinutes(hour, restaurantSettings.time_in_advance) <
                        new Date();

                      return (
                        <HourCheckbox
                          initial={{ x: -200 }}
                          animate={{ x: 0 }}
                          exit={{ x: -200 }}
                          transition={{
                            type: 'spring',
                            stiffness: 50,
                            mass: 1,
                          }}
                          key={i}
                          onClick={() => {
                            if (!isDisabled) {
                              /* setHourSelected( */
                              /*   hour.getHours() > 21 ? addDays(hour, 1) : h */
                              /* ); */
                              setHourSelected(h);
                            }
                          }}
                          selected={h === hourSelected}
                          disabled={isDisabled}
                        >
                          <div>
                            {!isDisabled && (
                              <MdCheck size={15} color="#ffffff" />
                            )}
                          </div>
                          <p style={{ color: isDisabled && '#979797' }}>
                            {format(hour, "HH'h'mm")}
                          </p>
                        </HourCheckbox>
                      );
                    })
                  )}
                </AnimatePresence>
              </HoursAvailable>
            </HourDiv>
          </DataPickerArea>
        )}

        {!hasUsedDelivery && inputPhone && inputPhone.charAt(14) !== '_' && (
          <Form onSubmit={handleSubmit} ref={formRef}>
            <Input name="name" placeHolder="Nome" />

            <ReactInputMask mask="(99) 99999-9999" defaultValue={inputPhone}>
              <Input name="phone" placeHolder="Telefone" />
            </ReactInputMask>

            <Input name="email" placeHolder="Email" />

            <SelectGroup>
              {optionStates.length > 0 && orderType === 'delivery' && (
                <SelectField>
                  <SelectStyled
                    name="state"
                    options={optionStates}
                    placeholder="Selecione seu estado ..."
                    onChange={(e) => {
                      setSelectedState(e.value);
                    }}
                    isSearchable
                    noOptionsMessage={() => 'nada encontrado ...'}
                  />
                </SelectField>
              )}
              {orderType === 'delivery' && (
                <SelectField>
                  <SelectStyled
                    name="cities"
                    options={optionCities}
                    placeholder="Selecione sua cidade ..."
                    onChange={(e) => {
                      setSelectedCity(e.value);
                    }}
                    isSearchable
                    noOptionsMessage={() => 'nada encontrado ...'}
                  />
                </SelectField>
              )}

              {optionNeihgboorhood.length > 0 && orderType === 'delivery' && (
                <SelectField>
                  <SelectStyled
                    name="neighborhood"
                    options={optionNeihgboorhood}
                    placeholder="Selecione seu bairro ..."
                    onChange={(e) => {
                      setSelectedNeihgboorhood(e.value);
                    }}
                    isSearchable
                    noOptionsMessage={() => 'nada encontrado ...'}
                  />
                </SelectField>
              )}
            </SelectGroup>

            {acceptsAllNeihgboorhoods.toString() === 'true' && (
              <AcceptsPhrase>
                <p>{`Aceitamos delivery em todos os bairros em ${selectedCity}`}</p>
              </AcceptsPhrase>
            )}

            <AddressField>
              {/* {orderType === 'delivery' && (
                <div>
                  <ReactInputMask
                    mask="99999-999"
                    onChange={(e) => handleSearchCepNew(e)}
                  >
                    <Input name="zip_code" placeHolder="CEP" />
                  </ReactInputMask>
                  {!validCep && (
                    <Row>
                      <p style={{ color: 'red', textAlign: 'right' }}>
                        CEP inválido.
                      </p>
                    </Row>
                  )}
                </div>
              )} */}

              {orderType === 'delivery' && (
                <StreetInputField>
                  <InputField>
                    <Input
                      type="text"
                      placeholder="Digite sua rua ..."
                      name="street"
                      id="street"
                      // value={selectedStreet}
                      // onChange={(e) => {
                      //   setSelectedStreet(e.target.value);
                      // }}
                      autoComplete="off"
                    />
                  </InputField>
                  <InputField>
                    <Input
                      type="text"
                      placeholder="Número"
                      name="number"
                      id="number"
                      // value={selectedNumber}
                      // onChange={(e) => {
                      //   setSelectedNumber(maskOnlyNumbers(e.target.value));
                      // }}
                      autoComplete="off"
                    />
                  </InputField>
                </StreetInputField>
              )}
              {optionNeihgboorhood.length === 0 && orderType === 'delivery' && (
                <Input
                  type="text"
                  placeholder="Bairro"
                  name="neighborhood"
                  // value={selectedNeihgboorhood}
                  // onChange={(e) => {
                  //   setSelectedNeihgboorhood(e.target.value);
                  // }}
                  autoComplete="off"
                />
              )}

              {orderType === 'delivery' && (
                <>
                  {/* <Input
                    type="text"
                    placeholder="Seu CEP"
                    name="zip_code"
                    value={selectedZipCode}
                    onChange={(e) => {
                      setSelectedZipCode(maskOnlyNumbers(e.target.value));
                    }}
                    autoComplete="off"
                  /> */}

                  <Input
                    name="city"
                    placeHolder="CEP"
                    style={{ display: 'none' }}
                  />
                  <Input
                    name="state"
                    placeHolder="CEP"
                    style={{ display: 'none' }}
                  />

                  <Input
                    type="text"
                    name="complement"
                    placeholder="Ex: Casa 3, fundos"
                    // value={selectedComplement}
                    // onChange={(e) => {
                    //   setSelectedComplement(e.target.value);
                    // }}
                    autoComplete="off"
                  />

                  <Input
                    type="text"
                    name="reference"
                    placeholder="Ex: Prox. ao mercado x"
                    // value={selectedReference}
                    // onChange={(e) => {
                    //   setSelectedReference(e.target.value);
                    // }}
                    autoComplete="off"
                  />
                </>
              )}

              <SelectGroup>
                <SelectField>
                  <SelectStyled
                    name="payment_method"
                    options={paymentMethods}
                    isSearchable
                    placeholder="Método de pagamento"
                    onChange={(e) => setMethod(e.label)}
                  />
                  {method === 'Dinheiro' && (
                    <Input
                      name="user_change"
                      placeholder="Troco pra quanto? (R$)"
                      style={{ marginTop: 10 }}
                      type="number"
                      step="0.01"
                    />
                  )}
                </SelectField>
              </SelectGroup>

              <Input
                name="details"
                label="Informações adicionais"
                onChange={(e) => setDetails(e.target.value)}
              />
            </AddressField>

            <FooterSubmit>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {
                  method && (
                    <div style={{ position: "relative" }}>
                      <Button
                        buttonColor="#2EC9B7"
                        inverted
                        title="Pedir e Registrar Pagamento"
                        disabled={isMakingOrder}
                        style={{ marginRight: 8 }}
                        type='submit'
                        onClick={() => setRegisterPayment('register-payment')}
                      />

                      <Warning
                        containerStyles={{
                          maxWidth: 600,
                          width: 410,
                          position: "absolute",
                          bottom: 40,
                          right: 20,
                          flexDirection: "column"
                        }}
                        content="Agora você pode registrar o pagamento no caixa ao fazer o pedido, informando o método de pagamento"
                        onClick={toggleWarning}
                        show={warningbutton}
                        title="Registrar pagamento"
                        type="success"
                      />
                    </div>


                  )
                }
                <Button
                  buttonColor="#2EC9B7"
                  title="Fazer Pedido"
                  disabled={isMakingOrder}
                  style={{ marginLeft: "auto" }}
                  type='submit'
                  onClick={() => setRegisterPayment(null)}
                />
              </div>
            </FooterSubmit>
          </Form>
        )}

        {userInfo && (
          <>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                justifyContent: 'space-between',
                padding: 8,
              }}
            >
              <div>
                <p>{userInfo.name}</p>
                <p>{userInfo.phone}</p>
                <p>{userInfo.email}</p>
              </div>
              <div>
                <FaPen
                  onClick={() => toggleModalEditUser(userInfo)}
                  title="Editar cliente"
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </div>

            {!isWithdrawalAllowed &&
              userInfo.addresses.map((address, idx) => (
                <UserInfoCard selected={selectedAddressId.includes(address.id)}>
                  <button
                    title="Selecionar endereço"
                    style={{
                      background: 'transparent',
                      border: 'none',
                      textAlign: 'left',
                      width: '90%',
                    }}
                    disabled={address.delivery_tax === false || address.delivery_tax === null}
                    onClick={() => {
                      handleChangeAddress({
                        name: userInfo.name,
                        email: userInfo.email,
                        phone: userInfo.phone,
                        address,
                      });
                      verifySelectedAddress(address.id);
                      setDeliveryTax(address.delivery_tax);
                    }}
                    type="button"
                  >
                    <p>
                      {address.street} - {address.number},{' '}
                      {address.neighborhood}
                    </p>
                    <p>
                      {address.city} / {address.state} - {address.zip_code}
                    </p>

                    <p>
                      {address.complement && `${address.complement} -`}{' '}
                      {address.reference}
                    </p>
                    <p style={{
                      textDecoration: !hasDeliveryTax ? 'line-through' : 'none',
                    }}>
                      {address.delivery_tax === false || address.delivery_tax === null
                        ? 'Endereço de entrega muito distante'
                        : `Taxa de entrega: R$${address.delivery_tax}`}
                    </p>
                  </button>

                  <div>
                    <FaPen
                      onClick={() => {
                        toggleModalEditUserAddress(address);
                        setInputAddressIdx(idx);
                        setInputAddressId(address.id);
                      }}
                      title="Editar endereço"
                    />
                    <FaTrash
                      style={{ marginLeft: 4 }}
                      color="#ff2c3a"
                      cursos="pointer"
                      onClick={() => toggleModalDelete(address.id)}
                      title="Deletar endereço"
                    />
                  </div>
                </UserInfoCard>
              ))}

            {userInfo.addresses.length < 2 && (
              <DeleteUserInfoButton
                type="button"
                onClick={toggleModalCreateUser}
              >
                Adicionar novo endereço{' '}
              </DeleteUserInfoButton>
            )}

            <SelectGroup>
              <SelectField>
                {/* {user.delivery_info.is_withdrawal_allowed &&
                  user.delivery_info.is_withdrawal_active && (
                    <div>
                      <input
                        type="checkbox"
                        id="is_withdrawal_allowed"
                        name="is_withdrawal_allowed"
                        onChange={(e) => {
                          setIsWithdrawalAllowed(!isWithdrawalAllowed);
                          changeOrderType();
                        }}
                      />
                      <label
                        htmlFor="is_withdrawal_allowed"
                        style={{ fontWeight: 'bold', marginLeft: 10 }}
                      >
                        Cliente irá retirar o pedido.
                      </label>
                    </div>
                  )}
                {user.delivery_info.has_sms_service && (
                  <div>
                    <input
                      type="checkbox"
                      id="will_receive_sms"
                      name="will_receive_sms"
                      onChange={(e) => setWillReceiveSms(!willReceiveSms)}
                    />
                    <label
                      htmlFor="will_receive_sms"
                      style={{ fontWeight: 'bold', marginLeft: 10 }}
                    >
                      Enviar sms pro usuário quando o pedido ficar pronto
                    </label>
                  </div>
                )} */}

                <PersonalSelectStyled
                  name="payment_method"
                  options={paymentMethods}
                  isSearchable
                  placeholder="Método de pagamento"
                  onChange={(e) => {
                    setMethod(e.label);
                    setMethodId(e.value);
                  }}
                />
                {method === 'Dinheiro' && (
                  <InputStyled
                    name="user_change"
                    placeholder="Troco pra quanto? (R$)"
                    style={{ marginTop: 10 }}
                    type="number"
                    step="0.01"
                    onChange={(e) => {
                      setUserChangeValue(e.target.value);
                    }}
                  />
                )}
              </SelectField>
            </SelectGroup>

            <Form>
              <Input
                name="details"
                label="Informações adicionais"
                onChange={(e) => setDetails(e.target.value)}
              />
            </Form>

            <Footer>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {
                  method && (
                    <div style={{ position: "relative" }}>
                      <Button
                        buttonColor="#2EC9B7"
                        inverted
                        title="Pedir e Registrar Pagamento"
                        onClick={() => handleGoToCartWithUser("register")}
                        disabled={isMakingOrder}
                        style={{ marginRight: 8 }}
                      />

                      <Warning
                        containerStyles={{
                          maxWidth: 600,
                          width: 410,
                          position: "absolute",
                          bottom: 40,
                          right: 20
                        }}
                        content="Agora você pode registrar o pagamento no caixa ao fazer o pedido, informando o método de pagamento"
                        onClick={toggleWarning}
                        show={warningbutton}
                        title="Registrar pagamento"
                        type="success"
                      />
                    </div>
                  )
                }
                <Button
                  buttonColor="#2EC9B7"
                  title="Fazer Pedido"
                  onClick={() => handleGoToCartWithUser(null)}
                  disabled={isMakingOrder}
                  style={{ marginLeft: "auto" }}
                />
              </div>
            </Footer>
          </>
        )}
      </Content>
    </Container>
  );
}

export default InputUserInfo;