import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'ui-kit-takeat';
import { Container } from './styles';
import { NavBar } from './styles';
import { FaCartArrowDown, FaCartPlus, FaPercent } from 'react-icons/fa';
import { PageTitle } from '~/components/PageTitle';

function Header() {
  const location = useLocation(); // Obtém a localização atual
  const [pathname, setPathname] = useState('');

  // Atualize o estado "pathname" sempre que a localização mudar
  useEffect(() => {
    setPathname(location.pathname);
  }, [location.pathname]);

  function getTitleName(path) {
    switch (path) {
      case '/reports/products/products':
        return 'Produtos Vendidos';

      case '/reports/products/canceled-products':
        return 'Produtos Cancelados';

      case '/reports/products/discounts':
        return 'Descontos';

      default:
        break;
    }
  }

  return (
    <Container>
      <div>
        <PageTitle>{getTitleName(pathname)}</PageTitle>
      </div>

      <NavBar>
        <Link to={'/reports/products/products'}>
          <Button
            title="Produtos vendidos"
            icon={<FaCartPlus />}
            inverted={pathname !== '/reports/products/products'}
            onClick={() => {}}
          />
        </Link>
        <Link to={'/reports/products/canceled-products'}>
          <Button
            title="Produtos cancelados"
            icon={<FaCartArrowDown />}
            inverted={pathname !== '/reports/products/canceled-products'}
            onClick={() => {}}
          />
        </Link>
        <Link to={'/reports/products/discounts'}>
          <Button
            title="Descontos"
            icon={<FaPercent />}
            inverted={pathname !== '/reports/products/discounts'}
            onClick={() => {}}
          />
        </Link>
      </NavBar>
    </Container>
  );
}

export default Header;
