import styled from 'styled-components';

export const ComplementsSelectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Title = styled.span`
  color: #979797;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
`;

export const GridArea = styled.div`
  width: 100%;
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  overflow-y: scroll;
`;

export const Badge = styled.span`
  background: #FF2C3A;
  color: white;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  padding: 0 5px;
  border-radius: 100px;
`;

export const SubTitle = styled.span`
  color: #FF2C3A;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;