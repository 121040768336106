import L from 'leaflet';
import './tipcircle.css';

// https://stackoverflow.com/questions/68130434/how-to-bind-a-tooltip-to-the-edge-of-a-leaflet-l-circle
L.TipCircle = L.Circle.extend({
  initialize(latlng, options, legacyOptions) {
    // Create invisible marker
    this._tip = L.circleMarker([0, 0], { opacity: 0, radius: 0 });

    // Initialize as a normal Circle
    L.Circle.prototype.initialize.call(this, latlng, options, legacyOptions);
  },
  redraw() {
    L.Circle.prototype.redraw.call(this);
    this._setTip();
  },
  onAdd(map) {
    L.Circle.prototype.onAdd.call(this);
    this._setTip();
    this._tip.addTo(map);
  },
  onRemove() {
    this._tip.remove();
    L.Circle.prototype.onAdd.call(this);
  },
  _setTip() {
    // Set the location for the tooltip to latitude of the bottom of the circle's
    // bounding box, and the longitude of its centre.
    this._tip.setLatLng([this.getBounds().getNorth(), this.getLatLng().lng]);

    // Set the label to the circle's radius in metres
    const tipText = `${(this.getRadius() / 1000).toFixed(1)} km`;

    // Remove any old tooltip and attach the new one
    this._tip.unbindTooltip();
    this._tip.bindTooltip(tipText, {
      opacity: 1,
      direction: 'center',
      permanent: true,
      className: 'circleTip',
    });
  },
});
