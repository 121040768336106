import React from 'react';
import { Container, CategoryProductsMenu } from './styles';
import { VideoPlayer } from '~/components/VideoPlayer';
import { PageTitle } from '~/components/PageTitle';

const CategoryHeader = ({ activeTab }) => {
  return (
    <Container>
      <CategoryProductsMenu>
        <PageTitle>Cardápio</PageTitle>
        {activeTab === 0 && <VideoPlayer id={1} />}
        {activeTab === 1 && <VideoPlayer id={34} />}
      </CategoryProductsMenu>
    </Container>
  );
};

export default CategoryHeader;
