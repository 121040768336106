import { Modal } from 'reactstrap';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding-right: 3px;

  footer {
    padding: 5px 3px 0 0;
    background-color: white;
    width: calc(100% + 3px);
    display: flex;
    justify-content: space-between;
    gap: 10px;
    position: sticky;
    bottom: 0;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1);
    clip-path: inset(-15px 0px 0px 0px);
    transition: all 0.3s;
  }
`;

export const AreaSeparator = styled.div`
  background-color: #cdcdcd;
  height: 1px;
  width: 100%;

  margin: 10px 0;
`;

export const ModalFiscalDiff = styled(Modal)`
  .modal-content {
    padding: 20px;
  }

  .modal-body {
    padding: 0px;
  }

  .modal-footer {
    padding: 0px;
  }

  h2 {
    margin-bottom: 10px;
    color: #4d4d4c;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
  }

  p {
    font-family: Poppins;
    font-size: 16px;
  }
`;
