import React, { useState, useRef } from 'react';

import * as Yup from 'yup';

import { Form } from '@unform/web';
import ReactInputMask from 'react-input-mask';

import { Row, Col } from 'reactstrap';

import { InnerArea, ButtonsArea } from './styles';

import Input from '~/components/Form/Input';
import Select from '~/components/Form/SelectInput';
import PersonalButton from '~/components/Buttons/PersonalButton';

import api from '~/services/api';
import getValidationErrors from '~/services/getValidationErrors';

function InpuLabel({ label = '', required = false }) {
  return (
    <div style={{ lineHeight: '18px', display: 'flex' }}>
      <p style={{ fontWeight: 'bold', color: '#6c6c6c' }}> {label} </p>
      {required && (
        <span style={{ fontSize: 14, color: '#FF2C3A' }}>*</span>
      )}
    </div>
  );
}

const ufs = [
  'AC', 'AL', 'AP', 'AM',
  'BA', 'CE', 'DF', 'ES',
  'GO', 'MA', 'MT', 'MS',
  'MG', 'PA', 'PB', 'PR',
  'PE', 'PI', 'RJ', 'RN',
  'RS', 'RO', 'RR', 'SC',
  'SP', 'SE', 'TO',
];

function CreateClient({ onExit }) {
  const [ufOptions] = useState(ufs.map((uf) => {
    return { label: uf, value: uf };
  }));

  const [initialData] = useState({});
  const [, setRerender] = useState(false);

  const formRef = useRef(null);

  function handleExit() {
    formRef.current.setErrors({});

    if (onExit) {
      onExit();
    }
  }

  async function handleSave(exit) {
    if (formRef.current === null) {
      return;
    }

    formRef.current.setErrors({});

    const data = {
      ...formRef.current.getData(),
    };

    if (!data.number || isNaN(data.number) || data.number < -1) {
      data.number = -1;
    }

    if (data.uf) {
      data.state = data.uf;
    }

    const schema = Yup.object().shape({
      name: Yup.string().required('Nome obrigatório'),
      phone: Yup
        .string()
        .matches(/\(\d\d\) \d{5}-\d{4}/, 'Preencha corretamente')
        .required('Telefone obrigatório'),
      zip_code: Yup.lazy((value) => {
        return value === ''
          ? Yup.string()
          : Yup.string().matches(/\d{2}\.\d{3}-\d{3}/, 'Preencha');
      }),
      street: Yup.string().required('Rua obrigatório'),
      number: Yup.number().min(-1),
      neighborhood: Yup.string().required('Bairro obrigatório'),
      city: Yup.string().required('Cidade obrigatório'),
      state: Yup.string().required('Estado obrigatório'),
      complement: Yup.string(),
      reference: Yup.string(),
    });

    try {
      await schema.validate(data, { abortEarly: false });
    } catch (error) {
      const errors = getValidationErrors(error);

      formRef.current.setErrors(errors);
      console.log('***', data, error);
      return;
    }

    data.zip_code = data.zip_code.replace(/[-.]/g, '');
    data.country = 'BR';

    try {
      await api.post('restaurants/buyer-with-address', data);
    } catch (error) {
      console.error('Save buyer error', error);
      return;
    }

    formRef.current.reset();
    setRerender((state) => !state);

    if (exit) {
      handleExit();
    }
  }

  const phoneTimer = useRef(0);

  function handlePhoneChange(event) {
    if (phoneTimer.current) {
      clearTimeout(phoneTimer.current);
    }

    const value = event.target.value;

    phoneTimer.current = setTimeout(async () => {
      const re = /\(\d\d\) \d{5}-\d{4}/;

      if (value.match(re)) {
        try {
          const response = await api.get(
            `restaurants/buyers?phone=${value}`
          );

          const data = response.data;

          if (data) {
            if (data.delivery_addresses.length) {
              const idx = data.delivery_addresses.length - 1;
              const last_address = data.delivery_addresses[idx];

              const uf = {
                value: last_address.state,
                label: last_address.state,
              };

              const formData = {
                ...formRef.current.getData(),
              };

              for (const k in formData) {
                if (!formData[k]) {
                  delete formData[k];
                }
              }

              formRef.current.setData({
                name: data.name,
                ...formData,
                zip_code: last_address.zip_code,
                city: last_address.city,
                neighborhood: last_address.neighborhood,
                street: last_address.street,
                number: last_address.number,
                complement: last_address.complement,
                reference: last_address.reference,
                uf,
              });

              setRerender((state) => !state);
            }
          }
        } catch (e) {
          console.log('Phone error', e);
        }
      }
    }, 300);
  }

  const zipTimer = useRef(0);

  function handleZipChange(event) {
    if (zipTimer.current) {
      clearTimeout(zipTimer.current);
    }

    const value = event.target.value;

    zipTimer.current = setTimeout(async () => {
      const re = /\d{2}\.\d{3}-\d{3}/;

      if (value.match(re)) {
        try {
          const zip = value.replace(/[-.]/g, '');

          const response = await api.get(
            `public/delivery/cep/${zip}`
          );

          const data = response.data;
          const uf = {
            value: data.uf,
            label: data.uf,
          };

          formRef.current.setData({
            ...formRef.current.getData(),
            uf,
            neighborhood: data.bairro,
            city: data.localidade,
            street: data.logradouro,
          });

          setRerender((state) => !state);
        } catch (e) {
          console.log('Zip code error', e);
        }
      }
    }, 300);
  }

  return (
    <Form
      initialData={initialData}
      ref={formRef}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <div style={{ flexGrow: 1 }}>
        <div style={{ color: '#6c6c6c', fontSize: '1.15rem', fontWeight: 'bold' }}>
          Cadastrar cliente
        </div>
        <InnerArea>
          <Row>
            <Col md="6">
              <InpuLabel label="Nome" required />
              <Input
                name="name"
                type="text"
                placeholder="Nome do cliente"
              />
            </Col>
            <Col md="6">
              <InpuLabel label="Telefone" required />
              <ReactInputMask
                mask="(99) 99999-9999"
                onChange={handlePhoneChange}
              >
                <Input
                  name="phone"
                  type="text"
                  placeholder="(XX) XXXXX-XXXX"
                />
              </ReactInputMask>
            </Col>
          </Row>
          <Row>
            <Col md="2">
              <InpuLabel label="CEP" />
              <ReactInputMask
                mask="99.999-999"
                onChange={handleZipChange}
              >
                <Input
                  name="zip_code"
                  type="text"
                  placeholder="XX.XXX-XXX"
                />
              </ReactInputMask>
            </Col>
            <Col md="8">
              <InpuLabel label="Endereço" required />
              <Input
                name="street"
                type="text"
                placeholder="Nome da rua ou avenida"
              />
            </Col>
            <Col md="2">
              <InpuLabel label="Número" />
              <Input
                name="number"
                type="number"
                placeholder="76"
              />
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <InpuLabel label="Bairro" required />
              <Input
                name="neighborhood"
                type="text"
                placeholder="Nome do bairro"
              />
            </Col>
            <Col md="5">
              <InpuLabel label="Cidade" required />
              <Input
                name="city"
                type="text"
                placeholder="Nome da cidade"
              />
            </Col>
            <Col md="2">
              <InpuLabel label="Estado" required />
              <Select
                name="uf"
                placeholder="UF"
                options={ufOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <InpuLabel label="Complemento" />
              <Input
                name="complement"
                type="text"
                placeholder="Casa, apartamento"
              />
            </Col>
            <Col md="6">
              <InpuLabel label="Referência" />
              <Input
                name="reference"
                type="text"
                placeholder="Próximo à praia"
              />
            </Col>
          </Row>
        </InnerArea>
      </div>

      <ButtonsArea>
        <PersonalButton
          color="#FF2C3A"
          message="Cancelar"
          style={{ margin: '0px' }}
          onClick={handleExit}
        />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <PersonalButton
            color="#0CCAB4"
            message="Salvar e cadastrar outro"
            fontColor="#0ccab4"
            style={{
              margin: '0px',
              backgroundColor: 'transparent',
              border: '1px solid #0ccab4',
            }}
            onClick={() => handleSave(false)}
          />

          <PersonalButton
            color="#0CCAB4"
            message="Salvar e sair"
            type="submit"
            style={{ margin: '0px 0px 0px 10px' }}
            onClick={() => handleSave(true)}
          />
        </div>
      </ButtonsArea>
    </Form>
  );
}

export default CreateClient;
