import React, { useState } from 'react';
import { BadgeContainer } from './styles';
import { Tooltip } from 'ui-kit-takeat';

export const TypeBadge = ({ type }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const badgeColor = {
    COMBO: '#C8131B',
    PESO: '#FFB32F',
  };

  return (
    <BadgeContainer
      color={badgeColor[type] || '#C8131B'}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {type}
      <Tooltip
        show={showTooltip}
        content={`Esse produto é do tipo ${type}`}
        containerStyles={{ padding: 10, width: 200, top: 20 }}
      />
    </BadgeContainer>
  );
};
