import React, { useEffect } from 'react';
import { InfoArea, PreCartContainer, Footer, CategoriesArea } from './styles';
import { Button } from 'ui-kit-takeat';
import { CategoryAdded } from '../CategoryAdded';
import { usePreCart } from '~/context/PreCartContext';
import formatValue from '~/utils/formatValue';
import { useCart } from '~/context/OrderingSystem/Cart';
import toast from 'react-hot-toast';

export const PreCart = ({ setIsSelectingComplements }) => {
  const { preCart, setPreCart, getPreCartTotal, categoriesRequired } =
    usePreCart();
  const { addToCart } = useCart();

  const checkCategoriesRequired = () => {
    if (categoriesRequired.length > 0) {
      let isChecked = true;
      categoriesRequired.forEach((category) => {
        const preCartComplementCategory = preCart.complementsCategory.find(
          (c) => c.id === category.id
        );
        if (!preCartComplementCategory) {
          isChecked = false;
          return;
        }
        const catAmout = preCartComplementCategory.complements.reduce(
          (acc, curr) => acc + curr.amount,
          0
        );
        if (catAmout < preCartComplementCategory.minimum) {
          isChecked = false;
          return;
        }
      });
      return isChecked;
    }
    return true;
  };

  const handleAddToCart = () => {
    const isCategoriesRequiredChecked = checkCategoriesRequired();
    if (!isCategoriesRequiredChecked) {
      toast.error('Favor adicionar todos os complementos obrigatórios');
      return;
    }

    const toAdd = preCart.product.use_weight
      ? {
          ...preCart,
          inputProductWeight:
            preCart.inputProductWeight ||
            (
              parseFloat(preCart.inputProductPrice) /
              parseFloat(preCart.product.price)
            ).toFixed(3),
          combo_price: preCart.product.is_combo ? getPreCartTotal() : null,
        }
      : {
          ...preCart,
          combo_price: preCart.product.is_combo ? getPreCartTotal() : null,
        };

    addToCart(toAdd);
    setPreCart({});
    setIsSelectingComplements(false);
  };

  return (
    <PreCartContainer>
      <InfoArea>
        <h2>Complementos selecionados</h2>

        <CategoriesArea>
          {preCart.complementsCategory?.length > 0 ? (
            preCart.complementsCategory?.map((category, idx) => (
              <CategoryAdded key={category.id} category={category} />
            ))
          ) : (
            <span>Nenhum complemento adicionado</span>
          )}
        </CategoriesArea>
      </InfoArea>
      <Footer>
        <Button
          title={`Adicionar ao carrinho ${formatValue(getPreCartTotal())}`}
          style={{ width: '100%' }}
          onClick={() => handleAddToCart()}
        />
      </Footer>
    </PreCartContainer>
  );
};
