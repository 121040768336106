import React from 'react';

import { BsPeople } from 'react-icons/bs';

import { FiCoffee } from 'react-icons/fi';

import { Rating } from '@material-ui/lab';
import {
  Header,
  Bloco,
  Content,
  Icon,
  Value,
  RateContent,
  Comment,
  CashReport,
} from './styles';

export default function ReportCard({
  name,
  total = 0,
  totalNoService = 0,
  type,
  color,
  rate,
  comment,
  category,
  clientPhone,
}) {
  const total_show = total ? parseFloat(total).toLocaleString('pt-BR') : '0.00';

  return (
    <Bloco>
      <Header>{name}</Header>
      <Content>
        <Icon>
          {type === 'products' ? <FiCoffee size={16} color="#33333340" /> : ''}
          {type === 'cash' ? '' : ''}
          {type === 'cashReport' ? '' : ''}
          {type === 'clients' ? <BsPeople size={16} color="#33333340" /> : ''}
          {type === 'majorClient' && ''}
          {/* {type === 'rate' ? <Rate>{rate === 0 ? <>—</> : rate}</Rate> : ''} */}

          {type === 'takeat' ? '' : ''}
        </Icon>
        <Value>
          {type === 'products' ? <>{total || ''}</> : ''}
          {type === 'cash' ? <>R${total_show}</> : ''}
          {type === 'cashReport' ? (
            <CashReport>
              R${total_show}{' '}
              {/* <span>
                + R$ {(Number(total) * 0.1).toFixed(2)} em taxa de serviço
              </span> */}
            </CashReport>
          ) : (
            ''
          )}
          {type === 'clients' ? <>{total}</> : ''}
          {type === 'majorClient' ? (
            <p>
              <span>{clientPhone}</span>
              <span>R$ {total}</span>
            </p>
          ) : (
            ''
          )}
          {type === 'takeat' ? <>{total}</> : ''}
          {type === 'rateCount' ? (
            <p>
              <span>{total}</span>
              {/* <FiStar size={12} /> */}
            </p>
          ) : (
            ''
          )}

          {type === 'rate' ? (
            <RateContent>
              <p>{rate}</p>
              <Rating
                name="half-rating-read"
                value={rate || 0}
                precision={0.1}
                readOnly
              />
            </RateContent>
          ) : (
            ''
          )}
          {type === 'growth' && (
            <p
              style={{
                color: total > 0 ? 'red' : total === 0 ? 'black' : 'green',
              }}
            >
              <>{total > 0 ? 'Aumentou ' : total === 0 ? ' - ' : 'Reduziu '}</>
              {total !== 0 && Math.abs(total).toFixed(2)}%
            </p>
          )}
          {type === 'category' && (
            <>
              <p>{category}</p>
              <p>R${total.toFixed(2)}</p>
            </>
          )}
        </Value>

        <Comment>
          <span>{comment}</span>
        </Comment>
      </Content>
    </Bloco>
  );
}
