import styled from 'styled-components';
import { Link } from 'react-router-dom';
export const Container = styled.div`
  margin: auto;
  width: 95%;
  background-color: #fff;
  border-radius: 7px;

  margin-top: 30px;
  background: ${(props) => props.theme.colors.white};
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #f4f4f4;
`;

export const Header = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;
`;

export const SetSliceSizeButton = styled.button`
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: #ff2c3a;
  font-size: 18px;
  margin-top: 16px;
  margin-left: 40px;
  float: left;
  font-weight: bold;
`;

export const ComplementsArea = styled.div`
  width: 100%;
  margin: auto;

  border-radius: 8px;
  padding: 20px;
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div`
  svg {
    cursor: pointer;
  }
`;

export const CategoryHeader = styled.div`
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
  padding-left: 30px;

  background-color: #f4f4f4;

  div {
    display: flex;
    align-items: flex-end;

    svg {
      cursor: pointer;
    }

    p {
      margin-left: 16px;
    }
  }

  p {
    font-size: 20px;
    margin-top: 14px;
  }
`;

export const CategoryItemHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding: 30px;
`;

export const CategoryItemBody = styled.div`
  width: 100%;
  display: flex;
  padding: 0 30px;
  justify-content: space-between;
`;

export const ButtonCollapse = styled.div`
  margin-left: 50px;
  border: none;
  background: none;
  margin-bottom: 16px;
  font-weight: bold;

  cursor: pointer;
`;

export const CategoryName = styled.div`
  font-weight: bold;
  font-size: 25px;
  margin-left: 12px;
`;
export const CategoryItemHeaderTitle = styled.p`
  font-weight: bold;
`;

export const ButtonAssociate = styled.button`
  border: none;
  text-decoration: underline;
  background-color: transparent;
  color: #ff2c3a;
  font-weight: bold;
  font-size: 16px;
  margin-top: 12px;
`;
