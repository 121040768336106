import styled from 'styled-components';

export const Container = styled.div``;

export const Actions = styled.div``;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;
