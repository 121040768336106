import styled from "styled-components";

export const InputField = styled.div`
  p {
    font-size: 14px;
    font-weight: normal;
  }

  margin-top: 16px;
  margin-right: 4px;

  input {
    border-radius: 5px;
    border: 1px solid #33333330;
    padding: 5px 10px;
    width: 100%;
  }
`;

export const StreetInputField = styled.div`
  display: flex;
  margin-top: -20px;
  margin-bottom: -10px;
`;

export const AddressField = styled.div`
  width: 100%;

  p {
    text-align: left;
    font-weight: 700;
  }
`;