import styled, { css } from 'styled-components';

export const DoneButton = styled.button`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-self: center;
  float: right;
  position: absolute;
  clear: both;
  right: 0;
  margin-right: 10px;
  border: none;
  background: #28a745;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
    border-radius: 50%;
  }
`;

export const ButtonCancel = styled.button`
  color: #ff2c3a;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  font-weight: bold;
`;
export const DisplayNoneImprimir = styled.button`
  display: none;
`;
export const Print = styled.button`
  height: 48px;
  width: 440px;
  border-radius: 5px;

  border: none;
  background: #ff2c3a;
  font-weight: bold;

  svg {
    margin-right: 6px;
  }

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  }
`;
export const PrintOutline = styled.button`
  height: 48px;
  width: 440px;
  border-radius: 5px;

  border: none;
  background: transparent;
  color: #ff2c3a;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 20px;
  svg {
    margin-right: 6px;
  }

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  }
`;

export const Footer = styled.footer`
  display: fixed;
  bottom: 20px;
`;

export const ContainerOrders = styled.div`
  max-height: 70vh;
  overflow-y: scroll;

  ${(props) =>
    props.browserZoomLevel > 100 &&
    css`
      max-height: 55vh;
    `}

  @media (max-width: 1441px) {
    max-height: 55vh;
  }
`;

export const ModalContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ModalContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  h3 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #4d4d4c;
    margin: 0;
  }

  svg {
    cursor: pointer;
  }
`;
