/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import XLSX from 'xlsx-js-style';
import formatCompleteDate from '~/services/formatCompleteDate';

async function getProductsData(historyIntermediary, intermediary) {
  const sheet_body = [];
  historyIntermediary.forEach((item) => {
    const sheet_item = [
      formatCompleteDate(item.createdAt),

      item.balance && !isNaN(item.balance)
        ? item.type === '+'
          ? parseFloat(item.balance) - parseFloat(item.quantidade)
          : parseFloat(item.balance) + parseFloat(item.quantidade)
        : '-',

      item.type === '+'
        ? parseFloat(item.quantidade)
        : parseFloat(item.quantidade * -1),

      item.balance && !isNaN(item.balance) ? parseFloat(item.balance) : '-',

      item.total && !isNaN(item.total)
        ? parseFloat(item.total)
        : item.unitary_price &&
          !isNaN(item.unitary_price) &&
          item.quantidade &&
          !isNaN(item.quantidade) &&
          parseFloat(item.quantidade) > 0
        ? parseFloat(item.quantidade) * parseFloat(item.unitary_price)
        : '',

      item.unitary_price && !isNaN(item.unitary_price)
        ? parseFloat(item.unitary_price)
        : '-',

      (item.product && `[P] ${item.product.name}`) ||
        (item.complement && `[C] ${item.complement.name}`) ||
        (item.intermediary && `[PI] ${item.intermediary.name}`) ||
        (item.intermediary &&
          item.operation === 'intermediary_deleted' &&
          ` - Deletado`) ||
        (!item.complement &&
          !item.product &&
          !item.intermediary &&
          (item.operation !== 'nfe'
            ? 'Editado Manualmente'
            : 'Entrada de Nota Fiscal')),
      item.user ? item.user.name : '-',
      item.justificative ? item.justificative : !item.nfe && '-',
    ];

    sheet_body.push(sheet_item);
  });
  const sheet_header = [
    'DATA / HOTA',
    `SALDO INICIAL (${intermediary.unidade || ''})`,
    'VARIAÇÃO',
    `SALDO FINAL (${intermediary.unidade || ''})`,
    'VALOR',
    'CUSTO UNITARIO',
    'OPERAÇÃO',
    'USUÁRIO',
    'JUSTIFICATIVA',
  ];

  return { data: [sheet_header, ...sheet_body] };
}

export async function generateIntermediaryInputSheet(
  historyIntermediary,
  intermediary
) {
  const { data } = await getProductsData(historyIntermediary, intermediary);
  const columns = 'abcdefghijklmnopqrstuvwxyz'.toLocaleUpperCase().split('');

  const num_cols = data[0].length;

  const workbook = XLSX.utils.book_new();

  const worksheet = XLSX.utils.aoa_to_sheet(data);
  const colSizes = [];

  for (let i = 0; i < num_cols; i++) {
    let biggest = 0;
    for (let j = 0; j < data.length; j++) {
      if (data[j][i].length > biggest) {
        biggest = data[j][i].length;
      }
    }
    colSizes.push(biggest);
  }

  // Every column with 15 chars of width
  worksheet['!cols'] = new Array(num_cols);
  worksheet['!rows'] = new Array(data.length);
  worksheet['!cols'] = colSizes.map((size) => {
    return { wch: size + 4 };
  });
  worksheet['!rows'].fill({ hpt: 18 });

  const num_lines = data.length;

  for (let j = 0; j < num_cols; j++) {
    for (let i = 1; i <= num_lines; i++) {
      const cell = worksheet[columns[j] + i];
      if (cell) {
        if (i === 1) {
          cell.s = {
            numFmt: 50,
            font: {
              bold: true,
            },
            fill: {
              fgColor: {
                rgb: 'ECFFFD',
              },
              bgColor: {
                rgb: 'ECFFFD',
              },
            },
            border: {
              top: {
                style: 'thin',
                color: {
                  rgb: '000000',
                },
              },
              bottom: {
                style: 'thin',
                color: {
                  rgb: '000000',
                },
              },
              left: {
                style: 'thin',
                color: {
                  rgb: '000000',
                },
              },
              right: {
                style: 'thin',
                color: {
                  rgb: '000000',
                },
              },
            },
          };
        }

        if (j > 0) {
          cell.s = {
            ...cell.s,
            alignment: { horizontal: 'center', vertical: 'center' },
          };
        }
      }
    }
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório Compras');

  return XLSX.write(workbook, { type: 'buffer' });
}
