import React, { useState, useCallback } from 'react';
import Input from '~/components/Form/Input';
import { Form } from '@unform/web';

import { toast } from 'react-hot-toast';
import {
  Actions,
  ActionsInside,
  DeleteAndEditActions,
  SeeButton,
  InputsTable,
} from './styles';

import formatCompleteDate from '~/services/formatCompleteDate';
import formatDate from '~/services/formatDate';

import api from '~/services/api';

import { Modal, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';

export default function StockTransferManifestRowEntry({ stock, getNfes }) {
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasNotExistInput, setHasNotExistInput] = useState(false);

  const [modalStock, setModalStock] = useState(false);
  function toggleStock() {
    setModalStock(!modalStock);
    setHasNotExistInput(false);
  }

  const openModal = useCallback(
    async (data) => {
      setLoading(true);
      try {
        const response = await api.get(
          `/restaurants/stock-transfer/${stock.id}`
        );
        setInfo(response.data);
        response.data.forEach((input) => {
          if (!input.exists) {
            setHasNotExistInput(true);
          }
        });
        setModalStock(true);
      } catch (err) {
        console.log('errrr', err);
        toast.error('Falha ao carregar dados de transferencia de estoque');
      }
      setLoading(false);
    },
    [stock]
  );

  const updateStockTransfer = useCallback(async () => {
    setLoading(true);
    try {
      await api.put(`/restaurants/stock-transfer/accept/${stock.id}`, {
        info,
      });
      getNfes();
      setModalStock(false);
      toast.success('Estoque aceito com sucesso.');
    } catch (err) {
      console.log('errrr', err);
      toast.error('Falha ao carregar dados de transferencia de estoque');
    }
    setLoading(false);
  }, [stock, info]);

  return (
    <>
      <tr>
        <td>{stock?.nome_emitente}</td>
        <td>{formatCompleteDate(stock?.data_emissao)}</td>
        <td>
          R$
          {parseFloat(stock?.valor_total || 0).toLocaleString('pt-br', {
            minimumFractionDigits: 2,
          })}
        </td>
        <td>{stock?.chave_nfe}</td>
        {/* <td>{stock?.numero}</td> */}
        
        <td>
          {' '}
          <Actions>
            <ActionsInside>
              <DeleteAndEditActions>
                <SeeButton onClick={() => openModal()}>
                  {loading ? 'Aguarde...' : 'Detalhes'}
                </SeeButton>
              </DeleteAndEditActions>
            </ActionsInside>
          </Actions>
        </td>
      </tr>

      <Modal isOpen={modalStock} toggle={toggleStock} size="lg">
        <ModalBody>
          <Form initialData={info}>
            <h5>Detalhes da Transferência</h5>
            <Row>
              <Col md="4">
                <Input
                  label="Fornecedor"
                  name="restaurant_dest"
                  value={stock?.nome_emitente}
                  disabled
                />
              </Col>
              <Col md="4">
                <Input
                  label="Status"
                  name="value"
                  value={stock.status === 'created' ? 'Pendente' : 'Aceita'}
                  disabled
                />
              </Col>
              <Col md="4">
                <Input
                  label="Aceito em"
                  name="accepted_at"
                  value={
                    stock.accepted_at
                      ? formatCompleteDate(stock?.accepted_at)
                      : ''
                  }
                  disabled
                />
              </Col>
            </Row>

            <Row>
              <Col md="4">
                <Input
                  label="Criada"
                  name="createdAt"
                  value={formatCompleteDate(stock?.createdAt)}
                  disabled
                />
              </Col>
              <Col md="4">
                <Input
                  label="Valor"
                  name="value"
                  value={`R$${stock?.value?.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`}
                  disabled
                />
              </Col>
              <Col md="4">
                <Input
                  label="Vencimento"
                  name="due_date"
                  value={formatDate(stock?.due_date)}
                  disabled
                />
              </Col>
            </Row>

            <Row style={{ marginTop: 7, marginBottom: 7 }}>
              <Col md="12">
                <strong>Observações: </strong>
                {`  ${stock?.observation}`}
              </Col>
            </Row>

            <Row
              style={{ marginTop: 17, marginBottom: 10, textAlign: 'center' }}
            >
              <Col md="12">
                <strong>Itens que existem em seu estoque </strong>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <InputsTable borderless>
                  <thead>
                    <tr>
                      <th>Insumo</th>
                      <th>Quantidade</th>
                      <th>Custo unitário</th>
                      <th>Custo total</th>
                    </tr>
                  </thead>

                  <tbody>
                    {info?.length > 0 &&
                      (info.filter((inf) => inf.input_origin && inf.exists)
                        .length > 0 ? (
                        info
                          .filter((inf) => inf.input_origin && inf.exists)
                          .map((input) => (
                            <tr>
                              <td>{input.input_origin.name}</td>

                              <td>
                                {parseFloat(input.quantidade).toFixed(2)}{' '}
                                {input.input_origin.unidade}
                              </td>

                              <td>
                                R$
                                {parseFloat(
                                  input.unitary_price
                                )?.toLocaleString('pt-br', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </td>

                              <td>
                                R$
                                {parseFloat(input.total_price)?.toLocaleString(
                                  'pt-br',
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </td>
                            </tr>
                          ))
                      ) : (
                        <tr>
                          <td> - </td>
                          <td> - </td>
                          <td> - </td>
                        </tr>
                      ))}
                  </tbody>
                  <thead style={{ marginTop: 15 }}>
                    <tr>
                      <th>Produto Intermediário</th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {info?.length > 0 &&
                      (info.filter((inf) => inf.inter_origin && inf.exists)
                        .length > 0 ? (
                        info
                          .filter((inf) => inf.inter_origin && inf.exists)
                          .map((input) => (
                            <tr>
                              <td>{input.inter_origin.name}</td>
                              <td>
                                {parseFloat(input.quantidade).toFixed(2)}{' '}
                                {input.inter_origin.unidade}
                              </td>
                              <td>
                                R$
                                {parseFloat(
                                  input.unitary_price
                                )?.toLocaleString('pt-br', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </td>
                              <td>
                                R$
                                {parseFloat(input.total_price)?.toLocaleString(
                                  'pt-br',
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </td>
                            </tr>
                          ))
                      ) : (
                        <tr>
                          <td> - </td>
                          <td> - </td>
                          <td> - </td>
                          <td> - </td>
                        </tr>
                      ))}
                  </tbody>
                </InputsTable>
              </Col>
            </Row>

            {hasNotExistInput && (
              <div>
                <Row
                  style={{
                    marginTop: 13,
                    marginBottom: 13,
                    textAlign: 'center',
                  }}
                >
                  <Col md="12">
                    <strong>Itens que serão criados no seu estoque </strong>
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <InputsTable borderless>
                      <thead>
                        <tr>
                          <th>Insumo</th>
                          <th>Quantidade</th>
                          <th>Custo unitário</th>
                          <th>Custo total</th>
                        </tr>
                      </thead>

                      <tbody>
                        {info?.length > 0 &&
                          info.map(
                            (input) =>
                              input.input_origin &&
                              !input.exists && (
                                <tr>
                                  <td>{input.input_origin.name}</td>

                                  <td>
                                    {parseFloat(input.quantidade).toFixed(2)}{' '}
                                    {input.input_origin.unidade}
                                  </td>

                                  <td>
                                    R$
                                    {parseFloat(
                                      input.unitary_price
                                    )?.toLocaleString('pt-br', {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </td>

                                  <td>
                                    R$
                                    {parseFloat(
                                      input.total_price
                                    )?.toLocaleString('pt-br', {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </td>
                                </tr>
                              )
                          )}
                        {info?.length > 0 &&
                          info.map(
                            (input) =>
                              input.inter_origin &&
                              !input.exists && (
                                <tr>
                                  <td>{input.inter_origin.name}</td>

                                  <td>
                                    {parseFloat(input.quantidade).toFixed(2)}{' '}
                                    {input.inter_origin.unidade}
                                  </td>

                                  <td>
                                    R$
                                    {parseFloat(
                                      input.unitary_price
                                    )?.toLocaleString('pt-br', {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </td>

                                  <td>
                                    R$
                                    {parseFloat(
                                      input.total_price
                                    )?.toLocaleString('pt-br', {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </td>
                                </tr>
                              )
                          )}
                      </tbody>
                    </InputsTable>
                  </Col>
                </Row>
              </div>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => toggleStock()}
            disabled={loading}
          >
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
