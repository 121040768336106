/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import XLSX from 'xlsx-js-style';

async function getProductsData(categories, products) {
  const sheet_subitems = [];
  const sheet_data = products.map((prod) => {
    const sheet_item = {
      id: prod.id,
      name: prod.name,
      cmv: prod.cmv,
      price: prod.price,
      margin:
        prod.price > 0 ? ((1 - prod.cmv / prod.price) * 100)?.toFixed(2) : '-',
      brute_profit: (prod.price - prod.cmv)?.toFixed(2),
      amount: prod.amount,
      period_cmv: prod.period_cmv?.toFixed(2),
      profit: prod.profit?.toFixed(2),
      earnings: prod.earnings?.toFixed(2),
      cat: prod.category?.id,
    };

    prod.complements.forEach((c) => {
      sheet_subitems.push({
        name: c.name,
        cmv: c.cmv,
        price: c.price,
        margin: c.price > 0 ? parseFloat(((1 - c.cmv / c.price) * 100)) : '-',
        brute_profit: parseFloat((c.price - c.cmv)),
        amount: parseFloat(c.amount),
        period_cmv: parseFloat(c.period_cmv),
        profit: parseFloat(c.profit),
        earnings: parseFloat(c.earnings),
        product_id: prod.id,
      });
    });

    return sheet_item;
  });

  const sheet_header = [
    'Produto',
    'CMV',
    'Preço atual de venda',
    'Margem bruta',
    'Lucro bruto',
    'Qnt vendida',
    'CMV médio do período',
    'Lucro medido',
    'Vendas medidas',
  ];

  const sheet_body = [];
  let catRows = [];
  let compRows = [];
  let index = 2;

  for (const cat of categories.sort((a, b) => a.name.localeCompare(b.name))) {
    catRows.push(index);
    index++;
    const rowCat = [
      cat.name,
      '-',
      '-',
      '-',
      '-',
      cat.amount.total,
      '-',
      '-',
      cat.category_earning.total,
    ];
    sheet_body.push(rowCat);

    sheet_data.forEach((prod) => {
      if (prod.cat === cat.id) {
        index++;
        const row = [
          prod.name,
          prod.cmv,
          prod.price,
          prod.margin,
          prod.brute_profit,
          prod.amount,
          prod.period_cmv,
          prod.profit,
          prod.earnings,
        ];
        sheet_body.push(row);

        sheet_subitems
          .filter((s) => s.product_id === prod.id)
          .forEach((s) => {
            compRows.push(index);
            index++;
            const subRow = [
              `  - ${s.name}`,
              s.cmv,
              s.price,
              s.margin,
              s.brute_profit,
              s.amount,
              s.period_cmv,
              s.profit,
              s.earnings,
            ];
            sheet_body.push(subRow);
          });
      }
    });
  }

  return { data: [sheet_header, ...sheet_body], catRows, compRows };
}

export async function generateProductsSheetXLSX(categories, products) {
  const { data, catRows, compRows } = await getProductsData(
    categories,
    products
  );
  const sheet_header = [
    'Produto',
    'CMV',
    'Preço atual de venda',
    'Margem bruta',
    'Lucro bruto',
    'Qnt vendida',
    'CMV médio do período',
    'Lucro medido',
    'Vendas medidas',
  ];
  const columns = 'abcdefghijklmnopqrstuvwxyz'.toLocaleUpperCase().split('');

  const num_cols = data[0].length;

  const workbook = XLSX.utils.book_new();

  const worksheet = XLSX.utils.aoa_to_sheet(data);

  // Every column with 15 chars of width
  worksheet['!cols'] = new Array(num_cols);
  worksheet['!cols'].fill({ wch: 15 });

  const num_lines = data.length;

  for (let j = 0; j < num_cols; j++) {
    for (let i = 1; i <= num_lines; i++) {
      const cell = worksheet[columns[j] + i];
      if (cell) {
        if (catRows.includes(i)) {
          cell.s = {
            font: {
              bold: true,
            },
            fill: {
              fgColor: {
                rgb: 'ECFFFD',
              },
              bgColor: {
                rgb: 'ECFFFD',
              },
            },
            border: {
              top: {
                style: 'thin',
                color: {
                  rgb: '000000',
                },
              },
              bottom: {
                style: 'thin',
                color: {
                  rgb: '000000',
                },
              },
            },
          };
        }

        if (compRows.includes(i)) {
          cell.s = {
            font: {
              color: { rgb: '3db2c1' },
            },
          };
        }

        if (j > 0) {
          cell.s = { ...cell.s, alignment: { horizontal: 'center' } };
        }
      }
    }
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório Produtos');

  return XLSX.write(workbook, { type: 'buffer' });
}
