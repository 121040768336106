import React, {
  createContext,
  useMemo,
  useState,
  useContext,
  useEffect,
} from 'react';
import api from '~/services/api';
import { toast } from 'react-hot-toast';
import { useAuth } from '../AuthContext';

const ClubeContext = createContext();

const ClubeProvider = ({ children }) => {
  const [sessionsAwarded, setSessionsAwarded] = useState(
    JSON.parse(localStorage.getItem('@gddashboard:sessionsAwarded')) || []
  );
  const [rescues, setRescues] = useState(
    JSON.parse(localStorage.getItem('@gddashboard:rescues')) || []
  );
  const { user } = useAuth();

  async function handlePontuarSession({ session_key }) {
    if (user.has_clube) {
      try {
        const response = await api.post('public/clube/send/session', {
          session_key,
          rescues,
        });

        toast.success('Clientes pontuados!');
        setSessionsAwarded((state) => {
          return [...state, response.data.id];
        });
        const phoneNumbersOnSession = [
          response.data.client?.phone,
          ...response.data.bills
            ?.map((bill) => {
              if (bill.buyer?.phone) {
                return bill.buyer?.phone;
              }
              return;
            })
            .filter((phone) => !!phone),
        ];

        setRescues((state) =>
          state.filter(
            (rescue) => !phoneNumbersOnSession.includes(rescue.phone)
          )
        );
      } catch (error) {
        toast.error('Não foi possível pontuar essa sessão. Tente novamente');
      }
    }
  }

  useEffect(() => {
    localStorage.setItem('@gddashboard:rescues', JSON.stringify(rescues));
  }, [rescues]);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:sessionsAwarded',
      JSON.stringify(sessionsAwarded)
    );
  }, [sessionsAwarded]);

  const value = useMemo(
    () => ({
      sessionsAwarded,
      setSessionsAwarded,
      handlePontuarSession,
      rescues,
      setRescues,
    }),
    [
      sessionsAwarded,
      setSessionsAwarded,
      handlePontuarSession,
      rescues,
      setRescues,
    ]
  );
  return (
    <ClubeContext.Provider value={value}>{children}</ClubeContext.Provider>
  );
};

function useClube() {
  const context = useContext(ClubeContext);

  if (!context) {
    throw new Error('useClube must be used within an ClubeProvider');
  }

  return context;
}

export { useClube, ClubeProvider };
