import React from 'react';

import { Spinner } from 'reactstrap';
import { Container, Table, Order, Body, Phone, Time, Items } from './styles';

import formatTime from '~/services/formatTime';
import formatPhone from '~/services/formatPhoneString';

import ItemOrder from './ItemOrder';

export default function DeleteItemOrders({ item, from, modalDelete, orders }) {
  const userName = item?.buyer
    ? formatPhone(item?.buyer.phone)
    : item?.waiter?.name;

  return (
    <Container>
      <Table>
        <strong>MESA {item?.table?.table_number}</strong>
      </Table>
      <Order>Pedido #{item?.basket?.basket_id}</Order>
      <Body>
        <Phone>{userName}</Phone>
        <Time>{formatTime(item?.basket.start_time)}</Time>
      </Body>

      <p style={{ marginTop: 10, fontSize: 13, textAlign: "center" }}>Selecione o item ou quantidade de itens que deseja cancelar</p>
      <Items>
        {orders?.length > 0 ? (
          orders?.map((order) => (
            <ItemOrder item={item} order={order} from={from} orders={orders} />
          ))
        ) : (
          <div style={{ marginTop: 20 }}>
            <Spinner />
          </div>
        )}
      </Items>

      {console.log(orders)}
    </Container>
  );
}
