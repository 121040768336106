import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';

export const Wrapper = styled.div`
  padding: 20px;
  height: calc(100vh - 48px);
  overflow-y: auto;
`;

export const DataPickerAreaItem = styled.div`
  margin-top: 10px;
  width: 100%;
  span {
    font-weight: bold;
  }
`;

export const NumberFormatStyled = styled(NumberFormat)`
  height: 44px;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  margin-top: 8px;

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }
`;

export const ButtonCollapse = styled.div`
  border: none;
  background: none;
  margin-bottom: 10px;
  border-bottom: 1px solid #EBEBEB;
  padding-bottom: 5px;
  width: 100%;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonsDiv = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
  align-items: flex-end;

  @media (max-width: 1370px) {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  width: 100%;
  padding: 20px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  margin-top: 10px;
`;

export const ScrollArea = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 60vh;

  @media (max-width: 1281px) {
    max-height: 45vh;
  }

  @media (min-width: 1282px) and (max-width: 1441px) {
    max-height: 55vh;
  }
`;
export const Content = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: 90px;
`;
export const TablesPlace = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  width: 100%;
  min-height: 300px;
  background-color: white;
  border-radius: 8px;
  overflow-x: auto;
`;

export const BillExplanation = styled.div`
  width: 50%;
  margin-left: 60px;
  margin-top: 20px;
`;

export const Badge = styled.div`
  width: 16px;
  height: 16px;
  font-size: 13px;
  text-align: center;
  border-radius: 50%;
  background-color: red;
  color: white;
  margin-right: 5px;
`;

export const BadgeDiv = styled.div`
  display: flex;
`;

export const DateSelectorDiv = styled.div`
  width: 90%;
  height: 60px;
  display: flex;
  clear: both;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 20px;
  flex-direction: row;
`;

export const DateDiv = styled.div`
  float: left;
  left: 0;
  margin-left: 15px;
`;

export const SubmitDate = styled.button`
  height: 30px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 15px;
  border: none;
  background: #17a2b8;
  margin-left: 10px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
  }
`;

export const SelectDateButton = styled.button`
  height: 40px;
  border-radius: 20px;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  color: white;
  align-self: center;
  margin-right: 15px;
  border: none;
  background: #17a2b8;
  margin-left: 10px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
  }

  :selected {
    border: none;
  }
`;

export const ErrorContainer = styled.div`
  width: 60%;
  min-width: 600px;
  margin: 10px auto 30px auto;

  background: orange;
  color: #fff;

  border-radius: 8px;
  padding: 30px;

  p {
    text-align: center;
    font-size: 22px;
  }
`;

export const DataPickerArea = styled.div`
  display: inline-block;
`;

export const DatePickerStyled = styled(DatePicker)`
  height: 40px;
  padding: 10px;

  margin-right: 15px;
  margin-left: 10px;

  border-radius: 20px;
  align-self: center;
  border: 1px solid ${(props) => props.theme.colors.lightGray};

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 20px;
  }
`;
