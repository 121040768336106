import React, { useState, useCallback, useEffect } from 'react';

import { toast } from 'react-hot-toast';

import {
  format,
  startOfDay,
  endOfDay,
} from 'date-fns';

import {
  LossTable,
  SearchDiv,
  ButtonsSelectDate,
} from './styles';

import api from '~/services/api';
import formatCompleteDate from '~/services/formatCompleteDate';
import { DownloadButton, FullCalendar } from 'ui-kit-takeat';
import { generateLossReportSheet } from '~/services/generateLossReportSheet';
import FileSaver from 'file-saver';

export default function InputLossReport({ type }) {
  const [rangeStartDate, setRangeStartDate] = useState(
    {
      start: startOfDay(new Date()),
      end: endOfDay(new Date())
    }
  );

  const [history, setHistory] = useState([]);

  const handleDownloadSheet = async () => {
    try {
      const sheet = await generateLossReportSheet(history);

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        type === '+'
          ? `Sobras (${format(new Date(), 'dd-MM-yyyy')}).xlsx`
          : `Perdas (${format(new Date(), 'dd-MM-yyyy')}).xlsx`
      );
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };

  const updateHistory = useCallback(
    async (inicial, final) => {
      try {
        const response = await api.get('/restaurants/history-inputs-report', {
          params: {
            start_date: inicial.toISOString(),
            end_date: final.toISOString(),
            type
          },
        });

        setHistory(response.data);
      } catch (error) {
        toast.error('Falha ao carregar dados');
      }
    },
    [setHistory, type]
  );

  useEffect(() => {
    updateHistory(rangeStartDate.start, rangeStartDate.end);
  }, [rangeStartDate]);

  return (
    <div
      style={{
        background: '#fff',
        borderRadius: 8,
        padding: '20px',
        boxShadow: '1px 0 5px 1px rgba(0, 0, 0, 0.1)',
      }}
    >
      <SearchDiv>
        <DownloadButton
          onClick={() => {
            if (history.length > 0) {
              handleDownloadSheet();
            }
          }}
        />

        <ButtonsSelectDate>
          <FullCalendar
            isRange
            onDateSelected={setRangeStartDate}
            value={rangeStartDate}
            hideFields={[
              'this_year'
            ]}
          />
        </ButtonsSelectDate>
      </SearchDiv>
      <LossTable borderless>
        <thead>
          <tr>
            <th>Tipo</th>
            <th>Nome</th>
            <th>{type === '+' ? 'Sobras' : 'Perdas'}</th>
            {/* <th>Valor</th> */}
            <th>Custo</th>
            <th>Justificativa</th>
            <th>Data</th>
            <th>Usuário</th>
          </tr>
        </thead>
        <tbody>
          {history?.length ? (
            history.map((item) => (
              <tr key={item.id}>
                <td> {item.is_intermediary ? 'Intermediário' : 'Insumo'} </td>
                <td> {item.name} </td>
                <td>
                  {type}{' '}
                  {`${parseFloat(item.quantidade).toFixed(3)} ${item.unidade}`}{' '}
                </td>
                {/* <td> {item.total !== null ? `R$ ${item.total}` : '-'} </td> */}
                <td> {item.cmv !== null ? `R$ ${item.cmv}` : '-'} </td>
                <td> {item.justificative || '-'} </td>
                <td> {formatCompleteDate(item.created_at)} </td>
                <td> {item.user?.name || '-'} </td>
              </tr>
            ))
          ) : (
            <tr>
              <td style={{ textAlign: 'center' }} colSpan={7}>
                {' '}
                Período sem dados!{' '}
              </td>
            </tr>
          )}
        </tbody>
      </LossTable>
    </div>
  );
}
