import styled from 'styled-components';

export const Print = styled.button`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-self: center;
  float: right;
  position: absolute;
  clear: both;
  right: 0;
  margin-right: 10px;
  border: none;
  background: #f0b402;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
    border-radius: 50%;
  }
`;

export const DisplayNoneImprimir = styled.div`
  display: none;

  @media print {
    display: block;
  }
`;
export const ButtonCancel = styled.button`
  color: #ff2c3a;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  font-weight: bold;
`;

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 10px;
  border-bottom: 1px solid #cdcdcd;
  flex-direction: column;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  align-self: flex-start;

  svg {
    cursor: pointer;
  }
`;

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  gap: 5px;

  ${(props) => props.grow && 'flex-grow: 1;'}
`;

export const CardColumnItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const TableNumber = styled.span`
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #F6F6F6;
  padding: 5px;
  border-radius: 5px;
`

export const CardBody = styled.div`
 display: flex;
  justify-content: space-between;
`
export const DateArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;

  strong{
    font-weight: 600;
  }
`
export const BillInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`