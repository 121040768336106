import React, { useState, useCallback } from 'react';

import { toast } from 'react-hot-toast';

import {
  format,
  startOfDay,
  endOfDay,
} from 'date-fns';

import api from '~/services/api';
import { DownloadButton, DayCalendar } from 'ui-kit-takeat';
import FileSaver from 'file-saver';
import { generateInventoryReport } from '~/services/SheetGenerate/generateInventoryReport';
import { useAuth } from '~/context/AuthContext';

export default function InputInventoryReport({ type }) {
  const [filterDate, setFilterDate] = useState({
    start: startOfDay(new Date()),
    end: endOfDay(new Date())
  });

  const { user } = useAuth();

  const [loading, setLoading] = useState(false);

  const handleDownloadSheet = async (history) => {
    try {
      const sheet = await generateInventoryReport(history, user, format(new Date(filterDate.end), 'dd/MM/yyyy'));

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        `Inventario (${format(new Date(filterDate.end), 'dd-MM-yyyy')}).xlsx`
      );
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };

  const updateHistory = useCallback(
    async () => {
      setLoading(true);
      try {
        const response = await api.get('/restaurants/inputs/inventory/day', {
          params: {
            end_date: filterDate.end.toISOString()
          },
        });

        handleDownloadSheet(response.data);
      } catch (error) {
        toast.error('Falha ao carregar dados');
      }
      setLoading(false);
    },
    [filterDate]
  );

  return (
    <div
      style={{
        background: '#fff',
        borderRadius: 8,
        padding: '20px',
        boxShadow: '1px 0 5px 1px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <div>
        <p>Selecione a data para baixar o inventário naquele dia.</p>
        <p> <strong>Lembre de atualizar o estoque!</strong></p>
      </div>

      <div style={{display: 'flex', gap: 30, alignItems: 'center'}}>
        <DayCalendar
          onDateSelected={setFilterDate}
          value={filterDate}
        />

        <DownloadButton
          borderRadius={50}
          buttonWidth={85}
          onClick={updateHistory}
          fixed="open"
          text="Download"
          isLoading={loading}
        />
      </div>
    </div>
  );
}
