import React from 'react';
import { Container, Header } from './styles';
import { CartItem } from './CartItem';
import { useCart } from '~/context/OrderingSystem/Cart';

export const Cart = () => {
  const { cart } = useCart();

  return (
    <Container>
      <Header>
        <h3>Carrinho</h3>
        <h3>Balcão</h3>
      </Header>
      {cart?.length > 0 ? (
        cart?.map((item) => {
          return <CartItem key={item.basket_id} item={item} />;
        })
      ) : (
        <span>Carrinho vazio :(</span>
      )}
    </Container>
  );
};
