import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;
export const Header = styled.div`
  display: flex;
  background-color: #28a745;
  justify-content: space-between;
  align-items: center;
  color: white;
  height: 50px;
  width: 100%;
  padding: 20px;
  text-align: center;
  border-radius: 10px 10px 0 0;

  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
`;

export const Bloco = styled.div`
  min-width: 200px;
  height: calc(100% - 55px);
  padding: 0px;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 12px;
  border: solid 1px #28a745;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  flex-grow: 1;
`;

export const SelectDateButton = styled.button`
  height: 40px;
  border-radius: 20px;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  color: white;
  align-self: center;
  margin-right: 15px;
  border: none;
  background: #17a2b8;
  margin-left: 10px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
  }

  :selected {
    border: none;
  }
`;

export const SubmitDate = styled.button`
  height: 30px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 15px;
  border: none;
  background: #17a2b8;
  margin-left: 10px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
  }
`;
export const DateDiv = styled.div`
  float: left;
  left: 0;
  margin-left: 15px;
`;
export const DateSelectorDiv = styled.div`
  width: 90%;
  height: 60px;
  display: flex;
  clear: both;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 20px;
  flex-direction: row;
`;