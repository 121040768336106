import styled from 'styled-components';
// import { Link } from 'react-router-dom';

import { Form } from '@unform/web';

export const Wrapper = styled.div`
  margin: 10px;
`;

export const Header = styled.div`
  margin: 10px;
  display: flex;
  justify-content: space-between;
`;

export const Card = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Times = styled.div`
  display: flex;
  align-items: center;

  input {
    width: 100px;
    border: 0px;
    border-bottom: 1px solid #33333330;
    border-radius: 0px;
    margin-left: 3px;
    margin-right: 3px;
    transition: all 1s;
    text-align: center;

    :focus {
      border: none;
      border-bottom: 1px solid red;
      border-radius: 0px;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #d3d3d3;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #d3d3d3;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #d3d3d3;
    }
  }
`;

export const StyledForm = styled(Form)``;
