import React, { useState, useCallback} from 'react';
import { Form } from '@unform/web';

import { Modal, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';
import Input from '~/components/Form/Input';
import api from '~/services/api';
import Select from '~/components/Form/SelectInput';
import { useAuth } from '~/context/AuthContext';
import NfeAvulsaItem from '../NfeAvulsaItem';
import { Checkbox } from 'ui-kit-takeat';
import NfeAvulsaVolume from '../NfeAvulsaVolume';

export default function NfeAvulsa({
  modal,
  toggle,
  getNfces
}) {

  const { user } = useAuth();

  const [loadingNfe, setLoadingNfe] = useState(false);
  const [mensagemSefaz, setMensagemSefaz] = useState('');
  const [erros, setErros] = useState([]);

  const [hasNotaRef, setHasNotaRef] = useState(false);
  const [notaRef, setNotaRef] = useState(null);

  const localDestinoOptions = [
    {label: '1 – Operação interna', value: '1'},
    {label: '2 – Operação interestadual', value: '2'},
    {label: '3 – Operação com exterior', value: '3'},
  ];

  const finalidadeOptions = [
    {label: '1 – Normal', value: '1'},
    {label: '2 – Complementar', value: '2'},
    {label: '3 – Nota de ajuste', value: '3'},
    {label: '4 – Devolução', value: '4'},
  ];

  const tipoOptions = [
    {label: '0 – Nota Fiscal de Entrada', value: '0'},
    {label: '1 – Nota Fiscal de Saída', value: '1'},
  ];

  const consumidorOptions = [
    {label: '0 – Normal', value: '0'},
    {label: '1 – Consumidor final', value: '1'},
  ];

  const modalidadeOptions = [
    {label: '0 – Por conta do emitente', value: '0'},
    {label: '1 – Por conta do destinatário', value: '1'},
    {label: '2 – Por conta de terceiros', value: '2'},
    {label: '9 – Sem frete', value: '3'},
  ];

  const presencaOptions = [
    {label: '0 – Não se aplica (por exemplo, para a Nota Fiscal complementar ou de ajuste)', value: '0'},
    {label: '1 – Operação presencial', value: '1'},
    {label: '2 – Operação não presencial, pela Internet', value: '2'},
    {label: '3 – Operação não presencial, Teleatendimento', value: '3'},
    {label: '4 – NFC-e em operação com entrega em domicílio', value: '4'},
    {label: '9 – Operação não presencial, outros', value: '9'},
  ];

  const indicadorOptions = [
    {label: '1 – Contribuinte ICMS (informar a IE do destinatário)', value: '1'},
    {label: '2 – Contribuinte isento de Inscrição no cadastro de Contribuintes do ICMS', value: '2'},
    {label: '9 – Não Contribuinte, que pode ou não possuir Inscrição Estadual no Cadastro de Contribuintes do ICMS', value: '9'},
  ];

  const [data, setData] = useState({
    cnpj_emitente: user?.cnpj || '',
  });

  const [itens, setItens] = useState([{
    numero_item: 1
  }]);

  const [volumes, setVolumes] = useState([]);

  // useEffect(() => {
  //   console.log(itens);
  // }, [itens]);

 const [nextId, setNextId]= useState(2);
 const [nextVolumeId, setNextVolumeId]= useState(1);

 const handleDevolucao = useCallback(async () => {
    try {
      setLoadingNfe(true);
      setMensagemSefaz('');
      setErros([]);

      const thisItens = itens.map(dt => dt.dataValues);
      data.itens = thisItens;

      const thisVolumes = volumes.map(dt => dt.dataValues);
      data.volumes = thisVolumes;

      if(hasNotaRef){
        data.notas_referenciadas = [{ chave_nfe: notaRef }];
      }

      const response = await api.post(
        `/restaurants/new-nfe-avulsa`,
        data
      );

      if (response.data.status === 'erro_autorizacao') {
        setMensagemSefaz(response.data.mensagem);
      } else if (response.data.status === 'autorizado') {
        window.open(
          response.data.url,
          'janela',
          'width=795, height=590, top=100, left=699, scrollbars=no, status=no, toolbar=no, location=no, menubar=no, resizable=no, fullscreen=no'
        );
        getNfces();
        toggle();
      } else {
        setMensagemSefaz(response.data?.mensagem || '');
        setErros(response?.data?.erros || []);
      }

      setLoadingNfe(false);
    } catch (err) {
      console.log(err);
      setLoadingNfe(false);
    }
  });

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalBody>
          <Form>
            <Row>
              <Col md="8">
                <Input
                  name="natureza_operacao"
                  label="Natureza da Operação"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.natureza_operacao = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="4">
                <Select 
                  name="tipo_documento"
                  options={tipoOptions}
                  label="Tipo Documento"
                  onChange={e => {
                    let thisData = data;
                    thisData.tipo_documento = e.value;
                    setData(thisData);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Select 
                  name="finalidade_emissao"
                  options={finalidadeOptions}
                  label="Finalidade Emissão"
                  onChange={e => {
                    let thisData = data;
                    thisData.finalidade_emissao = e.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="6">
                <Select 
                  name="local_destino"
                  options={localDestinoOptions}
                  label="Local Destino"
                  onChange={e => {
                    let thisData = data;
                    thisData.local_destino = e.value;
                    setData(thisData);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Select 
                  name="consumidor_final"
                  options={consumidorOptions}
                  label="Consumidor Final"
                  onChange={e => {
                    let thisData = data;
                    thisData.consumidor_final = e.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="4">
                <Select 
                  name="presenca_comprador"
                  options={presencaOptions}
                  label="Presença Comprador"
                  onChange={e => {
                    let thisData = data;
                    thisData.presenca_comprador = e.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="4">
                <Select 
                  name="modalidade_frete"
                  options={modalidadeOptions}
                  label="Modalidade Frete"
                  onChange={e => {
                    let thisData = data;
                    thisData.modalidade_frete = e.value;
                    setData(thisData);
                  }}
                />
              </Col>
            </Row>

            <Row style={{marginTop: 10}}>
              <Col
                style={{ borderBottom: '1px solid #EBEBEB', marginBottom: 5 }}
              >
                <h5>Dados do Emitente:</h5>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <Input
                  name="nome_emitente"
                  label="Razão Social"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.nome_emitente = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="3">
                <Input
                  name="cnpj_emitente"
                  label="CNPJ"
                  type="text"
                  defaultValue={data.cnpj_emitente}
                  onChange={e => {
                    let thisData = data;
                    thisData.cnpj_emitente = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="3">
                <Input
                  name="inscricao_estadual_emitente"
                  label="Inscrição Estadual"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.inscricao_estadual_emitente = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md="7">
                <Input
                  name="logradouro_emitente"
                  label="Logradouro"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.logradouro_emitente = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="2">
                <Input
                  name="numero_emitente"
                  label="Nº"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.numero_emitente = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="3">
                <Input
                  name="complemento_emitente"
                  label="Complemento"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.complemento_emitente = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md="4">
                <Input
                  name="municipio_emitente"
                  label="Município"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.municipio_emitente = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="2">
                <Input
                  name="uf_emitente"
                  label="UF"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.uf_emitente = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="4">
                <Input
                  name="bairro_emitente"
                  label="Bairro"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.bairro_emitente = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="2">
                <Input
                  name="cep_emitente"
                  label="CEP"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.cep_emitente = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col
                style={{
                  borderBottom: '1px solid #EBEBEB',
                  marginBottom: 5,
                  paddingTop: 10,
                }}
              >
                <h5>Dados do Destinatário:</h5>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <Input
                  name="nome_destinatario"
                  label="Razão Social"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.nome_destinatario = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="3">
                <Input
                  name="cnpj_destinatario"
                  label="CNPJ"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.cnpj_destinatario = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="3">
                <Input
                  name="inscricao_estadual_destinatario"
                  label="Inscrição Estadual"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.inscricao_estadual_destinatario = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md="4">
                <Input
                  name="logradouro_destinatario"
                  label="Logradouro"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.logradouro_destinatario = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="2">
                <Input
                  name="numero_destinatario"
                  label="Nº"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.numero_destinatario = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="2">
                <Input
                  name="complemento_destinatario"
                  label="Complemento"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.complemento_destinatario = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="4">
                <Select 
                  name="indicador_inscricao_estadual_destinatario"
                  options={indicadorOptions}
                  label="Indicador Inscrição Estadual"
                  onChange={e => {
                    let thisData = data;
                    thisData.indicador_inscricao_estadual_destinatario = e.value;
                    setData(thisData);
                  }}
                />

              </Col>
            </Row>

            <Row>
              <Col md="4">
                <Input
                  name="municipio_destinatario"
                  label="Município"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.municipio_destinatario = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="2">
                <Input
                  name="uf_destinatario"
                  label="UF"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.uf_destinatario = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="4">
                <Input
                  name="bairro_destinatario"
                  label="Bairro"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.bairro_destinatario = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="2">
                <Input
                  name="cep_destinatario"
                  label="CEP"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.cep_destinatario = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col
                style={{
                  borderBottom: '1px solid #EBEBEB',
                  marginBottom: 5,
                  paddingTop: 10,
                }}
              >
                <h5>Dados da Nota:</h5>
              </Col>
            </Row>

            <Row>
              <Col md="2">
                <Input
                  name="valor_total"
                  label="Valor Total"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  onChange={e => {
                    let thisData = data;
                    thisData.valor_total = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="2">
                <Input
                  name="valor_desconto"
                  label="Valor Desconto"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  onChange={e => {
                    let thisData = data;
                    thisData.valor_desconto = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="2">
                <Input
                  name="valor_frete"
                  label="Valor Frete"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  onChange={e => {
                    let thisData = data;
                    thisData.valor_frete = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="2">
                <Input
                  name="valor_pis"
                  label="Valor PIS"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  onChange={e => {
                    let thisData = data;
                    thisData.valor_pis = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="2">
                <Input
                  name="valor_ipi"
                  label="Valor IPI"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  onChange={e => {
                    let thisData = data;
                    thisData.valor_ipi = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="2">
                <Input
                  name="valor_cofins"
                  label="Valor COFINS"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  onChange={e => {
                    let thisData = data;
                    thisData.valor_cofins = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md="2">
                <Input
                  name="icms_aliquota"
                  label="ICMS Aliquota"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  onChange={e => {
                    let thisData = data;
                    thisData.icms_aliquota = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="2">
                <Input
                  name="icms_base_calculo"
                  label="ICMS Base Cálculo"
                  type="text"
                  fontSize={{ fontSize: 10 }}
                  onChange={e => {
                    let thisData = data;
                    thisData.icms_base_calculo = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="2">
                <Input
                  name="icms_valor_total"
                  label="ICMS Valor Total"
                  type="text"
                  fontSize={{ fontSize: 10 }}
                  onChange={e => {
                    let thisData = data;
                    thisData.icms_valor_total = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="2">
                <Input
                  name="icms_aliquota_st"
                  label="ICMS Aliquota ST"
                  type="text"
                  fontSize={{ fontSize: 10 }}
                  onChange={e => {
                    let thisData = data;
                    thisData.icms_aliquota_st = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="2">
                <Input
                  name="icms_base_calculo_st"
                  label="ICMS Base Cálc. ST"
                  type="text"
                  fontSize={{ fontSize: 10 }}
                  onChange={e => {
                    let thisData = data;
                    thisData.icms_base_calculo_st = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="2">
                <Input
                  name="icms_valor_total_st"
                  label="ICMS Valor Total ST"
                  type="text"
                  fontSize={{ fontSize: 10 }}
                  onChange={e => {
                    let thisData = data;
                    thisData.icms_valor_total_st = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md="2">
                <Input
                  name="issqn_aliquota"
                  label="ISSQN Aliquota"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  onChange={e => {
                    let thisData = data;
                    thisData.issqn_aliquota = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="2">
                <Input
                  name="issqn_base_calculo"
                  label="ISSQN Base Cálculo"
                  type="text"
                  fontSize={{ fontSize: 10 }}
                  onChange={e => {
                    let thisData = data;
                    thisData.issqn_base_calculo = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="2">
                <Input
                  name="issqn_valor_total"
                  label="ISSQN Valor Total"
                  type="text"
                  fontSize={{ fontSize: 10 }}
                  onChange={e => {
                    let thisData = data;
                    thisData.issqn_valor_total = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="3">
                <Input
                  name="pis_aliquota_porcentual"
                  label="PIS Alíquota Porcentagem"
                  type="text"
                  fontSize={{ fontSize: 10 }}
                  onChange={e => {
                    let thisData = data;
                    thisData.pis_aliquota_porcentual = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="3">
                <Input
                  name="cofins_aliquota_porcentual"
                  label="COFINS Alíquota Porcentagem"
                  type="text"
                  fontSize={{ fontSize: 10 }}
                  onChange={e => {
                    let thisData = data;
                    thisData.cofins_aliquota_porcentual = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <Input
                  name="informacoes_adicionais_contribuinte"
                  label="Informações Adicionais"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  onChange={e => {
                    let thisData = data;
                    thisData.informacoes_adicionais_contribuinte = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
            </Row>

            <Row 
              style={{
                borderBottom: '1px solid #EBEBEB',
                marginBottom: 5,
                paddingTop: 10,
              }}
            >
              <Col md="2">
                <h5>Fornecedor:</h5>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Input
                  name="nome_transportador"
                  label="Razão Social"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.nome_transportador = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="3">
                <Input
                  name="cnpj_transportador"
                  label="CNPJ"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.cnpj_transportador = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="3">
                <Input
                  name="inscricao_estadual_transportador"
                  label="Inscrição Estadual"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.inscricao_estadual_transportador = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Input
                  name="endereco_transportador"
                  label="Endereço"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.endereco_transportador = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="4">
                <Input
                  name="municipio_transportador"
                  label="Município"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.municipio_transportador = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
              <Col md="2">
                <Input
                  name="uf_transportador"
                  label="UF"
                  type="text"
                  onChange={e => {
                    let thisData = data;
                    thisData.uf_transportador = e.target.value;
                    setData(thisData);
                  }}
                />
              </Col>
            </Row>
            
            <Row 
              style={{
                borderBottom: '1px solid #EBEBEB',
                borderTop: '1px solid #EBEBEB',
                marginBottom: 5,
                marginTop: 5,
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <Col md="12">
                <Checkbox 
                  label="Referenciar nota"
                  name="has_nota_ref"
                  checked={hasNotaRef}
                  onClick={() => setHasNotaRef(!hasNotaRef)}
                  style={{fontSize: 14}}
                />
              </Col>
              {
                hasNotaRef &&
                <Row>
                  <Col>
                    <Input
                      name="nota_ref"
                      type="text"
                      onChange={e => {
                        setNotaRef(e.target.value);
                      }}
                      placeholder="Chave da Nota"
                      value={notaRef}
                    />
                  </Col>
                </Row>
              }
            </Row>

            <Row 
              style={{
                borderBottom: '1px solid #EBEBEB',
                marginBottom: 5,
                paddingTop: 10,
              }}
            >
              <Col md="2">
                <h5>Volumes:</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <button name="add_volume" 
                  onClick={e => {
                    setVolumes([...volumes, {numero_item: nextVolumeId }]);
                    setNextVolumeId(nextVolumeId + 1);
                  }}
                  type="button"
                  style={{
                    borderRadius: '25px',
                    height: 30,
                    border: 'none',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    background: '#2AB7A6',
                    color: 'white',
                    textAlign: 'center',
                    marginTop: 5
                  }}
                > + Adicionar volume</button>
              </Col>
            </Row>

            {
              volumes.map((item, key) => (
                <NfeAvulsaVolume
                  key={item.numero_item}
                  number={key}
                  item={item}
                  itens={volumes}
                  setItens={setVolumes}
                />
              ))
            }

            
            <Row 
              style={{
                borderBottom: '1px solid #EBEBEB',
                marginBottom: 5,
                paddingTop: 15,
                marginTop: 10
              }}
            >
              <Col md="2">
                <h5>Itens:</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <button name="add_item" 
                  onClick={e => {
                    setItens([...itens, {numero_item: nextId }]);
                    setNextId(nextId + 1);
                  }}
                  type="button"
                  style={{
                    borderRadius: '25px',
                    height: 30,
                    border: 'none',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    background: '#2AB7A6',
                    color: 'white',
                    textAlign: 'center',
                    marginTop: 5
                  }}
                > + Adicionar item</button>
              </Col>
            </Row>

            {
              itens.map((item, key) => (
                <NfeAvulsaItem
                  key={item.numero_item}
                  number={key}
                  item={item}
                  itens={itens}
                  setItens={setItens}
                />
              ))
            }
          </Form>

          {mensagemSefaz !== '' && (
            <Row style={{ marginTop: 10 }}>
              <h5 style={{ color: 'red' }}>
                <strong>Mensagem SEFAZ:</strong> {mensagemSefaz}
              </h5>
            </Row>
          )}
          {
            erros.length > 0 && 
              <div style={{ marginTop: 10 }}>
                {erros.map(erro => (
                  <p style={{ color: 'red' }}>
                    - {erro.mensagem}
                  </p>
                ))}
              </div>
          }
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => toggle()}>
            Fechar
          </Button>
          <Button
            color="success"
            onClick={() => {
              console.log(data);
              handleDevolucao();
            }}
            disabled={loadingNfe}
          >
            {loadingNfe ? 'Aguarde' : 'Enviar'}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
