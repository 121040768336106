import styled from 'styled-components';

export const LoadingOrder = styled.div`
  width: 500px;
  height: 500px;
  background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  border-radius: 7px;
  position: absolute;
  top: 200px;
  left: 35%;

  z-index: 100000;

  h1 {
    text-align: center;
    font-size: 16px;
    margin-top: 15px;
  }

  img {
    width: 92px;
  }
`;
