import React, { useCallback, useEffect, useState, createContext, useContext } from 'react';
import { BalanceTablesArea } from '~/components/BalancePageComponents/BalanceTablesArea';
import { BalanceCart } from '~/components/BalancePageComponents/BalanceCart';
import { BalanceOrderArea } from '~/components/BalancePageComponents/BalanceOrderArea';
import { AddClientModal } from '~/components/BalancePageComponents/AddClientModal';
import api from '~/services/api';
import { PageContainer, BalanceCard } from './styles';
import { useMenu } from '~/context/OrderingSystem/Menu';
import { NewHeader } from '~/components/NewHeader';
import { SelectComplements } from '~/components/SelectComplements';
import { useCart } from '~/context/OrderingSystem/Cart';
import { useAuth } from '~/context/AuthContext';
// Criar o contexto
const balanceContext = createContext({});

// Componente BalancePage
export const BalancePage = () => {
  const [tables, setTables] = useState([]);
  const [userMenu, setUserMenu] = useState([]);
  const [weightProducts, setWeightProducts] = useState([{}]);
  const [command, setCommand] = useState('');
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [isSelectingComplements, setIsSelectingComplements] = useState(false);
  const [additionalProducts, setAdditionalProducts] = useState([]);
  const [commandTotal, setCommandTotal] = useState(0);
  const [addClient, setAddClient] = useState(false);
  const [weightProductSelected, setWeightProductSelected] = useState({});

  const [price, setPrice] = useState(weightProductSelected?.price || 0);
  const [weight, setWeight] = useState('0');

  const { menu, getMenu } = useMenu();
  const { cart } = useCart();
  const { user } = useAuth();


  const getTables = useCallback(async () => {
    const response = await api.get('/restaurants/tables');
    const tables = response.data.filter((table) => {
      return table?.table_type === 'table' || table?.table_type === 'command';
    });

    const sortedTables = tables.sort((a, b) => {
      if (a.status !== 'ongoing' && b.status === 'ongoing') {
        return -1;
      } else if (a.status === 'ongoing' && b.status !== 'ongoing') {
        return 1;
      }
      return 0;
    });

    setTables(sortedTables);
    setCommand({
      id: tables?.[0].id,
      label: tables?.[0].table_number.toString(),
      status: tables?.[0].status,
      tableType: tables?.[0].table_type
    });
  }, []);

  useEffect(() => {
    getTables();
    setUserMenu(menu);
    const hasProductWeightCategory = menu.filter((category) => {
      const categoryHasWeightProduct = category.products.some((product) => product.use_weight === true);
      return categoryHasWeightProduct;
    });
    const productsWeight = hasProductWeightCategory.map((categoria) => {
      return categoria.products.filter((produto) => produto.use_weight === true);
    }).flat();
    setWeightProducts(productsWeight);

  }, [getTables, menu]);

  useEffect(() => {
    const additional = cart.filter((add) => add.use_weight === false);
    setAdditionalProducts(additional);
    const total = cart.reduce((acumulador, objeto) => {
      if (objeto.combo_price === null && objeto.complement_categories.length > 0) {
        const soma_preco_categorias = objeto.complement_categories.reduce((accCat, categoria) => {
          const soma_complementos = categoria.complements.reduce((accComp, complemento) => {
            return accComp + parseFloat(complemento.price || 0);
          }, 0);
          return accCat + soma_complementos;
        }, 0);
        return acumulador + soma_preco_categorias + parseFloat(objeto.price);
      } else {
        const totalCommand = objeto.use_weight === false
          ? parseFloat(acumulador) + (parseFloat(objeto.combo_price || objeto.price) * parseFloat(objeto.amount))
          : parseFloat(acumulador) + (parseFloat(objeto.price || objeto.weight_price) * parseFloat(objeto.weight || 0));

        return totalCommand;
      }
    }, 0);

    setCommandTotal(total);
  }, [cart]);

  function handleSetWeight(e) {
    const newWeight = e.replace(',', '.')

    setWeight(newWeight)
  }

  return (
    <balanceContext.Provider
      value={{
        userMenu,
        command,
        setSelectedCategory,
        selectedCategory,
        setSelectedProduct,
        selectedProduct,
        isSelectingComplements,
        setIsSelectingComplements,
        cart,
        weightProducts,
        additionalProducts,
        commandTotal,
        user,
        setAddClient,
        getTables,
        setWeightProductSelected,
        weightProductSelected,
        getMenu,
        tables,
        setCommand,
        setTables
      }}
    >
      <NewHeader />
      <PageContainer>
        {addClient && <AddClientModal setAddClient={setAddClient} addClient={addClient} />}
        {isSelectingComplements ? (
          <BalanceCard>
            <SelectComplements
              product={selectedProduct}
              setIsSelectingComplements={setIsSelectingComplements}
            />
          </BalanceCard>
        ) : (
          <BalanceCard>
            <BalanceTablesArea />
            <BalanceCart price={price} setPrice={setPrice} weight={weight} setWeight={(e) => { handleSetWeight(e) }} />
            <BalanceOrderArea setPrice={setPrice} setWeight={setWeight} />
          </BalanceCard>
        )}
      </PageContainer>
    </balanceContext.Provider>
  );
};


export const useBalanceContext = () => useContext(balanceContext);
