import styled from 'styled-components';

export const CategoryAddedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 5px;

  &:not(:last-child) {
    border-bottom: 1px solid #cdcdcd;
  }

  h3 {
    margin: 0;
    color: #4d4d4c;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
