import React from 'react';

import { ButtonContainer } from './styles';

export default function PersonalButton({
  message,
  color = false,
  Icon,
  disabled = false,
  fontColor = null,
  ...rest
}) {
  return (
    <ButtonContainer
      type="button"
      color={color}
      disabled={disabled}
      fontColor={fontColor}
      {...rest}
    >
      <span>
        {Icon && <Icon size={14} />} {message}
      </span>
    </ButtonContainer>
  );
}
