/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
import React, { useState, useRef, useContext, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import CreatableSelect from 'react-select/creatable';


import { FaCheck, FaCopy, FaPlus} from 'react-icons/fa';
import OrderProductContext from '../../../pages/Products/ProductsTables/context';
import {
  ProductTableRow,
  ProductImage,
  MenuItemPromotion,
  NoImage,
  ProductName,
  ProductPrice,
  ProductDescription,
  ButtonsTd,
  ItemTd,
  ProductAvatar,
  NumberFormatStyled,
  SubmitStyledButton,
} from './styles';

import api from '~/services/api';
import formatValue from '../../../utils/formatValue';

import { useAuth } from '~/context/AuthContext';
import Select from '~/components/Form/SelectInput';
import Input from '~/components/Form/Input';
import { FiTrash } from 'react-icons/fi';

export default function ItemProductIfood({
  product,
  category,
  handleDelete,
  getCategories,
  index,
  menewProducts,
  setProducts,
  products,
  categories,
  setCategoriesFiltered,
  setCategories,
  sliceSize,
  setComplementsCategories,
  complementCategories,
  fiscalGroups,
  ifoodMenus,
  ifoodRestaurants,
  use_ifood_pdv_code
}) {
  const [modalImage, setModalImage] = useState(false);
  const [modalEditProduct, setModalEditProduct] = useState(false);
  const [
    complementCategoriesInsideProduct,
    setComplementCategoriesInsideProduct,
  ] = useState([]);
  const { user } = useAuth();
  // drag and drop
  const ref = useRef();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropDownHidden, setDropDownHidden] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const { move, onDrop } = useContext(OrderProductContext);

  const [ifoodProducts, setIfoodProducts] = useState(product.ifood_products || []);

  const [{ isDragging }, dragRef] = useDrag({
    item: { type: 'PRODUCTCARD', index, product_id: product.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (!monitor.didDrop()) {
        const draggedIndex = item.index;
        const targetIndex = index;

        onDrop(item.product_id, product.id, draggedIndex, targetIndex);
      }
    },
  });

  const [, dropRef] = useDrop({
    accept: 'PRODUCTCARD',
    hover(item, monitor) {
      const draggedIndex = item.index;
      const targetIndex = index;

      if (draggedIndex === targetIndex) {
        return;
      }

      const targetSize = ref.current.getBoundingClientRect();
      const targetCenter = targetSize.height / 2;
      const draggedOffset = monitor.getClientOffset();
      const draggedTop = draggedOffset.y - targetSize.top;
      if (draggedIndex < targetIndex && draggedTop < targetCenter) {
        return;
      }
      if (draggedIndex > targetIndex && draggedTop > targetCenter) {
        return;
      }
      move(item.product_id, product.id, draggedIndex, targetIndex);

      item.index = targetIndex;
    },
    drop(item, monitor) {
      const draggedIndex = item.index;
      const targetIndex = index;

      onDrop(item.product_id, product.id, draggedIndex, targetIndex);

      setDropDownHidden(false);
    },
  });

  dragRef(dropRef(ref));

  function toggleImage() {
    setModalImage(!modalImage);
  }

  function toggleModalEditProduct() {
    setModalEditProduct(!modalEditProduct);
  }

  const initialData = {
    image_id: {
      id: product.image ? product.image.id : null,
      url: product.image ? (product?.image?.url_thumb || product?.image?.url) : null,
    },
  };

  async function handleSubmit(data) {
    try {
      const parsedData = {};

      if (data.image_id) {
        parsedData.image_id = Number(data.image_id);
      }

      await api.put(`restaurants/products/${product.id}`, parsedData);

      toast.success('Produto atualizado com sucesso!');
      localStorage.setItem('@gddashboard:isMenuUpdated', true);
      toggleImage();
      // getMenu(userScreenTop);

      // getCategories();
    } catch (error) {
      toast.error('Falha ao atualizar produto');
      console.log(error);
    }
  }

  async function handleChangeProduct(value, event, idx) {
    const [type, prodId] = idx.split('-');
    const id = Number(prodId);

    const data = {};

    if (type === 'pdv') {
      data.available = value;
    } else {
      data.available_in_delivery = value;
    }

    try {
      await api.put(`restaurants/products/${id}`, data);

      const productFind = products?.findIndex((prod) => prod.id === id);

      const newProductsArray = [...products];

      newProductsArray[productFind] = {
        ...product,
        ...data,
      };

      const newProducts = [...newProductsArray];

      setProducts(newProducts);

      const catFind = categories?.findIndex((cat) => cat.id === category.id);

      categories[catFind].products = newProducts;
      localStorage.setItem('@gddashboard:isMenuUpdated', true);

      toast.success('Produto alterado com sucesso!');
    } catch (err) {
      toast.error('Erro ao ativar/ desativar produto!');
      console.log(err);
    }
  }

  const getComplementsCategoryInsideProduct = useCallback(async () => {
    const response = await api.get(
      `/restaurants/product-complement-categories/${product.id}`
    );

    setComplementCategoriesInsideProduct(response.data);
  }, [product]);

  const [collapsed, setCollapsed] = useState([]);

  const handleCollapse = (id) => {
    getComplementsCategoryInsideProduct();
    if (collapsed.includes(id)) {
      const newCollapse = collapsed.filter((c) => c !== id);
      setCollapsed(newCollapse);
    } else {
      setCollapsed((state) => [...state, id]);
    }
  };

  function currencyFormatter(value) {
    if (!Number(value)) return 'R$ 0,00';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  }

  const handleIfood = useCallback(
    async (data) => {
      const ifood_restaurant_id = parseInt(data.ifood_restaurant_id);

      try {
        const thisData = {
          ifood_array_id: data.ifood_products,
          ifood_restaurant_id,
          product_id: product.id,
        };

        console.log(data)

        await api.post(`/restaurants/ifood/products/array`, thisData);

        toast.success('Produto associado com sucesso!');
      } catch (error) {
        if (error.response.data.type === 'already') {
          let message = '';
          ifoodMenus
            .find((menu) => menu.ifood_restaurant_id === ifood_restaurant_id)
            ?.products?.filter((prod) =>
              error.response.data.array.includes(prod.value)
            )
            .map((prod) => {
              message = `${message + prod.label}, `;
            });
          toast.error(`${message} já vinculado(s)`);
        } else {
          toast.error('Erro ao vincular');
        }
      }
    },
    [product]
  );

  const handleIfoodPdv = useCallback(
    async (data) => {
      const ifood_restaurant_id = parseInt(data.ifood_restaurant_id);

      try {
        const thisData = {
          ifood_id: data.ifood_products,
          ifood_restaurant_id,
          product_id: product.id,
        };

        const response = await api.post(`/restaurants/ifood/products`, thisData);

        const newIfoodProducts = [
          ...ifoodProducts,
          response.data
        ];

        setIfoodProducts(newIfoodProducts);

        toast.success('Produto associado com sucesso!');
      } catch (error) {
        if (error.response.data.type === 'already') {
          let message = '';
          ifoodMenus
            .find((menu) => menu.ifood_restaurant_id === ifood_restaurant_id)
            ?.products?.filter((prod) =>
              error.response.data.array.includes(prod.value)
            )
            .map((prod) => {
              message = `${message + prod.label}, `;
            });
          toast.error(`${message} já vinculado(s)`);
        } else {
          toast.error(error.response.data?.error || 'Erro ao vincular');
        }
      }
    },
    [product, ifoodProducts]
  );

  const handleDeleteIfood = useCallback(
    async ({ ifood_id, ifood_restaurant_id }) => {
      try {
        const thisData = {
          ifood_id,
          ifood_restaurant_id: parseInt(ifood_restaurant_id),
          product_id: product.id,
        };

        await api.delete(`/restaurants/ifood/products`, { data: thisData });

        const newIfoodProducts = ifoodProducts.filter(thisProd => thisProd !== ifoodProducts.find(
          prod => prod.ifood_id === ifood_id && prod.ifood_restaurant_id === parseInt(ifood_restaurant_id)
        ));

        setIfoodProducts(newIfoodProducts);

        toast.success('Associação removida com sucesso!');
      } catch (error) {
        toast.error('Erro ao desvincular');
      }
    },
    [product, ifoodProducts]
  );

  return (
    <>
      <ProductTableRow available sold_off={product?.sold_off} ref={ref}>
        <ItemTd>
          <ProductAvatar>
            {product?.image ? (
              <ProductImage src={(product?.image?.url_thumb || product?.image?.url)} alt="Foto do produto" />
            ) : (
              <NoImage />
            )}
          </ProductAvatar>
          <ProductDescription>
            <ProductName>{product?.name}</ProductName>
          </ProductDescription>
        </ItemTd>
        {!user.only_delivery && (
          <ProductPrice>
            {product?.price_promotion ? (
              <>
                <MenuItemPromotion>
                  {formatValue(product?.price)}
                </MenuItemPromotion>

                <NumberFormatStyled
                  prefix="R$"
                  format={currencyFormatter}
                  name="value"
                  defaultValue={formatValue(product?.price_promotion)}
                  disabled
                />
              </>
            ) : (
              <>
                <NumberFormatStyled
                  prefix="R$"
                  format={currencyFormatter}
                  name="value"
                  defaultValue={formatValue(product?.price)}
                  disabled
                />
              </>
            )}
          </ProductPrice>
        )}

        <ProductPrice style={{ width: '450px' }}>
          {ifoodRestaurants.map((restaurant) => (
            <Form onSubmit={use_ifood_pdv_code ? handleIfoodPdv : handleIfood }>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '100%', marginRight: 10 }}>
                  {use_ifood_pdv_code ? 
                   <div style={{marginTop: 10, marginBottom: 10}}>
                    <Input
                      name="ifood_products"
                      label={`Código PDV${ifoodRestaurants.length > 1 ? ` (${restaurant.name})` : ''}`}
                    />
                   </div> :
                   <>
                      <Select
                        name="ifood_products"
                        isMulti
                        defaultValue={ifoodMenus
                          .find(
                            (menu) =>
                              menu.ifood_restaurant_id ===
                              restaurant.ifood_restaurant_id
                          )
                          ?.products?.filter((prod) =>
                            product.ifood_products
                              .map((ifo) => ifo.ifood_id)
                              .includes(prod.value)
                          )}
                        options={
                          ifoodMenus.find(
                            (menu) =>
                              menu.ifood_restaurant_id ===
                              restaurant.ifood_restaurant_id
                          )?.products || []
                        }
                        label={ifoodRestaurants.length > 1 ? restaurant.name : ''}
                        menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      />
                    </>
                  }
                </div>
                <Input
                  style={{ display: 'none' }}
                  value={restaurant.ifood_restaurant_id}
                  name="ifood_restaurant_id"
                />
                <div>
                  <SubmitStyledButton
                    id="limit"
                    type="submit"
                    style={{
                      marginLeft: 'auto',
                      marginTop: (ifoodRestaurants.length > 1 || use_ifood_pdv_code) ? 35 : 10,
                    }}
                  >
                    {use_ifood_pdv_code ? <FaPlus /> : <FaCheck />}
                  </SubmitStyledButton>
                </div>
              </div>
              {use_ifood_pdv_code && ifoodProducts.filter(prod => prod.ifood_restaurant_id === restaurant.ifood_restaurant_id).map(prod => (
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                  <div style={{width: '100%', marginRight: 10}}>
                    <Input 
                      disabled
                      name="display_pdv"
                      value={prod.ifood_id}
                      style={{ color: '#3D3D3D'}}
                    /> 
                  </div>

                  <div>
                  <SubmitStyledButton
                    id="limit"
                    type="button"
                    style={{
                      marginRight: '5px',
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(prod.ifood_id);
                      toast.success('Código copiado com sucesso.')
                    }}
                  >
                    {<FaCopy />}
                  </SubmitStyledButton>
                  </div>

                  <div>
                    <SubmitStyledButton
                      id="limit"
                      type="button"
                      style={{
                        // marginLeft: 'auto',
                        background: '#ff2c3a'
                      }}
                      onClick={e => handleDeleteIfood({ ifood_id: prod.ifood_id, ifood_restaurant_id: restaurant.ifood_restaurant_id })}
                    >
                      {<FiTrash />}
                    </SubmitStyledButton>
                  </div>

                  {/* <FiTrash 
                    style={{color: 'red', cursor: 'pointer'}}
                    onClick={e => handleDeleteIfood({ ifood_id: prod.ifood_id, ifood_restaurant_id: restaurant.ifood_restaurant_id })}
                  /> */}
                </div>
              ))}
            </Form>
          ))}
        </ProductPrice>

        <ButtonsTd />
        {/* 
        <ButtonsTd>
          <div
            style={{
              display: 'flex',
              height: 'auto',
            }}
          >
            <FaAngleDown
              size={20}
              title="Mostrar complementos"
              cursor="pointer"
              onClick={() => {
                handleCollapse(product?.id);
              }}
              style={{
                transition: 'all 0.2s',
                transform: collapsed.includes(product?.id)
                  ? 'rotate(-180deg)'
                  : 'rotate(0deg)',
                marginLeft: 22,
              }}
            />
          </div>
        </ButtonsTd> */}
      </ProductTableRow>

      {/* {complementCategoriesInsideProduct &&
        complementCategoriesInsideProduct.length > 0 &&
        complementCategoriesInsideProduct.map((comp_category, idx) => (
          <ProductsComplementsCategory
            complementCategory={comp_category}
            complementCategoriesProduct={complementCategoriesInsideProduct}
            setComplementCategoriesInsideProduct={
              setComplementCategoriesInsideProduct
            }
            index={idx}
            isOpen={!collapsed.includes(product?.id)}
            getCategories={getCategories}
            menewProducts={menewProducts}
            product={product}
            categories={categories}
            setCategories={setCategories}
            category={category}
            setComplementsCategories={setComplementsCategories}
            complementCategories={complementCategories}
            fiscalGroups={fiscalGroups}
          />
        ))} */}
    </>
  );
}
