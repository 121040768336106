import styled from 'styled-components';
import { Link } from 'react-router-dom';

import DatePicker from 'react-datepicker';

import { Table } from 'reactstrap';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: calc(100vh - 148px);
  overflow-y: auto;
  padding: 0 20px 20px;
`;

export const ScrollArea = styled.div`
  overflow-y: scroll;
  max-height: 80vh;

  @media (max-width: 1281px) {
    max-height: 60vh;
  }

  @media (min-width: 1282px) and (max-width: 1441px) {
    max-height: 75vh;
  }
`;

export const CloseButton = styled(Link)`
  height: 30px;
  border-radius: 8px;

  padding-left: 10px;
  padding-right: 10px;
  color: white;

  border: none;
  background: #04bfbf;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  text-align: center;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const Header = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;
`;

export const ErrorContainer = styled.div`
  width: 60%;
  min-width: 600px;
  margin: 10px auto 30px auto;

  background: orange;
  color: #fff;

  border-radius: 8px;
  padding: 30px;

  p {
    text-align: center;
    font-size: 22px;
  }
`;

export const Card = styled.div`
  width: 100%;
  padding: 20px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const THProducts = styled.th`
  text-align: center;
`;

export const TDProducts = styled.td`
  text-align: center;
  padding: ${(props) => (props.check ? '12px 7px' : '0px')} !important;
  transform: ${(props) => (props.check ? 'scaleY(1)' : 'scaleY(0)')} !important;
  border: ${(props) => !props.check && 'none'} !important;
  transition: all 0.3s;
  border-bottom: ${(props) => props.hasComplements && 'none'};
`;

export const TheadProducts = styled.thead`
  position: sticky;
  top: -1px;
  background-color: #ffffff;
  z-index: 1;
  box-shadow: inset 0px -1px 0px 0px #000000;
`;

export const CategoriesTable = styled(Table)`
  width: 40%;
  min-width: 400px;
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 0 20px;
  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  padding: 0 30px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #eee;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const StyledLink = styled(Link)`
  right: 0;
  margin-right: auto;
  padding-left: 10px;
  float: right;
  &:hover {
    text-decoration: none;
  }
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div``;

export const ReportHeader = styled.div`
  width: 90%;
  clear: both;
  margin: 0 auto;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

export const ReportHeaderChartDiv = styled.div`
  width: 100%;

  padding: 10px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;

  h5 {
    position: absolute;
  }
`;

export const HeaderCards = styled.div`
  width: 100%;

  display: grid;
  padding: 10px;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
`;

export const HeaderChart = styled.div`
  height: 300px;
  padding: 15px;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 7px;

  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  background: #fff;
`;

export const HeaderLegend = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 50px;
  float: right;
  right: 0;
  justify-content: center;
  text-align: center;
  display: flex;
  first-child {
    border-right: 1px solid #dee2e6;
  }
`;

export const HeaderChartFooter = styled.div`
  margin-bottom: 0;
  width: 100%;
  height: 50px;
  display: flex;
  div {
    width: 50%;
    text-align: center;
  }
`;

export const BoxOthers = styled.div`
  margin: 0 auto;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  width: 90%;
  flex-wrap: wrap;
`;

export const DateSelectorDiv = styled.div`
  width: 90%;
  height: 60px;
  display: flex;
  clear: both;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 20px;
  flex-direction: row;
`;

export const DateDiv = styled.div``;

export const Content = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-top: 20px;
  padding: 15px;
`;

export const DisplayNoneImprimir = styled.button`
  display: none;
`;

export const Print = styled.button`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-self: center;
  margin-right: 10px;
  border: none;
  background: #d60300;
  right: 0;
  margin-left: auto;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
    border-radius: 50%;
  }
`;

export const PrintDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Legends = styled.div`
  display: flex;
  width: 90%;
  align-items: center;
  text-align: center;
  justify-content: center;
  clear: both;
  position: relative;
`;

export const CategoryLegend = styled.div.attrs((props) => ({
  color: props.color,
}))`
  padding: 5px;
  display: flex;
  align-text: center;
  margin: auto 0;

  div {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    margin-right: 5px;
  }
`;

export const SubmitDate = styled.button`
  height: 30px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 15px;
  border: none;
  background: #17a2b8;
  margin-left: 10px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
  }
`;

export const SelectDateButton = styled.button`
  height: 40px;
  border-radius: 20px;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 15px;
  border: 1px solid transparent;
  background: #17a2b8;
  margin-left: 10px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
  }

  :selected {
    border: none;
  }
`;

export const ButtonsSelectDate = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    p {
      padding-left: 15px;
    }
  }
`;

export const DataPickerArea = styled.div`
  display: inline-block;
`;

export const DatePickerStyled = styled(DatePicker)`
  height: 40px;
  padding: 10px;

  margin-right: 15px;
  margin-left: 10px;

  border-radius: 20px;
  align-self: center;
  border: 1px solid ${(props) => props.theme.colors.lightGray};

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 20px;
  }
`;

export const ComplementRow = styled.tr`
  transform: ${(props) =>
    props.compCheck ? 'scaleY(1)' : 'scaleY(0)'} !important;

  td {
    padding: ${(props) => (props.compCheck ? '12px 7px' : '0px')} !important;
    transform: ${(props) =>
      props.compCheck ? 'scaleY(1)' : 'scaleY(0)'} !important;
    text-align: center;
    border: none;
    border-bottom: ${(props) =>
      props.lastComp && props.compCheck && '1px solid grey'};
    border: ${(props) => !props.compCheck && 'none'} !important;
    transition: all 0.3s;
  }
`;

export const CompNameCell = styled.td`
  &::after {
    content: '';
    position: absolute;
    top: -14px;
    left: 17px;
    height: 30px;
    width: 1px;
    background-color: #0ccab4;
  }
`;

export const RowSeparator = styled.tr`
  height: 5px;
  background-color: transparent;

  td {
    padding: 0px !important;
    border: none;
  }
`;
