import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  position: fixed;
  top: ${(props) => Math.max(70 - props.scroll, 5)}px;
  right: 10px;
  z-index: 1000;

  height: 90vh;
  width: 35%;
  max-width: 450px;
  min-width: 350px;

  background-color: white;
  box-shadow: -4px 4px 17px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
`;

export const ScrollableDiv = styled.div`
  width: 100%;
  height: calc(100% - 54px);
  overflow-y: scroll;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ff2c3a;
  width: 100%;
  height: 38px;
  border-radius: 10px 10px 0 0;
  padding: 0 10px;
  margin: 0;

  svg {
    cursor: pointer;
    font-weight: 700;
    font-size: 20px;
    line-height: 38px;
    color: #ffffff !important;
  }

  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 38px;
    color: #ffffff !important;
  }
`;

export const MainNews = styled.div`
  width: calc(100% - 40px);
  margin: 0 20px 15px;
  padding: 20px 0;
  border-bottom: 1px solid #d9d9d9;

  p,
  span {
    margin: 0;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #666666;
  }

  img {
    width: 100%;
    margin: 10px 0;
    border-radius: 5px;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: ${(props) => props.clickable && 'pointer'};
  position: relative;

  &:not(:last-child) {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid lightgrey;
  }

  p {
    width: 100%;
    text-align: justify;
  }

  img {
    width: fit-content;
    max-width: 100%;
    border-radius: 5;
    margin: 10px 0;
    object-fit: contain;
  }
`;

export const Recent = styled.div`
  width: calc(100% - 40px);
  margin: 0 20px;
  padding: 0 0 20px 0;
  display: flex;
  flex-direction: column;
`;

export const Old = styled.div`
  width: calc(100% - 40px);
  margin: 0 20px;
  padding: 0 0 20px 0;
  display: flex;
  flex-direction: column;
`;

export const CategoryTitle = styled.span`
  display: block;
  margin: 0;
  margin-bottom: 10px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
`;

export const News = styled.div`
  cursor: ${(props) => props.clickable && 'pointer'};
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  p {
    margin: 0;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
`;

export const Tag = styled.span`
  display: block;
  font-weight: 700;
  font-size: 10px;
  line-height: 18px;
  color: #666666;
  text-transform: uppercase;
  background-color: ${(props) => props.color};
  border-radius: 20px;
  padding: 0 10px;
  width: fit-content;
`;

export const DateSpan = styled.span`
  display: block;
  margin-left: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 2px;
  color: #6a6868;
`;

export const Content = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #666666 !important;
`;

export const Pending = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 9px;
  margin-left: 5px;
  background-color: #ffa814;
`;

export const Empty = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  align-items: center;
  justify-content: center;

  img {
    width: 200px;
    margin-bottom: 20px;
  }
  h5 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #666666;
  }
  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #000000;
  }
`;
