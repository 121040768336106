import { Form } from '@unform/web';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 20px;
`;

export const Header = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;
`;

export const Card = styled.div``;
export const StyledForm = styled(Form)``;
export const Head = styled.div``;
export const CheckboxInfo = styled.div`
  h3 {
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 10px;
  }
  span {
    font-weight: bold;
    margin-bottom: 5px;
  }
`;

export const NavDiv = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  clear: both;
`;

export const NavLinkSpan = styled.span`
  margin-right: 5px;
`;

export const NavDivTeste = styled.div`
  float: right;
`;

export const FiscalMessage = styled.div`
  background: #d3d3d3;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  height: 119px;
  padding: 10px;
  p {
    font-weight: bold;
  }
`;

export const InnerArea = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 729px;
  max-height: 720px;
 
  padding: 20px 20px 220px;

  @media (max-width: 1281px) {
    padding: 20px 20px 420px;
    max-height: 584px;
    height: 584px;
    margin-bottom: 420px;
  }

  @media (min-width: 1282px) and (max-width: 1450px) {
    padding: 20px 20px 420px;
    max-height: 620px;
    height: 620px;
  }


  ${(props) =>
    props.browserZoomLevel > 100 &&
    css`
      padding: 20px 20px 720px;
    `}
`;
export const AreaSwitcher = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    font-size: 14px;
    margin-left: 8px;
  }
`;
export const ButtonsArea = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100px;
  width: 92%;
  position: fixed;
  bottom: 0px;

  padding-top: 20px;

  border-top: 1px solid #c4c4c4;
  background: #fff;

  @media (max-width: 1300px) {
    bottom: 0px;
  }
`;
