import styled from 'styled-components';

export const Icon = styled.span`
  float: left;
  margin-left: 15px;
`;

export const ItemsCount = styled.span`
  display: flex;
  background: white;
  justify-content: center;
  align-items: center;
  height: 25px;
  min-width: 25px;
  border-radius: 50%;

  color: #3bd2c1;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const Content = styled.div`
  background-color: white;
  color: black;
  padding-top: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  border-radius: 0 0 12px 12px;
`;

export const LoadingDiv = styled.div`
  padding-top: 20px;
  justify-content: center;
  height: 60px;
  width: 100%;
  text-align: center;
`;


export const StyleKanbanWarning = styled.div`
display:flex,
flexDirection:column,
alignItems:center,
justifyContent:center,
height:100%,
fontWeight: 700,
fontSize: 14px, 
lineHeight: 21px,

`;
