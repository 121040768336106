import React from 'react';

import { Card, CardBody, CardFooter, CardTitle, Row, Col } from 'reactstrap';
import { FiMeh } from 'react-icons/fi';

export default function BadCard({ ratings }) {
  let reviewsCount = 0;
  let badReviewsCount = 0;
  let percentBadReview = 0;

  if (ratings?.bad_reviews) {
    ratings.bad_reviews.forEach((rating) => {
      reviewsCount += rating.count;
      badReviewsCount += rating.count;
    });
  }

  if (ratings?.good_reviews) {
    ratings.good_reviews.forEach((rating) => {
      reviewsCount += rating.count;
    });
  }

  if (reviewsCount > 0) {
    percentBadReview = ((badReviewsCount * 100) / reviewsCount).toFixed(0);
  }

  return (
    <>
      <Card className="card-stats">
        <CardBody>
          <Row>
            <Col xs="5">
              <div className="info-icon text-center icon-warning">
                <i className="tim-icons">
                  <FiMeh color="#fff" />
                </i>
              </div>
            </Col>
            <Col xs="7">
              <div className="numbers">
                <p className="card-category">Avaliações Negativas</p>
                <CardTitle tag="h3">
                  <span>{badReviewsCount}</span> {` / `}
                  <span>{percentBadReview}%</span>
                </CardTitle>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <hr />
          <div className="stats">
            {ratings?.bad_reviews && ratings?.bad_reviews.length > 0 ? (
              ratings?.bad_reviews.map((rating) => (
                <span>{`${rating.adjective} (${rating.count}) - `}</span>
              ))
            ) : (
              <span>-</span>
            )}
          </div>
        </CardFooter>
      </Card>
    </>

    // <Bloco>
    //   <Header>
    //     <SadIcon>
    //       <RiEmotionSadLine />
    //     </SadIcon>
    //     <Total>
    //       <span>Avaliações Negativas</span>
    //     </Total>
    //     <Info>
    //       <span>{badReviewsCount}</span>
    //       <span>{percentBadReview}%</span>
    //     </Info>
    //   </Header>

    //   <CardDescription>
    //     São aquelas que receberam 3 ou menos estrelas de seus clientes. Ao terem
    //     que selecionar os aspectos em que seu estabelecimento deve melhorar,
    //     esses foram os resultados:{' '}
    //   </CardDescription>

    //   <ReviewsTable borderless>
    //     <tbody>
    //       {ratings.bad_reviews ? (
    //         ratings.bad_reviews.map((rating) => (
    //           <tr>
    //             <td>{rating.adjective}</td>
    //             <td>{rating.count}</td>
    //           </tr>
    //         ))
    //       ) : (
    //         <></>
    //       )}
    //     </tbody>
    //   </ReviewsTable>
    // </Bloco>
  );
}
