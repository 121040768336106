import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { Modal, ModalBody } from 'reactstrap';
import { MdDelete, MdEdit } from 'react-icons/md';

import {
  Container,
  CategoriesTable,
  Header,
  TableArea,
  Content,
  TableContainer,
} from './styles';

import api from '~/services/api';
import RegisterButton from '../../components/Buttons/RegisterButton';
import StorePayment from './StorePayment';
import EditCustomMethods from './EditCustomMethods';
import EditMethods from './EditMethods';
import { useAuth } from '~/context/AuthContext';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { VideoPlayer } from '~/components/VideoPlayer';
import { Checkbox, ToggleButton } from 'ui-kit-takeat';
import { PageTitle } from '~/components/PageTitle';

function PaymentForm() {
  const { user, updateUser, paymentMethodsState } = useAuth();
  const [paymentData, setPaymentData] = useState(false);
  const [customPaymentData, setCustomPaymentData] = useState(false);
  const [paymentDataModal, setPaymentDataModal] = useState(false);
  const [modalPaymentMethods, setModalPaymentMethods] = useState(false);
  const [modalChangeCustomMethods, setModalChangeCustomMethods] =
    useState(false);
  const [modalEditMethods, setModalEditMethods] = useState(false);

  const [modalChangeCustomMethodsData, setModalChangeCustomMethodsData] =
    useState();

  const toggleModalPaymentMethods = () => {
    setModalPaymentMethods(!modalPaymentMethods);
  };

  const toggleModalChangeCustomMethods = () => {
    setModalChangeCustomMethods(!modalChangeCustomMethods);
  };

  const toggleModalEditMethods = () => {
    setModalEditMethods(!modalEditMethods);
  };

  function handleChangeModalEditPaymentMethod(method) {
    setPaymentDataModal(method);
    setModalEditMethods(!modalEditMethods);
  }

  function handleChangeModalEditCustomPaymentMethod(method) {
    setModalChangeCustomMethodsData(method);
    setModalChangeCustomMethods(!modalChangeCustomMethods);
  }

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'FinanceReceiptMethods'}`
      );

      const { can_read } = response.data.FinanceReceiptMethods;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  async function handleSetIsFavorite(data, keyword, id) {
    const paymetFiltered = paymentData.find((item) => item.id === id);

    paymetFiltered.is_favorite = data;

    await api.put(`/restaurants/payment-method-info/${keyword}`, {
      is_favorite: data,
    });

    const paymentsFiltered = paymentData.map((item) => {
      if (item.id === id) {
        return { ...item, is_favorite: data };
      }
      return item;
    });

    setPaymentData(paymentsFiltered);
  }

  async function handleSetIsFavoriteCustom(data, id, name) {
    await api.put(`/restaurants/custom-payment-methods/${id}`, {
      is_favorite: data,
      name,
    });

    const paymentsFiltered = customPaymentData.payment_methods.map((item) => {
      if (item.id === id) {
        return { ...item, is_favorite: data };
      }
      return item;
    });

    setCustomPaymentData({
      ...customPaymentData,
      payment_methods: paymentsFiltered,
    });
  }

  const getPaymentMethods = useCallback(async () => {
    try {
      const paymentsFiltered = paymentMethodsState.filter(
        (payment) => payment.keyword !== 'others'
      );

      setPaymentData(paymentsFiltered);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, []);

  const getCustomPaymentMethods = useCallback(async () => {
    try {
      const response = await api.get(`/restaurants/custom-payment-methods`);

      setCustomPaymentData(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, []);

  useEffect(() => {
    try {
      getPaymentMethods();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getPaymentMethods]);

  useEffect(() => {
    try {
      getCustomPaymentMethods();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getCustomPaymentMethods]);

  async function handleChangeMethod(value, idx) {
    try {
      idx = Number(idx);
      const { keyword } = paymentData[idx];

      await api.put(`/restaurants/payment-method-info/${keyword}`, {
        available: value,
      });

      paymentData[idx].available = value;
      setPaymentData([...paymentData]);

      const paymentActive = paymentData[idx].withdrawal_accepts || paymentData[idx].delivery_accepts


      if (keyword === 'prazo' && !paymentActive) {
        const updateProfile = {};
        updateProfile.credit_register_enabled = value;
        updateUser(updateProfile);

        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      }
    } catch (error) {
      toast.error(`Erro ao atualizar método! ${error.message}`);
    }
  }

  async function handleChangeMethodDelivery(value, idx, paymentMethod = null) {
    try {
      idx = Number(idx);
      const { keyword } = paymentData[idx];

      await api.put(`/restaurants/payment-method-info/${keyword}`, {
        delivery_accepts: value,
      });

      paymentData[idx].delivery_accepts = value;
      setPaymentData([...paymentData]);


      const paymentActive = paymentData[idx].withdrawal_accepts || paymentData[idx].available


      if (paymentMethod === "prazo" && !paymentActive) {
        const updateProfile = {};
        updateProfile.credit_register_enabled = value;
        updateUser(updateProfile);

        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      }


    } catch (error) {
      toast.error(`Erro ao atualizar método! ${error.message}`);
    }
  }

  async function handleChangeMethodWithdrawal(value, idx, paymentMethod = null) {
    try {
      idx = Number(idx);
      const { keyword } = paymentData[idx];

      await api.put(`/restaurants/payment-method-info/${keyword}`, {
        withdrawal_accepts: value,
      });

      paymentData[idx].withdrawal_accepts = value;
      setPaymentData([...paymentData]);


      const paymentActive = paymentData[idx].delivery_accepts || paymentData[idx].available


      if (paymentMethod === "prazo" && !paymentActive) {
        const updateProfile = {};
        updateProfile.credit_register_enabled = value;
        updateUser(updateProfile);

        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      }
    } catch (error) {
      toast.error(`Erro ao atualizar método! ${error.message}`);
    }
  }

  async function handleChangeCustomMethod(value, idx) {
    try {
      idx = Number(idx);
      const { name, id } = customPaymentData.payment_methods[idx];

      await api.put(`/restaurants/custom-payment-methods/${id}`, {
        name,
        available: value,
      });

      customPaymentData.payment_methods[idx].available = value;
      setCustomPaymentData({ ...customPaymentData });
    } catch (error) {
      toast.error(`Erro ao atualizar método! ${error.message}`);
    }
  }

  async function handleChangeCustomMethodDelivery(value, idx) {
    try {
      idx = Number(idx);
      const { name, id } = customPaymentData.payment_methods[idx];

      await api.put(`/restaurants/custom-payment-methods/${id}`, {
        name,
        delivery_accepts: value,
      });

      customPaymentData.payment_methods[idx].delivery_accepts = value;
      setCustomPaymentData({ ...customPaymentData });
    } catch (error) {
      toast.error(`Erro ao atualizar método! ${error.message}`);
    }
  }

  async function handleChangeCustomMethodWithdrawal(value, idx) {
    try {
      idx = Number(idx);
      const { name, id } = customPaymentData.payment_methods[idx];

      await api.put(`/restaurants/custom-payment-methods/${id}`, {
        name,
        withdrawal_accepts: value,
      });

      customPaymentData.payment_methods[idx].withdrawal_accepts = value;
      setCustomPaymentData({ ...customPaymentData });
    } catch (error) {
      toast.error(`Erro ao atualizar método! ${error.message}`);
    }
  }

  async function handleDeleteCustomMethod(id) {
    if (window.confirm('Realmente deseja deletar esse método?')) {
      try {
        await api.delete(`/restaurants/custom-payment-methods/${id}`);

        getCustomPaymentMethods();

        toast.success('Método deletado com sucesso');
      } catch (error) {
        toast.error('Erro ao deletar metodo');
      }
    }
  }

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div>
          <div style={{ display: 'flex' }}>
            <PageTitle>Métodos de recebimento</PageTitle>
            <VideoPlayer id={3} />
          </div>
          <p>
            Ative/ Desative ou crie seus próprios métodos de pagamento que serão
            aceitos seu estabelecimento
          </p>
        </div>
        <RegisterButton
          text="Criar métodos"
          onClick={toggleModalPaymentMethods}
        />
      </Header>

      <Content>
        <TableArea>
          <h5>Métodos Personalizados</h5>
          <TableContainer>
            <CategoriesTable borderless>
              <thead>
                <tr>
                  <th>Método</th>

                  <th>Conta bancária</th>
                  <th>Taxa (%) </th>
                  <th>Prazo (Dias)</th>
                  <th>Só dias úteis</th>
                  {!user.only_delivery && <th>PDV</th>}
                  {user.delivery_info?.is_delivery_allowed && <th>Delivery</th>}
                  {user.delivery_info?.is_withdrawal_allowed && <th>Retirada</th>}
                  <th>Favoritar</th>
                  <th>Açoes</th>
                </tr>
              </thead>
              <tbody>
                {customPaymentData &&
                  customPaymentData.payment_methods.length &&
                  customPaymentData.payment_methods.map((paymentMethod, id) => (
                    <tr key={paymentMethod.id}>
                      <td>{paymentMethod.name}</td>

                      <td>{paymentMethod.bank_account_name || 'sem conta'}</td>
                      <td>{`${paymentMethod.service_tax} %` || ''}</td>
                      <td>{paymentMethod.interval || ''}</td>
                      <td>{paymentMethod.include_weekends ? 'Não' : 'Sim'}</td>
                      {!user.only_delivery && (
                        <td>
                          <ToggleButton
                            margin="0 auto"
                            value={paymentMethod.available}
                            onClick={(e) =>
                              handleChangeCustomMethod(e, id)
                            }
                          />
                        </td>
                      )}

                      {user.delivery_info?.is_delivery_allowed && (
                        <td>
                          <ToggleButton
                            margin="0 auto"
                            value={paymentMethod.delivery_accepts}
                            onClick={(e) =>
                              handleChangeCustomMethodDelivery(e, id)
                            }
                          />
                        </td>
                      )}

                      {user.delivery_info?.is_withdrawal_allowed && (
                        <td>
                          <ToggleButton
                            margin="0 auto"
                            value={paymentMethod.withdrawal_accepts}
                            onClick={(e) =>
                              handleChangeCustomMethodWithdrawal(e, id)
                            }
                          />
                        </td>
                      )}

                      <td>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Checkbox
                            checked={paymentMethod.is_favorite}
                            onClick={(e) =>
                              handleSetIsFavoriteCustom(
                                !paymentMethod.is_favorite,
                                paymentMethod.id,
                                paymentMethod.name
                              )
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <MdEdit
                          size={20}
                          color="33333310"
                          title="Editar Método"
                          onClick={() => {
                            handleChangeModalEditCustomPaymentMethod(
                              paymentMethod
                            );
                          }}
                        />

                        <MdDelete
                          size={20}
                          color="ff0000"
                          title="Deletear Método"
                          onClick={() => {
                            handleDeleteCustomMethod(paymentMethod.id);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </CategoriesTable>
          </TableContainer>
        </TableArea>
        <TableArea>
          <h5>Métodos Pré-definidos</h5>
          <TableContainer>
            <CategoriesTable borderless>
              <thead>
                <tr>
                  <th>Método</th>
                  <th>Conta bancária</th>
                  <th>Taxa (%) </th>
                  <th>Prazo (Dias)</th>
                  <th>Só dias úteis</th>
                  {!user.only_delivery && <th>PDV</th>}
                  {user.delivery_info?.is_delivery_allowed && <th>Delivery</th>}
                  {user.delivery_info?.is_withdrawal_allowed && <th>Retirada</th>}
                  <th>Favoritar</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {paymentData &&
                  paymentData.length > 0 &&
                  paymentData.map(
                    (paymentMethod, id) =>
                      !paymentMethod.is_ifood && (
                        <tr
                          key={paymentMethod.id}

                        >
                          <td>
                            {paymentMethod.name}{' '}
                            {paymentMethod.keyword === 'prazo' && '(Fiado)'}
                          </td>

                          <td>
                            {paymentMethod.bank_account_name || 'sem conta'}
                          </td>
                          <td>{`${paymentMethod.service_tax} %` || ''}</td>
                          <td>{paymentMethod.interval || ''}</td>
                          <td>
                            {paymentMethod.include_weekends ? 'Não' : 'Sim'}
                          </td>
                          {!user.only_delivery && (
                            <td>
                              <ToggleButton
                                margin="0 auto"
                                value={paymentMethod.available}
                                onClick={(e) => handleChangeMethod(e, id)}
                              />
                            </td>
                          )}
                          {user.delivery_info?.is_delivery_allowed && (
                            <td>
                              <ToggleButton
                                margin="0 auto"
                                value={paymentMethod.delivery_accepts}
                                onClick={(e) =>
                                  handleChangeMethodDelivery(e, id, paymentMethod.keyword)
                                }
                              />
                            </td>
                          )}

                          {user.delivery_info?.is_withdrawal_allowed && (
                            <td>
                              <ToggleButton
                                margin="0 auto"
                                value={paymentMethod.withdrawal_accepts}
                                onClick={(e) =>
                                  handleChangeMethodWithdrawal(e, id, paymentMethod.keyword)
                                }
                              />
                            </td>
                          )}

                          <td>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <Checkbox
                                checked={paymentMethod.is_favorite}
                                onClick={() =>
                                  handleSetIsFavorite(
                                    !paymentMethod.is_favorite,
                                    paymentMethod.keyword,
                                    paymentMethod.id
                                  )
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <MdEdit
                              size={20}
                              color="33333310"
                              title="Editar Método"
                              onClick={() => {
                                handleChangeModalEditPaymentMethod(
                                  paymentMethod
                                );
                              }}
                            />
                          </td>
                        </tr>
                      )
                  )}
              </tbody>
            </CategoriesTable>
          </TableContainer>
        </TableArea>
        <Modal
          isOpen={modalEditMethods}
          toggle={toggleModalEditMethods}
          size="lg"
        >
          <ModalBody>
            <EditMethods
              toggle={toggleModalEditMethods}
              getCustomPaymentMethods={getPaymentMethods}
              paymentMethod={paymentDataModal}
            />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalChangeCustomMethods}
          toggle={toggleModalChangeCustomMethods}
          size="lg"
        >
          <ModalBody>
            <EditCustomMethods
              toggle={toggleModalChangeCustomMethods}
              getCustomPaymentMethods={getCustomPaymentMethods}
              paymentMethod={modalChangeCustomMethodsData}
            />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalPaymentMethods}
          toggle={toggleModalPaymentMethods}
          size="lg"
        >
          <ModalBody>
            <StorePayment
              toggle={toggleModalPaymentMethods}
              getCustomPaymentMethods={getCustomPaymentMethods}
            />
          </ModalBody>
        </Modal>
      </Content>
    </Container>
  );
}

export default PaymentForm;