import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import getValidationErrors from '~/services/getValidationErrors';

import api from '~/services/api';
import { Button, DefaultInput, DefaultSelect } from 'ui-kit-takeat';
import { FormContainer } from './styles';

function CreateBalance({ toggle, getBalances }) {
  const formRef = useRef(null);

  const [name, setName] = useState(null);
  const [portName,setPortName] = useState(null);
  const [bitRate,setBitRate] = useState(7);
  const [baudRate,setBaudRate] = useState(4800);

  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit() {
    setIsLoading(true);
    const data = { port_name: portName, bit_rate: bitRate, baud_rate: baudRate, name };
    
    if(!name || name === ''){
      toast.error('Preencha o nome');
      setIsLoading(false);
      return;
    }

    if(!portName || portName === ''){
      toast.error('Preencha a porta');
      setIsLoading(false);
      return;
    }

    try {
      await api.post(`/restaurants/balances`, {
        port_name: data.port_name,
        bit_rate: data.bit_rate,
        baud_rate: data.baud_rate,
        name: data.name
      });

      toast.success('Balança criada com sucesso!');

      toggle();
      getBalances();
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Falha ao criar balança');
    }
    setIsLoading(false);
  }

  return (
    <Form onSubmit={handleSubmit} ref={formRef}>
      <FormContainer>
        <DefaultInput 
          title="Nome" 
          name="name"
          type="text" 
          onChange={e => setName(e.target.value)}
        />

        <DefaultInput 
          title="Porta" 
          name="port_name"
          type="text" 
          onChange={e => setPortName(e.target.value)}
        />

        <DefaultSelect
          label="Baud Rate"
          name="baud_rate"
          options={[
            { value: 1200, label: 1200 },
            { value: 2400, label: 2400 },
            { value: 3600, label: 3600 },
            { value: 4800, label: 4800 },
            { value: 9600, label: 9600 },
          ]}
          defaultValue={{ value: 4800, label: 4800 }}
          onChange={e => setBaudRate(e.value)}
        />

        <DefaultSelect
          label="Bit Rate"
          name="bit_rate"
          options={[
            { value: 7, label: 7 },
            { value: 8, label: 8 },
          ]}
          defaultValue={{ value: 7, label: 7 }}
          onChange={e => setBitRate(e.value)}
        />

        <Button 
          type="submit" 
          title="Salvar" 
          style={{ width: '83px', marginLeft: 'auto' }}
          disabled={isLoading}
        />
      </FormContainer>
    </Form>
  );
}

export default CreateBalance;
