import React from "react";
import { Body, Container, Title, OrderInfo, TableArea } from "./styles"
import { useBalanceContext } from '~/pages/BalancePage';
import formatDateYYYY from '~/services/formatDateYYYY';
import formatTime from '~/services/formatTime';
import formatValue from '~/utils/formatValue';
export const PrintBillsOrderOperation = React.forwardRef(({ products, session }, ref) => {

  const {
    user,
    command,
    userMenu
  } = useBalanceContext();
  return (
    <Container ref={ref}>
      <Body>
        <Title>{user.fantasy_name}</Title>

        <OrderInfo>
          <span className="header">COMANDA {command.label || command.table_number}</span>
          <span className="header">Nº{session?.id || "00000"}</span>
        </OrderInfo>

        <OrderInfo>
          <span>{session !== undefined ? formatDateYYYY(session?.start_time) : null}</span>
          <span>{session !== undefined ? formatTime(session?.start_time) : null}</span>
        </OrderInfo>

        <div style={{ width: '110mm', padding: "0px 24px 0px 24px" }}>

          <OrderInfo className="borderArea">
            <span>{session?.client?.name || "Cliente"}</span>
            <span>{session?.client?.phone}</span>
          </OrderInfo>
        </div>


        <OrderInfo>
          <span className="info">PEDIDO</span>
          <span className="info">VALOR</span>
        </OrderInfo>

        {products?.filter((product) => product?.use_weight)
          .map((product) =>
          (
            <>
              <OrderInfo>
                <span>{product.name}</span>
                <span>R$/Kg: {product.price.replace(".", ',')}</span>
              </OrderInfo>

              <OrderInfo>
                <span>Peso: {product.weight}kg</span>
                <span className="info">TOTAL: {formatValue(product.total_service_price)}</span>
              </OrderInfo>
            </>
          )
          )}

        <TableArea>
          <thead>
            <tr>
              <th> PRODUTO</th>
              <th>QTD.</th>
              <th>VALOR</th>
            </tr>
          </thead>
          <tbody>
            {userMenu.length > 0 && userMenu
              .filter((cat) => cat.name === "Bebidas")
              .map((category) => (
                <>
                  {/* <tr key={category.name}>
                                <th>{category.name}</th>
                                <th colSpan="2"> </th>
                                </tr> */}
                  {category.products.map((product) => (
                    <tr key={product.id}>
                      <td>{product.name}</td>
                      <td></td>
                      <td></td>
                    </tr>
                  ))}
                </>
              ))}
            <tr>
              <td></td>
              <td></td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colSpan="3"> TOTAL:</th>
            </tr>
          </tfoot>
        </TableArea>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <strong>Desenvolvido por Takeat</strong>
        </div>
      </Body>
    </Container>
  )
})