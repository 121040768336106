import styled from 'styled-components';

export const ButtonContainer = styled.button`
  text-align: center;
  display: flex;
  align-items: center;

  margin: 10px 0;
  background-color: transparent;
  color: #33333370;
  height: 36px;

  text-decoration: underline;

  font-weight: bold;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  transition: background 0.2s;
  right: 0;

  &:hover {
    color: #333;
  }

  svg {
    margin-right: 6px;
  }

  span {
    color: #33333390;
  }
`;
