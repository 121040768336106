import React from 'react';

import { Spinner } from 'reactstrap';
import { Content, ItemsCount, LoadingDiv } from '../styles';
import { Header, Bloco} from './styles';
import TableDeliveryButton from '~/components/Operation/TablesOperation/TableDeliveryButton';
import OrderNewDelivery from '../../Items/OrderNewDelivery';

export default function CardOrdersSchedulingNew({
  baskets,
  handlePrintItem,
  handleCancelItem,
  loading,
  setItemIfoodId,
  handleUpdate,
  countOrders
}) {
  return (
    <Bloco>
      <Header>
        NOVOS AGENDAMENTOS
        <ItemsCount style={{ color: '#fe1933' }}>{countOrders}</ItemsCount>
      </Header>
      <Content style={{ borderBottomLeftRadius: 12 }}>
        <TableDeliveryButton margin={'5px 5px 0px 5px'} isScheduling={true} />
        {loading ? (
          <LoadingDiv>
            <Spinner color="danger" />
          </LoadingDiv>
        ) : (
          baskets?.length >= 1 &&
          baskets
            ?.filter((order) => order.scheduled_to)
            .map((item) => (
              <OrderNewDelivery
                key={item.basket.id}
                handleCancelItem={handleCancelItem}
                handlePrintItem={handlePrintItem}
                item={item}
                setItemIfoodId={setItemIfoodId}
                handleUpdate={handleUpdate}
              />
            ))
        )}
      </Content>
    </Bloco>
  );
}