/* eslint-disable no-shadow */
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';

import { toast } from 'react-hot-toast';
// import PDF, { Text, AddPage, Line, Image, Table, Html } from 'jspdf-react';
import { Button as ButtonUi } from "ui-kit-takeat"
import {
  format,
  startOfDay,
  endOfDay,
  endOfWeek,
  subHours,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  addHours,
} from 'date-fns';
import { Form } from '@unform/web';
import { MdArrowDropDown } from 'react-icons/md';
import {
  Row,
  Col,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Container,
} from 'reactstrap';
import Input from '~/components/Form/Input';

import {
  Wrapper,
  DateSelectorDiv,
  DateDiv,
  SubmitDate,
  Card,
  Content,
  SelectDateButton,
} from './styles';

import TaskRow from '~/components/Rows/TaskRow';
import Select from '~/components/Form/SelectInput';

import api from '~/services/api';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { FaPlus } from 'react-icons/fa';

export default function Tasks() {
  const formRef = useRef(null);

  const defaultInicial = useMemo(() => {
    return format(
      startOfWeek(subHours(new Date(), 3)),
      "yyyy-MM-dd'T'HH:mm:ss"
    );
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfWeek(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const inicialFormat = format(new Date(defaultInicial), 'dd/MM/yyyy, HH:mm');
  const finalFormat = format(new Date(defaultFinal), 'dd/MM/yyyy, HH:mm');
  const phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [hourPhrase, setHourPhrase] = useState(phrase);

  function setToday() {
    setInicialDate(
      format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setWeek() {
    setInicialDate(
      format(startOfWeek(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );
    setFinalDate(
      format(endOfWeek(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfWeek(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfWeek(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setMonth() {
    setInicialDate(
      format(startOfMonth(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );
    setFinalDate(
      format(endOfMonth(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfMonth(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfMonth(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  const [initialPre, setInitialPre] = useState(inicialDate);
  const [finalPre, setFinalPre] = useState(finalDate);

  function handleChangeDate() {
    setInicialDate(format(new Date(initialPre), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(new Date(finalPre), "yyyy-MM-dd'T'HH:mm:ss"));
    const inicialFormat = format(new Date(initialPre), 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(new Date(finalPre), 'dd/MM/yyyy, HH:mm');
    const phrase = `De ${inicialFormat} até ${finalFormat}`;

    setHourPhrase(phrase);
  }

  const [tasks, setTasks] = useState([]);
  const [tasksFiltered, setTasksFiltered] = useState([]);
  const [filter, setFilter] = useState('pending');

  const getTasks = useCallback(async () => {
    try {
      const response = await api.get('restaurants/tasks', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });

      setTasks(response.data);
      let filtered;

      if (filter === 'pending') {
        filtered = response.data.filter((task) => task.check === false);
      } else if (filter === 'completed') {
        filtered = response.data.filter((task) => task.check === true);
      } else if (filter === 'all') {
        filtered = response.data;
      }
      setTasksFiltered(filtered);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [inicialDate, finalDate]);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [modal, setModal] = useState(false);
  const toggleRegister = () => setModal(!modal);

  function handleFilter(value) {
    setFilter(value);
    let filter;
    if (value === 'pending') {
      filter = tasks.filter((task) => task.check === false);
    } else if (value === 'completed') {
      filter = tasks.filter((task) => task.check === true);
    } else if (value === 'all') {
      filter = tasks;
    }
    setTasksFiltered(filter);
  }

  const handleRegister = useCallback(async (data) => {
    const { due_date, title } = data;
    data.due_date = addHours(new Date(data.due_date), 12);

    if (due_date === '' || title === '') {
      toast.error('Preencha o Título e a Data de Lembrete');
    } else {
      try {
        await api.post('/restaurants/tasks', data);
        getTasks();
        setModal(false);
        toast.success("Cadastrada com sucesso")
      } catch (err) {
        toast.error('Erro ao cadastrar Tarefa.');
      }
    }
  }, []);

  // uservalidate
  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'Tasks'}`
      );

      const { can_read } = response.data.Tasks;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  useEffect(() => {
    getTasks();
  }, [getTasks]);

  const selectOptions = [
    { value: 'pending', label: 'Pendentes' },
    { value: 'completed', label: 'Completas' },
    { value: 'all', label: 'Todas' },
  ];

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Wrapper>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <ButtonUi title='Cadastrar tarefa' onClick={toggleRegister} icon={<FaPlus />} style={{ marginBottom: 16 }} />
      </div>

      <Card>
        <Content>
          <Row style={{ marginBottom: 15 }}>
            <Col md="4">
              <p>{hourPhrase}</p>
              <Form>
                <Select
                  // label="Unidade"
                  name="filterSelect"
                  id="filterSelect"
                  options={selectOptions}
                  defaultValue={{ label: 'Pendentes', value: 'pending' }}
                  onChange={(e) => handleFilter(e.value)}
                />
              </Form>
            </Col>
            <Col md="8">
              <div style={{ float: 'right' }}>
                <SelectDateButton
                  className="btn-round mr-auto"
                  onClick={setToday}
                  color="info"
                >
                  Hoje
                </SelectDateButton>
                <SelectDateButton
                  className="btn-round mr-auto"
                  onClick={setWeek}
                  color="info"
                >
                  Essa semana
                </SelectDateButton>
                <SelectDateButton
                  className="btn-round mr-auto"
                  onClick={setMonth}
                  color="info"
                >
                  Esse mês
                </SelectDateButton>
                <SelectDateButton
                  className="btn-round mr-auto"
                  onClick={toggle}
                  color="info"
                >
                  Selecionar Período
                  <MdArrowDropDown color="white" size={20} />
                </SelectDateButton>
              </div>
              <div style={{ float: 'right', marginRight: '15px' }}>
                <Collapse isOpen={isOpen}>
                  <Form onSubmit={handleChangeDate} ref={formRef}>
                    <DateSelectorDiv>
                      <DateDiv>
                        <Input
                          id="datetime-local"
                          label="Data Inicial"
                          type="datetime-local"
                          name="initialDate"
                          onChange={(e) => setInitialPre(e.target.value)}
                          defaultValue={defaultInicial}
                          className="data"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </DateDiv>
                      <DateDiv>
                        <Input
                          id="datetime-local"
                          label="Data Final"
                          type="datetime-local"
                          name="finalDate"
                          onChange={(e) => setFinalPre(e.target.value)}
                          defaultValue={defaultFinal}
                          className="data"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </DateDiv>
                      <div>
                        <SubmitDate onClick="submit">Filtrar</SubmitDate>
                      </div>
                    </DateSelectorDiv>
                  </Form>
                </Collapse>
              </div>
            </Col>
          </Row>
        </Content>
        <Table>
          <tbody>
            {tasksFiltered.map((task) => (
              <TaskRow
                key={task.id}
                task={task}
                getTasks={getTasks}
                inicialDate={inicialDate}
                finalDate={finalDate}
                filter={filter}
                handleFilter={handleFilter}
              />
            ))}
          </tbody>
        </Table>
      </Card>

      <Modal isOpen={modal} toggle={toggleRegister}>
        <ModalHeader>Cadastrar Tarefa</ModalHeader>
        <Form onSubmit={handleRegister}>
          <ModalBody>
            <Container>
              <Row>
                <Col md="7">
                  <Input label="Título" name="title" />
                </Col>
                <Col md="5">
                  <Input
                    id="date-local"
                    label="Data de Lembrete"
                    type="date"
                    name="due_date"
                    defaultValue={format(new Date(), 'yyyy-MM-dd')}
                    className="data"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Input label="Descrição" name="description" />
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <ButtonUi inverted onClick={toggleRegister} title='Fechar' type='button' />
            <ButtonUi type="submit" title='Cadastrar' />
          </ModalFooter>
        </Form>
      </Modal>
    </Wrapper>
  );
}
