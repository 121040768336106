import React, { useState, useRef, useEffect } from 'react';
import { useField } from '@unform/core';

import { MdInsertPhoto } from 'react-icons/md';
import { toast } from 'react-hot-toast';

import { Container, DefaultAvatar } from './styles';
import api from '~/services/api';

export default function AvatarInput({ name, title, ...rest }) {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue } = useField(name);

  const [fileId, setFileId] = useState(defaultValue && defaultValue.id);
  const [preview, setPreview] = useState(defaultValue && defaultValue.url);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'dataset.fileid',
      clearValue() {
        ref.value = '';
        setPreview(null);
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (defaultValue) {
      setPreview(defaultValue.url);
      setFileId(defaultValue.id);
    }
  }, [defaultValue]);

  async function handleChange(e) {
    try {
      const data = new FormData();

      data.append('file', e.target.files[0]);

      if (fileId) {
        await api.delete(`restaurants/files/${defaultValue.id}`);
      }

      const response = await api.post('restaurants/files/original', data);
      const { id, url } = response.data;

      setFileId(id);
      setPreview(url);
    } catch (err) {
      toast.error(err.response.data.error);
    }
  }

  return (
    <Container>
      <label htmlFor="avatar">
        {preview ? (
          <img src={preview} alt="User Avatar" title={title} />
        ) : (
          <DefaultAvatar title={title}>
            <MdInsertPhoto size={45} color="#ddd" />
            <span>Adicionar Foto</span>
          </DefaultAvatar>
        )}

        <input
          type="file"
          id="avatar"
          accept="image/*"
          data-fileid={fileId}
          onChange={handleChange}
          ref={ref}
          {...rest}
        />
      </label>
    </Container>
  );
}
