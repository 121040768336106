import styled from 'styled-components';

export const Group = styled.div`
  display: flex;
  flex-direction: ${(props) => props.horizontal ? "row" : "column"};
  gap: 10px;
`;

export const Container = styled.div`
  display: flex;
  // align-items: center;
  gap: 10px;
  cursor: pointer;

  span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #6c6c6c;
  }
`;

export const Button = styled.div`
  box-sizing: border-box;
  width: 19px;
  height: 19px;
  border-radius: 15px;
  border: 2px solid #2ec9b7;
`;

export const Fill = styled.div`
  box-sizing: border-box;
  width: 13px;
  height: 13px;
  border-radius: 13px;
  background-color: #2ec9b7;
  margin: 1px;
  transition: all 0.3s;
  opacity: ${(props) => (props.selected ? 1 : 0)};
`;

