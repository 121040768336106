import React from 'react';


import {
  Container,
  Restaurant,
  Footer,
  TotalRow,
  MotoboysTable,
  DetailHeader,
} from './styles';
import { format } from 'date-fns';


const PrintMotoboysDetails = React.forwardRef(
  (
    {
      item,
      restaurant,
      motoboy,
      dates
    },
    ref
  ) => {
    const motoboyFiltered = item?.find(item => item.id === motoboy.id)

    return (
      <Container ref={ref}>
        <React.Fragment key={item?.id}>
          <Restaurant style={{ borderBottom: "none" }}>
            <strong>{restaurant?.fantasy_name}</strong>
          </Restaurant>
          <Restaurant>
            <strong>RELATÓRIO MOTOBOY</strong>
          </Restaurant>

          <TotalRow>
            <span>Período:</span>
            <span>{format(new Date(dates?.initialDate), 'dd/MM/yy')} a {format(new Date(dates?.endDate), 'dd/MM/yy')}</span>
          </TotalRow>
          <TotalRow>
            <span>Nome:</span>
            <span>{motoboyFiltered?.name}</span>
          </TotalRow>
          <TotalRow>
            <span>Telefone:</span>
            <span>{motoboyFiltered?.phone}</span>
          </TotalRow>
          <MotoboysTable>
            {motoboyFiltered !== null ? (
              motoboyFiltered?.sessions?.length <= 0 ? (
                <thead>
                  <tr>
                    <DetailHeader colSpan={4}>
                      Não houveram entregas desse motoboy nesse período
                    </DetailHeader>
                  </tr>
                </thead>
              ) : (
                <>
                  <thead>
                    <tr>
                      <th>DATA</th>
                      <th>BAIRRO</th>
                      <th>TAXA</th>
                      {motoboyFiltered?.is_ifood_on_demand && <th>ENTREGA FÁCIL</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {motoboyFiltered?.sessions
                      ?.sort((a, b) => b.created_at?.localeCompare(a.created_at))
                      .map((session) => {
                        const date = session.created_at
                          ? format(new Date(session.created_at), 'HH:mm - dd/MM/yy')
                          : session.createdAt
                            ? format(new Date(session.createdAt), 'HH:mm - dd/MM/yy')
                            : ' - ';
                        return (
                          <tr key={session.id}>
                            <td>{date}</td>
                            <td>{session?.neighborhood}</td>
                            <td>R${session?.delivery_tax_price}</td>
                            {motoboyFiltered?.is_ifood_on_demand && session?.ifood_on_demand_value && <td>R${session.ifood_on_demand_value}</td>}
                          </tr>
                        );
                      })}
                  </tbody>
                </>
              )
            ) : (
              <thead>
                <tr>
                  <DetailHeader colSpan={4}>
                    Clique em um motoboy para ver seus detalhes
                  </DetailHeader>
                </tr>
              </thead>
            )}
          </MotoboysTable>

          <TotalRow style={{ borderTop: "1px solid #858585" }}>

            <span>TOTAL ARRECADADO </span>

            <span>
              R$
              {motoboyFiltered?.sessions
                ?.reduce(
                  (total, s) => total + parseFloat(s.delivery_tax_price),
                  0
                )
                .toFixed(2)}
            </span>
          </TotalRow>

          {motoboyFiltered?.is_ifood_on_demand && 
            <TotalRow>
              <span>TOTAL TAXA ENTREGA FÁCIL IFOOD </span>

              <span>
                R$
                {motoboyFiltered?.sessions
                  ?.reduce(
                    (total, s) => total + parseFloat(s.ifood_on_demand_value || 0),
                    0
                  )
                  .toFixed(2)}
              </span>
            </TotalRow>
          }

          <Footer>
            <span>Desenvolvido por Takeat!</span>
          </Footer>
        </React.Fragment>

      </Container>
    );
  }
);

export default PrintMotoboysDetails;
