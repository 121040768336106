import styled from 'styled-components';
import ScrollContainer from 'react-indiana-drag-scroll';

export const Container = styled.div``;

export const Header = styled.div`
  display: block;
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  padding: 16px 16px 8px;
`;

export const InputArea = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  padding: 0 16px;
  align-items:end;
`;

export const CategoryArea = styled(ScrollContainer)`
  display: flex;
  flex-wrap: nowrap;
  flex-shrink: 0;
  padding: 16px 16px 8px;
  gap: 8px;
  cursor: grab;
  align-content: center;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const CategoryName = styled.div`
  background-color: #f6f6f6;
  border-radius: 10px;
  color: #545454;
  width: fit-content;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  padding: 6px 16px;
  align-content: center;
  white-space: nowrap;
  border: ${({ selectedCategory }) =>
    selectedCategory ? '1px solid #C8131B' : '1px solid transparent'};
`;

export const ButtonAction = styled.div`
  position: relative;
  flex-shrink: 0;
  height: 66px;
  border-top: 1px solid #ededed;
`;

export const ButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -32px;
  left: 50%;
  transform: translateX(-50%);

  span {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
  }
`;

export const ButtonGoToCart = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(200, 19, 27, 1);
  border-radius: 50%;
  position: relative;
  cursor: pointer;

  img {
    width: 35px;
    height: 45px;
  }
`;

export const CountBadge = styled.div`
  position: absolute;
  top: 0;
  left: 45px;
  font-size: 12px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans- 'se' rif;
  background: #fff;
  width: 22px;
  height: 22px;
  color: #c8131b;
  border: 1px solid #c8131b;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
