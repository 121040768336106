import styled from 'styled-components';

export const StyleFill = styled.div`
  height: 4px;
  width: 22%;
  background-color: #ff403d;
  @media (max-width: 480px) {
    width: 15%;
  }
`;

export const StyleVoid = styled.div`
  height: 4px;
  width: 22%;
  background-color: #c0c4c2;
  @media (max-width: 480px) {
    width: 15%;
  }
`;

export const StyleAnimation = styled.div`
  height: 4px;
  width: 25%;
  @media (max-width: 480px) {
    width: 15%;
  }
  display: inline-block;
  background-size: 200% 100%;
  background-image: linear-gradient(to left, #ff403d 50%, #c0c4c2 50%);
  -webkit-animation: progressbar 1s ease infinite !important;
  -moz-animation: progressbar 1s ease infinite !important;
  -o-animation: progressbar 1s ease infinite !important;
  animation: progressbar 1s ease infinite !important;

  @keyframes progressbar {
    0% {
      opacity: 1;
      background-position: 0 0;
    }
    100% {
      opacity: 1;
      background-position: -100% 0;
    }
  }
`;
