import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { exportComponentAsJPEG } from 'react-component-export-image';
import {
  FaBookOpen,
  FaBars,
  FaFileInvoiceDollar,
  FaPiggyBank,
} from 'react-icons/fa';
import { FaRegCircleXmark } from 'react-icons/fa6';
import formatDateYYYY from '~/services/formatDateYYYY';

import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { toast } from 'react-hot-toast';

import {
  Wrapper,
  Content,
  ContentOperation,
  Footer,
  SaveButton,
  FooterItem,
  PaymentAdvice,
} from './styles';
import api from '~/services/api';
import { useAuth } from '~/context/AuthContext';
import { useTables } from '~/context/TablesContext';
import ImgToPrint from '~/components/ImgToPrint';
import { FiHelpCircle } from 'react-icons/fi';
import { NewHeader } from '~/components/NewHeader';
import { NewNewSidebar } from '~/components/NewNewSidebar';
import { DeliveryNavBar } from '~/components/DeliveryNavBar';
import { Button } from 'ui-kit-takeat';
import { menuItems } from '~/utils/menuItems';

function AdminLayout({ children }) {
  const [isModalQrcodeOpened, setIsModalQrcodeOpened] = useState(false);
  const { tableMenu } = useTables();

  const {
    user,
    toastUpdateSystemMessage4,
    setToastUpdateSystemMessage4,
    websocketManager,
    updateUser,
    setData,
  } = useAuth();

  const imgRef = useRef(null);

  const toggleModalQrcode = () => {
    setIsModalQrcodeOpened(!isModalQrcodeOpened);
  };

  const page = window.location.pathname;

  const firstPath = page.split('/')[1];
  const secondPath = page.split('/')[2];
  const history = useHistory();

  const [toastMessage, setToastMessage] = useState(
    toastUpdateSystemMessage4.toString() === 'true'
  );

  function toggleToast() {
    setToastUpdateSystemMessage4(false);

    setToastMessage(false);
  }

  function handleOpenInvoices() {
    try {
      history.push('/financial/invoices');
    } catch (error) {
      console.log(error);
      toast.error('Erro ao abrir financeiro');
    }
  }

  useEffect(() => {
    if (websocketManager) {
      websocketManager.addMessageCallback(4, (data) => {
        if (data.type === 'enable-menu') {
          const { value } = data;

          updateUser({
            enable_menu: value === 'true',
          });
        }
      });
    }
  }, [websocketManager, updateUser]);

  const [pendingPaymentNotificationOpen, setPendingPaymentNotificationOpen] =
    useState(false);

  const handleNotifyPayment = async () => {
    try {
      const response = await api.put('/restaurants/notify-payment');
      console.log('response ', response.data);
    } catch (err) {
      console.log(err);
      toast.error('Erro ao notificar pagamento');
    }
  };

  const updateDataWithNotifyPayment = async () => {
    try {
      const response = await api.put('/restaurants/notify-payment');
      console.log('response ', response.data);
      setData((state) => {
        return {
          ...state,
          user: {
            ...state.user,
            temporary_active: response.data.temporary_active,
            suspension_alert: response.data.suspension_alert,
            is_active: response.data.is_active,
          },
        };
      });
    } catch (err) {
      console.log(err);
      toast.error('Erro ao notificar pagamento');
    }
  };

  const openNotifyPaymentModal = () => {
    setPendingPaymentNotificationOpen(true);
    if (!user.temporary_active) {
      handleNotifyPayment();
    }
  };

  const hideSideBar = [
    '/operation',
    '/operation/',
    '/balcony',
    '/balcony/',
    '/balance',
    '/balance/',
  ];

  const hideFooter = ['/balance', '/balance/'];

  return hideSideBar.includes(page) ? (
    <>
      <Modal
        isOpen={pendingPaymentNotificationOpen}
        toggle={() => setPendingPaymentNotificationOpen(false)}
        onClosed={() => {
          if (!user.temporary_active) {
            updateDataWithNotifyPayment();
          }
          setPendingPaymentNotificationOpen(false);
        }}
        size="md"
      >
        <ModalBody
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 24,
            padding: 16,
          }}
        >
          {user.temporary_active ? (
            <>
              <p
                style={{
                  color: '#4D4D4C',
                  textAlign: 'center',
                  fontFamily: 'Poppins',
                  fontSize: 18,
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: 'normal',
                  alignSelf: 'flex-start',
                }}
              >
                Você já informou ter realizado o pagamento
              </p>
              <p
                style={{
                  color: '#4D4D4C',
                  textAlign: 'justify',
                  fontFamily: 'Poppins',
                  fontSize: 16,
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: 'normal',
                  marginBottom: 0,
                  alignSelf: 'flex-start',
                }}
              >
                Assim que o pagamento for confirmado, sua conta será
                desbloqueada automaticamente. A equipe de suporte não é mais
                responsável pelo desbloqueio do seu sistema
              </p>
              <p
                style={{
                  color: '#4D4D4C',
                  textAlign: 'justify',
                  fontFamily: 'Poppins',
                  fontSize: 16,
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: 'normal',
                  marginBottom: 0,
                  alignSelf: 'flex-start',
                }}
              >
                Esse processo pode levar até 3 dias úteis.
              </p>
            </>
          ) : (
            <>
              <p
                style={{
                  color: '#4D4D4C',
                  textAlign: 'center',
                  fontFamily: 'Poppins',
                  fontSize: 18,
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: 'normal',
                  alignSelf: 'flex-start',
                }}
              >
                Sistema liberado!
              </p>
              <p
                style={{
                  color: '#4D4D4C',
                  textAlign: 'justify',
                  fontFamily: 'Poppins',
                  fontSize: 16,
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: 'normal',
                  marginBottom: 0,
                  alignSelf: 'flex-start',
                }}
              >
                Seu acesso foi temporariamente liberado enquanto verificamos o
                pagamento. Se não for identificado dentro de 3 dias úteis, o
                sistema automaticamente bloqueará sua conta novamente.
              </p>
            </>
          )}
          <Button
            type="button"
            buttonColor="#2EC9B7"
            title="Confirmar"
            onClick={() => {
              if (!user.temporary_active) {
                updateDataWithNotifyPayment();
              }
              setPendingPaymentNotificationOpen(false);
            }}
          />
        </ModalBody>
      </Modal>
      {user.suspension_alert && user.is_active && (
        <PaymentAdvice>
          <FaRegCircleXmark size={32} color="#FF2C3A" />
          {user.suspension_date ? (
            <p>
              Sua conta será bloqueada no dia{' '}
              <b>{formatDateYYYY(new Date(user.suspension_date))}</b> Renove seu
              pagamento e evite o bloqueio do sistema.
            </p>
          ) : (
            <p>
              Sua conta está prestes a ser suspensa. Renove seu pagamento e
              evite o bloqueio do sistema
            </p>
          )}
          <Button
            type="button"
            title="Abrir faturas"
            textStyle={{ fontWeight: 700, whiteSpace: 'nowrap', fontSize: 14 }}
            onClick={handleOpenInvoices}
          />
        </PaymentAdvice>
      )}
      {!user.is_active && (
        <PaymentAdvice style={{ backgroundColor: '#F7C3CA' }}>
          <FaRegCircleXmark size={32} color="#FF2C3A" />
          <p>
            Conta <b>bloqueada</b> por falta de pagamento.
          </p>
          <Button
            type="button"
            title="Abrir faturas"
            textStyle={{ fontWeight: 700, whiteSpace: 'nowrap', fontSize: 14 }}
            onClick={handleOpenInvoices}
          />
          <Button
            type="button"
            title="Já efetuei o pagamento"
            inverted={true}
            textStyle={{ fontWeight: 700, whiteSpace: 'nowrap', fontSize: 14 }}
            onClick={openNotifyPaymentModal}
          />
        </PaymentAdvice>
      )}
      <ContentOperation>{children}</ContentOperation>
      <Modal isOpen={isModalQrcodeOpened} toggle={toggleModalQrcode}>
        <ModalHeader>Cardápio Digital</ModalHeader>
        <ModalBody>
          <p>
            Agora você pode compartilhar seu cardápio em suas redes sociais!
            Basta salvar o QrCode ou copiar o link e compartilhar à vontade.
            Aproveite!
          </p>

          <p>
            Link:{' '}
            {tableMenu && tableMenu?.table_code?.restaurant_url}
          </p>
          {tableMenu && (
            <>
              <ImgToPrint
                ref={imgRef}
                imgSrc={tableMenu?.table_code?.url}
              />

              <SaveButton
                type="button"
                onClick={() => exportComponentAsJPEG(imgRef)}
              >
                Baixar Imagem
              </SaveButton>
            </>
          )}
        </ModalBody>
      </Modal>

      {!user?.is_pdv && !hideFooter.includes(page) && (
        <Footer>
          <FooterItem
            className="botao-menu"
            onClick={() => history.push('/search')}
          >
            <FaBars />
            <span>Menu</span>
          </FooterItem>
          <FooterItem onClick={() => history.push('/products')}>
            <FaBookOpen />
            <span>Cardápio</span>
          </FooterItem>
          <FooterItem onClick={() => history.push('/operation/bills')}>
            <FaFileInvoiceDollar />
            <span>Comandas</span>
          </FooterItem>
          {user.has_nfce && (
            <FooterItem
              onClick={() => history.push('/operation/fiscal/issued')}
            >
              <FaPiggyBank />
              <span>Notas Emitidas</span>
            </FooterItem>
          )}
          <FooterItem onClick={() => history.push('/help')}>
            <FiHelpCircle />
            <span>Ajuda</span>
          </FooterItem>
        </Footer>
      )}
    </>
  ) : (
    <>
      <Modal
        isOpen={pendingPaymentNotificationOpen}
        toggle={() => setPendingPaymentNotificationOpen(false)}
        onClosed={() => {
          if (!user.temporary_active) {
            updateDataWithNotifyPayment();
          }
          setPendingPaymentNotificationOpen(false);
        }}
        size="md"
      >
        <ModalBody
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 24,
            padding: 16,
          }}
        >
          {user.temporary_active ? (
            <>
              <p
                style={{
                  color: '#4D4D4C',
                  textAlign: 'center',
                  fontFamily: 'Poppins',
                  fontSize: 18,
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: 'normal',
                  alignSelf: 'flex-start',
                }}
              >
                Você já informou ter realizado o pagamento
              </p>
              <p
                style={{
                  color: '#4D4D4C',
                  textAlign: 'justify',
                  fontFamily: 'Poppins',
                  fontSize: 16,
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: 'normal',
                  marginBottom: 0,
                  alignSelf: 'flex-start',
                }}
              >
                Assim que o pagamento for confirmado, sua conta será
                desbloqueada automaticamente. A equipe de suporte não é mais
                responsável pelo desbloqueio do seu sistema
              </p>
              <p
                style={{
                  color: '#4D4D4C',
                  textAlign: 'justify',
                  fontFamily: 'Poppins',
                  fontSize: 16,
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: 'normal',
                  marginBottom: 0,
                  alignSelf: 'flex-start',
                }}
              >
                Esse processo pode levar até 3 dias úteis.
              </p>
            </>
          ) : (
            <>
              <p
                style={{
                  color: '#4D4D4C',
                  textAlign: 'center',
                  fontFamily: 'Poppins',
                  fontSize: 18,
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: 'normal',
                  alignSelf: 'flex-start',
                }}
              >
                Sistema liberado!
              </p>
              <p
                style={{
                  color: '#4D4D4C',
                  textAlign: 'justify',
                  fontFamily: 'Poppins',
                  fontSize: 16,
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: 'normal',
                  marginBottom: 0,
                  alignSelf: 'flex-start',
                }}
              >
                Seu acesso foi temporariamente liberado enquanto verificamos o
                pagamento. Se não for identificado dentro de 3 dias úteis, o
                sistema automaticamente bloqueará sua conta novamente.
              </p>
            </>
          )}
          <Button
            type="button"
            buttonColor="#2EC9B7"
            title="Confirmar"
            onClick={() => {
              if (!user.temporary_active) {
                updateDataWithNotifyPayment();
              }
              setPendingPaymentNotificationOpen(false);
            }}
          />
        </ModalBody>
      </Modal>
      {user.suspension_alert && user.is_active && (
        <PaymentAdvice>
          <FaRegCircleXmark size={32} color="#FF2C3A" />
          {user.suspension_date ? (
            <p>
              Sua conta será bloqueada no dia{' '}
              <b>{formatDateYYYY(new Date(user.suspension_date))}</b> Renove seu
              pagamento e evite o bloqueio do sistema.
            </p>
          ) : (
            <p>
              Sua conta está prestes a ser suspensa. Renove seu pagamento e
              evite o bloqueio do sistema
            </p>
          )}
          <Button
            type="button"
            title="Abrir faturas"
            textStyle={{ fontWeight: 700, whiteSpace: 'nowrap', fontSize: 14 }}
            onClick={handleOpenInvoices}
          />
        </PaymentAdvice>
      )}
      {!user.is_active && (
        <PaymentAdvice style={{ backgroundColor: '#F7C3CA' }}>
          <FaRegCircleXmark size={32} color="#FF2C3A" />
          <p>
            Conta <b>bloqueada</b> por falta de pagamento.
          </p>
          <Button
            type="button"
            title="Abrir faturas"
            textStyle={{ fontWeight: 700, whiteSpace: 'nowrap', fontSize: 14 }}
            onClick={handleOpenInvoices}
          />
          <Button
            type="button"
            title="Já efetuei o pagamento"
            inverted={true}
            textStyle={{ fontWeight: 700, whiteSpace: 'nowrap', fontSize: 14 }}
            onClick={openNotifyPaymentModal}
          />
        </PaymentAdvice>
      )}
      <Wrapper>
        {!user?.is_pdv &&
          user.enable_menu &&
          page !== '/kds' &&
          page !== '/kitchen' && (
            <>
              {/* <NewSideBar /> */}
              <NewNewSidebar
                menuItems={menuItems}
                user={user}
                color="#FF2C3A"
              />
              {/* <SideBar
                collapsed={collapsed}
                toggleCollapse={toggleCollapse}
                style={{ position: 'relative' }}
              /> */}
              {/* <div
                style={{
                  position: 'fixed',
                  top: 300,
                  left: 150,
                  width: '415px',
                  height: 157,
                  backgroundColor: '#fff',
                  borderRadius: 7,
                  padding: 20,
                  filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.2))',
                  display: toastMessage ? 'flex' : 'none',
                  zIndex: 2000000,
                }}
              >
                <div style={{}}>
                  <p style={{ fonteWeight: 'bold', fontSize: 18 }}>
                    <FaExclamationTriangle color="darkOrange" />{' '}
                    <strong>
                      Agora o estoque atualizará
                      <br />{' '}
                      <span style={{ color: 'red' }}> 1 vez por dia</span>{' '}
                      sempre às <span style={{ color: 'red' }}>03h00!</span>
                    </strong>
                  </p>
                  <span>
                    Caso precise atualizar manualmente, basta clicar no botâo{' '}
                    <strong>“Atualizar” </strong>na página “Estoque e Insumos”.
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <button
                    type="button"
                    style={{
                      background: '#3BD2C1',
                      color: '#fff',
                      width: 54,
                      height: 48,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      borderRadius: 7,
                      marginLeft: 10,
                      fontWeight: 'bold',
                      border: 'none',
                    }}
                    onClick={(e) => toggleToast(false)}
                  >
                    OK
                  </button>
                </div>
              </div> */}
            </>
          )}

        <Content
          style={{
            flexGrow: 1,
          }}
        >
          <NewHeader />
          {firstPath === 'delivery' && secondPath !== 'orders' && (
            <div style={{ padding: '20px ' }}>
              <DeliveryNavBar />
            </div>
          )}
          {/* <Navbar collapsed={collapsed} toggleCollapse={toggleCollapse} /> */}
          {children}
        </Content>
      </Wrapper>
    </>
  );
}

export default AdminLayout;

AdminLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
