import React from 'react';
import { FaTrash } from 'react-icons/fa';
import { ActionsDiv, DeletePaymentForm, Row } from './styles';
import formatValue from '~/utils/formatValue';
import { useAuth } from '~/context/AuthContext';
import posIcon from '~/assets/img/pos-icon.svg';
import { Spinner } from 'reactstrap';
import { BsCheckLg } from 'react-icons/bs';

export const PaymentRow = ({
  payment,
  method,
  deletePayments,
  stoneTransactions,
  allPaymentMethods,
  handleStoneTransactions,
  loadingPos,
  toggleCancelPos,
}) => {
  const { user } = useAuth();
  const excludeIds = [238];

  return (
    <Row>
      <td style={{ color: excludeIds.includes(method.id) && '#2ec9b7' }}>
        {method.label}
      </td>
      <td>{formatValue(payment.payment_value)}</td>
      <td style={{ textAlign: 'right' }}>
        <ActionsDiv>
          {user.has_stone_pdv &&
            ['CREDIT', 'DEBIT', 'credit', 'debit'].includes(
              allPaymentMethods.find(
                (pay) => pay.id === payment.payment_method_id
              )?.method || false
            ) &&
            parseFloat(payment.payment_value) > 0 && (
              <td style={{ textAlign: 'center' }}>
                {!stoneTransactions.find(
                  (trans) =>
                    trans.payment_balcony_id === payment.id &&
                    trans.status !== 'canceled'
                ) && (
                  <div>
                    <DeletePaymentForm
                      to="#"
                      onClick={() =>
                        !loadingPos &&
                        handleStoneTransactions({
                          payment_value: payment.payment_value,
                          payment_balcony_id: payment.id,
                          method: allPaymentMethods.find(
                            (pay) => pay.id === payment.payment_method_id
                          )?.method,
                        })
                      }
                      style={loadingPos ? { pointerEvents: 'none' } : {}}
                    >
                      <img src={posIcon} alt='' style={{ height: 18 }} />
                    </DeletePaymentForm>
                  </div>
                )}

                {stoneTransactions.find(
                  (trans) =>
                    trans.payment_balcony_id === payment.id &&
                    trans.status === 'pending'
                ) && (
                  <div>
                    <DeletePaymentForm
                      to="#"
                      // onClick={() => toggleCancelPos(payment.id)}
                      disabled={loadingPos}
                    >
                      <Spinner
                        size="sm"
                        color="#3BD2C1"
                        style={{
                          width: 15,
                          height: 15,
                          marginBottom: 4,
                          color: '#3BD2C1',
                          cursor: 'default',
                        }}
                      />
                    </DeletePaymentForm>
                  </div>
                )}

                {stoneTransactions.find(
                  (trans) =>
                    trans.payment_balcony_id === payment.id &&
                    trans.paid_at !== null
                ) && (
                  <div>
                    <DeletePaymentForm
                      to="#"
                      // onClick={() => handleStoneTransactions(payment.id)}
                      disabled={loadingPos}
                    >
                      <BsCheckLg
                        style={{
                          width: 20,
                          height: 20,
                          color: '#3BD2C1',
                        }}
                      />
                    </DeletePaymentForm>
                  </div>
                )}
              </td>
            )}
          {!excludeIds.includes(method.id) &&
            !stoneTransactions.find(
              (trans) =>
                trans.payment_balcony_id === payment.id &&
                trans.paid_at !== null
            ) && (
              <FaTrash size={15} onClick={() => deletePayments(payment.id)} />
            )}
        </ActionsDiv>
      </td>
    </Row>
  );
};
