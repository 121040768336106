import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 40px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom: 1px solid black;
  }

  & + div {
    border-top: 1px solid #33333310;
  }
`;
