import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Modal } from 'reactstrap';
import styled from 'styled-components';

export const PageContainer = styled.div`
  padding: 20px;
  height: calc(100vh - 100px);
  display: flex;
`;

export const BalconyCard = styled.div`
  background-color: white;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  box-shadow: 1px 0px 5px 1px rgba(0, 0, 0, 0.1);
  display: flex;
`;

export const MenuArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: auto;
  gap: 10px;
  width: 65%;

  h3 {
    margin: 0;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    color: #4d4d4c;
  }
`;

export const PaymentArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-left: 1px solid #ccc;
  width: 35%;
`;

export const ModalErrorNF = styled(Modal)`
  .modal-content {
    padding: 20px;
  }

  h2 {
    color: #4d4d4c;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
  }

  p {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
`;

export const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #4d4d4c;

  span {
    color: #4d4d4c;
  }

  &:visited {
    color: #4d4d4c;
  }
`;

export const ModalCashier = styled(Modal)`
  max-width: 80%;

  .modal-content {
    max-height: 95vh;
    padding: 20px;
  }

  h2 {
    color: #4d4d4c;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
  }
`;
