import React from 'react';
import { FaTrash } from 'react-icons/fa';

function PaymentMethod({ method, handleDeleteMethod }) {
  return (
    <tr>
      <td>{method?.name}</td>
      <td>
        <FaTrash
          color="#ff403d"
          title="Deletar método"
          style={{ cursor: 'pointer', marginLeft: 8 }}
          onClick={() => {
            handleDeleteMethod(method.id);
          }}
        />
      </td>
    </tr>
  );
}

export default PaymentMethod;