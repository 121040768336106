import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const PrintNFCe = React.forwardRef(({ html }, ref) => {
  return (
    <div style={{ float: 'left', marginLeft: -5 }} ref={ref}>
      {ReactHtmlParser(html)}
    </div>
  );
});

export default PrintNFCe;
