import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-hot-toast';

import { MdDelete } from 'react-icons/md';
import { Row } from 'reactstrap';

import { Table, Delete } from './styles';

import api from '~/services/api';

function Accesses() {
  const [userRoles, setUserRoles] = useState([]);

  const getUserRoles = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/user-roles');

      const roles = response.data.filter((item) => {
        return !item.is_protected;
      });

      setUserRoles(roles);
    } catch (err) {
      toast.error('Erro ao consultar os acessos');
    }
  }, []);

  useEffect(() => {
    getUserRoles();
  }, [getUserRoles]);

  const deleteRole = useCallback(
    async (id) => {
      try {
        await api.put('/restaurants/user-roles/delete', {
          role_id: id,
        });

        const roles = userRoles.filter((item) => item.id !== id);

        setUserRoles(roles);

        toast.success('Acesso apagado!');
      } catch (err) {
        toast.error('Erro ao apagar o acesso!');
      }
    },
    [userRoles]
  );

  return (
    <>
      <h3 style={{ marginLeft: '20px' }}> Gerenciar tipos de usuários </h3>

      <Row>
        <div
          style={{
            height: '50vh',
            display: 'inline-block',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <Table borderless>
            <thead>
              <tr>
                <th>Nível de acesso</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {userRoles.map((role) => (
                <tr key={role.id}>
                  <td> {role.name} </td>
                  <td>
                    <div>
                      <Delete onClick={() => deleteRole(role.id)}>
                        <MdDelete size={20} />
                      </Delete>
                    </div>
                  </td>
                </tr>
              ))}

              {userRoles.length === 0 && (
                <div style={{ display: 'flex' }}>
                  <div style={{ margin: 'auto', color: '#776' }}>
                    Nenhum acesso encontrado
                  </div>
                </div>
              )}
            </tbody>
          </Table>
        </div>
      </Row>
    </>
  );
}

export default Accesses;
