import React from 'react';

import { Container } from './styles';

function PermissionErrorContainer() {
  return (
    <Container>
      <p>
        Seu usuário atual não permite acesso a esse menu. Para mais informações,
        contate o administrador do sistema.
      </p>
    </Container>
  );
}

export default PermissionErrorContainer;
