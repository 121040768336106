import styled from 'styled-components';

export const Container = styled.div`
  @page {
    size: 75mm 200mm;
    margin: 0;
  }
  @media print {
    * {
      background: transparent;
      color: #000;
      text-shadow: none;
      filter: none;
      -ms-filter: none;
      font-family: Arial, Helvetica, sans-serif;
      padding-left: 10px;
    }
    html,
    body {
      width: 75mm;
      height: 200mm;
    }
    h1 {
      margin-right: auto;
    }
    img {
      width: 70mm;
      height: auto;
    }
  }
`;

export const Table = styled.div`
  padding-top: 4px;
  width: 110mm;
  text-align: center;
  font-size: 50px;
  margin-right: auto;

  strong {
    font-size: 26px;
  }
`;

export const Bill = styled.div`
  padding-top: 5px;
  width: 110mm;
  text-align: center;
  font-size: 25px;
  margin-right: auto;
`;

export const Restaurant = styled.p`
  text-transform: capitalize;
  padding-top: 10px;
  width: 110mm;
  text-align: center;
  font-size: 18px;
  margin-right: auto;
  padding-bottom: 15px;
  border-bottom: solid #858585 1px;
`;

export const Body = styled.div`
  width: 110mm;
  clear: both;
  display: block;
`;

export const Phone = styled.div`
  text-align: left;
  padding-left: 5px;
  font-size: 25px;
  padding-top: 8px;

  float: left;
`;

export const Price = styled.div`
  text-align: right;
  padding-right: 15px;
  font-size: 22px;
  padding-top: 10px;
  float: right;
`;

export const Items = styled.div`
  width: 110mm;
  padding-left: 10px;
 
`;

export const Item = styled.div`
  padding-left: 10px;
  padding-right: 15px;
`;

export const NamePrice = styled.div`
  width: 100%;
  display: flex;
`;

export const ItemName = styled.div`
  font-size: 22px;
  width: 65%;
`;

export const PriceItem = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 25px;
  width: 35%;
  float: right;
`;

export const ComplementsTitle = styled.div`
  font-size: 20px;
`;

export const OrderTime = styled.div`
  width: 100%;
  font-size: 20px;
  padding-left: 10px;
  padding-top: 20px;
  align-self: center;
  text-align: center;
`;

export const Complements = styled.div`
  font-size: 20px;
  padding-left: 15px;
  width: 75%;
`;

export const PriceComplement = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 20px;
  width: 25%;
  float: right;
`;

export const Obs = styled.div`
  padding-top: 5px;
  font-size: 20px;
`;

export const Footer = styled.div`
  margin-top: 20px;
  padding-top: 10px;
 
  font-size: 12px;
  text-align: center;
  width: 110mm;
  padding-bottom: 30px;
`;

export const AlreadyClosed = styled.div`
  margin-top: 10px;
  border-top: solid black 1px;
  padding-bottom: 5px;
  font-size: 25px;
  text-align: center;
  width: 110mm;
`;

export const TotalDiv = styled.div`
  padding-top: 5px;
  margin-top: 20px;
  border-top: solid black 1px;
  padding-bottom: 5px;
  font-size: 25px;
  text-align: center;
  width: 110mm;
  padding-bottom: 15px;
`;

export const TotalRow = styled.div`
 
  padding: 0 10px;
  width: 110mm;
 display: flex;
 justify-content: space-between;
 font-size:16px
`;

export const TotalName = styled.div`
  text-align: left;
  padding-left: 5px;
  font-size: 16px;
  padding-top: 5px;
  float: left;
`;

export const TotalPrice = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 16px;
  padding-top: 5px;
  float: right;
`;

export const TotalName10 = styled.div`
  text-align: left;
  padding-left: 5px;
  font-size: 16px;
  padding-top: 5px;
  float: left;
`;

export const TotalPrice10 = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 20px;
  padding-top: 5px;
  float: right;
`;

export const ItemsArea = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 15px;

  & + div {
    margin-top: 26px;
  }

  h4 {
    margin-left: 8px;
  }
`;

export const TotalServices = styled.div`
  display: flex;
  width: 110mm;
  justify-content: space-between;

  strong {
    font-size: 26px;
    padding-right: 15px;
  }

  h4 {
    margin-left: 8px;
  }
`;

export const TotalRowPayments = styled.div`
  display: flex;
  width: 110mm;
  flex-direction: column;
  justify-content: center;
  margin-top: 8px;
  padding-left: 12px;

  div {
    display: flex;
    justify-content: space-between;
  }
`;

export const DetailHeader = styled.th`
  font-size: 22px;
  text-align: center !important;
`;

export const MotoboysTable = styled.table`
  width: 110mm; 
  padding: 0 10px;
 border-collapse: initial;
  border-spacing: 0 20px;
  background: ${(props) => props.theme.colors.white};


  thead {
    background-color: #000;
    color: #fff;
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      color: #fff;
      th:last-child {
        text-align: right;
      }
      th{
        color: #fff;
        font-size: 14px;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #eee;
      padding-bottom: 15px;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;
