import { Form } from '@unform/web';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { MdDateRange } from 'react-icons/md';
import { toast } from 'react-hot-toast';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import ReactInputMask from 'react-input-mask';
import moment from 'moment';
import CheckboxInput from '~/components/Form/Checkbox';
import Input from '~/components/Form/Input';
import api from '~/services/api';
import getValidationErrors from '~/services/getValidationErrors';
import {
  ButtonAdd,
  ButtonsArea,
  DataPickerArea,
  DatePickerStyled,
  InnerArea,
  NumberFormatStyled,
  PageTitle,
} from '../styles';
import Select from '~/components/Form/SelectInput';
import SaveButton from '~/components/Buttons/SaveButton';

import DefaulButton from '~/components/Buttons/DefaultButton';

import formatdate from '../../../../services/formatDateYYYY';
import { FaPlus } from 'react-icons/fa';
import PersonalButton from '~/components/Buttons/PersonalButton';
import { FiX } from 'react-icons/fi';

// import { Container } from './styles';

function Edit({
  transaction,
  getTransactions,
  toggleModalEditTransaction,
  banks,
  categories,
  freqOptions,
  suppliers,
  getSuppliers,
  getPaymentMethods,
  paymentMethods,
  getCategories,
  setIsPaid,
  getBanks,
}) {
  const [paidCheck, setPaidCheck] = useState(transaction.paid);

  const [dueDate, setDuedate] = useState();
  const [competenceDate, setCompetenceDate] = useState();
  const [paidAtDate, setpaidAtDate] = useState();
  const [valueState, setValueState] = useState(transaction.value);
  const [isModalErrorOpened, setIsModalErrorOpened] = useState(false);

  const transactionFormRef = useRef(null);

  function handleSetPaidCheck() {
    setPaidCheck(!paidCheck);

    setpaidAtDate(new Date());
  }

  const toggleModalError = useCallback(() => {
    setIsModalErrorOpened(!isModalErrorOpened);
  }, [isModalErrorOpened]);
  const supplierFiltered = suppliers.filter(
    (supplier) => supplier.value === transaction?.provider?.id
  );

  const selectSuppler = supplierFiltered
    ? {
        value: supplierFiltered[0]?.value,
        label: supplierFiltered[0]?.label,
      }
    : null;

  const paymmentFiltered = paymentMethods.filter(
    (item) => item.value === transaction?.cash_flow_payment_method?.id
  );

  const selectPayment = paymmentFiltered
    ? {
        value: paymmentFiltered[0]?.value,
        label: paymmentFiltered[0]?.label,
      }
    : null;

  const banksFiltered = banks.filter(
    (item) => item.value === transaction?.bank_account?.id
  );

  const selectBank = banksFiltered[0]
    ? {
        value: banksFiltered[0].value,
        label: banksFiltered[0].label,
      }
    : null;

  const categoryFiltered = categories?.filter(
    (item) => item.value === transaction?.cash_flow_category_subcategory_id
  );

  const selectCategory = categoryFiltered
    ? {
        value: categoryFiltered.length > 0 ? categoryFiltered[0].value : '',
        label: transaction.text,
      }
    : '';

  const initialData = {
    description: transaction.description,
    information: transaction.information,

    competence_date: transaction.competence_date,
    paid: transaction.paid,
    paid_at: transaction.paid_At,
    provider_id: selectSuppler,
    bank_account_id: selectBank,
    cash_flow_payment_method_id: selectPayment,
    cash_flow_category_subcategory_id: selectCategory,
    repit: !!transaction.freq_type,
    freq_type: transaction.freq_type,
    freq_value: transaction.freq_value,
  };

  async function handleSubmit(data) {
    try {
      if (!valueState) {
        toggleModalError();
      } else {
        const readyValue = valueState.floatValue
          ? (valueState.floatValue / 100).toFixed(2)
          : valueState;

        if (transactionFormRef?.current) {
          transactionFormRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          // cash_flow_category_subcategory_id: Yup.string().required(
          //   'Essa categoria foi excluída, selecione outra por favor'
          // ),
          bank_account_id: Yup.string().required('Conta obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.put(`/restaurants/cash-flows/${transaction.id}`, {
          value: readyValue,
          description: data.description,
          information: data.information,
          due_date: dueDate,
          competence_date: competenceDate,
          paid: data.paid,
          paid_at: paidAtDate,
          provider_id: data.provider_id || null,
          bank_account_id: data.bank_account_id,
          cash_flow_payment_method_id: data.cash_flow_payment_method_id || null,
          cash_flow_category_subcategory_id:
            data?.cash_flow_category_subcategory_id || null,
          freq_type: data.freq_type,
          freq_value: data.freq_value,
        });

        toast.success('Movimentação editada com sucesso!');
        setIsPaid(data.paid);
        getTransactions();
        toggleModalEditTransaction();
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (transactionFormRef?.current) {
          transactionFormRef.current.setErrors(errors);
        }
      } else {
        toast.error(`Erro ao cadastrar Movimentação!`);
        console.log(error);
      }
    }
  }

  function convertData(data) {
    const dataFormt = moment(data, 'DD/MM/YYYY');

    return dataFormt.format('DD-MM-YYYY');
  }

  function currencyFormatter(value) {
    if (!Number(value)) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  }

  function handleValueChange(e) {
    setValueState(e);
  }

  const dueDateText = transaction.due_date
    ? formatdate(transaction.due_date)
    : '__/__/____';
  const paidText = transaction.paid_at
    ? formatdate(transaction.paid_at)
    : '__/__/____';
  const competenceText = transaction.competence_date
    ? formatdate(transaction.competence_date)
    : '__/__/____';

  //create banks
  const [isModalCreateBankOpen, setIsModalCreateBankOpen] = useState(false);
  const formBankRef = useRef(null);

  const toggleModalCreateBank = useCallback(() => {
    setIsModalCreateBankOpen(!isModalCreateBankOpen);
  }, [isModalCreateBankOpen]);

  const handleSubmitBank = useCallback(
    async (data) => {
      try {
        if (formBankRef?.current) {
          formBankRef.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/restaurants/cash-flows/bank-accounts', {
          name: data.name,
        });

        toast.success('Conta cadastrada com sucesso!');

        getBanks();
        toggleModalCreateBank();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formBankRef?.current) {
            formBankRef.current.setErrors(errors);
          }
        } else {
          // console.tron.log(error);
          toast.error(`Erro ao cadastrar conta!`);
        }
      }
    },
    [getBanks, toggleModalCreateBank]
  );

  // create suplliers

  const [isModalCreateSuppliersOpen, setIsModalCreatesuppliersOpen] =
    useState(false);

  const formRefSupplier = useRef(null);
  const toggleModalCreateSuplliers = useCallback(() => {
    setIsModalCreatesuppliersOpen(!isModalCreateSuppliersOpen);
  }, [isModalCreateSuppliersOpen]);

  const handleSupplierSubmit = useCallback(
    async (data) => {
      try {
        if (formRefSupplier?.current) {
          formRefSupplier.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          cnpj: Yup.string()
            .required('CNPJ/ CPF obrigatório')
            .trim()
            .matches('^[0-9]+$', 'Apenas números, sem espaço'),
          phone: Yup.string().required('Telefone obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/restaurants/cash-flows/providers', {
          name: data.name,
          phone: data.phone,
          cnpj: data.cnpj,
        });

        toast.success('Fornecedor cadastrada com sucesso!');

        getSuppliers();
        toggleModalCreateSuplliers();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRefSupplier?.current) {
            formRefSupplier.current.setErrors(errors);
          }
        } else {
          toast.error(`Erro ao cadastrar fornecedor!`);
        }
      }
    },
    [getSuppliers, toggleModalCreateSuplliers]
  );

  //create cost center
  const [isModalCreateCategoryOpen, setIsModalCreateCategoryOpen] =
    useState(false);
  const [subCategories, setSubCategories] = useState(false);
  const cashFlowTypes = [
    { value: 'financeiro', label: 'Financeiro' },
    { value: 'operacional', label: 'Operacional' },
    { value: 'insumos', label: 'Insumos e Produtos' },
  ];

  const selecSubCategoriesOptions =
    subCategories.length > 0 &&
    subCategories?.map((sub) => {
      return {
        value: sub.id,
        label: sub.name,
      };
    });

  const formCategoryRef = useRef(null);
  const getSubCategories = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows/subcategories');

      setSubCategories(response.data);
    } catch (error) {
      toast.error('Erro ao baixar subcategorias');
    }
  }, []);

  const toggleModalCreateCategory = useCallback(() => {
    setIsModalCreateCategoryOpen(!isModalCreateCategoryOpen);
  }, [isModalCreateCategoryOpen]);

  const handleCategorySubmit = useCallback(
    async (data) => {
      try {
        if (formCategoryRef?.current) {
          formCategoryRef.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          type: Yup.string().required('Tipo obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/restaurants/cash-flows/categories', {
          name: data.name,
          subcategories: data.subcategories,
          type: data.type,
        });

        toast.success('Categoria cadastrada com sucesso!');

        getCategories();
        toggleModalCreateCategory();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formCategoryRef?.current) {
            formCategoryRef.current.setErrors(errors);
          }
        } else {
          toast.error(`Erro ao cadastrar catedoria!`);
        }
      }
    },
    [getCategories, toggleModalCreateCategory]
  );

  useEffect(() => {
    getSubCategories();
  }, [getSubCategories]);

  //create payment method
  const formPaymentRef = useRef(null);
  const [isModalCreateMethodOpen, setIsModalCreateMethodOpen] = useState(false);

  const toggleModalCreateMethod = useCallback(() => {
    setIsModalCreateMethodOpen(!isModalCreateMethodOpen);
  }, [isModalCreateMethodOpen]);

  const handleMethodSubmit = useCallback(
    async (data) => {
      try {
        if (formPaymentRef?.current) {
          formPaymentRef.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/restaurants/cash-flows/payment-methods', {
          name: data.name,
        });

        toast.success('Conta cadastrada com sucesso!');

        getPaymentMethods();
        toggleModalCreateMethod();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formPaymentRef?.current) {
            formPaymentRef.current.setErrors(errors);
          }
        } else {
          // console.tron.log(error);
          toast.error(`Erro ao cadastrar conta!`);
        }
      }
    },
    [getPaymentMethods, toggleModalCreateMethod]
  );

  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: 20,
          paddingBottom: 0,
        }}
      >
        <PageTitle>Editar Lançamento</PageTitle>
        <FiX
          onClick={toggleModalEditTransaction}
          color="#ff2c3a"
          size={21}
          cursor="pointer"
        />
      </div>

      <Modal isOpen={isModalCreateBankOpen} toggle={toggleModalCreateBank}>
        <ModalHeader> Criar Conta</ModalHeader>
        <Form onSubmit={handleSubmitBank} ref={formBankRef}>
          <ModalBody>
            <Input label="Nome do banco" name="name" />
          </ModalBody>
          <ModalFooter>
            <SaveButton type="submit" message="Salvar" />
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={isModalCreateSuppliersOpen}
        toggle={toggleModalCreateSuplliers}
      >
        <ModalHeader> Criar Fornecedor</ModalHeader>
        <Form onSubmit={handleSupplierSubmit} ref={formRefSupplier}>
          <ModalBody>
            <Input label="Nome do fornecedor" name="name" />
            <Input label="CNPJ" name="cnpj" />
            <ReactInputMask mask="(99) 99999-9999">
              <Input
                label="Telefone"
                name="phone"
                maskChar=""
                placeholder="(xx) xxxxx-xxxx"
              />
            </ReactInputMask>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={toggleModalCreateSuplliers}>
              Cancelar
            </Button>
            <Button color="success" type="submit">
              Salvar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={isModalCreateCategoryOpen}
        toggle={toggleModalCreateCategory}
      >
        <ModalHeader> Criar Categoria</ModalHeader>
        <Form onSubmit={handleCategorySubmit} ref={formCategoryRef}>
          <ModalBody>
            <Input label="Nome do categoria" name="name" />
            <Select
              label="Subcategorias"
              name="subcategories"
              isMulti
              options={selecSubCategoriesOptions}
            />
            <Select label="Tipo" name="type" options={cashFlowTypes} />
          </ModalBody>
          <ModalFooter>
            <SaveButton type="submit" message="Salvar" />
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={isModalCreateMethodOpen} toggle={toggleModalCreateMethod}>
        <ModalHeader> Criar métodos de pagamento</ModalHeader>
        <Form onSubmit={handleMethodSubmit} ref={formPaymentRef}>
          <ModalBody>
            <Input label="Nome do método" name="name" />
          </ModalBody>
          <ModalFooter>
            <SaveButton type="submit" message="Salvar" />
          </ModalFooter>
        </Form>
      </Modal>

      <InnerArea browserZoomLevel={browserZoomLevel}>
        <Form
          onSubmit={handleSubmit}
          ref={transactionFormRef}
          initialData={initialData}
        >
          <ModalBody>
            <Row>
              <Col md="4">
                <DataPickerArea>
                  <span>Valor (R$)</span>
                  <NumberFormatStyled
                    prefix="R$"
                    format={currencyFormatter}
                    defaultValue={valueState}
                    onValueChange={(e) => {
                      handleValueChange(e);
                    }}
                  />
                </DataPickerArea>
              </Col>
              <Col md="4">
                <DataPickerArea>
                  <span>
                    Vencimento <MdDateRange />
                  </span>

                  <DatePickerStyled
                    selected={dueDate}
                    onChange={(date) => setDuedate(date)}
                    locale="pt"
                    dateFormat="dd/MM/yyyy"
                    placeholderText={dueDateText}
                    customInput={
                      <ReactInputMask mask={'99/99/9999'} maskChar={null} />
                    }
                  />
                </DataPickerArea>
              </Col>
              <Col md="4">
                <DataPickerArea>
                  <>
                    <span>
                      Competência <MdDateRange />
                    </span>

                    <DatePickerStyled
                      selected={competenceDate}
                      onChange={(date) => setCompetenceDate(date)}
                      locale="pt"
                      dateFormat="dd/MM/yyyy"
                      placeholderText={competenceText}
                      customInput={
                        <ReactInputMask mask={'99/99/9999'} maskChar={null} />
                      }
                    />
                  </>
                </DataPickerArea>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <Row>
                  <Col md="10">
                    <Select
                      label="Conta"
                      name="bank_account_id"
                      placeholder="Selecione a conta"
                      options={banks}
                    />
                  </Col>
                  <Col md="2">
                    <ButtonAdd
                      type="button"
                      onClick={toggleModalCreateBank}
                      title="Cadastrar Conta Bancária"
                    >
                      <FaPlus />
                    </ButtonAdd>
                  </Col>
                </Row>
              </Col>
              <Col md="6">
                <Row>
                  <Col md="10">
                    <Select
                      label="Forma de pagamento"
                      name="cash_flow_payment_method_id"
                      placeholder="Selecione o pgmt"
                      options={paymentMethods}
                    />
                  </Col>
                  <Col md="2">
                    <ButtonAdd
                      type="button"
                      onClick={toggleModalCreateMethod}
                      title="Cadastrar forma de pagamento"
                    >
                      <FaPlus />
                    </ButtonAdd>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Input label="Descrição" name="description" />

            <Row>
              {transaction?.cash_flows.length === 0 && (
                <Col md="6">
                  <Row>
                    <Col md="10">
                      <Select
                        label="Centro de custo"
                        name="cash_flow_category_subcategory_id"
                        placeholder="Centro de custo"
                        options={categories}
                      />
                    </Col>

                    <Col md="2">
                      <ButtonAdd
                        type="button"
                        onClick={toggleModalCreateCategory}
                        title="Cadastrar centro de custo"
                      >
                        <FaPlus />
                      </ButtonAdd>
                    </Col>
                  </Row>
                </Col>
              )}
              <Col md="6">
                <Row>
                  <Col md="10">
                    <Select
                      label="Fornecedor"
                      name="provider_id"
                      placeholder="Informe o fornecedor"
                      options={suppliers}
                    />
                  </Col>
                  <Col md="2">
                    <ButtonAdd
                      type="button"
                      onClick={toggleModalCreateSuplliers}
                      title="Cadastrar fornecedor"
                    >
                      <FaPlus />
                    </ButtonAdd>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Input label="Linha digitável" name="information" />

            <CheckboxInput
              name="paid"
              label="Pago"
              onChange={handleSetPaidCheck}
            />

            <Modal isOpen={isModalErrorOpened} toggle={toggleModalError}>
              <ModalBody>
                Os campos Valor e Vencimento são obrigatórios
              </ModalBody>
              <ModalFooter>
                <DefaulButton
                  onClick={toggleModalError}
                  message="Ok, entendi"
                />
              </ModalFooter>
            </Modal>

            {paidCheck && (
              <Row>
                <Col lg="3">
                  <DataPickerArea>
                    <>
                      <span>
                        Pago em <MdDateRange />
                      </span>

                      <DatePickerStyled
                        selected={paidAtDate}
                        onChange={(date) => setpaidAtDate(date)}
                        locale="pt"
                        dateFormat="dd/MM/yyyy"
                        placeholderText={paidText}
                        customInput={
                          <ReactInputMask mask={'99/99/9999'} maskChar={null} />
                        }
                      />
                    </>
                  </DataPickerArea>
                </Col>
              </Row>
            )}

            {/* <CheckboxInput
            name="repit"
            label="Repetir"
            onChange={handleSetRepitCheck}
          />

          {repitCheck && (
            <Row>
              <Col>
                <Input label="Qtd repetições" name="freq_value" type="number" />
              </Col>
              <Col>
                <Select
                  label="Frequência"
                  name="freq_type"
                  options={freqOptions}
                />
              </Col>
            </Row>
          )} */}
          </ModalBody>
          <ButtonsArea>
            <PersonalButton
              type="submit"
              message="Salvar"
              title="Salvar"
              color="#2EC9B7"
            />
          </ButtonsArea>
        </Form>
      </InnerArea>
    </>
  );
}

export default Edit;
