import XLSX from 'xlsx-js-style';

import formatCompleteDate from '../formatCompleteDate';
import { sheetStyles } from '~/utils/sheetStyles';
import { getTableName } from '~/utils/getTableName';

function getDiscountsData(discounts) {
  const headers = [
    'Mesa',
    'Data/Hora',
    'Valor original',
    'Valor c/ desconto',
    'Desconto (R$)',
    '%',
    'Justificativa',
    'Usuário',
  ];

  const data = [];
  const total = ['Total', '-', 0, 0, 0, 0, '-', '-'];

  const discountsData = discounts.map((discount) => {
    const coupon = discount.coupon_transaction?.discount_coupon;
    const discountPercent = discount.discount_percent
      ? discount.discount_percent.replace('.', ',')
      : (
          (discount.discount_total / discount.old_total_price) *
          100
        ).toLocaleString('pt-BR', {
          maximumFractionDigits: 2,
        });

    const discountDate = discount.discount_at
      ? formatCompleteDate(discount.discount_at)
      : formatCompleteDate(discount.createdAt);

    total[2] += parseFloat(discount.old_total_price);
    total[3] += parseFloat(discount.total_price);
    total[4] += parseFloat(discount.discount_total);

    return [
      getTableName(discount.table),
      discountDate,
      parseFloat(discount.old_total_price),
      parseFloat(discount.total_price),
      parseFloat(discount.discount_total),
      discountPercent,
      coupon ? `Cupom(${coupon.name})` : discount.discount_obs,
      discount.users?.name || '-',
    ];
  });

  total[5] = ((total[4] / total[2]) * 100).toLocaleString('pt-BR', {
    maximumFractionDigits: 2,
  });

  data.push(headers);
  data.push(total);
  data.push(...discountsData);

  return data;
}

// /restaurants/motoboys/sessions
export function generateDiscountSheet(discounts) {
  const rows = getDiscountsData(discounts);
  const num_cols = 8;

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(rows);

  // Every column with 15 chars of width
  worksheet['!cols'] = new Array(num_cols);
  worksheet['!cols'].fill({ wch: 16 });

  worksheet['!rows'] = new Array(rows.length + 1);
  worksheet['!rows'].fill({ hpx: 18 });

  sheetStyles(rows, num_cols, worksheet, 1);

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório Descontos');

  return XLSX.write(workbook, { type: 'buffer' });
}
