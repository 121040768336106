import { Modal } from 'reactstrap';
import styled from 'styled-components';

export const ModalStyled = styled(Modal)`
  .modal-content {
    border-radius: 8px;
  }

  .modal-header {
    padding: 20px 20px 0;

    h2 {
      color: #666;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      margin: 0 0 10px;
    }
  }

  .modal-body {
    padding: 0 20px;

    p {
      color: #666;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      margin: 0 0 5px;
    }
  }

  .modal-footer {
    padding: 0 20px 20px;
  }
`;
