/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import XLSX from 'xlsx-js-style';
import style from './sheetStyle';

async function getData(loss, user, date) {
  const sheet_body = [];
  loss.inputs.forEach((item) => {
    const sheet_item = [
      item.ncm,
      item.name,
      item.unidade,
      isNaN(item.balance)
        ? ' - '
        : parseFloat(item.balance),
      item.unitary_price ? parseFloat(item.unitary_price) : ' - ',
      item.total_value ? parseFloat(item.total_value) : ' - ',
    ];
    sheet_body.push(sheet_item);
  });

  //cabeçalhos
  const header0 = ['Registro de inventário'];
  const header1 = ['Estoque existente:', date];
  const header2 = ['Restaurante:', user.fantasy_name];
  const header3 = ['CNPJ:', user.cnpj];

  if(user.adress.inscricao_estadual){
    header3.push('Inscrição estadual:');
    header3.push(user.adress.inscricao_estadual);
  }

  const sheet_header = [
    'NCM',
    'Insumo',
    'Unidade',
    'Quantidade',
    'Valor Unitário',
    'Valor em Estoque',
  ];

  const sheet_body_inter = [];
  loss.intermediaries.forEach((item) => {
    const sheet_item = [
      item.name,
      item.unidade,
      isNaN(item.balance)
        ? ' - '
        : item.balance?.replace('.',','),
    ];
    sheet_body_inter.push(sheet_item);
  });

  const sheet_header_inter = [
    'Produto Intermediário',
    'Unidade',
    'Quantidade'
  ];

  return { inputs: [header0, header1, header2, header3, sheet_header, ...sheet_body], intermediaries: [header0, header1, header2, header3, sheet_header_inter, ...sheet_body_inter] };
}

export async function generateInventoryReport(loss, user, date) {
  const { inputs, intermediaries } = await getData(loss, user, date);

  const columns = 'abcdefghijklmnopqrstuvwxyz'.toLocaleUpperCase().split('');

  const num_cols = inputs[0].length;
  const cols_comps = intermediaries[0].length;

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(inputs);
  const comp_sheet = XLSX.utils.aoa_to_sheet(intermediaries);

  // Every column with 15 chars of width
  worksheet['!cols'] = new Array(num_cols);
  worksheet['!cols'].fill({ wch: 15 });
  comp_sheet['!cols'] = new Array(cols_comps);
  comp_sheet['!cols'].fill({ wch: 15 });

  const num_lines = inputs.length;
  const comp_num_lines = intermediaries.length;

  const red = 'ff6d6d';
  const black = '000000';

  {
    worksheet['A5'].s = style().Font('ffffff', '', '').Bg(black).Get();
    worksheet['B5'].s = style().Font('ffffff', '', '').Bg(black).Get();
    worksheet['C5'].s = style().Font('ffffff', '', '').Bg(black).Get();
    worksheet['D5'].s = style().Font('ffffff', '', '').Bg(black).Get();
    worksheet['E5'].s = style().Font('ffffff', '', '').Bg(black).Get();
    worksheet['F5'].s = style().Font('ffffff', '', '').Bg(black).Get();
  }

  for (let j = 0; j < num_cols; j++) {
    for (let i = 1; i <= num_lines; i++) {
      const cell = worksheet[columns[j] + i];

      const s = style();

      if (i === 1) {
        // s.Bg(red).Borders('tb');
        s.Font({Bold: true})
      }

      if (j === num_cols - 1) {
        s.Font(black, '', 'center').Number();
      } else if (j > 0) {
        s.Font(black, '', 'center');
      }

      if (i === 5) {
        s.Bg(black).Borders('tb');
        s.Font('FFFFFF', '', '');
      }


      if (cell) {
        cell.s = s.Get();
      }
    }
  }

  for (let j = 0; j < cols_comps; j++) {
    for (let i = 1; i <= comp_num_lines; i++) {
      const cell = comp_sheet[columns[j] + i];

      const s = style();

      if (i === 1) {
        s.Bg(red).Borders('tb');
      }

      if (j === num_cols - 1) {
        s.Font(black, '', 'center').Number();
      } else if (j > 0) {
        s.Font(black, '', 'center');
      }

      if (cell) {
        cell.s = s.Get();
      }
    }
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Insumos');
  XLSX.utils.book_append_sheet(workbook, comp_sheet, 'Intermediários');

  return XLSX.write(workbook, { type: 'buffer' });
}
