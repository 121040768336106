import React, { useRef, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';

import { Row, Col, Spinner } from 'reactstrap';
import InputMask from 'react-input-mask';
import { Wrapper, Header, Card, Container } from './styles';

import api from '~/services/api';
import { useAuth } from '~/context/AuthContext';

import nfeInfo from '~/services/NFeInfo';

import CheckboxInput from '~/components/Form/Checkbox';
import Input from '~/components/Form/Input';
import SaveButton from '~/components/Buttons/SaveButton';
import Select from '~/components/Form/SelectInput';
import { useMenu } from '~/context/OrderingSystem/Menu';
import ImageInput from '~/components/Form/ImageInput';
import { useIfood } from '~/context/iFoodContext';
import getValidationErrors from '~/services/getValidationErrors';

function CreateComplement({
  category,
  getCategories,
  toggle,
  setCount,
  complementsCategories,
  setComplementsCategories,
}) {
  const formRef = useRef(null);
  const { user } = useAuth();
  const { getMenu } = useMenu();
  const { ifoodComplements, getIfoodComplements } = useIfood();

  async function handleSubmit(data, { reset }) {
    const formatPrice = (price) => {
      return parseFloat(price.replace(',', '.')).toFixed(2);
    };

    const formattedPrice = formatPrice(data.price);

    const formattedDeliveryPrice = data.delivery_price
      ? formatPrice(data.delivery_price)
      : null;

    const parsedData = {
      name: data.name,
      price: formattedPrice,
      delivery_price: formattedDeliveryPrice,
      limit: data.limit,
      show_on_report: data.show_on_report,
      available: data.available,
      available_in_delivery: data.available_in_delivery,
      description: data.description,
      ifood_complement_id: data.ifood_complement,
    };

    if (data.image_id) {
      parsedData.image_id = data.image_id;
    }

    if (user.has_nfce) {
      parsedData.ncm = data.ncm;
      parsedData.cfop = data.cfop;
      parsedData.csosn = data.csosn;
      parsedData.icms_origem = data.icms_origem;
      parsedData.cofins = data.cofins;
      parsedData.pis = data.pis;
      if (data.csosn === '00') {
        parsedData.aliquota = data.aliquota;
        parsedData.base_calculo = data.base_calculo;
      }
    }

    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        limit: Yup.string().required('Limite obrigatório'),
        price: Yup.string().required('Preço obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.post(
        'restaurants/product/complements',
        parsedData
      );

      const { id } = response.data;

      await api.post('restaurants/product/complement-category/add-complement', {
        complements: [id],
        complement_category_id: category.id,
      });

      toast.success('Complemento cadastrado com sucesso!');

      const newCategories = [...complementsCategories];

      const categoryFind = newCategories.findIndex(
        (item) => item.id === category.id
      );

      if (categoryFind !== -1) {
        newCategories[categoryFind].complements = [
          ...newCategories[categoryFind].complements,
          response.data,
        ];
      }

      setComplementsCategories(newCategories);

      getMenu();
      reset();
      formRef.current.setData({ available: true });

      if (user.ifood_merchant_uuid !== null) {
        getIfoodComplements();
      }
      toggle();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else {
        toast.error('Erro ao criar complemento');
        console.log(error.message);
      }
    }
  }

  const icms_origem_options = nfeInfo.icms_origem.map((icms) => ({
    value: icms.value,
    label: icms.description,
  }));

  const csosn_options = nfeInfo.csosn.map((c) => ({
    value: c.csosn,
    label: `${c.csosn} - ${c.description}`,
  }));

  const base_calculo_options = nfeInfo.base_calculo.map((c) => ({
    value: c.value,
    label: c.description,
  }));

  const [csosnSelected, setCsosnSelected] = useState('');
  const [cfopOptions, setCfopOptions] = useState([]);

  useEffect(() => {
    const cfopSelect = nfeInfo.csosn.filter((cs) => cs.csosn === csosnSelected);
    const cfop = [];

    cfopSelect.map((selected) =>
      selected.cfop?.forEach((cf) => {
        const cfopDesc = nfeInfo.cfopDescription.filter(
          (cfop) => cfop.cfop === cf
        );
        console.log('cfopdesc', cfopDesc);
        cfop.push({
          value: cfopDesc[0].cfop,
          label: `${cfopDesc[0].cfop} - ${cfopDesc[0].description}`,
        });
      })
    );

    setCfopOptions(cfop);
  }, [csosnSelected]);

  return (
    <Wrapper>
      <Header>
        <div>
          <h2>Cadastrar Complementos</h2>
          <p>
            Aqui você cadastra o complemento diretamente na categoria escolhida.{' '}
          </p>
          <p>
            Para facilitar, deixaremos também, o mesmo disponível para ser
            inserido em outra categoria, posteriormente.{' '}
          </p>
        </div>
      </Header>
      <Card>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Container>
            <Row>
              <Col sm="12">
                <ImageInput name="image_id" complement />
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                <Input
                  label="Nome"
                  name="name"
                  type="text"
                  placeholder="Nome do complemento"
                />
              </Col>
              <Col>
                <Input
                  label="Limite"
                  name="limit"
                  type="number"
                  placeholder="Limite individual"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                <Input
                  label="Preço"
                  name="price"
                  type="text"
                  placeholder="R$"
                />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Input
                  label="Descrição"
                  name="description"
                  type="textarea"
                  placeholder="Descrição do complemento"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                <Input
                  label="Preço no delivery"
                  name="delivery_price"
                  type="text"
                  placeholder="R$"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CheckboxInput
                  defaultChecked
                  label="Disponível no presencial"
                  name="available"
                />
              </Col>
              <Col>
                <CheckboxInput
                  defaultChecked
                  label="Disponível no delivery"
                  name="available_in_delivery"
                />
              </Col>
            </Row>
            <Row />
            <Row />

            {user.has_ifood && (
              <Row>
                <h4>Associar complemento ao iFood</h4>
                {ifoodComplements !== null ? (
                  <Select
                    name="ifood_complement"
                    placeholder={
                      ifoodComplements.length === 0
                        ? 'Esse restaurante não possui complementos no iFood'
                        : 'Selecionar'
                    }
                    options={ifoodComplements}
                    label="Complemento iFood"
                  />
                ) : (
                  <Spinner style={{ margin: '0 auto' }} />
                )}
              </Row>
            )}
            {user.has_nfce && (
              <div style={{ paddingTop: 30 }}>
                <Row>
                  <Col>
                    <h5>Fiscal</h5>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 10 }}>
                  <Col sm="12" lg="6">
                    <InputMask mask="99999999">
                      <Input label="NCM" name="ncm" type="text" />
                    </InputMask>
                  </Col>

                  <Col sm="12" lg="6" style={{ marginTop: 15 }}>
                    <Select
                      name="icms_origem"
                      options={icms_origem_options}
                      label="ICMS de Origem"
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: 10 }}>
                  <Col sm="12" lg="6">
                    <Select
                      name="csosn"
                      options={csosn_options}
                      label="CSOSN"
                      onChange={(e) => setCsosnSelected(e.value)}
                    />
                  </Col>

                  <Col sm="12" lg="6">
                    <Select name="cfop" options={cfopOptions} label="CFOP" />
                  </Col>
                </Row>

                <Row style={{ paddingTop: 10 }}>
                  <Col sm="12" lg="6">
                    <Select
                      name="cofins"
                      options={nfeInfo.cofins_options}
                      label="Cofins"
                    />
                  </Col>

                  <Col sm="12" lg="6">
                    <Select
                      name="pis"
                      options={nfeInfo.cofins_options}
                      label="Pis"
                    />
                  </Col>
                </Row>

                {csosnSelected === '00' && (
                  <div>
                    <Row style={{ paddingTop: 20 }}>
                      <Col sm="12" lg="6">
                        <Input
                          label="Alíquota (Entre 0 e 100)"
                          name="aliquota"
                          type="text"
                          maxlength="3"
                        />
                      </Col>

                      <Col sm="12" lg="6" style={{ marginTop: 15 }}>
                        <Select
                          name="base_calculo"
                          options={base_calculo_options}
                          label="Base Cálculo"
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            )}
            <SaveButton type="submit">Cadastrar</SaveButton>
          </Container>
        </Form>
      </Card>
    </Wrapper>
  );
}

export default CreateComplement;
