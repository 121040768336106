import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import api from '~/services/api';
import { Container } from './styles';

import ProductCategory from './Categories';

import { useAuth } from '~/context/AuthContext';

import PermissionErrorContainer from '~/components/PermissionErrorContainer';

function FiscalRelation() {
  const { user } = useAuth();

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'MenuProducts'}`
      );

      const { can_read } = response.data.MenuProducts;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  const [fiscalGroups, setFiscalGroups] = useState([]);

  const getFiscalGroups = useCallback(async () => {
    if (user.has_nfce) {
      try {
        const response = await api.get(`/restaurants/fiscal-groups`);
        const fiscal = response.data.map(group => {
          return {
            label: group.title,
            value: group.id,
            id: group.id
          }
        });
        setFiscalGroups(fiscal);
      } catch (error) {
        toast.error('Erro ao grupos fiscais');
      }
    }
  }, []);

  useEffect(() => {
    validateUser();
    getFiscalGroups();
  }, [validateUser, getFiscalGroups]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <ProductCategory user={user} fiscalGroups={fiscalGroups}/>
    </Container>
  );
}

export default FiscalRelation;