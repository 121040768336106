import React from 'react';

import { Spinner } from 'reactstrap';
import { Content, ItemsCount, LoadingDiv } from '../styles';
import { Header, Bloco } from './styles';
import OrderReadyDelivery from '../../Items/OrderReadyDelivery';

export default function CardOrdersSchedulingReady({
  baskets,
  handleItemFinished,
  handleCancelItem,
  loading,
  getAllOrders,
  paymentMethods,
  allPaymentMethods,
  favoritePaymentMethods,
  handleUpdate,
  countOrders,
}) {
  // const [paymentMethods, setPaymentMethods] = useState([]);
  return (
    <Bloco>
      <Header>
        PRONTOS
      </Header>
      <Content style={{ borderBottomRightRadius: 12 }}>
        {loading ? (
          <LoadingDiv>
            <Spinner color="warning" />
          </LoadingDiv>
        ) : (
          baskets
            ?.filter((basket) => basket.scheduled_to)
            .map((item) => (
              <OrderReadyDelivery
                key={item.basket.id}
                handleCancelItem={handleCancelItem}
                handleItemFinished={handleItemFinished}
                item={item}
                paymentMethods={paymentMethods}
                allPaymentMethods={allPaymentMethods}
                favoritePaymentMethods={favoritePaymentMethods}
                getAllOrders={getAllOrders}
                handleUpdate={handleUpdate}
              />
            ))
        )}
      </Content>
    </Bloco>
  );
}