import React, { useState, useImperativeHandle, useCallback } from 'react';
import { FaArrowAltCircleUp } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import { toast } from 'react-hot-toast';
import api from '~/services/api';
import { Container } from './styles';

function Annotation({ cashier, getCashiers }, ref) {
  const [annotationIsOpened, setAnnotationIsOpened] = useState(false);

  const handleOpenAnnotation = useCallback(() => {
    if (annotationIsOpened) {
      setAnnotationIsOpened(false);
      getCashiers();
    } else {
      setAnnotationIsOpened(true);
    }
  }, [annotationIsOpened, getCashiers]);

  useImperativeHandle(ref, () => {
    return { handleOpenAnnotation };
  });

  const [annotation, setAnnotation] = useState('');
  async function handleSaveAnnoatation() {
    try {
      await api.put(`/restaurants/cashier-audit/${cashier.id}`, {
        annotation,
      });
      toast.success('Anotação salva com sucesso');
      setAnnotationIsOpened(false);
      setAnnotation('');
    } catch (error) {
      toast.error('Erro ao salvar comnentário');
    }
  }

  return (
    <Container opened={annotationIsOpened}>
      <textarea
        style={{ resize: 'none', padding: 20 }}
        name="annotation"
        cols="30"
        rows="3"
        defaultValue={cashier?.annotation}
        onChange={(e) => setAnnotation(e.target.value)}
        maxLength="300"
      />

      <FaArrowAltCircleUp
        title="Salvar anotação"
        style={{ cursor: 'pointer' }}
        onClick={handleSaveAnnoatation}
        size={21}
        color="grey"
      />
      <FiX
        size={21}
        color="grey"
        title="Fechar anotação"
        style={{ cursor: 'pointer', top: 10 }}
        onClick={() => {
          setAnnotationIsOpened(false);
        }}
      />
    </Container>
  );
}

export default React.forwardRef(Annotation);
