import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 10px;
`;

export const Header = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;
`;

export const NavDiv = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  clear: both;
`;

export const NavDivTeste = styled.div`
  float: right;
`;

export const IfoodTable = styled.table`
  width: 100%;
  min-width: 200px;
  margin: 10px auto 10px 0;
  border-collapse: initial;
  border-spacing: 0 10px;
  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  border-radius: 8px;
  padding: 0 20px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid transparent;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const InputsTable = styled.table`
  width: 100%;
  min-width: 200px;
  margin: 15px auto 15px 0;
  border-collapse: initial;
  border-spacing: 0 20px;
  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  padding: 0 30px;
  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid transparent;
    }
    td:last-child {
      text-align: right;
    }
    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const NavLinkSpan = styled.span`
  margin-right: 5px;
`;

export const Card = styled.div`
  margin: 0 auto;
  width: 80%;
  padding: 30px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
`;

export const ErrorContainer = styled.div`
  width: 60%;
  min-width: 600px;
  margin: 10px auto 30px auto;

  background: orange;
  color: #fff;

  border-radius: 8px;
  padding: 30px;

  p {
    text-align: center;
    font-size: 22px;
  }
`;
