import React from 'react';
import OrderingSystemProduct from '~/components/OrderingSystem/Products/Product';
import { Container } from './styles';

export default function OrderingSystemProducts({
  categoryState,
  isTableDelivery,
  setProductsFiltered,
  isProductFiltered,
  setAdded,
  toggleProductArea,
}) {
  return isTableDelivery ? (
    <>
      {isProductFiltered ? (
        <Container>
          {categoryState?.map((item) =>
            item
              .map((it) => it)
              .filter((category) => category?.cat?.available_in_delivery)
              ?.map((product) => (
                <OrderingSystemProduct
                  product={product?.prod}
                  key={product?.prod?.id}
                  setProductsFiltered={setProductsFiltered}
                  isTableDelivery={isTableDelivery}
                  setAdded={setAdded}
                  toggleProductArea={toggleProductArea}
                />
              ))
          )}
        </Container>
      ) : (
        <div style={{ borderTop: '1px solid #33333320', paddingTop: 15 }}>
          {categoryState?.available_in_delivery && (
            <span style={{ fontWeight: 'bold' }}>{categoryState?.name}</span>
          )}
          <Container>
            {categoryState?.available_in_delivery ? (
              <>
                {categoryState?.products
                  ?.filter((prd) => prd?.available_in_delivery)
                  .map((product) => (
                    <OrderingSystemProduct
                      product={product}
                      key={product.id}
                      setProductsFiltered={setProductsFiltered}
                      isTableDelivery={isTableDelivery}
                      setAdded={setAdded}
                      toggleProductArea={toggleProductArea}
                    />
                  ))}
              </>
            ) : (
              <p>Favor selecionar uma categoria.</p>
            )}
          </Container>
        </div>
      )}
    </>
  ) : (
    <>
      {isProductFiltered ? (
        <Container>
          {categoryState?.map((item) =>
            item
              .map((it) => it)
              .filter((category) => category?.cat?.available)
              ?.map((product) => (
                <OrderingSystemProduct
                  product={product?.prod}
                  key={product?.prod?.id}
                  setProductsFiltered={setProductsFiltered}
                  isTableDelivery={isTableDelivery}
                  setAdded={setAdded}
                  toggleProductArea={toggleProductArea}
                />
              ))
          )}
        </Container>
      ) : (
        <div style={{ borderTop: '1px solid #33333320', paddingTop: 15 }}>
          {categoryState?.available && (
            <span style={{ fontWeight: 'bold' }}>{categoryState?.name}</span>
          )}
          <Container>
            {categoryState?.available ? (
              <>
                {categoryState?.products
                  ?.filter((prd) => prd.available)
                  .map((product) => (
                    <OrderingSystemProduct
                      product={product}
                      key={product.id}
                      setProductsFiltered={setProductsFiltered}
                      isTableDelivery={isTableDelivery}
                      setAdded={setAdded}
                      toggleProductArea={toggleProductArea}
                    />
                  ))}
              </>
            ) : (
              <p>Favor selecionar uma categoria.</p>
            )}
          </Container>
        </div>
      )}
    </>
  );
}
