import React from 'react';

import { FiUser } from 'react-icons/fi';
import { Content, ItemsCount, Icon } from '../styles';
import { Header, Bloco } from './styles';
import CloseIndividual from '~/components/Tables/CloseOrder/Items/CloseIndividual';

export default function CardCloseIndividual({
  individualBills,
  handlePrintItem,
}) {
  return (
    <Bloco>
      <Header>
        <Icon>
          <FiUser color="#fff" size={25} />
        </Icon>
        INDIVIDUAIS
        <ItemsCount>{individualBills.length}</ItemsCount>
      </Header>
      <Content>
        {individualBills ? (
          individualBills.map((item) => (
            <CloseIndividual
              key={item.id}
              item={item}
              handlePrintItem={handlePrintItem}
              individualBills={individualBills}
            />
          ))
        ) : (
          <p>Nenhum dado para informar</p>
        )}
      </Content>
    </Bloco>
  );
}
