import styled from 'styled-components';
import { Modal } from 'reactstrap';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  h3 {
    margin: 0;
    color: #4d4d4c;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }
`;

export const UserInfoDiv = styled.div`
  display: flex;
  gap: 10px;
`;

export const ModalNFe = styled(Modal)`
  .modal-content {
    padding: 20px;
  }

  h3 {
    color: #4d4d4c;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }
`;

export const ModalGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;
