import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { toast } from 'react-hot-toast';
import {
  format,
  startOfDay,
  endOfDay,
  subHours,
  addHours,
  differenceInDays,
} from 'date-fns';
import {
  Row,
  Col,
  Collapse,
  CardBody,
  Card as Cardstrap,
  CardFooter,
  CardTitle,
} from 'reactstrap';
import { Card, Container, Header, CategoriesTable } from './styles';

import GoodCard from '~/components/Rate/GoodCard';
import BadCard from '~/components/Rate/BadCard';
import api from '~/services/api';
import ClientsRatingRow from '../ClientsRatingRow';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { PageTitle } from '~/components/PageTitle';
import { FullCalendar } from 'ui-kit-takeat';

function ReportsRate() {
  const [buyers, setBuyers] = useState(0);
  const [rangeOn, setRangeOn] = useState(true);
  const [ratings, setRatings] = useState([]);
  const formRef = useRef(null);

  const defaultInicial = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm");
  }, []);
  const inicialFormat = format(new Date(defaultInicial), 'dd/MM/yyyy, HH:mm');
  const finalFormat = format(new Date(defaultFinal), 'dd/MM/yyyy, HH:mm');
  const phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [hourPhrase, setHourPhrase] = useState(phrase);

  const getRatings = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/ratings', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });

      response.data.ratings.sort((a, b) => {
        if (a.createdAt < b.createdAt) {
          return 1;
        }
        return -1;
      });
      setRatings(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [inicialDate, finalDate]);

  const getBuyers = useCallback(async () => {
    try {
      const response = await api.get('restaurants/reports/buyers', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });
      setBuyers(response.data.length);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [inicialDate, finalDate]);

  useEffect(() => {
    getRatings();
    getBuyers();
  }, [getRatings, getBuyers]);

  const rate = ratings.ratings_average ? ratings.ratings_average.toFixed(2) : 0;
  const percentRating = ((ratings.ratings_count * 100) / buyers).toFixed(0);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'ReportsRatings'}`
      );

      const { can_read } = response.data.ReportsRatings;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  useEffect(() => {
    const phrase = `De ${format(
      new Date(inicialDate),
      'dd/MM/yyyy, HH:mm'
    )} até ${format(new Date(finalDate), 'dd/MM/yyyy, HH:mm')}`;
    setHourPhrase(phrase);
  }, [inicialDate, finalDate]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div>
          <PageTitle>Relatório de Avaliações</PageTitle>
          <p>Acompanhe as avaliações recebidas pelo seu estabelecimento!</p>
        </div>
      </Header>
      <Card>
        <div style={{ display: 'flex' }}>
          <p style={{ flexGrow: 1 }}>{hourPhrase}</p>
          <FullCalendar
            hideFields={['this_year']}
            value={{ start: new Date(inicialDate), end: new Date(finalDate) }}
            onDateSelected={(e) => {
              const days = differenceInDays(e.end, e.start);

              if (days > 31) {
                setRangeOn(false);
              } else {
                setRangeOn(true);
                setInicialDate(e.start);
                setFinalDate(e.end);
              }
            }}
          />
        </div>
        <p
          style={{
            textAlign: 'right',
            color: 'red',
            opacity: rangeOn ? 0 : 1,
          }}
        >
          Favor selecionar o intervalo máximo de 31 dias entre as datas.
        </p>

        <Row>
          <Col lg="4" md="4">
            <Cardstrap className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="5">
                    <div
                      className="info-icon text-center"
                      style={{ background: '#ffc107' }}
                    >
                      <i className="tim-icons icon-shape-star" />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Média de Avaliações</p>
                      <CardTitle tag="h3">{rate}</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  Média de avaliações do estabelecimento
                </div>
              </CardFooter>
            </Cardstrap>
          </Col>

          <Col lg="4" md="4">
            <Cardstrap className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="5">
                    <div className="info-icon text-center">
                      <i className="tim-icons icon-chart-bar-32" />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Avaliações</p>
                      <CardTitle tag="h3">{ratings.ratings_count}</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">Total de avaliações</div>
              </CardFooter>
            </Cardstrap>
          </Col>
          <Col lg="4" md="4">
            <Cardstrap className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="5">
                    <div className="info-icon text-center icon-info">
                      <i className="tim-icons icon-single-02" />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Clientes</p>
                      <CardTitle tag="h3">{buyers}</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">Total de clientes</div>
              </CardFooter>
            </Cardstrap>
          </Col>
        </Row>

        <Row>
          <Col lg="6" md="6">
            <GoodCard ratings={ratings} />
          </Col>
          <Col lg="6" md="6">
            <BadCard ratings={ratings} />
          </Col>
        </Row>

        <Cardstrap>
          <CardBody>
            <CategoriesTable borderless>
              <thead>
                <tr>
                  <th style={{ textAlign: 'center' }}>Telefone</th>
                  <th style={{ textAlign: 'center' }}>Nome</th>
                  <th style={{ textAlign: 'center' }}>Avaliação</th>
                  <th style={{ textAlign: 'center' }}>Comentário</th>
                  <th style={{ textAlign: 'center' }}>Atributos</th>
                  <th style={{ textAlign: 'center' }}>Feita em:</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {ratings.ratings &&
                  ratings.ratings.length > 0 &&
                  ratings.ratings.map((item) => (
                    <ClientsRatingRow rating={item} />
                  ))}
              </tbody>
            </CategoriesTable>
          </CardBody>
        </Cardstrap>
      </Card>
    </Container>
  );
}

export default ReportsRate;
