import React, { useEffect, useRef, useState } from 'react';
import {
  ActionsDiv,
  Comment,
  ComplementTag,
  Complements,
  InputProductAmount,
  Item,
  ModalDelete,
  ModalObservation,
  NameActions,
} from './styles';
import { BsChat } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { useCart } from '~/context/OrderingSystem/Cart';
import { Button } from 'ui-kit-takeat';

export const CartItem = ({ item }) => {
  const { removeFromCart, increment, decrement, addObservation, changeProductAmount } = useCart();
  const [modalObservationOpen, setModalObservationOpen] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [observation, setObservation] = useState(item.observation || '');
  const textAreaRef = useRef(null);
  const complements = item.complement_categories.flatMap(
    (category) => category.complements
  );

  const [amount, setAmount] = useState(item?.amount)

  // Sincronizar o estado local sempre que item.amount mudar
  useEffect(() => {
    setAmount(item.amount);
  }, [item.amount]);

  function handleChangeProductAmount(id, ordering_system_id, value) {
    setAmount(Number(value));
    changeProductAmount(id, ordering_system_id, Number(value))
  }

  return (
    <Item>
      <NameActions>
        <span>{item.name}</span>

        <ActionsDiv>
          <BsChat
            color="#666"
            onClick={() => {
              setModalObservationOpen(true);
            }}
          />
          <AiOutlineMinusCircle
            onClick={() => decrement(item.id, item.ordering_system_id)}
          />
          {!item?.is_unitary ? (
            <InputProductAmount
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              value={amount}
              onChange={(e) => handleChangeProductAmount(item.id, item.ordering_system_id, e.target.value)}
            />
          ) : (
            <span style={{ color: '#FF281E', fontSize: 20 }}>{amount}</span>
          )}

          <AiOutlinePlusCircle
            onClick={() => increment(item.id, item.ordering_system_id)}
          />
          <FaTrash
            style={{ height: 15 }}
            onClick={() => setModalDeleteOpen(true)}
          />
        </ActionsDiv>
      </NameActions>

      <Complements>
        {complements.map((complement) => {
          if (complement.amount <= 0) return null;

          return (
            <ComplementTag key={complement.id}>{complement.name}</ComplementTag>
          );
        })}
      </Complements>

      {item.observation && (
        <Comment>
          <BsChat />
          <span>{item.observation}</span>
        </Comment>
      )}

      <ModalObservation
        onOpened={() => {
          if (textAreaRef.current) {
            textAreaRef.current.focus();
          }
        }}
        isOpen={modalObservationOpen}
        toggle={() => setModalObservationOpen((state) => !state)}
      >
        <h3>Adicionar observação</h3>
        <textarea
          ref={textAreaRef}
          placeholder="Adicionar observação"
          value={observation}
          onChange={(e) => setObservation(e.target.value)}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 10,
          }}
        >
          <Button
            title="Cancelar"
            onClick={() => setModalObservationOpen(false)}
          />
          <Button
            title="Salvar"
            buttonColor="#2ec9b7"
            onClick={() => {
              addObservation(item.id, item.ordering_system_id, observation);
              setModalObservationOpen(false);
            }}
          />
        </div>
      </ModalObservation>

      <ModalDelete
        isOpen={modalDeleteOpen}
        toggle={() => setModalDeleteOpen((state) => !state)}
      >
        <h3>Deseja realmente excluir esse produto do carrinho?</h3>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 10,
          }}
        >
          <Button
            title="Não excluir"
            onClick={() => setModalDeleteOpen(false)}
          />
          <Button
            title="Excluir"
            buttonColor="#2ec9b7"
            onClick={() => {
              removeFromCart(item.basket_id, item.id);
              setModalDeleteOpen(false);
            }}
          />
        </div>
      </ModalDelete>
    </Item>
  );
};
