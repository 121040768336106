import React from 'react';

import { Card, CardBody, CardFooter, CardTitle, Row, Col } from 'reactstrap';
import { FiSmile } from 'react-icons/fi';

export default function GoodCard({ ratings }) {
  // const [modal, setModal] = useState(false);
  // const toggle = () => setModal(!modal);
  let reviewsCount = 0;
  let goodReviewsCount = 0;
  let percentGoodReview = 0;

  if (ratings?.good_reviews) {
    ratings.good_reviews.forEach((rating) => {
      reviewsCount += rating.count;
      goodReviewsCount += rating.count;
    });
  }

  if (ratings?.bad_reviews) {
    ratings.bad_reviews.forEach((rating) => {
      reviewsCount += rating.count;
    });
  }

  if (reviewsCount > 0) {
    percentGoodReview = ((goodReviewsCount * 100) / reviewsCount).toFixed(0);
  }

  return (
    <>
      <Card className="card-stats">
        <CardBody>
          <Row>
            <Col xs="5">
              <div className="info-icon text-center icon-success">
                <i className="tim-icons">
                  <FiSmile color="#fff" />
                </i>
              </div>
            </Col>
            <Col xs="7">
              <div className="numbers">
                <p className="card-category">Avaliações Positivas</p>
                <CardTitle tag="h3">
                  <span>{goodReviewsCount}</span> {` / `}
                  <span>{percentGoodReview}%</span>
                </CardTitle>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <hr />
          <div className="stats">
            {ratings?.good_reviews && ratings?.good_reviews.length > 0 ? (
              ratings?.good_reviews.map((rating) => (
                <>{`${rating.adjective} (${rating.count}) - `}</>
              ))
            ) : (
              <span>-</span>
            )}
          </div>
        </CardFooter>
      </Card>
    </>
  );
}
