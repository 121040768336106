import React, { useEffect } from 'react';
import { Container, ItemList } from './styles';

export const ModalCancelItem = ({ bill }) => {
  const userName = bill.buyer
    ? bill.buyer.name || bill.buyer.phone
    : bill.waiter.name;

  const orders = bill.order_baskets.map((ob) => ob.orders).flat();

  useEffect(() => {
    console.log('Bill: ', bill);
    console.log('Orders: ', orders);
  }, []);

  return (
    <Container>
      <h2>{userName}</h2>
      {orders.map((order) => {
        const complements = order.complement_categories
          .map((cc) => {
            return cc.order_complements;
          })
          .flat();

        return (
          <ItemList key={order.id}>
            <h3>
              {order.amount}x {order.product.name}
            </h3>
            {complements.map((complement) => {
              return (
                <span>
                  - {complement.amount}x {complement.complement.name}
                </span>
              );
            })}
          </ItemList>
        );
      })}
    </Container>
  );
};
