import React, { useCallback, useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { Spinner, Collapse } from 'reactstrap';
import { BillsArea, Container, ItemHeader, Item } from './styles';
import formatCompleteDate from '~/services/formatCompleteDate';
import formatValue from '~/services/formatValue';
import api from '~/services/api';

function BillItem({ item }) {
  const [subCollapse, setSubCollapse] = useState([]);
  const [bill, setBill] = useState();
  const getBills = useCallback(async (session_id) => {
    const response = await api.get(
      `/restaurants/table-sessions-bills/${session_id}`
    );

    setBill(response.data);
  }, []);
  const handleSubCollapse = (item) => {
    if (subCollapse.includes(item)) {
      setSubCollapse((state) => state.filter((i) => i !== item));
    } else {
      setSubCollapse([item]);
    }
  };
  return (
    <Container>
      <ItemHeader
        onClick={() => {
          handleSubCollapse(item?.basket_id);
          getBills(item?.id);
        }}
      >
        <span>
          <FaAngleDown />
          <span style={{ marginLeft: 10 }}>#{item?.basket_id}</span>
        </span>

        <span>{formatCompleteDate(item?.start_time)}</span>
        <span>{formatValue(item?.total_service_price)}</span>
      </ItemHeader>
      <Collapse isOpen={subCollapse.includes(item?.basket_id)}>
        <BillsArea>
          {bill ? (
            <>
              <p style={{ paddingTop: 10 }}>
                {bill?.print_bills[0]?.buyer?.phone} -{' '}
                {bill?.print_bills[0]?.buyer?.name}
              </p>
              {bill?.products &&
                bill?.products.length > 0 &&
                bill?.products.map((product) => (
                  <>
                    <Item>
                      {product.use_weight ? (
                        <p style={{ fontWeight: 'bold' }}>
                          {product?.weight}kg {product?.name}
                        </p>
                      ) : (
                        <p style={{ fontWeight: 'bold' }}>
                          {product?.amount}x {product?.name}
                        </p>
                      )}

                      <p>{formatValue(product?.total_service_price)}</p>
                    </Item>

                    {product.complement_categories &&
                      product.complement_categories.length > 0 &&
                      product.complement_categories.map(
                        (category) =>
                          category.order_complements &&
                          category.order_complements.length > 0 &&
                          category.order_complements.map((comp) => (
                            <Item>
                              {comp.use_weight ? (
                                <p style={{ fontSize: 12 }}>
                                  {comp?.weight}kg {comp?.complement.name}
                                </p>
                              ) : (
                                <p style={{ fontSize: 12 }}>
                                  {comp?.amount}x {comp?.complement.name}
                                </p>
                              )}
                            </Item>
                          ))
                      )}
                  </>
                ))}
              <Item>
                <p style={{ fontWeight: 'bold' }}>TOTAL</p>
                <p>{formatValue(item?.total_service_price)}</p>
              </Item>

              <Item>
                <p style={{ fontWeight: 'bold' }}>Pago</p>
                <p>
                  {item?.total_service_price > 0
                    ? formatValue(Number(item?.total_service_price))
                    : 'R$ 0'}
                </p>
              </Item>
              {item?.payments &&
                item?.payments.length > 0 &&
                item?.payments.map((payment) => (
                  <Item>
                    <li style={{ paddingLeft: 10 }}>
                      {' '}
                      {payment.payment_method?.name}
                    </li>
                    <p>
                      {payment.payment_value > 0
                        ? formatValue(Number(payment.payment_value))
                        : 0}
                    </p>
                  </Item>
                ))}
            </>
          ) : (
            <Spinner />
          )}
        </BillsArea>
      </Collapse>
    </Container>
  );
}

export default BillItem;
