import styled from "styled-components";

export const Container = styled.div`
    display:flex;
    width:100%;
    height:100%;
    position:fixed;
    align-items:center;
`

export const ModalContent = styled.div`
    display:grid;
    grid-template-columns: 1fr;
    gap:16px;
    width:100%;
    padding:24px;
    background-color:#fff;
    border-radius: 150px;    

    .custom-modal .modal-content {
  border-radius: 150px !important;
  overflow: hidden;
}

    span#title{
        font-size:16px;
        font-weight:700;
    }
    
    span#fullRegister{
        display:flex;
        justify-content:flex-end;
        font-size:14px;
        text-decoration:underline;
        color:#2EC9B7;
        cursor:pointer;
    }
`

export const InformationArea = styled.div`
  display:grid;
  gap:16px;
  grid-template:
    "email birthday"
    "cpf cep"
    "neighborhood street"
    "number complement";
`


export const ButtonArea = styled.div`
    display:flex;
    justify-content:space-between;
    flex-direction:row;
`