import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import { DropdownItem, Table } from 'reactstrap';
import NumberFormat from 'react-number-format';

export const SwitchStyled = styled(Switch)`
  ${(props) =>
    !props.available &&
    css`
      opacity: 0.5 !important;
    `}
  ${(props) =>
    props.sold_off &&
    css`
      opacity: 0.5 !important;
    `}
`;

export const Container = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 30px;
  justify-content: center;
  display: flex;
`;

export const Content = styled.div`
  height: 100%;
  padding: 20px;
  position: relative;
`;
export const AreaSwitcher = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    font-size: 14px;
    margin-left: 8px;
  }
`;
export const LegendStyled = styled.p`
  margin-top: -10px;
`;
export const ButtonsArea = styled.div`
  display: flex;
  justify-content: space-between;
  /* width: 97%;
  position: absolute;
  bottom: 80px; */
`;
export const NumberFormatStyled = styled(NumberFormat)`
  text-align: center;
  height: 44px;
  width: 90px;
  max-width: 103px;
  min-width: 82px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  margin-top: 8px;
  ${(props) =>
    !props.available &&
    css`
      opacity: 0.5 !important;
    `}
  ${(props) =>
    props.sold_off &&
    css`
      opacity: 0.5 !important;
    `}
  ${(props) =>
    props.limit
      ? 'width: 60px;'
      : `
  width: 90px;
  min-width: 82px;
  max-width: 103px;
  `}
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }
  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    background-color: #f5f5f5;
    color: #808080;
  `}
`;
export const SubmitStyledButton = styled.button`
  height: 44px;
  width: 44px;
  padding: 10px;
  border-radius: 8px;
  border: none;
  margin-top: 8px;
  margin-left: 8px;
  background-color: #0ccab4;
  svg {
    color: #fff;
  }
  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.colors.lightGray};
    svg {
      filter: opacity(0.3);
      color: #333;
    }
  }
`;

export const CategoriesTable = styled(Table)`
  width: 95%;
  min-width: 400px;
  align-items: center;
  border-collapse: separate;
  border-spacing: 0 20px;
  background: #fff6b3;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  padding: 0 20px;
  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #dee2e6;
    }
    td:last-child {
      text-align: right;
    }
    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;
export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
  svg {
    color: ${(props) => props.theme.colors.tertiary};
    margin-right: 10px;
  }
`;

export const Delete = styled.button`
  border: none;
  background: none;
  &:active {
    border: none;
  }
  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  float: right;
  svg {
    cursor: pointer;
    color: #333;
  }
`;

export const DeleteAndEditActions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StatusTag = styled.div.attrs((props) => ({
  color: props.color,
  backgroundcolor: lighten(0.33, props.color),
}))`
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundcolor};
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  &::before {
    content: ' ';
    display: inline-block;
    position: relative;
    margin-right: 5px;
    width: 10px;
    height: 10px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
  }
`;
export const ModalPaymentBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const DisplayNoneImprimir = styled.button`
  display: none;
`;

export const ProductTableRow = styled.tr`
  border-bottom: none;

  img {
    border-radius: 5px;
  }
`;

export const ProductImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin: 10px 5px 10px 0;
  cursor: pointer;
  ${(props) =>
    props.available &&
    css`
      img {
        opacity: 0.7;
      }
    `}
  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 1025px) {
    width: 50px;
    height: 50px;
  }
`;

export const ModalFeaturedProduct = styled.div`
  padding: 20px;
  align-items: center;
  text-align: left;
  h4 {
    margin-bottom: 20px;
  }
`;

export const MenuItemPromotion = styled.p`
  text-align: center;
  color: red;
  text-decoration: line-through;
  filter: opacity(60%);
  position: absolute;
  top: 11px;
  left: 0px;
  width: calc(100% - 67px);
  max-width: 103px;
  min-width: 82px;
  margin-left: 7px;
`;

export const EditButton = styled.button`
  height: 25px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 15px;
  border: none;
  background: #17a2b8;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 6px;
  width: 90%;
  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const DeleteButton = styled.button`
  height: 25px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 15px;
  border: none;
  background: red;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 6px;
  width: 90%;
  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const FeaturedProductButton = styled.button`
  height: 25px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: black;
  align-self: center;
  margin-right: 15px;
  border: none;
  background: #ffc107;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
  width: 90%;
  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: black;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const NoImage = styled.div`
  width: 80px;
  height: 80px;
  cursor: pointer;
  background: #33333330;
  border-radius: 8px;
  margin-bottom: 8px;

  @media (max-width: 1025px) {
    width: 50px;
    height: 50px;
  }
`;

export const Description = styled.p`
  min-width: 390px;
  max-width: 390px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
  @media (max-width: 1281px) {
    min-width: 100px;
    max-width: 100px;
  }

  @media (min-width: 1282px) and (max-width: 1679px) {
    min-width: 150px;
    max-width: 150px;
  }
`;

export const ProductName = styled.p`
  max-width: 390px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;

  @media (max-width: 1281px) {
    min-width: 100px;
    max-width: 100px;
  }

  @media (min-width: 1282px) and (max-width: 1679px) {
    min-width: 150px;
    max-width: 150px;
  }
`;

export const ProductPrice = styled.td`
  padding-right: 20px;
  /* min-width: 149px; */
  position: relative;
`;
export const ItemTd = styled.td`
  display: flex;
  align-items: center;
  svg {
    margin-right: 16px;
    cursor: pointer;
  }
`;

export const ProductDescription = styled.div`
  ${(props) =>
    !props.available &&
    css`
      opacity: 0.5;
    `}
  ${(props) =>
    props.sold_off &&
    css`
      opacity: 0.5;
    `}
  display: flex;
  flex-direction: column;
  margin-left: 26px;

  @media (max-width: 1281px) {
    margin-left: 8px;
  }
`;

export const ProductAvatar = styled.div`
  ${(props) =>
    !props.available &&
    css`
      opacity: 0.5;
    `}
  ${(props) =>
    props.sold_off &&
    css`
      opacity: 0.5;
    `}
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

export const ButtonLinnk = styled.button`
  border: none;
  text-decoration: underline;
  color: ${(props) => (props.group ? '#ff2c3a' : '#0CCAB4')};
  background-color: transparent;
  font-weight: bold;
  margin-left: 45px;
  margin-bottom: 20px;

  &:disabled {
    pointer-events: none;
    color: #808080;
  }

  @media (max-width: 1281px) {
    margin-left: 0px;
    font-size: 12px;
  }

  :disabled {
    color: grey;
  }
`;

export const ButtonsTd = styled.td`
  text-align: left;
`;

export const ProductAvailable = styled.td`
  padding-right: 20px;
`;

export const TdDisplayNone = styled.td``;

export const StyledDropdownItem = styled(DropdownItem)`
  ${({ disabled }) =>
    disabled &&
    `
  pointer-events: none;
  color: #808080;
`}
`;
