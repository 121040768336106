import styled from 'styled-components';
import InputMask from 'react-input-mask';
import SelectPersonal from 'react-select';
import Select from '~/components/Form/SelectInput';
import 'react-datepicker/dist/react-datepicker.css';
import { motion } from 'framer-motion';

export const Container = styled.div`
  margin-bottom: 100px;
  width: 100%;
`;

export const DataPickerArea = styled.div`
  display: flex;
  height: 335px;
  justify-content: space-between;

  @media (max-width: 1025px) {
    flex-direction: column;
    height: 600px;
  }

  .react-datepicker {
    border: none;
    position: absolute;
    top: -27px;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .react-datepicker__month {
    width: 305px;
    margin: 0;

    @media (max-width: 1025px) {
      width: 305px;
    }

    @media (min-width: 1026px) and (max-width: 1441px) {
      width: 214px;
    }

    @media (min-width: 1442px) and (max-width: 1681px) {
      width: 283px;
    }
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    border: none;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    background: #f4f4f4;
    color: #979797;
    border-radius: 3px;
  }

  .react-datepicker__navigation {
    opacity: 2;
    width: 50px;
    height: 50px;
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: #ff2c3a;
  }

  .react-datepicker__navigation--next {
    right: 0px;
  }

  .react-datepicker__day-names {
    background-color: #ffffff;

    div {
      width: 41px;
      margin: 0;

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 35px;
      text-align: center;
      color: #666666;
    }

    div:first-child,
    div:last-child {
      color: #979797;
    }
  }

  .react-datepicker__day {
    width: 41px;
    margin: 0;

    margin-top: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 35px;
    text-align: center;
    color: #666666;
  }

  .react-datepicker__day--selected {
    background-color: #ff2c3a;
    color: #ffffff;
    border-radius: 20px;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #f0f0f0;
  }

  .react-datepicker__current-month {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 35px;
    text-align: center;
    color: #ff2c3a;

    height: 44px;
  }

  .react-datepicker__header {
    background: #f4f4f4;
    border: none;
    border-radius: 0px;
    text-transform: capitalize;
    width: 305px;

    @media (max-width: 1025px) {
      width: 305px;
    }

    @media (min-width: 1026px) and (max-width: 1441px) {
      width: 214px;
    }

    @media (min-width: 1442px) and (max-width: 1681px) {
      width: 283px;
    }
  }

  .react-datepicker__input-container {
    width: fit-content;
  }
  .react-datepicker-wrapper {
    width: fit-content;
  }
  input {
    background: transparent;
    border: none;
    width: 185px;
    text-transform: capitalize;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
    text-align: center;
    color: #666666;
  }
`;

export const HourDiv = styled.div`
  width: 288px;

  @media (max-width: 1025px) {
    width: 305px;
    height: 290px;
  }

  @media (min-width: 1026px) and (max-width: 1281px) {
    width: 148px;
  }

  @media (min-width: 1282px) and (max-width: 1441px) {
    width: 200px;
  }

  @media (min-width: 1442px) and (max-width: 1681px) {
    width: 211px;
  }
`;

export const HourTitle = styled.div`
  background: #f4f4f4;
  color: #ff2c3a;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: #ff2c3a;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
  }
`;

export const Subtitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #4d4d4c;
`;

export const HoursAvailable = styled.div`
  padding: 10px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 3px;
  max-height: 250px;

  overflow-y: scroll;
  overflow-x: hidden;

  @media (max-width: 1025px) {
    grid-template-columns: auto auto auto;
  }

  @media (min-width: 1026px) and (max-width: 1281px) {
    grid-template-columns: auto auto;
    font-size: 12px;
  }

  @media (min-width: 1282px) and (max-width: 1441px) {
    grid-template-columns: auto auto;
  }

  @media (min-width: 1442px) and (max-width: 1681px) {
    grid-template-columns: auto auto auto;
    font-size: 12px;
  }
`;

export const HourCheckbox = styled(motion.div)`
  display: flex;
  width: fit-content;
  align-items: center;
  margin-bottom: 18px;

  div {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 5px;
    background: ${(props) =>
    props.disabled ? '#979797' : props.selected ? '#FF1C32' : '#ffffff'};
    border: 1px solid ${(props) => (props.selected ? '#FF1C32' : '#979797')};
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  width: 35%;
  padding-top: 10px;
  padding-right: 24px;
  background: #fff;
  z-index: 1000000;
  border-top: 1px solid #33333310;
  background-color: #fff;
`;

export const FooterSubmit = styled.footer`
  position: absolute;
  bottom: 0;
  padding-top: 10px;
  padding-right: 24px;
  width: 35%;
  background: #fff;
  z-index: 1000000;
  border-top: 1px solid #33333310;
`;

export const ButtonGoToAccount = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-decoration: none;
  color: #333;
  svg {
    margin-bottom: 4px;
  }
`;

export const ButtonGoToCart = styled.button`
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    144deg,
    rgba(251, 74, 32, 1) 28%,
    rgba(250, 5, 97, 1) 100%
  );
  border-radius: 50%;
  margin-bottom: 25px;
  position: relative;
  cursor: pointer;
`;

export const ButtonGoToCartSubmit = styled.button`
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background:
    rgba(251, 74, 32, 1) 28%,
    rgba(250, 5, 97, 1) 100%
  );
  border-radius: 50%;
  margin-bottom: 25px;
  position: relative;
  cursor: pointer;
  border:none;
`;

export const CountBadge = styled.div`
  position: absolute;
  top: 0;
  left: 45px;
  font-size: 12px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans- 'se' rif;
  background: #fff;
  width: 22px;
  height: 22px;
  color: rgba(251, 74, 32, 1);
  border: 1px solid rgba(251, 74, 32, 1);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;
`;

export const Content = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  position: fixed;
  width: 100%;
  max-width: 410px;
  height: 60px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-left: 10px;
  padding-right: 10px;

  -webkit-box-shadow: 0px 1px 31px -20px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 1px 31px -20px rgba(120, 120, 120, 1);
  box-shadow: 0px 1px 31px -20px rgba(120, 120, 120, 1);

  h1 {
    font-size: 18px;
  }
`;

export const Text = styled.div`
  margin-top: 80px;
  width: 90%;

  p {
    text-align: center;
    font-size: 14px;
  }
`;

export const SelectGroup = styled.div`
  margin-top: 14px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SelectField = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  p {
    font-size: 14px;
    text-align: left;
    margin-bottom: 4px;
  }

  & + div {
    margin-top: 24px;
  }
`;

export const SelectStyled = styled(Select)`
  width: 100%;
  /* margin-top: -20px; */
  margin-bottom: -20px;

  & + & {
    width: 100%;
  }
`;

export const PersonalSelectStyled = styled(SelectPersonal)`
  width: 100%;

  & + & {
    width: 100%;
  }
`;

export const AddressField = styled.div`
  width: 100%;

  p {
    text-align: left;
    font-weight: 700;
  }
`;

export const StreetInputField = styled.div`
  display: flex;
  margin-top: -20px;
  margin-bottom: -10px;
`;

export const InputField = styled.div`
  p {
    font-size: 14px;
    font-weight: normal;
  }

  margin-top: 16px;
  margin-right: 4px;

  input {
    border-radius: 5px;
    border: 1px solid #33333330;
    padding: 5px 10px;
    width: 100%;
  }
`;

export const AcceptsPhrase = styled.div`
  max-width: 90%;

  margin-top: 16px;
`;

export const ComplementAddress = styled.div``;

export const ButtonOrders = styled.button`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 414px;
  height: 48px;
  background: linear-gradient(
    144deg,
    rgba(251, 74, 32, 1) 28%,
    rgba(250, 5, 97, 1) 100%
  );
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 7px 7px 0px 0px;

  svg {
    margin-right: 4px;
  }
`;

export const Main = styled.div`
  margin-top: 110px;
  width: 100%;

  padding-bottom: 55px;
`;

export const Nav = styled.nav`
  width: 100%;
  height: 40px;
  max-width: 410px;
  background: #fff;
  position: fixed;
  top: 60px;

  border-bottom: 1px solid #33333310;

  -webkit-box-shadow: 0px 1px 31px -20px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 1px 31px -20px rgba(120, 120, 120, 1);
  box-shadow: 0px 1px 31px -20px rgba(120, 120, 120, 1);

  display: flex;
  justify-content: center;
  padding-left: 24px;

  li {
    display: inline-flex;
    list-style: none;
    margin-right: 24px;
    color: #33333390;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;

    & + li {
      border-left: 1px solid #33333390;
      padding-left: 24px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const UserAddress = styled.div`
  width: 90%;
  min-height: 120px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  color: #333;
  border-radius: 7px;
  padding: 8px;
  text-decoration: none;
  -webkit-box-shadow: 0px 0px 21px -17px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 0px 21px -17px rgba(120, 120, 120, 1);
  box-shadow: 0px 0px 21px -17px rgba(120, 120, 120, 1);

  & + div {
    margin-top: 16px;
  }
`;

export const ButtonGroup = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-end;
`;

export const ChangeAddress = styled.button`
  background: transparent;
  border: none;
  color: rgba(251, 74, 32, 1);
  border: 1px solid rgba(251, 74, 32, 1);
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 4px;
`;

export const VerifyAddress = styled.button`
  background: linear-gradient(
    144deg,
    rgba(251, 74, 32, 1) 28%,
    rgba(250, 5, 97, 1) 100%
  );
  border: none;
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
`;

export const ValidAddressTitle = styled.div`
  margin-top: 100px;

  p {
    font-weight: bold;
  }
`;

export const ModalTitle = styled.div`
  p {
    text-align: left;
    font-weight: bold;
  }
`;

export const ModalBody = styled.div`
  width: 100%;
  max-width: 410px;
  margin-top: 8px;

  input {
    margin-top: 18px;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 8px;
  padding-top: 8px;

  border-top: 1px solid #33333310;

  display: flex;
  justify-content: space-around;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #77dd77;
  border: none;
  border-radius: 7px;
`;

export const CancelButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #ff1814;
  border: none;
  border-radius: 7px;
  margin-left: 4px;
`;

export const UserSearchArea = styled.div`
  display: flex;
  align-items: center;
`;

export const InputStyled = styled(InputMask)`
  border: 1px solid #33333320;
  border-radius: 7px;
  height: 44px;
  padding-left: 10px;
  width: 100%;
`;

export const UserSearchInput = styled.input`
  border: 1px solid #33333320;

  border-radius: 5px;
  height: 30px;
  padding: 10px;
`;

export const ErrorPhrase = styled.p`
  margin-top: 10px;
  color: #ff403d;
  font-size: 14px;
`;

export const UserInfoCard = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  margin-top: 16px;
  width: 100%;
  height: auto;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 15px;
  border-radius: 7px;
  filter: ${(props) =>
    props.selected
      ? 'drop-shadow(0 0 4px rgba(250, 0, 0, 0.7));'
      : 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.1))'};

  :hover {
    transition: filter 1s;
    filter: drop-shadow(0 0 4px rgba(250, 0, 0, 0.7));
  }

  svg {
    :hover {
      transition: color 0.5s;
      color: red;
    }
  }
`;

export const DeleteUserInfoButton = styled.button`
  background: linear-gradient(
    144deg,
    rgba(251, 74, 32, 1) 28%,
    rgba(250, 5, 97, 1) 100%
  );
  border: none;
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
`;
