/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { format } from 'date-fns';
import XLSX from 'xlsx-js-style';

async function getProductsData(loss) {
  const sheet_body = [];
  loss.forEach((item) => {
    const sheet_item = [
      item.is_intermediary ? 'Intermediário' : 'Insumo',
      item.name,
      isNaN(item.quantidade)
        ? ' - '
        : parseFloat(item.quantidade),
      item.unidade,
      item.cmv ? parseFloat(item.cmv) : ' - ',
      item.justificative,
      format(new Date(item.created_at), 'dd/MM/yy - HH:mm'),
    ];
    sheet_body.push(sheet_item);
  });

  const sheet_header = [
    'Tipo',
    'Nome',
    'Quantidade',
    'Unidade',
    'Custo',
    'Justificativa',
    'Data',
  ];

  return { data: [sheet_header, ...sheet_body] };
}

export async function generateLossReportSheet(loss) {
  const { data } = await getProductsData(loss);

  const columns = 'abcdefghijklmnopqrstuvwxyz'.toLocaleUpperCase().split('');

  const num_cols = data[0].length;

  const workbook = XLSX.utils.book_new();

  const worksheet = XLSX.utils.aoa_to_sheet(data);
  const colSizes = [];

  for (let i = 0; i < num_cols; i++) {
    let biggest = 0;
    for (let j = 0; j < data.length; j++) {
      if (data[j][i].length > biggest) {
        biggest = data[j][i].length;
      }
    }
    colSizes.push(biggest);
  }

  // Every column with 15 chars of width
  worksheet['!cols'] = new Array(num_cols);
  worksheet['!rows'] = new Array(data.length);
  worksheet['!cols'] = colSizes.map((size) => {
    return { wch: size + 4 };
  });
  worksheet['!rows'].fill({ hpt: 18 });

  console.log('sizes > ', colSizes);
  const num_lines = data.length;

  for (let j = 0; j < num_cols; j++) {
    for (let i = 1; i <= num_lines; i++) {
      const cell = worksheet[columns[j] + i];
      if (cell) {
        if (i === 1) {
          cell.s = {
            numFmt: 50,
            font: {
              bold: true,
            },
            fill: {
              fgColor: {
                rgb: 'ECFFFD',
              },
              bgColor: {
                rgb: 'ECFFFD',
              },
            },
            border: {
              top: {
                style: 'thin',
                color: {
                  rgb: '000000',
                },
              },
              bottom: {
                style: 'thin',
                color: {
                  rgb: '000000',
                },
              },
              left: {
                style: 'thin',
                color: {
                  rgb: '000000',
                },
              },
              right: {
                style: 'thin',
                color: {
                  rgb: '000000',
                },
              },
            },
          };
        }

        if (j > 0) {
          cell.s = {
            ...cell.s,
            alignment: { horizontal: 'center', vertical: 'center' },
          };
        }
      }
    }
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório Produtos');

  return XLSX.write(workbook, { type: 'buffer' });
}
