import { Form } from '@unform/web';
import React, {
  useState,
} from 'react';

import { FiMail } from 'react-icons/fi';
import { toast } from 'react-hot-toast';
import { Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import Input from '~/components/Form/Input';
import api from '~/services/api';

// import { Container } from './styles';

function SendCashierReportButton({ boxId }) {
  const [sendingEmail, setSendingEmail] = useState(false);

  // const toggleSendingEmail = useCallback(() => {
  //   setSendingEmail(!sendingEmail);
  // }, [sendingEmail]);

  // useImperativeHandle(ref, () => {
  //   return {
  //     toggleSendingEmail,
  //   };
  // });

  const [isSendEmailModaOpened, setIsSendEmailModaOpened] = useState(false);
  function toggleSendEmailModal() {
    setIsSendEmailModaOpened(!isSendEmailModaOpened);
  }

  async function handleSendReportToEmail() {
    try {
      setSendingEmail(true);
      await api.post(`/restaurants/cashier-opening/send-email/${boxId}`, {
        email: '',
      });

      toast.success('Email enviado com sucesso');
      setSendingEmail(false);
    } catch (error) {
      console.log(error);
      toast.error('Falha ao enviar email');
      setSendingEmail(false);
    }
  }

  return sendingEmail ? (
    <button
      type="button"
      onClick={() => toggleSendEmailModal()}
      className="btn btn-info d-flex"
      style={{ display: 'flex', alignItems: 'center' }}
    >
      {' '}
      <Spinner size="sm" style={{ marginRight: 8, marginTop: '-4px' }} />
      <span>Enviando</span>
    </button>
  ) : (
    <>
      <button
        type="button"
        onClick={() => handleSendReportToEmail()}
        style={{ display: 'flex', alignItems: 'center', background: "transparent", border: "none" }}

      >
        <span style={{ textDecoration:"underline", color: "#3BD2C1", fontWeight: "bold" }}>
        <FiMail style={{ marginRight: 4 }} color="#3BD2C1" />
          Enviar por email
         
        </span>
      </button>
      <Modal isOpen={isSendEmailModaOpened} toggle={toggleSendEmailModal}>
        <Form onSubmit={handleSendReportToEmail}>
          <ModalBody>
            <Input
              name="email"
              label="Digite o email a ser enviado o relatório"
            />
          </ModalBody>
          <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button type="submit" className="btn btn-success">
              Enviar
            </button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default SendCashierReportButton;
