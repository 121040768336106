import React, {
  useContext,
  createContext,
  useMemo,
  useCallback,
  useState,
  useEffect
} from 'react';
import api from '~/services/api';
import { useAuth } from '../AuthContext';

const TablesContext = createContext();

function TablesProvider({ children }) {
  const { token } = useAuth();

  const [tablesAvailable, setTablesAvailable] = useState([]);
  const [tableMenu, setTableMenu] = useState();

  const getTablesAvailable = useCallback(async () => {
    const response = await api.get('/restaurants/tables', {
      params: {
        status: 'available',
      },
    });

    const tablesAvailableFind = response.data.filter(
      (tables) => tables.table_type !== 'menu'
    );
    const tableMenuFind = response.data.filter(
      (tables) => tables.table_type === 'menu'
    );

    setTablesAvailable(tablesAvailableFind);
    setTableMenu(tableMenuFind[0]);
  }, []);

  useEffect(() => {
    getTablesAvailable();
  }, [token]);

  const value = useMemo(
    () => ({
      tablesAvailable,
      getTablesAvailable,
      tableMenu,
    }),
    [tablesAvailable, getTablesAvailable, tableMenu]
  );

  return (
    <TablesContext.Provider value={value}>{children}</TablesContext.Provider>
  );
}

function useTables() {
  const context = useContext(TablesContext);

  if (!context) {
    throw new Error('useTables must be used within an OrderProvider');
  }

  return context;
}

export { TablesProvider, useTables };
