import React from 'react';
import { Img2, QrCodeArea } from './styles';
import Template from '~/assets/img/template_qrcode.png';

import { useAuth } from '~/context/AuthContext';

const ImgToPrint = React.forwardRef(({ imgSrc }, ref) => {
  const { user } = useAuth();

  return (
    <QrCodeArea
      ref={ref}
      style={{
        backgroundImage: `url(${Template || ''})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <p>{user.fantasy_name}</p>
      <Img2 src={imgSrc} />
    </QrCodeArea>
  );
});

export default ImgToPrint;
