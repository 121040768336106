import React, { useCallback, useEffect, useState } from 'react';
import {
  Modal,
  ModalBody,
  Spinner,
} from 'reactstrap';

import {
  FaCircle,
  FaMinus,
  FaPencilAlt,
  FaPlus,
  FaPrint,
  FaTimes,
  FaTrash,
  FaAngleDown,
} from 'react-icons/fa';
import {
  getDay,
  startOfDay,
  endOfDay,
  format,
  addMinutes,
  subMinutes,
} from 'date-fns';
import { toast } from 'react-hot-toast';
import {
  Content,
  OperationArea,
  OrderSystemArea,
  BillTableArea,
  ButtonsArea,
  ProductsCard,
  CardHeader,
  ButtonCancel,
  XButton,
  ConfirmDiv,
  ConfirmationButton,
  CanceledButton,
  AmountArea,
  SvgContainer,
  AddedItem,
  CardProduct,
  CardComplement,
  CardContent,
  CardHeaderPhone,
  CardHeaderData,
  CardHeaderRadio,
  Fill,
  UserInfoCard,
  CreateUserAddressButton,
  DataPickerArea,
  HourTitle,
  HourDiv,
  HoursAvailable,
  HourCheckbox,
} from './styles';

import { MdCheck } from 'react-icons/md';
import ReactDatePicker from 'react-datepicker';
import { AnimatePresence } from 'framer-motion';

import { useCart } from '~/context/OrderingSystem/Cart';
import Menu from '~/pages/OrderingSystem/Menu';
import api from '~/services/api';
import { useAuth } from '~/context/AuthContext';
import { Button, Checkbox, InformationDiv } from 'ui-kit-takeat';
import Select from 'react-select';
import { FaPen } from 'react-icons/fa';
import CreateAddressModal from './CreateAddressModal';
import EditAddressModal from './EditAddressModal';
import AddMotoboy from './AddMotoboy';
import formatCompleteDate from '~/services/formatCompleteDate';

function TableEditDelivery({ session, item, toggleDeleteDelivery, disabled, handleUpdate, orderReady }) {
  const { closeCart } = useCart();
  const { user, paymentMethodsState } = useAuth();
  const [confirmCancel, setConfirmCancel] = useState([]);
  const [canceled, setCanceled] = useState([]);
  const [added, setAdded] = useState([]);
  const [orderBasket, setOrderBasket] = useState([]);
  const [isModalOperationOpened, setIsModalOperationOpened] = useState(false);
  const [confirmMinimumModal, setConfirmMinimumModal] = useState(false);

  const toggleMinimumModal = () => {
    setConfirmMinimumModal(!confirmMinimumModal);
  };

  function toggleModalOperation() {
    setIsModalOperationOpened(!isModalOperationOpened);
    setAdded([]);
    setCanceled([]);
    setDeliveryMethod('delivery');
    setInitialDeliveryMethod('delivery');
    setPaymentMethodId('');
    setHasDeliveryTax(false);
    setInitialHasDeliveryTax(false);
    setInitialPaymentMethodId('');
    setInitialTaxValue(null);
    setDeliveryTax(0);
    setSelectedAddress(null);
    setHasCreatedAddress(false);
    setInitialSelectedAddress(null);
    setBuyerDeliveryAddresses([]);
    closeCart();
  }
  const getBills = useCallback(async () => {
    try {
      const res = await api.get(
        `restaurants/table-sessions-bills/${item.session_id}`
      );

      setOrderBasket(res.data.bills[0].order_baskets[0]);
      if (res.data.session) {
        setDeliveryMethod(res.data.session.with_withdrawal ? 'retirada' : 'delivery');
        setInitialDeliveryMethod(res.data.session.with_withdrawal ? 'retirada' : 'delivery');
        setPaymentMethodId(res.data.session.intended_payment_method_id);
        setInitialPaymentMethodId(res.data.session.intended_payment_method_id);
        if (res.data.session.delivery_tax_price > 0) {
          setHasDeliveryTax(true);
          setInitialHasDeliveryTax(true);
          setDeliveryTax(res.data.session.delivery_tax_price);
          setInitialTaxValue(res.data.session.delivery_tax_price);
        }
        if (res.data.session.buyer_delivery_address) {
          setSelectedAddress(res.data.session.buyer_delivery_address.id);
          setInitialSelectedAddress(res.data.session.buyer_delivery_address.id);
        }
      }
    } catch (error) {
      toast.error('Erro ao carregar informações');
      console.log('error', error)
    }
  }, [item.id]);

  const calculateNewTotal = (item) => {
    const currentPrice = orderBasket.orders.reduce(
      (acc, cur) => acc + parseFloat(cur.total_price),
      0
    );
    const addedPrice = added.reduce(
      (acc, cur) => acc + cur.amount * parseFloat(cur.product.delivery_price),
      0
    );

    const canceledPrice = canceled.reduce(
      (acc, cur) =>
        acc +
        parseFloat(
          orderBasket.orders.find((o) => o.product.id === cur)?.product
            ?.total_price || 0
        ),

      0
    );

    return (
      currentPrice +
      addedPrice -
      canceledPrice -
      (parseFloat(item?.total_price) || 0)
    );
  };

  const [loadingAlter, setLoadingAlter] = useState(false);

  const alterOrder = async () => {
    const body = {
      added,
      canceled,
    }

    if (deliveryMethod !== initialDeliveryMethod) {
      body.delivery_method = deliveryMethod
      if (deliveryMethod === 'delivery') {
        body.buyer_delivery_address_id = selectedAddress
      }
      if (deliveryMethod === 'retirada' && orderReady) {
        try {
          await api.post(
            `/restaurants/motoboys/relate`,
            {
              motoboy_id: null,
              session_id: item.session_id,
            }
          )
        } catch (error) {
          console.log('error', error);
          toast.error('Erro ao cancelar motoboy de pedido');
          return;
        }
      }
    }

    if (selectedAddress !== initialSelectedAddress && selectedAddress) {
      body.delivery_method = 'delivery'
      body.buyer_delivery_address_id = selectedAddress
    }

    if (hasDeliveryTax !== initialHasDeliveryTax || initialSelectedAddress !== selectedAddress) {
      body.delivery_tax = Number(deliveryTax)
    }

    if (paymentMethodId !== initialPaymentMethodId) {
      body.payment_method_id = paymentMethodId
    }

    if (item.scheduled_to && hourSelected !== previousHourSelected) {
      body.scheduled_to = hourSelected
    }

    setLoadingAlter(true);
    try {
      const response = await api.put(
        `/restaurants/delivery/orders/alter/${item.session_id}`,
        body,
      );

      const newOrder = response.data;
      item.basket.total_price = newOrder.total_price;
      item.total_delivery_price = newOrder.total_delivery_price;
      setIsModalOperationOpened(false);
      closeCart();
      setAdded([]);
      setCanceled([]);
      handleUpdate();
      toast.success('Pedido alterado com sucesso');
    } catch (err) {
      console.log('alterOrder error: ', err);
    }
    setLoadingAlter(false);
  };

  const handleIncreaseAmount = (item) => {
    setAdded((state) =>
      state.map((s) => {
        if (s.product.id === item.product.id) {
          return { ...s, amount: s.amount + 1 };
        }
        return s;
      })
    );
  };

  const handleDecreaseAmount = (item) => {
    setAdded((state) =>
      state
        .map((s) => {
          if (s.product.id === item.product.id) {
            return { ...s, amount: s.amount - 1 };
          }
          return s;
        })
        .filter((s) => s.amount > 0)
    );
  };



  const [initialDeliveryMethod, setInitialDeliveryMethod] = useState('delivery');
  const [initialPaymentMethodId, setInitialPaymentMethodId] = useState('');
  const [initialHasDeliveryTax, setInitialHasDeliveryTax] = useState(false);
  const [initialSelectedAddress, setInitialSelectedAddress] = useState(null);
  const [initialTaxValue, setInitialTaxValue] = useState(null);
  const [hasCreatedAddress, setHasCreatedAddress] = useState(false);

  const [deliveryMethod, setDeliveryMethod] = useState('delivery');
  const [hasDeliveryTax, setHasDeliveryTax] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethodId, setPaymentMethodId] = useState('');
  const [buyerDeliveryAddresses, setBuyerDeliveryAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [deliveryTax, setDeliveryTax] = useState(0);

  const [allPaymentMethods, setAllPaymentMethods] = useState([]);
  const getPaymentMethods = useCallback(async () => {
    try {
      const allPM = paymentMethodsState.map((method) => {
        return {
          label: method.name,
          value: method.id,
        }
      });
      setAllPaymentMethods(allPM);
      const paymentOptions = deliveryMethod === "delivery" ? paymentMethodsState
        .filter((item) => item.delivery_accepts && item.keyword !== 'pix_auto' && item.keyword !== 'online_ifood')
        .map((method) => {
          return {
            label: method.name,
            value: method.id,
          };
        }) : paymentMethodsState
          .filter((item) => item.withdrawal_accepts && item.keyword !== 'pix_auto' && item.keyword !== 'online_ifood')
          .map((method) => {
            return {
              label: method.name,
              value: method.id,
            };
          })

      setPaymentMethods(paymentOptions);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [paymentMethodsState, deliveryMethod]);

  useEffect(() => {
    if (isModalOperationOpened) {
      getBills();
      getPaymentMethods();
      getUserInfo();
    }
  }, [isModalOperationOpened]);

  const [isModalEditAddressOpened, setIsModalEditAddressOpened] = useState(false);

  const [userEditInfo, setUserEditInfo] = useState();

  function toggleModalEditUserAddress(data) {
    setIsModalEditAddressOpened(!isModalEditAddressOpened);
    setUserEditInfo(data);
  }

  const [isModalCreateAddressOpened, setIsModalCreateAddressOpened] = useState(false);

  function toggleModalCreateUser() {
    setIsModalCreateAddressOpened(!isModalCreateAddressOpened);
  }

  const openModal = () => {
    setIsModalOperationOpened(true);
  }

  const getDeliveryTaxByAddress = async (addressId) => {
    try {
      const response = await api.post('/restaurants/buyers/delivery-tax', {
        buyer_address_id: addressId,
      });

      return response.data;
    } catch (error) {
      console.log(error.message);
      return -1;
    }
  };

  const handleChangeDeliveryTax = () => {
    if (paymentMethodId === 301 || paymentMethodId === 572 || paymentMethodId === 2548) {
      toast.error('Não é possível alterar taxa de entrega para pagamentos online.');
      return;
    }
    if (deliveryMethod === 'retirada') {
      if (initialTaxValue !== null) {
        if (hasDeliveryTax) {
          setHasDeliveryTax(false);
          setDeliveryTax(0);
          return;
        }
        if (!hasDeliveryTax) {
          setHasDeliveryTax(true);
          setDeliveryTax(initialTaxValue);
          return;
        }
      }
      toast.error('Não é possível adicionar taxa de entrega para retirada');
      return
    }
    if (!selectedAddress) {
      toast.error('Selecione um endereço');
      return;
    }
    if (buyerDeliveryAddresses.length > 0 && selectedAddress && !hasDeliveryTax) {
      setHasDeliveryTax(!hasDeliveryTax);
      setDeliveryTax(buyerDeliveryAddresses.find((a) => a.id === selectedAddress).delivery_tax);
    } else if (buyerDeliveryAddresses.length > 0 && hasDeliveryTax) {
      setHasDeliveryTax(!hasDeliveryTax);
      setDeliveryTax(0);
    } else if (buyerDeliveryAddresses.length === 0) {
      toast.error('Usuário não possui endereço de entrega');
    }
  }

  const handleChangeRadio = (e) => {
    setDeliveryMethod(e)
    if (initialDeliveryMethod === 'retirada' && e === 'retirada') {
      setSelectedAddress(null);
      setHasDeliveryTax(false);
      setDeliveryTax(0);
      return
    }
    if (initialDeliveryMethod === 'retirada' && e === 'delivery') {
      if (buyerDeliveryAddresses.length === 1) {
        if (buyerDeliveryAddresses[0].delivery_tax >= 0) {
          setSelectedAddress(buyerDeliveryAddresses[0].id);
          setHasDeliveryTax(true);
          setDeliveryTax(buyerDeliveryAddresses[0].delivery_tax);
        }
      }
    }
  }

  const getUserInfo = async () => {
    setBuyerDeliveryAddresses([]);
    try {
      const response = await api.get(
        `/restaurants/buyers?phone=${item.buyer.phone}`
      );
      const data = response.data;
      await getAddressesDeliveryTax(data.delivery_addresses);
    } catch (error) {
      console.log('getUserInfo', error);
    }
  }

  const getAddressesDeliveryTax = async (data) => {
    data.forEach(async (address) => {
      try {
        const addressTax = await getDeliveryTaxByAddress(address.id);
        setBuyerDeliveryAddresses((state) => {
          return [
            ...state,
            {
              ...address,
              delivery_tax: addressTax,
            },
          ];
        });
      } catch (error) {
        console.log('getAddressesDeliveryTax', error);
      }
    });
  }


  const handleChangeAddress = (addressId) => {
    const addressDeliveryTax = buyerDeliveryAddresses.find((a) => a.id === addressId).delivery_tax;
    if (addressDeliveryTax === -1) {
      toast.error('Endereço não atendido. Por favor selecione outro endereço.');
      return;
    }
    setSelectedAddress(addressId);
    setHasDeliveryTax(true);
    setDeliveryTax(addressDeliveryTax);
  }

  useEffect(() => {
    if (hasCreatedAddress) {
      buyerDeliveryAddresses.forEach((address) => {
        if (address.delivery_tax >= 0) {
          setSelectedAddress(address.id);
          setHasDeliveryTax(true);
          setDeliveryTax(address.delivery_tax);
        } else if (address.delivery_tax === -1) {
          toast.error('Endereço não atendido. Por favor selecione outro endereço.');
        }
      });
    }
  }, [buyerDeliveryAddresses, hasCreatedAddress])

  const [modalMotoboy, setModalMotoboy] = useState(false);
  const toggleModalMotoboy = () => {
    setModalMotoboy(!modalMotoboy);
  };

  const [isSchedluing, setIsSchedluing] = useState(false);
  const [loadingTimes, setLoadingTimes] = useState(false);
  const [hours, setHours] = useState([]);
  const [previousHourSelected] = useState(item?.scheduled_to);
  const [hourSelected, setHourSelected] = useState(item?.scheduled_to);
  const [startDate, setStartDate] = useState(startOfDay(new Date(item?.scheduled_to)));
  const [restaurantSettings, setRestaurantSettings] = useState(null);
  const [activeDays, setRestaurantActiveDays] = useState([]);
  const [maxDate, setMaxDate] = useState(null);

  const getRestaurantConfigs = useCallback(async () => {
    try {
      const response = await api.get(`/restaurants/order-scheduling/`);
      setRestaurantSettings(response.data);
    } catch (err) {
      console.log('getRestaurantConfigs error: ', err);
    }
  }, []);

  useEffect(() => {
    if (isModalOperationOpened && item?.scheduled_to) {
      getRestaurantConfigs();
    }
  }, [getRestaurantConfigs, isModalOperationOpened, item?.scheduled_to]);

  useEffect(() => {
    if (!restaurantSettings) {
      setRestaurantActiveDays([]);
      return;
    }

    const daysFilter = [];

    const active_days =
      deliveryMethod === 'delivery'
        ? restaurantSettings.active_days
        : restaurantSettings.withdrawal_active_days;

    for (let i = 0; i < 7; i += 1) {
      if (active_days[i] === 't') {
        daysFilter.push(i);
      }
    }

    const max_time_in_advance =
      deliveryMethod === 'delivery'
        ? restaurantSettings.max_time_in_advance
        : restaurantSettings.withdrawal_max_time_in_advance;

    if (max_time_in_advance) {
      const start = startOfDay(new Date());
      const end = endOfDay(addMinutes(start, max_time_in_advance));

      setMaxDate(end);
    } else {
      setMaxDate(null);
    }

    setRestaurantActiveDays(daysFilter);
  }, [restaurantSettings, deliveryMethod]);

  const getTimes = async (day) => {
    setLoadingTimes(true);
    const orderType = deliveryMethod === 'delivery' ? 'delivery' : 'withdrawal';
    try {

      const response = await api.get(
        '/restaurants/order-scheduling/available-times',
        {
          params: {
            day,
            with_withdrawal: orderType.toString() === 'withdrawal',
          },
        }
      );

      setHours(response.data.times);
    } catch (err) {
      console.log('getTimes error: '.err);
    }
    setLoadingTimes(false);
  };

  const handleDateUpdate = (date) => {
    setStartDate(date);
  };

  useEffect(() => {
    const day = startDate.getDay();
    if (activeDays.includes(day)) {
      getTimes(startDate.toISOString());
    }
  }, [startDate]);

  const getFirstLetter = (word) => {
    return word.substring(0, 1).toUpperCase();
  };

  const isWeekday = (date) => {
    const day = getDay(date);

    const ref_date = new Date(date).getTime();

    if (ref_date < startOfDay(new Date())) {
      return false;
    }

    if (maxDate) {
      if (ref_date > maxDate.getTime()) {
        return false;
      }
    }
    // activeDays.split('').map(item => {
    //   return {

    //   }
    // })

    return activeDays.includes(day);
  };

  return (
    <Content
    /* onClick={} */
    /* disabled={disabled} */
    >
      <button
        onClick={() => openModal()}
        disabled={disabled}
        style={{
          border: 'none',
          background: 'transparent',
        }}
      >
        <FaPencilAlt size={16} color="#FE1933" />
      </button>
      {isModalCreateAddressOpened && (
        <CreateAddressModal
          isModalCreateAddressOpened={isModalCreateAddressOpened}
          toggleModalCreateUser={toggleModalCreateUser}
          user={user}
          item={item}
          getUserInfo={getUserInfo}
          setHasCreatedAddress={setHasCreatedAddress}
        />
      )}
      {isModalEditAddressOpened && (
        <EditAddressModal
          isModalEditAddressOpened={isModalEditAddressOpened}
          toggleModalEditUserAddress={toggleModalEditUserAddress}
          item={item}
          user={user}
          userEditInfo={userEditInfo}
          getUserInfo={getUserInfo}
        />
      )}
      {modalMotoboy && (
        <AddMotoboy
          toggleModalMotoboy={toggleModalMotoboy}
          item={item}
          user={user}
          modalMotoboy={modalMotoboy}
          alterOrder={alterOrder}
        />
      )}
      <Modal isOpen={confirmMinimumModal} toggle={toggleMinimumModal}>
        <ModalBody style={{ fontSize: 18 }}>
          Essa alteração deixará o total do pedido <b>abaixo do mínimo</b> do
          delivery. Deseja alterar mesmo assim?
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Button
              title="Não"
              onClick={() => {
                toggleMinimumModal();
                confirmCancel.pop();
              }}
            />
            <Button
              title="Sim"
              buttonColor="#2ec9b7"
              onClick={() => {
                toggleMinimumModal();
                setCanceled((state) => [
                  ...state,
                  confirmCancel[confirmCancel.length - 1],
                ]);
              }}
            />
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={isModalOperationOpened}
        toggle={toggleModalOperation}
        size='takeat'
      >
        <ModalBody>
          <OperationArea>
            <OrderSystemArea>
              <Menu
                toggleModalOperation={toggleModalOperation}
                setAdded={setAdded}
                isTableDelivery
              />
            </OrderSystemArea>
            <BillTableArea>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <h2>Pedido#{item?.basket?.basket_id}</h2>
                <h2>Delivery</h2>
              </div>

              {item.ifood_on_demand_id &&
                <InformationDiv
                  text='Esse pedido já está confirmado com o Entrega Fácil iFood, então não é possível alterar informações'
                  type='warning'
                  containerStyles={{ marginBottom: 5 }}
                />
              }

              <ProductsCard>
                <CardHeader>
                  <CardHeaderPhone>
                    <FaPrint size={20} />
                    <div>
                      {item.buyer && (
                        <span>{item.buyer.phone}</span>
                      )}
                      <span style={{ fontWeight: '500' }}>#{item?.basket?.basket_id} / #{item?.attendance_password}</span>
                    </div>
                  </CardHeaderPhone>
                  <span style={{ height: '1px', backgroundColor: '#fff' }} />
                  <CardHeaderData>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                    }}>
                      <CardHeaderRadio>
                        <input
                          type="radio"
                          name="deliveryMethod"
                          value="delivery"
                          id="delivery"
                          onChange={(e) => handleChangeRadio(e.target.value)}
                          checked={deliveryMethod === 'delivery'}
                          disabled={item.ifood_on_demand_id}
                        />
                        <Fill style={{ visibility: deliveryMethod === 'delivery' ? 'visible' : 'hidden' }} />
                        <div>
                          <span>Delivery</span>
                        </div>
                      </CardHeaderRadio>
                      <CardHeaderRadio>
                        <input
                          type="radio"
                          name="deliveryMethod"
                          value="retirada"
                          id="retirada"
                          onChange={(e) => handleChangeRadio(e.target.value)}
                          checked={deliveryMethod === 'retirada'}
                          disabled={item.ifood_on_demand_id}
                        />
                        <Fill style={{ visibility: deliveryMethod === 'retirada' ? 'visible' : 'hidden' }} />
                        <div>
                          <span>Retirada</span>
                        </div>
                      </CardHeaderRadio>
                    </div>
                    <div>
                      <Checkbox
                        label="Taxa de entrega"
                        checked={hasDeliveryTax}
                        onClick={handleChangeDeliveryTax}
                        customColor='#6C6C6C'
                        disabled={item.ifood_on_demand_id}
                      />
                    </div>
                  </CardHeaderData>
                  {deliveryMethod === 'delivery' &&
                    buyerDeliveryAddresses.length > 0 &&
                    buyerDeliveryAddresses.map((address) => {
                      return (
                        <UserInfoCard
                          selected={selectedAddress === address.id}
                        >
                          <button
                            style={{
                              background: 'transparent',
                              border: 'none',
                              textAlign: 'left',
                              width: '90%',
                            }}
                            onClick={() => {
                              handleChangeAddress(address.id);
                            }}
                            type="button"
                            title="Selecionar endereço"
                            disabled={item.ifood_on_demand_id}
                          >
                            <p>
                              {address.street} - {address.number}
                            </p>
                            <p>
                              {address.neighborhood} - {address.zip_code}
                            </p>

                            <p>
                              {address.city} - {address.state}
                            </p>
                            <p style={{ textDecoration: !hasDeliveryTax ? 'line-through' : 'none' }}>
                              {address.delivery_tax >= 0 && `Taxa de entrega: R$ ${address.delivery_tax.toFixed(2)}`}
                              {address.delivery_tax === -1 && 'Endereço não atendido'}
                            </p>
                          </button>

                          {initialSelectedAddress !== address.id && (
                            <div>
                              <FaPen
                                onClick={() => {
                                  toggleModalEditUserAddress(address);
                                }}
                                title="Editar endereço"
                                disabled={item.ifood_on_demand_id}
                              />
                            </div>
                          )}
                        </UserInfoCard>
                      )
                    }
                    )}
                  {buyerDeliveryAddresses.length === 0 && deliveryMethod === 'delivery' && (
                    <>
                      <div>Usuário ainda não pediu no delivery, favor preencher os dados abaixo.</div>
                      <CreateUserAddressButton
                        type="button"
                        onClick={toggleModalCreateUser}
                        disabled={item.ifood_on_demand_id}
                      >
                        Adicionar endereço{' '}
                      </CreateUserAddressButton>
                    </>
                  )}
                  {item.scheduled_to && (
                    <div
                      style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center', cursor: 'pointer' }}
                      onClick={() => {
                        setIsSchedluing(!isSchedluing);
                      }}
                    >
                      <FaAngleDown
                        size={20}
                        title={`Agendado para ${formatCompleteDate(item.scheduled_to)}`}
                        style={{
                          transition: 'all 0.2s',
                          transform: !isSchedluing
                            ? 'rotate(-90deg)'
                            : 'rotate(0)',
                        }}
                      />
                      <span style={{ lineHeight: 0 }}>
                        {`Agendado para ${formatCompleteDate(item.scheduled_to)}`}
                      </span>
                    </div>
                  )}
                  {isSchedluing && (
                    <DataPickerArea>
                      <div style={{ position: 'relative' }}>
                        <ReactDatePicker
                          dateFormat="dd MMMM yyyy"
                          formatWeekDay={(day) => getFirstLetter(day)}
                          locale="pt"
                          selected={startDate}
                          onSelect={(e) => handleDateUpdate(e)}
                          onChange={(e) => handleDateUpdate(e)}
                          customInput={<span />}
                          open
                          filterDate={isWeekday}
                        />
                      </div>

                      <HourDiv>
                        {/* {!daySelected && <p>Selecione o dia desejado para que os horários sejam exibidos.</p>} */}
                        <HourTitle>
                          <span>Hora</span>
                        </HourTitle>

                        <HoursAvailable>
                          <AnimatePresence>
                            {loadingTimes ? (
                              <Spinner style={{ margin: '15px auto', display: 'block' }}>
                                <span className="visually-hidden">Carregando...</span>
                              </Spinner>
                            ) : (
                              hours?.map((h, i) => {
                                const hour = new Date(h);
                                const isDisabled =
                                  subMinutes(hour, restaurantSettings.time_in_advance) <
                                  new Date();

                                return (
                                  <HourCheckbox
                                    initial={{ x: -200 }}
                                    animate={{ x: 0 }}
                                    exit={{ x: -200 }}
                                    transition={{
                                      type: 'spring',
                                      stiffness: 50,
                                      mass: 1,
                                    }}
                                    key={i}
                                    onClick={() => {
                                      if (!isDisabled) {
                                        /* setHourSelected( */
                                        /*   hour.getHours() > 21 ? addDays(hour, 1) : h */
                                        /* ); */
                                        setHourSelected(h);
                                      }
                                    }}
                                    selected={h === hourSelected}
                                    disabled={isDisabled}
                                  >
                                    <div>
                                      {!isDisabled && (
                                        <MdCheck size={15} color="#ffffff" />
                                      )}
                                    </div>
                                    <p style={{ color: isDisabled && '#979797' }}>
                                      {format(hour, "HH'h'mm")}
                                    </p>
                                  </HourCheckbox>
                                );
                              })
                            )}
                          </AnimatePresence>
                        </HoursAvailable>
                      </HourDiv>
                    </DataPickerArea>
                  )}
                  {buyerDeliveryAddresses.length === 1 && deliveryMethod === 'delivery' && (
                    <>
                      <div>Deseja cadastrar novo endereço para cliente?</div>
                      <CreateUserAddressButton
                        type="button"
                        onClick={toggleModalCreateUser}
                        disabled={item.ifood_on_demand_id}
                      >
                        Adicionar novo endereço{' '}
                      </CreateUserAddressButton>
                    </>
                  )}
                  <CardHeaderData>
                    <span style={{ width: '200px' }}>Método de Pgmto.</span>
                    <div style={{
                      width: '100%',
                    }}>
                      {paymentMethods.length >= 0 && (
                        <Select
                          options={paymentMethods}
                          placeholder="Método de pagamento"
                          value={allPaymentMethods.find((m) => m.value == paymentMethodId)}
                          isDisabled={item.ifood_on_demand_id}
                          onChange={(e) => {
                            if (paymentMethodId === 301 || paymentMethodId === 572 || paymentMethodId === 2548) {
                              toast.error('Não é possível alterar o método de pagamento para pagamentos online.');
                              return;
                            }
                            setPaymentMethodId(e.value);
                          }}
                        />
                      )}
                    </div>
                  </CardHeaderData>
                  {deliveryMethod === 'retirada' && initialTaxValue && (
                    <p style={{ fontSize: '12px', textDecoration: !hasDeliveryTax ? 'line-through' : 'none' }}>
                      Taxa de entrega: R$ {initialTaxValue}
                    </p>
                  )}
                </CardHeader>
                {orderBasket.orders?.map((o) => {
                  const check = confirmCancel.includes(o.id);
                  const checkCancel = canceled.includes(o.id);
                  return (
                    <CardContent>
                      <CardProduct canceled={checkCancel}>
                        <span>
                          {o.amount}x {o.product.name}
                        </span>
                        <span>
                          {format(new Date(orderBasket.start_time), 'HH:MM')}
                        </span>
                        <span>R${o.total_price}</span>
                        <ButtonCancel>
                          {checkCancel ? (
                            <CanceledButton>Cancelado</CanceledButton>
                          ) : check ? (
                            <ConfirmDiv>
                              <ConfirmationButton
                                confirm
                                style={{ marginRight: '5px' }}
                                onClick={() => {
                                  console.log('calc > ', calculateNewTotal(o));
                                  if (
                                    calculateNewTotal(o) >=
                                    parseFloat(
                                      user.delivery_info.delivery_minimum_price
                                    )
                                  ) {
                                    setCanceled((state) => [...state, o.id]);
                                  } else {
                                    toggleMinimumModal();
                                  }
                                }}
                              >
                                Sim
                              </ConfirmationButton>
                              <ConfirmationButton
                                onClick={() =>
                                  setConfirmCancel((state) =>
                                    state.filter((s) => s !== o.id)
                                  )
                                }
                              >
                                Não
                              </ConfirmationButton>
                              <span>Quer cancelar?</span>
                            </ConfirmDiv>
                          ) : (
                            <XButton
                              onClick={() =>
                                setConfirmCancel((state) => [...state, o.id])
                              }
                              disabled={item.ifood_on_demand_id}
                            >
                              <FaTimes size={20} color="#ffffff" />
                            </XButton>
                          )}
                        </ButtonCancel>
                      </CardProduct>
                      {o.complement_categories?.map((cc) => {
                        return cc.order_complements?.map((oc) => {
                          return (
                            <CardComplement>
                              <FaCircle size={7} />
                              <span style={{ flexGrow: 1 }}>
                                {oc.amount}x {oc.complement.name}
                              </span>
                              {oc.price > 0 && <span>R${oc.price}</span>}
                            </CardComplement>
                          );
                        });
                      })}
                    </CardContent>
                  );
                })}
              </ProductsCard>
              {added.length > 0 && (
                <div style={{ marginTop: '10px' }}>
                  <span
                    style={{
                      fontFamily: 'Poppins',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      fontSize: '16px',
                      color: '#4D4D4C',
                    }}
                  >
                    Novos itens
                  </span>
                  {added.map((a) => {
                    return (
                      <div style={{ marginBottom: '20px' }}>
                        <AddedItem>
                          <span>{a?.product.name}</span>
                          <AmountArea>
                            <SvgContainer
                              onClick={() => handleDecreaseAmount(a)}
                            >
                              <FaMinus size={14} />
                            </SvgContainer>
                            <span
                              style={{ minWidth: '12px', textAlign: 'center' }}
                            >
                              {a?.amount}
                            </span>
                            <SvgContainer
                              onClick={() => handleIncreaseAmount(a)}
                            >
                              <FaPlus size={14} />
                            </SvgContainer>
                            <FaTrash
                              size={16}
                              color="#FF2C3A"
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                setAdded((state) =>
                                  state.filter(
                                    (s) => s.product.id != a?.product.id
                                  )
                                )
                              }
                            />
                          </AmountArea>
                        </AddedItem>
                        {a.complementsCategory.map((cc) =>
                          cc.complements.map((c) => {
                            return (
                              <div style={{ marginLeft: '20px' }}>
                                <FaCircle
                                  size={6}
                                  style={{ marginRight: '5px' }}
                                />
                                {c.amount}x {c.name}
                              </div>
                            );
                          })
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
              <ButtonsArea>
                <Button
                  onClick={toggleDeleteDelivery}
                  title="Cancelar pedido inteiro"
                  buttonColor='#FF2C3A'
                  containerStyles={{ height: 43 }}
                  disabled={item.ifood_on_demand_id}
                />
                {orderReady && initialDeliveryMethod === 'retirada' && deliveryMethod === 'delivery' ? (
                  <Button
                    disabled={
                      added.length + canceled.length === 0
                      && initialPaymentMethodId === paymentMethodId
                      && initialDeliveryMethod === deliveryMethod
                      && initialSelectedAddress === selectedAddress
                      && initialHasDeliveryTax === hasDeliveryTax
                      || loadingAlter || item.ifood_on_demand_id
                    }
                    containerStyles={{ height: 43 }}
                    isLoading={loadingAlter}
                    title="Alterar pedido"
                    buttonColor="#2ec9b7"
                    onClick={() => {
                      if (deliveryMethod === 'delivery' && selectedAddress === null) {
                        toast.error('Usuário não possui endereço de entrega. Por favor selecione um endereço.');
                        return;
                      }
                      toggleModalMotoboy();
                    }}
                  />
                ) : (
                  <Button
                    disabled={
                      added.length + canceled.length === 0
                      && initialPaymentMethodId === paymentMethodId
                      && initialDeliveryMethod === deliveryMethod
                      && initialSelectedAddress === selectedAddress
                      && initialHasDeliveryTax === hasDeliveryTax
                      && previousHourSelected === hourSelected
                      || loadingAlter || item.ifood_on_demand_id
                    }
                    containerStyles={{ height: 43 }}
                    isLoading={loadingAlter}
                    title="Alterar pedido"
                    buttonColor="#2ec9b7"
                    onClick={() => {
                      if (deliveryMethod === 'delivery' && selectedAddress === null) {
                        toast.error('Usuário não possui endereço de entrega. Por favor selecione um endereço.');
                        return;
                      }
                      alterOrder();
                    }}
                  />
                )}
              </ButtonsArea>
            </BillTableArea>
          </OperationArea>
        </ModalBody>
      </Modal>
    </Content>
  );
}

export default TableEditDelivery;