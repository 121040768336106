import { Form } from '@unform/web';
import {
  addHours,
  endOfDay,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subHours,
} from 'date-fns';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { toast } from 'react-hot-toast';
import { Col, Collapse, Row } from 'reactstrap';
import Input from '~/components/Form/Input';

import api from '~/services/api';

import {
  Container,
  Content,
  DateDiv,
  DateSelectorDiv,
  SelectDateButton,
  SubmitDate,
  WaitersList,
  WaiterName,
  WaiterItem,
  WaiterTax,
} from './styles';

function ReportWaiters({ initialDate, finalDateComponent }) {
  const [waiters, setWaiters] = useState([]);
  const formRef = useRef(null);

  const defaultInicial = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const inicialFormat = format(new Date(initialDate), 'dd/MM/yyyy, HH:mm');
  const finalFormat = format(new Date(finalDateComponent), 'dd/MM/yyyy, HH:mm');
  const phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [inicialDate, setInicialDate] = useState(initialDate);
  const [finalDate, setFinalDate] = useState(finalDateComponent);
  const [hourPhrase, setHourPhrase] = useState(phrase);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  function setToday() {
    setInicialDate(
      format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfDay(subHours(new Date(), 27)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setWeek() {
    setInicialDate(
      format(
        startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
        "yyyy-MM-dd'T'HH:mm:ss"
      )
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setMonth() {
    setInicialDate(
      format(startOfMonth(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfMonth(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  const [initialPre, setInitialPre] = useState(inicialDate);
  const [finalPre, setFinalPre] = useState(finalDate);
  const [totalWaiters, setTotalWaiters] = useState(0);
  const [totalBox, setTotalBox] = useState(0);

  function handleChangeDate() {
    setInicialDate(format(new Date(initialPre), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(new Date(finalPre), "yyyy-MM-dd'T'HH:mm:ss"));
    const inicialFormat = format(new Date(initialPre), 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(new Date(finalPre), 'dd/MM/yyyy, HH:mm');
    const phrase = `De ${inicialFormat} até ${finalFormat}`;

    setHourPhrase(phrase);
  }

  const getPayments = useCallback(async () => {
    const response = await api.get('restaurants/reports/cashier', {
      params: {
        start_date: `${format(
          addHours(new Date(inicialDate), 3),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}`,
        end_date: `${format(
          addHours(new Date(finalDate), 3),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}`,
      },
    });

    const total = response.data.total_service_tax;
    setTotalBox(total);
    console.log(total);
  }, [finalDate, inicialDate]);

  const getWaiters = useCallback(async () => {
    try {
      const response = await api.get('restaurants/reports/waiters', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });

      const totalValueWaiter = response.data.reduce((acum, curr) => {
        return acum + Number(curr.sum);
      }, 0);

      setTotalWaiters(totalValueWaiter);

      const waitersOrderedBySum = response.data.sort((a, b) => {
        return a.sum + b.sum;
      });

      setWaiters(waitersOrderedBySum);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [inicialDate, finalDate]);

  useEffect(() => {
    getWaiters();
    getPayments();
  }, [getWaiters, getPayments]);

  return (
    <Container>
      <Content>
        <h2 style={{ marginLeft: 10, marginTop: 10 }}>Relatório de garçons</h2>
        <Row>
          <Col>
            <p style={{ marginLeft: 10, marginTop: 10 }}>{hourPhrase}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <SelectDateButton
                className="btn-round mr-auto"
                onClick={setToday}
                color="info"
              >
                Hoje / Ontem
              </SelectDateButton>
              <SelectDateButton
                className="btn-round mr-auto"
                onClick={setWeek}
                color="info"
              >
                Essa semana
              </SelectDateButton>
              <SelectDateButton
                className="btn-round mr-auto"
                onClick={setMonth}
                color="info"
              >
                Esse mês
              </SelectDateButton>
              <SelectDateButton
                className="btn-round mr-auto"
                onClick={toggle}
                color="info"
              >
                Selecionar Período
                <MdArrowDropDown color="white" size={20} />
              </SelectDateButton>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <div>
              <Collapse isOpen={isOpen}>
                <Form onSubmit={handleChangeDate} ref={formRef}>
                  <DateSelectorDiv>
                    <DateDiv>
                      <Input
                        id="datetime-local"
                        label="Data Inicial"
                        type="datetime-local"
                        name="initialDate"
                        onChange={(e) => setInitialPre(e.target.value)}
                        defaultValue={defaultInicial}
                        className="data"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </DateDiv>
                    <DateDiv>
                      <Input
                        id="datetime-local"
                        label="Data Final"
                        type="datetime-local"
                        name="finalDate"
                        onChange={(e) => setFinalPre(e.target.value)}
                        defaultValue={defaultFinal}
                        className="data"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </DateDiv>

                    <SubmitDate type="submit">Filtrar</SubmitDate>
                  </DateSelectorDiv>
                </Form>
              </Collapse>
            </div>
          </Col>
        </Row>

        <WaitersList>
          <WaiterItem>
            <WaiterName style={{ fontWeight: 'bold' }}>Nome</WaiterName>{' '}
            <WaiterTax style={{ color: '#33333370', fontWeight: 'bold' }}>
              {' '}
              Total Vendas{' '}
            </WaiterTax>
            <WaiterTax style={{ fontWeight: 'bold' }}>
              Taxa de serviço
            </WaiterTax>
          </WaiterItem>
          {waiters &&
            waiters.length > 0 &&
            waiters.map((waiter) => (
              <WaiterItem>
                <WaiterName>{waiter?.waiter?.name || 'Outros'}</WaiterName>{' '}
                <WaiterTax
                  style={{ color: '#33333370' }}
                >{`R$ ${waiter?.sum}`}</WaiterTax>
                <WaiterTax>{`R$ ${(
                  (totalBox * waiter.sum) /
                  totalWaiters
                ).toFixed(2)}`}</WaiterTax>
              </WaiterItem>
            ))}
        </WaitersList>
      </Content>
    </Container>
  );
}
export default ReportWaiters;
