import styled from 'styled-components';

export const Container = styled.div`
  width: 60%;
  min-width: 600px;
  margin: 10px auto 30px auto;

  background: orange;
  color: #fff;

  border-radius: 8px;
  padding: 30px;

  p {
    text-align: center;
    font-size: 22px;
  }

  @media(max-width: 1300px){
    min-width: 300px;
  }
`;
