import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { FaSearch } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';

import styled from 'styled-components';

export const InputSearchArea = styled.div`
  display: flex;
  align-items: center;

  height: 44px;

  border: 1px solid #33333320;
  border-radius: 4px;

  input {
    border: none;
    width: 100%;
  }

  button {
    margin-left: 8px;
    border: none;
    background-color: transparent;
  }

  span {
    margin-left: 8px;
    margin-right: 8px;

    border: none;
    background-color: transparent;
  }
`;

function SearchInput({ name, maxlength, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, inputRef, registerField]);

  return (
    <InputSearchArea>
      <span>
        <FaSearch color="#999" />
      </span>

      <input
        ref={inputRef}
        defaultValue={defaultValue}
        maxLength={maxlength}
        {...rest}
      />

      <span>
        <FiX
          color="#999"
          title="Limpar seleção"
          cursor="pointer"
          onClick={() => {
            inputRef.current.value = '';
          }}
        />
      </span>
    </InputSearchArea>
  );
}

export default SearchInput;
