import React from 'react';

import CategoryThumbnail from './Category';

import { Container, CategoryHorizontalScroll } from './styles';

export default function CategoriesMenu({
  menu,
  handleChangeCategory,
  isTableDelivery,
}) {
  return (
    <Container>
      <span style={{ fontWeight: 'bold' }}>Categorias111</span>
      <nav>
        <CategoryHorizontalScroll>
          {isTableDelivery
            ? menu
                ?.map((cat) => cat)
                .filter((category) => category.available_in_delivery)
                .map((item) => (
                  <React.Fragment key={item.id}>
                    {item?.products?.length > 0 && (
                      <CategoryThumbnail
                        category={item}
                        handleChangeCategory={handleChangeCategory}
                      />
                    )}
                  </React.Fragment>
                ))
            : menu
                ?.map((cat) => cat)
                .filter((category) => category.available)
                .map((item) => (
                  <React.Fragment key={item.id}>
                    {item?.products?.length > 0 && (
                      <CategoryThumbnail
                        category={item}
                        handleChangeCategory={handleChangeCategory}
                      />
                    )}
                  </React.Fragment>
                ))}
        </CategoryHorizontalScroll>
      </nav>
    </Container>
  );
}
