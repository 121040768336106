import React from "react";
import { Button, Container, Fill, Group } from "./styles";

const RadioGroup = ({
  selected,
  onClick,
  options,
  horizontal,
  labelStyle,
}) => {
  return (
    <Group horizontal={horizontal}>
      {options.map((op) => (
        <Container
          onClick={() => {
            if (onClick) {
              onClick(op.value);
            }
          }}
        >
          <Button>
            <Fill selected={selected === op.value} />
          </Button>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={labelStyle}>
              {op.label}
            </span>
            {op.description && (
              <span style={{ fontSize: '14px' }}>
                {op.description}
              </span>
            )}
          </div>
        </Container>
      ))}
    </Group>
  );
};

export default RadioGroup;

