import styled from 'styled-components';
import InputMask from 'react-input-mask';

export const Wrapper = styled.div`
  padding: 0 20px 20px;
  height: 100%;
  overflow-y: auto;
`;

export const Header = styled.div`
  margin: 0 0 20px 0;
  display: flex;
  justify-content: space-between;

  h2 {
    margin: 0;
  }
`;

export const ScrollArea = styled.div`
  overflow-y: scroll;
  max-height: 80vh;

  @media (max-width: 1281px) {
    max-height: 65vh;
  }

  @media (min-width: 1282px) and (max-width: 1441px) {
    max-height: 72vh;
  }
`;

export const Card = styled.div`
  width: 100%;
  padding: 20px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
`;

export const InputPhone = styled(InputMask)`
  height: 44px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }
`;

export const Label = styled.span`
  margin-bottom: 5px;
`;

export const DivButton = styled.div`
  width: 100%;
  height: 30px;
  style: block;
  position: relative;
`;

export const Content = styled.div`
  width: 100%;
`;
