import styled from 'styled-components';
import { Link } from 'react-router-dom';

import DatePicker from 'react-datepicker';

import { Table } from 'reactstrap';

export const Container = styled.div`
  padding: 0 20px 20px;
  height: calc(100vh - 148px);
  overflow-y: auto;
`;

export const Header = styled.div`
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
`;

export const ScrollArea = styled.div`
  overflow-y: scroll;
  max-height: 75vh;

  @media (max-width: 1281px) {
    max-height: 53vh;
  }

  @media (min-width: 1282px) and (max-width: 1441px) {
    max-height: 68vh;
  }
`;
export const Card = styled.div`
  width: 100%;
  padding: 20px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const ErrorContainer = styled.div`
  width: 60%;
  min-width: 600px;
  margin: 10px auto 30px auto;

  background: orange;
  color: #fff;

  border-radius: 8px;
  padding: 30px;

  p {
    text-align: center;
    font-size: 22px;
  }
`;

export const TableReport = styled.table`
  width: 100%;
  border-collapse: initial;
  border-spacing: 0 20px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: center;
    tr {
      padding-right: 5px;
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #eee;
      padding-bottom: 5px;
      padding-right: 5px;
      text-align: center;
    }
    td:last-child {
      text-align: right;
      padding-right: 0px;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const CategoriesTable = styled(Table)`
  width: 40%;
  min-width: 400px;
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 0 20px;
  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  padding: 0 30px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #eee;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div``;

export const ReportHeader = styled.div`
  width: 100%;
  clear: both;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

export const ReportHeaderChartDiv = styled.div`
  width: 99%;
  clear: both;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  h5 {
    position: absolute;
  }
`;

export const HeaderCards = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export const HeaderChart = styled.div`
  width: 50%;
  height: 300px;
  margin-top: 10px;
  float: right;
  right: 0;
  justify-content: center;
  text-align: center;
  display: flex;
`;

export const HeaderLegend = styled.div`
  margin-bottom: 0;
  width: 100%;
  height: 50px;
  float: right;
  right: 0;
  justify-content: center;
  text-align: center;
  display: flex;
  first-child {
    border-right: 1px solid #dee2e6;
  }
`;

export const HeaderChartFooter = styled.div`
  margin-bottom: 0;
  width: 100%;
  height: 50px;
  display: flex;
  div {
    width: 50%;
    text-align: center;
  }
`;

export const BoxOthers = styled.div`
  margin: 0 auto;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  width: 90%;
  flex-wrap: wrap;
`;

export const DateSelectorDiv = styled.div`
  display: flex;
  clear: both;
  text-align: center;
  flex-direction: row;
  margin-top: 5px;
`;

export const DateDiv = styled.div`
  float: left;
  left: 0;
  margin-left: 15px;
`;

export const Content = styled.div`
  padding-top: 20px;
`;

export const DisplayNoneImprimir = styled.button`
  display: none;
`;

export const Print = styled.button`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-self: center;
  margin-right: 10px;
  border: none;
  background: #d60300;
  right: 0;
  margin-left: auto;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
    border-radius: 50%;
  }
`;

export const PrintDiv = styled.div`
  right: 0;
  display: flex;
  width: 100%;
  align-itens: right;
  text-align: right;
  justify-content: right;
  clear: both;
  position: relative;
`;

export const Legends = styled.div`
  display: flex;
  width: 90%;
  align-itens: center;
  text-align: center;
  justify-content: center;
  clear: both;
  position: relative;
`;

export const CategoryLegend = styled.div.attrs((props) => ({
  color: props.color,
}))`
  padding: 5px;
  display: flex;
  align-text: center;
  margin: auto 0;

  div {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    margin-right: 5px;
  }
`;

export const SubmitDate = styled.button`
  height: 30px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 15px;
  border: none;
  background: #17a2b8;
  margin-left: 10px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
  }
`;

export const SelectDateButton = styled.button`
  height: 40px;
  border-radius: 20px;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 15px;
  border: none;
  background: #17a2b8;
  margin-left: 10px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
  }

  :selected {
    border: none;
  }
`;

export const DataPickerArea = styled.div`
  display: inline-block;
`;

export const DatePickerStyled = styled(DatePicker)`
  height: 40px;
  padding: 10px;

  margin-right: 15px;
  margin-left: 10px;

  border-radius: 20px;
  align-self: center;
  border: 1px solid ${(props) => props.theme.colors.lightGray};

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 20px;
  }
`;

export const ButtonsSelectDate = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;

  div {
    p {
      padding-left: 15px;
    }
  }
`;

export const DownloadSheetButton = styled.button`
  display: flex;
  align-items: center;
  align-self: flex-end;
  height: 30px;
  font-size: 12px;
  background-color: #04bfbf;
  border-radius: 8px;
  border: none;
  margin-left: 15;
  padding: 0 12px;
`;
