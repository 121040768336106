import React from 'react';
import Input from '~/components/Form/Input';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';

import api from '~/services/api';
import { Row, Col } from 'reactstrap';
import { FaCheck } from 'react-icons/fa';
import { ButtonAdd } from '~/pages/Financial/Transactions/styles';
import Select from '~/components/Form/SelectInput';

export default function EditTransactionCashFlowRow({ cash, categories, getTransactions }) {

  const categoryFiltered = categories?.filter(
    (item) => item.value === cash?.cash_flow_category_subcategory_id
  );

  const selectCategory = categoryFiltered
    ? {
      value: categoryFiltered.length > 0 ? categoryFiltered[0].value : '',
      label: cash.cash_flow_text,
    }
    : '';

  const initialData = {
    description: cash.description,
    cash_flow_category_subcategory_id: selectCategory,
  };

  async function handleSubmit(data) {
    try {
      await api.put(`/restaurants/cash-flows/category/${cash.id}`, {
        cash_flow_category_subcategory_id:
          data?.cash_flow_category_subcategory_id || null,
      });

      getTransactions();
    
      toast.success('Centro de custo editado com sucesso!');
    } catch (error) {
      toast.error('Ocorreu um erro ao fazer essa alteração');
      console.log(error);
    }
  }

  return (
    <>
      <Form initialData={initialData} onSubmit={handleSubmit} key={cash.id}>
        <Row>
          <Col md="5">
            <Input label="Descrição" name="description" disabled />
          </Col>
          {/* <Col md="3">
              <Input label="Data de Vencimento" name="due_date" value={format(new Date(cash.due_date), 'dd/MM/yyyy')} disabled/>
            </Col> */}
          <Col md="2">
            <Input
              label="Valor"
              name="value"
              value={parseFloat(cash.value).toLocaleString('pt-br', {
                minimumFractionDigits: 2,
              })}
              disabled
            />
          </Col>
          <Col md="4" style={{marginTop: 10}}>
            <Select
              label="Centro de custo"
              name="cash_flow_category_subcategory_id"
              placeholder="Centro de custo"
              options={categories}
              size={310}
            />
          </Col>

          <Col md="1" style={{marginTop: -20}}>
            <ButtonAdd
              type="submit"
              title="Salvar centro de custo"
            >
              <FaCheck />
            </ButtonAdd>
          </Col>
        </Row>
      </Form>
    </>
  );
}
