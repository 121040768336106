import styled from 'styled-components';

export const ComplementButtonContainer = styled.div`
  min-width: 200px;
  display: flex;
  align-items: center;
`;

export const Decrement = styled.button`
  background-color: transparent;
  display: flex;
  padding: 5px;
  height: 100%;
  width: 40px;
  min-width: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid #cfcfcf;
  transition: all 0.3s;

  &:hover {
    border-color: #ff2c3a;

    svg {
      color: #ff2c3a;
    }
  }

  svg {
    width: 12px;
    min-width: 12px;
    color: ${({ disabled }) => (!disabled ? '#ff2c3a' : '#d9d9d9')};
    transition: all 0.3s;
  }

  ${({ disabled }) => disabled && 'pointer-events: none;'}
`;

export const Increment = styled.button`
  background-color: transparent;
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 0px 5px 5px 0px;
  border: 1px solid #c4c4c4;
  height: 100%;
  flex-grow: 1;
  transition: all 0.3s;

  &:hover {
    border-color: #ff2c3a;
  }

  svg {
    width: 12px;
    min-width: 12px;
    color: ${({ disabled }) => (!disabled ? '#ff2c3a' : '#d9d9d9')};
    transition: all 0.3s;
  }

  ${({ disabled }) => disabled && 'pointer-events: none;'}
`;

export const ProductName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
  flex-grow: 1;

  p {
    margin: 0;
    text-align: left;
    color: #666;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  span {
    text-align: left;
    color: #b4b4b4;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
  }
`;
