import styled from 'styled-components';

export const Container = styled.div`
  // display: flex;
  // flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding:20px;

`;

export const Header = styled.div`
  font-weight:700;
  font-size:18px;
  margin-bottom:24px;
`

export const InformationArea = styled.div`
  display:grid;
  gap:16px;
  grid-template:
    "client-phone birthday"
    "name name"
    "email cpf"
    "cep street"
    "number complement";
`

export const ButtonSection = styled.div`
  display:flex;
  justify-content:space-between;
  margin-top:40px;
`