import styled from 'styled-components';

import { Form } from '@unform/web';

export const Container = styled.div`
  padding: 20px;
  height: calc(100vh - 48px);
  overflow-y: scroll;
`;

export const CostCenterTable = styled.table`
  width: 100%;
  padding: 20px 10px 10px 10px;

  th,
  td:not(:first-child) {
    text-align: center;
  }

  td:first-child,
  th:first-child {
    position: sticky;
    left: 0px;
    background-color: #ffffff;
  }
`;

export const ErrorContainer = styled.div`
  width: 60%;
  min-width: 600px;
  margin: 10px auto 30px auto;

  background: orange;
  color: #fff;

  border-radius: 8px;
  padding: 30px;

  p {
    text-align: center;
    font-size: 22px;
  }
`;

export const CCTableHeader = styled.thead`
  position: sticky;
  top: 0;
  padding: 12px 7px;
  border-bottom: none;
  background-color: #ffffff;
  z-index: 1;

  box-shadow: 0px 1px 0px 0px #525f7f;
`;

export const CategoryRow = styled.tr`
  /* background-color: #ff8d72; */
  color: #fb6340;
  cursor: pointer;
  border-bottom: 1px solid lightgrey;
  :hover {
    color: #fff;
    background-color: #fb6340;

    td {
      background-color: #fb6340;
    }
  }

  td {
    padding: 12px 7px;
  }
`;

export const EarningRow = styled.tr`
  /* background-color: #2dce89; */
  cursor: pointer;
  border-bottom: 1px solid lightgrey;
  color: #2dce89;
  background-color: #fff;
  td {
    padding: 12px 7px;
  }

  :hover {
    color: #fff;
    background-color: #2dce89;

    td {
      background-color: #2dce89;
    }
  }
`;

export const SubCategoryRow = styled.tr`
  cursor: ${(props) => props.clickable && 'pointer'};

  span {
    border-bottom: 1px solid lightgrey;
    padding: 12px 7px;
  }
`;

export const ResultRow = styled.tr`
  /* background-color: #f2ea4b; */
  border-bottom: 1px solid lightgrey;

  td {
    padding: 12px 7px;
    color: #525f7f;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CollapsableTd = styled.td`
  transition: padding 0.3s;
  padding: ${(props) => (props.check ? '12px 7px' : '0')};
  border-bottom: ${(props) => (props.check ? '1px solid lightgrey' : 'none')};
`;

export const StyledSelect = styled(Form)`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .react-select__control {
    width: 200px;
  }

  .react-select__menu {
    z-index: 2;
  }
`;

export const DownloadSheetButton = styled.button`
  display: flex;
  align-items: center;
  align-self: flex-end;
  height: 38px;
  font-size: 12px;
  background-color: #04bfbf;
  border-radius: 4px;
  border: none;
  margin: 10px 0px 10px 5px;
  padding: 0 12px;
`;
