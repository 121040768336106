import React, { useCallback, useEffect, useState } from 'react';
import { Actions, BalanceTable, Container, Delete, Header } from './style';
import { Button } from 'ui-kit-takeat';
import api from '~/services/api';
import { MdDelete, MdEdit } from 'react-icons/md';
import toast from 'react-hot-toast';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import CreateBalance from './Create';
import EditBalance from './Edit';

function Balances() {
  const [balances, setBalances] = useState([]);
  const [editBalance, setEditBalance] = useState(null);

  const getBalances = useCallback (async () => {
    const response = await api.get(`/restaurants/balances`);
    setBalances(response.data);
  }, []);

  const deleteBalance = useCallback(async (id) => {
    if(window.confirm('Tem certeza que deseja deletar essa balança?')){
      await api.delete(`/restaurants/balances/${id}`);
      toast.success('Balança deletada com sucesso');
      getBalances();
    }
  }, []);

  useEffect(() => {
    getBalances();
  }, []);

  const [isModalRegisterOpen, setIsModalRegisterOpen] = useState(false);
  function toggleModalRegister() {
    setIsModalRegisterOpen(!isModalRegisterOpen);
  }

  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  function toggleModalEdit() {
    setIsModalEditOpen(!isModalEditOpen);
  }

  function openModalEdit(balance) {
    setEditBalance(balance);
    setIsModalEditOpen(true);
  }
  
  return (
    <Container>
      <Header>
        <Button
          onClick={toggleModalRegister}
          title="Nova Balança"
        />
      </Header>

      <Modal isOpen={isModalRegisterOpen} toggle={toggleModalRegister}>
        <ModalHeader>Nova Balança</ModalHeader>
        <ModalBody>
          <CreateBalance
            toggle={toggleModalRegister}
            getBalances={getBalances}
          />
        </ModalBody>
      </Modal>

      <Modal isOpen={isModalEditOpen} toggle={toggleModalEdit}>
        <ModalHeader>Editar Balança</ModalHeader>
        <ModalBody>
          <EditBalance
            toggle={toggleModalEdit}
            getBalances={getBalances}
            balance={editBalance}
          />
        </ModalBody>
      </Modal>

      <BalanceTable borderless>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Porta</th>
            <th>Baud Rate</th>
            <th>Bit Rate</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {balances?.map((balance) => (
            <tr key={balance.id}>
              <td>{balance.name}</td>
              <td>{balance.port_name}</td>
              <td>{balance.baud_rate}</td>
              <td>{balance.bit_rate}</td>
              <td>
                <Actions>
                  <MdEdit
                    size={20}
                    onClick={() => {
                      openModalEdit(balance);
                    }}
                    color="#90C2E7"
                    title="Editar Balança"
                    style={{ cursor: 'pointer' }}
                  />

                  <Delete onClick={() => deleteBalance(balance.id)}>
                    <MdDelete size={20} />
                  </Delete>
                </Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </BalanceTable>
    </Container>
  );
}

export default Balances;