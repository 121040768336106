import styled from 'styled-components';
import { Table } from 'reactstrap';

export const Container = styled.div`
  margin: 10px;
`;

export const HistoryTable = styled(Table)`
  width: 100%;
  margin: 0 auto;
  border-spacing: 0 10px;
  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  padding: 30px;

  thead {
    border-bottom: 10px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      top: 0;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;