import React from 'react';
import { Radar } from 'react-chartjs-2';
import { Container } from './styles';

const RadarChart = ({ data }) => {
  const options = {
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          color: 'rgb(255, 99, 132)',
        },
      },
    },
    scale: {
      ticks: { beginAtZero: true },
    },
  };

  return (
    <Container>
      <Radar data={data} options={options} />
    </Container>
  );
};

export default RadarChart;
