import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';

export const Row = styled.tr`
  height: 30px;
`;

export const ActionsDiv = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;

  svg,
  img {
    width: 15px;
    height: 20px;
    cursor: pointer;
    color: #ff2c3a;
  }
`;

export const DeletePaymentForm = styled(Link)`
  padding-bottom: 5px;
  color: red;
`;
