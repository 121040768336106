import React from 'react';

import { MdAdd, MdFileDownload } from 'react-icons/md';

import { ButtonContainer } from './styles';

export default function RegisterButton({ text, download, ...rest }) {
  return (
    <ButtonContainer type="button" {...rest}>
      {download ? (
        <MdFileDownload color="#fff" size={16} />
      ) : (
        <MdAdd color="#fff" size={16} />
      )}
      <span>{text || 'CADASTRAR'}</span>
    </ButtonContainer>
  );
}
