import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';

import { Content } from './styles';
import Box from './Box';
import OpeningBox from './OpeningBox';
import AuditCashier from './AuditCashier';
import Header from './Header';

function CashManagementPage() {


  return (
    <>
      <Header />
      <Content>
        <Switch>
          <Route path="/operation/opening-box" component={OpeningBox} />
          <Route path="/operation/cashier" component={Box} />
          <Route path="/operation/audit-box" component={AuditCashier} />
        </Switch>
      </Content>
    </>
  );
}

export default CashManagementPage;