import toast from 'react-hot-toast';
import api from '../services/api';
import { format } from 'date-fns';

// function getPaymentStatus(item) {
//   if (item?.ifood_paid) {
//     return 'Pagamento já efetuado';
//   } else if (
//     item?.intended_payment_method?.id === 301 ||
//     item?.intended_payment_method?.id === 238 ||
//     item?.intended_payment_method?.id === 572 ||
//     item?.intended_payment_method?.id === 2548
//   ) {
//     return 'Pagamento já efetuado';
//   } else {
//     return 'Pagamento na entrega.';
//   }
// }

function isPaidMethod(paymentMethod) {
  const paidMethods = [301, 238, 572, 2548];
  return paidMethods.includes(paymentMethod?.id);
}

function getPaymentStatus(item) {
  if (item?.ifood_paid) {
    return "Pagamento já efetuado.";
  }

  const paymentMethod = item?.intended_payment_method || item?.payment_method;

  if (isPaidMethod(paymentMethod)) {
    return "Pagamento já efetuado";
  } else if (item?.with_withdrawal) {
    return "Pagamento na retirada.";
  } else {
    return "Pagamento na entrega.";
  }
}

export async function handleNotifyWhatsapp(item, orders, user, status, ifood_on_demand_id = null) {
  if (
    user.delivery_info.notify_whatsapp &&
    item?.table?.table_type === 'delivery'
  ) {
    const userPhone =
      status === 'motoboy' ? item?.motoboy?.phone : item?.buyer?.phone;

    if (user.use_wpp) {
      const textWpp = getDeliveryText(status, orders, item, user, ifood_on_demand_id);

      const textSent = await api.post('/restaurants/uzapi/delivery-msg', {
        text: textWpp,
        phone: userPhone,
        token: user.name,
      });

      if (textSent.data.status === 400 || textSent.data.status === 'error') {
        if (textSent.data.status === 'error') {
          toast.error(textSent.data.message, { duration: 5000 });
        } else {
          toast.error(
            'Whatsapp está desconectado, essa mensagem deverá ser enviada manualmente',
            { duration: 5000 }
          );

          const text = getDeliveryText(status, orders, item, user, ifood_on_demand_id)
            .replace(/\n/g, '%0a')
            .replace(/&/g, '%26');

          window.open(
            `https://api.whatsapp.com/send?phone=55${userPhone}&text=${text}`,
            'wppTakeat'
          );
        }
      } else {
        toast.success(
          status === 'motoboy'
            ? 'Whatsapp com informações do pedido enviado para motoboy'
            : `Whatsapp ${status === 'accepted'
              ? 'informando pedido ACEITO'
              : status === 'ready'
                ? 'informando pedido PRONTO'
                : 'pedindo avaliação'
            } enviado para cliente`
        );
      }
    } else {
      const text = getDeliveryText(status, orders, item, user, ifood_on_demand_id)
        .replace(/\n/g, '%0a')
        .replace(/&/g, '%26');

      window.open(
        `https://api.whatsapp.com/send?phone=55${userPhone}&text=${text}`,
        'wppTakeat'
      );
    }
  }
}

const getDeliveryText = (status, orders, item, user, ifood_on_demand_id) => {
  const userName = item?.buyer?.name.split(' ')[0];
  const deliveryAddress = item?.buyer?.delivery_address;

  const userAdress = item.with_withdrawal
    ? `${user.adress?.neighborhood || ''} - ${user.adress?.street || ''} ${user.adress?.number || ''
    } - ${user.adress?.city || ''} - ${user.adress?.state || ''}`
    : `${deliveryAddress?.neighborhood || ''} - ${deliveryAddress?.street || ''
    } ${deliveryAddress?.number || ''} - ${deliveryAddress?.city || ''} - ${deliveryAddress?.state || ''
    } - ${deliveryAddress?.zip_code || ''}${deliveryAddress?.complement && '\n' + deliveryAddress.complement
    }${deliveryAddress?.reference && '\n' + deliveryAddress.reference}` || '';

  const userPaymentMethod = item?.intended_payment_method?.name || '';

  const fantasyNameChanged = user?.fantasy_name.replace('&', '%26');

  const paymentStatus = getPaymentStatus(item);
  const isPaid = paymentStatus === 'Pagamento já efetuado';

  const productsWpp = orders?.map((prod) => {
    return `\n*${prod.amount}x - ${prod.product.name} - R$ ${formatPrice(
      prod.total_price
    )}*\n${prod.complement_categories?.length > 0
      ? prod.complement_categories
        .map((complement_cat) => {
          return `    - ${complement_cat.complement_category.name}\n${complement_cat.order_complements.length > 0 &&
            complement_cat.order_complements
              .map((comp) => {
                return `    ${comp.amount}x - ${comp.complement.name
                  } - R$${formatPrice(comp.complement.price)}\n`;
              })
              .join('')
            }`;
        })
        .join('')
      : ''
      }`;
  });

  const total =
    Number(item?.basket.total_service_price) + Number(item?.delivery_tax_price);

  if (status === 'accepted') {
    try {
      return `Olá${userName && ', ' + userName
        }! Aqui é do *${fantasyNameChanged}*.\nO seu pedido já foi aceito e está ${item.scheduled_to
          ? `agendado para *` +
          format(new Date(item.scheduled_to), 'dd/MM/yy - HH:mm*')
          : 'em preparo'
        }!\n\n*Itens:*${productsWpp.join('')}\n\n${item.rescue || item.coupon_discount
          ? `Subtotal com taxa (se houver): *R$ ${formatPrice(total)}*\n`
          : ''
        }${item.rescue
          ? `Resgate Clube: *R$${formatPrice(item.rescue.cashback)}*\n`
          : ''
        }${item.coupon_discount
          ? `Desconto cupom: *R$${formatPrice(item.coupon_discount)}*\n`
          : ''
        }Total com taxa (se houver): *R$ ${formatPrice(
          total - (item.coupon_discount || 0) - (item.rescue?.cashback || 0)
        )}*\n\n*Endereço${item.with_withdrawal ? ' para retirada' : ''
        }:* ${userAdress}\n\n*Pagamento:* ${userPaymentMethod || 'Não informado'
        }`;
    } catch (err) {
      console.log('err send new delivery > ', err);
    }
  } else if (status === 'ready') {
    return item?.with_withdrawal
      ? `O seu pedido está pronto${userName && ', ' + userName
      }! 😋\nVocê pode vir retirar no local, estaremos aguardando por você!\n\nAgradecemos pela preferência e paciência. Em caso de qualquer dúvida, estamos à disposição. ❤️`
      : `O seu pedido está pronto${userName && ', ' + userName
      }! 😋\nO nosso motoboy está a caminho e em breve ele chegará ao seu endereço.${ifood_on_demand_id ? `\nAcompanhe sua entrega por aqui: https://meupedido.ifood.com.br/${ifood_on_demand_id}` : ''}\n\nAgradecemos pela preferência e paciência. Em caso de qualquer dúvida, estamos à disposição. ❤️`;
  } else if (status === 'finished') {
    return `*Obrigado pela preferência, ${userName}!*\n\nSempre que precisar, nossa equipe estará a disposição.\n\nConte para nós, de 1 a 10, o que você achou da experiência de comprar no *${fantasyNameChanged}* ?\n\nAté a próxima! ☺️`;
  }

  if (status === 'motoboy') {
    return `Pedido: #${item?.attendance_password
      }\n\nEndereço: ${userAdress}\n\nCliente: ${item?.buyer?.name} - ${item?.buyer?.phone
      }${item?.buyer?.localizer ? '\nID iFood: ' + item.buyer.localizer : ''
      }\n\n${getPaymentStatus(item)}${!isPaid
        ? `\nMétodo: ${userPaymentMethod || 'Não informado'}\n${item.rescue || item.coupon_discount
          ? `Subtotal com taxa (se houver): *R$ ${formatPrice(total)}*\n`
          : ''
        }${item.rescue
          ? `Resgate Clube: *R$${formatPrice(item.rescue.cashback)}*\n`
          : ''
        }${item.coupon_discount
          ? `Desconto cupom: *R$${formatPrice(item.coupon_discount)}*\n`
          : ''
        }Total com taxa (se houver): *R$ ${formatPrice(
          total - (item.coupon_discount || 0) - (item.rescue?.cashback || 0)
        )}*`
        : ''
      }${parseFloat(item?.user_change || 0) > 0
        ? `\nTroco para: R$ ${formatPrice(item?.user_change)}`
        : ''
      }\n\nDados do pedido:${productsWpp.join('')}`;
  }

  if (status === 'ifood_on_demand' && ifood_on_demand_id) {
    return `Seu pedido em breve estará com você! Acompanhe sua entrega por aqui: https://meupedido.ifood.com.br/${ifood_on_demand_id}`;
  }
};

const formatPrice = (price) => {
  return parseFloat(price)?.toLocaleString('pt-br', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
