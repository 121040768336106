import styled from 'styled-components';

export const Container = styled.div`
  padding: 15px;
  background-color: #fff;
  border-radius: 7px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));

  overflow-x: hidden;
`;

export const Title = styled.h3`
  font-size: 14px;
  color: #33333370;
  font-weight: 400;
`;

export const ContainertInfo = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  & + div {
    border-top: 1px solid #33333310;
  }
  display: flex;
  justify-content: space-between;
`;

export const Sum = styled.strong`
  font-size: 14px;
  color: #33333380;
  font-weight: normal;
`;

export const Info = styled.strong`
  font-size: 14px;
  font-weight: bold;
`;

export const ButtonOpenWaiterModal = styled.button`
  width: 80px;
  height: 30px;
  background-color: transparent;
  color: #007c77;
  border: none;
  border-radius: 7px;
  position: fixed;
  top: 10px;
  right: 10px;
  font-size: 14px;
  font-weight: bold;
`;
