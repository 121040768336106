import styled from 'styled-components';

export const Container = styled.div`
  width: auto;
  height: auto;
`;

export const Table = styled.div`
  padding-top: 10px;
  width: 110mm;
  text-align: center;
  font-size: 25px;
  margin-right: auto;
`;
export const DisplayNoneImprimir = styled.button`
  display: none;
`;

export const Print = styled.button`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-self: center;
  float: right;
  position: relative;
  display: block;
  right: 0;
  margin-right: 10px;
  border: none;
  background: #f0b402;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
    border-radius: 50%;
  }
`;

export const DeliveryPrice = styled.div`
  padding-top: 15px;
  margin-top: 10px;
  border-top: solid black 1px;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 10px;

  div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    span {
      font-size: 16px;
      font-weight: bold;
    }
  }
`;
export const Order = styled.div`
  padding-top: 5px;
  width: 110mm;
  text-align: center;
  font-size: 15px;
  margin-right: auto;
  padding-bottom: 15px;
  border-bottom: solid black 1px;
`;

export const Restaurant = styled.div`
  padding-top: 10px;
  width: 110mm;
  text-align: center;
  font-size: 20px;
  margin-right: auto;
  padding-bottom: 15px;
  border-bottom: solid black 1px;
`;

export const Body = styled.div`
  width: 110mm;
  clear: both;
`;

export const Phone = styled.div`
  text-align: left;
  padding-left: 5px;
  font-size: 15px;
  padding-top: 10px;
  float: left;
`;

export const Time = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 15px;
  padding-top: 10px;
  float: right;
`;
export const DeliveryInfo = styled.div`
  padding-top: 10px;
  margin-top: 10px;
  border-top: solid black 1px;
  padding-bottom: 10px;
  border-bottom: solid black 1px;
  display: flex;
  flex-direction: column;

  span {
    font-size: 16px;
  }
`;
export const Items = styled.div`
  padding-top: 20px;
`;

export const Item = styled.div`
  padding-top: 5px;
  padding-left: 10px;
`;

export const ItemName = styled.div`
  font-size: 15px;
`;

export const ComplementsTitle = styled.div`
  font-size: 13px;
`;

export const Complements = styled.div`
  font-size: 12px;
  padding-left: 15px;
`;

export const Obs = styled.div`
  font-size: 13px;
`;
