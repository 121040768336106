import React from 'react';
import './style.css';

const ProgressBar = () => {
  return (
    <div className="container">
      <div className="loading-line-container">
        <div className="loading-line"></div>
      </div>
    </div>

  );
};

export default ProgressBar;