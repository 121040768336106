import styled from 'styled-components';

export const Container = styled.div`

  @page {
    size: 75mm 200mm;
    margin: 0;
    
  }
  @media print {
    * {
      background: transparent;
      color: #000;
      text-shadow: none;
      filter: none;
      -ms-filter: none;
      font-family: Arial, Helvetica, sans-serif;
      padding-left: 10px;    
    }
    html,
    body {
      width: 75mm;
      height: 200mm;
    }
    h1 {
      margin-right: auto;
    }
    img {
      width: 70mm;
      height: auto;
    }
  }
`;


export const Restaurant = styled.p`
  text-transform: capitalize;
  padding-top: 10px;
  width: 110mm;
  text-align: center;
  font-size: 18px;
  margin-right: 10px;
  padding-bottom: 15px;
  border-bottom: solid #858585 1px;
`;

export const ItemTitle = styled.p`
  text-transform: capitalize;
  width: 108mm; 
  font-size: 18px;
  margin-left: 10px;
  background-color: #000;
  color: #fff;
`;

export const ItemDescription = styled.p`
  text-transform: capitalize;
  width: 110mm;
  margin-left: 10px;
  font-size: 18px;
 
`;

export const Footer = styled.div`
  margin-top: 20px;
  padding-top: 10px;
 
  font-size: 12px;
  text-align: center;
  width: 110mm;
  padding-bottom: 30px;
`;

export const TotalRow = styled.div` 

  width: 110mm;
 display: flex;
 flex-direction: column;
 font-size:16px;
 margin-bottom: 20px;
`;





