/* eslint-disable react/prop-types */
import React, { useContext, useRef, useState, useCallback } from 'react';

import { useDrag, useDrop } from 'react-dnd';
import { FaCheck } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import {
  NoImage,
  ProductImage,
  SubmitStyledButton,
  NumberFormatStyled,
  Description,
} from './styles';
import OrderComplementContext from '../context';

import api from '~/services/api';
import { Form } from '@unform/web';
import Select from '~/components/Form/SelectInput';
import Input from '~/components/Form/Input';

// import { Container } from './styles';

function ComplementsRow({
  complement,
  handleChangeComplement,
  handleChangeEditComplement,
  handleDelete,
  index,
  user,
  complementsData,
  category,
  setCategories,
  categories,
  ifoodMenus,
  ifoodRestaurants,
  ifoodProducts,
  fiscalGroups
}) {
  const { move, onDrop } = useContext(OrderComplementContext);
  const ref = useRef();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropDownHidden, setDropDownHidden] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const [{ isDragging }, dragRef] = useDrag({
    item: { type: 'COMPLEMENTCARD', index, complement_id: complement.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (!monitor.didDrop()) {
        const draggedIndex = item.index;
        const targetIndex = index;

        onDrop(item.complement_id, complement.id, draggedIndex, targetIndex);
      }
    },
  });

  const [, dropRef] = useDrop({
    accept: 'COMPLEMENTCARD',
    hover(item, monitor) {
      const draggedIndex = item.index;
      const targetIndex = index;

      if (draggedIndex === targetIndex) {
        return;
      }

      const targetSize = ref.current.getBoundingClientRect();
      const targetCenter = targetSize.height / 2;
      const draggedOffset = monitor.getClientOffset();
      const draggedTop = draggedOffset.y - targetSize.top;
      if (draggedIndex < targetIndex && draggedTop < targetCenter) {
        return;
      }
      if (draggedIndex > targetIndex && draggedTop > targetCenter) {
        return;
      }
      move(item.complement_id, complement.id, draggedIndex, targetIndex);

      item.index = targetIndex;
    },

    drop(item, monitor) {
      const draggedIndex = item.index;
      const targetIndex = index;

      onDrop(item.complement_id, complement.id, draggedIndex, targetIndex);

      setDropDownHidden(false);
    },
  });

  dragRef(dropRef(ref));

  function currencyFormatter(value) {
    if (!Number(value)) return 'R$ 0,00';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  }

  function currencyFormatterLimit(value) {
    if (!Number(value)) return 'R$ 0,00';

    const amount = value;

    return `${amount}`;
  }

  const [buttonsSubmitDesbloqued, setButtonsSubmitDesbloqued] = useState([]);

  function handleDesbloquedSubmitButton(id) {
    if (complement.id === id) {
      setButtonsSubmitDesbloqued([id]);
    } else {
      const idsFiltered = buttonsSubmitDesbloqued.filter((item) => item !== id);
      setButtonsSubmitDesbloqued(idsFiltered);
    }
  }

  const [
    buttonsSubmitLimitDesbloqued,
    setButtonsSubmitLimitDesbloqued,
  ] = useState([]);

  function handleDesbloquedSubmitLimitButton(id) {
    if (complement.id === id) {
      setButtonsSubmitLimitDesbloqued([id]);
    } else {
      const idsFiltered = buttonsSubmitLimitDesbloqued.filter(
        (item) => item !== id
      );
      setButtonsSubmitLimitDesbloqued(idsFiltered);
    }
  }

  const [price, setPrice] = useState(0);
  const [limit, setLimit] = useState(0);

  const handleSubmitPrice = useCallback(async () => {
    const readyValue = price.floatValue
      ? (price.floatValue / 100).toFixed(2)
      : price;

    const formatPrice = (price) => {
      return price > 0 ? price.replace(',', '.') : '0.00';
    };

    const formattedPrice = formatPrice(readyValue);

    await api.put(`restaurants/product/complements/${complement.id}`, {
      price: formattedPrice,
    });

    const complementFiltered = complementsData?.find(
      (item) => item.id === complement.id
    );
    // const newCategories = [...productsCategories];

    // newCategories.map((cat, idix) =>
    //   cat.products.map((prod, indx) =>
    //     prod?.complement_categories.map((com_cat, indice) =>
    //       com_cat.complements.map((item, idex) => {
    //         if (item.id === complement.id) {
    //           newCategories[idix].products[indx].complement_categories[
    //             indice
    //           ].complements[idex].price = formattedPrice;
    //         }
    //       })
    //     )
    //   )
    // );

    // setProductsCategories(newCategories);

    complementFiltered.price = formattedPrice;
    toast.success('Preço alterado com sucesso.');

    setButtonsSubmitDesbloqued([]);
    setButtonsSubmitLimitDesbloqued([]);
  }, [complement, price, complementsData]);

  function handleValueChange(e) {
    setPrice(e);

    category.complements[index].price = e.floatValue
      ? (e.floatValue / 100).toFixed(2)
      : 0;
  }

  const handleSubmitLimit = useCallback(async () => {
    const readyValue = limit.floatValue ? limit.floatValue : limit;

    await api.put(`restaurants/product/complements/${complement.id}`, {
      limit: readyValue,
    });

    const complementFiltered = complementsData?.find(
      (item) => item.id === complement.id
    );
    complementFiltered.limit = readyValue;
    toast.success('Limite alterado com sucesso.');

    // const newCategories = [...productsCategories];

    // newCategories.map((cat, idix) =>
    //   cat.products.map((prod, indx) =>
    //     prod?.complement_categories.map((com_cat, indice) =>
    //       com_cat.complements.map((item, idex) => {
    //         if (item.id === complement.id) {
    //           newCategories[idix].products[indx].complement_categories[
    //             indice
    //           ].complements[idex].limit = readyValue;
    //         }
    //       })
    //     )
    //   )
    // );

    // setProductsCategories(newCategories);

    setButtonsSubmitDesbloqued([]);
    setButtonsSubmitLimitDesbloqued([]);
  }, [complement, limit, complementsData]);

  function handleLimitChange(e) {
    setLimit(e);

    category.complements[index].limit = e.floatValue ? e.floatValue : 0;
  }

  const handleDuplicateComplement = useCallback(
    async (complement_id) => {
      try {
        const response = await api.put(`/restaurants/complements/duplicate`, {
          complement_category_id: category.id,
          complement_id,
        });

        const duplicate_item = response.data;

        const newComplementsCategories = [...categories];

        newComplementsCategories.map((cat, idix) => {
          if (cat.id === category.id) {
            newComplementsCategories[idix].complements.splice(
              [index + 1],
              0,
              duplicate_item
            );
          }
        });

        setCategories(newComplementsCategories);

        localStorage.setItem('@gddashboard:isMenuUpdated', true);
        toast.success('Complemento duplicado com sucesso');
      } catch (error) {
        toast.error('Erro ao duplicar complemento');
        console.log(error);
      }
    },
    [category, index, categories, setCategories]
  );

  const handleFiscal = useCallback(
    async (data) => {
      const fiscal_group_id = parseInt(data.fiscal_group_id);

      try {
        const thisData = {
          ncm: data.ncm,
          fiscal_group_id,
          type: 'complement'
        };

        await api.put(`/restaurants/update/fiscal/${complement.id}`, thisData);

        toast.success('Produto associado com sucesso!');
      } catch (error) {
        toast.error('Erro ao vincular');
      }
    },
    [complement]
  );

  return (
    <tr isDragging={isDragging} ref={ref}>
      <td>
        <div
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          {complement?.image ? (
            <ProductImage
              src={complement?.image?.url_thumb || complement?.image?.url}
              alt="Foto do produto"
              // onClick={toggleImage}
              // title="Clique para atualizar a foto"
              style={{ marginLeft: 16 }}
            />
          ) : (
            <NoImage
              // onClick={toggleImage}
              // title="Clique para atualizar a foto"
              style={{ marginBottom: 10, marginLeft: 16 }}
            />
          )}
        </div>
      </td>
      <td>
        <Description>
          <strong>{complement?.name}</strong>
        </Description>
      </td>

      <td>
        {' '}
        <NumberFormatStyled
          prefix="R$"
          format={currencyFormatter}
          name="value"
          value={complement?.price}
          disabled
        />
      </td>

      <td style={{ width: '500px' }}>
        <Form onSubmit={handleFiscal}>
          <div style={{ display: 'flex' }}>
            <Input
              style={{ width: '90px' }}
              defaultValue={complement.ncm}
              name="ncm"
              placeholder="NCM"
            />
            <div style={{ width: '100%', marginRight: 10, marginLeft: 10 }}>
              <Select
                name="fiscal_group_id"
                defaultValue={fiscalGroups
                  .find(
                    (group) =>
                      group.id ===
                      complement.fiscal_group_id
                  )}
                options={fiscalGroups}
                placeholder="Grupo fiscal"
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              />
            </div>
            <SubmitStyledButton
              id="limit"
              type="submit"
              style={{
                marginLeft: 'auto',
              }}
            >
              <FaCheck />
            </SubmitStyledButton>
          </div>
        </Form>
      </td>

      <td />
    </tr>
  );
}

export default ComplementsRow;
