import React, { useEffect, useRef, useState } from 'react';
//import { RiKnifeLine } from 'react-icons/ri';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import { Content, ItemsCount, LoadingDiv } from '../styles';
import { Header, Bloco, StyleKanbanWarning, FilterArea } from './styles';
//import iconTakeat from '~/assets/img/icon-site.svg';
import OrderReadyDelivery from '../../Items/OrderReadyDelivery';
import { Button, Checkbox, Warning } from 'ui-kit-takeat';
//import api from '~/services/api';
//import toast from 'react-hot-toast';
import FinishBillsInBatch from '~/components/FinishBillsInBatch';
import { FaExclamationTriangle, FaFilter } from 'react-icons/fa';
import InsertPayment from "~/assets/img/insert_payment.jpeg"
import { IoFilter } from 'react-icons/io5';
//import { IoInformationCircleOutline } from 'react-icons/io5';
export default function CardOrdersReady({
  baskets,
  handleItemFinished,
  handleCancelItem,
  loading,
  getAllOrders,
  hasOrderActive,
  paymentMethods,
  allPaymentMethods,
  favoritePaymentMethods,
  handleUpdate,
  countOrders,
}) {


  // const getPaymentMethods = useCallback(async () => {
  //   const response = await api.get('restaurants/payment-methods');
  //   const parsedData = response.data.payment_methods.map((data) => ({
  //     id: data.id,
  //     label: data.name,
  //     available: data.available,
  //     keyword: data.keyword,
  //   }));
  //   setPaymentMethods(parsedData);
  // }, []);

  // useEffect(() => {
  //   getPaymentMethods();
  // }, [getPaymentMethods]);

  // const handleCloseSessionsWithOnlinePayments = useCallback(async () => {
  //   try {
  //     const response = await api.put('restaurants/delivery/orders/close-paid')
  //     getAllOrders()
  //   } catch (error) {
  //     console.log(error.message)
  //     toast.error("Erro ao fechar comandas.")
  //   }
  // }, [])

  //finish bills in batch
  const [isModalFinishBillsInBatchOpened, setIsModalFinishBillsInBatchOpened] = useState(false)
  function toggleModalFinishBillsInBatch() {
    setIsModalFinishBillsInBatchOpened(!isModalFinishBillsInBatchOpened)
  }

  const [isModalConfirmationOpened, setIsModalConfirmationOpened] = useState(false)
  function toggleModalConfirmFinishBills() {
    setIsModalConfirmationOpened(!isModalConfirmationOpened)
  }

  // const [isModalCompletePaymentOpened, setIsModalCompletePaymentOpened] = useState(false)
  // function toggleModalCompletePayment() {
  //   setIsModalCompletePaymentOpened(!isModalCompletePaymentOpened)
  // }

  // const [isCompletingPayments, setIsCompletingPayments] = useState(false)

  // async function handleConfirmFinishBillsInBatch() {
  //   try {
  //     setIsCompletingPayments(true)
  //     await api.put("/restaurants/delivery/orders/fill-payments")
  //     setIsCompletingPayments(false)
  //     setIsModalFinishBillsInBatchOpened(!isModalFinishBillsInBatchOpened)
  //   } catch (error) {
  //     toast.error("Erro ao completar pagamentos.")
  //   }
  // }
  const [showPaid, setShowPaid] = useState(true);
  const [showUnpaid, setShowUnpaid] = useState(true);

  const handleFilterChange = (filterType) => {
    if (filterType === 'paid') {
      if (showPaid && !showUnpaid) {
        return
      }
      setShowPaid(!showPaid);
    } else if (filterType === 'unpaid') {
      if (showUnpaid && !showPaid) {
        return
      }
      setShowUnpaid(!showUnpaid);
    }
  };

  const [isFilterModalOpened, setIsFilterModalOpened] = useState(false)

  function toggleFilterModal() {
    setIsFilterModalOpened(!isFilterModalOpened)
  }

  const filterAreaRef = useRef(null);

  const handleClickOutside = (event) => {
    if (filterAreaRef.current && !filterAreaRef.current.contains(event.target)) {
      setIsFilterModalOpened(false); // Fecha o modal de filtros
    }
  };

  useEffect(() => {
    if (isFilterModalOpened) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isFilterModalOpened]);


  //warning state
  const warningFilterStorage = JSON.parse(
    localStorage.getItem('@gddashboard:warningFilterStorage')
  );
  const [warningbutton, setWarningButton] = useState(
    warningFilterStorage === null ? true : warningFilterStorage
  );

  function toggleWarning() {
    setWarningButton(false)
    localStorage.setItem('@gddashboard:warningFilterStorage', false)

  }

  return (
    <Bloco>
      <Modal isOpen={isModalFinishBillsInBatchOpened} toggle={toggleModalFinishBillsInBatch} size='lg'>
        <FinishBillsInBatch isDelivery={true} toggleModalFinishBillsInBatch={toggleModalFinishBillsInBatch} getSessions={getAllOrders} />
      </Modal>

      {/* <Modal isOpen={isModalCompletePaymentOpened} toggle={toggleModalCompletePayment} size='lg'>
        <ModalBody style={{ height: 361 }}>
          <div>
            <h1 style={{ fontSize: 18, color: "#3d3d3d" }}>
              <strong>Pagamento das Comandas:</strong> Finalize com Rapidez!
            </h1>
            <p style={{ fontSize: 16, color: "#3d3d3d" }}>
              Para finalizar todas as comandas com pagamento já informado, clique em “Completar Pgmto.
              e Finalizar”. Se preferir finalizar apenas as comandas com pagamento completo,
              clique em “Finalizar Comandas”.
            </p>
            <div style={{ display: "flex", alignItems: "center", marginTop: 20, background: "#E8F8FF", border: "1px solid #01AFFF", borderRadius: 5 }}>
              <div style={{ display: "flex", justifyContent: "center", width: 100 }}>
                <IoInformationCircleOutline color='#01AFFF' size={32} />
              </div>
              <p>
                <strong>Dica: </strong>Finalizar com "Completar Pgmto. e Finalizar" é mais rápido,
                pois o sistema já possui as informações de pagamento.
                Escolha a opção que melhor lhe atende e finalize suas comandas com praticidade!
              </p>
            </div>
          </div>

        </ModalBody>
        <footer style={{ display: "flex", justifyContent: "space-between", padding: 20 }}>
          <Button inverted buttonColor='#FF2C3A' title='Voltar' onClick={toggleModalCompletePayment} />
          <Button
            buttonColor='#2EC9B7'
            inverted
            title={isCompletingPayments ? "Completando pagamentos" : "Completar Pgmto. e Finalizar"}
            onClick={() => { handleConfirmFinishBillsInBatch(); setIsModalCompletePaymentOpened(false) }} />
          <Button
            buttonColor='#2EC9B7'
            title='Confirmar'
            onClick={() => { toggleModalFinishBillsInBatch(); setIsModalCompletePaymentOpened(false) }} />
        </footer>
      </Modal> */}

      <Modal isOpen={isModalConfirmationOpened} toggle={toggleModalConfirmFinishBills}>
        <ModalBody>
          <div style={{ textAlign: "center" }}>
            <FaExclamationTriangle size={40} color='#FFA814' />
            <h1 style={{ fontSize: 18, fontWeight: "bold", marginTop: 10 }}>
              Apenas as comandas com ”Método de pagamento” selecionado serão finalizadas.
            </h1>
            <p>
              Há dois modos de selecionar o “Método de Pagamento”:
            </p>
            <img src={InsertPayment} alt="Imagem mostrando onde inserir pagamento na comanda" />
          </div>
          <footer style={{ display: "flex", justifyContent: "space-between" }}>
            <Button inverted buttonColor='#FF2C3A' title='Cancelar' onClick={toggleModalConfirmFinishBills} />
            <Button
              buttonColor='#2EC9B7'
              title='Confirmar'
              onClick={() => { toggleModalFinishBillsInBatch(); setIsModalConfirmationOpened(false) }}
            />
          </footer>
        </ModalBody>
      </Modal>
      <Header>
        <div>PRONTOS</div>

        <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
          <Button
            buttonColor="#1d9688"
            style={{ marginRight: 8, border: (!showPaid || !showUnpaid) ? "1px solid #fff" : "none" }}
            title='Filtrar'
            icon={<IoFilter color='#fff' size={21} />}
            onClick={toggleFilterModal}
          />
          <Warning
            containerStyles={{
              maxWidth: 600,
              width: 410,
              position: "absolute",
              top: 40,
              right: 20
            }}
            content="Agora você pode filtrar por aqui os pedidos 100% pagos ou não pagos."
            onClick={toggleWarning}
            show={warningbutton}
            title="Filtrar Pedidos"
            type="success"
          />
          <ItemsCount>{countOrders}</ItemsCount>
          {isFilterModalOpened && (
            <FilterArea ref={filterAreaRef}>
              <p>
                <Checkbox label='Pago' checked={showPaid}
                  onClick={() => handleFilterChange('paid')} />
              </p>
              <p>
                <Checkbox label='Não Pagos' checked={showUnpaid}
                  onClick={() => handleFilterChange('unpaid')} />
              </p>
            </FilterArea>
          )}

        </div>

      </Header>
      <Content style={{ borderBottomRightRadius: 12 }}>
        <Button
          title="Finalizar comandas"
          onClick={() => {
            toggleModalConfirmFinishBills()
          }}
          containerStyles={{
            width: '-webkit-fill-available',
            height: 30,
            margin: '5px 5px 0',
          }}
          inverted
          buttonColor='#3BD2C1'
        />

        {loading ? (
          <LoadingDiv>
            <Spinner color="warning" />
          </LoadingDiv>
        ) : (
          baskets
            .filter((order) => !order.scheduled_to)
            .filter(item => {
              if (showPaid && showUnpaid) {
                return true; // Exibe todos os itens
              }
              if (showPaid) {
                return item.is_paid === true;
              }
              if (showUnpaid) {
                return item.is_paid === false;
              }
              return false;
            })
            .map((item) => (
              <OrderReadyDelivery
                key={item.basket.id}
                handleCancelItem={handleCancelItem}
                handleItemFinished={handleItemFinished}
                item={item}
                paymentMethods={paymentMethods}
                allPaymentMethods={allPaymentMethods}
                favoritePaymentMethods={favoritePaymentMethods}
                getAllOrders={getAllOrders}
                handleUpdate={handleUpdate}
              />
            ))
        )}
        {!hasOrderActive && (
          <StyleKanbanWarning>
            <p style={{ color: "#666666" }}>Não há pedido</p>
            <p style={{ color: "#666666" }}>no momento</p>
          </StyleKanbanWarning>
        )}

      </Content>
    </Bloco>
  );
}