import { Modal } from 'reactstrap';
import styled from 'styled-components';

export const StyledModalCancelAll = styled(Modal)`
  .modal-content {
    padding: 0;
    max-height: 90vh;
  }

  h1 {
    color: #666;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    margin: 0;
  }

  .modal-header {
    padding: 24px 24px 0;
  }

  .modal-footer {
    padding: 24px !important;
  }

  .modal-body {
    padding: 8px 24px 0;
    max-height: calc(90vh - 234px);

    p {
      color: #666;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin: 0 0 32px;
    }
  }

  .icon-warning {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: #ffa814;
    margin: 0 auto 24px;
  }
`;
