import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';

import { toast } from 'react-hot-toast';

import {
  format,
  endOfDay,
  startOfMonth,
  addHours,
} from 'date-fns';

import {
  Wrapper,
  Header,
  Card,
  Content,
  ScrollArea,
  ButtonsDiv,
} from './styles';
import api from '~/services/api';
import NfesDevolucao from '~/components/NfesEntry/NfesDevolucao';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { FullCalendar } from 'ui-kit-takeat';

export default function NFeDevolucao() {
  const [searchDate, setSearchDate] = useState({
    start: startOfMonth(new Date()),
    end: endOfDay(new Date()),
  });

  const [nfesFiltered, setNfesFiltered] = useState([]);
  const [loading, setLoading] = useState(true);

  const updateNfe = useCallback(async () => {
    await api.get('/restaurants/nfe-received/pull');
  }, []);

  const getNfes = useCallback(async () => {
    try {
      const response = await api.get('restaurants/nfe-received', {
        params: {
          start_date: `${format(
            addHours(new Date(searchDate.start), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(searchDate.end), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          type: 'devolucao',
        },
      });

      setNfesFiltered(response.data);
    } catch (err) {
      console.log(err);
      toast.error('Erro ao carregar informações');
    }
  }, [searchDate]);

  useEffect(() => {
    getNfes();
    setLoading(false);
  }, [getNfes]);

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'FiscalReturnedNF'}`
      );

      const { can_read } = response.data.FiscalReturnedNF;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Wrapper>
      <Header>
        <p>
          Veja aqui as notas fiscais de devolução emitidas.
        </p>

        <ButtonsDiv>
          <FullCalendar value={searchDate} onDateSelected={setSearchDate} />
        </ButtonsDiv>
      </Header>
      <Card>
        <Content>
          <ScrollArea>
            <NfesDevolucao
              nfes={nfesFiltered}
              loading={loading}
              handleUpdate={getNfes}
            />
          </ScrollArea>
        </Content>
      </Card>
    </Wrapper>
  );
}
