import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import UserInfo from '~/components/Delivery/UserInfo';
import Cart from '~/components/OrderingSystem/Cart';
import {
  Content,
  OperationArea,
  OrderSystemArea,
  BillTableArea,
} from './styles';
import { useCart } from '~/context/OrderingSystem/Cart';
import Menu from '~/pages/OrderingSystem/Menu';
import { ModalConfirmation } from '~/components/ModalConfirmation';

function TableDelivery({
  session,
  getMinimalSessions,
  setMinimalSessions,
  inicialDate,
  finalDate,
  type,
  getTablesAvailable,
  sessionId,
  handleSearchInputTables,
  setCount,
  setInputValue,
  setTablesFilteredSearch,
  setAllTablesAvailable,
}) {
  const { closeCart, cart } = useCart();
  const [deliveryTax, setDeliveryTax] = useState(null);
  const [hasDeliveryTax, setHasDeliveryTax] = useState(true);
  const [discountObs, setDiscountObs] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [modalAdvice, setModalAdvice] = useState(false);
  const [adminPassword, setAdminPassword] = useState(null);

  function toggleModalAdvice() {
    setModalAdvice(!modalAdvice);
  }

  const [isModalOperationOpened, setIsModalOperationOpened] = useState(false);
  function toggleModalOperation() {
    if (cart.length > 0) {
      setModalAdvice(true);
    } else {
      setIsModalOperationOpened(!isModalOperationOpened);
      closeCart();
    }
  }

  function toggleModalOperationWithoutCloseCart() {
    setIsModalOperationOpened(!isModalOperationOpened);
  }

  function confirmCloseCart() {
    setIsModalOperationOpened(!isModalOperationOpened);
    toggleModalAdvice();
    closeCart();
  }

  const [modalEyeOpen, setModalEyeOpen] = useState(false);
  function toggleEyeOpen() {
    setModalEyeOpen(!modalEyeOpen);
  }
  const tableId = type !== 'available' ? session?.table?.id : session?.id;
  const tableNumber = 'Delivery';
  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
  return (
    <Content
      type={type}
      nullTable={session?.status}
      onClick={setIsModalOperationOpened}
    >
      {type !== 'available' ? (
        <>
          <p>{session?.table?.table_number}</p>
        </>
      ) : (
        <p>Delivery</p>
      )}

      <ModalConfirmation
        isOpen={modalAdvice}
        toggle={toggleModalAdvice}
        cancel={toggleModalAdvice}
        cancelText={'Irei fazer o pedido'}
        confirm={confirmCloseCart}
        confirmText={'Sim, sair e excluir o carrinho'}
        title="Você possui itens no carrinho. Tem certeza que deseja sair sem fazer o pedido?"
      />

      <Modal
        isOpen={isModalOperationOpened}
        toggle={toggleModalOperation}
        size="takeat"
      >
        <ModalBody>
          <OperationArea browserZoomLevel={browserZoomLevel}>
            <OrderSystemArea browserZoomLevel={browserZoomLevel}>
              <Menu
                tableId={tableId}
                getTablesAvailable={getTablesAvailable}
                getSessions={getMinimalSessions}
                session={sessionId}
                handleSearchInputTables={handleSearchInputTables}
                setInputValue={setInputValue}
                setTablesFilteredSearch={setTablesFilteredSearch}
                setAllTablesAvailable={setAllTablesAvailable}
                isTableDelivery
              />
            </OrderSystemArea>
            <BillTableArea browserZoomLevel={browserZoomLevel}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h2>Delivery </h2>{' '}
                <span
                  style={{ fontWeight: 'bold', fontSize: 20, paddingRight: 10 }}
                >
                  Carrinho
                </span>
              </div>
              <Cart
                isTableDelivery
                deliveryTax={deliveryTax}
                setDiscount={setDiscount}
                setDiscountObs={setDiscountObs}
                discount={discount}
                discountObs={discountObs}
                adminPassword={adminPassword}
                setAdminPassword={setAdminPassword}
                isBalcony={false}
                hasDeliveryTax={hasDeliveryTax}
              />
              <UserInfo
                toggleModalOperation={toggleModalOperationWithoutCloseCart}
                setDeliveryTax={setDeliveryTax}
                hasDeliveryTax={hasDeliveryTax}
                setHasDeliveryTax={setHasDeliveryTax}
                discount={discount}
                discountObs={discountObs}
                adminPassword={adminPassword}
                setAdminPassword={setAdminPassword}
              />
              {/* <ButtonsArea>
                <button>Fazer PEdido</button>
              </ButtonsArea> */}
            </BillTableArea>
          </OperationArea>
        </ModalBody>
      </Modal>
    </Content>
  );
}

export default TableDelivery;