import React from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import { StyledModalCancel } from './styles';
import { Button } from 'ui-kit-takeat';
import { useRef } from 'react';
import Input from '~/components/Form/Input';
import { Form } from '@unform/web';

export const ModalCancel = ({ isOpen, setIsOpen, onSubmit, loading }) => {
  const cancelForm = useRef(null);
  return (
    <StyledModalCancel isOpen={isOpen} toggle={() => setIsOpen(false)}>
      <Form onSubmit={(data) => onSubmit(data, cancelForm)} ref={cancelForm}>
        <ModalBody>
          <Input
            name="order_cancel_password"
            label="Senha de cancelamento*"
            type="password"
          />
          <Input name="cancel_reason" label="Justificativa*" />
        </ModalBody>
        <ModalFooter>
          <Button
            title="Confirmar"
            type="submit"
            buttonColor="#2EC9B7"
            disabled={loading}
            isLoading={loading}
            style={{ marginLeft: 'auto' }}
          />
        </ModalFooter>
      </Form>
    </StyledModalCancel>
  );
};
