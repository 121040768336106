import React from 'react';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { StyledModalCancelAll } from './styles';
import { IoWarningOutline } from 'react-icons/io5';
import { Button } from 'ui-kit-takeat';
import { ModalCancelItem } from '../ModalCancelItem';

export const ModalCancelAll = ({
  bills,
  isOpen,
  setIsOpen,
  onConfirm,
  loading,
}) => {
  return (
    <StyledModalCancelAll isOpen={isOpen} toggle={() => setIsOpen(false)}>
      <ModalHeader>
        <div className="icon-warning">
          <IoWarningOutline size={40} />
        </div>
        <h1>Tem certeza que deseja cancelar todos os pedidos dessa comanda?</h1>
      </ModalHeader>
      <ModalBody>
        <p>
          Ao cancelar todos os pedidos, a mesa estará disponível para uso
          novamente e os pagamentos serão deletados.
        </p>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            overflowY: 'auto',
            maxHeight: 'calc(90vh - 319px)',
          }}
        >
          {bills?.length > 0 &&
            bills.map((bill) => {
              return <ModalCancelItem key={bill.id} bill={bill} />;
            })}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          title="Não"
          onClick={() => {
            setIsOpen(false);
          }}
        />
        <Button
          buttonColor="#2EC9B7"
          title="Sim, cancelar tudo"
          disabled={loading}
          isLoading={loading}
          onClick={onConfirm}
        />
      </ModalFooter>
    </StyledModalCancelAll>
  );
};
