import React, { useCallback, useEffect, useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { FaDownload } from 'react-icons/fa';

import { toast } from 'react-hot-toast';

import FileSaver from 'file-saver';
import { CardBody, Card, Spinner, Collapse } from 'reactstrap';

import {
  CategoryRow,
  CCTableHeader,
  CollapsableTd,
  Container,
  CostCenterTable,
  EarningRow,
  Header,
  ResultRow,
  SubCategoryRow,
  StyledSelect,
  DownloadSheetButton,
} from './styles';

import Select from '~/components/Form/SelectInput';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';

import api from '~/services/api';
import { generateSheet } from '~/services/SheetGenerate/generateDRESheet';
import { PageTitle } from '~/components/PageTitle';
import { eachYearOfInterval } from 'date-fns';

function Dre() {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [liquidProfit, setLiquidProfit] = useState([]);
  const [cmv, setCmv] = useState([]);
  const [totalPaid, setTotalPaid] = useState([]);
  const [contributionMargin, setContributionMargin] = useState([]);
  const [financial, setFinancial] = useState([]);
  const [inputs, setInputs] = useState([]);
  const [operational, setOperational] = useState([]);
  const [methodsSubtotal, setMethodsSubtotal] = useState([]);
  const [taxSubtotal, setTaxSubtotal] = useState([]);
  const [earningManual, setEarningManual] = useState([]);

  const [collapse, setCollapse] = useState([]);
  const [subCollapse, setSubCollapse] = useState([]);
  const [collapseTaxCard, setCollapseTaxCard] = useState(false);
  const [resultFlat, setResultFlat] = useState([]);
  const [resultPercentage, setResultPercentage] = useState([]);
  const [loadingDre, setLoadingDre] = useState(false);
  const [govTaxSubtotal, setGovTaxSubtotal] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);

  const years = eachYearOfInterval({
    start: new Date('01/01/2022'),
    end: new Date(),
  }).map((y) => {
    return {
      label: y.getFullYear(),
      value: y.getFullYear(),
    };
  });

  const thisYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(
    years.find((y) => y.value === thisYear)
  );

  const getPayments = useCallback(async () => {
    setLoadingDre(true);
    const response = await api.get(
      `restaurants/dre/payments?year=${selectedYear.value}`
    );

    setPaymentMethods(response.data.payment_methods);
    setMethodsSubtotal(response.data.methods_subtotal);
    setTaxSubtotal(response.data.tax_subtotal);
    setEarningManual(response.data.earning_subtotal);
  }, [selectedYear]);

  const getExpenses = useCallback(async () => {
    setLoadingDre(true);
    const response = await api.get(
      `restaurants/reports/expenses-dre?year=${selectedYear.value}`
    );

    setFinancial(response.data.filter((cf) => cf.type === 'financeiro'));
    setInputs(response.data.filter((cf) => cf.type === 'insumos'));
    const respOperational = response.data.filter(
      (cf) => cf.type === 'operacional'
    );
    setOperational(respOperational);

    const x = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    setTotalPaid(
      x.map((v, i) => {
        return respOperational.reduce((acc, cur) => acc + cur.values[i], 0);
      })
    );
  }, [selectedYear]);

  const format = (value) => {
    if (value === null) {
      value = 0;
    }
    return value.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleCollapse = (id) => {
    if (collapse.includes(id)) {
      const newCollapse = collapse.filter((c) => c !== id);
      setCollapse(newCollapse);
    } else {
      setCollapse((state) => [...state, id]);
    }
  };

  const handleSubCollapse = (id) => {
    if (subCollapse.includes(id)) {
      const newSubCollapse = subCollapse.filter((c) => c !== id);
      setSubCollapse(newSubCollapse);
    } else {
      setSubCollapse((state) => [...state, id]);
    }
  };

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'FinanceDRE'}`
      );

      const { can_read } = response.data.FinanceDRE;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  useEffect(() => {
    setLiquidProfit(
      methodsSubtotal.map(
        (m, i) =>
          m +
          earningManual[i] -
          taxSubtotal[i] -
          financial.reduce((acc, cur) => acc + cur.values[i], 0)
      )
    );
    setGovTaxSubtotal(
      govTaxSubtotal.map((t, i) =>
        financial.reduce((acc, cur) => acc + cur.values[i], 0)
      )
    );
  }, [methodsSubtotal, taxSubtotal, financial, earningManual]);

  useEffect(() => {
    setResultFlat(
      contributionMargin.map((cm, i) => {
        return cm - totalPaid[i];
      })
    );
  }, [liquidProfit, contributionMargin, totalPaid]);

  useEffect(() => {
    setResultPercentage(
      methodsSubtotal.map((m, i) => {
        return m === 0 ? ' - ' : (resultFlat[i] / (m + earningManual[i])) * 100;
      })
    );
  }, [methodsSubtotal, resultFlat]);

  useEffect(() => {
    if (resultPercentage.length > 0) {
      setLoadingDre(false);
    }
  }, [resultPercentage]);

  useEffect(() => {
    setContributionMargin(
      liquidProfit.map((l, i) => {
        return l - inputs.reduce((acc, cur) => acc + cur.values[i], 0);
      })
    );
    setCmv(
      methodsSubtotal.map((m, i) => {
        return m === 0
          ? ' - '
          : (inputs.reduce((acc, cur) => acc + cur.values[i], 0) /
              (m + earningManual[i])) *
              100;
      })
    );
  }, [liquidProfit, inputs]);

  useEffect(() => {
    getExpenses();
    getPayments();
  }, [getPayments, getExpenses, selectedYear]);

  const handleDownloadSheet = () => {
    try {
      const expenses = {
        financial,
        inputs,
        operational,
      };

      const payments = {
        payment_methods: paymentMethods,
        methods_subtotal: methodsSubtotal,
        tax_subtotal: taxSubtotal,
        earning_subtotal: earningManual,
      };

      const sheet = generateSheet(selectedYear.value, expenses, payments);

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        `DRE-${selectedYear.value}_(${format(new Date(), 'dd-MM-yyyy')}).xlsx`
      );
    } catch (err) {
      console.error('Error download sheet', err);
    }
  };

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <PageTitle>Demonstração do Resultado do Exercício (DRE) </PageTitle>
        <p>Acompanhe os custos mensais de seu estabelecimento!</p>
      </Header>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <StyledSelect>
          <span style={{ marginRight: '5px' }}>Selecionar ano: </span>
          <Select
            name="year"
            options={years}
            defaultValue={selectedYear}
            onChange={(e) => setSelectedYear(e)}
          />
        </StyledSelect>
        <DownloadSheetButton onClick={handleDownloadSheet}>
          <FaDownload style={{ marginRight: '3px' }} />
          Baixar planilha
        </DownloadSheetButton>
      </div>
      <Card
        style={{
          height: 'calc(100% - 114px)',
          marginBottom: 0,
          padding: 5,
          borderRadius: 8,
          boxShadow: '1px 0px 5px 1px rgba(0,0,0,0.1)',
        }}
      >
        <CardBody
          style={{
            textAlign: loadingDre && 'center',
            padding: 0,
            overflow: 'scroll',
            height: 'calc(100% - 120px)',
          }}
        >
          {loadingDre ? (
            <Spinner />
          ) : (
            <CostCenterTable>
              <CCTableHeader>
                <tr>
                  <th
                    style={{
                      padding: '12px 7px',
                      color: '#525f7f',
                      textAlign: 'left',
                    }}
                  >
                    Contas
                  </th>
                  <th style={{ padding: '12px 7px', color: '#525f7f' }}>
                    JANEIRO
                  </th>
                  <th style={{ padding: '12px 7px', color: '#525f7f' }}>
                    FEVEREIRO
                  </th>
                  <th style={{ padding: '12px 7px', color: '#525f7f' }}>
                    MARÇO
                  </th>
                  <th style={{ padding: '12px 7px', color: '#525f7f' }}>
                    ABRIL
                  </th>
                  <th style={{ padding: '12px 7px', color: '#525f7f' }}>
                    MAIO
                  </th>
                  <th style={{ padding: '12px 7px', color: '#525f7f' }}>
                    JUNHO
                  </th>
                  <th style={{ padding: '12px 7px', color: '#525f7f' }}>
                    JULHO
                  </th>
                  <th style={{ padding: '12px 7px', color: '#525f7f' }}>
                    AGOSTO
                  </th>
                  <th style={{ padding: '12px 7px', color: '#525f7f' }}>
                    SETEMBRO
                  </th>
                  <th style={{ padding: '12px 7px', color: '#525f7f' }}>
                    OUTUBRO
                  </th>
                  <th style={{ padding: '12px 7px', color: '#525f7f' }}>
                    NOVEMBRO
                  </th>
                  <th style={{ padding: '12px 7px', color: '#525f7f' }}>
                    DEZEMBRO
                  </th>
                </tr>
              </CCTableHeader>
              <tbody>
                <EarningRow
                  onClick={() => {
                    handleCollapse('profits');
                  }}
                >
                  <td style={{ fontWeight: 'bold' }}>
                    {' '}
                    <MdKeyboardArrowRight
                      style={{
                        transition: 'all 0.2s',
                        transform: collapse.includes('profits')
                          ? 'rotate(90deg)'
                          : 'rotate(0)',
                      }}
                    />{' '}
                    Receitas com vendas
                  </td>
                  {methodsSubtotal.map((st) => (
                    <td>{format(st)}</td>
                  ))}
                </EarningRow>
                {paymentMethods.map((p) => {
                  const check = collapse.includes('profits');
                  return (
                    <SubCategoryRow>
                      <CollapsableTd check={check}>
                        <Collapse isOpen={check}>{p.name}</Collapse>
                      </CollapsableTd>
                      {p.data.map((v) => (
                        <CollapsableTd check={check}>
                          <Collapse isOpen={check}>{format(v)}</Collapse>
                        </CollapsableTd>
                      ))}
                    </SubCategoryRow>
                  );
                })}

                <EarningRow>
                  <td style={{ fontWeight: 'bold' }}>
                    Receitas Não Operacionais
                  </td>
                  {earningManual.map((st) => (
                    <td>{format(st)}</td>
                  ))}
                </EarningRow>

                {financial.map((f) => {
                  const check = collapse.includes(f.id);
                  return (
                    <>
                      <CategoryRow onClick={() => handleCollapse(f.id)}>
                        <td style={{ fontWeight: 'bold' }}>
                          <MdKeyboardArrowRight
                            style={{
                              transition: 'all 0.2s',
                              transform: check ? 'rotate(90deg)' : 'rotate(0)',
                            }}
                          />
                          {f.category}
                        </td>
                        {f.values.map((v) => (
                          <td>{format(v)}</td>
                        ))}
                      </CategoryRow>
                      {f.subs.map((s) => {
                        const subcheck = subCollapse.includes(s.id) && check;
                        return (
                          <>
                            <SubCategoryRow
                              clickable={s.subsubs.length > 0}
                              onClick={() => {
                                handleSubCollapse(s.id);
                              }}
                            >
                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}>
                                  &emsp;
                                  {s.subsubs.length > 0 && (
                                    <MdKeyboardArrowRight
                                      style={{
                                        transition: 'all 0.2s',
                                        transform: subcheck
                                          ? 'rotate(90deg)'
                                          : 'rotate(0)',
                                      }}
                                    />
                                  )}
                                  {s.name}
                                </Collapse>
                              </CollapsableTd>
                              {s.values.map((v) => (
                                <CollapsableTd check={check}>
                                  <Collapse isOpen={check}>
                                    {format(v)}
                                  </Collapse>
                                </CollapsableTd>
                              ))}
                            </SubCategoryRow>
                            {s.subsubs.map((ss) => {
                              return (
                                <SubCategoryRow>
                                  <CollapsableTd check={subcheck}>
                                    <Collapse isOpen={subcheck}>
                                      &emsp;&emsp;&emsp;{ss.name}
                                    </Collapse>
                                  </CollapsableTd>
                                  {ss.values.map((vv) => (
                                    <CollapsableTd check={subcheck}>
                                      <Collapse isOpen={subcheck}>
                                        {format(vv)}
                                      </Collapse>
                                    </CollapsableTd>
                                  ))}
                                </SubCategoryRow>
                              );
                            })}
                          </>
                        );
                      })}
                    </>
                  );
                })}
                <CategoryRow onClick={() => handleCollapse('tax')}>
                  <td style={{ fontWeight: 'bold' }}>
                    <MdKeyboardArrowRight
                      style={{
                        transition: 'all 0.2s',
                        transform: collapseTaxCard
                          ? 'rotate(90deg)'
                          : 'rotate(0)',
                      }}
                    />
                    Despesas com vendas
                  </td>
                  {taxSubtotal.map((st) => (
                    <td>{format(st)}</td>
                  ))}
                </CategoryRow>
                {paymentMethods.map((p) => {
                  const check = collapse.includes('tax');
                  if (parseFloat(p.tax) > 0) {
                    return (
                      <SubCategoryRow>
                        <CollapsableTd check={check}>
                          <Collapse isOpen={check}>
                            {`Taxa de ${p.name} (${p.tax}%)`}
                          </Collapse>
                        </CollapsableTd>
                        {p.taxData.map((v) => (
                          <CollapsableTd check={check}>
                            <Collapse isOpen={check}>{format(v)}</Collapse>
                          </CollapsableTd>
                        ))}
                      </SubCategoryRow>
                    );
                  }
                })}
                <ResultRow>
                  <td
                    style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                  >
                    Faturamento líquido
                  </td>
                  {liquidProfit.map((p) => (
                    <td>{format(p)}</td>
                  ))}
                </ResultRow>
                {inputs.map((i) => {
                  const check = collapse.includes(i.id);
                  return (
                    <>
                      <CategoryRow
                        onClick={() => {
                          handleCollapse(i.id);
                        }}
                      >
                        <td style={{ fontWeight: 'bold' }}>
                          <MdKeyboardArrowRight
                            style={{
                              transition: 'all 0.2s',
                              transform: check ? 'rotate(90deg)' : 'rotate(0)',
                            }}
                          />
                          {i.category}
                        </td>
                        {i.values.map((v) => (
                          <td>{format(v)}</td>
                        ))}
                      </CategoryRow>

                      {i.subs.map((s) => {
                        const subcheck = subCollapse.includes(s.id) && check;
                        return (
                          <>
                            <SubCategoryRow
                              clickable={s.subsubs.length > 0}
                              onClick={() => {
                                handleSubCollapse(s.id);
                              }}
                            >
                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}>
                                  &emsp;
                                  {s.subsubs.length > 0 && (
                                    <MdKeyboardArrowRight
                                      style={{
                                        transition: 'all 0.2s',
                                        transform: subcheck
                                          ? 'rotate(90deg)'
                                          : 'rotate(0)',
                                      }}
                                    />
                                  )}
                                  {s.name}
                                </Collapse>
                              </CollapsableTd>
                              {s.values.map((v) => (
                                <CollapsableTd check={check}>
                                  <Collapse isOpen={check}>
                                    {format(v)}
                                  </Collapse>
                                </CollapsableTd>
                              ))}
                            </SubCategoryRow>
                            {s.subsubs.map((ss) => {
                              return (
                                <SubCategoryRow>
                                  <CollapsableTd check={subcheck}>
                                    <Collapse isOpen={subcheck}>
                                      &emsp;&emsp;&emsp;{ss.name}
                                    </Collapse>
                                  </CollapsableTd>
                                  {ss.values.map((vv) => (
                                    <CollapsableTd check={subcheck}>
                                      <Collapse isOpen={subcheck}>
                                        {format(vv)}
                                      </Collapse>
                                    </CollapsableTd>
                                  ))}
                                </SubCategoryRow>
                              );
                            })}
                          </>
                        );
                      })}
                    </>
                  );
                })}
                <ResultRow>
                  <td
                    style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                  >
                    Despesas CMV (%)
                  </td>
                  {cmv.map((v) => (
                    <td>{format(v)}%</td>
                  ))}
                </ResultRow>
                <ResultRow>
                  <td
                    style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                  >
                    Margem de contribuição (R$)
                  </td>
                  {contributionMargin.map((c) => (
                    <td>{format(c)}</td>
                  ))}
                </ResultRow>
                {operational.map((o) => {
                  const check = collapse.includes(o.id);
                  return (
                    <>
                      <CategoryRow onClick={() => handleCollapse(o.id)}>
                        <CollapsableTd style={{ fontWeight: 'bold' }}>
                          <MdKeyboardArrowRight
                            style={{
                              transition: 'all 0.2s',
                              transform: check ? 'rotate(90deg)' : 'rotate(0)',
                            }}
                          />
                          {o.category}
                        </CollapsableTd>
                        {o.values.map((v) => (
                          <CollapsableTd check={check}>
                            {format(v)}
                          </CollapsableTd>
                        ))}
                      </CategoryRow>

                      {o.subs.map((s) => {
                        const subcheck = subCollapse.includes(s.id) && check;
                        return (
                          <>
                            <SubCategoryRow
                              clickable={s.subsubs.length > 0}
                              onClick={() => {
                                handleSubCollapse(s.id);
                              }}
                            >
                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}>
                                  &emsp;
                                  {s.subsubs.length > 0 && (
                                    <MdKeyboardArrowRight
                                      style={{
                                        transition: 'all 0.2s',
                                        transform: subcheck
                                          ? 'rotate(90deg)'
                                          : 'rotate(0)',
                                      }}
                                    />
                                  )}
                                  {s.name}
                                </Collapse>
                              </CollapsableTd>
                              {s.values.map((v) => (
                                <CollapsableTd check={check}>
                                  <Collapse isOpen={check}>
                                    {format(v)}
                                  </Collapse>
                                </CollapsableTd>
                              ))}
                            </SubCategoryRow>
                            {s.subsubs.map((ss) => {
                              return (
                                <SubCategoryRow>
                                  <CollapsableTd check={subcheck}>
                                    <Collapse isOpen={subcheck}>
                                      &emsp;&emsp;&emsp;{ss.name}
                                    </Collapse>
                                  </CollapsableTd>
                                  {ss.values.map((vv) => (
                                    <CollapsableTd check={subcheck}>
                                      <Collapse isOpen={subcheck}>
                                        {format(vv)}
                                      </Collapse>
                                    </CollapsableTd>
                                  ))}
                                </SubCategoryRow>
                              );
                            })}
                          </>
                        );
                      })}
                    </>
                  );
                })}
                <ResultRow>
                  <td
                    style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                  >
                    Total de contas pagas
                  </td>
                  {totalPaid.map((t) => (
                    <td>{format(t)}</td>
                  ))}
                </ResultRow>
                <ResultRow>
                  <td
                    style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                  >
                    Resultado operacional (R$)
                  </td>
                  {resultFlat.map((v) => (
                    <td>{format(v)}</td>
                  ))}
                </ResultRow>
                <ResultRow>
                  <td
                    style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                  >
                    Resultado operacional (%)
                  </td>
                  {resultPercentage.map((v) => (
                    <td>{format(v)}%</td>
                  ))}
                </ResultRow>
              </tbody>
            </CostCenterTable>
          )}
        </CardBody>
      </Card>
    </Container>
  );
}

export default Dre;
