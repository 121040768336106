import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';

export const Container = styled.div`
  width: 100%;

  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fff;
  width: 100%;
  padding: 0 15px 20px 20px;
  height: 100%;
  overflow: scroll;

  @media (max-width: 1100px) {
    padding: 0 5px 20px 10px;
  }
`;

export const CategoriesTable = styled(Table)`
  width: 100%;
  align-items: center;
  border-collapse: separate;
  border-radius: 8px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    border-top: none;
    tr {
      th {
        color: #333;
        text-align: center;
        button {
          border: none;
          background-color: transparent;
          font-weight: bold;
          color: #333;
        }
      }
      th:first-child {
        text-align: left;
      }
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      font-size: 16px;
      border-bottom: 1px solid #33333310;
      color: #333;
      text-align: center;
    }
    td:last-child {
      text-align: right;
    }
    td:first-child {
      text-align: left;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;
export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }

  margin-right: 10px;
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div``;

export const NavDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  clear: both;
`;

export const NavDivTeste = styled.div`
  float: right;
  margin-left: -11px;
`;

export const LoadingDiv = styled.div`
  padding-top: 20px;
  justify-content: right;
  height: 60px;
  width: 100%;
  text-align: right;
`;

export const EmptyDiv = styled.div`
  padding-top: 20px;
  justify-content: center;
  height: 60px;
  width: 95%;
  text-align: center;
`;

export const NavLinkSpan = styled.span`
  margin-right: 5px;
`;

export const OrdersContainer = styled.div`
  background-color: #fff;
  width: 100%;
  padding-top: 20px;

  height: calc(100vh - 155px);
  overflow: hidden;
  box-shadow: 1px 0px 5px 1px rgba(0, 0, 0, 0.1);

  border-radius: 7px;
  border-top-left-radius: ${(props) => props.isFirst && '0px'};
  transition: border-radius 0.2s ease-in;
`;

export const OrdersHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding: 0 10px;

  span {
    color: #666;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    flex-basis: 20%;

    text-align: center;
    &:last-child {
      text-align: right;
    }
    &:first-child {
      text-align: left;
    }

    @media (max-width: 1100px) {
      font-size: 12px;
    }
  }
`;
