/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { Badge } from 'reactstrap';
import api from '~/services/api';

import { AverageTime, Complement, OrdersInfo, ProductName } from './styles';
import { FaNoteSticky } from 'react-icons/fa6';
import { AverageArea } from '~/components/NfesEntry/NfeManifestItem/styles';
import { FaClock } from 'react-icons/fa';
import formatTime from '~/services/formatTime';

function OrderItemsCanceled({ product, basket, isLate, index, isVeryLate }) {
  const handleCheckProduct = useCallback(
    async (id, status) => {
      await api.put(`/restaurants/kds/basket/order-check/${id}`, {
        checked: status,
      });
    },
    []
  );

  return (
    <OrdersInfo isLate={isLate} isVeryLate={isVeryLate} status={basket?.status}>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <span
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 10,
          }}
        >
          <ProductName canceled={product?.canceled_at} isLate={isLate} checked={product.checked} isVeryLate={isVeryLate}>
            {product?.amount}x {product?.name}
          </ProductName>
          <span style={{ marginTop: 3, flexWrap: "wrap" }}>
            {product?.complements &&
              product?.complements.length > 0 &&
              product?.complements?.map((comp) => (
                <Complement isVeryLate={isVeryLate} isLate={isLate}>
                  {comp.amount}x {comp.name}
                </Complement>
              ))}
          </span>

          {product.details && (
            <span
              style={{ marginTop: 3, display: 'flex', alignItems: 'center' }}
            >
              <FaNoteSticky />
              <span style={{ marginLeft: 4, wordWrap: "break-word", maxWidth: "21rem" }}>{product.details}</span>
            </span>
          )}
        </span>
      </span>

      <AverageTime>
        <span>
          #{basket?.basket_key}
        </span>
        <span style={{ display: "flex", justifyContent: "flex-end" }}>
          <FaClock />{' '}
          <span style={{ marginLeft: 8 }}>
            {basket &&
              basket.arrived_at &&
              formatTime(basket?.arrived_at)}
          </span>
        </span>
      </AverageTime>
    </OrdersInfo>
  );
}

export default OrderItemsCanceled;
