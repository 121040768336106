import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';

export const Container = styled.div`
  margin: 10px;
`;
export const SetSliceSizeButton = styled.button`
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: #ff2c3a;
  font-size: 16px;
  margin-top: 10px;
  margin-left: 40px;
  float: left;
  font-weight: bold;
`;

export const Header = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;

  &:nth-child(2) {
    margin-top: 82px;
    border-top: 1px solid #33333320;
    padding-top: 18px;
  }
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }

  svg {
    color: ${(props) => props.theme.colors.tertiary};
    margin-right: 10px;
  }
`;

export const ProductsTable = styled(Table)`
  width: 100%;
  margin: 10px auto 30px auto;
  border-collapse: initial;
  border-spacing: 0 20px;
  overflow: hidden;
  padding: 0 30px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-top: 1px solid #33333310;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const CategoryHeader = styled.div`
  background: #33333320;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 20px;
  border-radius: 7px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 14px;
  padding-left: 14px;

  div {
    display: flex;
    align-items: center;
  }

  p {
    font-size: 20px;
    margin-top: 14px;
  }
`;

export const ProductImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin: 10px 5px 10px 0;
  border-radius: 5px;

  ${(props) =>
    props.available &&
    css`
      img {
        opacity: 0.7;
      }
    `}
`;

export const Delete = styled.button`
  border: none;
  background: none;
  margin-right: 8px;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div``;

export const ProductTableRow = styled.tr`
  ${(props) =>
    !props.available &&
    css`
      opacity: 0.5;
    `}
  ${(props) =>
    props.sold_off &&
    css`
      opacity: 0.5;
    `}
`;

export const ButttonArea = styled.div`
  margin-left: 50px;
  margin-bottom: 20px;
`;

export const ButtonCollapse = styled.div`
  margin-left: 50px;
  border: none;
  background: none;
  margin-bottom: 16px;
  font-weight: bold;

  cursor: pointer;
`;
