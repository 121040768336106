/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { format } from 'date-fns';
import XLSX from 'xlsx-js-style';

async function getEarningsData(data) {
  let sheet_data = [];
  let catRows = [];
  let index = 2;

  const tableNames = {
    balcony: 'Balcão',
    table: 'Mesa',
    delivery: 'Delivery',
  };

  sheet_data.push([
    'TOTAL',
    data.totalPaymentsReport.reduce(
      (acc, cur) =>
        acc +
        cur.payments.reduce(
          (acc2, cur2) => acc2 + parseFloat(cur2.payment_value),
          0
        ),
      0
    ),
    data.localPaymentsReport.reduce(
      (acc, cur) =>
        acc +
        cur.payments.reduce(
          (acc2, cur2) => acc2 + parseFloat(cur2.payment_value),
          0
        ),
      0
    ),
    data.deliveryPaymentsReport.reduce(
      (acc, cur) =>
        acc +
        cur.payments.reduce(
          (acc2, cur2) => acc2 + parseFloat(cur2.payment_value),
          0
        ),
      0
    ),
    data.ifoodPaymentsReport.reduce(
      (acc, cur) =>
        acc +
        cur.payments.reduce(
          (acc2, cur2) => acc2 + parseFloat(cur2.payment_value),
          0
        ),
      0
    ),
    data.totalPaymentsReport.reduce(
      (acc, cur) => acc + parseFloat(cur.tax_payments_price),
      0
    ),
    data.totalPaymentsReport.reduce(
      (acc, cur) => acc + parseFloat(cur.liquid_payments_price),
      0
    ),
  ]);

  data.totalPaymentsReport.forEach((pay, idx) => {
    const sheet_item = [
      pay.name,
      pay.total_payments_price,
      data.localPaymentsReport[idx].total_payments_price,
      data.deliveryPaymentsReport[idx].total_payments_price,
      data.ifoodPaymentsReport[idx].total_payments_price,
      pay.tax_payments_price,
      pay.liquid_payments_price,
    ];
    sheet_data.push(sheet_item);

    catRows.push(index + 1);
    index++;
    pay.payments.forEach((p) => {
      index++;
      let sheet_subitem = {};
      if (p.is_food) {
        sheet_subitem = [
          `Ifood - ${format(new Date(p.created_at), 'dd/MM/yy - HH:mm')}`,
          p.payment_value,
          '-',
          '-',
          p.payment_value,
          '-',
          '-',
        ];
      } else if (p.is_delivery) {
        sheet_subitem = [
          `Delivery - ${format(new Date(p.created_at), 'dd/MM/yy - HH:mm')}`,
          p.payment_value,
          '-',
          p.payment_value,
          '-',
          '-',
          '-',
        ];
      } else {
        sheet_subitem = [
          `${tableNames[p.table_type] + ' ' + p.table_number} - ${format(
            new Date(p.created_at),
            'dd/MM/yy - HH:mm'
          )}`,
          p.payment_value,
          p.payment_value,
          '-',
          '-',
          '-',
          '-',
        ];
      }

      sheet_data.push(sheet_subitem);
    });
  });

  const sheet_header = [
    'Método',
    'Total',
    'Presencial',
    'Delivery',
    'iFood',
    'Taxa',
    'Líquido',
  ];

  return { data: [sheet_header, ...sheet_data], catRows };
}

export async function generateEarningsSheetXLSX(req) {
  const { data, catRows } = await getEarningsData(req);

  const columns = 'abcdefghijklmnopqrstuvwxyz'.toLocaleUpperCase().split('');

  const num_cols = data[0].length;

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(data);

  // Every column with 15 chars of width
  worksheet['!cols'] = new Array(num_cols);
  worksheet['!cols'].fill({ wch: 15 });

  const num_lines = data.length;

  for (let j = 0; j < num_cols; j++) {
    for (let i = 1; i <= num_lines; i++) {
      const cell = worksheet[columns[j] + i];

      if (catRows.includes(i)) {
        cell.s = {
          fill: {
            fgColor: {
              rgb: 'ff6d6d',
            },
            bgColor: {
              rgb: 'ff6d6d',
            },
          },
          border: {
            top: {
              style: 'thin',
              color: {
                rgb: '000000',
              },
            },
            bottom: {
              style: 'thin',
              color: {
                rgb: '000000',
              },
            },
          },
        };
      }

      if (j > 0) {
        cell.s = { ...cell.s, alignment: { horizontal: 'center' } };
      }
    }
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório Produtos');

  return XLSX.write(workbook, { type: 'buffer' });
}
