import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 10px;
  width: 100%;

    ${(props) =>
    props.browserZoomLevel > 100 &&
    css`
       max-height: 85vh;
    `}

`;
