import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-hot-toast';

import Switch from 'react-switch';
import { Modal, ModalBody, Collapse } from 'reactstrap';

import { FaAngleDown } from 'react-icons/fa';
import api from '~/services/api';
import {
  Container,
  CategoryName,
  CategoryHeader,
  ButtonAssociate,
  SetSliceSizeButton,
} from './styles';
import Complements from '../Complements';

import CreateComplement from '../Complements/Create';
import AssociateComplement from './AssociateComplement';
import ComplementCategoryDropdown from './ComplementCategoryDropdown';
import RegisterButton from '~/components/Buttons/RegisterButton';

function ComplementCategories({
  menewProducts,
  productsCategories,
  setProductsCategories,
  fiscalGroups,
}) {
  const [count, setCount] = useState('');

  const [categories, setCategories] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalAssociateCategory, setModalAssociateCategory] = useState();
  const [modalCreateComplements, setModalCreateComplements] = useState();

  const [modalNewComplement, setModalNewComplement] = useState();

  function toggle() {
    setModal(!modal);
  }

  function toggleNewComplement() {
    setModalNewComplement(!modalNewComplement);
  }

  function AssociateComplements(category) {
    setModalAssociateCategory(category);
    toggle();
  }

  function createComplements(category) {
    setModalCreateComplements(category);
    toggleNewComplement();
  }

  const getCategories = useCallback(async () => {
    try {
      const response = await api.get(
        '/restaurants/product/complement-category'
      );

      setCategories(response.data);
    } catch (err) {
      toast.error('Falha ao carregar dados');
    }
  }, []);

  async function handleChangeComplementCategory(value, event, idx) {
    try {
      const id = Number(idx);
      await api.put(`/restaurants/product/complement-category/${id}`, {
        available: value,
      });

      getCategories();
      toast.success('Categoria alterado com sucesso!');
      localStorage.setItem('@gddashboard:isMenuUpdated', true);
    } catch (error) {
      toast.error('Erro ao ativar/ desativar categoria');
    }
  }

  useEffect(() => {
    try {
      getCategories();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getCategories]);

  const [collapse, setCollapse] = useState([]);

  const handleCollapse = (id) => {
    if (collapse.includes(id)) {
      const newCollapse = collapse.filter((c) => c !== id);
      setCollapse(newCollapse);
    } else {
      setCollapse((state) => [...state, id]);
    }
  };

  const [sliceSize, setSliceSize] = useState(5);
  const [angleChanged, setAngleChange] = useState(false);

  function handleSetSliceSize() {
    setSliceSize(sliceSize + 5);
    setAngleChange(true);
  }

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalBody>
          <AssociateComplement
            category={modalAssociateCategory}
            toggle={toggle}
            getCategories={getCategories}
            setCount={setCount}
            productsCategories={productsCategories}
            setProductsCategories={setProductsCategories}
          />
        </ModalBody>
      </Modal>
      <Modal isOpen={modalNewComplement} toggle={toggleNewComplement} size="lg">
        <ModalBody>
          <CreateComplement
            category={modalCreateComplements}
            getCategories={getCategories}
            toggle={toggleNewComplement}
            setCount={setCount}
          />
        </ModalBody>
      </Modal>
      {categories && categories.length > 0 ? (
        categories.slice(0, sliceSize).map((category, index) => (
          <Container>
            <CategoryHeader>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Switch
                  id={`${category.id}`}
                  onChange={handleChangeComplementCategory}
                  draggable
                  checked={category.available}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={27}
                  width={44}
                  handleDiameter={16}
                  activeBoxShadow="0 0 2px 3px #111"
                  boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                  onColor="#0CCAB4"
                  offColor="#666666"
                  disabled={category.is_ifood}
                />
                <CategoryName>{category.name}</CategoryName>
              </div>

              <div>
                <FaAngleDown
                  size={20}
                  title="Mostrar Produtos"
                  onClick={() => {
                    handleCollapse(category.id);
                  }}
                  style={{
                    transition: 'all 0.2s',
                    transform: collapse.includes(category.id)
                      ? 'rotate(-90deg)'
                      : 'rotate(0)',
                    marginLeft: 22,
                  }}
                />

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 30,
                  }}
                >
                  <ComplementCategoryDropdown
                    category={category}
                    getCategories={getCategories}
                    productsCategories={productsCategories}
                    setProductsCategories={setProductsCategories}
                    categories={categories}
                    setCategories={setCategories}
                    index={index}
                    fiscalGroups={fiscalGroups}
                  />
                </div>
              </div>
            </CategoryHeader>

            <div style={{ padding: 20 }}>
              <RegisterButton
                text="Adicionar complemento"
                title="Cria complementos dentro desta categoria"
                onClick={() => {
                  createComplements(category);
                }}
              />

              {!category.is_multistore_child && (
                <ButtonAssociate
                  title="Associar complementos já existentes"
                  onClick={() => {
                    AssociateComplements(category);
                  }}
                >
                  Associar complementos já existentes
                </ButtonAssociate>
              )}
            </div>
            <Collapse isOpen={!collapse.includes(category.id)}>
              <Complements
                category={category}
                count={count}
                setCount={setCount}
                getCategories={getCategories}
                menewProducts={menewProducts}
                allCategories={categories}
                setProductsCategories={setProductsCategories}
                productsCategories={productsCategories}
                setCategories={setCategories}
                fiscalGroups={fiscalGroups}
              />
            </Collapse>
          </Container>
        ))
      ) : (
        <p style={{ padding: 20 }}>Carregando Complementos</p>
      )}

      <div>
        {' '}
        <SetSliceSizeButton type="button" onClick={handleSetSliceSize}>
          <FaAngleDown
            style={{
              transition: 'all 0.2s',
              transform: !angleChanged ? 'rotate(-90deg)' : 'rotate(0)',
            }}
          />{' '}
          Ver mais categorias
        </SetSliceSizeButton>
      </div>
    </>
  );
}

export default ComplementCategories;
