import styled from 'styled-components';

export const Print = styled.button`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-self: center;
  float: right;
  position: relative;
  display: block;
  right: 0;
  margin-right: 10px;
  border: none;
  background: #f0b402;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
    border-radius: 50%;
  }
`;

export const DisplayNoneImprimir = styled.button`
  display: none;
`;

export const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 150px;
  align-self: stretch;
  align-items: ${({ onEnd }) => (onEnd ? 'flex-end' : 'flex-start')};

  ${(props) => props.grow && 'flex-grow: 1;'}
`;

export const CardColumnItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
