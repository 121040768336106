import React, { useRef } from 'react';

import ReactToPrint from 'react-to-print';
import { AiOutlinePrinter } from 'react-icons/ai';
import PrintReportProducts from '~/components/Prints/PrintReportProducts';

import { Print, DisplayImprimir, PrintDiv } from './styles';

export default function PrintReportButton({
  categories,
  complements,
  products,
  inicialFormat,
  finalFormat,
  total_restaurant_amount,
  total_restaurant_price,
  payments,
  totalBox,
  buyers,
  restaurantName,
  categoriesCanceled,
  total_restaurant_canceled_service_price,
  total_restaurant_canceled_amount,
  has_service_tax,
  service_tax,
  total_restaurant_price_without_service,
  type,
}) {
  const componentRef = useRef(null);
  return (
    <>
      <PrintDiv>
        <ReactToPrint
          trigger={() => (
            <Print>
              <AiOutlinePrinter color="white" size={20} />
            </Print>
          )}
          content={() => componentRef.current}
        />
      </PrintDiv>
      <DisplayImprimir>
        <PrintReportProducts
          categories={categories}
          products={products}
          total_restaurant_amount={total_restaurant_amount}
          total_restaurant_price={total_restaurant_price}
          payments={payments}
          totalBox={totalBox}
          buyers={buyers}
          inicialFormat={inicialFormat}
          finalFormat={finalFormat}
          ref={componentRef}
          restaurantName={restaurantName}
          categoriesCanceled={categoriesCanceled}
          total_restaurant_canceled_service_price={
            total_restaurant_canceled_service_price
          }
          total_restaurant_canceled_amount={total_restaurant_canceled_amount}
          has_service_tax={has_service_tax}
          service_tax={service_tax}
          total_restaurant_price_without_service={
            total_restaurant_price_without_service
          }
          complements={complements}
          type={type}
        />
      </DisplayImprimir>
    </>
  );
}
