import React from 'react';
import { Spinner } from 'reactstrap';
import { Content, ItemsCount, LoadingDiv } from '../styles';
import { Header, Bloco } from './styles';
import OrderAndamentoDelivery from '../../Items/OrderAndamentoDelivery';

export default function CardOrdersSchedulingAndamento({
  baskets,
  handleItemFinished,
  handleCancelItem,
  loading,
  handleItemReady,
  handleUpdate,
  countOrders,
}) {
  return (
    <Bloco>
      <Header>
        EM ANDAMENTO
        <ItemsCount style={{ color: '#ffa50b' }}>{countOrders}</ItemsCount>
      </Header>
      <Content>
        {loading ? (
          <LoadingDiv>
            <Spinner color="warning" />
          </LoadingDiv>
        ) : (
          baskets
            ?.filter((order) => order?.scheduled_to)
            .map((item) => (
              <OrderAndamentoDelivery
                key={item.basket.id}
                handleCancelItem={handleCancelItem}
                handleItemFinished={handleItemFinished}
                item={item}
                handleItemReady={handleItemReady}
                handleUpdate={handleUpdate}
              />
            ))
        )}
      </Content>
    </Bloco>
  );
}