import React from 'react';
import { Category, CategoryImg, CategoryTitle, Container } from './styles';
import { useMenu } from '~/context/OrderingSystem/Menu';
import imgPlaceholder from '~/assets/img/ImgPlaceholder.png';

export const Categories = ({ setCategoryShowing }) => {
  const { menu } = useMenu();

  return (
    <Container>
      {menu
        .filter((category) => category.available)
        .map((category) => {
          const imageToUse = category.image?.url || category.products.find(
            (product) => !!product.image?.url
          )?.image.url;

          return (
            <Category
              key={category.id}
              onClick={() => setCategoryShowing(category)}
            >
              <CategoryImg src={imageToUse ? imageToUse : imgPlaceholder} />
              <CategoryTitle>{category.name}</CategoryTitle>
            </Category>
          );
        })}
    </Container>
  );
};
