import NumberFormat from 'react-number-format';
import styled from 'styled-components';

export const StyledNumberFormat = styled(NumberFormat)`
  padding: 10px;
  height: 40px;
  max-width: calc(33% - 5px);
  border-radius: 5px;
  border: 1px solid #3bd2c1;
  text-align: center;

  color: #666;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;

  &:focus {
    outline: none;
    border: 2px solid #3bd2c1;
  }
`;
