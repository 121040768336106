import React, { useEffect, useState } from 'react';
import { NewHeader } from '~/components/NewHeader';
import {
  BackLink,
  BalconyCard,
  MenuArea,
  ModalCashier,
  ModalErrorNF,
  PageContainer,
  PaymentArea,
} from './styles';
import { Categories } from '~/components/BalconyPageComponents/Categories';
import { BalconyProducts } from '~/components/BalconyPageComponents/BalconyProducts';
import { BalconyPaymentArea } from '~/components/BalconyPageComponents/BalconyPaymentArea';
import { useMenu } from '~/context/OrderingSystem/Menu';

import { FaCashRegister, FaSearch, FaTimes } from 'react-icons/fa';
import { Hyperlink, IconInput, Button } from 'ui-kit-takeat';
import { IoIosArrowBack } from 'react-icons/io';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { SelectComplements } from '~/components/SelectComplements';
import { useCart } from '~/context/OrderingSystem/Cart';
import { ModalConfirmation } from '~/components/ModalConfirmation';
import OpeningBox from '../Financial/CashManagementPage/OpeningBox';

export const BalconyPage = () => {
  const { menu } = useMenu();
  const allProducts = menu.flatMap((category) => category.products);
  const [categoryShowing, setCategoryShowing] = useState(
    menu.filter((category) => category.available)[0]
  );
  const [searchProduct, setSearchProduct] = useState('');
  const [isSelectingComplements, setIsSelectingComplements] = useState(false);
  const [productSelecting, setProductSelecting] = useState(null);
  const [modalErrorNF, setModalErrorNF] = useState(false);
  const [responseNfce, setResponseNfce] = useState(null);
  const [cashierOpen, setCashierOpen] = useState(false);

  const toggleComplementArea = (product) => {
    setProductSelecting(product);
    setIsSelectingComplements(true);
  };

  useEffect(() => {
    if (searchProduct.length >= 3) {
      const filteredProducts = allProducts.filter((product) =>
        product.name.toLowerCase().includes(searchProduct.toLowerCase())
      );

      const searchResult = {
        name: 'Buscando por: ' + searchProduct,
        products: filteredProducts,
      };

      setCategoryShowing(searchResult);
    } else {
      setCategoryShowing(menu[0]);
    }
  }, [searchProduct]);

  useEffect(() => {
    setCategoryShowing(menu.filter((category) => category.available)[0]);
  }, [menu]);

  useEffect(() => {
    closeCart();
  }, []);

  const { closeCart, cart } = useCart();
  const history = useHistory();

  useEffect(() => {
    if (cart.length > 0) {
      setPreventExit(true);
    } else {
      setPreventExit(false);
    }
  }, [cart]);

  const [preventExit, setPreventExit] = useState(false);
  const [modalAdvice, setModalAdvice] = useState(false);
  const [locationPath, setLocationPath] = useState('');

  function toggleModalAdvice() {
    setModalAdvice(!modalAdvice);
    setPreventExit(true);
  }

  useEffect(() => {
    const unblock = history.block((location) => {
      if (preventExit) {
        setModalAdvice(true);
        setPreventExit(false);
        setLocationPath(location.pathname);
        return false;
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [history, preventExit]);

  function confirmCloseCart() {
    toggleModalAdvice();
    closeCart();
    history.push(locationPath);
  }

  return (
    <>
      <ModalConfirmation
        isOpen={modalAdvice}
        toggle={toggleModalAdvice}
        confirm={confirmCloseCart}
        confirmText={'Sim, sair e excluir o carrinho'}
        cancel={toggleModalAdvice}
        cancelText={'Irei fazer o pedido'}
        title={
          'Você possui itens no carrinho. Tem certeza que deseja sair sem fazer o pedido?'
        }
      />
      <NewHeader />
      <PageContainer>
        <BalconyCard>
          {isSelectingComplements ? (
            <SelectComplements
              product={productSelecting}
              setIsSelectingComplements={setIsSelectingComplements}
            />
          ) : (
            <>
              <MenuArea>
                <div
                  style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
                >
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>
                      <BackLink to="/operation">
                        <IoIosArrowBack />
                        <span>Voltar</span>
                      </BackLink>
                      <h3>Categorias</h3>
                    </div>
                    <IconInput
                      color="#cdcdcd"
                      icon={<FaSearch color="#cdcdcd" />}
                      deleteIcon={<FaTimes color="#cdcdcd" />}
                      isClearable
                      deleteButton={() => setSearchProduct('')}
                      containerStyles={{
                        width: 300,
                        height: 30,
                        padding: '0px 10px',
                      }}
                      placeholder="Buscar"
                      value={searchProduct}
                      onChange={(e) => setSearchProduct(e.target.value)}
                    />
                    <Button
                      title="Ver Caixa"
                      icon={<FaCashRegister />}
                      buttonColor="#2EC9B7"
                      style={{ height: 30, padding: '0px 10px' }}
                      onClick={() => setCashierOpen(true)}
                    />
                  </div>
                  <Categories setCategoryShowing={setCategoryShowing} />
                </div>
                <div>
                  <h3>{categoryShowing?.name}</h3>
                  <BalconyProducts
                    products={categoryShowing?.products}
                    toggleComplementArea={toggleComplementArea}
                  />
                </div>
              </MenuArea>

              <PaymentArea>
                <BalconyPaymentArea
                  setResponseNfce={setResponseNfce}
                  setModalErrorNF={setModalErrorNF}
                />
              </PaymentArea>
            </>
          )}
        </BalconyCard>

        <ModalCashier isOpen={cashierOpen} toggle={() => setCashierOpen(false)}>
          <h2>Caixa</h2>
          <OpeningBox />
        </ModalCashier>

        <ModalErrorNF
          isOpen={modalErrorNF}
          toggle={() => setModalErrorNF((state) => !state)}
        >
          <h2>
            Ocorreu um erro ao tentar emitir a Nota Fiscal. Corrija-o e tente
            novamente!
          </h2>
          <p>Erro(s):</p>
          {responseNfce?.status_sefaz === '100' ? (
            <p style={{ color: 'green' }}>
              Mensagem SEFAZ: {responseNfce?.mensagem_sefaz}
            </p>
          ) : (
            <div style={{ color: 'red' }}>
              <p style={{ color: 'red' }}>
                Mensagem SEFAZ:{' '}
                {responseNfce?.response
                  ? responseNfce?.response.mensagem
                  : responseNfce?.mensagem_sefaz}
              </p>
              {responseNfce?.response &&
                responseNfce?.response.codigo === 'erro_validacao_schema' &&
                responseNfce?.response.erros &&
                responseNfce?.response.erros.map((e) => <p> - {e.mensagem}</p>)}
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Hyperlink
              fontSize={16}
              textDecoration="underline"
              href="/operation/bills?isCompleted=true"
              onClick={() => setModalErrorNF(false)}
            >
              Ver comandas finalizadas
            </Hyperlink>
            <Button
              title="Ok"
              buttonColor="#2ec9b7"
              onClick={() => setModalErrorNF(false)}
            />
          </div>
        </ModalErrorNF>
      </PageContainer>
    </>
  );
};
