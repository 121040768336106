import { Form } from '@unform/web';
import React, { useState, useRef, useCallback } from 'react';
import * as Yup from 'yup';
import { MdDateRange } from 'react-icons/md';
import { toast } from 'react-hot-toast';
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import moment from 'moment';
import CheckboxInput from '~/components/Form/Checkbox';
import Input from '~/components/Form/Input';
import api from '~/services/api';
import getValidationErrors from '~/services/getValidationErrors';
import {
  ButtonAdd,
  ButtonsArea,
  DataPickerArea,
  DatePickerStyled,
  InnerArea,
  NumberFormatStyled,
  PageTitle,
} from '../styles';
import Select from '~/components/Form/SelectInput';
import SaveButton from '~/components/Buttons/SaveButton';

import DefaulButton from '~/components/Buttons/DefaultButton';

import formatdate from '../../../../services/formatDateYYYY';
import PersonalButton from '~/components/Buttons/PersonalButton';
import { FiX } from 'react-icons/fi';
import { FaPlus } from 'react-icons/fa';
import ReactInputMask from 'react-input-mask';

// import { Container } from './styles';

function EditEarning({
  transaction,
  getTransactions,
  toggleModalEditTransaction,
  banks,
  getBanks,
  categories,
  freqOptions,
  suppliers,
  paymentMethods,
  setIsPaid,
}) {
  const [paidCheck, setPaidCheck] = useState(transaction.paid);

  const [dueDate, setDuedate] = useState();
  const [competenceDate, setCompetenceDate] = useState();
  const [paidAtDate, setpaidAtDate] = useState();
  const [valueState, setValueState] = useState(transaction.value);
  const [isModalErrorOpened, setIsModalErrorOpened] = useState(false);
  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
  const transactionFormRef = useRef(null);

  function handleSetPaidCheck() {
    setPaidCheck(!paidCheck);

    setpaidAtDate(new Date());
  }

  const toggleModalError = useCallback(() => {
    setIsModalErrorOpened(!isModalErrorOpened);
  }, [isModalErrorOpened]);

  const banksFiltered = banks.filter((item) =>
    item.value === transaction?.bank_account?.id
      ? transaction?.bank_account?.id
      : ''
  );

  const selectBank =
    banksFiltered.length > 0
      ? {
          value: banksFiltered[0].value,
          label: banksFiltered[0].label,
        }
      : {
          value: null,
          label: 'Selecione a conta',
        };

  const initialData = {
    description: transaction.description,
    information: transaction.information,

    competence_date: transaction.competence_date,
    paid: transaction.paid,
    paid_at: transaction.paid_At,

    bank_account_id: selectBank,
  };

  async function handleSubmit(data) {
    try {
      if (!valueState) {
        toggleModalError();
      } else {
        const readyValue = valueState.floatValue
          ? (valueState.floatValue / 100).toFixed(2)
          : valueState;

        if (transactionFormRef?.current) {
          transactionFormRef.current.setErrors({});
        }

        await api.put(`/restaurants/cash-flows/${transaction.id}`, {
          value: readyValue,
          description: data.description,
          due_date: dueDate,
          competence_date: competenceDate,
          paid: data.paid,
          paid_at: paidAtDate,
          bank_account_id: data.bank_account_id || null,
        });

        toast.success('Movimentação editada com sucesso!');
        setIsPaid(data.paid);
        getTransactions();
        toggleModalEditTransaction();
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (transactionFormRef?.current) {
          transactionFormRef.current.setErrors(errors);
        }
      } else {
        toast.error(`Erro ao cadastrar Movimentação!`);
        console.log(error);
      }
    }
  }

  function convertData(data) {
    const dataFormt = moment(data, 'DD/MM/YYYY');

    return dataFormt.format('DD-MM-YYYY');
  }

  function currencyFormatter(value) {
    if (!Number(value)) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  }

  function handleValueChange(e) {
    setValueState(e);
  }

  const dueDateText = transaction.due_date
    ? formatdate(transaction.due_date)
    : '__/__/____';
  const paidText = transaction.paid_at
    ? formatdate(transaction.paid_at)
    : '__/__/____';
  const competenceText = transaction.competence_date
    ? formatdate(transaction.competence_date)
    : '__/__/____';

  //create banks
  const [isModalCreateBankOpen, setIsModalCreateBankOpen] = useState(false);
  const formBankRef = useRef(null);

  const toggleModalCreateBank = useCallback(() => {
    setIsModalCreateBankOpen(!isModalCreateBankOpen);
  }, [isModalCreateBankOpen]);

  const handleSubmitBank = useCallback(
    async (data) => {
      try {
        if (formBankRef?.current) {
          formBankRef.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/restaurants/cash-flows/bank-accounts', {
          name: data.name,
        });

        toast.success('Conta cadastrada com sucesso!');

        getBanks();
        toggleModalCreateBank();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formBankRef?.current) {
            formBankRef.current.setErrors(errors);
          }
        } else {
          // console.tron.log(error);
          toast.error(`Erro ao cadastrar conta!`);
          console.log(error);
        }
      }
    },
    [getBanks, toggleModalCreateBank]
  );

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: 20,
          paddingBottom: 0,
        }}
      >
        <PageTitle>Editar Lançamento</PageTitle>
        <FiX
          onClick={toggleModalEditTransaction}
          color="#ff2c3a"
          size={21}
          cursor="pointer"
        />
      </div>

      <Modal isOpen={isModalCreateBankOpen} toggle={toggleModalCreateBank}>
        <ModalHeader> Criar Conta</ModalHeader>
        <Form onSubmit={handleSubmitBank} ref={formBankRef}>
          <ModalBody>
            <Input label="Nome do banco" name="name" />
          </ModalBody>
          <ModalFooter>
            <SaveButton type="submit" message="Salvar" />
          </ModalFooter>
        </Form>
      </Modal>
      <InnerArea>
        <Form
          onSubmit={handleSubmit}
          ref={transactionFormRef}
          initialData={initialData}
        >
          <ModalBody>
            <Row>
              <Col>
                <DataPickerArea>
                  <span>Valor (R$)</span>
                  <NumberFormatStyled
                    prefix="R$"
                    format={currencyFormatter}
                    defaultValue={valueState}
                    onValueChange={(e) => {
                      handleValueChange(e);
                    }}
                  />
                </DataPickerArea>
              </Col>
              <Col>
                <DataPickerArea>
                  <span>
                    Vencimento <MdDateRange />
                  </span>

                  <DatePickerStyled
                    selected={dueDate}
                    onChange={(date) => setDuedate(date)}
                    locale="pt"
                    dateFormat="dd/MM/yyyy"
                    placeholderText={dueDateText}
                    customInput={
                      <ReactInputMask mask={'99/99/9999'} maskChar={null} />
                    }
                  />
                </DataPickerArea>
              </Col>
              <Col>
                <DataPickerArea>
                  <>
                    <span>
                      Competência <MdDateRange />
                    </span>

                    <DatePickerStyled
                      selected={competenceDate}
                      onChange={(date) => setCompetenceDate(date)}
                      locale="pt"
                      dateFormat="dd/MM/yyyy"
                      placeholderText={competenceText}
                      customInput={
                        <ReactInputMask mask={'99/99/9999'} maskChar={null} />
                      }
                    />
                  </>
                </DataPickerArea>
              </Col>
            </Row>

            <Row>
              <Col>
                <Row>
                  <Col md="10" style={{ marginTop: 10 }}>
                    <Select
                      label="Conta"
                      name="bank_account_id"
                      placeholder="Selecione a conta"
                      options={banks}
                    />
                  </Col>
                  <Col md="2">
                    <ButtonAdd
                      type="button"
                      onClick={toggleModalCreateBank}
                      title="Cadastrar Conta Bancária"
                    >
                      <FaPlus />
                    </ButtonAdd>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Input label="Descrição" name="description" />
              </Col>
            </Row>

            <CheckboxInput
              name="paid"
              label="Pago"
              onChange={handleSetPaidCheck}
            />

            <Modal isOpen={isModalErrorOpened} toggle={toggleModalError}>
              <ModalBody>
                Os campos Valor e Vencimento são obrigatórios
              </ModalBody>
              <ModalFooter>
                <DefaulButton
                  onClick={toggleModalError}
                  message="Ok, entendi"
                />
              </ModalFooter>
            </Modal>

            {paidCheck && (
              <Row>
                <Col lg="3">
                  <DataPickerArea>
                    <>
                      <span>
                        Pago em <MdDateRange />
                      </span>

                      <DatePickerStyled
                        selected={paidAtDate}
                        onChange={(date) => setpaidAtDate(date)}
                        locale="pt"
                        dateFormat="dd/MM/yyyy"
                        placeholderText={paidText}
                        customInput={
                          <ReactInputMask mask={'99/99/9999'} maskChar={null} />
                        }
                      />
                    </>
                  </DataPickerArea>
                </Col>
              </Row>
            )}

            {/* <CheckboxInput
            name="repit"
            label="Repetir"
            onChange={handleSetRepitCheck}
          />

          {repitCheck && (
            <Row>
              <Col>
                <Input label="Qtd repetições" name="freq_value" type="number" />
              </Col>
              <Col>
                <Select
                  label="Frequência"
                  name="freq_type"
                  options={freqOptions}
                />
              </Col>
            </Row>
          )} */}
          </ModalBody>
          <ButtonsArea>
            <PersonalButton
              type="submit"
              message="Salvar"
              title="Salvar"
              color="#2EC9B7"
            />
          </ButtonsArea>
        </Form>
      </InnerArea>
    </>
  );
}

export default EditEarning;
