import React, { useState } from 'react';

import {LabelResearch,SatisfactionOption} from './styles'
import { Button, Container } from './styles';
import api from '../../services/api';

export const Research = ({ research, notif }) => {
  const [rated, setRated] = useState(notif.rated);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const sendResearch = async (value) => {


    await api.post('/restaurants/research-answer', {
      research_id: research.id,
      value,
      notification_id: notif.id,
    });
    await delay(300);
    setRated(true);
    notif.rated = true;
  };

  return rated ? (
    <>
      <p style={{ marginTop: 5 }}>Obrigado por avaliar!</p>
    </>
  ) : (
    <>
      <p style={{ marginTop: 5,marginBottom:16 }}>{research.question}</p>
      <div style={{backgroundColor:"#F3F3F3",width:'100%',borderRadius:8}}>
      <LabelResearch >
      <span >Muito esforço</span>
      <span >Pouco esforço</span>
      </LabelResearch>

      <Container >

        <Button
          onClick={() => sendResearch(1)}
          onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.2)'
          ,e.currentTarget.style.backgroundColor = 'white')}
          onMouseDown={(e) => (e.currentTarget.style.backgroundColor = '#FF2C3A')}
          onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)',
          e.currentTarget.style.backgroundColor = 'transparent'
        )}
        >
          <SatisfactionOption onMouseDown={(e) => (e.currentTarget.style.color = 'white')}
           onMouseEnter={(e) => (e.currentTarget.style.color = '#666666')}
           onMouseLeave={(e) => (e.currentTarget.style.color = '#666666')}
          >1</SatisfactionOption>

        </Button>
        <Button
          onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.2)'
          ,e.currentTarget.style.backgroundColor = 'white',
          e.currentTarget.style.color = '#666666')}
          onMouseDown={(e) => (e.currentTarget.style.backgroundColor = '#FF2C3A')}
          onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)',
          e.currentTarget.style.backgroundColor = 'transparent'
        )}
          onClick={() => sendResearch(2)}
        >
          <SatisfactionOption onMouseDown={(e) => (e.currentTarget.style.color = 'white')}
          onMouseEnter={(e) => (e.currentTarget.style.color = '#666666')}
          onMouseLeave={(e) => (e.currentTarget.style.color = '#666666')}
          >2</SatisfactionOption>
        </Button>


        <Button
          onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.2)'
          ,e.currentTarget.style.backgroundColor = 'white',
          e.currentTarget.style.color = '#666666')}
          onMouseDown={(e) => (e.currentTarget.style.backgroundColor = '#FFA814')}
          onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)',
          e.currentTarget.style.backgroundColor = 'transparent'
        )}
          onClick={() => sendResearch(3)}
        >
          <SatisfactionOption onMouseDown={(e) => (e.currentTarget.style.color = 'white')}
          onMouseEnter={(e) => (e.currentTarget.style.color = '#666666')}
          onMouseLeave={(e) => (e.currentTarget.style.color = '#666666')}
          >3</SatisfactionOption>
        </Button>


        <Button
          
          onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.2)'
          ,e.currentTarget.style.backgroundColor = 'white',
          e.currentTarget.style.color = '#666666')}
          onMouseDown={(e) => (e.currentTarget.style.backgroundColor = '#2EC9B7')}
          onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)',
          e.currentTarget.style.backgroundColor = 'transparent'
        )}
          onClick={() => sendResearch(4)}
        >
          <SatisfactionOption onMouseDown={(e) => (e.currentTarget.style.color = 'white')}
          onMouseEnter={(e) => (e.currentTarget.style.color = '#666666')}
          onMouseLeave={(e) => (e.currentTarget.style.color = '#666666')}
          >4</SatisfactionOption>
        </Button>


        <Button
          onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.2)'
          ,e.currentTarget.style.backgroundColor = 'white',
          e.currentTarget.style.color = '#666666')}
          onMouseDown={(e) => (e.currentTarget.style.backgroundColor = '#2EC9B7')}
          onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)',
          e.currentTarget.style.backgroundColor = 'transparent'
        )}
          onClick={() => sendResearch(5)}
        >
          <SatisfactionOption onMouseDown={(e) => (e.currentTarget.style.color = 'white')}
          onMouseEnter={(e) => (e.currentTarget.style.color = '#666666')}
          onMouseLeave={(e) => (e.currentTarget.style.color = '#666666')}
          >5</SatisfactionOption>
        </Button>


      </Container>
    </div>
    </>
  );
};
