import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  z-index: 400000;
  left: 50px;
  top: 190px;
  display: ${(props) => (props.opened ? 'block' : 'none')};
  background: '#fff';
  /* display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 400px; */

  svg {
    position: absolute;
    right: 12px;
    top: 80px;
  }
`;
