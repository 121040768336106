import React from 'react';
import {
  Item,
  PriceItem,
  ItemArea,
  OrderTime,
  ItemInfo,
} from './styles';

import formatTime from '~/services/formatTime';

export default function EyeOrderBillCashback({ rescue }) {
  return (
    <Item key={rescue.id} style={{ width: '100%', color: '#018D66' }}>
      <ItemArea>
        <ItemInfo>
          <strong>Resgate Cashback</strong>
          <OrderTime>{formatTime(rescue?.createdAt)}</OrderTime>
        </ItemInfo>
        <PriceItem>- R${rescue.cashback} </PriceItem>
      </ItemArea>
    </Item>
  );
}
