import styled from 'styled-components';

import { Form } from '@unform/web';

export const Wrapper = styled.div`
  margin: 10px;
`;

export const Header = styled.div`
  margin: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled.h4`
  margin-bottom: 10px;
  font-weight: bold;
  color: #3d3d3d !important;
`;

export const Container = styled.div``;

export const StyledForm = styled(Form)``;
