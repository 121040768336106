import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ItemContainer = styled(Link)`
  ${({ color }) =>
    color ? `background-color: ${color};` : `background-color: #a82743;`}
  position: relative;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  cursor: pointer;

  ${({ disabled }) => disabled && `pointer-events: none;`}
`;

export const IconContainer = styled.div`
  width: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${({ color }) =>
    color ? `background-color: ${color};` : `background-color: #a82743;`}
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #fff;
  gap: 3px;
  color: ${({ selected, color }) => (selected ? `${color}` : '#fff')};
  border: ${({ selected, color }) =>
    selected ? `1px solid ${color}` : 'none'};
  border-radius: 8px;

  ${({ selected }) =>
    selected &&
    `background-color: #f5f5f5; border-radius: 8px 0 0 8px; border-right: none; clip-path: inset(-10px 0 -10px -10px);`}

  &:hover {
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
    z-index: 10;
  }

  div {
    height: 12px;
    span {
      font-size: 8px;
      font-family: Poppins;
      text-decoration: none;
      display: inline-block;
      color: inherit;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export const TextContainer = styled.span`
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  transform-origin: left;
  transform: scaleX(0);

  color: #666;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ModalContainer = styled.div`
  position: absolute;
  z-index: 999;
  top: 0;
  left: 50px;
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #fff;
  transition: transform 0.15s linear;
  transform: scaleX(${({ open }) => (open ? 1 : 0)});
  transform-origin: left;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.11);
  cursor: default;
`;

export const ModalHeader = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid;
  ${({ color }) => color && `border-color: ${color};`}
  background-color: #ffffff;

  color: #666;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

// height: 160px;
export const SubitemsDiv = styled.div`
  min-width: 210px;
  max-width: 840px;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

// width: 210px;
export const Subitem = styled(Link)`
  position: relative;
  display: flex;
  height: ${(props) => (props.isSubSub ? '40px' : '40px')};
  min-height: ${(props) => (props.isSubSub ? '40px' : '40px')};
  padding: 0px 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  cursor: pointer;
  text-decoration: none;
  text-align: left;
  ${({ disabled }) =>
    disabled &&
    `pointer-events: none;
  cursor: default;`}

  color: #666;
  font-family: Poppins;
  font-size: ${(props) => (props.isSubSub ? '12px' : '14px')};
  font-style: normal;
  font-weight: 500;
  line-height: 14px;

  svg {
    z-index: 1;
    width: ${(props) => (props.isSubSub ? '5px' : '7px')};
    height: ${(props) => (props.isSubSub ? '5px' : '7px')};
    color: ${(props) =>
      props.isSubSub ? '#d9d9d9' : props.color || '#a82743'};
    ${({ isSubSub }) => isSubSub && 'margin-left: 1px;'}
  }

  &:hover {
    background-color: #f5f5f5;
    color: #666;
  }

  &:active,
  &:focus,
  &:visited {
    color: #666;
  }
`;

export const Subsubitem = styled.div`
  display: flex;
  height: 20px;
  padding: 0px 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  color: #666;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  svg {
    width: 4px;
    height: 4px;
    color: #d9d9d9;
  }

  &:hover {
    background: #ffecec;
  }
`;

export const ThreadLine = styled.div`
  height: ${({ isFirst }) => (isFirst ? '20px' : '40px')};
  width: 1px;
  background-color: #d9d9d9;
  position: absolute;
  top: ${({ isFirst }) => (isFirst ? '0px' : '-20px')};
  left: 23px;
`;

export const SecondLine = styled.div`
  height: 20px;
  width: 1px;
  background-color: #d9d9d9;
  position: absolute;
  top: 20px;
  left: 23px;
`;

export const RestaurantImage = styled.img`
  width: 82px;
  height: 82px;
  border-radius: 5px;
`;

export const SearchInput = styled.div`
  border-radius: 5px;
  width: 190px;
  border: 1px solid #cdcdcd;
  background-color: #fff;
  padding-left: 20px;
  display: flex;
  align-items: center;
  color: #6c6c6c;
  font-family: Poppins;
  font-size: 14px;
  line-height: normal;
  input {
    width: 100%;
    flex: 1;
    border: none;
    color: #6c6c6c;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;

    ::placeholder {
      color: #CDCDCD;
    }

    &:focus-visible {
      outline: none;
    }
  }
`;

export const SearchLink = styled(Link)`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-decoration: underline;
  gap: 10px;
  margin-left: 10px;

  &:hover {
    color: #666;
  }
`;