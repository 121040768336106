import React, { useContext, createContext, useState, useEffect } from 'react';
import { startingPrice } from '~/utils/functions';
// import { startingPrice } from '~/utils/functions';

const PreCartContext = createContext();

function PreCartProvider({ children }) {
  const [preCart, setPreCart] = useState({});
  const [categoriesRequired, setCategoriesRequired] = useState([]);

  const changeProductPreCart = (field, value) => {
    setPreCart((state) => {
      return {
        ...state,
        [field]: value,
      };
    });
  };

  const incrementOnPreCart = (category, complement) => {
    const { id, name, additional, minimum, more_expensive_only, use_average } =
      category;

    const catExists = preCart.complementsCategory?.find(
      (complementCategory) => {
        return complementCategory.id === category.id;
      }
    );

    if (!catExists) {
      setPreCart((state) => {
        return {
          ...state,
          complementsCategory: [
            ...state.complementsCategory,
            {
              id,
              name,
              additional,
              minimum,
              more_expensive_only,
              use_average,
              complements: [
                {
                  amount: 1,
                  current_cmv: complement.current_cmv,
                  id: complement.id,
                  name: complement.name,
                  price: complement.price,
                  delivery_price: complement.delivery_price,
                  is_cmv_manual: complement.is_cmv_manual,
                },
              ],
            },
          ],
        };
      });
    } else {
      const complementExists = catExists.complements.find(
        (complementCategory) => {
          return complementCategory.id === complement.id;
        }
      );

      if (!complementExists) {
        setPreCart((state) => {
          return {
            ...state,
            complementsCategory: state.complementsCategory.map(
              (complementCategory) => {
                if (complementCategory.id === category.id) {
                  return {
                    ...complementCategory,
                    complements: [
                      ...complementCategory.complements,
                      {
                        amount: 1,
                        current_cmv: complement.current_cmv,
                        id: complement.id,
                        name: complement.name,
                        price: complement.price,
                        delivery_price: complement.delivery_price,
                        is_cmv_manual: complement.is_cmv_manual,
                      },
                    ],
                  };
                }

                return complementCategory;
              }
            ),
          };
        });
      } else {
        setPreCart((state) => {
          return {
            ...state,
            complementsCategory: state.complementsCategory.map(
              (complementCategory) => {
                if (complementCategory.id === category.id) {
                  return {
                    ...complementCategory,
                    complements: complementCategory.complements.map(
                      (complementInside) => {
                        if (complement.id === complementInside.id) {
                          return {
                            ...complementInside,
                            amount: complementInside.amount + 1,
                          };
                        }

                        return complementInside;
                      }
                    ),
                  };
                }

                return complementCategory;
              }
            ),
          };
        });
      }
    }
  };

  const decrementOnPreCart = (category, complement) => {
    const catExists = preCart.complementsCategory?.find(
      (complementCategory) => {
        return complementCategory.id === category.id;
      }
    );

    if (!catExists) {
      return;
    } else {
      const complementExists = catExists.complements.find(
        (complementCategory) => {
          return complementCategory.id === complement.id;
        }
      );

      if (!complementExists) {
        return;
      } else {
        if (complementExists.amount === 1) {
          setPreCart((state) => {
            return {
              ...state,
              complementsCategory: state.complementsCategory
                .map((complementCategory) => {
                  if (complementCategory.id === category.id) {
                    return {
                      ...complementCategory,
                      complements: complementCategory.complements.filter(
                        (complementInside) => {
                          return complementInside.id !== complement.id;
                        }
                      ),
                    };
                  }

                  return complementCategory;
                })
                .filter(
                  (complementCategory) =>
                    complementCategory.complements.length > 0
                ),
            };
          });
        } else {
          setPreCart((state) => {
            return {
              ...state,
              complementsCategory: state.complementsCategory.map(
                (complementCategory) => {
                  if (complementCategory.id === category.id) {
                    return {
                      ...complementCategory,
                      complements: complementCategory.complements.map(
                        (complementInside) => {
                          if (complement.id === complementInside.id) {
                            return {
                              ...complementInside,
                              amount: complementInside.amount - 1,
                            };
                          }

                          return complementInside;
                        }
                      ),
                    };
                  }

                  return complementCategory;
                }
              ),
            };
          });
        }
      }
    }
  };

  const changeComplementPreCart = (category, complement, value) => {
    const { id, name, additional, minimum, more_expensive_only, use_average } =
      category;

    const catExists = preCart.complementsCategory?.find(
      (complementCategory) => {
        return complementCategory.id === category.id;
      }
    );

    if (!catExists) {
      if (value <= 0) return;
      setPreCart((state) => {
        return {
          ...state,
          complementsCategory: [
            ...state.complementsCategory,
            {
              id,
              name,
              additional,
              minimum,
              more_expensive_only,
              use_average,
              complements: [
                {
                  amount: value,
                  current_cmv: complement.current_cmv,
                  id: complement.id,
                  name: complement.name,
                  price: complement.price,
                  delivery_price: complement.delivery_price,
                  is_cmv_manual: complement.is_cmv_manual,
                },
              ],
            },
          ],
        };
      });
    } else {
      const complementExists = catExists.complements.find(
        (complementCategory) => {
          return complementCategory.id === complement.id;
        }
      );

      if (!complementExists) {
        if (value <= 0) return;

        setPreCart((state) => {
          return {
            ...state,
            complementsCategory: state.complementsCategory.map(
              (complementCategory) => {
                if (complementCategory.id === category.id) {
                  return {
                    ...complementCategory,
                    complements: [
                      ...complementCategory.complements,
                      {
                        amount: value,
                        current_cmv: complement.current_cmv,
                        id: complement.id,
                        name: complement.name,
                        price: complement.price,
                        delivery_price: complement.delivery_price,
                        is_cmv_manual: complement.is_cmv_manual,
                      },
                    ],
                  };
                }

                return complementCategory;
              }
            ),
          };
        });
      } else {
        setPreCart((state) => {
          return {
            ...state,
            complementsCategory: state.complementsCategory
              .map((complementCategory) => {
                if (complementCategory.id === category.id) {
                  return {
                    ...complementCategory,
                    complements: complementCategory.complements
                      .map((complementInside) => {
                        if (complement.id === complementInside.id) {
                          return {
                            ...complementInside,
                            amount: value,
                          };
                        }

                        return complementInside;
                      })
                      .filter((complement) => complement.amount > 0),
                  };
                }

                return complementCategory;
              })
              .filter((compCategory) => compCategory.complements.length > 0),
          };
        });
      }
    }
  };

  const getComplementCategoryAmount = (category_id) => {
    const category = preCart.complementsCategory?.find(
      (category) => category.id === category_id
    );

    if (!category) return 0;

    return category.complements.reduce((acc, complement) => {
      return acc + complement.amount;
    }, 0);
  };

  const getComplementAmount = (complement_id, category_id) => {
    const complement = preCart.complementsCategory
      ?.find((category) => category.id === category_id)
      ?.complements.find((complement) => complement.id === complement_id);

    if (!complement) return 0;

    return complement.amount;
  };

  const getPreCartTotal = () => {
    const prodPrice = parseFloat(
      preCart.product?.price_promotion || preCart.product?.price
    );

    const productStartingPrice = preCart.product?.is_combo
      ? startingPrice(preCart.product.complement_categories)
      : 0;

    const amount = preCart.amount;

    const weight =
      preCart.inputProductWeight || preCart.inputProductPrice / prodPrice || 1;

    let complementMoreExpensive = 0;

    const totalComplementsPrice =
      preCart.complementsCategory
        ?.filter(
          (category) =>
            category.additional === true &&
            category.more_expensive_only === false
        )
        .map((complementCat) => {
          if (complementCat.use_average) {
            const amountAverage = complementCat.complements.reduce(
              (accum, curr) => accum + curr.amount,
              0
            );

            const totalPriceAverage =
              complementCat.complements
                .map((item) => item)
                .reduce(
                  (acum, curr) => acum + Number(curr.price) * curr.amount,
                  0
                ) / amountAverage;

            return totalPriceAverage > 0 ? Number(totalPriceAverage) : 0;
          }
          return complementCat.complements
            .map((item) => item)
            .reduce((acum, curr) => acum + Number(curr.price) * curr.amount, 0);
        })
        .reduce((acum, curr) => acum + curr, 0) + complementMoreExpensive;

    let total = prodPrice * amount * weight;

    total += (totalComplementsPrice + productStartingPrice) * amount;

    return total;
  };

  const value = {
    preCart,
    setPreCart,
    incrementOnPreCart,
    decrementOnPreCart,
    getPreCartTotal,
    changeProductPreCart,
    changeComplementPreCart,
    getComplementCategoryAmount,
    getComplementAmount,
    setCategoriesRequired,
    categoriesRequired,
  };

  return (
    <PreCartContext.Provider value={value}>{children}</PreCartContext.Provider>
  );
}

function usePreCart() {
  const context = useContext(PreCartContext);

  if (!context) {
    throw new Error('usePreCart must be used within an OrderProvider');
  }

  return context;
}

export { PreCartProvider, usePreCart };
