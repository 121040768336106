import React, { useState, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';

import { MdInsertPhoto } from 'react-icons/md';
import { Row, Col, Spinner } from 'reactstrap';
import {
  Wrapper,
  Header,
  Card,
  QrcodeContainer,
  DefaultAvatar,
  FormContainer,
  NumberFormatStyled,
} from './styles';

import history from '~/services/history';
import api from '~/services/api';

import Input from '~/components/Form/Input';
import InputMask from '~/components/Form/InputMask';
import Checkbox from '~/components/Form/Checkbox';
import BackButton from '~/components/Buttons/BackButton';
import SaveButton from '~/components/Buttons/SaveButton';
import Select from '~/components/Form/SelectInput';
import { PageTitle } from '~/components/PageTitle';

function CreateTable() {
  const [preview, setPreview] = useState('');
  const [qrcode, setQrcode] = useState('');
  const [saved, setSaved] = useState(false);
  const [multipleTables, setMultipleTables] = useState(false);
  const [startQuantity, setStartQuantity] = useState(1);
  const [endQuantity, setEndQuantity] = useState(null);
  const [loading, setLoading] = useState(false);
  const qrcodeRef = useRef();

  const [tableTypeValue, setTableTypeValue] = useState({
    label: 'Mesa',
    value: 'table',
  });

  async function handleQrcode() {
    try {
      if (
        preview.url &&
        window.confirm('Tem certeza que deseja gerar um novo QRCode?')
      ) {
        await api.delete(`restaurants/qrcode/${preview.id}`);
        if (qrcode.length > 0) {
          const schema = Yup.object().shape({
            qrcode: Yup.string()
              .min(6, 'O código precisa ter 6 dígitos')
              .max(6, 'O código precisa ter 6 dígitos')
              .matches(/^[a-z0-9]+$/i, 'Código só pode ser alfanumérico'),
          });

          await schema.validate(
            { qrcode },
            {
              abortEarly: false,
            }
          );
          const response = await api.post('restaurants/qrcode', {
            code: qrcode,
          });

          setPreview(response.data);
        } else {
          const response = await api.post('restaurants/qrcode');
          setPreview(response.data);
        }
        toast.success('QRCode criado com sucesso!');
      }
      if (!preview.url) {
        if (qrcode.length > 0) {
          const schema = Yup.object().shape({
            qrcode: Yup.string()
              .min(6, 'O código precisa ter 6 dígitos')
              .max(6, 'O código precisa ter 6 dígitos')
              .matches(/^[a-z0-9]+$/i, 'Código só pode ser alfanumérico'),
          });

          await schema.validate(
            { qrcode },
            {
              abortEarly: false,
            }
          );
          const response = await api.post('restaurants/qrcode', {
            code: qrcode,
          });

          setPreview(response.data);
        } else {
          const response = await api.post('restaurants/qrcode');

          setPreview(response.data);
        }

        toast.success('QRCode criado com sucesso!');
      }
      setSaved(false);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        // Validation failed
        toast.error(`Erro ao gerar QRCode! ${error}`);
      } else {
        toast.error(
          `Erro ao gerar novo QRCode, favor verificar seus dados e tentar novamente `
        );
        // toast.error(`Erro ao gerar novo QRCode ${error.message}`);
      }
    }
  }

  async function handleSubmit(data) {
    if (data.table_number < 1 || data.table_number === '') {
      toast.error('Número da mesa deve ser maior que 0');
      return;
    }
    if (data.is_multiple) {
      try {
        setLoading(true);
        await api.post('restaurants/tables/multiple', {
          start: data.start_multiple,
          end: data.end_multiple,
          table_type: tableTypeValue.value,
        });

        setSaved(true);
        setLoading(false);
        toast.success('Mesas criadas com sucesso!');
        history.push('/register/tables');
      } catch (err) {
        console.log('Multiple table error: ', JSON.stringify(err, null, 2));
        setLoading(false);
        toast.error(err.response.data.error);
      }
    } else if (preview) {
      try {
        let price_limit = null;

        const isLounge = data.table_type === 'lounge' ? true : false;

        if (!loungePriceLimit && isLounge) {
          toast.error('Saldo do lounge obrigatorio.');
          return false;
        }

        if (loungePriceLimit && isLounge) {
          price_limit = loungePriceLimit.floatValue / 100;
        }

        await api.post('restaurants/tables', {
          table_number: Number(data.table_number),
          table_code_id: preview.id,
          table_type: data.table_type,
          price_limit,
        });

        setSaved(true);
        toast.success('Mesa criada com sucesso!');
        history.push('/register/tables');
      } catch (error) {
        toast.error(error.response.data.error);
      }
    } else {
      toast.error('Favor criar o qrCode antes de cadastrar a mesa');
    }
  }

  useEffect(() => {
    qrcodeRef.current = preview;
    return async () => {
      if (!saved && preview.url) {
        const deletedQrcode = qrcodeRef.current;
        await api.delete(`/qrcode/${deletedQrcode.id}`);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loungePriceLimit, setLoungePriceLimit] = useState(null);

  function currencyFormatter(value) {
    if (!Number(value)) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  }

  const tableOptions = [
    { label: 'Mesa', value: 'table' },
    { label: 'Comanda', value: 'command' },
    // {label: 'Lounge', value: 'lounge'},
    { label: 'Mesa Pré-paga', value: 'prepaid' },
  ];

  return (
    <Wrapper>
      <Header>
        <PageTitle>Cadastrar Mesas</PageTitle>
        <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
      </Header>
      <Card>
        <Form onSubmit={handleSubmit}>
          <div>
            <Row>
              <Col sm="12">
                <Checkbox
                  label="Criar várias mesas"
                  name="is_multiple"
                  onChange={() => setMultipleTables(!multipleTables)}
                />
              </Col>
            </Row>
            {loading ? (
              <div style={{ margin: '0 auto', width: 'fit-content' }}>
                <Spinner />
              </div>
            ) : multipleTables ? (
              <>
                <Row>
                  <Col sm="12">
                    <Select
                      label="Tipo"
                      defaultValue={tableTypeValue}
                      options={tableOptions}
                      onChange={(e) => setTableTypeValue(e)}
                      name="table_type"
                      style={{ marginTop: 20 }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    sm="12"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <h4 style={{ margin: 0 }}>Mesas numeradas</h4>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <h4 style={{ margin: '0 5px' }}>De </h4>
                      <Input
                        style={{ width: 150, textAlign: 'center' }}
                        label={null}
                        value={startQuantity}
                        onChange={(e) => setStartQuantity(e.target.value)}
                        name="start_multiple"
                        type="number"
                        min="1"
                        placeholder="Número inicial"
                      />
                      <h4 style={{ margin: '0 5px' }}> a </h4>
                      <Input
                        style={{ width: 150, textAlign: 'center' }}
                        label={null}
                        value={endQuantity}
                        onChange={(e) => setEndQuantity(e.target.value)}
                        name="end_multiple"
                        type="number"
                        min="1"
                        placeholder="Número final"
                      />
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col sm="12">
                    <QrcodeContainer>
                      {preview.url ? (
                        <img src={preview.url} alt="QR code da mesa" />
                      ) : (
                        <DefaultAvatar>
                          <MdInsertPhoto size={45} color="#ddd" />
                        </DefaultAvatar>
                      )}
                      <h4>Código atual da mesa</h4>
                      <span>{preview.key ? preview.key : 'Nenhum código'}</span>
                      <FormContainer>
                        <InputMask
                          mask="******"
                          maskChar=""
                          maskPlaceholder="______"
                          label="Novo código da mesa"
                          name="qrcode_key"
                          type="text"
                          value={qrcode}
                          onChange={(e) => {
                            setQrcode(e.target.value);
                          }}
                          style={{ textTransform: 'lowercase' }}
                        />
                        <p>
                          OBS: São aceitos apenas letras de a-z minúsculas e
                          números.
                        </p>
                        <span>
                          Caso queira um código aleatório, apenas clique em
                          Gerar QRCode
                        </span>
                        <button type="button" onClick={handleQrcode}>
                          Gerar QRCode
                        </button>
                      </FormContainer>
                    </QrcodeContainer>
                  </Col>
                </Row>

                <Row>
                  <Col sm="4">
                    <Select
                      label="Tipo"
                      defaultValue={tableTypeValue}
                      options={tableOptions}
                      onChange={(e) => setTableTypeValue(e)}
                      name="table_type"
                      style={{ marginTop: 20 }}
                    />
                  </Col>
                  <Col sm="8">
                    <Input
                      label={`Número da ${tableTypeValue?.label || 'Mesa'}`}
                      name="table_number"
                      type="number"
                      min="1"
                      placeholder="Número positivo maior que 0"
                    />
                  </Col>
                </Row>

                {tableTypeValue?.value === 'lounge' && (
                  <Row style={{ width: '100%' }}>
                    <Col sm="4" style={{ float: 'right', right: 0 }}>
                      <span>Saldo do lounge</span>
                      <NumberFormatStyled
                        prefix="R$"
                        format={currencyFormatter}
                        name="value"
                        onValueChange={(e) => {
                          setLoungePriceLimit(e);
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
            <SaveButton type="submit">Cadastrar</SaveButton>
          </div>
        </Form>
      </Card>
    </Wrapper>
  );
}

export default CreateTable;