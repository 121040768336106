import React, { useState, useEffect } from 'react';
import { Container, OpenOrders, CloseOrders, PaymentTable } from './styles';
import EyeOrderBillOperation from '../EyeOrderBillOperation';
import api from '~/services/api';
import { useBills } from '~/context/BillsContext';
import { Button } from 'ui-kit-takeat';
import { FaTimes } from 'react-icons/fa';
import { ModalCancel } from '../ModalCancel';
import getValidationErrors from '~/services/getValidationErrors';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useAuth } from '~/context/AuthContext';
import { ModalCancelAll } from './ModalCancelAll';

const EyeOrdersBillOperation = (
  {
    inicialDate,
    finalDate,
    setSessions,
    toggleEyeOpen,
    getSessions,
    getTablesAvailable,
    session,
    sessionId,
    handleSearchInputTables,
    setAllTablesAvailable,
    setInputValue,
    setTablesFilteredSearch,
    setIsDivideBillsAreaOpen,
  },
  ref
) => {
  const { user } = useAuth();
  const { getTableBills, closedBills, pendingBills } = useBills();
  const [modalCancelAll, setModalCancelAll] = useState(false);
  const [modalConfirmCancel, setModalConfirmCancel] = useState(false);
  const [loadingConfirmCancel, setLoadingConfirmCancel] = useState(false);

  const hasKey = user ? user.has_order_cancel_password : false;
  const session_id =
    session?.table?.table_number || session?.table?.table_type === 'balcony'
      ? session?.id
      : null;

  const handleCancelAll = async (data, ref) => {
    setLoadingConfirmCancel(true);
    try {
      if (ref?.current) {
        ref.current.setErrors({});
      }

      const schema = Yup.object().shape({
        order_cancel_password: Yup.string().required('Senha obrigatória'),
        cancel_reason: Yup.string().required('Justicativa obrigatória'),
      });

      if (data) {
        await schema.validate(data, {
          abortEarly: false,
        });
      }

      await api.delete(`restaurants/session/cancel/${session_id}`, {
        data: {
          order_cancel_password: data?.order_cancel_password,
          cancel_reason: data?.cancel_reason,
        },
      });

      getSessions()
      setModalConfirmCancel(false)
      setModalCancelAll(false)
      getTablesAvailable()
      toast.success('Pedidos cancelados com sucesso');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (ref?.current) {
          ref.current.setErrors(errors);
        }
      } else if (
        err?.response?.data?.errorType === 'incorrect_order_cancel_password'
      ) {
        toast.error('Senha incorreta');
      } else {
        toast.error('Erro ao cancelar pedido');

        console.log('handleCancelAll error: ', err);
      }
    }
    setLoadingConfirmCancel(false);
  };

  useEffect(() => {
    getTableBills(session_id);
  }, [getTableBills, session_id]);

  useEffect(() => {
    if (session.has_tax) {
      getTableBills(session_id);
    }
  }, [session.has_tax]);

  let length = 0;
  pendingBills.forEach((bill) => {
    if (parseFloat(bill.total_price) > 0) {
      length++;
    }
  });

  return (
    <Container>
      <OpenOrders>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <strong>Comanda</strong>
          <Button
            disabled={pendingBills.length < 1}
            title="Cancelar tudo"
            icon={<FaTimes />}
            style={{ height: 30, padding: '0 16px' }}
            onClick={() => setModalCancelAll(true)}
          />
        </div>
        <PaymentTable borderless>
          <tbody>
            {pendingBills &&
              pendingBills.length > 0 &&
              pendingBills.map(
                (bill) =>
                  parseFloat(bill.total_price) > 0 && (
                    <EyeOrderBillOperation
                      bill={bill}
                      session={session}
                      setSessions={setSessions}
                      inicialDate={inicialDate}
                      finalDate={finalDate}
                      toggleEyeOpen={toggleEyeOpen}
                      length={length}
                      getSessions={getSessions}
                      getTablesAvailable={getTablesAvailable}
                      getTableSessions={getTableBills}
                      handleSearchInputTables={handleSearchInputTables}
                      setAllTablesAvailable={setAllTablesAvailable}
                      setInputValue={setInputValue}
                      setTablesFilteredSearch={setTablesFilteredSearch}
                      setIsDivideBillsAreaOpen={setIsDivideBillsAreaOpen}
                    />
                  )
              )}

            {pendingBills.length < 1 && <p>Mesa sem clientes no momento</p>}
          </tbody>
        </PaymentTable>
      </OpenOrders>
      <CloseOrders>
        <h6>
          <strong>Contas Individuais Fechadas</strong>
        </h6>

        <PaymentTable borderless>
          <tbody>
            {closedBills.length > 0 ? (
              closedBills.map((bill) => {
                const userName = bill.buyer
                  ? bill.buyer.phone
                  : bill.waiter.name;
                return (
                  <tr key={bill.id}>
                    <td>{userName}</td>
                    <td>R${bill.total_service_price}</td>
                  </tr>
                );
              })
            ) : (
              <p>Nenhuma conta fechada</p>
            )}
          </tbody>
        </PaymentTable>
      </CloseOrders>
      <ModalCancelAll
        bills={pendingBills}
        isOpen={modalCancelAll}
        setIsOpen={setModalCancelAll}
        onConfirm={() => {
          if (hasKey) {
            setModalConfirmCancel(true);
          } else {
            console.log('Aqui');
            handleCancelAll();
          }
        }}
        loading={loadingConfirmCancel}
      />

      <ModalCancel
        isOpen={modalConfirmCancel}
        setIsOpen={setModalConfirmCancel}
        onSubmit={handleCancelAll}
        loading={loadingConfirmCancel}
      />
    </Container>
  );
};

export default EyeOrdersBillOperation;
