/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
import React, { useState, useRef, useContext, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';

import { FaCheck } from 'react-icons/fa';
import OrderProductContext from '../../../pages/Products/ProductsTables/context';

import {
  ProductTableRow,
  ProductImage,
  MenuItemPromotion,
  NoImage,
  ProductName,
  ProductPrice,
  ProductDescription,
  ButtonsTd,
  ItemTd,
  ProductAvatar,
  NumberFormatStyled,
  SubmitStyledButton,
} from './styles';

import api from '~/services/api';
import formatValue from '../../../utils/formatValue';

import { useAuth } from '~/context/AuthContext';
import Select from '~/components/Form/SelectInput';
import Input from '~/components/Form/Input';

export default function ItemProductFiscal({
  product,
  index,
  fiscalGroups,
}) {
  const [
    complementCategoriesInsideProduct,
    setComplementCategoriesInsideProduct,
  ] = useState([]);
  const { user } = useAuth();
  // drag and drop
  const ref = useRef();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropDownHidden, setDropDownHidden] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const { move, onDrop } = useContext(OrderProductContext);

  const [{ isDragging }, dragRef] = useDrag({
    item: { type: 'PRODUCTCARD', index, product_id: product.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (!monitor.didDrop()) {
        const draggedIndex = item.index;
        const targetIndex = index;

        onDrop(item.product_id, product.id, draggedIndex, targetIndex);
      }
    },
  });

  const [, dropRef] = useDrop({
    accept: 'PRODUCTCARD',
    hover(item, monitor) {
      const draggedIndex = item.index;
      const targetIndex = index;

      if (draggedIndex === targetIndex) {
        return;
      }

      const targetSize = ref.current.getBoundingClientRect();
      const targetCenter = targetSize.height / 2;
      const draggedOffset = monitor.getClientOffset();
      const draggedTop = draggedOffset.y - targetSize.top;
      if (draggedIndex < targetIndex && draggedTop < targetCenter) {
        return;
      }
      if (draggedIndex > targetIndex && draggedTop > targetCenter) {
        return;
      }
      move(item.product_id, product.id, draggedIndex, targetIndex);

      item.index = targetIndex;
    },
    drop(item, monitor) {
      const draggedIndex = item.index;
      const targetIndex = index;

      onDrop(item.product_id, product.id, draggedIndex, targetIndex);

      setDropDownHidden(false);
    },
  });

  dragRef(dropRef(ref));

  const getComplementsCategoryInsideProduct = useCallback(async () => {
    const response = await api.get(
      `/restaurants/product-complement-categories/${product.id}`
    );

    setComplementCategoriesInsideProduct(response.data);
  }, [product]);

  const [collapsed, setCollapsed] = useState([]);

  function currencyFormatter(value) {
    if (!Number(value)) return 'R$ 0,00';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  }

  const handleFiscal = useCallback(
    async (data) => {
      const fiscal_group_id = parseInt(data.fiscal_group_id);

      try {
        const thisData = {
          ncm: data.ncm,
          fiscal_group_id,
          type: 'product'
        };

        await api.put(`/restaurants/update/fiscal/${product.id}`, thisData);

        toast.success('Produto associado com sucesso!');
      } catch (error) {
        toast.error('Erro ao vincular');
      }
    },
    [product]
  );

  return (
    <>
      <ProductTableRow available sold_off={product?.sold_off} ref={ref}>
        <ItemTd>
          <ProductAvatar>
            {product?.image ? (
              <ProductImage src={(product?.image?.url_thumb || product?.image?.url)} alt="Foto do produto" />
            ) : (
              <NoImage />
            )}
          </ProductAvatar>
          <ProductDescription>
            <ProductName>{product?.name}</ProductName>
          </ProductDescription>
        </ItemTd>
        {!user.only_delivery && (
          <ProductPrice>
            {product?.price_promotion ? (
              <>
                <MenuItemPromotion>
                  {formatValue(product?.price)}
                </MenuItemPromotion>

                <NumberFormatStyled
                  prefix="R$"
                  format={currencyFormatter}
                  name="value"
                  defaultValue={formatValue(product?.price_promotion)}
                  disabled
                />
              </>
            ) : (
              <>
                <NumberFormatStyled
                  prefix="R$"
                  format={currencyFormatter}
                  name="value"
                  defaultValue={formatValue(product?.price)}
                  disabled
                />
              </>
            )}
          </ProductPrice>
        )}

        <ProductPrice style={{ width: '450px' }}>
          <Form onSubmit={handleFiscal}>
            <div style={{ display: 'flex' }}>
              <Input
                style={{ width: '90px' }}
                defaultValue={product.ncm}
                name="ncm"
                placeholder="NCM"
              />
              <div style={{ width: '100%', marginRight: 10, marginLeft: 10 }}>
                <Select
                  name="fiscal_group_id"
                  defaultValue={fiscalGroups
                    .find(
                      (group) =>
                        group.id ===
                        product.fiscal_group_id
                    )}
                  options={fiscalGroups}
                  placeholder="Grupo fiscal"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
              </div>
              <SubmitStyledButton
                id="limit"
                type="submit"
                style={{
                  marginLeft: 'auto',
                }}
              >
                <FaCheck />
              </SubmitStyledButton>
            </div>
          </Form>
        </ProductPrice>

        <ButtonsTd />
      </ProductTableRow>

      {/* {complementCategoriesInsideProduct &&
        complementCategoriesInsideProduct.length > 0 &&
        complementCategoriesInsideProduct.map((comp_category, idx) => (
          <ProductsComplementsCategory
            complementCategory={comp_category}
            complementCategoriesProduct={complementCategoriesInsideProduct}
            setComplementCategoriesInsideProduct={
              setComplementCategoriesInsideProduct
            }
            index={idx}
            isOpen={!collapsed.includes(product?.id)}
            getCategories={getCategories}
            menewProducts={menewProducts}
            product={product}
            categories={categories}
            setCategories={setCategories}
            category={category}
            setComplementsCategories={setComplementsCategories}
            complementCategories={complementCategories}
            fiscalGroups={fiscalGroups}
          />
        ))} */}
    </>
  );
}
