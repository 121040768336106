import React, { useState } from 'react';
import { FaPlay, FaTimes } from 'react-icons/fa';
import YouTube from 'react-youtube';
import { ModalBody } from 'reactstrap';
import { useVideos } from '~/context/VideosContext';
import {
  Button,
  Icon,
  ModalYoutube,
  YoutubeContainer,
  Container,
} from './style';

export const VideoPlayer = ({ id, containerStyles }) => {
  const { getVideoById } = useVideos();
  const [isHidden, setIsHidden] = useState(true);
  const [modalYoutubeOpen, setModalYoutubeOpen] = useState(false);
  const [player, setPlayer] = useState(null);
  const [bigPlayer, setBigPlayer] = useState(null);
  const [clickHere, setClickHere] = useState(false);
  const thisVideo = getVideoById(id);

  const toggleModalYoutube = () => {
    setModalYoutubeOpen(!modalYoutubeOpen);
  };

  return (
    <Container
      style={containerStyles}
      show={thisVideo?.is_active}
      onMouseLeave={() => {
        if (player) {
          setIsHidden(true);
          setClickHere(false);
        }
      }}
    >
      <Button
        clickHere={clickHere}
        onMouseEnter={() => {
          if (player) {
            setIsHidden(false);
            setClickHere(true);
          }
        }}
        onClick={() => {
          toggleModalYoutube();
          setIsHidden(true);
          setClickHere(false);
        }}
      >
        <Icon>
          <FaPlay size={12} />
        </Icon>
        {clickHere
          ? 'Clique aqui para expandir'
          : thisVideo?.title || 'Assista'}
      </Button>
      <YoutubeContainer hidden={isHidden}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <h1>{thisVideo?.section_name}</h1>
        </div>
        <YouTube
          videoId={thisVideo?.url}
          onReady={(e) => setPlayer(e.target)}
          onPlay={() => {
            toggleModalYoutube();
            setIsHidden(true);
            setClickHere(false);
            player.pauseVideo();
          }}
          opts={{
            width: 380,
            height: 240,
            playerVars: { enablejsapi: 1 },
          }}
        />
      </YoutubeContainer>
      <ModalYoutube isOpen={modalYoutubeOpen} toggle={toggleModalYoutube}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            padding: '24px 24px 0',
          }}
        >
          <h1 style={{ marginBottom: 0 }}>{thisVideo?.section_name}</h1>
          <FaTimes
            color="#FE1933"
            size={25}
            style={{ cursor: 'pointer' }}
            onClick={() => toggleModalYoutube()}
          />
        </div>
        <ModalBody>
          <YouTube
            videoId={thisVideo?.url}
            onReady={(e) => setBigPlayer(e.target)}
            opts={{ playerVars: { autoplay: 1 } }}
          />
        </ModalBody>
      </ModalYoutube>
    </Container>
  );
};