import XLSX from 'xlsx-js-style';
import style from './sheetStyle';

import formatCompleteDate from '../formatCompleteDate';

function getClientData(start, end, clients) {
  const start_str = formatCompleteDate(start);
  const end_str = formatCompleteDate(end);

  const lines = [
    [`Período de ${start_str} até ${end_str}`],
    [
      'Telefone',
      'Nome',
      'Gastou até hoje (R$)',
      'Gastou até hoje (com taxas)(R$)',
      'Visitas',
      'Última avaliação',
      'Última visita',
    ],
  ];

  lines.push(
    ...clients.map((c) => {
      return [
        c.phone ? c.phone : '',
        c.name ? c.name : '',
        parseFloat(c.total),
        parseFloat(c.total_service),
        c.visits,
        c.last_rating ? c.last_rating : '',
        c.last_visit ? formatCompleteDate(new Date(c.last_visit)) : '',
      ];
    })
  );

  return lines;
}

export function generateSheet(start, end, content) {
  const rows = getClientData(start, end, content);

  const columns = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  const num_cols = 13;

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(rows);

  worksheet['!cols'] = new Array(num_cols);
  worksheet['!cols'].fill({ wch: 16 });

  worksheet['!cols'][2] = { wch: 20 };
  worksheet['!cols'][3] = { wch: 28 };

  worksheet["!merges"] = [{
    s: { r: 0, c: 0 },
    e: { r: 0, c: 6 },
  }];

  const white = 'ffffff';
  const black = '000000';
  const red1 = 'ff3838';
  const red2 = 'ffa6a6';
  const red3 = 'ffd7d7';
  const gold = 'e8f2a1';

  for (let i = 1; i < 3; i += 1) {
    for (let j = 0; j < 7; j += 1) {
      const cell = worksheet[columns[j] + i];

      if (cell) {
        if (i === 1) {
          cell.s = style().Font(gold, 'center', 'center').Bg(red1).Get();
        } else {
          cell.s = style().Font(white, 'center', 'center').Bg(red1).Get();
        }
      }
    }
  }

  for (let i = 3; i <= rows.length; i += 1) {
    for (let j = 0; j < 7; j += 1) {
      const cell = worksheet[columns[j] + i];

      if (cell) {
        if (i % 2 === 0) {
          cell.s = style().Bg(red2).Borders('lr', red3);
        } else {
          cell.s = style().Bg(white).Borders('lr', red3);
        }

        if (i === rows.length) {
          cell.s.Borders('b', red3);
        }

        if (j === 2 || j === 3) {
          cell.s.Number();
        } else {
          cell.s.Font(black, 'center', 'center');
        }

        cell.s = cell.s.Get();
      }
    }
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório de Clientes');

  return XLSX.write(workbook, { type: 'buffer' });
}
