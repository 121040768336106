import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-hot-toast';

import {
  Container,
  Header,
  ProductCategoriesContainer,
  StyledLink,
  ButtonContainer,
} from './styles';

import api from '~/services/api';

function OrderProductCategory() {
  const [categories, setCategories] = useState([]);

  const getCategories = useCallback(async () => {
    const response = await api.get('/restaurants/product-categories-order');

    setCategories(response.data.categories);
    const options = [];
    for (let i = 1; i <= response.data.count; i += 1) {
      options.push({
        label: i,
        value: i,
      });
    }
  }, []);

  useEffect(() => {
    try {
      getCategories();
    } catch (err) {
      // console.tron.log(err);
      toast.error('Erro ao carregar dados');
    }
  }, [getCategories]);

  return (
    <Container>
      <Header>
        <div>
          <h2>Ordenar Produtos</h2>
          <p>Selecione uma categoria de produtos para ordenar seus items</p>
        </div>
      </Header>

      <ProductCategoriesContainer>
        {categories.map((category, index) => (
          <StyledLink
            to={{
              pathname: `/products/order-products/category`,
              state: { category },
            }}
          >
            <ButtonContainer>
              <span>{category.name}</span>
            </ButtonContainer>
          </StyledLink>
        ))}
      </ProductCategoriesContainer>
    </Container>
  );
}

export default OrderProductCategory;
