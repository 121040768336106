import React, { useState } from 'react';
import { toast } from 'react-hot-toast';

import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { TiStarburstOutline, TiCancel } from 'react-icons/ti';
import { MdDone } from 'react-icons/md';

import { Wrapper, Header, LugarPedidos } from './styles';

import { useOrder } from '~/context/orderContext';
import history from '~/services/history';
import api from '~/services/api';

import UpdateButton from '~/components/Buttons/UpdateButton';
import BackButton from '~/components/Buttons/BackButton';
import CardOrdersAndamento from '~/components/Orders/Cards/CardOrdersAndamento';
import CardOrdersNew from '~/components/Orders/Cards/CardOrdersNew';
import CardOrdersCanceled from '~/components/Orders/Cards/CardOrdersCanceled';
import CardOrdersFinished from '~/components/Orders/Cards/CardOrdersFinished';

function Orders() {
  const [activeTab, setActiveTab] = useState('1');
  const {
    pendingBaskets,
    acceptedBaskets,
    finishedBaskets,
    canceledBaskets,
    loading,
    getAllOrders,
    updateTime,
    moveOrderToAccepted,
    moveOrderToFinished,
    moveOrderToCanceled,
  } = useOrder();

  async function handlePrintItem(id) {
    try {
      await api.put(`restaurants/orders/${id}`, {
        order_status: 'accepted',
      });
      moveOrderToAccepted({ order_basket_id: id });
      toast.success('Pedido aceito!');
    } catch (err) {
      toast.error('Não foi possível atualizar o pedido! tente novamente');
    }
  }

  // async function handleItemReady(id) {
  // /   try {
  //     await api.put(`restaurants/orders/${id}`, {
  //      order_status: 'ready',
  //     });
  //      getData();
  //      toast.success('Pedido Pronto!');
  //    } catch (err) {
  //      toast.error('Não foi possível atualizar o pedido! tente novamente');
  //    }
  //  }

  async function handleUpdate() {
    getAllOrders();
  }

  async function handleItemFinished(id) {
    try {
      await api.put(`restaurants/orders/${id}`, {
        order_status: 'finished',
      });
      moveOrderToFinished({ order_basket_id: id });
      toast.success('Pedido entregue!');
    } catch (err) {
      toast.error('Não foi possível atualizar o pedido! tente novamente');
    }
  }

  async function handleCancelItem({ order_basket_id, from }) {
    try {
      if (window.confirm('Tem certeza que deseja cancelar este pedido?')) {
        await api.put(`restaurants/orders/cancel/${order_basket_id}`);
        moveOrderToCanceled({ order_basket_id, from });
        toast.success('Pedido cancelado!');
      }
    } catch (err) {
      toast.error('Não foi possível atualizar o pedido! tente novamente');
    }
  }
  return (
    <Wrapper>
      <Header>
        <div>
          <h2>Acompanhar Pedidos</h2>
          <span>Última Atualização {updateTime}</span>
          <UpdateButton onClick={handleUpdate} />
        </div>
        <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
      </Header>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === '1' ? 'active' : ''}
            onClick={() => setActiveTab('1')}
          >
            <span>
              <TiStarburstOutline color="grey" size={20} />{' '}
            </span>
            Pedidos Novos e Em Andamento
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={activeTab === '2' ? 'active' : ''}
            onClick={() => setActiveTab('2')}
          >
            <span>
              <MdDone color="grey" size={20} />{' '}
            </span>
            Pedidos Finalizados
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={activeTab === '3' ? 'active' : ''}
            onClick={() => setActiveTab('3')}
          >
            <span>
              <TiCancel color="grey" size={20} />{' '}
            </span>
            Pedidos Cancelados
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <LugarPedidos>
            <CardOrdersNew
              handlePrintItem={handlePrintItem}
              handleCancelItem={handleCancelItem}
              baskets={pendingBaskets}
              loading={loading}
            />
            <CardOrdersAndamento
              baskets={acceptedBaskets}
              handleItemFinished={handleItemFinished}
              handleCancelItem={handleCancelItem}
              loading={loading}
            />
          </LugarPedidos>
        </TabPane>

        <TabPane tabId="2">
          <LugarPedidos>
            <CardOrdersFinished baskets={finishedBaskets} loading={loading} />
          </LugarPedidos>
        </TabPane>

        <TabPane tabId="3">
          <LugarPedidos>
            <CardOrdersCanceled baskets={canceledBaskets} loading={loading} />
          </LugarPedidos>
        </TabPane>
      </TabContent>
    </Wrapper>
  );
}

export default Orders;
