import React from 'react';
import { Button } from 'ui-kit-takeat';
import { NavBar } from './styles';
import {
  FaCalendarCheck,
  FaClock,
  FaLocationArrow,
  FaMotorcycle,
  FaPercentage,
} from 'react-icons/fa';
import { GiFullMotorcycleHelmet } from 'react-icons/gi';
import { BsFillGearFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const DeliveryNavBar = ({}) => {
  const history = useHistory();
  const thisPath = window.location.pathname.split('/delivery')[1];
  const windowWidth = window.innerWidth;

  return (
    <NavBar>
      <Button
        title="Delivery"
        icon={<FaMotorcycle />}
        inverted={thisPath !== '/orders'}
        onClick={() => history.push('/delivery/orders')}
      />
      <Button
        title="Motoboys"
        icon={<GiFullMotorcycleHelmet />}
        inverted={thisPath !== '/motoboys'}
        onClick={() => history.push('/delivery/motoboys')}
      />
      <Button
        title="Endereços"
        icon={<FaLocationArrow />}
        inverted={thisPath !== '/addresses'}
        onClick={() => history.push('/delivery/addresses')}
      />
      <Button
        title={windowWidth >= 1105 && 'Funcionamento'}
        icon={<FaClock />}
        inverted={thisPath !== '/opening-hours'}
        onClick={() => history.push('/delivery/opening-hours')}
      />
      <Button
        title={windowWidth >= 1230 && 'Agendamento'}
        icon={<FaCalendarCheck />}
        inverted={thisPath !== '/scheduling'}
        onClick={() => history.push('/delivery/scheduling')}
      />
      <Button
        title={windowWidth >= 1305 && 'Cupons'}
        icon={<FaPercentage />}
        inverted={thisPath !== '/coupons'}
        onClick={() => history.push('/delivery/coupons')}
      />
      <Button
        title={windowWidth >= 1375 && 'Config.'}
        icon={<BsFillGearFill />}
        inverted={thisPath !== '/config'}
        onClick={() => history.push('/delivery/config')}
      />
    </NavBar>
  );
};
