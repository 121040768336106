import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 10px;
`;

export const Header = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;
`;

export const Card = styled.div`
  margin: 20px auto;
  width: 90%;
  display: flex;
  padding: 30px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
`;

export const Bloco = styled.div`
  margin: 20px auto;
  width: 32%;
  height: 500px;
  padding: 30px;
  display: flex;
  float: left;
  margin-right: 10px;
  background: red;
  border-radius: 8px;
`;

export const LugarPedidos = styled.div`
  display: flex;
  background-color: white;
  border: 1px solid #dee2e6;
  border-top: none;
  border-bottom-radius: 10px;
`;
