import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 48px);
  padding: 20px;

  h2 {
    color: #3d3d3d;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const TransfersTable = styled.div`
  width: 100%;
  min-width: 200px;
  margin: 20px auto 40px;
  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TableRow = styled.div`
  background-color: #f4f4f4;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 20px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;

  span {
    color: #666666;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex-basis: 20%;
  }

  span:first-child {
    flex-basis: 40%;
    text-align: left;
  }

  span:last-child {
    text-align: right;
  }
`;

export const CollapsedRow = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  gap: 20px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  transform-origin: 'top';
  transform: ${(props) => (props.check ? 'scaleY(1)' : 'scaleY(0)')};
  padding: ${(props) => (props.check ? '10px' : '0px 10px')};
  ${(props) => !props.check && 'height: 0px'};

  span {
    color: #666666;
    text-align: center;
    flex-basis: 20%;
  }

  span:first-child {
    flex-basis: 40%;
    text-align: left;
  }

  span:last-child {
    text-align: right;
  }
`;

export const RowContainer = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 5px;
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 25px 20px;
  border-bottom: 1px solid #d9d9d9;
  gap: 20px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #666666;

  span {
    color: #666666;
    text-align: center;
    flex-basis: 20%;
  }

  span:first-child {
    flex-basis: 40%;
    text-align: left;
  }

  span:last-child {
    text-align: right;
  }
`;

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin: 25px 0;
`;

export const SearchDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  min-width: 400px;
  margin: 40px auto 25px;
  background: #ffffff;
  border: 1px solid #f7c3ca;
  border-radius: 5px;
  padding: 10px 20px;

  input {
    background: transparent;
    border: none;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #6c6c6c;
  }
`;

export const SearchBtn = styled.div`
  border: none;
  background: transparent;
  color: #f7c3ca;
  cursor: pointer;
`;

export const WarningContainer = styled.div`
  position: absolute;
  top: -115px;
  left: 10px;
  width: 416px;
  height: 116px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 10px 20px;
  transition: all 0.2s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  ${(props) => !props.show && 'pointer-events: none'};

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`;

export const StyledDatePicker = styled.div`
  background: transparent;
  padding: 10px 20px;
  border: 1px solid #ff2c3a;
  width: fit-content;
  border-radius: 5px;
  display: flex;
  align-items: center;
  position: relative;
  float: right;

  .react-datepicker {
    border: none;
    border-radius: 8px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
    top: 10px;
    right: -46px;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    border: none;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__navigation {
    opacity: 0;
    width: 50px;
    height: 50px;
  }

  .react-datepicker__day-names {
    background-color: #ffffff;

    div {
      width: 41px;
      margin: 0;

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 35px;
      text-align: center;
      color: #666666;
    }

    div:first-child,
    div:last-child {
      color: #979797;
    }
  }

  .react-datepicker__day {
    width: 41px;
    margin: 0;
    border-radius: 20px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 35px;
    text-align: center;
    color: #666666;
  }

  .react-datepicker__day--selected {
    background-color: #ff2c3a;
    color: #ffffff;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #f0f0f0;
  }

  .react-datepicker__current-month {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 35px;
    text-align: center;
    color: #ff2c3a;

    height: 44px;
  }

  .react-datepicker__header {
    background: #f4f4f4;
    border: none;
    border-radius: 8px 8px 0 0;
    text-transform: capitalize;
  }

  .react-datepicker__input-container {
    width: fit-content;
  }
  .react-datepicker-wrapper {
    width: fit-content;
  }
  input {
    background: transparent;
    border: none;
    width: 185px;
    text-transform: capitalize;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
    text-align: center;
    color: #666666;
  }
`;

export const NotFoundText = styled.p`
  width: 100%;
  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #666666;
`;
