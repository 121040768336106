import { Form } from '@unform/web';
import {
  differenceInHours,
  differenceInMonths,
  endOfDay,
  format,
  startOfDay,
  subHours,
} from 'date-fns';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-hot-toast';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import * as Yup from 'yup';
import Input from '~/components/Form/Input';
import { useAuth } from '~/context/AuthContext';
import { useOrder } from '~/context/orderContext';
import api from '~/services/api';
import getValidationErrors from '~/services/getValidationErrors';
import ButtonDefault from '~/components/Buttons/DefaultButton';
import Textarea from '~/components/Form/Textarea';
import {
  Container,
  ReconnectButton,
  WppModal,
  RatingContent,
  RatingHeader,
  RatingBody,
} from './styles';
import OrdersPage from './OrdersPage';
import Select from '~/components/Form/SelectInput';
import wppLostConnection from '../../../assets/img/wpp_lost_connection.png';
import { FaExclamationTriangle } from 'react-icons/fa';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { useWhatsgw } from '~/context/WhatsgwContext';
import PersonalButton from '~/components/Buttons/PersonalButton';
import { Button } from 'ui-kit-takeat';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// import PersonalOutlineButton from '~/components/Buttons/PersonalOutlineButton';
// import PersonalButton from '~/components/Buttons/PersonalButton';
function DeliveryOrdersPage() {
  const [sessionId, setSessionId] = useState();
  const cancelForm = useRef(null);
  const { created, setCreated } = useOrder();
  const [itemIfoodId, setItemIfoodId] = useState(null);
  const [ifood_motives, setIfoodMotives] = useState([]);

  const {
    user,
    modalOperationMessageWhatsappOnline,
    modalNpsCloseTime,
    setModalNpsCloseTime,
    userAlreadyRate,
    setUserAlreadyRate,
  } = useAuth();

  const { wppConnectedGw, setShowReconnectGw } = useWhatsgw();

  const [wppModal, setWppModal] = useState(true);
  const wppConnected = JSON.parse(
    localStorage.getItem('@gddashboard:wppConnected')
  );

  const history = useHistory();

  const [isModalSystemBloquedOpen, setIsModalSystemBloqued] = useState(false);

  function toggleModalSystemBloqued() {
    setIsModalSystemBloqued(!isModalSystemBloquedOpen);
  }

  useEffect(() => {
    setIsModalSystemBloqued(!user.is_active);
  }, [user.is_active]);

  async function handleOpenInvoice() {
    try {
      const response = await api.get(`restaurants/invoices/last`);
      window.open(response.data.invoice_url);
    } catch (error) {
      console.log(error);
      history.push('/financial/invoices');
    }
  }

  const toggleWppModal = () => {
    setWppModal(!wppModal);
  };
  const [isModalWhatsappOnlineOpened, setIsModalWhatsappOnlineOpened] =
    useState(modalOperationMessageWhatsappOnline.toString() === 'true');

  function toggleWhatsappOnlineModal() {
    setIsModalWhatsappOnlineOpened(!isModalWhatsappOnlineOpened);
  }
  const hasKey = user ? user.has_order_cancel_password : false;

  const [modalCancelOrderIsOpened, setModalCancelOrderIsOpened] =
    useState(false);

  async function getIfoodCancellationReasons(session_id) {
    try {
      const response = await api.get(
        `restaurants/ifood/cancellationReasons/${session_id}`
      );

      if (response.data && response.data.length > 0) {
        setIfoodMotives(response.data);
      } else {
        setIfoodMotives([{ value: 200, label: 'Cancelado no ifood' }]);
      }
    } catch (err) {
      toast.error(
        'Não foi possível carregar motivos para cancelar o pedido! tente novamente'
      );
    }
  }

  function handleOpenCancelOrderModal(
    order_basket_id,
    from,
    session_id,
    ifood_id
  ) {
    getIfoodCancellationReasons(session_id);
    setModalCancelOrderIsOpened(true);
    setSessionId(session_id);
    setItemIfoodId(ifood_id);
  }

  const toggleModalCancelOrder = useCallback(() => {
    setModalCancelOrderIsOpened(!modalCancelOrderIsOpened);
    setItemIfoodId(null);
  }, [modalCancelOrderIsOpened]);

  const {
    setLoading,
    getAllOrders,
    moveOrderToAccepted,
    moveOrderToFinished,
    moveOrderToFinishedDelivery,
    moveOrderToReady,
  } = useOrder();

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'DeliveryCurrentSessions'}`
      );

      const { can_read } = response.data.DeliveryCurrentSessions;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  async function handlePrintItem(id, ifood_id, is_delivery) {
    try {
      const response = await api.put(`restaurants/orders/${id}`, {
        order_status: 'accepted',
        ifood_id,
        is_delivery,
      });

      moveOrderToAccepted({
        order_basket_id: id,
        accepted_at: response.data?.accepted_at,
      });

      toast.success('Pedido aceito!');
    } catch (err) {
      toast.error('Não foi possível atualizar o pedido! tente novamente');
    }
  }

  async function handleItemFinished(id) {
    try {
      await api.put(`restaurants/orders/${id}`, {
        order_status: 'finished',
      });
      moveOrderToFinished({ order_basket_id: id });
      toast.success('Pedido entregue!');
    } catch (err) {
      toast.error('Não foi possível atualizar o pedido! tente novamente');
    }
  }

  async function handleItemFinishedDelivery(id) {
    try {
      await api.put(`restaurants/orders/${id}`, {
        order_status: 'finished',
      });
      moveOrderToFinishedDelivery({ order_basket_id: id });
      toast.success('Pedido entregue!');
    } catch (err) {
      toast.error('Não foi possível atualizar o pedido! tente novamente');
    }
  }

  async function handleItemReady(
    id,
    ifood_id,
    with_withdrawal,
    ifood_on_demand_id
  ) {
    try {
      const response = await api.put(`restaurants/orders/${id}`, {
        order_status: 'ready',
        ifood_id,
        with_withdrawal,
      });

      moveOrderToReady({
        order_basket_id: id,
        ready_at: response.data.ready_at,
        ifood_on_demand_id: ifood_on_demand_id || null,
      });
      toast.success('Pedido pronto pra entrega!');
    } catch (err) {
      toast.error('Não foi possível atualizar o pedido! tente novamente');
    }
  }

  async function handleCancelItemModal(data) {
    try {
      if (cancelForm?.current) {
        cancelForm.current.setErrors({});
      }

      const requiredSchema = Yup.string().required('Campo obrigatório');

      const schema = Yup.object().shape({
        order_cancel_password: Yup.string().concat(
          hasKey ? requiredSchema : null
        ),
        cancel_reason: Yup.string().concat(hasKey ? requiredSchema : null),
        ifood_motive: Yup.string().concat(
          itemIfoodId !== null && itemIfoodId ? requiredSchema : null
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      await api.put(`/restaurants/delivery/orders/cancel/${sessionId}`, {
        order_cancel_password: data.order_cancel_password,
        cancel_reason: data.cancel_reason,
        ifood_motive: data.ifood_motive,
      });
      toggleModalCancelOrder();
      getAllOrders();

      toast.success('Pedido cancelado!');
    } catch (error) {
      console.log('error', error);
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (cancelForm?.current) {
          cancelForm.current.setErrors(errors);
        }
      } else if (
        error.response?.data.errorType === 'incorrect_order_cancel_password'
      ) {
        toast.error('senha incorreta');
      } else {
        toast.error('Erro ao cancelar pedido!');
      }
    }
  }

  async function handleCancelItem({
    order_basket_id,
    from,
    session_id,
    ifood_id,
  }) {
    try {
      if (itemIfoodId !== null || hasKey) {
        handleOpenCancelOrderModal(order_basket_id, from, session_id, ifood_id);
      } else if (
        window.confirm('Tem certeza que deseja cancelar este pedido?')
      ) {
        await api.put(`/restaurants/delivery/orders/cancel/${session_id}`);
        toast.success('Pedido cancelado!');
        getAllOrders();
      }
    } catch (err) {
      toast.error('Não foi possível atualizar o pedido! tente novamente');
    }
  }

  // Comandas

  const defaultInicial = useMemo(() => {
    return format(
      startOfDay(subHours(new Date(), 27)),
      "yyyy-MM-dd'T'HH:mm:ss"
    );
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [setLoading]);

  //modal de serviço de whatsapp

  // function handleSendMessageToTakeat() {
  //   window.open(
  //     'https://api.whatsapp.com/send?phone=5527996350965&utm_source=banner-upsell&utm_medium=dashboard-takeat&utm_campaign=campanha-upselll'
  //   );
  // }

  // const [isModalWhatsappOpened, setIsModalWhatsappOpened] = useState(
  //   modalOperationMessageWhatsapp.toString() === 'true'
  // );

  // function toggleWhatsappModal() {
  //   setIsModalWhatsappOpened(!isModalWhatsappOpened);
  // }

  //nps modal
  const [isModalNpsOpen, setIsModalNpsOpen] = useState(
    differenceInMonths(new Date(), new Date(user.created_at || new Date())) <=
    3 &&
    userAlreadyRate?.toString() !== 'true' &&
    differenceInHours(new Date(), new Date(modalNpsCloseTime)) > 6 &&
    user.only_delivery
  );
  const [rating, setRating] = useState();
  const [showCommentArea, setShowCommentArea] = useState(false);

  function toggleModalNps() {
    setIsModalNpsOpen(!isModalNpsOpen);
    setModalNpsCloseTime(new Date());
  }

  function handleSetRating(e) {
    setRating(e);
    setShowCommentArea(true);
  }

  async function handleSubmit(data) {
    try {
      await api.post('/restaurants/research-answer', {
        research_id: 101,
        value: rating,
        comment: data.comment || null,
      });

      setUserAlreadyRate(true);
      setIsModalNpsOpen(false);
    } catch (error) {
      toast.error('Erro ao enviar avaliação');
    }
  }



  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>

      <Modal
        isOpen={isModalSystemBloquedOpen}
        toggle={toggleModalSystemBloqued}
        size="md"
      >
        <div
          style={{
            fontSize: '16px',
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            padding: '20px',
          }}
        >
          <button
            type="button"
            style={{
              border: 'none',
              background: 'transparent',
              color: '#333',
              fontSize: '16px',
              fontWeight: 'bold',
            }}
            onClick={toggleModalSystemBloqued}
          >
            X
          </button>
        </div>

        <ModalBody>
          <div
            style={{
              height: '300px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p>
              <FaExclamationTriangle color="#f10d27" size={90} />
            </p>
            <p style={{ fontSize: '26px' }}>
              <strong>Atenção</strong>
            </p>
            <p style={{ fontSize: '16px', textAlign: 'center' }}>
              O sistema está bloqueado por falta de pagamento. Efetue o
              pagamento para voltar a usar.
            </p>
          </div>
        </ModalBody>
        <ModalFooter />
        <button
          style={{
            width: '100%',
            background: '#f10d27',
            color: '#fff',
            border: 'none',
            fontSize: 20,
            height: 50,
            fontWeight: 'bold',
          }}
          type="button"
          onClick={() => {
            handleOpenInvoice();
            toggleModalSystemBloqued();
          }}
        >
          Pagar fatura
        </button>
      </Modal>

      {/* {!user?.use_wpp && user?.only_delivery && (
        <Modal
          isOpen={isModalWhatsappOpened}
          toggle={toggleWhatsappModal}
          size="md"
        >
          <ModalBody>
            <img
              src={WhatsappBanner}
              alt="banner de venda de serviço de whatsapp"
              style={{ width: '100%' }}
            />
          </ModalBody>
          <ModalFooter>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <PersonalOutlineButton
                borderColor="#2ec9b7"
                color="#fff"
                type="button"
                fontColor="#2ec9b7"
                onClick={() => {
                  setModalOperationMessageWhatsapp(false);
                  setIsModalWhatsappOpened(false);
                }}
                message="Não desejo ver novamente"
              />

              <PersonalButton
                className="btn"
                type="button"
                onClick={() => {
                  handleSendMessageToTakeat();
                }}
                color="#2ec9b7"
                message="Entrar em contato"
              />
            </div>
          </ModalFooter>
        </Modal>
      )} */}
      <OrdersPage
        handleItemFinished={handleItemFinished}
        handleItemFinishedDelivery={handleItemFinishedDelivery}
        handleCancelItem={handleCancelItem}
        handleItemReady={handleItemReady}
        handlePrintItem={handlePrintItem}
        setItemIfoodId={setItemIfoodId}
      />

      <Modal isOpen={isModalNpsOpen} toggle={toggleModalNps} size="lg">
        <Form onSubmit={handleSubmit}>
          <ModalBody>
            <RatingContent>
              <RatingHeader>
                <span
                  style={{ fontSize: 20, fontWeight: 'bold', color: '#424242' }}
                >
                  QUEREMOS A SUA AVALIAÇÃO!
                </span>
                <span
                  style={{ fontSize: 16, color: '#424242', width: '654px' }}
                >
                  Caso você ou algum familiar/amigo venha a abrir um novo
                  estabelecimento, o quanto você estaria disposto a recomendar o
                  uso do sistema Takeat ?
                </span>
              </RatingHeader>
              <RatingBody>
                <div
                  style={{
                    width: '90%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <span
                    style={{
                      fontSize: 14,
                      color: '#424242',
                      fontWeight: 'bold',
                    }}
                  >
                    Não Recomendo
                  </span>
                  <span
                    style={{
                      fontSize: 14,
                      color: '#424242',
                      fontWeight: 'bold',
                    }}
                  >
                    Recomendo
                  </span>
                </div>
                <div
                  style={{
                    width: '90%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <button
                    style={{ background: '#FC1412' }}
                    type="button"
                    onClick={(e) => handleSetRating(e.target.innerText)}
                  >
                    0
                  </button>
                  <button
                    style={{ background: '#FE191C' }}
                    type="button"
                    onClick={(e) => handleSetRating(e.target.innerText)}
                  >
                    1
                  </button>
                  <button
                    style={{ background: '#FE3030' }}
                    type="button"
                    onClick={(e) => handleSetRating(e.target.innerText)}
                  >
                    2
                  </button>
                  <button
                    style={{ background: '#FF8B00' }}
                    type="button"
                    onClick={(e) => handleSetRating(e.target.innerText)}
                  >
                    3
                  </button>
                  <button
                    style={{ background: '#FF9B23' }}
                    type="button"
                    onClick={(e) => handleSetRating(e.target.innerText)}
                  >
                    4
                  </button>
                  <button
                    style={{ background: '#FFCD0C' }}
                    type="button"
                    onClick={(e) => handleSetRating(e.target.innerText)}
                  >
                    5
                  </button>
                  <button
                    style={{ background: '#FEC400' }}
                    type="button"
                    onClick={(e) => handleSetRating(e.target.innerText)}
                  >
                    6
                  </button>
                  <button
                    style={{ background: '#80CD0D' }}
                    type="button"
                    onClick={(e) => handleSetRating(e.target.innerText)}
                  >
                    7
                  </button>
                  <button
                    style={{ background: '#70B407' }}
                    type="button"
                    onClick={(e) => handleSetRating(e.target.innerText)}
                  >
                    8
                  </button>
                  <button
                    style={{ background: '#00C132' }}
                    type="button"
                    onClick={(e) => handleSetRating(e.target.innerText)}
                  >
                    9
                  </button>
                  <button
                    style={{ background: '#009A22' }}
                    type="button"
                    onClick={(e) => handleSetRating(e.target.innerText)}
                  >
                    10
                  </button>
                </div>

                {showCommentArea && (
                  <Textarea
                    name="comment"
                    size
                    placeHolder={
                      rating <= 5
                        ? 'Diga o que podemos melhorar para que você se sinta realmente satisfeito.'
                        : 'Nos dê um feedback para que possamos melhorar ainda mais.'
                    }
                  />
                )}
              </RatingBody>
            </RatingContent>
          </ModalBody>
          <ModalFooter>
            <div
              style={{
                width: '92%',
                marginLeft: 20,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div style={{ display: 'flex' }}>
                <PersonalButton
                  message="Avaliar depois"
                  color="#FF2C3A"
                  style={{ marginRight: 8 }}
                  type="button"
                  onClick={toggleModalNps}
                />
                <PersonalButton
                  message="Enviar"
                  color="#2EC9B7"
                  type="submit"
                  disabled={!rating}
                />
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>

      {/* Modal whatsgw */}
      <WppModal
        isOpen={
          wppModal &&
          user.use_wpp &&
          wppConnectedGw === false &&
          wppConnectedGw !== null
        }
        toggle={toggleWppModal}
      >
        <img src={wppLostConnection} />
        <h1>CONEXÃO PERDIDA COM WHATSAPP</h1>
        <p>
          Atenção, não detectamos conexão com o seu WhatsApp. Clique no botão
          abaixo e reestabeleça conexão o quanto antes.
        </p>
        <ReconnectButton
          onClick={() => {
            toggleWppModal();
            setShowReconnectGw(true);
          }}
        >
          Reestabelecer conexão
        </ReconnectButton>
      </WppModal>

      <Modal isOpen={modalCancelOrderIsOpened} toggle={toggleModalCancelOrder}>
        <Form onSubmit={handleCancelItemModal} ref={cancelForm}>
          <ModalBody>
            {hasKey && (
              <Input
                name="order_cancel_password"
                label="Senha de cancelamento"
                type="password"
              />
            )}
            {hasKey && <Input name="cancel_reason" label="Justificativa" />}
            {itemIfoodId !== null && itemIfoodId && (
              <Select
                name="ifood_motive"
                options={ifood_motives}
                label="Motivo"
              />
            )}
          </ModalBody>

          <ModalFooter>
            <ButtonDefault message="Confirmar" type="submit" />
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={created.length > 0}>
        <ModalBody>
          <h3>Novos itens foram criados</h3>
          <h4>
            Recebemos do iFood produtos e/ou complementos que não estão
            cadastrados em nosso sistema. Por isso, criamos categorias
            exclusivas de produtos iFood e adicionamos esses novos itens nelas.
            Você pode realizar quaisquer alterações que deseja em sua página de
            produtos.
          </h4>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
            }}
          >
            <div>
              <p>Produtos</p>
              <ul style={{ padding: 0 }}>
                {created
                  .filter((p) => p.ifood_product_id)
                  .map((p) => {
                    return <li key={p.id}>{p.name}</li>;
                  })}
              </ul>
            </div>
            <div>
              <p>Complementos</p>
              <ul style={{ padding: 0 }}>
                {created
                  .filter((p) => p.ifood_complement_id)
                  .map((p) => {
                    return <li key={p.id}>{p.name}</li>;
                  })}
              </ul>
            </div>
          </div>
          <ButtonDefault
            message="OK"
            style={{ margin: '10px auto' }}
            onClick={() => setCreated([])}
          />
        </ModalBody>
      </Modal>
    </Container>
  );
}

export default DeliveryOrdersPage;
