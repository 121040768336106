import React from 'react';


import {
  Container,
  Body,
  Phone,
  Price,
} from './styles';


const PrintPaymentReportFiscal = React.forwardRef(
  (
    {
      items, phrase
    },
    ref
  ) => {

    const total = items.reduce((acc, item) => acc + item.total, 0);

    return (
      <Container ref={ref}>
        {items ? (
          <React.Fragment key={items.id}>
            <Body style={{textAlign: 'center', marginTop: 10}}>
              <h2><strong>Relatório de Pagamentos - Emissão de Notas Fiscais</strong></h2>
              <h3>{phrase}</h3>
            </Body>
            {
              items.map(item => item.total > 0 && (
              <Body>
                <Phone>
                  {item.name}
                </Phone>
                <Price>
                  R${parseFloat(item.total).toLocaleString('pt-br', { minimumFractionDigits: 2})} 
                </Price>
              </Body>
              ))
            }
            <Body style={{borderTop: '1px solid black', marginTop: 5, marginBottom: 5}}>
              <Phone>
                <strong>Total</strong>
              </Phone>
              <Price>
                <strong>R${parseFloat(total).toLocaleString('pt-br', { minimumFractionDigits: 2})}</strong>
              </Price>
            </Body>
          </React.Fragment>
        ) : (
          <div />
        )}
      </Container>
    );
  }
);

export default PrintPaymentReportFiscal;
