import { Form } from '@unform/web';

import React, { useEffect, useRef, useState } from 'react';

import { Modal, Row } from 'reactstrap';
import CheckboxInput from '~/components/Form/Checkbox';
import Input from '~/components/Form/Input';
import { Hyperlink, Button, Warning } from 'ui-kit-takeat';
import Select from '~/components/Form/SelectInput';
import { useAuth } from '~/context/AuthContext';
import { useCart } from '~/context/OrderingSystem/Cart';
import * as Yup from 'yup';
import MenuItemList from './MenuItemList';

import {
  Container,
  Content,
  MenuContent,
  MenuItem,
  ItemListHeader,
  ModalGrid,
} from './styles';
import BuyersScrollNav from '~/components/BuyersScrollNav';
import InputMask from 'react-input-mask';
import getValidationErrors from '~/services/getValidationErrors';

import api from '~/services/api';
import { toast } from 'react-hot-toast';

const Cart = ({
  isTableDelivery,
  deliveryTax,
  discount,
  setDiscount,
  setDiscountObs,
  adminPassword,
  setAdminPassword,
  isBalcony,
  session,
  isAvailable,
  isCommand,
  setClientOperation,
  hasDeliveryTax,
}) => {
  const { cart } = useCart();
  const {
    restaurantDiscountObs,
    user,
    setBuyerCreatedByWaiter,
    setBuyersCreatedByWaiter,
    buyersCreatedByWaiter,
    buyerSelectedByWaiter,
  } = useAuth();
  const [discountObsOptions, setDiscountObsOptions] = useState([]);
  const [discountPercent, setDiscountPercent] = useState(null);
  const [discountText, setDiscountText] = useState(false);
  const [applyDiscount, setApplyDiscount] = useState(false);

  const [totalWithDiscount, setTotalWithDiscount] = useState(null);

  const [showNotificationNewClientSignUp, setShowNotificationNewClientSignUp] =
    useState(() => {
      const notification = localStorage.getItem(
        '@notification:showNewClientSignUp'
      );
      if (!notification) {
        return true;
      } else {
        return false;
      }
    });

  const totalProductPrice = isTableDelivery
    ? cart.reduce((TotalAccumulator, order) => {
      if (order.combo_price) {
        return (
          TotalAccumulator + parseFloat(order.combo_price) * order.amount
        );
      }

      const totalCategories = order.complement_categories.reduce(
        (categoryAccumulator, category) => {
          if (category.more_expensive_only) {
            let totalComplements = 0;
            category.complements.forEach((complement) => {
              if (
                totalComplements <
                parseFloat(
                  complement.delivery_price
                    ? complement.delivery_price
                    : complement.price
                ) *
                (parseInt(complement.amount) >= 1 ? 1 : 0)
              ) {
                totalComplements =
                  parseFloat(
                    complement.delivery_price
                      ? complement.delivery_price
                      : complement.price
                  ) * (parseInt(complement.amount) >= 1 ? 1 : 0);
              }
            });
            return categoryAccumulator + totalComplements;
          }

          if (category.use_average) {
            const amountAverage = category.complements.reduce(
              (accum, curr) => accum + curr.amount,
              0
            );

            const totalPriceAverage =
              category.complements
                .map((item) => item)
                .reduce(
                  (acum, curr) =>
                    acum + (curr.delivery_price || curr.price) * curr.amount,
                  0
                ) / amountAverage;

            return totalPriceAverage + categoryAccumulator;
          }
          const totalComplements = category.complements.reduce(
            (complementAccumulator, complement) => {
              return (
                complementAccumulator +
                (complement.delivery_price || complement.price) *
                complement.amount
              );
            },
            0
          );
          return categoryAccumulator + totalComplements;
        },
        0
      );

      if (order.weight) {
        const sum = order.weight_price
          ? parseFloat(totalCategories) + parseFloat(order.weight_price)
          : (parseFloat(totalCategories) +
            parseFloat(order.price * order.weight)) *
          order.amount;
        return TotalAccumulator + sum;
      }
      return (
        TotalAccumulator +
        (parseFloat(totalCategories) + parseFloat(order.price)) * order.amount
      );
    }, 0)
    : cart?.reduce((TotalAccumulator, order) => {
      if (order.combo_price) {
        return (
          TotalAccumulator + parseFloat(order.combo_price) * order.amount
        );
      }

      const totalCategories = order.complement_categories.reduce(
        (categoryAccumulator, category) => {
          if (category.more_expensive_only) {
            let totalComplements = 0;
            category.complements.forEach((complement) => {
              if (
                totalComplements <
                parseFloat(complement.price) *
                (parseInt(complement.amount) >= 1 ? 1 : 0)
              ) {
                totalComplements =
                  parseFloat(complement.price) *
                  (parseInt(complement.amount) >= 1 ? 1 : 0);
              }
            });
            return categoryAccumulator + totalComplements;
          }
          if (category.use_average) {
            const amountAverage = category.complements.reduce(
              (accum, curr) => accum + curr.amount,
              0
            );

            const totalPriceAverage =
              category.complements
                .map((item) => item)
                .reduce((acum, curr) => acum + curr.price * curr.amount, 0) /
              amountAverage;

            return totalPriceAverage + categoryAccumulator;
          }
          const totalComplements = category.complements.reduce(
            (complementAccumulator, complement) => {
              return (
                complementAccumulator + complement.price * complement.amount
              );
            },
            0
          );
          return categoryAccumulator + totalComplements;
        },
        0
      );

      if (order.weight) {
        const sum = order.weight_price
          ? parseFloat(totalCategories) + parseFloat(order.weight_price)
          : (parseFloat(totalCategories) +
            parseFloat(order.price * order.weight)) *
          order.amount;
        return TotalAccumulator + sum;
      }
      return (
        TotalAccumulator +
        (parseFloat(totalCategories) + parseFloat(order.price)) * order.amount
      );
    }, 0);

  const handleChangeDiscount = (target) => {
    const targetValue = target.value.replace(/(R|\$|%)/g, '').replace(',', '.');

    if (targetValue.length < 1) {
      setDiscount('');
      setDiscountPercent('');
      setTotalWithDiscount(totalProductPrice + (deliveryTax || 0));
    } else if (target.name === 'flat_discount') {
      setDiscount(targetValue);
      setDiscountPercent(
        (
          (parseFloat(targetValue.replace(',', '.')) /
            (totalProductPrice + (deliveryTax || 0))) *
          100 || 0
        ).toFixed(2)
      );
      setTotalWithDiscount(
        totalProductPrice +
        (deliveryTax || 0) -
        parseFloat(targetValue.replace(',', '.'))
      );
    } else {
      setDiscount(
        (
          (parseFloat(targetValue.replace(',', '.')) *
            (totalProductPrice + (deliveryTax || 0))) /
          100
        ).toFixed(2)
      );
      setDiscountPercent(targetValue);
      setTotalWithDiscount(
        totalProductPrice +
        (deliveryTax || 0) -
        (parseFloat(targetValue.replace(',', '.')) *
          (totalProductPrice + deliveryTax)) /
        100
      );
    }
  };

  useEffect(() => {
    const obs_options = restaurantDiscountObs?.map((disc) => ({
      value: disc.id,
      label: disc.obs,
    }));
    setDiscountObsOptions([
      ...(obs_options || []),
      { value: 'other', label: 'Outro' },
    ]);
  }, [restaurantDiscountObs]);

  useEffect(() => {
    if (applyDiscount && discount) {
      setTotalWithDiscount(totalProductPrice + (deliveryTax || 0) - discount);
      setDiscountPercent(
        (
          discount && (discount / (totalProductPrice + deliveryTax)) * 100
        ).toFixed(2)
      );
    }
  }, [cart, deliveryTax, totalProductPrice, applyDiscount, setDiscount]);

  function deleteDiscount() {
    if (totalWithDiscount) {
      setTotalWithDiscount(null);
    }
  }

  //create buyers by waiter/pdv
  const formRef = useRef(null);
  const [newBuyer, setNewBuyer] = useState(false);

  const [buyers, setBuyers] = useState([]);
  const [isModalCreateBuyerOpened, setIsModalCreateBuyerOpened] =
    useState(false);

  useEffect(() => {
    async function fetchData() {
      if (isAvailable) {
        const tableOrCommand = isCommand ? 'Comanda' : 'Mesa';
        setBuyers([
          { name: '+ Cliente', id: -33801 },
          { name: tableOrCommand, id: -33800 },
        ]);
      } else {
        const response = await api.get(
          `restaurants/table-sessions-bills/${session.id}`
        );

        const buyersNew = response.data.bills
          .filter((bill) => bill.status !== 'finished')
          .filter((item) => item.buyer)
          .map((bill) => {
            return {
              id: bill?.buyer?.id,
              name: bill?.buyer?.name || '',
              phone: bill?.buyer?.phone,
            };
          });

        const tableOrCommand = isCommand ? 'Comanda' : 'Mesa';
        setBuyers([
          { name: '+ Cliente', id: -33801 },
          { name: tableOrCommand, id: -33800 },
          ...buyersNew,
        ]);

        setNewBuyer(false);
      }
    }

    if (!isBalcony && !isTableDelivery) {
      fetchData();
    }
  }, [session]);

  function toggleModalCreateBuyer() {
    setIsModalCreateBuyerOpened(!isModalCreateBuyerOpened);
    setIsAddClient(false);
    setShowCompleteRegister(false);
    setClientInfo(null);
    setCpfClient('');
    setCepClient('');
    setBirthdayClient('');
  }

  function handleCreateBuyer() {
    toggleModalCreateBuyer();
  }

  const [clientPhone, setClientPhone] = useState('');
  const [showCompleteRegister, setShowCompleteRegister] = useState(false);
  const [isAddClient, setIsAddClient] = useState(false);
  const [clientInfo, setClientInfo] = useState(null);

  const [cpfClient, setCpfClient] = useState('');
  const [cepClient, setCepClient] = useState('');
  const [birthdayClient, setBirthdayClient] = useState('');

  async function handleSubmit(data) {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        name: Yup.string()
          .required('Usuário obrigatório')
          .min(3, 'Mínimo de 3 letras'),
        phone: Yup.string().matches(/\(\d\d\)\s\d{5}-\d{4}/, {
          message: 'Preencha todos os 9 números',
          excludeEmptyString: true,
        }),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const clientData = isAddClient ? data : null;

      if (isAvailable && isAddClient && !clientInfo.hasClient) {
        const res = await api.post(`restaurants/clients`, data);

        setClientOperation && setClientOperation(res.data);
      }

      if (isCommand) {
        if (buyers.length === 3) {
          toast.error('Mesa comanda só pode ser adicionado 1 usuário.');
        } else {
          const id_buyer_created = Math.floor(Math.random() * -1000);
          setBuyerCreatedByWaiter({
            name: data.name,
            phone: data.phone,
            clientData,
          });

          const newBuyers = [...buyers];

          newBuyers.splice(2, 0, {
            name: data.name,
            phone: data.phone,
            id: id_buyer_created,
            clientData,
          });

          setBuyers(newBuyers);

          setNewBuyer(id_buyer_created);

          setBuyersCreatedByWaiter(newBuyers);

          toggleModalCreateBuyer();
        }
      } else {
        const id_buyer_created = Math.floor(Math.random() * -1000);
        setBuyerCreatedByWaiter({
          name: data.name,
          phone: data.phone,
          clientData,
        });

        const newBuyers = [...buyers];

        newBuyers.splice(2, 0, {
          name: data.name,
          phone: data.phone,
          id: id_buyer_created,
          clientData,
        });

        setBuyers(newBuyers);

        setNewBuyer(id_buyer_created);

        setBuyersCreatedByWaiter(newBuyers);

        toggleModalCreateBuyer();
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
        console.log('@@', data.phone, errors);
      } else {
        alert('Falha ao criar usuário...');
      }
    }
  }

  async function getClient() {
    try {
      const res = await api.post('/restaurants/get-clients', {
        phone: clientPhone,
      });

      setClientInfo(res.data);

      const client = res.data.client;
      if (client) {
        setCpfClient(client.cpf);
        setCepClient(client.cep);
        setBirthdayClient(client.birthday);
        setClientOperation && setClientOperation(client);
      }
    } catch (error) {
      console.log('vvv', error);
      toast.error('Erro ao consultar cliente');
    }
  }

  useEffect(() => {
    if (clientPhone[14] && clientPhone[14] !== '_') {
      setShowCompleteRegister(true);
    }
  }, [clientPhone]);

  return (
    <Container>
      <Modal isOpen={isModalCreateBuyerOpened} toggle={toggleModalCreateBuyer}>
        <Form
          onSubmit={handleSubmit}
          ref={formRef}
          initialData={clientInfo?.client || null}
        >
          <div style={{ display: 'grid', gap: '5px', padding: '20px' }}>
            <div style={{ fontSize: 16, fontWeight: 700 }}>
              Adicionar cliente
            </div>
            <ModalGrid>
              <Input name="name" label="Nome do cliente *" />
              <InputMask
                mask="(99) 99999-9999"
                maskChar=""
                onChange={(e) => setClientPhone(e.target.value)}
                disabled={isAddClient}
              >
                <Input name="phone" label="Celular" placeholder="(opcional)" />
              </InputMask>
            </ModalGrid>

            {!isAddClient && showCompleteRegister && (
              <div style={{ marginLeft: 'auto' }}>
                <Hyperlink
                  fontSize="14px"
                  textDecoration="underline"
                  onClick={() => {
                    getClient();
                    setIsAddClient(true);
                  }}
                >
                  Cadastro completo
                </Hyperlink>
              </div>
            )}

            {isAddClient && (
              <ModalGrid>
                <Input name="email" label="E-mail" placeholder="(opcional)" />

                <InputMask
                  mask="999.999.999-99"
                  maskChar=""
                  value={cpfClient}
                  onChange={(e) => setCpfClient(e.target.value)}
                >
                  <Input name="cpf" label="CPF" placeholder="(opcional)" />
                </InputMask>

                <InputMask
                  mask="99/99/9999"
                  maskChar=""
                  value={birthdayClient}
                  onChange={(e) => setBirthdayClient(e.target.value)}
                >
                  <Input
                    name="birthday"
                    label="Data de Nascimento"
                    placeholder="(opcional)"
                  />
                </InputMask>

                <InputMask
                  mask="99.999-999"
                  value={cepClient}
                  onChange={(e) => setCepClient(e.target.value)}
                >
                  <Input
                    name="cep"
                    label="CEP"
                    type="text"
                    placeholder="(opcional)"
                  />
                </InputMask>

                <Input name="street" label="Rua" placeholder="(opcional)" />

                <Input name="bairro" label="Bairro" placeholder="(opcional)" />

                <Input name="number" label="Número" placeholder="(opcional)" />

                <Input
                  name="complement"
                  label="Complemento"
                  placeholder="(opcional)"
                />
              </ModalGrid>
            )}
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Button
                type="button"
                onClick={toggleModalCreateBuyer}
                title="Cancelar"
                buttonColor="#ff2c3a"
              />
              <Button type="submit" title="Salvar" buttonColor="#2ec9b7" />
            </div>
          </div>
        </Form>
      </Modal>

      <Content>
        {!isTableDelivery && !isBalcony && (
          <>
            <BuyersScrollNav
              buyers={buyers}
              createBuyer={handleCreateBuyer}
              newBuyer={newBuyer}
            />
            <div>
              <Warning
                type="success"
                content={`A opção 'Cadastrar cliente' agora está apenas na parte superior deste PDV em '+ Cliente'`}
                show={showNotificationNewClientSignUp}
                onClick={() => {
                  setShowNotificationNewClientSignUp(false);
                  localStorage.setItem(
                    '@notification:showNewClientSignUp',
                    'false'
                  );
                }}
              />
            </div>
          </>
        )}

        {isTableDelivery && (
          <Form>
            <CheckboxInput
              label="Aplicar desconto"
              name="apply"
              value={applyDiscount}
              onChange={() => {
                setApplyDiscount(!applyDiscount);
                deleteDiscount();
              }}
            />
          </Form>
        )}

        {applyDiscount && (
          <>
            <span style={{ color: 'grey' }}>Desconto</span>
            <div
              style={{
                display: 'flex',
                margin: '10px 0 0',
                position: 'relative',
              }}
            >
              <input
                name="flat_discount"
                type="text"
                placeholder="R$"
                style={{ width: '50%', padding: '3px 10px' }}
                value={discount && `R$${discount.replace('.', ',')}`}
                onChange={(e) => handleChangeDiscount(e.target)}
              />
              <input
                name="discount"
                placeholder="%"
                type="text"
                style={{ width: '50%', padding: '3px 10px', marginLeft: '5px' }}
                value={
                  discountPercent &&
                  (discountPercent > 100
                    ? 100
                    : discountPercent.replace('.', ','))
                }
                onChange={(e) => handleChangeDiscount(e.target)}
              />
              {discount && (
                <p style={{ position: 'absolute', right: '15px', top: '5px' }}>
                  %
                </p>
              )}
            </div>
            <Form>
              <Row>
                <Select
                  label={null}
                  name="discount_obs"
                  options={discountObsOptions}
                  onChange={(e) => {
                    if (e.value === 'other') {
                      setDiscountText(true);
                    } else {
                      setDiscountText(false);
                      setDiscountObs(e.label);
                    }
                  }}
                />
                {discountText && (
                  <Input
                    type="text"
                    placeholder="Escreva o motivo"
                    id="discount_obs"
                    name="discount_obs"
                    onChange={(e) => setDiscountObs(e.target.value)}
                  />
                )}
              </Row>
              {user.order_cancel_password !== '' && (
                <Input
                  placeholder="Senha administrativa"
                  id="cancel_password"
                  name="cancel_password"
                  type="password"
                  value={adminPassword}
                  autocomplete="off"
                  onChange={(e) => setAdminPassword(e.target.value)}
                />
              )}
            </Form>
          </>
        )}
        <ItemListHeader>
          <span
            style={{
              fontWeight: 'bold',
              fontSize: 18,
              lineHeight: '18px',
            }}
          >
            Carrinho
          </span>
        </ItemListHeader>
        <MenuContent>
          <MenuItem>
            {cart.length > 0
              ? cart.map((item) => (
                <MenuItemList item={item} key={item.ordering_system_id} />
              ))
              : 'Sem itens no carrinho'}
          </MenuItem>
          {deliveryTax ? (
            <p
              style={{
                textDecoration: !hasDeliveryTax ? 'line-through' : 'none',
              }}
            >
              - Taxa de entrega: R$
              {deliveryTax}
            </p>
          ) : ""}
          <p style={{ fontWeight: 'bold', marginTop: 20 }}>
            - Subtotal: R${' '}
            {totalWithDiscount
              ? totalWithDiscount.toFixed(2)
              : (
                totalProductPrice + (hasDeliveryTax ? deliveryTax || 0 : 0)
              ).toFixed(2)}
          </p>
        </MenuContent>
      </Content>
    </Container>
  );
};

export default Cart;
