import styled from 'styled-components';
import ScrollContainer from 'react-indiana-drag-scroll';

export const Container = styled.div`
  padding-right: 10px;
  background: #fff;
  width: 100%;
  height: 156px;
  margin-top: 10px;

  z-index: 10;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    h1 {
      font-size: 24px;
      font-weight: bold;
    }
    > div {
      font-size: 14px;
    }
  }
  nav {
    display: inline-flex;
    width: 100%;
    padding-left: 10px;
  }
`;

export const CategoryHorizontalScroll = styled(ScrollContainer)`
  display: flex;
  align-content: center;
  padding-bottom: 15px;
`;
