import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';

import { Row, Col, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import ButtonDefault from '~/components/Buttons/DefaultButton';
import {
  Item,
  ItemName,
  Complements,
  Obs,
  DeleteAndEditActions,

} from '../styles';
import { useOrder } from '~/context/orderContext';
import api from '~/services/api';

import Select from '~/components/Form/SelectInput';
import getValidationErrors from '~/services/getValidationErrors';
import Input from '~/components/Form/Input';
import { useAuth } from '~/context/AuthContext';
import PersonalButton from '~/components/Buttons/PersonalButton';

export default function ItemOrder({ orders, order, item, from, session }) {
  const { cancelOrderItem, getAllOrders, loadingCancelItems } = useOrder();

  const { user } = useAuth();
  const { amount } = order;

  const [loading, setLoading] = useState(false);

  const [loadingCancelOrdemItem, setLoadingCancelOrdemItem] = useState(false);

  const hasKey = user ? user.has_order_cancel_password : false;

  const changeAmount = useCallback(
    async (data) => {
      const newAmount = order?.amount - data?.amount
      setLoading(true);
      try {
        if (newAmount === 0) {
          cancelOrderItem({
            order_basket_id: item.basket.id,
            order_id: order.id,
            from,
          });
        } else {
          await api.put(
            `restaurants/orders/basket/${item.basket.id}/item/${order.id}/${newAmount}`
          );
          toast.success('Pedido atualizado.');
          getAllOrders();
        }

      } catch (error) {
        toast.error('Erro ao atualizar pedido.');
      }
      setLoading(false);
    },
    [getAllOrders, item, order]
  );

  const options = [];

  for (let i = 1; i <= amount; i++) {
    options.push({ value: i, label: i.toString() });
  }

  const defaultOption = [{ value: amount, label: amount.toString() }];

  const cancelForm = useRef(null);

  const [modalCancelOrderIsOpened, setModalCancelOrderIsOpened] = useState(
    false
  );

  const [dataState, setDataState] = useState('');

  const toggleModalCancelOrder = useCallback(
    (data) => {
      setModalCancelOrderIsOpened(!modalCancelOrderIsOpened);
      setDataState(data);
    },
    [modalCancelOrderIsOpened]
  );

  const handleCancelItemModal = useCallback(
    async (data) => {
      console.log(order)
      setLoading(true);
      console.log('loading true');
      try {
        if (cancelForm?.current) {
          cancelForm.current.setErrors({});
        }

        const schema = Yup.object().shape({
          order_cancel_password: Yup.string().required('senha obrigatória'),
          cancel_reason: Yup.string().required('justicativa obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const newAmount = order?.amount - dataState?.amount

        await api.put(
          `restaurants/orders/basket/${item.basket.id}/item/${order.id}/${newAmount}`,

          {
            order_cancel_password: data.order_cancel_password,
            cancel_reason: data.cancel_reason,
          }
        );

        toast.success('Pedido atualizado.');
        toggleModalCancelOrder();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (cancelForm?.current) {
            cancelForm.current.setErrors(errors);
          }
        } else if (
          error.response.data.errorType === 'incorrect_order_cancel_password'
        ) {
          toast.error('senha incorreta');
        } else {
          toast.error('Erro ao cancelar pedido!');
        }
      }
      console.log('loading false');
      setLoading(false);
    },
    [toggleModalCancelOrder, dataState, item, order, getAllOrders]
  );

  function handleChangeAmount(data) {
    if (hasKey) {
      toggleModalCancelOrder(data);
    } else {
      changeAmount(data);
    }
  }

  return (
    <Item key={order.id}>
      <Modal isOpen={modalCancelOrderIsOpened} toggle={toggleModalCancelOrder}>
        <Form onSubmit={handleCancelItemModal} ref={cancelForm}>
          <ModalBody>
            <Input
              name="order_cancel_password"
              label="Senha de cancelamento"
              type="password"
            />
            <Input name="cancel_reason" label="Justificativa" />
          </ModalBody>
          <ModalFooter>
            <ButtonDefault message="Confirmar" type="submit" disabled={loading || loadingCancelItems} />
          </ModalFooter>
        </Form>
      </Modal>

      <Form onSubmit={handleChangeAmount}>

        <Row md="12">

          <Col md="5">
            <ItemName style={{ margin: 'auto' }}>
              <strong>{order?.product.name}</strong>
            </ItemName>
            {order?.complement_categories.map((category) => (
              <div key={category.id}>
                {/* <ComplementsTitle>
                  {category.complement_category.name}:
                </ComplementsTitle> */}
                {category.order_complements.map((complement) => (
                  <Complements key={complement.id}>
                    - {complement.amount} x {complement.complement.name}{' '}
                  </Complements>
                ))}
              </div>
            ))}
            {order?.details !== '' && <Obs>Obs: {order?.details}</Obs>}
          </Col>
          <Col md="3">
            {order.amount > 1 && (

              <Select
                name="amount"
                options={options}
                defaultValue={{ label: "0", value: 0 }}
              />
            )}
          </Col>
          <Col md="4">
            <DeleteAndEditActions>
              {orders.length > 1 ? (
                order.amount > 1 ? (
                  <PersonalButton type="submit" disabled={loading || loadingCancelItems} color="#ff2c3a" message="Cancelar" />
                ) : (
                  <PersonalButton
                    onClick={(e) => {
                      setLoadingCancelOrdemItem(true);
                      cancelOrderItem({
                        order_basket_id: item.basket.id,
                        order_id: order.id,
                        from,
                      });
                      setLoadingCancelOrdemItem(false);
                    }}
                    disabled={loadingCancelOrdemItem || loadingCancelItems}
                    type="button"
                    color="#ff2c3a"
                    message="Cancelar"
                  />
                )
              ) : order.amount > 1 ? (
                <PersonalButton type="submit" disabled={loading || loadingCancelItems} color="#ff2c3a" message="Cancelar" />

              ) : (
                <PersonalButton
                  color="#ff2c3a"
                  onClick={(e) => {
                    setLoadingCancelOrdemItem(true);
                    cancelOrderItem({
                      order_basket_id: item.basket.id,
                      order_id: order.id,
                      from,
                    });
                    setLoadingCancelOrdemItem(false);
                  }}
                  disabled={loadingCancelOrdemItem || loadingCancelItems}
                  message="Cancelar"
                  type="button"
                />
              )}


            </DeleteAndEditActions>
          </Col>
        </Row>
      </Form>

    </Item>
  );
}
