import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { toast } from 'react-hot-toast';

import {
  Pedido,
  Time,
  ContentBody,
  TableNumber,
  PhoneNumber,
  DeleteAndEditActions,
  CardColumnItem,
  CardRow,
} from './styles';

import formatTime from '~/services/formatTime';
import api from '~/services/api';
import { OrderButton, OrderStatusTag } from 'ui-kit-takeat';
import { FaEye, FaUtensils } from 'react-icons/fa';

export default function WaiterTicket({ item }) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  async function handleCheckTicket() {
    try {
      await api.put(`/restaurants/help-requests/check/${item.id}`);
      toast.success('Atendido com sucesso.');
    } catch (error) {
      toast.error('Erro ao atualizar chamado.');
    }
  }

  return (
    <Pedido>
      <ContentBody>
        <CardRow>
          <CardColumnItem onStart>
            {item?.command_table_number ? (
              <TableNumber style={{ marginLeft: 2 }}>
                Comanda {item?.table_number}/ Mesa {item?.command_table_number}
              </TableNumber>
            ) : (
              <TableNumber style={{ marginLeft: 2 }}>
                Mesa {item?.table_number}
              </TableNumber>
            )}
          </CardColumnItem>
          <CardColumnItem>
            {item?.buyer?.phone && (
              <PhoneNumber>{item?.buyer?.phone}</PhoneNumber>
            )}
          </CardColumnItem>
        </CardRow>
        <CardRow>
          <CardColumnItem onStart>
            {item?.buyer?.name && (
              <PhoneNumber style={{ marginLeft: 2 }}>
                {item?.buyer?.name}
              </PhoneNumber>
            )}
          </CardColumnItem>
          <Time>
            {item?.created_at
              ? formatTime(item?.created_at)
              : formatTime(item?.createdAt)}
          </Time>
        </CardRow>
        <CardRow>
          <div>
            <OrderStatusTag
              text="Pedido de ajuda"
              color="#FF2C3A"
              width="147px"
            />
          </div>

          <DeleteAndEditActions>
            <OrderButton
              icon={<FaUtensils />}
              color="#FF2C3A"
              title="Atendido"
              inverted
              onClick={handleCheckTicket}
            />

            <OrderButton icon={<FaEye />} color="#FFA814" onClick={toggle} />
          </DeleteAndEditActions>
        </CardRow>
      </ContentBody>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <h3>Mesa {item?.table_number}</h3>
          <p>
            {item?.buyer?.phone} {` - ${item?.buyer?.name}`}
          </p>
          <p>{item?.comment}</p>
        </ModalBody>
      </Modal>
    </Pedido>
  );
}
