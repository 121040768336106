import styled from 'styled-components';

export const PreCartContainer = styled.div`
  position: relative;
  width: 35%;
  padding: 16px 16px 0 16px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #cdcdcd;
`;

export const InfoArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: calc(100% - 72px);

  span {
    color: #666;
    font-size: 12px;
  }

  h2 {
    margin: 0;
    color: #4d4d4c;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }
`;

export const CategoriesArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll;
  padding-right: 5px;
`;

export const Footer = styled.footer`
  position: sticky;
  background-color: #fff;
  bottom: 0;
  padding: 16px 0;
  border-top: 1px solid #cdcdcd;
`;
