import { Table } from 'reactstrap';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 48px);
  overflow-y: auto;
  padding: 20px;
`;
export const Card = styled(Table)`
  width: 100%;
  min-width: 400px;
  align-items: center;
  border-collapse: separate;
  border-spacing: 0 0;
  background: #fff;

  border-radius: 8px;
  padding: 20px;
  margin: 0 auto;
  margin-top: 20px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    border-top: none;
    tr {
      th {
        button {
          border: none;
          background-color: transparent;
          font-weight: bold;
          color: #333;
        }
      }
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-top: 1px solid #33333310;
      padding: 10px 0 !important;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
`;

export const CategoryListItem = styled.div`
  width: 100%;

  align-items: center;
  border-bottom: 1px solid #33333310;
`;

export const CategoryListInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const ErrorContainer = styled.div`
  width: 60%;
  min-width: 600px;
  margin: 10px auto 30px auto;

  background: orange;
  color: #fff;

  border-radius: 8px;
  padding: 30px;

  p {
    text-align: center;
    font-size: 22px;
  }
`;
