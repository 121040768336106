import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 150px;
  align-self: stretch;

  ${(props) => props.grow && 'flex-grow: 1;'}
`;

export const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const CardColumnItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ onStart }) => (onStart ? 'flex-start' : 'flex-end')};
`;

export const Pedido = styled.div`
  width: 100%;
  height: 120px;
  border: solid #dee2e6 1px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px;
`;

export const Header = styled.div`
  height: 0px;
  width: 100%;
  display: flex;
  position: absolute;
`;

export const OrderNumber = styled.span`
  font-weight: bold;
`;

export const Time = styled.span`
  color: #33333390;
`;

export const OrdersInfo = styled.div`
  display: flex;
  padding: 20px;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;

    & + div {
      margin-left: 46px;
    }

    p {
      margin-top: 8px;
    }
  }
`;

export const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 5px;

  width: 100%;
`;

export const PedidoItems = styled.div`
  height: 30px;
  align-self: center;
  float: right;
  position: absolute;
  clear: both;
  left: 0;
  font-size: 1.3em;
  margin-top: 4px;
  margin-left: 10px;
  border: none;
  background: white;
`;

export const Eye = styled.button`
  height: 30px;
  background: white;
`;

export const Pencil = styled.button`
  height: 30px;
  align-self: center;
  float: right;
  position: absolute;
  clear: both;
  right: 0;
  margin-right: 50px;
  border: none;
  background: white;
`;

export const TableNumber = styled.span`
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  color: #333;

  @media (max-width: 410px) {
    margin-left: 130px;
    font-size: 12px;
  }
`;

export const PhoneNumber = styled.span`
  color: #33333390;

  @media (max-width: 410px) {
    margin-left: 130px;
    font-size: 12px;
  }
`;

export const DeleteAndEditActions = styled.div`
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;

export const AcceptButton = styled(Link)`
  border-radius: 8px;
  padding: 5px;
  width: 100px;

  color: white;
  align-self: center;
  margin-right: 0px;
  border: none;
  background: #28a745;
  font-size: 14px;
  display: flex;

  text-align: center;
  margin-bottom: 6px;

  svg {
    margin-right: 3px;
  }

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const SeeButton = styled(Link)`
  width: 100px;
  border-radius: 8px;
  padding: 5px;

  color: white;
  align-self: center;

  border: none;
  background: #17a2b8;
  font-size: 14px;
  display: flex;

  text-align: center;
  margin-bottom: 6px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }

  svg {
    margin-right: 3px;
  }
`;

export const CancelButton = styled.button`
  width: 100px;
  border-radius: 8px;
  padding: 5px;
  font-size: 14px;
  color: white;
  align-self: center;
  border: none;
  background: #ff403d;

  display: flex;
  text-align: center;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }

  :disabled {
    filter: opacity(50%);
  }

  div {
    margin: auto;
    text-align: center;
  }

  svg {
    margin-right: 3px;
  }
`;

export const Badge = styled.div`
  color: #fff;
  padding: 5px;
  border-radius: 7px;
  font-size: 12px;

  ${(props) =>
    props.type === 'newOrder' &&
    css`
      background-color: #ff403d;
    `};

  ${(props) =>
    props.type === 'orderDelivered' &&
    css`
      background-color: purple;
    `};

  ${(props) =>
    props.type === 'orderFinished' &&
    css`
      background-color: #7fd1b9;
    `};

  ${(props) =>
    props.type === 'orderCanceled' &&
    css`
      background-color: grey;
    `};

  ${(props) =>
    props.type === 'orderAccepted' &&
    css`
      background-color: #90c2e7;
    `};

  ${(props) =>
    props.type === 'waiterTicket' &&
    css`
      background-color: darkOrange;
    `};
`;
