import React from 'react';

import { StyledLink, Wrapper } from './styles';
import { ListGroup, ListGroupItem, Row } from 'reactstrap';

function ProductMenu() {
  return (
    <Wrapper>
      <div style={{ padding: 50 }}>
        <Row>
          <ListGroup style={{ margin: 'auto', fontSize: 20 }}>
            <ListGroupItem action>
              <StyledLink to="/products">Produtos</StyledLink>
            </ListGroupItem>
            <ListGroupItem action>
              <StyledLink to="/products/categories">Categorias</StyledLink>
            </ListGroupItem>
            <ListGroupItem action>
              <StyledLink to="/products/complement-categories">
                Categorias de Complementos
              </StyledLink>
            </ListGroupItem>
            <ListGroupItem action>
              <StyledLink to="/products/complements">Complementos</StyledLink>
            </ListGroupItem>
            <ListGroupItem action>
              <StyledLink to="/products/inputs">Estoque e Insumos</StyledLink>
            </ListGroupItem>
            <ListGroupItem action>
              <StyledLink to="/products/order-product-categories">
                Ordernar Cardápio
              </StyledLink>
            </ListGroupItem>
            <ListGroupItem action>
              <StyledLink to="/products/order-products">
                Ordernar Produtos
              </StyledLink>
            </ListGroupItem>
          </ListGroup>
        </Row>
      </div>
    </Wrapper>
  );
}

export default ProductMenu;
