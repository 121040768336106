import React, { useCallback, useEffect, useState } from 'react';
import { Container, FormArea } from './styles';
import Switch from 'react-switch';
import api from '~/services/api';
import toast from 'react-hot-toast';

function ChangeAcceptRestaurantTax({ session, getMinimalSessions }) {
  const [hasTaxState, setHasTaxState] = useState(!session?.has_tax);

  useEffect(() => {
    setHasTaxState(!session?.has_tax);
  }, [session.has_tax])
  const handleSubmit = useCallback(
    async (data) => {
      setHasTaxState(data);
      await api.put('restaurants/update-session-tax', {
        session_key: session?.key,
        has_tax: hasTaxState,
      }).then(() => {
        toast.success('Taxa de serviço atualizada com sucesso')
      }).catch(() => {
        toast.error('Erro ao atualizar taxa de serviço. Tente novamente.');
      }).finally(() => {
        // getMinimalSessions();
      })
    },
    [session, hasTaxState, getMinimalSessions]
  );

  return (
    <Container>
      {/* <Form onSubmit={handleSubmit}> */}
      <FormArea>
        <div>
          {/* <input */}
          {/*   type="checkbox" */}
          {/*   name="has_tax" */}
          {/*   checked={hasTaxState} */}
          {/*   onChange={() => { */}
          {/*     handleSubmit(!hasTaxState); */}
          {/*   }} */}
          {/* /> */}
          <Switch
            onChange={() => handleSubmit(!hasTaxState)}
            checked={!hasTaxState}
            checkedIcon={false}
            uncheckedIcon={false}
            height={16}
            width={32}
            handleDiameter={16}
            activeBoxShadow="0 0 2px 3px #111"
            boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
            onColor="#0CCAB4"
            offColor="#666666"
          />
          <h6>
            <strong>taxa de serviço</strong>
          </h6>
        </div>
        {/* <button type="submit">Salvar</button> */}
      </FormArea>
      {/* </Form> */}
    </Container>
  );
}

export default ChangeAcceptRestaurantTax;