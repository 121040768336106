import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { toast } from 'react-hot-toast';
import {
  format,
  startOfDay,
  endOfDay,
  endOfMonth,
  subHours,
  startOfWeek,
  startOfMonth,
  addHours,
  subMonths,
} from 'date-fns';
import { Form } from '@unform/web';
import { Input } from '@material-ui/core';
import { Collapse } from 'reactstrap';
import { useAuth } from '~/context/AuthContext';

import ClientsCard from '~/components/Reports/ClientsCard';
import RadarChart from '~/components/Reports/RadarChart';

import {
  Container,
  ClientsCardArea,
  DateDiv,
  DateSelectorDiv,
  HeaderCards,
  PieChart,
  HeaderLegend,
  A,
  SubmitDate,
  SelectDateButton,
  ButtonsSelectDate,
  ChartsArea,
  PieChartPayments,
} from './styles';

import ReportCard from '~/components/Reports/ReportCard';
import ReportChartPiePrincipal from '~/components/Reports/ReportChartPiePrincipal';
import formatToMonth from '~/services/formatToMonth';
import formatToYear from '~/services/formatToYear';

import api from '~/services/api';

import LineChart from '~/components/Reports/LineChart';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';

function ReportsPrincipal() {
  const [categories, setCategories] = useState([]);
  const [permission, setPermission] = useState();
  const [totais, setTotais] = useState([]);
  const [buyers, setBuyers] = useState(0);
  const [, setWaiters] = useState(0);
  const [productsState, setProductsState] = useState('');
  const [majorClient, setMajorClient] = useState('');
  const [, setMajorWaiter] = useState('');
  const [bills, setBills] = useState();
  const [categoriesName, setCategoriesName] = useState();
  const [categoriesOrderSold, setCategoriesOrderSold] = useState();
  const [ordersValueMonth, setOrdersValueMonth] = useState();
  const [totalPriceMonth, setTotalPriceMonth] = useState();
  const [paymentsCashier, setPaymentsCashier] = useState();
  const [totalBox, setTotalBox] = useState(0);
  const [totalWaiters, setTotalWaiters] = useState(0);

  const [loading, setLoading] = useState();

  const { user } = useAuth();
  const { has_service_tax } = user;

  const formRef = useRef(null);

  const defaultInicial = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const inicialFormat = format(new Date(defaultInicial), 'dd/MM/yyyy, HH:mm');
  const finalFormat = format(new Date(defaultFinal), 'dd/MM/yyyy, HH:mm');
  const phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [hourPhrase, setHourPhrase] = useState(phrase);

  function setToday() {
    setInicialDate(
      format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfDay(subHours(new Date(), 27)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setWeek() {
    setInicialDate(
      format(
        startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
        "yyyy-MM-dd'T'HH:mm:ss"
      )
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setMonth() {
    setInicialDate(
      format(startOfMonth(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfMonth(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  const [initialPre, setInitialPre] = useState(inicialDate);
  const [finalPre, setFinalPre] = useState(finalDate);

  function handleChangeDate() {
    setInicialDate(format(new Date(initialPre), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(new Date(finalPre), "yyyy-MM-dd'T'HH:mm:ss"));
    const inicialFormat = format(new Date(initialPre), 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(new Date(finalPre), 'dd/MM/yyyy, HH:mm');
    const phrase = `De ${inicialFormat} até ${finalFormat}`;

    setHourPhrase(phrase);
  }

  const [payments, setPayments] = useState([]);
  const [topClients, setTopClients] = useState([]);
  const [topWaiters, setTopWaiters] = useState([]);
  const [ratings, setRatings] = useState(0);

  const colors = [
    '#22aa99',
    '#289976',
    '#329262',
    '#5574a6',
    '#1F8A70',
    '#00796B',
    '#287D7D',
    '#2a778d',
    '#009688',
    '#22aa90',
    '#289977',
    '#329261',
    '#5574a7',
    '#1F8A71',
    '#00796C',
    '#287D7E',
    '#2a778e',
    '#009684',
    '#226666',
    '#669999',
    '#407F7F',
    '#0D4D4D',
    '#003333',
    '#27556C',
    '#6E91A1',
    '#477286',
    '#113C51',
    '#032536',
    '#68b684',
    '#a6e1fa',
    '#75dddd',
    '#84c7d0',
    '#04151f',
    '#183a37',
    '#0c7489',
    '#119da4',
    '#13505b',
  ];

  function random_color() {
    const cores = colors;
    const cor = cores[0];
    cores.splice(0, 1);
    return cor;
  }

  const CategorysToLegend = categories.map(function (categorie) {
    return {
      name: categorie.name,
      color: random_color(),
    };
  });

  const cores = [];
  CategorysToLegend.map((category) => cores.push(category.color));

  const getGroupedSessions = useCallback(async () => {
    const response = await api.get('restaurants/reports/grouped/sessions', {
      params: {
        period: 'month',
        timezone: 180,
      },
    });

    const totalPriceData = response.data.map((obj) => obj.total_price);

    const monthData = response.data.map(
      (obj) =>
        `${formatToMonth(obj.created_at)} /${formatToYear(obj.created_at)}`
    );

    setOrdersValueMonth(monthData);
    setTotalPriceMonth(totalPriceData);
  }, []);

  const getCategories = useCallback(async () => {
    try {
      const response = await api.get('restaurants/reports/orders', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });

      const categoriesFiltered = response.data.categoriesReport.filter(
        (cat) => cat.products.length > 0
      );

      const categoriesNameData = categoriesFiltered.map((cat) => cat.name);

      const categoriesOrderPriceData = categoriesFiltered.map(
        (cat) => cat.total_sold_orders_price
      );

      setCategoriesName(categoriesNameData);
      setCategoriesOrderSold(categoriesOrderPriceData);

      const productsDataArray = categoriesFiltered.map((cat) => cat.products);
      const productsArray = [];
      const products = [];

      productsDataArray.forEach((el) => productsArray.push(el));

      productsArray.map((prod) => products.push(...prod));

      const productsOrderByTotal = products.sort((a, b) => {
        return a.total_sold_product_price - b.total_sold_product_price;
      });

      setProductsState(productsOrderByTotal.pop());

      setCategories(response.data.categoriesReport);

      setTotais({
        total_restaurant_price: response.data.total_restaurant_service_price,
        total_without_service: response.data.total_restaurant_price,
        total_restaurant_amount: response.data.total_restaurant_amount,
      });
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [inicialDate, finalDate]);

  const getBills = useCallback(async () => {
    const response = await api.get('restaurants/reports/sessions', {
      params: {
        start_date: `${format(
          addHours(new Date(inicialDate), 3),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}`,
        end_date: `${format(
          addHours(new Date(finalDate), 3),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}`,
      },
    });

    setBills(response.data);
  }, [inicialDate, finalDate]);

  const getBuyers = useCallback(async () => {
    try {
      const response = await api.get('restaurants/reports/buyers', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });

      const buyersOrderedBySum = response.data.sort((a, b) => {
        return a.sum - b.sum;
      });

      const majorClientData = buyersOrderedBySum.slice(-1);

      setMajorClient(majorClientData[0]);

      setBuyers(response.data.length);

      const top5Clients = buyersOrderedBySum.reverse().slice(0, 5);

      setTopClients(top5Clients);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [inicialDate, finalDate]);

  const getWaiters = useCallback(async () => {
    try {
      const response = await api.get('restaurants/reports/waiters', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          limit: 5,
        },
      });

      const totalValueWaiter = response.data.reduce((acum, curr) => {
        return acum + Number(curr.sum);
      }, 0);

      setTotalWaiters(totalValueWaiter);

      const waitersOrderedBySum = response.data.sort((a, b) => {
        return a.sum - b.sum;
      });

      const majorWaioterData = waitersOrderedBySum.slice(-1);

      setMajorWaiter(majorWaioterData[0]);

      setWaiters(response.data.length);

      const top5Waiters = waitersOrderedBySum.reverse().slice(0, 5);

      setTopWaiters(top5Waiters);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [inicialDate, finalDate]);

  const getRatings = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/ratings', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });
      setRatings(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [inicialDate, finalDate]);

  const getPayments = useCallback(async () => {
    const response = await api.get('restaurants/reports/cashier', {
      params: {
        start_date: `${format(
          addHours(new Date(inicialDate), 3),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}`,
        end_date: `${format(
          addHours(new Date(finalDate), 3),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}`,
      },
    });

    setPaymentsCashier(response.data);

    const total = response.data.total_payment_methods_price;
    setTotalBox(total);

    const parsedData = response.data.paymentsReport.map((data) => ({
      id: data.name,
      label: data.name,
      value: data.total_payments_price,
      color: colors,
    }));
    setPayments(parsedData);
  }, [finalDate, inicialDate]);

  function setLastMonth() {
    const currMonth = new Date();

    const prevMonth = subMonths(currMonth, 1);
    const startOfMonthParsed = startOfMonth(prevMonth);
    const endOfMonthParsed = endOfMonth(prevMonth);

    setInicialDate(startOfMonthParsed);
    setFinalDate(endOfMonthParsed);

    const inicialFormat = format(startOfMonthParsed, 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(endOfMonthParsed, 'dd/MM/yyyy, HH:mm');
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  const validateUser = useCallback(async () => {
    try {
      setLoading(true);

      await api.post('/restaurants/users/permission');

      setPermission(true);

      setLoading(false);
    } catch (error) {
      setPermission(false);
      if (error.response.data.payload.user_access === 'pdv') {
        window.location.href = '/operation';
      }

      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    getGroupedSessions();
  }, [getGroupedSessions]);

  useEffect(() => {
    getPayments();
  }, [getPayments]);

  useEffect(() => {
    getBuyers();
  }, [getBuyers]);

  useEffect(() => {
    getRatings();
  }, [getRatings]);

  useEffect(() => {
    getBills();
  }, [getBills]);

  useEffect(() => {
    getWaiters();
  }, [getWaiters]);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  const [isOpen, setIsOpen] = useState(false);

  const rate = ratings.ratings_average ? ratings.ratings_average.toFixed(2) : 0;

  const comment_service_paid = `+ R$${paymentsCashier?.total_service_tax.toLocaleString(
    'pt-BR'
  )} em taxas de serviços`;

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <ButtonsSelectDate>
        <div>
          <SelectDateButton
            className="btn-round mr-auto"
            onClick={setToday}
            color="info"
          >
            Hoje
          </SelectDateButton>
          <SelectDateButton
            className="btn-round mr-auto"
            onClick={setWeek}
            color="info"
          >
            Essa semana
          </SelectDateButton>
          <SelectDateButton
            className="btn-round mr-auto"
            onClick={setMonth}
            color="info"
          >
            Mês atual
          </SelectDateButton>
          <SelectDateButton
            className="btn-round mr-auto"
            onClick={setLastMonth}
            color="info"
          >
            Mês anterior
          </SelectDateButton>
        </div>

        <div>
          <Collapse isOpen={isOpen}>
            <Form onSubmit={handleChangeDate} ref={formRef}>
              <DateSelectorDiv>
                <DateDiv>
                  <Input
                    id="datetime-local"
                    label="Data Inicial"
                    type="datetime-local"
                    name="initialDate"
                    onChange={(e) => setInitialPre(e.target.value)}
                    defaultValue={defaultInicial}
                    style={{fontSize: '1rem'}}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </DateDiv>
                <DateDiv>
                  <Input
                    id="datetime-local"
                    label="Data Final"
                    type="datetime-local"
                    name="finalDate"
                    onChange={(e) => setFinalPre(e.target.value)}
                    defaultValue={defaultFinal}
                    style={{fontSize: '1rem'}}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </DateDiv>
                <div>
                  <SubmitDate onClick="submit">Filtrar</SubmitDate>
                </div>
              </DateSelectorDiv>
            </Form>
          </Collapse>
        </div>

        <p>{hourPhrase}</p>
      </ButtonsSelectDate>

      <HeaderCards>
        <A href="/reports/products">
          <ReportCard
            name="Vendas Realizadas"
            total={paymentsCashier?.total_price_without_service_tax}
            totalNoService={totais.total_without_service}
            type="cashReport"
            color="#007C77"
            comment={has_service_tax && comment_service_paid}
          />
        </A>
        <A>
          <ReportCard
            name="Ticket Médio"
            total={bills?.avg_price}
            type="cash"
            color="#007C77"
          />
        </A>
        <A href="/reports/products">
          <ReportCard
            name="Total de Pedidos"
            total={totais.total_restaurant_amount}
            type="products"
            color="#007C77"
          />
        </A>

        <A>
          <ReportCard
            name="Produto mais vendido"
            total={productsState?.name}
            type="products"
            color="#007C77"
          />
        </A>

        <A>
          <ReportCard
            name="Total de clientes"
            total={buyers}
            type="clients"
            color="#007C77"
          />
        </A>
        <A>
          <ReportCard
            name="Maior cliente"
            total={majorClient?.sum}
            clientPhone={majorClient?.buyer?.phone}
            type="majorClient"
            color="#007C77"
          />
        </A>
        <A>
          <ReportCard
            name="Avaliações"
            type="rate"
            color="#007C77"
            rate={rate}
          />
        </A>
      </HeaderCards>

      <ChartsArea>
        <PieChart>
          <HeaderLegend>Vendas / Arrecadação (R$)</HeaderLegend>
          <LineChart
            data={{
              labels: ordersValueMonth,
              datasets: [
                {
                  label: 'Vendas (R$)',
                  data: totalPriceMonth,
                  backgroundColor: '#007C7770',
                  borderColor: '#007C77',
                  borderWidth: 1,
                  tension: 0.4,
                },
              ],
            }}
          />
        </PieChart>
        <PieChart>
          <HeaderLegend>Produtos (R$)</HeaderLegend>
          <RadarChart
            data={{
              labels: categoriesName,
              datasets: [
                {
                  label: 'Produtos (R$)',
                  data: categoriesOrderSold,
                  backgroundColor: '#007C7750',
                  borderColor: '#007C77',
                  borderWidth: 1,
                },
              ],
            }}
          />
        </PieChart>
      </ChartsArea>

      <ClientsCardArea>
        <ClientsCard
          headerText="Top 5 Clientes"
          data={topClients}
          type="client"
        />
        <ClientsCard
          headerText="Top 5 Garçons"
          data={topWaiters}
          type="waiter"
          initialDate={inicialDate}
          finalDate={finalDate}
          totalBox={totalBox}
          totalWaiters={totalWaiters}
        />
        <PieChartPayments>
          <HeaderLegend>Métodos de pagamento</HeaderLegend>
          <ReportChartPiePrincipal
            radius={0.5}
            data={payments}
            colors={cores}
          />
        </PieChartPayments>
      </ClientsCardArea>
    </Container>
  );
}

export default ReportsPrincipal;
