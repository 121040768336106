import React, { useCallback, useState } from 'react';
import { FiSearch } from 'react-icons/fi';

import { Spinner, Row, Col } from 'reactstrap';

import {
  CategoriesTable,
  Container,
  LoadingDiv,
  EmptyDiv,
  NavLinkSpan,
} from './styles';

import { useAuth } from '~/context/AuthContext';

import Bill from '~/components/Bill';
import Select from '../Form/SelectInput';
import { Form } from '@unform/web';
import Input from '../Form/Input';
import api from '~/services/api';
import toast from 'react-hot-toast';
import InputMask from 'react-input-mask';

export default function BillsDelivery({
  sessions,
  getSessions,
  loading,
  setSessions,
  handleUpdate,
}) {
  const { user } = useAuth();

  const selectOptions = [
    { label: 'Nº do Pedido Delivery', value: 'delivery' },
    { label: 'Telefone', value: 'phone' },
  ];

  const [searchType, setSearchType] = useState({
    label: 'Nº da Comanda',
    value: 'session',
  });
  const [searchInput, setSearchInput] = useState('');
  const [searchedSessions, setSearchedSessions] = useState(null);

  const submitSearch = useCallback(async (data) => {
    try {
      let id =
        data.filter_type === 'phone'
          ? data.filter_input
          : data.filter_input?.replaceAll('#', '').replaceAll(' ', '');
      const response = await api.get('/restaurants/sessions/search', {
        params: {
          type: data.filter_type,
          id,
        },
      });

      setSearchedSessions(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, []);

  return (
    <Container>
      <div>
        <NavLinkSpan>Pesquisar</NavLinkSpan>{' '}
      </div>

      <Form onSubmit={submitSearch}>
        <Row>
          <Col md="6" style={{ marginTop: 4 }}>
            <Select
              name="filter_type"
              options={selectOptions}
              defaultValue={selectOptions[0]}
              onChange={(e) => setSearchType(e)}
            />
          </Col>
          <Col md="4">
            {searchType?.value === 'phone' ? (
              <InputMask
                mask="(99) 99999-9999"
                onChange={(e) => {
                  setSearchInput(e.target.value);
                }}
                value={searchInput}
              >
                <Input placeholder="Busque pelo telefone" name="filter_input" />
              </InputMask>
            ) : (
              <Input
                name="filter_input"
                type="text"
                placeholder="Digite aqui..."
                onChange={(e) => setSearchInput(e.target.value)}
                value={searchInput}
              />
            )}
          </Col>
          <Col md="2">
            <button
              type="submit"
              style={{
                padding: '12px 24px',
                background: '#fff',
                color: '#fff',
                marginRight: 8,
                border: 'none',
                borderRadius: 8,
                marginTop: 6,
              }}
              title="Pesquisar"
            >
              <FiSearch size={21} color="black" />
            </button>
          </Col>
        </Row>

        {Array.isArray(searchedSessions) &&
          (searchedSessions.length > 0 ? (
            <CategoriesTable borderless>
              <thead>
                <tr>
                  <th>Mesa</th>
                  <th>Data/Hora</th>
                  <th>Nº</th>
                  <th>Valor</th>
                  <th>Já pago</th>
                  {/* {user.has_nfce && <th>NFCe</th>} */}
                  <th>Status</th>
                  <th>Ações</th>
                </tr>
              </thead>
              {loading ? (
                <tbody>
                  <tr>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td>
                      <LoadingDiv>
                        <Spinner color="success" />
                      </LoadingDiv>
                    </td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {searchedSessions.map((session) => (
                    <Bill
                      key={session.key}
                      session={session}
                      getSessions={getSessions}
                      // payments={payments}
                      // setAllPayments={setAllPayments}
                      // paymentMethods={paymentMethods}
                      setSessions={setSessions}
                      sessions={sessions}
                      has_nfce={user.has_nfce}
                      handleUpdate={handleUpdate}
                    />
                  ))}
                </tbody>
              )}
            </CategoriesTable>
          ) : (
            <EmptyDiv>
              <span>Não foi encontrada nenhuma comanda com esse filtro.</span>
            </EmptyDiv>
          ))}
      </Form>
    </Container>
  );
}
