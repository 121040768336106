import React, { useEffect, useState } from 'react';
import { ToReceiveModalStyles } from './styles';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import api from '~/services/api';
import { getStatusName, getTableNameByNumber } from '~/utils/getTableName';
import formatValue from '~/utils/formatValue';
import { format } from 'date-fns';
import { FilterTable, InformationDiv } from 'ui-kit-takeat';

export const ToReceiveModal = ({ isOpen, toggle }) => {
  const [tablesToReceive, setTablesToReceive] = useState([]);
  const [sortIndex, setSortIndex] = useState({ id: 5, type: 'desc' });

  const getSort = (a, b) => {
    if (sortIndex.id === 0) {
      return (
        (a.table_number - b.table_number) * (sortIndex.type === 'asc' ? 1 : -1)
      );
    }
    if (sortIndex.id === 1) {
      return (
        (a.to_receive - b.to_receive) * (sortIndex.type === 'asc' ? 1 : -1)
      );
    }
    if (sortIndex.id === 2) {
      return (
        (getStatusName(a.session_status) || 'ZZZ').localeCompare(
          getStatusName(b.session_status) || 'ZZZ'
        ) * (sortIndex.type === 'asc' ? 1 : -1)
      );
    }
    if (sortIndex.id === 3) {
      return (
        (parseFloat(a.total_service_price) -
          parseFloat(b.total_service_price)) *
        (sortIndex.type === 'asc' ? 1 : -1)
      );
    }
    if (sortIndex.id === 4) {
      return (a.paid - b.paid) * (sortIndex.type === 'asc' ? 1 : -1);
    }
    if (sortIndex.id === 5) {
      return (
        (a.last_purchase || 0)
          .toString()
          .localeCompare((b.last_purchase || 0).toString()) *
        (sortIndex.type === 'asc' ? 1 : -1)
      );
    }
  };

  const getTablesToReceive = async () => {
    const { data } = await api.get(
      '/restaurants/cashier-opening-event/to-receive'
    );

    setTablesToReceive(data);
  };

  useEffect(() => {
    if (isOpen) {
      getTablesToReceive();
    }
  }, [isOpen]);

  return (
    <ToReceiveModalStyles isOpen={isOpen} toggle={toggle}>
      <ModalHeader>
        <h2>A receber</h2>
        <InformationDiv
          containerStyles={{
            marginTop: 8,
            padding: '8px 12px',
            gap: 8,
          }}
          title=""
          type='info'
          text="São listadas mesas apenas no período de abertura do caixa atual."
        />
      </ModalHeader>
      <ModalBody>
        <FilterTable
          header={[
            'Mesa',
            'A receber',
            'Status',
            'Total',
            'Pago',
            'Último pedido',
          ]}
          alwaysLeft
          defaultFilter={{ id: 5, type: 'desc' }}
          clickableHeaders={[0, 1, 2, 3, 4, 5]}
          onChangeFilter={(filter) => setSortIndex(filter)}
        >
          {tablesToReceive
            .sort((a, b) => getSort(a, b))
            .map((table) => {
              return (
                <tr>
                  <td>{getTableNameByNumber(table.table_number, table.table_type)}</td>
                  <td>{formatValue(table.to_receive)}</td>
                  <td>{getStatusName(table.session_status)}</td>
                  <td>{formatValue(table.total_service_price)}</td>
                  <td>{formatValue(table.paid)}</td>
                  <td>
                    {format(new Date(table.last_purchase), 'dd/MM/yy - HH:mm')}
                  </td>
                </tr>
              );
            })}
        </FilterTable>
        {/* <table>
          <thead>
            <tr>
              <th>Mesa</th>
              <th>A receber</th>
              <th>Status</th>
              <th>Total</th>
              <th>Pago</th>
              <th>Último pedido</th>
            </tr>
          </thead>
          <tbody>
            {tablesToReceive.map((table) => {
              return (
                <tr>
                  <td>{getTableNameByNumber(table.table_number)}</td>
                  <td>{formatValue(table.to_receive)}</td>
                  <td>{getStatusName(table.session_status)}</td>
                  <td>{formatValue(table.total_service_price)}</td>
                  <td>{formatValue(table.paid)}</td>
                  <td>
                    {format(new Date(table.last_purchase), 'dd/MM/yy - HH:mm')}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table> */}
        {/* {tablesToReceive.map((table) => {
          return <ToReceiveItem item={table} />;
        })} */}
      </ModalBody>
      <ModalFooter></ModalFooter>
    </ToReceiveModalStyles>
  );
};