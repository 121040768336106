import { takeLatest, put, all } from 'redux-saga/effects';
import socketio from 'socket.io-client';

import Push from '~/services/Push';
import history from '~/services/history';

import { socketConnectionSuccess } from './actions';

export function* connectSocket({ payload }) {
  const { profile } = payload;

  const { id } = profile;

  const socket = socketio(`${process.env.REACT_APP_API_URL}`, {
    query: {
      user_id: id,
      restaurant_id: id,
    },
  });

  socket.on('notification', ({ notification, text }) => {
    // console.log('emitiu a notificação');
    Push.create('Solicitação de fechamento de conta', {
      body: text,
      onClick() {
        window.focus();
        this.close();
        history.push('/bills/close-solicitation');
      },
    });
  });
  yield put(socketConnectionSuccess(socket));
}

export default all([takeLatest('@socketio/CONNECTION_REQUEST', connectSocket)]);
