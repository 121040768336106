import React, { useState, useRef } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import { FiSearch, FiX } from 'react-icons/fi';
import { toast } from 'react-hot-toast';
import { Modal } from 'reactstrap';

import NotificationBadge from 'react-notification-badge/lib/components/NotificationBadge';
import { Effect } from 'react-notification-badge';
import { LoadingPage } from 'ui-kit-takeat';
import { FaArrowLeft } from 'react-icons/fa';
import { useCart } from '~/context/OrderingSystem/Cart';
import { useMenu } from '~/context/OrderingSystem/Menu';

import OrderingSystemProducts from '~/components/OrderingSystem/Products';

import CategoriesMenu from '~/components/OrderingSystem/Categories';

import logo_icon from '../../../assets/img/logo_icon.png';
import {
  Container,
  Content,
  MenuContent,
  Footer,
  ButtonGoToCart,
  ButtonAction,
  SearchBar,
  InputTableNumber,
  ButtonDeleteInput,
  ButtonSearchInput,
  ProductArea,
  ProductAreaHeader,
  ButtonGoBack,
  InnerArea,
} from './styles';
import { useBills } from '~/context/BillsContext';
import PaymentFormBalcony from '~/components/PaymentFormBalcony';
import { useAuth } from '~/context/AuthContext';
import Product from '../Product';

const Menu = ({
  tableId,
  getTablesAvailable,
  getSessions,
  session,
  setInputValue,
  setAllTablesAvailable,
  setTablesFilteredSearch,
  isTableDelivery,
  client,
  tableType,
  // paymentMethods,
  responseNfce,
  setResponseNfce,
  nfWarning,
  setNFWarning,
  setIsModalOperationOpened,
  handleUpdate,
  setAdded,
  toggleModalOperation,
  tableSession,
}) => {
  const { cart, addCartToOrder, closeCart } = useCart();
  const {
    handleChangeCategory,
    handleSearchInputProduct,
    productsFiltered,
    categoryState,
    setProductsFiltered,
    menu,
    getMenu,
  } = useMenu();
  const { getTableBills } = useBills();
  const { user } = useAuth();

  const inputProductRef = useRef(null);
  const [makingOrder, setMakingOrder] = useState(false);
  const [isModalPaymentsOpen, setIsModalPaymentsOpen] = useState(false);
  const [commandTableNumber, setCommandTableNumber] = useState('');
  const [commandTableNumberError, setCommandTableNumberError] = useState(false)

  function toggleModalPayments() {
    setIsModalPaymentsOpen(!isModalPaymentsOpen);
  }

  function handleInputChange(e) {
    setCommandTableNumber(e.target.value);
  }

  async function handleGoToCart() {
    if (!cart.some(basket => basket.amount > 0)) {
      toast.error("Adicione produtos ao carrinho");
      return;
    }


    if (tableType === 'balcony') {
      if (cart.length < 1) {
        toast.error('Carrinho vazio');
        return;
      } else {
        toggleModalPayments();
        return;
      }
    } else if (cart.length < 1) {
      toast.error('Carrinho vazio');
      return;
    } else {
      if (tableType === "command" && tableSession?.require_table_number && !commandTableNumber) {
        setCommandTableNumberError(true);
        toast.error("Informar o número ou local da mesa!");
        return;
      }
      setMakingOrder(true);
      if (client?.id) {
        const clientId = client.id;
        await addCartToOrder(
          tableId,
          clientId,
          'in_person',
          null,
          null,
          toggleModalOperation,
          null,
          true,
          commandTableNumber,
          getMenu
        );
      } else {
        await addCartToOrder(
          tableId,
          null,
          'in_person',
          null,
          null,
          toggleModalOperation,
          null,
          true,
          commandTableNumber,
          getMenu
        );
      }


      getTableBills(session);
      getTablesAvailable();
      setMakingOrder(false);
      setAllTablesAvailable([]);
      setInputValue('');
      setTablesFilteredSearch('');
      closeCart();
      getSessions();

    }
  }

  const cartAmount = cart.reduce((accum, curr) => accum + curr.amount, 0);
  const [isProductAreaOpen, setIsProductAreaOpen] = useState(false);
  const [productState, setProductState] = useState({});

  function toggleProductArea(product) {
    setIsProductAreaOpen(!isProductAreaOpen);

    setProductState(product);
  }

  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);

  return makingOrder ? (
    <LoadingPage duration={3} text="Fazendo seu pedido..." />
  ) : (
    <Container>

      <Modal
        isOpen={isModalPaymentsOpen}
        toggle={toggleModalPayments}
        size={user.has_nfce ? 'xl' : 'md'}
      >
        <InnerArea browserZoomLevel={browserZoomLevel}>
          <PaymentFormBalcony
            tableId={tableId}
            client={client}
            clientId={client?.id}
            addCartToOrder={addCartToOrder}
            setMakingOrder={setMakingOrder}
            toggleModalPayments={toggleModalPayments}
            getSessions={getSessions}
            responseNfce={responseNfce}
            setResponseNfce={setResponseNfce}
            nfWarning={nfWarning}
            setNFWarning={setNFWarning}
            setIsModalOperationOpened={setIsModalOperationOpened}
            handleUpdate={handleUpdate}
            commandTableNumber={commandTableNumber}
            session={session}
          />
        </InnerArea>
      </Modal>

      {
        isProductAreaOpen ? (
          <ProductArea>
            <ProductAreaHeader>
              <ButtonGoBack
                onClick={() => {
                  setIsProductAreaOpen(false);
                }}
              >
                <FaArrowLeft />
                <span>Voltar ao cardápio</span>
              </ButtonGoBack>
            </ProductAreaHeader>
            <Product
              product={productState}
              isTableDelivery={isTableDelivery}
              countProducts={1}
              setAdded={setAdded}
              closeProductModal={() => setIsProductAreaOpen(false)}
            />
          </ProductArea>
        ) : (
          <Content>
            <div>
              <SearchBar>
                <input
                  name="product"
                  type="text"
                  placeholder="Buscar produto"
                  style={{ height: 30 }}
                  onChange={(e) => {
                    handleSearchInputProduct(e.target.value, isTableDelivery);
                  }}
                  ref={inputProductRef}
                />

                <ButtonSearchInput
                  type="button"
                  title="Mostrar todos"
                  onClick={() => {
                    setProductsFiltered('');
                    inputProductRef.current.value = '';
                  }}
                >
                  <FiSearch size={14} color="rgba(251, 74, 32, 1)" />
                </ButtonSearchInput>

                <ButtonDeleteInput
                  type="button"
                  title="Mostrar todos"
                  onClick={() => {
                    setProductsFiltered('');
                    inputProductRef.current.value = '';
                  }}
                >
                  <FiX size={14} color="rgba(251, 74, 32, 1)" />
                </ButtonDeleteInput>
              </SearchBar>
            </div>

            <CategoriesMenu
              handleChangeCategory={handleChangeCategory}
              menu={menu}
              isTableDelivery={isTableDelivery}
            />

            {productsFiltered && productsFiltered?.length > 0 ? (
              <MenuContent>

                <OrderingSystemProducts
                  categoryState={productsFiltered}
                  isTableDelivery={isTableDelivery}
                  isProductFiltered
                  setAdded={setAdded}
                  toggleProductArea={toggleProductArea}
                />
              </MenuContent>
            ) : (
              <MenuContent>

                <OrderingSystemProducts
                  categoryState={categoryState}
                  isTableDelivery={isTableDelivery}
                  isProductFiltered={false}
                  setAdded={setAdded}
                  toggleProductArea={toggleProductArea}
                />
              </MenuContent>
            )}

            {!isTableDelivery && (
              <Footer>
                <div>
                  {tableType === 'command' && (
                    <InputTableNumber
                      maxLength={20}
                      value={commandTableNumber}
                      placeholder="Nº da mesa"
                      onChange={(e) => handleInputChange(e)}
                      commandTableNumberError={commandTableNumberError}
                    />
                  )}
                  <ButtonAction>
                    <ButtonGoToCart onClick={handleGoToCart}>
                      <img
                        src={logo_icon}
                        alt="logo da takeat"
                        style={{ left: '20px', position: 'absolute' }}
                      />
                      {/* {!!cart.length > 0 && <CountBadge>{cart.length}</CountBadge>} */}

                      <NotificationBadge
                        count={cartAmount}
                        effect={Effect.SCALE}
                        style={{
                          width: '25px',
                          height: '25px',
                          borderRadius: '12px',
                          position: 'absolute',
                          top: '10px',
                          paddingTop: '5px',
                          zIndex: '1000',
                        }}
                      />
                    </ButtonGoToCart>
                    <span>Pedir</span>
                  </ButtonAction>
                </div>
              </Footer>
            )}
          </Content>
        )
      }
    </Container >
  );
};

export default Menu;