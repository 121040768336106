import React, { useState, useRef, useEffect } from 'react';
import { useField } from '@unform/core';

import { MdInsertPhoto } from 'react-icons/md';
import { toast } from 'react-hot-toast';

import { Container, DefaultAvatar } from './styles';
import api from '~/services/api';

export default function ImageInput({
  name,
  product,
  products,
  setProducts,
  setProduct,
  complement,
  isComplement = false,
  setSelectedComplement = false,
  setCategories,
  allCategories,
  ...rest
}) {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue } = useField(name);

  const [fileId, setFileId] = useState(defaultValue && defaultValue.id);
  const [preview, setPreview] = useState(defaultValue && defaultValue.url);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'dataset.fileid',
      clearValue() {
        if (ref.value) {
          ref.value = '';
        }
        setPreview(null);
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (defaultValue) {
      setPreview(defaultValue.url);
      setFileId(defaultValue.id);
    }
  }, [defaultValue]);

  async function handleChange(e) {
    try {
      const data = new FormData();

      data.append('file', e.target.files[0]);

      const response = await api.post('restaurants/files', data);
      const { id, url, name: nameData, path, updatedAt } = response.data;

      setFileId(id);
      setPreview(url);

      if (!isComplement && !complement && products) {
        const productFind = products.findIndex(
          (prod) => prod.id === product.id
        );

        const newProductsArray = [...products];

        newProductsArray[productFind] = {
          ...product,
          image: {
            url,
            id,
            name: nameData,
            path,
            updatedAt,
          },
        };

        const newProductsState = [...newProductsArray];

        setProducts(newProductsState);
        setProduct({
          ...product,
          image: {
            url,
            id,
            name: nameData,
            path,
            updatedAt,
          },
        });
      }

      // if (isComplement) {
      //   const newCategories = [...products];

      //   newCategories.map((cat, idix) =>
      //     cat.complements.map((item, idex) => {
      //       if (item.id === product.id) {
      //         newCategories[idix].complements[idex] = {
      //           ...item,
      //           image: {
      //             url,
      //             id,
      //             name: nameData,
      //             path,
      //             updatedAt,
      //           },
      //         };
      //       }
      //     })
      //   );

      //   setCategories(newCategories);
      // }

      // if(isComplement){
      //   const newCategories = [...allCategories];

      //   newCategories.map((cat, idix) =>
      //     cat.complements.map((item, idex) => {
      //       if (item.id === complement.id) {
      //         newCategories[idix].complements[idex] = {...complement, image: {
      //                       url,
      //                       id,
      //                       name: nameData,
      //                       path,
      //                       updatedAt,
      //                     }};
      //       }
      //     })
      //   );
  
      //   setCategories(newCategories);
      //   }
    } catch (err) {
      toast.error('Erro ao atualizar imagem');
      console.log(err);
    }
  }

  return (
    <Container>
      <label htmlFor="avatar">
        {preview ? (
          <img src={preview} alt="User Avatar" />
        ) : (
          <DefaultAvatar>
            <MdInsertPhoto size={45} color="#ddd" />
            <span>Adicionar Foto</span>
          </DefaultAvatar>
        )}

        <input
          type="file"
          id="avatar"
          accept="image/*"
          data-fileid={fileId}
          onChange={handleChange}
          ref={ref}
          {...rest}
        />
      </label>
    </Container>
  );
}
