import styled from 'styled-components';

export const PrintDiv = styled.div`
  right: 0;
  display: flex;
  width: 100%;
  align-items: right;
  text-align: right;
  justify-content: center;
  clear: both;
  position: sticky;
  bottom: 0px;
  background-color: white;
  padding: 10px 0 24px;
`;

export const DisplayNoneImprimir = styled.button`
  display: none;
`;

export const DisplayImprimir = styled.div`
  width: 100%;
`;

export const ButtonPrint = styled.button`
  border: none;
  width: 100%;
  height: 40px;
  border-radius: 7px;
  color: #fff;
  background: #d60300;
`;
