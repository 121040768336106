import React, { useRef, useState, useCallback } from 'react';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';

import { Row, Col, Container, Button, ButtonGroup } from 'reactstrap';

import { FaQuestionCircle } from 'react-icons/fa';
import { Wrapper, Header, Card, Times, StyledForm } from './styles';

import api from '~/services/api';

import Input from '~/components/Form/Input';
import Checkbox from '~/components/Form/Checkbox';

import SaveButton from '~/components/Buttons/SaveButton';
import { useAuth } from '~/context/AuthContext';
import { useMenu } from '~/context/OrderingSystem/Menu';

function Store({ toggle, getCategories, category }) {
  const [startTime, setStartTime] = useState(() => {
    if (category.start_time) {
      const d = new Date(category.start_time);
      const hours = d.getHours().toString().padStart(2, '0');
      const mins = d.getMinutes().toString().padStart(2, '0');

      return `${hours}:${mins}`;
    }

    return null;
  });

  const [endTime, setEndTime] = useState(() => {
    if (category.end_time) {
      const d = new Date(category.end_time);
      const hours = d.getHours().toString().padStart(2, '0');
      const mins = d.getMinutes().toString().padStart(2, '0');

      return `${hours}:${mins}`;
    }

    return null;
  });

  function readDaysSelected(value) {
    const selected = [];

    for (let i = 0; i < 7; i++) {
      if (value[i] === 't') {
        selected.push(i);
      }
    }

    return selected;
  }

  function daysSelectedToStr(selected) {
    const days = [];

    for (let i = 0; i < 7; i++) {
      days.push(selected.includes(i) ? 't' : 'f');
    }

    return days.join('');
  }

  const [weekDays] = useState([
    'Dom',
    'Seg',
    'Ter',
    'Qua',
    'Qui',
    'Sex',
    'Sáb',
  ]);
  const [selectedDays, setSelectedDays] = useState(
    readDaysSelected(category.active_days)
  );

  const initialData = {
    name: category.name,
    time: category.preparation_time,
    available: category.available,
    is_exclusive: category.is_exclusive,
    available_in_delivery: category.available_in_delivery,
    startTime,
    endTime,
  };

  const { getMenu } = useMenu();

  const formRef = useRef(null);
  const { user } = useAuth();

  async function handleUpdate(data) {
    try {
      const start_time = data.startTime
        ? `2021-01-09T${data.startTime}:00-03:00`
        : null;
      const end_time = data.endTime
        ? `2021-01-09T${data.endTime}:00-03:00`
        : null;

      const schema = Yup.object().shape({
        name: Yup.string().required('O nome é obrigatório'),
        start_time: Yup.date('Data inicial inválida').nullable(),
        end_time: Yup.date('Data inicial inválida').nullable(),
      });

      await schema.validate(
        {
          name: data.name,
          start_time,
          end_time,
        },
        {
          abortEarly: false,
        }
      );

      await api.put(`/restaurants/product-category/${category.id}`, {
        name: data.name,
        preparation_time: data.time,
        available: data.available,
        available_in_delivery: data.available_in_delivery,
        is_exclusive: data.is_exclusive,
        start_time,
        end_time,
        active_days: daysSelectedToStr(selectedDays),
      });

      if (category) {
        category.name = data.name;
        category.preparation_time = data.time;
        category.available = data.available;
        category.available_in_delivery = data.available_in_delivery;
        category.is_exclusive = data.is_exclusive;
        category.start_time = start_time;
        category.end_time = end_time;
      }

      toast.success('Categoria atualizada com sucesso!');
      getMenu();
      getCategories();
      toggle();
    } catch (error) {
      if (error.response.data.errorType === 'only_one_time') {
        toast.error(
          `Favor preencher os tempos dos horários de início e fim das categorias disponíveis.`
        );
      } else {
        toast.error(`Erro ao atualizar categoria!`);
      }
    }
  }

  const onDaySelected = useCallback(
    (selected) => {
      const index = selectedDays.indexOf(selected);

      if (index < 0) {
        selectedDays.push(selected);
      } else {
        selectedDays.splice(index, 1);
      }

      setSelectedDays([...selectedDays]);
    },
    [selectedDays]
  );

  return (
    <Wrapper>
      <Header>
        <div>
          <h3>Editar categoria de produtos</h3>
        </div>
      </Header>
      <Container>
        <Row>
          <Col md="6 ml-auto mr-auto">
            <Card>
              <StyledForm
                initialData={initialData}
                ref={formRef}
                onSubmit={handleUpdate}
              >
                <Input
                  label="Nome"
                  name="name"
                  type="text"
                  placeholder="Nome da categoria"
                  disabled={category?.is_multistore_child || false}
                />
                <Input
                  label="Tempo de preparo"
                  name="time"
                  type="number"
                  placeholder="Tempo (em minutos)"
                />
                {!user?.only_delivery && (
                  <Checkbox label="Categoria disponível" name="available" />
                )}

                {user?.delivery_info?.is_delivery_allowed && (
                  <Checkbox
                    label="Categoria disponível no delivery"
                    name="available_in_delivery"
                  />
                )}

                <Checkbox
                  label="Uso exclusivo"
                  name="is_exclusive"
                  disabled={category?.is_multistore_child || false}
                />

                <div>
                  <b> Dias: </b>
                  <FaQuestionCircle
                    color="darkOrange"
                    title="Dias em que que essa categoria ficará disponível para o cliente"
                  />
                </div>

                <ButtonGroup style={{ margin: '10px 0' }}>
                  {weekDays.map((item, i) => (
                    <Button
                      color="info"
                      size="md"
                      tag="label"
                      className="btn-simple"
                      style={{
                        margin: '0 1px',
                        padding: '5px',
                        'font-size': '12pt',
                        filter: selectedDays.includes(i)
                          ? 'opacity(100%)'
                          : 'opacity(40%)',
                      }}
                      onClick={() => onDaySelected(i)}
                      active={selectedDays.includes(i)}
                    >
                      {item}
                    </Button>
                  ))}
                </ButtonGroup>

                <div style={{ marginBottom: -10 }}>
                  <b> Horário disponível: </b>

                  <FaQuestionCircle
                    color="darkOrange"
                    title="Determina a faixa de horário em que essa categoria ficará disponível para o cliente realizar o pedido"
                  />
                </div>

                {category && (
                  <Times>
                    <div> das </div>
                    <Input
                      type="time"
                      name="startTime"
                      disabled={category?.is_multistore_child || false}
                    />
                    <div> às </div>
                    <Input
                      type="time"
                      name="endTime"
                      disabled={category?.is_multistore_child || false}
                    />{' '}
                    horas.
                  </Times>
                )}

                <SaveButton
                  style={{ float: 'right', marginTop: '30px' }}
                  type="submit"
                >
                  Salvar
                </SaveButton>
              </StyledForm>
            </Card>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Store;
