import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  background-color: #f5f5f5;

  font-family: "Poppins";
`;

export const Header = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

export const Title = styled.h2`
  margin: 0;

  color: #666;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const Card = styled.div`
  width: 100%;
  padding: 10px;
  background: #fff;
  border-radius: 5px;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 480px;
  justify-content: flex-start;
  align-content: flex-start;
`;

export const SearchInput = styled.div`
  width: 310px;
  display: flex;
  padding: 10px 20px;
  align-items: center;
  gap: 20px;
  border-radius: 5px;
  border: 1px solid #CDCDCD;
  background-color: #fff;
  input {
    width: 100%;
    flex: 1;
    color: #6C6C6C;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    border: none;
  
    &::placeholder {
      color: #CDCDCD;
    }

    &:focus-visible {
      outline: none;
    }
  }
`;

export const SearchLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-decoration: underline;
  gap: 10px;

  &:hover {
    color: #666;
  }
`;