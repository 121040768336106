/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef, useCallback } from 'react';

import { Form } from '@unform/web';

import ReactToPrint from 'react-to-print';

import {
  FaCashRegister,
  FaExclamationCircle,
  FaEye,
  FaMinus,
  FaPercentage,
  FaPlus,
  FaPrint,
} from 'react-icons/fa';
import { BiTransfer } from 'react-icons/bi';
import { AiOutlinePrinter } from 'react-icons/ai';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import {
  Modal,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  Spinner,
} from 'reactstrap';
import PaymentForm from '~/components/PaymentForm';
import Input from '~/components/Form/Input';
import Select from '~/components/Form/SelectInput';
import ClientArea from './ClientArea';
import Cart from '~/components/OrderingSystem/Cart';

import SelectTable from '~/components/Form/SelectTable';
import FooterNF from '~/components/FooterNF';
import {
  Content,
  OperationArea,
  OrderSystemArea,
  BillTableArea,
  DisplayNoneImprimir,
  ButtonsArea,
  Print,
  CloseButton,
  ModalPaymentBody,
  TablePayment,
  ButtonAloneArea,
  AddDiscountObsButton,
  ButtonStyled,
  DivideArea,
  NumberFormatStyled,
  PeopleArea,
  DiscountInfoTable,
  InnerArea
} from './styles';
import { useAuth } from '~/context/AuthContext';
import { useCart } from '~/context/OrderingSystem/Cart';
import api from '~/services/api';
import Menu from '~/pages/OrderingSystem/Menu';
import EyeOrdersBillOperation from '~/components/Tables/EyeOrdersBillOperation';
import OrdersBillOperation from '~/components/Tables/Bills/OrdersBillOperation';
import PrintBillsTableOperation from '~/components/Prints/PrintBillsTableOperation';
import EyeBillsTableOperation from '~/components/Tables/CloseOrder/Eye/EyeBillsTableOperation';
import { useBills } from '~/context/BillsContext';
import getValidationErrors from '~/services/getValidationErrors';
import DivideBillsArea from '../DivideBillsArea';
import { ActionButton, Button, DefaultInput } from 'ui-kit-takeat';
import { ModalConfirmation } from '~/components/ModalConfirmation';
import formatValue from '~/utils/formatValue';
import { FiX } from "react-icons/fi";


function TableOperation({
  session,
  getMinimalSessions,
  setRenderState,
  // payments,
  // setAllPayments,
  paymentMethods,
  setMinimalSessions,
  inicialDate,
  finalDate,
  type,
  getTablesAvailable,
  updateOpeninBoxOperation,
  sessionId,
  minimalSessions,
  setAllTablesAvailable,
  setInputValue,
  setTablesFilteredSearch,
  handleSearchInputTables,
  hasFilteredSessions,
}) {
  const {
    user,
    restaurantDiscountObs,
    updateDiscountObs,
    setWaiterId,
    setBuyerCreatedByWaiter,
    setBuyerSelectedByWaiter,
  } = useAuth();
  const { loadingBills, peopleAtTable, updatePeopleAtTable } = useBills();
  const { closeCart, cart } = useCart();
  const [, setTableChanged] = useState(null);
  const [count, setCount] = useState('');
  const [modal, setModal] = useState(false);
  const [modalAdvice, setModalAdvice] = useState(false);
  const [hasOpenedBox, setHasOpenedBox] = useState(false);
  const [mouseEnter, setMouseEnter] = useState(false);

  const [productBills, setProductBills] = useState();
  const [rescues, setRescues] = useState(
    JSON.parse(localStorage.getItem('@gddashboard:rescues')) || []
  );

  const [peopleInput, setPeopleInput] = useState(peopleAtTable);
  const [oldInput, setOldInput] = useState(null);
  const [inputChanged, setInputChanged] = useState(false);

  const textColor = mouseEnter ? "#FF2C3A" : "#5E5E5E";
  const styleClose = {
    marginTop: "5px",
    color: textColor,
    cursor: "pointer"
  };

  useEffect(() => {
    localStorage.setItem('@gddashboard:rescues', JSON.stringify(rescues));
  }, [rescues]);

  const [isModalVerifyPasswordOpened, setIsModalVerifyPasswordOpened] =
    useState(false);

  function toggleModalAdvice() {
    setModalAdvice(!modalAdvice);
  }

  function isMouseIn() {
    setMouseEnter(!mouseEnter)
  }

  function updateBoxAndSession() {
    updateOpeninBoxOperation();

    if (hasFilteredSessions) {
      setAllTablesAvailable('');
      setInputValue('');
      setTablesFilteredSearch('');
      getMinimalSessions();
      getTablesAvailable();
    }
  }

  const [isModalOperationOpened, setIsModalOperationOpened] = useState(false);
  function toggleModalOperation() {
    if (cart.length > 0) {
      setModalAdvice(true);
    } else {
      setIsModalOperationOpened(!isModalOperationOpened);

      closeCart();
    }
  }

  function toggleModalOperationWithoutVeriryCart() {
    setIsModalOperationOpened(!isModalOperationOpened);
  }

  function toggleModalVerifyPassword() {
    if (isModalOperationOpened) {
      setIsModalVerifyPasswordOpened(false);
    } else {
      setIsModalVerifyPasswordOpened(!isModalVerifyPasswordOpened);
      setTimeout(() => {
        return document.getElementById('input_waiter_password')?.focus();
      }, 1);
    }
  }

  function confirmCloseCart() {
    setIsModalOperationOpened(!isModalOperationOpened);
    toggleModalAdvice();
    closeCart();
  }

  const [modalEye, setModalEye] = useState(false);
  function toggleEye() {
    setModalEye(!modalEye);
    getPayments();
  }

  const [modalEyeOpen, setModalEyeOpen] = useState(false);
  function toggleEyeOpen() {
    setModalEyeOpen(!modalEyeOpen);
  }

  const [isModalTransferPasswordOpen, setIsModalTransferPasswordOpen] =
    useState(false);

  const [transferDataState, setTransferDataState] = useState();

  const [modalTransfer, setModalTransfer] = useState(false);

  const [paid, setPaid] = useState(0);
  const [payments, setAllPayments] = useState([]);
  const [, setPorcent] = useState(0);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const [tables, setTables] = useState([]);

  const getTables = useCallback(async (id) => {
    const response = await api.get('/restaurants/tables');
    const tablesData = response.data;

    const tablesFiltered = tablesData.filter(
      (table) => table.table_type === 'table' || table.table_type === 'command'
    );

    const tablesOptions = [];

    tablesFiltered.forEach((table) => {
      let status;
      if (table.status === 'available') {
        status = 'Disponível';
      } else {
        status = 'Em Uso';
      }

      tablesOptions.push({
        value: table.id,
        label: `${table.table_number.toString()} - ${status}`,
      });
    });

    setTables(tablesOptions);
  }, []);

  async function printClick() {
    try {
      await api.post('restaurants/printers/printer-queue', {
        table_session_id: session.id,
        close_after_print: true,
      });
      const newSessions = minimalSessions.filter((s) => s.id !== session.id);
      setMinimalSessions(newSessions);
      getTablesAvailable();

      toast.success('Comanda impressa e fechada.');
    } catch (error) {
      toast.error('Erro ao imprimir e fechar comanda.');
      console.log(error);
    }
  }

  async function printClickNoCloseTable() {
    try {
      await api.post('restaurants/printers/printer-queue', {
        table_session_id: session.id,
        close_after_print: false,
      });

      toast.success('Comanda impressa');
    } catch (error) {
      toast.error('Erro ao imprimir comanda.');
    }
  }

  const [paymentsBill, setPaymentsBill] = useState();

  async function handlePrintItem({ session_key }) {
    try {
      await api.put('restaurants/close-table-bill', {
        key: session_key,
      });
      getMinimalSessions();
      getTablesAvailable();

      toast.success('Comanda fechada!');
    } catch (error) {
      toast.error('Não foi possível finalizar a comanda! tente novamente');
    }
  }

  const getPayments = useCallback(() => {
    let total = 0;

    paymentsBill &&
      paymentsBill.forEach((payment) => {
        total += parseFloat(payment.payment_value);
      });
    setPaid(total.toFixed(2).toString());

    const porcentPaid = (total / session?.total_service_price) * 100;
    setPorcent(isNaN(porcentPaid) ? 0 : parseFloat(porcentPaid.toFixed(2)));
  }, [paymentsBill, session]);

  useEffect(() => {
    try {
      getPayments();
    } catch (error) {
      toast.error('Erro ao carregar informações');
    }
  }, [getPayments]);

  const componentRef = useRef(null);

  const toggle = useCallback(() => {
    setModal(!modal);
    getPayments();
  }, [getPayments, modal]);

  async function toggleTransfer() {
    await getTables();
    setModalTransfer(!modalTransfer);
  }

  function toggleModalTransferPassword() {
    setIsModalTransferPasswordOpen(!isModalTransferPasswordOpen);
  }

  function handleTransferTable(data) {
    if (user.has_order_transfer_password) {
      toggleModalTransferPassword();
      setTransferDataState(data);
    } else {
      handleTransferTableBill(data);
    }
  }

  async function handleVerifyTransferPassword(data) {
    setIsSubmiting(true);
    const response = await api.post('/restaurants/verify-transfer', {
      order_transfer_password: data.order_transfer_password,
    });

    if (response.data.success) {
      handleTransferTableBill(transferDataState);
      toggleModalTransferPassword();
    } else {
      toast.error('Senha incorreta');
      setIsSubmiting(false);
    }
  }

  const [modalDiscount, setModalDiscount] = useState(false);
  function toggleDiscount() {
    setModalDiscount(!modalDiscount);
    setDiscountTotal(0);
    setDiscountPercent(0);
    setNewTotalPrice(session.total_price);
    setDiscountObs('');
    setDiscountObservation(false)
  }

  const [modalAddObsDiscount, setModalAddObsDiscount] = useState(false);
  function toggleAddObsDiscount() {
    setModalAddObsDiscount(!modalAddObsDiscount);
  }

  const [discountPercent, setDiscountPercent] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);
  const [newTotalPrice, setNewTotalPrice] = useState(session.total_price);
  const [discountSelection, setDiscountSelection] = useState('');
  const [discountObservation, setDiscountObservation] = useState(false);
  const [discountObs, setDiscountObs] = useState('');
  const [discountObsOptions, setDiscountObsOptions] = useState([]);
  const [isApplyingDiscount, setIsApplyingDiscount] = useState(false)
  const [isModalErrorUserPdvOpened, setIsModalUserPdvOpened] = useState(false);

  const toggleModalErrorUserPdv = useCallback(() => {
    setIsModalUserPdvOpened(!isModalErrorUserPdvOpened);
  }, [isModalErrorUserPdvOpened]);

  useEffect(() => {
    const obs_options = restaurantDiscountObs?.map((disc) => ({
      value: disc.id,
      label: disc.obs,
    }));
    setDiscountObsOptions([...obs_options, { value: 'other', label: 'Outro' }]);
  }, [restaurantDiscountObs]);

  useEffect(() => {
    setNewTotalPrice(session.total_price);
  }, [session.total_price]);

  async function handleAddObsDiscount(data) {
    try {
      await updateDiscountObs(data.obs);
      toast.success('Motivo cadastrado!');
      toggleAddObsDiscount();
    } catch (error) {
      toast.error('Erro ao cadastrar motivo.');
    }
  }

  async function handleDiscount(data) {
    try {
      setIsApplyingDiscount(true)
      if (
        user.order_cancel_password !== '' &&
        (data.cancel_password === '' || !data.cancel_password)
      ) {
        toast.error('Insira a senha administrativa!');
        return false;
      }

      await api.post('restaurants/discount', {
        session_key: session.key,
        discount_tax: discountPercent,
        obs: discountObservation ? `${discountObs} - ${discountObservation}` : discountObs,
        password: data.cancel_password,
      });
      getMinimalSessions();
      setInputValue('');
      setAllTablesAvailable('');
      setTablesFilteredSearch('');
      toast.success('Desconto aplicado!');
      toggleDiscount();
      setIsApplyingDiscount(false)
    } catch (error) {
      setIsApplyingDiscount(false)
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);

      } else {
        toast.error('Erro ao aplicar desconto');
      }
    }
  }

  async function handleCancelDiscount() {
    try {
      setIsApplyingDiscount(true)
      await api.post('restaurants/cancel-discount', {
        session_key: session.key,
      });
      getMinimalSessions();
      setDiscountTotal(0);
      setDiscountPercent(0);
      setInputValue('');
      setAllTablesAvailable('');
      setTablesFilteredSearch('');
      toast.success('Desconto removido!');
      toggleDiscount();
      setIsApplyingDiscount(false)
    } catch (error) {
      toast.error('Erro ao remover desconto');
      setIsApplyingDiscount(false)
    }
  }

  async function handleTransferTableBill(data) {
    try {
      setIsSubmiting(true);
      await api.put('restaurants/transfer-table-bill', {
        key: session.key,
        table_id: data.new_table_id,
      });

      setTableChanged(data.new_table_id);
      toggleTransfer();
      toggleModalOperation();
      getTablesAvailable();
      setInputValue('');
      setAllTablesAvailable('');
      setTablesFilteredSearch('');
      toast.success('Mesa transferida com sucesso!');
      setIsSubmiting(false);
    } catch (error) {
      toast.error('Erro ao transferir mesa.');
      setIsSubmiting(false);
      // console.log('error', error);
    }
  }

  const tableId = type !== 'available' ? session?.table?.id : session?.id;

  const isBalcony = session?.table?.table_type === 'balcony';
  const contentType = isBalcony && type === 'open' ? 'openBalcony' : type;

  useEffect(() => {
    const payments_bill = session?.payments;

    setPaymentsBill(payments_bill);
  }, [session]);

  useEffect(() => {
    const payments_bill = session?.payments;

    setAllPayments(payments_bill);
  }, [session]);

  const [printBills, setPrintBills] = useState([]);
  const [sessionBills, setSessionbills] = useState([]);
  const [commandTableNumber, setCommandTableNumber] = useState([]);
  const [billsState, setBillsState] = useState([]);
  const [bills, setBills] = useState(null)

  const getBillsSession = useCallback(async () => {
    const response = await api.get(
      `restaurants/table-sessions-bills/${session.id}`
    );
    const handlePrintBills = response.data.print_bills.map((pb) => {
      const rescues = response.data.bills.find((b) => b.id === pb.id).rescues;

      return { ...pb, rescues };
    });
    const handleBills = response.data.bills
      .map((pb) => pb.order_baskets.map((order) => order.orders))
      .flat(2);

    const handleBillsFiltered = handleBills.filter((bill) => bill.use_weight === true)

    setPrintBills(handlePrintBills);
    setSessionbills(response.data.session);
    setProductBills(response.data.products);
    setBills(handleBillsFiltered)

    const pending = response.data.bills.filter(
      (bill) => bill.status !== 'finished'
    );
    setBillsState(pending);

    const baskets = [];

    const basket = response.data.bills?.map((bill) =>
      bill.order_baskets.map((item) => baskets.push(item))
    );

    const sorted = baskets.sort((a, b) => {
      if (a.id < b.id) {
        return 1;
      }

      if (a.id > b.id) {
        return -1;
      }

      return 0;
    });

    setCommandTableNumber(sorted[0]);
  }, [session.id]);

  function searchTableName(table) {
    if (table.table_type === 'balcony') {
      return 'Balcão';
    }

    if (table.table_type === 'delivery') {
      return 'Delivery';
    }

    if (table.table_type === 'pos') {
      return 'POS';
    }

    if (table.table_number === -10) {
      return 'Mesa Copa';
    }

    if (table.table_type === 'command') {
      return `Comanda ${table.table_number}`;
    }

    return `Mesa ${table.table_number}`;
  }

  function verifyWaiterPassword() {
    setBuyerCreatedByWaiter('');
    setBuyerSelectedByWaiter('');
    if (user.ask_waiter_key && user?.is_pdv) {
      toggleModalVerifyPassword();
    } else {
      setIsModalOperationOpened(true);
    }
  }

  async function verifyWaiterPasswordAccepted(data) {
    try {
      const response = await api.get(
        `/restaurants/verify-waiter-key/${data.pdv_key}`
      );

      if (response.data.id) {
        setIsModalOperationOpened(true);
        setWaiterId(response.data.id);
        setIsModalVerifyPasswordOpened(false);
      }
    } catch (error) {
      toast.error('Senha invalida');
    }
  }

  const toggleModalHasOpenedBoxMessage = useCallback(() => {
    setHasOpenedBox(!hasOpenedBox);
  }, [hasOpenedBox]);

  // function searchTableName(table) {
  //   if (table.table_type === 'balcony') {
  //     return 'Balcão';
  //   }

  //   if (table.table_type === 'delivery') {
  //     return 'Delivery';
  //   }

  //   return table.table_number;
  // }

  const openingBoxRef = useRef();

  const verifyOpeningBox = useCallback(async () => {
    try {
      await api.get('/restaurants/cashier-opening-verify');

      toggle();
    } catch (err) {
      if (err.response?.data?.errorType === 'cashier_opening_not_found') {
        toggleModalHasOpenedBoxMessage();
      } else {
        toast.error('Erro ao carregar valores totais');
      }
    }
  }, [toggle, toggleModalHasOpenedBoxMessage]);

  const [isModalOnlineDepositsOpened, setIsModalOnlineDepositsOpened] =
    useState(false);
  const [onlineDeposits, setOnlineDeposits] = useState([]);

  const toggleOnlineDepositisModal = useCallback(() => {
    setIsModalOnlineDepositsOpened(!isModalOnlineDepositsOpened);
  }, [isModalOnlineDepositsOpened]);

  const openingBox = useCallback(
    async (data) => {
      if (!user?.is_pdv) {
        try {
          if (openingBoxRef?.current) {
            openingBoxRef.current.setErrors({});
          }

          const schema = Yup.object().shape({
            opening_value: Yup.string().required('Valor obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          const response = await api.post('/restaurants/cashier-opening', {
            initial_value: data.opening_value,
          });

          const { online_deposits } = response.data;
          setOnlineDeposits(online_deposits);

          if (online_deposits.length > 0) {
            toggleOnlineDepositisModal();
          }

          toggleModalHasOpenedBoxMessage();
          toggle();

          toast.success('Caixa aberto com sucesso.');
        } catch (error) {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            if (openingBoxRef?.current) {
              openingBoxRef.current.setErrors(errors);
            }
          } else if (error.response.data.errorType === 'cashier_already_open') {
            toast.error('Caixa já aberto');
          } else {
            toast.error('Erro ao abrir o caixa');
          }
        }
      } else {
        toggleModalErrorUserPdv();
      }
    },
    [
      toggleModalHasOpenedBoxMessage,
      toggle,
      user,
      toggleModalErrorUserPdv,
      toggleOnlineDepositisModal,
    ]
  );

  const [isDivideBillsAreaOpen, setIsDivideBillsAreaOpen] = useState(false);

  function currencyFormatter(value) {
    if (value === 0) {
      return '';
    }

    if (Number(value) === 1) {
      return `${value} pessoa`;
    } else {
      return `${value} pessoas`;
    }
  }

  const [isReport, setIsReport] = useState(false);

  useEffect(() => {
    setPeopleInput(peopleAtTable);
  }, [peopleAtTable]);

  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);

  return (
    <Content type={contentType} onClick={verifyWaiterPassword}>
      {type !== 'available' ? (
        <>
          <p>
            {isBalcony
              ? 'Balcão'
              : session?.table?.table_number === -10
                ? 'Copa'
                : session?.table?.table_number}
          </p>

          <TablePayment>
            <p>R$ {(session?.total_service_price - paid).toFixed(2)}</p>
          </TablePayment>
        </>
      ) : (
        <p>{isBalcony ? 'Balcão' : session?.table_number}</p>
      )}

      {/* <Modal isOpen={modalOpeningBox} toggle={toggleModalOpeningBox}>
        <Form onSubmit={openingBox} ref={openingBoxRef}>
          <ModalBody>
            <Input
              name="opening_value"
              label="Valor inicial no caixa (Dinheiro)"
              type="number"
              step="0.01"
              min="0"
            />
          </ModalBody>
          <ModalFooter>
            <ButtonStyled operationType="deposit" type="submit">
              Abrir caixa
            </ButtonStyled>
          </ModalFooter>
        </Form>
      </Modal> */}
      <Modal
        isOpen={isModalOnlineDepositsOpened}
        toggle={toggleOnlineDepositisModal}
      >
        <ModalBody>
          <strong>
            Os valores a seguir, que foram pagamentos online feitos enquanto seu
            caixa estava fechado, entrarão no caixa que você está abrindo:
          </strong>
          {onlineDeposits &&
            onlineDeposits.length > 0 &&
            onlineDeposits
              .filter((item) => item.total > 0)
              .map((deposit) => (
                <p>
                  {deposit.description} - R$ {deposit.total.toFixed(2)}
                </p>
              ))}
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-success"
            onClick={() => {
              toggleOnlineDepositisModal();
              setOnlineDeposits([]);
            }}
          >
            Ok, entendi
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={hasOpenedBox} toggle={toggleModalHasOpenedBoxMessage}>
        <Form onSubmit={openingBox} ref={openingBoxRef}>
          <ModalBody>
            <FaExclamationCircle color="darkOrange" size={32} />
            <h3>Você precisa abrir seu caixa para inserir pagamentos.</h3>

            <Input
              name="opening_value"
              label="Valor inicial no caixa (Dinheiro)"
              type="number"
              step="0.01"
              min="0"
            />
          </ModalBody>
          <ModalFooter>
            <ButtonStyled
              operationType="withdrawal"
              type="button"
              onClick={toggleModalHasOpenedBoxMessage}
            >
              Cancelar
            </ButtonStyled>
            <ButtonStyled operationType="deposit" type="submit">
              Abrir caixa
            </ButtonStyled>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={isModalErrorUserPdvOpened}
        toggle={toggleModalErrorUserPdv}
      >
        <ModalBody>
          Seu perfil de usuário não possui permissão de abrir caixa. Solicite ao
          seu administrador
        </ModalBody>
        <ModalFooter>
          <div style={{ width: '100%' }}>
            <ButtonStyled
              operationType="deposit"
              type="button"
              onClick={toggleModalErrorUserPdv}
              style={{ float: 'right' }}
            >
              Entendi, irei solicitar.
            </ButtonStyled>
          </div>
        </ModalFooter>
      </Modal>

      <ModalConfirmation
        isOpen={modalAdvice}
        toggle={toggleModalAdvice}
        title="Você possui itens no carrinho. Tem certeza que deseja sair sem fazer o pedido?"
        confirmText="Sim, sair e excluir o carrinho"
        cancelText="Irei fazer o pedido"
        confirm={confirmCloseCart}
        cancel={toggleModalAdvice}
      />

      <Modal
        isOpen={isModalVerifyPasswordOpened}
        toggle={toggleModalVerifyPassword}
      >
        <Form onSubmit={verifyWaiterPasswordAccepted}>
          <ModalBody>
            <Input
              label="Senha de acesso do garçom as mesas do PDV."
              name="pdv_key"
              type="password"
              id="input_waiter_password"
              maxlength={3}
              onChange={(e) => {
                if (e.target.value.length === 3) {
                  verifyWaiterPasswordAccepted({ pdv_key: e.target.value });
                }
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              title="Validar senha"
              buttonColor="#7fd1b9"
              onClick={() => { }}
            />
            <Button
              onClick={(e) => {
                e.preventDefault();
                toggleModalVerifyPassword();
              }}
              buttonColor="#dc3545"
              title="Cancelar"
            />
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={isModalOperationOpened}
        toggle={toggleModalOperation}
        size="takeat"
      >
        <ModalBody>
          <OperationArea browserZoomLevel={browserZoomLevel}>
            {isDivideBillsAreaOpen ? (
              <DivideArea browserZoomLevel={browserZoomLevel}>
                <DivideBillsArea
                  setIsDivideBillsAreaOpen={setIsDivideBillsAreaOpen}
                  session={session}
                  setSession={setMinimalSessions}
                  setPaid={setPaid}
                  setCount={setCount}
                  setAllPaymentsFather={setAllPayments}
                  setPaymentsBillFather={setPaymentsBill}
                  paymentsBill={paymentsBill}
                  handlePrintItem={handlePrintItem}
                  printClick={printClick}
                  getBillsSession={getBillsSession}
                  loadingBills={loadingBills}
                  setAllTablesAvailable={setAllTablesAvailable}
                  setTablesFilteredSearch={setTablesFilteredSearch}
                  isTableDelivery={false}
                  tableType={session?.table?.table_type}
                  setInputValue={setInputValue}
                  count={count}
                  printBills={printBills}
                  sessionBills={sessionBills}
                  billsState={billsState}
                  productBills={productBills}
                  commandTableNumber={commandTableNumber}
                />
              </DivideArea>
            ) : (
              <>
                <OrderSystemArea browserZoomLevel={browserZoomLevel}>
                  <Menu
                    tableId={tableId}
                    toggleModalOperation={toggleModalOperationWithoutVeriryCart}
                    getTablesAvailable={getTablesAvailable}
                    getSessions={getMinimalSessions}
                    session={sessionId}
                    setAllTablesAvailable={setAllTablesAvailable}
                    setInputValue={setInputValue}
                    setTablesFilteredSearch={setTablesFilteredSearch}
                    isTableDelivery={false}
                    tableType={session?.table?.table_type}
                    tableSession={session?.table}
                  />
                </OrderSystemArea>

                <BillTableArea browserZoomLevel={browserZoomLevel}>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <h2>{searchTableName(session?.table)}</h2>
                    <PeopleArea>
                      <ActionButton
                        icon={<FaMinus />}
                        width={30}
                        height={30}
                        buttonColor={peopleInput <= 1 && '#f6f6f6'}
                        pointerEvents={peopleInput <= 1 && 'none'}
                        onClick={() => {
                          if (Number(peopleInput) > 1) {
                            setPeopleInput(Number(peopleInput) - 1);
                            updatePeopleAtTable({
                              session_id: session.id,
                              people: Number(peopleInput) - 1,
                            });
                          }
                        }}
                      />
                      <NumberFormatStyled
                        format={currencyFormatter}
                        name="value"
                        value={Number(peopleInput)}
                        placeholder="Nº pessoas"
                        onValueChange={(e) => {
                          setPeopleInput(e.value);
                          if (e.value.length > 0) {
                            setInputChanged(true);
                          }
                        }}
                        onFocus={() => {
                          setInputChanged(false);
                          setOldInput(peopleInput);
                          setPeopleInput('');
                        }}
                        onBlur={() => {
                          if (!inputChanged) {
                            setPeopleInput(oldInput);
                          } else {
                            updatePeopleAtTable({
                              session_id: session.id,
                              people: Number(peopleInput),
                            });
                            setInputChanged(false);
                          }
                        }}
                      />
                      <ActionButton
                        icon={<FaPlus />}
                        width={30}
                        height={30}
                        buttonColor="#3BD2C1"
                        onClick={() => {
                          setPeopleInput(Number(peopleInput) + 1);
                          updatePeopleAtTable({
                            session_id: session.id,
                            people: Number(peopleInput) + 1,
                          });
                        }}
                      />
                    </PeopleArea>
                  </div>
                  <Cart
                    isTableDelivery={false}
                    isBalcony={false}
                    session={session}
                    isCommand={session?.table?.table_type === 'command'}
                  />
                  <EyeOrdersBillOperation
                    sessionId={sessionId}
                    inicialDate={inicialDate}
                    finalDate={finalDate}
                    setSessions={setMinimalSessions}
                    toggleEyeOpen={toggleEyeOpen}
                    getSessions={getMinimalSessions}
                    getTablesAvailable={getTablesAvailable}
                    session={session}
                    handleSearchInputTables={handleSearchInputTables}
                    setAllTablesAvailable={setAllTablesAvailable}
                    setInputValue={setInputValue}
                    setTablesFilteredSearch={setTablesFilteredSearch}
                    setIsDivideBillsAreaOpen={setIsDivideBillsAreaOpen}
                  />

                  <ClientArea
                    session_id={session?.id}
                    session={session}
                    getMinimalSessions={getMinimalSessions}
                  />
                  <ButtonsArea>
                    {user.is_printed_on_web || user.print_bills_on_web ? (
                      <ReactToPrint
                        onBeforeGetContent={async () => {
                          await getBillsSession();
                          setIsReport(false);
                        }}
                        trigger={
                          !loadingBills
                            ? () => (
                              <ButtonAloneArea
                                type="closing"
                                disabled={!session?.key}
                              >
                                <FaPrint size={22} color="#e9243e" />
                                Fechar
                              </ButtonAloneArea>
                            )
                            : () => <Spinner color="danger" type="grow" />
                        }
                        content={() => componentRef.current}
                        onAfterPrint={() => {
                          handlePrintItem({
                            session_key: session.key,
                          });
                          setAllTablesAvailable('');
                          setInputValue('');
                          setTablesFilteredSearch('');
                        }}
                      />
                    ) : (
                      <ButtonAloneArea
                        type="closing"
                        disabled={!session?.key}
                        onClick={() => {
                          printClick();
                          setIsReport(false);
                        }}
                      >
                        <FaPrint size={22} color="#e9243e" />
                        Fechar
                      </ButtonAloneArea>
                    )}
                    {!user?.is_pdv && (
                      <ButtonAloneArea
                        type="box"
                        onClick={verifyOpeningBox}
                        disabled={!session?.key}
                      >
                        <FaCashRegister size={22} color="#009f43" />

                        <span> Caixa</span>
                      </ButtonAloneArea>
                    )}

                    {user.is_printed_on_web || user.print_bills_on_web ? (
                      <ReactToPrint
                        onBeforeGetContent={async () => {
                          await getBillsSession();
                          setIsReport(true);
                        }}
                        trigger={() => (
                          <ButtonAloneArea
                            type="details"
                            disabled={!session?.key}
                          >
                            <FaEye size={22} color="#0099ad" />

                            <span>Espelho</span>
                          </ButtonAloneArea>
                        )}
                        content={() => componentRef.current}
                      />
                    ) : (
                      <ButtonAloneArea
                        type="details"
                        onClick={() => {
                          printClickNoCloseTable();
                          setIsReport(true);
                        }}
                        disabled={!session?.key}
                      >
                        <FaEye size={22} color="#0099ad" />

                        <span>Espelho</span>
                      </ButtonAloneArea>
                    )}

                    {!user?.is_pdv && (
                      <ButtonAloneArea
                        type="discount"
                        onClick={toggleDiscount}
                        disabled={!session?.key}
                      >
                        <FaPercentage size={22} color="#7f0071" />
                        <span>Desconto</span>
                      </ButtonAloneArea>
                    )}

                    <ButtonAloneArea
                      type="transfer"
                      onClick={toggleTransfer}
                      disabled={!session?.key || contentType === 'openBalcony'}
                    >
                      <BiTransfer size={22} color="#ffbb2f" />
                      <span style={{ textAlign: 'center', lineHeight: '14px' }}>
                        Transf. Mesa
                      </span>
                    </ButtonAloneArea>
                  </ButtonsArea>
                </BillTableArea>
              </>
            )}
          </OperationArea>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalDiscount}
        toggle={toggleDiscount}
        style={{ width: 600 }}
      >
        {session.old_total_price === null || !session.old_total_price ? (
          <>
            <Form onSubmit={handleDiscount} autoComplete="off">
              <ModalBody style={{ padding: 20 }}>
                <Row>
                  <Col>
                    <h3>Aplicar Desconto</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h6 style={{ textAlign: 'center' }}>
                      Desconto deve ser entre R$0,00 e{' '}
                      {formatValue(session.total_price)}
                    </h6>
                  </Col>
                </Row>
                <div
                  style={{
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gap: 10,
                  }}
                >
                  <Button
                    type="button"
                    buttonColor="#01AFFF"
                    textStyle={{ fontWeight: 700 }}
                    title="5%"
                    onClick={() => {
                      setDiscountPercent(5);
                      setDiscountTotal(
                        (parseFloat(session.total_price) * 0.05).toFixed(2)
                      );
                      setNewTotalPrice(
                        (parseFloat(session.total_price) * 0.95).toFixed(2)
                      );
                    }}
                  />
                  <Button
                    type="button"
                    buttonColor="#01AFFF"
                    textStyle={{ fontWeight: 700 }}
                    title="10%"
                    onClick={() => {
                      setDiscountPercent(10);
                      setDiscountTotal(
                        (parseFloat(session.total_price) * 0.1).toFixed(2)
                      );
                      setNewTotalPrice(
                        (parseFloat(session.total_price) * 0.9).toFixed(2)
                      );
                    }}
                  />
                  <Button
                    type="button"
                    buttonColor="#01AFFF"
                    textStyle={{ fontWeight: 700 }}
                    title="15%"
                    onClick={() => {
                      setDiscountPercent(15);
                      setDiscountTotal(
                        (parseFloat(session.total_price) * 0.15).toFixed(2)
                      );
                      setNewTotalPrice(
                        (parseFloat(session.total_price) * 0.85).toFixed(2)
                      );
                    }}
                  />
                  <Button
                    type="button"
                    buttonColor="#01AFFF"
                    textStyle={{ fontWeight: 700 }}
                    title="25%"
                    onClick={() => {
                      setDiscountPercent(25);
                      setDiscountTotal(
                        (parseFloat(session.total_price) * 0.25).toFixed(2)
                      );
                      setNewTotalPrice(
                        (parseFloat(session.total_price) * 0.75).toFixed(2)
                      );
                    }}
                  />
                  <Button
                    type="button"
                    buttonColor="#01AFFF"
                    textStyle={{ fontWeight: 700 }}
                    title="50%"
                    onClick={() => {
                      setDiscountPercent(50);
                      setDiscountTotal(
                        (parseFloat(session.total_price) * 0.5).toFixed(2)
                      );
                      setNewTotalPrice(
                        (parseFloat(session.total_price) * 0.5).toFixed(2)
                      );
                    }}
                  />
                  <Button
                    type="button"
                    buttonColor="#01AFFF"
                    textStyle={{ fontWeight: 700 }}
                    title="100%"
                    onClick={() => {
                      setDiscountPercent(100);
                      setDiscountTotal(
                        (parseFloat(session.total_price) * 1).toFixed(2)
                      );
                      setNewTotalPrice(
                        (parseFloat(session.total_price) * 0).toFixed(2)
                      );
                    }}
                  />
                </div>
                <Row>
                  <Col md="4" style={{ margin: 'auto' }}>
                    <Input
                      type="float"
                      placeholder="R$"
                      id="discount_tax"
                      name="discount_tax"
                      onChange={(e) => {
                        setDiscountTotal(e.target.value.replace(',', '.'));
                        if (e.target.value !== '') {
                          setDiscountPercent(
                            (parseFloat(e.target.value.replace(',', '.')) *
                              100) /
                            parseFloat(session.total_price)
                          );
                          setNewTotalPrice(
                            (
                              parseFloat(session.total_price) -
                              parseFloat(e.target.value.replace(',', '.'))
                            ).toFixed(2)
                          );
                        } else {
                          setDiscountPercent(0);
                          setDiscountTotal(0);
                          setNewTotalPrice(session.total_price);
                        }
                      }}
                    />
                  </Col>
                  <Col md="4" style={{ margin: 'auto' }}>
                    <Input
                      type="number"
                      step=".01"
                      placeholder="%"
                      id="discount_tax"
                      name="discount_tax"
                      onChange={(e) => {
                        setDiscountPercent(e.target.value);
                        if (e.target.value !== '') {
                          setDiscountTotal(
                            (
                              parseFloat(session.total_price) *
                              (e.target.value / 100)
                            ).toFixed(2)
                          );
                          setNewTotalPrice(
                            (
                              parseFloat(session.total_price) *
                              (1 - e.target.value / 100)
                            ).toFixed(2)
                          );
                        } else {
                          setDiscountPercent(0);
                          setDiscountTotal(0);
                          setNewTotalPrice(session.total_price);
                        }
                      }}
                    />
                  </Col>
                </Row>
                <DiscountInfoTable>
                  <div>
                    <span>Total antigo</span>
                    <span style={{ textAlign: 'center' }}>Desconto</span>
                    <span style={{ textAlign: 'center' }}>Novo total</span>
                    <span style={{ textAlign: 'right' }}>Com taxa</span>
                  </div>
                  <div>
                    <span>{formatValue(session.total_price)}</span>
                    <span style={{ textAlign: 'center' }}>
                      {formatValue(discountTotal)} |{' '}
                      {parseFloat(discountPercent).toLocaleString('pt-br', {
                        maximumFractionDigits: 2,
                      })}
                      %
                    </span>
                    <span style={{ textAlign: 'center' }}>
                      {formatValue(session.total_price - discountTotal)}
                    </span>
                    <span style={{ textAlign: 'right' }}>
                      {formatValue(session.total_service_price - discountTotal)}
                    </span>
                  </div>
                </DiscountInfoTable>
                <AddDiscountObsButton
                  type="button"
                  onClick={() => toggleAddObsDiscount()}
                >
                  Cadastrar Motivo
                </AddDiscountObsButton>
                <Select
                  label="Motivo: "
                  name="discount_obs"
                  options={discountObsOptions}
                  onChange={(e) => {
                    setDiscountSelection(e.value);
                    setDiscountObs(e.label);
                  }}
                />

                {discountSelection === 'other' && (
                  <Input
                    type="text"
                    placeholder="Escreva o motivo"
                    id="discount_obs"
                    name="discount_obs"
                    onChange={(e) => setDiscountObs(e.target.value)}
                  />
                )}
                <DefaultInput
                  type="text"
                  placeholder="Observação"
                  id="observation"
                  name="observation"
                  style={{ height: 40, padding: '7px 14px' }}
                  onChange={(e) => setDiscountObservation(e.target.value)}
                />
                {user.order_cancel_password !== '' && (
                  <Row style={{ marginTop: 10 }}>
                    <Col md="12">
                      <Input
                        label="Senha administrativa:"
                        id="cancel_password"
                        name="cancel_password"
                        type="password"
                        autocomplete="new-password"
                      />
                    </Col>
                  </Row>
                )}
              </ModalBody>
              <ModalFooter
                style={{
                  position: 'sticky',
                  bottom: 0,
                  background: '#ffffff',
                }}
              >
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    toggleDiscount();
                  }}
                  title="Cancelar"
                />
                <Button
                  type="submit"
                  title="Aplicar Desconto"
                  buttonColor="#2EC9B7"
                  onClick={() => { }}
                  disabled={
                    discountPercent > 100 ||
                    discountPercent === 0 ||
                    discountObs === '' ||
                    isApplyingDiscount
                  }
                />
              </ModalFooter>
            </Form>
          </>
        ) : (
          <>
            <Form onSubmit={handleCancelDiscount}>
              <ModalBody>
                <Row>
                  <Col>
                    <h3 style={{ margin: 0 }}>Desconto Aplicado</h3>
                  </Col>
                </Row>
                <Row style={{ marginTop: 30 }}>
                  <Col md="4">
                    <h4>{session.discount_percent}%</h4>
                    <p>Desconto aplicado</p>
                  </Col>
                  <Col md="4">
                    <h4>R${session.discount_total}</h4>
                    <p>Desconto aplicado</p>
                  </Col>
                  <Col md="4">
                    <h4>
                      R$
                      {(
                        parseFloat(session.total_price) +
                        parseFloat(session.discount_total)
                      ).toFixed(2)}
                    </h4>
                    <p>Valor da comanda sem desconto</p>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <p>
                      <strong>Motivo:</strong> {session.discount_obs}
                    </p>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="submit"
                  title="Cancelar desconto"
                  onClick={() => { }}
                  inverted
                  disabled={isApplyingDiscount}
                />
                <Button
                  type="button"
                  onClick={() => toggleDiscount()}
                  title="Fechar"
                />
              </ModalFooter>
            </Form>
          </>
        )}
      </Modal>

      <Modal isOpen={modalAddObsDiscount} toggle={toggleAddObsDiscount}>
        <Form onSubmit={handleAddObsDiscount}>
          <ModalBody>
            <h4>Adicionar Motivo</h4>
            <Row>
              <Col md="12">
                <Input
                  type="text"
                  placeholder="Escreva o motivo"
                  id="obs"
                  name="obs"
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              title="Adicionar motivo"
              onClick={() => { }}
              buttonColor="#2ec9b7"
            />
            <Button onClick={toggleAddObsDiscount} title="Fechar" />
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={modal}
        toggle={toggle}
        size="lg"
        onClosed={updateBoxAndSession}

      >
        <InnerArea browserZoomLevel={browserZoomLevel}>
          <div style={{ display: "flex" }}>
            <ModalPaymentBody>
              <PaymentForm
                session={session}
                getSessions={getMinimalSessions}
                payments_bill={paymentsBill}
                setPaymentsBill={setPaymentsBill}
                all_payments={payments}
                getPaymentsBill={getPayments}
                setAllPayments={setAllPayments}
                paymentMethods={paymentMethods}
                setCount={setCount}
                hasFilteredSessions={hasFilteredSessions}
                rescues={rescues}
                setRescues={setRescues}
              />
              <OrdersBillOperation session={session} isOpen={modal} />
            </ModalPaymentBody>

            <FiX size={30} style={styleClose} onMouseEnter={isMouseIn} onMouseLeave={isMouseIn} onClick={verifyOpeningBox} />
          </div>
          <FooterNF
            getBillsSession={getBillsSession}
            loadingBills={loadingBills}
            session={session}
            handlePrintItem={handlePrintItem}
            setAllTablesAvailable={setAllTablesAvailable}
            setInputValue={setInputValue}
            setTablesFilteredSearch={setTablesFilteredSearch}
            printClick={printClick}
            toggleEye={toggleEye}
            has_nfce={user.has_nfce}
            payments={payments}
            componentRef={componentRef}
            getSessions={getMinimalSessions}
            rescues={rescues}
            setRescues={setRescues}
          />

        </InnerArea>
      </Modal>
      <Modal isOpen={modalEyeOpen} toggle={toggleEyeOpen}>
        <ModalBody>
          <Container>
            <EyeOrdersBillOperation
              session={session}
              inicialDate={inicialDate}
              finalDate={finalDate}
              setSessions={setMinimalSessions}
              toggleEyeOpen={toggleEyeOpen}
              getSessions={getMinimalSessions}
              handleSearchInputTables={handleSearchInputTables}
              setAllTablesAvailable={setAllTablesAvailable}
              setInputValue={setInputValue}
              setTablesFilteredSearch={setTablesFilteredSearch}
            />
          </Container>
        </ModalBody>
        <ModalFooter>
          {user.is_printed_on_web || user.print_bills_on_web ? (
            <ReactToPrint
              onBeforeGetContent={async () => {
                await getBillsSession();
              }}
              trigger={() => (
                <CloseButton>
                  <div style={{ fontSize: 15 }}>
                    <AiOutlinePrinter size={14} style={{ marginRight: 5 }} />
                    Imprimir
                  </div>
                </CloseButton>
              )}
              content={() => componentRef.current}
            />
          ) : (
            <Print onClick={printClickNoCloseTable}>
              <AiOutlinePrinter color="black" size={20} />
            </Print>
          )}
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={isModalTransferPasswordOpen}
        toggle={toggleModalTransferPassword}
      >
        <Form onSubmit={handleVerifyTransferPassword}>
          <ModalBody>
            <Input
              name="order_transfer_password"
              label="Senha de Transferência"
              type="password"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={toggleModalTransferPassword}
              title="Cancelar"
            />
            <Button
              type="submit"
              buttonColor="#2ec9b7"
              onClick={() => { }}
              disabled={isSubmiting}
              title="Transferir"
            />
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modalTransfer} toggle={toggleTransfer}>
        <Form onSubmit={handleTransferTable}>
          <ModalBody style={{ padding: 20 }}>
            <h3 style={{ fontSize: 20, fontWeight: 700, marginBottom: 10 }}>
              Transferência de Mesa
            </h3>
            {tables.length !== 0 ? (
              <div>
                <h6 style={{ fontSize: 16 }}>
                  Mesa atual: <strong>{session?.table?.table_number}</strong>
                </h6>
                <SelectTable
                  name="new_table_id"
                  label="Nova mesa:"
                  options={tables.filter((a) => a.value !== session?.table?.id)}
                />
              </div>
            ) : (
              <p style={{ color: 'red' }}>
                Não há nenhuma mesa disponível no momento.
              </p>
            )}
          </ModalBody>
          <ModalFooter style={{ padding: '0 20px 20px' }}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                toggleTransfer();
              }}
              title="Cancelar"
            />
            {tables.length !== 0 && (
              <Button
                type="submit"
                title="Transferir"
                onClick={() => { }}
                buttonColor="#2EC9B7"
                disabled={isSubmiting}
              />
            )}
          </ModalFooter>
        </Form>
      </Modal>
      <DisplayNoneImprimir>
        <PrintBillsTableOperation
          item={session}
          restaurant={user}
          ref={componentRef}
          paymentData={payments}
          printBills={printBills}
          sessionBills={sessionBills}
          count={count}
          productBills={productBills}
          commandTableNumber={commandTableNumber}
          report={isReport}
          bills={bills}
        />
      </DisplayNoneImprimir>
      <Modal
        isOpen={modalEye}
        toggle={toggleEye}
        size="lg"
        onClosed={updateOpeninBoxOperation}
      >
        <InnerArea browserZoomLevel={browserZoomLevel}>
          <ModalBody>
            <ModalPaymentBody>
              {/* <EyePaymentBills session={session} restaurant={user} /> */}
              <PaymentForm
                session={session}
                payments_bill={paymentsBill}
                setPaymentsBill={setPaymentsBill}
                all_payments={payments}
                getPaymentsBill={getPayments}
                setAllPayments={setAllPayments}
                paymentMethods={paymentMethods}
                setCount={setCount}
                getSessions={getMinimalSessions}
                setRenderState={setRenderState}
                setRescues={setRescues}
              />
              <EyeBillsTableOperation
                item={session}
                payments_bill={paymentsBill}
                count={count}
              />
              {/* <OrdersBill session={session} /> */}
            </ModalPaymentBody>
          </ModalBody>
          {session.status === 'finished' && (
            <ModalFooter>
              <FooterNF
                session={session}
                toggleEye={toggleEye}
                getSessions={getMinimalSessions}
                payments={payments}
                has_nfce={user.has_nfce}
                getTablesAvailable={getTablesAvailable}
              />
            </ModalFooter>
          )}

        </InnerArea>
      </Modal>
    </Content>
  );
}

export default TableOperation;