import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  width: 30%;
  display: flex;
  flex-direction: column;
  border: 1px solid #ededed;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  overflow-y: auto;
  flex-grow: 1;

  span#addClient {
    color: #2ec9b7;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
  }
`;

export const AddClientDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const DisplayNone = styled.div`
  display:none;
`

export const OrderArea = styled.div`
  span {
    font-weight: 700;
    font-size: 16px;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background-color: white;
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  gap: 16px;

  div {
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }
`;
