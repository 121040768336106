import React from 'react';
import { ComplementsSelectionContainer, GridArea, Title, Badge, SubTitle, TitleContainer } from './styles';
import { ComplementButton } from '../ComplementButton';

export const ComplementsSelection = ({ category }) => {
  const getPhrase = () => {
    if (category?.optional && !category?.limit) {
      return 'Escolha os complementos desejados';
    }
    if (category?.optional && category?.limit) {
      return `Escolha até ${category?.limit} complementos`;
    }
    if (category?.limit && category?.minimum) {
      return `Escolha entre ${category?.minimum} e ${category?.limit} complementos`;
    }
    if (category?.limit && !category?.minimum) {
      return `Escolha até ${category?.limit} complementos`;
    }
    if (!category?.limit && category?.minimum) {
      return `Escolha pelo menos ${category?.minimum} complementos`;
    }
    return 'Escolha os complementos desejados';
  };

  return (
    <ComplementsSelectionContainer>
      <TitleContainer>
        {!category?.optional && (
          <Badge>Obrigatório</Badge>
        )}
        <Title>{getPhrase()}</Title>
        {category?.more_expensive_only && (
          <SubTitle>Apenas o valor do complemento mais caro será cobrado</SubTitle>
        )}
        {category?.use_average && (
          <SubTitle>O valor da média dos complementos será adicionado</SubTitle>
        )}
      </TitleContainer>
      <GridArea>
        {category?.complements
          .filter((complement) => complement.available)
          .map((complement) => {
            return (
              <ComplementButton category={category} complement={complement} />
            );
          })}
      </GridArea>
    </ComplementsSelectionContainer>
  );
};