/* eslint-disable react/prop-types */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';

import {
  Row,
  Col,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Modal,
} from 'reactstrap';
import InputMask from 'react-input-mask';

import { FaCheck, FaQuestionCircle, FaTrash } from 'react-icons/fa';
import { format } from 'date-fns';
import { MdDelete } from 'react-icons/md';
import {
  Container,
  ButtonsArea,
  ErrorContainer,
  InputsTable,
  NumberFormatStyled,
  CmvButtons,
  SubmitStyledButton,
  SelectStyled,
  InputStyled,
  ButtonAdd,
  Delete,
  NumberFormatStyledValue,
  PersonalizedHoursContainer,
} from './styles';

import api from '~/services/api';
import { useAuth } from '~/context/AuthContext';

import nfeInfo from '~/services/NFeInfo';

import CheckboxInput from '~/components/Form/Checkbox';
import Input from '~/components/Form/Input';

import Select from '~/components/Form/SelectInput';
import { useMenu } from '~/context/OrderingSystem/Menu';
import ImageInput from '~/components/Form/ImageInput';
import { useIfood } from '~/context/iFoodContext';
import getValidationErrors from '~/services/getValidationErrors';
import PersonalButton from '~/components/Buttons/PersonalButton';
import OutlineButton from '~/components/Buttons/OutlineButton';
import NFeInfo from '~/services/NFeInfo';
import formatValue from '~/utils/formatValue';
import { TextareaContainer } from '~/components/Form/styles';
import Textarea from '~/components/Form/Textarea';
import { DefaultInput, Button } from 'ui-kit-takeat';

function CreateComplementInsideProduct({
  complementsCategories,
  setComplementsCategories,
  toggleModalCrerateComplement,
  category,
  setComplementCategoriesInsideProduct,
  complementCategoriesInsideProduct,
  fiscalGroups,
}) {
  const formRefComplement = useRef(null);
  const { user } = useAuth();
  const { getMenu } = useMenu();
  const { ifoodComplements, getIfoodComplements } = useIfood();
  const [complement, setComplement] = useState({});
  const [complementFiscal, setComplementFiscal] = useState({});
  const [modalInput, setModalInput] = useState(false);
  const [createCashFlowOnNewInput, setCreateCashFlowOnNewInput] =
    useState(true);

  const [enableTimes, setEnableTimes] = useState(false);
  const [daysSelected, setDaysSelected] = useState([
    't',
    't',
    't',
    't',
    't',
    't',
    't',
  ]);
  const [hourSelected, setHourSelected] = useState({
    start: '',
    end: '',
  });

  const handleChangeDay = (idx) => {
    const updatedDays = [...daysSelected];
    updatedDays[idx] = updatedDays[idx] === 'f' ? 't' : 'f';
    setDaysSelected(updatedDays);
  };

  function handleCreateCashFlowOnNewInput() {
    if (createCashFlowOnNewInput === true) {
      setCreateCashFlowOnNewInput(false);
    } else {
      setCreateCashFlowOnNewInput(true);
    }
  }
  const toggleModalInput = () => {
    setModalInput(!modalInput);
    setCreateCashFlowOnNewInput(true);
  };

  const [modalIntermediary, setModalIntermediary] = useState(false);
  const toggleIntermediary = () => setModalIntermediary(!modalIntermediary);
  const [paidCheck, setPaidCheck] = useState(false);

  function handleSetPaidCheck() {
    setPaidCheck(!paidCheck);
  }

  const icms_origem_options = nfeInfo.icms_origem.map((icms) => ({
    value: icms.value,
    label: icms.description,
  }));

  const csosn_options = nfeInfo.csosn.map((c) => ({
    value: c.csosn,
    label: `${c.csosn} - ${c.description}`,
  }));

  const base_calculo_options = nfeInfo.base_calculo.map((c) => ({
    value: c.value,
    label: c.description,
  }));

  const [csosnSelected, setCsosnSelected] = useState('');
  const [cfopOptions, setCfopOptions] = useState([]);

  useEffect(() => {
    const cfopSelect = nfeInfo.csosn.filter((cs) => cs.csosn === csosnSelected);
    const cfop = [];

    cfopSelect.map((selected) =>
      selected.cfop?.forEach((cf) => {
        const cfopDesc = nfeInfo.cfopDescription.filter(
          (cfop) => cfop.cfop === cf
        );
        console.log('cfopdesc', cfopDesc);
        cfop.push({
          value: cfopDesc[0].cfop,
          label: `${cfopDesc[0].cfop} - ${cfopDesc[0].description}`,
        });
      })
    );

    setCfopOptions(cfop);
  }, [csosnSelected]);

  // regime simples
  const [icmsOrigemValue, setIcmsOrigemValue] = useState(
    icms_origem_options.find((x) => x.value === complement.icms_origem)
  );
  const [csosnValue, setCsosnValue] = useState(
    csosn_options.find((x) => x.value === complement.csosn)
  );
  const [cfopValue, setCfopValue] = useState(
    NFeInfo.cfop_options.find((x) => x.value === complement.cfop)
  );
  const [cofinsValue, setCofinsValue] = useState(
    NFeInfo.cofins_options.find((x) => x.value === complement.cofins)
  );
  const [pisValue, setPisValue] = useState(
    NFeInfo.cofins_options.find((x) => x.value === complement.pis)
  );
  const [cestValue, setCestValue] = useState(complement.cest);
  const [baseCalculoValue, setBaseCalculoValue] = useState(
    complement.aliquota || complement.icms_aliquota
  );
  const [aliquotaValue, setAliquotaValue] = useState(
    base_calculo_options.find((x) => x.value === complement.base_calculo)
  );
  // regime normal
  const [cstValue, setCstValue] = useState(
    NFeInfo.icms_situacao_tributaria.find((x) => x.value === complement.cst)
  );
  const [pisAliquotaValue, setPisAliquotaValue] = useState(
    complement.pis_aliquota
  );
  const [cofinsAliquotaValue, setCofinsAliquotaValue] = useState(
    complement.cofins_aliquota
  );
  const [icmsModalidadeBCValue, setIcmsModalidadeBCValue] = useState(
    NFeInfo.icms_modalidade_base_calculo.find(
      (x) => x.value === complement.icms_modalidade_base_calculo
    )
  );
  const [cbenefValue, setCbenefValue] = useState(complement.cbenef);
  const group = fiscalGroups.find((x) => x.id === complement.fiscal_group_id);
  const [fiscalGroup, setFiscalGroup] = useState(null);
  const [fiscalGroupDefault, setFiscalGroupDefault] = useState(
    group
      ? {
        label: group.title,
        value: group.id,
      }
      : null
  );

  async function updateFiscalValues(id) {
    const group = fiscalGroups.find((group) => group.id === id);
    setFiscalGroup(id);
    setFiscalGroupDefault({
      value: group.id,
      label: group.title,
    });
    setIcmsOrigemValue(
      icms_origem_options.find((x) => x.value === group.icms_origem)
    );
    setCsosnValue(csosn_options.find((x) => x.value === group.csosn));
    setCfopValue(NFeInfo.cfop_options.find((x) => x.value === group.cfop));
    setCofinsValue(
      NFeInfo.cofins_options.find((x) => x.value === group.cofins)
    );
    setPisValue(NFeInfo.cofins_options.find((x) => x.value === group.pis));
    setCestValue(group.cest);
    setAliquotaValue(group.aliquota || group.icms_aliquota);
    setBaseCalculoValue(
      base_calculo_options.find((x) => x.value === group.base_calculo)
    );
    // regime normal
    setCstValue(
      NFeInfo.icms_situacao_tributaria.find((x) => x.value === group.cst)
    );
    setPisAliquotaValue(group.pis_aliquota);
    setCofinsAliquotaValue(group.cofins_aliquota);
    setIcmsModalidadeBCValue(
      NFeInfo.icms_modalidade_base_calculo.find(
        (x) => x.value === group.icms_modalidade_base_calculo
      )
    );
    setCbenefValue(group.cbenef);
  }

  async function handleSaveComplement(data) {
    try {
      const formatPrice = (price) => {
        return parseFloat(price.replace(',', '.')).toFixed(2);
      };

      const formattedPrice = formatPrice(data.price);

      const formattedDeliveryPrice = data.delivery_price
        ? formatPrice(data.delivery_price)
        : null;

      const parsedData = {
        name: data.name,
        price: formattedPrice,
        delivery_price: formattedDeliveryPrice,
        limit: data.limit,
        show_on_report: data.show_on_report,
        available: data.available,
        available_in_delivery: data.available_in_delivery,
        description: data.description,
        ifood_complement_id: data.ifood_complement,
      };

      if (data.image_id) {
        parsedData.image_id = data.image_id;
      }

      if (formRefComplement?.current) {
        formRefComplement.current.setErrors({});
      }

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        limit: Yup.string().required('Limite obrigatório'),
        price: Yup.string().required('Preço obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setComplement(parsedData);

      if (user.has_nfce) {
        setActiveTab('2');
      } else {
        setActiveTab('3');
        // try {
        //   const response = await api.post(
        //     'restaurants/product/complements',
        //     parsedData
        //   );

        //   const { id } = response.data;

        //   await api.post(
        //     'restaurants/product/complement-category/add-complement',
        //     {
        //       complements: [id],
        //       complement_category_id: category.id,
        //     }
        //   );

        //   const newCategoriesInside = [...complementCategoriesInsideProduct];

        //   newCategoriesInside.map((cat, idix) => {
        //     if (cat.id === category.id) {
        //       newCategoriesInside[idix].complements = [
        //         ...newCategoriesInside[idix].complements,
        //         response.data,
        //       ];
        //     }
        //   });

        //   setComplementCategoriesInsideProduct(newCategoriesInside);

        //   const newCategories = [...complementsCategories];

        //   const categoryFind = newCategories.findIndex(
        //     (item) => item.id === category.id
        //   );

        //   if (categoryFind !== -1) {
        //     newCategories[categoryFind].complements = [
        //       ...newCategories[categoryFind].complements,
        //       response.data,
        //     ];
        //   }

        //   setComplementsCategories(newCategories);

        //   if (user.ifood_merchant_uuid !== null) {
        //     getIfoodComplements();
        //   }

        //   getMenu();
        //   toast.success('Complemento cadastrado com sucesso!');
        //   toggleModalCrerateComplement();
        // } catch (error) {
        //   toast.error('Erro ao criar complemento');
        //   console.log(error.message);
        // }
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRefComplement?.current) {
          formRefComplement.current.setErrors(errors);
        }
      }

      console.log(error.message);
    }
  }

  async function handleSaveFiscal(data) {
    try {
      const parsedData = {
        fiscal_group_id: fiscalGroup,
      };
      if (user.has_nfce) {
        if (user.regime_tributario === '1') {
          parsedData.ncm = data.ncm;
          parsedData.cfop = cfopValue?.value || data.cfop;
          parsedData.csosn = csosnValue?.value || data.csosn;
          parsedData.cest = cestValue?.value || data?.cest || null;
          parsedData.icms_origem = icmsOrigemValue?.value || data.icms_origem;

          if (csosnSelected === '00') {
            parsedData.aliquota = aliquotaValue || data.aliquota;
            parsedData.base_calculo =
              baseCalculoValue?.value || data.base_calculo;
          }

          if (user.has_nfce && data.cofins !== undefined) {
            parsedData.cofins = cofinsValue?.value || data.cofins;
          }

          if (user.has_nfce && data.pis !== undefined) {
            parsedData.pis = pisValue?.value || data.pis;
          }
        } else {
          parsedData.ncm = data.ncm;
          parsedData.cfop = cfopValue?.value || data.cfop;
          parsedData.csosn = csosnValue?.value || data.csosn;
          parsedData.cest = cestValue?.value || data?.cest || null;
          parsedData.icms_origem = icmsOrigemValue?.value || data.icms_origem;
          parsedData.icms_aliquota = aliquotaValue || data.icms_aliquota;

          parsedData.cst = cstValue?.value || data.cst;
          parsedData.pis_aliquota = pisAliquotaValue || data.pis_aliquota;
          parsedData.cofins_aliquota =
            cofinsAliquotaValue || data.cofins_aliquota;
          parsedData.icms_modalidade_base_calculo =
            icmsModalidadeBCValue?.value || data.icms_modalidade_base_calculo;
          parsedData.cbenef = cbenefValue || data.cbenef;

          if (user.has_nfce && data.cofins !== undefined) {
            parsedData.cofins = cofinsValue?.value || data.cofins;
          }

          if (user.has_nfce && data.pis !== undefined) {
            parsedData.pis = pisValue?.value || data.pis;
          }
        }
      }

      setComplementFiscal(parsedData);

      setActiveTab('3');

      // const response = await api.post('restaurants/product/complements', {
      //   ...complement,
      //   ...parsedData,
      // });

      // const { id } = response.data;

      // await api.post('restaurants/product/complement-category/add-complement', {
      //   complements: [id],
      //   complement_category_id: category.id,
      // });

      // const newCategoriesInside = [...complementCategoriesInsideProduct];

      // newCategoriesInside.map((cat, idix) => {
      //   if (cat.id === category.id) {
      //     newCategoriesInside[idix].complements = [
      //       ...newCategoriesInside[idix].complements,
      //       response.data,
      //     ];
      //   }
      // });

      // setComplementCategoriesInsideProduct(newCategoriesInside);

      // const newCategories = [...complementsCategories];

      // const categoryFind = newCategories.findIndex(
      //   (item) => item.id === category.id
      // );

      // if (categoryFind !== -1) {
      //   newCategories[categoryFind].complements = [
      //     ...newCategories[categoryFind].complements,
      //     response.data,
      //   ];
      // }

      // setComplementsCategories(newCategories);

      // if (user.ifood_merchant_uuid !== null) {
      //   getIfoodComplements();
      // }

      // getMenu();
      // toast.success('Complemento cadastrado com sucesso!');
      // toggleModalCrerateComplement();
    } catch (error) {
      toast.error('Erro ao criar fiscal');
      console.log(error.message);
    }
  }

  const [activeTab, setActiveTab] = useState('1');

  // inputs and intermediaries
  const [selectedComplement, setSelectedComplement] = useState({});
  const [inputs, setInputs] = useState([]);
  const [intermediaries, setIntermediaries] = useState([]);
  const [isMultistoreChild, setIsMultistoreChild] = useState(
    selectedComplement?.is_multistore_child || false
  );
  const arrayInputs = [];

  const [restaurantInputs, setRestaurantInputs] = useState([]);
  const [restaurantInputsSelect, setRestaurantInputsSelect] = useState([]);
  const [inputSelectedId, setInputSelectedId] = useState(null);

  const [restaurantIntermediaries, setRestaurantIntermediaries] = useState([]);
  const [restaurantIntermediarySelect, setRestaurantIntermediarySelect] =
    useState([]);
  const [intermediarySelectedId, setIntermediarySelectedId] = useState(null);

  const inputsTotal = arrayInputs.reduce((Acc, input) => Acc + input, 0);
  const [totalInputs, setTotalInputs] = useState(inputsTotal.toFixed(2));

  const getInputs = useCallback(async () => {
    try {
      const response = await api.get('restaurants/inputs-minimal');
      const inputs_id =
        inputs.length > 0 ? inputs?.map((input) => input.id) : [];

      const new_inputs = response.data.filter(
        (input) => !inputs_id.includes(input.id)
      );
      const select_inputs = new_inputs.map((input) => ({
        value: input.id,
        label: input.name,
        unidade: input.unidade,
        unitary_price: input.unitary_price,
        quantidade: input.quantidade,
        name: input.name,
        id: input.id,
      }));
      setRestaurantInputs(response.data);
      setRestaurantInputsSelect(select_inputs);
    } catch (err) {
      toast.error('Erro ao carregar insumos.');
      console.log(err.message);
    }
  }, [inputs]);

  const getIntermediaries = useCallback(async () => {
    try {
      const response = await api.get('restaurants/intermediary-minimal');
      const intermediaries_id = intermediaries?.map((inter) => inter.id);

      const new_inter = response.data.filter(
        (inter) => !intermediaries_id.includes(inter.id)
      );
      const select_inter = new_inter.map((inter) => ({
        value: inter.id,
        label: inter.name,
        unidade: inter.unidade,
        unitary_price: inter.unitary_price,
        quantidade: inter.quantidade,
        name: inter.name,
        id: inter.id,
      }));
      setRestaurantIntermediaries(response.data);
      setRestaurantIntermediarySelect(select_inter);
    } catch (err) {
      toast.error('Erro ao carregar produtos intermediarios.');
    }
  }, [intermediaries]);

  useEffect(() => {
    getInputs();
  }, [getInputs]);

  useEffect(() => {
    getIntermediaries();
  }, [getIntermediaries]);

  // Variáveis e funções dos calculos de CMV

  // const [cmvCheck, setCmvCheck] = useState(false);
  const [currentCmv, setCurrentCmv] = useState('0.00');

  function currencyFormatter(value) {
    if (!Number(value)) return 'R$ 0,00';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  }

  useEffect(() => {
    setCurrentCmv(totalInputs);
  }, [totalInputs]);

  useEffect(() => {
    const inputs_id = inputs.length > 0 ? inputs?.map((input) => input.id) : [];

    const new_inputs = restaurantInputs.filter(
      (input) => !inputs_id.includes(input.id)
    );
    const select_inputs = new_inputs.map((input) => ({
      value: input.id,
      label: input.name,
      unidade: input.unidade,
      unitary_price: input.unitary_price,
      quantidade: input.quantidade,
      name: input.name,
      id: input.id,
    }));

    setRestaurantInputsSelect(select_inputs);
  }, [inputs, restaurantInputs]);

  useEffect(() => {
    const inter_id = intermediaries?.map((inter) => inter.id);

    const new_inter = restaurantIntermediaries.filter(
      (inter) => !inter_id.includes(inter.id)
    );
    const select_inter = new_inter.map((inter) => ({
      value: inter.id,
      label: inter.name,
      unidade: inter.unidade,
      unitary_price: inter.unitary_price,
      quantidade: inter.quantidade,
      name: inter.name,
      id: inter.id,
    }));

    setRestaurantIntermediarySelect(select_inter);
  }, [intermediaries, restaurantIntermediaries]);

  const unidade_options = [
    {
      value: 'UN',
      label: 'UNIDADE',
    },
    {
      value: 'KG',
      label: 'QUILOGRAMA',
    },
    {
      value: 'L',
      label: 'LITRO',
    },
    {
      value: 'ML',
      label: 'MILILITRO',
    },
    {
      value: 'PC',
      label: 'PACOTE',
    },
    {
      value: 'BD',
      label: 'BALDE',
    },
    {
      value: 'CX',
      label: 'CAIXA',
    },
    {
      value: 'FD',
      label: 'FARDO',
    },
    {
      value: 'GF',
      label: 'GARRAFA',
    },
    {
      value: 'G',
      label: 'GRAMA',
    },
    {
      value: 'GL',
      label: 'GALAO',
    },
    {
      value: 'PT',
      label: 'POTE',
    },
  ];

  const [interInputs, setInterInputs] = useState([]);
  const [defaultInterValue, setDefaultInterValue] = useState(0);
  const [selectInput, setSelectInput] = useState([]);
  const [quantInter, setQuantInter] = useState(0);

  const [interInter, setInterInter] = useState([]);
  const [defaultInterInterValue, setDefaultInterInterValue] = useState(0);
  const [selectInter, setSelectInter] = useState([]);

  function insertInputInter(selectInput, defaultInterValue) {
    const exists = interInputs.filter((int) => int.id === selectInput.value);

    if (exists.length > 0) {
      toast.error('Insumo já cadastrado');
    } else {
      setInterInputs((state) => {
        return [
          ...state,
          {
            name: selectInput.label,
            id: selectInput.value,
            quantidade_inter_value: defaultInterValue.replace(',', '.'),
            unidade: selectInput.unidade,
            unitary_price: selectInput.unitary_price,
            quantidade: selectInput.quantidade,
          },
        ];
      });
    }
  }

  const formIntermediaryRef = useRef(null);
  const formRef = useRef(null);
  const formRefBank = useRef(null);
  const formRefSupplier = useRef(null);

  const [suppliers, setSuppliers] = useState();
  const [banks, setBanks] = useState();

  const getSuppliers = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows/providers');

      const providersSelect = response?.data?.map((provider) => {
        return {
          value: provider.id,
          label: provider.name,
        };
      });

      setSuppliers(providersSelect);
    } catch (error) {
      toast.error('Erro ao baixar fornecedores');
    }
  }, []);

  const getBanks = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows/bank-accounts');

      const banksSelect = response?.data?.map((bank) => {
        return {
          value: bank.id,
          label: bank.name,
        };
      });

      setBanks(banksSelect);
    } catch (error) {
      toast.error('Erro ao baixar contas de banco');
    }
  }, []);

  useEffect(() => {
    getBanks();
  }, [getBanks]);

  useEffect(() => {
    getSuppliers();
  }, [getSuppliers]);

  // create banks

  const [isModalCreateBankOpen, setIsModalCreateBankOpen] = useState(false);

  const toggleModalCreateBank = useCallback(() => {
    setIsModalCreateBankOpen(!isModalCreateBankOpen);
  }, [isModalCreateBankOpen]);

  const handleBankSubmit = useCallback(
    async (data) => {
      try {
        if (formRefBank?.current) {
          formRefBank.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/restaurants/cash-flows/bank-accounts', {
          name: data.name,
        });

        toast.success('Conta cadastrada com sucesso!');

        getBanks();
        toggleModalCreateBank();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRefBank?.current) {
            formRefBank.current.setErrors(errors);
          }
        } else {
          // console.tron.log(error);
          toast.error(`Erro ao cadastrar conta!`);
        }
      }
    },
    [getBanks, toggleModalCreateBank]
  );

  // create suplliers

  const [isModalCreateSuppliersOpen, setIsModalCreatesuppliersOpen] =
    useState(false);

  const toggleModalCreateSuplliers = useCallback(() => {
    setIsModalCreatesuppliersOpen(!isModalCreateSuppliersOpen);
  }, [isModalCreateSuppliersOpen]);

  const handleSupplierSubmit = useCallback(
    async (data) => {
      try {
        if (formRefSupplier?.current) {
          formRefSupplier.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          cnpj: Yup.string()
            .required('CNPJ/ CPF obrigatório')
            .trim()
            .matches('^[0-9]+$', 'Apenas números, sem espaço'),
          phone: Yup.string().required('Telefone obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/restaurants/cash-flows/providers', {
          name: data.name,
          phone: data.phone,
          cnpj: data.cnpj,
        });

        toast.success('Fornecedor cadastrada com sucesso!');

        getSuppliers();
        toggleModalCreateSuplliers();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRefSupplier?.current) {
            formRefSupplier.current.setErrors(errors);
          }
        } else {
          toast.error(`Erro ao cadastrar fornecedor!`);
        }
      }
    },
    [getSuppliers, toggleModalCreateSuplliers]
  );

  const handleInput = useCallback(
    async (data) => {
      try {
        if (formRef.current) {
          formRef.current.setErrors({});
        }

        if (data.total_to_send && parseFloat(data.total_to_send) > 0) {
          const schema = Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            unidade: Yup.string().required('Unidade Obrigatória'),
            quantidade: Yup.string().required('Quantidade Obrigatória'),
            total_to_send: Yup.number().min(0, 'Não pode ser negativo'),
            category: Yup.string().required('Centro de custo obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });
        } else {
          const schema = Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            unidade: Yup.string().required('Unidade Obrigatória'),
            quantidade: Yup.string().required('Quantidade Obrigatória'),
            total_to_send: Yup.number().min(0, 'Não pode ser negativo'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });
        }

        const new_date = `${data.due_date}T03:00:00.000Z`;
        const new_paid = `${data.paid_at}T03:00:00.000Z`;

        const handleData = {
          name: data.name,
          quantidade: data.quantidade.replace(',', '.'),
          unidade: data.unidade,
          total: parseFloat(data.total_to_send.replace(',', '.')),
          cash_flow_category_subcategory_id: data.category || null,
          minimum_stock: data.minimum_stock,
          ideal_stock: data.ideal_stock,
          provider_id: data.provider_id || null,
          bank_account_id: data.bank_account_id || null,
          due_date: data.due_date ? new_date : null,
          paid: data.paid,
          paid_at: data.paid ? new_paid : null,
          create_cash_flow: data.create_cashflow,
        };

        const response = await api.post(`/restaurants/inputs`, handleData);
        setInputs((state) => {
          return [response.data, ...state];
        });

        toast.success('Insumo adiconado com sucesso!');
        setModalInput(false);
        setCreateCashFlowOnNewInput(true);

        setPaidCheck(!paidCheck);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRef.current) {
            formRef.current.setErrors(errors);
          }
        } else {
          toast.error('Erro ao adicionar insumo.');
        }
      }
    },
    [paidCheck]
  );

  const [categories, setCategories] = useState();

  const getCategoriesDetails = useCallback(async () => {
    try {
      const response = await api.get(
        '/restaurants/cash-flows/categories-detail'
      );

      const categoriesSelect = response?.data?.map((category) => {
        return {
          value: category.id,
          label: category.text,
        };
      });

      setCategories(categoriesSelect);
    } catch (error) {
      toast.error('Erro ao baixar métodos de pagamento');
    }
  }, []);

  useEffect(() => {
    getCategoriesDetails();
  }, [getCategoriesDetails]);

  // const [intermediary, setIntermediary] = useState([]);
  const [filterString, setFilterString] = useState('');
  const [unidadeSelected, setUnidadeSelected] = useState('Kg');
  const [nameIntermediary, setNameIntermediary] = useState('');
  const [yeldIntermediary, setYeldIntermediary] = useState(1);

  function deleteInputInter(id) {
    const updated = interInputs.filter((int) => int.id !== id);
    setInterInputs(updated);
  }

  async function handleIntermediary(data) {
    try {
      if (formIntermediaryRef.current) {
        formIntermediaryRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        unidade: Yup.string().required('Unidade Obrigatória'),
        quantidade_inter: Yup.string().required('Quantidade Obrigatória'),
        yield: Yup.string().required('Valor Obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const inputs = interInputs.map((inter) => ({
        id: inter.id,
        quantidade: inter.quantidade_inter_value,
      }));
      const intermediaries = interInter.map((inter) => ({
        id: inter.id,
        quantidade: inter.quantidade_inter_value,
      }));

      const handleData = {
        name: data.name,
        quantidade: quantInter.toString().replace(',', '.'),
        unidade: data.unidade,
        recipe: data.recipe,
        yield: data.yield,
        minimum_stock: data.minimum_stock,
        ideal_stock: data.ideal_stock,
        inputs,
        intermediaries,
      };

      const response = await api.post(`/restaurants/intermediary`, handleData);
      setIntermediaries((state) => {
        return [response.data, ...state];
      });

      setInterInputs([]);
      setInterInter([]);
      getInputs(filterString);
      toggleIntermediary();
      toast.success('Produto intermediario adiconado com sucesso!');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formIntermediaryRef.current) {
          formIntermediaryRef.current.setErrors(errors);
        }
      } else {
        toast.error('Erro ao adicionar produto intermediário.');
      }
    }
  }
  function insertInterInter(selectInter, defaultInterValue) {
    const exists = interInter.filter((int) => int.id === selectInter.value);

    if (exists.length > 0) {
      toast.error('Produto Intermediário já cadastrado');
    } else {
      setInterInter([
        ...interInter,
        {
          name: selectInter.label,
          id: selectInter.value,
          quantidade_inter_value: defaultInterValue.replace(',', '.'),
          unidade: selectInter.unidade,
          unitary_price: selectInter.unitary_price,
          quantidade: selectInter.quantidade,
        },
      ]);
    }
  }

  function deleteInterInter(id) {
    const updated = interInter.filter((int) => int.id !== id);
    setInterInter(updated);
  }

  const [inputArray, setInputArray] = useState([]);
  const [intermediaryArray, setIntermediaryArray] = useState([]);

  function handleSetSelectedComplement(e) {
    setSelectedComplement({ price: e });
  }

  const [intermediaryState, setIntermediaryState] = useState();
  const [inputState, setInputState] = useState();
  const [inputQuantidade, setInputQuantidade] = useState();
  const [intermediaryQuantidade, setIntermediaryQuantidade] = useState();

  const handleAddInputAtArray = useCallback(() => {
    if (inputQuantidade) {
      const inputsAlreadyInserted = inputArray.find(
        (item) => item.input_id === inputSelectedId
      );

      if (!inputsAlreadyInserted) {
        setInputArray((state) => {
          return [
            {
              quantidade: inputQuantidade.replace(',', '.'),
              input_id: inputSelectedId,
              name: inputState.name,
              unitary_price: inputState.unitary_price,
            },
            ...state,
          ];
        });
        setInputQuantidade('');
      } else {
        toast.error('Item já inserido');
      }
    } else {
      toast.error('Inserir valor do consumo');
    }
  }, [inputQuantidade, inputSelectedId, inputState, inputArray]);

  function handleAddIntermediaryAtArray() {
    if (intermediaryQuantidade) {
      const intermediaryAlreadyInserted = intermediaryArray.find(
        (item) => item.intermediary_id === intermediarySelectedId
      );
      if (!intermediaryAlreadyInserted) {
        setIntermediaryArray((state) => {
          return [
            {
              quantidade: intermediaryQuantidade.replace(',', '.'),
              intermediary_id: intermediarySelectedId,
              name: intermediaryState.name,
              unitary_price: intermediaryState.unitary_price,
            },
            ...state,
          ];
        });
        setIntermediaryQuantidade('');
      } else {
        toast.error('Inserir valor do consumo');
      }
    } else {
      toast.error('Inserir valor do consumo');
    }
  }

  function handleDeleteIntermediaryArray(id) {
    const itensFiltered = intermediaryArray.filter(
      (item) => item.intermediary_id !== id
    );

    setIntermediaryArray(itensFiltered);
  }

  function handleDeleteInputArray(id) {
    const itensFiltered = inputArray.filter((item) => item.input_id !== id);

    setInputArray(itensFiltered);
  }

  const [activeButtonsSubmitQuantidade, setActiveButtonsSubmitQuantidade] =
    useState([]);

  function handleActiveButton(id) {
    setActiveButtonsSubmitQuantidade([id]);
  }

  const [inputQuantidadeAtList, setInputQuantidadeAtList] = useState();
  const [inputQuantidadeState, setInputQuantidadeState] = useState({});

  useEffect(() => {
    const inputFind = document.getElementById(`${inputQuantidadeState?.id}`);

    if (inputFind) {
      inputFind.value =
        inputQuantidadeState &&
        formatValue(
          parseFloat(inputQuantidadeState?.quantidade) *
          parseFloat(inputQuantidadeState?.unitary_price).toFixed(2) || '0.00'
        );
    }
  }, [inputQuantidadeState]);

  console.log(inputQuantidadeState);

  const [intermediaryQuantidadeAtList, setIntermediaryQuantidadeAtList] =
    useState();

  const [intermediaryQuantidadeState, setIntermediaryQuantidadeState] =
    useState({});

  useEffect(() => {
    const intermediaryFind = document.getElementById(
      `${intermediaryQuantidadeState?.id}`
    );

    if (intermediaryFind) {
      intermediaryFind.value =
        intermediaryQuantidadeState &&
        formatValue(
          parseFloat(intermediaryQuantidadeState?.quantidade) *
          parseFloat(intermediaryQuantidadeState?.unitary_price).toFixed(2) ||
          '0.00'
        );
    }

    console.log(intermediaryQuantidadeState);
  }, [intermediaryQuantidadeState]);

  const handleTimeInput = (time) => {
    let validTime = '';
    const array = time.split('');

    array.forEach((element, i) => {
      if (i === 0 && element > 2) {
        validTime += '2';
      } else if (i === 1 && element > 3 && array[0] > 1) {
        validTime += '3';
      } else if (i === 3 && element > 5) {
        validTime += '5';
      } else {
        validTime += element;
      }
      return;
    });

    return validTime;
  };

  function handleChangeInputQuantidadeAtArray(id) {
    const inputFind = inputArray.find((item) => item.input_id === id);
    if (inputFind) {
      inputFind.quantidade = inputQuantidadeAtList;

      toast.success('Quantidade alterada.');
    }
  }

  function handleChangeIntermediaryQuantidadeAtArray(id) {
    const intermediaryFind = intermediaryArray.find(
      (item) => item.intermediary_id === id
    );
    if (intermediaryFind) {
      intermediaryFind.quantidade = intermediaryQuantidadeAtList;

      toast.success('Quantidade alterada.');
    }
  }
  async function handleSubmitComplement(data) {
    try {
      const response = await api.post('restaurants/product/complements', {
        ...complement,
        ...complementFiscal,
        intermediaries: intermediaryArray,
        inputs: inputArray,
        enable_times: enableTimes,
        active_days: daysSelected.join(''),
        start_time: new Date(`2023/01/01 ${hourSelected.start}`),
        end_time: new Date(`2023/01/01 ${hourSelected.end}`),
      });

      const { id } = response.data;

      await api.put(`/restaurants/product/complements/cmv/${id}`, {
        current_cmv: currentCmv || null,
        is_cmv_manual: true,
      });

      await api.post('restaurants/product/complement-category/add-complement', {
        complements: [id],
        complement_category_id: category.id,
      });

      const newCategoriesInside = [...complementCategoriesInsideProduct];

      newCategoriesInside.map((cat, idix) => {
        if (cat.id === category.id) {
          newCategoriesInside[idix].complements = [
            ...newCategoriesInside[idix].complements,
            response.data,
          ];
        }
      });

      setComplementCategoriesInsideProduct(newCategoriesInside);

      const newCategories = [...complementsCategories];

      const categoryFind = newCategories.findIndex(
        (item) => item.id === category.id
      );

      if (categoryFind !== -1) {
        newCategories[categoryFind].complements = [
          ...newCategories[categoryFind].complements,
          response.data,
        ];
      }

      setComplementsCategories(newCategories);

      if (user.ifood_merchant_uuid !== null) {
        getIfoodComplements();
      }

      // getMenu();
      toast.success('Complemento cadastrado com sucesso!');
      toggleModalCrerateComplement();
    } catch (error) {
      toast.error('Erro ao criar complemento');
      console.log(error.message);
    }
  }
  return (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === '1' ? 'active' : ''}
            onClick={() => {
              setActiveTab('1');
            }}
            style={{ cursor: 'pointer', color: '#333' }}
          >
            Complemento
          </NavLink>
        </NavItem>
        {user.has_nfce && (
          <NavItem>
            <NavLink
              className={activeTab === '2' ? 'active' : ''}
              onClick={() => {
                setActiveTab('2');
              }}
              style={{ cursor: 'pointer', color: '#333' }}
              disabled={activeTab !== '2'}
            >
              Fiscal
            </NavLink>
          </NavItem>
        )}
        <NavItem>
          <NavLink
            className={activeTab === '3' ? 'active' : ''}
            onClick={() => {
              setActiveTab('3');
            }}
            style={{ cursor: 'pointer', color: '#333' }}
            disabled={activeTab !== '3'}
          >
            Estoque
          </NavLink>
        </NavItem>
      </Nav>
      <Container>
        {activeTab === '1' && (
          <Form
            onSubmit={handleSaveComplement}
            ref={formRefComplement}
            initialData={complement}
          >
            <Row>
              <Col md="8">
                <Row>
                  <Col sm="12" lg="6">
                    <Input
                      label="Nome"
                      name="name"
                      type="text"
                      placeholder="Nome do complemento"
                    />
                  </Col>
                  <Col sm="12" lg="6">
                    <Input
                      label="Limite"
                      name="limit"
                      type="number"
                      placeholder="Limite individual"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Textarea
                      label="Descrição"
                      name="description"
                      type="textarea"
                      placeholder="Descrição do complemento"
                    />
                  </Col>
                </Row>
              </Col>
              <Col md="4">
                <Row>
                  <Col sm="12">
                    <ImageInput name="image_id" complement />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col md="4" style={{ display: 'flex' }}>
                <CheckboxInput defaultChecked name="available" />
                <span>Disponível no presencial</span>
              </Col>
              <Col md="4" style={{ display: 'flex' }}>
                <CheckboxInput defaultChecked name="available_in_delivery" />
                <span>Disponível no delivery</span>
              </Col>
              <Col md="4" style={{ display: 'flex' }}>
                <CheckboxInput
                  name="enable_times"
                  value={enableTimes}
                  onChange={() => setEnableTimes(!enableTimes)}
                />
                <span>Horário personalizado</span>
              </Col>
            </Row>

            {enableTimes && (
              <PersonalizedHoursContainer>
                <div>
                  <span style={{ fontWeight: 'bold' }}>Dias:</span>
                  <div style={{ display: 'flex', gap: 5 }}>
                    <Button
                      type="button"
                      title="Dom"
                      buttonColor="#2ec9b7"
                      containerStyles={{
                        width: 60,
                        height: 30,
                        opacity: daysSelected[0] === 'f' ? 0.5 : 1,
                      }}
                      onClick={() => {
                        handleChangeDay(0);
                      }}
                    />
                    <Button
                      type="button"
                      title="Seg"
                      containerStyles={{
                        width: 60,
                        height: 30,
                        opacity: daysSelected[1] === 'f' ? 0.5 : 1,
                      }}
                      buttonColor="#2ec9b7"
                      onClick={() => {
                        handleChangeDay(1);
                      }}
                    />
                    <Button
                      type="button"
                      title="Ter"
                      containerStyles={{
                        width: 60,
                        height: 30,
                        opacity: daysSelected[2] === 'f' ? 0.5 : 1,
                      }}
                      buttonColor="#2ec9b7"
                      onClick={() => {
                        handleChangeDay(2);
                      }}
                    />
                    <Button
                      type="button"
                      title="Qua"
                      containerStyles={{
                        width: 60,
                        height: 30,
                        opacity: daysSelected[3] === 'f' ? 0.5 : 1,
                      }}
                      buttonColor="#2ec9b7"
                      onClick={() => {
                        handleChangeDay(3);
                      }}
                    />
                    <Button
                      type="button"
                      title="Qui"
                      containerStyles={{
                        width: 60,
                        height: 30,
                        opacity: daysSelected[4] === 'f' ? 0.5 : 1,
                      }}
                      buttonColor="#2ec9b7"
                      onClick={() => {
                        handleChangeDay(4);
                      }}
                    />
                    <Button
                      type="button"
                      title="Sex"
                      containerStyles={{
                        width: 60,
                        height: 30,
                        opacity: daysSelected[5] === 'f' ? 0.5 : 1,
                      }}
                      buttonColor="#2ec9b7"
                      onClick={() => {
                        handleChangeDay(5);
                      }}
                    />
                    <Button
                      type="button"
                      title="Sáb"
                      containerStyles={{
                        width: 60,
                        height: 30,
                        opacity: daysSelected[6] === 'f' ? 0.5 : 1,
                      }}
                      buttonColor="#2ec9b7"
                      onClick={() => {
                        handleChangeDay(6);
                      }}
                    />
                  </div>
                </div>
                <div>
                  <span style={{ fontWeight: 'bold' }}>Horários:</span>
                  <div
                    style={{
                      display: 'flex',
                      gap: 5,
                      alignItems: 'center',
                    }}
                  >
                    <span>das</span>
                    <DefaultInput
                      placeholder="__:__"
                      mask="99:99"
                      maskChar="0"
                      style={{
                        width: '74px',
                        padding: '0px 10px',
                        height: '30px',
                      }}
                      value={hourSelected.start}
                      onChange={(e) => {
                        const value = e.target.value;
                        setHourSelected((state) => {
                          return {
                            start: handleTimeInput(value),
                            end: state.end,
                          };
                        });
                      }}
                    />
                    <span>às</span>
                    <DefaultInput
                      placeholder="__:__"
                      mask="99:99"
                      maskChar="0"
                      style={{
                        width: '74px',
                        padding: '0px 10px',
                        height: '30px',
                      }}
                      value={hourSelected.end}
                      onChange={(e) => {
                        const value = e.target.value;
                        setHourSelected((state) => {
                          return {
                            start: state.start,
                            end: handleTimeInput(value),
                          };
                        });
                      }}
                    />
                  </div>
                </div>
              </PersonalizedHoursContainer>
            )}

            <Row>
              <Col sm="12" lg="6">
                <Input
                  label="Preço"
                  name="price"
                  type="number"
                  placeholder="R$"
                  onChange={(e) => {
                    handleSetSelectedComplement(e.target.value);
                  }}
                  step="0.01"
                  min="0"
                />
              </Col>

              <Col sm="12" lg="6">
                <Input
                  label="Preço no delivery"
                  name="delivery_price"
                  type="number"
                  placeholder="R$"
                  step="0.01"
                  min="0"
                />
              </Col>
            </Row>

            <Row />
            <Row />

            {user.has_ifood && (
              <Row>
                {ifoodComplements !== null ? (
                  <Select
                    name="ifood_complement"
                    placeholder={
                      ifoodComplements.length === 0
                        ? 'Esse restaurante não possui complementos no iFood'
                        : 'Selecionar'
                    }
                    options={ifoodComplements}
                    label="Associar Complemento ao iFood"
                  />
                ) : (
                  <Spinner style={{ margin: '0 auto' }} />
                )}
              </Row>
            )}
            <ButtonsArea>
              <PersonalButton
                color="#FF2C3A"
                message="Cancelar"
                style={{
                  marginLeft: 0,
                }}
                onClick={() => {
                  toggleModalCrerateComplement();
                }}
              />

              <PersonalButton color="#0CCAB4" message="Próximo" type="submit" />
            </ButtonsArea>
          </Form>
        )}
        {activeTab === '2' && (
          <div>
            {user.has_nfce ? (
              <Form onSubmit={handleSaveFiscal}>
                <Row>
                  <Col sm="12">
                    <Select
                      name="fiscal_group_id"
                      options={fiscalGroups?.map((group) => {
                        return {
                          label: group.title,
                          value: group.id,
                        };
                      })}
                      label="Grupo Fiscal"
                      onChange={(e) => updateFiscalValues(e.value)}
                      value={fiscalGroupDefault}
                    />
                  </Col>
                </Row>
                {user.has_nfce &&
                  (user.regime_tributario === '1' ? (
                    <div>
                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <InputMask mask="99999999">
                            <Input label="NCM" name="ncm" type="text" />
                          </InputMask>
                        </Col>

                        <Col sm="12" lg="6" style={{ marginTop: 15 }}>
                          <Select
                            name="icms_origem"
                            options={icms_origem_options}
                            label="ICMS de Origem"
                            value={icmsOrigemValue}
                            onChange={(e) => {
                              setIcmsOrigemValue(
                                icms_origem_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                          />
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="csosn"
                            options={csosn_options}
                            label="CSOSN"
                            onChange={(e) => {
                              setCsosnSelected(e.value);
                              setCsosnValue(
                                csosn_options.find((x) => x.value === e.value)
                              );
                            }}
                            value={csosnValue}
                          />
                        </Col>

                        <Col sm="12" lg="6">
                          <Select
                            name="cfop"
                            options={cfopOptions}
                            label="CFOP"
                            onChange={(e) => {
                              setCfopValue(
                                NFeInfo.cfop_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={cfopValue}
                          />
                        </Col>
                      </Row>

                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="cofins"
                            options={nfeInfo.cofins_options}
                            label="Cofins"
                            onChange={(e) => {
                              setCofinsValue(
                                NFeInfo.cofins_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={cofinsValue}
                          />
                        </Col>

                        <Col sm="12" lg="6">
                          <Select
                            name="pis"
                            options={nfeInfo.cofins_options}
                            label="Pis"
                            onChange={(e) => {
                              setPisValue(
                                NFeInfo.cofins_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={pisValue}
                          />
                        </Col>
                      </Row>

                      {(csosnValue?.value === '00' ||
                        aliquotaValue ||
                        baseCalculoValue) && (
                          <div>
                            <Row style={{ paddingTop: 20 }}>
                              <Col sm="12" lg="6">
                                <Input
                                  label="Alíquota (Entre 0 e 100)"
                                  name="aliquota"
                                  type="text"
                                  value={aliquotaValue}
                                  onChange={(e) => {
                                    setAliquotaValue(e.target.value);
                                  }}
                                />
                              </Col>

                              <Col sm="12" lg="6" style={{ marginTop: 15 }}>
                                <Select
                                  name="base_calculo"
                                  options={base_calculo_options}
                                  label="Base Cálculo"
                                  value={baseCalculoValue}
                                  onChange={(e) => {
                                    setBaseCalculoValue(
                                      base_calculo_options.find(
                                        (x) => x.value === e.value
                                      )
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          </div>
                        )}

                      {(csosnValue?.value === '500' || cestValue) && (
                        <Row style={{ paddingTop: 10 }}>
                          <Col sm="12" lg="3">
                            <InputMask
                              mask="9999999"
                              onChange={(e) => {
                                setCestValue(e.target.value);
                              }}
                              value={cestValue}
                            >
                              <Input label="CEST" name="cest" type="text" />
                            </InputMask>
                          </Col>

                          {/* {
                            csosnValue?.value === '500' &&
                            (
                              <>
                                <Col sm="12" lg="3">
                                  <Input label="Base de Cálculo ICMS Retido na operação anterior" name="icms_base_calculo_retido_st" type="text" />
                                </Col>
                                <Col sm="12" lg="3">
                                  <Input label="Alíquota suportada pelo Consumidor Final" name="icms_aliquota_final" type="text" />
                                </Col>
                                <Col sm="12" lg="3">
                                  <Input label="Valor do ICMS próprio do Substituto" name="icms_valor_substituto" type="text" />
                                </Col>
                                <Col sm="12" lg="3">
                                  <Input label="Valor do ICMS ST Retido na operação anterior" name="icms_valor_retido_st" type="text" />
                                </Col>
                              </>
                            )
                          } */}
                        </Row>
                      )}
                    </div>
                  ) : (
                    <div>
                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="icms_origem"
                            options={icms_origem_options}
                            label="ICMS de Origem"
                            value={icmsOrigemValue}
                            onChange={(e) => {
                              setIcmsOrigemValue(
                                icms_origem_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                          />
                        </Col>

                        <Col sm="12" lg="6">
                          <Input
                            label="Alíquota ICMS (%)"
                            name="icms_aliquota"
                            type="text"
                            value={aliquotaValue}
                            onChange={(e) => {
                              setAliquotaValue(e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="cfop"
                            options={cfopOptions}
                            label="CFOP"
                            onChange={(e) => {
                              setCfopValue(
                                NFeInfo.cfop_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={cfopValue}
                          />
                        </Col>
                        <Col sm="12" lg="6">
                          <Select
                            name="cst"
                            options={NFeInfo.icms_situacao_tributaria}
                            label="CST"
                            onChange={(e) => {
                              setCstValue(
                                NFeInfo.icms_situacao_tributaria.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={cstValue}
                          />
                        </Col>
                      </Row>

                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="csosn"
                            options={csosn_options}
                            label="CSOSN"
                            onChange={(e) => {
                              setCsosnSelected(e.value);
                              setCsosnValue(
                                csosn_options.find((x) => x.value === e.value)
                              );
                            }}
                            value={csosnValue}
                          />
                        </Col>
                        <Col sm="12" lg="6">
                          <InputMask mask="99999999">
                            <Input label="NCM" name="ncm" type="text" />
                          </InputMask>
                        </Col>
                      </Row>

                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="pis"
                            options={nfeInfo.pis_situacao_tributaria}
                            label="PIS"
                            onChange={(e) => {
                              setPisValue(
                                NFeInfo.cofins_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={pisValue}
                          />
                        </Col>

                        <Col sm="12" lg="6">
                          <Input
                            label="Alíquota PIS (%)"
                            name="pis_aliquota"
                            type="text"
                            onChange={(e) => {
                              setPisAliquotaValue(e.target.value);
                            }}
                            value={pisAliquotaValue}
                          />
                        </Col>
                      </Row>

                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="cofins"
                            options={nfeInfo.pis_situacao_tributaria}
                            label="COFINS"
                            onChange={(e) => {
                              setCofinsValue(
                                NFeInfo.cofins_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={cofinsValue}
                          />
                        </Col>

                        <Col sm="12" lg="6">
                          <Input
                            label="Alíquota COFINS (%)"
                            name="cofins_aliquota"
                            type="text"
                            onChange={(e) => {
                              setCofinsAliquotaValue(e.target.value);
                            }}
                            value={cofinsAliquotaValue}
                          />
                        </Col>
                      </Row>

                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="icms_modalidade_base_calculo"
                            options={nfeInfo.icms_modalidade_base_calculo}
                            label="Modalidade Base Calculo"
                            onChange={(e) => {
                              setIcmsModalidadeBCValue(
                                NFeInfo.icms_modalidade_base_calculo.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={icmsModalidadeBCValue}
                          />
                        </Col>

                        <Col sm="12" lg="6">
                          <Input
                            label="Código de Benefícios Fiscais (cBenef)"
                            name="cbenef"
                            type="text"
                            onChange={(e) => {
                              setCbenefValue(e.target.value);
                            }}
                            value={cbenefValue}
                          />
                        </Col>
                      </Row>

                      {(csosnValue?.value === '500' || cestValue) && (
                        <Row style={{ paddingTop: 10 }}>
                          <Col sm="12" lg="6">
                            <InputMask
                              mask="9999999"
                              onChange={(e) => {
                                setCestValue(e.target.value);
                              }}
                              value={cestValue}
                            >
                              <Input label="CEST" name="cest" type="text" />
                            </InputMask>
                          </Col>
                        </Row>
                      )}
                    </div>
                  ))}

                <ButtonsArea>
                  <PersonalButton
                    color="#FF2C3A"
                    message="Cancelar"
                    style={{
                      marginLeft: 0,
                    }}
                    onClick={() => {
                      toggleModalCrerateComplement();
                    }}
                  />

                  <div style={{ display: 'flex' }}>
                    <OutlineButton
                      text="Voltar"
                      style={{
                        marginLeft: 0,
                      }}
                      onClick={() => {
                        setActiveTab('1');
                      }}
                    />
                    <PersonalButton
                      color="#0CCAB4"
                      message="Próximo"
                      type="submit"
                    />
                  </div>
                </ButtonsArea>
              </Form>
            ) : (
              <>
                <div
                  style={{
                    background: '#D3D3D3',
                    height: 119,
                    borderRadius: 7,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p style={{ fontSize: 20, fontWeight: 'bold' }}>
                    Seu plano atual não contempla a função de fiscal!
                  </p>
                  <p>
                    Entre em contato com nosso time de suporte e faça a
                    ativação.
                  </p>
                </div>
                <ButtonsArea>
                  <PersonalButton
                    color="#FF2C3A"
                    message="Cancelar"
                    style={{
                      marginLeft: 0,
                    }}
                    onClick={() => {
                      toggleModalCrerateComplement();
                    }}
                  />

                  <div style={{ display: 'flex' }}>
                    <OutlineButton
                      text="Voltar"
                      style={{
                        marginLeft: 0,
                      }}
                      onClick={() => {
                        setActiveTab('1');
                      }}
                    />
                  </div>
                </ButtonsArea>
              </>
            )}
          </div>
        )}

        {activeTab === '3' && (
          <>
            {user.use_stock ? (
              <>
                <InputsTable borderless style={{ width: '54%' }}>
                  <thead>
                    <tr>
                      <th>Custo do Item (CMV)</th>
                      <th>Valor de Venda</th>
                      <th>Lucro Bruto</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td style={{ color: '#A6803A' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          {/* <StyledCheckbox onClick={() => toggleCmvCheck()}>
                            <input type="checkbox" checked={cmvCheck} />
                            <span>Inserir valor manualmente</span>
                          </StyledCheckbox> */}
                          <CmvButtons>
                            <NumberFormatStyled
                              prefix="R$"
                              name="value"
                              format={currencyFormatter}
                              defaultValue={formatValue(currentCmv || '0.00')}
                              value={formatValue(currentCmv || '0.00')}
                              onChange={(e) =>
                                setCurrentCmv(
                                  e.target.value
                                    .replace(/(R|\$|\s)/g, '')
                                    .replace(',', '.')
                                )
                              }
                            />
                            {/* <SubmitStyledButton
                              onClick={() => handleUpdateCmv()}
                            >
                              <FaCheck />
                            </SubmitStyledButton> */}
                          </CmvButtons>
                        </div>
                      </td>
                      <td>
                        R$
                        {parseFloat(selectedComplement.price).toLocaleString(
                          'pt-BR',
                          {
                            minimumFractionDigits: 2,
                          }
                        )}
                      </td>
                      <td style={{ color: 'green' }}>
                        R$
                        {(
                          parseFloat(selectedComplement.price) -
                          parseFloat(currentCmv || 0)
                        ).toLocaleString('pt-BR', {
                          minimumFractionDigits: 2,
                        })}
                      </td>
                    </tr>
                  </tbody>
                </InputsTable>

                <InputsTable borderless>
                  <thead>
                    <tr>
                      <th>Insumo</th>
                      <th>Consumo</th>
                      <th>Valor</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ width: '60%' }}>
                        <SelectStyled
                          placeholder="Selecione o insumo"
                          name="input"
                          options={restaurantInputsSelect}
                          onChange={(e) => {
                            setInputSelectedId(e.value);
                            setInputState(e);
                          }}
                        />
                      </td>

                      <td>
                        <InputStyled
                          name="input_quantidade"
                          placeholder="0"
                          type="number"
                          onChange={(e) => setInputQuantidade(e.target.value)}
                          value={inputQuantidade}
                        />
                      </td>
                      <td>-</td>
                      <td>
                        {!isMultistoreChild && (
                          <PersonalButton
                            color="#0CCAB4"
                            message="Associar"
                            onClick={handleAddInputAtArray}
                            style={{ float: 'right' }}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <ButtonAdd onClick={toggleModalInput}>
                        + Cadastrar novo
                      </ButtonAdd>
                    </tr>
                    {inputArray &&
                      inputArray.length > 0 &&
                      inputArray.map((item) => (
                        <tr>
                          <td>{item.name}</td>
                          <td style={{ display: 'flex' }}>
                            <InputStyled
                              defaultValue={item.quantidade}
                              type="number"
                              key={item.quantidade}
                              onClick={() => handleActiveButton(item.input_id)}
                              onChange={(e) => {
                                setInputQuantidadeAtList(e.target.value);
                                setInputQuantidadeState({
                                  id: `input${item?.id}`,
                                  quantidade: e.target.value,
                                  unitary_price: item?.unitary_price,
                                });
                              }}
                            />
                            <SubmitStyledButton
                              disabled={
                                !activeButtonsSubmitQuantidade.includes(
                                  item.input_id
                                )
                              }
                              onClick={() =>
                                handleChangeInputQuantidadeAtArray(
                                  item.input_id
                                )
                              }
                            >
                              <FaCheck />
                            </SubmitStyledButton>
                          </td>
                          <td>
                            <NumberFormatStyledValue
                              id={`input${item?.id}`}
                              defaultValue={formatValue(
                                item?.quantidade * item?.unitary_price || '0.00'
                              )}
                              disabled
                              value={formatValue(
                                item?.quantidade * item?.unitary_price || '0.00'
                              )}
                            />
                          </td>
                          <td>
                            <FaTrash
                              color="#FF2C3A"
                              onClick={() => {
                                handleDeleteInputArray(item.input_id);
                              }}
                              cursor="pointer"
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </InputsTable>
                <InputsTable borderless>
                  <thead>
                    <tr>
                      <th>Produto Intermediário</th>
                      <th>Consumo</th>
                      <th>Valor</th>
                      <th>Ações</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td style={{ width: '60%' }}>
                        <SelectStyled
                          placeholder="Selecione o produto intermediário"
                          name="intermediary"
                          options={restaurantIntermediarySelect}
                          onChange={(e) => {
                            setIntermediarySelectedId(e.value);
                            setIntermediaryState(e);
                          }}
                        />
                      </td>
                      <td>
                        <InputStyled
                          name="intermediary_quantidade"
                          placeholder="0"
                          type="number"
                          onChange={(e) =>
                            setIntermediaryQuantidade(e.target.value)
                          }
                          value={intermediaryQuantidade}
                        />
                      </td>
                      <td>-</td>
                      <td>
                        {!isMultistoreChild && (
                          <PersonalButton
                            color="#0CCAB4"
                            message="Associar"
                            onClick={handleAddIntermediaryAtArray}
                            style={{ float: 'right' }}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <ButtonAdd onClick={toggleIntermediary}>
                        + Cadastrar novo
                      </ButtonAdd>
                    </tr>

                    {intermediaryArray &&
                      intermediaryArray.length > 0 &&
                      intermediaryArray.map((item) => (
                        <tr>
                          <td>{item.name}</td>
                          <td style={{ display: 'flex' }}>
                            <InputStyled
                              defaultValue={item.quantidade}
                              type="number"
                              key={item.quantidade}
                              onClick={() =>
                                handleActiveButton(item.intermediary_id)
                              }
                              onChange={(e) => {
                                setIntermediaryQuantidadeAtList(e.target.value);
                                setIntermediaryQuantidadeState({
                                  id: `inter${item?.id}`,
                                  quantidade: e.target.value,
                                  unitary_price: item?.unitary_price,
                                });
                              }}
                            />
                            <SubmitStyledButton
                              disabled={
                                !activeButtonsSubmitQuantidade.includes(
                                  item.intermediary_id
                                )
                              }
                              onClick={() =>
                                handleChangeIntermediaryQuantidadeAtArray(
                                  item.intermediary_id
                                )
                              }
                            >
                              <FaCheck />
                            </SubmitStyledButton>
                          </td>
                          <td>
                            <NumberFormatStyledValue
                              id={`inter${item?.id}`}
                              defaultValue={formatValue(
                                item?.quantidade * item?.unitary_price || '0.00'
                              )}
                              disabled
                              value={formatValue(
                                item?.quantidade * item?.unitary_price || '0.00'
                              )}
                            />
                          </td>
                          <td>
                            <FaTrash
                              color="#FF2C3A"
                              onClick={() => {
                                handleDeleteIntermediaryArray(
                                  item.intermediary_id
                                );
                              }}
                              cursor="pointer"
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </InputsTable>
              </>
            ) : (
              <ErrorContainer>
                <p style={{ fontSize: 23 }}>
                  Boas notícias! Seu plano atual já contempla a função de
                  controle de estoque!
                </p>
                <p style={{ fontSize: 16 }}>
                  Para ativar essa funcionalidade, entre em contato com o time
                  de suporte que sua ativação será realizada sem custos extras.
                </p>
              </ErrorContainer>
            )}
            <ButtonsArea
              style={{ position: 'unset', paddingRight: 0, width: '100%' }}
            >
              <PersonalButton
                color="#FF2C3A"
                message="Cancelar"
                style={{
                  marginLeft: 0,
                }}
                onClick={() => {
                  toggleModalCrerateComplement();
                }}
              />

              <div style={{ display: 'flex' }}>
                <OutlineButton
                  text="Voltar"
                  style={{
                    marginLeft: 0,
                  }}
                  onClick={() =>
                    user.has_nfce ? setActiveTab('2') : setActiveTab('1')
                  }
                />
                <PersonalButton
                  color="#0CCAB4"
                  message="Salvar"
                  type="button"
                  onClick={handleSubmitComplement}
                />
              </div>
            </ButtonsArea>
          </>
        )}

        <Modal isOpen={modalInput} toggle={toggleModalInput}>
          <Form onSubmit={handleInput} ref={formRef}>
            <ModalHeader>Cadastrar Insumos </ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col md="12">
                    <Input label="Nome do Insumo:" name="name" type="text" />
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <Input
                      label="Estoque atual:"
                      name="quantidade"
                      type="number"
                      step="0.001"
                      icon={
                        <FaQuestionCircle
                          title="Quantidade de insumo adiciondo ao estoque"
                          color="orange"
                          style={{ marginLeft: 5 }}
                        />
                      }
                    />
                  </Col>
                  <Col md="4">
                    <Select
                      label="Unidade:"
                      name="unidade"
                      options={unidade_options}
                      defaultValue={unidade_options[1]}
                    // onChange={(e) => setInputSelectedId(e.value)}
                    />
                  </Col>
                  <Col md="4">
                    <Input
                      label="Valor Gasto"
                      name="total_to_send"
                      type="number"
                      step="0.01"
                      defaultValue="1"
                      icon={
                        <FaQuestionCircle
                          title="Custo referente à quantidade de insumo adicionado."
                          color="orange"
                          style={{ marginLeft: 5 }}
                        />
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Input
                      label="Estoque Mínimo"
                      name="minimum_stock"
                      type="number"
                      step="0.001"
                      icon={
                        <FaQuestionCircle
                          title="Caso o estoque do Insumo esteja abaixo da quantidade mínima, você receberá uma notificação na central de tarefas."
                          color="orange"
                          style={{ marginLeft: 5 }}
                        />
                      }
                    />
                  </Col>
                  <Col md="6">
                    <Input
                      label="Estoque Ideal"
                      name="ideal_stock"
                      type="number"
                      step="0.001"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Select
                      label="Associar centro de custo"
                      name="category"
                      options={categories}
                      placeholder="Selecione a categoria"
                    // onChange={(e) => setInputSelectedId(e.value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <label>
                      <CheckboxInput
                        name="create_cashflow"
                        label="Vincular esta movimentação ao financeiro"
                        defaultChecked
                        onChange={() => {
                          handleCreateCashFlowOnNewInput();
                        }}
                      />
                    </label>
                  </div>
                </Row>

                {createCashFlowOnNewInput && (
                  <>
                    <p style={{ marginTop: 10 }}>
                      Informações Adicionais{' '}
                      <FaQuestionCircle
                        color="orange"
                        title="Complemente as informações do seu lançamento para uma melhor gestão de estoque."
                      />
                    </p>
                    <Row>
                      <Col>
                        <Row>
                          <Col md="9" style={{ marginLeft: 5 }}>
                            <Select
                              label="Fornecedor"
                              name="provider_id"
                              options={suppliers}
                              placeholder="Fornecedor"
                            // onChange={(e) => setInputSelectedId(e.value)}
                            />
                          </Col>
                          <Col md="1">
                            <button
                              type="button"
                              style={{
                                width: 32,
                                height: 32,
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                background: '#3BD2C1',
                                borderRadius: '50%',
                                border: 'none',
                                marginBottom: 15,
                              }}
                              title="Cadastrar Fornecedor"
                              onClick={toggleModalCreateSuplliers}
                            >
                              +
                            </button>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row>
                          <Col md="9" style={{ marginLeft: 5 }}>
                            <Select
                              label="Conta"
                              name="bank_account_id"
                              options={banks}
                              placeholder="Conta"
                            // onChange={(e) => setInputSelectedId(e.value)}
                            />
                          </Col>
                          <Col md="1">
                            <button
                              type="button"
                              style={{
                                width: 32,
                                height: 32,
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                background: '#3BD2C1',
                                borderRadius: '50%',
                                border: 'none',
                                marginBottom: 15,
                              }}
                              onClick={toggleModalCreateBank}
                              title="Cadastrar Conta Bancária"
                            >
                              +
                            </button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Input
                        id="date-local"
                        label="Vencimento"
                        type="date"
                        name="due_date"
                        defaultValue={format(new Date(), 'yyyy-MM-dd')}
                        className="data"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Row>
                    <Row>
                      <Col>
                        <CheckboxInput
                          name="paid"
                          label="Pago"
                          onChange={handleSetPaidCheck}
                        />
                      </Col>{' '}
                    </Row>

                    {paidCheck && (
                      <Row>
                        <Col>
                          <Input
                            id="date-local"
                            label="Pago em:"
                            type="date"
                            name="paid_at"
                            defaultValue={format(new Date(), 'yyyy-MM-dd')}
                            className="data"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Col>
                      </Row>
                    )}
                  </>
                )}
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={toggleModalInput}>
                Cancelar
              </Button>
              <Button color="success" type="submit">
                Salvar
              </Button>
            </ModalFooter>
          </Form>
        </Modal>

        <Modal isOpen={modalIntermediary} toggle={toggleIntermediary} size="xl">
          <Form onSubmit={handleIntermediary} ref={formIntermediaryRef}>
            <ModalHeader>Adicionar Produto Intermediário</ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col md="12">
                    <Input
                      label="Nome:"
                      name="name"
                      id="name_intermediary"
                      type="text"
                      onChange={(e) => setNameIntermediary(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <p>
                      O rendimento da ficha técnica (receita) que iremos
                      inserir, produz:
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <Input
                      name="yield"
                      defaultValue={1}
                      type="number"
                      step="0.001"
                      onChange={(e) => setYeldIntermediary(e.target.value)}
                    />
                  </Col>
                  <Col md="8">
                    <p style={{ marginTop: 15 }}>
                      {unidadeSelected} de{' '}
                      {nameIntermediary === '' ? '-' : nameIntermediary}
                    </p>
                  </Col>
                </Row>
                <Row
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <Col md="12" style={{ marginTop: 10 }}>
                    <p>Ficha Técnica</p>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Select
                      label="Insumos"
                      name="inputs_select"
                      options={restaurantInputsSelect}
                      placeholder="Selecione"
                      onChange={(e) => setSelectInput(e)}
                      icon={
                        <FaQuestionCircle
                          title="Vincule os insumos usados na receita para produzir seu produto intermediário"
                          color="orange"
                          style={{ marginLeft: 5 }}
                        />
                      }
                    />
                  </Col>
                  <Col md="4">
                    <Input
                      label="Quantidade"
                      name="quantidade"
                      type="text"
                      defaultValue={0}
                      onChange={(e) => {
                        setDefaultInterValue(e.target.value);
                        document.getElementById('add').disabled = false;
                      }}
                      icon={
                        <FaQuestionCircle
                          title="Insira a quantidade necessária utilizada na receita para produzir seu produto intermediário."
                          color="orange"
                          style={{ marginLeft: 5 }}
                        />
                      }
                    />
                  </Col>
                  <Col md="2" style={{ margin: 'auto', marginBottom: 15 }}>
                    <button
                      type="button"
                      id="add"
                      style={{
                        background: '#3BD2C1',
                        width: 40,
                        height: 40,
                        borderRadius: '50%',
                        border: 'none',
                        fontSize: 16,
                      }}
                      onClick={() =>
                        insertInputInter(selectInput, defaultInterValue)
                      }
                    >
                      +
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <InputsTable borderless>
                      <thead>
                        <tr>
                          <th>Insumo</th>
                          <th>
                            Consumo unitário{' '}
                            <FaQuestionCircle
                              title="Quantidade necessária para
                            se produzir uma unidade do Produto Intermediário."
                              color="orange"
                              style={{ marginLeft: 2 }}
                            />
                          </th>
                          <th>
                            Custo do insumo{' '}
                            <FaQuestionCircle
                              title="Custo de uma unidade do insumo."
                              color="orange"
                              style={{ marginLeft: 2 }}
                            />
                          </th>
                          <th>
                            Consumo total{' '}
                            <FaQuestionCircle
                              title="Quantidade necessária para
                                se produzir uma receita do Produto Intermediário."
                              color="orange"
                              style={{ marginLeft: 2 }}
                            />
                          </th>
                          <th>
                            Custo da receita{' '}
                            <FaQuestionCircle
                              title="Custo para se produzir uma receita do Produto Intermediário."
                              color="orange"
                              style={{ marginLeft: 2 }}
                            />
                          </th>
                          <th>Disponível</th>
                          <th>Ações</th>
                        </tr>
                      </thead>

                      <tbody>
                        {interInputs.length > 0 &&
                          interInputs.map((input) => (
                            <tr>
                              <td>{input.name}</td>
                              <td>
                                {parseFloat(
                                  input.quantidade_inter_value /
                                  yeldIntermediary
                                ).toLocaleString('pt-BR', {
                                  minimumFractionDigits: 2,
                                })}{' '}
                                {input.unidade}
                              </td>

                              <td>
                                R$
                                {parseFloat(input.unitary_price).toLocaleString(
                                  'pt-BR',
                                  {
                                    minimumFractionDigits: 2,
                                  }
                                )}
                              </td>
                              <td>
                                {parseFloat(input.quantidade_inter_value)
                                  .toFixed(3)
                                  .replace('.', ',')}{' '}
                                {input.unidade}
                              </td>

                              <td>
                                R$
                                {parseFloat(
                                  input.quantidade_inter_value *
                                  // yeldIntermediary *
                                  input.unitary_price
                                ).toLocaleString('pt-BR', {
                                  minimumFractionDigits: 2,
                                })}
                              </td>
                              <td>
                                {' '}
                                {input.quantidade.replace('.', ',')}{' '}
                                {input.unidade}
                              </td>

                              <td>
                                <Delete
                                  type="button"
                                  onClick={() => deleteInputInter(input.id)}
                                >
                                  <MdDelete size={20} />
                                </Delete>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </InputsTable>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ margin: 5 }} />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Select
                      label="Prod. Interm."
                      name="inter_select"
                      options={restaurantIntermediarySelect}
                      placeholder="Selecione"
                      onChange={(e) => setSelectInter(e)}
                      icon={
                        <FaQuestionCircle
                          title="Vincule os produtos intermediários usados na receita para produzir seu novo produto intermediário."
                          color="orange"
                          style={{ marginLeft: 5 }}
                        />
                      }
                    />
                  </Col>
                  <Col md="4">
                    <Input
                      label="Quantidade"
                      name="quantidade"
                      type="text"
                      defaultValue={0}
                      onChange={(e) => {
                        setDefaultInterInterValue(
                          e.target.value.replace(',', '.')
                        );
                        document.getElementById('add1').disabled = false;
                      }}
                    />
                  </Col>
                  <Col md="2" style={{ margin: 'auto', marginBottom: 15 }}>
                    <button
                      type="button"
                      id="add1"
                      style={{
                        background: '#3BD2C1',
                        width: 40,
                        height: 40,
                        borderRadius: '50%',
                        border: 'none',
                        fontSize: 16,
                      }}
                      onClick={() =>
                        insertInterInter(selectInter, defaultInterInterValue)
                      }
                    >
                      +
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <InputsTable borderless>
                      <thead>
                        <tr>
                          <th>Prod. interm.</th>
                          <th>Consumo nitário</th>
                          <th>Custo do Prod. Int.</th>
                          <th>Consumo total</th>
                          <th>Custo da receita</th>
                          <th>Disponível</th>
                          <th>Ações</th>
                        </tr>
                      </thead>

                      <tbody>
                        {interInter.length > 0 &&
                          interInter.map((inter) => (
                            <tr>
                              <td>{inter.name}</td>
                              <td>
                                {parseFloat(
                                  inter.quantidade_inter_value /
                                  yeldIntermediary
                                ).toLocaleString('pt-BR', {
                                  minimumFractionDigits: 2,
                                })}{' '}
                                {inter.unidade}
                              </td>

                              <td>
                                R$
                                {parseFloat(inter.unitary_price).toLocaleString(
                                  'pt-BR',
                                  {
                                    minimumFractionDigits: 2,
                                  }
                                )}
                              </td>
                              <td>
                                {parseFloat(inter.quantidade_inter_value)
                                  .toFixed(3)
                                  .replace('.', ',')}
                                {inter.unidade}
                              </td>

                              <td>
                                R$
                                {parseFloat(
                                  inter.quantidade_inter_value *
                                  // yeldIntermediary *
                                  inter.unitary_price
                                ).toLocaleString('pt-BR', {
                                  minimumFractionDigits: 2,
                                })}
                              </td>
                              <td>
                                <td>
                                  {' '}
                                  {inter.quantidade.replace('.', ',')}{' '}
                                  {inter.unidade}
                                </td>
                              </td>

                              <td>
                                <Delete
                                  type="button"
                                  onClick={() => deleteInterInter(inter.id)}
                                >
                                  <MdDelete size={20} />
                                </Delete>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </InputsTable>
                  </Col>
                </Row>

                <Row style={{ marginTop: 10 }}>
                  <Col style={{ marginTop: 10 }}>
                    <p>Receita</p>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <TextareaContainer
                      name="recipe"
                      placeholder="Salve a receita do produto intermediário"
                      type="text"
                      maxLength="500"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="7">
                    <Input
                      label="Quantidade em Estoque"
                      name="quantidade_inter"
                      onChange={(e) => setQuantInter(e.target.value)}
                      type="number"
                      step="0.001"
                      icon={
                        <FaQuestionCircle
                          title="Quantidade criada do Produto Intermediário nessa receita."
                          color="orange"
                          style={{ marginLeft: 5 }}
                        />
                      }
                    />
                  </Col>
                  <Col md="5" style={{ marginTop: 0 }}>
                    <Select
                      label="Unidade:"
                      name="unidade"
                      options={unidade_options}
                      defaultValue={unidade_options[1]}
                      onChange={(e) => setUnidadeSelected(e.label)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Input
                      label="Estoque Mínimo"
                      name="minimum_stock"
                      icon={
                        <FaQuestionCircle
                          title="Caso o estoque do Produto Intermediário esteja abaixo da quantidade mínima, você receberá uma notificação na central de tarefas.."
                          color="orange"
                          style={{ marginLeft: 5 }}
                        />
                      }
                    />
                  </Col>
                  <Col md="6">
                    <Input label="Estoque Ideal" name="ideal_stock" />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={toggleIntermediary}>
                Cancelar
              </Button>
              <Button color="primary" type="submit">
                Salvar
              </Button>{' '}
            </ModalFooter>
          </Form>
        </Modal>
        <Modal
          isOpen={isModalCreateSuppliersOpen}
          toggle={toggleModalCreateSuplliers}
        >
          <ModalHeader> Criar Fornecedor</ModalHeader>
          <Form onSubmit={handleSupplierSubmit} ref={formRefSupplier}>
            <ModalBody>
              <Input label="Nome do fornecedor" name="name" />
              <Input label="CNPJ" name="cnpj" />
              <InputMask mask="(99) 99999-9999">
                <Input
                  label="Telefone"
                  name="phone"
                  maskChar=""
                  placeholder="(xx) xxxxx-xxxx"
                />
              </InputMask>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={toggleModalCreateSuplliers}>
                Cancelar
              </Button>
              <Button color="success" type="submit">
                Salvar
              </Button>
            </ModalFooter>
          </Form>
        </Modal>

        <Modal isOpen={isModalCreateBankOpen} toggle={toggleModalCreateBank}>
          <ModalHeader> Criar Conta</ModalHeader>
          <Form onSubmit={handleBankSubmit} ref={formRefBank}>
            <ModalBody>
              <Input label="Nome do banco" name="name" />
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={toggleModalCreateBank}>
                Cancelar
              </Button>
              <Button color="success" type="submit">
                Salvar
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Container>
    </>
  );
}

export default CreateComplementInsideProduct;
