import React, { useState } from 'react';

import {
  Spinner,
  Row,
  Col,
} from 'reactstrap';

import {
  CategoriesTable,
  Container,
  LoadingDiv,
} from './styles';

import Nfe from './Nfe';
import StockTransferManifestRow from '../Rows/StockTransferManifestRow';
import { Form } from '@unform/web';
import Input from '../Form/Input';

export default function Nfes({ nfes, loading, html, inputs, cashFlows, handleUpdate, getInputs, getNfes }) {
  const [search, setSearch] = useState('');

  return (
    <Container>
      <Row style={{paddingRight: 20, paddingLeft: 20}}>
        <Col md="12">
          <Form>
            <Input
              name="search"
              type="text"
              placeholder="Pesquise pelo número, chave da nota ou fornecedor"
              onChange={e => setSearch(e.target.value)}
            />
          </Form>
        </Col>
      </Row>

      <CategoriesTable borderless>
        <thead>
          <tr>
            <th>Fornecedor</th>
            <th>Emissão</th>
            <th>Chave</th>
            <th>Nº NFe</th>
            <th>Valor</th>
            <th>Ações</th>
          </tr>
        </thead>
        {loading ? (
          <tbody>
            <tr>
              <td />
              <td />
              <td />
              <td />
              <td>
                <LoadingDiv>
                  <Spinner color="info" />
                </LoadingDiv>
              </td>
              <td />
              <td />
              <td />
              <td />
            </tr>
          </tbody>
        ) : (
          <tbody>
            {nfes
            .filter(nfe => search !== '' ? (nfe?.chave_nfe.includes(search) || nfe?.numero.includes(search) || (nfe?.nome_emitente && nfe?.nome_emitente?.toLowerCase().includes(search?.toLowerCase()))) : true)
            .map((nfe) => (!nfe.is_stock ?
              <Nfe
                key={nfe.id}
                nfe={nfe} html={html}
                getInputs={getInputs}
                inputs={inputs}
                handleUpdate={handleUpdate}
                cashFlows={cashFlows}
              />
              :
              <StockTransferManifestRow
                key={nfe.id}
                stock={nfe}
                getNfes={getNfes}
              />
            ))}
          </tbody>
        )}
      </CategoriesTable>
    </Container>
  );
}
