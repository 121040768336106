import React from 'react';

import { RiBillLine } from 'react-icons/ri';
import { FaFileInvoiceDollar } from 'react-icons/fa';

import { ButtonContainer } from './styles';

export default function ButtonDefault({ label, color, ...rest }) {
  return (
    <ButtonContainer type="button" {...rest} color={color}>
      <span>
        {color === '#0dcaf0' ? <FaFileInvoiceDollar /> : <RiBillLine />} {label}
      </span>
    </ButtonContainer>
  );
}
