import React, { useState, useEffect, useCallback } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import { v4 } from 'uuid';

import { Content, ButtonsArea } from './styles';

import api from '~/services/api';

import Select from '~/components/Form/SelectInput';

import { useMenu } from '~/context/OrderingSystem/Menu';
import PersonalButton from '~/components/Buttons/PersonalButton';

function AssociateComplement({
  category,
  toggle,
  getCategories,
  setCount,
  productsCategories,
  setProductsCategories,
  categories,
  complementsCategories,
  setComplementsCategories,
}) {
  const [complements, setComplements] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { getMenu } = useMenu();

  const complementsInitial = complementsCategories.find(cat => cat.id === category.id).complements.map((complement) => ({
    value: complement.id,
    label: `${complement.name} (R$${complement.price})`,
  }));

  // const getComplementsByCategoryId = useCallback(async () => {
  //   const response = await api.get(`/restaurants/product/complement-category/${category.id}`);

  //   const data = response.data.complements.map((complement) => ({
  //     value: complement.id,
  //     label: complement.name,
  //   }));

  //   setComplementsCategory(data);
  // }, []);

  const getComplements = useCallback(async () => {
    const response = await api.get('/restaurants/product/complements');

    const data = response.data.map((complement) => ({
      value: complement.id,
      label: `${complement.name} (R$${complement.price})`,
    }));

    setComplements(data);
  }, []);

  useEffect(() => {
    try {
      getComplements();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getComplements]);

  // useEffect(() => {
  //   getComplementsByCategoryId();
  // }, [getComplementsByCategoryId])

  async function handleSubmit(data) {
    try {
      const response = await api.put(
        `restaurants/product/complement-category/${category.id}`,
        {
          complements: data.complements,
        }
      );

      const aleatoryId = v4();

      setCount(aleatoryId);

      getMenu();

      setSelectedCategory(response.data.id);
      toggle();

      const newComplementsCategories = [...complementsCategories];

      newComplementsCategories.map((cat, idix) => {
        if (cat.id === category.id) {
          newComplementsCategories[idix].complements =
            response.data.complements;
        }
      });

      setComplementsCategories(newComplementsCategories);

      await toast.success('Categoria cadastrada com sucesso!');
    } catch (error) {
      if (selectedCategory) {
        await api.delete(
          `/restaurants/product/complement-category/${selectedCategory}`
        );
      }
      toast.error('Falha ao salvar categoria');
    }
  }

  console.log('categoryyyyy', category);

  return (
    
        <Content>

            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
              }}
              onSubmit={handleSubmit}
            >
              <div>
                <h3>Complementos</h3>

                <Select
                  label="Associe complementos já existente"
                  name="complements"
                  isMulti
                  defaultValue={complementsInitial}
                  options={complements}
                  disabled={category.is_multistore_child || false}
                />

                {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <RegisterButton
                    text="... ou crie um novo Complemento"
                    disabled={createNewComplementArea}
                    onClick={() => setCreateNewComplementArea(true)}
                  />
                </div>  */}
               </div>

              <ButtonsArea>
                <PersonalButton
                  color="#FF2C3A"
                  message="Cancelar"
                  style={{
                    marginLeft: 0,
                  }}
                  onClick={() => {
                    toggle();
                  }}
                />
                <PersonalButton
                  color="#0CCAB4"
                  message="Salvar"
                  type="submit"
                />
              </ButtonsArea>
            </Form>
        
 
        </Content>  
  );
}

export default AssociateComplement;
