import React, { useEffect } from 'react';
import { SelectComplementsContainer } from './styles';
import { ComplementsArea } from './ComplementsArea';
import { PreCart } from './PreCart';
import { usePreCart } from '~/context/PreCartContext';

export const SelectComplements = ({ product, setIsSelectingComplements }) => {
  const { setPreCart, setCategoriesRequired } = usePreCart();

  useEffect(() => {
    setPreCart({
      product: product,
      amount: 1,
      observation: '',
      complementsCategory: [],
      isProductWeight: product.use_weight,
    });
    setCategoriesRequired(
      product.complement_categories.filter((category) => category.optional === false)
    )
  }, []);

  return (
    <SelectComplementsContainer>
      <ComplementsArea
        product={product}
        setIsSelectingComplements={setIsSelectingComplements}
      />
      <PreCart setIsSelectingComplements={setIsSelectingComplements} />
    </SelectComplementsContainer>
  );
};
