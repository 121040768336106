import React from 'react';

import { Container, Order, Items } from './styles';
import ItemOrdersBill from './ItemOrdersBill';

export default function DeleteItemOrdersBill({
  item,
  from,
  session,
  setAllTablesAvailable,
  setInputValue,
  setTablesFilteredSearch,
  handleCancelItem
}) {
  return (
    <Container>
      <Order>Pedido #{item?.basket_id}</Order>
      <p style={{ marginTop: 10, fontSize: 13, textAlign: "center" }}>Selecione o item ou quantidade de itens que deseja cancelar</p>
      <Items>
        {item?.orders.map((order) => (
          <ItemOrdersBill
            item={item}
            order={order}
            from={from}
            session={session}
            setAllTablesAvailable={setAllTablesAvailable}
            setInputValue={setInputValue}
            setTablesFilteredSearch={setTablesFilteredSearch}
            handleCancelItem={handleCancelItem}
          />
        ))}
      </Items>
    </Container>
  );
}
