import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  span {
    color: #4d4d4c;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  h3 {
    margin: 0;
    color: #4d4d4c;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }
`;
