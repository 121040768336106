import React, { useCallback, useState, useEffect } from 'react';

import api from '~/services/api';

import BillItem from '../BillItem';
import { Container } from './styles';

function PrePaidExtract({ tableId }) {
  const [tableBills, setTableBills] = useState([]);

  const getTableBills = useCallback(async () => {
    const response = await api.get(`/restaurants/prepaid-sessions/${tableId}`);

    setTableBills(response.data);
  }, [tableId]);

  useEffect(() => {
    try {
      getTableBills();
    } catch (error) {
      console.log(error.message);
    }
  }, [getTableBills]);

  return tableBills.length > 0 ? (
    <Container>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 10,
        }}
      >
        <span>Pedido</span>

        <span>Valor</span>
      </div>
      {tableBills &&
        tableBills.length > 0 &&
        tableBills.map((item) => <BillItem item={item} />)}
    </Container>
  ) : (
    <p style={{ textAlign: 'center' }}>Sem comandas no momento ...</p>
  );
}

export default PrePaidExtract;
