import styled from 'styled-components';

export const Header = styled.div`
  justify-content: center;
  font-size: 14px;
  padding: 10px;
  height: 25px;
  text-align: center;
  align-items: center;
  color: #33333370;
  font-weight: 400;
`;

export const Content = styled.div`
  display: flex;
  font-size: 16px;
  height: 75px;

  align-items: center;
  justify-content: center;
  clear: both;

  svg {
    margin-right: 8px;
  }
`;

export const Icon = styled.div`
  font-size: 25px;
`;

export const Value = styled.div`
  font-size: 18px;

  p {
    font-size: 16px;
    margin-top: 8px;

    span {
      display: flex;
      flex-direction: column;
      margin-top: 4px;
    }
  }
`;

export const Bloco = styled.div`
  height: 113px;
  padding: 0px;
  display: flex;
  flex-direction: column;

  background: white;
  border-radius: 8px;
  color: #333;
  font-weight: 700;

  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  text-align: center;
  transition-duration: 0.2s;

  &:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
`;

export const IconTakeat = styled.span`
  float: left;
  margin-left: 25px;
  margin-top: -3px;

  img {
    width: 25px;
    height: 50px;
  }
`;

export const Rate = styled.span`
  padding-left: 2px;
  padding-bottom: 6px;
  font-size: 16px;
`;

export const Comment = styled.div`
  bottom: 0;
  right: 0;
  position: absolute;
  margin-bottom: 0px;
  width: 100%;
  text-align: center;
  justify-content: center;
  background: none;
  font-weight: normal;

  color: #33333370;

  span {
    font-size: 13px;
  }
`;

export const RateContent = styled.div`
  width: 120px;

  p {
    margin-top: 8px;
    margin-bottom: 1px;
  }

  svg {
    font-size: 18px;
    margin-top: 10px;
  }
`;

export const CashReport = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 12px;
    margin-top: 8px;
    font-weight: normal;
    color: #007c7770;
  }
`;
