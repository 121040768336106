import { Modal } from 'reactstrap';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  .modal-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  h3 {
    margin: 0;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    color: #4d4d4c;
  }
`;

export const DiscountGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
`;

export const DiscountInfoTable = styled.div`
  display: flex;
  flex-direction: column;

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;

    font-size: 14px;
    font-weight: 500;
  }

  div:first-child {
    font-size: 16px;
    font-weight: 600;
  }

  span {
    width: 25%;
  }
`;
