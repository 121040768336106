import React, { useEffect, useState } from 'react';
import {
  InfoGrid,
  InfoGridPrice,
  InfoGridRow,
  InfoGridRowTitle,
  InfoGridWeight,
  OrderInformation,
  OrderInformationTitle,
  EmptyList,
  NameSpan,
  IconsSpan,
  InfoArea
} from './styles';
import { FaRegTrashAlt } from 'react-icons/fa';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
import formatValue from '~/utils/formatValue';
export const ProductDetails = ({
  cart,
  increment,
  decrement,
  removeFromCart,
}) => {
  const [balanceProducts, setBalanceProducts] = useState([{}]);

  function handleDecrementCart(product) {
    if (product.amount === 1) {
      removeFromCart(product.basket_id, product.id);
    } else {
      decrement(product.id, product.ordering_system_id);
    }
  }

  useEffect(() => {
    const cartFilteredByProductWeight = cart.filter(
      (product) => product.use_weight === true
    );
    setBalanceProducts(cartFilteredByProductWeight);
  }, [cart]);

  return (
    <>
      <OrderInformation>
        <OrderInformationTitle>Balança</OrderInformationTitle>
        {balanceProducts.length > 0 ? (
          <>
            {cart?.map(
              (product) =>
                product.use_weight && (
                  <InfoArea>
                    <InfoGridRow style={{paddingTop:7}}>
                      <InfoGridRowTitle>{product.name}</InfoGridRowTitle>
                      <InfoGridPrice>
                        {formatValue(product.weight_price)}
                      </InfoGridPrice>
                      <FaRegTrashAlt
                        color="#C8131B"
                        size={16}
                        onClick={() =>
                          removeFromCart(product.basket_id, product.id)
                        }
                        style={{ cursor: 'pointer' }}
                      />
                    </InfoGridRow>
                    <InfoGridRow style={{paddingBottom:17,paddingTop:4}}>
                      <InfoGridRowTitle style={{ fontSize: 12 }}>
                        R$/Kg: {formatValue(product.price).replace(/(R\$\s)/, '')}
                      </InfoGridRowTitle>
                      <InfoGridWeight>
                        {product.weight?.replace('.', ',')}kg
                      </InfoGridWeight>
                      <div style={{ width: 16, height: 16 }} />
                    </InfoGridRow>
                  </InfoArea>
                )
            )}
          </>
        ) : (
          <EmptyList>Nenhum produto pesado</EmptyList>
        )}
      </OrderInformation>

      <OrderInformation>
        <OrderInformationTitle>Adicionais</OrderInformationTitle>
        {cart.length === 0 ? (
          <EmptyList>Nenhum produto selecionado</EmptyList>
        ) : (
          cart.map((product) => (
            <InfoGrid key={product.id}>
              {!product.use_weight && (
                <>
                  <NameSpan>{product.name}</NameSpan>

                  <IconsSpan>
                    <FiMinusCircle
                      onClick={() => handleDecrementCart(product)}
                      color="#C8131B"
                    />
                    <span>{product.amount}</span>
                    <FiPlusCircle
                      color="#C8131B"
                      onClick={() =>
                        increment(product.id, product.ordering_system_id)
                      }
                    />
                    <FaRegTrashAlt
                      color="#C8131B"
                      onClick={() =>
                        removeFromCart(product.basket_id, product.id)
                      }
                    />
                  </IconsSpan>
                </>
              )}
            </InfoGrid>
          ))
        )}
      </OrderInformation>
    </>
  );
};
