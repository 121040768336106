import styled from 'styled-components';

export const Container = styled.div`
  @page {
    size: 75mm auto;
    padding: 10px;
   
  }
  @media print {
    * {
      background: transparent;
      color: #000;
      text-shadow: none;
      filter: none;
      -ms-filter: none;
    }
    html,
    body {
      width: 75mm;
      height: auto;
    }
    h1 {
      margin-right: auto;
    }
    img {
      width: 70mm;
      height: auto;
    }
  }

  padding-left: 20px;
 
`;

export const Table = styled.div`
  padding-top: 10px;
  width: 110mm;
  text-align: center;
  font-size: 38px;
  margin-right: auto;
`;

export const Order = styled.div`
  padding-top: 5px;
  width: 110mm;
  text-align: center;
  font-size: 25px;
  margin-right: auto;
`;

export const Restaurant = styled.div`
  padding-top: 40px;
  padding-bottom: 20px;
  width: 110mm;
  text-align: center;
  font-size: 20px;
  margin-right: auto;
  text-transform: uppercase;
`;

export const Body = styled.div`
  width: 110mm;
  clear: both;
  display: flex;
  justify-content: space-between; padding-left: 10px;
  padding-right: 25px;
`;

export const Phone = styled.div`
  text-align: left;
  padding-left: 30px;
  font-size: 22px;
  padding-top: 10px;
`;

export const Time = styled.div`
  text-align: right;

  font-size: 20px;
  padding-top: 10px;
`;

export const Items = styled.div`
  width: 110mm;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 25px;

  span {
    font-size: 20px;
  }
`;

export const Item = styled.div`
  
  padding-top: 12px;
`;

export const ItemName = styled.div`
  font-size: 22px;
  display: flex;
  justify-content: space-between;

  strong {
    font-size: 18px;
  }
`;

export const ComplementsTitle = styled.div`
  font-size: 20px;
  padding-left: 10px;
  
`;

export const Complements = styled.div`
  font-size: 20px;
  padding-left: 10px;
`;

export const Obs = styled.div`
  font-size: 20px;
`;

export const ItemHeader = styled.div`
  height: 24px;
  width: 110mm;
  background: #000;
  color: #fff;
  padding-right: 25px;

  span{
    color: #fff;
    margin-right: 8px;
    font-size: 20px;
  }



`


export const Footer = styled.div`
  margin-top: 20px;
  padding-top: 10px;
  border-top: solid #858585 1px;
  font-size: 14px;
  text-align: center;
  width: 110mm;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
`;

export const DeliveryInfo = styled.div`
  padding-top: 10px;
  margin-top: 10px;
width: 110mm;
  padding-bottom: 10px;

  display: flex;
  flex-direction: column;
  padding-left: 10px;


  span {
    font-size: 20px;
  }
`;

export const DeliveryPrice = styled.div`
  padding-top: 15px;
  margin-top: 10px;
  border-top: solid black 1px;
  padding-bottom: 10px;
  width: 110mm;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  font-size: 20px;
  padding-right: 25px;

  div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;

    span {
      font-size: 20px;
      font-weight: bold;
    }
  }
`;
