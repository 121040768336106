import React, { useRef } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import { Row, Col } from 'reactstrap';
import getValidationErrors from '~/services/getValidationErrors';

import api from '~/services/api';

import Input from '~/components/Form/Input';
import SaveButton from '~/components/Buttons/SaveButton';
import { useAuth } from '~/context/AuthContext';

function CreateWaiter({ toggleModalRegisterWaiter, getWaiters }) {
  const formRef = useRef(null);
  const { user } = useAuth();
  async function handleSubmit(data) {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        email: Yup.string()
          .email('Inserir um email válido')
          .required('Email ogrigatório'),
        password: Yup.string()
          .min(6, 'A senha precisa de 6 dígitos')
          .required('A senha é obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post('restaurants/waiters', {
        name: data.name,
        email: data.email,
        password: data.password,
        pdv_key: data.pdv_key,
      });

      toast.success('Garçom criado com sucesso!');

      toggleModalRegisterWaiter();
      getWaiters();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else if (!error.response.ok) {
        switch (error.response.data.errorType) {
          case 'waiter_pdv_key_alreary_exists':
            toast.error('Senha já usada por outro garçom');
            break;
          case 'email_already_exists':
            toast.error('Email já usado por outro garçom');
            break;
          default:
            toast.error('Falha ao criar garçom');
        }
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit} ref={formRef}>
      <div>
        <Row>
          <Col sm="12">
            <Input label="Nome do Garçom:" name="name" type="text" />
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <Input label="E-mail do Garçom:" name="email" type="text" />
          </Col>
          <Col sm="6">
            <Input label="Senha do Garçom:" name="password" type="password" />
          </Col>
        </Row>
        {user.ask_waiter_key && (
          <Row>
            <Col sm="12">
              <Input
                label="Senha para abrir mesas pelo PDV: (3 dígitos)"
                name="pdv_key"
                type="text"
                maxlength={3}
              />
            </Col>
          </Row>
        )}

        <SaveButton type="submit" style={{ float: 'right' }}>
          Cadastrar
        </SaveButton>
      </div>
    </Form>
  );
}

export default CreateWaiter;
