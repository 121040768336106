import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { TextareaContainer } from './styles';

function Input({ name, label, maxlength, size=null,...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [value, setValue] = React.useState('');

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, inputRef, registerField]);

  return (
    <TextareaContainer size={size}>
      <div>
        <label htmlFor={fieldName}>{label}</label>
        {maxlength && (
          <span
            style={{
              marginLeft: 8,
              fontWeight: 500,
              color: value.length > maxlength ? '#FF2C3A' : '#0CCAB4',
            }}
          >
            {`${value.length || 0}/${maxlength}`}
          </span>
        )}
      </div>
      <textarea maxLength={maxlength} onChange={(e) => setValue(e.target.value)} ref={inputRef} defaultValue={defaultValue} {...rest} />
      {error && <span>{error}</span>}
    </TextareaContainer>
  );
}

export default Input;
