import React, { useEffect, useState } from 'react';
import { Button, DefaultInput, DefaultSelect } from 'ui-kit-takeat';
import { DiscountGrid, DiscountInfoTable, StyledModal } from './styles';
import { CurrencyInput } from '~/components/CurrencyInput';
import formatValue from '~/utils/formatValue';
import { useAuth } from '~/context/AuthContext';
import toast from 'react-hot-toast';

export const ModalDiscount = ({
  isOpen,
  setIsOpen,
  total,
  discountObsOptions,
  onApplyDiscount,
}) => {
  const { user } = useAuth();
  const [discount, setDiscount] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [discountObs, setDiscountObs] = useState(null);
  const [discountMotive, setDiscountMotive] = useState('');
  const [discountObservation, setDiscountObservation] = useState(null);
  const [adminPassword, setAdminPassword] = useState('');

  useEffect(() => {
    console.log('discountObs', discountObs);
  }, [discountObs]);

  return (
    <StyledModal isOpen={isOpen} toggle={() => setIsOpen((state) => !state)}>
      <h3>Aplicar desconto</h3>
      <p>Desconto deve ser entre R$ 0,00 e {formatValue(total)}</p>

      <DiscountGrid>
        <Button
          title="5%"
          buttonColor="#01afff"
          onClick={() => {
            setDiscount((total * 0.05).toFixed(2));
            setDiscountPercent(5);
          }}
        />
        <Button
          title="10%"
          buttonColor="#01afff"
          onClick={() => {
            setDiscount((total * 0.1).toFixed(2));
            setDiscountPercent(10);
          }}
        />
        <Button
          title="15%"
          buttonColor="#01afff"
          onClick={() => {
            setDiscount(total * (0.15).toFixed(2));
            setDiscountPercent(15);
          }}
        />
        <Button
          title="25%"
          buttonColor="#01afff"
          onClick={() => {
            setDiscount((total * 0.25).toFixed(2));
            setDiscountPercent(25);
          }}
        />
        <Button
          title="50%"
          buttonColor="#01afff"
          onClick={() => {
            setDiscount((total * 0.5).toFixed(2));
            setDiscountPercent(50);
          }}
        />
        <Button
          title="100%"
          buttonColor="#01afff"
          onClick={() => {
            setDiscount(total.toFixed(2));
            setDiscountPercent(100);
          }}
        />
      </DiscountGrid>

      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <CurrencyInput
          onChange={(e) => {
            console.log('Trigger on change discount');
            const value = e.floatValue || 0;
            setDiscount((value / 100).toFixed(2));
            setDiscountPercent(
              total === 0 ? 0 : ((value / 100 / total) * 100).toFixed(2)
            );
          }}
        />
        <DefaultInput
          placeholder="%"
          width="150px"
          onChange={(e) => {
            console.log('Trigger on change discount percent');
            const value = e.target.value;
            setDiscountPercent(value);
            setDiscount((total * (value / 100)).toFixed(2));
          }}
        />
      </div>

      <DiscountInfoTable>
        <div>
          <span>Total antigo</span>
          <span style={{ textAlign: 'center' }}>Desconto</span>
          <span style={{ textAlign: 'right' }}>Novo total</span>
        </div>
        <div>
          <span>{formatValue(total)}</span>
          <span style={{ textAlign: 'center' }}>
            {formatValue(discount)} |{' '}
            {parseFloat(discountPercent || 0).toLocaleString('pt-br', {
              maximumFractionDigits: 2,
            })}
            %
          </span>
          <span style={{ textAlign: 'right' }}>
            {formatValue(total - discount)}
          </span>
        </div>
      </DiscountInfoTable>

      <DefaultSelect
        label="Motivo"
        options={discountObsOptions}
        value={discountObsOptions.find((item) => item.value === discountObs)}
        onChange={(e) => {
          setDiscountObs(e.value);
          setDiscountMotive(e.label === 'Outro' ? '' : e.label);
        }}
      />
      {discountObs === 'other' && (
        <DefaultInput
          placeholder="Escreva o motivo"
          name="motive"
          value={discountMotive}
          onChange={(e) => setDiscountMotive(e.target.value)}
        />
      )}

      <DefaultInput
        type="text"
        placeholder="Observação"
        id="observation"
        name="observation"
        style={{ height: 40, padding: '7px 14px' }}
        value={discountObservation}
        onChange={(e) => setDiscountObservation(e.target.value)}
      />

      {user.order_cancel_password !== '' && (
        <DefaultInput
          title="Senha administrativa:"
          id="cancel_password"
          name="testing"
          type="password"
          autocomplete="new-password"
          value={adminPassword}
          onChange={(e) => setAdminPassword(e.target.value)}
        />
      )}

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button title="Cancelar" onClick={() => setIsOpen(false)} />
        <Button
          title="Aplicar desconto"
          buttonColor="#2ec9b7"
          onClick={() => {
            if (adminPassword !== user.order_cancel_password) {
              toast.error('Senha administrativa incorreta');
            } else {
              toast.success('Desconto aplicado com sucesso');
              onApplyDiscount(discount, discountMotive, adminPassword, discountObservation);
              setIsOpen(false);
            }
          }}
          disabled={
            discountPercent > 100 ||
            discountPercent === 0 ||
            discountObs === '' ||
            !discountObs
          }
        />
      </div>
    </StyledModal>
  );
};
