import React from 'react';

import { BsDownload } from 'react-icons/bs';

import { ButtonContainer } from './styles';

export default function DownloadButton({ ...rest }) {
  return (
    <ButtonContainer type="button" style={{ background: '#28a745' }} {...rest}>
      <BsDownload color="#fff" size={16} />
      <span style={{ marginLeft: 5 }}>BAIXAR</span>
    </ButtonContainer>
  );
}
