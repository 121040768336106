import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import { Row, Col } from 'reactstrap';
import Switch from 'react-switch';
import { Container, ButtonsArea, InnerArea, AreaSwitcher } from './styles';
import api from '~/services/api';

import Input from '~/components/Form/Input';
import CheckboxInput from '~/components/Form/Checkbox';
import Select from '~/components/Form/SelectInput';

import getValidationErrors from '~/services/getValidationErrors';
import PersonalButton from '~/components/Buttons/PersonalButton';
import { useAuth } from '~/context/AuthContext';
import { Button, DefaultInput } from 'ui-kit-takeat';
import { PersonalizedHoursContainer } from '../../Edit/styles';

function CreateComplementCategory({
  setComplementsCategories,
  complementsCategories,
  toggle,
}) {
  const [complements, setComplements] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const formRef = useRef(null);
  const { user } = useAuth();
  const getComplements = useCallback(async () => {
    const response = await api.get('/restaurants/product/complements');

    const data = response.data.map((complement) => ({
      value: complement.id,
      label: complement.name,
    }));

    setComplements(data);
  }, []);

  const [enableTimes, setEnableTimes] = useState(false);
  const [daysSelected, setDaysSelected] = useState([
    't',
    't',
    't',
    't',
    't',
    't',
    't',
  ]);
  const [hourSelected, setHourSelected] = useState({
    start: '00:00',
    end: '23:59',
  });

  const handleChangeDay = (idx) => {
    const updatedDays = [...daysSelected];
    updatedDays[idx] = updatedDays[idx] === 'f' ? 't' : 'f';
    setDaysSelected(updatedDays);
  };

  const handleTimeInput = (time) => {
    let validTime = '';
    const array = time.split('');

    array.forEach((element, i) => {
      if (i === 0 && element > 2) {
        validTime += '2';
      } else if (i === 1 && element > 3 && array[0] > 1) {
        validTime += '3';
      } else if (i === 3 && element > 5) {
        validTime += '5';
      } else {
        validTime += element;
      }
      return;
    });

    return validTime;
  };

  useEffect(() => {
    try {
      getComplements();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getComplements]);

  async function handleSubmit(data) {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        limit: Yup.string().required('Máximo obrigatório'),
        minimum: Yup.string().required('Mínimo obrigatório'),
        question: Yup.string().required('Pergunta obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.post(
        'restaurants/product/complement-category',
        {
          name: data.name,
          question: data.question,
          limit: data.limit,
          minimum: Number(data.minimum),
          single_choice: false,
          optional: data.optional,
          available: availableCategory,
          available_in_delivery: availableDeliveryCategory,
          additional: data.additional,
          complements: data.complements,
          more_expensive_only: data.more_expensive_only,
          use_average: data.use_average,
          enable_times: enableTimes,
          active_days: daysSelected.join(''),
          start_time: new Date(`2023/01/01 ${hourSelected.start}`),
          end_time: new Date(`2023/01/01 ${hourSelected.end}`),
        }
      );
      setSelectedCategory(response.data.id);

      toast.success('Categoria cadastrada com sucesso!');

      setComplementsCategories((state) => {
        return [...state, response.data];
      });

      toggle();
    } catch (error) {
      if (selectedCategory) {
        await api.delete(
          `/restaurants/product/complement-category/${selectedCategory}`
        );
      }

      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else {
        toast.error('Falha ao salvar categoria');
        console.log(error.message);
      }
    }
  }

  const [moreExpensiveChecked, setMoreExpensiveChecked] = useState(false);
  const [useAverageChecked, setUseAverageChecked] = useState(false);
  const [availableCategory, setAvailableCategory] = useState(true);

  function handleChangeAvailableCategory() {
    setAvailableCategory(!availableCategory);
  }

  const [availableDeliveryCategory, setAvailableDeliveryCategory] =
    useState(true);

  function handleChangeAvailableDeliveryCategory() {
    setAvailableDeliveryCategory(!availableDeliveryCategory);
  }
  return (
    <Container>
      <InnerArea>
        <Row>
          <Col md="12">
            <p style={{ fontSize: 18 }}>Grupo de complementos</p>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit} ref={formRef}>
          <Row>
            <Col sm="12" lg="6">
              <Input
                label="Nome"
                name="name"
                type="text"
                placeholder="Nome da categoria"
              />
            </Col>
            <Col sm="12" lg="3">
              <Input
                label="Mínimo"
                name="minimum"
                type="number"
                placeholder="1"
              />
            </Col>
            <Col sm="12" lg="3">
              <Input
                label="Máximo"
                name="limit"
                type="number"
                placeholder="1"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Input
                label="Pergunta"
                name="question"
                type="text"
                placeholder="Escolha uma opção de carne, Qual o ponto da carne..."
              />
            </Col>
          </Row>
          <Row>
            <Select
              name="complements"
              isMulti
              options={complements}
              label="Associar complementos à categoria"
            />
          </Row>
          <Row>
            {!user.only_delivery && (
              <Col md="3">
                {/* <CheckboxInput
   defaultChecked
   label="Disponível no presencial"
   name="available"
 /> */}
                <AreaSwitcher title="Disponível no presencial">
                  <Switch
                    onChange={handleChangeAvailableCategory}
                    draggable
                    checked={availableCategory}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={27}
                    width={44}
                    handleDiameter={16}
                    activeBoxShadow="0 0 2px 3px #111"
                    boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                    onColor="#0CCAB4"
                    offColor="#666666"
                  />
                  <span>PDV</span>
                </AreaSwitcher>
              </Col>
            )}

            {user?.delivery_info?.is_delivery_allowed && (
              <Col md="3">
                <AreaSwitcher title="Disponível no Delivery">
                  <Switch
                    onChange={handleChangeAvailableDeliveryCategory}
                    draggable
                    checked={availableDeliveryCategory}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={27}
                    width={44}
                    handleDiameter={16}
                    activeBoxShadow="0 0 2px 3px #111"
                    boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                    onColor="#0CCAB4"
                    offColor="#666666"
                  />
                  <span>Delivery</span>
                </AreaSwitcher>
              </Col>
            )}
          </Row>
          <Row className="mt-2">
            <Col md="4">
              <CheckboxInput
                defaultChecked
                label="Adicional"
                name="additional"
              />
            </Col>

            <Col md="4">
              <CheckboxInput defaultChecked label="Opcional" name="optional" />
            </Col>

            {/* <Col md="3">
              <CheckboxInput
                defaultChecked
                label="Escolha única"
                name="single_choice"
              />
            </Col> */}
          </Row>
          <Row>
            <Col sm="12" md="4">
              <CheckboxInput
                label="Cobrar apenas o mais caro"
                name="more_expensive_only"
                onChange={() => setMoreExpensiveChecked(!moreExpensiveChecked)}
                disabled={useAverageChecked}
              />
            </Col>

            <Col sm="12" md="4">
              <CheckboxInput
                label="Cobrar a média do preço"
                name="use_average"
                onChange={() => setUseAverageChecked(!useAverageChecked)}
                disabled={moreExpensiveChecked}
              />
            </Col>
            <Col sm="12" md="4">
              <CheckboxInput
                label="Horário personalizado"
                name="enable_times"
                onChange={() => setEnableTimes(!enableTimes)}
                value={enableTimes}
              />
            </Col>
            {/* <Col sm="12" lg="6">
              <CheckboxInfo>
                <h4> Legenda</h4>
                <div>
                  <span>Disponível</span>
                  <p>
                    Se esta categoria está disponível no restaurante no momento
                  </p>
                </div>
                <div>
                  <span>Disponível no presencial</span>
                  <p>
                    Se esta categoria está disponível para os clientes
                    presenciais
                  </p>
                </div>
                <div>
                  <span>Disponível no delivery</span>
                  <p>
                    Se esta categoria está disponível no cardápio do delivery
                  </p>
                </div>
                <div>
                  <span>Opcional</span>
                  <p>O cliente poderá escolher ou não algo nesta categoria</p>
                </div>
                <div>
                  <span>Escolha única</span>
                  <p>
                    O cliente poderá escolher somente uma opção nesta categoria
                  </p>
                </div>
                <div>
                  <span>Adicional</span>
                  <p>
                    Se marcado, os complementos terão preço adicional. Caso
                    contrário, serão gratúitos
                  </p>
                </div>
                <div>
                  <span>Cobra apenas complemento mais caro</span>
                  <p>
                    Se marcado, apenas o complemento mais caro da categoria será
                    cobrado.
                  </p>
                </div>
                <div>
                  <span>Cobra a média do preço dos complementos</span>
                  <p>
                    Se marcado, será feita cobrança calculando a média de preço
                    dos complementos.
                  </p>
                </div>
              </CheckboxInfo>
            </Col> */}
          </Row>

          {enableTimes && (
            <PersonalizedHoursContainer>
              <div>
                <span style={{ fontWeight: 'bold' }}>Dias:</span>
                <div style={{ display: 'flex', gap: 5 }}>
                  <Button
                    type="button"
                    title="Dom"
                    buttonColor="#2ec9b7"
                    containerStyles={{
                      width: 60,
                      height: 30,
                      opacity: daysSelected[0] === 'f' ? 0.5 : 1,
                    }}
                    onClick={() => {
                      handleChangeDay(0);
                    }}
                  />
                  <Button
                    type="button"
                    title="Seg"
                    containerStyles={{
                      width: 60,
                      height: 30,
                      opacity: daysSelected[1] === 'f' ? 0.5 : 1,
                    }}
                    buttonColor="#2ec9b7"
                    onClick={() => {
                      handleChangeDay(1);
                    }}
                  />
                  <Button
                    type="button"
                    title="Ter"
                    containerStyles={{
                      width: 60,
                      height: 30,
                      opacity: daysSelected[2] === 'f' ? 0.5 : 1,
                    }}
                    buttonColor="#2ec9b7"
                    onClick={() => {
                      handleChangeDay(2);
                    }}
                  />
                  <Button
                    type="button"
                    title="Qua"
                    containerStyles={{
                      width: 60,
                      height: 30,
                      opacity: daysSelected[3] === 'f' ? 0.5 : 1,
                    }}
                    buttonColor="#2ec9b7"
                    onClick={() => {
                      handleChangeDay(3);
                    }}
                  />
                  <Button
                    type="button"
                    title="Qui"
                    containerStyles={{
                      width: 60,
                      height: 30,
                      opacity: daysSelected[4] === 'f' ? 0.5 : 1,
                    }}
                    buttonColor="#2ec9b7"
                    onClick={() => {
                      handleChangeDay(4);
                    }}
                  />
                  <Button
                    type="button"
                    title="Sex"
                    containerStyles={{
                      width: 60,
                      height: 30,
                      opacity: daysSelected[5] === 'f' ? 0.5 : 1,
                    }}
                    buttonColor="#2ec9b7"
                    onClick={() => {
                      handleChangeDay(5);
                    }}
                  />
                  <Button
                    type="button"
                    title="Sáb"
                    containerStyles={{
                      width: 60,
                      height: 30,
                      opacity: daysSelected[6] === 'f' ? 0.5 : 1,
                    }}
                    buttonColor="#2ec9b7"
                    onClick={() => {
                      handleChangeDay(6);
                    }}
                  />
                </div>
              </div>
              <div>
                <span style={{ fontWeight: 'bold' }}>Horários:</span>
                <div
                  style={{
                    display: 'flex',
                    gap: 5,
                    alignItems: 'center',
                  }}
                >
                  <span>das</span>
                  <DefaultInput
                    placeholder="__:__"
                    mask="99:99"
                    maskChar="0"
                    style={{
                      width: '74px',
                      padding: '0px 10px',
                      height: '30px',
                    }}
                    value={hourSelected.start}
                    onChange={(e) => {
                      const value = e.target.value;
                      setHourSelected((state) => {
                        return {
                          start: handleTimeInput(value),
                          end: state.end,
                        };
                      });
                    }}
                  />
                  <span>às</span>
                  <DefaultInput
                    placeholder="__:__"
                    mask="99:99"
                    maskChar="0"
                    style={{
                      width: '74px',
                      padding: '0px 10px',
                      height: '30px',
                    }}
                    value={hourSelected.end}
                    onChange={(e) => {
                      const value = e.target.value;
                      setHourSelected((state) => {
                        return {
                          start: state.start,
                          end: handleTimeInput(value),
                        };
                      });
                    }}
                  />
                </div>
              </div>
            </PersonalizedHoursContainer>
          )}

          <ButtonsArea>
            <PersonalButton
              color="#FF2C3A"
              message="Cancelar"
              style={{
                marginLeft: 0,
              }}
              onClick={() => {
                toggle();
              }}
            />

            <PersonalButton
              color="#0CCAB4"
              message="Salvar"
              type="submit"
              style={{
                marginRight: 10,
              }}
            />
          </ButtonsArea>
        </Form>
      </InnerArea>
    </Container>
  );
}

export default CreateComplementCategory;
