import React from 'react';


import {
  Container,
  Body,
  Phone,
  Price,
  ItemsArea,
} from './styles';

const PrintRecipe = React.forwardRef(
  (
    {
      intermediary
    },
    ref
  ) => {

    return (
      <Container ref={ref}>
        {intermediary ? (
          <React.Fragment key={intermediary.id}>
            <ItemsArea>
              <Body>
                <Phone style={{ fontWeight: 'normal' }}>
                  Receita - <strong>{intermediary.name}</strong>
                </Phone>
                <Phone>
                  Porção / Rendimento: {intermediary.yield} {intermediary.unidade}
                </Phone>
              </Body>
              {
                intermediary.intermediary_inputs.map((inter) => (
                  <Body>
                    <Phone>
                      {inter.input.name}
                    </Phone>
                    <Price>
                      {inter.quantidade} {inter.input.unidade}
                    </Price>
                  </Body>
                ))
              }
              {
                intermediary.intermediary_intermediaries.map((inter) => (
                  <Body>
                    <Phone>
                      {inter.intermediary.name}
                    </Phone>
                    <Price>
                      {inter.quantidade} {inter.intermediary.unidade}
                    </Price>
                  </Body>
                ))
              }
              <Body style={{ marginTop: 20 }}>
                <Phone>
                  <strong>Modo de preparo:</strong>
                </Phone>
              </Body>
              <Body>
                <textarea defaultValue={intermediary.recipe} style={{ width: '100%', height: '200mm', border: 'none', fontWeight: 'normal', paddingRight: 7, fontSize: 17 }} />
              </Body>
            </ItemsArea>
            {/* <Footer>
              <strong>Desenvolvido por Takeat</strong>
            </Footer> */}
          </React.Fragment>
        ) : (
          <div />
        )}
      </Container>
    );
  }
);

export default PrintRecipe;
