import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { AiOutlinePrinter } from 'react-icons/ai';

import PrintQrCode from '~/components/PrintQrCode';

import { Print, DisplayNoneImprimir } from '../styles';

const QrCodePrint = ({ table }) => {
  const componentRef = useRef(null);

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <Print>
            <AiOutlinePrinter color="white" size={20} />
          </Print>
        )}
        content={() => componentRef.current}
      />

      <DisplayNoneImprimir>
        <PrintQrCode ref={componentRef} table={table} />
      </DisplayNoneImprimir>
    </>
  );
};

export default QrCodePrint;
