import styled from 'styled-components';

export const StyledButton = styled.button`
  display: flex;
  min-height: 50px;
  padding: 8px 10px;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
  border-radius: 5px;
  background: #f3f3f3;
  border: 1px solid transparent;
  ${({ requiredChecked }) => !requiredChecked && 'flex-wrap: wrap;'}
  ${({ selected }) => selected && 'border-color: #979797;'}
  ${({ required, requiredChecked }) => (required && !requiredChecked) && 'background-color: #FFF0F2;'}
  ${({ required, requiredChecked }) => (required && requiredChecked) && 'background-color: #ECFFFD;'}

  span {
    text-align: left;
    color: #4d4d4c;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
  }

  svg {
    min-width: 8px;
  }
`;

export const Badge = styled.span`
  background: #FF2C3A;
  color: white !important;
  font-family: Poppins;
  font-size: 10px !important;
  font-weight: 500 !important;
  padding: 0 5px !important;
  border-radius: 100px !important;
`;