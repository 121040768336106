import styled from 'styled-components';

export const BillsArea = styled.div`
  border: 1px solid #f4f4f4;
  padding: 10px;
  border-radius: 7px;
  margin-top: -20px;
  margin-bottom: 20px;
`;

export const Container = styled.div`
  border-top: 1px solid #f4f4f4;
  padding-top: 10px;
`;

export const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f4f4f4;
  padding: 6px;
  border-radius: 7px;
  margin-bottom: 10px;
  cursor: pointer;

  span {
    font-weight: bold;
  }
`;
export const Item = styled.div`
  display: flex;
  justify-content: space-between;
`;
