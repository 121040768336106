import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Header from './Header';

import { Content } from './styles';
import ReportsProducts from './ReportsProducts';
import ReportsOrderCanceled from './ReportsOrderCanceled';
import ReportsDiscount from './ReportsDiscount';

function ReportsProductsPage() {
  return (
    <>
      <Header />
      <Content>
        <Switch>
          <Route
            path="/reports/products/products"
            component={ReportsProducts}
          />
          <Route
            path="/reports/products/canceled-products"
            component={ReportsOrderCanceled}
          />
          <Route
            path="/reports/products/discounts"
            component={ReportsDiscount}
          />
        </Switch>
      </Content>
    </>
  );
}

export default ReportsProductsPage;
