/* eslint-disable react/prop-types */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { Collapse } from 'reactstrap';
import produce from 'immer';
import { toast } from 'react-hot-toast';

import { ProductsTable } from './styles';
import api from '~/services/api';
import ItemProductsFiscal from '~/components/Products/ItemProductsFiscal';
import OrderProductCategoryContext from './context';
import { useAuth } from '~/context/AuthContext';
import { useMenu } from '~/context/OrderingSystem/Menu';

const ProductsTablesFiscal = (
  {
    category,
    user,
    getCategories,
    menewProducts,
    categories,
    cache,
    setCategoriesFiltered,
    setCategories,
    setComplementsCategories,
    complementCategories,
    fiscalGroups,
    ifoodMenus,
    ifoodRestaurants,
    ifoodProducts,
  },
  ref
) => {
  const [isOpen, setIsOpen] = useState(true);
  const { user: restaurant } = useAuth();
  const [products, setProducts] = useState(category?.products);
  const toggle = () => setIsOpen(!isOpen);
  const { getMenu } = useMenu();

  useEffect(() => {
    setProducts(category?.products);
  }, [category]);

  function setProductsState(data) {
    setProducts((state) => {
      return [...state, data];
    });
  }

  useImperativeHandle(ref, () => {
    return {
      setProductsState,
      products,
      toggle,
    };
  });

  async function move(from_product_id, to_product_id, from, to) {
    try {
      const next = produce(products, (draft) => {
        const dragged = draft[from];

        draft.splice(from, 1);
        draft.splice(to, 0, dragged);

        for (let i = 0; i < draft.length; i++) {
          draft[i].custom_order = i;
        }
      });

      setProducts(next);
      const catFind = categories.findIndex((cat) => cat.id === category.id);

      categories[catFind].products = next;
    } catch (err) {
      toast.error('Erro ao atualizar posição da categoria');
    }
  }

  async function onDrop(from_product_id, to_product_id, from, to) {
    try {
      await api.put('/restaurants/products-custom-order', {
        from_product_id,
        to_product_id,
        to_position: to,
        from_position: from,
      });

      toast.success('Posição Atualizada com sucesso!');
    } catch (err) {
      toast.error('Erro ao atualizar posição da categoria');
    }
  }

  async function handleDelete(id) {
    try {
      if (window.confirm('Tem certeza que deseja deletar este produto?')) {
        await api.delete(`/restaurants/products/${id}`);

        const produtcsFiltered = products.filter(
          (product) => product.id !== id
        );

        const catFind = categories.findIndex((cat) => cat.id === category.id);

        categories[catFind].products = produtcsFiltered;

        setProducts(produtcsFiltered);

        getMenu();

        toast.success('Produto deletado com sucesso!');

        localStorage.setItem('@gddashboard:isMenuUpdated', true);
      }
    } catch (error) {
      toast.error('Erro ao deletar produto');
    }
  }

  const [sliceSize, setSliceSize] = useState(5);
  const [angleChanged, setAngleChange] = useState(false);

  return (
    <OrderProductCategoryContext.Provider value={{ products, move, onDrop }}>
      {/* <ButtonCollapse onClick={toggle}>
        Exibir/ Esconder Produtos{' '}
        <MdExpandMore size={20} title="Mostrar Produtos" />
      </ButtonCollapse> */}

      <Collapse isOpen={isOpen}>
        <ProductsTable responsive borderless>
          <thead>
            <tr>
              <th>Item</th>
              {!restaurant?.only_delivery && <th>Preço</th>}

              <th>Fiscal</th>
              {/* <th>Grupo Fiscal</th> */}

              <th></th>
            </tr>
          </thead>

          <tbody>
            {products &&
              products.map((product, index) => (
                <ItemProductsFiscal
                  product={product}
                  category={category}
                  handleDelete={handleDelete}
                  is_priority={user.is_priority}
                  getCategories={getCategories}
                  key={product.id}
                  index={index}
                  menewProducts={menewProducts}
                  setProducts={setProducts}
                  products={products}
                  categories={categories}
                  setCategoriesFiltered={setCategoriesFiltered}
                  setCategories={setCategories}
                  sliceSize={sliceSize}
                  setComplementsCategories={setComplementsCategories}
                  complementCategories={complementCategories}
                  fiscalGroups={fiscalGroups}
                  ifoodMenus={ifoodMenus}
                  ifoodRestaurants={ifoodRestaurants}
                  ifoodProducts={ifoodProducts}
                />
              ))}
          </tbody>
        </ProductsTable>

        {/* {sliceSize < products.length && (
          <div>
            {' '}
            <SetSliceSizeButton type="button" onClick={handleSetSliceSize}>
              <FaAngleDown
                style={{
                  transition: 'all 0.2s',
                  transform: !angleChanged ? 'rotate(-90deg)' : 'rotate(0)',
                }}
              />{' '}
              Ver mais produtos
            </SetSliceSizeButton>
          </div>
        )} */}
      </Collapse>
    </OrderProductCategoryContext.Provider>
  );
};

export default forwardRef(ProductsTablesFiscal);
