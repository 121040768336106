import React, { useCallback, useEffect, useState } from 'react';
import { Container, ModalGrid, ModalNFe, UserInfoDiv } from './styles';
import { DefaultSelect, DefaultInput, Hyperlink, Button } from 'ui-kit-takeat';
import toast from 'react-hot-toast';
import api from '~/services/api';
import { uf_options, ie_options, local_options } from '~/utils/consts';

export const Fiscal = ({
  handleCheckIsBalconyOpen,
  formData,
  setFormData,
  makeOrder,
  setTypeSelected,
  totalDifference,
  setShowModalDifference,
}) => {
  const [modalNFeOpen, setModalNFeOpen] = useState(false);

  const docOptions = [
    {
      value: 'cpf',
      label: 'CPF',
    },
    {
      value: 'cnpj',
      label: 'CNPJ',
    },
  ];

  const docSelected = docOptions.find((opt) => opt.value === formData.doc);

  DefaultInput.defaultProps = {
    style: {
      height: 38,
      padding: '5px 10px',
    },
  };

  const getNfeClientInfo = useCallback(async (cnpj) => {
    try {
      const response = await api.get(`restaurants/nfe-client/${cnpj}`);

      if (response.data) {
        setFormData({
          ...formData,
          nome_destinatario: response.data.nome,
          logradouro_destinatario: response.data.logradouro,
          numero_destinatario: response.data.numero,
          bairro_destinatario: response.data.bairro,
          municipio_destinatario: response.data.municipio,
          uf_destinatario: response.data.uf,
          indicador_inscricao_estadual_destinatario:
            response.data.indicador_inscricao_estadual || '9',
          inscricao_estadual_destinatario: response.data.inscricao_estadual,
          local_destino: response.data.local_destino
            ? parseInt(response.data.local_destino)
            : 1,
          email: response.data.email,
        });
      }
    } catch (error) {
      toast.error('Erro ao buscar informações desse CNPJ');
    }
  }, []);

  useEffect(() => {
    const formattedCnpj = formData.cnpj?.replace(/\D/g, '');

    if (formData.type === 'nfe' && formattedCnpj?.length === 14) {
      getNfeClientInfo(formattedCnpj);
    }
  }, [formData.cnpj]);

  return (
    <Container>
      <h3>NFCe</h3>
      <UserInfoDiv>
        <DefaultSelect
          options={docOptions}
          value={docSelected}
          onChange={(e) => setFormData((state) => ({ ...state, doc: e.value }))}
        />
        <DefaultInput
          placeholder={docSelected.label}
          mask={
            docSelected.value === 'cpf'
              ? '999.999.999-99'
              : '99.999.999/9999-99'
          }
          style={{ height: 38, padding: '5px 10px' }}
          value={formData[formData.doc] || ''}
          onChange={(e) => {
            e.persist();

            setFormData((state) => ({
              ...state,
              [formData.doc]: e.target.value,
            }));
          }}
        />
      </UserInfoDiv>
      <Hyperlink
        color="#2EC9B7"
        fontSize={14}
        textDecoration="underline"
        onClick={() => {
          setModalNFeOpen(true);
          setTypeSelected('nfe');
        }}
      >
        Mudar para NFe
      </Hyperlink>

      <ModalNFe
        isOpen={modalNFeOpen}
        toggle={() => {
          setModalNFeOpen((state) => !state);
          setTypeSelected('nfce');
        }}
      >
        <h3>Emitir NFe</h3>
        <ModalGrid>
          <DefaultSelect
            label="Documento"
            options={docOptions}
            value={docSelected}
            onChange={(e) =>
              setFormData((state) => ({ ...state, doc: e.value }))
            }
          />
          <DefaultInput
            title={docSelected.label}
            mask={
              docSelected.value === 'cpf'
                ? '999.999.999-99'
                : '99.999.999/9999-99'
            }
            value={formData[formData.doc]}
            onChange={(e) => {
              setFormData((state) => ({
                ...state,
                [formData.doc]: e.target.value,
              }));
            }}
          />
          <DefaultInput
            title="Nome"
            value={formData.nome_destinatario}
            onChange={(e) => {
              setFormData((state) => ({
                ...state,
                nome_destinatario: e.target.value,
              }));
            }}
          />
          <DefaultInput
            title="Logradouro"
            value={formData.logradouro_destinatario}
            onChange={(e) => {
              setFormData((state) => ({
                ...state,
                logradouro_destinatario: e.target.value,
              }));
            }}
          />
          <DefaultInput
            title="Número"
            value={formData.numero_destinatario}
            onChange={(e) => {
              setFormData((state) => ({
                ...state,
                numero_destinatario: e.target.value,
              }));
            }}
          />
          <DefaultInput
            title="Bairro"
            value={formData.bairro_destinatario}
            onChange={(e) => {
              setFormData((state) => ({
                ...state,
                bairro_destinatario: e.target.value,
              }));
            }}
          />
          <DefaultInput
            title="Município"
            value={formData.municipio_destinatario}
            onChange={(e) => {
              setFormData((state) => ({
                ...state,
                municipio_destinatario: e.target.value,
              }));
            }}
          />
          <DefaultSelect
            label="UF"
            options={uf_options}
            value={uf_options.find(
              (opt) => opt.value === formData.uf_destinatario
            )}
            onChange={(e) => {
              setFormData((state) => ({
                ...state,
                uf_destinatario: e.value,
              }));
            }}
          />
          <DefaultInput
            title="CEP"
            value={formData.cep}
            onChange={(e) => {
              setFormData((state) => ({
                ...state,
                cep: e.target.value,
              }));
            }}
          />
          <DefaultSelect
            label="Estado de origem"
            options={local_options}
            value={local_options.find(
              (opt) => opt.value === formData.local_destino
            )}
            onChange={(e) => {
              setFormData((state) => ({
                ...state,
                local_destino: e.value,
              }));
            }}
          />
          <DefaultSelect
            label="Indicador da Inscrição Estadual"
            options={ie_options}
            value={ie_options.find(
              (opt) =>
                opt.value === formData.indicador_inscricao_estadual_destinatario
            )}
            onChange={(e) => {
              setFormData((state) => ({
                ...state,
                indicador_inscricao_estadual_destinatario: e.value,
              }));
            }}
          />
          <DefaultInput
            title="Inscrição estadual (opcional)"
            value={formData.inscricao_estadual_destinatario}
            onChange={(e) => {
              setFormData((state) => ({
                ...state,
                inscricao_estadual_destinatario: e.target.value,
              }));
            }}
          />
          <DefaultInput
            title="Informações adicionais"
            value={formData.informacoes_adicionais_contribuinte}
            onChange={(e) => {
              setFormData((state) => ({
                ...state,
                informacoes_adicionais_contribuinte: e.target.value,
              }));
            }}
          />
          <DefaultInput
            title="Enviar cópia por e-mail"
            value={formData.email}
            onChange={(e) => {
              setFormData((state) => ({
                ...state,
                email: e.target.value,
              }));
            }}
          />
          <Button
            title="Pedir e emitir NFe"
            onClick={() => {
              const isBalconyOpen = handleCheckIsBalconyOpen();
              if (isBalconyOpen) {
                toast.error('Mesa Balcão está aberta no momento. Por favor, volte e feche ela para fazer um pedido no Modo Balcão.');
                return;
              }
              if (totalDifference !== 0) {
                setShowModalDifference(true);
                return;
              }
              makeOrder(true);
              setModalNFeOpen(false);
            }}
            buttonColor="#2EC9B7"
            style={{ gridColumn: '1 / span 2' }}
          />
        </ModalGrid>
      </ModalNFe>
    </Container>
  );
};