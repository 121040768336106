import XLSX from 'xlsx-js-style';
import style from './sheetStyle';

import formatCompleteDate from '../formatCompleteDate';

function getTableName(table_number, table_type) {
  switch (table_type) {
    case 'delivery':
      return 'Delivery';
    case 'balcony':
      return 'Balcão';
    case 'table':
      return table_number;
    default:
      return table_number;
  }
}

function getCanceledData(start, end, orders) {
  const start_str = formatCompleteDate(start);
  const end_str = formatCompleteDate(end);

  const lines = [
    [`Período de ${start_str} até ${end_str}`],
    [
      'Mesa',
      'Data/Hora',
      'Cliente/Garçom',
      'Quantidade',
      'Produto',
      'Valor',
      'Justificativa',
      'Usuário',
    ],
  ];

  lines.push(
    ...orders.map((o) => {
      const client_name = o.basket.bill.waiter
        ? o.basket.bill.waiter.name
        : (o.basket.bill.buyer.name || o.basket.bill.buyer.phone);

      const { table_number, table_type } = o.basket.bill.session.table;
      
      return [
        getTableName(table_number, table_type),
        formatCompleteDate(o.canceled_at),
        client_name,
        o.amount,
        o.product.name,
        parseFloat(o.total_price),
        o.cancel_reason || '',
        o.users.name,
      ];
    })
  );

  return lines;
}

export function generateSheet(start, end, orders) {
  const rows = getCanceledData(start, end, orders);

  const columns = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  const num_cols = 13;

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(rows);

  worksheet['!cols'] = new Array(num_cols);
  worksheet['!cols'].fill({ wch: 16 });

  worksheet['!cols'][4] = { wch: 20 };
  worksheet['!cols'][6] = { wch: 28 };

  worksheet["!merges"] = [{
    s: { r: 0, c: 0 },
    e: { r: 0, c: 7 },
  }];

  const black = '000000';
  const white = 'ffffff';
  const red1 = 'ff3838';
  const red2 = 'ffd7d7';
  const red3 = 'ff6d6d';
  const gold = 'e8f2a1';

  for (let i = 1; i < 3; i += 1) {
    for (let j = 0; j < 8; j += 1) {
      const cell = worksheet[columns[j] + i];

      if (cell) {
        if (i === 1) {
          cell.s = style().Font(gold, 'center', 'center').Bg(red1).Get();
        } else {
          cell.s = style().Font(white, 'center', 'center').Bg(red1).Get();
        }
      }
    }
  }

  for (let i = 3; i <= rows.length; i += 1) {
    for (let j = 0; j < 8; j += 1) {
      const cell = worksheet[columns[j] + i];

      if (cell) {
        if (i % 2 === 0) {
          cell.s = style().Bg(red2).Borders('lr', red3);
        } else {
          cell.s = style().Bg(white).Borders('lr', red3);
        }

        if (i === rows.length) {
          cell.s.Borders('b', red3);
        }

        if (j === 5) {
          cell.s.Number();
        } else if (j < 2) {
          cell.s.Font(black, 'center', 'center');
        }

        cell.s = cell.s.Get();
      }
    }
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório de Cancelamento');

  return XLSX.write(workbook, { type: 'buffer' });
}
