import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
`;

export const NavBar = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
`;

export const PageTitle = styled.h1`
  color: #3d3d3d;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
