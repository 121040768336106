import React from 'react';

import { MdUpdate } from 'react-icons/md';

import { ButtonContainer } from './styles';

export default function SaveButton({ text, color, ...rest }) {
  return (
    <ButtonContainer type="button" {...rest} style={color ? {background: color} : {}}>
      <MdUpdate color="#fff" size={16} />
      <span>{text ? text : 'ATUALIZAR'}</span>
    </ButtonContainer>
  );
}
