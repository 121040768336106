import React, { useState } from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import { toast } from 'react-hot-toast';
import { Modal } from 'reactstrap';
import api from '~/services/api';
import formatCompleteDate from '~/services/formatDate';
import EditTicket from '../Edit';

import { Actions, Delete } from './styles';

function Coupom({ coupom, getTickets }) {
  const [isModalEditTicketOpen, setIsModalEditTicketOpen] = useState(false);

  function toggleModalEditTicket() {
    setIsModalEditTicketOpen(!isModalEditTicketOpen);
  }

  async function handleDelete(id) {
    try {
      if (window.confirm('Tem certeza que deseja deletar este cupom?')) {
        await api.delete(`/restaurants/discount-coupons/${id}`);
        getTickets();
        toast.success('Cupom deletado com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao deletar cupom');
    }
  }
  return (
    <tr key={coupom.id}>
      <Modal
        isOpen={isModalEditTicketOpen}
        toggle={toggleModalEditTicket}
        size="lg"
      >
        <EditTicket
          coupom={coupom}
          getTickets={getTickets}
          toggleModalEditTicket={toggleModalEditTicket}
        />
      </Modal>
      <td>{coupom.code}</td>
      <td>{coupom.name}</td>
      <td>{coupom.is_active ? 'Sim' : 'Não'}</td>
      <td>{coupom.is_public ? 'Sim' : 'Não'}</td>

      <td>
        {coupom.discount_type === 'absolute' && `R$${coupom.discount}`}
        {coupom.discount_type === 'percentage' &&
          `${(parseFloat(coupom.discount) * 100).toFixed(2)}%`}
        {coupom.discount_type === 'free-shipping' && `FRETE`}
      </td>
      <td>R${coupom.minimum_price}</td>
      <td>R${coupom.maximum_discount}</td>
      <td>{coupom.limit_amount}</td>
      <td>
        {coupom.used_amount} {coupom.used_amount === 1 ? 'vez' : 'vezes'}
      </td>
      <td>
        {coupom.limit_date === null
          ? 'Sem expiração'
          : formatCompleteDate(coupom.limit_date)}
      </td>
      <td>
        {' '}
        <Actions>
          <MdEdit
            size={20}
            onClick={toggleModalEditTicket}
            style={{ cursor: 'pointer' }}
          />

          <Delete onClick={() => handleDelete(coupom.id)}>
            <MdDelete size={20} />
          </Delete>
        </Actions>
      </td>
    </tr>
  );
}

export default Coupom;
