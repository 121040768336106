/* eslint-disable react/prop-types */
import React, { useContext, useRef, useState, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { FaCheck, FaCopy, FaPlus } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import {
  NoImage,
  ProductImage,
  SubmitStyledButton,
  NumberFormatStyled,
  Description,
} from './styles';
import OrderComplementContext from '../context';

import api from '~/services/api';
import { Form } from '@unform/web';
import Select from '~/components/Form/SelectInput';
import Input from '~/components/Form/Input';
import { FiTrash } from 'react-icons/fi';

// import { Container } from './styles';

function ComplementsRow({
  complement,
  handleChangeComplement,
  handleChangeEditComplement,
  handleDelete,
  index,
  user,
  complementsData,
  category,
  setCategories,
  categories,
  ifoodMenus,
  ifoodRestaurants,
}) {
  const { move, onDrop } = useContext(OrderComplementContext);
  const ref = useRef();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropDownHidden, setDropDownHidden] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const [{ isDragging }, dragRef] = useDrag({
    item: { type: 'COMPLEMENTCARD', index, complement_id: complement.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (!monitor.didDrop()) {
        const draggedIndex = item.index;
        const targetIndex = index;

        onDrop(item.complement_id, complement.id, draggedIndex, targetIndex);
      }
    },
  });

  const [, dropRef] = useDrop({
    accept: 'COMPLEMENTCARD',
    hover(item, monitor) {
      const draggedIndex = item.index;
      const targetIndex = index;

      if (draggedIndex === targetIndex) {
        return;
      }

      const targetSize = ref.current.getBoundingClientRect();
      const targetCenter = targetSize.height / 2;
      const draggedOffset = monitor.getClientOffset();
      const draggedTop = draggedOffset.y - targetSize.top;
      if (draggedIndex < targetIndex && draggedTop < targetCenter) {
        return;
      }
      if (draggedIndex > targetIndex && draggedTop > targetCenter) {
        return;
      }
      move(item.complement_id, complement.id, draggedIndex, targetIndex);

      item.index = targetIndex;
    },

    drop(item, monitor) {
      const draggedIndex = item.index;
      const targetIndex = index;

      onDrop(item.complement_id, complement.id, draggedIndex, targetIndex);

      setDropDownHidden(false);
    },
  });

  dragRef(dropRef(ref));

  function currencyFormatter(value) {
    if (!Number(value)) return 'R$ 0,00';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  }

  const handleIfood = useCallback(
    async (data) => {
      const ifood_restaurant_id = parseInt(data.ifood_restaurant_id);

      try {
        const thisData = {
          ifood_array_id: data['ifood_products'],
          ifood_restaurant_id,
          complement_id: complement.id,
        };

        await api.post(`/restaurants/ifood/complements/array`, thisData);

        toast.success('Complemento associado com sucesso!');
      } catch (error) {
        if (error.response.data.type === 'already') {
          let message = '';

          ifoodMenus
            .find((menu) => menu.ifood_restaurant_id === ifood_restaurant_id)
            .complements.filter((prod) =>
              error.response.data.array.includes(prod.value)
            )
            .map((prod) => {
              message = message + prod.label + ', ';
            });

          toast.error(message + ' já vinculado(s)');
        } else {
          toast.error('Erro ao vincular');
        }
      }
    },
    [complement]
  );

  const [ifoodProducts, setIfoodProducts] = useState(complement.ifood_complements);

  const handleIfoodPdv = useCallback(
    async (data) => {
      const ifood_restaurant_id = parseInt(data.ifood_restaurant_id);

      try {
        const thisData = {
          ifood_id: data.ifood_products,
          ifood_restaurant_id,
          complement_id: complement.id,
        };

        const response = await api.post(`/restaurants/ifood/complements`, thisData);

        const newIfoodProducts = [
          ...ifoodProducts,
          response.data
        ];

        setIfoodProducts(newIfoodProducts);

        toast.success('Complemento associado com sucesso!');
      } catch (error) {
        toast.error(error.response.data?.error || 'Erro ao vincular');
      }
    },
    [complement, ifoodProducts]
  );

  const handleDeleteIfood = useCallback(
    async ({ ifood_id, ifood_restaurant_id }) => {
      try {
        const thisData = {
          ifood_id,
          ifood_restaurant_id: parseInt(ifood_restaurant_id),
          complement_id: complement.id,
        };

        await api.delete(`/restaurants/ifood/complements`, { data: thisData });

        const newIfoodProducts = ifoodProducts.filter(thisProd => thisProd !== ifoodProducts.find(
          prod => prod.ifood_id === ifood_id && prod.ifood_restaurant_id === parseInt(ifood_restaurant_id)
        ));

        setIfoodProducts(newIfoodProducts);

        toast.success('Associação removida com sucesso!');
      } catch (error) {
        toast.error('Erro ao desvincular');
      }
    },
    [complement, ifoodProducts]
  );

  const use_ifood_pdv_code = user.use_ifood_pdv_code;

  return (
    <tr isDragging={isDragging} ref={ref} key={complement.id}>
      <td>
        <div
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          {complement?.image ? (
            <ProductImage
              src={complement?.image?.url_thumb || complement?.image?.url}
              alt="Foto do produto"
              // onClick={toggleImage}
              // title="Clique para atualizar a foto"
              style={{ marginLeft: 16 }}
            />
          ) : (
            <NoImage
              // onClick={toggleImage}
              // title="Clique para atualizar a foto"
              style={{ marginBottom: 10, marginLeft: 16 }}
            />
          )}
        </div>
      </td>
      <td>
        <Description>
          <strong>{complement?.name}</strong>
        </Description>
      </td>

      <td>
        {' '}
        <NumberFormatStyled
          prefix="R$"
          format={currencyFormatter}
          name="value"
          value={complement?.price}
          disabled
        />
      </td>

      <td style={{ width: '500px' }}>
        {ifoodRestaurants.map((restaurant) => (
          <Form onSubmit={use_ifood_pdv_code ? handleIfoodPdv : handleIfood }>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '100%', marginRight: 10 }}>
                {use_ifood_pdv_code ? 
                  <div style={{marginTop: 10, marginBottom: 10}}>
                  <Input
                    name="ifood_products"
                    label={`Código PDV${ifoodRestaurants.length > 1 ? ` (${restaurant.name})` : ''}`}
                  />
                  </div> :
                  <>
                    <Select
                      name="ifood_products"
                      isMulti
                      defaultValue={ifoodMenus
                        .find(
                          (menu) =>
                            menu.ifood_restaurant_id ===
                            restaurant.ifood_restaurant_id
                        )
                        ?.complements?.filter((prod) =>
                          complement.ifood_complements
                            ?.map((ifo) => ifo.ifood_id)
                            .includes(prod.value)
                        )}
                      options={
                        ifoodMenus.find(
                          (menu) =>
                            menu.ifood_restaurant_id ===
                            restaurant.ifood_restaurant_id
                        )?.complements || []
                      }
                      label={ifoodRestaurants.length > 1 ? restaurant.name : ''}
                      menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                  </>
                }
              </div>
              <Input
                style={{ display: 'none' }}
                value={restaurant.ifood_restaurant_id}
                name="ifood_restaurant_id"
              />
              <div>
                <SubmitStyledButton
                  id="limit"
                  type="submit"
                  style={{
                    marginLeft: 'auto',
                    marginTop: ifoodRestaurants.length > 1 ? 35 : 10,
                  }}
                >
                  {use_ifood_pdv_code ? <FaPlus /> : <FaCheck />}
                </SubmitStyledButton>
              </div>
            </div>
            {use_ifood_pdv_code && ifoodProducts.filter(prod => prod.ifood_restaurant_id === restaurant.ifood_restaurant_id).map(prod => (
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                <div style={{width: '100%', marginRight: 10}}>
                  <Input 
                    disabled
                    name="display_pdv"
                    value={prod.ifood_id}
                    style={{ color: '#3D3D3D'}}
                  /> 
                </div>

                <div>
                <SubmitStyledButton
                  id="limit"
                  type="button"
                  style={{
                    marginRight: '5px',
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(prod.ifood_id);
                    toast.success('Código copiado com sucesso.')
                  }}
                >
                  {<FaCopy />}
                </SubmitStyledButton>
                </div>

                <div>
                  <SubmitStyledButton
                    id="limit"
                    type="button"
                    style={{
                      // marginLeft: 'auto',
                      background: '#ff2c3a'
                    }}
                    onClick={e => handleDeleteIfood({ ifood_id: prod.ifood_id, ifood_restaurant_id: restaurant.ifood_restaurant_id })}
                  >
                    {<FiTrash />}
                  </SubmitStyledButton>
                </div>

                {/* <FiTrash 
                  style={{color: 'red', cursor: 'pointer'}}
                  onClick={e => handleDeleteIfood({ ifood_id: prod.ifood_id, ifood_restaurant_id: restaurant.ifood_restaurant_id })}
                /> */}
              </div>
            ))}
          </Form>
        ))}
      </td>

      <td />
    </tr>
  );
}

export default ComplementsRow;
