import React from 'react';
import { CategorySelectionContainer, Title } from './styles';
import { CategoryButton } from '../CategoryButton';

export const CategorySelection = ({
  categories,
  categoryShowing,
  setCategoryShowing,
}) => {
  return (
    <CategorySelectionContainer>
      <Title>
        Categorias:
      </Title>
      {categories.map((category) => (
        <CategoryButton
          text={category.name}
          selected={category.id === categoryShowing.id}
          required={!category.optional}
          category={category}
          onClick={() => setCategoryShowing(category)}
        />
      ))}
    </CategorySelectionContainer>
  );
};