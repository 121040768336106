import React, {
  createContext,
  useMemo,
  useState,
  useContext,
  useCallback,
} from 'react';
import { useAuth } from '../AuthContext';
import api from '~/services/api';
import { toast } from 'react-hot-toast';

const IFoodContext = createContext();

const IFoodProvider = ({ children }) => {
  const { user } = useAuth();
  const [ifoodProducts, setIfoodProducts] = useState([]);
  const [ifoodComplements, setIfoodComplements] = useState([]);
  const [ifoodMenus, setIfoodMenus] = useState(() => {
    const menus = localStorage.getItem('@gddashboard:ifood_menu');
    if (menus) {
      return JSON.parse(menus);
    }
    return [];
  });

  const [ifoodRestaurants, setIfoodRestaurants] = useState(() => {
    const menus = localStorage.getItem('@gddashboard:ifood_restaurants');

    if (menus) {
      return JSON.parse(menus);
    }

    return [];
  });

  const getIfoodProducts = useCallback(async () => {
    try {
      const response = await api.get(
        `restaurants/ifood/${user?.ifood_merchant_uuid}/products`
      );

      const data = response.data.map((product) => ({
        value: product.id,
        label: product.name ? product.name : 'Pizza',
      }));

      setIfoodProducts(data.sort((a, b) => (a.label > b.label ? 1 : -1)));
      localStorage.setItem(
        '@gddashboard:ifood_products',
        JSON.stringify(data.sort((a, b) => (a.label > b.label ? 1 : -1)))
      );
    } catch (err) {
      console.log('get ifood products error: ', err.message);
    }
  }, [user]);

  const getIfoodMenus = useCallback(async () => {
    try {
      const response = await api.get(`restaurants/ifood/products`);

      setIfoodMenus(response.data);
      localStorage.setItem(
        '@gddashboard:ifood_menu',
        JSON.stringify(response.data)
      );

      const ifoodRestaurants = response.data.map((menu) => {
        return {
          name: menu.name,
          ifood_restaurant_id: menu.ifood_restaurant_id,
          merchant_id: menu.merchant_id,
        };
      });

      setIfoodRestaurants(ifoodRestaurants);
      localStorage.setItem(
        '@gddashboard:ifood_restaurants',
        JSON.stringify(ifoodRestaurants)
      );

      toast.success('Produtos iFood atualizados');
    } catch (err) {
      console.log('get ifood products error: ', err.message);
      toast.error('Erro ao carregar produtos iFood');
    }
  }, [user]);

  const getIfoodComplements = useCallback(async () => {
    try {
      const response = await api.get(
        `restaurants/ifood/${user?.ifood_merchant_uuid}/complements`
      );

      const data = response.data.map((complement) => ({
        value: complement.id,
        label: complement.name ? complement.name : 'Pizza',
      }));

      setIfoodComplements(data.sort((a, b) => (a.label > b.label ? 1 : -1)));
      localStorage.setItem(
        '@gddashboard:ifood_complements',
        JSON.stringify(data.sort((a, b) => (a.label > b.label ? 1 : -1)))
      );
    } catch (err) {
      console.log('get ifood complements error: ', err.message);
    }
  }, [user]);

  // useEffect(() => {
  //   const menus = localStorage.getItem('@gddashboard:ifood_restaurants');
  //   console.log('testeeeee', menus, user?.has_ifood, ifoodRestaurants, user);

  //   if (!menus) {
  //     if(user?.has_ifood && (!ifoodRestaurants || ifoodRestaurants.length === 0)){
  //       getIfoodMenus();
  //     }
  //   }
  // }, [ifoodRestaurants, user]);

  const value = useMemo(
    () => ({
      ifoodProducts,
      setIfoodProducts,
      ifoodComplements,
      setIfoodComplements,
      getIfoodProducts,
      getIfoodComplements,
      getIfoodMenus,
      ifoodMenus,
      ifoodRestaurants,
    }),
    [
      ifoodProducts,
      setIfoodProducts,
      ifoodComplements,
      setIfoodComplements,
      getIfoodProducts,
      getIfoodComplements,
      getIfoodMenus,
      ifoodMenus,
      ifoodRestaurants,
    ]
  );
  return (
    <IFoodContext.Provider value={value}>{children}</IFoodContext.Provider>
  );
};

function useIfood() {
  const context = useContext(IFoodContext);

  if (!context) {
    throw new Error('useIfood must be used within an IFoodProvider');
  }

  return context;
}

export { useIfood, IFoodProvider };
