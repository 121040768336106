import styled from 'styled-components';
import { Carousel } from 'reactstrap';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

export const Content = styled.div`
  width: calc(100vw - 200px);
  min-height: 100vh;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

export const ContentOperation = styled.div`
  height: 100vh;
`;

export const CarouselStyled = styled(Carousel)`
  width: 100%;

  img {
    width: 100%;
  }
`;

export const BadgeDelivery = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: orange;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -10px;
  top: -10px;
`;

export const Footer = styled.footer`
  display: flex;
  width: 85%;
  height: 60px;
  padding: 10px;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  z-index: 999;

  position: fixed;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);

  border-radius: 100px;
  border-top: 1px solid #dfdfdf;
  background: #fff;
  box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.15);
`;

export const FooterItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 133px;
  gap: 10px;
  height: 100%;
  flex-grow: 1;
  cursor: pointer;
  border-radius: 50px;
  transition: background 0.2s linear;

  &:hover {
    background: rgba(255, 0, 0, 0.05);
  }

  span {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  svg {
    height: 16px;
    color: #fe1933;
  }
`;

export const ButtonNews = styled.button`
  position: relative;
`;

export const BadgeNew = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: darkorange;
  font-size: 12px;
  top: -4px;
  right: 18px;
  position: absolute;
  color: #fff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const QrCodeArea = styled.div`
  width: 470px;
  background-color: grey;
  display: flex;
  justify-content: center;
`;

export const Img2 = styled.img`
  width: 320px;
`;

export const SaveButton = styled.button`
  margin-top: 16px;
  border: 0;
  color: #fff;
  background-color: #ff403d;
  border-radius: 7px;
  padding: 10px 15px;
  float: right;
  :hover {
    filter: brightness(1.2);
  }
`;

export const PaymentAdvice = styled.div`
  position: relative;
  z-index: 9;
  width: 100%;
  height: 60px;
  background-color: #FFF0F2;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  gap: 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.13);

  p {
    color: #3D3D3D;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
  }
`;