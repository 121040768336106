import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';

export default function formatToMonth(date) {
  if (!(date instanceof Date)) {
    return format(parseISO(date), 'MMM', {
      locale: pt,
    });
  }

  return format(date, 'MMM', {
    locale: pt,
  });
}
