/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { Badge } from 'reactstrap';
import api from '~/services/api';

import { Complement, OrdersInfo, ProductName } from './styles';
import { FaNoteSticky } from 'react-icons/fa6';

function OrderItem({ product, basket, isLate, index, isVeryLate }) {
  const handleCheckProduct = useCallback(
    async (id, status) => {
      await api.put(`/restaurants/kds/basket/order-check/${id}`, {
        checked: status,
      });
    },
    []
  );

  return (
    <OrdersInfo isLate={isLate} isVeryLate={isVeryLate} status={basket?.status}>
      <span style={{ display: 'flex', alignItems: 'center', width: "90%" }}>
        {basket?.status === 'doing' && !product.canceled_at && (
          <span>
            <input
              type="checkbox"
              width={25}
              checked={product.checked}
              onChange={() => {
                handleCheckProduct(product?.id, !product.checked);
              }}
            />
          </span>
        )}

        <span
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 10,
          }}
        >
          <ProductName canceled={product?.canceled_at} isLate={isLate} checked={product?.checked} isVeryLate={isVeryLate}>
            {product?.amount}x {product?.name}
          </ProductName>
          <span style={{ marginTop: 3, flexWrap: "wrap" }}>
            {product?.complements &&
              product?.complements.length > 0 &&
              product?.complements?.map((comp) => (
                <Complement isVeryLate={isVeryLate} isLate={isLate} key={comp?.id}>
                  {comp.amount}x {comp.name}
                </Complement>
              ))}
          </span>

          {product.details && (
            <span
              style={{ marginTop: 3, display: 'flex', alignItems: 'center' }}
            >
              <FaNoteSticky />
              <span style={{ marginLeft: 4, wordWrap: "break-word", maxWidth: "21rem" }}>{product.details}</span>
            </span>
          )}
        </span>
      </span>

      <span
        style={{
          display: 'flex',
          flexDirection: 'column',
          fontWeight: 'bold',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span style={{ fontSize: 18 }}>{product?.average_time}</span>
        <span style={{ fontSize: 10, marginTop: -6 }}>min</span>
      </span>
    </OrdersInfo>
  );
}

export default OrderItem;
