import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  padding: 20px;
  height: calc(100vh - 145px);
  overflow-y: auto;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  h1 {
    font-weight: bold;
    font-size: 20px;
  }
`;

export const ScrollArea = styled.div`
  overflow-y: scroll;
  max-height: 70vh;

  @media (max-width: 1281px) {
    max-height: 60vh;
  }

  @media (min-width: 1282px) and (max-width: 1441px) {
    max-height: 60vh;
  }
`;

export const Card = styled.div`
  width: 100%;
  height: calc(100% - 66px);
  padding: 30px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-weight: bold;
    font-size: 18px;
  }
  /* justify-content: center;
  display: flex;
  flex-direction: column; */
`;

export const ErrorContainer = styled.div`
  width: 60%;
  min-width: 600px;
  margin: 10px auto 30px auto;

  background: orange;
  color: #fff;

  border-radius: 8px;
  padding: 30px;

  p {
    text-align: center;
    font-size: 22px;
  }
`;

export const CategoriesTable = styled.table`
  min-width: 400px;
  max-width: 1400px;
  margin-bottom: 26px;
  margin-left: auto;
  margin-right: auto;
  border-collapse: separate;
  border-spacing: 10px 30px;
  background: ${(props) => props.theme.colors.white};

  border-radius: 8px;
  padding: 0 30px;
  display: block;
  overflow-x: auto;
  white-space: nowrap;

  thead {
    border-bottom: 30px solid transparent;
    text-align: center;
  }
  tbody {
    td {
      border-bottom: 1px solid #eee;
      text-align: center;
      border-bottom: none;
    }

    tr:last-child {
      td {
        border-bottom: none;
        text-align: center;
      }
    }
  }

  @media (max-width: 1680px) {
    max-width: 1200px;
  }

  @media (max-width: 1440px) {
    max-width: 1000px;
  }

  @media (max-width: 1366px) {
    max-width: 900px;
  }

  @media (max-width: 1024px) {
    max-width: 700px;
  }
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div``;

export const BoxTotal = styled.div`
  width: 90%;
  clear: both;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #dee2e6;
  display: flex;
`;

export const BoxOthers = styled.div`
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: space-around;
  width: 90%;
  flex-wrap: wrap;
`;

export const DateSelectorDiv = styled.div`
  width: 90%;
  clear: both;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 20px;
  display: flex;
`;

export const DateDiv = styled.div`
  right: 0;
  margin-left: 15px;
`;

export const SubmitDate = styled.button`
  height: 30px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 15px;
  border: none;
  background: #17a2b8;
  margin-left: 10px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
  }
`;

export const SelectDateButton = styled.button`
  height: 40px;
  border-radius: 20px;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  color: white;
  align-self: center;
  margin-right: 15px;
  border: none;
  background: #17a2b8;
  margin-left: 10px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
  }

  :selected {
    border: none;
  }
`;

export const DownloadSheetButton = styled.button`
  height: 30px;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  color: #2ec9b7;
  border: 1px solid #2ec9b7;
  background: transparent;
  font-weight: bold;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  }
`;
