import styled from 'styled-components';

export const Container = styled.div`
  padding: 30px;
`;

export const Wrapper = styled.div`
  padding: 30px;
  padding-top: 10px;
`;
