import styled from 'styled-components';
import { lighten } from 'polished';
import { Link } from 'react-router-dom';

import { Table } from 'reactstrap';

export const ButtonCollapse = styled.div`
  border: none;
  background: none;
  margin-bottom: 10px;
  border-bottom: 1px solid #EBEBEB;
  padding-bottom: 5px;
  width: 100%;
  cursor: pointer;
`;

export const ContainerPrint = styled.div`
  @page {
    size: 75mm 200mm;
    margin: 0;
  }
  @media print {
    html,
    body {
      width: 75mm;
      height: 200mm;
    }
    h1 {
      margin-right: auto;
    }
    img {
      width: 70mm;
      height: auto;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 30px;
  justify-content: center;
  display: flex;
`;

export const CategoriesTable = styled(Table)`
  width: 95%;
  min-width: 400px;
  align-items: center;
  border-collapse: separate;
  border-spacing: 0 20px;
  background: #fff6b3;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  padding: 0 30px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #dee2e6;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;
export const StyledLink = styled.button`
  &:hover {
    text-decoration: none;
  }
  border: none;

  margin-right: 10px;
`;

export const Delete = styled.div`
  border: none;
  background: none;

  &:active {
    border: none;
  }
  &:hover {
    cursor: pointer;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div``;

export const StatusTag = styled.div.attrs((props) => ({
  color: props.color,
  backgroundcolor: lighten(0.33, props.color),
}))`
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundcolor};
  font-weight: bold;
  width-max: 120px;
  padding: 5px 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  &::before {
    content: ' ';
    display: inline-block;
    position: relative;
    margin-right: 5px;
    width: 10px;
    height: 10px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
  }
`;
export const ModalPaymentBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const DisplayNoneImprimir = styled.button`
  display: none;
`;

export const DeleteAndEditActions = styled.div`
  display: flex;
  align-self: center;
  margin-top: auto;
  flex-direction: column;
  // height: 30px;
  margin: auto;
`;

export const CloseButton = styled(Link)`
  height: 25px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 0px;
  border: none;
  background: #dc3545;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 6px;
  width: 98%;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const ActionsInside = styled.div`
  padding-top: 2px;
  display: flex;
  flex-direction: row;
  float: right;
  // position: absolute;
  // clear: both;
  right: 0;
  // margin-right: 5px;
`;

export const CashierButton = styled(Link)`
  height: 25px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 0px;
  border: none;
  background: #28a745;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 6px;
  width: 98%;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const ReopenButton = styled(Link)`
  height: 25px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 0px;
  border: none;
  background: #17a2b8;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 6px;
  width: 98%;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const SeeButton = styled(Link)`
  height: 25px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 0px;
  border: none;
  background: #17a2b8;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 6px;
  width: 98%;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const Print = styled.button`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-self: center;
  float: right;
  position: relative;
  display: block;
  right: 0;
  margin-right: 10px;
  border: none;
  background: #f0b402;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
    border-radius: 50%;
  }
`;
