import { Table } from 'reactstrap';
import styled from 'styled-components';

import DatePicker from 'react-datepicker'
export const DisplayNoneImprimir = styled.button`
  display: none;
`;
export const Container = styled.tr`
  padding-bottom: 0px !important;

  border-bottom: none;
td{
  border-bottom: none;

}

`;

export const ContainerTr = styled.tr`
  border: none;
td{
  border: none;
}

`;

export const TdStyled = styled.td`
border: none;
border-bottom: none !important;
  padding-bottom: 0px !important;
  padding-top: 20px !important;
`

export const Card = styled(Table)`
  width: 100%;

  align-items: center;
  border-radius:7px;
  border-collapse: separate;

  background: #fff;




  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    border-top: none;
    tr {
          border: none;
      th {
        button {
          border: none;
          background-color: transparent;
          font-weight: bold;
          color: #333;
        }
      }
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    tr {
      border-bottom: none;
      td {
        border-bottom: none;
        text-align: left;
      }
    }
    td:last-child {
      text-align: right;
    }
  }
`;

export const DatePickerStyledUnify = styled(DatePicker)`
  height: 44px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  width: 100%;
  margin-top: 8px;
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }
`;

export const ClosedButton = styled.button`
  width: 110px;
  height: 36px;
  color: #979797;
  background: #D9D9D9;
  border-radius: 5px;
  border: none;
  font-weight: bold;
`