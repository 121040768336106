import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'ui-kit-takeat';
import { Container } from './styles';
import { NavBar } from './styles';
import { FaCashRegister } from 'react-icons/fa';
import { FaFilterCircleDollar } from 'react-icons/fa6';
import { HiDocumentDownload, HiDocumentRemove } from 'react-icons/hi';
import { PageTitle } from '~/components/PageTitle';

function Header() {
  const location = useLocation(); // Obtém a localização atual
  const [pathname, setPathname] = useState('');

  // Atualize o estado "pathname" sempre que a localização mudar
  useEffect(() => {
    setPathname(location.pathname);
  }, [location.pathname]);

  function getTitleName(path) {
    switch (path) {
      case '/operation/fiscal/manifest':
        return 'Manifesto de notas';

      case '/operation/fiscal/entries':
        return 'Entrada de notas';

      case '/operation/fiscal/issued':
        return 'Notas Emitidas';

      case '/operation/fiscal/nfe-avulsa':
        return 'Notas Emitidas';

      case '/operation/fiscal/returned':
        return 'Notas Devolvidas';

      default:
        break;
    }
  }

  return (
    <Container>
      <div>
        <PageTitle>{getTitleName(pathname)}</PageTitle>
      </div>

      <NavBar>
        <Link to={'/operation/fiscal/issued'}>
          <Button
            title="Notas Emitidas"
            icon={<HiDocumentDownload />}
            inverted={pathname !== '/operation/fiscal/issued'}
            onClick={() => {}}
          />
        </Link>
        <Link to={'/operation/fiscal/manifest'}>
          <Button
            title="Manifesto de Notas"
            icon={<FaCashRegister />}
            inverted={pathname !== '/operation/fiscal/manifest'}
            onClick={() => {}}
          />
        </Link>
        <Link to={'/operation/fiscal/entries'}>
          <Button
            title="Entrada de Notas"
            icon={<FaFilterCircleDollar />}
            inverted={pathname !== '/operation/fiscal/entries'}
            onClick={() => {}}
          />
        </Link>
        <Link to={'/operation/fiscal/returned'}>
          <Button
            title="Notas Devolvidas"
            icon={<HiDocumentRemove />}
            inverted={pathname !== '/operation/fiscal/returned'}
            onClick={() => {}}
          />
        </Link>
      </NavBar>
    </Container>
  );
}

export default Header;