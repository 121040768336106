/* eslint-disable no-console */
/* eslint-disable no-shadow */
import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { Form } from '@unform/web';

import {
  FaPlus,
  FaInfoCircle,
} from 'react-icons/fa';

import * as Yup from 'yup';
import { registerLocale } from 'react-datepicker';
import ReactSelect from 'react-select';
import FileSaver from 'file-saver';
import 'react-datepicker/dist/react-datepicker.css';
import pt from 'date-fns/locale/pt-BR';

import { MdDateRange } from 'react-icons/md';

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
  TabContent,
  TabPane,
  Table,
} from 'reactstrap';
import {
  format,
  subHours,
  startOfMonth,
  addHours,
  endOfMonth,
} from 'date-fns';
import ReactInputMask from 'react-input-mask';
import { toast } from 'react-hot-toast';
import { FiArrowDown, FiX } from 'react-icons/fi';
import { generateTransactionsSheet } from '~/services/SheetGenerate/generateTransactionsSheet';
import Select from '~/components/Form/SelectInput';

import Input from '~/components/Form/Input';
// import Select from '~/components/Form/SelectInput';

import SaveButton from '~/components/Buttons/DefaultButton';

import {
  Container,
  NumberFormatStyled,
  Card,
  Header,
  DataPickerArea,
  DatePickerStyled,
  FilterArea,
  DeleteFilterButton,
  StyledLink,
  DatePickerStyledUnify,
  ButtonAdd,
  CardTable,
  InnerArea,
  ButtonsArea,
} from './styles';
import { Tooltip } from 'ui-kit-takeat';

import Transaction from './Transaction';
import CheckboxInput from '~/components/Form/Checkbox';
import api from '~/services/api';
import getValidationErrors from '~/services/getValidationErrors';
import formatCompleteDate from '~/services/formatDate';
import formatValue from '~/utils/formatValue';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { Button, DownloadButton, FullHourCalendar, Tabs } from 'ui-kit-takeat';
import PersonalButton from '~/components/Buttons/PersonalButton';
import { PageTitle } from '~/components/PageTitle';
import ReactModal from 'react-modal';

registerLocale('pt', pt);

function Transactions() {
  const transactionFormRef = useRef(null);
  const [isModalErrorOpened, setIsModalErrorOpened] = useState(false);
  const [, setTransactions] = useState();
  const [banks, setBanks] = useState();
  const [suppliers, setSuppliers] = useState();
  const [paymentMethods, setPaymentMethods] = useState();
  const [categories, setCategories] = useState();
  const [isModalCreateTransactionOpen, setIsModalCreateTransactionOpen] =
    useState(false);
  const [dateSelected, setDateSelected] = useState({
    start: startOfMonth(subHours(new Date(), 3)),
    end: endOfMonth(subHours(new Date(), 3)),
  });
  const [bankUnify, setBankUnify] = useState(null);
  const [methodUnify, setMethodUnify] = useState(null);

  const [isEarningTotal, setIsEarningTotal] = useState();
  const [isNotEarningTotal, setIsNotEarningTotal] = useState();
  const [isEarningTotalPaid, setIsEarningTotalPaid] = useState();
  const [isNotEarningTotalPaid, setIsNotEarningTotalPaid] = useState();
  const [transactionsToUnify, setTransactionsToUnify] = useState([]);

  const [isEarningOption, setIsEarningOption] = useState(false);

  const [paidCheck, setPaidCheck] = useState(false);
  const [repitCheck, setRepitCheck] = useState(false);
  const [dueDate, setDuedate] = useState('');
  const [competenceDate, setCompetenceDate] = useState('');
  const [paidAtDate, setpaidAtDate] = useState('');
  const [paidParams, setPaidParams] = useState({
    value: '',
    label: 'Situação',
  });
  const [providerParams, setProviderParams] = useState({
    value: '',
    label: 'Fornecedor',
  });
  const [bankParams, setBankParams] = useState({
    value: '',
    label: 'Conta',
  });
  const [categoryParams, setCategoryParams] = useState({
    value: '',
    label: 'Centro de custo',
    type: '',
  });
  const [paymentParams, setPaymenParams] = useState({
    value: '',
    label: 'Método',
  });

  const [isEarning, setIsEarning] = useState({
    value: '',
    label: 'Despesas / Receitas',
  });

  const [, setCategorySelected] = useState({
    value: '',
    label: 'Centro de custo',
    type: '',
  });

  const [valueState, setValueState] = useState();
  const [isEarningTransactions, setIsEarningTransactions] = useState([]);
  const [isNotEarningTransactions, setIsNotEarningTransactions] = useState([]);

  const [activeTab, setActiveTab] = useState(0);

  function toggleTab(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  function handleSetPaidCheck() {
    setPaidCheck(!paidCheck);

    setpaidAtDate(new Date());
  }

  function handleSetRepitCheck() {
    setRepitCheck(!repitCheck);
  }

  function handleSetCheks() {
    setPaidCheck(false);
    setRepitCheck(false);
  }

  const toggleModalError = useCallback(() => {
    setIsModalErrorOpened(!isModalErrorOpened);
  }, [isModalErrorOpened]);

  function currencyFormatter(value) {
    if (!Number(value)) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  }

  // Date Select
  const defaultInicial = useMemo(() => {
    return format(
      startOfMonth(subHours(new Date(), 3)),
      "yyyy-MM-dd'T'HH:mm:ss"
    );
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfMonth(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const inicialFormat = format(new Date(defaultInicial), 'dd/MM/yyyy, HH:mm');
  const finalFormat = format(new Date(defaultFinal), 'dd/MM/yyyy, HH:mm');
  const phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [inicialDate, setInicialDate] = useState(
    startOfMonth(subHours(new Date(), 3))
  );
  const [finalDate, setFinalDate] = useState(
    endOfMonth(subHours(new Date(), 3))
  );

  const [, setHourPhrase] = useState(phrase);

  const freqOptions = [
    { value: 'day', label: 'Diáriamente' },
    { value: 'week', label: 'Semanalmente' },
    { value: 'month', label: 'Mensalmente' },
    { value: 'year', label: 'Anualmente' },
  ];

  const [loading, setLoading] = useState(false);

  // Get Datas
  const getTransactions = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get('/restaurants/cash-flows', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          paid: paidParams.value,
          provider_id: providerParams.value,
          bank_account_id: bankParams.value,
          payment_method_id: paymentParams.value,
          category_subcategory_id: categoryParams.value,
          is_earning: isEarning.value,
          blind_cash_flow: true,
        },
      });

      setTransactions(response.data.cash_flows);
      setIsNotEarningTotal(response.data.is_not_earning_total);
      setIsEarningTotal(response.data.is_earning_total);
      setIsNotEarningTotalPaid(response.data.is_not_earning_total_paid);
      setIsEarningTotalPaid(response.data.is_earning_total_paid);

      const isEarningTransactionsData = response.data.cash_flows.filter(
        (item) => item.is_earning
      );
      setIsEarningTransactions(isEarningTransactionsData);

      const isNotEarningTransactionsData = response.data.cash_flows.filter(
        (item) => !item.is_earning
      );
      setIsNotEarningTransactions(isNotEarningTransactionsData);
    } catch (error) {
      toast.error('Erro ao baixar movimentações');
    }
    setLoading(false);
  }, [
    finalDate,
    inicialDate,
    paidParams,
    providerParams,
    paymentParams,
    categoryParams,
    bankParams,
    isEarning,
  ]);

  // Get Datas after post new transaction
  const getNewTransactions = useCallback(async (data) => {
    try {
      const response = await api.get('/restaurants/cash-flows', {
        params: {
          start_date: `${format(
            addHours(new Date(data.inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(data.finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          paid: data.paidParams,
          provider_id: data.providerParams,
          bank_account_id: data.bankParams,
          payment_method_id: data.paymentParams,
          category_subcategory_id: data.categoryParams,
          is_earning: data.isEarning,
          blind_cash_flow: true,
        },
      });
      console.log(data);
      setTransactions(response.data.cash_flows);
      setIsNotEarningTotal(response.data.is_not_earning_total);
      setIsEarningTotal(response.data.is_earning_total);
      setIsNotEarningTotalPaid(response.data.is_not_earning_total_paid);
      setIsEarningTotalPaid(response.data.is_earning_total_paid);

      const isEarningTransactionsData = response.data.cash_flows.filter(
        (item) => item.is_earning
      );
      setIsEarningTransactions(isEarningTransactionsData);

      const isNotEarningTransactionsData = response.data.cash_flows.filter(
        (item) => !item.is_earning
      );
      setIsNotEarningTransactions(isNotEarningTransactionsData);
    } catch (error) {
      toast.error('Erro ao baixar movimentações');
    }
  }, []);

  const getBanks = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows/bank-accounts');

      const banksSelect = response?.data?.map((bank) => {
        return {
          value: bank.id,
          label: bank.name,
        };
      });

      setBanks(banksSelect);
    } catch (error) {
      toast.error('Erro ao baixar contas de banco');
    }
  }, []);

  const getSuppliers = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows/providers');

      const providersSelect = response?.data?.map((provider) => {
        return {
          value: provider.id,
          label: provider.name,
        };
      });

      setSuppliers(providersSelect);
    } catch (error) {
      toast.error('Erro ao baixar fornecedores');
    }
  }, []);

  const getPaymentMethods = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows/payment-methods');

      const methodsSelect = response?.data?.map((method) => {
        return {
          value: method.id,
          label: method.name,
        };
      });

      setPaymentMethods(methodsSelect);
    } catch (error) {
      toast.error('Erro ao baixar métodos de pagamento');
    }
  }, []);

  const getCategoriesDatails = useCallback(async () => {
    try {
      const response = await api.get(
        '/restaurants/cash-flows/categories-detail'
      );

      const categoriesSelect = response?.data?.map((category) => {
        return {
          value: category.id,
          label: category.text,
          type: category.type,
          is_deleted: category.is_deleted,
        };
      });

      setCategories(categoriesSelect);
    } catch (error) {
      toast.error('Erro ao baixar centro de custos');
    }
  }, []);

  useEffect(() => {
    getTransactions();
  }, [
    paidParams,
    providerParams,
    paymentParams,
    categoryParams,
    bankParams,
    isEarning,
  ]);

  useEffect(() => {
    getTransactions();
  }, []);

  useEffect(() => {
    getBanks();
  }, [getBanks]);

  useEffect(() => {
    getSuppliers();
  }, [getSuppliers]);

  useEffect(() => {
    getPaymentMethods();
  }, [getPaymentMethods]);

  useEffect(() => {
    getCategoriesDatails();
  }, [getCategoriesDatails]);

  const toggleModalCreateTransaction = useCallback(() => {
    setIsModalCreateTransactionOpen(!isModalCreateTransactionOpen);

    setCategorySelected({
      value: '',
      label: 'Centro de custo',
      type: '',
    });
    setValueState('');
  }, [isModalCreateTransactionOpen]);

  const [isModalErrorCategoryOpened, setIsModalErrorCategoryOpened] =
    useState(false);

  const toggleModalErrorCategory = useCallback(() => {
    setIsModalErrorCategoryOpened(!isModalErrorCategoryOpened);
  }, [isModalErrorCategoryOpened]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        // if (categorySelected.type === 'insumos') {
        //   toggleModalErrorCategory();
        // } else

        if (!dueDate || !valueState) {
          toggleModalError();
        } else {
          const readyValue = (valueState.floatValue / 100).toFixed(2);

          const is_earning = data.is_earning || false;

          if (transactionFormRef?.current) {
            transactionFormRef.current.setErrors({});
          }
          const schema = Yup.object().shape({
            cash_flow_category_subcategory_id: is_earning
              ? Yup.string().nullable()
              : Yup.string().required('Centro de custo obrigatório'),
            bank_account_id: Yup.string().required('Conta obrigatória'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          const response = await api.post('/restaurants/cash-flows', {
            value: readyValue,
            description: data.description,
            information: data.information,
            due_date: dueDate,
            competence_date: competenceDate,
            paid: data.paid,
            paid_at: paidAtDate,
            provider_id: data.provider_id || null,
            bank_account_id: data.bank_account_id,
            cash_flow_payment_method_id:
              data.cash_flow_payment_method_id || null,
            cash_flow_category_subcategory_id: is_earning
              ? null
              : data.cash_flow_category_subcategory_id,
            freq_type: data.freq_type || '',
            freq_value: data.freq_value || '',
            is_earning,
          });

          toast.success('Movimentação cadastrada com sucesso!');

          const newData = {
            inicialDate: inicialDate,
            finalDate: finalDate,
            paid: paidParams.value,
            provider_id: providerParams.value,
            bank_account_id: bankParams.value,
            payment_method_id: paymentParams.value,
            category_subcategory_id: categoryParams.value,
            is_earning: isEarning.value,
          };

          getNewTransactions(newData);

          // if (response.data[0].is_earning) {

          //   const newIsEarning = [response.data[0], ...isEarningTransactions]

          //   setIsEarningTransactions(newIsEarning)
          // } else {
          //   const notIsEarning = [response.data[0], ...isNotEarningTransactions]
          //   setIsNotEarningTransactions(notIsEarning);
          // }

          toggleModalCreateTransaction();
          setCategorySelected({
            value: '',
            label: 'Centro de custo',
            type: '',
          });
          setValueState('');
          setIsEarningOption(false);
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (transactionFormRef?.current) {
            transactionFormRef.current.setErrors(errors);
          }
        } else {
          toast.error(`Erro ao cadastrar Movimentação!`);
          console.log(error);
        }
      }
    },
    [
      getNewTransactions,
      toggleModalCreateTransaction,
      competenceDate,
      dueDate,
      paidAtDate,
      valueState,
      toggleModalError,
    ]
  );

  const handleDeleteTransaction = useCallback(
    async (id) => {
      try {
        if (window.confirm('Deseja excluir essa movimentação?')) {
          await api.delete(`/restaurants/cash-flows/${id}`);

          toast.success('Movimentação deletada com sucesso!');
          getTransactions();
        }
      } catch (error) {
        toast.error('Erro ao deletar a movimentação');
      }
    },
    [getTransactions]
  );

  function deleteFilters() {
    setBankParams('');
    setPaymenParams('');
    setProviderParams('');
    setPaidParams('');
    setCategoryParams('');
    setIsEarning('');
  }

  function handleChangePaidSituation() {
    if (paidParams) {
      if (paidParams.value === true) {
        setPaidParams({ value: false, label: 'Não Pago' });
      }

      if (paidParams.value === false) {
        setPaidParams({ value: true, label: 'Pago' });
      }

      if (paidParams.value === '') {
        setPaidParams({ value: true, label: 'Pago' });
      }
    } else {
      setPaidParams({ value: true, label: 'Pago' });
    }
    getTransactions();
  }

  const handleOrderDueDate = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          paid: paidParams.value,
          provider_id: providerParams.value,
          bank_account_id: bankParams.value,
          payment_method_id: paymentParams.value,
          category_subcategory_id: categoryParams.value,
          blind_cash_flow: true,
        },
      });

      const sorted = response.data.cash_flows.sort(function (a, b) {
        if (a.due_date > b.due_date) {
          return 1;
        }

        if (a.due_date < b.due_date) {
          return -1;
        }

        return 0;
      });
      setIsNotEarningTransactions(sorted);
    } catch (error) {
            toast.error('Erro ao baixar movimentações');
    }
  }, [bankParams,
    finalDate,
    inicialDate,
    categoryParams,
    paidParams,
    providerParams,
    paymentParams,]);

  const handleOrderCostCenter = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          paid: paidParams.value,
          provider_id: providerParams.value,
          bank_account_id: bankParams.value,
          payment_method_id: paymentParams.value,
          category_subcategory_id: categoryParams.value,
          blind_cash_flow: true,
        },
      });
      const sorted = response.data.cash_flows.sort(function (a, b) {
        if (a.text > b.text) {
          return 1;
        }

        if (a.text < b.text) {
          return -1;
        }

        return 0;
      });
      setIsNotEarningTransactions(sorted);
    } catch (error) {
      toast.error('Erro ao baixar movimentações');
    }
  }, [
    bankParams,
    finalDate,
    inicialDate,
    categoryParams,
    paidParams,
    providerParams,
    paymentParams,
  ]);


  const handleOrderValue = useCallback( async () => {
    try {
      const response = await api.get('/restaurants/cash-flows', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          paid: paidParams.value,
          provider_id: providerParams.value,
          bank_account_id: bankParams.value,
          payment_method_id: paymentParams.value,
          category_subcategory_id: categoryParams.value,
          blind_cash_flow: true,
        },
      });
      const sorted = response.data.cash_flows.sort(function (a, b) {
        const valueA = parseFloat(a.value);
        const valueB = parseFloat(b.value);
        if (valueA > valueB) {
          return -1;
        }

        if (valueA < valueB) {
          return 1;
        }

        return 0;
      });
      setIsNotEarningTransactions(sorted);
    } catch (error) {
      console.log(error);
      toast.error('Erro ao baixar movimentações');
    }
  },[
    bankParams,
    finalDate,
    inicialDate,
    categoryParams,
    paidParams,
    providerParams,
    paymentParams,
  ]);

  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);

  const handleOrderProviders = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          paid: paidParams.value,
          provider_id: providerParams.value,
          bank_account_id: bankParams.value,
          payment_method_id: paymentParams.value,
          category_subcategory_id: categoryParams.value,
          blind_cash_flow: true,
        },
      });

      const sorted = response.data.cash_flows.sort((a, b) => {
        const descriptionA = a.description.toUpperCase();
        const descriptionB = b.description.toUpperCase();
        if (descriptionA > descriptionB) {
          return 1;
        }

        if (descriptionA < descriptionB) {
          return -1;
        }

        return 0;
      });
      
      setIsNotEarningTransactions(sorted);
    } catch (error) {
      toast.error('Erro ao baixar movimentações');
      console.log(error);
    }
  }, [
    bankParams,
    finalDate,
    inicialDate,
    categoryParams,
    paidParams,
    providerParams,
    paymentParams,
  ]);

  
  function handleValueChange(e) {
    setValueState(e);
  }

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'FinanceCashFlows'}`
      );

      const { can_read } = response.data.FinanceCashFlows;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  const isEarningOptions = [
    {
      label: 'Despesas',
      value: false,
    },
    {
      label: 'Receitas',
      value: true,
    },
  ];

  function handleAddTransactionToUnify(transaction) {
    const transactionFind = transactionsToUnify.findIndex(
      (item) => item.id === transaction.id
    );

    if (transactionFind !== -1) {
      setTransactionsToUnify((state) => {
        const transactionFiltered = state.filter(
          (item) => item.id !== transaction.id
        );
        console.log(transactionFiltered);
        return transactionFiltered;
      });
    } else {
      setTransactionsToUnify((state) => {
        console.log([...state, transaction]);
        return [...state, transaction];
      });
    }
  }

  const [isModalUnifyTransactionOpened, setModalUnifyTransactionsOpened] =
    useState(false);

  function toggleModalUnifyTransactions() {
    setModalUnifyTransactionsOpened(!isModalUnifyTransactionOpened);
  }

  const submitUnify = useCallback(
    async (data) => {
      try {
        const transactions = transactionsToUnify.map((trans) => trans.id);
        const handleData = {
          due_date: dueDate,
          bank_account_id: bankUnify,
          cash_flow_payment_method_id: methodUnify,
          description: data.description,
          transactions,
        };
        await api.post('/restaurants/cash-flows/unify', handleData);
        toast.success('Lançamentos unificados com sucesso!');
        setTransactionsToUnify([]);
        setBankUnify(null);
        setDuedate(null);
        setMethodUnify(null);
        toggleModalUnifyTransactions();
        getTransactions();
      } catch (error) {
        console.log(error);
        toast.error('Erro ao unificar lançamentos. Tente novamente');
      }
    },
    [transactionsToUnify, dueDate, methodUnify, bankUnify]
  );

  const handleDownloadSheet = async () => {
    try {
      const sheet = await generateTransactionsSheet(
        isNotEarningTransactions,
        isEarningTransactions
      );

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        `Lancamentos(${format(new Date(inicialDate), 'dd-MM')}_${format(
          new Date(finalDate),
          'dd-MM'
        )}).xlsx`
      );
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };

  useEffect(() => {
    setInicialDate(
      format(new Date(dateSelected.start), "yyyy-MM-dd'T'HH:mm:ss")
    );
    setFinalDate(format(new Date(dateSelected.end), "yyyy-MM-dd'T'HH:mm:ss"));
  }, [dateSelected]);

  //create banks
  const [isModalCreateBankOpen, setIsModalCreateBankOpen] = useState(false);
  const formBankRef = useRef(null);

  const toggleModalCreateBank = useCallback(() => {
    setIsModalCreateBankOpen(!isModalCreateBankOpen);
  }, [isModalCreateBankOpen]);

  const handleSubmitBank = useCallback(
    async (data) => {
      try {
        if (formBankRef?.current) {
          formBankRef.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/restaurants/cash-flows/bank-accounts', {
          name: data.name,
        });

        toast.success('Conta cadastrada com sucesso!');

        getBanks();
        toggleModalCreateBank();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formBankRef?.current) {
            formBankRef.current.setErrors(errors);
          }
        } else {
          // console.tron.log(error);
          toast.error(`Erro ao cadastrar conta!`);
        }
      }
    },
    [getBanks, toggleModalCreateBank]
  );

  // create suplliers

  const [isModalCreateSuppliersOpen, setIsModalCreatesuppliersOpen] =
    useState(false);

  const formRefSupplier = useRef(null);
  const toggleModalCreateSuplliers = useCallback(() => {
    setIsModalCreatesuppliersOpen(!isModalCreateSuppliersOpen);
  }, [isModalCreateSuppliersOpen]);

  const handleSupplierSubmit = useCallback(
    async (data) => {
      try {
        if (formRefSupplier?.current) {
          formRefSupplier.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          cnpj: Yup.string()
            .required('CNPJ/ CPF obrigatório')
            .trim()
            .matches('^[0-9]+$', 'Apenas números, sem espaço'),
          phone: Yup.string().required('Telefone obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/restaurants/cash-flows/providers', {
          name: data.name,
          phone: data.phone,
          cnpj: data.cnpj,
        });

        toast.success('Fornecedor cadastrada com sucesso!');

        getSuppliers();
        toggleModalCreateSuplliers();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRefSupplier?.current) {
            formRefSupplier.current.setErrors(errors);
          }
        } else {
          toast.error(`Erro ao cadastrar fornecedor!`);
        }
      }
    },
    [getSuppliers, toggleModalCreateSuplliers]
  );

  //create cost center
  const [isModalCreateCategoryOpen, setIsModalCreateCategoryOpen] =
    useState(false);
  const [subCategories, setSubCategories] = useState(false);
  const cashFlowTypes = [
    { value: 'financeiro', label: 'Financeiro' },
    { value: 'operacional', label: 'Operacional' },
    { value: 'insumos', label: 'Insumos e Produtos' },
  ];

  const selecSubCategoriesOptions =
    subCategories.length > 0 &&
    subCategories?.map((sub) => {
      return {
        value: sub.id,
        label: sub.name,
      };
    });

  const formCategoryRef = useRef(null);
  const getSubCategories = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows/subcategories');

      setSubCategories(response.data);
    } catch (error) {
      toast.error('Erro ao baixar subcategorias');
    }
  }, []);

  const toggleModalCreateCategory = useCallback(() => {
    setIsModalCreateCategoryOpen(!isModalCreateCategoryOpen);
  }, [isModalCreateCategoryOpen]);

  const handleCategorySubmit = useCallback(
    async (data) => {
      try {
        if (formCategoryRef?.current) {
          formCategoryRef.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          type: Yup.string().required('Tipo obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/restaurants/cash-flows/categories', {
          name: data.name,
          subcategories: data.subcategories,
          type: data.type,
        });

        toast.success('Categoria cadastrada com sucesso!');

        getCategoriesDatails();
        toggleModalCreateCategory();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formCategoryRef?.current) {
            formCategoryRef.current.setErrors(errors);
          }
        } else {
          toast.error(`Erro ao cadastrar catedoria!`);
        }
      }
    },
    [getCategoriesDatails, toggleModalCreateCategory]
  );

  useEffect(() => {
    getSubCategories();
  }, [getSubCategories]);

  //create payment method
  const formPaymentRef = useRef(null);
  const [isModalCreateMethodOpen, setIsModalCreateMethodOpen] = useState(false);

  const toggleModalCreateMethod = useCallback(() => {
    setIsModalCreateMethodOpen(!isModalCreateMethodOpen);
  }, [isModalCreateMethodOpen]);

  const handleMethodSubmit = useCallback(
    async (data) => {
      try {
        if (formPaymentRef?.current) {
          formPaymentRef.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/restaurants/cash-flows/payment-methods', {
          name: data.name,
        });

        toast.success('Conta cadastrada com sucesso!');

        getPaymentMethods();
        toggleModalCreateMethod();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formPaymentRef?.current) {
            formPaymentRef.current.setErrors(errors);
          }
        } else {
          // console.tron.log(error);
          toast.error(`Erro ao cadastrar conta!`);
        }
      }
    },
    [getPaymentMethods, toggleModalCreateMethod]
  );
  const [blindCashFlowTooltip, setBlindCashFlowTooltip] = useState(false);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Modal isOpen={isModalCreateBankOpen} toggle={toggleModalCreateBank}>
        <ModalHeader> Criar Conta</ModalHeader>
        <Form onSubmit={handleSubmitBank} ref={formBankRef}>
          <ModalBody>
            <Input label="Nome do banco" name="name" />
          </ModalBody>
          <ModalFooter>
            <SaveButton type="submit" message="Salvar" />
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={isModalCreateSuppliersOpen}
        toggle={toggleModalCreateSuplliers}
      >
        <ModalHeader> Criar Fornecedor</ModalHeader>
        <Form onSubmit={handleSupplierSubmit} ref={formRefSupplier}>
          <ModalBody>
            <Input label="Nome do fornecedor" name="name" />
            <Input label="CNPJ" name="cnpj" />
            <ReactInputMask mask="(99) 99999-9999">
              <Input
                label="Telefone"
                name="phone"
                maskChar=""
                placeholder="(xx) xxxxx-xxxx"
              />
            </ReactInputMask>
          </ModalBody>
          <ModalFooter>
            <Button title="Cancelar" onClick={toggleModalCreateSuplliers}>
              Cancelar
            </Button>
            <Button buttonColor="#2EC9B7" type="submit" title="Salvar">
              Salvar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={isModalCreateCategoryOpen}
        toggle={toggleModalCreateCategory}
      >
        <ModalHeader> Criar Categoria</ModalHeader>
        <Form onSubmit={handleCategorySubmit} ref={formCategoryRef}>
          <ModalBody>
            <Input label="Nome do categoria" name="name" />
            <Select
              label="Subcategorias"
              name="subcategories"
              isMulti
              options={selecSubCategoriesOptions}
            />
            <Select label="Tipo" name="type" options={cashFlowTypes} />
          </ModalBody>
          <ModalFooter>
            <SaveButton type="submit" message="Salvar" />
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={isModalCreateMethodOpen} toggle={toggleModalCreateMethod}>
        <ModalHeader> Criar métodos de pagamento</ModalHeader>
        <Form onSubmit={handleMethodSubmit} ref={formPaymentRef}>
          <ModalBody>
            <Input label="Nome do método" name="name" />
          </ModalBody>
          <ModalFooter>
            <SaveButton type="submit" message="Salvar" />
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={isModalUnifyTransactionOpened}
        toggle={toggleModalUnifyTransactions}
        size="xl"
      >
        <Form onSubmit={submitUnify}>
          <ModalHeader>Unificar contas a receber</ModalHeader>
          <ModalBody>
            <Table>
              <thead>
                <tr>
                  <th>Recebimento</th>
                  <th>Método de pagamento</th>
                  <th>Competência</th>
                  <th>Conta bancária</th>
                  <th>Valor</th>
                </tr>
              </thead>
              <tbody>
                {transactionsToUnify &&
                  transactionsToUnify.length > 0 &&
                  transactionsToUnify.map((item) => (
                    <tr>
                      <td>{formatCompleteDate(item?.due_date || 0)}</td>
                      <td>{item?.cash_flow_payment_method?.name || '-'}</td>
                      <td>{item?.description || '-'}</td>
                      <td>{item?.bank_account?.name || '-'}</td>
                      <td>{formatValue(item?.value) || '-'}</td>
                    </tr>
                  ))}
                <tr>
                  <td>
                    <DatePickerStyledUnify
                      selected={dueDate}
                      onChange={(date) => setDuedate(date)}
                      locale="pt"
                      name="due_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="__/__/____"
                      customInput={
                        <ReactInputMask mask={'99/99/9999'} maskChar={null} />
                      }
                    />
                  </td>
                  <td>
                    {' '}
                    <ReactSelect
                      placeholder="Método"
                      options={paymentMethods}
                      onChange={(e) => {
                        setMethodUnify(e.value);
                      }}
                      name="cash_flow_payment_method_id"
                    />
                  </td>
                  <td>
                    <Input placeHolder="Descrição" name="description" />
                  </td>
                  <td style={{ marginBottom: 15 }}>
                    <ReactSelect
                      placeholder="Conta"
                      options={banks}
                      onChange={(e) => {
                        setBankUnify(e.value);
                      }}
                      name="bank_account_id"
                    />
                  </td>
                  <td>
                    <span style={{ color: 'green', fontWeight: 'bold' }}>
                      {' '}
                      Total R${' '}
                      {transactionsToUnify
                        .reduce((accum, curr) => accum + Number(curr.value), 0)
                        .toFixed(2)
                        .replace('.', ',')}
                    </span>
                  </td>
                </tr>
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-warning"
              onClick={toggleModalUnifyTransactions}
            >
              Cancelar
            </button>
            <button type="submit" className="btn btn-success">
              Unificar
            </button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={isModalErrorOpened} toggle={toggleModalError}>
        <ModalBody>Os campos Valor e Vencimento são obrigatórios</ModalBody>
        <ModalFooter>
          <SaveButton onClick={toggleModalError} message="Ok, entendi" />
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={isModalErrorCategoryOpened}
        toggle={toggleModalErrorCategory}
      >
        <ModalBody>
          <strong> Não é possível concluir a ação.</strong>
          <p>Contas a Pagar de centros de custos relacionados a:</p>
          <p>
            <strong> • Insumos</strong>
          </p>
          <p>
            <strong>• Revendas</strong>
          </p>
          <p>
            {' '}
            devem ser lançados através da página de cadastro ou edição de
            insumos, pois o pagamento deve estar vinculado a uma entrada de
            mercadoria Botão: Ir para a página de insumos Na página, basta
            editar um insumo existente ou cadastrar um novo. O lançamento
            entrará no financeiro automaticamente
          </p>
        </ModalBody>
        <ModalFooter>
          <StyledLink onClick={toggleModalErrorCategory} to="/products/inputs">
            Ir para insumos
          </StyledLink>
          <SaveButton onClick={toggleModalErrorCategory} message="Voltar" />
        </ModalFooter>
      </Modal>

      <Header>
        <PageTitle>
          <div style={{ position: 'relative' }}>
            Lançamentos
            <FaInfoCircle
              style={{ marginLeft: 5 }}
              size={22}
              onMouseEnter={() => setBlindCashFlowTooltip(true)}
              onMouseLeave={() => setBlindCashFlowTooltip(false)}
            ></FaInfoCircle>
            <Tooltip
              show={blindCashFlowTooltip}
              content={
                <p style={{ textAlign: 'justify' }}>
                  Para ter uma visualização completa dos Lançamentos, é necessário entrar na Área do Gestor.
                </p>
              }
              containerStyles={{
                top: '30px',
                left: '91px',
                width: '288px',
              }}
            />
          </div>
        </PageTitle>
        <div
          style={{
            display: 'flex',
            gap: 10,
          }}
        >
          <FullHourCalendar
            value={dateSelected}
            onDateSelected={(e) => setDateSelected(e)}
            hideFields={['this_year']}
            maxRange={90}
            onError={(e) => toast.error(e)}
            isLoading={loading}
            disabled={loading}
          />

          <FullHourCalendar
            isEnd
            value={dateSelected}
            onDateSelected={(e) => setDateSelected(e)}
            onError={(e) => toast.error(e)}
            hideFields={['this_year']}
            maxRange={90}
            isLoading={loading}
            disabled={loading}
          />
          <Button
            title="Buscar"
            onClick={() => getTransactions()}
            style={{ height: 39, padding: 10 }}
            inverted
          />
        </div>
        <Button
          title="Cadastrar"
          icon={<FaPlus />}
          onClick={toggleModalCreateTransaction}
        />
        {/* <RegisterButton onClick={toggleModalCreateTransaction} /> */}
      </Header>

      <FilterArea>
        <div style={{ flexGrow: 1 }}>
          <ReactSelect
            placeholder="Conta"
            options={banks}
            onChange={(e) => {
              setBankParams(e);
            }}
            value={bankParams}
          />
        </div>
        <div style={{ flexGrow: 1 }}>
          <ReactSelect
            placeholder="Forma de pagamento"
            options={paymentMethods}
            onChange={(e) => {
              setPaymenParams(e);
            }}
            value={paymentParams}
          />
        </div>
        <div style={{ flexGrow: 1 }}>
          <ReactSelect
            placeholder="Centro de custo"
            options={categories}
            onChange={(e) => {
              setCategoryParams(e);
            }}
            value={categoryParams}
          />
        </div>
        <div style={{ flexGrow: 1 }}>
          <ReactSelect
            placeholder="Fornecedor"
            options={suppliers}
            onChange={(e) => {
              setProviderParams(e);
            }}
            value={providerParams}
          />
        </div>

        <div style={{ flexGrow: 1 }}>
          <ReactSelect
            placeholder="Situação"
            options={[
              { label: 'Pago', value: true },
              { label: 'Não Pago', value: false },
            ]}
            onChange={(e) => {
              setPaidParams(e);
            }}
            value={paidParams}
          />
        </div>

        <DeleteFilterButton onClick={deleteFilters}>Limpar</DeleteFilterButton>
      </FilterArea>

        <div style={{ height: 30 }} />

      <div style={{ display: 'flex', gap: 10, zIndex: 0, position: 'relative' }}>
        <Tabs
          tabs={['Contas a Pagar', 'Contas a Receber']}
          onChangeTab={setActiveTab}
          selected={activeTab}
        />
        <DownloadButton
          justifyContent="flex-start"
          text="Baixar planilha"
          onClick={handleDownloadSheet}
          fixed="open"
          width={145}
        />
      </div>

      <TabContent activeTab={activeTab}>
        <TabPane tabId={0}>
          <Card firstTab>
            <CardTable borderless>
              <thead>
                <tr>
                  <th>
                    {' '}
                    <button
                      type="button"
                      onClick={handleOrderDueDate}
                      title="Ordenar por vencimento"
                    >
                      <FiArrowDown />
                      Vencimento
                    </button>
                  </th>
                  <th>
                    {' '}
                    <button
                      type="button"
                      onClick={handleOrderProviders}
                      title="Ordenar por descrição"
                    >
                      <FiArrowDown />
                      Descrição
                    </button>
                  </th>
                  <th>
                    {' '}
                    <button
                      type="button"
                      onClick={handleOrderProviders}
                      title="Ordenar por fornecedor"
                    >
                      <FiArrowDown />
                      Fornecedor
                    </button>
                  </th>
                  <th>
                    {' '}
                    <button
                      type="button"
                      onClick={handleOrderCostCenter}
                      title="Ordenar por centro de custo"
                    >
                      <FiArrowDown />
                      Centro de custo
                    </button>
                  </th>
                  <th>
                    <button
                      type="button"
                      className="true"
                      onClick={handleChangePaidSituation}
                      title="Alterar situação"
                    >
                      Pago?
                    </button>
                  </th>
                  <th>
                  <button
                  type="button"
                  className="true"
                  onClick={handleOrderValue}
                  title="Ordenar por valor"
                  >
                  <FiArrowDown />  
                  VALOR
                  </button>
                  </th>
                  <th>Ações</th>
                </tr>
              </thead>

              {isNotEarningTransactions &&
                isNotEarningTransactions.length > 0 &&
                isNotEarningTransactions.map((transactions) => (
                  <tbody key={transactions.id}>
                    <Transaction
                      transaction={transactions}
                      handleDeleteTransaction={handleDeleteTransaction}
                      getTransactions={getTransactions}
                      banks={banks}
                      getBanks={getBanks}
                      paymentMethods={paymentMethods}
                      suppliers={suppliers}
                      freqOptions={freqOptions}
                      categories={categories}
                      handleAddTransactionToUnify={handleAddTransactionToUnify}
                      isEarningTransaction={false}
                      getPaymentMethods={getPaymentMethods}
                      getSuppliers={getSuppliers}
                      getCategories={getCategoriesDatails}
                    />
                  </tbody>
                ))}
            </CardTable>
          </Card>
        </TabPane>
        <TabPane tabId={1}>
          <Card firstTab={activeTab === 0}>
            <CardTable borderless>
              <thead>
                <tr>
                  <th>
                    <button
                      type="button"
                      className="btn btn-warning"
                      disabled={transactionsToUnify.length < 2}
                      style={{ color: '#fff' }}
                      onClick={toggleModalUnifyTransactions}
                    >
                      Unificar
                    </button>
                  </th>
                  <th>
                    {' '}
                    <button
                      type="button"
                      onClick={handleOrderDueDate}
                      title="Ordenar por vencimento"
                    >
                      <FiArrowDown />
                      Recebimento
                    </button>
                  </th>
                  <th>Método</th>
                  <th>Descrição</th>
                  <th>Conta Bancária</th>
                  <th>Recebido</th>
                  <th>Valor</th>
                  <th>Ações</th>
                </tr>
              </thead>

              {isEarningTransactions &&
                isEarningTransactions.length > 0 &&
                isEarningTransactions.map((transaction) => (
                  <tbody key={transaction.id}>
                    <Transaction
                      transaction={transaction}
                      handleDeleteTransaction={handleDeleteTransaction}
                      getTransactions={getTransactions}
                      banks={banks}
                      getBanks={getBanks}
                      paymentMethods={paymentMethods}
                      suppliers={suppliers}
                      freqOptions={freqOptions}
                      categories={categories}
                      handleAddTransactionToUnify={handleAddTransactionToUnify}
                      isEarningTransaction
                    />
                  </tbody>
                ))}
            </CardTable>
          </Card>
        </TabPane>
      </TabContent>

      <ReactModal
        onRequestClose={toggleModalCreateTransaction}
        isOpen={isModalCreateTransactionOpen}
        className="modal-content-takeat-without-overflow"
        closeTimeoutMS={500}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 20,
            paddingBottom: 0,
          }}
        >
          <PageTitle>Novo Lançamento</PageTitle>
          <FiX
            onClick={toggleModalCreateTransaction}
            color="#ff2c3a"
            size={21}
            cursor="pointer"
          />
        </div>
        <InnerArea browserZoomLevel={browserZoomLevel}>
          <Form onSubmit={handleSubmit} ref={transactionFormRef}>
            <Row>
              <Col md="4">
                <DataPickerArea>
                  <span>Valor</span>
                  <NumberFormatStyled
                    prefix="R$"
                    placeholder="R$"
                    format={currencyFormatter}
                    name="value"
                    onValueChange={(e) => {
                      handleValueChange(e);
                    }}
                  />
                </DataPickerArea>
              </Col>
              <Col md="4">
                <DataPickerArea>
                  <span>
                    Vencimento <MdDateRange />
                  </span>

                  <DatePickerStyled
                    selected={dueDate}
                    onChange={(date) => setDuedate(date)}
                    locale="pt"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="__/__/____"
                    customInput={
                      <ReactInputMask mask={'99/99/9999'} maskChar={null} />
                    }
                  />
                </DataPickerArea>
              </Col>
              <Col md="4">
                <DataPickerArea>
                  <>
                    <span>
                      Competência <MdDateRange />
                    </span>

                    <DatePickerStyled
                      selected={competenceDate}
                      onChange={(date) => setCompetenceDate(date)}
                      locale="pt"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="__/__/____"
                      customInput={
                        <ReactInputMask mask={'99/99/9999'} maskChar={null} />
                      }
                    />
                  </>
                </DataPickerArea>
              </Col>
            </Row>

            <Row>
              <Col md="4">
                <Select
                  label="Tipo de Lançamento"
                  name="is_earning"
                  options={[
                    { value: false, label: 'Despesa' },
                    { value: true, label: 'Receita' },
                  ]}
                  defaultValue={{ value: false, label: 'Despesa' }}
                  onChange={(e) => setIsEarningOption(e.value)}
                />
              </Col>
              <Col md="4">
                <Row>
                  <Col md="10">
                    <Select
                      label="Conta"
                      name="bank_account_id"
                      placeholder="Selecione a conta"
                      options={banks}
                    />
                  </Col>
                  <Col md="1">
                    <ButtonAdd
                      type="button"
                      onClick={toggleModalCreateBank}
                      title="Cadastrar Conta Bancária"
                      style={{ right: 0 }}
                    >
                      <FaPlus />
                    </ButtonAdd>
                  </Col>
                </Row>
              </Col>
              <Col md="4">
                <Row>
                  <Col md="10">
                    <Select
                      label="Forma de pagamento"
                      name="cash_flow_payment_method_id"
                      placeholder="Selecione o pgmt"
                      options={paymentMethods}
                    />
                  </Col>
                  <Col md="1">
                    <ButtonAdd
                      type="button"
                      onClick={toggleModalCreateMethod}
                      title="Cadastrar forma de pagamento"
                      style={{ right: 0 }}
                    >
                      <FaPlus />
                    </ButtonAdd>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Input label="Descrição" name="description" />

            <Row>
              <Col md="6">
                <Row>
                  <Col md="10">
                    <Select
                      label="Centro de custo"
                      name="cash_flow_category_subcategory_id"
                      placeholder="Centro de custo"
                      options={categories?.filter((cat) => !cat.is_deleted)}
                      onChange={(e) => {
                        setCategorySelected(e);
                      }}
                      isDisabled={isEarningOption}
                    />
                  </Col>
                  <Col md="2">
                    <ButtonAdd
                      type="button"
                      onClick={toggleModalCreateCategory}
                      title="Cadastrar centro de custo"
                    >
                      <FaPlus />
                    </ButtonAdd>
                  </Col>
                </Row>
              </Col>
              <Col md="6">
                <Row>
                  <Col md="10">
                    <Select
                      label="Fornecedor"
                      name="provider_id"
                      placeholder="Informe o fornecedor"
                      options={suppliers}
                    />
                  </Col>
                  <Col md="2">
                    <ButtonAdd
                      type="button"
                      onClick={toggleModalCreateSuplliers}
                      title="Cadastrar fornecedor"
                    >
                      <FaPlus />
                    </ButtonAdd>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Input label="Linha digitável" name="information" />
            <Row>
              <Col md="2">
                <CheckboxInput
                  name="paid"
                  label="Pago"
                  onChange={handleSetPaidCheck}
                />
              </Col>
              <Col md="3">
                <CheckboxInput
                  name="repit"
                  label="Recorrente"
                  onChange={handleSetRepitCheck}
                />
              </Col>
            </Row>

            {paidCheck && (
              <Row>
                <Col lg="3">
                  <DataPickerArea>
                    <>
                      <span>
                        Pago em <MdDateRange />
                      </span>

                      <DatePickerStyled
                        selected={paidAtDate}
                        onChange={(date) => setpaidAtDate(date)}
                        locale="pt"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="__/__/____"
                        customInput={
                          <ReactInputMask mask={'99/99/9999'} maskChar={null} />
                        }
                      />
                    </>
                  </DataPickerArea>
                </Col>
              </Row>
            )}

            {repitCheck && (
              <Row>
                <Col>
                  <Input
                    label="Qtd repetições"
                    name="freq_value"
                    type="number"
                  />
                </Col>
                <Col>
                  <Select
                    label="Frequência"
                    name="freq_type"
                    options={freqOptions}
                  />
                </Col>
              </Row>
            )}

            <ButtonsArea>
              <PersonalButton
                type="submit"
                message="Salvar"
                title="Salvar"
                color="#2EC9B7"
              />
            </ButtonsArea>
          </Form>
        </InnerArea>
      </ReactModal>
    </Container>
  );
}

export default Transactions;