import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import api from '~/services/api';
import { Container } from './styles';

import ProductCategory from './Categories';

import { useAuth } from '~/context/AuthContext';

import PermissionErrorContainer from '~/components/PermissionErrorContainer';

function IfoodRelation() {
  const { user } = useAuth();

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'MenuProducts'}`
      );

      const { can_read } = response.data.MenuProducts;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <ProductCategory user={user} />
    </Container>
  );
}

export default IfoodRelation;
