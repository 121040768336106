import styled from 'styled-components';

export const OrderInformation = styled.div`
  border: 1px solid #f6f6f6;
  border-radius: 8px;
  padding: 8px;
`;

export const InfoArea = styled.div`
   &:not(:last-child) {
    border-bottom: 1px solid #F6F6F6;
  }
`

export const InfoGrid = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 8px 0%;
`;

export const InfoGridRow = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const InfoGridRowTitle = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #545454;
  flex-grow: 1;
`;

export const InfoGridPrice = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: right;
  color: #43af50;
`;

export const InfoGridWeight = styled.span`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.5px;
  text-align: right;
  color: #545454;
`;

export const OrderInformationTitle = styled.span`
  display: block;
  color: #545454;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  border-bottom: 1px solid #ededed;
  padding-bottom: 4px;
  width: 100%;
`;

export const EmptyList = styled.span`
  color: #545454;
  padding: 8px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  display: block;
  width: 100%;
  text-align: center;
`;

export const NameSpan = styled.span`
  flex-grow: 1;
  color: #545454;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: left;
`;

export const AmountSpan = styled.span`
  width: 15px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  color: #545454;
`;


export const IconsSpan = styled.div`
  display: flex;
  flex-direction:row;
  align-items: center;
  gap: 4px;
  //padding: 8px 0;

  svg {
    cursor: pointer;
    color: #c8131b;
    width: 16px;
    height: 16px;
  }

  span {
  width: 15px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  color: #545454;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #C8131B;
  }
`