import React, { useCallback, useState, useEffect, useRef } from 'react';

import { Modal, ModalFooter } from 'reactstrap';
import {
  FiMinusCircle,
  FiMessageSquare,
  FiAlertCircle,
  FiSearch,
  FiX,
} from 'react-icons/fi';
import { v4 } from 'uuid';
import { FaPlusCircle } from 'react-icons/fa';
import { useCart } from '~/context/OrderingSystem/Cart';
import formatValue from '~/services/formatValue';
import { useAuth } from '~/context/AuthContext';
import { usePreCart } from '~/context/PreCartContext';
import axios from 'axios';
import {
  Container,
  Content,
  ImageProduct,
  DescriptionProduct,
  AddItemsProduct,
  EditAction,
  EditActionButtons,
  ComplementsHeaderProduct,
  ComplementItem,
  ComplementItemDescription,
  ComplementItemAmount,
  InformationText,
  ConfirmButton,
  ModalTitle,
  Footer,
  QuestionSubtitle,
  SearchBar,
  ButtonDeleteInput,
  ButtonSearchInput,
  InputProductAmount,
  InputProductWeight,
  InputComplementAmount,
  ComplementsArea,
  ComplementItemArea,
  MandatoryItem,
  ComplementQuestion,
  ComplementsHeaderInfo,
  ComplementName,
} from './styles';
import { startingPrice } from '~/utils/functions';
import toast from 'react-hot-toast';

const Product = ({ product, closeProductModal, isTableDelivery, setAdded }) => {
  const { addToCart, countProducts } = useCart();

  const [observation, setObservation] = useState('');
  const [amount, setAmount] = useState(1);
  const [modalPhrase, setModalPhrase] = useState('');
  const [modalAction] = useState([]);
  const [complementsCategory, setComplementsCategory] = useState([]);
  const [mandatoryComplements, setMandatoryComplements] = useState([]);
  const [check, setCheck] = useState([]);
  const [count, setCount] = useState('');
  const [isProductWeight] = useState(product?.use_weight);
  const [inputProductWeight, setInputProductWeight] = useState(0);
  const [inputProductPrice, setInputProductPrice] = useState(null);
  const [inputProductWeightCalculated, setInputProductPriceCalculated] =
    useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [productTotalPrice, setProductTotalPrice] = useState(0);
  const [addCart, setAddCart] = useState(false);
  const { preCart, setPreCart, changeProductPreCart } = usePreCart();

  const { user } = useAuth();

  function toggleModal() {
    setIsOpen(!modalIsOpen);
  }

  function closeModal() {
    setIsOpen(false);

    if (modalAction.length > 0) {
      modalAction[0]();
    }
  }

  function openModal(phrase) {
    setIsOpen(true);
    setModalPhrase(phrase);
  }

  function handleAddObservation(e) {
    setObservation(e.target.value);
  }

  function handleChangeProductAmount(e) {
    setAmount(Number(e.target.value));
  }

  function increment() {
    setAmount(amount + 1);
  }

  function decrement() {
    setAmount(amount - 1);
  }

  const [stateFocus, setStateFocus] = useState([]);

  function handleChangeComplementAmount(
    amount,
    id,
    name,
    price,
    delivery_price,
    limit,
    categoryLimit,
    categoryId,
    categoryAdditional,
    categoryMoreExpensiveOnly,
    minimum,
    useAverage,
    is_cmv_manual,
    current_cmv
  ) {
    // adicionar os complementos das categorias obrigatorias no state
    const aleatoryId = v4();

    setCount(aleatoryId);

    setStateFocus([id]);

    const categoryFind = mandatoryComplements.findIndex(
      (category) => category.id === categoryId
    );
    if (categoryFind >= 0) {
      const complementFind = mandatoryComplements[
        categoryFind
      ].complements.findIndex((item) => item.id === id);

      if (complementFind >= 0) {
        const categoryTotalAmount = complementsCategory[
          categoryFind
        ]?.complements.reduce((acum, curr) => acum + curr.amount, 0);
        if (
          Number(amount) <= Number(categoryLimit) &&
          Number(amount) <= Number(limit)
        ) {
          if (categoryTotalAmount > 0) {
            if (
              Number(amount) <= Number(categoryLimit) &&
              Number(amount) + Number(categoryTotalAmount) <=
              Number(categoryLimit)
            ) {
              mandatoryComplements[categoryFind].complements[
                complementFind
              ].checkAmount = amount;
            }
          } else {
            mandatoryComplements[categoryFind].complements[
              complementFind
            ].checkAmount = amount;
          }
        }
      }
    }

    // Adicionando aos complementos
    const newCategoryExist = [...complementsCategory];
    const categoryExist = newCategoryExist.findIndex(
      (category) => category.id === categoryId
    );

    if (categoryExist >= 0) {
      const categoryTotalAmount = complementsCategory[
        categoryExist
      ].complements.reduce((acum, curr) => acum + curr.amount, 0);

      const complementExist = newCategoryExist[
        categoryExist
      ].complements.findIndex((complement) => complement.id === id);
      if (
        complementExist >= 0 &&
        newCategoryExist[categoryExist].complements.length === 1
      ) {
        if (
          Number(amount) >= 0 &&
          Number(amount) <= Number(categoryLimit)
          // Number(amount) + Number(categoryTotalAmount) <= Number(categoryLimit)
        ) {
          if (Number(amount) <= Number(limit)) {
            newCategoryExist[categoryExist].complements[
              complementExist
            ].amount = amount;
            newCategoryExist[categoryExist].minimum = minimum;
          } else {
            openModal(
              'Quantidade digitade é maior que o limite do complemento'
            );
          }
        } else {
          openModal(
            'Essa quantidade digitada é maior que o limite máximo permitido para essa categoria.'
          );
        }
      } else if (
        complementExist >= 0 &&
        newCategoryExist[categoryExist].complements.length > 1
      ) {
        if (
          Number(amount) >= 0 &&
          Number(amount) <= Number(categoryLimit) &&
          Number(categoryTotalAmount) <= Number(categoryLimit)
        ) {
          if (Number(amount) <= Number(limit)) {
            newCategoryExist[categoryExist].complements[
              complementExist
            ].amount = amount;
            newCategoryExist[categoryExist].minimum = minimum;
          } else {
            openModal(
              'Quantidade digitade é maior que o limite do complemento'
            );
          }
        } else {
          openModal(
            'Essa quantidade digitada é maior que o limite máximo permitido para essa categoria.'
          );
        }
      } else if (amount < 0) {
        return 0;
      } else if (
        Number(amount) <= Number(categoryLimit) &&
        Number(amount) <= Number(limit)
        // Number(amount) + Number(categoryTotalAmount) <= Number(categoryLimit)
      ) {
        newCategoryExist[categoryExist].complements.push({
          id,
          name,
          price,
          delivery_price,
          amount,
          is_cmv_manual,
          current_cmv,
        });

        newCategoryExist[categoryExist].minimum = minimum;
      } else if (
        Number(amount) + Number(categoryTotalAmount) <=
        Number(categoryLimit)
      ) {
        openModal('Quantidade digitade é maior que o limite da categoria');
      } else {
        openModal(
          'Essa quantidade digitada é maior que o limite máximo permitido para esse complemento.'
        );
      }

      setComplementsCategory(newCategoryExist);
    } else if (amount < 0) {
      return 0;
    } else if (amount <= categoryLimit && amount <= limit) {
      setComplementsCategory([
        ...complementsCategory,
        {
          id: categoryId,
          additional: categoryAdditional,
          complements: [
            {
              id,
              name,
              price,
              delivery_price,
              amount,
              is_cmv_manual,
              current_cmv,
            },
          ],
          more_expensive_only: categoryMoreExpensiveOnly,
          minimum,
          use_average: useAverage,
        },
      ]);
    } else if (Number(amount) > Number(categoryLimit)) {
      openModal('Quantidade digitade é maior que o limite da categoria');
    } else {
      openModal(
        'Essa quantidade digitada é maior que o limite máximo permitido para esse complemento.'
      );
    }
  }

  function handleAddComplements(
    id,
    name,
    price,
    delivery_price,
    limit,
    categoryLimit,
    categoryId,
    categoryAdditional,
    categoryMoreExpensiveOnly,
    minimum,
    useAverage,
    is_cmv_manual,
    current_cmv
  ) {
    // Adicionando aos complementos
    const newCategoryExist = [...complementsCategory];
    const categoryExist = newCategoryExist.findIndex(
      (category) => category.id === categoryId
    );

    if (categoryExist >= 0) {
      const categoryTotalAmount = complementsCategory[
        categoryExist
      ].complements.reduce((acum, curr) => acum + curr.amount, 0);

      const complementExist = newCategoryExist[
        categoryExist
      ].complements.findIndex((complement) => complement.id === id);
      if (complementExist >= 0) {
        if (categoryTotalAmount < categoryLimit) {
          if (
            newCategoryExist[categoryExist].complements[complementExist]
              .amount < limit
          ) {
            newCategoryExist[categoryExist].complements[
              complementExist
            ].amount += 1;
            newCategoryExist[categoryExist].minimum = minimum;
          } else {
            openModal(
              'Você só pode adicionar outro complemento ao seu lanche, pois o limite deste complemento foi alcançado.'
            );
            return;
          }
        } else {
          openModal('Limite de catgoria');
        }
      } else {
        newCategoryExist[categoryExist].complements.push({
          id,
          name,
          price,
          delivery_price,
          amount: 1,
          is_cmv_manual,
          current_cmv,
        });

        newCategoryExist[categoryExist].minimum = minimum;
      }

      console.log('new category > ', newCategoryExist);

      setComplementsCategory(newCategoryExist);
    } else {
      setComplementsCategory([
        ...complementsCategory,
        {
          id: categoryId,
          additional: categoryAdditional,
          complements: [
            {
              id,
              name,
              price,
              delivery_price,
              amount: 1,
              is_cmv_manual,
              current_cmv,
            },
          ],
          more_expensive_only: categoryMoreExpensiveOnly,
          minimum,
          use_average: useAverage,
        },
      ]);
    }
    // adicionar os complementos das categorias obrigatorias no state
    const aleatoryId = v4();

    setCount(aleatoryId);

    const categoryFind = mandatoryComplements.findIndex(
      (category) => category.id === categoryId
    );
    if (categoryFind >= 0) {
      const complementFind = mandatoryComplements[
        categoryFind
      ].complements.findIndex((item) => item.id === id);

      if (complementFind >= 0) {
        mandatoryComplements[categoryFind].complements[
          complementFind
        ].checkAmount += 1;
      }
    }
  }

  function handleRemoveComplements(categoryId, complementId) {
    // remover os complementos das categorias obrigatorias no state
    const aleatoryId = v4();

    setCount(aleatoryId);
    const categoryFind = mandatoryComplements.findIndex(
      (category) => category.id === categoryId
    );
    if (categoryFind >= 0) {
      const complementFind = mandatoryComplements[
        categoryFind
      ].complements.findIndex((item) => item.id === complementId);

      if (complementFind >= 0) {
        if (
          mandatoryComplements[categoryFind].complements[complementFind]
            .checkAmount > 0
        ) {
          mandatoryComplements[categoryFind].complements[
            complementFind
          ].checkAmount -= 1;
        }
      }
    }

    // Remover complemento das categorias
    const newCategoryExist = [...complementsCategory];
    const categoryExistId = newCategoryExist.findIndex(
      (category) => category.id === categoryId
    );

    if (categoryExistId >= 0) {
      const complementExist = newCategoryExist[
        categoryExistId
      ].complements.findIndex((complement) => complement.id === complementId);

      if (
        newCategoryExist[categoryExistId].complements[complementExist].amount >
        0
      ) {
        newCategoryExist[categoryExistId].complements[
          complementExist
        ].amount -= 1;

        setComplementsCategory(newCategoryExist);
      }
    }
  }

  let complementMoreExpensive = 0;
  complementsCategory
    .filter((category) => category.more_expensive_only === true)
    .forEach((item) => {
      if (isTableDelivery) {
        let local_price = 0;
        item.complements.forEach((complement) => {
          if (
            local_price <
            parseFloat(
              complement.delivery_price
                ? complement.delivery_price
                : complement.price
            ) *
            (parseInt(complement.amount) >= 1 ? 1 : 0)
          ) {
            local_price =
              parseFloat(
                complement.delivery_price
                  ? complement.delivery_price
                  : complement.price
              ) * (parseInt(complement.amount) >= 1 ? 1 : 0);
          }
        });
        complementMoreExpensive += local_price;
      } else {
        let local_price = 0;
        item.complements.forEach((complement) => {
          if (
            local_price <
            parseFloat(complement.price) *
            (parseInt(complement.amount) >= 1 ? 1 : 0)
          ) {
            local_price =
              parseFloat(complement.price) *
              (parseInt(complement.amount) >= 1 ? 1 : 0);
          }
        });
        complementMoreExpensive += local_price;
      }
    });

  const comboStartingPrice = product?.is_combo
    ? startingPrice(product?.complement_categories, isTableDelivery)
    : 0;

  const totalComplementsPrice = isTableDelivery
    ? complementsCategory
      .filter(
        (category) =>
          category.additional === true &&
          category.more_expensive_only === false
      )
      .map((complementCat) => {
        if (complementCat.use_average) {
          const amountAverage = complementCat.complements.reduce(
            (accum, curr) => accum + curr.amount,
            0
          );

          const totalPriceAverage =
            complementCat.complements
              .map((item) => item)
              .reduce(
                (acum, curr) =>
                  acum + (curr.delivery_price || curr.price) * curr.amount,
                0
              ) / amountAverage;

          return totalPriceAverage > 0 ? Number(totalPriceAverage) : 0;
        }
        return complementCat.complements
          .map((item) => item)
          .reduce(
            (acum, curr) =>
              acum + (curr.delivery_price || curr.price) * curr.amount,
            0
          );
      })
      .reduce((acum, curr) => acum + curr, 0) + complementMoreExpensive
    : complementsCategory
      .filter(
        (category) =>
          category.additional === true &&
          category.more_expensive_only === false
      )

      .map((complementCat) => {
        if (complementCat.use_average) {
          const amountAverage = complementCat.complements.reduce(
            (accum, curr) => accum + curr.amount,
            0
          );

          const totalPriceAverage =
            complementCat.complements
              .map((item) => item)
              .reduce((acum, curr) => acum + curr.price * curr.amount, 0) /
            amountAverage;
          return totalPriceAverage > 0 ? Number(totalPriceAverage) : 0;
        }
        return complementCat.complements
          .map((item) => item)
          .reduce((acum, curr) => acum + curr.price * curr.amount, 0);
      })
      .reduce((acum, curr) => acum + curr, 0) + complementMoreExpensive;

  function totalCategoryComplementsAmount(categoryId) {
    const total = complementsCategory
      .filter((category) => category.id === categoryId)
      .map((item) =>
        item.complements.reduce((acum, curr) => acum + curr.amount, 0)
      );

    return total;
  }

  // useState(() => {
  //   const categoryCompl = mandatoryComplements.map((item) => item);
  //   const category = categoryCompl[0].complements.reduce(
  //     (acum, curr) => acum + curr.checkAmount,
  //     0
  //   );

  //   setCheck([...check, category]);

  //   console.log(categoryCompl);
  // }, []);

  useEffect(() => {
    if (isTableDelivery) {
      const mandatory = product?.complement_categories
        ?.filter((item) => item.available_in_delivery)
        .filter((item) => item.optional === false)
        .map((cc) => {
          return {
            ...cc,
            complements: cc.complements.map((complement) => {
              return {
                ...complement,
                checkAmount: 0,
              };
            }),
          };
        });
      setMandatoryComplements(mandatory);
    } else {
      const mandatory = product?.complement_categories
        ?.filter((item) => item.available)
        .filter((item) => item.optional === false)
        .map((cc) => {
          return {
            ...cc,
            complements: cc.complements.map((complement) => {
              return {
                ...complement,
                checkAmount: 0,
              };
            }),
          };
        });
      setMandatoryComplements(mandatory);
    }
  }, [product.complement_categories, product]);

  useEffect(() => {
    const category = mandatoryComplements.map((item) =>
      item.complements.reduce((acum, curr) => acum + curr.checkAmount, 0)
    );

    setCheck(category);
  }, [mandatoryComplements, count]);

  // const check = [];

  // for (const cat of mandatoryComplements) {
  //   const category = cat.complements.reduce(
  //     (acum, curr) => acum + curr.checkAmount,
  //     0
  //   );

  //   check.push(category);
  // }

  function deleteCheckAmount() {
    mandatoryComplements.map((item) =>
      item.complements.map((compl) => (compl.checkAmount = 0))
    );
  }

  function addToCarts() {
    if (amount === 0) {
      toast.error("Adicionar quantidade")
      return
    }
    const combo_price =
      Number(totalComplementsPrice) +
      Number(product.price) +
      comboStartingPrice;

    if (isProductWeight) {
      if (inputProductWeight) {
        const inputWeightComboPrice =
          amount *
          (Number(totalComplementsPrice) +
            Number(product.price * inputProductWeight) +
            comboStartingPrice);

        if (mandatoryComplements.length > 0) {
          const checkedMandatoryComplements = check.reduce(
            (acum, curr) => acum && curr > 0
          );

          const hasMinimum = complementsCategory.filter(
            (item) => item.minimum > 0
          );

          const minimumCheck = complementsCategory.map((category) => {
            const complementsAmountSum = category.complements.reduce(
              (acum, curr) => curr.amount + acum,
              0
            );

            return category.minimum > 1
              ? category.minimum <= complementsAmountSum
              : true;
          });

          if (hasMinimum) {
            if (minimumCheck.every((item) => item === true)) {
              if (checkedMandatoryComplements) {
                countProducts({ id: product.id, amount: amount || 1 });
                if (setAdded) {
                  setAdded((state) => {
                    const exists = state.find(
                      (s) => s.product.id === product.id
                    );
                    if (exists) {
                      return state.map((s) => {
                        if (s.product.id === product.id) {
                          return { ...s, amount: s.amount + 1 };
                        }
                        return s;
                      });
                    }
                    return [
                      ...state,
                      {
                        product,
                        amount,
                        observation,
                        complementsCategory,
                        isProductWeight,
                        inputProductWeight,
                      },
                    ];
                  });
                }

                addToCart({
                  product,
                  amount,
                  observation,
                  complementsCategory,
                  isProductWeight,
                  inputProductWeight,
                  combo_price: inputWeightComboPrice,
                });

                // history.push(`/menu/${restaurantId}`);
                closeProductModal();
                deleteCheckAmount();
              } else {
                openModal('Favor adicionar todos os complementos obrigatórios');
              }
            } else {
              openModal('Favor adicionar a quantidade mínima de complementos');
            }
          } else if (checkedMandatoryComplements) {
            countProducts({ id: product.id, amount: amount || 1 });
            if (setAdded) {
              setAdded((state) => {
                const exists = state.find((s) => s.product.id === product.id);
                if (exists) {
                  return state.map((s) => {
                    if (s.product.id === product.id) {
                      return { ...s, amount: s.amount + 1 };
                    }
                    return s;
                  });
                }
                return [
                  ...state,
                  {
                    product,
                    amount,
                    observation,
                    complementsCategory,
                    isProductWeight,
                    inputProductWeight,
                  },
                ];
              });
            }
            addToCart({
              product,
              amount,
              observation,
              complementsCategory,
              isProductWeight,
              inputProductWeight,
              combo_price: inputWeightComboPrice,
            });

            // history.push(`/menu/${restaurantId}`);
            closeProductModal();
            deleteCheckAmount();
          } else {
            openModal('Favor adicionar todos os complementos obrigatórios');
          }
        } else {
          const hasMinimum = complementsCategory.filter(
            (item) => item.minimum > 1
          );

          const minimumCheck = complementsCategory.map((category) => {
            const complementsAmountSum = category.complements.reduce(
              (acum, curr) => curr.amount + acum,
              0
            );

            return category.minimum > 1
              ? category.minimum <= complementsAmountSum
              : true;
          });

          if (hasMinimum) {
            if (minimumCheck.every((item) => item === true)) {
              countProducts({ id: product.id, amount: amount || 1 });
              if (setAdded) {
                setAdded((state) => {
                  const exists = state.find((s) => s.product.id === product.id);
                  if (exists) {
                    return state.map((s) => {
                      if (s.product.id === product.id) {
                        return { ...s, amount: s.amount + 1 };
                      }
                      return s;
                    });
                  }
                  return [
                    ...state,
                    {
                      product,
                      amount,
                      observation,
                      complementsCategory,
                      isProductWeight,
                      inputProductWeight,
                    },
                  ];
                });
              }
              addToCart({
                product,
                amount,
                observation,
                complementsCategory,
                isProductWeight,
                inputProductWeight,
                combo_price: inputWeightComboPrice,
              });

              // history.push(`/menu/${restaurantId}`);
              closeProductModal();
              deleteCheckAmount();
            } else {
              openModal('Favor adicionar a quantidade mínima de complementos');
            }
          } else {
            countProducts({ id: product.id, amount: amount || 1 });
            if (setAdded) {
              setAdded((state) => {
                const exists = state.find((s) => s.product.id === product.id);
                if (exists) {
                  return state.map((s) => {
                    if (s.product.id === product.id) {
                      return { ...s, amount: s.amount + 1 };
                    }
                    return s;
                  });
                }
                return [
                  ...state,
                  {
                    product,
                    amount,
                    observation,
                    complementsCategory,
                    isProductWeight,
                    inputProductWeight,
                  },
                ];
              });
            }
            addToCart({
              product,
              amount,
              observation,
              complementsCategory,
              isProductWeight,
              inputProductWeight,
              combo_price: inputWeightComboPrice,
            });

            // history.push(`/menu/${restaurantId}`);
            closeProductModal();
            deleteCheckAmount();
          }
        }
      } else if (inputProductPrice) {
        const inputPriceComboPrice =
          Number(totalComplementsPrice) +
          Number(inputProductPrice) +
          comboStartingPrice;

        if (mandatoryComplements.length > 0) {
          const checkedMandatoryComplements = check.reduce(
            (acum, curr) => acum && curr > 0
          );

          const hasMinimum = complementsCategory.filter(
            (item) => item.minimum > 0
          );

          const minimumCheck = complementsCategory.map((category) => {
            const complementsAmountSum = category.complements.reduce(
              (acum, curr) => curr.amount + acum,
              0
            );

            return category.minimum > 1
              ? category.minimum <= complementsAmountSum
              : true;
          });

          if (hasMinimum) {
            if (minimumCheck.every((item) => item === true)) {
              if (checkedMandatoryComplements) {
                countProducts({ id: product.id, amount: amount || 1 });
                addToCart({
                  product,
                  amount,
                  observation,
                  complementsCategory,
                  isProductWeight,
                  inputProductPrice,
                  inputProductWeight: inputProductWeightCalculated,
                  combo_price: inputPriceComboPrice,
                });

                // history.push(`/menu/${restaurantId}`);
                closeProductModal();
                deleteCheckAmount();
              } else {
                openModal('Favor adicionar todos os complementos obrigatórios');
              }
            } else {
              openModal('Favor adicionar a quantidade mínima de complementos');
            }
          } else if (checkedMandatoryComplements) {
            countProducts({ id: product.id, amount: amount || 1 });
            addToCart({
              product,
              amount,
              observation,
              complementsCategory,
              isProductWeight,
              inputProductPrice,
              inputProductWeight: inputProductWeightCalculated,
              combo_price: inputPriceComboPrice,
            });

            // history.push(`/menu/${restaurantId}`);
            closeProductModal();
            deleteCheckAmount();
          } else {
            openModal('Favor adicionar todos os complementos obrigatórios');
          }
        } else {
          const hasMinimum = complementsCategory.filter(
            (item) => item.minimum > 1
          );

          const minimumCheck = complementsCategory.map((category) => {
            const complementsAmountSum = category.complements.reduce(
              (acum, curr) => curr.amount + acum,
              0
            );

            return category.minimum > 1
              ? category.minimum <= complementsAmountSum
              : true;
          });

          if (hasMinimum) {
            if (minimumCheck.every((item) => item === true)) {
              countProducts({ id: product.id, amount: amount || 1 });
              addToCart({
                product,
                amount,
                observation,
                complementsCategory,
                isProductWeight,
                inputProductPrice,
                inputProductWeight: inputProductWeightCalculated,
                combo_price: inputPriceComboPrice,
              });

              // history.push(`/menu/${restaurantId}`);
              closeProductModal();
              deleteCheckAmount();
            } else {
              openModal('Favor adicionar a quantidade mínima de complementos');
            }
          } else {
            countProducts({ id: product.id, amount: amount || 1 });
            addToCart({
              product,
              amount,
              observation,
              complementsCategory,
              isProductWeight,
              inputProductPrice,
              inputProductWeight: inputProductWeightCalculated,
              combo_price: inputPriceComboPrice,
            });

            // history.push(`/menu/${restaurantId}`);
            closeProductModal();
            deleteCheckAmount();
          }
        }
      } else {
        toast.error('Favor informar o peso ou valor do produto.');
      }
    } else if (mandatoryComplements.length > 0) {
      const checkedMandatoryComplements = check.reduce(
        (acum, curr) => acum && curr > 0
      );

      const hasMinimum = complementsCategory.filter((item) => item.minimum > 0);

      const minimumCheck = complementsCategory.map((category) => {
        const complementsAmountSum = category.complements.reduce(
          (acum, curr) => curr.amount + acum,
          0
        );

        return category.minimum > 1
          ? category.minimum <= complementsAmountSum
          : true;
      });

      if (hasMinimum) {
        if (minimumCheck.every((item) => item === true)) {
          if (checkedMandatoryComplements) {
            countProducts({ id: product.id, amount: amount || 1 });
            if (setAdded) {
              setAdded((state) => {
                const exists = state.find((s) => s.product.id === product.id);
                if (exists) {
                  return state.map((s) => {
                    if (s.product.id === product.id) {
                      return { ...s, amount: s.amount + 1 };
                    }
                    return s;
                  });
                }
                return [
                  ...state,
                  {
                    product,
                    amount,
                    observation,
                    complementsCategory,
                    isProductWeight,
                    inputProductWeight,
                  },
                ];
              });
            }
            addToCart({
              product,
              amount,
              observation,
              complementsCategory,
              combo_price,
            });

            // history.push(`/menu/${restaurantId}`);
            closeProductModal();
            deleteCheckAmount();
          } else {
            openModal('Favor adicionar todos os complementos obrigatórios');
          }
        } else {
          openModal('Favor adicionar a quantidade mínima de complementos');
        }
      } else if (checkedMandatoryComplements) {
        countProducts({ id: product.id, amount: amount || 1 });
        if (setAdded) {
          setAdded((state) => {
            const exists = state.find((s) => s.product.id === product.id);
            if (exists) {
              return state.map((s) => {
                if (s.product.id === product.id) {
                  return { ...s, amount: s.amount + 1 };
                }
                return s;
              });
            }
            return [
              ...state,
              {
                product,
                amount,
                observation,
                complementsCategory,
                isProductWeight,
                inputProductWeight,
              },
            ];
          });
        }
        addToCart({
          product,
          amount,
          observation,
          complementsCategory,
          combo_price,
        });
        deleteCheckAmount();
      } else {
        openModal('Favor adicionar todos os complementos obrigatórios');
      }
    } else {
      const hasMinimum = complementsCategory.filter((item) => item.minimum > 1);

      const minimumCheck = complementsCategory.map((category) => {
        const complementsAmountSum = category.complements.reduce(
          (acum, curr) => curr.amount + acum,
          0
        );

        return category.minimum > 1
          ? category.minimum <= complementsAmountSum
          : true;
      });

      if (hasMinimum) {
        if (minimumCheck.every((item) => item === true)) {
          countProducts({ id: product.id, amount: amount || 1 });
          if (setAdded) {
            setAdded((state) => {
              const exists = state.find((s) => s.product.id === product.id);
              if (exists) {
                return state.map((s) => {
                  if (s.product.id === product.id) {
                    return { ...s, amount: s.amount + 1 };
                  }
                  return s;
                });
              }
              return [
                ...state,
                {
                  product,
                  amount,
                  observation,
                  complementsCategory,
                  isProductWeight,
                  inputProductWeight,
                },
              ];
            });
          }

          addToCart({
            product,
            amount,
            observation,
            complementsCategory,
            combo_price,
          });
          deleteCheckAmount();

          // history.push(`/menu/${restaurantId}`);
          closeProductModal();
        } else {
          openModal('Favor adicionar a quantidade mínima de complementos');
        }
      } else {
        countProducts({ id: product.id, amount: amount || 1 });
        if (setAdded) {
          setAdded((state) => {
            const exists = state.find((s) => s.product.id === product.id);
            if (exists) {
              return state.map((s) => {
                if (s.product.id === product.id) {
                  return { ...s, amount: s.amount + 1 };
                }
                return s;
              });
            }
            return [
              ...state,
              {
                product,
                amount,
                observation,
                complementsCategory,
                isProductWeight,
                inputProductWeight,
              },
            ];
          });
        }
        addToCart({
          product,
          amount,
          observation,
          complementsCategory,
          combo_price,
        });
        deleteCheckAmount();
        // history.push(`/menu/${restaurantId}`);
        closeProductModal();
      }
    }
  }

  function handleChangeProductWeight(e) {
    setInputProductWeight(e.target.value);
  }

  function handleChangeProductPrice(e) {
    setInputProductPrice(e.target.value);
    const value = e.target.value.replace(',', '.');
    let thisWeight = parseFloat(value) / parseFloat(product.price);
    thisWeight = thisWeight.toFixed(3);
    setInputProductPriceCalculated(isNaN(thisWeight) ? 0 : thisWeight);
  }

  const inputComplementRef = useRef(null);

  const [complementsFiltered, setComplementsFiltered] = useState();
  const [filter, setFilter] = useState([]);

  function adcFilter(key, name) {
    const newFilter = { id: key, nome: name };
    const filtroExistente = filter.find((filtro) => filtro.id === key);

    if (filtroExistente) {
      const novoFiltro = filter.map((filtro) =>
        filtro.id === key ? newFilter : filtro
      );
      setFilter(novoFiltro);
    } else {
      const novoFiltro = [...filter, newFilter];
      setFilter(novoFiltro);
    }
  }

  function deleteValue(complement) {
    const inputElement = document.getElementById(complement?.name);
    inputElement.value = '';
    adcFilter(complement.id, '');
  }

  //jeito antigo de filtrar
  // function handleSearchInputComplement(data, complement) {
  //   if (data.length >= 3) {
  //     const complementsFind = complement.complements.map((prod) => {
  //       if (
  //         prod.name
  //           .toLowerCase()
  //           .normalize('NFD')
  //           .replace(/[\u0300-\u036f]/g, '')
  //           .includes(data.toLowerCase())
  //       ) {
  //         return prod;
  //       }
  //       return '';
  //     });

  //     const arrayReady = complementsFind.filter((cat) => cat !== '');

  //     setComplementsFiltered({
  //       name: complement.name,
  //       complements: arrayReady,
  //     });
  //   }

  //   if (data.length === 0) {
  //     setComplementsFiltered({});
  //   }
  // }

  // function deleteValue(name) {
  //   const inputElement = document.getElementById(name);

  //   inputElement.value = '';
  // }

  // const verifyArray = useCallback(
  //   (complement, item) => {
  //     const trueArray = complementsCategory
  //       .filter((category) => category.id === complement.id)
  //       .map(
  //         (cat) =>
  //           cat.complements.filter((complement) => complement.id === item.id)
  //             .length < 1
  //       );

  //     return trueArray;
  //   },
  //   [complementsCategory]
  // );
  const getWeightBalance = useCallback(async () => {
    try {
      const response = await axios.post('http://127.0.0.1:9600/', {
        port_name: user.balance.port_name,
        baud_rate: user.balance.baud_rate,
        bit_rate: user.balance.bit_rate,
      });
      if (response?.data?.peso && parseFloat(response.data.peso) > 0) {
        setInputProductWeight(response?.data?.peso?.replace(',', '.'));
        setProductTotalPrice(
          (
            parseFloat(response?.data?.peso) * parseFloat(product?.price)
          ).toFixed(3)
        );
        changeProductPreCart(
          'inputProductWeight',
          response?.data?.peso?.replace(',', '.'),
          'inputProductPrice',
          productTotalPrice.toString()
        );
        if (product.complement_categories.length === 0) {
          setAddCart(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [user]);

  useEffect(() => {
    if (isProductWeight && user.balance) {
      const intervalId = setInterval(() => {
        getWeightBalance();
      }, 500);

      return () => clearInterval(intervalId);
    }
  }, [preCart.isProductWeight, getWeightBalance]);

  // useEffect(() => {
  //   try {
  //     if (addCart) {
  //       addToCarts();
  //     }
  //   } catch (erro) {
  //     console.log(erro);
  //   }
  // }, [preCart]);

  return (
    <Container>
      <Content>
        <Modal isOpen={modalIsOpen} toggle={toggleModal} onClosed={closeModal}>
          <ModalTitle>
            <p>{modalPhrase}</p>
          </ModalTitle>

          <ModalFooter>
            <ConfirmButton onClick={closeModal}>Ok, entendi</ConfirmButton>
          </ModalFooter>
        </Modal>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 25,
            paddingRight: 12,
            marginBottom: -12,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <DescriptionProduct>
              <h1>{product?.name}</h1>
              <p style={{ marginTop: -10 }}>{product?.description}</p>
            </DescriptionProduct>
            <EditAction>
              {isProductWeight ? (
                <span style={{ fontWeight: 'bold' }}>Peso do Produto (kg)</span>
              ) : (
                <span style={{ fontWeight: 'bold' }}>Quantidade</span>
              )}
              <EditActionButtons>
                {isProductWeight ? (
                  <InputProductWeight
                    type="number"
                    placeholder="ex: 0,800"
                    onChange={(e) => {
                      handleChangeProductWeight(e);
                      setProductTotalPrice(
                        e.target.value.replace(',', '.') *
                        parseFloat(product?.price).toFixed(3)
                      );
                    }}
                    value={inputProductWeight}
                    disabled={inputProductPrice && inputProductPrice !== ''}
                  />
                ) : (
                  <>
                    <button onClick={decrement} type="button">
                      {amount > 1 && !product?.is_unitary && (
                        <FiMinusCircle size={20} color="#FF281E" />
                      )}
                    </button>

                    {!product?.is_unitary ? (
                      <InputProductAmount
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        value={amount}
                        onChange={(e) => handleChangeProductAmount(e)}
                      />
                    ) : (
                      <span style={{ color: '#FF281E', fontSize: 20 }}>1</span>
                    )}

                    {!product?.is_unitary && (
                      <button onClick={increment} type="button">
                        <FaPlusCircle size={20} color="#FF281E" />
                      </button>
                    )}
                  </>
                )}
              </EditActionButtons>
            </EditAction>

            {isProductWeight && (
              <EditAction style={{ marginTop: 10 }}>
                Preço do Produto (R$)
                <EditActionButtons>
                  <InputProductWeight
                    type="number"
                    placeholder="R$"
                    onChange={(e) => {
                      handleChangeProductPrice(e);
                    }}
                    disabled={inputProductWeight && inputProductWeight !== ''}
                    value={inputProductPrice !== 0 ? inputProductPrice : ''}
                  />
                </EditActionButtons>
              </EditAction>
            )}
          </div>
          <div>
            {product?.image && (
              <ImageProduct>
                <img
                  src={product?.image?.url_thumb || product?.image?.url}
                  alt={product?.description}
                />
              </ImageProduct>
            )}
          </div>
        </div>

        <AddItemsProduct>
          <div>
            <InformationText>
              <FiMessageSquare color="#ff2c3a" />
              <input
                placeholder="Observação aqui. Ex: tirar item x ... "
                value={observation}
                onChange={(e) => {
                  handleAddObservation(e);
                }}
                maxLength="150"
                style={{ color: '#333333' }}
              />
            </InformationText>

            {isTableDelivery ? (
              <ComplementsArea
                complementLength={product?.complement_categories.length}
              >
                {product?.complement_categories
                  .filter((item) => item.available_in_delivery)
                  .map((complement, index) => (
                    <ComplementItemArea
                      key={complement.id}
                      complementLength={product?.complement_categories.length}
                      positionArray={
                        index % 2 !== 0 &&
                        product?.complement_categories.length % 2 !== 0
                      }
                    >
                      <ComplementsHeaderProduct>
                        <ComplementsHeaderInfo>
                          <ComplementQuestion>
                            {complement.question}
                          </ComplementQuestion>
                          {complement.limit > complement.minimum && (
                            <>
                              {complement.minimum > 1 ? (
                                <QuestionSubtitle>
                                  {`Escolha entre ${complement.minimum} e ${complement.limit
                                    }
                        ${complement.limit > 1 ? ' itens' : ' item'} no total.`}
                                  {complement.more_expensive_only && (
                                    <div
                                      style={{ marginTop: 10, color: 'red' }}
                                    >
                                      Apenas o valor do complemento mais caro
                                      será cobrado
                                    </div>
                                  )}
                                </QuestionSubtitle>
                              ) : (
                                <QuestionSubtitle>
                                  {`Escolha até ${complement.limit} ${complement.limit > 1 ? ' itens' : ' item'
                                    } no total.`}
                                  {complement.more_expensive_only && (
                                    <div
                                      style={{ marginTop: 20, color: 'red' }}
                                    >
                                      Apenas o valor do complemento mais caro
                                      será cobrado
                                    </div>
                                  )}
                                </QuestionSubtitle>
                              )}
                            </>
                          )}

                          {complement.limit < complement.minimum && (
                            <>
                              {complement.minimum > 1 ? (
                                <QuestionSubtitle>
                                  {`Escolha ${complement.minimum
                                    }                        ${complement.limit > 1 ? ' itens' : ' item'
                                    } no mínimo.`}
                                  {complement.more_expensive_only && (
                                    <div
                                      style={{ marginTop: 10, color: 'red' }}
                                    >
                                      Apenas o valor do complemento mais caro
                                      será cobrado
                                    </div>
                                  )}
                                </QuestionSubtitle>
                              ) : (
                                <QuestionSubtitle>
                                  {`Escolha até ${complement.limit} ${complement.limit > 1 ? ' itens' : ' item'
                                    } no total.`}
                                  {complement.more_expensive_only && (
                                    <div
                                      style={{ marginTop: 20, color: 'red' }}
                                    >
                                      Apenas o valor do complemento mais caro
                                      será cobrado
                                    </div>
                                  )}
                                </QuestionSubtitle>
                              )}
                            </>
                          )}

                          {complement.limit === complement.minimum && (
                            <>
                              {complement.minimum > 1 ? (
                                <QuestionSubtitle>
                                  {`Escolha ${complement.minimum} ${complement.limit > 1 ? ' itens' : ' item'
                                    } no total.`}
                                  {complement.more_expensive_only && (
                                    <div
                                      style={{ marginTop: 10, color: 'red' }}
                                    >
                                      Apenas o valor do complemento mais caro
                                      será cobrado
                                    </div>
                                  )}
                                </QuestionSubtitle>
                              ) : (
                                <QuestionSubtitle>
                                  {`Escolha até ${complement.limit} ${complement.limit > 1 ? ' itens' : ' item'
                                    } no total.`}
                                  {complement.more_expensive_only && (
                                    <div
                                      style={{ marginTop: 20, color: 'red' }}
                                    >
                                      Apenas o valor do complemento mais caro
                                      será cobrado
                                    </div>
                                  )}
                                </QuestionSubtitle>
                              )}
                            </>
                          )}

                          {complement.optional !== true && (
                            <MandatoryItem>Obrigatório</MandatoryItem>
                          )}
                        </ComplementsHeaderInfo>
                        {complement.complements.length > 10 && (
                          <SearchBar>
                            <input
                              name="product"
                              type="text"
                              placeholder="Buscar Complemento"
                              style={{ height: 30 }}
                              onChange={(e) => {
                                adcFilter(complement.id, e.target.value);
                              }}
                              ref={inputComplementRef}
                              id={complement?.name}
                            />

                            <ButtonSearchInput

                            // title="Mostrar todos"
                            // onClick={() => {
                            //   setComplementsFiltered('');
                            //   inputComplementRef.current.value = '';
                            // }}
                            >
                              <FiSearch size={14} />
                            </ButtonSearchInput>

                            <ButtonDeleteInput
                              type="button"
                              title="Mostrar todos"
                              onClick={() => {
                                setComplementsFiltered('');
                                deleteValue(complement);
                              }}
                            >
                              <FiX size={14} />
                            </ButtonDeleteInput>
                          </SearchBar>
                        )}
                      </ComplementsHeaderProduct>

                      <ComplementItem>
                        {complement.complements
                          .filter((comp) => comp.available_in_delivery)
                          .filter((item) => {
                            const filtroCorrespondente = filter.find(
                              (filtro) =>
                                filtro.id === complement.id &&
                                filtro.nome.length >= 3
                            );
                            const objetosFiltrados =
                              filtroCorrespondente &&
                              item.name
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                                .includes(
                                  filtroCorrespondente.nome.toLowerCase()
                                );
                            if (objetosFiltrados !== undefined)
                              return (
                                filtroCorrespondente &&
                                item.name
                                  .toLowerCase()
                                  .normalize('NFD')
                                  .replace(/[\u0300-\u036f]/g, '')
                                  .includes(
                                    filtroCorrespondente.nome.toLowerCase()
                                  )
                              );
                            else return item;
                          })
                          .map((item) => (
                            <li key={item.id}>
                              <ComplementItemDescription>
                                <ComplementName
                                  style={{
                                    fontWeight:
                                      item.limit > 0 ? 'normal' : 'bold',
                                  }}
                                >
                                  {item.name}
                                </ComplementName>
                                {Number(item.delivery_price || item.price) >
                                  0 && complement.additional === true ? (
                                  <span>
                                    {formatValue(
                                      item.delivery_price || item.price
                                    )}
                                  </span>
                                ) : null}

                                {/* {complement.limit > 1 && (
                                <ComplementItemDescriptionLimit>
                                  Até {item.limit}{' '}
                                  {item.limit > 1 ? 'itens' : 'item'}
                                </ComplementItemDescriptionLimit>
                              )} */}
                              </ComplementItemDescription>

                              <ComplementItemAmount>
                                {complementsCategory
                                  .filter(
                                    (category) => category.id === complement.id
                                  )
                                  .map((cat) =>
                                    cat.complements
                                      .filter(
                                        (complement) =>
                                          complement.id === item.id
                                      )
                                      .map((filtered) => {
                                        return (
                                          <React.Fragment key={complement.id}>
                                            <FiMinusCircle
                                              size={18}
                                              color="#ff2c3a"
                                              onClick={() => {
                                                handleRemoveComplements(
                                                  complement.id,
                                                  item.id
                                                );
                                              }}
                                            />

                                            <InputComplementAmount
                                              onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              autoFocus={stateFocus.includes(
                                                filtered.id
                                              )}
                                              value={filtered.amount}
                                              onChange={(e) =>
                                                handleChangeComplementAmount(
                                                  Number(e.target.value),
                                                  item.id,
                                                  item.name,
                                                  item.price,
                                                  item.delivery_price,
                                                  item.limit,
                                                  complement.limit,
                                                  complement.id,
                                                  complement.additional,
                                                  complement.more_expensive_only,
                                                  complement.minimum,
                                                  complement.use_average
                                                )
                                              }
                                            />
                                          </React.Fragment>
                                        );
                                      })
                                  )}

                                {complementsCategory &&
                                  complementsCategory.length > 0 &&
                                  complementsCategory
                                    .filter(
                                      (category) =>
                                        category.id === complement.id
                                    )
                                    .map(
                                      (cat) =>
                                        cat.complements.filter(
                                          (complement) =>
                                            complement.id === item.id
                                        ).length < 1 &&
                                        totalCategoryComplementsAmount(
                                          complement.id
                                        ) < complement.limit &&
                                        item.limit > 0 && (
                                          <InputComplementAmount
                                            onKeyPress={(event) => {
                                              if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                            onChange={(e) =>
                                              handleChangeComplementAmount(
                                                Number(e.target.value),
                                                item.id,
                                                item.name,
                                                item.price,
                                                item.delivery_price,
                                                item.limit,
                                                complement.limit,
                                                complement.id,
                                                complement.additional,
                                                complement.more_expensive_only,
                                                complement.minimum,
                                                complement.use_average
                                              )
                                            }
                                          />
                                        )
                                    )}

                                {complementsCategory &&
                                  complementsCategory.length > 0
                                  ? complementsCategory
                                    .filter(
                                      (category) =>
                                        category.id === complement.id
                                    )
                                    .map((cat) =>
                                      cat.complements.filter(
                                        (complement) =>
                                          complement.id === item.id
                                      )
                                    ).length < 1 &&
                                  totalCategoryComplementsAmount(
                                    complement.id
                                  ) < complement.limit &&
                                  item.limit > 0 && (
                                    <InputComplementAmount
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                      onChange={(e) =>
                                        handleChangeComplementAmount(
                                          Number(e.target.value),
                                          item.id,
                                          item.name,
                                          item.price,
                                          item.delivery_price,
                                          item.limit,
                                          complement.limit,
                                          complement.id,
                                          complement.additional,
                                          complement.more_expensive_only,
                                          complement.minimum,
                                          complement.use_average
                                        )
                                      }
                                    />
                                  )
                                  : totalCategoryComplementsAmount(
                                    complement.id
                                  ) < complement.limit &&
                                  item.limit > 0 && (
                                    <InputComplementAmount
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                      onChange={(e) =>
                                        handleChangeComplementAmount(
                                          Number(e.target.value),
                                          item.id,
                                          item.name,
                                          item.price,
                                          item.delivery_price,
                                          item.limit,
                                          complement.limit,
                                          complement.id,
                                          complement.additional,
                                          complement.more_expensive_only,
                                          complement.minimum,
                                          complement.use_average
                                        )
                                      }
                                    />
                                  )}

                                {totalCategoryComplementsAmount(complement.id) <
                                  complement.limit &&
                                  item.limit > 0 && (
                                    <FaPlusCircle
                                      size={18}
                                      color="#ff2c3a"
                                      onClick={() => {
                                        handleAddComplements(
                                          item.id,
                                          item.name,
                                          item.price,
                                          item.delivery_price,
                                          item.limit,
                                          complement.limit,
                                          complement.id,
                                          complement.additional,
                                          complement.more_expensive_only,
                                          complement.minimum,
                                          complement.use_average,
                                          item.is_cmv_manual,
                                          item.current_cmv
                                        );
                                      }}
                                    />
                                  )}
                              </ComplementItemAmount>
                            </li>
                          ))}
                      </ComplementItem>
                    </ComplementItemArea>
                  ))}
              </ComplementsArea>
            ) : (
              <ComplementsArea
                complementLength={product?.complement_categories.length}
              >
                {product?.complement_categories
                  .filter((item) => item.available)
                  .map((complement) => (
                    <ComplementItemArea
                      key={complement.id}
                      complementLength={product?.complement_categories.length}
                    >
                      <ComplementsHeaderProduct>
                        <ComplementsHeaderInfo>
                          <ComplementQuestion>
                            {complement.question}
                          </ComplementQuestion>
                          {complement.limit > complement.minimum && (
                            <>
                              {complement.minimum > 1 ? (
                                <QuestionSubtitle>
                                  {`Escolha entre ${complement.minimum} e ${complement.limit
                                    }
                        ${complement.limit > 1 ? ' itens' : ' item'} no total.`}
                                  {complement.more_expensive_only && (
                                    <div
                                      style={{ marginTop: 10, color: 'red' }}
                                    >
                                      Apenas o valor do complemento mais caro
                                      será cobrado
                                    </div>
                                  )}
                                </QuestionSubtitle>
                              ) : (
                                <QuestionSubtitle>
                                  {`Escolha até ${complement.limit} ${complement.limit > 1 ? ' itens' : ' item'
                                    } no total.`}
                                  {complement.more_expensive_only && (
                                    <div
                                      style={{ marginTop: 20, color: 'red' }}
                                    >
                                      Apenas o valor do complemento mais caro
                                      será cobrado
                                    </div>
                                  )}
                                </QuestionSubtitle>
                              )}
                            </>
                          )}

                          {complement.limit < complement.minimum && (
                            <>
                              {complement.minimum > 1 ? (
                                <QuestionSubtitle>
                                  {`Escolha ${complement.minimum
                                    }                        ${complement.limit > 1 ? ' itens' : ' item'
                                    } no mínimo.`}
                                  {complement.more_expensive_only && (
                                    <div
                                      style={{ marginTop: 10, color: 'red' }}
                                    >
                                      Apenas o valor do complemento mais caro
                                      será cobrado
                                    </div>
                                  )}
                                </QuestionSubtitle>
                              ) : (
                                <QuestionSubtitle>
                                  {`Escolha até ${complement.limit} ${complement.limit > 1 ? ' itens' : ' item'
                                    } no total.`}
                                  {complement.more_expensive_only && (
                                    <div
                                      style={{ marginTop: 20, color: 'red' }}
                                    >
                                      Apenas o valor do complemento mais caro
                                      será cobrado
                                    </div>
                                  )}
                                </QuestionSubtitle>
                              )}
                            </>
                          )}

                          {complement.limit === complement.minimum && (
                            <>
                              {complement.minimum > 1 ? (
                                <QuestionSubtitle>
                                  {`Escolha ${complement.minimum} ${complement.limit > 1 ? ' itens' : ' item'
                                    } no total.`}
                                  {complement.more_expensive_only && (
                                    <div
                                      style={{ marginTop: 10, color: 'red' }}
                                    >
                                      Apenas o valor do complemento mais caro
                                      será cobrado
                                    </div>
                                  )}
                                </QuestionSubtitle>
                              ) : (
                                <QuestionSubtitle>
                                  {`Escolha até ${complement.limit} ${complement.limit > 1 ? ' itens' : ' item'
                                    } no total.`}
                                  {complement.more_expensive_only && (
                                    <div
                                      style={{ marginTop: 20, color: 'red' }}
                                    >
                                      Apenas o valor do complemento mais caro
                                      será cobrado
                                    </div>
                                  )}
                                </QuestionSubtitle>
                              )}
                            </>
                          )}

                          {complement.optional !== true && (
                            <MandatoryItem>Obrigatório</MandatoryItem>
                          )}
                        </ComplementsHeaderInfo>

                        {complement.complements.length > 10 && (
                          <SearchBar>
                            <input
                              name="product"
                              type="text"
                              placeholder="Buscar Complemento"
                              style={{ height: 30 }}
                              onChange={(e) => {
                                adcFilter(complement.id, e.target.value);
                              }}
                              ref={inputComplementRef}
                              id={complement?.name}
                            />

                            <ButtonSearchInput

                            // title="Mostrar todos"
                            // onClick={() => {
                            //   setComplementsFiltered('');
                            //   inputComplementRef.current.value = '';
                            // }}
                            >
                              <FiSearch size={14} />
                            </ButtonSearchInput>

                            <ButtonDeleteInput
                              type="button"
                              title="Mostrar todos"
                              onClick={() => {
                                setComplementsFiltered('');
                                deleteValue(complement);
                              }}
                            >
                              <FiX size={14} />
                            </ButtonDeleteInput>
                          </SearchBar>
                        )}
                      </ComplementsHeaderProduct>

                      <ComplementItem>
                        {complement.complements
                          .filter((comp) => comp.available)
                          .filter((item) => {
                            const filtroCorrespondente = filter.find(
                              (filtro) =>
                                filtro.id === complement.id &&
                                filtro.nome.length >= 3
                            );
                            const objetosFiltrados =
                              filtroCorrespondente &&
                              item.name
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                                .includes(
                                  filtroCorrespondente.nome.toLowerCase()
                                );
                            if (objetosFiltrados !== undefined)
                              return (
                                filtroCorrespondente &&
                                item.name
                                  .toLowerCase()
                                  .normalize('NFD')
                                  .replace(/[\u0300-\u036f]/g, '')
                                  .includes(
                                    filtroCorrespondente.nome.toLowerCase()
                                  )
                              );
                            else return item;
                          })
                          .map((item) => (
                            <li key={item.id}>
                              <ComplementItemDescription>
                                <ComplementName
                                  style={{
                                    fontWeight:
                                      item.limit > 0 ? 'normal' : 'bold',
                                  }}
                                >
                                  {item.name}
                                </ComplementName>
                                {(Number(item.price) > 0) &
                                  (complement.additional === true) ? (
                                  <span>{formatValue(item.price)}</span>
                                ) : null}

                                {/* {complement.limit > 1 && (
                                <ComplementItemDescriptionLimit>
                                  Até {item.limit}{' '}
                                  {item.limit > 1 ? 'itens' : 'item'}
                                </ComplementItemDescriptionLimit>
                              )} */}
                              </ComplementItemDescription>

                              <ComplementItemAmount>
                                {complementsCategory
                                  .filter(
                                    (category) => category.id === complement.id
                                  )
                                  .map((cat) =>
                                    cat.complements
                                      .filter(
                                        (complement) =>
                                          complement.id === item.id
                                      )
                                      .map((filtered) => {
                                        return (
                                          <React.Fragment key={complement.id}>
                                            <FiMinusCircle
                                              size={18}
                                              color="#ff2c3a"
                                              onClick={() => {
                                                handleRemoveComplements(
                                                  complement.id,
                                                  item.id
                                                );
                                              }}
                                            />

                                            <InputComplementAmount
                                              onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              autoFocus={stateFocus.includes(
                                                filtered.id
                                              )}
                                              value={filtered.amount}
                                              onChange={(e) =>
                                                handleChangeComplementAmount(
                                                  Number(e.target.value),
                                                  item.id,
                                                  item.name,
                                                  item.price,
                                                  item.delivery_price,
                                                  item.limit,
                                                  complement.limit,
                                                  complement.id,
                                                  complement.additional,
                                                  complement.more_expensive_only,
                                                  complement.minimum,
                                                  complement.use_average
                                                )
                                              }
                                            />
                                          </React.Fragment>
                                        );
                                      })
                                  )}

                                {complementsCategory &&
                                  complementsCategory.length > 0 &&
                                  complementsCategory
                                    .filter(
                                      (category) =>
                                        category.id === complement.id
                                    )
                                    .map(
                                      (cat) =>
                                        cat.complements.filter(
                                          (complement) =>
                                            complement.id === item.id
                                        ).length < 1 &&
                                        totalCategoryComplementsAmount(
                                          complement.id
                                        ) < complement.limit &&
                                        item.limit > 0 && (
                                          <InputComplementAmount
                                            onKeyPress={(event) => {
                                              if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                            onChange={(e) =>
                                              handleChangeComplementAmount(
                                                Number(e.target.value),
                                                item.id,
                                                item.name,
                                                item.price,
                                                item.delivery_price,
                                                item.limit,
                                                complement.limit,
                                                complement.id,
                                                complement.additional,
                                                complement.more_expensive_only,
                                                complement.minimum,
                                                complement.use_average
                                              )
                                            }
                                          />
                                        )
                                    )}

                                {complementsCategory &&
                                  complementsCategory.length > 0
                                  ? complementsCategory
                                    .filter(
                                      (category) =>
                                        category.id === complement.id
                                    )
                                    .map((cat) =>
                                      cat.complements.filter(
                                        (complement) =>
                                          complement.id === item.id
                                      )
                                    ).length < 1 &&
                                  totalCategoryComplementsAmount(
                                    complement.id
                                  ) < complement.limit &&
                                  item.limit > 0 && (
                                    <InputComplementAmount
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                      onChange={(e) =>
                                        handleChangeComplementAmount(
                                          Number(e.target.value),
                                          item.id,
                                          item.name,
                                          item.price,
                                          item.delivery_price,
                                          item.limit,
                                          complement.limit,
                                          complement.id,
                                          complement.additional,
                                          complement.more_expensive_only,
                                          complement.minimum,
                                          complement.use_average
                                        )
                                      }
                                    />
                                  )
                                  : totalCategoryComplementsAmount(
                                    complement.id
                                  ) < complement.limit &&
                                  item.limit > 0 && (
                                    <InputComplementAmount
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                      onChange={(e) =>
                                        handleChangeComplementAmount(
                                          Number(e.target.value),
                                          item.id,
                                          item.name,
                                          item.price,
                                          item.delivery_price,
                                          item.limit,
                                          complement.limit,
                                          complement.id,
                                          complement.additional,
                                          complement.more_expensive_only,
                                          complement.minimum,
                                          complement.use_average
                                        )
                                      }
                                    />
                                  )}

                                {totalCategoryComplementsAmount(complement.id) <
                                  complement.limit &&
                                  item.limit > 0 && (
                                    <FaPlusCircle
                                      size={18}
                                      color="#ff2c3a"
                                      onClick={() => {
                                        handleAddComplements(
                                          item.id,
                                          item.name,
                                          item.price,
                                          item.delivery_price,
                                          item.limit,
                                          complement.limit,
                                          complement.id,
                                          complement.additional,
                                          complement.more_expensive_only,
                                          complement.minimum,
                                          complement.use_average,
                                          item.is_cmv_manual,
                                          item.current_cmv
                                        );
                                      }}
                                    />
                                  )}
                              </ComplementItemAmount>
                            </li>
                          ))}
                      </ComplementItem>
                    </ComplementItemArea>
                  ))}
              </ComplementsArea>
            )}
          </div>
        </AddItemsProduct>

        <Footer>
          {product.sold_off ? (
            <button type="button">
              <span>
                <FiAlertCircle size={22} />
              </span>
              Produto Esgotado
            </button>
          ) : (
            <button onClick={addToCarts} type="button">
              Adicionar ao carrinho
              {isProductWeight ? (
                <span>
                  {inputProductPrice && inputProductPrice !== ''
                    ? formatValue(
                      Number(totalComplementsPrice) +
                      Number(inputProductPrice) +
                      comboStartingPrice
                    )
                    : formatValue(
                      amount *
                      (Number(totalComplementsPrice) +
                        Number(product.price * inputProductWeight) +
                        comboStartingPrice)
                    )}
                </span>
              ) : (
                <span>
                  {formatValue(
                    amount *
                    (Number(totalComplementsPrice) +
                      Number(product.price) +
                      comboStartingPrice)
                  )}
                </span>
              )}
            </button>
          )}
        </Footer>
      </Content>
    </Container>
  );
};

export default Product;
