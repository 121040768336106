import React, { useState, useRef, useEffect, useCallback } from 'react';
import ReactToPrint from 'react-to-print';
import { AiOutlinePrinter } from 'react-icons/ai';

import { Container, Modal, ModalBody, ModalFooter } from 'reactstrap';
import {
  OrderNumber,
  ContentBody,
  TableNumber,
  Actions,
  Pedido,
  CardRow,
} from '../styles';
import { Print, DisplayNoneImprimir } from './styles';

import { useAuth } from '~/context/AuthContext';
import formatTime from '~/services/formatTime';
import EyeBillsTableOperation from '../../Eye/EyeBillsTableOperation';
import PrintBillsTableOperation from '~/components/Prints/PrintBillsTableOperation';
import api from '~/services/api';
import { OrderButton, OrderStatusTag } from 'ui-kit-takeat';
import { FaEye, FaPrint } from 'react-icons/fa';

export default function CloseTable({ item, handlePrintItem, printQueueTable }) {
  const [modal, setModal] = useState(false);
  const [bills, setBills] = useState([]);
  const [printBills, setPrintBills] = useState([]);
  const [sessionBills, setSessionbills] = useState([]);
  const [productBills, setProductBills] = useState([]);
  const [commandTableNumber, setCommandTableNumber] = useState();
  const toggle = () => setModal(!modal);

  const { user } = useAuth();
  const componentRef = useRef(null);

  function closeClick() {
    printQueueTable(item);
  }

  const getBillsSession = useCallback(async () => {
    const response = await api.get(
      `restaurants/table-sessions-bills/${item.id}`
    );

    setBills(response.data.bills);
    setPrintBills(response.data.print_bills);
    setSessionbills(response.data.session);
    setProductBills(response.data.products);

    const baskets = [];

    const sorted = baskets.sort((a, b) => {
      if (a.id < b.id) {
        return 1;
      }

      if (a.id > b.id) {
        return -1;
      }

      return 0;
    });

    setCommandTableNumber(sorted[0]);
  }, [item.id]);

  useEffect(() => {
    if (modal) {
      getBillsSession();
    }
  }, [getBillsSession, modal]);

  return (
    <Pedido>
      <ContentBody>
        <CardRow grow onEnd>
          <div>
            <TableNumber>
              {item?.table?.table_type === "command" ?
                `Comanda ${item.table?.table_number} / 
              Mesa ${item.bills[0]?.command_table_number ?
                  item.bills[0]?.command_table_number : "não informada"}`
                :
                `Mesa ${item.table?.table_number}`
              }
            </TableNumber>
          </div>
          <div>
            <OrderNumber>{formatTime(item.updatedAt)}</OrderNumber>
          </div>
        </CardRow>
        <CardRow>
          <div>
            <OrderStatusTag
              text={item?.table?.table_type === "command" ? "Fechamento de comanda" : "Fechamento de mesa"}
              color="#FFA814"
              width={item?.table?.table_type === "command" ? "202px" : "180px"}
            />
          </div>
          <Actions>
            {user.is_printed_on_web || user.print_bills_on_web ? (
              <ReactToPrint
                onBeforeGetContent={async () => {
                  await getBillsSession();
                }}
                trigger={() => (
                  <OrderButton
                    title="Fechar"
                    icon={<FaPrint />}
                    color="#2EC9B7"
                  />
                )}
                content={() => componentRef.current}
                onAfterPrint={() => {
                  handlePrintItem({
                    session_key: item.key,
                    table_id: item?.table?.id
                  });
                  // renderOperation();
                }}
              />
            ) : (
              <OrderButton
                title="Fechar"
                icon={<FaPrint />}
                color="#2EC9B7"
                onClick={closeClick}
              />
            )}
            <OrderButton icon={<FaEye />} color="#ffa814" onClick={toggle} />
          </Actions>
        </CardRow>
      </ContentBody>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            <EyeBillsTableOperation item={item} bills={bills} />
          </Container>
        </ModalBody>
        <ModalFooter>
          <ReactToPrint
            onBeforeGetContent={async () => {
              await getBillsSession();
            }}
            trigger={() => (
              <Print>
                <AiOutlinePrinter color="black" size={20} />
              </Print>
            )}
            content={() => componentRef.current}
          />
        </ModalFooter>
      </Modal>

      <DisplayNoneImprimir>
        <PrintBillsTableOperation
          item={item}
          restaurant={user}
          ref={componentRef}
          paymentData={item?.payments}
          isOpen={modal}
          printBills={printBills}
          sessionBills={sessionBills}
          productBills={productBills}
          commandTableNumber={commandTableNumber}
        />
      </DisplayNoneImprimir>
    </Pedido>
  );
}
