import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 8px;

  h2 {
    color: #666;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    margin: 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #d9d9d9;
  }
`;

export const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  h3 {
    color: #666;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    margin: 0;
  }

  span {
    color: #666;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`;
