import React from 'react';

const PrintNFCe = React.forwardRef(({ html }, ref) => {
  const data = { __html: html };
  return (
    <div style={{ float: 'left' }} ref={ref} dangerouslySetInnerHTML={data} />
  );
});

export default PrintNFCe;
