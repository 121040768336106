import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';

import * as Yup from 'yup';

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import getValidationErrors from '~/services/getValidationErrors';
import { Wrapper, Header, Card } from './styles';
import { FaInfoCircle } from 'react-icons/fa';
import history from '~/services/history';
import api from '~/services/api';

import Input from '~/components/Form/Input';
import BackButton from '~/components/Buttons/BackButton';
import SaveButton from '~/components/Buttons/SaveButton';
import ChangePasswordButton from '~/components/Buttons/ChangePasswordButton';
import { useAuth } from '~/context/AuthContext';
import { connectSocket } from '~/store/modules/socketio/sagas';
import Select from '~/components/Form/SelectInput';
import { PageTitle } from '~/components/PageTitle';
import CheckboxInput from '~/components/Form/Checkbox';
import { Tooltip } from 'ui-kit-takeat';

function EditWaiter() {
  const location = useLocation();
  const formRef = useRef(null);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const { user } = useAuth();
  const waiter = location.state?.waiter;
  const [devices, setDevices] = useState([]);
  const [acceptCashPos, setAcceptCashPos] = useState(false);
  const [acceptCashFlowTooltip, setAcceptCashFlowTooltip] = useState(false);

  useEffect(() => {
    if (!waiter) {
      history.push('/register/waiters');
    }
  }, [waiter]);

  useEffect(() => {
    if (waiter) {
      setAcceptCashPos(waiter.pos_use_cash);
    }
  }, [waiter])
  
  
  const initialData = {
    name: waiter?.name,
    email: waiter?.email,
    password: waiter?.password,
    pdv_key: waiter?.pdv_key,
  };

  async function handleSubmit(data) {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        email: Yup.string()
          .email('Inserir um email válido')
          .required('Email ogrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await api.put(`restaurants/waiters/${waiter.id}`, {
        name: data.name,
        email: data.email,
        password: waiter?.password,
        pdv_key: data.pdv_key,
        stone_device_id: data.stone_device_id || null,
        pos_use_cash: data.pos_use_cash,
      });

      toast.success('Usuário de garçom atualizado com sucesso!');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else if (!error.response.ok) {
        switch (error.response.data.errorType) {
          case 'waiter_pdv_key_alreary_exists':
            toast.error('Senha já usada por outro garçom.');
            break;
          default:
            toast.error('Falha ao editar garçom');
        }
      }
    }
  }

  // submit de mudar senha
  async function handleSubmitChangePassword(data) {
    try {
      await api.put(`restaurants/waiters/${waiter.id}`, {
        password: data.password,
        email: waiter?.email,
      });
      toast.success('Senha do garçom atualizada com sucesso!');
    } catch (err) {
      toast.error('Falha ao alterar senha.');
    }
  }

  const getDevices = useCallback(async () => {
    try {
      const response = await api.get(`restaurants/stone/devices`);
      const devices = response.data
        .filter((dev) => !dev.waiter)
        .map((dev) => {
          return {
            label: dev.serial_number,
            value: dev.id,
          };
        });

      devices.push({
        label: 'Nenhum',
        value: null,
      });

      setDevices(devices);
    } catch (error) {
      toast.error('Falha ao encontrar POS Stone.');
    }
  }, []);

  useEffect(() => {
    if (user.has_stone_pos) {
      getDevices();
    }
  }, []);

  if (!waiter) {
    return null;
  }

  return (
    <Wrapper>
      <Header>
        <div>
          <PageTitle>Editar Garçons</PageTitle>
        </div>
        <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
      </Header>
      <Card>
        <Form
          id="save-form"
          initialData={initialData}
          onSubmit={handleSubmit}
          ref={formRef}
        >
          <div>
            <Row>
              <Col sm="12">
                <Input label="Nome do Garçom:" name="name" type="text" />
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <Input label="E-mail do Garçom:" name="email" type="text" />
              </Col>
              <Col sm="6">
                <label />
                <ChangePasswordButton onClick={toggle} />
              </Col>
            </Row>
            {user.ask_waiter_key && (
              <Row>
                <Col sm="6">
                  <Input
                    label="Senha para abrir mesas pelo PDV: (3 dígitos)"
                    name="pdv_key"
                    type="text"
                    maxlength={3}
                  />
                </Col>
              </Row>
            )}
            {user.has_stone_pos && waiter && (
              <Row>
                <Col sm="12">
                  <Select
                    label="POS Stone (S/N):"
                    name="stone_device_id"
                    options={devices}
                    defaultValue={
                      waiter.stone_device
                        ? {
                            label: waiter.stone_device.serial_number,
                            value: waiter.stone_device.id,
                          }
                        : null
                    }
                  />
                </Col>
              </Row>
            )}
            {user.use_smart_pos && (
              <Row>
                <Col sm="12" style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                  <CheckboxInput
                    label="Aceitar pagamento em dinheiro em maquininha POS"
                    name="pos_use_cash"
                    id="pos_use_cash"
                    checked={acceptCashPos}
                    onChange={() => setAcceptCashPos(!acceptCashPos)}
                  />
                  <FaInfoCircle
                    style={{ marginLeft: 5 }}
                    size={22}
                    onMouseEnter={() => setAcceptCashFlowTooltip(true)}
                    onMouseLeave={() => setAcceptCashFlowTooltip(false)}
                  ></FaInfoCircle>
                  <Tooltip
                    show={acceptCashFlowTooltip}
                    content={
                      <p style={{
                        textAlign: 'justify',
                        wordBreak: 'break-word',
                        hyphens: 'auto',
                        textIndent: 16,
                      }}>
                        Se essa opção estiver marcada, o garçom poderá aceitar pagamentos em dinheiro 
                        na maquininha POS em que estiver logado, pelas mesas 'Ficha' e 'Balcão'.
                        (O garçom deve deslogar e logar novamente para que a opção seja aplicada)
                      </p>
                    }
                    containerStyles={{
                      top: '30px',
                      left: '200px',
                      width: '360px',
                    }}
                  />
                </Col>
              </Row>
            )}
            <SaveButton type="submit" form="save-form">
              Cadastrar
            </SaveButton>
          </div>
        </Form>
      </Card>

      <Modal isOpen={modal} toggle={toggle}>
        <Form initialData={initialData} onSubmit={handleSubmitChangePassword}>
          <ModalHeader toggle={toggle}>Alterar Senha</ModalHeader>
          <ModalBody>
            <div>
              <Row>
                <Col lg="12">
                  <Input label="Nova Senha" name="password" type="password" />
                  <span>A senha deve conter pelo menos 6 caracteres.</span>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" onClick={toggle}>
              Salvar
            </Button>{' '}
            <Button color="danger" onClick={toggle}>
              Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Wrapper>
  );
}

export default EditWaiter;