import React, { useState } from 'react';
import { Container, ModalContent, ButtonArea, InformationArea } from './styles';
import { DefaultInput, Button } from 'ui-kit-takeat';
import { Modal } from 'reactstrap';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import api from '~/services/api';
import { useAuth } from '~/context/AuthContext';

export const AddClientModal = ({ addClient, setAddClient }) => {
  const { setBuyerCreatedByWaiter, buyerCreatedByWaiter } = useAuth();
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    cpf: '',
    birthday: '',
    cep: '',
    street: '',
    neighborhood: '',
    number: '',
    complement: '',
    ...buyerCreatedByWaiter.clientData,
  });

  function toggleAddClientModal() {
    setAddClient(!addClient);
  }

  const updateFormData = (newData) => {
    setFormData({ ...formData, ...newData });
  };

  const [askFullRegister, setAskFullRegister] = useState(false);
  const [fullRegister, setFullRegister] = useState(false);

  function handlePhone(tel) {
    updateFormData({ phone: tel });
    if (tel?.length >= 15) {
      setAskFullRegister(true);
    } else {
      setAskFullRegister(false);
    }
  }

  const getClient = async () => {
    try {
      const { data } = await api.post('/restaurants/get-clients', {
        phone: formData.phone,
      });

      updateFormData(data.client);
    } catch (err) {
      console.log('getClient error: ', err);
    }
  };

  async function handleSubmit() {
    try {
      const schema = Yup.object().shape({
        name: Yup.string()
          .required('Usuário obrigatório')
          .min(3, 'Mínimo de 3 letras'),
        phone: Yup.string().matches(/\(\d\d\)\s\d{5}-\d{4}/, {
          message: 'Preencha todos os 9 números',
          excludeEmptyString: true,
        }),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });

      setBuyerCreatedByWaiter({
        clientData: formData,
        name: formData.name,
        phone: formData.phone,
      });
      toggleAddClientModal();
    } catch (erro) {
      console.log(erro);
      erro.errors.map((err) => {
        toast.error(err);
      });
    }
  }

  return (
    <Modal isOpen={addClient} toggle={toggleAddClientModal}>
      <ModalContent>
        <span id="title">Adicionar cliente</span>
        <DefaultInput
          title="Nome do cliente*"
          onChange={(e) => updateFormData({ name: e.target.value })}
          value={formData.name}
        />
        <DefaultInput
          title="Celular"
          placeholder="(Opcional)"
          mask="(99) 99999-9999"
          onChange={(e) => handlePhone(e.target.value)}
          value={formData.phone}
        />
        {askFullRegister && !fullRegister && (
          <span
            id="fullRegister"
            onClick={() => {
              getClient();
              setFullRegister(true);
            }}
          >
            Cadastro completo
          </span>
        )}
        {fullRegister && (
          <InformationArea>
            <DefaultInput
              title="E-mail"
              placeholder="(opcional)"
              onChange={(e) => updateFormData({ email: e.target.value })}
              value={formData.email}
              containerStyles={{
                gridArea: 'email',
                gap: 10,
              }}
            />
            <DefaultInput
              title="CPF"
              placeholder="(opcional)"
              onChange={(e) => updateFormData({ cpf: e.target.value })}
              value={formData.cpf}
              containerStyles={{
                gridArea: 'cpf',
                gap: 10,
              }}
              mask="999.999.999-99"
            />
            <DefaultInput
              title="Data de nascimento"
              placeholder="(opcional)"
              onChange={(e) => updateFormData({ birthday: e.target.value })}
              value={formData.birthday}
              containerStyles={{
                gridArea: 'birthday',
                gap: 10,
              }}
              mask="99/99/9999"
            />
            <DefaultInput
              title="CEP"
              placeholder="(opcional)"
              onChange={(e) => updateFormData({ cep: e.target.value })}
              value={formData.cep}
              containerStyles={{
                gridArea: 'cep',
                gap: 10,
              }}
              mask="99999-999"
            />
            <DefaultInput
              title="Rua"
              placeholder="(opcional)"
              onChange={(e) => updateFormData({ street: e.target.value })}
              value={formData.street}
              containerStyles={{
                gridArea: 'street',
                gap: 10,
              }}
            />
            <DefaultInput
              title="Bairro"
              placeholder="(opcional)"
              onChange={(e) => updateFormData({ neighborhood: e.target.value })}
              value={formData.neighborhood}
              containerStyles={{
                gridArea: 'neighborhood',
                gap: 10,
              }}
            />
            <DefaultInput
              title="Número"
              placeholder="(opcional)"
              onChange={(e) => updateFormData({ number: e.target.value })}
              value={formData.number}
              containerStyles={{
                gridArea: 'number',
                gap: 10,
              }}
            />
            <DefaultInput
              title="Complemento"
              placeholder="(opcional)"
              onChange={(e) => updateFormData({ complement: e.target.value })}
              value={formData.complement}
              containerStyles={{
                gridArea: 'complement',
                gap: 10,
              }}
            />
          </InformationArea>
        )}

        <ButtonArea>
          <Button
            onClick={() => setAddClient(false)}
            title="Cancelar"
            buttonColor="#C8131B"
            inverted="true"
          />
          <Button
            title="Salvar"
            buttonColor="#2EC9B7"
            onClick={() => handleSubmit()}
          />
        </ButtonArea>
      </ModalContent>
    </Modal>
  );
};
