import styled from 'styled-components';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export const Container = styled.div`
  display: block;
  width: 20%;
  overflow-y: auto;
  border-right: 1px solid #ededed;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;
export const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #4d4d4c;

  span {
    color: #4d4d4c;
  }

  &:visited {
    color: #4d4d4c;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  max-height: calc(100% - 99px);
`;

export const BodyTitle = styled.span`
  font-size: 20px;
  font-weight: 700;
  padding: 0 16px 16px;
`;

export const TablesArea = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  padding: 0 16px 16px;
  max-height: calc(100% - 46px);
  overflow-y: auto;

  span {
    font-size: 20px;
    font-weight: 700;
  }
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  background-color: ${(props) =>
    props.tableStatus === 'available' ? '#2EC9B7' : '#C8131B'};
  border:${(props) => props.isTableSelected? "2px solid #0F635A" : "2px solid transparent"};
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  span {
    font-size: 23px;
    color: white;
    margin-top: 5px;
  }
  p {
    color: white;
  }
`;
