/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { toast } from 'react-hot-toast';

import {
  Modal,
  ModalBody,
} from 'reactstrap';
import api from '~/services/api';
import OrderProductCategory from '~/pages/Products/OrderProductCategory';
import { useMenu } from '~/context/OrderingSystem/Menu';

import {
  Container,
  CategoryHeader,
  CategoryName,
} from './styles';

import ProductsTablesFiscal from '../../../pages/Products/ProductsTablesFiscal';
function CategoryItem({
  category,
  user,
  getCategories,
  handleChangeModalEditCategory,
  handleDeleteCategory,
  menewProducts,
  categories,
  cache,
  setCategoriesFiltered,
  setCategories,
  idx,
  setComplementsCategories,
  complementCategories,
  fiscalGroups,
  ifoodMenus,
  ifoodRestaurants,
  ifoodProducts,
}) {
  const { updateMenuCategory } = useMenu();
  const [modalOrdenationCategory, setModalOrdenationCategory] = useState(false);
  const [modalCreateProduct, setModalCreateProduct] = useState(false);
  const [pdvAvailable, setPdvAvailable] = useState(category?.available);
  const [deliveryAvailable, setDeliveryAvailable] = useState(
    category?.available_in_delivery
  );

  const [isExclusiveAvailable, setIsExclusiveAvailable] = useState(
    category?.is_exclusive
  );

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    setPdvAvailable(category?.available);
    setDeliveryAvailable(category?.available_in_delivery);
    setIsExclusiveAvailable(category?.is_exclusive);
  }, [category]);

  const toggleModalCreateProduct = useCallback(() => {
    setModalCreateProduct(!modalCreateProduct);
  }, [modalCreateProduct]);

  function toggleModalOrdenationCategory() {
    setModalOrdenationCategory(!modalOrdenationCategory);
  }

  async function handleChangeCategory(value, event, idx) {
    const id = Number(idx);
    try {
      await api.put(`restaurants/product-category-available/${id}`, {
        available: value,
      });

      setPdvAvailable(value);
      if (category) {
        category.available = value;
      }

      updateMenuCategory(id, { available: value });
      toast.success('Categoria alterada com sucesso!');
    } catch (err) {
      toast.error('Erro ao ativar/ desativar categoria!');
    }
  }

  async function handleChangeDeliveryCategory(value, event, idx) {
    const id = Number(idx);
    try {
      await api.put(`restaurants/product-category-available/${id}`, {
        available_in_delivery: value,
      });
      setDeliveryAvailable(value);
      if (category) {
        category.available_in_delivery = value;
      }

      updateMenuCategory(id, { available: value });
      toast.success('Categoria alterada com sucesso!');
    } catch (err) {
      toast.error('Erro ao ativar/ desativar categoria!');
    }
  }

  async function handleChangeExclusiveCategory(value, event, idx) {
    const id = Number(idx);
    try {
      await api.put(`restaurants/product-category-available/${id}`, {
        is_exclusive: value,
      });
      setIsExclusiveAvailable(value);
      if (category) {
        category.is_exclusive = value;
      }

      updateMenuCategory(id, { available: value });

      toast.success('Categoria alterada com sucesso!');
    } catch (err) {
      toast.error('Erro ao editar categoria!');
    }
  }

  const productsRef = useRef(null);
  const toggleProductsList = () => {
    return productsRef?.current?.toggle();
  };

  const handleSetProduct = useCallback(
    (data) => {
      productsRef.current.setProductsState(data);
      toggleModalCreateProduct();
    },
    [toggleModalCreateProduct]
  );

  const [collapsed, setCollapsed] = useState([]);

  const handleCollapse = (id) => {
    if (collapsed.includes(id)) {
      const newCollapse = collapsed.filter((c) => c !== id);
      setCollapsed(newCollapse);
    } else {
      setCollapsed((state) => [...state, id]);
    }
  };

  const handleDuplicateCategoryProduct = useCallback(
    async (category_id) => {
      try {
        const response = await api.put(
          `/restaurants/product-category/duplicate/${category_id}`
        );

        const duplicate_item = response.data;

        const newCategories = [...categories];

        newCategories.splice([idx + 1], 0, duplicate_item);
        setCategories(newCategories);
        localStorage.setItem('@gddashboard:isMenuUpdated', true);
        toast.success('Categoria duplicado com sucesso');
      } catch (error) {
        toast.error('Erro ao duplicar categoria');
      }
    },
    [categories, idx, setCategories]
  );

  return (
    <Container>
      <Modal
        isOpen={modalOrdenationCategory}
        toggle={toggleModalOrdenationCategory}
        size="lg"
        onClosed={getCategories}
      >
        <ModalBody>
          <OrderProductCategory
            updateCategories={getCategories}
            toggleModalOrdenationCategory={toggleModalOrdenationCategory}
          />
        </ModalBody>
      </Modal>

      <CategoryHeader>
        <CategoryName>
          <span style={{ fontSize: '22px' }}>{category?.name}</span>
        </CategoryName>
      </CategoryHeader>

      <ProductsTablesFiscal
        category={category}
        user={user}
        getCategories={getCategories}
        menewProducts={menewProducts}
        categories={categories}
        ref={productsRef}
        cache={cache}
        setCategoriesFiltered={setCategoriesFiltered}
        setCategories={setCategories}
        setComplementsCategories={setComplementsCategories}
        complementCategories={complementCategories}
        fiscalGroups={fiscalGroups}
        ifoodMenus={ifoodMenus}
        ifoodRestaurants={ifoodRestaurants}
        ifoodProducts={ifoodProducts}
      />
    </Container>
  );
}

export default CategoryItem;