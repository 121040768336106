import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import api from '~/services/api';
import { Container } from './styles';

import ProductCategory from './Categories';

import { useAuth } from '~/context/AuthContext';

import PermissionErrorContainer from '~/components/PermissionErrorContainer';

function Products() {
  const { getMenewProducts, menewProducts, user } = useAuth();
  const [permission, setPermission] = useState();

  const [fiscalGroups, setFiscalGroups] = useState([]);

  const getFiscalGroups = useCallback(async () => {
    if (user.has_nfce) {
      try {
        const response = await api.get(`/restaurants/fiscal-groups`);

        setFiscalGroups(response.data);
      } catch (error) {
        toast.error('Erro ao grupos fiscais');
      }
    }
  }, []);

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'MenuProducts'}`
      );

      const { can_read } = response.data.MenuProducts;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
    getFiscalGroups();
  }, [validateUser, getFiscalGroups]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      {/* <ProductComplementHeader
        menewProducts={menewProducts}
        user={user}
        getMenewProducts={getMenewProducts}
      /> */}
      <ProductCategory
        menewProducts={menewProducts}
        user={user}
        getMenewProducts={getMenewProducts}
        fiscalGroups={fiscalGroups}
      />
    </Container>
  );
}

export default Products;
