import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px;
  height: calc(100vh - 48px);
  overflow-y: auto;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Card = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 20px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
`;

export const QrcodeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h4 {
    margin-top: 20px;
  }
`;

export const DefaultAvatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border: 2px dashed #ddd;
  background: #fff;

  span {
    color: #ddd;
    font-size: 16px;
    font-weight: bold;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  label {
    margin-left: auto;
    margin-right: auto;
  }

  input {
    max-width: 100px;
    margin: 0 auto;
  }
  button {
    margin: 20px auto 0 auto;
    padding: 10px 15px;
    background: ${(props) => props.theme.colors.secondary};
    color: #fff;
    font-weight: bold;
    border: none;
    border-radius: 4px;
  }
`;
