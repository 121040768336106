import React, { useRef, useState, useCallback } from 'react';
import { Form } from '@unform/web';

import { Row, Col, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import {
  Item,
  ItemName,
  Complements,
  Obs,
  DeleteAndEditActions,

} from '../styles';
import { useOrder } from '~/context/orderContext';
import api from '~/services/api';

import Select from '~/components/Form/SelectInput';
import { useBills } from '~/context/BillsContext';
import Input from '~/components/Form/Input';
import ButtonDefault from '~/components/Buttons/DefaultButton';
import { useAuth } from '~/context/AuthContext';
import getValidationErrors from '~/services/getValidationErrors';
import PersonalButton from '~/components/Buttons/PersonalButton';

export default function ItemOrdersBill({
  order,
  item,
  from,
  session,
  setAllTablesAvailable,
  setInputValue,
  setTablesFilteredSearch,
  handleCancelItem
}) {
  const { cancelOrderItem, getAllOrders, loadingCancelItems } = useOrder();
  const { user } = useAuth();

  const { amount } = order;
  const { getTableBills } = useBills();

  const cancelForm = useRef(null);

  const hasKey = user ? user.has_order_cancel_password : false;

  const [modalCancelOrderIsOpened, setModalCancelOrderIsOpened] = useState(
    false
  );

  const [dataState, setDataState] = useState('');

  const [loading, setLoading] = useState(false);

  const [loadingCancelItem, setLoadingCancelItem] = useState(false);

  const changeAmount = useCallback(
    async (data) => {

      const newAmount = order?.amount - data?.amount
      setLoading(true);
      try {
        if (newAmount === 0) {          
          handleCancelOrderItem(item?.id, order.id, from, session.id)
        } else {
          await api.put(
            `restaurants/orders/basket/${item.id}/item/${order.id}/${newAmount}`
          );
          toast.success('Pedido atualizado.');
          getTableBills(session.id);

          getAllOrders();
          setAllTablesAvailable('');
          setInputValue('');
          setTablesFilteredSearch('');
        }
      } catch (error) {
        toast.error('Erro ao atualizar pedido.');
      }
      setLoading(false);
    },
    [
      getAllOrders,
      getTableBills,
      item,
      order,
      session,
      setAllTablesAvailable,
      setInputValue,
      setTablesFilteredSearch,
      loading
    ]
  );

  const toggleModalCancelOrder = useCallback(
    (data) => {
      setModalCancelOrderIsOpened(!modalCancelOrderIsOpened);
      setDataState(data);
    },
    [modalCancelOrderIsOpened]
  );

  const handleCancelItemModal = useCallback(
    async (data) => {
      setLoading(true);
      try {
        if (cancelForm?.current) {
          cancelForm.current.setErrors({});
        }

        const schema = Yup.object().shape({
          order_cancel_password: Yup.string().required('senha obrigatória'),
          cancel_reason: Yup.string().required('justicativa obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const newAmount = order?.amount - dataState?.amount

        await api.put(
          `restaurants/orders/basket/${item.id}/item/${order.id}/${newAmount}`,
          {
            order_cancel_password: data.order_cancel_password,
            cancel_reason: data.cancel_reason,
          }
        );
        toast.success('Pedido atualizado.');
        getAllOrders();
        getTableBills(session.id);
        toggleModalCancelOrder();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (cancelForm?.current) {
            cancelForm.current.setErrors(errors);
          }
        } else if (
          error.response.data.errorType === 'incorrect_order_cancel_password'
        ) {
          toast.error('senha incorreta');
        } else {
          toast.error('Erro ao cancelar pedido!');
        }
      }
      setLoading(false);
    },
    [
      toggleModalCancelOrder,
      dataState,
      item,
      order,
      getAllOrders,
      getTableBills,
      session,
      loading
    ]
  );

  function handleChangeAmount(data) {
    if (hasKey) {
      toggleModalCancelOrder(data);
    } else {
      changeAmount(data);
    }
  }

  const options = [];

  for (let i = 1; i <= amount; i++) {
    options.push({ value: i, label: i.toString() });
  }

  const defaultOption = [{ value: amount, label: amount.toString() }];

  const handleCancelOrderItem = useCallback(async (item_id, order_id, from, id_session) => {
    setLoadingCancelItem(true);
    await cancelOrderItem({
      order_basket_id: item_id,
      order_id: order_id,
      from,
      id: id_session,
    });
    setAllTablesAvailable('');
    setInputValue('');
    setTablesFilteredSearch('');
    setLoadingCancelItem(false);
  });

  return (
    <Item key={order.id} style={{ paddingBottom: 10 }}>


      <Modal isOpen={modalCancelOrderIsOpened} toggle={toggleModalCancelOrder}>
        <Form onSubmit={handleCancelItemModal} ref={cancelForm}>
          <ModalBody>
            <Input
              name="order_cancel_password"
              label="Senha de cancelamento"
              type="password"
            />
            <Input name="cancel_reason" label="Justificativa" />
          </ModalBody>
          <ModalFooter>
            <ButtonDefault message="Confirmar" type="submit" disabled={loading || loadingCancelItems} />
          </ModalFooter>
        </Form>
      </Modal>
      <Form onSubmit={handleChangeAmount}>

        <Row md="12">

          <Col md="5">
            <ItemName style={{ margin: 'auto' }}>
              <strong>{order?.product.name}</strong>
            </ItemName>
            {order?.complement_categories.map((category) => (
              <div key={category.id}>
                {/* <ComplementsTitle>
                  {category.complement_category.name}:
                </ComplementsTitle> */}
                {category.order_complements.map((complement) => (
                  <Complements key={complement.id}>
                    - {complement.amount} x {complement.complement.name}{' '}
                  </Complements>
                ))}
              </div>
            ))}
            {order?.details !== '' && <Obs>Obs: {order?.details}</Obs>}
          </Col>
          <Col md="3">
            {order.amount > 1 && (

              <Select
                name="amount"
                options={options}
                defaultValue={{ label: "0", value: 0 }}
              />
            )}
          </Col>
          <Col md="4">
            <DeleteAndEditActions>
              {item?.orders.length > 1 ? (
                order.amount > 1 ? (
                  <PersonalButton type="submit" disabled={loading || loadingCancelItems} color="#ff2c3a" message="Cancelar" />
                ) : (
                  <PersonalButton
                    onClick={e => handleCancelOrderItem(item?.id, order.id, from, session.id, item?.amount)}
                    disabled={loadingCancelItem || loadingCancelItems}
                    type="button"
                    color="#ff2c3a"
                    message="Cancelar"
                  />
                )
              ) : order.amount > 1 ? (
                <PersonalButton type="submit" disabled={loading || loadingCancelItems} color="#ff2c3a" message="Cancelar" />

              ) : (
                <PersonalButton
                  color="#ff2c3a"
                  onClick={async () => {
                    setLoadingCancelItem(true);
                    await handleCancelItem({
                      order_basket_id: item.id,
                      from: 'pending',
                    });
                    setLoadingCancelItem(false);
                  }}
                  disabled={loadingCancelItem || loadingCancelItems}
                  message="Cancelar"
                  type="button"
                />
              )}


            </DeleteAndEditActions>
          </Col>
        </Row>
      </Form>
    </Item>
  );
}
