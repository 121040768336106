import React, { useCallback, useState } from 'react';
import { Form } from '@unform/web';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import ReactSelect from 'react-select';
import FileSaver from 'file-saver';
import { getMonth } from 'date-fns';

import { MdDelete, MdEdit } from 'react-icons/md';
import { generateIntermediaryInputSheet } from '../../../../services/generateIntermediaryInputSheet';
import { FaQuestionCircle } from 'react-icons/fa';
import { Container, HistoryTable, InputsTable, InputAreaStyled } from '../styles';
import { MonthCalendar, DownloadButton, Button } from 'ui-kit-takeat';
import Input from '~/components/Form/Input';
import Select from '~/components/Form/SelectInput';
import formatCompleteDate from '~/services/formatCompleteDate';
import TextareaContainer from '~/components/Form/Textarea';
import { FiArrowDown } from 'react-icons/fi';
import toast from 'react-hot-toast';
import api from '~/services/api';

const IntermediaryRowModal = ({
  setIntermediary,
  inputs,
  intermediaries,
  setIntermediaryFiltered,
  getInputs,
  filterString,
  setMonthYearDate,
  historyIntermediary,
  setSelectedInterInput,
  setSelectedInterIntermediaryInput,
  quantidadeUpdateInterInp,
  intermediary,
  selecteInterInput,
  setModalUnit,
  toggle,
  modal,
  monthYearDate,
  selectedInterIntermediaryInput,
  filterStringInter,
  setHistoryIntermediary,
  modalEye,
  toggleEye,
  modalUnit,
  setQuantidadeUpdateInterInp,
  toggleUnit,
}) => {
  const [defaultInterValue, setDefaultInterValue] = useState(0);
  const [selectInput, setSelectInput] = useState([]);
  const [defaultInterInterValue, setDefaultInterInterValue] = useState(0);
  const [selectInter, setSelectInter] = useState([]);
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const [modalYield, setModelYield] = useState(false);
  const toggleYield = () => {
    setModelYield(!modalYield);
  };


  // orderDate vai ser usado para ordernar o horario no modal de historico
  // true vai ser horario mais recente primeiro
  const [orderDate, setOrderDate] = useState(true);

  const [loading, setLoading] = useState(false);

  const [inputQuant, setInputQuant] = useState(
    parseFloat(intermediary.quantidade),
  );
  const [inputZero, setInputZero] = useState(0);
  const [inputInicial, setInputInicial] = useState(
    parseFloat(intermediary.quantidade),
  );
  const [valueToSend, setValueToSend] = useState(
    parseFloat(intermediary.quantidade),
  );

  const [modalInterInput, setModalInterInput] = useState(false);
  const [modalInterIntermediaryInput, setModalInterIntermediaryInput] = useState(false);

  const [newUnit, setNewUnit] = useState('');
  const [valueNewUnit, setValueNewUnit] = useState(1);

  const [selectedJustificative, setSelectedJustificative] = useState(null);
  const justificatives = [
    { value: 0, label: 'Perda por validade/vencimento' },
    { value: 1, label: 'Desperdício de produção' },
    { value: 2, label: 'Item Danificado' },
    { value: 3, label: 'Devolução de mercadoria' },
    { value: -1, label: 'Outros' },
  ];

  const inputs_select = inputs
    .filter((inp) => !inp.is_master)
    .map((input) => ({
      value: input.id,
      label: input.name,
      unidade: input.unidade,
    }));
  const inter_select = intermediaries.map((inter) => ({
    value: inter.id,
    label: inter.name,
    unidade: inter.unidade,
  }));

  const handleChangeUnit = useCallback(async () => {
    try {
      const handleData = {
        intermediary_id: intermediary.id,
        value: valueNewUnit,
        unit_change: newUnit,
      };

      if (!valueNewUnit || parseFloat(valueNewUnit) === 0) {
        toast.error('Valor de conversão deve ser válido e diferente de zero.');
      } else if (newUnit === '') {
        toast.error('Informe a nova unidade.');
      } else {
        await api.put('/restaurants/change-unit-intermediary', handleData);

        // document.getElementById('update_button').click();
        getInputs();
        setValueNewUnit(1);
        setNewUnit('');
        setModalUnit(false);

        toast.success('Unidades convertidas com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao atualizar unidades.');
    }
  }, [newUnit, valueNewUnit, intermediary.id]);

  const handleUpdateInput = useCallback(
    async (data) => {
      setLoading(true);
      try {
        let justificative = data.inputed_justificative || null;

        if (selectedJustificative?.value !== -1) {
          justificative = selectedJustificative?.label;
        }

        const handleData = {
          id: intermediary.id,
          // quantidade: valueToSend,
          quantidade: inputZero,
          name: data.name,
          recipe: data.recipe,
          minimum_stock: data.minimum_stock,
          ideal_stock: data.ideal_stock,
          justificative,
        };

        console.log('@@@', data, handleData, justificative, inputZero);

        if (!valueToSend && valueToSend !== 0) {
          toast.error('Coloque o valor gasto do insumo.');
        } else {
          const response = await api.put('/restaurants/intermediary', handleData);

          setIntermediary(response.data);
          // document.getElementById('update_button').click();
          getInputs();

          setInputInicial(valueToSend);
          setInputZero(0);
          console.log('@@@', valueToSend, inputZero);
          setInputQuant(valueToSend);
          setSelectedJustificative(null);

          toggle();

          toast.success('Produto Intermediario atualizado com sucesso!');
        }
      } catch (error) {
        toast.error('Erro ao atualizar insumo.');
      }
      setLoading(false);
    },
    [valueToSend, intermediary.id, setIntermediary, selectedJustificative]
  );

  const toggleModalInterInput = () => {
    setModalInterInput(!modalInterInput);
  };

  const toggleModalInterIntermediaryInput = () => {
    setModalInterIntermediaryInput(!modalInterIntermediaryInput);
  };

  async function insertInputInter(selectInput, defaultInterValue, filterStringInter, filterString) {
    const exists = intermediary.intermediary_inputs.filter((int) => int.input.id === selectInput.value);

    if (exists.length > 0) {
      toast.error('Insumo já cadastrado');
    } else {
      try {
        const data = {
          intermediary_id: intermediary.id,
          input_id: selectInput.value,
          quantidade: defaultInterValue.replace(',', '.'),
        };

        const response = await api.put('/restaurants/add-input-to-intermediary', data);

        setIntermediary(response.data);
        if (filterStringInter === '') {
          setIntermediaryFiltered(response.data);
        } else {
          const filter = response.data.filter((input) =>
            input.name.toLowerCase().startsWith(filterStringInter.toLowerCase())
          );

          setIntermediaryFiltered(filter);
        }
        getInputs(filterString);
        toast.success('Insumo adicionado ao Produto Intermediario.');
      } catch (err) {
        toast.error('Erro ao adicionar Insumo a Produto Intermediario.');
      }
    }
  }

  async function deleteInputInter(id) {
    const ok = window.confirm('Tem certeza que deseja excluir a relação entre o produto intermediário e o insumo?');

    if (ok) {
      try {
        const data = {
          intermediary_id: intermediary.id,
          input_id: Number(id),
        };

        const response = await api.post('/restaurants/delete-input-intermediary', data);

        setIntermediary(response.data);
        setIntermediaryFiltered(response.data);
        getInputs();

        toast.success('Insumo removido do Produto Intermediario.');
      } catch (err) {
        toast.error('Erro ao remover Insumo do Produto Intermediario.');
      }
    }
  }

  async function insertInterInter(selectInter, defaultInterValue, filterStringInter) {
    const exists = intermediary.intermediary_intermediaries.filter((int) => int.intermediary.id === selectInter.value);

    if (exists.length > 0) {
      toast.error('Produto Intermediário já cadastrado');
    } else {
      try {
        const data = {
          intermediary_id: intermediary.id,
          new_intermediary_id: selectInter.value,
          quantidade: defaultInterValue.replace(',', '.'),
        };

        const response = await api.put('/restaurants/add-intermediary-to-intermediary', data);

        setIntermediary(response.data);

        if (filterStringInter === '') {
          setIntermediaryFiltered(response.data);
        } else {
          const filter = response.data.filter((input) =>
            input.name.toLowerCase().startsWith(filterStringInter.toLowerCase())
          );

          setIntermediaryFiltered(filter);
        }

        toast.success('Produto Intermediario adicionado ao Produto Intermediario.');
      } catch (err) {
        toast.error('Erro ao adicionar Produto Intermediario a PI.');
      }
    }
  }

  async function deleteInterInter(id) {
    const ok = window.confirm('Tem certeza que deseja excluir a relação entre os produtos intermediários?');

    if (ok) {
      try {
        const data = {
          intermediary_id: intermediary.id,
          old_intermediary_id: Number(id),
        };

        const response = await api.post('/restaurants/delete-intermediary-intermediary', data);

        setIntermediary(response.data);
        setIntermediaryFiltered(response.data);
        getInputs();

        toast.success('Produto Intermediario removido do Produto Intermediario.');
      } catch (err) {
        toast.error('Erro ao remover Produto Intermediario do Produto Intermediario.');
      }
    }
  }

  const handleChangeYield = useCallback(
    async (data) => {
      try {
        const yieldUpdate = data.yield_update
          ? parseFloat(data.yield_update.replace(',', '.'))
          : parseFloat(intermediary.yield.replace(',', '.'));

        const handleData = {
          yield_update: yieldUpdate,
        };
        console.log(yieldUpdate);
        if (!data.yield_update || parseFloat(data.yield_update.replace(',', '.')) <= 0) {
          toast.error('Rendimento deve ser maior que zero.');
        } else {
          const response = await api.put(`/restaurants/intermediary/yield/${intermediary.id}`, handleData);

          setIntermediary(response.data);
          setIntermediaryFiltered(response.data);

          toast.success('Rendimento atualizado com sucesso!');
          setModelYield(false);
        }
      } catch (error) {
        console.log(error);
        toast.error('Erro ao atualizar rendimento.');
      }
    },
    [intermediary.id]
  );

  const handleUpdateIntermediaryInput = useCallback(
    async (data) => {
      try {
        let quantidade = data.quantidade.replace(',', '.');

        const handleData = {
          quantidade,
          intermediary_input_id: selecteInterInput.id,
        };

        const response = await api.put('/restaurants/update-intermediary-input', handleData);

        setIntermediary(response.data);
        setIntermediaryFiltered(response.data);

        setModalInterInput(false);

        toast.success('Ficha técnica atualizada!');
      } catch (error) {
        toast.error('Erro ao atualizar ficha técnica.');
      }
    },
    [selecteInterInput, setIntermediary, setIntermediaryFiltered]
  );

  const [quantidadeUpdateInterInter, setQuantidadeUpdateInterInter] = useState(null);

  const handleUpdateInterIntermediaryInput = useCallback(
    async (data) => {
      try {
        let quantidade = data.quantidade.replace(',', '.');

        const handleData = {
          quantidade,
          intermediary_intermediary_id: selectedInterIntermediaryInput.id,
        };

        const response = await api.put('/restaurants/update-intermediary-intermediary', handleData);

        setIntermediary(response.data);
        setIntermediaryFiltered(response.data);
        setModalInterIntermediaryInput(false);

        toast.success('Ficha técnica atualizada!');
      } catch (error) {
        toast.error('Erro ao atualizar ficha técnica.');
      }
    },
    [selectedInterIntermediaryInput]
  );

  const handleDownloadInputs = async () => {
    try {
      const sheet = await generateIntermediaryInputSheet(historyIntermediary, intermediary);
      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        `Movimentações Prod. Intermediario (${intermediary.name}) - ${months[getMonth(monthYearDate.start)]}.xlsx`
      );
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };

  function sortDate() {
    let thisHistory = historyIntermediary;
    setHistoryIntermediary([]);

    setHistoryIntermediary(
      thisHistory?.sort((a, b) => {
        if (!orderDate) {
          if (a.createdAt < b.createdAt) {
            return 1;
          }

          if (a.createdAt > b.createdAt) {
            return -1;
          }

          return 0;
        } else {
          if (a.createdAt > b.createdAt) {
            return 1;
          }

          if (a.createdAt < b.createdAt) {
            return -1;
          }

          return 0;
        }
      })
    );

    setOrderDate(!orderDate);
  }

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size='xl'>
        <Form onSubmit={handleUpdateInput} initialData={intermediary}>
          <ModalHeader>Editar Produto Intermediário</ModalHeader>
          <ModalBody>
            <Container>
              <Row>
                <Col>
                  <Input
                    label='Nome do Insumo'
                    name='name'
                    type='text'
                    disabled={intermediary?.is_multistore_child || false}
                  />
                </Col>
              </Row>
              {/* <Row>
                    <Col md="6">
                      <Input
                        label="Estoque Mínimo"
                        name="minimum_stock"
                        type="number"
                        step="0.001"
                      />
                    </Col>
                    <Col md="6">
                      <Input
                        label="Estoque Ideal"
                        name="ideal_stock"
                        type="number"
                        step="0.001"
                      />
                    </Col>
                  </Row> */}
              <Row>
                <Col md='2'>
                  <InputAreaStyled>
                    <b>Qtd. Disponível</b>
                    {parseFloat(intermediary.quantidade).toFixed(4)}
                  </InputAreaStyled>
                </Col>
                <Col md='3'>
                  <InputAreaStyled>
                    <b>Custo Unitário Atual </b>
                    <p>{intermediary.unitary_price}</p>
                  </InputAreaStyled>
                </Col>
                <Col md='3'>
                  <InputAreaStyled>
                    <b>Valor em Estoque</b>
                    {intermediary.total_value}
                  </InputAreaStyled>
                </Col>
                <Col md='4'>
                  <InputAreaStyled>
                    <b>Rendimento da Ficha Técnica</b>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      {intermediary.yield}{' '}
                      <MdEdit
                        onClick={() => toggleYield()}
                        style={{
                          marginLeft: 10,
                          marginTop: 5,
                          cursor: 'pointer',
                        }}
                      />
                    </div>
                  </InputAreaStyled>
                </Col>
              </Row>

              <Row>
                <Col md='4'>
                  <Row>
                    <Col md='1'>
                      {' '}
                      <button
                        type='button'
                        style={{
                          background: '#FF3939',
                          width: 32,
                          height: 32,
                          position: 'absolute',
                          bottom: 0,
                          left: 10,

                          borderRadius: '50%',
                          border: 'none',
                          marginBottom: 15,
                        }}
                        onClick={() => {
                          setInputQuant(parseFloat(inputQuant) - 1);
                          setInputZero(parseFloat(inputZero) - 1);
                          setValueToSend(inputInicial + parseFloat(inputZero) - 1);
                        }}
                      >
                        -
                      </button>
                    </Col>
                    <Col md='7' style={{ marginLeft: 5 }}>
                      {' '}
                      <Input
                        label='Quantidade'
                        name='quantidade'
                        type='number'
                        step='0.001'
                        value={inputZero}
                        onChange={(e) => {
                          setInputZero(e.target.value);
                          setValueToSend(inputInicial + parseFloat(e.target.value));
                        }}
                      />
                    </Col>
                    <Col md='1'>
                      <button
                        type='button'
                        style={{
                          width: 32,
                          height: 32,
                          position: 'absolute',
                          bottom: 0,
                          left: -8,
                          background: '#3BD2C1',
                          borderRadius: '50%',
                          border: 'none',
                          marginBottom: 15,
                        }}
                        onClick={() => {
                          setInputQuant(parseFloat(inputQuant) + 1);
                          setInputZero(parseFloat(inputZero) + 1);
                          setValueToSend(inputInicial + parseFloat(inputZero) + 1);
                        }}
                      >
                        +
                      </button>
                    </Col>
                  </Row>
                </Col>
                {/* <Col md="4" style={{ marginTop: 10 }}>
                      <Select
                        label="Unidade"
                        name="unidade"
                        options={unidade_options}
                        defaultValue={unidade_default}
                      />
                    </Col> */}

                <Col md='3' style={{ marginTop: 15 }}>
                  <p style={{ display: 'flex', flexDirection: 'column' }}>
                    <strong>Nova Quantidade:</strong>{' '}
                    <span>
                      {Number(inputZero) ? (inputInicial + parseFloat(inputZero)).toFixed(4) : inputInicial.toFixed(4)}
                    </span>
                  </p>
                </Col>

                <Col md='3'>
                  <Input label='Estoque Mínimo' name='minimum_stock' type='number' step='0.001' />
                </Col>
                <Col md='2'>
                  <Input label='Estoque Ideal' name='ideal_stock' type='number' step='0.001' />
                </Col>
              </Row>
              {inputZero && inputZero < 0 ? (
                <Row>
                  <Col md='6'>
                    <ReactSelect
                      placeholder='Selecione a justificativa'
                      options={justificatives}
                      onChange={(e) => {
                        setSelectedJustificative(e);
                      }}
                      value={selectedJustificative}
                      name='justificative'
                    />
                  </Col>

                  {selectedJustificative && selectedJustificative.value < 0 ? (
                    <Col md='6'>
                      <Input
                        style={{
                          marginTop: '-11px',
                          borderRadius: '4px',
                          height: '41px',
                        }}
                        placeholder='Escreva a justificativa'
                        name='inputed_justificative'
                        type='text'
                        id='input'
                      />
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              ) : (
                <></>
              )}
              <Row>
                <Col md='12'> </Col>
              </Row>
              <Row
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <Col md='12' style={{ marginTop: 10 }}>
                  <p>Ficha Técnica</p>
                </Col>
              </Row>
              {!intermediary?.is_multistore_child && (
                <Row>
                  <Col md='6'>
                    <Select
                      label='Insumos'
                      name='inputs_select'
                      options={inputs_select}
                      placeholder='Selecione'
                      onChange={(e) => setSelectInput(e)}
                      icon={
                        <FaQuestionCircle
                          title='Vincule os insumos usados na receita para produzir seu produto intermediário'
                          color='orange'
                          style={{ marginLeft: 5 }}
                        />
                      }
                    />
                  </Col>
                  <Col md='4'>
                    <Input
                      label='Quantidade'
                      name='quantidade'
                      type='text'
                      defaultValue={0}
                      onChange={(e) => {
                        setDefaultInterValue(e.target.value.replace(/[a-z|A-Z|\s]/g, ''));
                        document.getElementById('add').disabled = false;
                      }}
                      value={defaultInterValue}
                      icon={
                        <FaQuestionCircle
                          title='Insira a quantidade necessária utilizada na receita para produzir seu produto intermediário.'
                          color='orange'
                          style={{ marginLeft: 5 }}
                        />
                      }
                    />
                  </Col>
                  <Col md='2' style={{ margin: 'auto', marginBottom: 15 }}>
                    <button
                      type='button'
                      id='add'
                      style={{
                        background: '#3BD2C1',
                        width: 40,
                        height: 40,
                        borderRadius: '50%',
                        border: 'none',
                        fontSize: 16,
                      }}
                      onClick={() => insertInputInter(selectInput, defaultInterValue, filterStringInter, filterString)}
                    >
                      +
                    </button>
                  </Col>
                </Row>
              )}
              <Row>
                <Col md='12'>
                  <InputsTable borderless>
                    <thead>
                      <tr>
                        <th>Insumo</th>
                        <th>Gasto Únit.</th>
                        <th>Custo Únit.</th>
                        <th>Gasto total</th>
                        <th>Custo total</th>
                        <th>Disponível</th>
                        <th>Redução de</th>
                        <th> </th>
                      </tr>
                    </thead>

                    <tbody>
                      {intermediary.intermediary_inputs.length > 0 &&
                        intermediary.intermediary_inputs.map((inter) => (
                          <tr key={inter.id}>
                            <td>{inter.input.name}</td>
                            <td style={{ textAlign: 'center' }}>
                              {parseFloat(inter.quantidade).toFixed(4)} {inter.input.unidade}
                            </td>
                            <td>
                              {' '}
                              R$
                              {parseFloat(inter.input.unitary_price).toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {(parseFloat(inter.quantidade) * parseFloat(intermediary.yield))
                                .toFixed(3)
                                .replace('.', ',')}{' '}
                              {inter.input.unidade}
                            </td>
                            <td>
                              R$
                              {parseFloat(
                                inter.quantidade * intermediary.yield * inter.input.unitary_price
                              ).toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                            <td
                              style={{
                                textAlign: 'center',
                                color: 'green',
                              }}
                            >
                              {parseFloat(inter.input.quantidade).toFixed(4).replace('.', ',')} {inter.input.unidade}
                            </td>

                            <td style={{ color: 'red' }}>
                              {inputZero > 0 ? parseFloat(inputZero * inter.quantidade).toFixed(4) : 0}{' '}
                              {inter.input.unidade}
                            </td>

                            {!intermediary?.is_multistore_child && (
                              <td>
                                <div>
                                  <MdEdit
                                    size={20}
                                    color='#17a2b8'
                                    cursor='pointer'
                                    onClick={() => {
                                      setSelectedInterInput(inter);
                                      toggleModalInterInput();
                                    }}
                                  />

                                  <MdDelete
                                    size={20}
                                    color='red'
                                    cursor='pointer'
                                    onClick={() => deleteInputInter(inter.input.id)}
                                  />
                                </div>
                              </td>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </InputsTable>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div style={{ margin: 5 }} />
                </Col>
              </Row>
              {!intermediary?.is_multistore_child && (
                <Row>
                  <Col md='6'>
                    <Select
                      label='Produto Intermediário'
                      name='inter_select'
                      options={inter_select}
                      placeholder='Selecione'
                      onChange={(e) => setSelectInter(e)}
                      icon={
                        <FaQuestionCircle
                          title='Vincule os produtos intermediários usados na receita para produzir seu novo produto intermediário.'
                          color='orange'
                          style={{ marginLeft: 5 }}
                        />
                      }
                    />
                  </Col>
                  <Col md='4'>
                    <Input
                      label='Quantidade'
                      name='quantidade'
                      type='text'
                      defaultValue={0}
                      onChange={(e) => {
                        setDefaultInterInterValue(e.target.value.replace(/[a-z|A-Z|\s]/g, ''));
                        document.getElementById('add1').disabled = false;
                      }}
                      value={defaultInterInterValue}
                      icon={
                        <FaQuestionCircle
                          title='Insira a quantidade necessária utilizada na receita para produzir seu produto intermediário.'
                          color='orange'
                          style={{ marginLeft: 5 }}
                        />
                      }
                    />
                  </Col>
                  <Col md='2' style={{ margin: 'auto', marginBottom: 15 }}>
                    <button
                      type='button'
                      id='add1'
                      style={{
                        background: '#3BD2C1',
                        width: 40,
                        height: 40,
                        borderRadius: '50%',
                        border: 'none',
                        fontSize: 16,
                      }}
                      onClick={() =>
                        insertInterInter(selectInter, defaultInterInterValue?.replace(',', '.'), filterStringInter)
                      }
                    >
                      +
                    </button>
                  </Col>
                </Row>
              )}
              <Row>
                <Col md='12'>
                  <InputsTable borderless>
                    <thead>
                      <tr>
                        <th>Prod. Interm.</th>
                        <th>Gasto Únit.</th>
                        <th>Custo Únit.</th>
                        <th>Gasto total</th>
                        <th>Custo total</th>
                        <th>Disponível</th>
                        <th>Redução de</th>
                        <th> </th>
                      </tr>
                    </thead>

                    <tbody>
                      {intermediary.intermediary_intermediaries.length &&
                        intermediary.intermediary_intermediaries.map((inter) => (
                          <tr key={inter.id}>
                            <td>{inter.intermediary.name}</td>
                            <td style={{ textAlign: 'center' }}>
                              {parseFloat((inter.quantidade * intermediary.yield) / intermediary.yield).toFixed(4)}{' '}
                              {inter.intermediary.unidade}
                            </td>
                            <td>
                              {' '}
                              R$
                              {parseFloat(inter.intermediary.unitary_price).toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {(parseFloat(inter.quantidade) * parseFloat(intermediary.yield))
                                .toFixed(3)
                                .replace('.', ',')}{' '}
                              {inter.intermediary.unidade}
                            </td>
                            <td>
                              R$
                              {parseFloat(
                                inter.quantidade * intermediary.yield * inter.intermediary.unitary_price
                              ).toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                            <td
                              style={{
                                textAlign: 'center',
                                color: 'green',
                              }}
                            >
                              {parseFloat(inter.intermediary.quantidade).toFixed(4).replace('.', ',')}{' '}
                              {inter.intermediary.unidade}
                            </td>
                            <td style={{ color: 'red' }}>
                              {inputZero > 0 ? parseFloat(inputZero * inter.quantidade).toFixed(4) : 0}{' '}
                              {inter.intermediary.unidade}
                            </td>
                            {!intermediary?.is_multistore_child && (
                              <td>
                                <div>
                                  <MdEdit
                                    size={20}
                                    color='#17a2b8'
                                    cursor='pointer'
                                    onClick={() => {
                                      setSelectedInterIntermediaryInput(inter);
                                      toggleModalInterIntermediaryInput();
                                    }}
                                  />
                                  <MdDelete
                                    size={20}
                                    cursor='pointer'
                                    color='red'
                                    onClick={() => deleteInterInter(inter.intermediary.id)}
                                  />
                                </div>
                              </td>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </InputsTable>
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Col style={{ marginTop: 10 }}>
                  <p>Receita</p>
                </Col>
              </Row>
              <Row>
                <Col md='12'>
                  <TextareaContainer
                    name='recipe'
                    placeholder='Salve a receita do produto intermediário'
                    type='text'
                    maxLength='500'
                  />
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggle} title='Cancelar' />
            <Button buttonColor='#3BD2C1' type='submit' disabled={loading} title='Salvar' />{' '}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modalEye} toggle={toggleEye} size='xl'>
        <Row style={{ alignItems: 'center' }}>
          <Col md='9'>
            <p style={{ marginLeft: '40px' }}>
              {' '}
              Histórico do Produto Intermediário: <b>{intermediary.name}</b>
            </p>
          </Col>
          <Col md='3'>
            <div
              style={{
                margin: '10px 30px 10px 0px',
                float: 'right',
                display: 'flex',
                justifyItems: 'space-between',
              }}
            >
              <div style={{ margin: '12px 8px 0px 0px' }}>
                <DownloadButton
                  onClick={() => {
                    if (historyIntermediary.length > 0) {
                      handleDownloadInputs();
                    } else {
                      toast.error('Não há movimentações nesse período.');
                    }
                  }}
                />
              </div>
              <MonthCalendar onDateSelected={setMonthYearDate} />
            </div>
          </Col>
        </Row>
        <ModalBody>
          <Container>
            <HistoryTable>
              <thead>
                <tr>
                  <th style={{ cursor: 'pointer' }} onClick={() => sortDate()}>
                    <FiArrowDown />
                    Data / Hora
                  </th>
                  <th>Saldo Inicial ({intermediary.unidade})</th>
                  <th>Variação</th>
                  <th>Saldo Final ({intermediary.unidade})</th>
                  <th>Valor</th>
                  <th>Custo unitário</th>
                  <th>Operação</th>
                  <th>Usuário</th>
                  <th>Justificativa</th>
                </tr>
              </thead>
              <tbody>
                {historyIntermediary?.map((history) =>
                  history.type === '+' ? (
                    <tr style={{ background: '#D7EED2', color: '#1E3422' }}>
                      <td>{formatCompleteDate(history.createdAt)}</td>
                      <td>
                        {history.balance && !isNaN(history.balance)
                          ? `${(parseFloat(history.balance) - parseFloat(history.quantidade)).toLocaleString('pt-BR', {
                            minimumFractionDigits: 4,
                            maximumFractionDigits: 4,
                          })}`
                          : '-'}
                      </td>
                      <td>
                        {history.type} {parseFloat(history.quantidade).toFixed(4).replace('.', ',')}
                      </td>
                      <td>
                        {history.balance && !isNaN(history.balance)
                          ? `${parseFloat(history.balance).toLocaleString('pt-BR', {
                            minimumFractionDigits: 4,
                            maximumFractionDigits: 4,
                          })}`
                          : '-'}
                      </td>
                      <td>
                        {history.total && !isNaN(history.total)
                          ? `R$${parseFloat(history.total).toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                          : history.unitary_price &&
                          !isNaN(history.unitary_price) &&
                          history.quantidade &&
                          !isNaN(history.quantidade) &&
                          parseFloat(history.quantidade) > 0 &&
                          `R$${(parseFloat(history.quantidade) * parseFloat(history.unitary_price)).toLocaleString(
                            'pt-BR',
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}`}
                      </td>

                      <td>
                        {history.unitary_price && !isNaN(history.unitary_price)
                          ? `R$${parseFloat(history.unitary_price).toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                          : '-'}
                      </td>

                      <td>
                        {history.product && `[P] ${history.product.name}`}
                        {history.complement && `[C] ${history.complement.name}`}
                        {history.intermediary && `[PI] ${history.intermediary.name}`}
                        {history.intermediary && history.operation === 'intermediary_deleted' && ` - Deletado`}
                        {!history.complement &&
                          !history.product &&
                          !history.intermediary &&
                          (history.operation !== 'nfe' ? 'Editado Manualmente' : 'Entrada de Nota Fiscal')}
                      </td>

                      <td>{history.user ? history.user.name : '-'}</td>

                      <td>{history.justificative ? history.justificative : !history.nfe && '-'}</td>

                      {/* antigo */}

                      {/* <td>
                            {history.type}{' '}
                            {parseFloat(history.quantidade).toFixed(4).replace('.', ',')}{' '}
                            {intermediary.unidade}
                          </td>
                          <td>
                            {history.product && `[P] ${history.product.name}`}
                            {history.complement &&
                              `[C] ${history.complement.name}`}
                            {history.operation === 'canceled_item' &&
                              ' - Cancelado'}
                            {!history.complement &&
                              !history.product &&
                              (history.operation !== 'nfe'
                                ? 'Editado Manualmente'
                                : 'Entrada de Nota Fiscal')}
                          </td>
                          <td>{history.total ? `R$${history.total}` : '-'}</td>
                          <td>
                            {parseFloat(history?.balance)
                              ? parseFloat(history.balance).toFixed(3)
                              : ''}
                          </td>
                          <td>{formatCompleteDate(history.createdAt)}</td> */}
                    </tr>
                  ) : (
                    <tr style={{ background: '#FBD1C5', color: '#590D12' }}>
                      <td>{formatCompleteDate(history.createdAt)}</td>
                      <td>
                        {history.balance && !isNaN(history.balance)
                          ? `${(parseFloat(history.balance) + parseFloat(history.quantidade)).toLocaleString('pt-BR', {
                            minimumFractionDigits: 4,
                            maximumFractionDigits: 4,
                          })}`
                          : '-'}
                      </td>
                      <td>
                        {history.type} {parseFloat(history.quantidade).toFixed(4).replace('.', ',')}
                      </td>
                      <td>
                        {history.balance && !isNaN(history.balance)
                          ? `${parseFloat(history.balance).toLocaleString('pt-BR', {
                            minimumFractionDigits: 4,
                            maximumFractionDigits: 4,
                          })}`
                          : '-'}
                      </td>
                      <td>
                        {history.total && !isNaN(history.total)
                          ? `R$${parseFloat(history.total).toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                          : '-'}
                      </td>

                      <td>
                        {history.unitary_price && !isNaN(history.unitary_price)
                          ? `R$${parseFloat(history.unitary_price).toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                          : '-'}
                      </td>

                      <td>
                        {history.product && `[P] ${history.product.name}`}
                        {history.complement && `[C] ${history.complement.name}`}
                        {history.intermediary && `[PI] ${history.intermediary.name}`}
                        {history.intermediary && history.operation === 'intermediary_deleted' && ` - Deletado`}
                        {!history.complement &&
                          !history.product &&
                          !history.intermediary &&
                          (history.operation !== 'nfe' ? 'Editado Manualmente' : 'Entrada de Nota Fiscal')}
                      </td>

                      <td>{history.user ? history.user.name : '-'}</td>

                      <td>{history.justificative ? history.justificative : !history.nfe && '-'}</td>
                    </tr>
                  )
                )}
              </tbody>
            </HistoryTable>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggleEye} title='Fechar' />
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalUnit} toggle={toggleUnit}>
        <ModalHeader>Converter Unidade</ModalHeader>
        <ModalBody>
          <Container>
            <Form>
              <Row>
                <Col md='4' style={{ margin: 'auto' }}>
                  1 {intermediary.unidade} equivale a:
                </Col>
                <Col md='4'>
                  <Input
                    type='number'
                    step='0.1'
                    label='Valor'
                    name='value_change'
                    id='value_change'
                    value={valueNewUnit}
                    placeholder='Valor de conversão'
                    onChange={(e) => setValueNewUnit(e.target.value)}
                  />
                </Col>
                <Col md='4'>
                  <Input
                    type='text'
                    name='unit_change'
                    placeholder='ex: KG'
                    label='Unidade'
                    value={newUnit}
                    onChange={(e) => setNewUnit(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <strong>Atualizado: </strong> {intermediary.quantidade} {intermediary.unidade} {'=>'}{' '}
                  {(parseFloat(intermediary.quantidade) * valueNewUnit).toFixed(3).replace('.', ',')}{' '}
                  {newUnit || 'nova unidade'}
                </Col>
              </Row>
            </Form>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button buttonColor='success' onClick={() => handleChangeUnit()} title='Converter' />
          <Button onClick={toggleUnit} title='Fechar' />
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalYield} toggle={toggleYield}>
        <Form onSubmit={handleChangeYield}>
          <ModalHeader>Alterar rendimento</ModalHeader>
          <ModalBody>
            <Container>
              <Row>
                <Col md='12'>
                  <Input
                    type='text'
                    label='Rendimento'
                    name='yield_update'
                    defaultValue={intermediary.yield}
                    placeholder='Rendimento'
                  />
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleYield} title='Fechar' />
            <Button buttonColor='success' type='submit' title='Salvar' />
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modalInterInput} toggle={toggleModalInterInput}>
        <Form onSubmit={handleUpdateIntermediaryInput} initialData={selecteInterInput}>
          <ModalHeader>Editar Ficha Técnica do Produto Intermediário</ModalHeader>
          <ModalBody>
            <Container>
              <Row>
                <Col md='6'>
                  <Input label='Insumo:' name='input.name' type='text' disabled />
                </Col>
                <Col md='5'>
                  <Input
                    label='Gasto Total:'
                    name='quantidade'
                    type='text'
                    value={quantidadeUpdateInterInp}
                    onChange={(e) => setQuantidadeUpdateInterInp(e.target.value.replace(/[a-z|A-Z|\s]/g, ''))}
                  />
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleModalInterInput} title='Cancelar' />
            <Button buttonColor='#3BD2C1' type='submit' onClick={toggleModalInterInput} title='Salvar' />{' '}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modalInterIntermediaryInput} toggle={toggleModalInterIntermediaryInput}>
        <Form onSubmit={handleUpdateInterIntermediaryInput} initialData={selectedInterIntermediaryInput}>
          <ModalHeader>Editar Ficha Técnica do Produto Intermediário</ModalHeader>
          <ModalBody>
            <Container>
              <Row>
                <Col md='6'>
                  <Input label='Produto intermediário:' name='intermediary.name' type='text' disabled />
                </Col>
                <Col md='5'>
                  <Input
                    label='Gasto Total:'
                    name='quantidade'
                    type='text'
                    value={quantidadeUpdateInterInter}
                    onChange={(e) => setQuantidadeUpdateInterInter(e.target.value.replace(/[a-z|A-Z|\s]/g, ''))}
                  />
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleModalInterIntermediaryInput} title='Cancelar' />
            <Button
              buttonColor='#3BD2C1'
              type='submit'
              onClick={toggleModalInterIntermediaryInput}
              title='Salvar'
            />{' '}
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default IntermediaryRowModal;