/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';
import Switch from 'react-switch';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';
import { AiOutlineEye, AiFillPushpin } from 'react-icons/ai';
import { BiTrash } from 'react-icons/bi';
import formatDate from '~/services/formatDate';

import { Container, Actions, Delete, CategoriesTable } from './styles';
import Input from '~/components/Form/Input';

import api from '~/services/api';

import ClientsRatingRow from '../../../pages/Reports/ClientsRatingRow';

function TaskRow({
  task,
  getTasks,
  inicialDate,
  finalDate,
  handleFilter,
  filter,
}) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modalRate, setModalRate] = useState(false);
  const toggleRate = () => setModalRate(!modalRate);

  const [isChecked, setIsChecked] = useState(task.check);

  const handleCheck = useCallback(async () => {
    if (
      task?.type === 'stock' ||
      task?.type === 'register' ||
      task?.type === 'rate'
    ) {
      try {
        const response = await api.put(`restaurants/tasks/check/${task.id}`);
        setIsChecked(response.data.check);
        if (response.data.check) {
          toast.success('Tarefa completa');
        } else {
          toast.success('Tarefa incompleta');
        }

        task.check = response.data.check;
        task.checked_at = response.data.checked_at;
        task.to_show = response.data.to_show;
        // handleFilter(filter);
      } catch (err) {
        toast.error('Erro ao completar tarefa');
      }
    } else if (task?.type === 'cash_flow') {
      try {
        const response = await api.put(
          `restaurants/tasks/check/cashflow/${task.id}`
        );
        setIsChecked(response.data.paid);
        if (response.data.paid) {
          toast.success('Tarefa completa');
        } else {
          toast.success('Tarefa incompleta');
        }

        task.check = response.data.paid;
        task.checked_at = response.data.paid_at;
        // handleFilter(filter);
      } catch (err) {
        toast.error('Erro ao completar tarefa');
      }
    }
  }, []);

  const handleDelete = useCallback(async () => {
    if (window.confirm('Tem certeza que deseja deletar essa tarefa?')) {
      try {
        await api.delete(`/restaurants/tasks/${task.id}`);
        toast.success('Tarefa deletada com sucesso!');
        getTasks();
      } catch (err) {
        toast.error('Erro ao deletar tarefa');
      }
    }
  }, []);

  return (
    <>
      <tr
        key={task.id}
        style={
          task.fixed
            ? { background: '#DCE1E5' }
            : task?.is_earning === true
            ? { background: '#A8F0D7' }
            : task?.is_earning === false
            ? { background: '#F5CCCC' }
            : {}
        }
      >
        <td style={{ width: 60 }}>
          <div style={{ marginLeft: 20, width: 60 }}>
            <Switch
              id={`${task.id}`}
              draggable
              onChange={handleCheck}
              checked={isChecked}
              checkedIcon={false}
              uncheckedIcon={false}
              height={20}
              width={40}
              handleDiameter={20}
              activeBoxShadow="0 0 2px 3px #111"
              boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
            />
          </div>
        </td>
        <td style={{ width: 120, textAlign: 'center' }}>
          {task?.due_date !== '-' ? formatDate(task?.due_date) : '-'}
        </td>
        <td>
          <p>
            <strong>
              {task?.fixed && <AiFillPushpin />} {task.title}
            </strong>
          </p>
          <h5>{task.subtitle}</h5>
          {task?.value && (
            <h5
              style={
                task?.is_earning === true
                  ? { color: 'green' }
                  : { color: 'red' }
              }
            >
              R$
              {parseFloat(task?.value || 0).toLocaleString('pt-br', {
                minimumFractionDigits: 2,
              })}
            </h5>
          )}
          {task?.rates?.length > 0 && (
            <h5 style={{ color: '#008F1D' }}>
              {task.rates.length} comentário{task.rates.length > 1 && 's'}
            </h5>
          )}
        </td>
        <td>
          {task?.cash_flows?.length > 0 && (
            <Actions>
              <Delete onClick={toggle}>
                <AiOutlineEye size={20} color="blue" />
              </Delete>
            </Actions>
          )}
          {task?.type === 'register' && (
            <Actions>
              <Delete onClick={handleDelete}>
                <BiTrash size={20} color="red" />
              </Delete>
            </Actions>
          )}
          {task?.rates?.length > 0 && (
            <Actions>
              <Delete onClick={toggleRate}>
                <AiOutlineEye size={20} color="blue" />
              </Delete>
            </Actions>
          )}
        </td>

        <Modal isOpen={modal} toggle={toggle} size="xl">
          <ModalHeader>Lançamentos Vinculados</ModalHeader>
          <ModalBody>
            <Container>
              {task?.cash_flows?.map((cash) => (
                <Form initialData={cash}>
                  <Row>
                    <Col md="5">
                      <Input label="Descrição" name="name" disabled />
                    </Col>
                    <Col md="2">
                      <Input
                        label="Valor"
                        name="value"
                        value={`R$ ${parseFloat(cash.value).toLocaleString(
                          'pt-br',
                          {
                            minimumFractionDigits: 2,
                          }
                        )}`}
                        disabled
                      />
                    </Col>
                    <Col md="5">
                      <Input
                        label="Categoria/Subcategoria"
                        name="cash_flow_text"
                        value={cash.category_text}
                        disabled
                      />
                    </Col>
                  </Row>
                </Form>
              ))}
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={toggle}>
              Fechar
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalRate} toggle={toggleRate} size="xl">
          <ModalHeader>Avaliações com Comentários</ModalHeader>
          <ModalBody>
            <Container>
              <CategoriesTable borderless>
                <thead>
                  <tr>
                    <th>Telefone</th>
                    <th>Nome</th>
                    <th>Avaliação</th>
                    <th>Comentário</th>
                    <th>Feita em:</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {task?.rates &&
                    task?.rates.length > 0 &&
                    task?.rates.map((item) => (
                      <ClientsRatingRow rating={item} />
                    ))}
                </tbody>
              </CategoriesTable>
            </Container>
          </ModalBody>
          <ModalFooter style={{ textAlign: 'right' }}>
            <Button color="danger" onClick={toggleRate}>
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
      </tr>
    </>
  );
}

export default TaskRow;
