import styled, { css } from 'styled-components';

export const Content = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  p {
    color: #fff;
  }

  ${(props) =>
    props.type === 'open' &&
    css`
      background-color: #ff403d;
    `}

  ${(props) =>
    props.type === 'closing' &&
    css`
      background-color: orange;
    `}

    ${(props) =>
    props.type === 'available' &&
    css`
        background-color: #2ec9b7;
      `}

      ${(props) =>
    props.nullTable === 'none' &&
    css`
          background-color: transparent;
        `}

  color: #fff;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

`;
export const ModalPaymentBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;


export const OperationArea = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: space-between;
  position: relative;

  flex-wrap: wrap;


`;
export const OrderSystemArea = styled.div`
  width: 65%;
  height: 80vh;
  overflow-y: hidden;
  border-right: 1px solid #33333320;

  @media (max-width: 500px) {
    width: 100%;
  }

 


`;
export const BillTableArea = styled.div`
  width: 35%;
  height: 80vh;
  max-height: 780px;
  overflow-y: auto;
  overflow-x: hidden;

  h2 {
    padding-left: 8px;
  }

  @media (max-width: 500px) {
    width: 100%;
    overflow-x: auto;
  }


`;

export const ButtonsArea = styled.div`
  padding-top: 16px;
  padding-left: 8px;
  height: 60px;
  border-top: 1px solid #33333330;
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  width: 35%;
  background-color: #fff;
`;

export const DisplayNoneImprimir = styled.button`
  display: none;
`;

export const Print = styled.button`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-self: center;
  float: right;
  position: relative;
  display: block;
  right: 0;
  margin-right: 10px;
  border: none;
  background: #f0b402;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
    border-radius: 50%;
  }
`;

export const CloseButton = styled.button`
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 12px;

  color: white;

  border: none;
  background: #dc3545;

  display: flex;

  svg {
    margin-right: 8px;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const ActionsInside = styled.div`
  padding-top: 2px;
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export const CashierButton = styled.button`
  border-radius: 8px;
  padding: 10px 15px;
  color: white;
  font-size: 12px;

  border: none;
  background: #28a745;

  display: flex;
  flex-direction: row;
  text-align: center;

  svg {
    margin-right: 8px;
  }

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const ReopenButton = styled.button`
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 12px;
  color: #000;

  border: none;
  background: #ffc107;

  display: flex;
  flex-direction: row;
  text-align: center;

  svg {
    margin-right: 8px;
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const SeeButton = styled.button`
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 12px;

  color: white;

  border: none;
  background: #17a2b8;

  display: flex;
  flex-direction: row;
  text-align: center;

  svg {
    margin-right: 8px;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const TablePayment = styled.div`
  border-top: 1px solid #ffffff40;
  height: 40%;
  width: 90%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 24px;

  /* background-color: #fff;
  color: #dc3545; */

  p {
    font-size: 12px;

    & + p {
      margin-top: -10px;
    }
  }
`;
