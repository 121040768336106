import styled from 'styled-components';

export const Container = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const DeliveryPrice = styled.div`
  padding-top: 15px;
  margin-top: 10px;
  border-top: solid #cdcdcd 1px;
  padding-bottom: 10px;
  width: 110mm;
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    span {
      font-size: 16px;
      font-weight: bold;
    }
  }
`;

export const Table = styled.div`
  padding-top: 10px;
  width: 110mm;
  text-align: center;
  font-size: 20px;
  margin-right: auto;
`;

export const Order = styled.div`
  width: 110mm;
  text-align: center;
  font-size: 15px;
  margin-right: auto;
  padding-bottom: 5px;
`;

export const Restaurant = styled.div`
  padding-top: 10px;
  width: 110mm;
  text-align: center;
  font-size: 20px;
  margin-right: auto;
  padding-bottom: 15px;
  border-bottom: solid black 1px;
`;

export const Body = styled.div`
  width: 110mm;
  clear: both;
`;

export const Phone = styled.div`
  text-align: left;

  font-size: 15px;
  padding-top: 10px;
  float: left;
`;

export const Time = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 15px;
  padding-top: 10px;
  float: right;
`;

export const Items = styled.div`
  width: 110mm;
  padding-top: 20px;
`;

export const Item = styled.div`
  padding-top: 5px;
`;

export const ItemName = styled.div`
  font-size: 15px;
`;

export const ComplementsTitle = styled.div`
  font-size: 13px;
`;

export const Complements = styled.div`
  font-size: 12px;
  padding-left: 15px;
`;

export const Obs = styled.div`
  font-size: 13px;
`;

export const DeliveryInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 4px;

  span {
    font-size: 15px;
  }
`;

export const MotoboyDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
