import React from 'react';
import { useLocation } from 'react-router-dom';

import { Card, Container, Header } from './styles';
import history from '~/services/history';
import BackButton from '~/components/Buttons/BackButton';
import PrintReportButton from '~/components/Buttons/PrintReportButton';

function PrintReportsProducts() {
  const location = useLocation();

  const {
    categories,
    complements,
    products,
    inicialFormat,
    finalFormat,
    total_restaurant_amount,
    total_restaurant_price,
    payments,
    totalBox,
    buyers,
    categoriesCanceled,
    total_restaurant_canceled_service_price,
    total_restaurant_canceled_amount,
    has_service_tax,
    service_tax,
    fantasy_name,
    total_restaurant_price_without_service,
  } = location.state;

  const restaurantName = fantasy_name;

  return (
    <Container>
      <Header>
        <div>
          <h2>Relatório de Produtos</h2>
          <p>Confira todas suas vendas detalhadamente!</p>
        </div>
        <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
      </Header>
      <Card>
        <PrintReportButton
          categories={categories}
          products={products}
          total_restaurant_amount={total_restaurant_amount}
          total_restaurant_price={total_restaurant_price}
          payments={payments}
          totalBox={totalBox}
          inicialFormat={inicialFormat}
          finalFormat={finalFormat}
          buyers={buyers}
          has_service_tax={has_service_tax}
          service_tax={service_tax}
          restaurantName={restaurantName}
          categoriesCanceled={categoriesCanceled}
          total_restaurant_canceled_service_price={
            total_restaurant_canceled_service_price
          }
          total_restaurant_canceled_amount={total_restaurant_canceled_amount}
          total_restaurant_price_without_service={
            total_restaurant_price_without_service
          }
          complements={complements}
        />
      </Card>
    </Container>
  );
}

export default PrintReportsProducts;
