import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  /* margin: 10px auto 30px auto; */
  border-collapse: initial;
  border-spacing: 0 20px;
  background: ${(props) => props.theme.colors.white};
  /* filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2)); */
  border-radius: 8px;
  padding: 0 30px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;

    th {
      background: white;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
    }

    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #eee;
      padding-bottom: 15px;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;
