import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { lighten } from 'polished';

export const Container = styled.div`
  padding: 20px;
  height: calc(100vh - 48px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const Card = styled.div`
  margin: 20px auto;
  width: 90%;
  padding: 30px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }

  svg {
    color: ${(props) => props.theme.colors.tertiary};
    margin-right: 10px;
  }
`;

export const StyledA = styled.a`
  &:hover {
    text-decoration: none;
  }

  svg {
    color: ${(props) => props.theme.colors.tertiary};
    margin-right: 10px;
  }
`;

export const TablesTable = styled.table`
  width: 70%;
  min-width: 900px;
  margin: 10px auto 10px auto;
  border-collapse: initial;
  border-spacing: 0 20px;
  background: #e9ecf2;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  padding: 0 20px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #a8b4cc;
      padding-bottom: 10px;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const CategoryHeader = styled.div`
  margin-left: 50px;
  margin-bottom: 20px;
`;

export const ProductImage = styled.img`
  width: 80px;
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div``;

export const DisplayNoneImprimir = styled.button`
  display: none;
`;

export const Print = styled.button`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-self: center;
  margin-right: 10px;
  border: none;
  background: #d60300;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
    border-radius: 50%;
  }
`;

export const StatusTag = styled.div.attrs((props) => ({
  color: props.color,
  backgroundcolor: lighten(0.33, props.color),
}))`
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundcolor};
  font-weight: bold;
  padding: 5px 10px;
  max-width: 150px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  &::before {
    content: ' ';
    display: inline-block;
    position: relative;
    margin-right: 5px;
    width: 10px;
    height: 10px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
  }
`;

export const EditButton = styled(Link)`
  height: 25px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 15px;
  border: none;
  background: #17a2b8;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 6px;
  width: 80%;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const DeleteAndEditActions = styled.div`
  display: flex;
  flex-direction: column;
`;
