import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { FaPrint } from 'react-icons/fa';
import { Delete, DisplayNoneImprimir } from './styles';
import PrintBillsIndividual from '~/components/Prints/PrintBillsIndividual';
import { useAuth } from '~/context/AuthContext';

function OrdersBillOperation({ bill, userName, session }) {
  const componentRef = useRef(null);
  const { user } = useAuth();
  return (
    <tr key={bill?.id}>
      <td>{userName}</td>
      <td>R${bill?.total_service_price}</td>
      <ReactToPrint
        trigger={() => (
          <Delete>
            <FaPrint size={21} />
          </Delete>
        )}
        content={() => componentRef.current}
      />
      {/* {console.tron.log(bill)} */}
      <td>
        <DisplayNoneImprimir>
          <PrintBillsIndividual
            item={{
              bill,
              table: session?.table,
            }}
            restaurant={user}
            ref={componentRef}
          />
        </DisplayNoneImprimir>
      </td>
    </tr>
  );
}

export default OrdersBillOperation;
