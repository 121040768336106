/* eslint-disable no-shadow */
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { toast } from 'react-hot-toast';
import {
  format,
  startOfDay,
  endOfDay,
  subHours,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  addHours,
  differenceInDays,
} from 'date-fns';
import {
  Wrapper,
  Header,
  Card,
  Content,
} from './styles';


import api from '~/services/api';
import UpdateButton from '~/components/Buttons/UpdateButton';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import BillsDelivery from '~/components/BillsDelivery';

export default function ViewDeliveryBills() {

  const params = new URLSearchParams(document.location.search);

  const [activeTab, setActiveTab] = useState(
    params?.get('isCompleted') === 'true' ? '4' : '1'
  );

  const defaultInicial = useMemo(() => {
    return startOfDay(subHours(new Date(), 27)).toISOString();
  }, []);
  const defaultFinal = useMemo(() => {
    return endOfDay(new Date()).toISOString();
  }, []);
  const inicialFormat = format(new Date(defaultInicial), 'dd/MM/yyyy, HH:mm');
  const finalFormat = format(new Date(defaultFinal), 'dd/MM/yyyy, HH:mm');
  const phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [hourPhrase, setHourPhrase] = useState(phrase);
  const [rangeOn, setRangeOn] = useState(true);

  const [monthYearDate, setMonthYearDate] = useState(null);

  function setToday() {
    setRangeOn(true);
    setInicialDate(startOfDay(subHours(new Date(), 27)).toISOString());
    setFinalDate(endOfDay(new Date()).toISOString());

    const inicialFormat = format(
      startOfDay(subHours(new Date(), 27)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setWeek() {
    setRangeOn(true);
    setInicialDate(
      startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }).toISOString()
    );
    setFinalDate(endOfDay(new Date()).toISOString());

    const inicialFormat = format(
      startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setMonth(date) {
    setRangeOn(true);

    const inicial = startOfMonth(date);
    const final = endOfMonth(date);

    setInicialDate(inicial.toISOString());
    setFinalDate(final.toISOString());

    const inicialFormat = format(inicial, 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(final, 'dd/MM/yyyy, HH:mm');

    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);

    setMonthYearDate(date);
  }

  const [initialPre, setInitialPre] = useState(inicialDate);
  const [finalPre, setFinalPre] = useState(finalDate);

  function handleChangeDate() {
    const days = differenceInDays(new Date(finalPre), new Date(initialPre));

    if (days > 31) {
      setRangeOn(false);
    } else {
      setRangeOn(true);
      setInicialDate(format(new Date(initialPre), "yyyy-MM-dd'T'HH:mm:ss"));
      setFinalDate(format(new Date(finalPre), "yyyy-MM-dd'T'HH:mm:ss"));
    }
    const inicialFormat = format(new Date(initialPre), 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(new Date(finalPre), 'dd/MM/yyyy, HH:mm');
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  const [sessions, setSessions] = useState([]);

  const [loading, setLoading] = useState(true);

  const [updateTime, setUpdateTime] = useState(() => {
    return format(new Date(), 'HH:mm');
  });

  const getSessions = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/table-sessions-minimal', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          filter_all: true,
          is_delivery: false,
        },
      });

      setSessions(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [finalDate, inicialDate]);

  useEffect(() => {
    setLoading(true);
    // getPayments();
    getSessions();

    setLoading(false);
  }, [getSessions]);

  async function handleUpdate() {
    setLoading(true);
    getSessions();
    // getPayments();
    // getPaymentMethods();
    setLoading(false);

    const nowTime = format(new Date(), 'HH:mm');
    setUpdateTime(nowTime);
  }

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  async function orderByTableNumber() {
    try {
      const response = await api.get('/restaurants/table-sessions-minimal', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });

      const sessionsOrder = response.data.sort((a, b) => {
        return a.table.table_number - b.table.table_number;
      });

      setSessions(sessionsOrder);
    } catch (err) {
      // console.log('setsessions', response.data, inicialDate, finalDate);
      toast.error('Erro ao carregar informações');
    }
  }

  async function orderByTableDate() {
    try {
      const response = await api.get('/restaurants/table-sessions-minimal', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${finalDate}`,
        },
      });

      setSessions(response.data);
    } catch (err) {
      // console.log('setsessions', response.data, inicialDate, finalDate);
      toast.error('Erro ao carregar informações');
    }
  }

  const [permission, setPermission] = useState(true);

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'OperationAllSessions'}`
      );

      const { can_read } = response.data.OperationAllSessions;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Wrapper>
      <Header>
        <div>
          <h2>Acompanhar Comandas Delivery</h2>
          <p>
            Aqui você acompanha as comandas das mesas e consegue controlar as
            comandas do delivery e a(s) forma(s) de pagamento usada(s).
          </p>
          <span>Última Atualização {updateTime}</span>
          <UpdateButton onClick={handleUpdate} />
        </div>
      </Header>
      <Card>
        <Content>
          <BillsDelivery
            sessions={sessions}
            getSessions={getSessions}
            loading={loading}
            // payments={payments}
            // paymentMethods={paymentMethods}
            setSessions={setSessions}
            finalDate={finalDate}
            inicialDate={inicialDate}
            orderByTableNumber={orderByTableNumber}
            orderByTableDate={orderByTableDate}
            handleUpdate={handleUpdate}
            params={params}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </Content>
      </Card>
    </Wrapper>
  );
}
