import styled, { css } from 'styled-components';
import { lighten } from 'polished';

export const ButtonContainer = styled.button.attrs((props) => ({
  color: props.color,
  backgroundcolor: lighten(0.33, props.color),
}))`
line-height: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  background: ${(props) => props.color};
  margin: 5px 0 0;
  margin-left: 8px;
  color: ${(props) => (props.fontColor ? props.fontColor : '#fff')};
  height: 36px;
  padding: 0 15px;
  font-weight: 700;
  border: 1px solid ${(props) => (props.borderColor ? props.borderColor : '#fff')};
  border-radius: 4px;
  font-size: 14px;
  transition: background 0.2s;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
      background-color: #333;
    `}
  svg {
    margin-right: 6px;
  }
  span {
    display: flex;
    align-items: center;
    color: ${(props) => (props.fontColor ? props.fontColor : '#fff')};
  }
`;
