import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  overflow: hidden;
`;

export const Header = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;

  &:nth-child(2) {
    margin-top: 82px;

    padding-top: 18px;
  }

  div {
    display: flex;
    align-items: center;

    svg {
      margin-left: 8px;
    }
  }
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }

  svg {
    color: ${(props) => props.theme.colors.tertiary};
    margin-right: 10px;
  }
`;

export const ProductsTable = styled.table`
  width: 90%;
  min-width: 900px;
  margin: 10px auto 30px auto;
  border-collapse: initial;
  border-spacing: 0 20px;
  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  padding: 0 30px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #eee;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const CategoryHeader = styled.div`
  background: #33333320;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 20px;
  border-radius: 7px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 14px;
  padding-left: 14px;

  div {
    display: flex;
    align-items: center;

    svg {
      cursor: pointer;
    }
  }

  p {
    font-size: 20px;
    margin-top: 14px;
  }
`;

export const ProductImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin: 10px 5px 10px 0;
  border-radius: 5px;

  ${(props) =>
    props.available &&
    css`
      img {
        opacity: 0.7;
      }
    `}
`;

export const Delete = styled.button`
  border: none;
  background: none;
  margin-right: 8px;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div``;

export const ProductTableRow = styled.tr`
  ${(props) =>
    !props.available &&
    css`
      opacity: 0.5;
    `}
  ${(props) =>
    props.sold_off &&
    css`
      opacity: 0.5;
    `}
`;

export const ButttonArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 50px;
  margin-bottom: 20px;
`;

export const ReorderContainer = styled.div`
  width: 350px;
  margin-bottom: 16px;

  button {
    font-weight: bold;
    font-size: 14px;
    border-radius: 7px;
    border: none;
    color: #ff403d;
    padding: 10px 15px;
    background-color: transparent;
    text-decoration: underline;
  }
`;
