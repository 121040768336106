import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CategoryProductsMenu = styled.div`
  display: flex;
  margin-bottom: 10px;
  h2 {
    font-weight: bold;
  }
`;

export const SecondaryProductsMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      margin-right: 8px;
    }
  }
`;
