import styled from 'styled-components';

export const CategoryThumbnailLink = styled.a`
  width: 80px;
  height: 80px;

  border-radius: 50%;
  border: 2px solid rgba(251, 74, 32, 1);
`;

export const CategoryList = styled.li`
  width: 90px;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  margin-right: 15px;

  p {
    font-size: 12px;
    max-width: 85px;
    margin-top: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
