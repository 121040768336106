import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px;
`;

export const CanceledCard = styled.div`
  border-radius: 8px;
  position: relative;
`;

export const AlteredCard = styled.div`
  max-height: 397px;

  border-radius: 5px;
  background: #ccbbed;
  border: 2px solid #ccbbed;
  position: relative;
  color: #fff;
`;

export const Content = styled.div`
  width: 432px;
  display: flex;

  justify-content: flex-start;

  font-size: 18px;
  color: #3d3d3d;

  p {
    text-align: center;
  }
`;
export const HeaderTitle = styled.span`
  font-size: 30px;
  font-weight: bold;
`;
export const NavLinkSpan = styled.span`
  margin-right: 5px;
`;
export const StyledTable = styled.table`
  width: 100%;
  min-width: 400px;
  align-items: center;
  margin-top: 10px;
  border-spacing: 0 20px;

  th,
  td {
    padding: 10px;
  }

  thead {
    border-bottom: none;
    text-align: left;
    background: #efefef;
    color: #6c6c6c;

    tr,
    th {
      border-color: #fff;
      border-style: solid;
      border-width: 4px;
      border-left: none;
    }

    /* tr {
      th:last-child {
        text-align: right;
      }
    } */
  }
  tbody {
    td {
      border-bottom: 1px solid #dee2e6;
    }
    /* td:last-child {
      text-align: right;
    } */

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const CardHeader = styled.div`
  background: #EDEDED;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  border-radius: 8px 8px 0 0;
`;

export const Header = styled.div`
  margin: 20px 30px 10px 3%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  h2 {
    font-size: 30px;
    font-weight: bold;
  }
`;

export const Card = styled.div`
  width: 95%;
  min-height: 730px;
  margin: 0 auto;
  background: ${(props) => props.theme.colors.white};
  /* filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1)); */
  border-radius: 5px;
  padding: 20px;
  display: flex;
  margin-top: -5px;
`;

export const AddKitchenCard = styled.button`
  width: 200px;
  height: 200px;
  color: #2ec9b7;
  border: 2px solid #2ec9b7;
  border-radius: 5px;
  background: #fff;

  p {
    color: #2ec9b7;
    font-weight: bold;
    margin-top: 10px;
    font-size: 18px;
  }
`;

export const Footer = styled.footer`
  padding: 10px;
`

export const ChooseKitchenCard = styled.button`
  width: 200px;
  height: 200px;
  border: none;
  border-radius: 5px;
  background: #2ec9b7;
  margin-left: 10px;

  p {
    color: #fff;
    font-weight: bold;
    margin-top: 10px;
    font-size: 18px;
  }
`;

export const ScreenOne = styled.div`
  padding: 20px;
  transform: ${(props) =>
    props.active ? 'translateX(0%)' : 'translateX(-90%)'};

  transition: all 800ms ease-in-out;

  opacity: ${(props) => (props.active ? '1' : '0')};

  position: absolute;
  width: 97%;
`;

export const InputStyled = styled.input`
  width: 65px;
  height: 38px;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  text-align: center;
  margin-right: 8px;
`;

export const ScreenTwo = styled.div`
  min-height: 600px;
  overflow-y: auto;
  padding: 20px;
  transform: ${(props) =>
    props.active ? 'translateX(0%)' : 'translateX(100%)'};
  transition: all 800ms ease-in-out;

  opacity: ${(props) => (props.active ? '1' : '0')};

  position: absolute;
  width: 97%;

  padding-bottom: 100px;
`;

export const ButtonsArea = styled.div`
  display: flex;
  justify-content: space-between;

  width: 97%;
  position: fixed;
  bottom: 25px;
  padding-right: 25px;
  padding-left: 20px;
  padding-top: 20px;

  border-top: 1px solid #c4c4c4;
  background: #fff;
`;

export const BadgeCount = styled.div`
  width: 20px;
  height: 20px;

  background: #33333390;
  color: #fff;
  margin-left: 8px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
