import XLSX from 'xlsx-js-style';
import style from './sheetStyle';

import formatCompleteDate from '../formatCompleteDate';

function getAuditData(cashiers) {
  const rows = cashiers.map((cashier) => {
    const start = formatCompleteDate(cashier.opened_at);
    const end = formatCompleteDate(cashier.closed_at);

    const header1 = [
      `Caixa #${cashier.cashier_opening_id}`,
      `${start} até ${end}`,
      '',
      '',
      `Anotações: ${cashier.annotation}`,
    ];

    const header2 = [''];
    const line1 = ['Sistema'];
    const line2 = ['Caixa'];
    const line3 = ['Conferência'];

    cashier.cashier_audit_items.forEach((item) => {
      if (item.payment_method.name === 'Dinheiro') {
        header2.push('Fundo de Caixa');
      } else {
        header2.push(item.payment_method.name);
      }

      line1.push(parseFloat(item.value));
      line2.push(parseFloat(item.system_value));

      if (item.checked) {
        line3.push(parseFloat(item.audited_value));
      } else {
        line3.push('');
      }
    });

    header2.push('Dinheiro', 'Sangria', 'Total');

    line1.push(
      parseFloat(cashier?.subtotal?.system_value | 0),
      '',
      parseFloat(cashier.total_system_value) - parseFloat(cashier.subtotal.system_value || 0),
    );
    line2.push(
      parseFloat(cashier.subtotal.value),
      parseFloat(cashier.manual_withdrawal), 
      parseFloat(cashier.total_value) - parseFloat(cashier.subtotal.value || 0),
    );
    line3.push('', '', parseFloat(cashier.total_audited_value));

    return [
      header1,
      header2,
      line1,
      line2,
      line3,
      [],
    ];
  });

  return rows.flat();
}

export function generateSheet(cashiers) {
  const rows = getAuditData(cashiers);

  let columns = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  // Extended columns for restaurants with too many payment methods
  columns.push(
    ...columns.map((v) => `A${v}`)
  );

  const num_cols = rows.reduce((max, row) => {
    return max < row.length ? row.length : max;
  }, 0);

  rows.forEach((row, i) => {
    const data_row = rows[6 * Math.floor(i / 6) + 3];
    if (row.length < data_row.length) {
      const filler = new Array(data_row.length - row.length).fill('');
      row.push(...filler);
    }
  });

  const workbook = XLSX.utils.book_new();

  const worksheet = XLSX.utils.aoa_to_sheet(rows);

  // Every column with 15 chars of width
  worksheet['!cols'] = new Array(num_cols);
  worksheet['!cols'].fill({ wch: 16 });
  worksheet["!merges"] = [];

  const white = 'ffffff';
  const red1 = 'ff3838';
  const red2 = 'ffa6a6';
  const red3 = 'ff6d6d';
  const red4 = 'ff8b8b';
  const gold = 'e8f2a1';

  const num_lines = rows.length;

  for (let i = 1; i < num_lines; i ++) {
    let k = (i - 1) % 6;

    if (k < 2) {
      for (let j = 0; j < rows[i].length; j++) {
        const cell = worksheet[columns[j] + i];
        if (cell) {
          if (k === 0 && j > 0) {
            cell.s = style().Font(gold).Bg(red1).Get();
          } else if (k === 0 && j === 0) {
            cell.s = style().Font(white, 'center', 'center').Bg(red1).Get();
          } else {
            cell.s = style().Font(white).Bg(red1).Get();
          }
        }
      }

      if (k === 0) {
        worksheet["!merges"].push({
          s: { r: i - 1, c: 0 },
          e: { r: i, c: 0 },
        });
      }
    } else if (k < 5) {
      const cell = worksheet[columns[0] + i];
      if (cell) {
        cell.s = style().Font(white).Bg(red1).Get();
      }

      for (let j = 1; j < rows[i].length; j++) {
        const cell = worksheet[columns[j] + i];
        if (cell) {
          if (j % 2 !== 0) {
            cell.s = style().Number().Bg(red2);
          } else {
            cell.s = style().Number().Bg(red4);
          }

          if (k !== 4) {
            cell.s.Borders('lr', red3);
          } else {
            cell.s.Borders('lrb', red3);
          }

          cell.s = cell.s.Get();
        }
      }
    }
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Conferência de Caixa');

  return XLSX.write(workbook, { type: 'buffer' });
}
