import React, { useState, useCallback, useEffect, useRef } from 'react';
import FileSaver from 'file-saver';
import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';
import { format, getMonth } from 'date-fns';
import * as Yup from 'yup';

import { FiArrowDown } from 'react-icons/fi';
import { MdDelete, MdEdit } from 'react-icons/md';
import { AiOutlineEye } from 'react-icons/ai';
import { FaQuestionCircle } from 'react-icons/fa';
import { RiArrowLeftRightLine } from 'react-icons/ri';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';
import ReactSelect from 'react-select';
import { Button, DownloadButton, MonthCalendar } from 'ui-kit-takeat';

import ReactInputMask from 'react-input-mask';
import CheckboxGroup from 'react-checkbox-group/lib/CheckboxGroup';

import {
  Container,
  HistoryTable,
  Actions,
  Delete,
  InputAreaStyled,
  StyledTooltip,
} from './styles';

import Input from '~/components/Form/Input';
import Select from '~/components/Form/SelectInput';
import CheckboxInput from '~/components/Form/Checkbox';
import InputCurrency from '~/components/Form/InputCurrency';

import { generateSpecificInputsReportSheetXLSX } from '../../../services/generateSpecificInputSheet';
import formatCompleteDate from '~/services/formatCompleteDate';
import getValidationErrors from '~/services/getValidationErrors';
import api from '~/services/api';

function InputRow({
  input,
  handleDelete,
  categories,
  filterString,
  banks,
  suppliers,
  getBanks,
  getSuppliers,
  isInventory,
  inventory,
  setInventory,
  getInputs,
  inputsFiltered,
  setInputsFiltered,
  paymentMethods,
  user,
}) {
  const categoryFiltered = categories?.filter(
    (item) => item.value === input?.cash_flow_category_subcategory_id
  );
  const formRefBank = useRef(null);
  const formRefSupplier = useRef(null);

  //orderDate vai ser usado para ordernar o horario no modal de historico
  //true vai ser horario mais recente primeiro
  const [orderDate, setOrderDate] = useState(true);

  const selectCategory = categoryFiltered
    ? {
        value: categoryFiltered.length > 0 ? categoryFiltered[0].value : '',
        label: categoryFiltered.length > 0 ? categoryFiltered[0].label : '',
      }
    : '';
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  const [monthYearDate, setMonthYearDate] = useState(null);
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setTotalToSend(0);
    setInputZero(0);
    setModal(!modal);
  };

  const [modalEye, setModalEye] = useState(false);

  const [modalUnit, setModalUnit] = useState(false);
  const toggleUnit = () => setModalUnit(!modalUnit);

  const [newUnitaryPrice, setNewUnitaryPrice] = useState(null);
  const [modalEditUnitaryPrice, setModalEditUnitaryPrice] = useState(false);
  const toggleEditUnitaryPrice = () => {
    setNewUnitaryPrice(null);
    setModalEditUnitaryPrice(!modalEditUnitaryPrice);
  };

  const [inputQuant, setInputQuant] = useState(parseFloat(input.quantidade));
  const [inputZero, setInputZero] = useState(0);
  const [inputInicial, setInputInicial] = useState(
    parseFloat(input.quantidade)
  );
  const [isAverage, setIsAverage] = useState(
    input.quantidade <= 0 ? [false] : [true]
  );
  const [tooltip, setTooltip] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [valueToSend, setValueToSend] = useState(parseFloat(input.quantidade));
  const [totalToSend, setTotalToSend] = useState(0);

  const [changeAllUnit, setChangeAllUnit] = useState(true);
  const [newUnit, setNewUnit] = useState('');
  const [valueNewUnit, setValueNewUnit] = useState(1);

  const [historyInput, setHistoryInput] = useState(null);

  const [createCashFlow, setCreateCashFlow] = useState(true);

  function handleCreateCashFlow() {
    setCreateCashFlow(!createCashFlow);
  }

  const justificatives = [
    { value: 0, label: 'Perda por validade/vencimento' },
    { value: 1, label: 'Desperdício de produção' },
    { value: 2, label: 'Item Danificado' },
    { value: 3, label: 'Devolução de mercadoria' },
    { value: -1, label: 'Outros' },
  ];

  const [selectedJustificative, setSelectedJustificative] = useState(null);

  useEffect(() => {
    if (parseFloat(valueToSend) > inputInicial) {
      setInputDisabled(false);
    } else {
      setInputDisabled(true);
    }
  }, [valueToSend, inputInicial]);

  const [loading, setLoading] = useState(false);

  const handleUpdateInput = useCallback(
    async (data) => {
      setLoading(true);
      try {
        let justificative = data.inputed_justificative || null;

        if (selectedJustificative?.value !== -1) {
          justificative = selectedJustificative?.label;
        }

        const new_date = data.due_date
          ? `${data.due_date}T03:00:00.000Z`
          : null;
        const new_paid = `${data.paid_at}T03:00:00.000Z`;

        const handleData = {
          id: input.id,
          quantidade: valueToSend,
          name: data.name,
          total: data.total_to_send.replace(',', '.') || 0,
          cash_flow_category_subcategory_id: data.category || null,
          minimum_stock: data.minimum_stock,
          ideal_stock: data.ideal_stock,
          provider_id: data.provider_id || null,
          bank_account_id: data.bank_account_id || null,
          due_date: new_date,
          paid: data.paid,
          paid_at: data.paid ? new_paid : null,
          justificative,
          is_average: !isAverage[0],
          create_cash_flow: inputZero >= 0 ? data.create_cashflow : false,
          cash_flow_payment_method_id: data.cash_flow_payment_method_id,
        };

        if (!data.quantidade) {
          toast.error('Coloque a quantidade do insumo.');
        } else if (
          valueToSend > parseFloat(inputInicial) &&
          !data.total_to_send
        ) {
          toast.error('Coloque o valor gasto do insumo.');
        } else if (isAverage.length < 1) {
          toast.error('Selecione um modo de calcular custo unitário.');
        } else if (valueToSend > parseFloat(inputInicial) && !data.category) {
          toast.error('Selecione um centro de custo.');
        } else {
          const response = await api.put(`/restaurants/inputs`, handleData);
          // document.getElementById('update_button').click();
          // getInputs();
          setInputInicial(valueToSend);
          setInputZero(0);
          setInputQuant(valueToSend);
          setCreateCashFlow(true);
          setSelectedJustificative(null);
          setModal(false);

          if (inputsFiltered) {
            const newInputs = [...inputsFiltered];
            const inputFind = newInputs.findIndex(
              (item) => item.id === input.id
            );

            if (inputFind !== -1) {
              newInputs[inputFind] = { ...response.data };

              setInputsFiltered(newInputs);
            }
          }

          toast.success('Insumo atualizado com sucesso!');
        }
      } catch (error) {
        console.log(error);
        toast.error('Erro ao atualizar insumo.');
      }
      setLoading(false);
    },
    [valueToSend, selectedJustificative, isAverage, inputInicial, input.id]
  );

  const [paidCheck, setPaidCheck] = useState(false);

  function handleSetPaidCheck() {
    setPaidCheck(!paidCheck);
  }

  const handleChangeUnit = useCallback(async () => {
    try {
      const handleData = {
        input_id: input.id,
        value: valueNewUnit,
        unit_change: newUnit,
        // unit_inicial: input.unidade,
        // change_all: changeAllUnit
      };

      if (!valueNewUnit || parseFloat(valueNewUnit) === 0) {
        toast.error('Valor de conversão deve ser válido e diferente de zero.');
      } else if (newUnit === '') {
        toast.error('Informe a nova unidade.');
      } else {
        const response = await api.put(`/restaurants/change-unit`, handleData);
        // document.getElementById('update_button').click();
        // getInputs();

        if (inputsFiltered) {
          const newInputs = [...inputsFiltered];
          const inputFind = newInputs.findIndex((item) => item.id === input.id);

          if (inputFind !== -1) {
            newInputs[inputFind] = { ...response.data };

            setInputsFiltered(newInputs);
          }
        }
        setValueNewUnit(1);
        setChangeAllUnit(true);
        setNewUnit('');
        setModalUnit(false);
        toast.success('Unidades convertidas com sucesso!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Erro ao atualizar unidades.');
    }
  }, [newUnit, valueNewUnit, changeAllUnit, input.id]);

  const handleChangeUnitaryPrice = useCallback(
    async (data) => {
      try {
        if (!newUnitaryPrice) {
          toast.error('Informe o novo custo unitário');
          return;
        }

        const handleData = {
          new_unitary_price: newUnitaryPrice,
        };

        await api.put(
          `/restaurants/inputs/unitary-price/${input.id}`,
          handleData
        );
        setNewUnitaryPrice(null);
        setModalEditUnitaryPrice(false);
        getInputs();
        toast.success('Preço unitário atualizado');
      } catch (error) {
        console.log(error);
        toast.error('Erro ao atualizar preço unitário');
      }
    },
    [input.id, newUnitaryPrice]
  );

  const toggleEye = useCallback(() => {
    setModalEye(!modalEye);
    setMonthYearDate(null);
  }, [modalEye]);

  // create banks

  const [isModalCreateBankOpen, setIsModalCreateBankOpen] = useState(false);

  const toggleModalCreateBank = useCallback(() => {
    setIsModalCreateBankOpen(!isModalCreateBankOpen);
  }, [isModalCreateBankOpen]);

  const handleBankSubmit = useCallback(
    async (data) => {
      try {
        if (formRefBank?.current) {
          formRefBank.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/restaurants/cash-flows/bank-accounts', {
          name: data.name,
        });

        toast.success('Conta cadastrada com sucesso!');

        getBanks();
        toggleModalCreateBank();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRefBank?.current) {
            formRefBank.current.setErrors(errors);
          }
        } else {
          // console.tron.log(error);
          toast.error(`Erro ao cadastrar conta!`);
        }
      }
    },
    [getBanks, toggleModalCreateBank]
  );

  // create suplliers

  const [isModalCreateSuppliersOpen, setIsModalCreatesuppliersOpen] =
    useState(false);

  const toggleModalCreateSuplliers = useCallback(() => {
    setIsModalCreatesuppliersOpen(!isModalCreateSuppliersOpen);
  }, [isModalCreateSuppliersOpen]);

  const handleSupplierSubmit = useCallback(
    async (data) => {
      try {
        if (formRefSupplier?.current) {
          formRefSupplier.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          cnpj: Yup.string()
            .required('CNPJ/ CPF obrigatório')
            .trim()
            .matches('^[0-9]+$', 'Apenas números, sem espaço'),
          phone: Yup.string().required('Telefone obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/restaurants/cash-flows/providers', {
          name: data.name,
          phone: data.phone,
          cnpj: data.cnpj,
        });

        toast.success('Fornecedor cadastrada com sucesso!');

        getSuppliers();
        toggleModalCreateSuplliers();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRefSupplier?.current) {
            formRefSupplier.current.setErrors(errors);
          }
        } else {
          toast.error(`Erro ao cadastrar fornecedor!`);
        }
      }
    },
    [getSuppliers, toggleModalCreateSuplliers]
  );

  const inputInitialData = {
    ...input,
    quantidade: parseFloat(input?.quantidade).toFixed(4),
  };

  const getInputHistory = useCallback(
    (id, start) => {
      api
        .get('/restaurants/history-inputs', {
          params: {
            input_id: id || 0,
            start_date: start,
          },
        })
        .then((response) => {
          const history = response.data;
          setHistoryInput(
            history.sort((a, b) => {
              const d1 = new Date(a.createdAt).getTime();
              const d2 = new Date(b.createdAt).getTime();

              return orderDate ? d2 - d1 : d1 - d2;
            })
          );
        })
        .catch((e) => toast.error('Não foi possível consultar o histórico.'));
    },
    [orderDate]
  );

  useEffect(() => {
    if (modalEye && monthYearDate) {
      getInputHistory(input?.id, monthYearDate.start);
    }
  }, [modalEye, monthYearDate]);

  function currencyFormatter(value) {
    if (!Number(value)) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  }

  const handleDownloadInputs = async () => {
    try {
      const sheet = await generateSpecificInputsReportSheetXLSX(
        historyInput,
        input.unidade
      );
      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        `Movimentações Insumo (${input.name}) - ${
          months[getMonth(monthYearDate.start)]
        }.xlsx`
      );
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };

  function sortDate() {
    let history = historyInput ? historyInput.slice() : [];

    setHistoryInput(
      history.sort((a, b) => {
        const d1 = new Date(a.createdAt).getTime();
        const d2 = new Date(b.createdAt).getTime();

        return !orderDate ? d2 - d1 : d1 - d2;
      })
    );

    setOrderDate(!orderDate);
  }

  return (
    <>
      <tr>
        <td>
          {input.name} ({input.unidade})
        </td>

        {isInventory ? (
          <td>
            <input
              defaultValue={parseFloat(input.quantidade)}
              value={inventory[input.id]}
              onChange={(e) =>
                setInventory({ ...inventory, [input.id]: e.target.value })
              }
              style={{ width: '75px', textAlign: 'center' }}
            />
          </td>
        ) : parseFloat(input.quantidade) > 0 ? (
          <td>
            <strong style={{ color: 'green' }}>
              {parseFloat(input.quantidade).toFixed(4).replace('.', ',')}{' '}
              {input.unidade.toUpperCase()}
            </strong>
            {user?.allow_inputs && (
              <button
                style={{
                  border: 'none',
                  borderRadius: '50%',
                  width: 20,
                  height: 20,
                  background: 'orange',
                  marginLeft: 5,
                }}
                onClick={() => toggleUnit()}
              >
                <RiArrowLeftRightLine size={10} color="white" />
              </button>
            )}
          </td>
        ) : (
          <td>
            <strong style={{ color: 'red' }}>
              {parseFloat(input.quantidade).toFixed(4).replace('.', ',')}{' '}
              {input.unidade.toUpperCase()}
            </strong>
            {user?.allow_inputs && (
              <button
                style={{
                  border: 'none',
                  borderRadius: '50%',
                  width: 20,
                  height: 20,
                  background: 'orange',
                  marginLeft: 5,
                }}
                onClick={() => toggleUnit()}
              >
                <RiArrowLeftRightLine size={10} color="white" />
              </button>
            )}
          </td>
        )}

        <td>
          R$
          {parseFloat(input.unitary_price).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
          })}
          <button
            style={{
              border: 'none',
              width: 20,
              height: 20,
              background: 'transparent',
              marginLeft: 2,
            }}
            onClick={() => toggleEditUnitaryPrice()}
          >
            <MdEdit size={15} color="#17a2b8" />
          </button>
        </td>

        <td>
          {parseFloat(input.quantidade) >= 0
            ? `R$${parseFloat(input.total_value).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
              })}`
            : 'N/A'}
        </td>

        <td>{input.category_subcategory}</td>

        <td style={{ width: 100 }}>
          <Actions>
            <Delete onClick={toggleEye}>
              <AiOutlineEye size={20} color="blue" />
            </Delete>
            {!input.is_master && (
              <>
                <Delete onClick={toggle} disabled={input.is_master}>
                  <MdEdit size={20} color="#17a2b8" />
                </Delete>
                {!input?.is_multistore_child && (
                  <Delete
                    onClick={() => handleDelete(input.id, filterString)}
                    disabled={input.is_master}
                  >
                    <MdDelete size={20} />
                  </Delete>
                )}
              </>
            )}
          </Actions>

          <Modal
            isOpen={isModalCreateSuppliersOpen}
            toggle={toggleModalCreateSuplliers}
          >
            <ModalHeader> Criar Fornecedor</ModalHeader>
            <Form onSubmit={handleSupplierSubmit} ref={formRefSupplier}>
              <ModalBody>
                <Input label="Nome do fornecedor" name="name" />
                <Input label="CNPJ" name="cnpj" />
                <ReactInputMask mask="(99) 99999-9999">
                  <Input
                    label="Telefone"
                    name="phone"
                    maskChar=""
                    placeholder="(xx) xxxxx-xxxx"
                  />
                </ReactInputMask>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={toggleModalCreateSuplliers}
                  title="Cancelar"
                  type="button"
                />
                <Button
                  buttonColor="#2EC9B7"
                  type="submit"
                  title="Salvar"
                  onClick={() => {}}
                />
              </ModalFooter>
            </Form>
          </Modal>

          <Modal isOpen={isModalCreateBankOpen} toggle={toggleModalCreateBank}>
            <ModalHeader> Criar Conta</ModalHeader>
            <Form onSubmit={handleBankSubmit} ref={formRefBank}>
              <ModalBody>
                <Input label="Nome do banco" name="name" />
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={toggleModalCreateBank}
                  title="Cancelar"
                  type="button"
                />
                <Button
                  buttonColor="#2EC9B7"
                  type="submit"
                  title="Salvar"
                  onClick={() => {}}
                />
              </ModalFooter>
            </Form>
          </Modal>

          <Modal isOpen={modal} toggle={toggle}>
            <Form onSubmit={handleUpdateInput} initialData={inputInitialData}>
              <ModalHeader>Editar Insumos</ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col>
                      <Input
                        label="Nome do Insumo"
                        name="name"
                        type="text"
                        disabled={input?.is_multistore_child || false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <InputAreaStyled>
                        <b>Qtd. Disponível</b>
                        <Input
                          name="quantidade"
                          type="number"
                          step="0.001"
                          disabled
                        />
                      </InputAreaStyled>
                    </Col>
                    <Col md="4">
                      <InputAreaStyled>
                        <b>Custo Unitário Atual</b>
                        <Input
                          name="unitary_price"
                          type="number"
                          step=".01"
                          disabled
                        />
                      </InputAreaStyled>
                    </Col>
                    <Col md="4">
                      <InputAreaStyled>
                        <b>Valor em Estoque</b>
                        <Input
                          name="total_value"
                          type="number"
                          step=".001"
                          disabled
                        />
                      </InputAreaStyled>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4" style={{ marginLeft: 10 }}>
                      <Row>
                        <Col md="1">
                          <button
                            type="button"
                            style={{
                              width: 32,
                              height: 32,
                              position: 'absolute',
                              bottom: 0,
                              left: 10,
                              background: '#FF3939',
                              borderRadius: '50%',
                              border: 'none',
                              marginBottom: 15,
                            }}
                            onClick={() => {
                              setInputQuant(parseFloat(inputQuant) - 1);
                              setInputZero(parseFloat(inputZero) - 1);
                              setValueToSend(
                                inputInicial + parseFloat(inputZero) - 1
                              );
                            }}
                          >
                            -
                          </button>
                        </Col>
                        <Col md="9">
                          <Input
                            label="Quantidade"
                            name="quantidade"
                            type="number"
                            step="0.01"
                            value={inputZero}
                            onChange={(e) => {
                              setInputZero(e.target.value);
                              setValueToSend(
                                inputInicial + parseFloat(e.target.value)
                              );
                            }}
                          />
                        </Col>

                        <Col md="1">
                          <button
                            type="button"
                            style={{
                              width: 32,
                              height: 32,
                              position: 'absolute',
                              bottom: 0,
                              left: 132,
                              background: '#3BD2C1',
                              borderRadius: '50%',
                              border: 'none',
                              marginBottom: 15,
                            }}
                            onClick={() => {
                              setInputQuant(parseFloat(inputQuant) + 1);
                              setInputZero(parseFloat(inputZero) + 1);
                              setValueToSend(
                                inputInicial + parseFloat(inputZero) + 1
                              );
                            }}
                          >
                            +
                          </button>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="4" style={{ marginLeft: 15 }}>
                      <Input
                        label="Valor Gasto"
                        name="total_to_send"
                        type="number"
                        step="0.01"
                        value={totalToSend}
                        onChange={(e) => setTotalToSend(e.target.value)}
                        disabled={inputDisabled}
                        id="input"
                      />
                    </Col>
                    <Col md="3">
                      <Row>
                        <p
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: 10,
                          }}
                        >
                          <strong>Nova Qtd.</strong>{' '}
                          <span>
                            {isNaN(
                              (inputInicial + parseFloat(inputZero)).toFixed(4)
                            )
                              ? inputInicial.toFixed(4)
                              : (inputInicial + parseFloat(inputZero)).toFixed(
                                  4
                                )}
                          </span>
                        </p>
                      </Row>
                    </Col>
                  </Row>
                  {inputInicial + parseFloat(inputZero) > 0 &&
                    inputZero >= 0 && (
                      <Row>
                        <p style={{ fontWeight: 'bold' }}>
                          Qual custo unitário usar?{' '}
                          <FaQuestionCircle
                            color="orange"
                            onMouseEnter={() => setTooltip(true)}
                            onMouseLeave={() => setTooltip(false)}
                          />
                        </p>
                        <CheckboxGroup
                          name="average"
                          value={isAverage}
                          onChange={(e) => {
                            setIsAverage((state) => {
                              if (e.length < 1) {
                                return [];
                              }
                              return e.filter((i) => !state.includes(i));
                            });
                          }}
                        >
                          {(Checkbox) => (
                            <div
                              style={{
                                display: 'flex',
                              }}
                            >
                              <label
                                style={{
                                  marginRight: '20px',
                                }}
                              >
                                <Checkbox value={true} /> Novo (R$
                                {(inputZero === 0 ||
                                isNaN(
                                  parseFloat(totalToSend) /
                                    parseFloat(inputZero)
                                )
                                  ? 0
                                  : parseFloat(totalToSend) /
                                    parseFloat(inputZero)
                                ).toFixed(2)}
                                )
                              </label>
                              {input.quantidade > 0 && (
                                <label
                                  title={
                                    input.quantidade <= 0
                                      ? 'A quantidade atual deste insumo no estoque é menor que zero, portanto não podemos calcular uma média ponderada.'
                                      : undefined
                                  }
                                >
                                  <Checkbox
                                    value={false}
                                    disabled={input.quantidade <= 0}
                                  />{' '}
                                  Média ponderada (R$
                                  {(
                                    0 +
                                    (parseFloat(totalToSend) +
                                      parseFloat(input.total_value)) /
                                      (parseFloat(inputZero) +
                                        parseFloat(input.quantidade))
                                  ).toFixed(2)}
                                  )
                                </label>
                              )}
                            </div>
                          )}
                        </CheckboxGroup>
                      </Row>
                    )}
                  {inputZero && inputZero < 0 ? (
                    <Row>
                      <Col>
                        <ReactSelect
                          placeholder="Selecione a justificativa"
                          options={justificatives}
                          onChange={(e) => {
                            setSelectedJustificative(e);
                          }}
                          value={selectedJustificative}
                          name="justificative"
                        />
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}

                  {selectedJustificative && selectedJustificative.value < 0 ? (
                    <Row>
                      <Col>
                        <Input
                          placeholder="Escreva a justificativa"
                          name="inputed_justificative"
                          type="text"
                          id="input"
                        />
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  <Row>
                    <Col md="6">
                      <Input
                        label="Estoque Mínimo"
                        name="minimum_stock"
                        type="number"
                        step="0.001"
                      />
                    </Col>
                    <Col md="6">
                      <Input
                        label="Estoque Ideal"
                        name="ideal_stock"
                        type="number"
                        step="0.001"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Select
                        label="Associar Centro de Custo"
                        name="category"
                        options={categories}
                        placeholder="Selecione a categoria"
                        defaultValue={selectCategory}
                        disabled={input?.is_multistore_child || false}
                        // onChange={(e) => setInputSelectedId(e.value)}
                      />
                    </Col>
                  </Row>

                  {inputZero >= 0 && (
                    <>
                      <p style={{ marginTop: 10 }}>
                        Informações Adicionais{' '}
                        <FaQuestionCircle
                          color="orange"
                          title="Complemente as informações do seu lançamento para uma melhor gestão de estoque."
                        />
                      </p>

                      <Row>
                        <div
                          style={{
                            display: 'flex',
                          }}
                        >
                          <label>
                            <CheckboxInput
                              name="create_cashflow"
                              label="Vincular esta movimentação ao financeiro"
                              defaultChecked
                              onChange={() => {
                                handleCreateCashFlow();
                              }}
                            />
                          </label>
                        </div>
                      </Row>
                    </>
                  )}

                  {createCashFlow && inputZero >= 0 && (
                    <div>
                      <Row>
                        <Col>
                          <Row>
                            <Col md="10">
                              <Select
                                label="Fornecedor"
                                name="provider_id"
                                options={suppliers}
                                placeholder="Fornecedor"
                                // onChange={(e) => setInputSelectedId(e.value)}
                              />
                            </Col>
                            <Col md="1">
                              <button
                                type="button"
                                style={{
                                  width: 32,
                                  height: 32,
                                  position: 'absolute',
                                  bottom: 0,
                                  left: 0,
                                  background: '#3BD2C1',
                                  borderRadius: '50%',
                                  border: 'none',
                                  marginBottom: 15,
                                }}
                                title="Cadastrar Fornecedor"
                                onClick={toggleModalCreateSuplliers}
                              >
                                +
                              </button>
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Col md="10">
                              <Select
                                label="Conta"
                                name="bank_account_id"
                                options={banks}
                                placeholder="Conta"
                                // onChange={(e) => setInputSelectedId(e.value)}
                              />
                            </Col>
                            <Col md="1">
                              <button
                                type="button"
                                style={{
                                  width: 32,
                                  height: 32,
                                  position: 'absolute',
                                  bottom: 0,
                                  left: 0,
                                  background: '#3BD2C1',
                                  borderRadius: '50%',
                                  border: 'none',
                                  marginBottom: 15,
                                }}
                                onClick={toggleModalCreateBank}
                                title="Cadastrar Conta Bancária"
                              >
                                +
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Input
                          id="date-local"
                          label="Vencimento"
                          type="date"
                          name="due_date"
                          defaultValue={format(new Date(), 'yyyy-MM-dd')}
                          className="data"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Row>
                      <Row>
                        <Col>
                          <CheckboxInput
                            name="paid"
                            label="Pago"
                            checked={paidCheck}
                            onChange={handleSetPaidCheck}
                          />
                        </Col>{' '}
                      </Row>

                      {paidCheck && (
                        <>
                          <Row>
                            <Col>
                              <Input
                                id="date-local"
                                label="Pago em:"
                                type="date"
                                name="paid_at"
                                defaultValue={format(new Date(), 'yyyy-MM-dd')}
                                className="data"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Select
                              placeholder="Método de pagamento"
                              options={paymentMethods}
                              name="cash_flow_payment_method_id"
                            />
                          </Row>
                        </>
                      )}
                    </div>
                  )}
                </Container>

                <StyledTooltip show={tooltip}>
                  <p>
                    <span style={{ fontWeight: 'bold' }}>Média ponderada:</span>{' '}
                    Faz uma média ponderada entre seu estoque e o insumo que
                    você está dando entrada.
                  </p>
                  <p>
                    <span style={{ fontWeight: 'bold' }}>Atual:</span> Atualiza
                    o custo do estoque com base apenas no insumo que você está
                    dando entrada.
                  </p>
                </StyledTooltip>
              </ModalBody>
              <ModalFooter>
                <Button onClick={toggle} title="Cancelar" type="button" />
                <Button
                  buttonColor="#2EC9B7"
                  type="submit"
                  title="Salvar"
                  onClick={() => {}}
                  disabled={loading}
                />
              </ModalFooter>
            </Form>
          </Modal>

          <Modal isOpen={modalEye} toggle={toggleEye} size="xl">
            {/* <ModalHeader>Histórico do Insumo</ModalHeader> */}
            <ModalBody>
              <Container>
                <Row style={{ alignItems: 'center' }}>
                  <Col md="9">
                    Histórico do Insumo: <b>{input.name}</b>
                  </Col>

                  <Col md="3">
                    <div
                      style={{
                        marginBottom: 15,
                        float: 'right',
                        display: 'flex',
                      }}
                    >
                      <div
                        style={{ display: 'flex', margin: '15px 8px 0px 5px' }}
                      >
                        <DownloadButton
                          onClick={() => {
                            if (historyInput.length > 0) {
                              handleDownloadInputs();
                            } else {
                              toast.error(
                                'Não há movimentações nesse período.'
                              );
                            }
                          }}
                        />
                      </div>
                      <MonthCalendar onDateSelected={setMonthYearDate} />
                    </div>
                  </Col>
                </Row>

                <HistoryTable>
                  <thead>
                    <tr>
                      <th
                        style={{ cursor: 'pointer' }}
                        onClick={() => sortDate()}
                      >
                        <FiArrowDown />
                        Data / Hora
                      </th>
                      <th>Saldo Inicial ({input.unidade})</th>
                      <th>Variação</th>
                      <th>Saldo Final ({input.unidade})</th>
                      <th>Valor</th>
                      <th>Custo unitário</th>
                      <th>Operação</th>
                      <th>Usuário</th>
                      <th>Justificativa</th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyInput?.map((history) =>
                      history.type === '+' ? (
                        <tr style={{ background: '#D7EED2', color: '#1E3422' }}>
                          <td>{formatCompleteDate(history.createdAt)}</td>
                          <td>
                            {history.balance && !isNaN(history.balance)
                              ? `${(
                                  parseFloat(history.balance) -
                                  parseFloat(history.quantidade)
                                ).toLocaleString('pt-BR', {
                                  minimumFractionDigits: 4,
                                  maximumFractionDigits: 4,
                                })}`
                              : '-'}
                          </td>
                          <td>
                            {history.type}{' '}
                            {parseFloat(history.quantidade)
                              .toFixed(4)
                              .replace('.', ',')}
                          </td>
                          <td>
                            {history.balance && !isNaN(history.balance)
                              ? `${parseFloat(history.balance).toLocaleString(
                                  'pt-BR',
                                  {
                                    minimumFractionDigits: 4,
                                    maximumFractionDigits: 4,
                                  }
                                )}`
                              : '-'}
                          </td>
                          <td>
                            {history.total && !isNaN(history.total)
                              ? `R$${parseFloat(history.total).toLocaleString(
                                  'pt-BR',
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}`
                              : history.unitary_price &&
                                !isNaN(history.unitary_price) &&
                                history.quantidade &&
                                !isNaN(history.quantidade) &&
                                parseFloat(history.quantidade) > 0 &&
                                `R$${(
                                  parseFloat(history.quantidade) *
                                  parseFloat(history.unitary_price)
                                ).toLocaleString('pt-BR', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`}
                          </td>

                          <td>
                            {history.unitary_price &&
                            !isNaN(history.unitary_price)
                              ? `R$${parseFloat(
                                  history.unitary_price
                                ).toLocaleString('pt-BR', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`
                              : '-'}
                          </td>

                          <td>
                            {history.product && `[P] ${history.product.name}`}
                            {history.complement &&
                              `[C] ${history.complement.name}`}
                            {history.intermediary &&
                              `[PI] ${history.intermediary.name}`}
                            {history.intermediary &&
                              history.operation === 'intermediary_deleted' &&
                              ` - Deletado`}
                            {!history.complement &&
                              !history.product &&
                              !history.intermediary &&
                              (history.operation !== 'nfe'
                                ? 'Editado Manualmente'
                                : 'Entrada de Nota Fiscal')}
                          </td>
                          <td>{history.user ? history.user.name : '-'}</td>
                          <td>
                            {history.justificative
                              ? history.justificative
                              : !history.nfe && '-'}
                            {history.nfe &&
                              `Fornecedor: ${history.nfe?.provider || '-'}`}
                          </td>
                        </tr>
                      ) : (
                        <tr style={{ background: '#FBD1C5', color: '#590D12' }}>
                          <td>{formatCompleteDate(history.createdAt)}</td>
                          <td>
                            {history.balance && !isNaN(history.balance)
                              ? `${(
                                  parseFloat(history.balance) +
                                  parseFloat(history.quantidade)
                                ).toLocaleString('pt-BR', {
                                  minimumFractionDigits: 4,
                                  maximumFractionDigits: 4,
                                })}`
                              : '-'}
                          </td>
                          <td>
                            {history.type}{' '}
                            {parseFloat(history.quantidade)
                              .toFixed(4)
                              .replace('.', ',')}
                          </td>
                          <td>
                            {history.balance && !isNaN(history.balance)
                              ? `${parseFloat(history.balance).toLocaleString(
                                  'pt-BR',
                                  {
                                    minimumFractionDigits: 4,
                                    maximumFractionDigits: 4,
                                  }
                                )}`
                              : '-'}
                          </td>
                          <td>
                            {history.total && !isNaN(history.total)
                              ? `R$${parseFloat(history.total).toLocaleString(
                                  'pt-BR',
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}`
                              : '-'}
                          </td>

                          <td>
                            {history.unitary_price &&
                            !isNaN(history.unitary_price)
                              ? `R$${parseFloat(
                                  history.unitary_price
                                ).toLocaleString('pt-BR', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`
                              : '-'}
                          </td>

                          <td>
                            {history.product && `[P] ${history.product.name}`}
                            {history.complement &&
                              `[C] ${history.complement.name}`}
                            {history.intermediary &&
                              `[PI] ${history.intermediary.name}`}
                            {history.intermediary &&
                              history.operation === 'intermediary_deleted' &&
                              ` - Deletado`}
                            {!history.complement &&
                              !history.product &&
                              !history.intermediary &&
                              (history.operation !== 'nfe'
                                ? 'Editado Manualmente'
                                : 'Entrada de Nota Fiscal')}
                          </td>

                          <td>{history.user ? history.user.name : '-'}</td>

                          <td>
                            {history.justificative
                              ? history.justificative
                              : !history.nfe && '-'}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </HistoryTable>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button onClick={toggleEye} title="Fechar" type="button" />
            </ModalFooter>
          </Modal>

          <Modal isOpen={modalUnit} toggle={toggleUnit}>
            <ModalHeader>Converter Unidade</ModalHeader>
            <ModalBody>
              <Container>
                <Form>
                  <Row>
                    <Col md="4" style={{ margin: 'auto' }}>
                      1 {input.unidade} equivale a:
                    </Col>
                    <Col md="4">
                      <Input
                        type="number"
                        step="0.1"
                        label="Valor"
                        name="value_change"
                        id="value_change"
                        value={valueNewUnit}
                        placeholder="Valor de conversão"
                        onChange={(e) => setValueNewUnit(e.target.value)}
                      />
                    </Col>
                    <Col md="4">
                      <Input
                        type="text"
                        name="unit_change"
                        placeholder="ex: KG"
                        label="Unidade"
                        value={newUnit}
                        onChange={(e) => setNewUnit(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <strong>Atualizado: </strong> {input.quantidade}{' '}
                      {input.unidade} {'=>'}{' '}
                      {(parseFloat(input.quantidade) * valueNewUnit)
                        .toFixed(3)
                        .replace('.', ',')}{' '}
                      {newUnit || 'nova unidade'}
                    </Col>
                  </Row>
                </Form>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button onClick={toggleUnit} title="Fechar" type="button" />
              <Button
                buttonColor="#2EC9B7"
                type="submit"
                title="Converter"
                onClick={() => handleChangeUnit()}
              />
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={modalEditUnitaryPrice}
            toggle={toggleEditUnitaryPrice}
            size="sm"
          >
            <ModalBody>
              <Container>
                <Form onSubmit={handleChangeUnitaryPrice}>
                  <Row style={{ marginTop: 0 }}>
                    <Col>
                      <InputCurrency
                        name="new_unitary_price"
                        label="Novo custo unitário"
                        type="text"
                        prefix="R$"
                        placeholder="R$"
                        format={currencyFormatter}
                        onValueChange={(e) => {
                          setNewUnitaryPrice(
                            e.floatValue > 0 ? e.floatValue / 100 : null
                          );
                        }}
                      />
                    </Col>
                  </Row>
                </Form>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={toggleEditUnitaryPrice}
                title="Fechar"
                type="button"
              />
              <Button
                buttonColor="#2EC9B7"
                type="button"
                title="Salvar"
                onClick={handleChangeUnitaryPrice}
              />
            </ModalFooter>
          </Modal>
        </td>
      </tr>
    </>
  );
}

export default InputRow;
