import React, {
  useContext,
  createContext,
  useMemo,
  useCallback,
  useState,
} from 'react';
import toast from 'react-hot-toast';

import api from '~/services/api';

const BillsContext = createContext();

function BillsProvider({ children }) {
  const [bills, setBills] = useState([]);
  const [closedBills, setClosedBills] = useState([]);
  const [pendingBills, setPendingBills] = useState([]);
  const [printBills, setPrintBills] = useState([]);
  const [loadingBills, setLoadingBills] = useState(false);
  const [clientSession, setClientSession] = useState(null); //client da session, buscar por aqui
  const [peopleAtTable, setPeopleAtTable] = useState(null);

  const getTableBills = useCallback(async (id) => {
    if (!id) {
      setPendingBills([]);
      setClosedBills([]);
      setPrintBills([]);

      return 0;
    }

    try {
      setLoadingBills(true);
      setPendingBills([]);
      setClosedBills([]);
      setPrintBills([]);
      setClientSession(null);

      const response = await api.get(`restaurants/table-sessions-bills/${id}`);

      const pending = response.data.bills.filter(
        (bill) => bill.status !== 'finished'
      );

      setPendingBills(pending);

      const closed = response.data.bills.filter(
        (bill) => bill.status === 'finished'
      );
      setClosedBills(closed);

      setPrintBills(response.data.print_bills);
      setClientSession(response.data?.session?.client || null);
      setPeopleAtTable(response?.data?.session?.people_at_table || null);

      setLoadingBills(false);
    } catch (error) {
      console.log(error);
      setLoadingBills(false);
    }
  }, []);

  const updatePeopleAtTable = useCallback(async ({ session_id, people }) => {
    try {
      await api.put(`restaurants/session/people`, {
        session_id,
        people,
      });

      toast.success('Número de clientes atualizado.');
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.error);
    }
  }, []);

  const value = useMemo(
    () => ({
      closedBills,
      pendingBills,
      bills,
      getTableBills,
      printBills,
      loadingBills,
      clientSession,
      peopleAtTable,
      updatePeopleAtTable,
    }),
    [
      printBills,
      closedBills,
      pendingBills,
      getTableBills,
      bills,
      loadingBills,
      clientSession,
      peopleAtTable,
      updatePeopleAtTable,
    ]
  );

  return (
    <BillsContext.Provider value={value}>{children}</BillsContext.Provider>
  );
}

function useBills() {
  const context = useContext(BillsContext);

  if (!context) {
    throw new Error('useTables must be used within an OrderProvider');
  }

  return context;
}

export { BillsProvider, useBills };
