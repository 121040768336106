import React, { useState } from 'react';
import { FaPen, FaTrash } from 'react-icons/fa';
import { Modal } from 'reactstrap';
import Edit from '../Edit';

function Supplier({ supplier, handleDeleteSupplier, getSuppliers }) {
  const [isModalEditSupplierOpen, setIsModalEditSupplierOpen] = useState(false);

  function toggleModalEditSupplier() {
    setIsModalEditSupplierOpen(!isModalEditSupplierOpen);
  }
  return (
    <tr>
      <td>{supplier?.name}</td>
      <td>{supplier?.cnpj}</td>
      <td>{supplier?.phone}</td>
      <td>
        <FaPen
          title="Editar fornecedor"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            toggleModalEditSupplier();
          }}
        />
        <FaTrash
          color="#ff403d"
          title="Deletar forncedor"
          style={{ cursor: 'pointer', marginLeft: 8 }}
          onClick={() => {
            handleDeleteSupplier(supplier.id);
          }}
        />
      </td>

      <Modal isOpen={isModalEditSupplierOpen} toggle={toggleModalEditSupplier}>
        <Edit
          supplier={supplier}
          toggleModalEditSupplier={toggleModalEditSupplier}
          getSuppliers={getSuppliers}
        />
      </Modal>
    </tr>
  );
}

export default Supplier;
