import React from 'react';

import {
  Container,
  Restaurant,
  Footer,
  TotalRow,
  ItemTitle,
  ItemDescription,
} from './styles';

import formatCompleteDate from '~/services/formatCompleteDate';

const PrintManualCashierWithdrawal = React.forwardRef(
  ({ item, restaurant }, ref) => {
    return (
      <Container ref={ref}>
        <React.Fragment key={item?.id}>
          <Restaurant style={{ borderBottom: 'none' }}>
            <strong>{restaurant?.fantasy_name}</strong>
          </Restaurant>
          <Restaurant style={{ display: 'flex', flexDirection: 'column' }}>
            <strong>SANGRIA</strong>
            <strong>
              {item?.bank_account
                ? 'TRANSFERÊNCIA DE FUNDOS'
                : 'DESPESA'}
            </strong>
          </Restaurant>

          <TotalRow>
            <span>Data/Hora:</span>
            <span>
              {item?.createdAt
                ? formatCompleteDate(item?.createdAt)
                : formatCompleteDate(new Date())}
            </span>
          </TotalRow>

          {item?.bank_account ? (
            <>
              <ItemTitle>VALOR DE TRANSFERẼNCIA</ItemTitle>
              <ItemDescription>
                {parseFloat(item?.value).toFixed(2)}
              </ItemDescription>

              <ItemTitle>CONTA DE DESTINO</ItemTitle>
              <ItemDescription>{item?.bank_account?.name}</ItemDescription>
            </>
          ) : (
            <>
              <ItemTitle>VALOR DA DESPESA</ItemTitle>
              <ItemDescription>
                {parseFloat(item?.value).toFixed(2)}
              </ItemDescription>

              <ItemTitle>DESCRIÇÃO</ItemTitle>
              <ItemDescription>{item?.description}</ItemDescription>

              <ItemTitle>CENTRO DE CUSTO ASSOCIADO</ItemTitle>
              <ItemDescription>{item?.cash_flow_category}</ItemDescription>
            </>
          )}

          <Footer>
            <span>Desenvolvido por Takeat!</span>
          </Footer>
        </React.Fragment>
      </Container>
    );
  }
);

export default PrintManualCashierWithdrawal;
