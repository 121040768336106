import React from 'react';
import { Form } from '@unform/web';

import { Modal, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import Input from '~/components/Form/Input';
import { Button } from 'ui-kit-takeat';
import { format } from 'date-fns';

export default function NfeEntryInfoModal({
  modal,
  toggle,
  nfeReq
}) {

  function formatAsDateTime(date) {
    if (date) {
      return format(new Date(date + 'T12:00-03:00'), 'dd/MM/yyyy');
    }

    return format(new Date(0), 'dd/MM/yyyy');
  }
  
 return (
  <Modal isOpen={modal} toggle={toggle} size="lg">
    <ModalBody>
      <Form initialData={nfeReq}>
        {!nfeReq && (
          <Row>
            <Col>
              <h5 style={{ color: 'red' }}>
                <strong>
                  Informações da nota ainda não processada pela Sefaz. Tente
                  novamente mais tarde.
                </strong>
              </h5>
            </Col>
          </Row>
        )}
        <Row>
          <Col
            style={{ borderBottom: '1px solid #EBEBEB', marginBottom: 5 }}
          >
            <h5>Dados do Fornecedor:</h5>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Input
              name="nome_fantasia_emitente"
              label="Fornecedor"
              type="text"
              disabled
            />
          </Col>
          <Col md="6">
            <Input
              name="nome_emitente"
              label="Razão Social"
              type="text"
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Input name="cnpj_emitente" label="CNPJ" type="text" disabled />
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              borderBottom: '1px solid #EBEBEB',
              marginBottom: 5,
              paddingTop: 10,
            }}
          >
            <h5>Dados da Nota:</h5>
          </Col>
        </Row>
        <Row>
          <Col md="2">
            <Input
              name="valor_total"
              label="Valor Total (R$)"
              type="text"
              disabled
            />
          </Col>
          <Col md="2">
            <Input
              name="data_emissao"
              label="Data Emissão"
              value={format(
                new Date(nfeReq?.data_emissao || null),
                'dd/MM/yyyy'
              )}
              type="text"
              disabled
            />
          </Col>
          <Col md="8">
            <Input
              name="chave_nfe"
              label="Chave NFe"
              type="text"
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col md="2">
            <Input name="serie" label="Série" type="text" disabled />
          </Col>
          <Col md="2">
            <Input name="numero" label="Número" type="text" disabled />
          </Col>
          <Col md="2">
            <Input name="modelo" label="Modelo" type="text" disabled />
          </Col>
          <Col md="3">
            <Input
              name="codigo_unico"
              label="Código Único"
              type="text"
              disabled
            />
          </Col>
          <Col md="3">
            <Input name="versao" label="Versão" type="text" disabled />
          </Col>
        </Row>
      </Form>
      <Row>
        <Col
          style={{
            borderBottom: '1px solid #EBEBEB',
            marginBottom: 5,
            paddingTop: 10,
          }}
        >
          <h5>Duplicatas:</h5>
        </Col>
      </Row>
      {nfeReq && nfeReq?.duplicatas && nfeReq?.duplicatas.length > 0 ? (
        nfeReq?.duplicatas.map((dup) => (
          <Form initialData={dup}>
            <Row>
              <Col md="4">
                <Input name="numero" label="Número" type="text" disabled />
              </Col>
              <Col md="4">
                <Input
                  name="data_vencimento"
                  label="Data Vencimento"
                  value={formatAsDateTime(dup.data_vencimento)}
                  type="text"
                  disabled
                />
              </Col>
              <Col md="4">
                <Input
                  name="valor"
                  label="Valor (R$)"
                  type="text"
                  disabled
                />
              </Col>
            </Row>
          </Form>
        ))
      ) : (
        <p style={{ color: 'red' }}>Nota sem duplicatas.</p>
      )}

      <Form initialData={nfeReq}>
        <Row>
          <Col
            style={{
              borderBottom: '1px solid #EBEBEB',
              marginBottom: 5,
              paddingTop: 10,
            }}
          >
            <h5>Totais da Nota :</h5>
          </Col>
        </Row>
        <Row>
          <Col md="3">
            <Input
              name="valor_produtos"
              label="Valor dos Produtos"
              type="text"
              disabled
            />
          </Col>
          <Col md="3">
            <Input
              name="icms_base_calculo"
              label="Base de Cálculo do ICMS"
              type="text"
              disabled
            />
          </Col>
          <Col md="3">
            <Input
              name="icms_valor_total"
              label="Valor do ICMS"
              type="text"
              disabled
            />
          </Col>
          <Col md="3">
            <Input
              name="icms_base_calculo_st"
              label="Base de Cálc. do ICMS ST"
              type="text"
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col md="3">
            <Input
              name="icms_valor_total_st"
              label="Valor Total do ICMS ST"
              type="text"
              disabled
            />
          </Col>
          <Col md="3">
            <Input
              name="valor_pis"
              label="Valor do PIS"
              type="text"
              disabled
            />
          </Col>
          <Col md="3">
            <Input
              name="valor_cofins"
              label="Valor do COFINS"
              type="text"
              disabled
            />
          </Col>
          <Col md="3">
            <Input
              name="valor_ipi"
              label="Valor do IPI"
              type="text"
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col md="3">
            <Input
              name="valor_frete"
              label="Valor Total do Frete"
              type="text"
              disabled
            />
          </Col>
          <Col md="3">
            <Input
              name="valor_outras_despesas"
              label="Valor de Outras Despesas"
              type="text"
              disabled
            />
          </Col>
          <Col md="3">
            <Input
              name="valor_desconto"
              label="Valor Total de Desconto"
              type="text"
              disabled
            />
          </Col>
          <Col md="3">
            <Input
              name="valor_total"
              label="Valor Total da NFe"
              type="text"
              disabled
            />
          </Col>
        </Row>
      </Form>
      <Row>
        <Col
          style={{
            borderBottom: '1px solid #EBEBEB',
            marginBottom: 5,
            paddingTop: 10,
          }}
        >
          <h5>Produtos:</h5>
        </Col>
      </Row>
      {nfeReq && nfeReq?.itens && nfeReq?.itens.length > 0 ? (
        nfeReq?.itens.map((item) => (
          <Form
            initialData={item}
            style={{ paddingTop: 15, borderBottom: '1px solid #EBEBEB' }}
          >
            <Row>
              <Col>
                <h5>Produto #{item.numero_item}</h5>
              </Col>
            </Row>
            <Row>
              <Col md="2">
                <Input
                  name="codigo_produto"
                  label="Cód. Forn."
                  type="text"
                  disabled
                />
              </Col>
              <Col md="6">
                <Input
                  name="descricao"
                  label="Produto"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="2">
                <Input
                  name="quantidade_comercial"
                  label="Qnt. Nota"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="2">
                <Input
                  name="unidade_comercial"
                  label="Unidade"
                  type="text"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <Input
                  name="valor_unitario_comercial"
                  label="Valor de Custo"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="2">
                <Input
                  name="valor_bruto"
                  label="Valor Total"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="2">
                <Input name="codigo_ncm" label="NCM" type="text" disabled />
              </Col>
            </Row>
          </Form>
        ))
      ) : (
        <p style={{ color: 'red' }}>Nota sem duplicatas.</p>
      )}
    </ModalBody>
    <ModalFooter>
      <Button color="info" onClick={() => toggle()} title="Fechar" />
    </ModalFooter>
  </Modal>
  );
}
