import React, { useCallback, useState } from 'react';
import { FaPen, FaPlus, FaTrash } from 'react-icons/fa';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { toast } from 'react-hot-toast';
import { Collapse } from 'reactstrap';
import api from '~/services/api';

import { Container } from './styles';

function Subcategorie({
  subcategory,
  getCategories,
  setParentSubcategory,
  toggle,
  toggleEdit,
  setSubcategory,
  category,
}) {
  const hasSubs = subcategory.cash_flow_subcategories.length > 0;
  const [collapse, setCollapse] = useState(false);
  const [subCollapse, setSubCollapse] = useState([]);
  const handleDeleteSubcategory = useCallback(
    async (id) => {
      try {
        console.log('id > ', id);
        if (
          window.confirm(
            'Deseja realmente deletar essa subcategoria? Ela será deletada de todas as categorias que ela faz parte. Para deletar somente desta categoria, use o remover subcategoria'
          )
        ) {
          await api.delete(`/restaurants/cash-flows/subcategories/${id}`);

          getCategories();
        }
      } catch (error) {
        if (!error.response.ok) {
          if (
            error.response.data.errorType ===
            'can_not_delete_cash_flow_category'
          ) {
            toast.error(
              'O usuário não pode deletar subcategorias padrões do sistema'
            );
          } else {
            toast.error('Erro ao deletar subcategoria');
          }
        }
      }
    },
    [subcategory, getCategories]
  );

  const handleSubCollapse = (item) => {
    if (subCollapse.includes(item)) {
      setSubCollapse((state) => state.filter((i) => i !== item));
    } else {
      setSubCollapse((state) => [...state, item]);
    }
  };

  return (
    <>
      <Container
        onClick={() => hasSubs && setCollapse(!collapse)}
        style={{
          cursor: hasSubs && 'pointer',
        }}
      >
        <div>
          {hasSubs && (
            <MdKeyboardArrowRight
              style={{
                transition: 'all 0.2s',
                transform: collapse ? 'rotate(90deg)' : 'rotate(0)',
              }}
            />
          )}
          <span>{subcategory?.name}</span>
        </div>
        {!category.is_multistore_child && (
          <div>
            <FaPen
              onClick={(e) => {
                e.stopPropagation();
                setSubcategory(subcategory.id);
                toggleEdit();
              }}
              style={{ cursor: 'pointer', marginRight: 8 }}
            />
            <FaPlus
              onClick={() => {
                setParentSubcategory(subcategory);
                toggle();
              }}
              title="Criar subsubcategoria"
              style={{ cursor: 'pointer' }}
            />
            <FaTrash
              onClick={() => handleDeleteSubcategory(subcategory.id)}
              color="#ff403d"
              title="Deletar subcategoria"
              style={{ cursor: 'pointer', marginLeft: 8 }}
            />
          </div>
        )}
      </Container>
      {hasSubs && (
        <Collapse isOpen={collapse}>
          {subcategory.cash_flow_subcategories.map((sub) => {
            const hasSubsubs = sub.cash_flow_subcategories.length > 0;
            return (
              <>
                <Container
                  key={sub.id}
                  onClick={() => hasSubsubs && handleSubCollapse(sub.id)}
                  style={{
                    cursor: hasSubsubs && 'pointer',
                  }}
                >
                  <div>
                    <span>{'\u00A0'.repeat(8)}</span>
                    {hasSubsubs && (
                      <MdKeyboardArrowRight
                        style={{
                          transition: 'all 0.2s',
                          transform: subCollapse.includes(sub.id)
                            ? 'rotate(90deg)'
                            : 'rotate(0)',
                        }}
                      />
                    )}
                    <span>{sub.name}</span>
                  </div>
                  {!category.is_multistore_child && (
                    <div>
                      <FaPen
                        onClick={(e) => {
                          e.stopPropagation();
                          setSubcategory(sub.id);
                          toggleEdit();
                        }}
                        style={{ cursor: 'pointer', marginRight: 8 }}
                      />

                      <FaPlus
                        onClick={() => {
                          setParentSubcategory(sub);
                          toggle();
                        }}
                        title="Criar subsubcategoria"
                        style={{ cursor: 'pointer' }}
                      />
                      <FaTrash
                        onClick={() => handleDeleteSubcategory(sub.id)}
                        color="#ff403d"
                        title="Deletar subcategoria"
                        style={{ cursor: 'pointer', marginLeft: 8 }}
                      />
                    </div>
                  )}
                </Container>
                {hasSubsubs && (
                  <Collapse isOpen={subCollapse.includes(sub.id)}>
                    {sub.cash_flow_subcategories.map((subsub) => (
                      <Container key={subsub.id}>
                        <div>
                          <span>{'\u00A0'.repeat(12)}</span>
                          <span>{subsub.name}</span>
                        </div>
                        {!category.is_multistore_child && (
                          <div>
                            <FaPen
                              onClick={(e) => {
                                e.stopPropagation();
                                setSubcategory(subsub.id);
                                toggleEdit();
                              }}
                              style={{ cursor: 'pointer', marginRight: 8 }}
                            />

                            <FaTrash
                              onClick={() => handleDeleteSubcategory(subsub.id)}
                              color="#ff403d"
                              title="Deletar subcategoria"
                              style={{ cursor: 'pointer', marginLeft: 8 }}
                            />
                          </div>
                        )}
                      </Container>
                    ))}
                  </Collapse>
                )}
              </>
            );
          })}
        </Collapse>
      )}
    </>
  );
}

export default Subcategorie;
