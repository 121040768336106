import { Modal } from 'reactstrap';
import styled, { css } from 'styled-components';
import NumberFormat from 'react-number-format';

export const Content = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.type === 'openBalcony' &&
    css`
      background-color: orange;
    `}

  p {
    color: #fff;
  }

  ${(props) =>
    props.type === 'open' &&
    css`
      background-color: #ff2b3a;
    `}

  ${(props) =>
    props.type === 'closing' &&
    css`
      background-color: orange;
    `}

  ${(props) =>
    props.type === 'available' &&
    css`
      background-color: #7fd1b9;
    `}

  color: #fff;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const InnerArea = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 90vh;
  padding: 20px 20px 220px;

  @media (max-width: 1281px) {
    height: 90vh;
 }

  @media (min-width: 1282px) and (max-width: 1450px) {
    padding: 20px 20px 420px;
    height: 90vh;
  }

  ${(props) =>
    props.browserZoomLevel > 100 &&
    css`
      padding: 20px 20px 420px;
    `}
`;
export const AreaBlocked = styled.div`
  width: 36%;
  height: 105%;

  position: absolute;
  background: #00000045;
  z-index: 1000000000;
  top: -23px;
  right: -23px;

  @media (max-width: 500px) {
    width: 100%;
    overflow-x: auto;
  }

  @media (max-width: 1500px) {
    min-height: 550px;
    height: 590px;
  }
`;

export const NumberFormatStyled = styled(NumberFormat)`
  text-align: center;
  height: 32px;
  width: calc(100% - 54px);
  max-width: 103px;
  min-width: 100px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }
`;

export const PeopleArea = styled.div`
  margin-bottom: 30px;
  display: flex;
  gap: 5px;
`;

export const SubmitStyledButton = styled.button`
  height: 32px;
  width: 32px;
  padding: 4px;
  border-radius: 8px;
  border: none;
  margin-left: 8px;
  align-items: center;
  background-color: #0ccab4;
  svg {
    color: #fff;
  }
  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.colors.lightGray};
    svg {
      filter: opacity(0.3);
      color: #333;
    }
  }
`;

export const ModalPaymentBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ModalSized = styled(Modal)`
  width: 1800px;
`;

export const ButtonStyled = styled.button`
  margin-right: 8px;
  border: none;
  padding: 8px;
  border-radius: 7px;
  color: #fff;
  font-size: 14px;

  svg {
    margin-right: 8px;
  }

  ${(props) =>
    props.operationType === 'deposit' &&
    css`
      background-color: #28a745;
    `}

  ${(props) =>
    props.operationType === 'withdrawal' &&
    css`
      background-color: #ff403d;
    `}

    ${(props) =>
    props.operationType === 'report' &&
    css`
      background-color: #33333370;
    `}

      &:hover {
    filter: opacity(0.9);
  }
`;

export const OperationArea = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: space-between;
  position: relative;

  flex-wrap: wrap;
`;

export const DivideArea = styled.div`
  width: 100%;
  height: 80vh;
  overflow: hidden;

  @media (max-width: 500px) {
    width: 100%;
  }
`;
export const OrderSystemArea = styled.div`
  width: 65%;
  height: 80vh;
  overflow-y: hidden;
  border-right: 1px solid #33333320;

  @media (max-width: 500px) {
    width: 100%;
  }
`;
export const BillTableArea = styled.div`
  width: 35%;
  height: 80vh;
  max-height: 780px;
  overflow-y: auto;
  overflow-x: hidden;

  h2 {
    padding-left: 8px;
  }

  @media (max-width: 500px) {
    width: 100%;
    overflow-x: auto;
  }
`;

export const ButtonsArea = styled.div`
  padding-top: 16px;
  padding-left: 8px;
  height: 80px;
  border-top: 1px solid #33333330;
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  width: 35%;
  background-color: #fff;

  button {
    font-size: 8px;
  }
`;

export const DisplayNoneImprimir = styled.button`
  display: none;
`;

export const Print = styled.button`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-self: center;
  float: right;
  position: relative;
  display: block;
  right: 0;
  margin-right: 10px;
  border: none;
  background: #f0b402;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
    border-radius: 50%;
  }
`;

export const CloseButton = styled.button`
  border-radius: 50%;
  padding: 15px 15px;
  font-size: 8px;
  margin: auto;
  margin-bottom: 5px;

  color: white;

  border: none;
  background: #dc3545;

  display: flex;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const ActionsInside = styled.div`
  padding-top: 2px;
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export const ButtonAloneArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 81px;
  height: 75px;
  border: 1px solid;
  background-color: #fff;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    filter: drop-shadow(0 0 4px rgba(255, 0, 0, 0.4));
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
    `}

  ${(props) => props.type === 'closing' && 'border-color: #e9243e'}
  ${(props) => props.type === 'box' && 'border-color: #009f43'}
  ${(props) => props.type === 'details' && 'border-color: #0099ad'}
  ${(props) => props.type === 'discount' && 'border-color: #7f0071'}
  ${(props) => props.type === 'transfer' && 'border-color: #ffbb2f'}
`;

export const CashierButton = styled.button`
  border-radius: 50%;
  padding: 15px 15px;
  margin-bottom: 5px;
  color: white;
  font-size: 12px;

  border: none;
  background: #28a745;

  display: flex;
  flex-direction: row;
  text-align: center;

  // svg {
  //   margin-right: 8px;
  // }

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const AddDiscountObsButton = styled.button`
  color: blue;
  font-size: 12px;
  float: right;
  border: none;
  background: transparent;
  border: none;
  // display: flex;
  // flex-direction: row;
  // text-align: center;

  // :hover {
  //   filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
  //   text-decoration: none;
  //   color: white;
  // }
  // div {
  //   margin: auto;
  //   text-align: center;
  // }

  // &:disabled {
  //   opacity: 0.6;
  //   cursor: not-allowed;
  // }
`;

export const DiscountButton = styled.button`
  border-radius: 50%;
  padding: 15px 15px;
  margin: auto;
  margin-bottom: 5px;

  color: white;
  font-size: 12px;

  border: none;
  background: purple;

  display: flex;
  flex-direction: row;
  text-align: center;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const ReopenButton = styled.button`
  border-radius: 50%;
  padding: 15px 15px;
  font-size: 12px;
  color: #000;
  margin: auto;
  margin-bottom: 5px;
  border: none;
  background: #ffc107;

  display: flex;
  flex-direction: row;
  text-align: center;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const SeeButton = styled.button`
  border-radius: 50%;
  padding: 15px 15px;
  font-size: 12px;
  margin-bottom: 5px;

  color: white;

  border: none;
  background: #17a2b8;

  display: flex;
  flex-direction: row;
  text-align: center;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const TablePayment = styled.div`
  border-top: 1px solid #ffffff40;
  height: 40%;
  width: 90%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 24px;

  /* background-color: #fff;
  color: #dc3545; */

  p {
    font-size: 12px;

    & + p {
      margin-top: -10px;
    }
  }
`;

export const DiscountInfoTable = styled.div`
  display: flex;
  flex-direction: column;

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;

    font-size: 14px;
    font-weight: 500;
  }

  div:first-child {
    font-size: 16px;
    font-weight: 600;
  }

  span {
    width: 25%;
  }
`;
