import React from 'react';
import { Title } from './styles';

export const PageTitle = ({ children }) => {
  return (
    <Title>
      <h1>{children}</h1>
    </Title>
  );
};
