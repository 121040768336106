import React, { useState, useEffect, useCallback} from 'react';
import { toast } from 'react-hot-toast';
import {Button } from 'reactstrap';

import {
  Container,
  Header,
  TablesTable,
  StatusTag,
} from './styles';

import api from '~/services/api';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import formatCompleteDate from '~/services/formatDate';
import { PageTitle } from '~/components/PageTitle';

function Invoices() {
  const [invoices, setInvoices] = useState([]);

  const getInvoices = useCallback(async () => {
    try {
      const response = await api.get('restaurants/invoices');
      setInvoices(response.data);
    } catch (err) {
      toast.error('Erro ao carregar boletos.');
    }
  }, []);

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'FinanceInvoices'}`
      );

      const { can_read } = response.data.FinanceInvoices;

      setPermission(can_read);
    } catch (error) {
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  useEffect(() => {
    getInvoices();
  }, [getInvoices]);

  function getStatus(status) {
    switch (status) {
      case 'PENDING':
        return 'Pendente';
      case 'RECEIVED':
        return 'Pago';
      case 'CONFIRMED':
        return 'Pago';
      case 'OVERDUE':
        return 'Vencida';
      case 'REFUNDED':
        return 'Estornada';
      case 'DELETED':
        return 'Deletada';
      case 'RECEIVED_IN_CASH':
        return 'Pago';
      default:
        return status;
    }
  }

  function getStatusColor(status) {
    switch (status) {
      case 'PENDING':
        return '#CC9900';
      case 'RECEIVED':
        return 'green';
      case 'RECEIVED_IN_CASH':
        return 'green';
      case 'CONFIRMED':
        return 'green';
      case 'OVERDUE':
        return '#dc3545';
      case 'REFUNDED':
        return 'blue';
      case 'DELETED':
        return 'black';
      default:
        return 'white';
    }
  }

  function getButtonStatus(status) {
    switch (status) {
      case 'PENDING':
        return 'Pagar';
      case 'RECEIVED':
        return 'Recibo';
      case 'RECEIVED_IN_CASH':
        return 'Recibo';
      case 'CONFIRMED':
        return 'Recibo';
      case 'OVERDUE':
        return 'Pagar';
      case 'REFUNDED':
        return 'Recibo';
      case 'DELETED':
        return 'Recibo';
      default:
        return 'Link';
    }
  }

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div>
          <PageTitle>Faturas do Sistema</PageTitle>
        </div>
      </Header>
      <TablesTable borderless>
        <thead>
          <tr>
            <th>Vencimento</th>
            <th>Status</th>
            <th>Valor</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice) => (
            <tr>
              <td>{formatCompleteDate(invoice?.due_date)}</td>
              <td>
                <StatusTag color={getStatusColor(invoice.status)}>
                  <span>{getStatus(invoice.status)}</span>
                </StatusTag>
              </td>
              <td>R${invoice.value}</td>
              <td>
                <Button
                  style={{
                    background:
                      getButtonStatus(invoice.status) === 'Pagar'
                        ? 'green'
                        : '#0dcaf0',
                  }}
                  onClick={() => {
                    window.open(invoice.invoice_url);
                  }}
                >
                  {getButtonStatus(invoice.status)}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </TablesTable>
    </Container>
  );
}

export default Invoices;
