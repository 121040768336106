import styled from 'styled-components';
// import { Link } from 'react-router-dom';

import { Form } from '@unform/web';
import Input from '~/components/Form/Input';

export const Container = styled.div`
 
  margin-top: 10px;
`;

export const Wrapper = styled.div`
 
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  
  h3{
    font-size: 16px;
    font-weight: bold;
  }
`;

export const Card = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledForm = styled(Form)`
  width: 100%;
`;


export const ButtonAdd = styled.button`
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 10%;

  background: #3BD2C1;
  border-radius: 7px;
  border: none;
`

export const StyledInput = styled(Input)`
width: 102%;
`