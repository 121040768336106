import React from 'react';

import { Spinner } from 'reactstrap';
import { Content, ItemsCount, LoadingDiv } from '../styles';
import { Header, Bloco,StyleKanbanTextTop,StyleKanbanTextMid,HyperlinkStyle,StyleKanbanImage } from './styles';
import OrderNewDelivery from '../../Items/OrderNewDelivery';
import TableDeliveryButton from '~/components/Operation/TablesOperation/TableDeliveryButton';
import deliveryKanbanBottomImage from '../../../../assets/img/delivery_kanban_bottom.jpg';

export default function CardOrdersNew({
  baskets,
  handlePrintItem,
  handleCancelItem,
  loading,
  setItemIfoodId,
  hasOrderActive,
  user,
  handleUpdate,
  countOrders
}) {
  return (
    <Bloco>
      <Header>
        <div>NOVOS PEDIDOS</div>
        <ItemsCount style={{ color: '#fe1933' }}>{countOrders}</ItemsCount>
      </Header>
      <Content style={{ borderBottomLeftRadius: 12 }}>
        <TableDeliveryButton margin={'5px 5px 0px 5px'} isScheduling={false} />
        {loading ? (
          <LoadingDiv>
            <Spinner color="danger" />
          </LoadingDiv>
        ) : (
          baskets.length >= 1 &&
          baskets
            .filter(
              (order) =>
                order.table.table_type === 'delivery' && !order.scheduled_to
            )
            .map((item) => (
              <OrderNewDelivery
                key={item.basket.id}
                handleCancelItem={handleCancelItem}
                handlePrintItem={handlePrintItem}
                item={item}
                setItemIfoodId={setItemIfoodId}
                handleUpdate={handleUpdate}
              />
            ))
        )}
        {!hasOrderActive && (
          <StyleKanbanImage>
          <StyleKanbanTextTop>Não há pedido no momento</StyleKanbanTextTop> 
          <StyleKanbanTextMid>&ensp;Copie e divulgue seu delivery nas redes sociais para receber pedidos:</StyleKanbanTextMid>       
          <HyperlinkStyle
            fontSize={14}
            onClick={() =>
              window.open(
                `https://pedido.takeat.app/${
                  user?.nickname ? user?.nickname : user?.name
                }`
              )
            }
          >
            https://pedido.takeat.app/{user?.nickname ? user?.nickname : user?.name}
          </HyperlinkStyle>
          <img style={{width:"80%",marginTop:"10px"}} alt='' src={deliveryKanbanBottomImage}/>
        </StyleKanbanImage>
        )}
      </Content>
    </Bloco>
  );
}