import styled from 'styled-components';
import ScrollContainer from 'react-indiana-drag-scroll';

export const Container = styled(ScrollContainer)`
  display: flex;
  gap: 10px;
`;

export const Category = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 80px;
  cursor: pointer;
`;

export const CategoryImg = styled.img`
  border-radius: 50%;
  border: 2px solid #ff1b33;
  width: 70px;
  height: 70px;
  min-width: 70px;
  min-height: 70px;
  object-fit: cover;
`;

export const CategoryTitle = styled.span`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #4d4d4c;
  max-width: 80px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
