import React, { useEffect, useRef } from "react";
import { DisplayNoneImprimir } from "./styles";
import { useReactToPrint } from 'react-to-print';
import PrintBillsOrderOperation from '../../../Prints/PrintBillsOrderOperation';

const PrintedComponent = ({
  item,
  restaurant,
  count,
  paymentData,
  printBills,
  sessionBills,
  productBills,
  commandTableNumber,
  setOpenPrintable,
}) => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setOpenPrintable(false);
    }
  });

  useEffect(() => {
    if (componentRef.current) {
      setTimeout(() => {
        handlePrint();
      }, 500);
    }
  }, [handlePrint, componentRef])

  return (
    <>
      <DisplayNoneImprimir>
        <PrintBillsOrderOperation
          item={item}
          restaurant={restaurant}
          ref={componentRef}
          count={count}
          paymentData={paymentData}
          printBills={printBills}
          sessionBills={sessionBills}
          productBills={productBills}
          commandTableNumber={commandTableNumber}
        />
      </DisplayNoneImprimir>
    </>
  )
}

export default PrintedComponent;