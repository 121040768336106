export function socketConnectionRequest(profile) {
  return {
    type: '@socketio/CONNECTION_REQUEST',
    payload: { profile },
  };
}
export function socketConnectionSuccess(socket) {
  return {
    type: '@socketio/CONNECTION_SUCCESS',
    payload: { socket },
  };
}
