import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toJpeg } from 'html-to-image';
import { AiOutlineInstagram, AiOutlineWhatsApp } from 'react-icons/ai';
import { FaStar } from 'react-icons/fa';
import { Button, Modal, ModalBody } from 'reactstrap';
import SaveButton from '~/components/Buttons/DefaultButton';
import ClientRatingBanner from '~/components/ClientRatingBanner';
import api from '~/services/api';
import formatCompleteDate from '~/services/formatCompleteDate';
import { TableRow } from './style';

// import { Container } from './styles';

function ClientsRatingRow({ rating }) {
  const [isModalBannerOpened, setIsModalBannerOpened] = useState();
  const [ratingDetails, setRatingDetails] = useState();
  const [loading, setLoading] = useState(false);

  const getRatingDetails = useCallback(async () => {
    const response = await api.get(`/restaurants/ratings/detail/${rating.id}`);

    setRatingDetails(response.data);
  }, [rating]);

  function redirectWhatsApp() {
    window.open(
      `https://api.whatsapp.com/send?phone=55${rating?.buyer?.phone
        .replace('(', '')
        .replace(')', '')}&text=Olá!`,
      '_blank'
    );
  }

  const imgRef = useRef(null);

  function toggleModalBanner() {
    setIsModalBannerOpened(!isModalBannerOpened);
  }

  const downloadPng = useCallback(() => {
    if (imgRef.current === null) {
      return;
    }

    setLoading(true);
    toJpeg(imgRef.current, { cacheBust: true, fontEmbedCSS: false })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'my-image-name.jpg';
        link.href = dataUrl;
        link.click();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [imgRef]);

  useEffect(() => {
    if (isModalBannerOpened) {
      getRatingDetails();
    }
  }, [getRatingDetails, isModalBannerOpened]);

  return (
    <TableRow>
      <td>{rating?.buyer?.phone}</td>
      <td>{rating?.buyer?.name}</td>
      <td>
        {rating?.stars} <FaStar color="orange" />
      </td>
      <td style={{ maxWidth: '400px' }}>{rating?.comment}</td>
      <td>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            maxWidth: '250px',
            margin: '0 auto',
          }}
        >
          {rating?.rating_adjectives?.map((r) => (
            <span
              style={{
                fontSize: '10px',
                border: '1px solid red',
                padding: '0 5px',
                borderRadius: '8px',
                margin: '1px',
              }}
            >
              {r.adjective}
            </span>
          ))}
        </div>
      </td>
      <td>{formatCompleteDate(rating.createdAt)}</td>
      <td>
        <Button
          style={{ background: '#26d367', border: 'none', padding: '11px' }}
          onClick={() => redirectWhatsApp()}
          title="Enviar mensagem pro cliente"
        >
          <AiOutlineWhatsApp size={20} />
        </Button>
        <Button
          style={{
            background: 'linear-gradient(90deg, #ffc83a, #ea4b9f)',
            border: 'none',
            padding: '11px',
          }}
          onClick={toggleModalBanner}
          title="Gerar arte do comentário."
        >
          <AiOutlineInstagram size={20} />
        </Button>
      </td>

      <Modal isOpen={isModalBannerOpened} toggle={toggleModalBanner}>
        <ModalBody>
          <p>
            Agora você pode compartilhar os comentários em suas redes sociais!
            Basta salvar o Banner e compartilhar à vontade.
          </p>

          <>
            <ClientRatingBanner ref={imgRef} ratingDetails={ratingDetails} />

            <SaveButton
              type="button"
              onClick={downloadPng}
              title="Baixar Banner"
              message={loading ? 'Gerando Banner ...' : 'Baixar Banner'}
            />
          </>
        </ModalBody>
      </Modal>
    </TableRow>
  );
}

export default ClientsRatingRow;
