import { Form } from '@unform/web';
import React, { useCallback, useRef } from 'react';
import * as Yup from 'yup';

import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-hot-toast';
import InputMask from 'react-input-mask';
import SaveButton from '~/components/Buttons/SaveButton';
import Input from '~/components/Form/Input';

import { Container } from './styles';
import api from '~/services/api';
import getValidationErrors from '~/services/getValidationErrors';

function Edit({ supplier, toggleModalEditSupplier, getSuppliers }) {
  const formRef = useRef(null);

  const initialData = {
    name: supplier?.name,
    cnpj: supplier?.cnpj,
    phone: supplier?.phone,
  };

  const handleEditSupplier = useCallback(
    async (data) => {
      try {
        if (formRef?.current) {
          formRef.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          cnpj: Yup.string()
            .required('CNPJ/ CPF obrigatório')
            .trim()
            .matches('^[0-9]+$', 'Apenas números, sem espaço'),
          phone: Yup.string().required('Telefone obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.put(`/restaurants/cash-flows/providers/${supplier.id}`, {
          name: data.name,
          phone: data.phone,
          cnpj: data.cnpj,
        });

        toast.success('Fornecedor atualizado com sucesso!');

        getSuppliers();
        toggleModalEditSupplier();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRef?.current) {
            formRef.current.setErrors(errors);
          }
        } else {
          toast.error(`Erro ao atualizar fornecedor!`);
        }
      }
    },
    [getSuppliers, toggleModalEditSupplier]
  );

  return (
    <Container>
      <ModalHeader> Editar Fornecedor</ModalHeader>
      <Form
        onSubmit={handleEditSupplier}
        initialData={initialData}
        ref={formRef}
      >
        <ModalBody>
          <Input label="Nome do fornecedor" name="name" />
          <Input label="CNPJ / CPF" name="cnpj" />
          <InputMask mask="(99) 99999-9999">
            <Input
              label="Telefone"
              name="phone"
              maskChar=""
              placeholder="(xx) xxxxx-xxxx"
            />
          </InputMask>
        </ModalBody>
        <ModalFooter>
          <SaveButton type="submit" message="Salvar" />
        </ModalFooter>
      </Form>
    </Container>
  );
}

export default Edit;
