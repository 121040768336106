import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { toast } from 'react-hot-toast';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { AiOutlinePrinter } from 'react-icons/ai';
import ReactToPrint from 'react-to-print';
import api from '../../services/api';

import { useAuth } from '~/context/AuthContext';
import { useTables } from '../TablesContext';
import PrintBillsTableOperation from '~/components/Prints/PrintBillsTableOperation';
import { useBills } from '../BillsContext';
import { useOrder } from '../orderContext';

const ClosingContext = createContext();

const ClosingProvider = ({ children }) => {
  const { websocketManager, user } = useAuth();

  const [, setLoading] = useState(false);
  const { getTablesAvailable } = useTables();
  const { getTableBills } = useBills();
  const { getAllOrders } = useOrder();
  const componentRef = useRef(null);
  const [closingIndividualBills, setClosingIndividualBills] = useState(() => {
    const closingIndividualBillsStorage = localStorage.getItem(
      '@gddashboard:closingIndividualBills'
    );

    if (closingIndividualBillsStorage) {
      return JSON.parse(closingIndividualBillsStorage);
    }

    return [];
  });

  const [closingTableBills, setClosingTableBills] = useState(() => {
    const closingTableBillsStorage = localStorage.getItem(
      '@gddashboard:closingTableBills'
    );

    if (closingTableBillsStorage) {
      return JSON.parse(closingTableBillsStorage);
    }

    return [];
  });

  const getClosingIndividualBills = useCallback(async () => {
    const individualBills = await api.get(
      'restaurants/closing/individual-bills'
    );
    setClosingIndividualBills(individualBills.data);
  }, []);

  const getClosingTableBills = useCallback(async () => {
    const tableBills = await api.get('/restaurants/table-sessions-minimal', {
      params: {
        status: 'closing',
      },
    });
    setClosingTableBills(tableBills.data);
  }, []);

  const getAllClosingBills = useCallback(async () => {
    getClosingTableBills();
    getClosingIndividualBills();
  }, [getClosingIndividualBills, getClosingTableBills]);

  const printIndividualBill = useCallback(
    async ({ session_key, bill_id, table_id }) => {
      try {
        await api.put('restaurants/close-individual-bill', {
          key: session_key,
          bill_id,
        });

        setClosingIndividualBills(state => {
          return state.filter(item => item?.table?.id !== table_id)
        });

        toast.success('Comanda fechada com sucesso.');
      } catch (error) {
        toast.error('Não foi possível finalizar a comanda! tente novamente');
      }
    },
    []
  );

  const printTableBill = useCallback(
    async ({ session_key, table_id }) => {
      try {
        await api.put('restaurants/close-table-bill', {
          key: session_key,
        });

        setClosingTableBills(state => {
          return state.filter(item => item.table.id !== table_id)
        })
        toast.success('Comanda fechada com sucesso.');
      } catch (error) {
        toast.error('Não foi possível finalizar a comanda! tente novamente');
      }
    },
    []
  );

  const printQueueTable = useCallback(
    async (item) => {
      try {
        await api.post('restaurants/printers/printer-queue', {
          table_session_id: item.id,
          close_after_print: true,
        });

        const newTableBills = closingTableBills.filter(
          (bill) => bill.id !== item.id
        );
        setClosingTableBills(newTableBills);
        getTablesAvailable();
        toast.success('Mesa fechada e impressa com sucesso.');
      } catch (error) {
        toast.error('Erro ao fechar comanda.');
      }
    },
    [closingTableBills, getTablesAvailable]
  );

  //aqui
  // useEffect(() => {
  //   getAllClosingBills();
  // }, [getAllClosingBills]);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:closingIndividualBills',
      JSON.stringify(closingIndividualBills)
    );
  }, [closingIndividualBills]);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:closingTableBills',
      JSON.stringify(closingTableBills)
    );
  }, [closingTableBills]);

  /*
  useEffect(() => {
    let interval;
    if (user) {
      interval = setInterval(getAllClosingBills, 30000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [getAllClosingBills, user]);
  */

  const [modalTableClosing, setModalTableClosing] = useState(false);
  const [sessionTableClosing, setSessionTableClosing] = useState('');

  const toggleModalTableclosing = useCallback(() => {
    setModalTableClosing(!modalTableClosing);
  }, [modalTableClosing]);

  //credit card closing
  const [sessionTableClosingByCreditCard, setSessionTableClosingByCreditCard] = useState({});
  const [modalTableClosingByCreditCard, setModalTableClosingByCreditCard] = useState(false);

  const toggleModalTableClosingByCreditCard = useCallback(() => {
    setModalTableClosingByCreditCard(!modalTableClosingByCreditCard);
  }, [modalTableClosingByCreditCard]);

  useEffect(() => {
    if (websocketManager) {
      websocketManager.addMessageCallback(1, (data) => {
        if (data.type === 'table-closing') {
          const session = data.item;
          setSessionTableClosing(session);
          toggleModalTableclosing();
          setClosingTableBills((state) => [session, ...state]);
        } else if (data.type === 'credit-card-payment') {
          const session = data.item;

          setSessionTableClosingByCreditCard(session)
          toggleModalTableClosingByCreditCard();
          getTableBills()
          getAllOrders()
          getTablesAvailable()

        } else if (data.type === 'bill-closing') {
          const bill = data.item;

          setClosingIndividualBills((state) => [bill, ...state]);
        } else if (data.type === 'table-finished') {
          const { table_session_id } = data.item;

          setClosingTableBills((state) =>
            state.filter((s) => s.id !== table_session_id)
          );
        } else if (data.type === 'bill-finished') {
          const { bill_id } = data.item;

          setClosingIndividualBills((state) =>
            state.filter((s) => s.bill.id !== bill_id)
          );
        }
      });
    }
  }, [websocketManager, toggleModalTableclosing]);

  const [bills, setBills] = useState([]);
  const [printBills, setPrintBills] = useState([]);
  const [sessionBills, setSessionbills] = useState([]);
  const [productBills, setProductBills] = useState([]);

  const [commandTableNumber, setCommandTableNumber] = useState();

  const getBillsSession = useCallback(async () => {
    const response = await api.get(
      `restaurants/table-sessions-bills/${sessionTableClosing.id}`
    );

    setBills(response.data.bills);
    setPrintBills(response.data.print_bills);
    setSessionbills(response.data.session);
    setProductBills(response.data.products);

    const baskets = [];

    const basket = response.data.bills?.map((bill) =>
      bill.order_baskets.map((item) => baskets.push(item))
    );

    const sorted = baskets.sort((a, b) => {
      if (a.id < b.id) {
        return 1;
      }

      if (a.id > b.id) {
        return -1;
      }

      return 0;
    });

    setCommandTableNumber(sorted[0]);

    // setPrintBills(response.data.print_bills);
  }, [sessionTableClosing.id]);

  const value = useMemo(
    () => ({
      closingIndividualBills,
      closingTableBills,
      getClosingIndividualBills,
      getClosingTableBills,
      getAllClosingBills,
      printIndividualBill,
      printTableBill,
      printQueueTable,
    }),
    [
      closingIndividualBills,
      closingTableBills,
      getAllClosingBills,
      printQueueTable,
      getClosingIndividualBills,
      printIndividualBill,
      printTableBill,
      getClosingTableBills,
    ]
  );

  const page = window.location.pathname;

  return (
    <ClosingContext.Provider value={value}>
      {children}

      {page !== '/operation/kds' && (
        <>

          <Modal isOpen={modalTableClosing} toggle={toggleModalTableclosing}>
            <ModalBody>
              <h2 style={{ textAlign: 'center' }}>
                {sessionTableClosing?.table?.table_type === "command" ?
                  `Comanda ${sessionTableClosing?.table?.table_number} / Mesa ${sessionTableClosing.bills[0]?.command_table_number ?
                    sessionTableClosing.bills[0]?.command_table_number : "não informada"} solicitando fechamento`
                  :
                  `Mesa ${sessionTableClosing?.table?.table_number} solicitando
                  fechamento`
                }

              </h2>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                className="btn btn-info"
                onClick={toggleModalTableclosing}
              >
                Ciente
              </button>
              <ReactToPrint
                onBeforeGetContent={async () => {
                  await getBillsSession();
                }}
                trigger={() => (
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={toggleModalTableclosing}
                  >
                    <div>
                      <AiOutlinePrinter size={17} style={{ marginRight: 3 }} />
                      Fechar Mesa
                    </div>
                  </button>
                )}
                content={() => componentRef.current}
                onAfterPrint={() => {
                  printTableBill({
                    session_key: sessionTableClosing.key,
                  });
                  setSessionTableClosing('');
                  toggleModalTableclosing();
                  // renderOperation();
                }}
              />
            </ModalFooter>
          </Modal>


          <Modal isOpen={modalTableClosingByCreditCard} toggle={toggleModalTableClosingByCreditCard}>
            <ModalBody>
              <h1 style={{ textAlign: 'left', fontSize: 22 }}>
                Novo pagamento via cartão de crédito
              </h1>
              <p>Mesa: {sessionTableClosingByCreditCard?.table_number}</p>
              <p>Comanda: #{sessionTableClosingByCreditCard?.session_id}</p>
              <p>Tipo: {sessionTableClosingByCreditCard?.closed_type === "session" ? "Fechamento de comanda completa" : "Fechamento Individual"}</p>
              <p>
                Valor: R$ {sessionTableClosingByCreditCard?.paid_value}
              </p>
              <p>Cliente: {sessionTableClosingByCreditCard?.buyer?.phone}</p>


            </ModalBody>
            <ModalFooter style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                type="button"
                className="btn btn-info"
                onClick={toggleModalTableClosingByCreditCard}
              >
                Ciente
              </button>

            </ModalFooter>
          </Modal>

          <button type="button" style={{ display: 'none' }}>
            <PrintBillsTableOperation
              item={sessionTableClosing}
              restaurant={user}
              ref={componentRef}
              paymentData={sessionTableClosing?.payments}
              printBills={printBills}
              sessionBills={sessionBills}
              productBills={productBills}
              commandTableNumber={commandTableNumber}
            />
          </button>
        </>
      )}



    </ClosingContext.Provider>
  );
};

function useClosing() {
  const context = useContext(ClosingContext);

  if (!context) {
    throw new Error('useClosing must be used within an ClosingProvider');
  }

  return context;
}

export { ClosingProvider, useClosing };
