import React from 'react';

import { MdDone } from 'react-icons/md';
import { Spinner } from 'reactstrap';
import { Header, Bloco } from './styles';
import { Content, Icon, ItemsCount, LoadingDiv } from '../styles';
import OrderFinished from '~/components/Orders/Items/OrderFinished';

export default function CardOrdersFinished({
  baskets,
  loading,
  handleCancelItem,
}) {
  const count_orders = baskets.filter(
    (order) => order.table.table_type === 'delivery'
  ).length;
  return (
    <Bloco>
      <Header>
        <Icon>
          <MdDone color="#fff" size={25} />
        </Icon>
        FINALIZADOS
        <ItemsCount>{count_orders}</ItemsCount>
      </Header>
      <Content>
        {loading ? (
          <LoadingDiv>
            <Spinner color="success" />
          </LoadingDiv>
        ) : (
          baskets
            .filter((order) => order.table.table_type === 'delivery')
            .map((item) => (
              <OrderFinished
                key={item.basket.id}
                item={item}
                handleCancelItem={handleCancelItem}
              />
            ))
        )}
      </Content>
    </Bloco>
  );
}
