import styled from 'styled-components';

export const Container = styled.div`
  @page {
    size: 75mm auto;
    padding: 10px;
  }
  @media print {
    * {
      background: transparent;
      color: #000;
      text-shadow: none;
      filter: none;
      -ms-filter: none;
    }

    html,
    body {
      width: 75mm;
      height: auto;
    }
    h1 {
      margin-right: auto;
    }
    img {
      width: 70mm;
      height: auto;
    }
  }
`;

export const Table = styled.div`
  padding-top: 10px;
  width: 110mm;
  text-align: center;
  font-size: 40px;
  margin-right: auto;
`;

export const Order = styled.div`
  padding-top: 5px;
  width: 110mm;
  text-align: center;
  font-size: 25px;
  margin-right: auto;
`;

export const Restaurant = styled.div`
  padding-top: 10px;
  width: 110mm;
  text-align: center;
  font-size: 30px;
  margin-right: auto;
  padding-bottom: 15px;
  border-bottom: solid black 1px;
`;

export const Body = styled.div`
  width: 110mm;
  clear: both;
  display: flex;
  justify-content: space-between;
`;

export const Phone = styled.div`
  text-align: left;
  padding-left: 30px;
  font-size: 22px;
  padding-top: 10px;
`;

export const Time = styled.div`
  text-align: right;

  font-size: 25px;
  padding-top: 10px;
`;

export const Items = styled.div`
  width: 110mm;
  padding-top: 20px;
  padding-left: 30px;
  style: block;

  span {
    font-size: 20px;
  }
`;

export const Item = styled.div`
  padding-left: 20px;
  padding-top: 12px;
`;

export const ItemName = styled.div`
  font-size: 22px;

  strong {
    font-size: 22px;
  }
`;

export const ComplementsTitle = styled.div`
  font-size: 20px;
  padding-left: 15px;
`;

export const Complements = styled.div`
  font-size: 20px;
`;

export const Obs = styled.div`
  font-size: 20px;
`;

export const Footer = styled.div`
  margin-top: 20px;
  padding-top: 10px;
  border-top: solid black 1px;
  font-size: 25px;
  text-align: center;
  width: 110mm;
  padding-bottom: 30px;
`;

export const DeliveryInfo = styled.div`
  padding-top: 10px;
  margin-top: 10px;
  border-top: solid black 1px;
  padding-bottom: 10px;
  border-bottom: solid black 1px;
  display: flex;
  flex-direction: column;
  padding-left: 30px;

  span {
    font-size: 20px;
  }
`;

export const DeliveryPrice = styled.div`
  padding-top: 15px;
  margin-top: 10px;
  border-top: solid black 1px;
  padding-bottom: 10px;
  width: 110mm;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 10px;

  div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    span {
      font-size: 22px;
      font-weight: bold;
    }
  }
`;
