import React, { useState, useRef, useCallback, useEffect } from 'react';
import ReactToPrint from 'react-to-print';
import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';

import { Container, Modal, ModalBody, ModalFooter } from 'reactstrap';
import {
  FaCheck,
  FaEye,
  FaMotorcycle,
  FaPlus,
  FaPrint,
  FaRegClock,
  FaTimes,
} from 'react-icons/fa';
import api from '~/services/api';
import DeleteItemOrders from '~/components/Orders/DeleteItemOrders';
import { OrderNumber, PhoneNumber, CardBody, DateArea, BillInfo, CardHeader, UserDeliveryInfo, UserDeliveryInfoArea } from '../styles';
import {
  Print,
  DisplayNoneImprimir,
  PrintOutline,
  Footer,
  ContainerOrders,
  ButtonCancel,
  ModalContainer,
  ModalContainerHeader,
  CardContainer,
  Actions,
} from './styles';

import formatPhone from '~/services/formatPhoneString';
import EyeModalOrders from '~/components/EyeModalOrders';
import IfoodOnDemand from '~/components/Delivery/IfoodOnDemand';
import PrintNewOrderComponent from '~/components/PrintNewOrderComponent';
import { useAuth } from '~/context/AuthContext';
import { handleNotifyWhatsapp } from '~/utils/handleNotifyWhatsapp';
import logoTakeat from '../../../../assets/img/garfinho_red.png';
import logoIfood from '../../../../assets/img/ifood-logo.png';
import { useMotoboys } from '~/context/MotoboysContext';
import {
  Button,
  ActionButton,
  DefaultSelect,
  Checkbox,
  Tabs,
} from 'ui-kit-takeat';
import CreateMotoboy from '~/pages/Motoboys/Create';
import TableEditDelivery from '~/components/Operation/TablesOperation/TableEditDelivery';
import { useIfood } from '~/context/iFoodContext';
import { SiIfood } from 'react-icons/si';
import formatDateYYYY from '~/services/formatDateYYYY';
import formatTime from '~/services/formatTime';

export default function OrderAndamentoDelivery({
  item,
  handleItemFinished,
  handleCancelItem,
  handleItemReady,
  handleUpdate,
}) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [btnDisable, serBtnDisable] = useState(false);
  const [session, setSession] = useState(null);
  const { motoboys } = useMotoboys();
  const { user } = useAuth();

  const initialData = {
    motoboy: {
      value: motoboys[0]?.id,
      label: motoboys[0]?.name,
    },
  };

  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);

  const [modalDeleteDelivery, setModalDeleteDelivery] = useState(false);
  const toggleDeleteDelivery = () => {
    setModalDeleteDelivery(!modalDeleteDelivery);
    serBtnDisable(!btnDisable);
  };

  const [motoboyTab, setMotoboyTab] = useState(0);

  const [creatingMotoboy, setCreatingMotoboy] = useState(false);
  const [modalMotoboy, setModalMotoboy] = useState(false);

  const [motoboySelected, setMotoboySelected] = useState(null);
  const toggleModalMotoboy = () => {
    setModalMotoboy(!modalMotoboy);

    // onDemandData && setOnDemandData(null);
    // onDemandError && setOnDemandError(null);
    // motoboyTab === 1 && setMotoboyTab(0);
  };

  const [sendMsgMotoboy, setSendMsgMotoboy] = useState(
    JSON.parse(localStorage.getItem('@gddashboard:sendMsgMotoboy')) || false
  );

  const toggleMsgMotoboy = () => {
    setSendMsgMotoboy(!sendMsgMotoboy);
    localStorage.setItem('@gddashboard:sendMsgMotoboy', !sendMsgMotoboy);
  };

  const [orders, setOrders] = useState([]);
  const getOrders = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/basket-orders/${item.basket.id}`
      );

      setOrders(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [item]);

  const getSession = async () => {
    const response = await api.get(
      `/restaurants/delivery/session-orders/${item.session_id}`
    );

    setSession(response.data?.orders[0]);
  };

  useEffect(() => {
    if (modalDelete) {
      getSession();
    }
  }, [modalDelete, getSession]);

  useEffect(() => {
    if (modalDelete) {
      getOrders();
    }
  }, [modalDelete, getOrders]);

  const componentRef = useRef(null);

  const [loadingPrinter, setLoadingPrinter] = useState(false);

  async function printClick() {
    try {
      await api.post('restaurants/printers/printer-queue', {
        order_basket_id: item.basket.id,
        order_type: 'new_order',
      });
      toast.success('Pedido impresso.');
    } catch (error) {
      toast.error('Erro ao imprimir pedido.');
    }
  }

  async function printDeliverySession() {
    setLoadingPrinter(true);
    try {
      await api.post('restaurants/printers/printer-queue', {
        table_session_id: item.session_id,
        is_delivery: true,
      });

      toast.success('Pedido enviado para impressão.');
    } catch (error) {
      toast.error('Erro ao imprimir pedido.');
    }
    setLoadingPrinter(false);
  }

  const userName = item?.buyer
    ? formatPhone(
      item?.buyer?.phone
        ? item?.buyer.phone || item?.buyer.name
        : item?.buyer?.ifood_phone
    )
    : item?.waiter?.name;

  const userNameDelivery = item?.buyer
    ? item?.buyer?.ifood_phone
      ? item?.buyer?.ifood_phone
      : item?.buyer?.phone
    : item?.waiter?.name;

  function searchTableName(table) {
    if (table.table_type === 'delivery') {
      if (item?.with_withdrawal && item?.scheduled_to) {
        return 'Retirada Agendada';
      }
      if (item?.with_withdrawal) {
        return 'Retirada';
      }

      if (!item?.with_withdrawal && item?.scheduled_to) {
        return 'Delivery Agendado';
      }

      return 'Delivery';
    }

    if (table.table_type === 'prepaid') {
      return `Mesa  ${table.table_number} - Pagamento Antecipado`;
    }

    return `Mesa  ${table.table_number}`;
  }

  const handleAddMotoboy = async (data) => {
    try {
      const response = await api.post('/restaurants/motoboys/relate', {
        motoboy_id: motoboySelected.value,
        session_id: item.session_id,
      });

      item.motoboy = response.data;

      toast.success('Motoboy adicionado à entrega');
      toggleModalMotoboy();
      handleItemReady(
        item.basket.id,
        item.basket.ifood_id,
        item.with_withdrawal
      );
      if (!item.basket.ifood_id) {
        handleNotifyWhatsapp(item, orders, user, 'ready');
        if (sendMsgMotoboy && motoboySelected.phone) {
          console.log('Item: ', item);
          handleNotifyWhatsapp(item, orders, user, 'motoboy');
        }
      }
    } catch (err) {
      console.log('handleAddMotoboy Error: ', err);
      toast.error('Não foi possível adicionar motoboy à essa entrega');
    }
  };

  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);

  // useEffect(() => {
  //   if(motoboyTab === 1 && ifoodRestaurants.length === 0){
  //     getIfoodMenus();
  //   }
  // }, [motoboyTab]);

  return (
    <CardContainer>
      <CardHeader>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <BillInfo>
            <img
              src={item.basket.ifood_id ? logoIfood : logoTakeat}
              style={{ width: item.basket.ifood_id ? 38 : 20, height: 25 }}
            />
            <OrderNumber>#{item?.attendance_password}</OrderNumber>
            <OrderNumber>
              {item?.table.table_type === 'command' &&
                item?.table.table_type !== 'delivery' ? (
                `Comanda  ${item.table.table_number} / Mesa ${item?.basket?.command_table_number || 'não informada'
                } `
              ) : (
                <>
                  {item?.basket?.ifood_table
                    ? item?.basket?.ifood_table
                    : searchTableName(item?.table)}
                </>
              )}
            </OrderNumber>
          </BillInfo>

          {item?.ifood_restaurant?.name && (
            <p style={{ textAlign: "left" }}>
              {item.ifood_restaurant.name}
            </p>
          )}

        </div>
        <span>
          {item?.scheduled_to
            ? (
              <DateArea>
                <span style={{ fontSize: 12 }}>Agendado para:</span>
                <strong>
                  {formatDateYYYY(item?.scheduled_to)}
                </strong>
                <span>
                  <FaRegClock /> {formatTime(item?.scheduled_to)}
                </span>
              </DateArea>
            )
            : (
              <DateArea>
                <strong>
                  {formatDateYYYY(item?.basket.start_time)}
                </strong>
                <span>
                  <FaRegClock /> {formatTime(item?.basket.start_time)}
                </span>
              </DateArea>
            )}
        </span>
      </CardHeader>
      <CardBody>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
          {item?.buyer?.name && (
            <PhoneNumber style={{ fontWeight: 600 }}>{item.buyer.name}</PhoneNumber>
          )}
          {item?.basket?.schedule && (

            <PhoneNumber>
              <strong>{item?.basket?.schedule}</strong>
            </PhoneNumber>

          )}
          <PhoneNumber>{userNameDelivery}</PhoneNumber>
          {
            !item?.with_withdrawal && (
              <UserDeliveryInfoArea>
                {item?.buyer?.delivery_address?.neighborhood && (
                  <UserDeliveryInfo>
                    Bairro:  {item?.buyer?.delivery_address?.neighborhood}
                    - {item?.buyer?.delivery_address?.city}
                  </UserDeliveryInfo>
                )}

                {item?.buyer?.delivery_address?.zip_code && (
                  <UserDeliveryInfo>
                    CEP:  {item?.buyer?.delivery_address?.zip_code}
                  </UserDeliveryInfo>
                )}
              </UserDeliveryInfoArea>
            )
          }
        </div>
        <Actions>
          <FaCheck
            color="#2ec9b7"
            onClick={() => {
              if (
                !item?.with_withdrawal &&
                (motoboys.length > 0 ||
                  (user.has_ifood && [2, 15516, 49803, 31884, 54753, 44424].includes(user.id)))
              ) {
                toggleModalMotoboy();
                getOrders();
              } else {
                handleItemReady(
                  item.basket.id,
                  item.basket.ifood_id,
                  item.with_withdrawal
                );
                if (!item.basket.ifood_id) {
                  handleNotifyWhatsapp(item, orders, user, 'ready');
                }
              }
            }}
            size={16}
          />

          <FaEye color="#ffa814" onClick={toggle} size={20} />

          <TableEditDelivery
            item={item}
            toggleDeleteDelivery={toggleDeleteDelivery}
            handleUpdate={handleUpdate}
          />
        </Actions>
      </CardBody>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <ContainerOrders browserZoomLevel={browserZoomLevel}>
            <EyeModalOrders item={item} />
          </ContainerOrders>
        </ModalBody>
        <Footer
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 20,
          }}
        >
          {user.is_printed_on_web ? (
            <ReactToPrint
              onBeforeGetContent={async () => {
                await getOrders();
              }}
              trigger={() => (
                <Print>
                  <FaPrint color="#fff" size={20} />
                  Imprimir
                </Print>
              )}
              content={() => componentRef.current}
            />
          ) : (
            <>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <PrintOutline onClick={printClick}>
                  <FaPrint color="#ff2c3a" size={20} />
                  Imprimir em ambiente de produção
                </PrintOutline>
                {user.print_bills_delivery_on_web ? (
                  <ReactToPrint
                    onBeforeGetContent={async () => {
                      await getOrders();
                    }}
                    trigger={() => (
                      <Print>
                        <FaPrint color="#fff" size={20} />
                        Imprimir via de entrega
                      </Print>
                    )}
                    content={() => componentRef.current}
                  />
                ) : (
                  <Print
                    onClick={printDeliverySession}
                    disabled={loadingPrinter}
                  >
                    <FaPrint color="#fff" size={20} />
                    Imprimir via de entrega
                  </Print>
                )}
              </div>
            </>
          )}
        </Footer>
      </Modal>
      <Modal isOpen={modalDeleteDelivery} toggle={toggleDeleteDelivery}>
        <ModalBody>
          <Container>
            <h3>Tem certeza que deseja recusar o pedido?</h3>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            title="Recusar o pedido"
            onClick={() =>
              handleCancelItem({
                from: 'accepted',
                order_basket_id: item.basket.id,
                session_id: item.session_id,
                ifood_id: item.basket.ifood_id,
              })
            }
          />
          <Button onClick={() => toggleDeleteDelivery()} title="Desistir" />
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalMotoboy} toggle={toggleModalMotoboy}>
        {/* Aqui que fica a disponibilidade de selecionar o Entrega Fácil */}
        {user.has_ifood &&
          [2, 15516, 49803, 31884, 54753, 44424].includes(user.id) &&
          !item?.basket?.ifood_id &&
          item?.buyer?.delivery_address?.latitude &&
          item?.buyer?.delivery_address?.longitude && (
            <div>
              <Tabs
                containerStyles={{
                  gap: 0,
                }}
                onChangeTab={(e) => setMotoboyTab(e)}
                tabSelectedStyles={{
                  borderBottom: '1px solid red',
                }}
                tabStyles={{
                  borderTopLeftRadius: '0.2857rem',
                  boxShadow: 'none',
                }}
                tabs={[
                  <div>
                    <FaMotorcycle
                      color="#FF2C3A"
                      size={17}
                      style={{ marginRight: 5 }}
                    />
                    Motoboy próprio
                  </div>,
                  <div>
                    <SiIfood
                      color="#FF2C3A"
                      size={17}
                      style={{ marginRight: 5 }}
                    />
                    Entrega Fácil iFood
                  </div>,
                ]}
                selected={motoboyTab}
              />
            </div>
          )}

        {motoboyTab === 0 && (
          <div>
            {creatingMotoboy ? (
              <CreateMotoboy
                toggleModalRegisterMotoboy={() => setCreatingMotoboy(false)}
                motoboyCreated={(data) => {
                  setMotoboySelected(data);
                  setCreatingMotoboy(false);
                }}
              />
            ) : (
              <Form onSubmit={handleAddMotoboy} initialData={initialData}>
                <ModalContainer>
                  <ModalContainerHeader>
                    <h3>Adicionar motoboy a essa entrega?</h3>
                    <FaTimes
                      size={20}
                      color="red"
                      onClick={() => toggleModalMotoboy()}
                    />
                  </ModalContainerHeader>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '20px',
                    }}
                  >
                    <DefaultSelect
                      name="motoboy"
                      width="-webkit-fill-available"
                      options={motoboys.map((m) => {
                        return { value: m.id, label: m.name, phone: m.phone };
                      })}
                      value={motoboySelected}
                      onChange={(e) => setMotoboySelected(e)}
                    />

                    <ActionButton
                      icon={<FaPlus />}
                      buttonColor="#2ec9b7"
                      minWidth={40}
                      onClick={() => setCreatingMotoboy(true)}
                    />
                  </div>

                  {user.use_wpp && motoboySelected !== null && (
                    <div>
                      <Checkbox
                        checked={sendMsgMotoboy && motoboySelected.phone}
                        label="Enviar detalhes do pedido para motoboy"
                        onClick={() => toggleMsgMotoboy()}
                        disabled={!motoboySelected.phone}
                      />

                      {!motoboySelected.phone && (
                        <span style={{ color: 'red' }}>
                          Este motoboy não possui telefone cadastrado
                        </span>
                      )}
                    </div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        toggleModalMotoboy();
                        handleItemReady(
                          item.basket.id,
                          item.basket.ifood_id,
                          item.with_withdrawal
                        );
                        if (!item.basket.ifood_id) {
                          handleNotifyWhatsapp(item, orders, user, 'ready');
                        }
                      }}
                      title="Não adicionar motoboy"
                    />
                    <Button
                      type="submit"
                      onClick={() => { }}
                      title="Adicionar"
                      buttonColor="#2ec9b7"
                    />
                  </div>
                </ModalContainer>
              </Form>
            )}
          </div>
        )}

        {motoboyTab === 1 && (
          <IfoodOnDemand
            item={item}
            user={user}
            handleItemReady={handleItemReady}
            toggleModal={toggleModalMotoboy}
            orders={orders}
          />
        )}
      </Modal>

      <Modal isOpen={modalDelete} toggle={toggleDelete}>
        <ModalBody>
          <Container>
            <DeleteItemOrders
              item={item}
              modalDelete={modalDelete}
              from="accepted"
              orders={orders}
            />
          </Container>
        </ModalBody>
        <ModalFooter>
          <ButtonCancel
            color="danger"
            onClick={() =>
              handleCancelItem({
                order_basket_id: item.basket.id,
                from: 'accepted',
              })
            }
          >
            Cancelar todos de uma vez
          </ButtonCancel>
        </ModalFooter>
      </Modal>
      <DisplayNoneImprimir>
        <PrintNewOrderComponent
          restaurant={user}
          item={item}
          ref={componentRef}
          orders={orders}
        />
      </DisplayNoneImprimir>
    </CardContainer>
  );
}