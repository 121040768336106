import styled from 'styled-components';

export const InnerArea = styled.div`
  margin-top: 15px;
`;

export const ButtonsArea = styled.div`
  display: flex;
  flex-grow: 0;

  justify-content: space-between;
  width: 100%;

  padding-top: 20px;

  border-top: 1px solid #c4c4c4;
  background: #fff;
`;
