import React from "react";
import { Button, Container, Fill, Group } from "./styles";

export const RadioGroup = ({
  selected,
  onClick,
  options,
  horizontal,
  labelStyle,
}) => {
  return (
    <Group horizontal={horizontal}>
      {options.map((op) => (
        <Container
          onClick={() => {
            if (onClick) {
              onClick(op.value);
            }
          }}
        >
          <Button>
            <Fill selected={selected === op.value} />
          </Button>

          <span style={labelStyle}>
            {op.label}
          </span>
        </Container>
      ))}
    </Group>
  );
};



