import React from 'react';
import { CategoryAddedContainer } from './styles';
import { ComplementAdded } from '../ComplementAdded';

export const CategoryAdded = ({ category }) => {
  return (
    <CategoryAddedContainer>
      <h3>{category.name}</h3>
      {category.complements.map((complement) => {
        return (
          <ComplementAdded
            key={complement.id}
            category={category}
            complement={complement}
          />
        );
      })}
    </CategoryAddedContainer>
  );
};
