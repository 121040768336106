import React, { useState, useEffect, useCallback } from "react";
import {
  ModalContainer,
  ModalContainerHeader,
} from "./styles";
import {
  Modal,
} from 'reactstrap';
import { Form } from '@unform/web';
import { handleNotifyWhatsapp } from '~/utils/handleNotifyWhatsapp';
import { useMotoboys } from '~/context/MotoboysContext';
import api from '~/services/api';
import { Button, DefaultSelect } from 'ui-kit-takeat';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-hot-toast';

const AddMotoboy = (props) => {
  const { toggleModalMotoboy, item, user, modalMotoboy, alterOrder } = props;
  const { motoboys } = useMotoboys();
  const [orders, setOrders] = useState([]);
  const getOrders = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/basket-orders/${item.basket.id}`
      );

      setOrders(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [item]);
  useEffect(() => {
    getOrders();
  }, [])
  const [motoboySelected, setMotoboySelected] = useState(null);
  const initialData = {
    motoboy: {
      value: motoboys[0]?.id,
      label: motoboys[0]?.name,
    },
  };
  const handleAddMotoboy = async (data) => {
    try {
      const response = await api.post('/restaurants/motoboys/relate', {
        motoboy_id: motoboySelected.value,
        session_id: item.session_id,
      });

      item.motoboy = response.data;

      toast.success('Motoboy adicionado à entrega');
      alterOrder();
      toggleModalMotoboy();
      if (!item.basket.ifood_id) {
        handleNotifyWhatsapp(item, orders, user, 'ready');
      }
    } catch (err) {
      console.log('handleAddMotoboy Error: ', err);
      toast.error('Não foi possível adicionar motoboy à essa entrega');
    }
  };

  return (
    <>
    <Modal isOpen={modalMotoboy} toggle={toggleModalMotoboy}>
        <Form onSubmit={handleAddMotoboy} initialData={initialData}>
          <ModalContainer>
            <ModalContainerHeader>
              <h3>Adicionar motoboy a essa entrega?</h3>
              <FaTimes
                size={20}
                color="red"
                onClick={() => toggleModalMotoboy()}
              />
            </ModalContainerHeader>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <DefaultSelect
                name="motoboy"
                width="-webkit-fill-available"
                options={motoboys.map((m) => {
                  return { value: m.id, label: m.name, phone: m.phone };
                })}
                value={motoboySelected}
                onChange={(e) => setMotoboySelected(e)}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  toggleModalMotoboy();
                  if (!item.basket.ifood_id) {
                    handleNotifyWhatsapp(item, orders, user, 'ready');
                  }
                  alterOrder();
                }}
                title="Não adicionar motoboy"
              />
              <Button
                type="submit"
                onClick={() => {}}
                title="Adicionar"
                buttonColor="#2ec9b7"
              />
            </div>
          </ModalContainer>
        </Form>
      </Modal>
    </>
  )
}

export default AddMotoboy;