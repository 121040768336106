/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';
import { v4 } from 'uuid';

import { MdDelete, MdEdit } from 'react-icons/md';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';
import { Container, Actions, Delete } from './styles';
import Input from '~/components/Form/Input';

import api from '~/services/api';

function ComplementIntermediaryRow({
  intermediary,
  setIntermediaries,

  getCategories,
  setCount,
  setTotalInputs,
  isInsideProduct = false,
  complement,

  setComplementCategoriesInsideProduct,
  complementCategoriesInsideProduct,
}) {
  const { id } = intermediary.ComplementIntermediary;

  const handleDelete = useCallback(async () => {
    try {
      if (
        window.confirm(
          'Tem certeza que deseja desassociar o produto intermediário deste complemento?'
        )
      ) {
        const response = await api.delete(
          `restaurants/complement-intermediary/${id}`
        );

        setIntermediaries(response.data.intermediaries);

        const arrayInputs = [];

        response.data.inputs &&
          response.data.inputs.forEach((input) => {
            const total = parseFloat(
              (
                parseFloat(input.unitary_price) *
                parseFloat(input.ComplementInput.quantidade)
              ).toFixed(2)
            );
            arrayInputs.push(total);
          });

        response.data.intermediaries &&
          response.data.intermediaries.forEach((inter) => {
            const total = parseFloat(
              (
                parseFloat(inter.unitary_price) *
                parseFloat(inter.ComplementIntermediary.quantidade)
              ).toFixed(2)
            );
            arrayInputs.push(total);
          });

        if (arrayInputs.length > 0) {
          const inputsTotal = arrayInputs.reduce(
            (Acc, input) => Acc + input,
            0
          );
          setTotalInputs(inputsTotal);
        }

        if (isInsideProduct) {
          const newCategories = [...complementCategoriesInsideProduct];

          newCategories.map((cat, idix) =>
            cat.complements.map((item, idex) => {
              if (item.id === complement.id) {
                newCategories[idix].complements[idex].intermediaries =
                  response.data.intermediaries;
              }
            })
          );

          setComplementCategoriesInsideProduct(newCategories);
        } else {
          const aleatoryId = v4();

          setCount(aleatoryId);
          getCategories();
        }

        toast.success('Insumo desassociado a esse complemento com sucesso!');
      }
    } catch (err) {
      toast.error('Erro ao desassociar insumo.');
      console.log(err.message);
    }
  }, [
    getCategories,

    id,
    setCount,
    complement,
    isInsideProduct,
    setIntermediaries,
    setTotalInputs,
    complementCategoriesInsideProduct,
    setComplementCategoriesInsideProduct,
  ]);

  const handleUpdateIntermediary = useCallback(
    async (data) => {
      try {
        const handleData = {
          quantidade: data.quantidade.replace(',', '.'),
        };

        const response = await api.put(
          `/restaurants/complement-intermediary/${id}`,
          handleData
        );
        setIntermediaries(response.data.intermediaries);

        const arrayInputs = [];

        response.data.inputs &&
          response.data.inputs.forEach((input) => {
            const total = parseFloat(
              (
                parseFloat(input.unitary_price) *
                parseFloat(input.ComplementInput.quantidade)
              ).toFixed(2)
            );
            arrayInputs.push(total);
          });

        response.data.intermediaries &&
          response.data.intermediaries.forEach((inter) => {
            const total = parseFloat(
              (
                parseFloat(inter.unitary_price) *
                parseFloat(inter.ComplementIntermediary.quantidade)
              ).toFixed(2)
            );
            arrayInputs.push(total);
          });

        if (arrayInputs.length > 0) {
          const inputsTotal = arrayInputs.reduce(
            (Acc, input) => Acc + input,
            0
          );
          setTotalInputs(inputsTotal);
        }

        if (isInsideProduct) {
          const newCategories = [...complementCategoriesInsideProduct];

          newCategories.map((cat, idix) =>
            cat.complements.map((item, idex) => {
              if (item.id === complement.id) {
                newCategories[idix].complements[idex].intermediaries =
                  response.data.intermediaries;
              }
            })
          );

          setComplementCategoriesInsideProduct(newCategories);
        } else {
          const aleatoryId = v4();

          setCount(aleatoryId);
        }

        // getCategories();
        // getComplements();

        toast.success('Produto intermediário atualizado com sucesso!');
      } catch (error) {
        toast.error('Erro ao atualizar produto intermediário.');
        console.log(error.message);
      }
    },
    [
      id,
      setCount,
      setIntermediaries,
      setTotalInputs,
      complement,
      isInsideProduct,
      complementCategoriesInsideProduct,
      setComplementCategoriesInsideProduct,
    ]
  );

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const inter_initial = {
    name: intermediary.name,
    quantidade: intermediary.ComplementIntermediary.quantidade,
  };

  return (
    <>
      <tr>
        <td>{intermediary.name}</td>
        <td>
          {intermediary.ComplementIntermediary.quantidade}{' '}
          {intermediary.unidade}
        </td>
        <td>
          <Actions>
            <Delete onClick={toggle}>
              <MdEdit size={20} color="#17a2b8" />
            </Delete>
            <Delete onClick={() => handleDelete()}>
              <MdDelete size={20} />
            </Delete>
          </Actions>
          <Modal isOpen={modal} toggle={toggle}>
            <Form
              onSubmit={handleUpdateIntermediary}
              initialData={inter_initial}
            >
              <ModalHeader>
                Editar Informações do Produto Intermediário
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col md="6">
                      <Input
                        label="Produto Intermediário:"
                        name="name"
                        type="text"
                        disabled
                      />
                    </Col>
                    <Col md="5">
                      <Input
                        label="Esse Produto Gasta:"
                        name="quantidade"
                        type="text"
                      />
                    </Col>
                    <Col style={{ paddingTop: 45 }}>
                      <p>{intermediary.unidade}</p>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" type="submit" onClick={toggle}>
                  Salvar
                </Button>{' '}
                <Button color="danger" onClick={toggle}>
                  Cancelar
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        </td>
      </tr>
    </>
  );
}

export default ComplementIntermediaryRow;
