import XLSX from 'xlsx-js-style';
import style from './sheetStyle';

import formatCompleteDate from '../formatCompleteDate';

function getMotoboyData(motoboys) {
  motoboys = motoboys.filter((m) => m.sessions.length);

  const headers = ['Motoboy', 'Cliente', 'Bairro', 'Taxa', 'Data'];

  const data = [];

  for (const m of motoboys) {
    const clientData = m.sessions.map((s) => {
      return [
        '',
        s.buyer_name || s.buyer_phone || '',
        s.neighborhood || '',
        parseFloat(s.delivery_tax_price),
        formatCompleteDate(s.created_at),
      ];
    });

    clientData[0][0] = m.name;

    const total = m.sessions.reduce((acc, s) => {
      return acc + parseFloat(s.delivery_tax_price);
    }, 0);

    const footer = ['Total', '', '', total, ''];

    data.push(headers);
    data.push(...clientData);
    data.push(footer, []);
  }

  return data;
}

// /restaurants/motoboys/sessions
export function generateSheet(motoboys) {
  const rows = getMotoboyData(motoboys);

  const columns = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  const num_cols = 5;

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(rows);

  // Every column with 15 chars of width
  worksheet['!cols'] = new Array(num_cols);
  worksheet['!cols'].fill({ wch: 16 });

  const black = '000000';
  const blue = '729fcf';
  const light_blue = 'b4c7dc';

  for (let i = 0; i < rows.length; i += 1) {
    for (let j = 0; j < num_cols; j += 1) {
      const cell = worksheet[columns[j] + (i + 1)];

      switch (rows[i][0]) {
        case 'Motoboy':
          cell.s = style()
            .Bg(light_blue)
            .Borders('b', blue)
            .Get();
          break;
        case 'Total':
          cell.s = style()
            .Bg(light_blue)
            .Borders('t', blue)
            .Get();
          break;
        default:
          if (typeof rows[i][j] === 'number') {
            cell.s = style().Number().Get();
          } else if (rows[i][j] && j === num_cols - 1) {
            cell.s = style().Font(black, '', 'right').Get();
          }

          break;
      }
    }
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório Motoboys');

  return XLSX.write(workbook, { type: 'buffer' });
}
