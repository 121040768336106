import styled from 'styled-components';

export const Title = styled.div`
  h1 {
    color: #3d3d3d;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }
`;
