import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'ui-kit-takeat';
import { Container } from './styles';
import { NavBar } from './styles';
import { FaCashRegister } from 'react-icons/fa';
import { FaFilterCircleDollar } from 'react-icons/fa6';
import { PageTitle } from '~/components/PageTitle';

function Header() {
  const location = useLocation(); // Obtém a localização atual
  const [pathname, setPathname] = useState('');

  // Atualize o estado "pathname" sempre que a localização mudar
  useEffect(() => {
    setPathname(location.pathname);
  }, [location.pathname]);

  function getTitleName(path) {
    switch (path) {
      case '/operation/opening-box':
        return 'Caixa';

      case '/operation/cashier':
        return 'Controle de Caixa';

      case '/operation/audit-box':
        return 'Conferência de Caixa';

      default:
        break;
    }
  }

  return (
    <Container>
      <div>
        <PageTitle>{getTitleName(pathname)}</PageTitle>
      </div>

      <NavBar>
        <Link to={'/operation/opening-box'}>
          <Button
            title="Caixa"
            icon={<FaCashRegister />}
            inverted={pathname !== '/operation/opening-box'}
            onClick={() => { }}
          />
        </Link>
        <Link to={'/operation/cashier'}>
          <Button
            title="Controle de Caixa"
            icon={<FaFilterCircleDollar />}
            inverted={pathname !== '/operation/cashier'}
            onClick={() => { }}
          />
        </Link>
        {/* <Link to={'/operation/audit-box'}>
          <Button
            title="Conferência de Caixa"
            icon={<FaSearchDollar />}
            inverted={pathname !== '/operation/audit-box'}
            onClick={() => {}}
          />
        </Link> */}
      </NavBar>
    </Container>
  );
}

export default Header;
