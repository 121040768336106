import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Button as BtnReactStrap,
} from 'reactstrap';
import {
  Wrapper,
  Header,
  Card,
  StyledForm,
  Container,
  ButtonAdd,
  StyledInput,
} from './styles';
import getValidationErrors from '~/services/getValidationErrors';
import api from '~/services/api';

import Input from '~/components/Form/Input';
import Select from '~/components/Form/SelectInput';
import { useAuth } from '~/context/AuthContext';
import { Button, Checkbox } from 'ui-kit-takeat';
import { FaCheck, FaPlus } from 'react-icons/fa';
import { Form } from '@unform/web';
import { FaX } from 'react-icons/fa6';

function StorePayment({ toggle, getCustomPaymentMethods }) {
  const formRef = useRef(null);
  const [banks, setBanks] = useState();
  const { user } = useAuth();
  const [checkboxValues, setCheckboxValues] = useState({
    is_exclusive: false,
    delivery_accepts: true,
    available: true,
    include_weekends: false,
  });

  async function handleSubmit(data) {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        interval: Yup.string().required('Prazo obrigatório'),
        service_tax: Yup.string().required('A taxa é obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post('restaurants/custom-payment-methods', {
        name: data.name,
        available: checkboxValues.available,
        delivery_accepts: checkboxValues.delivery_accepts,
        interval: data.interval,
        service_tax: data.service_tax,
        include_weekends: !checkboxValues.include_weekends,
        bank_account_id: data.bank_account_id,
        method: data.method || null,
        is_exclusive: checkboxValues.is_exclusive,
      });

      toast.success('Forma de pagamento cadastrada com sucesso!');

      toggle();
      getCustomPaymentMethods();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else {
        toast.error(`Erro ao cadastrar forma de pagamento! ${error.message}`);
      }
      // console.tron.log(error);
    }
  }

  const getBanks = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows/bank-accounts');

      const banksOptions = response.data.map((bank) => {
        return {
          label: bank.name,
          value: bank.id,
        };
      });

      setBanks(banksOptions);
    } catch (error) {
      toast.error('Erro ao baixar contas');
    }
  }, []);

  useEffect(() => {
    getBanks();
  }, [getBanks]);

  const methodOptions = [
    { value: null, label: 'Nenhum' },
    { value: 'CREDIT', label: 'Crédito' },
    { value: 'DEBIT', label: 'Débito' },
  ];

  // create banks
  const formRefBank = useRef(null);
  const [isModalCreateBankOpen, setIsModalCreateBankOpen] = useState(false);

  const toggleModalCreateBank = useCallback(() => {
    setIsModalCreateBankOpen(!isModalCreateBankOpen);
  }, [isModalCreateBankOpen]);

  const handleBankSubmit = useCallback(
    async (data) => {
      try {
        if (formRefBank?.current) {
          formRefBank.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/restaurants/cash-flows/bank-accounts', {
          name: data.name,
        });

        toast.success('Conta cadastrada com sucesso!');

        getBanks();
        toggleModalCreateBank();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRefBank?.current) {
            formRefBank.current.setErrors(errors);
          }
        } else {
          // console.tron.log(error);
          toast.error(`Erro ao cadastrar conta!`);
        }
      }
    },
    [getBanks, toggleModalCreateBank]
  );

  return (
    <Wrapper>
      <Modal isOpen={isModalCreateBankOpen} toggle={toggleModalCreateBank}>
        <Form onSubmit={handleBankSubmit} ref={formRefBank}>
          <ModalBody>
            <Input label="Nome do banco" name="name" />
          </ModalBody>
          <ModalFooter>
            <BtnReactStrap color="danger" onClick={toggleModalCreateBank}>
              Cancelar
            </BtnReactStrap>
            <BtnReactStrap color="success" type="submit">
              Salvar
            </BtnReactStrap>
          </ModalFooter>
        </Form>
      </Modal>
      <Header>
        <h3>Cadastrar forma de pagamento</h3>
        <FaX onClick={toggle} cursor="pointer" color="#ff2c3a" />
      </Header>
      <Container>
        <Card>
          <StyledForm ref={formRef} onSubmit={handleSubmit}>
            <Row>
              <Col lg="7">
                <StyledInput
                  label="Nome"
                  name="name"
                  type="text"
                  placeholder="Forma de pagamento"
                />
              </Col>
              <Col lg="5">
                <Input
                  label="Taxa de transação %"
                  name="service_tax"
                  type="number"
                  step="0.01"
                  min="0"
                  placeholder="Taxa %"
                />
              </Col>
            </Row>
            <Row>
              <Col md="7">
                <Row>
                  <Col md="11">
                    <Select
                      name="bank_account_id"
                      label="Conta bancária"
                      options={banks}
                      placeholder="Selecione a conta"
                    />
                  </Col>
                  <Col md="1">
                    <ButtonAdd
                      type="button"
                      onClick={toggleModalCreateBank}
                      title="Cadastrar Conta Bancária"
                      style={{ left: -8 }}
                    >
                      <FaPlus />
                    </ButtonAdd>
                  </Col>
                </Row>
              </Col>
              <Col md="5">
                <Input
                  label="Prazo de recebimento"
                  name="interval"
                  type="number"
                  step="1"
                  placeholder="Dias"
                  min="0"
                />
              </Col>
            </Row>

            {user.has_stone_pdv && (
              <Row>
                <Col lg="12">
                  <Select
                    name="method"
                    label="Método de Pagamento (POS)"
                    options={methodOptions}
                    placeholder="Selecione o método que vai cobrar na POS"
                    defaultValue={methodOptions[0]}
                  />
                </Col>
              </Row>
            )}

            <Row>
              <Col md="6">
                <Checkbox
                  label="Contar só dias úteis para recebimento"
                  checked={checkboxValues.include_weekends}
                  onClick={(e) =>
                    setCheckboxValues({
                      ...checkboxValues,
                      include_weekends: e,
                    })
                  }
                />
              </Col>

              {!user.only_delivery && (
                <Col md="6">
                  <Checkbox
                    label="Disponível no PDV"
                    checked={checkboxValues.available}
                    onClick={(e) =>
                      setCheckboxValues({ ...checkboxValues, available: e })
                    }
                  />
                </Col>
              )}
            </Row>

            <Row>
              {user.delivery_info?.is_delivery_allowed && (
                <Col md="6">
                  <Checkbox
                    label="Disponível no Delivery"
                    checked={checkboxValues.delivery_accepts}
                    onClick={(e) =>
                      setCheckboxValues({
                        ...checkboxValues,
                        delivery_accepts: e,
                      })
                    }
                  />
                </Col>
              )}

              <Col md="6">
                <div style={{ position: 'relative' }}>
                  <Checkbox
                    label="Uso exclusivo"
                    checked={checkboxValues.is_exclusive}
                    onClick={(e) =>
                      setCheckboxValues({ ...checkboxValues, is_exclusive: e })
                    }
                  />
                  <span
                    style={{
                      color: '#6c6c6c',
                      fontSize: '12px',
                      position: 'absolute',
                      top: 20,
                      left: 29,
                    }}
                  >
                    Esse método não será exibido para o cliente, apenas no PDV
                  </span>
                </div>
              </Col>
            </Row>
            <Button
              type="submit"
              containerStyles={{ marginTop: 64, float: 'right' }}
              buttonColor="#2ec9b7"
              title="Cadastrar"
              icon={<FaCheck />}
              onClick={() => {}}
            />
            {/* <SaveButton type="submit" style={{ float: 'right' }}>
              Cadastrar
            </SaveButton> */}
          </StyledForm>
        </Card>
      </Container>
    </Wrapper>
  );
}

export default StorePayment;
