/* eslint-disable react/prop-types */
import React, { useContext, useRef, useState, useCallback } from 'react';

import Switch from 'react-switch';

import { FiMenu } from 'react-icons/fi';
import { useDrag, useDrop } from 'react-dnd';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
} from 'reactstrap';
import { FaCheck, FaEllipsisV, FaImage } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';
import {
  NoImage,
  ProductImage,
  SubmitStyledButton,
  NumberFormatStyled,
  Description,
  ProductName,
  ComplementDescription,
} from './styles';
import OrderComplementContext from '../context';

import api from '~/services/api';
import ImageInput from '~/components/Form/ImageInput';
import PersonalButton from '~/components/Buttons/PersonalButton';
import { differenceInMinutes, format } from 'date-fns';

// import { Container } from './styles';

function ComplementsRow({
  complement,
  handleChangeComplement,
  handleChangeEditComplement,
  handleDelete,
  index,
  user,
  complementsData,
  category,
  setCategories,
  categories,
}) {
  const { move, onDrop } = useContext(OrderComplementContext);
  const ref = useRef();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropDownHidden, setDropDownHidden] = useState(false);

  const canCreateComplement =
    !user?.price_table || user?.price_table?.create_complements;
  const canEditComplement =
    !user?.price_table || user?.price_table?.edit_complements || !user?.price_table?.is_customizable;

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const [{ isDragging }, dragRef] = useDrag({
    item: { type: 'COMPLEMENTCARD', index, complement_id: complement.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (!monitor.didDrop()) {
        const draggedIndex = item.index;
        const targetIndex = index;

        onDrop(item.complement_id, complement.id, draggedIndex, targetIndex);
      }
    },
  });

  const [, dropRef] = useDrop({
    accept: 'COMPLEMENTCARD',
    hover(item, monitor) {
      const draggedIndex = item.index;
      const targetIndex = index;

      if (draggedIndex === targetIndex) {
        return;
      }

      const targetSize = ref.current.getBoundingClientRect();
      const targetCenter = targetSize.height / 2;
      const draggedOffset = monitor.getClientOffset();
      const draggedTop = draggedOffset.y - targetSize.top;
      if (draggedIndex < targetIndex && draggedTop < targetCenter) {
        return;
      }
      if (draggedIndex > targetIndex && draggedTop > targetCenter) {
        return;
      }
      move(item.complement_id, complement.id, draggedIndex, targetIndex);

      item.index = targetIndex;
    },

    drop(item, monitor) {
      const draggedIndex = item.index;
      const targetIndex = index;

      onDrop(item.complement_id, complement.id, draggedIndex, targetIndex);

      setDropDownHidden(false);
    },
  });

  dragRef(dropRef(ref));

  function currencyFormatter(value) {
    if (!Number(value)) return 'R$ 0,00';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  }

  const [buttonsSubmitChangesDesbloqued, setButtonsSubmitChangesDesbloqued] =
    useState([]);

  function handleDesbloquedSubmitChangesButton(id) {
    if (complement.id === id) {
      setButtonsSubmitChangesDesbloqued([id]);
    } else {
      const idsFiltered = buttonsSubmitChangesDesbloqued.filter(
        (item) => item !== id
      );
      setButtonsSubmitChangesDesbloqued(idsFiltered);
    }
  }

  const [price, setPrice] = useState(complement.price);
  const [deliveryPrice, setDeliveryPrice] = useState(complement.delivery_price);
  const [limit, setLimit] = useState(complement.limit);

  const checkPersonalizedHoursActive = () => {
    if (!complement?.enable_times) {
      return true;
    }
    const daysActive = complement?.active_days.split('');
    const today = new Date().getDay();

    if (daysActive[today] === 't') {
      const diff1 = differenceInMinutes(
        new Date(`2023/01/01 ${format(new Date(), 'HH:mm')}`),
        new Date(
          `2023/01/01 ${format(new Date(complement?.start_time), 'HH:mm')}`
        )
      );

      const diff2 = differenceInMinutes(
        new Date(`2023/01/01 ${format(new Date(), 'HH:mm')}`),
        new Date(
          `2023/01/01 ${format(new Date(complement?.end_time), 'HH:mm')}`
        )
      );

      if (diff1 >= 0 && diff2 <= 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleSubmitChanges = useCallback(async () => {
    console.log(complement);
    await api.put(`restaurants/product/complements/${complement.id}`, {
      price: price,
      delivery_price: deliveryPrice,
      limit: limit,
    });
    localStorage.setItem('@gddashboard:isMenuUpdated', true);

    const complementFiltered = complementsData?.find(
      (item) => item.id === complement.id
    );

    complementFiltered.price = price;
    complementFiltered.delivery_price = deliveryPrice;
    complementFiltered.limit = limit;
    toast.success('Complemento alterado com sucesso.');
    setButtonsSubmitChangesDesbloqued([]);
  }, [complement, price, deliveryPrice, limit]);

  function handleValueChange(e) {
    const readyValue = e.floatValue ? (e.floatValue / 100).toFixed(2) : price;

    const formatPrice = (price) => {
      return price.replace(',', '.');
    };

    const formattedPrice = formatPrice(readyValue);

    setPrice(formattedPrice);
  }

  function handleDeliveryValueChange(e) {
    const readyDeliveryValue = e.floatValue
      ? (e.floatValue / 100).toFixed(2)
      : deliveryPrice;

    const formatDeliveryPrice = (deliveryPrice) => {
      return deliveryPrice.replace(',', '.');
    };

    const formattedDeliveryPrice = formatDeliveryPrice(readyDeliveryValue);
    setDeliveryPrice(formattedDeliveryPrice);
  }

  function handleLimitChange(e) {
    const readyLimitValue = e.floatValue;
    setLimit(readyLimitValue);
  }

  const handleDuplicateComplement = useCallback(
    async (complement_id) => {
      try {
        const response = await api.put(`/restaurants/complements/duplicate`, {
          complement_category_id: category.id,
          complement_id,
        });

        const duplicate_item = response.data;

        const newComplementsCategories = [...categories];

        newComplementsCategories.map((cat, idix) => {
          if (cat.id === category.id) {
            newComplementsCategories[idix].complements.splice(
              [index + 1],
              0,
              duplicate_item
            );
          }
        });

        setCategories(newComplementsCategories);

        localStorage.setItem('@gddashboard:isMenuUpdated', true);
        toast.success('Complemento duplicado com sucesso');
      } catch (error) {
        toast.error('Erro ao duplicar complemento');
        console.log(error);
      }
    },
    [category, index, categories, setCategories]
  );
  const [modalImage, setModalImage] = useState(false);

  function toggleImage() {
    setModalImage(!modalImage);
  }
  async function handleSubmit(data) {
    try {
      const parsedData = {};

      if (data.image_id) {
        parsedData.image_id = Number(data.image_id);
      }
      await api.put(
        `restaurants/product/complements/${complement.id}`,
        parsedData
      );

      toast.success('Imagem atualizada com sucesso!');
      localStorage.setItem('@gddashboard:isMenuUpdated', true);
      toggleImage();
      // getMenu(userScreenTop);

      // getCategories();
    } catch (error) {
      toast.error('Falha ao atualizar imagem');
      console.log(error);
    }
  }

  return (
    <tr isDragging={isDragging} ref={ref}>
      <td>
        <div
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          <FiMenu
            title="Clique e arraste para ordenar"
            size={18}
            onMouseDown={() => setDropDownHidden(true)}
            onMouseUp={() => setDropDownHidden(false)}
          />
          {complement?.image ? (
            <ProductImage
              src={complement?.image?.url_thumb || complement?.image?.url}
              alt="Foto do produto"
              onClick={toggleImage}
              title="Clique para atualizar a foto"
              style={{ marginLeft: 16 }}
            />
          ) : (
            <NoImage
              onClick={toggleImage}
              title="Clique para atualizar a foto"
              style={{ marginBottom: 10, marginLeft: 16 }}
            />
          )}
        </div>
        <Modal isOpen={modalImage} toggle={toggleImage} size="md">
          <ModalBody>
            <h3 className="text-center" style={{ marginBottom: 32 }}>
              Atualizar foto do produto
            </h3>
            <p className="text-center">
              Clique sobre a imagem e faça upload da nova foto do produto.
            </p>
            <Form onSubmit={handleSubmit}>
              <ImageInput
                name="image_id"
                alt="Foto do complemento"
                product={complement}
                products={categories}
                // disabled={complement?.is_multistore_child || false}
                isComplement
                setCategories={setCategories}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <a
                  href="https://drive.google.com/file/d/12vkubd07X2JfOe_V0J6zDwIJgef9L23u/view?usp=share_link"
                  target="blank"
                  style={{
                    fontWeight: 'bold',
                    color: '#58CEB1',
                    textDecoration: 'underline',
                  }}
                >
                  <FaImage color="#58CEB1" style={{ marginRight: 10 }} />{' '}
                  Acessar manual da foto
                </a>
                <PersonalButton
                  type="submit"
                  color="#58CEB1"
                  message="Salvar"
                />
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </td>
      <td>
        <ComplementDescription>
          <ProductName>{complement?.name}</ProductName>
          <Description>
            {!complement?.description ? '-' : complement?.description}
          </Description>
        </ComplementDescription>
      </td>
      {!user.only_delivery && (
        <td
          onClick={() => {
            if (!checkPersonalizedHoursActive()) {
              toast.error(
                'Para reativar esse item, clique nos 3 pontinhos > Editar item e desmarque a opção “Horário personalizado”',
                {
                  duration: 5000,
                }
              );
              return false;
            }
          }}
        >
          {' '}
          <Switch
            id={`pdv-${complement.id}`}
            onChange={handleChangeComplement}
            draggable
            checked={complement.available && checkPersonalizedHoursActive()}
            checkedIcon={false}
            uncheckedIcon={false}
            disabled={!checkPersonalizedHoursActive()}
            height={27}
            width={44}
            handleDiameter={16}
            activeBoxShadow="0 0 2px 3px #111"
            boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
            onColor="#0CCAB4"
            offColor="#666666"
          />
        </td>
      )}

      {user.delivery_info.is_delivery_allowed && (
        <td
          onClick={() => {
            if (!checkPersonalizedHoursActive()) {
              toast.error(
                'Para reativar esse item, clique nos 3 pontinhos > Editar item e desmarque a opção “Horário personalizado”',
                {
                  duration: 5000,
                }
              );
              return false;
            }
          }}
        >
          {' '}
          <Switch
            id={`delivery-${complement.id}`}
            onChange={handleChangeComplement}
            draggable
            checked={
              complement.available_in_delivery && checkPersonalizedHoursActive()
            }
            checkedIcon={false}
            uncheckedIcon={false}
            disabled={!checkPersonalizedHoursActive()}
            height={27}
            width={44}
            handleDiameter={16}
            activeBoxShadow="0 0 2px 3px #111"
            boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
            onColor="#0CCAB4"
            offColor="#666666"
          />
        </td>
      )}

      <td>
        {' '}
        <NumberFormatStyled
          prefix="R$"
          format={currencyFormatter}
          name="value"
          defaultValue={complement?.price}
          onValueChange={(e) => handleValueChange(e)}
          onClick={() => {
            setPrice(complement?.price);
            setDeliveryPrice(complement?.delivery_price);
            setLimit(complement?.limit);
            handleDesbloquedSubmitChangesButton(complement.id);
          }}
          key={complement?.price}
          disabled={!canEditComplement}
        />
      </td>

      {user.delivery_info.is_delivery_allowed && !user.only_delivery && (
        <td>
          <NumberFormatStyled
            prefix="R$"
            format={currencyFormatter}
            name="delivery_value"
            defaultValue={complement?.delivery_price}
            onValueChange={(e) => handleDeliveryValueChange(e)}
            onClick={() => {
              setPrice(complement?.price);
              setDeliveryPrice(complement?.delivery_price);
              setLimit(complement?.limit);
              handleDesbloquedSubmitChangesButton(complement.id);
            }}
            key={complement?.delivery_price}
            disabled={!canEditComplement}
          />
        </td>
      )}

      <td>
        <NumberFormatStyled
          name="limit"
          defaultValue={complement.limit}
          onValueChange={(e) => handleLimitChange(e)}
          onClick={() => {
            setPrice(complement?.price);
            setDeliveryPrice(complement?.delivery_price);
            setLimit(complement?.limit);
            handleDesbloquedSubmitChangesButton(complement.id);
          }}
          key={complement?.limit}
          disabled={!canEditComplement}
        />
      </td>
      <td>
        <SubmitStyledButton
          id="limit"
          onClick={handleSubmitChanges}
          type="button"
          disabled={
            !buttonsSubmitChangesDesbloqued.includes(complement.id) ||
            complement?.is_multistore_child
          }
        >
          <FaCheck />
        </SubmitStyledButton>
      </td>

      {/* {(!complement?.is_multistore_child || user.has_ifood) && ( */}
      {
        <td style={{ textAlign: 'right' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: 30,
            }}
          >
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggleDropdown}
              direction={index + 1 === complementsData?.length ? 'up' : 'down'}
            >
              <DropdownToggle tag="span">
                {' '}
                <FaEllipsisV
                  style={{ marginLeft: 20 }}
                  title="Mostrar Opções"
                  cursor="pointer"
                />
              </DropdownToggle>
              <DropdownMenu flip hidden={dropDownHidden}>
                <DropdownItem
                  disabled={!canEditComplement}
                  onClick={() => {
                    handleChangeEditComplement(complement);
                  }}
                >
                  Editar Complemento
                </DropdownItem>
                {!complement.is_multistore_child && (
                  <DropdownItem
                    disabled={!canCreateComplement}
                    onClick={() => handleDelete(complement.id)}
                  >
                    Excluir Item
                  </DropdownItem>
                )}
                {!complement.is_multistore_child && (
                  <DropdownItem
                    disabled={!canCreateComplement}
                    onClick={() => {
                      handleDuplicateComplement(complement.id);
                    }}
                  >
                    Duplicar Item
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          </div>
        </td>
      }
    </tr>
  );
}

export default ComplementsRow;
