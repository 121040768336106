import React, { useState, useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';
import { v4 } from 'uuid';

import { MdDelete, MdEdit } from 'react-icons/md';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';
import { Container, Actions, Delete } from './styles';
import Input from '~/components/Form/Input';

import api from '~/services/api';

function ComplementInputRow({
  input,
  setInputs,
  getComplements,
  getCategories,
  setCount,
  setTotalInputs,
  isInsideProduct = false,
  categories,
  setCategories,
  complement,
  setComplementCategoriesInsideProduct,
  complementCategoriesInsideProduct,
}) {
  const { id } = input.ComplementInput;

  const handleDelete = useCallback(async () => {
    try {
      if (
        window.confirm(
          'Tem certeza que deseja desassociar o insumo deste complemento?'
        )
      ) {
        const response = await api.delete(
          `restaurants/complement-inputs/${id}`
        );

        if (isInsideProduct) {
          const newCategories = [...complementCategoriesInsideProduct];

          newCategories.map((cat, idix) =>
            cat.complements.map((item, idex) => {
              if (item.id === complement.id) {
                newCategories[idix].complements[idex].inputs =
                  response.data.inputs;
              }
            })
          );

          setComplementCategoriesInsideProduct(newCategories);
        } else {
          const aleatoryId = v4();

          setCount(aleatoryId);
          getCategories();
        }

        const arrayInputs = [];

        response.data.inputs &&
          response.data.inputs.forEach((input) => {
            const total = parseFloat(
              (
                parseFloat(input.unitary_price) *
                parseFloat(input.ComplementInput.quantidade)
              ).toFixed(2)
            );
            arrayInputs.push(total);
          });

        response.data.intermediaries &&
          response.data.intermediaries.forEach((inter) => {
            const total = parseFloat(
              (
                parseFloat(inter.unitary_price) *
                parseFloat(inter.ComplementIntermediary.quantidade)
              ).toFixed(2)
            );
            arrayInputs.push(total);
          });

        if (arrayInputs.length > 0) {
          const inputsTotal = arrayInputs.reduce(
            (Acc, input) => Acc + input,
            0
          );
          setTotalInputs(inputsTotal);
        }

        setInputs(response.data.inputs);

        toast.success('Insumo desassociado a esse complemento com sucesso!');
      }
    } catch (err) {
      toast.error('Erro ao desassociar insumo.');
      console.log(err);
    }
  }, [
    setCount,
    id,
    setInputs,
    setTotalInputs,
    complementCategoriesInsideProduct,
    setComplementCategoriesInsideProduct,
    complement,
    isInsideProduct,
    getCategories,
  ]);

  const handleUpdateInput = useCallback(
    async (data) => {
      try {
        const handleData = {
          quantidade: data.quantidade.replace(',', '.'),
        };

        const response = await api.put(
          `/restaurants/complement-inputs/${id}`,
          handleData
        );

        if (isInsideProduct) {
          const newCategories = [...complementCategoriesInsideProduct];

          newCategories.map((cat, idix) =>
            cat.complements.map((item, idex) => {
              if (item.id === complement.id) {
                newCategories[idix].complements[idex].inputs =
                  response.data.inputs;
              }
            })
          );

          setComplementCategoriesInsideProduct(newCategories);
        } else {
          const aleatoryId = v4();

          setCount(aleatoryId);
        }

        const arrayInputs = [];

        response.data.inputs &&
          response.data.inputs.forEach((input) => {
            const total = parseFloat(
              (
                parseFloat(input.unitary_price) *
                parseFloat(input.ComplementInput.quantidade)
              ).toFixed(2)
            );
            arrayInputs.push(total);
          });

        response.data.intermediaries &&
          response.data.intermediaries.forEach((inter) => {
            const total = parseFloat(
              (
                parseFloat(inter.unitary_price) *
                parseFloat(inter.ComplementIntermediary.quantidade)
              ).toFixed(2)
            );
            arrayInputs.push(total);
          });

        if (arrayInputs.length > 0) {
          const inputsTotal = arrayInputs.reduce(
            (Acc, input) => Acc + input,
            0
          );
          setTotalInputs(inputsTotal);
        }

        setInputs(response.data.inputs);

        toast.success('Insumo atualizado com sucesso!');
      } catch (error) {
        toast.error('Erro ao atualizar insumo.');
      }
    },
    [
      setCount,
      id,
      setInputs,
      setTotalInputs,
      isInsideProduct,
      complementCategoriesInsideProduct,
      setComplementCategoriesInsideProduct,
      complement,
    ]
  );

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const input_initial = {
    name: input.name,
    quantidade: input.ComplementInput.quantidade,
  };

  return (
    <>
      <tr>
        <td>{input.name}</td>
        <td>
          {input.ComplementInput.quantidade} {input.unidade}
        </td>
        <td>
          <Actions>
            <Delete onClick={toggle}>
              <MdEdit size={20} color="#17a2b8" />
            </Delete>
            <Delete onClick={() => handleDelete()}>
              <MdDelete size={20} />
            </Delete>
          </Actions>
          <Modal isOpen={modal} toggle={toggle}>
            <Form onSubmit={handleUpdateInput} initialData={input_initial}>
              <ModalHeader>Editar Informações do Insumo</ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col md="6">
                      <Input label="Insumo:" name="name" type="text" disabled />
                    </Col>
                    <Col md="5">
                      <Input
                        label="Esse Produto Gasta:"
                        name="quantidade"
                        type="text"
                      />
                    </Col>
                    <Col style={{ paddingTop: 45 }}>
                      <p>{input.unidade}</p>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" type="submit" onClick={toggle}>
                  Salvar
                </Button>{' '}
                <Button color="danger" onClick={toggle}>
                  Cancelar
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        </td>
      </tr>
    </>
  );
}

export default ComplementInputRow;
