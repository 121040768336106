import NumberFormat from 'react-number-format';
import styled from 'styled-components';

export const Container = styled.tr`
  display: ${(props) => props.isOpen && 'none'};
`;

export const TdStyled = styled.td`
  margin-left: 58px;
  &::before {
    content: '';
    position: absolute;
    top: -36px;
    left: 0px;

    margin-right: 45px;
    margin-left: 28px;
    background-image: ${(props) => `url(${`${props.backgroundImage}`}) `};
    background-position: center;
    background-repeat: no-repeat;
    width: 40px;
    height: 80px;

    @media (max-width: 1281px) {
      margin-left: 0px;
    }
  }
`;

export const NumberFormatStyled = styled(NumberFormat)`
  text-align: center;
  height: 44px;
  ${(props) =>
    props.limit
      ? 'width: 60px;'
      : `
  width: 90px;
  min-width: 82px;
  max-width: 103px;
  `}
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  margin-top: 8px;

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    background-color: #f5f5f5;
    color: #808080;
  `}
`;

export const SubmitStyledButton = styled.button`
  height: 44px;
  width: 44px;
  padding: 10px;
  border-radius: 8px;
  border: none;
  margin-top: 8px;
  margin-left: 8px;
  background-color: #0ccab4;

  svg {
    color: #fff;
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.colors.lightGray};

    svg {
      filter: opacity(0.3);
      color: #333;
    }
  }
`;

export const DropdownDiv = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  margin-right: 15px;
  justify-content: end;
  @media (min-width: 1600px) {
    justify-content: end;
  }
`;
export const ComplementNameTable = styled.p`
  max-width: 390px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1679px) {
    min-width: 150px;
    max-width: 150px;
  }
  @media (max-width: 1026px) {
    min-width: 100px;
    max-width: 100px;
  }
`;
