import React, { useRef, useCallback } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import Input from '~/components/Form/Input';

import SaveButton from '~/components/Buttons/SaveButton';
import getValidationErrors from '~/services/getValidationErrors';
import api from '~/services/api';

function EditSubcategory({
  category,
  getCategories,
  toggleModalCreateSubcategory,
  getSubCategories,
  parentSubcategory,
  toggleEdit,
  subcategory,
}) {
  const formRef = useRef(null);
  const handleSubmit = useCallback(
    async (data) => {
      try {
        if (formRef?.current) {
          formRef.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        console.log('data > ', {
          name: data.name,
          id: subcategory,
        });

        await api.put(`/restaurants/cash-flows/subcategories/${subcategory}`, {
          name: data.name,
        });

        toast.success('Subcategoria alterada com sucesso!');

        getCategories();
        getSubCategories();
        toggleEdit();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRef?.current) {
            formRef.current.setErrors(errors);
          }
        } else if (!error.response?.ok) {
          if (
            error.response?.data?.errorType ===
            'some_cash_flow_category_is_protected'
          ) {
            toast.error(
              'O usuário não pode editar categorias padrões do sistema'
            );
          } else {
            toast.error('Erro ao cadastrar subcategoria');
          }
        }
      }
    },
    [getCategories, toggleModalCreateSubcategory, category, getSubCategories]
  );

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Input label="Nome da subcategoria" name="name" />
        <SaveButton style={{ float: 'right' }} type="submit" />
      </Form>
    </>
  );
}

export default EditSubcategory;
